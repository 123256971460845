<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col cols="10">
                <span class="text-h4 font-weight-bold">{{ mdlTtl }}</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex justify-right" cols="2">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </v-col>
        </v-row>
        <v-row class="mt-5 pa-0" no-gutters>
            <v-col class="overflow-y-auto rounded-lg pa-2" cols="12" style="height:500px;border: 1px solid #dbdbdb !important;">               
                <v-chip v-for="(item, j) in atndList" :key="j" 
                    class="ma-2 white--text"
                    color="blue"
                    label
                    >
                    {{item.name}}
                </v-chip>
            </v-col>
        </v-row>
    </v-sheet>            
</template>
<script>
export default {
    
    name: 'AtndMdl',

    props : {
        mdlTtl          : { type: String, default: '참석자 상세보기' },
        atndList      : { type: Array, default: () => { return [] } },
    },
            
    components: {
       
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
        }) 
    },

    computed: {
        
    },

    watch:{
        
    },
        
    methods: {        
        onClose: function () {
            this.$emit('isClose')
        },
    },
    
    data: () => ({
        headers    : [                        
            { text: '연번', value: 'rn', sortable: true, width: '70' },
            { text: '참석자', value: 'bnfcrNm', sortable: true, width: '80' },
        ],
    }),
};
</script>