<template>
    <v-sheet class="physcThrpyRcrdDay">        
        <div class="mx-2 mt-2 d-flex">
            <div style="width:83px;"></div>
            <v-spacer></v-spacer>
            <expandNowDatePicker :prtDate="''" @nowDate="getDate" :lmtNxtDt="lmtNxtDt"></expandNowDatePicker>
            <v-spacer></v-spacer>
            <div>
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn :class="list.class" height="30" :color="list.color"
                        :disabled="list.disabled" @click="onClickBtn(list.text)" samll rounded>
                        <v-icon small>{{list.icon}}</v-icon>
                        <span style="font-size: 1rem;">{{list.text}}</span>
                    </v-btn>
                </span>
            </div>
        </div>
        
        <div class="d-flex align-center mt-2">
            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold">물리치료기록</span>
            <!-- <v-btn
                class="ml-1" width="20" height="20" @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 25)" icon>
                <v-icon size="20" style="padding-top: 2px;">mdi-help-circle-outline</v-icon>
            </v-btn> -->
            <v-spacer></v-spacer>
            <div class="d-flex justify-center align-center pr-4">
                <span class="black--text font-weight-medium">작성자</span>
                <span class="px-1">&#58;</span>
                <span class="d-inline-block" style="width: 70px;">
                    <v-text-field v-model="allWrtr" color="blue" height="28" ref="info" readonly
                        hide-details outlined dense :key="forceRender"></v-text-field>
                </span>
                <v-btn 
                    class="ml-1 brown lighten-3" 
                    small 
                    min-width="30"
                    height="26"  
                    style="padding:0px 1px 0px 2px !important"
                    @click="onModal(1,0,0,0)"
                    >
                    <v-icon size="20" class="white--text">mdi-account-edit</v-icon>
                </v-btn>
            </div>
        </div>
        <v-form ref="form" lazy-validation>
            <div v-if="progress" class="mt-2">
                <div
                
                    class="d-inline-block"
                    style="width: 100%; height: 38px; text-align: center;">
                    <v-progress-linear
                        class="mb-1" color="yellow darken-2"
                        indeterminate>
                    </v-progress-linear>
                    <span class="greyC00--text font-weight-medium" style="font-size: 0.95rem;">불러오는&nbsp;중...</span>
                </div>            
            </div>
            <div v-else class="mt-2">
                <div class="d-flex greyE01 pa-0 mr-2__5">
                    <div class="d-flex justify-center align-center tableBT tableBB" style="width:7%;">
                        <span class="black--text font-weight-medium">수급자명</span>
                    </div>
                    <div class="d-flex justify-center align-center tableBT tableBL tableBB" style="width:7%;">
                        <span class="black--text font-weight-medium">성별(나이)</span>
                    </div>
                    <div class="tableBT tableBL" style="width:86%">
                        <div class="d-flex">
                            <div class="d-flex pa-1 justify-center align-center tableBB" style="width:15%">
                                <span class="black--text font-weight-medium">치료시간</span>
                            </div>
                            <div class="tableBL" style="width:85%">
                                <div class="d-flex tableBB">
                                    <div class="d-flex pa-1 justify-center align-center" style="width:10%">
                                        <span class="black--text font-weight-medium">구분</span>
                                    </div>
                                    <div class="tableBL d-flex pa-1 justify-center align-center" style="width:23%">
                                        <span class="black--text font-weight-medium">치료방법</span>
                                    </div>
                                    <div class="tableBL d-flex pa-1 justify-center align-center" style="width:35%">
                                        <span class="black--text font-weight-medium">특이사항</span>
                                    </div>
                                    <div class="tableBL d-flex pa-1 justify-center align-center" style="width:13%">
                                        <span class="black--text font-weight-medium">담당자</span>
                                    </div>
                                    <div class="tableBL d-flex pa-1 justify-center align-center" style="width:13%">
                                        <span class="black--text font-weight-medium">처리</span>
                                    </div>
                                    <div class="tableBL d-flex pa-1 justify-center align-center" style="width:6%">
                                        <span class="black--text font-weight-medium">기록</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="physcTRDList.length > 0" style="height:590px;overflow-y:scroll">
                    <div class="d-flex pa-0 ma-0" v-for="(item, j) in physcTRDList" :key="j">
                        <div class="d-flex justify-center align-center tableBB" style="width:7%;">
                            {{item.bnfcrNm}}
                        </div>
                        <div class="d-flex justify-center align-center tableBL tableBB" style="width:7%;">
                            <span class="cmmFontSizeOne d-inline-block">
                                <span class="d-inline-block pt-1">
                                    <v-img class="ic-sex" v-if="item.gndr == 'F'" src="../../assets/icon/ic-female.svg"></v-img>
                                    <v-img class="ic-sex" v-else-if="item.gndr == 'M'" src="../../assets/icon/ic-male.svg"></v-img>
                                </span>
                            </span>
                            <span class="mr-1">&#40;{{item.age}}&#41;</span>
                        </div>
                        <div class="tableBL" style="width:86%">
                            <div v-for="(list, k) in item.list" :key="k" class="d-flex">
                                <div class="d-flex pa-1 justify-center align-center tableBB" style="width:15%">
                                    <span style="width:60px;">
                                        <CmmTimeField v-model="list.ofrTmStrt"></CmmTimeField>                                     
                                    </span>                                
                                    <span class="px-1">&#126;</span>
                                    <span style="width:60px;">
                                        <CmmTimeField v-model="list.ofrTmEnd"></CmmTimeField> 
                                    </span>
                                </div>
                                <div class="tableBL" style="width:85%">
                                    <div class="d-flex tableBB" v-for="(itm, n) in list.subList" :key="n">
                                        <div class="d-flex pa-1 justify-center align-center" style="width:10%">
                                            {{itm.clsfc3Cd?.aftValCdToNm(codeItem37)?.substring(0,2)}}
                                        </div>
                                        <div class="tableBL pa-1" style="width:23%">
                                            <div class="d-flex">
                                                <div style="width:85%">
                                                    <span v-if="itm.clsfc3Cd=='1' && codeItem92.length > 0">   
                                                        <div class="d-inline-block" v-for="(cdlist, a) in codeItem92" :key="a">
                                                            <v-checkbox v-if="onShowItem(itm.thrMthdcd,cdlist.valcd)" class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="itm.itm1ValAr"  :label="cdlist.valcdnm" hide-details :value="cdlist.valcd"  :style="setStyle(cdlist.valcdnm.length)"></v-checkbox>
                                                        </div>
                                                    </span>
                                                    <span v-if="itm.clsfc3Cd=='2' && codeItem93.length > 0">                                   
                                                        <div class="d-inline-block" v-for="(cdlist, b) in codeItem93" :key="codeItem92.length+b">
                                                            <v-checkbox v-if="onShowItem(itm.thrMthdcd,cdlist.valcd)" class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="itm.itm1ValAr"  :label="cdlist.valcdnm" hide-details :value="cdlist.valcd"  :style="setStyle(cdlist.valcdnm.length)"></v-checkbox>
                                                        </div>
                                                    </span>
                                                </div>
                                                <div style="width:15%">
                                                    <v-btn @click="onModalType(j,k,n)" color="blue200" height="26" icon>
                                                        <v-icon>mdi-plus-circle-outline</v-icon>
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tableBL d-flex pa-1 justify-center align-center" style="width:35%">
                                            <div style="width:90%">
                                                <v-textarea v-model="itm.cntnt" class="rmrksArea" style="font-size:0.9rem !important;"
                                                    rows="2" outlined dense hide-details no-resize>
                                                </v-textarea>
                                            </div>
                                            <div style="width:10%">
                                                <v-btn @click="onModalCntnt(j,k,n)" color="grey006" height="26" icon>
                                                    <v-tooltip top >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon v-bind="attrs" v-on="on">mdi-clipboard-text-outline</v-icon>
                                                        </template>
                                                        <span>특이사항 불러오기</span>
                                                    </v-tooltip>
                                                </v-btn>
                                            </div>
                                        </div>
                                        <div class="tableBL d-flex pa-1 justify-center align-center" style="width:13%">
                                            <span style="width:65px;margin-left:12px;">
                                                <v-text-field v-model="itm.wrtr" color="blue" height="28" hide-details outlined dense :key="forceRender"></v-text-field>
                                            </span>
                                            <v-btn 
                                                class="ml-1 brown lighten-3" 
                                                small 
                                                min-width="22"
                                                height="22"  
                                                style="padding:0px 1px 0px 2px !important"
                                                @click="onModal(0,j,k,n)"
                                                >                                    
                                                <v-tooltip top >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon size="20" class="white--text" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                                    </template>
                                                    <span>직원선택</span>
                                                </v-tooltip>

                                            </v-btn>
                                        </div>
                                        <div class="tableBL d-flex pa-1 justify-center align-center" style="width:13%">
                                            <v-btn icon color='blue200' @click="onSave(j,k,n)">
                                                <v-icon>mdi-content-save-outline</v-icon>
                                            </v-btn>
                                            <v-btn icon :disabled="!(itm.bnftOfrRecHisPk > 0)" color='grey006'  @click="delRow(j,k,n)">
                                                <v-icon>mdi-trash-can-outline</v-icon>
                                            </v-btn>
                                            <v-btn class="" color="grey006" height="26" @click="onClickBtn('출력', itm.bnMmbrPk)" :disabled="!(itm.bnftOfrRecHisPk > 0)" icon>
                                                <v-icon>mdi-printer-outline</v-icon>
                                            </v-btn>
                                        </div>
                                        <div class="tableBL d-flex pa-1 justify-center align-center" style="width:6%">
                                            <v-btn v-if="itm.phyThrPlnPk > 0" icon @click="showDialog(j,k,n)">
                                                <v-icon>mdi-clipboard-text-outline</v-icon>
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="d-flex justify-center align-center py-4 mr-2__5" style="border-bottom: 1px solid #dbdbdb;">
                    <span>조회된 목록이 없습니다.</span>
                </div>
            </div>
        </v-form>
        <div class="ma-0 pa-0">
            <v-dialog v-model="menu[0]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="isModal(0)" @outEmp="getEmpInfo" :prtWrtDt="inqYMD"></EmpSelector>
            </v-dialog>
        </div>        
        <div class="pa-0 ma-0">
            <v-dialog eager v-model="menu[1]" persistent max-width="1400" min-width="1400">                
               <PhyscThrpyPlnComp 
                    ref="physcThrpyPln"
                    :bnMmbrPk="bnMmbrPk" 
                    :inqYYYY="year"
                    :physcThrpyPlnPk="physcThrpyPlnPk"
                    @modalEvent="modalEvent"></PhyscThrpyPlnComp>
            </v-dialog>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog eager v-model="menu[2]" persistent max-width="800" min-width="800">                
               <PhyscThrpyRcrdDayDirt 
                    ref="physcThrpyRcrdDayDirt"
                    :inqYMD="inqYMD"
                    :physcDayDirtPk="physcDayDirtPk"
                    @modalEvent="modalEvent"
                    @isClose="isModal(2)"></PhyscThrpyRcrdDayDirt>
            </v-dialog>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="menu[3]" content-class="round" persistent max-width="600">
                <PhyscThrpyType ref="physcThrpyType" 
                :physcThrpyTypePk="physcThrpyTypePk"
                :thrClcd="thrClcd"
                :thrMthdcd="thrMthdcd"
                @cdReload="cdReload"
                @isClose="isModal(3)" 
                @onApply="onModalApply"></PhyscThrpyType>
            </v-dialog>       
        </div>

        <div class="pa-0 ma-0">
            <v-dialog v-model="menu[4]" v-if="menu[4]" content-class="round" persistent max-width="600">
                <RecCntntListMdl ref="recCntntListMdl"                 
                :bnMmbrPk="bnMmbrPk"
                :clsfc1Cd="'304'"
                :clsfc2Cd="'1'"
                :clsfc3Cd="thrClcd"
                :inqYMD="inqYMD"
                @isClose="isModal(4)" 
                @outCntnt="setCntnt"></RecCntntListMdl>
            </v-dialog>       
        </div>

        
    </v-sheet>
</template>
<script>
import CmmTimeField from '../commons/CmmTimeField.vue';
import expandNowDatePicker from '../../components/commons/expandNowDatePicker.vue';
import EmpSelector from '../../components/commons/EmpSelector.vue';
import PhyscThrpyPlnComp from '../../components/bnftSrv/PhyscThrpyPln.vue'; //물리치료계획
import PhyscThrpyRcrdDayDirt from './PhyscThrpyRcrdDayDirt.vue'; //물리치료계획
import PhyscThrpyType from '../../components/bnftSrv/PhyscThrpyType.vue'; //치료방법
import RecCntntListMdl from './RecCntntListMdl.vue'; //특이사항 불러오기

import { getMultiStcd } from '../../api/index.js';
import { selPhyThrRecDdGrpList, insPhythrBnftOfrRecMulti, delAllBnftOfrRec } from '../../api/bnftrcrd.js';
import { selFcltyInitList  } from '../../api/opr.js';

export default {
    name: 'PhyscThrpyRcrdDay',
        
    components: {
        expandNowDatePicker,
        EmpSelector,
        PhyscThrpyPlnComp,
        CmmTimeField,
        PhyscThrpyRcrdDayDirt,
        PhyscThrpyType,
        RecCntntListMdl
    },

    created: function(){
         this.$nextTick(function () { 
            this.onCodeload()
            this.lmtNxtDt = this.$moment().format('YYYY-MM-DD')
            this.getFcltyInitList()
         })
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch:{ 
        'inqYMD':function(){      
            this.year = Number(this.inqYMD.substring(0,4))      
            this.onLoad()
        },
        getSubEvt: function (bol) {          

            if(bol && this.rmPhyThrRcrdCnfm){
                this.rmBnftOfrRec()
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
                this.rmPhyThrRcrdCnfm = false
            }
        },
    },
        
    methods: {
        onCodeload:function(){
            let fcltyNum = this.$store.getters.getFcltyNum
            getMultiStcd(['37', '92', '93'],fcltyNum)
                    .then((response) => ( this.codeMapping(response.data) ))
                    .catch((error) => console.log(error))
        },
        codeMapping: function (res){            
            this.codeItem37 = res.vCd37
            this.codeItem92 = res.vCd92
            this.codeItem93 = res.vCd93
        },        
        getDate: function (value) {
            this.inqYMD = value.date
        },
        setStyle:function(len){
            let style = "";
            let wd = (len*15)+20;
            style="width:"+wd+"px"        
            return style;
        },  
        onClickBtn: function (text, pk){
            switch (text) {
                case '전체저장':
                    this.onAllSave()
                    break
                case '물리치료추가':
                    this.physcDayDirtPk = 1
                    this.menu.splice(2,1,true)
                    break
                case '출력':
                    this.$parent.$parent.$parent.$parent.dlyThrPrt(pk)
                    break
                default:
                    break
            }
        },
        onLoad:function(){
            this.getPhyThrRecDdList()
        },        
        getPhyThrRecDdList(){
            
            let obj = {
                fcltyNum: this.$store.getters.getFcltyNum,               
                inqYMD: this.inqYMD,
            }
            this.progress = true
            selPhyThrRecDdGrpList(obj)
            .then((response) => ( this.getPhyThrRecDdListAfter(response.data)))
            .catch((error) => `console.log`('upload error /bnftrcrd/selPhyThrRecDdGrpList : ' + error))
        },
        getPhyThrRecDdListAfter:function(res){    
            
            this.physcTRDList.splice(0)
            
            res.forEach(el => {
                el.list.forEach(list => {
                    if(list.ofrTmHhStrt?.isEmptyVal() && list.ofrTmMmStrt?.isEmptyVal()) list.ofrTmStrt = list.ofrTmHhStrt + ":" + list.ofrTmMmStrt
                    if(list.ofrTmHhEnd?.isEmptyVal() && list.ofrTmMmEnd?.isEmptyVal()) list.ofrTmEnd = list.ofrTmHhEnd + ":" + list.ofrTmMmEnd
                    
                    list.subList.forEach(itm => {

                        if(itm.itm1ValAr == null) itm.itm1ValAr = []

                        if(itm.bnftOfrRecPk > 0) {
                            itm.thrMthdcd.splice(0)
                            itm.itm1ValAr.forEach(x => {
                                itm.thrMthdcd.push(x)
                            });
                        }
                    })
                    
                })

                this.physcTRDList.push(el)
            });         

            
            this.progress = false  
        },        
        onModal: function (key, idx1, idx2, idx3) {  
            this.key = key
            this.idx1 = idx1
            this.idx2 = idx2
            this.idx3 = idx3
            this.menu.splice(0, 1, true)
        },
        getEmpInfo: function (obj) {
            if(this.key == 0){
                let target = this.physcTRDList[this.idx1].list[this.idx2].subList[this.idx3]
                target.wrtr = obj[0].name
                target.wrtrMmbrPk = obj[0].mmbrPk
                this.idx = 0
            }else{
                this.allWrtr = obj[0].name
                this.physcTRDList.forEach(el => {
                    el.list.forEach(list => {
                        list.subList.forEach(itm => {
                            itm.wrtr = obj[0].name
                            itm.wrtrMmbrPk = obj[0].mmbrPk
                        });
                    });
                    
                });               
            }

            this.key = 0
            this.idx = 0
        },
        onShowItem:function(arr,target){
            let result = false
            if(arr !=undefined){
                result = arr.some(x=>x==target)
            }
            return result
        },
        onAllSave :function(){
            if(this.$refs.form.validate()){
                let obj = {list:[]}

                this.physcTRDList.forEach(el => {
                    el.list.forEach(list => {
                        let ofrTmHhStrt = ''
                        let ofrTmMmStrt = ''
                        let ofrTmHhEnd = ''
                        let ofrTmMmEnd = ''

                        if(list.ofrTmStrt?.isEmptyVal()){
                            let ofrTmStrt = list.ofrTmStrt.split(":")
                            ofrTmHhStrt = ofrTmStrt[0]
                            ofrTmMmStrt = ofrTmStrt[1]
                        }

                        if(list.ofrTmEnd?.isEmptyVal()){
                            let ofrTmEnd = list.ofrTmEnd.split(":")
                            ofrTmHhEnd = ofrTmEnd[0]
                            ofrTmMmEnd = ofrTmEnd[1]
                        }

                        list.subList.forEach(itm => {
                            if(itm.bnMmbrPk > 0){
                                let item = {
                                    fcltyNum: this.$store.getters.getFcltyNum,
                                    bnMmbrPk : itm.bnMmbrPk,
                                    userNm : this.$store.getters.getUserNm,
                                    bnftOfrRecHisPk:itm.bnftOfrRecHisPk,
                                    phyThrPlnPk:itm.phyThrPlnPk,
                                    recDt:this.inqYMD,
                                    wrtr:itm.wrtr,
                                    wrtrMmbrPk:itm.wrtrMmbrPk,
                                    cntnt:itm.cntnt,                    
                                    clsfc1Cd:'304',
                                    clsfc2Cd:'1',
                                    clsfc3Cd:itm.clsfc3Cd,
                                    listDtl :[{
                                        bnftOfrRec304Pk:itm.bnftOfrRecPk,
                                        ofrTmHhStrt:ofrTmHhStrt,
                                        ofrTmMmStrt:ofrTmMmStrt,
                                        ofrTmHhEnd:ofrTmHhEnd,
                                        ofrTmMmEnd:ofrTmMmEnd,
                                        itm1ValAr:itm.itm1ValAr,
                                        wrtr:itm.wrtr,
                                        wrtrMmbrPk:itm.wrtrMmbrPk,
                                    }]
                                }

                                if(itm.itm1ValAr.length > 0 || itm.cntnt?.isEmptyVal()){
                                    obj.list.push(item)
                                }
                            }
                        });

                    });

                });

                

                if(obj.list.length > 0){
                    insPhythrBnftOfrRecMulti(obj)
                        .then((response) => ( this.onSaveAfter(response.data) ))
                        .catch((error) => console.log('upload error /bnftrcrd/insPhythrBnftOfrRecMulti : ' + error))
                }else{                    
                    let obj = {} 
                    obj.actv = true                 // 경고 노출여부
                    obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                    obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                    obj.title = '변경 대상을 찾을 수 없습니다.'         // 경고 타이틀

                    this.$store.commit('setWrnnInf', obj)
                }
            }
        },
        onSave: function(idx1,idx2,idx3){
            let target = this.physcTRDList[idx1].list[idx2].subList[idx3]
            let gFcltyNum = this.$store.getters.getFcltyNum
            let gBnMmbrPk = target.bnMmbrPk
            let gUserNm = this.$store.getters.getUserNm
            let gInqYMD = this.inqYMD

            let ofrTmHhStrt = ''
            let ofrTmMmStrt = ''
            let ofrTmHhEnd = ''
            let ofrTmMmEnd = ''

            if(gBnMmbrPk > 0){
                
                if(this.physcTRDList[idx1].list[idx2].ofrTmStrt?.isEmptyVal()){
                    let ofrTmStrt = this.physcTRDList[idx1].list[idx2].ofrTmStrt.split(":")
                    ofrTmHhStrt = ofrTmStrt[0]
                    ofrTmMmStrt = ofrTmStrt[1]
                }

                if(this.physcTRDList[idx1].list[idx2].ofrTmEnd?.isEmptyVal()){
                    let ofrTmEnd = this.physcTRDList[idx1].list[idx2].ofrTmEnd.split(":")
                    ofrTmHhEnd = ofrTmEnd[0]
                    ofrTmMmEnd = ofrTmEnd[1]
                }

                let recItems = {
                    fcltyNum: gFcltyNum,
                    bnMmbrPk : gBnMmbrPk,
                    userNm : gUserNm,
                    bnftOfrRecHisPk:target.bnftOfrRecHisPk,
                    phyThrPlnPk:target.phyThrPlnPk,
                    recDt:gInqYMD,
                    wrtr:target.wrtr,
                    wrtrMmbrPk:target.wrtrMmbrPk,
                    cntnt:target.cntnt,                    
                    clsfc1Cd:'304',
                    clsfc2Cd:'1',
                    clsfc3Cd:target.clsfc3Cd,
                    listDtl :[{
                        bnftOfrRec304Pk:target.bnftOfrRecPk,
                        ofrTmHhStrt:ofrTmHhStrt,
                        ofrTmMmStrt:ofrTmMmStrt,
                        ofrTmHhEnd:ofrTmHhEnd,
                        ofrTmMmEnd:ofrTmMmEnd,
                        itm1ValAr:target.itm1ValAr,
                        wrtr:target.wrtr,
                        wrtrMmbrPk:target.wrtrMmbrPk,
                    }]
                }


                let obj = {list:[recItems]}

                console.log(obj)

                insPhythrBnftOfrRecMulti(obj)
                    .then((response) => ( this.onSaveAfter(response.data) ))
                    .catch((error) => console.log('upload error /bnftrcrd/insPhythrBnftOfrRecMulti : ' + error))
            }
            
        },
        onSaveAfter:function(res){
            let obj = {} 
            obj.actv = true
            obj.title = res.message         // 경고 타이틀
            obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200){                                                
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.onLoad()
            }
            else{
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)                
            }            

            this.$store.commit('setWrnnInf', obj)
        },
        delRow:function(idx1,idx2,idx3){
            this.idx1=idx1
            this.idx2=idx2
            this.idx3=idx3
            
            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            obj.title = '물리(작업)치료기록'
            obj.cntnt = [this.physcTRDList[this.idx1].list[this.idx2].subList[this.idx3].bnfcrNm+' 의 치료기록을(를) 삭제하시겠습니까?']
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 삭제하겠습니다.'

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
            });   

            this.rmPhyThrRcrdCnfm = true

        },
        rmBnftOfrRec:function(){    
            let target = this.physcTRDList[this.idx1].list[this.idx2].subList[this.idx3]            
            
            console.log(target)

            if(target.bnMmbrPk > 0){

                let obj = {
                    fcltyNum    : this.$store.getters.getFcltyNum,
                    userNm      : this.$store.getters.getUserNm,
                    recDt       : this.inqYMD,
                    bnMmbrPk    : target.bnMmbrPk,
                    clsfc1Cd    : '304',
                    clsfc2Cd    : '1',
                    clsfc3CdS   : [],
                }

                obj.clsfc3CdS.push(target.clsfc3Cd)

                
                delAllBnftOfrRec(obj)                
                    .then((response) => ( this.onDeleteAfter(response.data) ))
                    .catch((error) => console.log('upload error /bnftrcrd/delAllBnftOfrRec : ' + error))
            }else{
                this.idx=0
                let obj = {} 
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = '삭제 할 대상이 존재하지 않습니다.'         // 경고 타이틀

                this.$store.commit('setWrnnInf', obj)
            }           
        },
        onDeleteAfter(res){   
            
            let obj = {} 
            obj.actv = true
            obj.title = res.message         // 경고 타이틀
            obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200){                                                
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.onLoad()
            }
            else{
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)                
            }            

            this.$store.commit('setWrnnInf', obj)
            
            this.idx1=0
            this.idx2=0
            this.idx3=0
        },
        modalEvent: function (event) { 
            if(event == 99){
                this.onLoad();
            }else{               
                this.bnMmbrPk = -1
                this.physcThrpyPlnPk = -1
                this.menu.splice(1, 1, false)
            }      
        },
        isModal:function(key){
            if(key == 2){
                this.physcDayDirtPk = -1
            } else if(key == 3) {
                this.thrClcd = ''
                this.thrMthdcd.splice(0)
                this.physcThrpyTypePk = -1
            } else if(key == 4){
                this.thrClcd = ''
                this.bnMmbrPk = -1
            }

            this.menu.splice(key, 1, false)
        },
        onModalType:function(idx1,idx2,idx3){
            this.idx1=idx1
            this.idx2=idx2
            this.idx3=idx3

            this.thrClcd = this.physcTRDList[idx1].list[idx2].subList[idx3].clsfc3Cd

            this.thrMthdcd.splice(0)
            this.physcTRDList[idx1].list[idx2].subList[idx3].thrMthdcd.forEach(el => {
                this.thrMthdcd.push(el)
            });
            this.physcThrpyTypePk = 1

            this.menu.splice(3, 1, true)

        },
        onModalApply(selecter){
            this.physcTRDList[this.idx1].list[this.idx2].subList[this.idx3].thrMthdcd = selecter            
            this.idx1=0
            this.idx2=0
            this.idx3=0
        },
        cdReload:function(){               
            let fcltyNum = this.$store.getters.getFcltyNum
            getMultiStcd(['92','93'], fcltyNum)
                    .then((response) => ( this.cdReloadAfter(response.data) ))
                    .catch((error) => console.log(error))
        },
        cdReloadAfter:function(res){
            this.codeItem92 = res["vCd92"]
            this.codeItem93 = res["vCd93"]
            this.onLoad()
        },
        showDialog(idx1,idx2,idx3){                      
            let target = this.physcTRDList[idx1].list[idx2].subList[idx3]                              
            this.bnMmbrPk = target.bnMmbrPk
            this.physcThrpyPlnPk = target.bnftOfrPlnHisPk                                  
            this.menu.splice(1, 1, true) 
        },
        onModalCntnt:function(idx1,idx2,idx3){
            let target = this.physcTRDList[idx1].list[idx2].subList[idx3]                              
            this.bnMmbrPk = target.bnMmbrPk            
            this.idx1=idx1
            this.idx2=idx2
            this.idx3=idx3
            this.thrClcd = this.physcTRDList[idx1].list[idx2].subList[idx3].clsfc3Cd
            this.menu.splice(4, 1, true)
        },
        setCntnt:function(obj){
            this.physcTRDList[this.idx1].list[this.idx2].subList[this.idx3].cntnt = obj.cntnt
            this.idx1=0
            this.idx2=0
            this.idx3=0
        },
        getFcltyInitList:function(){           
            selFcltyInitList(this.$store.getters.getFcltyNum, ['4'])
                .then((response) => ( this.getFcltyInitListAfter(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/selFcltyInitList : ' + error))
        },
        getFcltyInitListAfter:function(res){
            let tday = this.$moment()
            let tommday = this.$moment().add(1, 'days')
            res.forEach(el => {
                if(el.itmVal1 == '1') this.lmtNxtDt = ''
                else {

                    let day     = this.$moment(tday.format('YYYYMMDD')+' '+el.itmVal2,'YYYYMMDD hh:mm')                    
                    let yn = this.$moment.duration(tday.diff(day)).asMinutes()                       
                    this.lmtNxtDt = yn > 0 ? tommday.format('YYYY-MM-DD') : tday.format('YYYY-MM-DD')
                }
            });
        }
    },
    
    data: () => ({
        rmPhyThrRcrdCnfm:false,
        bnMmbrPk:-1,
        physcThrpyPlnPk:-1,
        physcDayDirtPk:-1,
        forceRender:0,
        btnDisabled:true,
        inqYMD:'',
        allWrtr:'',
        key:0,
        idx1:0,
        idx2:0,
        idx3:0,
        year:0,
        menu: [false,false,false,false,false,false],        
        btnDialog: [false, false, false, false, false, false, false, false, false, false ],
        physcThrpyTypePk:-1,
        thrClcd:'',
        thrMthdcd:[],
        filledBtn: [            
            { icon: 'mdi-pencil-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '물리치료추가', disabled: false, },
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '전체저장', disabled: false, },
            // { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', disabled: true, },
        ],
        physcTRDheaders: [
            { text: '수급자',width: '', value: '', align: 'center', },
            { text: '성별(나이)',width: '', value: '', align: 'center', },
            { text: '치료시간',width: '', value: '', align: 'center', },
            { text: '구분',width: '', value: '', align: 'center', },
            { text: '치료방법',width: '', value: '', align: 'center', },
            { text: '특이사항',width: '', value: '', align: 'center', },
            { text: '담당자',width: '', value: '', align: 'center', },
            { text: '처리',width: '', value: '', align: 'center', },
            { text: '기록',width: '', value: '', align: 'center', },
        ],
        physcTRDList:[
            {bnMmbrPk:0,age:'',bnfcrNm:'',gndr:'',
                list:[{ofrTmHhEnd:'',ofrTmHhStrt:'',ofrTmMmEnd:'',ofrTmMmStrt:'',ofrTmStrt:'',ofrTmEnd:''
                    ,subList:[{
                        age:'',bnMmbrPk:0,bnfcrNm:'',bnftOfrPlnHisPk:'',bnftOfrRecHisPk:'',bnftOfrRecPk:0,clsfc3Cd:'',
                        cntnt:'',gndr:'',itm1ValAr:'',ofrDate:'',ofrTmHhEnd:'',ofrTmHhStrt:'',ofrTmMmEnd:'',ofrTmMmStrt:'',thrMthdcd:[],wrtr:'',wrtrMmbrPk:0,}]
                }]
            }
        ],
        codeItem37:[],
        codeItem92:[],
        codeItem93:[],
        progress:false,
        lmtNxtDt:'',
    }),
};
</script>
<style>
.physcThrpyRcrdDay .box-height .v-textarea textarea {max-width:98% !important;margin-bottom:6px !important;}
</style>