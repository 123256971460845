<template>
    <v-sheet>   
        <div style="position:relative;">
            <v-chip-group v-model="subTab" class="my-1" mandatory>
                <v-chip class="px-9" active-class="black100 white--text font-weight-bold" style="font-size: 1rem;" @click="isSubTab(0)">욕창변화관찰기록</v-chip>
                <v-chip class="px-9" active-class="black100 white--text font-weight-bold" style="font-size: 1rem;" @click="isSubTab(1)">욕창예방기록</v-chip>
            </v-chip-group>
            <div v-if="subTab==0" style="position:absolute;top:12px;right:0px;">
                <v-btn v-if="items.bnftOfrRecHisPk ==0"
                    class="ml-1" color="#3C9099" style="padding: 13px 12px 12px 10px !important"
                    min-width="30" height="25"
                    @click="getPreDate"
                    small outlined>
                    <v-icon size="14">mdi-text-box-search-outline</v-icon>
                    <span class="fontOneRem ml-1" style="padding-bottom: 2px;">전일자료</span>                
                </v-btn>
            </div>   
        </div>     
        <div v-show="subTab == 0">
            <v-form ref="tabform" lazy-validation>
            <div class="d-flex">
                <div class="pa-1 d-flex" style="position:relative;border: 1px solid #dbdbdb;" @click="dialog.splice(1, 1, true)">
                    <span class="d-inline-block" style="margin:auto;">
                        <v-img src="../../assets/nsgcarercrd_v1.png" width="300" :aspect-ratio="1.2/1"></v-img>
                    </span>
                    <span class="red--text" style="position:absolute;bottom:5px;right:12px;font-size:1rem">
                        클릭시 확대됩니다.
                    </span>
                </div>
                <v-row class="pa-0 ma-0 ml-2" no-gutters>
                    <v-col cols="12">
                        <v-row class="pa-0 ma-0" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                                <span class="black--text font-weight-medium">욕창여부</span>
                            </v-col>
                            <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4">
                                <span style="width:200px;">                                    
                                    <v-radio-group
                                        class="radio-dft-Theme mt-0"
                                        v-model="items.itm1Val" :rules="[rules.required]" hide-details row>
                                        <v-radio v-for="(code, i) in codeItem" :key="i" :ripple="false"  :label="code.valcdnm" :value="code.valcd"></v-radio>
                                    </v-radio-group>
                                </span>
                            </v-col>
                            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                                <span class="black--text font-weight-medium">작성자</span>
                            </v-col>
                            <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4">
                                <span class="d-inline-block" style="width: 100px;">
                                    <v-text-field v-model="items.wrtr" color="blue" ref="info" readonly
                                        :rules="[rules.required]" hide-details outlined dense :key="forceRender"></v-text-field>
                                </span>
                                <v-btn 
                                    class="ml-1 brown lighten-3" 
                                    small 
                                    min-width="30"
                                    height="26"  
                                    style="padding:0px 1px 0px 2px !important"
                                    @click="onModal(0)"
                                    >                                    
                                    <v-tooltip top >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="20" class="white--text" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                        </template>
                                        <span>직원선택</span>
                                    </v-tooltip>

                                </v-btn>
                             </v-col>
                        </v-row>
                        <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                             <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                                <span class="black--text font-weight-medium">부위</span>
                             </v-col>
                             <v-col class="justify-start align-center pa-1 ma-0" cols="10">
                                <span class="d-inline-block" v-for="(item, j) in codeItem22" :key="j" style="width:165px;">
                                    <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="items.itm2ValAr" :label="item.valcdnm" hide-details :value="item.valcd"></v-checkbox>
                                </span>
                             </v-col>                             
                        </v-row>
                        <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                             <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                                <span class="black--text font-weight-medium">크기</span>
                             </v-col>
                             <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="10">
                                <span style="width:150px">
                                    <v-text-field v-model="items.itm4Val" hide-details outlined dense></v-text-field>
                                </span> 
                                <span class="pl-1">cm</span>
                                <span class="pl-2">&#40;가로 * 세로 * 깊이&#41;</span>
                             </v-col>                             
                        </v-row>
                        <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                             <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                                <span class="black--text font-weight-medium">정도</span>
                             </v-col>
                             <v-col class="d-flex justify-start align-center pa-1 ma-0"  cols="4">
                                <span class="d-inline-block" style="width: 180px;">
                                    <v-select 
                                        v-model="items.itm5Val"                               
                                        :items="codeItem23"
                                        item-text="valcdnm"
                                        item-value="valcd"
                                        dense hide-details outlined
                                    /> 
                                </span>
                             </v-col>
                             <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                                <span class="black--text font-weight-medium">색깔</span>
                             </v-col>
                             <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4">
                                <span class="d-inline-block" style="width: 180px;">
                                    <v-select 
                                        v-model="items.itm6Val"                               
                                        :items="codeItem24"
                                        item-text="valcdnm"
                                        item-value="valcd"
                                        dense hide-details outlined
                                    /> 
                                </span>
                             </v-col>                            
                        </v-row>
                        <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                             <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                                <span class="black--text font-weight-medium">배액</span>
                             </v-col>
                             <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4">
                                <span class="d-inline-block" style="width: 180px;">
                                    <v-select 
                                        v-model="items.itm7Val"                               
                                        :items="codeItem25"
                                        item-text="valcdnm"
                                        item-value="valcd"
                                        dense hide-details outlined
                                    /> 
                                </span>
                             </v-col>
                             <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                                <span class="black--text font-weight-medium">냄새</span>
                             </v-col>
                             <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4">
                                <span class="d-inline-block" style="width: 180px;">
                                    <v-select 
                                        v-model="items.itm8Val"                               
                                        :items="codeItem26"
                                        item-text="valcdnm"
                                        item-value="valcd"
                                        dense hide-details outlined
                                    /> 
                                </span>
                             </v-col>                            
                        </v-row>
                        <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                             <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                                <span class="black--text font-weight-medium">처치</span>
                             </v-col>
                             <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4">
                                <span class="d-inline-block" style="width: 180px;">
                                    <v-select 
                                        v-model="items.itm9Val"                               
                                        :items="codeItem27"
                                        item-text="valcdnm"
                                        item-value="valcd"
                                        dense hide-details outlined
                                    /> 
                                </span>
                             </v-col>
                             <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                                <span class="black--text font-weight-medium">체위변경</span>
                             </v-col>
                             <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4">
                                {{items.posChgCnt}}
                             </v-col>                            
                        </v-row>
                        <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                             <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                                <span class="black--text font-weight-medium">방지도구</span>
                             </v-col>
                             <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="10">
                                <div v-for="(item, k) in codeItem28" :key="k">
                                    <span class="d-inline-block mr-1" :style="setStyle(item.valcdnm.length)">
                                        <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="items.itm3ValAr" :label="item.valcdnm"  hide-details :value="item.valcd" @click="onItemChk"></v-checkbox>
                                    </span>
                                </div>
                                <div style="width:150px;">
                                    <v-text-field v-model="items.itm3Val" :disabled="etcDisabled" hide-details outlined dense></v-text-field>
                                </div>
                             </v-col>                             
                        </v-row>
                        <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                             <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                                <div>
                                    <div class="d-flex justify-center black--text font-weight-medium">조치내용 및</div>
                                    <div class="d-flex justify-center black--text font-weight-medium">기타</div>
                                </div>
                             </v-col>
                             <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="4">
                                <v-textarea v-model="items.cntnt" class="rmrksArea" style="font-size:0.9rem !important;"
                                    rows="3" outlined dense hide-details no-resize>
                                </v-textarea>
                             </v-col>
                             <v-col class="greyE01 d-flex justify-center pa-0 ma-0" cols="2">
                                <div class="d-flex align-center nsgCareRcrdLabel" style="width:85px">
                                    <span class="black--text font-weight-medium mr-1" >사진첨부</span>
                                    <v-file-input 
                                        class="pa-0 ma-0"
                                        v-model="images" 
                                        :accept="imgAccept"
                                        dense hide-details truncate-length="5" 
                                        multiple counter
                                        prepend-icon="mdi-camera-outline"
                                        hide-input 
                                        @change="upImgFile()"
                                        ></v-file-input>
                                </div>
                            </v-col>
                             <v-col class="justify-start align-center ma-0 pa-1" cols="4" style="position: relative;">
                                <div style="position: absolute; top:5px; right:16px;z-index: 10;">
                                    <v-btn
                                        class="" color="#3C9099"
                                        @click="dialog.splice(2, 1, true)"
                                        icon
                                        dark small>                                        
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon size="28" v-bind="attrs" v-on="on">mdi-image-multiple-outline</v-icon>
                                            </template>
                                            <span>사진목록확대</span>
                                        </v-tooltip>
                                    </v-btn>
                                </div>
                                <div style="height:60px;overflow-y:auto">
                                    <span class="ma-1 d-inline-block" v-for="(item,n) in imageRes" :key="n">
                                        <imageCard ref="imageCard" :imgSrc="item.src" :imgWidth="'150'" @isEvent="deleteImg(n)" @onDown="fileDownload(n,2)" ></imageCard>
                                    </span>
                                </div>
                             </v-col>                            
                        </v-row>
                    </v-col>
                </v-row>
            </div>
            </v-form>
            <div class="d-flex align-center mt-2">
                <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                <span class="text-h6 font-weight-bold">욕창변화 관찰 기록 내역</span>
            </div>
            <div class="mt-2">
                <v-data-table
                    class="dft-hvr-tbl" height="110" locale="ko"
                    :headers="headers" :items="nsgCareRcrdList" 
                    hide-default-header hide-default-footer fixed-header dense disable-pagination>
                    <template v-slot:header="{ props }" >
                        <thead>
                            <tr> 
                                <th class="black--text clmsFixedHeader tableBR" style="width:10% !important;">{{props.headers[0].text}}</th>
                                <th class="black--text clmsFixedHeader tableBR" style="width:15% !important;">{{props.headers[1].text}}</th>
                                <th class="black--text clmsFixedHeader tableBR" style="width:20% !important;">{{props.headers[2].text}}</th>
                                <th class="black--text clmsFixedHeader tableBR" style="width:25% !important;">{{props.headers[3].text}}</th>
                                <th class="black--text clmsFixedHeader tableBR" style="width:10% !important;">{{props.headers[4].text}}</th>
                                <th class="black--text clmsFixedHeader tableBR" style="width:10% !important;">{{props.headers[5].text}}</th>
                                <th class="black--text clmsFixedHeader" style="width:10% !important;">{{props.headers[6].text}}</th>
                            </tr>
                        </thead>
                    </template>

                    <template v-slot:item='{ item }'>
                        <tr :class="[$parent.$parent.$parent.$parent.inqYMD == item.recDt ? 'onClr' : '']"
                            @click="tableClickEvent(item.recDt)">
                            <td class="clmsBodyCommon tableBR">{{item.recDt?.beforeDateFormatDot()}}</td> 
                            <td class="clmsBodyCommon tableBR">
                                <div style="width:120px" class="text-overflow">{{item.itm2ValNm}}</div>
                            </td>      
                            <td class="clmsBodyCommon tableBR">{{item.itm5ValNm}}</td>   
                            <td class="clmsBodyCommon tableBR">                                
                                <div style="width:220px" class="text-overflow">{{item.cntnt}}</div>
                            </td>      
                            <td class="clmsBodyCommon tableBR">{{item.posChgCnt}}</td>      
                            <td class="clmsBodyCommon tableBR">
                                <div style="width:100px" class="text-overflow">{{item.itm3ValNm}}</div>
                            </td>   
                            <td class="clmsBodyCommon">{{item.wrtr}}</td>            
                        </tr>
                    </template>
                </v-data-table>
            </div>
            <div>            
                <v-pagination
                    v-model="page"
                    :length="pageCount"
                    @input="getPage"
                    total-visible="5">
                </v-pagination>
            </div>
        </div>
        <div v-show="subTab == 1">
            <v-form ref="tabform2" lazy-validation>
            <div class="d-flex">
                <v-data-table height="655" locale="ko"
                    :headers="bnftOfrRecHeaders"
                    :items="bnftOfrRecList" 
                    hide-default-header       
                    hide-default-footer
                    fixed-header
                    dense disable-pagination>

                    <template v-slot:header="{ props }" >
                        <thead>
                            <tr> 
                                <th class="black--text clmsFixedHeader tableBR" style="width:90px !important;">{{props.headers[0].text}}</th>
                                <th class="black--text clmsFixedHeader tableBR" style="width:670px !important;">{{props.headers[1].text}}</th>
                                <th class="black--text clmsFixedHeader" style="width:230px !important;">{{props.headers[2].text}}</th>
                            </tr>
                        </thead>
                    </template>

                    <template v-slot:item='{ item }'>
                        <tr class="disSltRow">
                            <td class="clmsBodyCommon tableBR fixedCol0">
                                <span style="width:25px">{{item.ofrTmHhStrt}}</span>
                                <span style="width:10px">&#58;</span>
                                <span style="width:25px">{{item.ofrTmMmStrt}}</span>
                            </td>      
                            <td class="clmsBodyCommon tableBR fixedCol0">{{item.itm1Nm}}</td>    
                            <td class="clmsBodyCommon fixedCol0">
                                {{item.wrtr}}                                                                
                            </td>            
                        </tr>
                    </template>
                </v-data-table>
            </div>
            </v-form>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
                    <EmpSelector @isClose="isModal(0)" @outEmp="getEmpInfo" :prtWrtDt="$parent.$parent.$parent.$parent.inqYMD"></EmpSelector>
            </v-dialog>  
        </div>        
        <v-dialog v-model="dialog[1]" max-width="80%" content-class="round-md">
            <v-sheet style="position: relative;">
                <v-img src="../../assets/nsgcarercrd_v1.png" :height="$vuetify.breakpoint.height * 0.87" contain></v-img>
                <span style="position: absolute; bottom: 10px; left: 50%; transform: translateX(-50%);">
                    <v-btn class="rounded-circle" color="blue600" height="66" @click="dialog.splice(1, 1, false)">
                        <v-icon color="white" size="38">mdi-close</v-icon>
                    </v-btn>
                </span>
            </v-sheet>
        </v-dialog>
        <v-dialog v-model="dialog[2]" min-width="800" max-width="800" content-class="round-md">
            <v-sheet style="position: relative;" >
                <v-row no-gutters>
                    <v-col class="justify-start align-center ma-0" cols="12">
                        <div class="pa-4" style="height:400px;overflow-y:auto">
                            <span class="ma-1 d-inline-block" v-for="(item,n) in imageRes" :key="n">
                                <imageCard ref="imageCard" :imgSrc="item.src" :imgWidth="'150'" @isEvent="deleteImg(n)" @onDown="fileDownload(n,2)" ></imageCard>
                            </span>
                        </div>
                    </v-col>  
                </v-row>
                <span style="position: absolute; bottom: 10px; left: 50%; transform: translateX(-50%);">
                    <v-btn class="rounded-circle" color="blue600" height="66" @click="dialog.splice(2, 1, false)">
                        <v-icon color="white" size="38">mdi-close</v-icon>
                    </v-btn>
                </span>
            </v-sheet>
        </v-dialog>
    </v-sheet>
</template>
<script>
import EmpSelector from '../commons/EmpSelector.vue';
import imageCard from '../commons/imageCard.vue';
import { remoteURL } from '@/api/baseAxios';
import { tmpFileUpload, selAtchdFileList, delAtchdFile } from '../../api/index.js';
import { getMultiStcd, fileDownload } from '../../api/index.js';
import { selBdsrObsRecInfo, selBdsrObsRecList,  selBnftOfrRecList, delAllBnftOfrRec, insMultiBnftRcrd, } from '../../api/bnftrcrd.js';

export default {
    name: 'NsgCareRcrdTab1',
    
    components: {
        EmpSelector,
        imageCard,
    },

    props : {
        
    },

    created: function(){       
        this.onCode()        
    },

    mounted: function(){
        
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },  

    watch: {       

        getSubEvt: function (bol) {
            if(bol && this.rmNsgTab1AllCnfm){
                this.rmAllBnftOfrRec()
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
                this.rmNsgTab1AllCnfm = false
            }

            if(bol && this.rmNsgTab1ImgCnfm){
                this.rmAtchdFile()
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
                this.rmNsgTab1ImgCnfm = false
            }
        },

    },

    methods: {
        onLoad:function(){     
            this.page = 1       
            this.frmReset()
            this.getBdsrObsRecInfo()
            this.getBdsrObsRecList(0,this.itemsRows)
            this.$refs.tabform.resetValidation()  
            this.getBnftOfrRecList()
        },
        onCode:function(){
            getMultiStcd(['22', '23', '24', '25','26', '27', '28', '29'])
                .then((response) => ( this.codeMapping(response.data) ))
                .catch((error) => console.log(error))
        },
        codeMapping: function (res){            
            this.codeItem22 = res.vCd22
            this.codeItem23 = res.vCd23
            this.codeItem24 = res.vCd24
            this.codeItem25 = res.vCd25
            this.codeItem26 = res.vCd26
            this.codeItem27 = res.vCd27
            this.codeItem28 = res.vCd28
            this.codeItem29 = res.vCd29
            this.$refs.tabform.reset()  
        },
        frmReset:function(){            
            this.$refs.tabform.reset()  
            this.images = null                
            this.imageRes.splice(0) 

            this.items.bnftOfrRecHisPk = 0
            this.items.bnftOfrRecPk = 0
            this.items.recDt  =  ''
            this.items.mdfDt = ''
            this.items.itm1Val = ''
            this.items.itm2ValAr.splice(0)
            this.items.itm3ValAr.splice(0)
            this.items.itm3Val = ''
            this.items.itm4Val = ''
            this.items.itm5Val = ''
            this.items.itm6Val = ''
            this.items.itm7Val = ''
            this.items.itm8Val = ''
            this.items.itm9Val = ''
            this.items.cntnt = ''
            this.items.wrtr = ''
            this.items.wrtrMmbrPk = 0
            this.items.posChgCnt = 0
            this.items.files.splice(0)
        },
        getPreDate:function(){
            this.preData = true
            this.getBdsrObsRecInfo()
        },
        getBdsrObsRecInfo:function(){
            let gFcltyNum = this.$store.getters.getFcltyNum
            let gBnMmbrPk = this.$parent.$parent.$parent.$parent.bnMmbrPk

            let obj = {
                fcltyNum: gFcltyNum,
                bnMmbrPk : gBnMmbrPk,
                inqYMD : this.$parent.$parent.$parent.$parent.inqYMD
            }

            if(this.preData){
                let date = new Date(this.$parent.$parent.$parent.$parent.inqYMD?.beforeDateFormatHyp())
                let dateArr = new Date(date.setDate(date.getDate()-1)).toISOString().split('T',2)
                date = dateArr[0]?.afterDateFormatHyp()    
                obj.inqYMD = date
            }

            selBdsrObsRecInfo(obj)
                .then((response) => ( this.getBdsrObsRecInfoAfter(response.data) ))
                .catch((error) => console.log('upload error /bnfrcrd/selBdsrObsRecInfo : ' + error))
        },        
        getBdsrObsRecInfoAfter:function(res){     
            
            let obj = {}    

            if(res.statusCode == 200){  
                if(res.result !='' && res.result != null){  

                    if(!this.preData){
                        this.items.bnftOfrRecHisPk  = res.result.bnftOfrRecHisPk
                        this.items.bnftOfrRecPk     = res.result.bnftOfrRecPk
                    }

                    this.items.itm1Val          = res.result.itm1Val
                    this.items.itm2ValAr        = []
                    this.items.itm3ValAr        = []
                    this.items.itm3Val          = res.result.itm3Val
                    this.items.itm4Val          = res.result.itm4Val
                    this.items.itm5Val          = res.result.itm5Val
                    this.items.itm6Val          = res.result.itm6Val
                    this.items.itm7Val          = res.result.itm7Val
                    this.items.itm8Val          = res.result.itm8Val
                    this.items.itm9Val          = res.result.itm9Val
                    this.items.cntnt            = res.result.cntnt
                    this.items.wrtr             = res.result.wrtr
                    this.items.wrtrMmbrPk       = res.result.wrtrMmbrPk
                    this.items.posChgCnt        = res.result.posChgCnt
                    this.items.mdfDt            = res.result.mdfDt

                    if(res.result.itm2ValAr != ''){
                        res.result.itm2ValAr.forEach(val => {
                            this.items.itm2ValAr.push(val)
                        });
                    }
                    if(res.result.itm3ValAr ){
                        res.result.itm3ValAr.forEach(val => {
                            this.items.itm3ValAr.push(val)
                        });
                    }

                    this.onItemChk()

                    if(!this.preData){
                        this.getAtchdFileList(this.items.bnftOfrRecHisPk)
                    }  

                    this.$parent.$parent.$parent.$parent.outlinedBtn[0].disabled = false
                }
                
            }else{
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }

               
            
            this.preData = false
        },
        getAtchdFileList:function(value){
            let fcltyNum  = this.$store.getters.getFcltyNum            
            let clcd = '23'

            if(value > 0){
                selAtchdFileList(fcltyNum, clcd,value)
                        .then((response) => ( this.getAtchdFileListAfter(response.data) ))
                        .catch((error) => console.log('upload error /opr/selAtchdFileList : ' + error))
            }
        },
        getAtchdFileListAfter:function(res){                        
            res.forEach(x=>{
                if(x.fileKndCd == "2"){
                    x.src = remoteURL + 'opr/imgView?fileNm='+x.fileNm+'&filePth='+x.filePth+'&fileKndCd='+x.fileKndCd
                    this.imageRes.push(x)
                }
            })
        },
        getBdsrObsRecList : function(strt, lmt){     
            let obj = {
                fcltyNum: this.$store.getters.getFcltyNum,
                bnMmbrPk: this.$parent.$parent.$parent.$parent.bnMmbrPk,
                rnStrt:strt,
                rnLmt:lmt, 
            }     
            selBdsrObsRecList(obj)                
                .then((response) => ( this.getBdsrObsRecListAfter(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/selBdsrObsRecList : ' + error))
        },
        getBdsrObsRecListAfter:function(res){

            let obj = {}    

            if(res.statusCode == 200){
                  
                this.pageCount = Math.ceil(Number(res.result.rnTot)/this.itemsRows)
                this.nsgCareRcrdList = res.result.list
                
                this.nsgCareRcrdList.forEach(e => {
                    
                    if(e.itm5Val?.isEmptyVal()){
                        let target = this.codeItem23.find(x=>x.valcd == e.itm5Val)
                        if(target != undefined && target != null) e.itm5ValNm = target.valcdnm
                    }
                    
                    let itm2ValNm = ""
                    let n=0
                    if(e.itm2ValAr != null && e.itm2ValAr != undefined){
                        e.itm2ValAr.forEach(element => {                                        
                            let target = this.codeItem22.find(x=>x.valcd == element) 
                            if(target != undefined && target != null) { 
                                if(n == 0)
                                    itm2ValNm = target.valcdnm
                                else
                                    itm2ValNm += ","+target.valcdnm
                            }
                            n++
                        });
                    }
                    e.itm2ValNm = itm2ValNm
                    let itm3ValNm = ""
                    let k = 0
                    if(e.itm3ValAr != null && e.itm3ValAr != undefined){
                        e.itm3ValAr.forEach(element => {                                        
                            let target = this.codeItem28.find(x=>x.valcd == element) 
                            if(target != undefined && target != null) {
                                if(k == 0)
                                    itm3ValNm = target.valcdnm
                                else
                                    itm3ValNm += ","+target.valcdnm
                            }
                            k++
                        });
                    }
                    e.itm3ValNm = itm3ValNm
                });

            }else{
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                this.$store.commit('setWrnnInf', obj)
            }            
        },
        getPage:function(){
            let strt = ((this.page-1)*this.itemsRows)
            this.getBdsrObsRecList(strt,this.itemsRows)
        },
        getBnftOfrRecList:function(){
            let fcltyNum = this.$store.getters.getFcltyNum
            let obj = {
                fcltyNum: fcltyNum, 
                clsfc1Cd: '301',
                clsfc2Cd: '4',
                clsfc3Cd:'1',
                bnMmbrPk: this.$parent.$parent.$parent.$parent.bnMmbrPk,
                inqYMD: this.$parent.$parent.$parent.$parent.inqYMD,
            }
            
            selBnftOfrRecList(obj)
            .then((response) => ( this.getBnftOfrRecListAfter(response.data)))
            .catch((error) => console.log(error))
        },
        getBnftOfrRecListAfter:function(res){
            this.bnftOfrRecList.splice(0)
            let obj = {}    

            if(res.statusCode == 200){
                if(res.result != null){

                    res.result.forEach(e => {
                        let target = this.codeItem29.find(x=>x.valcd == e.itm1Val)
                        e.itm1Nm = target.valcdnm

                        this.bnftOfrRecList.push(e)
                    });
                }

            }else{
                    obj.actv = true                 // 경고 노출여부
                    obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                    obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                    obj.title = res.message         // 경고 타이틀
                    this.$store.commit('setWrnnInf', obj)
            }   
        },
        isSubTab: function (value) {
            this.subTab = value
            this.$emit('eventBtn');
        },
        
        getEmpInfo: function (obj) {
            this.items.wrtr = obj[0].name
            this.items.wrtrMmbrPk = obj[0].mmbrPk            
        },
        setStyle:function(len){
            let style = "";
            let wd = (len*16)+20;
            if(len > 7){
                style="width:128px"        
            }else{
                style="width:"+wd+"px"        
            }
            return style;
        },
        onItemChk:function(){            
            let etcBl = this.items.itm3ValAr.some((element) => element =='4')
            this.etcDisabled = !etcBl            
        },
        onModal: function (value) {
            this.dialog.splice(value, 1, true)
        },
        isModal: function (value) {
            this.dialog.splice(value, 1, false)
        },
        onSave:function(dupYn = '2'){

            if(this.$parent.$parent.$parent.$parent.bnMmbrPk > 0){

                if(this.subTab == 0){
                    if(this.$refs.tabform.validate()){
                        let obj = {       
                            fcltyNum    : this.$store.getters.getFcltyNum,
                            bnMmbrPk    : this.$parent.$parent.$parent.$parent.bnMmbrPk,
                            userNm      : this.$store.getters.getUserNm,
                            ignrYn      : dupYn,                
                            hisList     : [],
                        }

                        let recItems = {              
                            bnftOfrRecHisPk : this.items.bnftOfrRecHisPk,                                    
                            recDt           : this.$parent.$parent.$parent.$parent.inqYMD,
                            wrtr            : this.items.wrtr,
                            wrtrMmbrPk      : this.items.wrtrMmbrPk,
                            cntnt           : this.items.cntnt,
                            clsfc1Cd        : '303',
                            clsfc2Cd        : '1',
                            clsfc3Cd        : '1',
                            recList         : [],
                            files           : [],
                            oldFiles        : [],
                            mdfDt           : this.items.mdfDt,
                        }

                        let item = {
                            itm1Cd      : '1',
                            itm1Val     : this.items.itm1Val,
                            itm2Cd      : '2',
                            itm2ValAr   : this.items.itm2ValAr,
                            itm3Cd      : '3',
                            itm3Val     : this.items.itm3Val,
                            itm3ValAr   : this.items.itm3ValAr,
                            itm4Cd      : '4',
                            itm4Val     : this.items.itm4Val,
                            itm5Cd      : '5',
                            itm5Val     : this.items.itm5Val,
                            itm6Cd      : '6',
                            itm6Val     : this.items.itm6Val,
                            itm7Cd      : '7',
                            itm7Val     : this.items.itm7Val,
                            itm8Cd      : '8',
                            itm8Val     : this.items.itm8Val,
                            itm9Cd      : '9',
                            itm9Val     : this.items.itm9Val,
                            wrtr        : this.items.wrtr,
                            wrtrMmbrPk  : this.items.wrtrMmbrPk,
                            bnftOfrRec301Pk : this.items.bnftOfrRecPk,
                        }

                        recItems.recList.push(item)

                        this.imageRes.forEach(x=>{
                            if(x.atchdFilePk == undefined){
                                recItems.files.push(x)
                            }else{
                                recItems.oldFiles.push(x)
                            }
                        })

                        
                        
                        obj.hisList.push(recItems)

                        this.$parent.$parent.$parent.$parent.progress = true
                        insMultiBnftRcrd(obj)
                            .then((response) => ( this.onSaveAfter(response.data) ))
                            .catch((error) => console.log('upload error /bnfrcrd/insMultiBnftRcrd : ' + error))
                        
                    }else{                       
                        let obj = {} 
                        obj.actv = true                 // 경고 노출여부
                        obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                        obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                        obj.title = '필수항목을 입력해주세요.'         // 경고 타이틀

                        this.$store.commit('setWrnnInf', obj)
                    }
                }

             }else{
                let obj = {} 
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = '수급자를 선택해주세요.'         // 경고 타이틀

                this.$store.commit('setWrnnInf', obj)
            }

        },
        onSaveAfter:function(res){                       
            this.$emit('nsgCareRcrdTrigger', res);            
            if(res.statusCode == 200){      
                this.reLoad()
            }
        },
        upImgFile: function () {     
            let formData = new FormData();
            
            if(this.images != null){
                this.images.forEach(element => {
                    formData.append("files", element)
                })

                if(this.images.length > 0){
                    tmpFileUpload(formData)
                        .then((response) => ( this.upImgFileAfter(response.data) ))
                        .catch((error) => console.log('upload error /opr/tmpFileUpload : ' + error))
                }
            }

        },
        upImgFileAfter:function(res){
            
            res.files.forEach(x=>{
                x.src = remoteURL + 'opr/imgPrview?fileNm='+x.fileNm+'&filePth='+x.filePth+'&fileKndCd='+x.fileKndCd
                this.imageRes.push(x)
            })       

            this.images = null
        },
        delAll(){
            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            obj.title = '욕창변화관찰기록'
            obj.cntnt = [this.$moment(this.$parent.$parent.$parent.$parent.inqYMD,'YYYYMMDD').format('YYYY.MM.DD')+' 욕창변화관찰기록을(를) 삭제하시겠습니까?']
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 삭제하겠습니다.'

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
            });   

            this.rmNsgTab1AllCnfm = true
        },
        deleteImg(idx){
            if(this.imageRes[idx].atchdFilePk != undefined){
                this.idx = idx                

                let obj = {}
                obj.icon = 'mdi-alert'
                obj.iconClr = 'red'
                obj.title = '욕창변화관찰기록'
                obj.cntnt = [this.imageRes[idx].orgFileNm+'의 욕창변화관찰기록을(를) 삭제하시겠습니까?']
                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 삭제하겠습니다.'

                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });   

                this.rmNsgTab1ImgCnfm = true
            }else{
                this.imageRes.splice(idx,1)
            }
        },
        rmAtchdFile:function(){
            let pk = this.imageRes[this.idx].atchdFilePk
    
            if(pk > 0){
                let fcltyNum  = this.$store.getters.getFcltyNum 
                this.$parent.$parent.$parent.$parent.progress = true 
                delAtchdFile(fcltyNum, pk)
                    .then((response) => ( this.delAtchdFileAfter(response.data) ))
                    .catch((error) => console.log('upload error /opr/delAtchdFile : ' + error))
            }
        },
        rmAllBnftOfrRec:function(){
            let fcltyNum = this.$store.getters.getFcltyNum                    
            let obj = {
                    fcltyNum:fcltyNum,
                    userNm:this.$store.getters.getUserNm,
                    bnftOfrRecHisPk:this.items.bnftOfrRecHisPk,
                    recDt       : this.$parent.$parent.$parent.$parent.inqYMD,
                    bnMmbrPk    : this.$parent.$parent.$parent.$parent.bnMmbrPk,
                    clsfc1Cd    : '303',
                    clsfc2Cd    : '1',
                    clsfc3CdS   : ['1'],
            }      
            
            this.$parent.$parent.$parent.$parent.progress = true

            delAllBnftOfrRec(obj)            
                .then((response) => ( this.onDeleteAfter(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/delAllBnftOfrRec : ' + error))
        },        
        delAtchdFileAfter:function(res){            
            this.$emit('nsgCareRcrdTrigger', res);
            if(res.statusCode == 200) this.imageRes.splice(this.idx,1)
            this.idx = 0
        },
        onDeleteAfter:function(res){            
            this.$emit('nsgCareRcrdTrigger', res)

            if(res.statusCode == 200) this.onLoad()
        },
        tableClickEvent(value){
            if(value != ''){
                this.$emit("setDate",value)
            }
        },
        fileDownload:function(idx, type){
            if(type == 1){
                if(this.fileRes[idx] != undefined){
                    fileDownload(this.fileRes[idx])
                        .then((response) => ( this.fileDownLoaderAfter(response) ))
                        .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
                }else{
                    this.snackControll([true, 3000, '선택된 파일이 없습니다.', 'warning'])
                }
            }else if(type==2){
                if(this.imageRes[idx] != undefined){
                    fileDownload(this.imageRes[idx])
                        .then((response) => ( this.fileDownLoaderAfter(response) ))
                        .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
                }else{
                    this.snackControll([true, 3000, '선택된 파일이 없습니다.', 'warning'])
                }
            }else{
                    this.snackControll([true, 3000, '선택된 파일이 없습니다.', 'warning'])
            }
        },
        fileDownLoaderAfter: function (res) {
            let contentDisposition = decodeURI(res.headers['content-disposition'])
       
            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'));
                if (fileNameMatch)
                    [ , fileName ] = fileNameMatch.split('=');
            }
            link.href = url;
 
            fileName = fileName.replaceAll('"', '')
            link.setAttribute('download', `${fileName}`);
            link.style.cssText = 'display:none';
            document.body.appendChild(link);
            link.click();
            link.remove();
        },        
    },
    
    data: () => ({  
        rmNsgTab1AllCnfm :false,
        rmNsgTab1ImgCnfm :false,
        cardOver:false,      
        idx:0,
        type:'',
        delModelTitle:'',
        forceRender:0,
        subTab:0,
        pageCount:0,
        itemsRows: 4,
        page:1,
        images:null, 
        imageRes:[], 
        imgAccept:'image/*',  
        items:{bnftOfrRecHisPk:0,bnftOfrRecPk:0,recDt : '',mdfDt:'',
                itm1Val:'',itm2ValAr:[],itm3ValAr:[],itm3Val:'',itm4Val:'',itm5Val:''
                ,itm6Val:'',itm7Val:'',itm8Val:'',itm9Val:'',cntnt:'',wrtr:'',wrtrMmbrPk:0,posChgCnt:0, files:[]},        
        codeItem:[{valcd:'1',valcdnm:'유'},{valcd:'2',valcdnm:'무'}],
        codeItem22:[{valcd:'',valcdnm:''}],
        codeItem23:[{valcd:'',valcdnm:''}],
        codeItem24:[{valcd:'',valcdnm:''}],
        codeItem25:[{valcd:'',valcdnm:''}],
        codeItem26:[{valcd:'',valcdnm:''}],
        codeItem27:[{valcd:'',valcdnm:''}],
        codeItem28:[{valcd:'',valcdnm:''}],
        codeItem29:[{valcd:'',valcdnm:''}],
        etcDisabled:true,
        dialog:[false,false,false],
        nsgCareRcrdList:[],
        headers: [
            { text: '일자',width: '', value: '', align: 'center', },
            { text: '부위',width: '', value: '', align: 'center', },
            { text: '정도',width: '', value: '', align: 'center', },
            { text: '조치내용',width: '', value: '', align: 'center', },
            { text: '체위변경',width: '', value: '', align: 'center', },
            { text: '방지도구',width: '', value: '', align: 'center', },
            { text: '작성자',width: '', value: '', align: 'center', },
        ],
        bnftOfrRecList:[],
        bnftOfrRecHeaders:[
            { text: '시간대',width: '', value: '', align: 'center', },
            { text: '변경체위자세',width: '', value: '', align: 'center', },
            { text: '담당자',width: '', value: '', align: 'center', },
        ],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
        preData:false,        
        progress:false,
    }),
};
</script>
<style>
.nsgCareRcrdLabel .v-input__prepend-outer {margin-right: 0px !important}
.text-overflow {text-overflow:ellipsis;white-space:nowrap;overflow:hidden;}
</style>