<template>
    <v-sheet>
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>  
        <v-form ref="tabform" lazy-validation>
            <div class="pa-0 ma-0">
                <div class="d-flex pa-0 ma-0" no-gutters style="width:1320px; !important;border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:22%;">                                
                        <span class="black--text font-weight-medium">통증부위</span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:13%;border-left: 1px solid #dbdbdb !important;">
                        <span><v-simple-checkbox class="dftSmpChcBox-Theme" color="blue200" v-model="items.itmVal1" disabled></v-simple-checkbox></span>
                        <span class="black--text font-weight-medium">목</span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:13%;border-left: 1px solid #dbdbdb !important;">                                
                        <span><v-simple-checkbox class="dftSmpChcBox-Theme" color="blue200" v-model="items.itmVal2" disabled></v-simple-checkbox></span>
                        <span class="black--text font-weight-medium">어깨</span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:13%;border-left: 1px solid #dbdbdb !important;">        
                        <span><v-simple-checkbox class="dftSmpChcBox-Theme" color="blue200" v-model="items.itmVal3" disabled></v-simple-checkbox></span>                        
                        <span class="black--text font-weight-medium">팔/팔꿈치</span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:13%;border-left: 1px solid #dbdbdb !important;">
                        <span><v-simple-checkbox class="dftSmpChcBox-Theme" color="blue200" v-model="items.itmVal4" disabled></v-simple-checkbox></span>                                
                        <span class="black--text font-weight-medium">손/손목/손가락</span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:13%;border-left: 1px solid #dbdbdb !important;">
                        <span><v-simple-checkbox class="dftSmpChcBox-Theme" color="blue200" v-model="items.itmVal5" disabled></v-simple-checkbox></span>                                
                        <span class="black--text font-weight-medium">허리</span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:13%;border-left: 1px solid #dbdbdb !important;">      
                        <span><v-simple-checkbox class="dftSmpChcBox-Theme" color="blue200" v-model="items.itmVal6" disabled></v-simple-checkbox></span>                          
                        <span class="black--text font-weight-medium">다리/발</span>
                    </div>
                </div>
                <div style="height: 632px; overflow-y: auto">
                    <div v-for="(item, i) in msdDtlList" :key="i" class="d-flex pa-0 ma-0" no-gutters style="width:1320px; !important;border-bottom: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-start align-center pl-2 py-2 ma-0" style="width:22%;">                                
                            {{item.clsfc3Cdnm}}
                        </div>
                        <div v-for="(list, j) in item.list" :key="j" class="pa-1 ma-0" style="width:13%;border-left: 1px solid #dbdbdb !important;">
                            <div v-if="item.clsfc3Cd == '1'">
                                <div v-if="list.itmCd != '1' && list.itmCd != '5'" style="width:100%">                           
                                    <v-radio-group
                                        v-model="list.itmVal"
                                        class="radio-dft-Theme mt-0"
                                        hide-details row @change="onChnVal">
                                        <v-radio v-for="(code, n) in vCd54" :key="n" class="py-1 ma-0" :ripple="false" style="width:150px;" :label="code.valcdnm" :value="code.valcd"></v-radio>
                                    </v-radio-group>
                                </div>    
                            </div>
                            <div v-if="item.clsfc3Cd == '2'">
                                <div style="width:100%">                           
                                    <v-radio-group
                                        v-model="list.itmVal"
                                        class="radio-dft-Theme mt-0"
                                        hide-details row  @change="onChnVal">
                                        <v-radio v-for="(code, n) in vCd55" :key="n" class="py-1 ma-0" :ripple="false" style="width:150px;" :label="code.valcdnm" :value="code.valcd"></v-radio>
                                    </v-radio-group>
                                </div>    
                            </div>
                            <div v-if="item.clsfc3Cd == '3'">
                                <div style="width:100%">                           
                                    <v-radio-group
                                        v-model="list.itmVal"
                                        class="radio-dft-Theme mt-0"
                                        hide-details row  @change="onChnVal">
                                        <v-radio v-for="(code, n) in vCd56" :key="n" class="py-1 ma-0" :ripple="false" style="width:150px;" :label="code.valcdnm" :value="code.valcd"></v-radio>
                                    </v-radio-group>
                                </div>    
                            </div>
                            <div v-if="item.clsfc3Cd == '4'">
                                <div style="width:100%">                           
                                    <v-radio-group
                                        v-model="list.itmVal"
                                        class="radio-dft-Theme mt-0"
                                        hide-details row  @change="onChnVal">
                                        <v-radio v-for="(code, n) in vCd57" :key="n" class="py-1 ma-0" :ripple="false" style="width:150px;" :label="code.valcdnm" :value="code.valcd"></v-radio>
                                    </v-radio-group>
                                </div>    
                            </div>
                            <div v-if="item.clsfc3Cd == '5'">
                                <div style="width:100%">                           
                                    <v-radio-group
                                        v-model="list.itmVal"
                                        class="radio-dft-Theme mt-0"
                                        hide-details row  @change="onChnVal">
                                        <v-radio v-for="(code, n) in vCd60" :key="n" class="py-1 ma-0" :ripple="false" style="width:150px;"   :label="code.valcdnm" :value="code.valcd"></v-radio>
                                    </v-radio-group>
                                </div>    
                            </div>
                            <div v-if="item.clsfc3Cd == '6'">
                                <div v-for="(code, n) in vCd58" :key="n" style="width:100%">                                                           
                                    <div v-if="code.valcd != '6'">
                                        <v-checkbox class="dftChcBox-Theme pt-0" style="width: 180px; margin-top: 3px !important;" v-model="list.itmValAr"  hide-details :ripple="false" :label="code.valcdnm" :value="code.valcd" @click="onChnVal"></v-checkbox>                                    
                                    </div>
                                    <div class="d-flex" v-else>
                                        <div style="width: 55px;">
                                            <v-checkbox v-model="list.itmValAr" class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" hide-details :ripple="false" :label="code.valcdnm" :value="code.valcd" @click="onChnVal"></v-checkbox>
                                        </div>
                                        <div style="width: 90px;">
                                            <v-text-field color="blue" height="28" v-model="list.itmVal" hide-details outlined dense></v-text-field>
                                        </div>
                                    </div>
                                </div>    
                            </div>
                        </div>                    
                    </div>
                </div>
            </div>
        </v-form>
    </v-sheet>
</template>
<script>
import { groupBy } from '../../api/common.js';
import { getMultiStcd } from '../../api/index.js';
import { insMscldChckpDtl } from '../../api/emp.js';

export default {
    name: 'MsclsSystmChkWrkTab2',
    
    components: {
    },

    props : {
        
    },

    created: function(){   
         
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getCode(['54', '55', '56', '57', '58', '60'])   
        })
    },

    computed: {
       
    },  

    watch: {
        
    },

    methods: {
        onChnVal:function(){
            let val1 = 0
            let val2 = 0
            let val3 = 0
            let val4 = 0
            let val5 = 0
            let val6 = 0

            this.msdDtlList.forEach(e => {               
                e.list.forEach(item => {                    
                    switch (item.itmCd) {
                        case '1':
                            if(e.clsfc3Cd == '6'){
                                if(item.itmValAr != '' && item.itmValAr != undefined && item.itmValAr.length > 0) val1++
                            }else{
                                if(item.itmVal != '' && item.itmVal != undefined) val1++
                            }
                        break;
                        case '2':
                            if(e.clsfc3Cd == '6'){
                                if(item.itmValAr != '' && item.itmValAr != undefined && item.itmValAr.length > 0) val2++
                            }else{
                                if(item.itmVal != '' && item.itmVal != undefined) val2++
                            }    
                        break;
                        case '3':
                            if(e.clsfc3Cd == '6'){
                                if(item.itmValAr != '' && item.itmValAr != undefined && item.itmValAr.length > 0) val3++
                            }else{
                                if(item.itmVal != '' && item.itmVal != undefined) val3++                                    
                            }  
                        break;
                        case '4':
                            if(e.clsfc3Cd == '6'){
                                if(item.itmValAr != '' && item.itmValAr != undefined && item.itmValAr.length > 0) val4++
                            }else{
                                if(item.itmVal != '' && item.itmVal != undefined) val4++
                            }  
                        break;
                        case '5':
                            if(e.clsfc3Cd == '6'){
                                if(item.itmValAr != '' && item.itmValAr != undefined && item.itmValAr.length > 0) val5++
                            }else{
                                if(item.itmVal != '' && item.itmVal != undefined) val5++
                            }  
                        break;
                        case '6':
                            if(e.clsfc3Cd == '6'){
                                if(item.itmValAr != '' && item.itmValAr != undefined && item.itmValAr.length > 0) val6++
                            }else{
                                if(item.itmVal != '' && item.itmVal != undefined) val6++
                            }  
                        break;
                        default:
                            break;
                    }
                });
            });

            this.items.itmVal1 = (val1 > 0)? true :false
            this.items.itmVal2 = (val2 > 0)? true :false
            this.items.itmVal3 = (val3 > 0)? true :false
            this.items.itmVal4 = (val4 > 0)? true :false
            this.items.itmVal5 = (val5 > 0)? true :false
            this.items.itmVal6 = (val6 > 0)? true :false
        },
        onSave:function(){
            

            let gFcltyNum = this.$store.getters.getFcltyNum           
            let gUserNm = this.$store.getters.getUserNm

            let obj = {
                fcltyNum:gFcltyNum,
                userNm:gUserNm,
                mscldChckpPk:this.items.mscldChckpPk,
                empPk:this.items.empPk,
                mrgYn:this.items.mrgYn,
                crntCarer:this.items.crntCarer,
                wrtDt:this.items.wrtDt?.afterDateFormatHyp(),
                crntWrkCntnt:this.items.crntWrkCntnt,
                wrkPrd:this.items.wrkPrd,
                wrkHr1d:this.items.wrkHr1d,
                brkHr1d:this.items.brkHr1d,
                bfWrkCntnt:this.items.bfWrkCntnt,
                bfWrkPrd:this.items.bfWrkPrd,
                worker:this.items.worker,
                wrkDt:this.items.wrkDt?.afterDateFormatHyp(),
                wrkCntnt:this.items.wrkCntnt,
                wrtr:this.items.wrtr,
                list : []
            }

            

            this.msdDtlList.forEach(e => {                

                e.list.forEach(item => {
                    item.fcltyNum = gFcltyNum
                    item.userNm = gUserNm
                    item.empPk = this.items.empPk
                    item.examDt = this.items.wrtDt?.afterDateFormatHyp()
                    obj.list.push(item)
                });

            });

            // let bl = this.getValidate()
            let bl = true
            
            if(bl){
                insMscldChckpDtl(obj)
                    .then((response) => ( this.onSaveAfter(response.data) ))
                    .catch((error) => console.log('upload error /emp/insMscldChckpDtl : ' + error))
            }
        },
        onSaveAfter:function(res){
            this.$emit('empMsysTrigger', res)
        },
        setItems:function(item){
            this.items  = Object.assign({})
            if(item != null && item != '') this.items = item
        },
        setMsdDtlList:function(list){            
            this.msdDtlList.splice(0)

            let data = []

            list.forEach(item => {
                if(item.clsfc2Cd == '2'){
                    if(item.itmValAr == null) item.itmValAr = []
                    data.push(item)
                }else if(item.clsfc2Cd == '1'){
                    if(item.clsfc3Cd == '6'){
                        this.clsfc6Val = item.itmVal
                    }
                }
            });
            
            let groups = groupBy(data, 'clsfc3Cd')  

            Object.keys(groups).forEach((key)  => {
                let obj = {
                    clsfc3Cd : key,
                    clsfc3Cdnm : groups[key][0].clsfc3Cdnm,
                    list : groups[key]
                }
                this.msdDtlList.push(obj)
            });      
            this.onChnVal()            
            this.$refs.tabform.resetValidation()
            
        },

        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        stCdMapping: function (res) {
            this.vCd54 = res.vCd54
            this.vCd55 = res.vCd55
            this.vCd56 = res.vCd56
            this.vCd57 = res.vCd57
            this.vCd58 = res.vCd58
            this.vCd60 = res.vCd60
        },
        getValidate(){
            let res = true
            let clsfc1 = true
            let clsfc2 = true
            let clsfc3 = true
            let clsfc4 = true
            let clsfc5 = true
            let clsfc6 = true

            if(this.clsfc6Val == '1'){
                this.msdDtlList.forEach(e => {       
                    switch (e.clsfc3Cd) {
                        case '1':
                            clsfc1 = this.getItemRes(e)
                            break;
                        case '2':
                            clsfc2 = this.getItemRes(e)
                            break;
                        case '3':
                            clsfc3 = this.getItemRes(e)
                            break;
                        case '4':
                            clsfc4 = this.getItemRes(e)
                            break;
                        case '5':
                            clsfc5 = this.getItemRes(e)
                            break;
                        case '6':
                            clsfc6 = this.getItemArrRes(e)
                            break;
                        default:
                            break;
                    }
                })

                if(!clsfc6){
                    res = false
                    this.snackControll([true, 2500, "6. 지난 1년 동안 이러한 통증으로 발생된 일을 선택해주세요.", 'warning'])
                }

                if(!clsfc5){
                    res = false
                    this.snackControll([true, 2500, "5. 지난 1주일 동안에도 이러한 증상을 선택해주세요.", 'warning'])
                }

                if(!clsfc4){
                    res = false
                    this.snackControll([true, 2500, "4. 지난 1년 동안 이러한 증상을 선택해주세요.", 'warning'])
                }

                if(!clsfc3){
                    res = false
                    this.snackControll([true, 2500, "3. 그때의 아픈 정도를 선택해주세요.", 'warning'])
                }

                if(!clsfc2){
                    res = false
                    this.snackControll([true, 2500, "2. 한번 아프기 시작하면 통증 기간을 선택해주세요.", 'warning'])
                }

                if(!clsfc1){
                    res = false
                    this.snackControll([true, 2500, "1.통증의 구체적 부위를 선택해주세요.", 'warning'])
                }
            }
            
            return res
        },
        getItemRes:function(data){
            let res = false
            let cnt = 0
            data.list.forEach(item => {
                if(item.itmVal != '' && item.itmVal != undefined) cnt++
            });
            
            if(cnt > 0) res = true
            return res
        },
        getItemArrRes:function(data){
            let res = false
            let cnt = 0
            data.list.forEach(item => {
                if(item.itmValAr != '' && item.itmValAr != undefined && item.itmValAr.length > 0) cnt++
            });
            
            if(cnt > 0) res = true
            return res
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    
    data: () => ({ 
        vCd54:[],
        vCd55:[],
        vCd56:[],
        vCd57:[],
        vCd58:[],
        vCd60:[],
        clsfc6Val:'',
        items:{},
        msdDtlList:[
            {clsfc3Cd:'',clsfc3Cdnm:'',list:[]}
        ],
        snackOptions: [false, 3000, '', 'info'],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
    }),
};
</script>