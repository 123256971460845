<template>
    <v-sheet class="pa-7" >
        <v-row class="align-center pa-0 mb-7" no-gutters>
            <span class="text-h4 font-weight-bold topTitleColor--text">{{ $store.getters.getBnfcrState.name }}&nbsp;</span>
            <span class="text-h4 font-weight-bold">보호자 비밀번호 설정</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="onClose" x-large>mdi-close</v-icon>
        </v-row>        
        <v-divider :class="['mt-2', prtFmlyList.length > 3 ? 'mr-2__5' : 'mr-1']" color="black"></v-divider>
        <v-row :class="['greyE01', prtFmlyList.length > 3 ? 'mr-2__5' : 'mr-1']" no-gutters>
            <v-col class="txtAlgnMid tableBR py-2" cols="2">
                <span class="font-weight-medium">보호자명</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-2" cols="2">
                <span class="font-weight-medium">관계</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-2" cols="3">
                <span class="font-weight-medium">연락처</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-2" cols="4">
                <span class="font-weight-medium">비밀번호</span>
            </v-col>
            <v-col class="txtAlgnMid py-2" cols="1">
                <span class="font-weight-medium">처리</span>
            </v-col>
        </v-row>
        <v-divider :class="[prtFmlyList.length > 3 ? 'mr-2__5' : 'mr-1']"></v-divider>
        <v-row :class="[prtFmlyList.length > 3 ? 'mr-2__5' : 'mr-1']" no-gutters>
            <v-col class="txtAlgnMid tableBR pt-2" cols="2">
                <span class="">{{ prtFmlyInf.name }}</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR pt-2" cols="2">
                <span class="">{{ prtFmlyInf.rltns }}</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR pt-2" cols="3">
                <div v-if="typeof prtFmlyInf.celphn_1 == 'string'" class="ma-0 pa-0">
                    <span>{{ prtFmlyInf.celphn_1 }}</span>
                    <span v-if="prtFmlyInf.celphn_2 != ''" class="mx-1">-</span>
                    <span>{{ prtFmlyInf.celphn_2 }}</span>
                    <span v-if="prtFmlyInf.celphn_3 != ''" class="mx-1">-</span>
                    <span>{{ prtFmlyInf.celphn_3 }}</span>
                </div>
            </v-col>
            <v-col class="pa-1 tableBR" cols="4">
                <v-text-field
                    v-model="galPasswd"
                    :type="'password'" 
                    hide-details outlined dense>
                </v-text-field>
            </v-col>
            <v-col class="d-flex justify-center align-center" cols="1">
                <v-btn icon color='blue200' @click="preFmlyAcnt('gal')">
                    <v-icon>mdi-content-save-outline</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-divider :class="[prtFmlyList.length > 3 ? 'mr-2__5' : 'mr-1']"></v-divider>
        <div v-if="prtFmlyList.length > 0" class="overflow-y-auto" style="width: 100%; height: 120px;">
            <v-row
                :class="['tableBB', prtFmlyList.length > 3 ? '' : 'mr-1']"
                v-for="(itm, i) in prtFmlyList" :key="i"
                no-gutters>
                <v-col class="txtAlgnMid tableBR pt-2" cols="2">
                    <span class="">{{ itm.name }}</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR pt-2" cols="2">
                    <span class="">{{ itm.rltns }}</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR pt-2" cols="3">
                    <div v-if="typeof itm.celphn_1 == 'string'" class="ma-0 pa-0">
                        <span>{{ itm.celphn_1 }}</span>
                        <span v-if="itm.celphn_2 != ''" class="mx-1">-</span>
                        <span>{{ itm.celphn_2 }}</span>
                        <span v-if="itm.celphn_3 != ''" class="mx-1">-</span>
                        <span>{{ itm.celphn_3 }}</span>
                    </div>
                </v-col>
                <v-col class="pa-1 tableBR" cols="4">
                    <v-text-field
                        v-model="itm.passwd"
                        :type="'password'" 
                        hide-details outlined dense>
                    </v-text-field>
                </v-col>
                <v-col class="d-flex justify-center align-center" cols="1">
                    <v-btn icon color='blue200' @click="sltRow = i, preFmlyAcnt('fmly')">
                        <v-icon>mdi-content-save-outline</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </v-sheet>
</template>  

<script>
import { insFmlyAcnt } from '../../api/bnfcr.js';

export default {
    
    name: 'BnfcrFmlyActMgmt',
            
    components: {
    },

    created: function(){

    },

    props: {
        prtFmlyInf: { type: Object, default: new Object() },
        prtFmlyList: { type: Array, default: () => { return [] } },
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            
        })
    },

    computed: {
           
    },

    watch:{
        
    },
        
    methods: {
        // 팝업 닫기
        onClose: function () {
            this.$emit('isClose')
        },

        // 보호자 계정 저장 사전 준비
        preFmlyAcnt: function(txt){
            let obj = {
                fcltyNum            : this.$store.getters.getFcltyNum,
                userNm              : this.$store.getters.getUserNm,
                bnMmbrPk            : this.$store.getters.getBnfcrState.bnmmbr,
                bnName              : this.$store.getters.getBnfcrState.bnfcr,
                mmbrPk              : -1,
                passwd              : ''
            }

            switch (txt) {
                case 'gal':
                    obj.mmbrPk = this.prtFmlyInf.mmbrPk
                    obj.passwd = this.galPasswd
                    break;
                case 'fmly':
                    if(this.sltRow > -1){
                        obj.mmbrPk = this.prtFmlyList[this.sltRow].mmbrPk
                        obj.passwd = this.prtFmlyList[this.sltRow].passwd
                    }
                    break;
                default:
                    break;
            }

            if(obj.passwd != '')
                this.setFmlyAcnt(obj)
            else{
                let obj = {}
                obj.actv = true   
                obj.tmr = 5000                                // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'                          // 경고 유형 (success, info, warning, error)
                obj.title = '비밀번호를 입력해주세요.'          // 경고 타이틀
                obj.cntnt = ''                                // 경고 내용 (arr만 출력됨!)
                this.$store.commit('setWrnnInf', obj)

                this.forceRender++
            }
        },

        // 보호자 계정 저장
        setFmlyAcnt: function(obj){
            insFmlyAcnt(obj)
                .then((response) => ( this.setFmlyAcntAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/insFmlyAcnt : ' + error))
        },

        setFmlyAcntAfter: function(res){
            let obj = {}
            obj.actv = true
            obj.title = res.message             // 경고 타이틀
            obj.cntnt = res.messagesList        // 경고 내용 (arr만 출력됨!)  
            
            if(res.statusCode == 200){                                          
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)                 
            }
            else{
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
            }

            this.$store.commit('setWrnnInf', obj)
        }

    },
    
    data: () => ({
        sltRow: -1,
        galPasswd: '',
        forceRender:0,
    }),
};
</script>