<template>
    <v-sheet class="ma-0" rounded="md">
        <v-form ref="emrForm" lazy-validation>
            <v-divider class=""></v-divider>
            <v-row no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                    <span class="font-weight-medium">점검일</span>
                </v-col>
                <v-col class="d-flex justify-left align-center tableBL tableBR pa-1" cols="4">
                    <span class="d-inline-block" style="width: 133px;">
                        <CmmDateComp
                            v-model="inspDt" :required="true"
                            :disabled="$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk > 0">
                        </CmmDateComp>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                    <span class="font-weight-medium">작성자</span>
                </v-col>
                <v-col class="d-flex justify-left align-center tableBL" cols="4">
                    <span class="d-inline-block pa-1" style="width:120px;">
                        <v-text-field
                            v-model="wrtr"
                            height="28" readonly hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <v-btn
                        class="brown lighten-3 d-inline-block" style="padding:0px 1px 0px 2px !important"
                        min-width="30" height="28" @click="dialog.splice(0, 1, true)"
                        dark small depressed>
                        <v-tooltip top >
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                            </template>
                            <span>직원선택</span>
                        </v-tooltip>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="greyE01" no-gutters>
                <v-col class="tableBR py-1" style="text-align: center;" cols="1">
                    <span class="font-weight-medium">연번</span>
                </v-col>
                <v-col class="tableBR py-1" style="text-align: center;" cols="3">
                    <span class="font-weight-medium">의료기기명</span>
                </v-col>
                <v-col class="tableBR py-1" style="text-align: center;" cols="1">
                    <span class="font-weight-medium">수량</span>
                </v-col>
                <v-col class="tableBR py-1" style="text-align: center;" cols="2">
                    <span class="font-weight-medium">작동여부</span>
                </v-col>
                <v-col class="tableBR py-1" style="text-align: center;" cols="4">
                    <span class="font-weight-medium">비고</span>
                </v-col>
                <v-col class="py-1" style="text-align: center;" cols="1">
                    <span class="">
                        <v-btn class="mr-1" color="blue200" height="26" @click="addRow()"
                            :disabled="inspRecDtllist.length != 0 ? false : true" icon>
                            <v-icon>mdi-plus-circle-outline</v-icon>
                        </v-btn>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="tableBB" v-for="(list, i) in inspRecDtllist" :key="i" no-gutters>
                <v-col class="tableBR py-1" style="text-align: center;" cols="1">
                    <span>{{ list.rn }}</span>
                </v-col>
                <v-col class="tableBR pa-1" style="text-align: center;" cols="3">
                    <span class="d-inline-block" style="width: 100%;">
                        <v-text-field
                            v-model="list.itm1Val" :rules="[rules.required]"
                            height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
                <v-col class="tableBR pa-1" style="text-align: center;" cols="1">
                    <span class="d-inline-block" style="width:100%;">
                        <v-text-field
                            v-model="list.itm2Val" class="numericTextField"
                            type="number" height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center tableBR py-1" style="text-align: center;" cols="2">
                    <v-radio-group
                        v-model="list.itm3Val" :key="forceRender" style="width: 94px;"
                        class="radio-dft-Theme pa-0 ma-0 d-flex justify-center align-center d-inline-block pl-2" hide-details row>
                        <v-radio class="d-inline-block d-flex justify-center ma-0 pr-2" style=""
                            on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline" :ripple="false" label="Y" value="1">
                        </v-radio>
                        <v-radio class="d-inline-block d-flex justify-center ma-0" style=""
                            on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline" :ripple="false" label="N" value="2">
                        </v-radio>
                    </v-radio-group>
                </v-col>
                <v-col class="tableBR pa-1" style="text-align: center;" cols="4">
                    <span class="d-inline-block" style="width: 100%;">
                        <v-text-field
                            v-model="list.itm4Val"
                            height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
                <v-col class="py-1" style="text-align: center;" cols="1">
                    <span class="py-1">
                        <v-btn class="mr-1" color="grey006" height="26" v-if="list.inspRecDtlPk != null"
                            @click="dialog.splice(1, 1, true), delTitle=list.itm1Val, sltIndex=i" icon>
                            <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                        <v-btn class="mr-1" color="grey006" height="26" icon
                            v-else-if="list.inspRecDtlPk == null && inspRecDtllist.length > 1" @click="removeRow(i)">
                            <v-icon>mdi-minus-circle-outline</v-icon>
                        </v-btn>
                    </span>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="d-flex justify-center align-center" style="background-color: #ededed !important;" cols="2">
                    <div class="font-weight-medium">
                        <span class="d-inline-block" style="width: 100%; text-align: center;">점검내용</span>
                    </div>
                </v-col>
                <v-col class="d-flex justify-center align-center py-1" cols="10">
                    <span class="d-inline-block pa-1" style="width:100%;">
                        <v-textarea
                            class="rmrksArea" v-model="cntnt13" no-resize hide-details outlined dense>
                        </v-textarea>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
        </v-form>
        <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
            <EmpSelector @isClose="dialog.splice(0, 1, false)" @outEmp="getEmpInfo" :prtWrtDt="inspDt"></EmpSelector>
        </v-dialog>
        <v-dialog v-model="dialog[1]" max-width="500">    
            <btnModalDelete :title="delTitle" @madalState="delModalAct"></btnModalDelete>  
        </v-dialog>
    </v-sheet>
</template>

<script>
import { selInspRecDtl1list, insInspRecDtl, delInspRecDtl, delInspRecDtlInfo } from '../../api/sfty.js';
import { getMultiStcd } from '../../api/index.js';

import EmpSelector from '../commons/EmpSelector.vue';
import btnModalDelete from '../bnfcr/BnfcrDeleteConfirm.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';

export default {
    name: 'EmrMdcDvcIns',

    props : {
       
    },
        
    components: {
        EmpSelector,
        btnModalDelete,
        CmmDateComp,
    },

    created: function(){
        // this.getCode(['47'])
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {
        getSltItem: function () {
            return this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem
        },
    },

    watch:{
        getSltItem: function (value) {
            if(this.$parent.$parent.$parent.$parent.tab == 2){
                this.clearForm()
                let sltInspDate = value.substring(0, 7)
                let insPos = this.$parent.$parent.$parent.$parent.$parent.$parent.sftyList.findIndex(v => v.inspPrd == sltInspDate)
                let inspDate = this.$parent.$parent.$parent.$parent.$parent.$parent.sftyList[insPos].itm3

                this.getInspRecDtllist(inspDate?.afterDateFormatDot(), 1)

                if(insPos != -1)
                    this.cntnt13 = this.$parent.$parent.$parent.$parent.$parent.$parent.sftyList[insPos].cntnt13
            }
        },
    },
    
    methods: {
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        //응급의료기기점검 조회
        getInspRecDtllist: function (date){
            selInspRecDtl1list(this.$store.getters.getFcltyNum, this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk, date, 507, 3)
                .then((response) => ( this.getInspRecDtllistAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/selInspRecDtl1list : ' + error))
        },
        //응급의료기기점검 저장
        setInspRecDtl: function (obj){
            insInspRecDtl(obj)
                .then((response) => ( this.setInspRecAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/insInspRecDtl : ' + error))
        },
        //응급의료기기점검 삭제
        removeInspRecDtl: function (){
            delInspRecDtl(this.$store.getters.getFcltyNum, this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk, this.$store.getters.getUserNm )
                .then((response) => ( this.setInspRecAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/delInspRecDtl : ' + error))
        },
        //응급의료기기점검 단건 삭제
        removeInspRecDtlInfo: function (pk){
            delInspRecDtlInfo(this.$store.getters.getFcltyNum, this.$store.getters.getUserNm, pk )
                .then((response) => ( this.setInspRecDtlInfoAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/delInspRecDtlInfo : ' + error))
        },
        getInspRecDtllistAfter: function (res){
            this.inspTitle.splice(0)
            this.inspRecDtllist.splice(0)
            
            let keyList = []
            res.forEach(items => {
                items.itm1Cd = Number(items.itm1Cd)

                if(keyList.length == 0)
                    keyList.push(items.clsfc3CdNm)
                else{
                    let tmp = keyList.find(v => v === items.clsfc3CdNm)
                    if(tmp == undefined) keyList.push(items.clsfc3CdNm)
                }

                this.wrtr = items.wrtr
                this.mmbr = items.wrtrMmbrPk

                if(this.inspDt == ''){
                    if(items.inspDt == null)
                        this.inspDt = this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem.replaceAll('.', '')?.beforeDateFormatHyp()
                    else
                        this.inspDt = items.inspDt?.beforeDateFormatHyp()
                }
            });

            //res.sort((x, y) => x.itm1Cd - y.itm1Cd)

            this.inspTitle = keyList
            this.inspRecDtllist = res
        },
        setInspRecAfter: function (res){
            if(res.statusCode == 200){
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])
                this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk = 0
                this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem = -1
                this.$parent.$parent.$parent.$parent.$parent.$parent.getTableList()
                this.$parent.$parent.$parent.$parent.tab = 0
                
                this.clearForm()
                this.$parent.$parent.$parent.$parent.btnControll(1, true, 'fill')
                this.$parent.$parent.$parent.$parent.btnControll(0, true, 'out')
            }
            else
                this.$parent.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])
        },
        setInspRecDtlInfoAfter: function (res){
            if(res.statusCode == 200){
                let sltInspDate = this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem.substring(0, 7)
                this.getInspRecDtllist(sltInspDate.afterDateFormatMonthDot())
                
                this.clearForm()
            }
            else
                this.$parent.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])
        },
        preInspRecDtllist: function (){
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm
            obj.clsfc1Cd = 507
            obj.clsfc2Cd = 3
            obj.inspRecPk = this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk
            obj.inspDt = this.inspDt.afterDateFormatHyp()
            obj.cntnt1 = this.cntnt13
            obj.list = []

            let chk = this.$refs.emrForm.validate()

            if(chk){
                this.inspRecDtllist.forEach(items => {               
                    items.inspDtlDt = this.inspDt.afterDateFormatHyp()
                    items.inspDt = this.inspDt.afterDateFormatHyp()
                    items.wrtr = this.wrtr
                    items.wrtrMmbrPk = this.mmbr
                    items.inspTmHh = '12'
                    items.inspTmMm = '00'
                    items.itm1Cd = 1
                    items.itm2Cd = 2
                    items.itm3Cd = 3
                    items.itm4Cd = 4

                    if(items.itm2Val == null)
                        items.itm2Val = 0
                    
                    obj.list.push(items)
                });

                //console.log(obj)
                this.setInspRecDtl(obj)
            }
            else
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, '필수 항목을 입력해주세요.', 'info']) 
        },
        clearForm: function () {
            this.inspTitle.splice(0)
            //this.$refs.emrForm.reset()
            this.inspRecDtllist.splice(0)
            this.wrtr = ''
            this.mmbr = 0
            this.cntnt13 = ''
            this.inspDt = ''
            this.forceRender += 1
        },
        delModalAct: function (res) {
            if(res == 9990){    //OK
                this.removeInspRecDtlInfo(this.inspRecDtllist[this.sltIndex].inspRecDtlPk)
                
                this.dialog.splice(1, 1, false)
            }
            else if(res == 7)   //Cancel
                this.dialog.splice(1, 1, false)

            this.delTitle = ''
            this.sltIndex = -1
        },
        addRow: function () {
            let obj = Object.assign({}, this.dummyInspRecDtlInfo)
            obj.rn = this.inspRecDtllist.length + 1
            this.inspRecDtllist.push(obj)
        },
        removeRow: function (index) {
            this.inspRecDtllist.splice(index, 1)
        },
        getEmpInfo: function (obj) {
            this.wrtr = obj[0].name
            this.mmbr = obj[0].mmbrPk
        },
        timePicked: function (){
            this.$refs.timePicker.save(this.inspTm)
            this.forceRender += 1
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
    },

    data: () => ({
        sltIndex: -1,
        menu: [false],
        dialog: [false, false],
        inspTitle: [],
        inspRecDtllist: [],
        dummyInspRecDtlInfo: {
            inspDt: '', inspDtlDt: '', inspRecDtlPk: null, inspRecPk: null, clsfc1Cd: 507, clsfc3Cd: 1, clsfc2Cd: 3,  wrtr: '', inspTmHh: '', inspTmMm: '',
            itm2Cd: '2', itm1Cd: 1, itm4Cd: 4, itm3Cd: 3, itm3Val: null, itm2Val: null, itm1Val: null, itm4Val: null, },
        wrtr: '',
        mmbr: 0,
        cntnt13: '',
        inspDt: '',
        delTitle: '',
        forceRender: 0,
        rules: {
            required: value => !!value || 'Required.',
        },
    }),
};
</script>