<template>
    <div class="d-flex justify-center align-center">
        <v-btn class="mr-1" color="btnBrdrColor" x-small outlined @click="isPrev"
            style="background-color: white; width:26px;" height="26" :disabled="btnDisabled">
            <span class="black--text">
                <v-icon>mdi-chevron-left</v-icon>
            </span>
        </v-btn>
        <v-btn class="" color="btnBrdrColor" x-small outlined @click="isNext"
            style="background-color: white; width:26px;" height="26" :disabled="btnDisabled">
            <span class="black--text">
                <v-icon>mdi-chevron-right</v-icon>
            </span>
        </v-btn>
        <span class="text-h5 font-weight-bold mx-3 pb-1" style="color: #004584;">{{weekstarttxt}} ~ {{weekendtxt}}</span>
        <v-menu
            ref="menu" v-model="menu"
            :close-on-content-click="false" :return-value.sync="date"
            transition="scale-transition" offset-y min-width="auto" dense>
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="monthBtnPicker pa-0" style="background-color: white;min-width: 32px !important;" v-bind="attrs" v-on="on" color="btnBrdrColor" height="26" small outlined :disabled="btnDisabled">
                    <v-icon class="black--text" style="margin-top: 0px;" small>mdi-calendar-month</v-icon>
                </v-btn>
            </template>
            <v-date-picker
                v-model="date" type="date"
                class="cmmDtPckr" locale="en-US"
                :weekday-format="getDay" :month-format="getMonth" :header-date-format="getTitle"
                :allowed-dates="allowedDates" :key="forceRender"
                @click:date="datePickClose"
                no-title scrollable>
            </v-date-picker>
        </v-menu>
    </div>
</template>

<script>
export default {
    name: 'expandWeekOnlyPicker',

    props : {
        prtDate: { type: String, default: '' },
        btnDisabled : {type:Boolean, default:false}
    },
    
    components: {
    
    },

    created: function(){
        this.setToday()
    },

    mounted: function(){
        this.$nextTick(function () {
           this.setToday()
        })
    },

    watch: {
        'date': function() {            
            this.setWeekFormat(this.date)            
            this.$emit('nowWeek',  this.weekstart?.afterDateFormatHyp(), this.weekend?.afterDateFormatHyp())
        },
        'prtDate':function(){
            
            let pday = this.prtDate.substr(0,4)+"-"+this.prtDate.substr(4,2)+"-"+this.prtDate.substr(6,2)
            let currentDay = this.getDate(new Date(pday))  

            if(this.weekstart != currentDay){
                this.date = currentDay
            }
        }
    },

    methods: {
        isPrev: function (){        
            this.date = this.$moment(this.date,'YYYY-MM-DD').add(-7,'days').format('YYYY-MM-DD')
        },
        isNext: function (){            
            this.date = this.$moment(this.date,'YYYY-MM-DD').add(7,'days').format('YYYY-MM-DD')
        },
        datePickClose: function () {
            this.menu = false
            this.$refs.menu.save(this.date)
            this.forceRender += 1
        },
        //오늘 계산
        setToday : function(){  
            this.date = this.getDate()
        },
        getDate:function(currentDay = new Date()){            
            let theYear = currentDay.getFullYear();
            let theMonth = currentDay.getMonth();
            let theDate  = currentDay.getDate();            
            let theDayOfWeek = currentDay.getDay();
            let resultDay = null
            
            resultDay = new Date(theYear, theMonth, theDate - theDayOfWeek)

            resultDay = this.$moment(resultDay).format('YYYY-MM-DD')
            return resultDay
        },
        //주간계산
        setWeekFormat: function(date){
            this.weekstart      = date
            this.weekend        = this.$moment(date,'YYYY-MM-DD').add(6,'days').format('YYYY-MM-DD')
            this.weekstarttxt   = this.$moment(this.weekstart,'YYYY-MM-DD').format('YYYY.MM.DD')
            this.weekendtxt     = this.$moment(this.weekend,'YYYY-MM-DD').format('YYYY.MM.DD')
        }, 
        allowedDates: val => new Date(val).getDay() === 0,

        getTitle: function(date) {
            let monthName = [
                '1월',
                '2월',
                '3월',
                '4월',
                '5월',
                '6월',
                '7월',
                '8월',
                '9월',
                '10월',
                '11월',
                '12월',
            ];

            let i = new Date(date).getMonth(date)

            if(date.length == 4)
                return new Date(date).getFullYear() + '년'
            else
                return new Date(date).getFullYear() + '년 ' + monthName[i]
        },
        getMonth: function(date) {
            let monthName = [
                '1월',
                '2월',
                '3월',
                '4월',
                '5월',
                '6월',
                '7월',
                '8월',
                '9월',
                '10월',
                '11월',
                '12월',
            ];

            let i = new Date(date).getMonth(date)
            return monthName[i]
        },
        getDay: function (date) {
            const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토']
            let i = new Date(date).getDay(date)
            return daysOfWeek[i]
        },
    },

    data: () => ({
        menu: false,
        date: '',
        dotDate: '',
        weekstart : '',
        weekend : '',
        weekstarttxt:'',
        weekendtxt:'',
        forceRender: 0,
    }),
};
</script>