<template>
    <v-sheet class="ma-0" rounded="md">
        <v-divider class=""></v-divider>
        <v-row class="" no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                <span class="font-weight-medium">점검일</span>
            </v-col>
            <v-col class="d-flex justify-left align-center tableBL tableBR pa-1" cols="3">
                <span class="d-inline-block" style="width: 133px;">
                    <CmmDateComp
                        v-model="inspDt" :required="true"
                        :disabled="$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk > 0">
                    </CmmDateComp>
                </span>
            </v-col>
            <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                <span class="font-weight-medium">작성자</span>
            </v-col>
            <v-col class="d-flex justify-left align-center tableBL" cols="5">
                <span class="d-inline-block pa-1" style="width:120px;">
                    <v-text-field
                        v-model="wrtr" height="28"
                        readonly hide-details outlined dense>
                    </v-text-field>
                </span>
                <v-btn 
                    class="brown lighten-3 d-inline-block" style="padding:0px 1px 0px 2px !important"
                    min-width="30" height="28" @click="dialog.splice(0, 1, true)"
                    dark small depressed>
                    <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                        </template>
                        <span>직원선택</span>
                    </v-tooltip>
                </v-btn>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row class="greyE01" no-gutters>
            <v-col class="tableBR py-1" style="text-align: center;" cols="5">
                <span class="font-weight-medium">점검내용</span>
            </v-col>
            <v-col class="py-1" style="text-align: center;" cols="7">
                <span class="font-weight-medium">점검결과</span>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row v-for="(list, i) in inspRecDtllist" :key="i" class="tableBB" style="" no-gutters>
            <v-col class="d-flex justify-center align-center tableBR py-2" style="border-right: 1px solid #dbdbdb !important;" cols="5">
                <span class="">{{ list.itm1CdNm }}</span>
            </v-col>
            <v-col class="d-inline-block pa-0 ma-0" style="text-align: center;" cols="7">
                <v-radio-group
                    v-model="list.itm1Val" :key="forceRender" style="width: 276px; margin: auto !important;"
                    class="radio-dft-Theme pa-0 ma-0 d-flex justify-center align-center d-inline-block pl-2"
                    hide-details row>
                    <v-radio class="d-inline-block d-flex justify-center ma-0 pr-2" style="height: 39.11px;"
                        on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline" :ripple="false"
                        v-for="(cd, j) in vCd47" :key="j" :value="cd.valcd" :label="cd.valcdnm">
                    </v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col class="d-flex justify-center align-center" style="background-color: #ededed !important;" cols="2">
                <div class="font-weight-medium">
                    <span class="d-inline-block" style="width: 100%; text-align: center;">비고</span>
                    <span class="d-inline-block" style="width: 100%; text-align: center;">&#40;조치사항&#41;</span>
                </div>
            </v-col>
            <v-col class="d-flex justify-center align-center py-1" cols="10">
                <span class="d-inline-block pa-1" style="width:100%;">
                    <v-textarea
                        class="rmrksArea" v-model="cntnt11" no-resize hide-details outlined dense>
                    </v-textarea>
                </span>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <div class="d-flex align-center mt-5">
            <v-icon style="font-size: 28px; width: 8px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold ml-3">점검&nbsp;약품&nbsp;목록</span>
            <v-btn
                class="ml-1 mt-1" width="20" height="20" @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 13)" icon>
                <v-icon size="20" style="">mdi-help-circle-outline</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                class="mr-1" color="blue200" style="background-color: white; min-width: 100px; width: 100px;"
                height="26" @click="dialog.splice(1, 1, true)" outlined>
                약품목록관리
            </v-btn>
        </div>
        <v-divider :class="['mt-2', inspRecDtllist.length > 6 ? 'mr-2__5' : '']" style="width: 99%;"></v-divider>
        <v-row :class="['greyE01', inspRecDtllist.length > 6 ? 'mr-2__5' : '']" style="width: 99%;" no-gutters>
            <v-col class="d-flex justify-center align-center tableBR py-1" style="text-align: center;" cols="2">
                <span class="font-weight-medium">의약품명</span>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBR py-1" style="text-align: center;" cols="3">
                <span class="font-weight-medium">효능</span>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBR py-1" style="text-align: center;" cols="2">
                <span class="font-weight-medium">취급방법</span>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBR py-1" style="text-align: center;" cols="1">
                <span class="font-weight-medium">수량</span>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBR py-1" style="text-align: center;" cols="2">
                <span class="font-weight-medium">유효기한</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-1" style="text-align: center;" cols="2">
                <span class="font-weight-medium">상태</span>
            </v-col>
        </v-row>
        <v-divider :class="[inspRecDtllist.length > 6 ? 'mr-2__5' : '']" style="width: 99%;"></v-divider>
        <div v-if="inspRecDtllist.length > 0" class="d-inline-block overflow-y-auto" style="width: 99%; height: 270px;">
            <v-row v-for="(drug, k) in drugInspRecDtllist" :key="inspRecDtllist.length + k" class="tableBB" no-gutters>
                <v-col class="overflow-text-hidden txtAlgnMid tableBR px-1" style="line-height: 2.1;" cols="2">
                    <span class="">{{ drug.drugNm }}</span>
                </v-col>
                <v-col class="overflow-text-hidden txtAlgnMid tableBR px-1" style="line-height: 2.1;" cols="3">
                    <span class="">{{ drug.drugEfct }}</span>
                </v-col>
                <v-col class=" overflow-text-hidden txtAlgnMid tableBR px-1" style="line-height: 2.1;" cols="2">
                    <span class="">{{ drug.hndlMthd }}</span>
                </v-col>
                <v-col class="tableBR px-1" cols="1">
                    <span class="d-inline-block pa-1" style="width:100%;">
                        <v-text-field
                            v-model="drug.itm2Val" class="numericTextField"
                            type="number" height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR pa-1" cols="2">
                    <span class="d-inline-block" style="width: 133px;">
                        <CmmDateComp v-model="drug.itm3Val" :btnActv="false" :required="false"></CmmDateComp>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-1" style="text-align: center;" cols="2">
                    <span class="px-1" style="width:100%;">
                        <v-select class="hsptlSelect"
                            v-model="drug.itm4Val" height="28" :key="forceRender"
                            item-text="valcdnm" item-value="valcd" :items="vCdCustom"
                            dense hide-details outlined>
                        </v-select>
                    </span>
                </v-col>
            </v-row>
        </div>
        <v-row class="pa-0 tableBB" v-else no-gutters>
            <v-col class="py-2" style="text-align: center;" cols="12">
                <span class="grey006--text">※ 조회된 목록이 없습니다.</span>
            </v-col>
        </v-row>
        <v-dialog v-model="dialog[0]" content-class="round" persistent max-width="800">
            <EmpSelector @isClose="dialog.splice(0, 1, false)" @outEmp="getEmpInfo" :prtWrtDt="inspDt"></EmpSelector>
        </v-dialog>
        <v-dialog v-model="dialog[1]" content-class="round" persistent max-width="1100">
            <DrugInfMgmt :cmpTtl="'의약품 관리'" @reRoadTrg="reDrugList()"  @isClose="dialog.splice(1, 1, false)"></DrugInfMgmt>  
        </v-dialog>
    </v-sheet>
</template>

<script>
import { selInspRecDtl1list, selInspRecDtl507list, insInspRecDtl, delInspRecDtl, delInspRecDtlInfo } from '../../api/sfty.js';
import { getMultiStcd } from '../../api/index.js';

import DrugInfMgmt from '../commons/DrugInfMgmt.vue';
import EmpSelector from '../commons/EmpSelector.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';

export default {
    name: 'DrugInspc',

    props : {
       
    },
        
    components: {
        EmpSelector,
        DrugInfMgmt,
        CmmDateComp,
    },

    created: function(){
        this.getCode(['47'])
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {
        getSltItem: function () {
            return this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem
        },
    },

    watch:{
        getSltItem: function (value) {
            if(this.$parent.$parent.$parent.$parent.tab == 0){
                this.clearForm()
                let sltInspDate = value.substring(0, 7)
                let insPos = this.$parent.$parent.$parent.$parent.$parent.$parent.sftyList.findIndex(v => v.inspPrd == sltInspDate)
                let inspDate = this.$parent.$parent.$parent.$parent.$parent.$parent.sftyList[insPos].itm1

                this.getInspRecDtllist(inspDate?.afterDateFormatDot())
                this.getInspRecDtl507list()

                if(insPos != -1)
                    this.cntnt11 = this.$parent.$parent.$parent.$parent.$parent.$parent.sftyList[insPos].cntnt11
            }
        },
    },
    
    methods: {
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        //약품점검 조회
        getInspRecDtllist: function (date){
            selInspRecDtl1list(this.$store.getters.getFcltyNum, this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk, date, 507, 1, 1)
                .then((response) => ( this.getInspRecDtllistAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/selInspRecDtl1list : ' + error))
        },
        //약품 목록 조회
        getInspRecDtl507list: function (){
            selInspRecDtl507list(this.$store.getters.getFcltyNum, this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk)
                .then((response) => ( this.getInspRecDtl507listAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/selInspRecDtl507list : ' + error))
        },
        //약품점검 저장
        setInspRecDtl: function (obj){
            insInspRecDtl(obj)
                .then((response) => ( this.setInspRecAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/insInspRecDtl : ' + error))
        },
        //약품점검 삭제
        removeInspRecDtl: function (){
            delInspRecDtl(this.$store.getters.getFcltyNum, this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk, this.$store.getters.getUserNm )
                .then((response) => ( this.setInspRecAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/delInspRecDtl : ' + error))
        },
        //약품점검 단건 삭제
        removeInspRecDtlInfo: function (pk){
            delInspRecDtlInfo(this.$store.getters.getFcltyNum, this.$store.getters.getUserNm, pk )
                .then((response) => ( this.setInspRecDtlInfoAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/delInspRecDtlInfo : ' + error))
        },
        stCdMapping: function (res) {
            this.vCd47 = res.vCd47
        },
        getInspRecDtllistAfter: function (res){
            this.inspTitle.splice(0)
            this.inspRecDtllist.splice(0)

            let keyList = []
            res.forEach(items => {
                items.itm1Cd = Number(items.itm1Cd)

                if(keyList.length == 0)
                    keyList.push(items.clsfc3CdNm)
                else{
                    let tmp = keyList.find(v => v === items.clsfc3CdNm)
                    if(tmp == undefined) keyList.push(items.clsfc3CdNm)
                }

                this.wrtr = items.wrtr
                this.mmbr = items.wrtrMmbrPk

                if(this.inspDt == ''){
                    if(items.inspDt == null)
                        this.inspDt = this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem.replaceAll('.', '')?.beforeDateFormatHyp()
                    else
                        this.inspDt = items.inspDt?.beforeDateFormatHyp()
                }

                if(this.inspTm == ''){
                    if(items.inspTmHh == null)
                        this.inspTm = '12:00'
                    else
                        this.inspTm = items.inspTmHh + ':' + items.inspTmMm
                }
  
                if(items.itm1Val == null)
                    items.itm1Val = '4'
            });

            res.sort((x, y) => x.itm1Cd - y.itm1Cd)

            this.inspTitle = keyList
            this.inspRecDtllist = res
        },
        getInspRecDtl507listAfter: function (res){
            res.forEach(items => {
                this.menu.push(false)
                if(items.itm4Val == null)
                    items.itm4Val = '2'

                if(items.itm3Val == null)
                    items.itm3Val = new Date().toISOString().split('T',2)[0]
                else
                items.itm3Val = items.itm3Val?.beforeDateFormatHyp()

                if(items.itm2Val == null)
                    items.itm2Val = 0
                else 
                    items.itm2Val = Number(items.itm2Val)
            });
            this.drugInspRecDtllist = res
        },
        setInspRecAfter: function (res){
            if(res.statusCode == 200){
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])
                this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk = 0
                this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem = -1
                this.$parent.$parent.$parent.$parent.$parent.$parent.getTableList()
                this.$parent.$parent.$parent.$parent.tab = 0
                
                this.clearForm()
                this.$parent.$parent.$parent.$parent.btnControll(1, true, 'fill')
                this.$parent.$parent.$parent.$parent.btnControll(0, true, 'out')
            }
            else
                this.$parent.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])
        },
        setInspRecDtlInfoAfter: function (res){
            if(res.statusCode == 200){
                let sltInspDate = this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem.substring(0, 7)
                this.getInspRecDtllist(sltInspDate.afterDateFormatMonthDot())
                
                this.clearForm()
            }
            else
                this.$parent.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])
        },
        preInspRecDtllist: function (){
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm
            obj.clsfc1Cd = 507
            obj.clsfc2Cd = 1
            obj.inspRecPk = this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk
            obj.inspDt = this.inspDt.afterDateFormatHyp()
            obj.cntnt1 = this.cntnt11
            obj.list = []

            this.inspRecDtllist.forEach(items => {               
                items.inspDtlDt = this.inspDt.afterDateFormatHyp()
                items.inspDt = this.inspDt.afterDateFormatHyp()
                items.wrtr = this.wrtr
                items.wrtrMmbrPk = this.mmbr
                let tmp = this.inspTm.split(':', 2)
                items.inspTmHh = tmp[0]
                items.inspTmMm = tmp[1]
  
                obj.list.push(items)
            });

            this.drugInspRecDtllist.forEach(items => {
                let ooob = Object.assign({}, items)
                ooob.inspDtlDt = this.inspDt.afterDateFormatHyp()
                ooob.inspDt = this.inspDt.afterDateFormatHyp()
                ooob.wrtr = this.wrtr
                let tmp = this.inspTm.split(':', 2)
                ooob.inspTmHh = tmp[0]
                ooob.inspTmMm = tmp[1]
                ooob.itm3Val = items.itm3Val.afterDateFormatHyp()

                obj.list.push(ooob)
            });

            //console.log(obj)
            this.setInspRecDtl(obj)
        },
        reDrugList: function (){
            this.getInspRecDtl507list()
        },
        clearForm: function (){
            this.inspTitle.splice(0)
            this.inspRecDtllist.splice(0)
            this.wrtr = ''
            this.mmbr = 0
            this.cntnt11 = ''
            this.inspDt = ''
            this.inspTm = ''
            this.forceRender += 1
        },
        addRow: function (){
            let obj = Object.assign({}, this.drugInspRecDtlInfo)

            this.drugInspRecDtllist.push(obj)
        },
        getEmpInfo: function (obj) {
            this.wrtr = obj[0].name
            this.mmbr = obj[0].mmbrPk
        },
        timePicked: function (){
            this.$refs.timePicker.save(this.inspTm)
            this.forceRender += 1
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
    },

    data: () => ({
        menu: [false],
        dialog: [false, false],
        vCd47: [],
        vCdCustom: [ { valcd: '1', valcdnm: '폐기 '}, { valcd: '2', valcdnm: '보관' }, ],
        inspTitle: [],
        inspRecDtllist: [],
        drugInspRecDtllist: [],
        drugInspRecDtlInfo: {
            inspDt: '', inspDtlDt: '', inspRecDtlPk: 0, drugInfPk: 0, inspRecPk: 0, inspTmHh: '12', inspTmMm: '00',
            clsfc3Cd: '2', clsfc2Cd: '1', clsfc1Cd: '507', itm1Cd: '1', itm2Cd: '2', itm3Cd: '3', itm4Cd: '4', itm1Val: '', itm2Val: 0, itm3Val: '', itm4Val: '' },
        wrtr: '',
        mmbr: 0,
        cntnt11: '',
        inspDt: '',
        inspTm: '',
        forceRender: 0,
        rules: {
            required: value => !!value || 'Required.',
        },
    }),
};
</script>

<style>
    .numericTextField .v-input__control .v-input__slot { padding: 0 6px !important; }
</style>