<template>
    <v-sheet class="ma-0 pa-0" rounded="lg">
        <v-row class="pa-0 justify-center align-center" no-gutters>
            <span class="text-h4 font-weight-bold">입소일 수정</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="$parent.$parent.$parent.$parent.mdlOff" x-large>mdi-close</v-icon>
        </v-row>
        <v-row class="pa-0 mt-8 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">수급자 기본정보</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">수급자</span>
            </v-col>
            <v-col class="px-4 py-1" cols="7">
                <span class="mr-2">{{ bscsInf.name }}</span>
                &#40;
                <span v-if="bscsInf.gndr == 'M'">남</span>
                <span v-else-if="bscsInf.gndr == 'F'">여</span>
                <span class="ml-1">/ 만 {{ bscsInf.age }}세</span>
                &#41;
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">생년월일</span>
            </v-col>
            <v-col class="px-4 py-1" cols="7">
                <span>{{ $moment( bscsInf.rsdntBirth, 'YYYYMMDD' ).format('YYYY.MM.DD') }}</span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">입소일</span>
            </v-col>
            <v-col class="px-4 py-1" cols="3">
                {{ bscsInf.entDt }}
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">퇴소일</span>
            </v-col>
            <v-col class="px-4 py-1" cols="3">
                {{ bscsInf.lvDt }}
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mt-8 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">변경 정보</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="3">
                <span class="font-weight-medium">입소일</span>
            </v-col>
            <v-col class="px-4 py-1" cols="9">
                <span class="d-inline-block" style="width: 134px; padding-top: 1px;">
                    <CmmDateComp
                        v-model="entDt" :required="true"
                        :maxDt="bscsInf.lvDt"
                        @input="aplctSavBtn">
                    </CmmDateComp>
                </span>
                <span class="d-inline-block ml-2" style="width: 80px;">
                    <CmmTimeField v-model="entTm" :required="false" @input="aplctSavBtn"></CmmTimeField>
                </span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-divider class="white my-2"></v-divider>
        <v-row class="pa-0 justify-center" no-gutters>
            <v-btn
                class="" color="blueBtnColor" min-width="110" height="36"
                :disabled="savBtn" @click="dcsnSavBtn">
                <span class="white--text fontOneDotTwoRem">저장</span>
            </v-btn>
        </v-row>
        <v-divider class="white mt-1"></v-divider>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

import CmmDateComp from '../../commons/CmmDateComp.vue';         // 날짜 입력
import CmmTimeField from '../../commons/CmmTimeField.vue';       // 시간 입력

export default {
    name: 'BscEntSavMdl',
    
    components: {
        CmmDateComp,
        CmmTimeField,
    },

    props : { 

    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            let obj = this.$store.getters.getRootInObj
            this.aplctInf(obj)
        })
    },

    computed: {
  
    },

    watch: {
      
    },
        
    methods: {
        // 입소일 수정
        setEntDt: function (obj){
            this.savBtn = true

            http.post('bnfcrAuth/updBnfcrEntDt', obj)
                .then((response) => ( this.entDtRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/updBnfcrEntDt : ' + error))
        },

        entDtRst: function (res){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.messageList.length > 0)
                obj.cntnt = res.messageList

            if(res.statusCode == '200')
                this.savBtn = false
            else
                obj.type = 'warning'

            this.$store.commit('setWrnnInf', obj)
        },

        // 기본정보 적용
        aplctInf: function (obj) {
            this.bscsInf.bnMmbrPk = obj.bnMmbrPk
            this.bscsInf.bnfcrPk = obj.bnfcrPk
            this.bscsInf.name = obj.name
            this.bscsInf.gndr = obj.gndr
            this.bscsInf.age = obj.age
            this.bscsInf.rsdntBirth = obj.rsdntBirth
            this.bscsInf.entDt = this.$moment(obj.entDt, 'YYYYMMDD').format('YYYY-MM-DD')
            if(obj.lvDt != null && obj.lvDt != '')
                this.bscsInf.lvDt = this.$moment(obj.lvDt, 'YYYYMMDD').format('YYYY-MM-DD')
        },

        // 저장버튼 활성 여부 체크
        aplctSavBtn: function () {
            this.savBtn = true

            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.entDt) == false)
                return 0
            if(/^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(this.entTm) == false)
                return 0

            this.savBtn = false
        },

        // 저장버튼 분기
        dcsnSavBtn: function () {
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = '변경할 정보를 입력해주세요.'     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(this.entDt == ''){
                this.$store.commit('setWrnnInf', obj)
                return 0
            }

            let tmpDt = this.$moment(this.entDt, 'YYYY-MM-DD')
            if( tmpDt.isAfter(this.$moment(this.bscsInf.lvDt, 'YYYY-MM-DD')) ){
                    obj.title = '입소일은 퇴소일 이전만 입력 가능합니다.'
                    obj.type = 'warning'
                    this.$store.commit('setWrnnInf', obj)
                    return 0
            }

            let insObj = {}
            insObj.bnMmbrPk = this.bscsInf.bnMmbrPk
            insObj.bnfcrPk = this.bscsInf.bnfcrPk
            insObj.entDt = this.$moment(this.entDt, 'YYYY-MM-DD').format('YYYYMMDD')
            if(/^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(this.entTm)){
                let splitTime = this.entTm?.split(':', 2)
                insObj.entTmHh = splitTime[0]
                insObj.entTmMm = splitTime[1]
            }
            else{
                insObj.entTmHh = ''
                insObj.entTmMm = ''
            }

            this.setEntDt(insObj)
        },

    },

    data: () => ({
        savBtn: true,
        runAble: '0',
        entDt: '',
        entTm: '',
        bscsInf: {
            bnMmbrPk: -1, bnfcrPk: -1, name: '', gndr: '', age: '', rsdntBirth: '', entDt: '', lvDt: '',
        },
    }),
};
</script>

<!-- entTmHh: '', entTmMm: '', -->