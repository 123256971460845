import { http } from '@/api/baseAxios';

//분류코드 조회
function selCmnItmValClCdList(code) {
    return http.post('cmm/selCmnItmValClCdList', {fcltyNum : code})
}

//상세코드 조회
function selCmnItmValCd(code, valCd) {
    return http.post('cmm/selCmnItmValCd', {fcltyNum : code, valClcd : valCd})
}

//상세코드 저장
function insCmnItmValCd(obj) {
    return http.post('cmm/insCmnItmValCd', obj)
}

//상세코드 삭제
function delCmnItmValCd(obj) {
    return http.post('cmm/delCmnItmValCd', obj)
}

//서류관리 저장
function insDocMng(obj) {
    return http.post('cmm/insDocMng', obj)
}

//직원 서류관리 조회
function selDocMngCntList(code, bzcd, docCd, pk) {
    return http.post('cmm/selDocMngCntList', { fcltyNum: code, bzClcd: bzcd, docClcd: docCd, bzPk: pk })
}

//서류관리 삭제
function delDocMng(code, nm, pk) {
    return http.post('cmm/delDocMng', { fcltyNum: code, userNm: nm, docMngPk: pk })
}

//권한그룹 저장
function insCmnAuthGrp(obj) {
    return http.post('cmm/insCmnAuthGrp', obj)
}

//권한그룹  삭제
function delCmnAuthGrp(obj) {
    return http.post('cmm/delCmnAuthGrp', obj)
}

//권한그룹 단건 조회
function selCmnAuthGrpInfo(code, pk) {
    return http.post('cmm/selCmnAuthGrpInfo', {fcltyNum : code, cmnAuthGrpPk:pk})
}

//권한그룹 다건 조회
function selCmnAuthGrpList(code) {
    return http.post('cmm/selCmnAuthGrpList', {fcltyNum : code})
}

//권한화면 다건 조회
function selCmnAuthViewList(code, pk) {
    return http.post('cmm/selCmnAuthViewList', {fcltyNum : code, cmnAuthGrpPk:pk, upCmmViewPk:0})
}

//권한화면 다건 조회(JSON)
function selCmnAuthViewJsonList(code, pk) {
    return http.post('cmm/selCmnAuthViewJsonList', {fcltyNum : code, cmnAuthGrpPk:pk})
}

//권환 화면 설정 저장
function insCmnAuthGrpViewMap(obj) {
    return http.post('cmm/insCmnAuthGrpViewMap', obj)
}

//권한그룹 직원 다건 조회
function selCmnAuthGrpEmpList(code, pk) {
    return http.post('cmm/selCmnAuthGrpEmpList', {fcltyNum : code, cmnAuthGrpPk:pk})
}

//직원 고유번호 조회
function selCmnEmpInfo(code, pk) {
    return http.post('cmm/selCmnEmpInfo', { fcltyNum: code, mmbrPk: pk })
}

//공통항목코드 조회
function selCmnItmCd(code, clsfc1Cd, clsfc2Cd, clsfc3Cd, dspOrd, useyn=null) {
    return http.post('cmm/selCmnItmCd', { fcltyNum: code, clsfc1Cd: clsfc1Cd, clsfc2Cd: clsfc2Cd, clsfc3Cd: clsfc3Cd, dspOrd: dspOrd,inqUseYn:useyn})
}

//공통항목코드 저장
function insCmnItmCd(obj) {
    return http.post('cmm/insCmnItmCd', obj)
}

//권한그룹  삭제
function delCmnItmCd(obj) {
    return http.post('cmm/delCmnItmCd', obj)
}

export {
    selCmnItmValClCdList,
    selCmnItmValCd,
    insCmnItmValCd,
    delCmnItmValCd,
    insDocMng,
    selDocMngCntList,
    delDocMng,
    insCmnAuthGrp,
    delCmnAuthGrp,
    selCmnAuthGrpInfo,
    selCmnAuthGrpList,
    selCmnAuthViewList,
    selCmnAuthViewJsonList,
    insCmnAuthGrpViewMap,
    selCmnAuthGrpEmpList,
    selCmnEmpInfo,
    selCmnItmCd,
    insCmnItmCd,
    delCmnItmCd,
}