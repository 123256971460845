<template>
    <v-sheet class="ma-0 pa-7" rounded="md">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col cols="4">
                <span class="text-h4 font-weight-bold">조편성&nbsp;관리</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="1">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </v-col>
        </v-row>
        <v-divider class="mt-5 mr-2"></v-divider>
        <v-row class="greyE01 pa-0 mr-2" no-gutters>
            <v-col class="d-flex justify-center align-center tableBR py-1" cols="2">
                <span class="black--text font-weight-medium">조명</span>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBR py-1" cols="4">
                <span class="black--text font-weight-medium">설명</span>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBR py-1" cols="4">
                <span class="black--text font-weight-medium">직원</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-1" cols="2">
                <span class="">
                    <v-btn class="" color="blue200" height="26" @click="addRow" icon>
                        <v-icon>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                </span>
            </v-col>
        </v-row>
        <v-divider class="mr-2"></v-divider>
        <div v-if="grpList.length > 0">
            <v-row class="pa-0 mr-2" v-for="(item, j) in grpList" :key="j" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="d-flex justify-center align-center tableBR py-1" cols="2">
                    <span class="d-inline-block pa-1" style="width: 100%;">
                        <v-text-field
                            class="" height="28"
                            v-model="item.grpNm"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR py-1" cols="4">
                    <span class="d-inline-block pa-1" style="width: 100%;">
                        <v-text-field
                            class="" height="28"
                            v-model="item.dscp" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR px-1" cols="4">
                    <v-row class="ma-0 pa-0" no-gutters>
                        <v-col cols="10">
                            <span v-for="(emp, k) in item.empItm" :key="k" class="black--text">{{ emp.name }}, </span>
                        </v-col>
                        <v-col class="d-flex justify-right" cols="2">
                            <span class="" v-if="item.empGrpPk == 0">
                                <v-btn 
                                    class="ml-1 brown lighten-3 white--text" min-width="30" height="25"
                                    @click="menu.splice(0, 1, true), sltGrpList=j"
                                    style="padding: 0px 1px 0px 2px !important" small>
                                    <v-icon size="20">mdi-account-multiple</v-icon>
                                </v-btn>
                            </span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="d-flex justify-center align-center py-1" cols="2">
                    <span>
                        <v-btn class="" color="blueBtnColor" height="26" @click="preEmpGrp(j)" icon>
                            <v-icon>mdi-content-save-edit-outline</v-icon>
                        </v-btn>
                    </span>
                    <span v-if="item.empGrpPk == 0">
                        <v-btn @click="grpList.splice(j, 1)" icon>
                            <v-icon>mdi-minus-circle-outline</v-icon>
                        </v-btn>
                    </span>
                    <span v-else>
                        <v-btn @click="rechkModalTitle=item.grpNm, sltGrpList=j, menu.splice(1, 1, true)" icon>
                            <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                    </span>
                </v-col>
            </v-row>
        </div>
        <div v-else class="txtAlgnMid pt-2">
            <span class="grey006--text">※ 편성된 그룹이 존재하지 않습니다.</span>
            <v-divider class="mt-2"></v-divider>
        </div>
        <v-dialog v-model="menu[0]" max-width="700" content-class="round" persistent eager>
            <!-- 
                mdlTtl: 팝업 타이틀(누락시 = 참석자 선택), tabInfList: 활성화 탭 배열 ( '1': 수급자, '2': 직원 ),
                cd41, cd42: 직원 탭에서 사용하는 공통코드 (필수), intgrResList: Array - 팝업에서 선택된 목록
                mode: String - 단일 / 멀티 선택 ( '1': 단일, '2': 멀티, default: '2' )
                fixList: 선택을 제한할 목록, onClose: String - 결과 값 0 고정
                * fixList: 오브젝트에 다음 키를 추가 해야함 ( disChekr:boolean, checkr:String )
                ** disChekr: 체크 가능여부, checkr: 체크박스 모델
             -->
            <IntgrSlctn
                :mdlTtl="'직원 선택'" :tabInfList="['2']"
                :fixList="exclsList" :mode="'2'" :rtrInc="true" :rtrTyp="'1'"
                :cd41="$parent.$parent.$parent.$parent.$parent.$parent.vCd41"
                :cd42="$parent.$parent.$parent.$parent.$parent.$parent.vCd42"
                :cd65="$parent.$parent.$parent.$parent.$parent.$parent.vCd65"
                @intgrResList="getAplyList" @onClose="modalChanger">
            </IntgrSlctn>
        </v-dialog>
        <v-dialog v-model="menu[1]" max-width="500">
            <btnModalDelete :title="rechkModalTitle" @madalState="delModalAct"></btnModalDelete>  
        </v-dialog>
    </v-sheet>
</template>

<script>
import IntgrSlctn from '../commons/IntgrSlctn.vue';
import btnModalDelete from '../bnfcr/BnfcrDeleteConfirm.vue';

import { selEmpGrpList, insEmpGrp, delEmpGrp } from '../../api/emp.js';

export default {
    name: 'EmpGrpModal',

    props : {
   
    },
        
    components: {
        IntgrSlctn,
        btnModalDelete,
    },

    created: function(){
   
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getEmpGrpList()
        })
    },

    computed: {

    },

    watch:{

    },
    
    methods: {
        //직원 조 편성 관리 조회
        getEmpGrpList: function () {
            selEmpGrpList(this.$store.getters.getFcltyNum)
                .then((response) => ( this.getEmpGrpListAfter(response.data) ))
                .catch((error) => console.log('upload error /emp/selEmpGrpList : ' + error))
        },
        //직원 조 편성 저장
        setEmpGrp: function (obj) {
            insEmpGrp(obj)
                .then((response) => ( this.asyncResultAfter(response.data) ))
                .catch((error) => console.log('upload error /emp/insEmpGrp : ' + error))
        },
        //직원 조 편성 삭제
        removeEmpGrp: function (obj) {
            delEmpGrp(obj)
                .then((response) => ( this.asyncResultAfter(response.data) ))
                .catch((error) => console.log('upload error /emp/delEmpGrp : ' + error))
        },
        getEmpGrpListAfter: function (res) {
            res.forEach(item => {
                item.empItm.forEach(el => {
                    let tmp = el
                    tmp.disChekr = true
                    tmp.checkr = '2'
                    this.exclsList.push(el)
                });
            });

            this.grpList = res
        },
        asyncResultAfter: function (res) {
            if(res.statusCode == 200){
                this.clearForm()
                this.getEmpGrpList()
                this.snackControll([true, 2500, res.message, 'info'])
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        preEmpGrp: function (index) {
            let obj = Object.assign({}, this.grpList[index])
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm

            if(obj.grpNm != null && obj.grpNm != '' && obj.empItm.length > 0){
                this.setEmpGrp(obj)
            }
            else
                this.snackControll([true, 2500, '필수 항목을 입력해 주세요.', 'info'])
        },
        delEmpGrp: function (obj) {
            let tmp = Object.assign({}, obj)
            tmp.fcltyNum = this.$store.getters.getFcltyNum
            tmp.userNm = this.$store.getters.getUserNm

            this.removeEmpGrp(tmp)
        },
        addRow: function (){
            let obj = Object.assign({}, this.grpInfo)
            obj.empItm = []

            if(this.grpList.findIndex( v => v.empGrpPk == 0) == -1)
                this.grpList.push(obj)
        },
        delModalAct: function(res){
            if(res == 9990){    //OK
                this.delEmpGrp(this.grpList[this.sltGrpList])

                this.menu.splice(1, 1, false)
            }
            else if(res == 7)   //Cancel
                this.menu.splice(1, 1, false)

            this.sltGrpList = -1
        },
        getAplyList: function (arr){
            if(this.sltGrpList != -1){
                this.grpList[this.sltGrpList].empItm.splice(0)
                arr.forEach(ar => {
                    if(this.exclsList.findIndex( v => v.empPk == ar.empPk) == -1){
                        this.grpList[this.sltGrpList].empItm.push({ empPk: ar.empPk, name: ar.name, disChekr: false })
                    }
                });
            }
            this.sltGrpList = -1
        },
        clearForm: function () {
            this.sltGrpList = -1
            this.exclsList.splice(0)
            this.grpList.splice(0)
        },
        modalChanger: function(value) {
            this.menu.splice(value, 1, false)
        },
        onClose: function () {
            this.$emit('modalClose', '3')
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        menu: [false, false,],
        rechkModalTitle: '',
        sltGrpList: -1,
        exclsList: [],
        grpList: [],
        grpInfo: { empGrpPk: 0, grpNm: '', dscp: '', empItm: null, },
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>