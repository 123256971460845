<template>
    <v-sheet class="rounded-lg rounded-l-0 overflow-auto pa-2" color="" :min-width="areaWidth" :max-width="areaWidth">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="mx-2 mt-2 d-flex">
            <expandNowDatePicker :prtDate="sltDate" @nowDate="getDate"></expandNowDatePicker>
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn :class="list.class" height="30" :color="list.color"
                    :disabled="list.disabled" @click="onClickBtn(i)" samll rounded>
                    <v-icon small>{{list.icon}}</v-icon>
                    <span style="font-size: 1rem;">{{list.text}}</span>
                </v-btn>
            </span>
            <span v-for="(list, j) in outlinedBtn" :key="filledBtn.length + j">
                <v-btn :disabled="list.disabled"
                    :class="list.class" @click = "onPrtBtn()"
                    samll height="30" :color="list.color" rounded outlined>
                    <v-icon small>{{list.icon}}</v-icon>
                    {{list.text}}
                </v-btn>
            </span>  
        </div>
        <v-divider class="my-2"></v-divider>
        <div class="" style="position:relative;">
            <div class="d-flex align-center" style="position: absolute; top: 8px; left: 686px; z-index: 1;">
                <v-icon small>mdi-checkbox-blank-circle-outline</v-icon>
                <span class="font-weight-medium" style="font-size: 0.875rem;">&#58;&nbsp;양호&#44;&nbsp;&nbsp;</span>
                <v-icon small>mdi-triangle-outline</v-icon>
                <span class="font-weight-medium" style="font-size: 0.875rem;">&#58;&nbsp;보통&#44;&nbsp;&nbsp;</span>
                <v-icon small>mdi-close</v-icon>
                <span class="font-weight-medium" style="font-size: 0.875rem;">&#58;&nbsp;불량</span>
            </div>
        </div>
        <div>
            <v-tabs
                v-model="tab" class="tab-dft-Theme" active-class="black"
                center-active hide-slider>
                <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class" :disabled="item.enter">{{item.tab}}</v-tab>
            </v-tabs>

            <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>

            <v-tabs-items v-model="tab">
                <v-tab-item eager transition="fade-transition">
                    <HygInsLog ref="hygInsLog"></HygInsLog>
                </v-tab-item>
                <v-tab-item eager transition="fade-transition">
                    <SntDsnLog ref="sntDsnLog"></SntDsnLog>
                </v-tab-item>
            </v-tabs-items>
        </div>
        <v-dialog v-model="menu[0]" max-width="500">
            <btnModalDelete :title="delTitle" @madalState="delModalAct"></btnModalDelete>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { getMultiStcd } from '../../api/index.js';

import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import expandNowDatePicker from '../../components/commons/expandNowDatePicker.vue';
import HygInsLog from '../../components/sfty/HygInsLog.vue';
import SntDsnLog from '../../components/sfty/SntDsnLog.vue';

export default {
    name: 'FoodHygnMgmt',
    
    components: {
        btnModalDelete,
        expandNowDatePicker,
        HygInsLog,
        SntDsnLog,
    },

    props : {

    },

    created: function(){
        this.getCode(['41', '42', '48', '65'])

        for (let index = 0; index < this.outlinedBtn.length; index++) {
            this.dialog.push(false)      
        }
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.areaWidth = 1260 - Number(this.$parent.$parent.midAreaWidth)
            
            let timezoneOffset = new Date().getTimezoneOffset() * 60000
            let timezoneDate = new Date(Date.now() - timezoneOffset)
            this.sltDate = timezoneDate.toISOString().split('T',2)[0].afterDateFormatHyp()

            this.$refs.hygInsLog.getInspRecDtllist()
            this.$refs.sntDsnLog.getInspRecDtllist()
            if(this.$parent.$parent.selectedItem != -1)
                this.tabItems[1].enter = false
        })
    },

    computed: {
        getStrtDate: function () {
            return this.$parent.$parent.date.startDay
        },
        getSltInspRecPk: function () {
            return this.$parent.$parent.sltInspRecPk
        },
        getSelectedItem: function () {
            return this.$parent.$parent.selectedItem
        },
    },  

    watch: {
        getStrtDate: function (value) {

            let fDate = new Date(value.beforeDateFormatHyp())
            let nDate = new Date(this.$parent.$parent.sltDate.beforeDateFormatHyp())
            let lDate = new Date(this.$parent.$parent.date.endDay.beforeDateFormatHyp())

            if(fDate <= nDate && nDate <= lDate) this.$parent.$parent.sltDate = this.sltDate
            else {
                fDate.setDate(fDate.getDate()+3)
                this.sltDate = fDate.toISOString().split('T',2)[0].afterDateFormatHyp()
            }

        },
        getSltInspRecPk: function (value) {
            if(value == 0){
                this.btnControll(1, true, 'fill')
                this.btnControll(0, true, 'out')
                this.$refs.hygInsLog.cntnt = ''
                this.$refs.hygInsLog.wrtr = ''
                this.$refs.hygInsLog.clearForm()
                //this.$refs.sntDsnLog.getInspRecDtllist(0)
            }
            else if(value != 0){
                this.btnControll(1, false, 'fill')
                this.btnControll(0, false, 'out')

                let tmp = this.$parent.$parent.sftyList.filter(v => v.inspRecPk == value)
                //let pkS = this.$parent.$parent.sftyList.findIndex(v => v.inspRecPk == value)
                this.$refs.hygInsLog.cntnt = tmp[0].cntnt1
                this.$refs.hygInsLog.getInspRecDtllist()
                //this.$refs.sntDsnLog.getInspRecDtllist(this.$parent.$parent.sftyList[pkS].inspRecPkS)
            }
        },
        getSelectedItem: function (value) {
            if(value == -1){
                this.btnControll(0, true, 'fill')
                this.tabItems[1].enter = true
                this.tab = 0
            }
            else{
                this.btnControll(0, false, 'fill')
                this.tabItems[1].enter = false
            }
        },
    },

    methods: {
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        stCdMapping: function (res) {
            this.vCd41 = res.vCd41
            this.vCd42 = res.vCd42
            this.vCd48 = res.vCd48
            this.vCd65 = res.vCd65
        },
        getDate: function (value) {
            this.$parent.$parent.sltDate = value.date
            this.sltDate = value.date

            let fIndex = this.$parent.$parent.sftyList.findIndex(v => v.inspDt == value.date.beforeDateFormatDot())
            if(fIndex != -1){
                let pkkk = this.$parent.$parent.sftyList[fIndex].inspRecPk

                if(pkkk == null)
                    this.$parent.$parent.sltInspRecPk = 0
                else
                    this.$parent.$parent.sltInspRecPk = pkkk

                this.$parent.$parent.selectedItem = value.date.beforeDateFormatDot()
            }
            else{
                this.$parent.$parent.selectedItem = value.date.beforeDateFormatDot()
            }

            let nDate = new Date(value.date.beforeDateFormatHyp())
            nDate.setDate(nDate.getDate() + 1)

            this.nextDate = nDate.toISOString().split('T',2)[0].afterDateFormatHyp()
        },
        onLoad: function () {
            let sch = this.$parent.$parent.sftyList.findIndex(v => v.inspDt == this.$parent.$parent.selectedItem)
            this.$refs.hygInsLog.getInspRecDtllist()
            if(sch != -1){
                let pkkk = this.$parent.$parent.sftyList[sch].inspRecPkS
                this.$refs.sntDsnLog.getInspRecDtllist(pkkk)
            }
        },
        //위생점검 출력
        onPrtBtn:function(){
            let obj = {}
            obj.rptTyp = '1'
            obj.mode = '2'
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnMmbrPk = null            
            obj.prtClcd = '1'
            obj.inqClcd = 2
            obj.prtCd = '64'
            obj.inqYYYY = null
            obj.inqYmdStart = this.$parent.$parent.date.startDay
            obj.inqYmdEnd = this.$parent.$parent.date.endDay
            obj.list = [ { bzClcd: [], bzPk: [] } ]
            
            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: obj, outObj: null, runEvt: true,
            });
        },
        clearForm: function () {
        },
        btnControll: function (index, dis, type) {
            switch (type) {
                case 'out':
                    this.outlinedBtn[index].disabled = dis
                    break;
                case 'fill':
                    this.filledBtn[index].disabled = dis
                    break;
                default:
                    break;
            }
        },
        delModalAct: function (res) {
            let pkS = this.$parent.$parent.sftyList.findIndex(v => v.inspDt == this.$parent.$parent.selectedItem)
            this.delTitle = ''
            if(res == 9990){    //OK
                if(this.tab == 0)
                    this.$refs.hygInsLog.removeInspRecDtl()
                else if(this.tab == 1)
                    this.$refs.sntDsnLog.removeInspRecDtl(this.$parent.$parent.sftyList[pkS].inspRecPkS)
                this.menu.splice(0, 1, false)
            }
            else if(res == 7)   //Cancel
                this.menu.splice(0, 1, false)
        },
        onClickBtn: function (index) {
            //let pkS = this.$parent.$parent.sftyList.findIndex(v => v.inspDt == this.$parent.$parent.selectedItem)
            switch(index) {
                case 0:     //저장
                    if(this.tab == 0)
                        this.$refs.hygInsLog.preInspRecDtllist()
                    else if(this.tab == 1)
                        this.$refs.sntDsnLog.preInspRecDtllist()
                    break;
                case 1:     //삭제
                    if(this.tab == 0)
                        this.delTitle = '위생점검일지'
                        //this.$refs.hygInsLog.removeInspRecDtl()
                    else if(this.tab == 1)
                        this.delTitle = '위생소독일지'
                        //this.$refs.sntDsnLog.removeInspRecDtl(this.$parent.$parent.sftyList[pkS].inspRecPkS)
                    this.menu.splice(0, 1, true)
                    break;
                default:
                    break;
            }
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    data: () => ({
        tab: 0,
        menu: [false],
        dialog: [],
        delTitle: '',
        sltDate: '',
        nextDate: '',
        vCd41: [],
        vCd42: [],
        vCd48: [],
        vCd65: [],
        areaWidth: 708,
        tabItems: [
            { index: 0, tab: '위생점검일지', class: 'fontOneDotTwoRem px-10', enter: false },
            { index: 1, tab: '위생소독일지', class: 'fontOneDotTwoRem px-10', enter: true },
        ],
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'white--text', color:'blueBtnColor', text: '저장', disabled: true, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', disabled: true, },
        ],
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '출력', width:'1000px', disabled: true, },
        ],
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>