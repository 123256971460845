<template>
    <v-sheet class="rounded-lg rounded-l-0" min-width="1260" max-width="1560" height="835" color="">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div> 
        <div class="px-2 mt-2 mr-2 d-flex align-center">
            <div class="text-h4">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">8-5. 월별 손익관리</span>
            </div>
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn v-if="list.type == 'action'" @click="onClickBtn(i)"
                    :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                    <v-icon small>{{list.icon}}</v-icon>
                    <span style="font-size: 1rem;">{{list.text}}</span>
                </v-btn>               
            </span>
            <span class="d-inline" v-for="(list, j) in outlinedBtn" :key="filledBtn.length+j">
                <v-dialog
                    v-model="dialog[filledBtn.length + j]" :max-width="list.width">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                :class="list.class" samll height="30" :color="list.color" rounded outlined>
                                <v-icon small>{{list.icon}}</v-icon>
                                <span style="font-size: 1rem;">{{list.text}}</span>
                            </v-btn>
                        </template>
                </v-dialog>
            </span>
        </div>
        <div class="mt-5">
            <expandMonthPicker :prtDate="''" @nowYearWithMonth="getPickerDate"></expandMonthPicker>
        </div>
        <div class="mt-2 px-2">
            <v-tabs
                v-model="tab" class="tab-dft-Theme" active-class="black"
                center-active hide-slider>
                <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class" :disabled="item.enter">{{item.tab}}</v-tab>
            </v-tabs>
            <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
            <v-tabs-items v-model="tab">            
                <v-tab-item eager transition="fade-transition">
                    <mnthlPrlssTab1 
                        ref="mnthlPrlssTab1"
                        @trigger="mnthlPrlssTrigger">
                    </mnthlPrlssTab1>
                </v-tab-item>
                <v-tab-item eager transition="fade-transition">
                    <mnthlPrlssTab2 
                        ref="mnthlPrlssTab2"
                        @trigger="mnthlPrlssTrigger">
                    </mnthlPrlssTab2>
                </v-tab-item>            
            </v-tabs-items>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[0]" persistent max-width="1100" min-width="1100">                
                <mnthlPrlssLosItmWrt
                    v-if="dialog[0]"
                    :tabNo="tab" 
                    :calcClcd = "clcd"
                    @isClose="isModal(0)"
                    @modalEvent="modalEvent"
                ></mnthlPrlssLosItmWrt>
            </v-dialog>
        </div> 
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[1]" persistent max-width="800" min-width="800">                
                <fcltyPrfLosDtlMdl
                    v-if="dialog[1]"
                    :yymm       = "yymm"
                    :itmCd      = "itmCd"
                    :itmNm      = "itmNm"
                    :stdClcd    = "stdClcd" 
                    :calcClcd   = "clcd"
                    @isClose="isModal(1)"
                    @modalEvent="modalEvent"
                    @setAmt="setDtlItmAmt" 
                ></fcltyPrfLosDtlMdl>
            </v-dialog>
        </div> 
    </v-sheet>
</template>
<script>
import expandMonthPicker from '../../components/commons/expandMonthPicker.vue';
import mnthlPrlssLosItmWrt from '../../components/impExp/MnthlPrlssLosItmWrt.vue';
import fcltyPrfLosDtlMdl from '../../components/impExp/FcltyPrfLosDtlMdl.vue';
import mnthlPrlssTab1 from '../../components/impExp/MnthlPrlssTab1.vue';
import mnthlPrlssTab2 from '../../components/impExp/MnthlPrlssTab2.vue';


export default {
    name: 'MnthlPrlssMgmt',
        
    components: {
        expandMonthPicker,
        mnthlPrlssLosItmWrt,
        mnthlPrlssTab1,
        mnthlPrlssTab2,
        fcltyPrfLosDtlMdl,
    },

    created: function(){
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
        })
    },

    watch:{
       'tab1Act':function(){
            this.chkLoding()
       },
       'tab2Act':function(){
            this.chkLoding()
       },
    },

    methods: {        
        chkLoding:function(){
            if(this.tab1Act == '1' && this.tab2Act == '1'){
                this.filledBtn[0].disabled  = false
                // this.outlinedBtn[0].disabled  = false
            }
        },
        onClickBtn: function (key) {
            switch (key) {
                case 0:
                    this.onSave()               
                    break;
                default:
                    break;
            }
        },
        onSave:function(){
            switch (this.tab) {
                case 0:
                    this.$refs.mnthlPrlssTab1.onSave()               
                    break;
                case 1:
                    this.$refs.mnthlPrlssTab2.onSave()               
                    break;
                default:
                    break;
            }
        },
        getPickerDate: function (date) {
            this.filledBtn[0].disabled  = true
            // this.outlinedBtn[0].disabled  = true
            this.yymm = date
            this.tab1Act    ='2'
            this.tab2Act    ='2'
        },        
        snackControll: function (options) {
            this.snackOptions = options
        },
        mnthlPrlssTrigger:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onModal:function(key){
            this.dialog.splice(key, 1, true)
        },
        isModal:function(key){
            this.dialog.splice(key, 1, false)
        },
        modalEvent:function(key){
            if(key == 99){
                this.$refs.mnthlPrlssTab1.onLoad()
                this.$refs.mnthlPrlssTab2.onLoad()
            }
        },
        setDtlItmAmt:function(obj){
            if(obj.stdClcd == '1'){
                this.$refs.mnthlPrlssTab1.setItmAmt(obj)
            } else if(obj.stdClcd == '2'){
                this.$refs.mnthlPrlssTab2.setItmAmt(obj)
            }
        }
    },
    data: () => ({   
        tab     : 0,   
        yymm    : '',
        tab1Act : '2',
        tab2Act : '2',
        stdClcd : '',
        clcd    : '',
        itmCd   : '',
        itmNm   : '',
        dialog:[false,false,], 
        tabItems: [
            { index: 0, tab: '청구기준', class: 'fontOneDotTwoRem px-15', enter:false},
            { index: 1, tab: '입출금기준', class: 'fontOneDotTwoRem px-15', enter:false},
        ],         
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type: 'action', width:'800px', disabled: false, },            
        ],
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '출력', width:'1000px', disabled: true, },
        ],
        snackOptions: [false, 3000, '', 'info'],
    })
}
</script>