<template>
    <v-sheet class="pa-0 ma-0">
         <v-row class="mt-2" no-gutters>
            <v-col cols="12" class="d-flex align-center pa-0 pm-0">
                <exBtn :btnData="exBtn[0]" @btnClosed="expandClose" ref="exBtnClose0" @changeCheck="filterSearchD1" />
                <exBtn class="ml-1" :btnData="exBtn[1]" @btnClosed="expandClose" ref="exBtnClose1" @changeCheck="filterSearchD2" />
                <exBtn class="ml-1" :btnData="exBtn[2]" @btnClosed="expandClose" ref="exBtnClose2" @changeCheck="filterSearchD3" />
                <span class="d-inline-block ml-2" style="width: 130px;">
                    <v-text-field class="ma-0 pa-0 nameSearch" background-color="white" append-icon="mdi-magnify" width="100px" height="35px"
                        hide-details single-line rounded outlined dense filled label="이름조회" @input="setSearchText">
                    </v-text-field>
                </span>                
                <expandYearOnlyPicker style="padding-left:120px;" @nowYear = "getYear"></expandYearOnlyPicker>
                <v-spacer></v-spacer>
                <v-btn
                    class="ml-1" color="blueBtnColor" style="padding: 13px 12px 12px 10px !important"
                    @click="onSndCctvMdl"       
                    min-width="30" height="25" small outlined>
                    <v-icon size="20">mdi-arrow-expand-right</v-icon>
                    <span class="fontOneRem ml-1" style="padding-bottom: 2px;">일괄서명요청</span>                    
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 mt-2" no-gutters>
            <v-col cols="12" class="pa-0 pm-0">
                <v-data-table
                    class="mt-2 stts" locale="ko" height="660" item-key="rn"
                    :search="schDummy" 
                    :custom-filter="customFilter" 
                    :headers="cctvBdrmHeaders" 
                    :items="cctvBdrmList"
                    fixed-header disable-pagination hide-default-footer dense>

                    <template v-slot:header="props" >
                        <thead>
                            <th v-for="(list, i) in props.headers" :key="i">{{list.text}}</th>
                        </thead>
                    </template>

                    <template v-slot:item='{ item }'>
                
                        <tr class="fixedCol">
                            <td class="fixedTable fixPstn0 zPstn2 txtAlgnMid fontOneRem removeBB">
                                <span>{{item.rn}}</span>
                                <span class="d-inline-block" style="width:18px"></span>
                            </td>
                            <td class="fixedTable fixPstn1 zPstn2 txtAlgnMid fontOneRem removeBB">
                                <span>{{item.name}}</span>
                                <span class="d-inline-block" style="width:18px"></span>
                            </td>
                            <td class="fixedTable fixPstn3 zPstn2 removeBB">       
                                <div class="d-flex justify-center align-center">
                                    <span>{{item.entStcd}}</span>
                                    <span class="d-inline-block" style="width:18px"></span>
                                </div>                        
                            </td> 
                            <td class="txtAlgnMid fontOneRem removeBB">
                                <span>{{item.age}}</span>
                                <span class="d-inline-block" style="width:22px"></span>
                            </td>
                            <td class="txtAlgnMid fontOneRem removeBB">
                                 <v-img class="ic-sex ml-1" v-if="item.gndr == 'F'" src="../../assets/icon/ic-female.svg"></v-img>
                                <v-img class="ic-sex ml-1" v-else-if="item.gndr == 'M'" src="../../assets/icon/ic-male.svg"></v-img>
                            </td>
                            <td class="txtAlgnMid fontOneRem removeBB">
                                <span>{{item.rsdntBirth?.beforeDateFormatDot()}}</span>
                                <span class="d-inline-block" style="width:18px"></span>
                            </td>
                            <td class="txtAlgnMid fontOneRem removeBB">
                                <span>{{item.lvnRmNm}}</span>
                                <span class="d-inline-block" style="width:18px"></span>
                            </td>
                            <td class="txtAlgnMid fontOneRem removeBB">
                                <span :class="'d-inline-block ic-level level0'+item.acknwRtng">
                                    {{ item.acknwRtng == '99' ? '외' : item.acknwRtng }}
                                </span>
                                <span class="d-inline-block" style="width:18px"></span>
                            </td>
                            <td class="txtAlgnMid fontOneRem removeBB">
                                <span>{{ item.agrYnNm }}</span>
                                <span class="d-inline-block" style="width:18px"></span>
                            </td>
                            <td class="txtAlgnMid fontOneRem removeBB">
                                <span>{{ item.wrtDt?.beforeDateFormatDot() }}</span>
                                <span class="d-inline-block" style="width:18px"></span>
                            </td>
                            <td class="txtAlgnMid fontOneRem removeBB">
                                <span v-if="item.grdnMmbrPk != null">{{ item.agrRcvNm }}</span>
                                <span v-else>{{ item.grdName }}</span>
                                <span class="d-inline-block" style="width:18px"></span>
                            </td>
                            <td class="txtAlgnMid fontOneRem removeBB">
                                 <span class="black--text" v-if="item.grdnMmbrPk != null">{{ item.agrRcvRltns }}</span>
                                 <span class="black--text" v-else>{{ item.rltns }}</span>
                            </td>
                            <td class="txtAlgnMid fontOneRem removeBB">
                                <span v-if="item.grdnMmbrPk != null">
                                    <span>{{item.celphn1}}</span>
                                    <span class="px-1">&#45;</span>
                                    <span>{{item.celphn2}}</span>
                                    <span class="px-1">&#45;</span>
                                    <span>{{item.celphn3}}</span>
                                </span>
                                <span v-else>
                                    <span>{{item.grdCelphn_1}}</span>
                                    <span class="px-1">&#45;</span>
                                    <span>{{item.grdCelphn_2}}</span>
                                    <span class="px-1">&#45;</span>
                                    <span>{{item.grdCelphn_3}}</span>
                                </span>
                            </td>
                            <td class="txtAlgnMid fontOneRem removeBB">
                                <span>
                                    <v-btn
                                        color="grey006" height="26"
                                        @click="$parent.$parent.$parent.$parent.cnsnFlmnPrt(item.bnMmbrPk, item.cctvBdrmInfPk, item.agrYyyy)"
                                        :disabled="item.cctvBdrmInfPk == null"
                                        icon>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                                    <v-icon size="20">mdi-printer-outline</v-icon>
                                                </div>
                                            </template>
                                            <span>CCTV촬영동의출력</span>
                                        </v-tooltip>
                                    </v-btn>
                                </span>   
                            </td> 
                        </tr>
                    </template>                    
                </v-data-table>
            </v-col>
        </v-row>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" max-width="1400">    
                <BnfcrCctvSndMdl v-if="dialog[0]" 
                    ref="bnfcrCctvSndMdl" 
                    :yyyy="year" 
                    :cd65="vCd65" :cd109="vCd109"
                    @isClose="isModal(0)"></BnfcrCctvSndMdl>  
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import { getMultiStcd } from '../../api/index.js';
import { selLvnRmInf } from '../../api/opr.js';
import { selAllBnfcrCctvList, insAllCctvBdrmInf } from '../../api/bnfcr.js';
import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';
import exBtn from '../../components/commons/expandButtonCustom.vue';
import BnfcrCctvSndMdl from '../../components/bnfcr/BnfcrCctvSndMdl.vue';


export default {
    name: 'BnfcrCctvBdrmAllMgmt',

    components: {
        expandYearOnlyPicker,
        exBtn,
        BnfcrCctvSndMdl,
    },

    created: function(){
        this.getCode(['62', '63', '65', '109'])
        this.getLvnRmInf()
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            
        })
    },

    computed: {
        
    },

    watch:{
        
    },
        
    methods: {
        onSndCctvMdl:function(){
            this.setAllCctvBdrmInf()
        },
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        getLvnRmInf: function () {
            selLvnRmInf(this.$store.getters.getFcltyNum)
                .then((response) => ( this.getLvnRmInfAfter(response.data) ))
                .catch((error) => console.log('connect error /opr/selLvnRmInf : ' + error))
        },
        getAllBnfcrCctvList: function (){
            selAllBnfcrCctvList(this.$store.getters.getFcltyNum, this.year)
                .then((response) => ( this.getAllBnfcrCctvListAfter(response.data) ))
                .catch((error) => console.log('upload error /bnfcr/selCctvBdrmList : ' + error))
        },     
        setAllCctvBdrmInf:function(){
            let lastYearDay = this.$moment(this.year+'1201','YYYYMMDD').endOf('month').format('YYYYMMDD')

            let obj = {
                fcltyNum    :this.$store.getters.getFcltyNum, 
                agrYyyy     :this.year, 
                agrDtEnd    :lastYearDay, 
                userNm      :this.$store.getters.getUserNm,
                wrtDt       :this.$moment().format('YYYYMMDD')
            }

            insAllCctvBdrmInf(obj)
                .then((response) => ( this.setAllCctvBdrmInfAfter(response.data) ))
                .catch((error) => console.log('upload error /bnfcr/insAllCctvBdrmInf : ' + error))
        },
        
        getAllBnfcrCctvListAfter:function(res){
            this.cctvBdrmList.splice(0)
  
            res.forEach(items => {
                
                this.vCd65.forEach(code => {
                    if(items.entStcd == code.valcd) items.entStcd = code.valcdnm
                });

                if(items.agrYn == '1'){
                    items.agrYnNm = '예'
                }else if(items.agrYn == '2'){
                    items.agrYnNm = '아니오'
                }


                this.cctvBdrmList.push(items)
            });
        },
        setAllCctvBdrmInfAfter:function(res){            
            if(res.statusCode == 200){   
                this.onModel(0)
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        stCdMapping: function (res) {

            res.vCd62.forEach((code) => {
                this.exBtn[2].expandList.push(code.valcdnm)
            });//end foreach
            res.vCd65.forEach((code) => {
                this.exBtn[0].expandList.push(code.valcdnm)
            });//end foreach
            
            this.vCd62 = res.vCd62
            this.vCd63 = res.vCd63
            this.vCd65 = res.vCd65
            this.vCd109 = res.vCd109
        },
        getLvnRmInfAfter: function (res){
            this.lvnRmInf = res

            this.lvnRmInf.forEach((code) => {
                this.exBtn[1].expandList.push(code.lvnRmNm)
            });//end foreach
        },
        getYear: function (yyyy){
            this.year = yyyy             
            this.getAllBnfcrCctvList()
        },
        expandClose: function (index) {
         
            switch(index) {
                case 0: this.$refs.exBtnClose0.isClose()
                    break;
                case 1: this.$refs.exBtnClose1.isClose()
                    break;
                case 2: this.$refs.exBtnClose2.isClose()
                    break;               
                default:
                    this.$refs.exBtnClose0.isClose()
                    this.$refs.exBtnClose1.isClose()
                    this.$refs.exBtnClose2.isClose()
                    break;
            }

        },
        filterSearchD1: function (sch) {
                
            if(sch.length == 0) this.schChkField1.push('')

            this.schChkField1 = []
            sch.forEach((item) => {
                this.schChkField1.push(item)
            });

            //배열 공백 제거
            this.schChkField1 = this.schChkField1.filter(function(item) {
                return item !== null && item !== undefined && item !== '';
            });
            //배열 중복 값 제거
            this.schChkField1 = this.schChkField1.filter((val, idx) => {
                return this.schChkField1.indexOf(val) === idx
            });

            this.schDummy = this.schDummy + '.'
        },

        filterSearchD2: function (sch) {
                
            if(sch.length == 0) this.schChkField2.push('')

            this.schChkField2 = []
            sch.forEach((item) => {
                this.schChkField2.push(item)
            });

            //배열 공백 제거
            this.schChkField2 = this.schChkField2.filter(function(item) {
                return item !== null && item !== undefined && item !== '';
            });
            //배열 중복 값 제거
            this.schChkField2 = this.schChkField2.filter((val, idx) => {
                return this.schChkField2.indexOf(val) === idx
            });

            this.schDummy = this.schDummy + '.'
        },

        filterSearchD3: function (sch) {
                
            if(sch.length == 0) this.schChkField3.push('')

            this.schChkField3 = []
            sch.forEach((item) => {
                this.vCd62.forEach((code) => {
                    if(item == code.valcdnm) this.schChkField3.push(code.valcd)
                });
            });

            //배열 공백 제거
            this.schChkField3 = this.schChkField3.filter(function(item) {
                return item !== null && item !== undefined && item !== '';
            });
            //배열 중복 값 제거
            this.schChkField3 = this.schChkField3.filter((val, idx) => {
                return this.schChkField3.indexOf(val) === idx
            });

            this.schDummy = this.schDummy + '.'
        },
        setSearchText: function (value) {
            this.schField = value
            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column

            let col0 = filter.name.includes(this.schField)
            //console.log('text : ' + this.schField)
            
            let col1 = true
            if(this.schChkField1.length != 0){
                this.schChkField1.forEach((stcd, i) => {
                    if(i == 0) col1 = filter.entStcd.includes(stcd)
                    else col1 = col1 || filter.entStcd.includes(stcd)
                    //console.log('array1 : ' + this.schChkField1)
                });
            }

            let col2 = true
            if(this.schChkField2.length != 0){
                this.schChkField2.forEach((lvnRm, i) => {
                    if(i == 0) col2 = filter.lvnRmNm == lvnRm
                    else col2 = col2 || filter.lvnRmNm == lvnRm
                    //console.log('array2 : ' + this.schChkField2)
                });
            }

            let col3 = true
            if(this.schChkField3.length != 0){
                this.schChkField3.forEach((acknwRtng, i) => {
                    if(i == 0) col3 = filter.acknwRtng.includes(acknwRtng)
                    else col3 = col3 || filter.acknwRtng.includes(acknwRtng)
                    //console.log('array3 : ' + this.schChkField3)
                });
            }

            return col0 && col1 && col2 && col3;
        },
        onModel:function(value){
            this.dialog.splice(value, 1, true)
        },
        isModal:function(value){
            this.dialog.splice(value, 1, false)
        }
        
    },
    
    data: () => ({
        year:'',
        vCd62: [],
        vCd63: [],
        vCd65: [],
        vCd109: [],
        lvnRmInf: [],
        schField: '',
        schDummy: '',
        schChkField1: [],
        schChkField2: [],
        schChkField3: [],
        dialog:[false,false],
        exBtn: [ 
            { index:  0, btnTitle: '입소현황', btnIcon: 'house', exWidth: 104, exHeigth: 130, expandList: [], },
            { index:  1, btnTitle: '생활실', btnIcon: 'bed', exWidth: 124, exHeigth: 100, expandList: [], },
            { index:  2, btnTitle: '등급', btnIcon: 'wheelchair', exWidth: 104, exHeigth: 194, expandList: [], },
        ],
        cctvBdrmHeaders: [
            { text: '연번', value: 'rn', sortable: true, width: '80', align: 'center', class: 'black--text py-4 greyE01 fontOneRem fixPstn0 zPstn3 bnfcrMidIndex'},
            { text: '수급자', value: 'name', sortable: true, width: '110', align: 'center', class: 'black--text greyE01 fontOneRem fixPstn1 zPstn3 bnfcrMidIndex'},
            { text: '현황', value: 'entStcd', sortable: true, width: '100', align: 'center', class: 'black--text greyE01 fontOneRem fixPstn3 zPstn3 bnfcrMidIndex'},
            { text: '나이', value: 'age', sortable: true, width: '80', align: 'center', class: 'black--text greyE01 fontOneRem'},            
            { text: '성별', value: 'gndr', sortable: true, width: '80', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '생년월일', value: 'rsdntBirth', sortable: true, width: '120', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '생활실', value: 'lvnRmNm', sortable: true, width: '110', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '등급', value: 'acknwRtng', sortable: true, width: '80', align: 'center', class: 'black--text greyE01 fontOneRem'},           
            { text: '침실촬영동의여부', value: 'agrYn', sortable: true, width: '170', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '작성일', value: 'wrtDt', sortable: true, align: 'center', width: '130', class: 'black--text greyE01 fontOneRem'},
            { text: '보호자', value: 'agrRcvNm', sortable: true, align: 'center', width: '130', class: 'black--text greyE01 fontOneRem'},
            { text: '관계', value: 'agrRcvRltns', sortable: false, width: '150', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '연락처', value: '', sortable: false, width: '170', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '처리', value: '', sortable: false, width: '100', align: 'center', class: 'black--text greyE01 fontOneRem'},
        ],
        cctvBdrmList:[]
    }),
}
</script>