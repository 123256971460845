<template>
    <v-sheet class="ma-0 pa-0" rounded="">
        <v-snackbar v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500"
            class="pa-0" :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
            <div style="text-align: center;">
                <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{ snackOptions[2] }}</span>
            </div>
        </v-snackbar>
        <v-form ref="form" lazy-validation>
            <v-row class="pa-0 ma-0" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;">
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="1">
                    <span class="black--text font-weight-medium">그룹명</span>
                </v-col>
                <v-col class="pa-1 ma-0 d-flex justify-center align-center" cols="2">
                    {{ items.bnfcrgrNm }}
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="1">
                    <span class="black--text font-weight-medium">그룹 설명</span>
                </v-col>
                <v-col class="pa-1 ma-0 d-flex justify-center align-center" cols="5" style="height: 100px; overflow-y: auto;">
                    {{ items.bnfcrgrDesc }}
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="2">
                    <span class="black--text font-weight-medium">대상 수급자수</span>
                </v-col>
                <v-col class="pa-1 ma-0 d-flex justify-center align-center" cols="1">
                    {{ items.bnfcrCnt }}
                </v-col>
            </v-row>
            <v-row class="pa-0 ma-0 mt-2" style="height: 40px;">
                <v-col class="align-center pa-0 ma-0" cols="7">
                    <span class=" hamletFont text-h6 black--text">
                        <v-icon color="black">mdi-chevron-right</v-icon>
                        <span>수급자&nbsp;그룹별&nbsp;수급자&nbsp;관리</span>
                    </span>
                    <v-row class="pa-0 ma-0 mt-2"
                        style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb; height: 40px;">
                        <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="2" style="height: 40px;">
                            <span class="black--text font-weight-medium">작성일</span>
                        </v-col>
                        <v-col class="d-flex align-center pa-0 ma-0" cols="4">
                            <!-- <span class="d-inline-block ml-1" style="width:60%;">
                                <v-menu ref="menu2" v-model="menu[0]" min-width="100" max-width="300"
                                    :close-on-content-click="false" :return-value.sync="wrtDt"
                                    transition="scale-transition" offset-y dense>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="wrtDt" :rules="[rules.required]" readonly hide-details
                                            outlined dense v-bind="attrs" v-on="on">
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="wrtDt" no-title scrollable
                                        :min="nowDate">
                                        <v-spacer></v-spacer>
                                        <v-btn class="font-weight-bold" color="blueBtnColor" text
                                            @click="isModal(0)">Cancel</v-btn>
                                        <v-btn class="font-weight-bold" color="blueBtnColor" text
                                            @click="$refs.menu2.save(wrtDt)">OK</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </span> -->
                            <span class="white d-inline-block py-1 ml-1" style="width: 154px;">
                                <CmmDateComp v-model="wrtDt" :required="true"></CmmDateComp>
                            </span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="2" style="height: 40px;">
                            <span class="black--text font-weight-medium">작성자</span>
                        </v-col>
                        <v-col class="d-flex align-center pa-0 ma-0" cols="4" style="height: 40px;">
                            <span class="d-inline-block ml-1" style="width: 50%;">
                                <v-text-field
                                    v-model="wrtr" color="blue" height="28" ref="info"
                                    :key="forceRender"
                                    readonly hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <v-btn class="ml-1 brown lighten-3 " small min-width="30" height="25" dark
                                style="padding:0px 1px 0px 2px !important" @click="onModal(1)">
                                <v-icon size="20">mdi-account-edit</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-data-table class="msclnTable mt-2" height="520" locale="ko" :headers="headers" :items="items1"
                        hide-default-header disable-pagination hide-default-footer dense>
                        <template v-slot:header="{ props }">
                            <thead style="">
                                <th class=" justify-center align-center greyE01 py-1"
                                    style="border-right: 1px solid #dbdbdb !important; width: 10%; border-top: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                    {{ props.headers[0].text }}</th>
                                <th class=" justify-center align-center greyE01"
                                    style="border-right: 1px solid #dbdbdb !important; width: 15%; border-top: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                    {{ props.headers[1].text }}</th>
                                <th class=" justify-center align-center greyE01"
                                    style="border-right: 1px solid #dbdbdb !important; width: 10%; border-top: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                    {{ props.headers[2].text }}</th>
                                <th class=" justify-center align-center greyE01"
                                    style="border-right: 1px solid #dbdbdb !important; width: 10%; border-top: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                    {{ props.headers[3].text }}</th>
                                <th class=" justify-center align-center greyE01"
                                    style="border-right: 1px solid #dbdbdb !important; width: 10%; border-top: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                    {{ props.headers[4].text }}</th>
                                <th class=" justify-center align-center greyE01"
                                    style="border-right: 1px solid #dbdbdb !important; width: 20%; border-top: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                    {{ props.headers[5].text }}</th>
                                <th class=" justify-center align-center greyE01"
                                    style="border-right: 1px solid #dbdbdb !important; width: 15%; border-top: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                    {{ props.headers[6].text }}</th>
                                <th class=" justify-center align-center greyE01"
                                    style="width: 10%; border-top: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                    <v-btn class="mr-1" color="blue200" icon height="26" :disabled="btnOnOff"
                                        @click="onModal(2)"><v-icon>mdi-plus-circle-outline</v-icon>
                                    </v-btn>
                                </th>
                            </thead>
                        </template>
                        <template v-slot:item='{ item, index }'>
                            <tr 
                                style="">
                                <td class="fixedCol0"
                                    style="height:40px !important; text-align: center; border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                    <span style="width:35%;">{{ index + 1 }}</span>
                                </td>
                                <td class="fixedCol0"
                                    style="height:40px !important; text-align: center; border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                    <span style="width:35%;">{{ item.name }}</span>
                                </td>
                                <td class="fixedCol0"
                                    style="height:40px !important; text-align: center; border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                    <span class="">
                                        <v-img class="ic-sex ml-3" v-if="item.gndr == '여'"
                                            src="../../assets/icon/ic-female.svg"></v-img>
                                        <v-img class="ic-sex ml-3" v-else-if="item.gndr == '남'"
                                            src="../../assets/icon/ic-male.svg"></v-img>
                                    </span>
                                </td>
                                <td class="fixedCol0"
                                    style="height:40px !important; text-align: center; border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                    <span style="width:35%;">{{ item.age }}</span>
                                </td>
                                <td class="fixedCol0 d-flex justify-center align-center"
                                    style="height:40px !important; text-align: center; border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                    <span :class="'ic-level level0'+item.acknwRtng">{{ item.acknwRtng }}</span>
                                </td>
                                <td class="fixedCol0"
                                    style="height:40px !important; text-align: center; border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                    <v-btn icon class=" rounded d-inline-block"
                                        @click="showDialog(0, item.bnMmbrPk, item.bnftOfrPlnHisPk)"><v-icon>mdi-square-edit-outline</v-icon></v-btn>
                                </td>
                                <td class="fixedCol0"
                                    style="height:40px !important; text-align: center; border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                    <span style="width:35%;">{{ item.wrtDt }}</span>
                                </td>
                                <td class="fixedCol0"
                                    style="height:40px !important; text-align: center; border-bottom: 1px solid #dbdbdb !important;">
                                    <span style="width:9%;">
                                        <v-btn class="mr-1" color="grey006" icon height="26" v-if="item.bnfcrgrBnfcrPk > 0"
                                            @click="onDelete(index)"><v-icon>mdi-trash-can-outline</v-icon>
                                        </v-btn>
                                        <v-btn class="mr-1" color="grey006" icon height="26" v-else
                                            @click="onDelete1(index)"><v-icon>mdi-minus-circle-outline</v-icon>
                                        </v-btn>
                                    </span>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
                <v-col class="pl-4 ma-0 py-0" cols="5">
                    <span class=" hamletFont text-h6 black--text">
                        <v-icon color="black">mdi-chevron-right</v-icon>
                        <span>관련&nbsp;프로그램</span>
                    </span>
                    <v-data-table class="mt-2" height="560" locale="ko" 
                        :headers="headers1" :items="items3"
                        fixed-header
                        disable-pagination 
                        hide-default-header
                        hide-default-footer dense>
                        <template v-slot:header="{ props }">
                            <thead style="">
                                <th class="black--text clmsFixedHeader tableBT tableBR" style="width: 20%;">
                                    {{ props.headers[0].text }}</th>
                                <th class="black--text clmsFixedHeader tableBT tableBR" style="width: 40%;">
                                    {{ props.headers[1].text }}</th>
                                <th class="black--text clmsFixedHeader tableBT tableBR" style="width: 45%;">
                                    {{ props.headers[2].text }}</th>
                                <th class="black--text clmsFixedHeader tableBT py-2" style="width: 5%;">
                                    <v-btn color="blue200" icon height="26" :disabled="btnOnOff"
                                        @click="onModalType()"><v-icon>mdi-plus-circle-outline</v-icon>
                                    </v-btn>
                                </th>
                            </thead>
                        </template>
                        <template v-slot:item='{ item, index }'>
                            <tr>
                                <td class="clmsBodyCommon tableBR">
                                    <span>{{ item.rn }}</span>
                                </td>
                                <td class="clmsBodyCommon tableBR">
                                    {{ item.prgTypNm }}
                                </td>
                                <td class="clmsBodyCommon tableBR">
                                    <div style="font-size:0.875rem !important">{{ item.prgNm }}</div>
                                </td>
                                <td class="clmsBodyCommon">
                                    <span style="width:9%;">
                                        <v-btn class="mr-1" color="grey006" icon height="26" v-if="item.bnfcrgrPrgPk > 0"
                                            @click="onDelete2(index)"><v-icon>mdi-trash-can-outline</v-icon>
                                        </v-btn>
                                        <v-btn class="mr-1" color="grey006" icon height="26" v-else
                                            @click="onDelete3(index)"><v-icon>mdi-minus-circle-outline</v-icon>
                                        </v-btn>
                                    </span>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>


            </v-row>
        </v-form>
        <v-dialog v-model="dialogTab[0]" persistent max-width="1600" min-width="1600">
            <BnftSrvPlnComp 
                v-if="dialogTab[0]"
                ref="bnftSrvPln" 
                :btnDisabled="true" 
                :bnMmbrPk="bnMmbrPk" 
                :inqYYYY="year"
                :bnftSrvPlnCmpPk="bnftSrvPlnCmpPk"
                @modalEvent="modalEvent"
            ></BnftSrvPlnComp>
        </v-dialog>
        <v-dialog v-model="menu[1]" eager content-class="round" persistent max-width="800">
            <EmpSelector @isClose="isModal(1)" @outEmp="getEmpInfo" :prtWrtDt="''"></EmpSelector>
        </v-dialog>        
        <v-dialog v-model="menu[2]" max-width="700" content-class="round" persistent eager>           
            <IntgrSlctn
                :mdlTtl="'수급자 선택'" :tabInfList="['1']" :fixList="fixList" mode="2"
                :cd41="vCd41"
                :cd42="vCd42"
                :cd65="vCd65"
                :prtWrtDt = "prtWrtDt"
                @intgrResList="setBnfcr" @onClose="isModal(2)">
            </IntgrSlctn>
        </v-dialog>
        <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
            <PrgrmMultiSelector ref="PrgrmMultiSelector" :prgChkItems="prgChkItems" :prgNoItems="prgNoItems" @isClose="isModalType" @reload="reset()" @outEmp="setprg"></PrgrmMultiSelector>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500">
            <btnModalDelete :title="'수급자 그룹별 수급자'" @madalState="onDeleteBtn"></btnModalDelete>
        </v-dialog>
        <v-dialog v-model="dialogDelete1" max-width="500">
            <btnModalDelete :title="'관련 프로그램'" @madalState="onDeleteBtn1"></btnModalDelete>
        </v-dialog>
    </v-sheet>
</template>

<script>
import IntgrSlctn from '../../components/commons/IntgrSlctn.vue';
import PrgrmMultiSelector from '../../components/prgrm/PrgrmMultiSelector.vue';
import BnftSrvPlnComp from '../../components/bnftSrv/BnftSrvPln.vue';
import EmpSelector from '../../components/commons/EmpSelector.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';
import { getMultiStcd } from '../../api/index.js';
import { selBnfcrBnftOfrPlnList } from '../../api/bnftoffer';
import { selBnfcrgrBnfcrInfo, selBnfcrgrBnfcrList, selBnfcrgrPrgList, insBnfcrgrBnfcrMulti, delBnfcrgrBnfcr, insMultiBnfcrgrPrg, delBnfcrgrPrg } from '../../api/prgrm.js';
export default {
    name: 'PrpBnfcrGrp',

    components: {
        EmpSelector,
        BnftSrvPlnComp,
        IntgrSlctn,
        PrgrmMultiSelector,
        btnModalDelete,
        CmmDateComp,

    },
    props: {
    },
    created: function () {
        this.year = new Date().getFullYear()
        this.onCodeload()
    },
    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
        })
    },
    computed: {
        getBnmmbrPk() {
            return this.$store.getters.getBnfcrState.bnmmbr
        }
    },
    watch: {
        prgBnfcrgrInfPk: function () {            
            if (this.prgBnfcrgrInfPk > 0) {
                this.btnOnOff = false
            } else {
                this.btnOnOff = true
            }
        },
        getBnmmbrPk: function (value) {
            this.items2 = Object.assign({})
            if (value > 0) {
                this.btnOnOff = false
                
            } else {
              
                this.btnOnOff = true
            }
            this.bnMmbrPk = value
            this.selBnfcrBnftOfrPlnList()
        },
    },
    methods: {
        onCodeload: function(){
            getMultiStcd(['40', '41', '42', '65'])
                .then((response) => ( this.codeMapping(response.data) ))
                .catch((error) => console.log(error))
        },

        codeMapping:function(res){
            this.vCd40 = res.vCd40
            this.vCd41 = res.vCd41
            this.vCd42 = res.vCd42
            this.vCd65 = res.vCd65
        },
        selBnfcrBnftOfrPlnList: function () {
            let obj = {
                bnMmbrPk: this.$store.getters.getBnfcrState.bnmmbr,
                clsfc1Cd: '201',
                clsfc2CdS: ['999'],
            }
            selBnfcrBnftOfrPlnList(this.$store.getters.getFcltyNum, obj)
                .then((response) => (this.selBnfcrBnftOfrPlnListAfter(response.data)))
                .catch((error) => console.log('connect error /bnfcr/selCnslnHisList : ' + error))
        },
        selBnfcrBnftOfrPlnListAfter: function (res) {
            if (res.length > 0) {
                this.items2 = res[0]
            }
        },
        selBnfcrgrBnfcrInfo: function () {
            selBnfcrgrBnfcrInfo(this.$store.getters.getFcltyNum, this.$parent.$parent.$parent.$parent.selectedItem1)
                .then((response) => (this.selBnfcrgrBnfcrInfoAfter(response.data)))
                .catch((error) => console.log('connect error /prg/selBnfcrgrBnfcrInfo : ' + error))
        },
        selBnfcrgrBnfcrInfoAfter: function (res) {
            this.items = res
            this.prgBnfcrgrInfPk = res.prgBnfcrgrInfPk
        },
        selBnfcrgrPrgList: function () {
            selBnfcrgrPrgList(this.$store.getters.getFcltyNum, this.$store.getters.getBnfcrState.bnmmbr, this.$parent.$parent.$parent.$parent.selectedItem1)
                .then((response) => (this.selBnfcrgrPrgListAfter(response.data)))
                .catch((error) => console.log('connect error /prg/selBnfcrgrPrgList : ' + error))
        },
        selBnfcrgrPrgListAfter: function (res) {
            this.items3 = res
        },
        selBnfcrgrBnfcrList: function () {
            selBnfcrgrBnfcrList(this.$store.getters.getFcltyNum, null, this.$parent.$parent.$parent.$parent.selectedItem1, null)
                .then((response) => (this.selBnfcrgrBnfcrListAfter(response.data)))
                .catch((error) => console.log('connect error /bnfcr/selCnslnHisList : ' + error))
        },
        selBnfcrgrBnfcrListAfter: function (data) {
            this.wrtDt = this.$moment().format('YYYY-MM-DD')

            if (data != '') {
                this.items1 = data;
                this.items1.forEach(e => {
                    if (e.wrtDt != null) {
                        e.wrtDt = e.wrtDt.beforeDateFormatHyp();
                        if (data.length > 0) {                            
                            this.wrtr = e.wrtr
                        }
                    }
                });
                this.items1.forEach(e => {
                    if (e.gndr != null) {
                        if (e.gndr == 'F') {
                            e.gndr = '여';
                        } else if (e.gndr == 'M') {
                            e.gndr = '남';
                        }
                    }
                });
            } else {
                this.items1 = Object.assign([])
            }
            

        },
        setPrpInf: function (pk) {
            this.selBnfcrgrBnfcrInfo(pk)
            this.selBnfcrgrBnfcrList(pk)
            this.selBnfcrgrPrgList(pk)
            if(pk==-1){
                this.wrtDt = ''
                this.wrtr = ''
                this.$refs.form.resetValidation()
            }
            // this.insBnfcrgrBnfcrMulti(pk)
        },
        onModal: function (key) {
            this.menu.splice(key, 1, true)
            if (key == 2) {
                
                if(this.wrtDt != ''){
                    this.prtWrtDt = this.$moment(this.wrtDt, 'YYYY-MM-DD').format('YYYYMMDD')
                }else{
                    this.prtWrtDt = ''
                }

                this.fixList.splice(0)
                this.items1.forEach(x => {
                    let obj = {                        
                        name:x.name,
                        mmbrpk:x.bnMmbrPk,
                        disChekr:true,
                        checkr:'1'
                    }
                    this.fixList.push(obj)
                })
            }
        },
        onModalType() {
            this.dialog.splice(0, 1, true)
            this.prgChkItems.splice(0)
            this.items3.forEach(x => {
                this.prgChkItems.push(x.prgInfPk)
            })
            this.$refs.PrgrmMultiSelector.onShow()
        },
        setBnfcr: function (bnfcrs) {
            let mmbrs = []
            this.items1.forEach(x => { mmbrs.push(x.bnMmbrPk) })

            bnfcrs.forEach(x => {
                let bl = mmbrs.includes(x.bnMmbrPk)
                if (!bl) {
                    if (x.gndr != null) {
                        if (x.gndr == 'F') {
                            x.gndr = '여';
                        } else if (x.gndr == 'M') {
                            x.gndr = '남';
                        }
                    }
                    let obj = {
                        bnMmbrPk: x.bnMmbrPk,
                        name: x.name,
                        lnryn: x.lnryn,
                        gndr: x.gndr,
                        age: x.age,

                    }

                    this.items1.push(obj)
                }
            })
        },
        getEmpInfo: function (obj) {
            this.wrtr = obj[0].name
        },
        isModal: function (key) {
            this.menu.splice(key, 1, false)
        },
        
        isModalType: function (event = 1) {
            if (event == 99) {
                this.onCodeload()
            } else {
                this.dialog.splice(0, 1, false)
            }
        },
        showDialog(tabkey, bMpk, hisPk) {
            this.bnMmbrPk = bMpk
            this.bnftSrvPlnCmpPk = hisPk
            this.dialogTab.splice(tabkey, 1, true)            
        },
        modalEvent: function (event) {
            if (event == 99) {
                this.getAllBnftList();
            } else {
                if(event==7){
                    this.bnftSrvPlnCmpPk = -1
                }
                this.dialogTab.splice(0, 1, false)
            }
        },
        onShow: function () {
            if (this.$refs.form.validate()) {
                this.insBnfcrgrBnfcrMulti()
                this.insMultiBnfcrgrPrg()
            } else {
                this.snackControll([true, 5000, '필수항목을 입력해주세요.', 'warning'])
            }
        },
        insMultiBnfcrgrPrg: function () {
            let obj = {
                list: [],
            }
            this.items3.forEach(e => {
                if (this.$refs.form.validate()) {
                    let item = {
                        prgBnfcrgrInfPk: this.prgBnfcrgrInfPk,
                        bnfcrgrPrgPk: e.bnfcrgrPrgPk,
                        prgInfPk: e.prgInfPk,
                        wrtDt: this.items.wrtDt?.afterDateFormatHyp(),
                        wrtr: this.items.wrtr,
                        userNm: this.$store.getters.getUserNm,
                        fcltyNum: this.$store.getters.getFcltyNum,
                    }
                    obj.list.push(item)
                }
            });
            insMultiBnfcrgrPrg(obj)
                .then((response) => (this.insMultiBnfcrgrPrgAfter(response.data)))
                .catch((error) => console.log('connect error prgrm/insPrgInf : ' + error))
        },
        insMultiBnfcrgrPrgAfter: function (res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.selBnfcrgrPrgList()
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        insBnfcrgrBnfcrMulti: function () {
            let pk = this.$parent.$parent.$parent.$parent.selectedItem1
            let obj = {
                list: [],
            }
            this.items1.forEach(e => {
                let item = {
                    bnMmbrPk: e.bnMmbrPk,
                    bnfcrgrBnfcrPk: e.bnfcrgrBnfcrPk,
                    fcltyNum: this.$store.getters.getFcltyNum,
                    prgBnfcrgrInfPk: pk,
                    userNm: this.$store.getters.getUserNm,
                    wrtDt: this.wrtDt?.afterDateFormatHyp(),
                    wrtr: this.wrtr,
                }
                if(e.bnfcrgrBnfcrPk == null || e.bnfcrgrBnfcrPk == undefined) obj.list.push(item)
            });

            if(obj.list.length > 0){
                insBnfcrgrBnfcrMulti(obj)
                    .then((response) => (this.insBnfcrgrBnfcrMultiAfter(response.data)))
                    .catch((error) => console.log('connect error /opr/insBnfcrgrBnfcrMulti : ' + error))
            }
        },
        insBnfcrgrBnfcrMultiAfter(res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.selBnfcrgrBnfcrList()
            }
            else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        onDelete(key) {
            if (key > -1) {
                this.key = key
                this.dialogDelete = true
            } else {
                if (key != 0) {
                    this.items1.splice(key, 1)
                }
            }
        },
        onDelete2(key) {
            if (key > -1) {
                this.key = key
                this.dialogDelete1 = true
            } else {
                if (key != 0) {
                    this.items3.splice(key, 1)
                }
            }
        },
        onDelete1(key) {
            this.items1.splice(key, 1)
        },
        onDelete3(key) {
            this.items3.splice(key, 1)
        },
        delBnfcrgrBnfcrAfter(res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.dialogDelete = false
                this.reset()
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        reset() {
            this.selBnfcrgrBnfcrList()
            this.selBnfcrgrPrgList()
        },
        setprg(data) {
            let prgAr = []
            this.items3.forEach(x => { prgAr.push(x.prgTypMngPk) })
            data.forEach(x => {
                let bl = prgAr.includes(x.prgTypMngPk)
                if (!bl) {
                    let obj = {
                        prgNm: x.prgNm,
                        prgTypMngPk: x.prgTypMngPk,
                        prgTypNm: x.prgTypNm,
                        prgInfPk: x.prgInfPk,
                        rn: x.rn,
                    }
                    this.items3.push(obj)
                }
            });
        },
        onDeleteBtn: function (res) {
            if (res == 9990) {
                if (this.items1[this.key].bnfcrgrBnfcrPk > 0) {
                    let obj = {
                        fcltyNum: this.$store.getters.getFcltyNum,
                        userNm: this.$store.getters.getUserNm,
                        bnfcrgrBnfcrPk: this.items1[this.key].bnfcrgrBnfcrPk,
                    }
                    delBnfcrgrBnfcr(obj)
                        .then((response) => (this.delBnfcrgrBnfcrAfter(response.data)))
                        .catch((error) => console.log(error))
                } else {
                    this.items1.splice(this.key, 1)
                }
            } else if (res == 7) {
                this.dialogDelete = false
            }
        },

        onDeleteBtn1: function (res) {
            if (res == 9990) {
                if (this.items3[this.key].bnfcrgrPrgPk > 0) {
                    let obj = {
                        fcltyNum: this.$store.getters.getFcltyNum,
                        bnfcrgrPrgPk: this.items3[this.key].bnfcrgrPrgPk,
                        userNm: this.$store.getters.getUserNm,
                    }
                    delBnfcrgrPrg(obj)
                        .then((response) => (this.delBnfcrgrPrgAfter(response.data)))
                        .catch((error) => console.log(error))
                } else {
                    this.items3.splice(this.key, 1)
                }
            } else if (res == 7) {
                this.dialogDelete1 = false
            }
        },
        delBnfcrgrPrgAfter(res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.dialogDelete1 = false
                this.reset()
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
    },
    data: () => ({
        prtWrtDt:'',
        vCd40: [],
        vCd41:[],
        vCd42:[],
        vCd65:[],  
        headers: [
            { text: '연번', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '수급자명', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '성별', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '나이', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '등급', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '급여제공계획', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '작성일', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
        ],
        headers1: [
            { text: '연번', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '프로그램 유형', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '프로그램명', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '처리', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
        ],
        rules: {
            required: value => !!value || 'Required.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            selCheck: value => !(value == '선택' || value == '') || 'Required.',
        },
        items: [],
        items1: [],
        items2: [],
        items3: [],
        menu: [false, false],
        dialogTab: [false],
        nowDate: '',
        forceRender: 0,
        bnMmbrPk: 0,
        year: 0,
        fixList:[],
        chkItems: [],
        noItems: [],
        prgChkItems: [],
        prgNoItems: [],
        snackOptions: [false, 3000, '', 'info'],
        dialog: [false],
        btnOnOff: true,
        prgBnfcrgrInfPk: 0,
        dialogDelete: false,
        dialogDelete1: false,
        key: 0,
        wrtDt: '',
        wrtr: '',
        bnftSrvPlnCmpPk: -1,
    }),

};
</script>

<style>
.inCustomStyleFocus2 {
    background-color: #777;
    color: #ddd;
    width: 60px;
}
</style>