<template>
    <v-sheet class="ma-0 pa-0" rounded="lg">
        <v-divider :class="['mt-2', fmlyList.length > 10 ? 'mr-2__5' : '']"></v-divider>
        <v-row :class="['greyE01 pa-0', fmlyList.length > 10 ? 'mr-2__5' : 'ma-0']" no-gutters>
            <v-col class="d-flex justify-center align-center tableBR" cols="1">
                <span class="d-inline-block ml-1" style="width: 26px;">
                    <v-checkbox
                        class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="allChckr" :key="forceRender" @click="allChnger"
                        true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                        hide-details>
                    </v-checkbox>
                </span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2 tableBR" cols="2">
                <span class="black--text font-weight-medium">보호자</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2 tableBR" cols="2">
                <span class="black--text font-weight-medium">수급자</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2 tableBR" cols="2">
                <span class="black--text font-weight-medium">현황</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2 tableBR" cols="2">
                <span class="black--text font-weight-medium">관계</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2" cols="3">
                <span class="black--text font-weight-medium">연락처</span>
            </v-col>
        </v-row>
        <v-divider :class="[fmlyList.length > 10 ? 'mr-2__5' : '']"></v-divider>
        <div class="d-inline-block overflow-y-auto" style="width: 100%; height: 401px;">
            <v-row class="ma-0 pa-0" style="border-bottom: 1px solid #dbdbdb;"
                v-for="(wList, i) in fmlyList" :key="i" no-gutters>
                <v-col class="d-flex justify-center align-center tableBR" cols="1">
                    <span class="d-inline-block ml-1" style="width: 26px;">
                        <v-checkbox
                            class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                            v-model="wList.checkr" :key="forceRender" :disabled="wList.disChekr" @change="allChekrFunc"
                            true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                            hide-details>
                        </v-checkbox>
                    </span>                    
                </v-col>
                <v-col class="d-flex justify-center align-center py-2 tableBR" cols="2">
                    <span class="black--text">{{ wList.name }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-2 tableBR" cols="2">
                    <span class="black--text">{{ wList.bnName }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-2 tableBR" cols="2">
                    <span class="black--text">{{ wList.entStcdNm }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-2 tableBR" cols="2">
                    <span class="black--text">{{ wList.rltns }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-2" cols="3">
                    <span class="black--text">{{wList.celphn}}</span>
                </v-col>
            </v-row>
        </div>
        <v-row class="pa-0" no-gutters>
            <v-col class="d-flex" cols="12">
                <v-spacer></v-spacer>
                <span class="d-inline-block" style="width: 94px;">
                    <v-checkbox
                        v-model="entChgBox" :ripple="false"
                        class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                        true-value="1" false-value="2" label="퇴소자 조회"
                        on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                        hide-details>
                    </v-checkbox>
                </span>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import {  getAllFmlyList } from '../../api/index.js';

export default {
    name: 'SmsSndFmlyList',

    props : {
        
    },
            
    components: {
        
    },

    created: function(){
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getAllFmlyList('99')
        })
    },

    computed: {

    },

    watch: {
        'entChgBox':function(val){
            if(val == '1'){
                this.getAllFmlyList('4')
            }else{
                this.getAllFmlyList('99')
            }
        }
    },
        
    methods: {      
         //보호자 정보 조회
        getAllFmlyList: function (stcd=null) {
            getAllFmlyList(this.$store.getters.getFcltyNum, 0, stcd)
                .then((response) => ( this.getAllFmlyListAfter(response.data) ))
                .catch((error) => console.log('upload error /bnfcr/selAllFmlyList : ' + error))
        },       
        getAllFmlyListAfter: function (res) {  
            this.allChckr =  '2'
            this.fmlyList.splice(0)
            this.forceRender += 1

            res.forEach(item => {
                item.disChekr = false
                item.checkr = '2'
                item.dtTyp = 'fmly'
                item.celphn = (item.celphn_1 != '' && item.celphn_2 != '' && item.celphn_3 != '')?item.celphn_1+'-'+item.celphn_2+'-'+item.celphn_3:''
                this.$parent.$parent.$parent.$parent.vCd65.forEach((cd) => {
                    if(item.entStcd == cd.valcd) item.entStcdNm = cd.valcdnm
                })
                this.fmlyList.push(item)
            });
        },
        allChnger: function () {
            if(this.allChckr == '1') {
                this.fmlyList.forEach(item => {
                    if(item.disChekr == false) item.checkr = '1'
                });
            }
            else if(this.allChckr == '2') {
                this.fmlyList.forEach(item => {
                    if(item.disChekr == false) item.checkr = '2'
                });
            }
        },
        allChekrFunc: function () {
            let tCh = '1'
            this.fmlyList.forEach(el => {
                if(el.checkr == '2') tCh = '2'
            });
            this.allChckr = tCh
        },        
        chngInfrm: function (exChekrList) {
            this.fmlyList.forEach(items => {
                items.checkr = '2'
                items.disChekr = false
            })
            
            exChekrList.forEach(item => {
                this.fmlyList.forEach(items => {
                    if(item.mmbr == items.mmbrPk){
                        items.checkr = '1'
                        items.disChekr = true
                    }
                });
            });
        },
        fltrThrw: function () {
            let arr = this.fmlyList.filter(v => v.disChekr == false)

            return arr.filter(v => v.checkr == '1')
        },
    },
    
    data: () => ({
        fmlyList: [],
        vCd65:[],
        allChckr: '2',
        forceRender: 0,
        entChgBox:'2'
    }),
};
</script>