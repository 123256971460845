<template>
    <v-sheet>
        <v-form ref="tabform" lazy-validation>
            <div class="d-flex">                       
                <div class="d-inline-block pa-0 align-self-start" style="width:50%;">
                    <div class="d-flex pa-0 ma-0" no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                        
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:15%;">                                
                            <span class="black--text font-weight-medium">구분</span>
                        </div>
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:75%;border-left: 1px solid #dbdbdb !important;">                                
                            <span class="black--text font-weight-medium">내용</span>
                        </div>
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">                                
                            <span class="black--text font-weight-medium">점수</span>
                        </div>
                    </div>                    
                    <div v-for="(item,i) in groups1" :key="i" class="d-flex pa-0 ma-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:15%;">                                
                            <span class="black--text font-weight-medium">
                                {{item.itmNm}}<br/>
                                {{item.itmDesc}}
                            </span>                            
                        </div>
                        <div class="pa-0 ma-0" style="width:75%;border-left: 1px solid #dbdbdb !important;">
                            <div class="pl-1 ma-0">                                   
                                <v-radio-group
                                    class="radio-dft-Theme mt-0"
                                    v-model="itmVal[item.itmCd]" :rules="[rules.required]" hide-details row>
                                    <span v-for="(itm, j) in codegroups1[item.itmCd]" :key="j" >
                                        <v-radio class="pa-0 mr-1" style="width:495px" 
                                            :ripple="false" :label="itm.itmNm" :value="itm.itmVal"
                                            @click="setScore()"
                                        ></v-radio>
                                    </span>
                                </v-radio-group>   
                            </div>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:10%;border-left: 1px solid #dbdbdb !important;">
                            {{itmVal[item.itmCd]}}
                        </div>
                    </div>
                </div>
                <div class="d-inline-block pa-0 ml-3 align-self-start" style="width:50%;">
                    <div class="d-flex pa-0 ma-0" style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                       
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:15%;">                                
                            <span class="black--text font-weight-medium">구분</span>
                        </div>
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:75%;border-left: 1px solid #dbdbdb !important;">                                
                            <span class="black--text font-weight-medium">내용</span>
                        </div>
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">                                
                            <span class="black--text font-weight-medium">점수</span>
                        </div>
                    </div>
                    <div v-for="(item,i) in groups2" :key="i" class="d-flex pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:15%;">                                
                            <span class="black--text font-weight-medium">
                                {{item.itmNm}}<br/>
                                {{item.itmDesc}}
                            </span>                            
                        </div>
                        <div class="pa-0 ma-0" style="width:75%;border-left: 1px solid #dbdbdb !important;">
                            <div class="pl-1 ma-0">                                   
                                <v-radio-group
                                    class="radio-dft-Theme mt-0"
                                    v-model="itmVal[item.itmCd]" :rules="[rules.required]" hide-details row>
                                    <span v-for="(itm, j) in codegroups2[item.itmCd]" :key="j" >
                                        <v-radio class="pa-0 mr-1" style="width:495px" 
                                            :ripple="false" :label="itm.itmNm" :value="itm.itmVal"
                                            @click="setScore()"
                                        ></v-radio>
                                    </span>
                                </v-radio-group>   
                            </div>
                        </div>
                        <div class="pa-1 ma-0 d-flex justify-center align-center" style="width:10%;border-left: 1px solid #dbdbdb !important;"> 
                            {{itmVal[item.itmCd]}}
                        </div>
                    </div>
                    <div class="d-flex pa-0 ma-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">  
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:15%">                                
                            <span class="black--text font-weight-medium">총점</span>
                        </div>                    
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:75%;border-left: 1px solid #dbdbdb !important;">                                
                            6개 항목에 대해서 0점 부터 5점까지 배점<br />
                            총점 : 0점(완전독립수행)-30점(완전도움의존)
                        </div>
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0 red--text" style="width:10%;border-left: 1px solid #dbdbdb !important;"> 
                            {{totscore}}
                        </div>
                    </div>
                    <div class="d-flex pa-0 ma-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:15%">                                
                            <span class="black--text font-weight-medium">평가</span>
                        </div>
                        <div class="d-flex justify-start align-center pa-1 ma-0" style="width:85%;border-left: 1px solid #dbdbdb !important;">                                
                             <v-textarea v-model="evltn" class="rmrksArea" no-resize  
                                rows="12" outlined dense hide-details>
                            </v-textarea> 
                        </div>
                    </div>
                    <!-- <div class="pa-0 my-1 " no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0">                                
                            <span class="black--text font-weight-medium">총평</span>
                        </div>
                        <div class="d-flex justify-start align-center pa-1 ma-0" style="">                                                           
                            <v-textarea v-model="totevltn" class="rmrksArea" no-resize  
                                rows="3" outlined dense hide-details>
                            </v-textarea>
                        </div>
                    </div> -->
                </div>
            </div>
        </v-form>    
    </v-sheet>
</template>
<script>
import { insBnfcrExamHis} from '../../api/bnftSrv.js';

export default {
    name: 'PhyscThrpyEvltnTab1',

    props : {
        
    },

    computed: {
    },  

    watch:{        
        
    },

    created:function(){  
        
    },

    components: {
    },
    
    mounted: function(){  
    },
        
    methods: {    
        onShow(){
        },
        setStyle:function(value){
            let style = "";
            if(value > 13){
                style="width:200px;"
            }else if(value > 10 && value <= 13){
                style="width:160px;"
            }else if(value > 5 && value <= 10){
                style="width:130px;"
            }else if(value == 2){
                style="width:90px;"
            }else{
                style="width:100px;"
            }
            return style;
        },   
        
        onClose: function () {
            this.$emit('modalClose', this.dsrEvltn)
        },


        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },

        setData(data){  
            this.itmVal.splice(0)
            this.totevltn = ''

            this.data = data
            this.data.map(x=>{
                x.itmValCd = x.valClcd
                if(x.clsfc2Cd == "7"){
                    if(x.clsfc3Cd =='999'){
                        this.evltn = x.evltn
                    }else{
                        if(x.itmVal != undefined && x.itmVal != ''){
                            this.itmVal[x.itmCd] = x.itmVal
                        }
                    }
                }else if(x.clsfc2Cd == "999"){
                    this.totevltn = x.evltn
                }
            })
            this.setScore()
            this.$refs.tabform.resetValidation();
        },

        reset(){
            this.$refs.tabform.reset()
        },

        insBnfcrExam(data){
            if(this.$refs.tabform.validate()){
                //수급자평가 내역 setting

                let obj = {
                    fcltyNum:this.$store.getters.getFcltyNum,
                    userNm:this.$store.getters.getUserNm,
                    bnMmbrPk:data.bnMmbrPk,
                    bnfcrExamHisPk:data.bnfcrExamHisPk,
                    examClcd:'106',                    
                    examRsncd : data.examRsncd,
                    examDt : data.examDt.afterDateFormatHyp(),
                    wrtr : data.wrtr,
                    examYyyy : data.inqYYYY,
                }

                //setting
                let examList = []
                this.data.map(x=>{
                    if(x.clsfc2Cd == "7"){
                        if(x.clsfc3Cd =='999'){
                            x.evltn = this.evltn
                        }else{
                            x.itmVal = this.itmVal[x.itmCd]
                        }
                    }else if(x.clsfc2Cd == "999"){
                         x.evltn = this.totevltn
                    }

                    examList.push(x)
                });  

                insBnfcrExamHis(obj,examList)
                .then((response) => ( this.insBnfcrExamAfter(response.data)))
                .catch((error) => console.log(error))
            }
        },
        insBnfcrExamAfter: function (res) {
            this.$emit('physcThrpyEvltnTrigger', res)            
        },    

        selExamList:function(){
             //setting
            let examList = []
            this.data.map(x=>{
                if(x.clsfc2Cd == "7"){
                    if(x.clsfc3Cd =='999'){
                        x.evltn = this.evltn
                    }else{
                        x.itmVal = this.itmVal[x.itmCd]
                    }
                }else if(x.clsfc2Cd == "999"){
                        x.evltn = this.totevltn
                }

                examList.push(x)
            });  
            return examList
        },
        
        setScore(){
            this.totscore = 0
            this.itmVal.forEach((value) => {
                let val = Number(value)
                this.totscore += val
            })
            
        },
    },
    
    data: () => ({        
        data:[],
        obj:{},
        itmVal:[],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' || value == undefined) || 'Required.',
        },        
        totscore:0,
        evltn:'',
        totevltn:'',
        groups1:[
            {itmCd:'1',itmNm:'1. 대소변',itmDesc:''},
            {itmCd:'2',itmNm:'2. 식사',itmDesc:''},
            {itmCd:'3',itmNm:'3. 복장',itmDesc:''},
            {itmCd:'4',itmNm:'4. 개인위생',itmDesc:'(머리빗기, 양치질, 면도, 손발톱관리, 세면하기등)'},
        ],
        groups2:[
            {itmCd:'5',itmNm:'5. 보행',itmDesc:'(계단, 이동)'},
            {itmCd:'6',itmNm:'6. 목욕하기',itmDesc:''},
        ],
        codegroups1: {
                '1':[
                    {itmVal:'0',itmNm:'0: 화장실을 완벽하게 사용할 수 있으며, 실금 현상이 전혀 없다.'},
                    {itmVal:'1',itmNm:'1: 대소변을 볼 때 도움이 필요하며 가끔은 실금 현상이 있다.'},
                    {itmVal:'2',itmNm:'2: 1주일에 1회 이상 수면 중 대소변을 지리기도 한다.'},
                    {itmVal:'4',itmNm:'4: 1주일에 1회 이상 낮 시간에 대소변을 지리기도 한다.'},
                    {itmVal:'5',itmNm:'5: 대소변을 전혀 조절하지 못한다.'},
                ],
                '2':[
                    {itmVal:'0',itmNm:'0: 도움 없이 혼자서 먹을 수 있다.'},
                    {itmVal:'1',itmNm:'1: 식사중이나 특별한 음식을 먹을 때 약간의 도움이 필요하거나 식후 위생을 누군가 도와주어야 한다.'},
                    {itmVal:'2',itmNm:'2: 다른 사람의 중등고의 도움을 받아 식사하며 지저분하게 식사한다.'},
                    {itmVal:'4',itmNm:'4: 모든 식사를 다른 사람이 많이 도와주어야 한다.'},
                    {itmVal:'5',itmNm:'5: 스스로는 식사하지 못해 다른 사람이 먹여주어야 한다.'},
                ],
                '3':[
                    {itmVal:'0',itmNm:'0: 스스로 입고 벗을 수 있으며 자신의 옷장에서 옷을 고를 수 있다.'},
                    {itmVal:'1',itmNm:'1: 옷이 미리 골라져 있다면 입고 벗을 수 있다.'},
                    {itmVal:'2',itmNm:'2: 미리 준비된 옷이라도 다른 사람이 약간 도와주어야 입을 수 있다.'},
                    {itmVal:'4',itmNm:'4: 옷을 입을 때 많이 도와주어야 하는데, 협조 할 수 있다.'},
                    {itmVal:'5',itmNm:'5: 전혀 스스로는 옷을 입을 수 없으며, 다른 사람이 입혀줄 때도 있다.'},
                ],
                '4':[
                    {itmVal:'0',itmNm:'0: 다른 사람의 도움 없이도 항상 단정하게 옷 입고 몸치장을 할 수 있다.'},
                    {itmVal:'1',itmNm:'1: 적절한 몸치장을 스스로 할 수 있으나 면도 같은 것들은 도움을 필요로 한다.'},
                    {itmVal:'2',itmNm:'2: 몸치장에 다른 사람들의 도움과 규칙적인 감독을 필요로 한다.'},
                    {itmVal:'4',itmNm:'4: 다른 사람들이 전적으로 몸치장을 도와주어야 하는데 일단 몸치장을 한 다음에는 깨끗하게 유지할 수 있다.'},
                    {itmVal:'5',itmNm:'5: 몸치장을 하고 유지하는데 다른 사람들이 적극적으로 도와주어야 한다.'},
                ],
                            
            },
            codegroups2: {
                '5':[
                    {itmVal:'0',itmNm:'0: 외출하여 스스로 걸어 다닐 수 있다.'},
                    {itmVal:'1',itmNm:'1: 실내와 실외에서 걸어 다닐 수 있다.'},
                    {itmVal:'2',itmNm:'2: 다른 사람의 도움을 받거나 walker, wheelchair등을 이용하여 움직일 수 있다'},
                    {itmVal:'4',itmNm:'4: 의자나 휠체어에 앉아 있을 수는 있는데 다른 사람의 도움 없이 움직일 수 없다'},
                    {itmVal:'5',itmNm:'5: 하루의 반 이상을 침대에 누운 상태로 지낸다.'},
                ],
                '6':[
                    {itmVal:'0',itmNm:'0: 스스로 도움 없이 목욕할 수 있다.'},
                    {itmVal:'1',itmNm:'1: 탕에 들어가고 나오는 것을 도와주면 혼자 목욕할 수 있다.'},
                    {itmVal:'2',itmNm:'2: 얼굴과 손은 쉽게 씻지만 몸의 나머지 부분은 씻지 않는다.'},
                    {itmVal:'4',itmNm:'4: 스스로 씻지는 못하나 다른 사람들이 목욕시킬 때 협조는 할 수 있다.'},
                    {itmVal:'5',itmNm:'5: 스스로 씻으려는 노력을 전혀 하지 않으며 다른 사람들이 씻어 주려해도 저항한다.'},
                ],      
            }


    }),
};
</script>