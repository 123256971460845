<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" min-height="700">        
        <div class="d-flex justify-left align-center mb-2 ">
            <span class="text-h4 font-weight-bold">신체제재 작성 및 통지</span>
            <v-spacer></v-spacer>            
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <div class="d-flex justify-left align-center mb-2">
            <v-spacer></v-spacer>
            <v-btn v-for="(list, i) in filledBtn" :key="i"  @click="onClickBtn(i)"
                :class="list.class" height="30" :color="list.color" rounded
                :disabled="list.disabled"
                style="font-size: 14px;">
                <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                {{list.text}}
            </v-btn>            
        </div>
        <v-form ref="mform" lazy-validation>
            <v-row class="pa-0 mt-2" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">수급자</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pl-2 py-1 ma-0" cols="2">{{bnfcrInfo.name}}</v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">성별</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pl-2 py-1 ma-0" cols="2">
                    <span class="">
                        <v-img class="ic-sex" v-if="bnfcrInfo.gndr == '여'" src="../../assets/icon/ic-female.svg"></v-img>
                        <v-img class="ic-sex" v-else-if="bnfcrInfo.gndr == '남'" src="../../assets/icon/ic-male.svg"></v-img>
                    </span>
                    <span class="ml-1">&#40;{{bnfcrInfo.age}}&#41;</span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">                            
                    <span class="black--text font-weight-medium">등급</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pl-2 py-1 ma-0" cols="2">
                    <span :class="'ic-level level0'+bnfcrInfo.acknwRtng">{{bnfcrInfo.acknwRtng}}</span>
                </v-col>
            </v-row>
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">제재기간</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="10">
                    <span class="white d-inline-block" style="width: 154px;border-radius: 10px;"> 
                        <CmmDateComp v-model="items.snctDtStrt" :maxDt="items.snctDtEnd" :required="true"></CmmDateComp>                                                  
                    </span>
                    <span class="px-1">&#126;</span>
                    <span class="white d-inline-block" style="width: 154px;border-radius: 10px;">    
                        <CmmDateComp v-model="items.snctDtEnd" :minDt="items.snctDtStrt" :required="true"></CmmDateComp>                                               
                    </span>
                </v-col>            
            </v-row>
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <div>
                        <div class="d-flex justify-center black--text font-weight-medium">수급자상태</div>
                        <div class="d-flex justify-center black--text font-weight-medium">&#40;제재사유&#41;</div>
                    </div>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="10">
                    <v-textarea v-model="items.snctRsn" class="rmrksArea" style="font-size:0.9rem !important;"
                        :rules="[rules.required]"
                        rows="5" outlined dense hide-details no-resize>
                    </v-textarea>
                </v-col>            
            </v-row>
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <div>
                        <div class="d-flex justify-center black--text font-weight-medium">신체구속의방법</div>
                        <div class="d-flex justify-center black--text font-weight-medium">&#40;제재방법&#41;</div>
                    </div>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="10">
                    <v-textarea v-model="items.snctMthd" class="rmrksArea" style="font-size:0.9rem !important;"
                        :rules="[rules.required]"
                        rows="5" outlined dense hide-details no-resize>
                    </v-textarea>
                </v-col>            
            </v-row>
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <div>
                        <div class="d-flex justify-center black--text font-weight-medium">구속시간</div>
                        <div class="d-flex justify-center black--text font-weight-medium">&#40;제재시간&#41;</div>
                    </div>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="10">
                    <v-textarea v-model="items.snctTmCntnt" class="rmrksArea" style="font-size:0.9rem !important;"
                        rows="5" outlined dense hide-details no-resize>
                    </v-textarea>
                </v-col>            
            </v-row>
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <div>
                        <div class="d-flex justify-center black--text font-weight-medium">특기해야 할</div>
                        <div class="d-flex justify-center black--text font-weight-medium">심신의 상황</div>
                    </div>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="10">
                    <v-textarea v-model="items.spclNote" class="rmrksArea" style="font-size:0.9rem !important;"
                        rows="5" outlined dense hide-details no-resize>
                    </v-textarea>
                </v-col>            
            </v-row>
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <div>
                        <div class="d-flex justify-center black--text font-weight-medium">구속해제의</div>
                        <div class="d-flex justify-center black--text font-weight-medium">사유조건</div>
                    </div>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="10">
                    <v-textarea v-model="items.rlsRsn" class="rmrksArea" style="font-size:0.9rem !important;"
                        rows="5" outlined dense hide-details no-resize>
                    </v-textarea>
                </v-col>            
            </v-row>
        </v-form>
        <div class="ma-0 pa-0">
            <v-dialog eager v-model="dialog[0]" max-width="700">    
                <grdnNtcModel 
                    ref="grdnNtcModel"                   
                    @isClose="isModal(0)"
                    @modalEvent="physcSRtrigger"
                    ></grdnNtcModel>                  
            </v-dialog>   
        </div>
    </v-sheet>
</template>
<script>
import CmmDateComp from '../commons/CmmDateComp.vue';
import grdnNtcModel from './GrdnNtcModel.vue';
import { selPhysSnctInfo, insPhysSnct } from '../../api/bnftrcrd.js';

export default {
    name: 'PhyscSnctnRcrdWrt',
    
    components: {
        CmmDateComp,
        grdnNtcModel,
    },

    props : {
        bnfcrInfo:{type:Object, default:new Object}
    },

    created: function(){       
      
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            
        })
    },

    computed: {
       
    },  

    watch: {
       
    },

    methods: {        
        snackControll: function (options) {
            this.snackOptions = options
        },
        onClickBtn:function(key){
           switch (key) {
            case 0:
                this.dialog.splice(key, 1, true) 
                console.log(this.items.physSnctPk)
                this.$refs.grdnNtcModel.onLoad('6',this.items.physSnctPk,this.bnfcrInfo.bnMmbrPk,'1')                
                break;
            case 1:
                this.onSave()
                break;
           
            default:
                break;
           }
        },
        onClose: function () {
            this.$emit('isClose', 0)
            this.$refs.mform.reset()
            this.items.physSnctPk = 0
            this.items.bnMmbrPk = 0
            this.filledBtn[0].disabled = true
            this.$refs.mform.resetValidation()
        },
        getPhysSnctInfo:function(value){
            let gFcltyNum = this.$store.getters.getFcltyNum                
            selPhysSnctInfo(gFcltyNum,value)
                .then((response) => ( this.getPhysSnctInfoAfter(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/selPhysSnctInfo : ' + error))
        },
        getPhysSnctInfoAfter:function(res){
            this.filledBtn[0].disabled = false
            res.snctDtStrt = res.snctDtStrt.beforeDateFormatHyp()
            res.snctDtEnd = res.snctDtEnd.beforeDateFormatHyp()
            this.items = res  
        },
        onSave:function(){
            let gFcltyNum = this.$store.getters.getFcltyNum
            let gBnMmbrPk = this.bnfcrInfo.bnMmbrPk
            let gUserNm = this.$store.getters.getUserNm

            if(gBnMmbrPk > 0 && this.$refs.mform.validate()){
                let obj = JSON.parse(JSON.stringify(this.items))
                
                obj.fcltyNum = gFcltyNum
                obj.bnMmbrPk = gBnMmbrPk
                obj.userNm = gUserNm
                obj.wrtr = gUserNm
                obj.snctDtStrt = obj.snctDtStrt.afterDateFormatHyp()
                obj.snctDtEnd = obj.snctDtEnd.afterDateFormatHyp()
                insPhysSnct(obj)
                        .then((response) => ( this.onSaveAfter(response.data) ))
                        .catch((error) => console.log('upload error /bnftrcrd/insPhysSnct : ' + error))
            }
        },
        onSaveAfter:function(res){

            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)            
            obj.title =res.message                      // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200){                                                
                obj.type = 'success'
                this.$emit('modalEvent', 99);
                // this.getPhysSnctInfo(res.result)
                this.items.physSnctPk = res.resultPk
                this.filledBtn[0].disabled = false
            }else{
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

            }
            this.$store.commit('setWrnnInf', obj)
        },
        isModal: function (key) {
            this.dialog.splice(key, 1, false)
        },          
        physcSRtrigger: function (event) { 
            if(event == 99){
                this.$emit('modalEvent', 99);
            }     
        },  
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
    },    
    data: () => ({
        forceRender:0,
        snackOptions: [false, 3000, '', 'info'],
        menu:[false,false,],
        dialog:[false,],
        items:{
            physSnctPk:0,
            bnMmbrPk:0,
            fcltyNum:'',
            snctDtStrt:'',
            snctDtEnd:'',
            snctTmHhStrt:'',
            snctTmMmStrt:'',
            snctTmHhEnd:'',
            snctTmMmEnd:'',
            snctRsn:'',
            snctTmCntnt:'',
            snctMthd:'',
            spclNote:'',
            rlsRsn:'',
            wrtr:'',
            userNm:'',
        },
        filledBtn: [            
            { icon: 'mdi-bullhorn-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '보호자통지', disabled: true, },
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', disabled: false, },
        ],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
    }),
};
</script>