<template>
    <v-sheet>        
        <div>
            <div class="d-flex greyE01 pa-0 mr-2__5" style="line-height: 1.2;">
                <div class="d-flex justify-center align-center tableBT tableBB pa-1" style="width:10%;" >
                    <exBtn class="" :btnData="exBtn[0]" @btnClosed="expandClose" ref="exBtnClose0" @changeCheck="filterSearch"></exBtn>
                    <span class="black--text ml-1 fontNineThRem font-weight-medium">장기요양 필요영역</span>
                </div>
                <div class="tableBT tableBL" style="width:90%;">
                    <div class="d-flex">
                        <div class="d-flex py-2 justify-center align-center tableBB" style="width:35%">
                            <span class="black--text font-weight-medium">장기요양 세부목표</span>
                        </div>
                        <div class="tableBL" style="width:65%">
                            <div class="d-flex tableBB">
                                <div class="d-flex py-2 justify-center align-center" style="width:20%">
                                    <span class="black--text font-weight-medium">장기요양 필요내용</span>
                                </div>
                                <div class="tableBL d-flex py-2 justify-center align-center" style="width:59%">
                                    <span class="black--text font-weight-medium">세부 제공내용</span>
                                </div>
                                <div class="tableBL d-flex py-2 justify-center align-center" style="width:7%">
                                    <span class="black--text font-weight-medium">주기</span>
                                </div>
                                <div class="tableBL d-flex py-2 justify-center align-center" style="width:7%">
                                    <span class="black--text font-weight-medium">횟수</span>
                                </div>
                                <div class="tableBL d-flex py-2 justify-center align-center" style="width:7%">
                                    <span class="fontNineThRem black--text font-weight-medium">시간(분)</span>
                                </div>
                                <div class="tableBL d-flex py-2 justify-center align-center" style="width:10%">
                                    <span class="black--text font-weight-medium">작성자</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="height:455px;overflow-y:scroll;" class="ma-0 pa-0">
                <div v-if="listPln.length > 0">
                    <div v-for="(item, i) in listPln"  :key="i">
                        <div v-if="(srhObj != '') ? (srhObj.findIndex(x=>x == item.clsfc2Cdnm) != -1) : true" class="d-flex pa-0 ma-0">
                            <div class="d-flex justify-center align-center tableBB pa-1" style="width:10%;" >
                                <span class="black--text font-weight-medium">{{item.clsfc2Cdnm}}</span>
                            </div>
                            <div class="tableBL" style="width:90%;">
                                <div class="d-flex" v-for="(list,j) in item.list" :key="j" >
                                    <div class="d-flex py-1 justify-center align-center tableBB" style="width:35%;font-size:1rem !important;">
                                        {{list.dtldGls}}
                                    </div>
                                    <div class="tableBL" style="width:65%">
                                        <div class="d-flex tableBB" v-for="(subitm,k) in list.subList" :key="k">
                                            <div class="d-flex py-2 justify-center align-center" style="width:20%">
                                                {{subitm.clsfc3Cdnm}}
                                            </div>
                                            <div class="tableBL d-flex py-2 justify-center align-center" style="width:59%;font-size:1rem !important;">
                                                <div class="px-1">{{subitm.itmCdnm}}</div>
                                            </div>
                                            <div class="tableBL d-flex py-2 justify-center align-center" style="width:7%">
                                                {{subitm.itmVal1}}
                                            </div>
                                            <div class="tableBL d-flex py-2 justify-center align-center" style="width:7%">
                                                {{subitm.itmVal2}}
                                            </div>
                                            <div class="tableBL d-flex py-2 justify-center align-center" style="width:7%">
                                                {{subitm.itmVal3}}
                                            </div>
                                            <div class="tableBL d-flex py-2 justify-center align-center" style="width:10%">
                                                {{subitm.wrtr}}
                                            </div>
                                        </div>
                                    </div>
                                </div>                            
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else style="height:400px;overflow-y:auto;">
                    <div class="d-flex pa-0 ma-0">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;height:380px;" >
                            <span class="black--text font-weight-medium">※ 목록이 없습니다.</span>
                        </div>
                    </div>
                </div>
            </div>
            <v-divider class="mr-2__5"></v-divider>
        </div>                    
    </v-sheet>
</template>
<script>
import exBtn from '../commons/expandButtonCustom.vue';

export default {
    name: 'BnftSrvPlnTab1',

    props : {
        inqYYYY: Number
    },

    computed: {        
    },  

    components: {
        exBtn
    },
    
    mounted: function(){
    },
        
    methods: {   
        onShow(){                     
        },
        setStyle:function(value){
            let style = "";
            if(value > 1){
                style=""            
            }else{
                style="height:100% !important;"
            }
            return style;
        },   
        selBnftOfrPlnListAfter(data){   
            this.reset()
            this.exBtn[0].expandList.splice(0)

            data.outList.forEach(el => {
                el.visible = true
                this.listPln.push(el)
                this.exBtn[0].expandList.push(el.clsfc2Cdnm)
            });

        },
        getBnfcrExamSmryListAfter:function(res){
            this.dsrEvltn = ''
            res.forEach(el => {
                this.dsrEvltn = el.itmVal?.replaceAll("\n", "<br />")
            });
        },
        onClose: function () {
            this.$emit('modalClose', this.dsrEvltn)
        },

        reset(){
            this.cmnts = ''  
            this.listPln.splice(0)
        },

        close(){
            this.srhObj.splice(0)
            this.exBtn[0].expandList.splice(0)
            this.reset()            
        },          
        expandClose: function (index) {
            switch(index) {
                case 0: 
                    break;
                case 1: this.$refs.exBtnClose0.isClose()
                    break;
                default: this.$refs.exBtnClose0.isClose()
                    break;
            }
        },
        filterSearch:function(srh){  
            this.srhObj = srh       
        }
    },
    
    data: () => ({ 
        dsrEvltn:'',
        groups:[],
        tmpgroups:[],
        listPln:[{list:[{subList:[{listDtl:[]}]}]}],
        srhObj:[],
        cmnts:'', 
        exBtn: [
            {
                index:  0,
                btnTitle: '',
                btnIcon: 'listChecks',
                exWidth: 154,
                exHeigth: 300,
                expandList: [],
            },
        ],             
    }),
};
</script>