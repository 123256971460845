<template>
    <v-row class="ma-0 pa-0" no-gutters>
        <v-spacer></v-spacer>
        <v-col class="" cols="9">
            <div class="d-flex">
                <span
                    class="d-inline-block rounded-lg rounded-r-0 elevation-7 py-6 px-7"
                    style="width: 50%; height: 720px; background-color: white; position: relative; z-index: 1;">
                    
                    <v-row class="ma-0 pa-0" no-gutters>
                        <v-col class="" cols="1">
                            <v-btn @click="$parent.$parent.$parent.authStep=0" icon>
                                <v-icon class="pt-1" color="black" size="30">mdi-chevron-left</v-icon>
                            </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col class="d-flex justify-center align-center" cols="6">
                            <span v-if="sltAgr == 0" class="font-weight-bold" style="font-size: 1.4rem;">이용약관</span>
                            <span v-else-if="sltAgr == 1" class="font-weight-bold" style="font-size: 1.4rem;">개인정보 수집 및 이용동의</span>
                            <span v-else-if="sltAgr == 2" class="font-weight-bold" style="font-size: 1.4rem;">안내자료 수신동의</span>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="1"></v-col>
                    </v-row>
                    <v-row class="mt-2 pl-4" no-gutters>
                        <v-col cols="12">
                            <div class="overflow-y-auto d-inline-block pr-1" style="width: 100%; height: 600px;">
                                <SrvcPolicies v-if="sltAgr == 0"></SrvcPolicies>
                                <PrvcPlcy v-else-if="sltAgr == 1"></PrvcPlcy>
                            </div>
                        </v-col>
                    </v-row>

                </span>
                <span
                    class="d-inline-block rounded-lg rounded-l-0 elevation-7 py-6 px-12"
                    style="width: 50%; height: 720px; background-color: #faf8fd; position: relative;">
                    
                    <v-row class="mt-1 pa-0" no-gutters>
                        <v-col class="d-flex align-center" cols="12">
                            <span class="font-weight-bold" style="font-size: 2rem;">회원가입</span>
                        </v-col>
                    </v-row>
                    <v-row class="mt-7 pa-0" no-gutters>
                        <v-col class="d-flex align-center" cols="12">
                            <span class="fontOneRem">
                                회원가입 전, 요양SYS 약관들을 확인해주세요.
                            </span>
                        </v-col>
                    </v-row>
                    <v-row class="mt-16 pa-0" no-gutters>
                        <v-col class="" cols="12">
                            <v-card
                                class="px-4 py-2" rounded="md" elevation="0" outlined>
                                <v-row class="ma-0 pa-0" no-gutters>
                                    <v-col class="d-flex align-center" cols="12">
                                        <v-checkbox
                                            class="dftChcBox-Theme authAgr fontTwoRem pt-0" style="margin-top: 0px !important;"
                                            v-model="$parent.$parent.$parent.mmbrInfo.trmsAgrYn" @click="allRflChc()"
                                            true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                            hide-details>
                                        </v-checkbox>
                                        <span class="grey006--text font-weight-medium ml-4">전체동의</span>
                                        <v-icon class="ml-4 mr-2 greyC00--text" size="16" style="padding-top: 1px;">mdi-minus</v-icon>
                                        <span class="greyC00--text fontOneRem">필수 항목에 모두 동의합니다.</span>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class="mt-16 pa-0" no-gutters>
                        <v-col class="" cols="12">
                            <v-card
                                class="pl-4 pr-2 py-2" rounded="md" elevation="0" outlined>
                                <v-row class="ma-0 pa-0" no-gutters>
                                    <v-col class="d-flex align-center" cols="12">
                                        <v-checkbox
                                            class="dftChcBox-Theme authAgr fontTwoRem pt-0" style="margin-top: 0px !important;"
                                            v-model="reqList[0]" @change="rflChc()"
                                            true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                            hide-details>
                                        </v-checkbox>
                                        <span class="grey006--text font-weight-medium ml-4">이용약관 동의 (필수)</span>
                                        <v-spacer></v-spacer>
                                        <v-btn @click="sltAgr=0" icon>
                                            <v-icon style="padding-left: 3px; padding-top: 1px;">mdi-chevron-right</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class="mt-3 pa-0" no-gutters>
                        <v-col class="" cols="12">
                            <v-card
                                class="pl-4 pr-2 py-2" rounded="md" elevation="0" outlined>
                                <v-row class="ma-0 pa-0" no-gutters>
                                    <v-col class="d-flex align-center" cols="12">
                                        <v-checkbox
                                            class="dftChcBox-Theme authAgr fontTwoRem pt-0" style="margin-top: 0px !important;"
                                            v-model="reqList[1]" @change="rflChc()"
                                            true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                            hide-details>
                                        </v-checkbox>
                                        <span class="grey006--text font-weight-medium ml-4">개인정보 수집 및 이용동의 (필수)</span>
                                        <v-spacer></v-spacer>
                                        <v-btn @click="sltAgr=1" icon>
                                            <v-icon style="padding-left: 3px; padding-top: 1px;">mdi-chevron-right</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                    <!-- <v-row class="mt-3 pa-0" no-gutters>
                        <v-col class="" cols="12">
                            <v-card
                                class="pl-4 pr-2 py-2" rounded="md" elevation="0" outlined>
                                <v-row class="ma-0 pa-0" no-gutters>
                                    <v-col class="d-flex align-center" cols="12">
                                        <v-checkbox
                                            class="checkIcon authAgr" style="font-size: 2rem;" v-model="reqList[2]" @change="rflChc()"
                                            true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                            hide-details>
                                        </v-checkbox>
                                        <span class="grey006--text font-weight-medium ml-4">서비스 이용약관 (필수)</span>
                                        <v-spacer></v-spacer>
                                        <v-btn icon>
                                            <v-icon style="padding-left: 3px; padding-top: 1px;">mdi-chevron-right</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row> -->
                    <v-row class="mt-16 pa-0" no-gutters>
                        <v-col class="" cols="12">
                            <v-card
                                class="pl-4 pr-2 py-2" rounded="md" elevation="0" outlined>
                                <v-row class="ma-0 pa-0" no-gutters>
                                    <v-col class="d-flex align-center" cols="12">
                                        <v-checkbox
                                            v-model="$parent.$parent.$parent.mmbrInfo.ntcArgYn"
                                            class="dftChcBox-Theme authAgr fontTwoRem pt-0" style="margin-top: 0px !important;"
                                            true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                            hide-details>
                                        </v-checkbox>
                                        <span class="grey006--text font-weight-medium ml-4">안내자료 수신동의 (선택)</span>
                                        <v-spacer></v-spacer>
                                        <v-btn @click="sltAgr=2" icon>
                                            <v-icon style="padding-left: 3px; padding-top: 1px;">mdi-chevron-right</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class="mt-16 pa-0" no-gutters>
                        <v-col class="mt-6" cols="12">
                            <v-btn class="py-6" width="100%" color="#3688c1"
                            :disabled="$parent.$parent.$parent.mmbrInfo.trmsAgrYn == '2'"
                            @click="$parent.$parent.$parent.authStep = 2">
                                <span class="white--text" style="font-size: 1.3rem;">다음 단계로</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </span>
            </div>
        </v-col>
        <v-spacer></v-spacer>
    </v-row>
</template>

<script>
import SrvcPolicies from './SrvcPolicies.vue';
import PrvcPlcy from './PrvcPlcy.vue';

export default {
    name: 'AcptOftr',

    components: {
        SrvcPolicies,
        PrvcPlcy,
    },

    computed:{

    },

    watch:{

    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            this.allRflChc()
        })
    },

    methods: {
        allRflChc: function(){
            if(this.$parent.$parent.$parent.mmbrInfo.trmsAgrYn == '1'){
                for (let index = 0; index < this.reqList.length; index++) {
                    this.reqList.splice(index, 1, '1')
                }
            }
            else if(this.$parent.$parent.$parent.mmbrInfo.trmsAgrYn == '2'){
                for (let index = 0; index < this.reqList.length; index++) {
                    this.reqList.splice(index, 1, '2')
                }
            }
        },
        rflChc: function(){
            //let arrCnt = 0
            for (let index = 0; index < this.reqList.length; index++) {
                let res = this.reqList.filter( v => v == '1' )
                if(res.length == 3)
                    this.$parent.$parent.$parent.mmbrInfo.trmsAgrYn = '1'
                else
                    this.$parent.$parent.$parent.mmbrInfo.trmsAgrYn = '2'
            }
   
        },
    },

    data: () => ({
        sltAgr: 0,
        reqList: ['2','2','2',],
    }),
};
</script>

<style>
    .authAgr .v-input__control .v-input__slot .v-input--selection-controls__input i { font-size: 26px; }
    .authAgr .v-input__control .v-input__slot .v-input--selection-controls__input .v-input--selection-controls__ripple { margin-top: 8px; }
</style>