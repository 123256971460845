<template>
    <v-sheet>
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <v-form ref="tabform" lazy-validation>
            <div class="d-flex align-center mt-1">
                <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                <span class="text-h6 font-weight-bold">사례관리 회의 상세정보</span>
            </div>
            <v-row class="pa-0 ma-0 mt-2" no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;"> 
                <v-col class="greyE01 d-flex justify-center align-center ma-0" cols="2">
                    <span class="black--text font-weight-medium">회의일시</span>
                </v-col>
                <v-col class="d-flex pa-0 ma-0" cols="4">
                    <span class="white d-inline-block pa-1" style="width: 140px;">                           
                        <CmmDateComp v-model="items.mtngDt" :required="true"></CmmDateComp>
                    </span>
                    <span class="d-inline-block pa-1" style="width: 70px;">
                        <CmmTimeField v-model="items.mtngTmStrt" :required="true"></CmmTimeField>
                    </span>
                    <span class="white d-inline-block pa-1" style="width: 14px;">  
                        ~
                    </span>
                    <span class="d-inline-block pa-1" style="width: 70px;">
                        <CmmTimeField v-model="items.mtngTmEnd" :required="true"></CmmTimeField>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center ma-0" cols="2">
                    <span class="black--text font-weight-medium">작성자</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-0 ma-0" cols="4">
                    <span class="d-inline-block py-1 pl-2" style="width: 120px;">
                        <v-text-field
                            v-model="items.wrtr" :rules="[rules.required]" :key="forceRender"
                            color="blue" height="28" ref="info"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <v-btn 
                        class="ml-1 brown lighten-3 "  
                        small 
                        min-width="30"
                        height="25"  
                        dark
                        style="padding:0px 1px 0px 2px !important"
                        @click="onModal(1)"
                        >
                        <v-icon size="20">mdi-account-edit</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="pa-0 ma-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;"> 
                <v-col class="greyE01 d-flex justify-center align-center py-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">수급자</span>
                    <v-btn 
                        class="ml-1 deep-orange lighten-3" 
                        small 
                        min-width="30"
                        height="25"  
                        dark
                        style="padding:0px 1px 0px 2px !important"
                        @click="onIntgrSlctn(2)"
                        >
                        <v-icon size="20">mdi-account-multiple</v-icon>
                    </v-btn>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4">
                    <div style="height:60px;overflow-y:auto">
                        <v-chip 
                            v-for="(cdItme, i) in mtngAtndItems1" :key="i" 
                            class="pa-2 mr-2"
                            close
                            label                            
                            color="blue"
                            outlined
                            small    
                            @click:close="delBnfcr(i)"                                                               
                            >
                            {{cdItme.atndNm}}
                        </v-chip>
                    </div>                    
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center py-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">참석자</span>
                    <v-btn 
                        class="ml-1 brown lighten-3" 
                        small 
                        min-width="30"
                        height="25"  
                        dark
                        style="padding:0px 1px 0px 2px !important"
                        @click="onIntgrSlctn(3)"
                        >
                        <v-icon size="20">mdi-account-multiple</v-icon>
                    </v-btn>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4">
                    <div style="height:60px;overflow-y:auto">
                        <v-chip 
                            v-for="(cdItme, j) in mtngAtndItems2" :key="j" 
                            class="pa-2 mr-2"
                            close
                            label                            
                            color="blue"
                            outlined
                            small      
                            @click:close="delEmp(j)"                                
                            >
                            {{cdItme.atndNm}}
                        </v-chip>
                    </div>                    
                </v-col>
            </v-row>
            <v-row class="pa-0 ma-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;"> 
                <v-col class="greyE01 d-flex justify-center align-center ma-0" cols="2">
                    <span class="black--text font-weight-medium">회의장소</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="10">
                    <v-text-field v-model="items.mtngPlc" height="28" hide-details outlined dense :rules="[rules.required]"></v-text-field>
                </v-col>
            </v-row>
            <v-row class="pa-0 ma-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;"> 
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="2">
                    <span class="black--text font-weight-medium">선정사유<br />&#40;문제점&#41;</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="10">                    
                    <v-textarea v-model="items.mtngAgnd" :rules="[rules.required]" class="rmrksArea" style="font-size:0.9rem !important;"
                            rows="4" outlined dense hide-details no-resize>
                    </v-textarea>
                </v-col>
            </v-row>
            <v-row class="pa-0 ma-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;"> 
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="2">
                    <span class="black--text font-weight-medium">회의내용</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="10">                    
                    <v-textarea v-model="items.mtngCntnt" :rules="[rules.required]" class="rmrksArea" style="font-size:0.9rem !important;"
                            rows="14" outlined dense hide-details no-resize>
                    </v-textarea>
                </v-col>
            </v-row>
            <v-row class="pa-0 ma-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;"> 
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="2">
                    <span class="black--text font-weight-medium">회의결과</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="10">                    
                    <v-textarea v-model="items.mtngRslt" :rules="[rules.required]" class="rmrksArea" style="font-size:0.9rem !important;"
                            rows="10" outlined dense hide-details no-resize>
                    </v-textarea>
                </v-col>
            </v-row>
        </v-form>
        <div class="pa-0 ma-0">
            <v-dialog v-model="menu[1]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="isModal(1)" @outEmp="getEmpInfo" :prtWrtDt = "prtWrtDt"></EmpSelector>
            </v-dialog> 
        </div>        
        <div class="pa-0 ma-0">
            <v-dialog v-model="menu[2]" max-width="700" content-class="round" persistent eager>           
                <IntgrSlctn
                    :mdlTtl="mdlTtl" 
                    :tabInfList="tabInfList" :fixList="exclsList" mode="2"
                    :cd41="vCd41"
                    :cd42="vCd42"
                    :cd65="vCd65"
                    :prtWrtDt = "items.mtngDt"
                    @intgrResList="getAplyList" @onClose="isModal(2)">
                </IntgrSlctn>
            </v-dialog>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="title" @madalState="delMtngAtnd"></btnModalDelete>  
            </v-dialog>  
        </div>
    </v-sheet>
</template>
<script>
import { getMultiStcd,} from '../../api/index.js';
import { insMtngRcrd, delMtngAtnd } from '../../api/bnftSrv.js';
import IntgrSlctn from '../commons/IntgrSlctn.vue';
import EmpSelector from '../commons/EmpSelector.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import CmmDateComp from '../../components/commons/CmmDateComp.vue';
import CmmTimeField from '../../components/commons/CmmTimeField.vue';
export default {
    name: 'CaseMgmtTab1',
    
    components: {
        IntgrSlctn,
        EmpSelector,
        btnModalDelete,
        CmmDateComp,
        CmmTimeField,

    },

    props : {

    },

    computed: {
       
    },  

    created: function(){
        
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getCode(['41', '42', '65', '74'])
        })
    },

    watch: {       
    },

    methods: {
        onforce(){
            ++this.forceRender
        },
        onReset(){
            this.items = Object.assign({mtngDt:'',mtngTmStrt:'',mtngTmHhStrt:'',mtngTmMmStrt:'',mtngTmEnd:'',mtngTmHhEnd:'',mtngTmMmEnd:'',mtngPlc:'',wrtr:'',mtngAgnd:'',mtngCntnt:'',mtngRslt:''})
            this.mtngAtndItems1.splice(0)
            this.mtngAtndItems2.splice(0)
            this.noItems.splice(0)
            this.empItems.splice(0)
            this.chkItems.splice(0)
            this.$refs.tabform.resetValidation();
        },

        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        stCdMapping: function (res) {
            this.vCd41 = res.vCd41
            this.vCd42 = res.vCd42
            this.vCd65 = res.vCd65
            this.vCd74 = res.vCd74
        },

        onSave(mtngRcrdPk){
            if(this.$refs.tabform.validate()){
                let mtngTmStrt = this.items.mtngTmStrt.split(":")
                let mtngTmEnd = this.items.mtngTmEnd.split(":")

                let obj = {
                    mtngTypcd:'1',
                    mtngDt : this.items.mtngDt?.afterDateFormatHyp(),
                    mtngTmHhStrt : mtngTmStrt[0],
                    mtngTmMmStrt : mtngTmStrt[1],
                    mtngTmHhEnd : mtngTmEnd[0],
                    mtngTmMmEnd :  mtngTmEnd[1],
                    mtngPlc : this.items.mtngPlc,
                    mtngAgnd : this.items.mtngAgnd,
                    mtngCntnt : this.items.mtngCntnt,
                    mtngRslt : this.items.mtngRslt,
                    wrtr : this.items.wrtr,
                    fcltyNum : this.$store.getters.getFcltyNum,
                    userNm:this.$store.getters.getUserNm,
                    mtngRcrdPk : mtngRcrdPk,
                    mtngAtnds : [],
                }

                let mtngAtnds = []

                this.mtngAtndItems1.forEach(x=>{
                    mtngAtnds.push(x)
                })

                this.mtngAtndItems2.forEach(x=>{
                    mtngAtnds.push(x)
                })

                if(this.mtngAtndItems1.length == 0){
                    this.snackControll([true, 5000, "수급자를 선택해주세요.", 'warning'])
                    return
                }

                obj.mtngAtnds = mtngAtnds

                insMtngRcrd(obj)
                    .then((response) => ( this.onSaveAfter(response.data) ))
                    .catch((error) => console.log('upload error /opr/insMtngRcrd : ' + error))
            }

        },
        onSaveAfter(res){
             this.$emit('caseMgmtTrigger', res) 
        },

        getMtngRcrdInfoAfter(res){
            if(res != undefined && res !=''){
                this.items = res
                this.items.mtngDt = this.items.mtngDt?.beforeDateFormatHyp()
                this.items.mtngTmStrt = this.items.mtngTmHhStrt+":"+this.items.mtngTmMmStrt
                this.items.mtngTmEnd = this.items.mtngTmHhEnd+":"+this.items.mtngTmMmEnd
            }
        },
        
        getMtngAtndListAfter(res){
            this.mtngAtndItems1.splice(0)
            this.mtngAtndItems2.splice(0)
            res.forEach(x=>{
                if(x.pstnCd == "1"){
                    this.mtngAtndItems1.push(x)
                }

                if(x.pstnCd == "3"){
                    this.mtngAtndItems2.push(x)
                }
            })
        },

        getEmpInfo: function (obj) {
            this.items.wrtr = obj[0].name
        },
        
        onModal: function (key) {            
            if(key == 1) this.prtWrtDt = this.items.mtngDt
            this.menu.splice(key, 1, true)
            
        },
        onIntgrSlctn:function(key){
            if(key ==2){
                this.mdlTtl = '수급자 선택'                
                this.tabInfList.splice(0,1,'1')
                this.exclsList.splice(0)

                this.mtngAtndItems1.forEach(x=>{

                    let obj = {                    
                        name:x.atndNm,
                        mmbrpk:x.mmbrPk,
                        disChekr:true,
                        checkr:'1'
                    }
                    
                    this.exclsList.push(obj)
                })
                this.menu.splice(2, 1, true)                
            } else if(key ==3){              
                this.mdlTtl = '참석자 선택'                
                this.tabInfList.splice(0,1,'2')
                this.exclsList.splice(0)

                this.mtngAtndItems2.forEach(x=>{
                    let obj = {                    
                        name:x.atndNm,
                        mmbrpk:x.mmbrPk,
                        disChekr:true,
                        checkr:'2'
                    }                    
                    this.exclsList.push(obj)
                })

            }
            this.menu.splice(2, 1, true)
        },
        isModal: function (key) {
            this.menu.splice(key, 1, false)
        },

        getAplyList: function (arr){
            arr.forEach(el => {
                let obj = {
                    atndNm:el.name,
                    mmbrPk:0,
                    pstnCd : '',
                    mtngAtndPk : 0,
                }

                switch (this.mdlTtl) {
                    case '수급자 선택':
                        obj.pstnCd = '1'
                        obj.mmbrPk = el.bnMmbrPk
                        this.mtngAtndItems1.push(obj)
                        break;
                    case '참석자 선택':
                        obj.pstnCd = '3'
                        obj.mmbrPk = el.mmbrPk
                        this.mtngAtndItems2.push(obj)
                        break;
                    default:
                        break;
                }
            });
                   
        },

        setBnfcr:function(bnfcrs){
            let mmbrs = []
            this.mtngAtndItems1.forEach(x=>{mmbrs.push(x.mmbrPk)})

            bnfcrs.forEach(x=>{
                let bl = mmbrs.includes(x.bnMmbrPk)
                if(!bl){
                    let obj = {
                        atndNm:x.name,
                        mmbrPk:x.bnMmbrPk,
                        pstnCd : '1',
                        mtngAtndPk : 0,
                    }
                    this.mtngAtndItems1.push(obj)
                }
            })
        },
        delBnfcr:function(idx){
            if(this.mtngAtndItems1[idx].mtngAtndPk > 0){
                this.title = '수급자'
                this.idx = idx
                this.key = 1
                this.pk = this.mtngAtndItems1[idx].mtngAtndPk
                this.dialogDelete = true
            }else{
                this.mtngAtndItems1.splice(idx,1)
            }
        },
        setEmp:function(emps){
            let mmbrs = []
            this.mtngAtndItems2.forEach(x=>{mmbrs.push(x.mmbrPk)})

            emps.forEach(x=>{                            
                let bl = mmbrs.includes(x.mmbrPk)

                if(!bl){
                    let obj = {
                        atndNm:x.name,
                        mmbrPk:x.mmbrPk,
                        pstnCd : '3',
                        mtngAtndPk : 0,
                    }    
                    this.mtngAtndItems2.push(obj)
                }
            })
        },
        delEmp:function(idx){            
            if(this.mtngAtndItems2[idx].mtngAtndPk > 0){
                this.title = '참석자'
                this.idx = idx
                this.key = 2
                this.pk = this.mtngAtndItems2[idx].mtngAtndPk
                this.dialogDelete = true
            }else{
                this.mtngAtndItems2.splice(idx,1)
            }
        },      
        
        delMtngAtnd:function(res){
            if(res == 9990){
                if(this.pk > 0){
                    delMtngAtnd(this.$store.getters.getFcltyNum,this.pk,this.$store.getters.getUserNm)
                        .then((response) => ( this.delMtngAtndAfter(response.data) ))
                        .catch((error) => console.log('upload error /opr/insMtngRcrd : ' + error))
                }
            }else if(res == 7){
                this.dialogDelete = false
            }
        },
        delMtngAtndAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.mtngAtndItems1.splice(0)
                this.mtngAtndItems2.splice(0)
                this.key = 0
                this.idx = 0
                this.pk = 0
                this.$emit('caseMgmtTrigger') 
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.dialogDelete = false
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    data: () => ({
        prtWrtDt:'',
        key:0,
        idx:0,
        pk:0,
        title:'수급자',
        menu: [false,false,false,false],
        items:{mtngDt:'',mtngTmStrt:'',mtngTmHhStrt:'',mtngTmMmStrt:'',mtngTmEnd:'',mtngTmHhEnd:'',mtngTmMmEnd:'',mtngPlc:'',wrtr:'',mtngAgnd:'',mtngCntnt:'',mtngRslt:''},
        rtimes1:'',
        mtimes1:'',
        rtimes2:'',
        mtimes2:'',
        mtngAtndItems1:[],
        mtngAtndItems2:[],
        noItems:[],
        empItems:[],  
        chkItems:[],
        mdlTtl:'수급자 선택',
        tabInfList:[],
        exclsList:[],
        mode:'2',
        vCd41:[],
        vCd42:[],
        vCd65:[],
        filledBtn: [            
            { icon: 'mdi-pencil-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '신규', type: 'action', width:'600px', visible: true, disabled: true, },
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type: 'action', width:'500px', visible: true, disabled: true, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type: 'action', width:'500px', visible: true, disabled: true, },
        ],
         outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006 ', text: '출력', disabled: true, },
        ],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },   
        fileRules: [
            value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
        ],
        accept:'image/png, image/jpeg, image/bmp',
        forceRender:0,
        snackOptions: [false, 3000, '', 'info'],
        dialogDelete : false,
    }),
};
</script>
