<template>
    <v-sheet>
        <v-row class="pa-0 ma-0" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="1">
                <span class="black--text font-weight-medium">수급자</span>
            </v-col>
            <v-col class="pa-1 ma-0 d-flex justify-center align-center" cols="1">
                <span>{{items.name}}</span>
            </v-col>
            <v-col class="greyE01 pa-0 ma-0 d-flex align-center" cols="1">
                <v-row no-gutters>
                    <v-col class="black--text font-weight-medium d-flex justify-center" cols="12">성별</v-col>
                    <!-- <v-col class="black--text font-weight-medium d-flex justify-center" cols="12">&#40;나이&#41;</v-col> -->
                </v-row>       
            </v-col>
            <v-col class="pa-1 ma-0 d-flex justify-center align-center" cols="1">
                <span class="">
                    <v-img class="ic-sex" v-if="items.gndr == '여'" src="../../assets/icon/ic-female.svg"></v-img>
                    <v-img class="ic-sex" v-else-if="items.gndr == '남'" src="../../assets/icon/ic-male.svg"></v-img>
                </span>
                <span class="ml-1">&#40;{{items.age}}&#41;</span>
            </v-col>
            <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="1">
                <span class="black--text font-weight-medium">입소일</span>
            </v-col>
            <v-col class="pa-1 ma-0 d-flex justify-center align-center" cols="2">
                {{items.entDt}}
            </v-col>
            <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="1">
                <span class="black--text font-weight-medium">주요질환</span>
            </v-col>
            <v-col class="pa-1 ma-0 d-flex justify-center align-center" cols="4">
                <!-- {{items.majorDsss}} -->
                <v-tooltip
                    v-model="majorDsssTooltip"
                    bottom                    
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                        text
                        v-bind="attrs"
                        v-on="on"
                        height="20"
                        style="padding:0px 5px !important;"
                        >
                           {{items.majorDsss}}
                        </v-btn>
                    </template>
                    <span>{{items.majorOrgDsss}}</span>
                </v-tooltip>
            </v-col>
        </v-row>
    </v-sheet>
</template>
<script>
    import { getBnfcrBasicInfo } from '../../api/bnfcr.js';
     export default {
        name: 'bnfcrBasicInfo',
        props : {    
            bnMmbrPk:Number
        },   
        watch: {
            'bnMmbrPk':function(){
                this.getInfo()      
            },
        },
        mounted: function(){
            //돔 로딩이 끝난 후 작업
            this.$nextTick(function () {
                this.getInfo()
            })
        },
        
        methods:{
            getInfo(){  
                ++this.forceRender
                getBnfcrBasicInfo(this.$store.getters.getFcltyNum, this.bnMmbrPk)
                    .then((response) => ( this.setDataFormat(response.data)))
                    .catch((error) => console.log(error))
            },
            setDataFormat(data){ 
                this.clearItem()

                if(data !=''){
                    let gndr = data.gndr;
                    let entDt = data.entDt;
                    let majorDsss = data.majorDsss

                    //성별
                    if(gndr != null){
                        if(gndr == 'F'){
                            gndr = '여';
                        }else if(gndr == 'M'){
                            gndr ='남';
                        }
                    }

                    //날짜 형식 포멧 YYYY.MM.DD
                    if(entDt != null){
                        entDt = this.$moment(entDt,'YYYYMMDD').format('YYYY.MM.DD');
                    }
                    
                    if(majorDsss !=null){
                        majorDsss = this.cutText(majorDsss,20)
                    }
                    
                    this.items.name             = data.name
                    this.items.acknwRtng        = data.acknwRtng
                    this.items.age              = data.age
                    this.items.bnMmbrPk         = data.bnMmbrPk
                    this.items.copayRate        = data.copayRate
                    this.items.eduDeg           = data.eduDeg
                    this.items.entDt            = entDt;
                    this.items.gndr             = gndr;
                    this.items.majorDsss        = majorDsss
                    this.items.majorOrgDsss     = data.majorDsss
                    this.items.entTmHh          = data.entTmHh
                    this.items.entTmMm          = data.entTmMm

                    this.$emit('setParentData', this.items)
                }

                ++this.forceRender
            },
            bnfcrBasicInfoReLoad(){
                this.getInfo()
            },
            getBnfcrData(){
                return this.items
            },
            cutText(text, count, more = "...") {
                let length = text.length;

                if(length <= count)
                    return text;
                else
                    return text.substring(0, count) + " " + more;
            },
            clearItem:function(){
                this.items.name             = ''
                this.items.acknwRtng        = ''
                this.items.age              = 0
                this.items.bnMmbrPk         = 0
                this.items.copayRate        = 0
                this.items.eduDeg           = ''
                this.items.entDt            = ''
                this.items.gndr             = ''
                this.items.majorDsss        = ''
                this.items.majorOrgDsss     = ''
                this.items.entTmHh          = ''
                this.items.entTmMm          = ''
            }
        },

        data: () => ({
            forceRender:0,
            majorDsssTooltip:false,
            items:{
                name:'',
                acknwRtng:'',
                age: 0,
                bnMmbrPk: 0,
                copayRate: 0,
                eduDeg: '',
                entDt: '',
                gndr: '',
                majorDsss: '',
                majorOrgDsss: '',
                entTmHh:'',
                entTmMm:'',
            },
        })
     }
</script>