<template>
    <v-sheet class="ma-0 pa-0" rounded="md">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <v-row class="pa-0 mt-5" no-gutters>
            <v-col cols="4">
                <div class="d-flex align-center">
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold">4대보험</span>
                </div>
                <v-divider class="mt-2"></v-divider>
                <v-row class="greyE01 pa-0 ma-0" no-gutters>
                    <v-col class="d-flex justify-center align-center py-1 tableBR" cols="3">
                        <span class="black--text font-weight-medium">구분</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center py-1 tableBR" cols="3">
                        <span class="black--text font-weight-medium">가입일자</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center py-1 tableBR" cols="3">
                        <span class="black--text font-weight-medium">상실일자</span>
                    </v-col>
                    <v-col class="" cols="3">
                    </v-col>
                </v-row>
                <v-divider class=""></v-divider>
                <v-row class="pa-0 ma-0" no-gutters>
                    <v-col class="d-flex justify-center align-center py-1 tableBR" cols="3">
                        <span class="black--text font-weight-medium">국민연금</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center pa-1 tableBR" cols="3">
                        <span class="d-inline-block" style="width: 100%;">
                            <CmmDateComp
                                v-model="ntnlPnsn.rgstDt" :required="false" :btnActv="false">
                            </CmmDateComp>
                        </span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center pa-1 tableBR" cols="3">
                        <span class="d-inline-block" style="width: 100%;">
                            <CmmDateComp
                                v-model="ntnlPnsn.lossDt" :required="false" :btnActv="false">
                            </CmmDateComp>
                        </span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center" cols="3">
                        <span class="">
                            <v-btn class="" color="blueBtnColor" height="26" @click="preEmpEtcInsrn(ntnlPnsn)" icon>
                                <v-icon>mdi-content-save-edit-outline</v-icon>
                            </v-btn>
                        </span>
                        <span class="" v-if="ntnlPnsn.empEtcPk != 0">
                            <v-btn class="" color="grey006" height="26" @click="removeEmpEtc(ntnlPnsn.empEtcPk)" icon>
                                <v-icon>mdi-trash-can-outline</v-icon>
                            </v-btn>
                        </span>
                    </v-col>
                </v-row>
                <v-divider class=""></v-divider>
                <v-row class="pa-0 ma-0" no-gutters>
                    <v-col class="d-flex justify-center align-center py-1 tableBR" cols="3">
                        <span class="black--text font-weight-medium">건강보험</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center pa-1 tableBR" cols="3">
                        <span class="d-inline-block" style="width: 100%;">
                            <CmmDateComp
                                v-model="hlthInsrn.rgstDt" :required="false" :btnActv="false">
                            </CmmDateComp>
                        </span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center pa-1 tableBR" cols="3">
                        <span class="d-inline-block" style="width: 100%;">
                            <CmmDateComp
                                v-model="hlthInsrn.lossDt" :required="false" :btnActv="false">
                            </CmmDateComp>
                        </span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center" cols="3">
                        <span class="">
                            <v-btn class="" color="blueBtnColor" height="26" @click="preEmpEtcInsrn(hlthInsrn)" icon>
                                <v-icon>mdi-content-save-edit-outline</v-icon>
                            </v-btn>
                        </span>
                        <span class="" v-if="hlthInsrn.empEtcPk != 0">
                            <v-btn class="" color="grey006" height="26" @click="removeEmpEtc(hlthInsrn.empEtcPk)" icon>
                                <v-icon>mdi-trash-can-outline</v-icon>
                            </v-btn>
                        </span>
                    </v-col>
                </v-row>
                <v-divider class=""></v-divider>
                <v-row class="pa-0 ma-0" no-gutters>
                    <v-col class="d-flex justify-center align-center py-1 tableBR" cols="3">
                        <span class="black--text font-weight-medium">고용보험</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center pa-1 tableBR" cols="3">
                        <span class="d-inline-block" style="width: 100%;">
                            <CmmDateComp
                                v-model="emplyInsrn.rgstDt" :required="false" :btnActv="false">
                            </CmmDateComp>
                        </span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center pa-1 tableBR" cols="3">
                        <span class="d-inline-block" style="width: 100%;">
                            <CmmDateComp
                                v-model="emplyInsrn.lossDt" :required="false" :btnActv="false">
                            </CmmDateComp>
                        </span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center" cols="3">
                        <span class="">
                            <v-btn class="" color="blueBtnColor" height="26" @click="preEmpEtcInsrn(emplyInsrn)" icon>
                                <v-icon>mdi-content-save-edit-outline</v-icon>
                            </v-btn>
                        </span>
                        <span class="" v-if="emplyInsrn.empEtcPk != 0">
                            <v-btn class="" color="grey006" height="26" @click="removeEmpEtc(emplyInsrn.empEtcPk)" icon>
                                <v-icon>mdi-trash-can-outline</v-icon>
                            </v-btn>
                        </span>
                    </v-col>
                </v-row>
                <v-divider class=""></v-divider>
                <v-row class="pa-0 ma-0" no-gutters>
                    <v-col class="d-flex justify-center align-center pa-1 tableBR" cols="3">
                        <span class="black--text font-weight-medium">산재보험</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center py-1 tableBR" cols="3">
                        <span class="d-inline-block" style="width: 100%;">
                            <CmmDateComp
                                v-model="indstInsrn.rgstDt" :required="false" :btnActv="false">
                            </CmmDateComp>
                        </span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center pa-1 tableBR" cols="3">
                        <span class="d-inline-block" style="width: 100%;">
                            <CmmDateComp
                                v-model="indstInsrn.lossDt" :required="false" :btnActv="false">
                            </CmmDateComp>
                        </span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center" cols="3">
                        <span class="">
                            <v-btn class="" color="blueBtnColor" height="26" @click="preEmpEtcInsrn(indstInsrn)" icon>
                                <v-icon>mdi-content-save-edit-outline</v-icon>
                            </v-btn>
                        </span>
                        <span class="" v-if="indstInsrn.empEtcPk != 0">
                            <v-btn class="" color="grey006" height="26" @click="removeEmpEtc(indstInsrn.empEtcPk)" icon>
                                <v-icon>mdi-trash-can-outline</v-icon>
                            </v-btn>
                        </span>
                    </v-col>
                </v-row>
                <v-divider class=""></v-divider>
            </v-col>
            <v-col class="" cols="8">
                <div class="d-flex align-center">
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold">자격증&nbsp;정보</span>
                </div>
                <v-divider class="mt-2 ml-1"></v-divider>
                <v-row class="greyE01 ml-1 pa-0" no-gutters>
                    <v-col class="d-flex justify-center align-center py-1 tableBR" cols="2">
                        <span class="black--text font-weight-medium">자격증명</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center py-1 tableBR" cols="2">
                        <span class="black--text font-weight-medium">자격증번호</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center py-1 tableBR" cols="2">
                        <span class="black--text font-weight-medium">발급일</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center py-1 tableBR" cols="2">
                        <span class="black--text font-weight-medium">발급기관</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center py-1 tableBR" cols="2">
                        <span class="black--text font-weight-medium">첨부파일</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center" cols="2">
                        <span class="">
                            <v-btn class="mr-1" color="blue200" height="26" @click="addOnRow()" icon>
                                <v-icon>mdi-plus-circle-outline</v-icon>
                            </v-btn>
                        </span>
                    </v-col>
                </v-row>
                <v-divider class="ml-1"></v-divider>
                <v-row class="ml-1 pa-0" style="border-bottom: 1px solid #dbdbdb;"
                    v-for="(cList, i) in crtfcList" :key="i" no-gutters>
                    <v-col class="d-flex justify-center align-center pa-1 tableBR" cols="2">
                        <span class="d-inline-block" style="width: 100%;">
                            <v-text-field
                                v-model="cList.crtfcNm"
                                height="28" hide-details outlined dense>
                            </v-text-field>
                        </span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center pa-1 tableBR" cols="2">
                        <span class="d-inline-block" style="width: 100%;">
                            <v-text-field
                                v-model="cList.crtfcNum"
                                height="28" hide-details outlined dense>
                            </v-text-field>
                        </span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center pa-1 tableBR" cols="2">
                        <span class="d-inline-block" style="width: 100px;">
                            <CmmDateComp
                                v-model="cList.issueDt" :required="false" :btnActv="false">
                            </CmmDateComp>
                        </span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center pa-1 tableBR" cols="2">
                        <span class="d-inline-block" style="width: 100%;">
                            <v-text-field
                                v-model="cList.issueOrg"
                                height="28" hide-details outlined dense>
                            </v-text-field>
                        </span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center tableBR px-1" cols="2">
                        <v-file-input v-model="files[i]" @change="actBtn=i, upFile()"
                            class="pa-0 ma-0 otlnFileArea" color="blueBtnColor"
                            prepend-icon="mdi-image-plus-outline" truncate-length="4"
                            outlined hide-details dense>
                        </v-file-input>
                    </v-col>
                    <v-col class="d-flex justify-center align-center" cols="2">
                        <span class="">
                            <v-btn class="" color="blueBtnColor" height="26" @click="preEmpEtcCrtfc(cList, i)" icon>
                                <v-icon>mdi-content-save-edit-outline</v-icon>
                            </v-btn>
                        </span>
                        <span class="" v-if="cList.empEtcPk != 0">
                            <v-btn class="" color="grey006" height="26" @click="preRemoveEmpEtc(cList)" icon>
                                <v-icon>mdi-trash-can-outline</v-icon>
                            </v-btn>
                        </span>
                        <span class="" v-else>
                            <v-btn class="" color="grey006" height="26" @click="crtfcList.splice(i, 1), issueDt.splice(i, 1)" icon>
                                <v-icon>mdi-minus-circle-outline</v-icon>
                            </v-btn>
                        </span>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-form ref="insrnCrtfc" lazy-validation>
            <v-row class="pa-0 ma-0" no-gutters>
               
            </v-row>
        </v-form>
    </v-sheet>
</template>

<script>
import { selEmpEtcList, insEmpEtc, delEmpEtc } from '../../api/emp.js';
import { tmpFileUpload, delAtchdFile } from '../../api/index.js';

import CmmDateComp from '../commons/CmmDateComp.vue';

export default {
    name: 'EmpInsrnCrtfc',

    props : {
   
    },
        
    components: {
        CmmDateComp,
    },

    created: function(){
   
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            
        })
    },

    computed: {
        getSltEmpPk: function () {
            return this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk
        },
    },

    watch:{
        getSltEmpPk: function (value) {
            this.clearForm()
            if(value != -1)
                this.getEmpEtcList(value)
        },
    },
    
    methods: {
        getEmpEtcList: function (pk) {
            selEmpEtcList(this.$store.getters.getFcltyNum, pk)
                .then((response) => ( this.getEmpEtcListAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/selEmpEtcList : ' + error))
        },
        setEmpEtc: function (obj) {
            insEmpEtc(obj)
                .then((response) => ( this.asyncResult(response.data) ))
                .catch((error) => console.log('connect error /emp/insEmpEtc : ' + error))
        },
        removeEmpEtc: function (pk) {
            delEmpEtc(this.$store.getters.getFcltyNum, pk, this.$store.getters.getUserNm)
                .then((response) => ( this.asyncResult(response.data) ))
                .catch((error) => console.log('connect error /emp/delEmpEtc : ' + error))
        },
        //첨부파일 업로드
        athFileUpload: function (formData) {
            tmpFileUpload(formData)
                .then((response) => ( this.upFileAfter(response.data) ))
                .catch((error) => console.log('upload error /opr/tmpFileUpload : ' + error))
        },
        //첨부파일 삭제
        removeAthFile: function (pk) {
            delAtchdFile(this.$store.getters.getFcltyNum, pk)
                .then((response) => ( this.asyncResult(response.data) ))
                .catch((error) => console.log('upload error /opr/delAtchdFile : ' + error))
        },
        upFileAfter: function (res) {
            if(res.statusCode == 200)
                this.resFiles[this.actBtn] = res.files

            this.actBtn = -1
        },
        getEmpEtcListAfter: function (res) {
            this.crtfcList.splice(0)

            res.forEach(cd => {
                if(cd.etcClsfc1Cd == '1' && cd.etcClsfc2Cd == '1'){
                    this.ntnlPnsn.empEtcPk = cd.empEtcPk
                    this.ntnlPnsn.rgstDt = cd.rgstDt.beforeDateFormatHyp()
                    this.ntnlPnsn.lossDt = cd.lossDt.beforeDateFormatHyp()
                }
                else if(cd.etcClsfc1Cd == '1' && cd.etcClsfc2Cd == '2'){
                    this.hlthInsrn.empEtcPk = cd.empEtcPk
                    this.hlthInsrn.rgstDt = cd.rgstDt.beforeDateFormatHyp()
                    this.hlthInsrn.lossDt = cd.lossDt.beforeDateFormatHyp()
                }
                else if(cd.etcClsfc1Cd == '1' && cd.etcClsfc2Cd == '3'){
                    this.emplyInsrn.empEtcPk = cd.empEtcPk
                    this.emplyInsrn.rgstDt = cd.rgstDt.beforeDateFormatHyp()
                    this.emplyInsrn.lossDt = cd.lossDt.beforeDateFormatHyp()
                }
                else if(cd.etcClsfc1Cd == '1' && cd.etcClsfc2Cd == '4'){
                    this.indstInsrn.empEtcPk = cd.empEtcPk
                    this.indstInsrn.rgstDt = cd.rgstDt.beforeDateFormatHyp()
                    this.indstInsrn.lossDt = cd.lossDt.beforeDateFormatHyp()
                }
                else if(cd.etcClsfc1Cd == '2' && cd.etcClsfc2Cd == '5'){
                    cd.issueDt = cd.issueDt.beforeDateFormatHyp()
                    this.crtfcList.push(cd)
                    this.issueDt.push(false)
                }
            });

            if(this.crtfcList.length == 0){
                let tmp = Object.assign({}, this.crtfcInfo)
                this.issueDt.push(false)
                this.crtfcList.push(tmp)
            }
            else{
                this.crtfcList.forEach((cr, i) => {
                    if(cr.orgFileNm == null)
                        this.files[i] = { name: '' }
                    else
                        this.files[i] = { name: cr.orgFileNm }
                });
            }
        },
        asyncResult: function (res) {
            if(res.statusCode == 200){
                this.clearForm()
                this.getEmpEtcList(this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk)
                this.snackControll([true, 2500, res.message, 'info'])
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        upFile: function () {
            let formData = new FormData()

            if(this.files[this.actBtn] != {}){
                formData.append("files", this.files[this.actBtn])
                this.athFileUpload(formData)
            }           
        },
        preEmpEtcInsrn: function (obj) {
            let tmp = Object.assign({}, obj)
            tmp.userNm = this.$store.getters.getUserNm
            tmp.fcltyNum = this.$store.getters.getFcltyNum
            tmp.empPk = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk

            if(tmp.rgstDt == '' || tmp.lossDt == '')
                this.snackControll([true, 2500, '누락된 항목을 입력해주세요.', 'info'])
            else {
                tmp.rgstDt = tmp.rgstDt.afterDateFormatHyp()
                tmp.lossDt = tmp.lossDt.afterDateFormatHyp()

                this.setEmpEtc(tmp)
            }
        },
        preEmpEtcCrtfc: async function (obj, index) {
            let tmp = Object.assign({}, obj)
            tmp.userNm = this.$store.getters.getUserNm
            tmp.fcltyNum = this.$store.getters.getFcltyNum
            tmp.empPk = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk

            if(this.resFiles[index] == {})
                tmp.files = {}
            else
                tmp.files = this.resFiles[index]

            if(tmp.issueDt == '')
                this.snackControll([true, 2500, '누락된 항목을 입력해주세요.', 'info'])
            else {
                tmp.issueDt = tmp.issueDt.afterDateFormatHyp()

                if(tmp.atchdFilePk != null){
                    let chk = {}
                    await delAtchdFile(this.$store.getters.getFcltyNum, tmp.atchdFilePk)
                        .then((response) => (chk = response.data) )
                        .catch((error) => console.log('upload error /opr/delAtchdFile : ' + error))
                      
                    if(chk.statusCode == 200)
                        this.setEmpEtc(tmp)
                    else
                        this.snackControll([true, 5000, '저장 실패 관리자에게 문의해 주세요.', 'error'])
                }
                else
                    this.setEmpEtc(tmp)
            }
        },
        preRemoveEmpEtc: async function (obj) {
            if(obj.atchdFilePk != null){
                let chk = {}

                await delAtchdFile(this.$store.getters.getFcltyNum, obj.atchdFilePk)
                    .then((response) => (chk = response.data) )
                    .catch((error) => console.log('upload error /opr/delAtchdFile : ' + error))

                if(chk.statusCode == 200)
                    this.removeEmpEtc(obj.empEtcPk)
                else
                    this.snackControll([true, 5000, '삭제 실패 관리자에게 문의해 주세요.', 'error'])
            }
            else
                this.removeEmpEtc(obj.empEtcPk)
        },
        datePickClose: function (kind, index) {
            switch (kind) {
                case 0:
                    this.rgstDt.splice(index, 1, false)
                    break;
                case 1:
                    this.lossDt.splice(index, 1, false)
                    break;
                case 2:
                    this.issueDt.splice(index, 1, false)
                    break;
                default:
                    break;
            }
        },
        addOnRow: function (){
            let tmp = Object.assign({}, this.crtfcInfo)
            this.issueDt.push(false)
            this.crtfcList.push(tmp)
        },
        clearForm: function () {
            this.ntnlPnsn.empEtcPk = 0
            this.ntnlPnsn.rgstDt = ''
            this.ntnlPnsn.lossDt = ''
            this.hlthInsrn.empEtcPk = 0
            this.hlthInsrn.rgstDt = ''
            this.hlthInsrn.lossDt = ''
            this.emplyInsrn.empEtcPk = 0
            this.emplyInsrn.rgstDt = ''
            this.emplyInsrn.lossDt = ''
            this.indstInsrn.empEtcPk = 0
            this.indstInsrn.rgstDt = ''
            this.indstInsrn.lossDt = ''

            this.files.splice(0)
            this.resFiles.splice(0)
            this.crtfcList.splice(0)
            this.forceRender += 1
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        actBtn: -1,
        files: [],
        resFiles: [],
        rgstDt: [false, false, false, false,],
        lossDt: [false, false, false, false,],
        issueDt: [],
        crtfcList: [],  //자격증 리스트
        ntnlPnsn: {     //국민연금
            fcltyNum: 0, empPk: 0, empEtcPk: 0, etcClsfc1Cd: '1', etcClsfc2Cd: '1', rgstDt: '', lossDt: '', userNm: '',
        },
        hlthInsrn: {     //건강보험
            fcltyNum: 0, empPk: 0, empEtcPk: 0, etcClsfc1Cd: '1', etcClsfc2Cd: '2', rgstDt: '', lossDt: '', userNm: '',
        },
        emplyInsrn: {     //고용보험
            fcltyNum: 0, empPk: 0, empEtcPk: 0, etcClsfc1Cd: '1', etcClsfc2Cd: '3', rgstDt: '', lossDt: '', userNm: '',
        },
        indstInsrn: {     //산재보험
            fcltyNum: 0, empPk: 0, empEtcPk: 0, etcClsfc1Cd: '1', etcClsfc2Cd: '4', rgstDt: '', lossDt: '', userNm: '',
        },
        crtfcInfo: {      //자격증 정보
            fcltyNum: 0, empPk: 0, empEtcPk: 0, etcClsfc1Cd: '2', etcClsfc2Cd: '5', userNm: '', crtfcNm: '', crtfcNum: '',
            issueDt: '', issueOrg: '',
        },
        forceRender: 0,
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>

<style>
    .otlnFileArea .v-input__control .v-input__slot { padding: 0 6px !important; }
    .otlnFileArea .v-input__prepend-outer { margin: 2px 6px 0 0 !important; }
    .otlnFileArea .v-input__prepend-outer .v-input__icon button { color: #2a9bed; }
    .otlnFileArea .v-input__control .v-input__slot .v-input__append-inner .v-input__icon button { font-size: 18px !important; margin-top: 8px; }
    .otlnFileArea .v-input__control .v-input__slot .v-input__append-inner .v-input__icon { height: 16px; width: 12px; min-width: 12px; }
</style>