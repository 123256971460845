<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">         
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">목욕급여&nbsp;관찰기록</span>
            <v-spacer></v-spacer> 
            <span v-if="progress">
                <v-progress-circular            
                :size="25"
                color="primary"
                indeterminate
                ></v-progress-circular>
            </span>
            <span v-if="!progress">   
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn v-if="list.type == 'action' && list.visible" @click = "onClickBtn(list.text)"
                        :class="list.class" height="30" :color="list.color" rounded
                        :disabled="list.disabled"
                        style="font-size: 14px;">
                        <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                        {{list.text}}
                    </v-btn>
                </span>
                <span v-for="(list, j) in outlinedBtn" :key="filledBtn.length + j">
                    <v-btn :disabled="list.disabled"
                        :class="list.class" @click = "onClickBtn(list.text)"
                        samll height="30" :color="list.color" rounded outlined>
                        <v-icon small>{{list.icon}}</v-icon>
                        {{list.text}}
                    </v-btn>
                </span>
            </span> 
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <v-form ref="tabform" lazy-validation>
            <v-row class="pa-0 my-2" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1"> 
                    <span class="black--text font-weight-medium">목욕일</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="3">  
                    <span class="white d-inline-block pa-1" style="width: 90px;">
                        {{items.recDt}}                                                  
                    </span>
                    <span class="d-inline-block" style="width: 60px;">
                        <CmmTimeField v-model="items.ofrTmStrt" :required="true"></CmmTimeField>                       
                    </span>
                    <span class="white d-inline-block pa-1" style="width: 14px;">  
                        ~
                    </span>
                    <span class="d-inline-block" style="width: 60px;">
                        <CmmTimeField v-model="items.ofrTmEnd" :required="true"></CmmTimeField>                        
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1">
                    <span class="black--text font-weight-medium">작성자</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="7">  
                    <span class="d-inline-block py-1 pl-2" style="width: 100px;">
                        <v-text-field
                            v-model="items.wrtr" :rules="[rules.required]" :key="forceRender"
                            color="blue" height="28" ref="info"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <v-btn 
                        class="ml-1 brown lighten-3" 
                        small 
                        min-width="30"
                        height="25"  
                        dark
                        style="padding:0px 1px 0px 2px !important"
                        @click="onModal(0)"
                        >
                        <v-icon size="20">mdi-account-edit</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <basicInfo :bnMmbrPk="bnMmbrPk"></basicInfo>
            <div class="d-flex pa-0 mt-2" no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:10%;">                                
                    <span class="black--text font-weight-medium">구분</span>
                </div>
                <div class="d-flex" style="width:40%;">
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;">                                
                        <span class="black--text font-weight-medium">세부항목</span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:80%;border-left: 1px solid #dbdbdb !important;">                                
                        <span class="black--text font-weight-medium">시행내용</span>
                    </div>
                </div>
                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">                                
                    <span class="black--text font-weight-medium">구분</span>
                </div>
                <div class="d-flex" style="width:40%;">
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;">                                
                        <span class="black--text font-weight-medium">세부항목</span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:80%;border-left: 1px solid #dbdbdb !important;">                                
                        <span class="black--text font-weight-medium">시행내용</span>
                    </div>
                </div>
            </div>
            <div class="d-flex pa-0 ma-0" no-gutters>
                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-bottom: 1px solid #dbdbdb !important;">                                
                    <span class="black--text font-weight-medium">목욕전</span>
                </div>
                <div style="width:40%;">
                    <div v-for="(item, i) in groups1" :key="i" class="d-flex" style="width:100%;border-bottom: 1px solid #dbdbdb !important;">
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;">                                
                            <span class="black--text font-weight-medium">{{item.itmCdnm}}</span>
                        </div>
                        <div class="d-flex justify-start align-center pa-1 ma-0" style="width:80%;border-left: 1px solid #dbdbdb !important;">                                
                            <span v-if="item.valClcd == '1'" class="d-inline-block" style="width: 314px;">
                                <v-radio-group
                                    v-model="item.itmVal" :rules="[rules.required]"
                                    class="radio-dft-Theme mt-0 pl-2 pt-1" hide-details row :key="forceRender">
                                    <v-radio v-for="(list, j) in codeItem1" :key="j" class="pa-0 ma-0 mr-2" :ripple="false" :label="list.valcdnm" :value="list.valcd"></v-radio>                                
                                </v-radio-group>
                            </span>
                            <span v-if="item.valClcd == '16'" class="d-inline-block" style="width: 314px;">
                                <v-radio-group
                                    v-model="item.itmVal" :rules="[rules.required]"
                                    class="radio-dft-Theme mt-0 pl-2 pt-1" hide-details row :key="forceRender">
                                    <v-radio v-for="(list, j) in codeItem16" :key="j" class="pa-0 ma-0 mr-2" :ripple="false" :label="list.valcdnm" :value="list.valcd"></v-radio>                                
                                </v-radio-group>
                            </span>
                            <span v-if="item.valClcd == '17'" class="d-inline-block" style="width: 314px;">
                                <v-radio-group
                                    v-model="item.itmVal" :rules="[rules.required]"
                                    class="radio-dft-Theme mt-0 pl-2 pt-1" hide-details row :key="forceRender">
                                    <v-radio v-for="(list, j) in codeItem17" :key="j" class="pa-0 ma-0 mr-2" :ripple="false" :label="list.valcdnm" :value="list.valcd"></v-radio>                                
                                </v-radio-group>
                            </span>
                            <span v-if="item.valClcd == '18'" class="d-inline-block" style="width: 314px;">
                                <v-radio-group
                                    v-model="item.itmVal" :rules="[rules.required]"
                                    class="radio-dft-Theme mt-0 pl-2 pt-1" hide-details row :key="forceRender">
                                    <v-radio v-for="(list, j) in codeItem18" :key="j" class="pa-0 ma-0 mr-2" :ripple="false" :label="list.valcdnm" :value="list.valcd"></v-radio>                                
                                </v-radio-group>
                            </span>
                            <span v-if="item.valClcd == '19'" class="d-inline-block" style="width: 314px;">
                                <v-radio-group
                                    v-model="item.itmVal" :rules="[rules.required]"
                                    class="radio-dft-Theme mt-0 pl-2 pt-1" hide-details row :key="forceRender">
                                    <v-radio v-for="(list, j) in codeItem19" :key="j" class="pa-0 ma-0 mr-2" :ripple="false" :label="list.valcdnm" :value="list.valcd"></v-radio>                                
                                </v-radio-group>
                            </span>
                            <span v-if="item.valClcd == '20'" class="d-inline-block" style="width: 314px;">
                                <v-radio-group
                                    v-model="item.itmVal" :rules="[rules.required]"
                                    class="radio-dft-Theme mt-0 pl-2 pt-1" hide-details row :key="forceRender">
                                    <v-radio v-for="(list, j) in codeItem20" :key="j" class="pa-0 ma-0 mr-2" :ripple="false" :label="list.valcdnm" :value="list.valcd"></v-radio>                                
                                </v-radio-group>
                            </span>
                            <span v-if="item.valClcd == '21'" class="d-inline-block" style="width: 314px;">
                                <v-radio-group
                                    v-model="item.itmVal" :rules="[rules.required]"
                                    class="radio-dft-Theme mt-0 pl-2 pt-1" hide-details row :key="forceRender">
                                    <v-radio v-for="(list, j) in codeItem21" :key="j" class="pa-0 ma-0 mr-2" :ripple="false" :label="list.valcdnm" :value="list.valcd"></v-radio>                                
                                </v-radio-group>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                    <!-- <span>                            
                        <v-btn
                            @click="setCopyVal"
                            icon>
                            <v-tooltip bottom >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon class="blue--text" v-bind="attrs" v-on="on">mdi-arrow-right-bold-box-outline</v-icon>
                                </template>
                                <span>목욕전과 동일</span>
                            </v-tooltip>
                        </v-btn>
                    </span> -->
                    <span class="black--text font-weight-medium">목욕후</span>
                </div>
                <div style="width:40%;">
                    <div v-for="(item, i) in groups2" :key="i+groups1.length" class="d-flex" style="width:100%;border-bottom: 1px solid #dbdbdb !important;">
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;">                                
                            <span class="black--text font-weight-medium">{{item.itmCdnm}}</span>
                        </div>
                        <div class="d-flex justify-start align-center pa-1 ma-0" style="width:80%;border-left: 1px solid #dbdbdb !important;">                                                            
                            <span v-if="item.valClcd == '1'" class="d-inline-block" style="width: 314px;">
                                <v-radio-group
                                    v-model="item.itmVal"
                                     class="radio-dft-Theme mt-0 pl-2 pt-1" hide-details row :key="forceRender">
                                    <v-radio v-for="(list, j) in codeItem1" :key="j" class="pa-0 ma-0 mr-2" :ripple="false" :label="list.valcdnm" :value="list.valcd"></v-radio>                                
                                </v-radio-group>
                            </span>
                            <span v-if="item.valClcd == '16'" class="d-inline-block" style="width: 314px;">
                                <v-radio-group
                                    v-model="item.itmVal"
                                    class="radio-dft-Theme mt-0 pl-2 pt-1" hide-details row :key="forceRender">
                                    <v-radio v-for="(list, j) in codeItem16" :key="j" class="pa-0 ma-0 mr-2" :ripple="false" :label="list.valcdnm" :value="list.valcd"></v-radio>                                
                                </v-radio-group>
                            </span>
                            <span v-if="item.valClcd == '17'" class="d-inline-block" style="width: 314px;">
                                <v-radio-group
                                    v-model="item.itmVal"
                                    class="radio-dft-Theme mt-0 pl-2 pt-1" hide-details row :key="forceRender">
                                    <v-radio v-for="(list, j) in codeItem17" :key="j" class="pa-0 ma-0 mr-2" :ripple="false" :label="list.valcdnm" :value="list.valcd"></v-radio>                                
                                </v-radio-group>
                            </span>
                            <span v-if="item.valClcd == '18'" class="d-inline-block" style="width: 314px;">
                                <v-radio-group
                                    v-model="item.itmVal"
                                    class="radio-dft-Theme mt-0 pl-2 pt-1" hide-details row :key="forceRender">
                                    <v-radio v-for="(list, j) in codeItem18" :key="j" class="pa-0 ma-0 mr-2" :ripple="false" :label="list.valcdnm" :value="list.valcd"></v-radio>                                
                                </v-radio-group>
                            </span>
                            <span v-if="item.valClcd == '19'" class="d-inline-block" style="width: 314px;">
                                <v-radio-group
                                    v-model="item.itmVal"
                                    class="radio-dft-Theme mt-0 pl-2 pt-1" hide-details row :key="forceRender">
                                    <v-radio v-for="(list, j) in codeItem19" :key="j" class="pa-0 ma-0 mr-2" :ripple="false" :label="list.valcdnm" :value="list.valcd"></v-radio>                                
                                </v-radio-group>
                            </span>
                            <span v-if="item.valClcd == '20'" class="d-inline-block" style="width: 314px;">
                                <v-radio-group
                                    v-model="item.itmVal"
                                    class="radio-dft-Theme mt-0 pl-2 pt-1" hide-details row :key="forceRender">
                                    <v-radio v-for="(list, j) in codeItem20" :key="j" class="pa-0 ma-0 mr-2" :ripple="false" :label="list.valcdnm" :value="list.valcd"></v-radio>                                
                                </v-radio-group>
                            </span>
                            <span v-if="item.valClcd == '21'" class="d-inline-block" style="width: 314px;">
                                <v-radio-group
                                    v-model="item.itmVal"
                                    class="radio-dft-Theme mt-0 pl-2 pt-1" hide-details row :key="forceRender">
                                    <v-radio v-for="(list, j) in codeItem21" :key="j" class="pa-0 ma-0 mr-2" :ripple="false" :label="list.valcdnm" :value="list.valcd"></v-radio>                                
                                </v-radio-group>
                            </span>
                        </div>                        
                    </div>                    
                </div>
            </div>
            <div class="d-flex pa-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">
                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:10%;">                                
                    <span class="black--text font-weight-medium">특이사항</span>
                </div>
                <div class="d-flex pa-1" style="width:90%;border-left: 1px solid #dbdbdb !important;">
                    <v-text-field v-model="items.spclNote" height="28" hide-details outlined dense></v-text-field>
                </div>                
            </div>
        </v-form>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
                    <EmpSelector @isClose="isModal(0)" @outEmp="getEmpInfo" :prtWrtDt="prtWrtDt"></EmpSelector>
            </v-dialog>  
        </div>          
    </v-sheet>
</template>
<script>
import CmmTimeField from '../commons/CmmTimeField.vue';
import basicInfo from '../bnfcr/BnfcrBasicInfo.vue';
import EmpSelector from '../commons/EmpSelector.vue';
import { getCmnItmCd, getMultiStcd } from '../../api/index.js';
import { selBnftOfrRecList, selPreBnftOfrRecList, insMultiBnftRcrd, delBathAllBnftOfrRec} from '../../api/bnftrcrd.js';
import { selBnfcrBathTmInfo } from '../../api/bnftSrv.js';

export default {
    name: 'BathObsrvRcrd',
    
    components: {  
        CmmTimeField,
        EmpSelector,
        basicInfo,
    },

    props : {
        inqYMD          : { type: String, default: '' },  
        sTime           : { type: String, default: '' },  
        eTime           : { type: String, default: '' },
        bathMthdcd      : { type: String, default: '' },
        bnMmbrPk        : { type: Number, default: -1 },
        bathObsrvRcrdPk : { type: Number, default: -1 },
        empWrtr         : { type: String, default: '' }, 
        empWrtrpk       : { type: Number, default: -1 },  
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
        getSubActv: function () {
            return this.$store.getters.getSubActv
        },
    },

    created: function(){
        this.onCodeload()
        this.getCmnItmCd()
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {  
            this.items.ofrTmStrt    = this.sTime
            this.items.ofrTmEnd     = this.eTime            
            this.onLoad()
        })
    },

    watch: {
        getSubEvt: function (bol) {
            if(bol && this.bathCnfm){
                this.onSave('1')
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
                this.bathCnfm = false
            }

            if(bol && this.rmBathCnfm){
                this.rmBathAllBnftOfrRec()
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
                this.bathCnfm = false
            }
        },

        getSubActv:function (bol){
            if(!bol && this.bathCnfm){
                this.onLoad()
                this.bathCnfm = false
            }
        }
    },

    methods: {  
        onCodeload:function(){
            getMultiStcd(['1','16','17','18','19','20','21'])
                .then((response) => ( this.codeMapping(response.data) ))
                .catch((error) => console.log(error))
        },
        codeMapping:function(res){
            this.codeItem1.splice(0)
            this.codeItem16.splice(0) //건강상태
            this.codeItem17.splice(0) //피부손상
            this.codeItem18.splice(0) //정신상태
            this.codeItem19.splice(0) //식사유무
            this.codeItem20.splice(0) //목욕부위
            this.codeItem21.splice(0) //목욕방법
            this.codeItem1 = res["vCd1"]
            this.codeItem16 = res["vCd16"]
            this.codeItem17 = res["vCd17"]
            this.codeItem18 = res["vCd18"]
            this.codeItem19 = res["vCd19"]
            this.codeItem20 = res["vCd20"]
            this.codeItem21 = res["vCd21"]            
        },       
        onLoad(){       
            this.frmReset()                       
            this.items.recDt = this.inqYMD?.beforeDateFormatDot()
            this.items.wrtr = this.empWrtr
            this.items.wrtrMmbrPk = this.empWrtrpk                        
            if(this.bnMmbrPk > 0 && this.items.recDt !=''){
                this.filledBtn[0].disabled = false                
                this.getCmnItmCd()
            }
        },
        reLoad(){   
            this.getBnfcrBathTmInfo()         
            this.getData()
        },
        onClose: function () {
            this.$emit('modalEvent', 0)
        },
        frmReset(){            
            // this.items.ofrTmStrt = ''
            // this.items.ofrTmEnd = ''
            this.items.wrtr = ''
            this.items.wrtrMmbrPk =  0
            this.prtWrtDt=''

            this.rcrdInfo.splice(0)
            this.groups1.forEach(x=>{
                x.itmVal=''
            })
            this.groups2.forEach(x=>{
                x.itmVal=''
            })

            //수급자 목욕정보 초기화
            this.bathTmInfo.bathSchdlPk = 0
            this.bathTmInfo.actBathDt = ''
            this.bathTmInfo.bathMthdcd = ''

            this.$refs.tabform.resetValidation();

            this.filledBtn[0].visible = true
            this.filledBtn[2].disabled = true 
            this.outlinedBtn[0].disabled = true           
        },
        onClickBtn:function(text){
            if(text == "전일자료"){           
                this.getPreData()
            }else if(text == "저장"){
                this.onSave()
            }else if(text == "삭제"){
                let obj = {}
                obj.icon = 'mdi-alert'
                obj.iconClr = 'red'
                obj.title = '목욕급여 관찰기록'
                obj.cntnt = [this.$moment(this.inqYMD,'YYYYMMDD').format('YYYY.MM.DD')+'의 목욕급여 관찰기록을(를) 삭제하시겠습니까?']
                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 삭제하겠습니다.'

                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });   

                this.rmBathCnfm = true
            }else if(text == "출력"){
                this.bathObsrvRcrdPrt()
            }
        },        
        onSave:function(dupYn = '2'){

            if(this.bnMmbrPk > 0 && this.$refs.tabform.validate()){
                let  ofrTmStrt = this.items.ofrTmStrt?.split(":")
                let  ofrTmEnd = this.items.ofrTmEnd?.split(":")

                let obj = {
                    fcltyNum: this.$store.getters.getFcltyNum,
                    bnMmbrPk : this.bnMmbrPk,
                    userNm : this.$store.getters.getUserNm,
                    ignrYn      : dupYn,
                    bathTmInfo : {
                        bathSchdlPk:this.bathTmInfo.bathSchdlPk,
                        actBathDt:this.items.recDt?.afterDateFormatDot(),
                        bathMthdcd:'',
                        actBathTmHhStrt: ofrTmStrt[0],
                        actBathTmMmStrt : ofrTmStrt[1],
                        actBathTmHhEnd : ofrTmEnd[0],
                        actBathTmMmEnd : ofrTmEnd[1],
                        wrtr:this.items.wrtr,
                        wrtrMmbrPk:this.items.wrtrMmbrPk,
                    },
                    hisList     : []
                }

                let hisObj1 = {
                    clsfc1Cd    : '301',
                    clsfc2Cd    : '5',
                    clsfc3Cd    : '1',
                    recDt       : this.items.recDt?.afterDateFormatDot(),
                    cntnt       : '',
                    spclNote    : this.items.spclNote,
                    wrtr        : this.items.wrtr,
                    wrtrMmbrPk  : this.items.wrtrMmbrPk,
                    recList     : [],
                    mdfDt       : this.items.mdfDt1,
                }
                
                let items1 = {              
                    ofrTmHhStrt: ofrTmStrt[0],
                    ofrTmMmStrt : ofrTmStrt[1],
                    ofrTmHhEnd : ofrTmEnd[0],
                    ofrTmMmEnd : ofrTmEnd[1],
                    itm1Cd:'', itm1Val:'',itm2Cd:'',itm2Val:'',itm3Cd:'',itm3Val:'',itm4Cd:'',itm4Val:'',itm5Cd:'',
                    itm5Val:'',itm6Cd:'',itm6Val:'',itm7Cd:'',itm7Val:'',itm8Cd:'',itm8Val:'',itm9Cd:'',itm9Val:'',
                    itm10Cd:'',itm10Val:'',itm11Cd:'',itm11Val:'',itm12Cd:'',itm12Val:'',itm13Cd:'',itm13Val:'',
                    wrtr:'',
                    wrtrMmbrPk:0,
                }

                items1                  = this.setItmVal(this.groups1)
                items1.wrtr             = this.items.wrtr
                items1.wrtrMmbrPk       = this.items.wrtrMmbrPk
                items1.ofrTmHhStrt      = ofrTmStrt[0]
                items1.ofrTmMmStrt      = ofrTmStrt[1]
                items1.ofrTmHhEnd       = ofrTmEnd[0]
                items1.ofrTmMmEnd       = ofrTmEnd[1]
                items1.bnftOfrRec301Pk  = this.items.recpk1

                hisObj1.recList.push(items1)
                obj.hisList.push(hisObj1)

                let hisObj2 = {
                    clsfc1Cd    : '301',
                    clsfc2Cd    : '5',
                    clsfc3Cd    : '2',
                    recDt       : this.items.recDt?.afterDateFormatDot(),
                    cntnt       : '',
                    spclNote    : this.items.spclNote,
                    wrtr        : this.items.wrtr,
                    wrtrMmbrPk  : this.items.wrtrMmbrPk,
                    recList     : [],
                    mdfDt       : this.items.mdfDt2,
                }

                let items2 = {                    
                    itm1Cd:'', itm1Val:'',itm2Cd:'',itm2Val:'',itm3Cd:'',itm3Val:'',itm4Cd:'',itm4Val:'',itm5Cd:'',
                    itm5Val:'',itm6Cd:'',itm6Val:'',itm7Cd:'',itm7Val:'',itm8Cd:'',itm8Val:'',itm9Cd:'',itm9Val:'',
                    itm10Cd:'',itm10Val:'',itm11Cd:'',itm11Val:'',itm12Cd:'',itm12Val:'',itm13Cd:'',itm13Val:'',
                    wrtr:'',
                    wrtrMmbrPk:'',
                }
                
                items2 = this.setItmVal(this.groups2)
                items2.wrtr             = this.items.wrtr
                items2.wrtrMmbrPk       = this.items.wrtrMmbrPk
                items2.ofrTmHhStrt      = ofrTmStrt[0]
                items2.ofrTmMmStrt      = ofrTmStrt[1]
                items2.ofrTmHhEnd       = ofrTmEnd[0]
                items2.ofrTmMmEnd       = ofrTmEnd[1]
                items2.bnftOfrRec301Pk  = this.items.recpk2

                hisObj2.recList.push(items1)
                obj.hisList.push(hisObj2)

                let target = this.groups1.filter(x=>x.valClcd == '21')

                target.forEach(el => {
                    obj.bathTmInfo.bathMthdcd = el.itmVal
                });
                
                // console.log(obj)

                if(obj.hisList.length > 0){
                    this.progress = true
                    insMultiBnftRcrd(obj)
                        .then((response) => ( this.onSaveAfter(response.data) ))
                        .catch((error) => console.log('upload error /bnftrcrd/insMultiBnftRcrd : ' + error))
                }

            }
        },
        onSaveAfter:function(res){       
            let obj = {}    

            if(res.statusCode == 200){                                                
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)

                this.$emit('modalEvent', 99)
                this.reLoad()
            }else if(res.statusCode == 205) {                
                obj.icon = 'mdi-alert'
                obj.iconClr = 'red'
                obj.title = res.message
                obj.cntnt = []

                if(res.messagesList != null && res.messagesList != undefined){
                    res.messagesList.forEach(el => {
                        obj.cntnt.push(el)
                    });
                }

                obj.cntnt.push('등록된 기록을 삭제하고 저장하시겠습니까?')
                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 저장하겠습니다.'

                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });   

                this.bathCnfm = true
            }else{
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
            this.progress = false
        },        
        getBnfcrBathTmInfo(){
            selBnfcrBathTmInfo(this.$store.getters.getFcltyNum,this.bnMmbrPk, this.inqYMD)
            .then((response) => ( this.getBnfcrBathTmInfoAfter(response.data)))
            .catch((error) => console.log(error))
        },
        getBnfcrBathTmInfoAfter(res){
            
            if(res.statusCode == 200){
                if(res.result != null && res.result != undefined){
                    this.items.ofrTmStrt = res.result.actBathTmHhStrt + ":"+res.result.actBathTmMmStrt
                    this.items.ofrTmEnd = res.result.actBathTmHhEnd + ":"+res.result.actBathTmMmEnd

                    this.bathTmInfo.bathSchdlPk = res.result.bathSchdlPk
                    this.bathTmInfo.actBathDt = res.result.actBathDt
                    this.bathTmInfo.bathMthdcd = res.result.bathMthdcd
                }
            } else {                
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                this.$store.commit('setWrnnInf', obj)
            }
            
        },
        getData(){

            let fcltyNum = this.$store.getters.getFcltyNum
            let obj = {
                fcltyNum: fcltyNum, 
                inqTFclsfc1Cd: true, 
                inqTFclsfc2Cd: true, 
                inqTFclsfc3Cd: true, 
                inqTFitmCd: true, 
                clsfc1Cd: '301',
                clsfc2Cd: '5',
                bnMmbrPk: this.bnMmbrPk,
                inqYMD:this.inqYMD,
            }
            this.progress = true
            selBnftOfrRecList(obj)            
                .then((response) => ( this.resData(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/selBnftOfrRecList : ' + error))
        },
        resData(res){
            this.progress = false
            if(res.statusCode == 200){
                if(res.result.length > 0){
                    this.setData(res.result)
                    this.filledBtn[0].visible = false                 
                    this.filledBtn[2].disabled = false 
                    this.outlinedBtn[0].disabled = false
                }else{
                    this.setBasicVal()                
                }      
            } else {               
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!) 
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                
                this.$store.commit('setWrnnInf', obj)
            }   
        },  
        setData(res){  
            if(res.length > 0){  
                this.filledBtn[1].disabled = false               
                this.rcrdInfo = res                   
                this.items.recDt = res[0].recDt?.beforeDateFormatDot(),
                this.items.ofrTmStrt = res[0].ofrTmHhStrt + ":"+res[0].ofrTmMmStrt
                this.items.ofrTmEnd = res[0].ofrTmHhEnd + ":"+res[0].ofrTmMmEnd
                this.items.wrtr = res[0].wrtr
                this.items.wrtrMmbrPk = res[0].wrtrMmbrPk
                this.items.spclNote = res[0].spclNote

                res.forEach(x=>{
                    if(x.clsfc3Cd == "1"){
                        this.getItmVal(this.groups1,x)
                        this.items.hispk1    = x.bnftOfrRecHisPk
                        this.items.recpk1    = x.bnftOfrRec301Pk
                        this.items.mdfDt1    = x.mdfDt
                    }else if(x.clsfc3Cd == "2"){
                        this.getItmVal(this.groups2,x)
                        this.items.hispk2    = x.bnftOfrRecHisPk
                        this.items.recpk2    = x.bnftOfrRec301Pk
                        this.items.mdfDt2    = x.mdfDt
                    }
                })
            }
        },    
        getPreData:function(){
            let fcltyNum = this.$store.getters.getFcltyNum
            let obj = {
                fcltyNum: fcltyNum, 
                inqTFclsfc1Cd: true, 
                inqTFclsfc2Cd: true, 
                inqTFclsfc3Cd: true, 
                inqTFitmCd: true, 
                clsfc1Cd: '301',
                clsfc2Cd: '5',
                bnMmbrPk: this.bnMmbrPk,
                inqYMD:this.inqYMD,
            }
            this.progress = true
            selPreBnftOfrRecList(obj)
                .then((response) => ( this.getPreDataAfter(response.data)))
                .catch((error) => console.log(error))
        } , 
        getPreDataAfter(res){
            // console.log(res)
            if(res.length > 0){  
                // this.filledBtn[1].disabled = false               
                // this.rcrdInfo = res                   
                // this.items.recDt = res[0].recDt?.beforeDateFormatDot(),
                // this.items.ofrTmStrt = res[0].ofrTmHhStrt + ":"+res[0].ofrTmMmStrt
                // this.items.ofrTmEnd = res[0].ofrTmHhEnd + ":"+res[0].ofrTmMmEnd
                // this.items.wrtr = res[0].wrtr
                // this.items.wrtrMmbrPk = res[0].wrtrMmbrPk
                // this.items.spclNote = res[0].spclNote

                res.forEach(x=>{
                    x.bnftOfrRecHisPk = 0
                    x.bnftOfrRecPk = 0
                    if(x.clsfc3Cd == "1"){
                        this.getItmVal(this.groups1,x)
                    }else if(x.clsfc3Cd == "2"){
                        this.getItmVal(this.groups2,x)
                    }
                })
            }
            this.progress = false
        },
        setItmVal(targetObj){
            let iptObj = {}

            targetObj.forEach(x=>{
                switch(x.itmCd){
                    case "1" : 
                        iptObj.itm1Cd = x.itmCd
                        iptObj.itm1Val = x.itmVal
                        break;
                    case "2" : 
                        iptObj.itm2Cd = x.itmCd
                        iptObj.itm2Val = x.itmVal
                        break;
                    case "3" : 
                        iptObj.itm3Cd = x.itmCd
                        iptObj.itm3Val = x.itmVal
                    break;
                    case "4" : 
                        iptObj.itm4Cd = x.itmCd
                        iptObj.itm4Val = x.itmVal
                    break;
                    case "5" : 
                        iptObj.itm5Cd = x.itmCd
                        iptObj.itm5Val = x.itmVal
                    break;
                    case "6" : 
                        iptObj.itm6Cd = x.itmCd
                        iptObj.itm6Val = x.itmVal
                    break;
                    case "7" : 
                        iptObj.itm7Cd = x.itmCd
                        iptObj.itm7Val = x.itmVal
                    break;
                    case "8" : 
                        iptObj.itm8Cd = x.itmCd
                        iptObj.itm8Val = x.itmVal
                    break;
                    case "9" : 
                        iptObj.itm9Cd = x.itmCd
                        iptObj.itm9Val = x.itmVal
                    break;
                    case "10" : 
                        iptObj.itm10Cd = x.itmCd
                        iptObj.itm10Val = x.itmVal
                    break;
                    case "11" : 
                        iptObj.itm11Cd = x.itmCd
                        iptObj.itm11Val = x.itmVal
                    break;
                    case "12" : 
                        iptObj.itm12Cd = x.itmCd
                        iptObj.itm12Val = x.itmVal
                    break;
                    case "13" : 
                        iptObj.itm13Cd = x.itmCd
                        iptObj.itm13Val = x.itmVal
                    break;
                    default : 
                        break;

                }
            })    
            
            return iptObj
        },
        getItmVal(targetObj, iptObj){
            targetObj.forEach(e=>{
                switch(e.itmCd){
                    case "1" : 
                        e.itmVal = iptObj.itm1Val
                        break;
                    case "2" : 
                        e.itmVal = iptObj.itm2Val
                        break;
                    case "3" : 
                        e.itmVal = iptObj.itm3Val
                    break;
                    case "4" : 
                        e.itmVal = iptObj.itm4Val
                    break;
                    case "5" : 
                        e.itmVal = iptObj.itm5Val
                    break;
                    case "6" : 
                        e.itmVal = iptObj.itm6Val
                    break;
                    case "7" : 
                        e.itmVal = iptObj.itm7Val
                    break;
                    case "8" : 
                        e.itmVal = iptObj.itm8Val
                    break;
                    case "9" : 
                        e.itmVal = iptObj.itm9Val
                    break;
                    case "10" : 
                        e.itmVal = iptObj.itm10Val
                    break;
                    case "11" : 
                        e.itmVal = iptObj.itm11Val
                    break;
                    case "12" : 
                        e.itmVal = iptObj.itm12Val
                    break;
                    case "13" : 
                        e.itmVal = iptObj.itm13Val
                    break;
                    default : 
                        break;

                }
            })
        },
        getCmnItmCd(){
            let fcltyNum = this.$store.getters.getFcltyNum
            let obj = {
                code: fcltyNum, 
                inqTFclsfc1Cd: true, 
                inqTFclsfc2Cd: true, 
                inqTFclsfc3Cd: true, 
                inqTFitmCd: true, 
                clsfc1Cd: '301',
                clsfc2Cd: '5',
            }
            
            getCmnItmCd(obj)
            .then((response) => ( this.getCmnItmCdAfter(response.data)))
            .catch((error) => console.log(error))
        },
        getCmnItmCdAfter(res){
            
            this.groups1.splice(0)
            this.groups2.splice(0)
            res.forEach(element => {
                if(element.clsfc3Cd == '1'){
                    element.itmVal = '0'                    
                    this.groups1.push(element)
                }else if(element.clsfc3Cd == '2'){
                    element.itmVal = '0'
                    this.groups2.push(element)
                }
            });     
            this.getBnfcrBathTmInfo()
            this.getData()
        },

        setBasicVal:function(){
            this.groups1.forEach(element => {                    
                //초기값
                switch (element.valClcd) {
                    case '1':
                        element.itmVal = '2'
                        break;
                    case '16':
                        element.itmVal = '1'
                        break;
                    case '17':
                        element.itmVal = '4'
                        break;
                    case '18':
                        element.itmVal = '1'
                        break;
                    case '19':
                        element.itmVal = '2'
                        break;
                    case '20':
                        element.itmVal = '1'
                        break;
                    case '21':
                        element.itmVal = (this.bathMthdcd != '')? this.bathMthdcd:'3'
                        break;
                    default:
                        break;
                }
            });

            this.setCopyVal()
        },

        setCopyVal:function(){
            this.groups1.forEach((el,key) => { 
                if(this.groups2[key] != undefined) this.groups2[key].itmVal = el.itmVal
            });
        },
           
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
        getEmpInfo: function (obj) {
            this.items.wrtr = obj[0].name
            this.items.wrtrMmbrPk = obj[0].mmbrPk
        },
        onModal: function (value) {
            this.prtWrtDt = this.inqYMD            
            this.dialog.splice(value, 1, true)
        },
        isModal: function (value) {
            this.dialog.splice(value, 1, false)
        },
        onforce(){
            this.forceRender++
        },
        rmBathAllBnftOfrRec:function(){
            this.progress = true
            let fcltyNum = this.$store.getters.getFcltyNum                    
            let obj = {
                fcltyNum:fcltyNum,
                userNm:this.$store.getters.getUserNm,
                bathSchdlPk:this.bathTmInfo.bathSchdlPk,
                list:[
                    {
                        recDt: this.items.recDt?.afterDateFormatDot(),
                        clsfc1Cd: '301',
                        clsfc2Cd: '5',
                        bnMmbrPk : this.bnMmbrPk,
                        clsfc3CdS : ['1','2']
                    },
                ]
            }              
            
            delBathAllBnftOfrRec(obj)                
                .then((response) => ( this.rmAllBnftOfrRecAfter(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/delBathAllBnftOfrRec : ' + error))
        },
        rmAllBnftOfrRecAfter(res){
            this.frmReset()            
            this.progress = false

            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀
            obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200){
                obj.tmr = 2500      
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.$emit('modalEvent', 99)
                this.onClose()
            } else {                
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
            }
            
            this.$store.commit('setWrnnInf', obj)
        },                
        bathObsrvRcrdPrt :function(){
            let obj = {}
            obj.rptTyp = '1'
            obj.mode = '2'
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnMmbrPk = this.bnMmbrPk            
            obj.prtClcd = '1'
            obj.inqClcd = 2
            obj.prtCd = '60'
            obj.inqYYYY = ''
            obj.inqYmdStart = this.$moment(this.items.recDt,'YYYY.MM.DD').format('YYYYMMDD')
            obj.inqYmdEnd = this.$moment(this.items.recDt,'YYYY.MM.DD').format('YYYYMMDD')
            obj.list = [ { bzClcd: [], bzPk: [] } ]
            
            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: obj, outObj: null, runEvt: true,
            });
        }
    },
    data: () => ({   
        bathCnfm :false,
        rmBathCnfm :false,
        isPreData:false,
        prtWrtDt:'',                
        forceRender:0, 
        rtimes1:'',
        mtimes1:'',
        rtimes2:'',
        mtimes2:'',
        groups1:[{clsfc1Cd: "",clsfc1Cdnm: "",clsfc2Cd: "",clsfc2Cdnm: "",clsfc3Cd: "",clsfc3Cdnm: "",itmCd: "",itmCdnm: "",itmDesc: "",valClcd: "",itmVal:'0',bnftOfrRecHisPk:0,bnftOfrRec301Pk:0,mdfr:''}],
        groups2:[{clsfc1Cd: "",clsfc1Cdnm: "",clsfc2Cd: "",clsfc2Cdnm: "",clsfc3Cd: "",clsfc3Cdnm: "",itmCd: "",itmCdnm: "",itmDesc: "",valClcd: "",itmVal:'0',bnftOfrRecHisPk:0,bnftOfrRec301Pk:0,mdfr:''}],
        items:{recDt:'',wrtr:'',wrtrMmbrPk:0,ofrTmStrt:'',ofrTmEnd:'',spclNote:''},   
        bathTmInfo :{
            bathSchdlPk:0,
            actBathDt:'',
            bathMthdcd:'',
        }, 
        rcrdInfo : [],
        menu: [false],
        dialog : [false],
        codeItem1:[{valcd:'',valcdnm:''}],
        codeItem16:[{valcd:'',valcdnm:''}],
        codeItem17:[{valcd:'',valcdnm:''}],
        codeItem18:[{valcd:'',valcdnm:''}],
        codeItem19:[{valcd:'',valcdnm:''}],
        codeItem20:[{valcd:'',valcdnm:''}],
        codeItem21:[{valcd:'',valcdnm:''}],        
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
        filledBtn: [
            { icon: 'mdi-text-box-search-outline', class: 'white--text', color:'#3C9099', text: '전일자료', type:'action', visible: true, disabled: false,},
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type:'action', visible: true, disabled: false,},
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type: 'action', visible: true, disabled: true,},
        ],    
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006 ', text: '출력', disabled: true},
        ],        
        progress:false
    }),
};
</script>