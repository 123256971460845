<template>
    <v-sheet class="ma-0 pl-1 py-1" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <h4 class="text-h4 font-weight-bold">출력&nbsp;관리</h4>
            <v-btn
                class="ml-2 mt-1" width="30" height="30"
                @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 9)"
                icon>
                <v-icon size="30" style="padding-top: 2px;">mdi-help-circle-outline</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="$parent.$parent.$parent.$parent.mdlOff" x-large>mdi-close</v-icon>
        </v-row>
        
        <v-row v-if="mode == '2'" class="pa-0 mt-3" no-gutters>
            <v-col class="pr-2" cols="5">
                <v-row class="pa-0 align-center" no-gutters>
                    <v-icon
                        class="fontOneDotEgtRem" style="width: 24px;"
                        color="blue600 darken-1">
                        mdi-square-rounded-outline
                    </v-icon>
                    <h6 class="text-h6 font-weight-bold pl-2">출력물&nbsp;종류</h6>
                </v-row>
                <v-row class="pt-3 pl-7 pr-1 align-center" no-gutters>
                    <v-radio-group
                        class="radio-dft-Theme mt-0 pr-1" style="width: 180px;"
                        v-model="sltPrtClcd"
                        hide-details row>
                        <v-radio class="pa-0 mr-2" :ripple="false" label="데이터 조회" value="1"></v-radio>
                        <v-radio class="pa-0 mr-2" :ripple="false" label="양식" value="2"></v-radio>
                    </v-radio-group>
                    <v-spacer></v-spacer>
                    <span class="d-inline-block" style="width: 160px; height: 100%;">
                        <v-text-field
                            class="prtSearch" height="28" label="출력물 조회"
                            v-model="prtNmText" background-color="white" append-icon="mdi-magnify"
                            @input="prtListSelector()"
                            single-line rounded outlined dense filled hide-details>
                        </v-text-field>
                    </span>
                </v-row>
                <v-row class="mt-4 pl-7 align-center" no-gutters>
                    <span class="d-inline-block mx-1" style="width: 100%;">
                        <v-select class="selectCustom" @change="prtSelectChecker()" :key="forcerender"
                            v-model="sltInq" item-text="prtNm" item-value="prtCd" :items="prtList"
                            label="출력물 선택" dense hide-details outlined>
                        </v-select>
                    </span>
                </v-row>
            </v-col>
            <v-col class="" cols="4">
                <v-row class="pa-0 align-center" no-gutters>
                    <v-icon
                        class="fontOneDotEgtRem" style="width: 24px;"
                        color="blue600 darken-1">
                        mdi-square-rounded-outline
                    </v-icon>
                    <h6 class="text-h6 font-weight-bold pl-2">조회&nbsp;조건</h6>
                </v-row>
                <div class="pt-3 pl-6">
                    <v-row class="pa-0 align-center" no-gutters>
                        <span v-if="inqCnd[1]" class="d-inline-block ml-2 mb-3" style="width: 120px;">
                            <v-select
                                v-model="year" :items="yearList" label="연도선택" class="sltCtm"
                                @change="prtCndtn"
                                dense hide-details outlined>
                            </v-select>
                        </span>
                        <v-form
                            v-if="inqCnd[0] || inqCnd[8]"
                            class="d-flex mt-4 ml-2 mb-3" style="width: 100%;"
                            ref="inqYYMM"
                            lazy-validation>
                            <span class="d-inline-block" style="width: 90px;">
                                <v-menu
                                    ref="menu" v-model="menu[1]"
                                    :close-on-content-click="false" :return-value.sync="yymmStrt"
                                    transition="scale-transition" offset-y min-width="auto" dense>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="yymmStrt" :label="inqCnd[0] ? '연월조회' : '연월시작'"
                                            class="txtHzt-Theme" height="28"
                                            v-bind="attrs" v-on="on"
                                            readonly hide-details outlined dense>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="yymmStrt" :key="forcerender"
                                        type="month" 
                                        @click:month="$refs.menu.save(yymmStrt), prtCndtn()"
                                        no-title scrollable>
                                    </v-date-picker>
                                </v-menu>
                            </span>
                            <span v-if="inqCnd[8]" class="d-inline-block d-flex" style="width: 128px;">
                                <span class="mx-2">
                                    <v-icon size="16" color="greyE00">mdi-tilde</v-icon>
                                </span>
                                <v-menu
                                    ref="menu1" v-model="menu[2]"
                                    :close-on-content-click="false" :return-value.sync="yymmEnd"
                                    transition="scale-transition" offset-y min-width="auto" dense>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="yymmEnd"
                                            class="txtHzt-Theme" label="연월종료" height="28"
                                            v-bind="attrs" v-on="on"
                                            readonly hide-details outlined dense>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="yymmEnd" :key="forcerender"
                                        type="month" 
                                        @click:month="$refs.menu1.save(yymmEnd), prtCndtn()"
                                        no-title scrollable>
                                    </v-date-picker>
                                </v-menu>
                            </span>
                        </v-form>
                    </v-row>
                    <div class="d-flex justify-left align-center mt-1">
                        <span v-if="inqCnd[2]" class="d-inline-block ml-2" style="width:120px;">
                            <v-text-field
                                v-model="bnfcrInfo.name" class="txtHzt-Theme" label="수급자 선택" height="28"
                                hide-details readonly outlined dense>
                            </v-text-field>
                        </span>

                        <v-btn
                            v-if="inqCnd[2]"
                            class="ml-2 deep-orange lighten-3"
                            style="padding: 0px 1px 0px 2px !important; margin-bottom: 2px !important;"
                            min-width="30" height="26"  
                            @click="onModal(1)"
                            dark small>
                            <v-icon size="20">mdi-account-multiple</v-icon>
                        </v-btn>
                
                        <span v-if="inqCnd[3]" class="d-inline-block ml-2" style="width:120px;">
                            <v-text-field
                                v-model="empInfo.name" class="txtHzt-Theme" label="직원 선택" height="28"
                                hide-details readonly outlined dense>
                            </v-text-field>
                        </span>

                        <v-btn
                            v-if="inqCnd[3]"
                            class="ml-2 brown lighten-3"
                            style="padding: 0px 1px 0px 2px !important; margin-bottom: 2px !important;"
                            min-width="30" height="26"  
                            @click="onModal(0)"
                            dark small>
                            <v-icon size="20">mdi-account-multiple</v-icon>
                        </v-btn>

                    </div>
                    <v-form
                        class="d-flex justify-left align-center mt-4 ml-1"
                        ref="inqYMD"
                        lazy-validation>
                        <span
                            v-if="inqCnd[4] || inqCnd[7]"
                            class="d-inline-block py-1 px-1"
                            style="width: 106px;">
                            <CmmDateComp
                                v-model="inqYmdStart"
                                :label="inqCnd[4] ? '시작일' : '연월일조회'"
                                :minDt="$moment(inqYmdEnd, 'YYYY-MM-DD').subtract(1, 'months').add(1, 'days').format('YYYY-MM-DD')"
                                :required="false" :btnActv="false" :succBck="true"
                                @input="prtCndtn()" @blur="prtCndtnVdn()">
                            </CmmDateComp>
                        </span>
                        
                        <span v-if="inqCnd[4]" class="d-inline-block d-flex" style="width: 136px;">
                            <span class="d-flex align-center mx-2">
                                <v-icon size="16" color="greyE00">mdi-tilde</v-icon>
                            </span>
                            <span
                                v-if="inqCnd[4] || inqCnd[7]"
                                class="d-inline-block py-1 px-1"
                                style="width: 106px;">
                                <CmmDateComp
                                    v-model="inqYmdEnd"
                                    label="종료일"
                                    :maxDt="$moment(inqYmdStart, 'YYYY-MM-DD').add(1, 'months').subtract(1, 'days').format('YYYY-MM-DD')"
                                    :required="false" :btnActv="false" :succBck="true"
                                    @input="prtCndtn()">
                                </CmmDateComp>
                            </span>
                            
                        </span>
                    </v-form>
                </div>
            </v-col>
            <v-col class="pr-3" cols="3">
                <v-row class="pa-0 align-center" no-gutters>
                    <v-icon
                        class="fontOneDotEgtRem" style="width: 24px;"
                        color="blue600 darken-1">
                        mdi-square-rounded-outline
                    </v-icon>
                    <h6 class="text-h6 font-weight-bold pl-2">추가&nbsp;내용</h6>
                    <v-spacer></v-spacer>
                    <span 
                        v-if="prtList.filter(v => v.prtCd == this.sltInq).length == 1 ? true : false"
                        class="" style="padding-top: 3px;">
                        <v-btn
                            v-if="prtList.filter(v => v.prtCd == this.sltInq)[0].txtLbl.length > 0"
                            width="20" height="20" @click="prtCndtn()" icon>
                            <v-icon size="20">mdi-magnify</v-icon>
                        </v-btn>
                    </span>
                </v-row>
                <div class="pt-4 pl-6">
                    <span v-if="inqAddFld[0].onFld" class="d-inline-block ml-2 mb-3" style="width: 100%;">
                        <v-text-field
                            v-model="inTxt1" :label="inqAddFld[0].lbl"
                            class="txtHzt-Theme" height="28"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span v-if="inqAddFld[1].onFld" class="d-inline-block ml-2 mb-3" style="width: 100%;">
                        <v-text-field
                            v-model="inTxt2" :label="inqAddFld[1].lbl"
                            class="txtHzt-Theme" height="28"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                </div>
            </v-col>
        </v-row>
        <div class="posn-rltv mt-3">
            <div
                v-if="loadable"
                class="d-inline-block txtAlgnMid"
                style="width: 100%; height: 38px; position: absolute; top: 46px; left: 0;">
                <v-progress-linear
                    class="mb-1" color="yellow darken-2"
                    indeterminate>
                </v-progress-linear>
                <span class="greyC00--text font-weight-medium" style="font-size: 0.95rem;">불러오는&nbsp;중...</span>
            </div>
            <PdfViewer ref="pdfView" :url="pdfUrl" :pdfPath="pdfPath" :btnAct="pdfPath.filePth == ''"></PdfViewer>
            <v-divider class="mt-1"></v-divider>
        </div>
        <v-dialog v-model="menu[0]" max-width="700" content-class="round" persistent eager>
            <!-- 
                mdlTtl: 팝업 타이틀(누락시 = 참석자 선택), tabInfList: 활성화 탭 배열 ( '1': 수급자, '2': 직원 ),
                cd41, cd42: 직원 탭에서 사용하는 공통코드 (필수), intgrResList: Array - 팝업에서 선택된 목록
                mode: String - 단일 / 멀티 선택 ( '1': 단일, '2': 멀티, default: '2' )
                fixList: 선택을 제한할 목록, onClose: String - 결과 값 0 고정
                * fixList: 오브젝트에 다음 키를 추가 해야함 ( disChekr:boolean, checkr:String )
                ** disChekr: 체크 가능여부,  checkr: 체크박스 모델
             -->
            <IntgrSlctn
                :mdlTtl="mdlTtl" :tabInfList="tabInfList" :fixList="[]" :mode="'1'"
                :cd41="cd41" :cd42="cd42" :cd65="cd65" :rtrInc="true"
                @intgrResList="getAplyList" @onClose="menu.splice(0, 1, false)">
            </IntgrSlctn> 
        </v-dialog>
    </v-sheet>
</template>

<script>
import { selPrtList, selReportInfo, selMultiReportInfo } from '../../api/index.js';
import { remoteURL } from '@/api/baseAxios';

import IntgrSlctn from './IntgrSlctn.vue';

import PdfViewer from './PdfViewer.vue';

import CmmDateComp from './CmmDateComp.vue';

export default {
    name: 'ReportMgmt',

    props : {
        cd41: { type: Array, default: () => { return [] } },
        cd42: { type: Array, default: () => { return [] } },
        cd65: { type: Array, default: () => { return [] } },
        bzClCd: { type: Array, default: () => { return [] } },
        inqCd: { type: Array, default: () => { return [] } },
    },
            
    components: {
        PdfViewer,
        IntgrSlctn,
        CmmDateComp,
    },

    created: function(){

        this.year = new Date().getFullYear()
        let yearListAfter = []
        for (let index = 1950; index <= this.year; index++) {
            yearListAfter.push(index)
        }
        this.yearList = yearListAfter.reverse()
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(async function () {
            //출력물 리스트 조회
            await this.getPrtList()
       
            //팝업호출과 동시에 pdf 조회
            if(this.$store.getters.getRootRunEvt){
                this.mode = this.$store.getters.getRootInObj.mode
                switch (this.$store.getters.getRootInObj.rptTyp) {
                    case '1':
                        this.prtDataCall(this.$store.getters.getRootInObj)
                        break;
                    case '2':
                        this.prtMltDataCall(this.$store.getters.getRootInObj)
                        break;
                    case '3':
                        this.prtFmlyLeterCall(this.$store.getters.getRootInObj.pdfUrl, this.$store.getters.getRootInObj.pdfPath)
                        break;
                    default:
                        break;
                }
                this.$store.commit('setRootRunEvt', false)
            }
        })
    },

    computed: {
        getRootEvt: function () {
            return this.$store.getters.getRootRunEvt
        },
    },

    watch:{
        'bzClCd': function () {
            if(this.bzClCd.length != 0 && this.prtList.length != 0)
                this.prtSelectChecker()
        },
        'prtList': function () {
            if(this.bzClCd.length != 0 && this.prtList.length != 0)
                this.prtSelectChecker()
        },
        'sltPrtClcd': function () {
            if(this.bzClCd.length != 0 && this.prtList.length != 0){
                this.prtListSelector()
            }
        },
        'sltInq': function () {
            let obj = {}
            obj.bzPk = []
            obj.bzClcd = []
            obj.list = [{ bzClcd: [], bzPk: [] }]
            obj.fcltyNum = this.$store.getters.getFcltyNum
            if(this.sltPrtClcd == '2'){
                obj.prtClcd = this.sltPrtClcd 
                obj.prtCd = this.sltInq
                this.getPdfPath(obj)
            }
        },
    },
        
    methods: {
        getPrtList: async function () {
            await selPrtList()
                .then((response) => ( this.getPrtListAfter(response.data) ))
                .catch((error) => console.log('connect error /report/selPrtList : ' + error))
        },

        getPdfPath: function (obj) {
            this.loadable = true

            selReportInfo(obj)
                .then((response) => ( this.getPdfPathAfter(response.data) ))
                .catch((error) => console.log('connect error /report/selReportInfo : ' + error))
        },

        getMltPdfPath: function (obj) {
            this.loadable = true

            selMultiReportInfo(obj)
                .then((response) => ( this.getPdfPathAfter(response.data) ))
                .catch((error) => console.log('connect error /report/selMultiReportInfo : ' + error))
        },

        getPrtListAfter: function (res) {
            this.prtList.splice(0)

            this.orgPrtList = res

            this.orgPrtList.forEach((items, i) => {
                if(items.prtNm == '가정통신문') this.orgPrtList.splice(i, 1)
            });
        
            this.prtList = this.orgPrtList.filter(v => v.prtClcd == this.sltPrtClcd)
        },

        getPdfPathAfter: function (res) {
            if(typeof res.filePth != 'undefined'){
                let path = ''

                if(this.sltPrtClcd == '1')
                    path = remoteURL + 'opr/imgPrview?'
                else
                    path = remoteURL + 'opr/imgView?'

                if(res.statusCode?.slice(0, 2) == '20'){
                    if(res.statusCode == 205){
                        let obj = {}
                        obj.actv = true                 // 경고 노출여부
                        obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                        obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                        obj.title = res.message         // 경고 타이틀
                        obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                        this.$store.commit('setWrnnInf', obj)
                    }

                    let fileName = 'fileNm=' + res.fileNm
                    let filePath = 'filePth=' + res.filePth
                    let fileCode = 'fileKndCd=2'

                    this.pdfUrl = path + fileName + '&' + filePath + '&' + fileCode + '&' + 't=' + Math.random()
                    
                    this.pdfPath.filePth = res.filePth
                    this.pdfPath.fileNm = res.fileNm
                    this.pdfPath.orgFileNm = res.orgFileNm?.replaceAll(' ', '_')
                    
                    this.loadable = false
                }
            }
            else{
                this.$refs.pdfView.clear()
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 3500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'              // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)

                this.pdfPath.filePth = ''
                this.pdfPath.fileNm = ''
                this.pdfPath.orgFileNm = ''
            }
        },

        prtSelectChecker: function () {
            this.pdfUrl = ''
            this.pdfPath.filePth = ''
            this.pdfPath.fileNm = ''
            this.pdfPath.orgFileNm = ''

            this.yymmStrt = ''
            this.yymmEnd = ''
            this.bnfcrInfo.name = ''
            this.bnfcrInfo.bnMmbrPk = 0
            this.bnfcrInfo.bnfcrPk = 0
            this.empInfo.name = ''
            this.empInfo.empPk = 0
            this.year = ''
            this.inqYmdStart = ''
            this.inqYmdEnd = ''
            this.inTxt1 = ''
            this.inTxt2 = ''

            this.$refs.pdfView.clear()

            let pkCheckList = []
            let sltObj = this.prtList.filter(v => v.prtCd == this.sltInq)
          
            for (let index = 0; index < this.inqCnd.length; index++) {
                this.inqCnd.splice(index, 1, false)
            }
            for (let index = 0; index < this.inqAddFld.length; index++) {
                this.inqAddFld.splice(index, 1, { onFld: false, lbl: '' })
            }

            sltObj.forEach(items => {
                if(items.inqCd != null){
                    items.inqCd.forEach(item => {
                        pkCheckList.push(item.prtPk)
                        if(item != null || item != '')
                            this.inqCnd.splice(Number(item)-1, 1, true)
                    });
                }
                if(items.txtLbl.length > 0){
                    items.txtLbl.forEach((item, i) => {
                        this.inqAddFld.splice(i, 1, { onFld: true, lbl: item })
                    });
                }
            });

            this.forcerender += 1
            
        },

        prtDataCall: function (obj) {
            this.$refs.pdfView.clear()
            
            for (let index = 0; index < this.inqCnd.length; index++) {
                this.inqCnd[index] = false
            }

            this.sltPrtClcd = obj.prtClcd
            this.sltInq = obj.prtCd

            this.prtList.splice(0)
            this.prtList = this.orgPrtList.filter(v => v.prtClcd == this.sltPrtClcd)

            if(obj.prtCd != '' && obj.prtCd != null)
                this.getPdfPath(obj)
            this.forcerender += 1
        },

        prtMltDataCall: function (obj) {
            this.$refs.pdfView.clear()

            for (let index = 0; index < this.inqCnd.length; index++) {
                this.inqCnd[index] = false
            }

            this.sltPrtClcd = obj.prtClcd
            this.sltInq = obj.prtCd

            this.prtList.splice(0)
            this.prtList = this.orgPrtList.filter(v => v.prtClcd == this.sltPrtClcd)

            if(obj.prtCd != '' && obj.prtCd != null)
                this.getMltPdfPath(obj)
            
            this.forcerender += 1
        },

        prtFmlyLeterCall: function (url, obj) {
            if(typeof obj.filePth == 'undefined'){
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 3500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'              // 경고 유형 (success, info, warning, error)
                obj.title = '가정통신문이 등록되지 않아 출력에 실패했습니다.'
                obj.cntnt = []                  // 경고 내용 (arr만 출력됨!)
    
                this.$store.commit('setWrnnInf', obj)
            }
            else{
                this.pdfUrl = url
    
                this.pdfPath.filePth = obj.filePth
                this.pdfPath.fileNm = obj.fileNm
                this.pdfPath.orgFileNm = obj.orgFileNm
    
                this.loadable = false
            }
        },

        prtListSelector: function () {
            let dumm = []
            if(this.orgPrtList != null){
                this.orgPrtList.forEach((items ,i) => {
                    if(items.prtNm.match(this.prtNmText) == this.prtNmText && items.prtClcd == this.sltPrtClcd)
                        dumm.push(this.orgPrtList[i])
                });
            }
            this.prtList.splice(0)
            this.prtList = dumm
        },

        prtCndtn: function () {
            this.prtThrowObj.list[0].bzPk.splice(0)
            this.prtThrowObj.list[0].bzClcd.splice(0)

            let chk = true
            // let chkSub = true
            let sltObj = this.prtList.filter(v => v.prtCd == this.sltInq)
            let obj = Object.assign({}, this.prtThrowObj)
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.prtClcd = this.sltPrtClcd
            obj.prtCd = this.sltInq
            obj.inTxt1 = this.inTxt1
            obj.inTxt2 = this.inTxt2

            sltObj.forEach(items => {
                items.inqCd.forEach(item => {
                    switch (item) {
                        case '1':
                            if(this.yymmStrt == '' || items.txtLbl.length > 0 )
                                chk = false
                            break;
                        case '2':
                            if(this.year == '' || items.txtLbl.length > 0)
                                chk = false
                            break;
                        case '3':
                            if(this.bnfcrInfo.name == '' || items.txtLbl.length > 0)
                                chk = false
                            break;
                        case '4':
                            if(this.empInfo.name == '' || items.txtLbl.length > 0)
                                chk = false
                            break;
                        case '5':
                            if(this.inqYmdStart == '' || this.inqYmdEnd == '' || items.txtLbl.length > 0)
                                chk = false
                            if(this.$moment(this.inqYmdStart, 'YYYY-MM-DD').format('YYYY-MM-DD') == 'Invalid date')
                                chk = false
                            if(this.$moment(this.inqYmdEnd, 'YYYY-MM-DD').format('YYYY-MM-DD') == 'Invalid date')
                                chk = false
                            if(this.$moment(this.inqYmdEnd, 'YYYY-MM-DD').subtract(1, 'months').add(1, 'days').diff(this.$moment(this.inqYmdStart, 'YYYY-MM-DD')) > 0)
                                chk = false
                            if(this.$moment(this.inqYmdStart, 'YYYY-MM-DD').add(1, 'months').subtract(1, 'days').diff(this.$moment(this.inqYmdEnd, 'YYYY-MM-DD')) < 0)
                                chk = false
                            break;
                        case '8':
                            if(this.inqYmdStart == '' || items.txtLbl.length > 0)
                                chk = false
                            break;
                        case '9':
                            if( this.yymmStrt == '' || this.yymmEnd == '' )
                                chk = false
                            if(this.$moment(this.yymmEnd, 'YYYY-MM').diff(this.$moment(this.yymmStrt, 'YYYY-MM')) == 'NaN')
                                chk = false
                            if(this.$moment(this.yymmEnd, 'YYYY-MM').diff(this.$moment(this.yymmStrt, 'YYYY-MM')) < 0)
                                chk = false
                            break;
                        default:
                            chk = true
                            break;
                    }
                });

                if(items.txtLbl.length == 1){
                    if(this.inTxt1 != '')
                        chk = true
                }
                else if(items.txtLbl.length == 2){
                    if(this.inTxt1 != '' && this.inTxt2 != '')
                        chk = true
                }

                // if(items.txtLbl.length == 1){
                //     if(this.inTxt1 == '')
                //         chkSub = false
                // }
                // else if(items.txtLbl.length == 2){
                //     if(this.inTxt1 == '' || this.inTxt2 == '')
                //         chkSub = false
                // }
                // else if(items.txtLbl.length > 2)
                //     chkSub = false
            });
            
            if(chk){
                if(this.bnfcrInfo.bnMmbrPk != 0){
                    obj.bnMmbrPk = this.bnfcrInfo.bnMmbrPk
                    obj.bnfcrPk = this.bnfcrInfo.bnfcrPk
                }
                if(this.empInfo.empPk != 0)
                    obj.list[0].bzPk.push(this.empInfo.empPk)
                if(this.yymmStrt != '' && this.yymmEnd != ''){
                    obj.inqYyMmStart = this.$moment(this.yymmStrt, 'YYYY-MM').format('YYYYMM')
                    obj.inqYyMmEnd = this.$moment(this.yymmEnd, 'YYYY-MM').format('YYYYMM')
                }
                else if(this.yymmStrt != '')
                    obj.inqYYMM = this.yymmStrt?.afterDateFormatMonthHyp()
                if(this.year != '')
                    obj.inqYYYY = this.year
                if(this.inqYmdStart != '' && this.inqYmdEnd != ''){
                    obj.inqYmdStart = this.inqYmdStart?.afterDateFormatHyp()
                    obj.inqYmdEnd = this.inqYmdEnd?.afterDateFormatHyp()
                }
                else if(this.inqYmdStart != '')
                    obj.inqYMD = this.inqYmdStart?.afterDateFormatHyp()

                //console.log(obj)
                this.getPdfPath(obj)
            }
        },

        prtCndtnVdn: function (){
            if(this.inqYmdStart == '' || this.inqYmdEnd == '')
                this.$refs.inqYMD.resetValidation()
            else
                this.$refs.inqYMD.validate()
        },

        getAplyList: function (arr){
            if(this.inqCnd[2] == true){
                arr.forEach(el => {
                    this.bnfcrInfo.name = el.name
                    this.bnfcrInfo.bnMmbrPk = el.bnMmbrPk
                    this.bnfcrInfo.bnfcrPk = el.bnfcrPk
                });
            }
            else if(this.inqCnd[3] == true){
                arr.forEach(el => {
                    this.empInfo.name = el.name
                    this.empInfo.empPk = el.empPk
                });
            }

            this.prtCndtn()
        },

        clearComp: function() {
            this.$refs.pdfView.clear()
            this.loadable = true
        },

        onModal: function(index) {
            this.tabInfList.splice(0)

            if(index == 0){
                this.mdlTtl = '직원 선택'
                this.tabInfList.push('2')
            }
            else if(index == 1){
                this.mdlTtl = '수급자 선택'
                this.tabInfList.push('1')
            }

            this.menu.splice(0, 1, true)
        },
    },
    
    data: () => ({
        mode: '2',
        loadable: false,
        menu: [ false, false, false, false ],
        inqCnd: [ false, false, false, false, false, false, false, false, false, false ],
        inqAddFld : [
            { onFld: false, lbl: '' }, { onFld: false, lbl: '' },
            { onFld: false, lbl: '' }, { onFld: false, lbl: '' }, { onFld: false, lbl: '' },
        ],
        inTxt1: '',
        inTxt2: '',
        forcerender: 0,
        sltPrtClcd: '1',
        prtNmText: '',
        sltInq : '1',
        yymmStrt: '',
        yymmEnd: '',
        inqYmdStart: '',
        inqYmdEnd: '',
        bnfcrInfo: { name: '', bnMmbrPk: 0, bnfcrPk: 0 },
        empInfo: { name: '', empPk: 0 },
        year: '',
        yearList: [],
        prtList: [],
        orgPrtList: null,
        mdlTtl: '',
        tabInfList: [],
        pdfPath: { filePth: '', fileNm: '', orgFileNm: '' },
        pdfUrl: remoteURL + 'report/pdfView?inqYYMM=202210&prtCd=1&fcltyNum=14111000790&prtClcd=1',
        prtThrowObj: { fcltyNum: 0, bnMmbrPk: 0, prtClcd: '', inqClcd: 1, prtCd: '', list: [ { bzClcd: [], bzPk: [] } ], },
    }),
};
</script>

<style>
    .radioCustom div i { color: #666 !important; font-size: 20px !important; }
    .radioCustom label { font-weight: 500 !important; font-size: 16px !important; }
    .prtSearch div div div label { margin-top: 0px; top: 2px !important; }
    .prtSearch div .v-input__slot { padding: 0 12px 0 16px !important; }
    .prtSearch div .v-input__slot .v-input__append-inner { margin-top: 3px !important; }

    .selectCustom .v-input__control { background-color: white; }
    .selectCustom .v-input__control .v-input__slot { 
        min-height: 40px !important; padding-left: 18px !important; padding-right: 12px !important;
    }
    .selectCustom .v-input__control .v-input__slot label {
        font-size: 1.1rem !important; left: -5px !important; min-height: 26px !important;
    }
    .selectCustom .v-input__control .v-input__slot .v-label { top: 6px !important; }
    .selectCustom .v-input__control .v-input__slot .v-label--active { top: 0px !important; }
    .selectCustom .v-input__control .v-input__slot .v-select__slot .v-input__append-inner { margin-top: 6px !important; }
    .selectCustom .v-input__control .v-input__slot .v-select__slot .v-select__selections {
        line-height: 14px !important; margin-top: 2px; font-size: 1.2rem !important;
    }
    .selectCustom .v-input__control .v-input__slot .v-select__slot .v-select__selections div { min-height: 18px !important; }

    .txtCtm .v-input__control .v-input__slot .v-text-field__slot { overflow: initial !important; }
    .txtCtm .v-input__control .v-input__slot .v-text-field__slot .v-label { top: 1px !important; }
    .txtCtm .v-input__control .v-input__slot .v-text-field__slot .v-label--active {
        line-height: 16px !important; height: 20px !important; top: 4px !important;
    }

    .sltCtm .v-input__control .v-input__slot { padding: 0 6px 0 12px !important; }
    .sltCtm .v-input__control .v-input__slot .v-select__slot { overflow: initial !important; }
    .sltCtm .v-input__control .v-input__slot .v-select__slot .v-label { top: 3px !important; }
    .sltCtm .v-input__control .v-input__slot .v-select__slot .v-label--active {
        line-height: 16px !important; height: 20px !important; top: 4px !important;
    }
</style>