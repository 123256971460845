<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <v-col cols="4">
                <span class="text-h4 font-weight-bold">지급항목&nbsp;선택</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="1">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </v-col>
        </v-row>
        <div class="d-inline-block" style="width: 100%;">
            <v-divider class="mt-4"></v-divider>
            <v-row class="greyE01 pa-0" no-gutters>
                <v-col class="tableBR py-1" cols="2">
                </v-col>
                <v-col class="tableBR py-1" style="text-align: center;" cols="6">
                    <span class="font-weight-medium">급여내역</span>
                </v-col>
                <v-col class="py-1" style="text-align: center;" cols="4">
                    <span class="font-weight-medium">과세구분</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row v-for="(pList, i) in paymtsList" :key="i" class="pa-0 tableBB" no-gutters>
                <v-col class="d-flex justify-center tableBR py-1" cols="2">
                    <v-checkbox
                        v-model="pList.isSelected"
                        class="dftChcBox-Theme pt-0" style="width: 180px; margin-top: 3px !important;"
                        true-value="1" false-value="2"
                        on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                        hide-details>
                    </v-checkbox>
                </v-col>
                <v-col class="tableBR py-1" style="text-align: center;" cols="6">
                    <span class="">{{ pList.itmNm }}</span>
                </v-col>
                <v-col class="py-1" style="text-align: center;" cols="4">
                    <span class="">{{ pList.txClcdNm }}</span>
                </v-col>
            </v-row>
            <v-row class="pa-0 mt-4" no-gutters>
                <v-col class="d-flex justify-center" cols="12">
                    <v-btn
                        class="white--text" color="blueBtnColor" height="26" @click="aplItm" samll rounded>
                        <v-icon small>mdi-plus-circle-outline</v-icon>
                        <span class="ml-1" style="font-size: 1rem; padding-bottom: 1px;">적용</span>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </v-sheet>
</template>

<script>
import { selWgeItmList } from '../../api/impExp.js';


export default {
    name: 'AddPymItm',

    props : {
        rmList: { type: Array, default: () => { return [] } }
    },
        
    components: {

    },

    created: function(){
   
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getWgeItmList()
        })
    },

    computed: {

    },

    watch:{

    },
    
    methods: {
        getWgeItmList: function () {
            selWgeItmList(this.$store.getters.getFcltyNum,'2')
                .then((response) => ( this.getWgeItmListAfter(response.data) ))
                .catch((error) => console.log('connect error /impExp/selWgeItmList : ' + error))
        },
        getWgeItmListAfter: function (res) {
            this.paymtsList.splice(0)
            this.ddctnList.splice(0)

            res.forEach(item => {      
                item.isSelected = '2'

                switch (item.calcClcd) {
                    case '1':
                        this.paymtsList.push(item)
                        break;
                    case '2':
                         this.ddctnList.push(item)
                        break;
                    default:
                        break;
                }

                switch (item.txClcd) {
                    case '1':
                        item.txClcdNm = '과세'
                        break;
                    case '2':
                         item.txClcdNm = '비과세'
                        break;
                    default:
                        break;
                }

            });

            this.rmList.forEach(lll => {
                let cfm = this.paymtsList.findIndex( v => v.itmNm == lll.itmNm )
                if( cfm > -1 )  this.paymtsList.splice(cfm, 1)
            });

        },
        aplItm: function () {
            //{ "calcClcd": "1", "txClcd": "1",  "itmNm": "직책수당", "isSelected": "2", "txClcdNm": "과세" },
            this.$emit('sltList', this.paymtsList.filter( v => v.isSelected == '1' ))
            this.onClose()
        },
        onClose: function () {
            this.$emit('modalClose', '0')
        },
    },

    data: () => ({
        paymtsList: [],
        ddctnList: [],
    }),
};
</script>