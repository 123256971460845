<template>
    <v-system-bar class="pa-0 ma-0" color="bckgrColor" height="28">
        <v-spacer></v-spacer>
        <span class="inline-block pa-2"></span>
        <v-btn
            class="white--text text-subtitle-2 font-weight-mediums py-0 pr-3 pl-2 mr-2"        
            height="28"
            @click="menu.splice(1, 1, true), tab=0"
            dense small color="topBtnColor" elevation="3">
            <span style="width:25;"><v-img class="ic-notice" src="../assets/icon/ic-notice-new-s.svg" ></v-img></span>
            <span class="text-overflow" style="width:375px;text-align:left">{{ yysNotice.title }}</span>
        </v-btn>
        <v-btn
            class="white--text text-subtitle-2 font-weight-mediums py-0 pr-3 pl-2 mr-2"
            @click="onLink('https://www.yoyangsys.co.kr/help')"
            dense small color="topBtnColor" elevation="3">
            <v-img class="ic-notice" src="../assets/icon/ic-mnl-s.svg"></v-img>
            <span>메뉴얼</span>
        </v-btn>
        <v-btn
            class="white--text text-subtitle-2 font-weight-mediums py-0 pr-3 pl-2 mr-2"
            @click="menu.splice(1, 1, true), tab=3"
            dense small color="topBtnColor" elevation="3">            
            <span>고객센터&#40;1:1문의&#41;</span>
        </v-btn> 
        <v-btn
            class="white--text text-subtitle-2 font-weight-mediums py-0 pr-3 pl-2 mr-2"
            @click="menu.splice(1, 1, true), tab=1"
            dense small color="topBtnColor" elevation="3">
            <v-img class="ic-notice" src="../assets/icon/ic-notice-s.svg"></v-img>
            <span>기관공지</span>
        </v-btn>        
        <NotifyCard class="mr-2"></NotifyCard>
        <v-btn
            class="white--text text-subtitle-2 font-weight-mediums py-0 pr-3 pl-2"
            dense small color="topBtnColor" elevation="3" @click="cnfLogout()">
            <v-img class="ic-notice" src="../assets/icon/ic-logout.svg"></v-img>
            <span>로그아웃</span>
        </v-btn>
        <v-dialog v-model="menu[0]" max-width="1000" content-class="round" persistent>
            <FcltyNtcMdl ref="fcltyNtcMdl" @modalClose="modalChanger"></FcltyNtcMdl>
        </v-dialog>
        <v-dialog v-model="menu[1]" max-width="1200" content-class="round" persistent>
            <YysBbsMgmt v-if="menu[1]" ref="YysBbsMgmt" :sltTab="tab" @modalClose="modalChanger"></YysBbsMgmt>
        </v-dialog>
        <v-dialog v-model="menu[2]" max-width="1200" content-class="round" persistent>
            <WelcomeMdl v-if="menu[2]" ref="WelcomeMdl" @modalClose="modalChanger"></WelcomeMdl>
        </v-dialog>
        <div v-for="(item, i) in ntcList" :key="i">
            <v-dialog v-model="item.dialog" max-width="1200" content-class="round" persistent>
                <YysNoticePop v-if="item.dialog" ref="WelcomeMdl" :item="item" @isClose="item.dialog = false"></YysNoticePop>
            </v-dialog>
        </div>
    </v-system-bar>
</template>

<script>
import { http } from '@/api/baseAxios';
import FcltyNtcMdl from '../components/othr/FcltyNtcMdl.vue';
import YysBbsMgmt from '../components/othr/YysBbsMgmt.vue';
import YysNoticePop from '../components/othr/YysNoticePop.vue';
import WelcomeMdl from '../components/commons/WelcomeMdl.vue';
import NotifyCard from './NotifyCard.vue';

import VueJwtDecode from 'vue-jwt-decode'

export default {
    name: 'HeaderBar',
    
    components: {
        FcltyNtcMdl,
        NotifyCard,
        YysBbsMgmt,
        WelcomeMdl,
        YysNoticePop,
    },
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getWelcome()
            this.selYysBbsList()
        })
    },

    methods: {
        //로그아웃
        cnfLogout: function (){
            http.post('auth/logout', { fcltyNum: this.$store.getters.getFcltyNum, userNm: this.$store.getters.getUserNm })
                .then((response) => ( this.clearUserInfo(response.data) ))
                .catch((error) => console.log('axios error /auth/logout : ' + error))
        },
        //로그인 연장
        cnfRefreshToken: function (tk){
            http.post('auth/insRefreshToken', { token: tk })
                .then((response) => ( this.cnfRefreshTokenAfter(response.data) ))
                .catch((error) => console.log('axios error /auth/insRefreshToken : ' + error))
        },
        clearUserInfo: function (res){
            console.log(res)
            this.$store.commit('setAuthToken', null)
            this.$store.commit('setFcltyNum', 0)
            this.$store.commit('setUserNm', '')
            this.$store.commit('setSsMmbrPk', 0)
            this.$store.commit('setAcesAthrt', [])

            this.$router.go(0)
        },
        cnfRefreshTokenAfter: function (res){
            if(res.statusCode == '200'){
                let jwtDecode = null

                //로그인 인증 토큰 복호화
                let bytes = this.$crypto.AES.decrypt(res.token, this.$crypto.enc.Hex.parse(res.key), {
                    iv: this.$crypto.enc.Hex.parse("000102030405060708090A0B0C0D0E0F"),
                    padding: this.$crypto.pad.Pkcs7,
                    mode: this.$crypto.mode.CBC
                })

                //토큰정보를 얻어옴
                jwtDecode = VueJwtDecode.decode(bytes.toString(this.$crypto.enc.Utf8))

                //토큰정보를 vuex에 저장
                this.$store.commit('setAuthToken', bytes.toString(this.$crypto.enc.Utf8))
                this.$store.commit('setFcltyNum', jwtDecode.fcltyNum)
                this.$store.commit('setUserNm', decodeURI(jwtDecode.name))
                this.$store.commit('setSsMmbrPk', jwtDecode.mmbrPk)
                this.$store.commit('setExpTime', jwtDecode.exp)

                //this.$router.go(0)
            }
            else{
                this.clearUserInfo(res)
            }
        },
        modalChanger: function(value) {
            this.menu.splice(value, 1, false)
        },

        onLink:function(lnkUrl){            
            window.open(lnkUrl, '_blank')
        },

        //환영페이지 확인
        getWelcome:function(){
            let obj = {}

            if( this.$store.state.sessionInfo.jobNm == "대표자") {
                http.post('yyspym/selYysUseStrtInfo', obj)
                    .then((response) => ( this.getWelcomeRst(response.data) ))
                    .catch((error) => console.log('connect error /yyspym/selYysUseStrtInfo : ' + error))
            }

        },

        getWelcomeRst:function(res){
            let obj = {}    
            if(res.statusCode == 200){ 
                if(res.result != null) {
                    if(res.result.ntcPopYn == '1') this.menu.splice(2, 1, true)
                }
            } else {
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        //공지글 최신 1 가저오기
        selYysBbsList: function () {            
            let obj = {                
                rnStrt:0,
                rnLmt:5, 
                ntcYn:'1',
            }      

            http.post('ntsBbs/selYysBbsList', obj)
                .then((response) => ( this.getYysBbsListRst(response.data) ))
                .catch((error) => console.log('connect error /ntsBbs/selYysBbsList : ' + error))
        },

        getYysBbsListRst :function (res){
            let obj = {}    
            if(res.statusCode == 200){ 
                if(res.result.list !='' && res.result.list != null){  
                    res.result.list.forEach(el => {   
                        if(el.rn == 1)  {
                            this.yysNotice.title       = el.title
                            this.yysNotice.ntcBbsPk    = el.ntcBbsPk
                            this.yysNotice.crtr        = el.crtr
                            this.yysNotice.crtDt       = el.crtDt
                        }
                        el.dialog = true
                        this.ntcList.push(el)
                    });

                }
            } else {
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },
    },
    
    data: () => ({
        menu: [ false, false, false, false, ],
        tab:0,
        yysNotice:{title:'', ntcBbsPk:-1, crtr:'', crtDt:'',},
        ntcList:[],
    }),
};
</script>