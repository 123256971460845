<template>
    <v-sheet class="ma-0 pa-0" rounded="md">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <v-row class="" no-gutters>
            <!-- Left -->
            <v-col class="greyF01 pa-7" cols="5">
                <div class="mb-7">
                    <span class="text-h4 font-weight-bold">업무상세</span> 
                </div>
                <div class="mb-5">
                    <expandMonthPicker
                        ref="monthPicker"
                        :prtDate="$parent.$parent.$parent.$parent.sltDate"
                        @nowYearWithMonth="getPickerDate">
                    </expandMonthPicker>
                </div>
                <v-row class="white rounded rounded-b-0" no-gutters>
                    <v-col class="pt-3 pb-2" style="text-align: center;" cols="4">
                        <span class="black--text font-weight-medium">일정</span>
                    </v-col>
                    <v-col class="pt-3 pb-2" style="text-align: center;" cols="4">
                        <span class="black--text font-weight-medium">업무일정구분</span>
                    </v-col>
                    <v-col class="pt-3 pb-2" style="text-align: center; vertical-align: middle;" cols="4">
                        <span class="black--text font-weight-medium">업무명</span>
                    </v-col>
                </v-row>
                <v-divider color="black" style="padding:1px 0px; border-top: 1px solid #dbdbdb !important;"></v-divider>
                <div class="white rounded rounded-t-0 pb-2">
                    <v-row
                        :class="['qtrListRow', sltBzPlnPk == list.bzPlnPk ? 'sltRow' : 'disSltRow']"
                        ref="qtrRow" @click="tableClickEvent(list.bzPlnPk)"
                        style="border-bottom: 1px solid #dbdbdb !important;"
                        v-for="(list, i) in bzPlnList" :key="i" no-gutters>
                        <v-col class="py-2" style="text-align: center;" cols="4">
                            <span class="black--text">{{ list.bzDtStrt }}</span>
                        </v-col>
                        <v-col class="py-2" style="text-align: center;" cols="4">
                            <span class="black--text">{{ list.bzPlnClcd }}</span>
                        </v-col>
                        <v-col class="py-2" style="text-align: center; vertical-align: middle;" cols="4">
                            <span class="black--text">{{ list.bzPlnNm }}</span>
                        </v-col>
                    </v-row>
                    <v-row v-if="bzPlnList.length == 0" style="border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                        <v-col class="py-3" style="text-align: center;" cols="12">
                            <span class="grey006--text" style="font-size: 0.925rem;">&#8251; 업무상세 내역이 존재하지 않습니다.</span>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
            <!-- Right -->
            <v-col class="pa-7" cols="7">
                <v-row class="ma-0 pa-0" no-gutters>
                    <v-col class="d-flex" cols="12">
                        <v-spacer></v-spacer>
                        <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
                    </v-col>
                </v-row>
                <v-row class="mt-5 pa-0" no-gutters>
                    <v-col class="d-flex pr-1" cols="12">
                        <v-spacer></v-spacer>
                        <v-btn v-if="sltBzPlnPk == -1"
                            class="ml-2 white--text" color="blueBtnColor" height="30"
                            @click="prePlnInfo()" samll rounded>
                            <v-icon class="mr-1" small>mdi-content-save-outline</v-icon>
                            <span class="mr-1" style="font-size: 1rem;">등록</span>
                        </v-btn>
                        <v-btn v-else
                            class="ml-2 white--text" color="blueBtnColor" height="30"
                            @click="prePlnInfo()" samll rounded>
                            <v-icon class="mr-1" small>mdi-content-save-outline</v-icon>
                            <span class="mr-1" style="font-size: 1rem;">수정</span>
                        </v-btn>
                        <v-btn v-if="sltBzPlnPk != -1"
                            class="ml-2 white--text" color="grey006" height="30"
                            @click="rmBzPln(sltBzPlnPk)" samll rounded>
                            <v-icon class="mr-1" small>mdi-trash-can-outline</v-icon>
                            <span class="mr-1" style="font-size: 1rem;">삭제</span>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-form ref="AnnBsnsInfo" lazy-validation>
                    <v-divider class="mt-5 mr-1"></v-divider>
                    <v-row class="ma-0 pr-1" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center" cols="4">
                            <span class="black--text font-weight-medium">업무&nbsp;구분</span>
                        </v-col>
                        <v-col class="tableBL py-1 px-2" cols="8">
                            <span class="d-inline-block" style="width: 160px;">
                                <v-select class="wrkSelect"
                                    v-model="bzPlnInfo.bzPlnClcd" height="28" :rules="[rules.required]"
                                    item-text="valcdnm" item-value="valcd" :items="$parent.$parent.$parent.$parent.vCd95"
                                    dense hide-details outlined>
                                </v-select>
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-row class="ma-0 pr-1" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center" cols="4">
                            <span class="black--text font-weight-medium">업무명</span>
                        </v-col>
                        <v-col class="tableBL py-1 px-2" cols="8">
                            <span class="d-inline-block" style="width: 100%;">
                                <v-text-field
                                    v-model="bzPlnInfo.bzPlnNm" :rules="[rules.required]"
                                    color="blue" height="28"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-row class="ma-0 pr-1" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center" cols="4">
                            <span class="black--text font-weight-medium">업무일정</span>
                        </v-col>
                        <v-col class="d-flex align-center tableBL py-1 px-2" cols="8">
                            <span class="white d-inline-block py-1" style="width: 154px;">
                                <CmmDateComp v-model="bzPlnInfo.bzDtStrt" :required="true"></CmmDateComp>
                            </span>
                            <!-- <span class="d-flex align-center ml-4 mr-2">
                                <v-icon size="16" style="color: #dbdbdb;">mdi-tilde</v-icon>
                            </span>
                            <span class="white d-inline-block py-1 pl-2" style="width: 154px;">
                                <CmmDateComp v-model="bzPlnInfo.bzDtEnd" :minDt="bzPlnInfo.bzDtStrt" :required="true"></CmmDateComp>
                            </span> -->
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-row class="ma-0 pr-1" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center" cols="4">
                            <span class="black--text font-weight-medium">담당자</span>
                        </v-col>
                        <v-col class="d-flex align-center tableBL py-1 px-2" cols="8">
                            <span class="d-inline-block" style="width: 80%;">
                                <v-text-field
                                    v-model="bzPlnInfo.wrtr"
                                    color="blue" height="28"
                                    readonly hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="d-inline-block ml-2" style="width: 28px; height: 28px; margin-bottom: 2px;">
                                <v-btn 
                                    class="brown lighten-3 white--text" min-width="28" height="26"
                                    @click="menu.splice(3, 1, true)"
                                    style="padding: 0px 1px 0px 2px !important" small>
                                    <v-icon size="20">mdi-account-edit</v-icon>
                                </v-btn>
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                    <v-row class="ma-0 pr-1" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center" cols="4">
                            <span class="black--text font-weight-medium">업무내용</span>
                        </v-col>
                        <v-col class="d-flex align-center tableBL py-1 px-2" cols="8">
                            <span class="d-inline-block" style="width: 100%;">
                                <v-textarea
                                    class="rmrksArea" v-model="bzPlnInfo.bzCntnt" no-resize hide-details outlined dense>
                                </v-textarea>
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider class="mr-1"></v-divider>
                </v-form>
            </v-col> 
        </v-row>
        <v-dialog v-model="menu[3]" max-width="700" content-class="round" persistent eager>
            <!-- 
                mdlTtl: 팝업 타이틀(누락시 = 참석자 선택), tabInfList: 활성화 탭 배열 ( '1': 수급자, '2': 직원 ),
                cd41, cd42: 직원 탭에서 사용하는 공통코드 (필수), intgrResList: Array - 팝업에서 선택된 목록
                mode: String - 단일 / 멀티 선택 ( '1': 단일, '2': 멀티, default: '2' )
                fixList: 선택을 제한할 목록, onClose: String - 결과 값 0 고정
                * fixList: 오브젝트에 다음 키를 추가 해야함 ( disChekr:boolean, checkr:String )
                ** disChekr: 체크 가능여부,  checkr: 체크박스 모델
             -->
             <IntgrSlctn
                :mdlTtl="'직원 선택'" :tabInfList="['2']" :fixList="exclsList" :mode="'1'"
                :cd41="$parent.$parent.$parent.$parent.vCd41"
                :cd42="$parent.$parent.$parent.$parent.vCd42"
                :cd65="$parent.$parent.$parent.$parent.vCd65"                
                :prtWrtDt = "bzPlnInfo.bzDtStrt"
                @intgrResList="getAplyList" @onClose="modalChanger">
            </IntgrSlctn>
        </v-dialog>
    </v-sheet>
</template>

<script>
import expandMonthPicker from '../commons/expandMonthPicker.vue';
import IntgrSlctn from '../commons/IntgrSlctn.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';
import { selBzPlnList, selBzPlnInfo, insBzPln, delBzPln } from '../../api/opr.js';

export default {
    name: 'AnnBsnsDtl',

    props : {
   
    },
        
    components: {
        expandMonthPicker,
        IntgrSlctn,
        CmmDateComp,
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getBzPlnList(this.$parent.$parent.$parent.$parent.sltDate)

            this.bzPlnInfo.bzDtStrt = this.$parent.$parent.$parent.$parent.$refs.mnthlPlan.now
            // this.bzPlnInfo.bzDtEnd = this.$parent.$parent.$parent.$parent.$refs.mnthlPlan.now
        })
    },

    computed: {
        getYyMmDate: function () {
            return this.$parent.$parent.$parent.$parent.sltDate
        },
    },

    watch:{
        getYyMmDate: function (value) {
            this.getBzPlnList(value)
        },
        'sltBzPlnPk': function () {
            this.clearForm()
            if(this.sltBzPlnPk != -1)
                this.getBzPlnInfo(this.sltBzPlnPk)
        },
    },
    
    methods: {
        getBzPlnList: function (yyyy) {
            selBzPlnList(this.$store.getters.getFcltyNum, yyyy)
                .then((response) => ( this.getBzPlnListAfter(response.data) ))
                .catch((error) => console.log('connect error /opr/selBzPlnList : ' + error))
        },
        getBzPlnInfo: function (yyyy) {
            selBzPlnInfo(this.$store.getters.getFcltyNum, yyyy)
                .then((response) => ( this.getBzPlnInfoAfter(response.data) ))
                .catch((error) => console.log('connect error /opr/selBzPlnInfo : ' + error))
        },
        setBzPln: function (obj) {
            insBzPln(obj)
                .then((response) => ( this.asyncRes(response.data) ))
                .catch((error) => console.log('connect error /opr/insBzPln : ' + error))
        },
        rmBzPln: function (pk) {
            delBzPln(this.$store.getters.getFcltyNum, pk, this.$store.getters.getUserNm)
                .then((response) => ( this.asyncRes(response.data) ))
                .catch((error) => console.log('connect error /opr/delBzPln : ' + error))
        },
        getBzPlnListAfter: function (res) {
            res.forEach(items => {
                items.bzDtStrt = items.bzDtStrt?.beforeDateFormatDot()
                this.$parent.$parent.$parent.$parent.vCd95.forEach(cd => {
                    if(items.bzPlnClcd == cd.valcd)
                        items.bzPlnClcd = cd.valcdnm
                });
            });
            this.bzPlnList = res
        },
        getBzPlnInfoAfter: function (res) {
            res.bzDtStrt = res.bzDtStrt?.beforeDateFormatHyp()
            // res.bzDtEnd = res.bzDtEnd?.beforeDateFormatHyp()

            this.bzPlnInfo = res
        },
        asyncRes: function (res) {
            if(res.statusCode == 200){
                let dt = this.$parent.$parent.$parent.$parent.sltDate
                this.clearForm()
                this.sltBzPlnPk = -1
                this.snackControll([true, 2500, res.message, 'info'])
                this.getBzPlnList(dt)
                this.$parent.$parent.$parent.$parent.$refs.mnthlPlan.getBzPlnMnthList(dt.substring(0, 4), dt)
                this.$parent.$parent.$parent.$parent.$refs.anualPlan.getBzPlnYearList(dt.substring(0, 4))
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        prePlnInfo: function () {
            let obj = Object.assign({}, this.bzPlnInfo)
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm

            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(obj.bzDtStrt))
                obj.bzDtStrt = this.$moment(obj.bzDtStrt, 'YYYY-MM-DD').format('YYYYMMDD')
            else
                obj.bzDtStrt = ''

            obj.bzDtEnd = obj.bzDtStrt

            this.$parent.$parent.$parent.$parent.vCd95.forEach(cd => {
                if(obj.bzPlnClcd == cd.valcdnm)
                    obj.bzPlnClcd = cd.valcd
            });

            if(this.$refs.AnnBsnsInfo.validate()){
                this.setBzPln(obj)
            }
            else
                this.snackControll([true, 2500, '필수 입력 항목을 확인해 주세요.', 'info'])
        },
        tableClickEvent: function (pk){
            if(this.sltBzPlnPk == pk)
                this.sltBzPlnPk = -1
            else
                this.sltBzPlnPk = pk

        },
        getAplyList: function (arr){
            arr.forEach(items => {
                this.bzPlnInfo.wrtr = items.name
            });
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
        getPickerDate: function (date) {
            this.$parent.$parent.$parent.$parent.start = date.substr(0, 4) + '-' + date.substr(4, 2) + '-01'
            this.$parent.$parent.$parent.$parent.sltDate = date

            this.sltBzPlnPk = -1
        },
        clearForm: function() {
            this.bzPlnInfo = Object.assign({
                bzPlnPk: 0, bzPlnClcd: '1', bzPlnNm: '', clcdClr: '',  bzDtStrt: '', bzDtEnd: '', bzCntnt: '', wrtr: ''
            })
            this.$refs.AnnBsnsInfo.resetValidation()
            
            this.bzPlnInfo.bzDtStrt = this.$parent.$parent.$parent.$parent.$refs.mnthlPlan.now
            // this.bzPlnInfo.bzDtEnd = this.$parent.$parent.$parent.$parent.$refs.mnthlPlan.now
        },
        modalChanger: function() {
            this.menu.splice(3, 1, false)
        },
        onClose: function () {
            this.$emit('modalClose', '0')
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        menu: [ false, false, false ],
        exclsList: [],
        sltBzPlnPk : -1,
        bzPlnList: [],
        bzPlnInfo: {
            bzPlnPk: 0, bzPlnClcd: '1', bzPlnNm: '', clcdClr: '',  bzDtStrt: '', bzDtEnd: '', bzCntnt: '', wrtr: ''
        },
        rules: {
            required: value => !!value || 'Required.',
        },
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>