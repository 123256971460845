<template>
    <v-sheet>     
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div> 
        <v-form ref="tabform" lazy-validation>   
            <div class="d-flex mt-3">
                <div style="width:50%">
                    <div class="d-flex align-center">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">지급 항목</span>
                    </div>
                    <v-divider :class="['mt-1', paymtsList.length > 14 ? 'mr-2__5' : '']"></v-divider>
                    <div :class="['greyE01 d-flex pa-0', paymtsList.length > 14 ? 'mr-2__5' : '']">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:32%;">                                
                            <span class="black--text font-weight-medium">지급항목</span>                    
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0 tableBL" style="width:22%;">                                
                            <span class="black--text font-weight-medium">과세구분</span>
                        </div>        
                        <div class="d-flex justify-center align-center pa-1 ma-0 tableBL" style="width:21%;">                                
                            <span class="black--text font-weight-medium">사용여부</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0 tableBL" style="width:10%;">                                
                            <span class="black--text font-weight-medium">순서</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0 tableBL" style="width:15%;">                                
                            <v-btn icon class="mr-1" color="blue200" height="26" @click="addPaymtsRow()">
                                <v-icon>mdi-plus-circle-outline</v-icon>
                            </v-btn>
                        </div>
                    </div>
                    <v-divider :class="[paymtsList.length > 14 ? 'mr-2__5' : '']"></v-divider>
                    <div class="overflow-y-auto" style="height:632px;">
                        <div v-if="paymtsList.length > 0">
                            <div v-for="(item, j) in paymtsList" :key="j" style="width:100%;">
                                <v-form ref="paymtsWage" class="d-flex pa-0 ma-0 tableBB" lazy-validation>
                                    <div class="d-flex justify-start align-center py-1 px-2 ma-0" style="width:32%;">                                
                                        <span v-if="item.wgeItmClcd === '21'" style="width:100%">
                                            <v-text-field v-model="item.itmNm" :rules="[rules.required]" color="blue" height="28" hide-details outlined dense></v-text-field>
                                        </span>
                                        <span v-else>
                                            {{item.itmNm}}
                                        </span>
                                    </div>
                                    <div class="d-flex justify-center align-center pa-1 ma-0 tableBL" style="width:22%;">                                
                                        <span v-if="item.wgeItmClcd === '21'" style="width:100%">
                                            <v-radio-group v-model="item.txClcd" :rules="[rules.required]" class="radio-dft-Theme mt-0 pl-2 pt-1" hide-details row >
                                                <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="과세" value="1"></v-radio>                                
                                                <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="비과세" value="2"></v-radio>                                
                                            </v-radio-group>
                                        </span>
                                        <span v-else>
                                            {{item.txClcdNm}}
                                        </span>
                                    </div>     
                                    <div class="d-flex justify-center align-center pa-1 ma-0 tableBL" style="width:21%;">                                
                                        <span v-if="item.disble">
                                            <span v-if="item.useYn == '1'">사용</span>
                                            <span v-else-if="item.useYn == '2'">미사용</span>
                                        </span>
                                        <span v-else class="d-inline-block" style="width:100%"> 
                                            <v-radio-group v-model="item.useYn" :rules="[rules.required]" class="radio-dft-Theme mt-0 pl-2 pt-1" hide-details row>
                                                <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="사용" value="1"></v-radio>                                
                                                <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="미사용" value="2"></v-radio>                                
                                            </v-radio-group>
                                        </span>
                                    </div>
                                    <div class="d-flex justify-center align-center py-1 px-2 ma-0 tableBL" style="width:10%;">                                
                                        <span class="d-inline-block" style="width:100%">
                                        <v-text-field v-model="item.ord" color="blue" type="number" min="0" height="28" hide-details outlined dense></v-text-field>
                                        </span>
                                    </div>
                                    <div class="d-flex justify-center align-center pa-1 ma-0 tableBL" style="width:15%;">                                
                                        <v-btn icon color='blue200' @click="onSave(j, '1')">
                                            <v-icon>mdi-content-save-outline</v-icon>
                                        </v-btn>
                                        <div v-if="item.wgeItmClcd === '21'">
                                            <v-btn v-if="item.wgeItmPk > 0" icon color='grey006' @click="delPaymtsRow(j)"> 
                                                <v-icon>mdi-trash-can-outline</v-icon>                                           
                                            </v-btn> 
                                            <v-btn v-else icon color='grey006' @click="delPaymtsRow(j)"> 
                                                <v-icon>mdi-minus-circle-outline</v-icon>
                                            </v-btn>
                                        </div>
                                    </div>
                                </v-form>   
                            </div>
                        </div>
                        <div v-else>
                            <div class="d-flex justify-center py-2 ma-0 tableBB" style="width:100%;">                                
                                <span class="">※ 목록이 없습니다.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ml-5" style="width:50%">
                    <div class="d-flex align-center">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">공제 항목</span>
                    </div>
                    <v-divider :class="['mt-1', ddctnList.length > 14 ? 'EmpWageInfTab-headerResize' : '']"></v-divider>
                    <div :class="['greyE01 d-flex pa-0 ma-0', ddctnList.length > 14 ? 'EmpWageInfTab-headerResize' : '']">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:32%;">                                
                            <span class="black--text font-weight-medium">지급항목</span>                    
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0 tableBL" style="width:22%;">                                
                            <span class="black--text font-weight-medium">과세구분</span>
                        </div>        
                        <div class="d-flex justify-center align-center pa-1 ma-0 tableBL" style="width:21%;">                                
                            <span class="black--text font-weight-medium">사용여부</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0 tableBL" style="width:10%;">                                
                            <span class="black--text font-weight-medium">순서</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0 tableBL" style="width:15%;">                                
                            <v-btn icon class="mr-1" color="blue200" height="26" @click="addDdctnRow()">
                                <v-icon>mdi-plus-circle-outline</v-icon>
                            </v-btn>
                        </div>                    
                    </div>
                    <v-divider :class="[ddctnList.length > 14 ? 'EmpWageInfTab-headerResize' : '']"></v-divider>
                    <div class="overflow-y-auto" style="height:632px;">
                        <div v-if="ddctnList.length > 0">
                            <div v-for="(item, j) in ddctnList" :key="j" class="d-flex pa-0 ma-0 tableBB" style="width:100%;">
                                <div class="d-flex justify-start align-center py-1 px-2 ma-0" style="width:32%;">                                
                                    <span v-if="item.wgeItmClcd === '22'" style="width:100%">
                                        <v-text-field v-model="item.itmNm" color="blue" height="28" hide-details outlined dense></v-text-field>
                                    </span>
                                    <span v-else>
                                        {{item.itmNm}}
                                    </span>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0 tableBL" style="width:22%;">                                
                                    <span v-if="item.wgeItmClcd === '22'" style="width:100%">
                                        <v-radio-group v-model="item.txClcd" :rules="[rules.required]" class="radio-dft-Theme mt-0 pl-2 pt-1" hide-details row >
                                            <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="과세" value="1"></v-radio>                                
                                            <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="비과세" value="2"></v-radio>                                
                                        </v-radio-group>
                                    </span>
                                    <span v-else>
                                        {{item.txClcdNm}}
                                    </span>
                                </div>     
                                <div class="d-flex justify-center align-center pa-1 ma-0 tableBL" style="width:21%;">                                
                                    <span v-if="item.disble">
                                        <span v-if="item.useYn == '1'">사용</span>
                                        <span v-else-if="item.useYn == '2'">미사용</span>
                                    </span>
                                    <span v-else class="d-inline-block" style="width:100%"> 
                                        <v-radio-group v-model="item.useYn" :rules="[rules.required]" class="radio-dft-Theme mt-0 pl-2 pt-1" hide-details row >
                                            <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="사용" value="1"></v-radio>                                
                                            <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="미사용" value="2"></v-radio>                                
                                        </v-radio-group>
                                    </span>
                                </div>
                                <div class="d-flex justify-center align-center py-1 px-2 ma-0 tableBL" style="width:10%;">                                
                                    <span class="d-inline-block" style="width:100%">
                                       <v-text-field v-model="item.ord" color="blue" type="number" min="0" height="28" hide-details outlined dense></v-text-field>
                                    </span>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0 tableBL" style="width:15%;">                                
                                    <v-btn icon color='blue200' @click="onSave(j, '2')">
                                        <v-icon>mdi-content-save-outline</v-icon>
                                    </v-btn>
                                    <div v-if="item.wgeItmClcd === '22'">
                                        <v-btn v-if="item.wgeItmPk > 0" icon color='grey006' @click="delDdctnRow(j)"> 
                                            <v-icon>mdi-trash-can-outline</v-icon>                                           
                                        </v-btn> 
                                        <v-btn v-else icon color='grey006' @click="delDdctnRow(j)"> 
                                            <v-icon>mdi-minus-circle-outline</v-icon>
                                        </v-btn>
                                    </div>
                                </div>   
                            </div>
                        </div>
                        <div v-else>
                            <div class="d-flex justify-center py-2 tableBB" style="width:100%;">                                
                                <span class="">※ 목록이 없습니다.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>        
        </v-form>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="delTitle" @madalState="onDelete"></btnModalDelete>  
            </v-dialog>   
        </div>
    </v-sheet>
</template>
<script>
import { getMultiStcd } from '../../api/index.js';
import { selWgeItmList, insWgeItm, delWgeItm, insMultiWgeItm } from '../../api/impExp.js';
import btnModalDelete from '../bnfcr/BnfcrDeleteConfirm.vue';

export default {
    name: 'EmpWageInfTab2',
    
    components: {  
        btnModalDelete
    },

    props : {
    },

    computed: {
        
    },

    created: function(){  
    },

    mounted: function(){
        this.$nextTick(function () {           
        })
    },

    watch: {
        
    },

    methods: {
        setMultiWgeItm: function(obj){
            insMultiWgeItm(obj)
                .then((response) => ( this.onSaveAfter(response.data) ))
                .catch((error) => console.log('connect error /impExp/insMultiWgeItm : ' + error))
        },
        onLoad:function(){
            this.getWgeItmList()
        },
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        stCdMapping: function (res) {
            this.vCd78 = res.vCd78
        },
        onSave: function (index, mode) {
            let item = null
            if(mode == '1')
                item = this.paymtsList[index]
            else if(mode == '2')
                item = this.ddctnList[index]
            else{
                this.snackControll([true, 2500, '잘못된 호출 입니다.', 'error'])
                return
            }

            let obj = {
                fcltyNum:this.$store.getters.getFcltyNum,
                userNm:this.$store.getters.getUserNm,
                calcClcd:item.calcClcd,
                txClcd:item.txClcd,
                itmNm:item.itmNm,
                ord:item.ord,
                trgt:item.trgt,
                useYn:item.useYn,
                wgeItmClcd:item.wgeItmClcd,
                wgeItmStdPk:item.wgeItmStdPk,
                wgeItmPk:item.wgeItmPk,
            }

            if(this.$refs.paymtsWage.length > 0){
                if(this.$refs.paymtsWage[index].validate()){
                    insWgeItm(obj)
                        .then((response) => ( this.onSaveAfter(response.data) ))
                        .catch((error) => console.log('connect error /impExp/insWgeItm : ' + error))
                }
                else
                    this.snackControll([true, 2500, '필수 항목을 입력해주세요.', 'warning'])
            }
            else
                this.snackControll([true, 2500, '모듈 렌더링 오류.', 'warning'])
            
        },
        onTotSave: function () {
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm
            obj.list = []

            this.paymtsList.forEach(pay => {
                obj.list.push(pay)
            });

            this.ddctnList.forEach(ddctn => {
                obj.list.push(ddctn)
            });

            if(this.$refs.paymtsWage.length > 0){
                let cfm = true
                this.$refs.paymtsWage.forEach(vali => {
                    cfm = vali.validate()
                });
                if(cfm)
                    this.setMultiWgeItm(obj)
                else
                    this.snackControll([true, 2500, '필수 항목을 입력해주세요.', 'warning'])
            }
            else
                this.snackControll([true, 2500, '모듈 렌더링 오류.', 'warning'])
        },
        onSaveAfter: function (res) {            
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.getWgeItmList()
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },        

        onDelete:function(res){
            if(res == 9990){      
                console.log(this.selItm)
                if(this.selItm.wgeItmPk > 0 && this.selItm.wgeItmPk != undefined){
                    let obj = {
                            fcltyNum:this.$store.getters.getFcltyNum,
                            userNm : this.$store.getters.getUserNm,
                            wgeItmPk:this.selItm.wgeItmPk,
                    }  
                    
                    delWgeItm(obj)
                        .then((response) => ( this.onDeleteAfter(response.data)))
                        .catch((error) => console.log('upload error /impExp/delWgeItm : ' + error))
                }else{
                    this.selItm = Object.assign({})
                    this.dialogDelete = false  
                    this.snackControll([true, 2500, '삭제 할 대상이 존재하지 않습니다.', 'warning'])
                }
            }else if(res == 7){
                this.dialogDelete = false
            }

        },
        onDeleteAfter(res){                       
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.getWgeItmList()
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.selItm = Object.assign({})
            this.delTitle = ''
            this.dialogDelete = false   
        },

        getWgeItmList: function () {
            selWgeItmList(this.$store.getters.getFcltyNum, null)
                .then((response) => ( this.getWgeItmListAfter(response.data) ))
                .catch((error) => console.log('connect error /impExp/selWgeItmList : ' + error))
        },
        getWgeItmListAfter: function (res) {
            
            this.paymtsList.splice(0)
            this.ddctnList.splice(0)

            res.forEach(item => {      

                //수정제한
                switch (item.wgeItmClcd) {
                    case '1':
                        item.disble = true
                        break;
                    case '8':
                        item.disble = true
                        break;
                    case '9':
                        item.disble = true
                        break;
                    case '10':
                        item.disble = true
                        break;
                    case '11':
                        item.disble = true
                        break;
                    case '14':
                        item.disble = true
                        break;
                    case '15':
                        item.disble = true
                        break;
                    case '18':
                        item.disble = true
                        break;
                    case '19':
                        item.disble = true
                        break;
                    case '20':
                        item.disble = true
                        break;
                    default:
                        item.disble = false
                        break;
                }

                switch (item.calcClcd) {
                    case '1':
                        this.paymtsList.push(item)
                        break;
                    case '2':
                         this.ddctnList.push(item)
                        break;
                    default:
                        break;
                }

                switch (item.txClcd) {
                    case '1':
                        item.txClcdNm = '과세'
                        break;
                    case '2':
                         item.txClcdNm = '비과세'
                        break;
                    default:
                        break;
                }

                if(item.wgeItmClcd === '21'){
                    this.paymtsPk = item.wgeItmStdPk
                }else if(item.wgeItmClcd === '22'){
                    this.ddctnPk = item.wgeItmStdPk
                }
            });

            this.$refs.tabform.resetValidation();
        },

        getOrdMax: function(){
            let max = 0
            this.paymtsList.forEach(paymt => {
                if(Number(paymt.ord) > max) max = Number(paymt.ord)
            });
            this.ddctnList.forEach(ddctn => {
                if(Number(ddctn.ord) > max) max = Number(ddctn.ord)
            });
            return max+1
        },
        
        addPaymtsRow:function(){
            this.paymtsList.push({
                fcltyNum:this.$store.getters.getFcltyNum,
                calcClcd:'1',
                txClcd:'1',
                itmNm:'',
                ord:this.getOrdMax(),
                trgt:'2',
                useYn:'1',
                wgeItmClcd:'21',
                wgeItmStdPk:this.paymtsPk,
                wgeItmPk:0,
            })
        },

        delPaymtsRow:function(idx){
            if(this.paymtsList[idx].wgeItmPk > 0){
                this.delTitle = this.paymtsList[idx].itmNm
                this.selItm = Object.assign(this.paymtsList[idx])
                this.dialogDelete = true
            }else{
                this.paymtsList.splice(idx,1)
            }
        },

        addDdctnRow:function(){
            this.ddctnList.push({
                fcltyNum:this.$store.getters.getFcltyNum,
                calcClcd:'2',
                txClcd:'1',
                itmNm:'',
                ord:this.getOrdMax(),
                trgt:'2',
                useYn:'1',
                wgeItmClcd:'22',
                wgeItmStdPk:this.ddctnPk,
                wgeItmPk:0,
            })
        },

        delDdctnRow:function(idx){
            

             if(this.ddctnList[idx].wgeItmPk > 0){
                this.delTitle = this.ddctnList[idx].itmNm
                this.selItm = Object.assign(this.ddctnList[idx])
                this.dialogDelete = true
            }else{
                this.ddctnList.splice(idx,1)
            }
        },



        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        selItm:{},
        delTitle:'',
        vCd78:[], 
        paymtsPk:0,
        paymtsList:[],
        ddctnPk:0,
        ddctnList:[],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
        snackOptions: [false, 3000, '', 'info'],
        dialogDelete:false,
    }),
};
</script>