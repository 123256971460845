<template>
    <v-sheet class="ma-0" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <span class="text-h4 font-weight-bold">본인부담률&nbsp;{{ bscsInf.ttl }}</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="$parent.$parent.$parent.$parent.mdlOff" x-large>mdi-close</v-icon>
        </v-row>
        <v-row class="pa-0 mt-4 mb-2 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">기존 정보</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">수급자</span>
            </v-col>
            <v-col class="px-4 py-1" cols="7">
                <span class="mr-2">{{ bscsInf.name }}</span>
                &#40;
                <span v-if="bscsInf.gndr == 'M'">남</span>
                <span v-else-if="bscsInf.gndr == 'F'">여</span>
                <span class="ml-1">/ 만 {{ bscsInf.age }}세</span>
                &#41;
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">생년월일</span>
            </v-col>
            <v-col class="px-4 py-1" cols="7">
                <span>{{ $moment( bscsInf.rsdntBirth, 'YYYYMMDD' ).format('YYYY.MM.DD') }}</span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">본인부담률</span>
            </v-col>
            <v-col class="px-4 py-1" cols="7">
                <span>{{ bscsInf.nowCopayNm }}</span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mt-4 mb-2 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">변경 정보</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="3">
                <span class="font-weight-medium posn-rltv">
                    적용시작일
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="d-flex align-center px-4" cols="9">
                <span class="d-inline-block" style="width: 134px; padding-top: 1px;">
                    <CmmDateComp
                        v-model="bscsInf.aplStrt"
                        :required="true" :minDt="bscsInf.entDt" :maxDt="bscsInf.lvDt"
                        @input="aplctSavBtn">
                    </CmmDateComp>
                </span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 tableBR d-flex align-center justify-center" cols="3">
                <span class="font-weight-medium posn-rltv">
                    본인부담률
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="px-2 py-1" cols="9">
                <v-radio-group
                    v-model="bscsInf.copayRate" @change="aplctSavBtn"
                    class="radio-dft-Theme mt-0" ref="info" hide-details row>
                    <v-radio
                        v-for="(itm, i) in prtCd63" :key="i"
                        class="px-2" style="padding-bottom: 2px !important;"
                        :ripple="false" :label="itm.valcdnm" :value="itm.valcd">
                    </v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 tableBR d-flex align-center justify-center" cols="3">
                <span class="font-weight-medium posn-rltv">
                    변경사유
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="px-4 py-1" cols="9">
                <v-text-field
                    v-model="bscsInf.mdfRsn" @input="aplctSavBtn"
                    class="" height="28" color="blue"
                    hide-details outlined dense>
                </v-text-field>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <div
            class="overflow-y-auto mt-4" style="height: 80px;"
            v-if="cpyClmList.length > 0 || bscsInf.bnfcrCntrcInfPk > 0">
            <div
                v-if="cpyClmList.length > 0"
                class="brdSurrd px-6 py-2"
                style="background-color: #f4f4f4 !important;">
                <p class="fontOneDotOneRem red--text mb-1">
                    ※ 본인부담률 변경 적용기간에 본인부담금 청구건이 존재합니다.
                </p>
                <p class="fontOneRem grey006--text ma-0 pl-5" style="line-height: 1.2;">
                    [1-6.본인부담금 청구관리] 화면에서 변경된 본인부담률을 반영한 청구서를 재생성하시기 바랍니다.
                </p>
            </div>
            <div
                v-if="bscsInf.bnfcrCntrcInfPk > 0"
                class="brdSurrd mt-2 px-6 py-2"
                style="background-color: #f4f4f4 !important;">
                <p class="fontOneDotOneRem red--text mb-1">
                    ※ 본인부담률 변경 적용기간에 계약건이 존재합니다.
                </p>
                <p class="fontOneRem grey006--text ma-0 pl-5" style="line-height: 1.2;">
                    해당 계약의 계약일자: {{ bscsInf.cntrcDtNms }} 변경된 본인부담률은 표준약관에 반영됩니다.
                </p>
            </div>
        </div>
        <v-row class="pa-0 mt-4 mb-2 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">적용시작일 기준 청구서 정보</span>
        </v-row>
        <v-divider :class="[cpyClmList.length > 2 ? 'mr-2__5' : 'mr-1']" color="black"></v-divider>
        <v-row :class="[cpyClmList.length > 2 ? 'pr-2__5' : 'pr-1']" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="3">
                <span class="font-weight-medium">청구월도</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="6">
                <span class="font-weight-medium">청구일자</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid py-2" cols="3">
                <span class="font-weight-medium">본인부담률</span>
            </v-col>
        </v-row>
        <v-divider :class="[cpyClmList.length > 2 ? 'mr-2__5' : 'mr-1']"></v-divider>
        <div v-if="cpyClmList.length > 0" class="overflow-y-auto" style="width: 100%; height: 65px;">
            <v-row
                :class="['tableBB', cpyClmList.length > 2 ? '' : 'pr-1']"
                v-for="(itm, i) in cpyClmList" :key="i" no-gutters>
                <v-col class="txtAlgnMid tableBR py-1" cols="3">
                    <span>{{ $moment(itm.clmYymm, 'YYYYMM').format('YYYY.MM') }}</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR py-1" cols="6">
                    <span>{{ $moment(itm.clmDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                </v-col>
                <v-col class="txtAlgnMid py-1" cols="3">
                    <span>{{ itm.copayRateNm }}</span>
                </v-col>
            </v-row>
        </div>
        <v-row v-else class="tableBB pa-0" no-gutters>
            <v-col class="txtAlgnMid py-2" cols="12">
                <span class="fontOneRem grey006--text">※ 적용시작일을 입력하시면 조회됩니다. </span>
            </v-col>
        </v-row>
        <v-divider class="white my-2"></v-divider>
        <v-row class="pa-0 justify-center" no-gutters>
            <v-btn
                class="" color="blueBtnColor" min-width="110" height="36"
                :disabled="savBtn" @click="dcsnSavBtn">
                <span class="white--text fontOneDotTwoRem">저장</span>
            </v-btn>
        </v-row>
        <v-divider class="white mt-1"></v-divider>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

import CmmDateComp from '../../commons/CmmDateComp.vue';

export default {
    name: 'CopaySavMdl',

    components: {
        CmmDateComp,
    },

    props : {
        prtCd63: { type: Array, default: () => { return [] } },
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            let obj = this.$store.getters.getSubInObj
            this.aplctInf(obj)
        })
    },

    computed: {
      
    },

    watch: {
        'bscsInf.aplStrt': function(v){
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(v))
                this.getCpyClmList(this.bscsInf.bnMmbrPk, this.bscsInf.bnfcrPk, v)
            else
                this.cpyClmList.splice(0)
        }
    },

    methods:{
        // 본인부담률 적용 청구서 조회
        getCpyClmList: function (mbr, fcr, ymd){
            this.cpyClmList.splice(0)

            let obj = {}
            obj.bnMmbrPk = mbr
            obj.bnfcrPk = fcr
            obj.inqYMD = this.$moment(ymd, 'YYYY-MM-DD').format('YYYYMMDD')
            //obj.fcltyNum = this.$store.getters.getFcltyNum

            http.post('bnfcrAuth/selBnfcrCopayRateClmList', obj)
                .then((response) => ( this.cpyClmRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/selBnfcrCopayRateClmList : ' + error))
        },

        cpyClmRst: function (res){
            if(res.result.length > 0)
                this.cpyClmList = res.result
        },

        // 본인부담률 신규 등록
        setCpyInf: function (obj){
            this.savBtn = true

            http.post('bnfcrAuth/insBnfcrCopayRate', obj)
                .then((response) => ( this.insCpyRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/insBnfcrCopayRate : ' + error))
        },

        insCpyRst: function (res){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == '200')
                this.savBtn = false
            else
                obj.type = 'warning'

            this.$store.commit('setWrnnInf', obj)
        },

        // 본인부담률 수정
        setCpyUpd: function (obj){
            this.savBtn = true

            http.post('bnfcrAuth/updBnfcrCopayRate', obj)
                .then((response) => ( this.insCpyRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/updBnfcrCopayRate : ' + error))
        },

        // 기본정보 적용
        aplctInf: function (obj) {
            this.cpyHisList = obj.cpyHisList

            this.bscsInf.bnMmbrPk = obj.bnMmbrPk
            this.bscsInf.bnfcrPk = obj.bnfcrPk
            this.bscsInf.name = obj.name
            this.bscsInf.gndr = obj.gndr
            this.bscsInf.age = obj.age
            this.bscsInf.rsdntBirth = obj.rsdntBirth
            this.bscsInf.ttl = obj.subTtl
            this.bscsInf.nowCopayNm = obj.isNowObj?.copayRateNm
            this.bscsInf.entDt = this.$moment(obj.entDt, 'YYYYMMDD').format('YYYY-MM-DD')
            if(obj.lvDt == null || obj.lvDt == '')
                this.bscsInf.lvDt = '2099-12-31'
            else
                this.bscsInf.lvDt = this.$moment(obj.lvDt, 'YYYYMMDD').format('YYYY-MM-DD')

            if(obj.subTtl == '정보 수정'){
                this.bscsInf.bnfcrCopayRatePk = obj.bnfcrCopayRatePk
                this.bscsInf.aplStrt = this.$moment(obj.aplStrt, 'YYYYMMDD').format('YYYY-MM-DD')
                this.bscsInf.bnfcrCntrcInfPk = obj.bnfcrCntrcInfPk
                this.bscsInf.cntrcDtNms = obj.cntrcDtNms
                this.bscsInf.copayRate = obj.copayRate
                this.bscsInf.mdfRsn = obj.mdfRsn
            }
            else{
                delete this.bscsInf.bnfcrCopayRatePk
                this.rstFld()
            }

            this.aplctSavBtn()
        },

        // 저장버튼 활성 여부 체크
        aplctSavBtn: function () {
            this.savBtn = true

            let obj = this.$store.getters.getSubInObj

            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.bscsInf.aplStrt) == false)
                return 0
            if(this.bscsInf.copayRate == '')
                return 0
            if(this.bscsInf.mdfRsn == '')
                return 0
            if(this.bscsInf.copayRate == obj.copayRate)
                return 0

            this.savBtn = false
        },

        // 저장버튼 분기
        dcsnSavBtn: function () {
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = '변경할 정보를 입력해주세요.'     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(this.bscsInf.aplStrt == '' || this.bscsInf.copayRate == '' || this.bscsInf.mdfRsn == ''){
                this.$store.commit('setWrnnInf', obj)
                return 0
            }

            let tmpDt = this.$moment(this.bscsInf.aplStrt, 'YYYY-MM-DD')
            let chc = this.cpyHisList.filter( v => v.aplStrt == tmpDt.format('YYYYMMDD') )
            if(chc.length > 0 && this.$store.getters.getSubInObj.aplStrt != tmpDt.format('YYYYMMDD') ){
                obj.title = '중복된 적용시작일이 존재합니다.'
                obj.type = 'warning'
                this.$store.commit('setWrnnInf', obj)
                return 0
            }

            if( tmpDt.isBefore(this.$moment(this.bscsInf.entDt, 'YYYY-MM-DD'))
                || tmpDt.isAfter(this.$moment(this.bscsInf.lvDt, 'YYYY-MM-DD')) ){
                    obj.title = '적용시작일은 입소일 이후 퇴소일 이전만 입력 가능합니다.'
                    obj.type = 'warning'
                    this.$store.commit('setWrnnInf', obj)
                    return 0
            }

            let insObj = Object.assign({}, this.bscsInf)
            let cpy = this.prtCd63.findIndex( v => v.valcd == this.bscsInf.copayRate )
            if(cpy > -1){
                insObj.copayRateNm = this.prtCd63[cpy].valcdnm
                insObj.copayRateVal = this.prtCd63[cpy].valdesc?.replaceAll('%')
                insObj.copayRate = this.prtCd63[cpy].valcd
            }

            insObj.aplStrt = this.$moment(insObj.aplStrt, 'YYYY-MM-DD').format('YYYYMMDD')

            if(this.bscsInf.ttl == '신규 등록')
                this.setCpyInf(insObj)
            else if(this.bscsInf.ttl == '정보 수정')
                this.setCpyUpd(insObj)
        },

        // 입력필드 초기화
        rstFld: function () {
            this.bscsInf.aplStrt = ''
            this.bscsInf.copayRate = ''
            this.bscsInf.mdfRsn = ''
        }
    },

    data: () => ({
        bscsInf: {
            bnMmbrPk: -1, bnfcrPk: -1, name: '', gndr: '', age: '', rsdntBirth: '',
            aplStrt: '', copayRate: '', mdfRsn: '', ttl: '', nowCopayNm: '', entDt: '', lvDt: '',
            bnfcrCntrcInfPk: -1, cntrcDtNms: '',
        },
        cpyClmList: [],
        cpyHisList: [],
        savBtn: true,
    })
}
</script>