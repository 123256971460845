<template>
    <v-sheet class="rounded-lg rounded-l-0 overflow-auto pa-2" color="" :min-width="areaWidth" :max-width="areaWidth">
        <div class="d-flex align-center">
            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold">급여제공 기록상세</span>
        </div>
        <div class="mt-3 d-flex pa-0 mr-2__5" no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:25%;">                                
                <span class="black--text font-weight-medium">영역</span>
            </div>
            <div style="width:75%">
                <div class="d-flex pa-0 ma-0" no-gutters>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;">                                
                        <span class="black--text font-weight-medium">급여항목</span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:30%;border-left: 1px solid #dbdbdb !important;">                                
                        <span class="black--text font-weight-medium">제공내용</span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;">                                
                        <span class="black--text font-weight-medium">기록내용</span>
                    </div>
                </div>
            </div>
        </div>
        <div style="height:741px;overflow-y:scroll">
            <div class="d-flex pa-0 ma-0" no-gutters>
                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:25%;border-bottom: 1px solid #dbdbdb !important;">                                
                    <span class="black--text font-weight-medium">신체활동지원</span>
                </div>
                <div style="width:75%">
                    <div class="d-flex pa-0 ma-0" no-gutters>                    
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:65%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            작성자
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            {{plnList['wrtr'].val}}
                        </div>
                    </div>
                    <div class="d-flex pa-0 ma-0" no-gutters>                    
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            옷갈아입기
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:30%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            {{plnList['1'].cnt}}
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            {{plnList['1'].val}}
                        </div>
                    </div>
                    <div class="d-flex pa-0 ma-0" no-gutters>                    
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            세면하기
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:30%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            {{plnList['2'].cnt}}
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            {{plnList['2'].val}}
                        </div>
                    </div>
                    <div class="d-flex pa-0 ma-0" no-gutters>                    
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            양치하기
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:30%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                             {{plnList['3'].cnt}}
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            {{plnList['3'].val}}
                        </div>
                    </div>
                    <div class="d-flex pa-0 ma-0" no-gutters>                    
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            머리감기
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:30%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            {{plnList['5'].cnt}}
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            {{plnList['5'].val}}
                        </div>
                    </div>
                    <div class="d-flex pa-0 ma-0" no-gutters>                    
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            몸단장
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:30%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            {{plnList['6'].cnt}}
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            {{plnList['6'].val}}
                        </div>
                    </div>
                    <div class="d-flex pa-0 ma-0" no-gutters>                    
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            몸씻기
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:30%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            {{plnList['4'].cnt}}
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            <div class="pl-1" style="height:25px;line-height:25px;" v-if="rec301Info.bathSchdlPk > 0">
                                <div v-if="rec301Info.actBth != '' && rec301Info.actBth != null" style="text-align:center;color:blue">
                                    <v-icon color="blue">
                                        mdi-text-box-check-outline
                                    </v-icon>
                                    {{rec301Info.actBth}}    
                                </div>
                                <div v-else style="text-align:center;color:red">                                    
                                    {{rec301Info.bthTm}}
                                </div>
                            </div>                           
                        </div>
                    </div>
                    <div class="d-flex pa-0 ma-0" no-gutters>                    
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            식사하기
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:30%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            <div class="d-flex" style="width:90px;height:50px;padding-left:8px;">                                
                                <div>                                                                
                                    <span v-for="(itmMeal,n) in mealArr" :key="n">
                                        <v-chip                                        
                                            style="padding:0px !important; margin:1px;"
                                            label          
                                            :color="rec301Info.itmMeal.indexOf(itmMeal) == -1?'gray':'blue'"                                                          
                                            outlined
                                            small>
                                        {{itmMeal}}
                                        </v-chip>
                                    </span>
                                        
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex pa-0 ma-0" no-gutters>                    
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            체위변경
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:30%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            <div class="d-flex">                                           
                                <span class="ml-1">
                                    {{rec301Info.itmPos}}
                                </span>  
                            </div>
                        </div>
                    </div>
                    <div class="d-flex pa-0 ma-0" no-gutters>                    
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            화장실 이용하기
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:30%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            <div class="d-flex">                                                        
                                <span v-if="rec301Info.exrtYn =='1'">                                   
                                    <span class="pl-1">집중관찰</span>
                                </span>
                                <div v-else class="d-flex">                                    
                                    <span class="pl-1">{{rec301Info.itmToil}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex pa-0 ma-0" no-gutters>                    
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            이동도움
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:30%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            {{plnList['10'].val}}
                        </div>
                    </div>
                    <div class="d-flex pa-0 ma-0" no-gutters>                    
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            산책(외출) 동행
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:30%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                                            
                            <div class="pa-1">
                                <v-btn small outlined 
                                    style="padding:0px !important" 
                                    :class="['',(plnList['11'].val1== '1')?'blueBtnColor':'']">
                                    <v-icon size="18" left style="padding:0px !important;margin:0px !important" :class="['',(plnList['11'].val1== '1')?'white--text':'']">
                                        mdi-walk
                                    </v-icon>
                                    <span :class="['',(plnList['11'].val1== '1')?'white--text':'']">산책</span>
                                </v-btn>
                                <v-btn class="ml-1" small outlined 
                                    style="padding:0px !important" 
                                    :class="['',(plnList['11'].val2 == '1')?'blueBtnColor':'']">
                                    <v-icon size="18" left style="padding:0px !important;margin:0px !important" :class="['',(plnList['11'].val2 == '1')?'white--text':'']">
                                        mdi-exit-run
                                    </v-icon>
                                    <span :class="['',(plnList['11'].val2 == '1')?'white--text':'']">외출</span>
                                </v-btn>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex pa-0 ma-0" no-gutters>                    
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            특이사항
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:30%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            <div class="overflow-y-auto" style="width:100%;height:70px;">
                                <div v-html="plnList['note'].val"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex pa-0 ma-0" no-gutters>
                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:25%;border-bottom: 1px solid #dbdbdb !important;">                                
                    <span class="black--text font-weight-medium">인지&#183;정서 지원</span>
                </div>
                <div style="width:75%">                    
                    <div class="d-flex pa-0 ma-0" no-gutters>                    
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:65%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            작성자
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            {{plnList2['wrtr'].val}}
                        </div>
                    </div>
                    <div class="d-flex pa-0 ma-0" no-gutters>                    
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            인지관리지원
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:30%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            {{plnList2['1'].cnt}}
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            {{plnList2['1'].val}}
                        </div>
                    </div>
                    <div class="d-flex pa-0 ma-0" no-gutters>                    
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            의사소통,정서
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:30%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            {{plnList2['2'].cnt}}
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            {{plnList2['2'].val}}
                        </div>
                    </div>
                    <div class="d-flex pa-0 ma-0" no-gutters>                    
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            특이사항
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:30%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            <div class="overflow-y-auto" style="width:100%;height:70px;">
                                <div v-html="plnList2['note'].val"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex pa-0 ma-0" no-gutters>
                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:25%;border-bottom: 1px solid #dbdbdb !important;">                                
                    <span class="black--text font-weight-medium">기능회복</span>
                </div>
                <div style="width:75%">                    
                    <div class="d-flex pa-0 ma-0" no-gutters>                    
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:65%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            작성자
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            {{plnList2['wrtr'].val}}
                        </div>
                    </div>
                    <div class="d-flex pa-0 ma-0" no-gutters>                    
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            신체기능의 훈련
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:30%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            {{plnList2['3'].cnt}}
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            {{plnList2['3'].val}}
                        </div>
                    </div>
                    <div class="d-flex pa-0 ma-0" no-gutters>                    
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            기본동작 훈련
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:30%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            {{plnList2['4'].cnt}}
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            {{plnList2['4'].val}}
                        </div>
                    </div>  
                    <div class="d-flex pa-0 ma-0" no-gutters>                    
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            일상생활동작 훈련
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:30%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            {{plnList2['5'].cnt}}
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            {{plnList2['5'].val}}
                        </div>
                    </div>                     
                </div>
            </div>
            <div class="d-flex pa-0 ma-0" no-gutters>
                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:25%;border-bottom: 1px solid #dbdbdb !important;">                                
                    <span class="black--text font-weight-medium">건강및간호관리</span>
                </div>
                <div style="width:75%">
                    <div class="d-flex pa-0 ma-0" no-gutters>                    
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:65%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            작성자
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            {{hlthNrs.wrtr}}
                        </div>
                    </div>
                    <div class="d-flex pa-0 ma-0" no-gutters>                    
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:65%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            혈압&#40;고&#47;저&#41;
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                             {{hlthNrs.bldPrsH}}&#47;{{hlthNrs.bldPrsL}}
                        </div>
                    </div>
                    <div class="d-flex pa-0 ma-0" no-gutters>                    
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:65%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            맥박&#47;체온
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                           {{hlthNrs.pulse}}&#47;{{hlthNrs.tmprt}}
                        </div>
                    </div>
                    <div class="d-flex pa-0 ma-0" no-gutters>                    
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:65%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            호흡&#47;혈당
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            {{hlthNrs.rsprt}}&#47;{{hlthNrs.bldSgr}}
                        </div>
                    </div>
                    <div class="d-flex pa-0 ma-0" no-gutters>                    
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:65%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            체중
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            {{hlthNrs.wght}}
                        </div>
                    </div>
                    <div class="d-flex pa-0 ma-0" no-gutters>                    
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                
                            간호일지
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:65%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                                                            
                            <div class="overflow-y-auto" style="width:100%;height:100px;">
                                <div v-html="hlthNrs.cntnt"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-sheet>
</template>

<script>
import { getMultiStcd,} from '../../api/index.js';
import { selBnftOfrRecDayInfo } from '../../api/bnftrcrd.js';
export default {
    name: 'AllBlnftSrvRcrd',
    
    components: {
 
    },

    props : {

    },

    created: function(){       
        
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.areaWidth = 1260 - Number(this.$parent.$parent.midAreaWidth) 
            this.bnMmbrPk = this.$parent.$parent.sltBnMmBrPk   
            this.sltDate = this.$parent.$parent.sltDate
            this.onLoad()   
        })
    },

    computed: {
        getAreaWidth: function () {
            return this.$parent.$parent.midAreaWidth
        },
        getSltDate: function () {
            return this.$parent.$parent.sltDate
        },   
        getBnmmbrPk(){
            return this.$parent.$parent.sltBnMmBrPk
        }     
    },  

    watch: {
        getAreaWidth: function (value) {
            this.areaWidth = 1260 - Number(value)
        },
        getBnmmbrPk:function(value){
            this.bnMmbrPk = value  
            this.onLoad()
        },
        getSltDate: function(value){
            this.sltDate = value
            this.onLoad()
        },        
    },

    methods: {
        onLoad: function(){
            this.onCodeload()
        },
        onInit:function(){          
        },
        onCodeload:function(){
            getMultiStcd(['45'])
                .then((response) => ( this.codeMapping(response.data) ))
                .catch((error) => console.log(error))
        },
        codeMapping:function(res){
            this.vCd45 = res.vCd45
            this.getBnftOfrRecDayInfo()
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        
        getBnftOfrRecDayInfo:function(){          
            let obj = {
                fcltyNum: this.$store.getters.getFcltyNum,
                bnMmbrPk : this.bnMmbrPk,
                inqYMD : this.sltDate,
                clsfc1Cd:'301',
                clsfc2Cd:'1',
                clsfc3CdS:['1','2','3'],
            }
            selBnftOfrRecDayInfo(obj)
                        .then((response) => ( this.getBnftOfrRecDayInfoAfter(response.data) ))
                        .catch((error) => console.log('upload error /bnftoffer/selBnftOfrRecDayInfo : ' + error))
        }, 
        getBnftOfrRecDayInfoAfter:function(res){

            if(res.statusCode == 200){       

                this.plnList        = JSON.parse(JSON.stringify(this.dummyPlnList))
                this.plnList2       = JSON.parse(JSON.stringify(this.dummyPlnList2))            
                this.rec301Info     = JSON.parse(JSON.stringify(this.dummyRec301Info))
                this.hlthNrs        = JSON.parse(JSON.stringify(this.dummyHlthNrs))
    
                //급여제공계획
                res.result.plnList.forEach(x => {
                    if(x.clsfc2Cd == '1'){
                        switch(x.clsfc3Cd){
                            case "1":
                                this.plnList['1'].cnt = this.getCnt(x) //옷갈아입기
                            break;
                            case "2":
                                this.plnList['2'].cnt = this.getCnt(x) //세면하기
                            break;
                            case "3":
                                this.plnList['3'].cnt = this.getCnt(x) //양치하기
                            break;
                            case "4":
                                this.plnList['4'].cnt = this.getCnt(x) //몸씻기
                            break;
                            case "5":
                                this.plnList['5'].cnt = this.getCnt(x) //머리감기
                            break;
                            case "6":
                                this.plnList['6'].cnt = this.getCnt(x) //몸단장
                            break;
                            default: 
                             break;
                        }                
                    } else if(x.clsfc2Cd == '3'){
                        this.plnList2['1'].cnt = this.getCnt(x) //인지관리지원
                    } else if(x.clsfc2Cd == '4'){
                        this.plnList2['2'].cnt = this.getCnt(x) //정서지원
                    } else if(x.clsfc2Cd == '7'){
                        switch(x.clsfc3Cd){                       
                            case "2":
                                this.plnList2['3'].cnt = this.getCnt(x) //신체기능의 훈련
                            break;
                            case "3":
                                this.plnList2['4'].cnt = this.getCnt(x) //기본동작 훈련
                            break;
                            case "4":
                                this.plnList2['5'].cnt = this.getCnt(x) //일상생활동작 훈련
                            break;
                            default: 
                             break;
                        }
                    }
                });
    
                //급여제공기록
                res.result.recList.forEach(el => {
                    if(el.clsfc3Cd == '1'){
                        this.plnList['1'].val = this.getNullVal(el.itm1Val) //옷갈아입기
                        this.plnList['2'].val = this.getNullVal(el.itm2Val) //세면하기
                        this.plnList['3'].val = this.getNullVal(el.itm3Val) //양치하기
                        this.plnList['4'].val = this.getNullVal(el.itm4Val) //몸씻기
                        this.plnList['5'].val = this.getNullVal(el.itm5Val) //머리감기
                        this.plnList['6'].val = this.getNullVal(el.itm6Val) //몸단장
                        this.plnList['10'].val = this.getNullVal(el.itm10Val) //이동도움
                        this.plnList['note'].val = el.spclNote?.replaceAll('\n', '<br />') //특이사항
                        this.plnList['wrtr'].val = el.wrtr
    
                        //산책(외출) 동행
                        if(typeof el.itm11ValAr == "object" && el.itm11ValAr != null) {
                            el.itm11ValAr.forEach(itm => {
                                if(itm == '1') this.plnList['11'].val1 = '1'
                                else if(itm == '2') this.plnList['11'].val2 = '1'
                            });
                        }
                    } else if(el.clsfc3Cd == '2') {
                        this.plnList2['1'].val = this.getNullVal(el.itm1Val) //인지관리지원
                        this.plnList2['2'].val = this.getNullVal(el.itm2Val) //정서지원
                        this.plnList2['note'].val = el.spclNote?.replaceAll('\n', '<br />') //특이사항
                        this.plnList2['wrtr'].val = el.wrtr
                    } else if(el.clsfc3Cd == '3') {
                        
                        this.plnList2['3'].val = this.getNullVal(el.itm1Val) //신체기능의 훈련
                        this.plnList2['4'].val = this.getNullVal(el.itm2Val) //기본동작 훈련
                        this.plnList2['5'].val = this.getNullVal(el.itm3Val) //일상생활동작 훈련
    
                    }
                })
    
                res.result.rec301List.forEach(el => {
                    this.rec301Info.itmBth = el.itmBth
                    this.rec301Info.itmPos = this.getNullVal(el.itmPos)
                    this.rec301Info.itmToil = el.itmToil
                    this.rec301Info.actBth = el.actBth
                    this.rec301Info.bthTm = el.bthTm,                           
                    this.rec301Info.bathSchdlPk = el.bathSchdlPk
                    this.rec301Info.intbNutrYn = el.intbNutrYn
                    this.rec301Info.cathYn = el.cathYn
                    this.rec301Info.dprYn = el.dprYn
                    this.rec301Info.bdsrYn = el.bdsrYn
                    this.rec301Info.exrtYn = el.exrtYn
    
                    if(el.itmMeal != null){
                        el.itmMeal.forEach(x=>{
                            this.rec301Info.itmMeal.push(x)
                        })
                    }
                });
    
                //건강및간호관리
                if(res.result.hlthNrs != null){
                    this.hlthNrs.wrtr       = res.result.hlthNrs.wrtr
                    this.hlthNrs.bldPrsH    = res.result.hlthNrs.bldPrsH
                    this.hlthNrs.bldPrsL    = res.result.hlthNrs.bldPrsL
                    this.hlthNrs.pulse      = res.result.hlthNrs.pulse
                    this.hlthNrs.tmprt      = res.result.hlthNrs.tmprt
                    this.hlthNrs.rsprt      = res.result.hlthNrs.rsprt
                    this.hlthNrs.bldSgr     = res.result.hlthNrs.bldSgr
                    this.hlthNrs.wght       = res.result.hlthNrs.wght
                    this.hlthNrs.cntnt      = res.result.hlthNrs.cntnt?.replaceAll('\n', '<br />')
                }
            }
            else {
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        getCnt:function(obj){
            let cnt = ''
            this.vCd45.forEach(e=>{
                if(e.valcd == obj.itmVal1){
                    cnt = e.valcdnm
                }
            })
            if(obj.itmVal2 != null && obj.itmVal2 != ''){
                cnt += obj.itmVal2+'회'
            }
            if(obj.itmVal3 != null && obj.itmVal3 != ''){
                cnt += '/'+obj.itmVal3+'분'
            }
            return cnt
        },

        getNullVal(value){
            let res = ''
            if(value !='' && value !=null) res = value+' 회'
            else res = '0 회'

            return res
        }
        
    },
    data: () => ({
        areaWidth: 708,
        bnMmbrPk:0,
        sltDate:'',
        vCd45:[],        
        plnList:{
            '1':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val:'',},
            '2':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val:'',},
            '3':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val:'',},
            '4':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val:'',},
            '5':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val:'',},
            '6':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val:'',},
            '10':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val:'',},
            '11':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val1:'',val2:'',},
            'note':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val:'',},
            'wrtr':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val:'',},
        },
        dummyPlnList:{
            '1':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val:'',},
            '2':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val:'',},
            '3':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val:'',},
            '4':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val:'',},
            '5':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val:'',},
            '6':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val:'',},
            '10':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val:'',},
            '11':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val1:'',val2:'',},
            'note':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val:'',},
            'wrtr':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val:'',},
        },
        plnList2:{
            '1':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val:'',},
            '2':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val:'',},
            '3':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val:'',},
            '4':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val:'',},
            '5':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val:'',},
            'note':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val:'',},
            'wrtr':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val:'',},
        },
        dummyPlnList2:{
            '1':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val:'',},
            '2':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val:'',},
            '3':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val:'',},
            '4':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val:'',},
            '5':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val:'',},            
            'note':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val:'',},
            'wrtr':{clsfc3cdnm:'',itmval1:'',itmval2:'',itmval3:'',cnt:'',val:'',},
        },
        rec301Info:{
            actBth:'',
            bathSchdlPk:'',
            bdsrYn:'',
            bthTm:'',
            cathYn:'',
            dprYn:'',
            exrtYn:'',
            intbNutrYn:'',
            itmBth:'',
            itmMeal:[],
            itmPos:'',
            itmToil:'',
            recDt:'',
        },
        dummyRec301Info:{
            actBth:'',
            bathSchdlPk:'',
            bdsrYn:'',
            bthTm:'',
            cathYn:'',
            dprYn:'',
            exrtYn:'',
            intbNutrYn:'',
            itmBth:'',
            itmMeal:[],
            itmPos:'',
            itmToil:'',
            recDt:'',
        },
        hlthNrs:{
            wrtr:'',
            bldPrsH:'',
            bldPrsL:'',
            pulse:'',
            tmprt:'',
            rsprt:'',
            bldSgr:'',
            wght:'',
            cntnt:'',
        },
        dummyHlthNrs:{
            wrtr:'',
            bldPrsH:'',
            bldPrsL:'',
            pulse:'',
            tmprt:'',
            rsprt:'',
            bldSgr:'',
            wght:'',
            cntnt:'',
        },
        mealArr:['아침','점심','저녁','오전','오후'],
    }),
};
</script>