<template>
    <v-sheet class="ma-0" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <span class="text-h4 font-weight-bold">직원&nbsp;단일&nbsp;선택</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="$parent.$parent.$parent.$parent.mdlOff" x-large>mdi-close</v-icon>
        </v-row>
        <v-divider class="mt-5 white"></v-divider>
        <v-tabs hide-slider v-model="tab" color="white" class="black--text"
            active-class="bodyColor" center-active>
            <v-tab
                v-for="item in tabItems" :key="item.tab"
                :class="item.class"
                :disabled="!item.visible">
                {{ item.tab }}
            </v-tab>
        </v-tabs>
        <v-divider class="bodyColor mb-3" style="padding: 1px 0px;"></v-divider>
        <v-row class="pa-0 align-center" no-gutters>
            <v-col class="d-flex align-center" cols="5">
                <exBtn class="mr-1" :btnData="jobBtn" :key="frcRdr" @btnClosed="exBtnOff" @changeCheck="filterSearch" />
                <v-spacer></v-spacer>
                <span class="d-inline-block" style="width: 130px;">
                    <v-text-field
                        class="nameSearch" height="33px"
                        background-color="white" append-icon="mdi-magnify" label="이름조회"
                        :key="frcRdr" @input="setTxt"
                        single-line rounded outlined filled hide-details dense>
                    </v-text-field>
                </span>
            </v-col>
            <v-spacer></v-spacer>
            <v-btn
                class="ml-1" width="20" height="20"
                @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 15)"
                icon>
                <v-icon size="20" style="padding-top: 2px;">mdi-help-circle-outline</v-icon>
            </v-btn>
            <span class="ml-1 grey006--text fontOneRem">목록이 보이지 않는 경우</span>
        </v-row>
        <v-divider class="mt-2"></v-divider>
        <v-tabs-items v-model="tab">
            <v-tab-item :transition="false">
                <v-data-table
                    class="dftTbl" height="478" locale="ko"
                    :headers="empHdr" :items="empList"
                    :search="schDummy" :custom-filter="customFilter"
                    :loading="empLdr"
                    fixed-header disable-pagination hide-default-footer dense>
                    <template v-slot:header="props"> 
                        <thead>
                            <tr>
                                <th class="" v-for="(list, i) in props.headers" :key="i">
                                    <span>{{ list.text }}</span>
                                </th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item }'>
                        <tr @click="getTblEvt(item)">
                            <td>{{ item.name }}</td>
                            <td>
                                <div class="" style="height: 20px;">
                                    <v-img class="d-inline-block" v-if="item.gndr == 'F'" src="../../assets/icon/ic-female.svg"></v-img>
                                    <v-img class="d-inline-block" v-else-if="item.gndr == 'M'" src="../../assets/icon/ic-male.svg"></v-img>
                                </div>
                            </td>
                            <td>{{ item.jobNm }}</td>
                            <td>{{ item.entDtDot }}</td>
                            <td>{{ item.rtrDtDot }}</td>
                            <td>{{ item.wrkSchdl }}</td>
                        </tr>
                    </template>
                </v-data-table>
            </v-tab-item>
            <v-tab-item :transition="false">
                <v-data-table
                    class="dftTbl" height="478" locale="ko"
                    :headers="extEmpHdr" :items="extEmpList"
                    :search="schDummy" :custom-filter="customFilter"
                    :loading="empLdr"
                    fixed-header disable-pagination hide-default-footer dense>
                    <template v-slot:header="props"> 
                        <thead>
                            <tr>
                                <th class="" v-for="(list, i) in props.headers" :key="i">
                                    <span>{{ list.text }}</span>
                                </th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item }'>
                        <tr @click="getTblEvt(item)">
                            <td>{{ item.name }}</td>
                            <td>{{ item.jobNm }}</td>
                            <td>{{ item.entDtDot }}</td>
                            <td>{{ item.rtrDtDot }}</td>
                            <td>{{ item.celphn }}</td>
                        </tr>
                    </template>
                </v-data-table>
            </v-tab-item>
        </v-tabs-items>
        <v-divider class="mt-3 white"></v-divider>
        <v-row class="pa-0 justify-end" no-gutters>
            <span class="fontEighthRem grey006--text mr-2">※&nbsp;기준일&#58;</span>
            <span class="fontEighthRem grey006--text mr-1">
                {{ $moment(this.$store.getters.getSubInObj.wrtDt, 'YYYYMMDD')?.format('YYYY.MM.DD') }}
            </span>
        </v-row>
    </v-sheet>
</template>

<script>
import { getEmpChcList, getExtEmpList } from '../../api/emp.js';

import exBtn from './expandButtonCustom.vue';

export default {
    name: 'SlctEmpSchdl',

    props : {
        prtCd41:{ type: Array, default: () => { return [] } },
        prtCd42:{ type: Array, default: () => { return [] } },
        prtCd70:{ type: Array, default: () => { return [] } },
    },
            
    components: {
        exBtn,
    },

    created: function(){
       
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.setExBtnItm()
            if(this.$store.getters.getSubInObj != null){
                let obj = this.$store.getters.getSubInObj
                if(obj.empTab)
                    this.tabItems[0].visible = obj.empTab
                if(obj.extEmpTab)
                    this.tabItems[1].visible = obj.extEmpTab
            }
            if(this.tab == 0)
                this.getEmpList(this.$store.getters.getSubInObj.wrtDt)
            else if(this.tab == 1)
                this.getExtEmpList(this.$store.getters.getSubInObj.wrtDt)
        })
    },

    computed: {

    },

    watch: {
        'prtCd42': function () {
            this.setExBtnItm()
        },
        'tab': function () {
            if(this.tab == 0)
                this.getEmpList(this.$store.getters.getSubInObj.wrtDt)
            else if(this.tab == 1)
                this.getExtEmpList(this.$store.getters.getSubInObj.wrtDt)

            this.setExBtnItm()
            this.frcRdr += 1
            this.schDummy = ''
            this.schField = ''
        },
    },
        
    methods: {
        // 직원 목록 조회 (근무 기간별)
        getEmpList: function (ymd) {
            this.empList.splice(0)
            this.empLdr = true

            getEmpChcList(this.$store.getters.getFcltyNum, 1, ymd)
                .then((response) => ( this.getEmpRst(response.data) ))
                .catch((error) => console.log('connect error /emp/selEmpChcList : ' + error))
        },
        // 외부강사,자원봉사자 목록 조회 (계약 기간별)
        getExtEmpList: function (ymd) {
            this.extEmpList.splice(0)
            this.empLdr = true

            getExtEmpList(this.$store.getters.getFcltyNum, ymd)
                .then((response) => ( this.getExtEmpRst(response.data) ))
                .catch((error) => console.log('connect error /emp/getExtEmpList : ' + error))
        },
        getEmpRst: function (res) {
            if(res.length > 0){
                res.forEach(itm => {
                    let chc = this.prtCd42.findIndex( v => v.valcd == itm.jobCd )
                    if(chc > -1)
                        itm.jobNm = this.prtCd42[chc].valcdnm

                    if(itm.entDt != '' && itm.entDt != null)
                        itm.entDtDot = this.$moment(itm.entDt, 'YYYYMMDD').format('YYYY.MM.DD')
                    if(itm.rtrDt != '' && itm.rtrDt != null)
                        itm.rtrDtDot = this.$moment(itm.rtrDt, 'YYYYMMDD').format('YYYY.MM.DD')

                    itm.wrkSchdl = itm.wrkSchdl?.replace('~',' ~ ')
                });

                this.empList = res
            }

            this.empLdr = false
        },
        getExtEmpRst: function (res) {
            if(res.length > 0){
                res.forEach(itm => {
                    let chc = this.prtCd42.findIndex( v => v.valcd == itm.jobCd )
                    if(chc > -1)
                        itm.jobNm = this.prtCd42[chc].valcdnm

                    if(itm.entDt != '' && itm.entDt != null)
                        itm.entDtDot = this.$moment(itm.entDt, 'YYYYMMDD').format('YYYY.MM.DD')
                    if(itm.rtrDt != '' && itm.rtrDt != null)
                        itm.rtrDtDot = this.$moment(itm.rtrDt, 'YYYYMMDD').format('YYYY.MM.DD')
                });

                this.extEmpList = res
            }

            this.empLdr = false
        },
        setTxt: function (value) {
            this.schField = value
            this.schDummy = this.schDummy + '.'
        },
        filterSearch: function (sch) {
            switch(this.schChkField.target) {
                case 'jobCd': this.schChkField.jobCd.splice(0)
                    sch.forEach((item) => { this.schChkField.jobCd.push(item) });
                    break;
            }

            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column
            let col0 = filter.name.includes(this.schField)

            let col1 = true
            if(this.schChkField.jobCd.length != 0){
                this.schChkField.jobCd.forEach((stcd, i) => {
                    if(i == 0) col1 = filter.jobNm.includes(stcd)
                    else col1 = col1 || filter.jobNm.includes(stcd)
                });
            }

            return col0 && col1;
        },
        setExBtnItm: function () {
            this.jobBtn.expandList.splice(0)
            if(this.prtCd42.length > 0){
                this.prtCd42.forEach(code => {
                    if(this.tab == 0)
                        this.jobBtn.expandList.push(code.valcdnm)
                    else{
                        if(code.valcdnm == '외부강사' || code.valcdnm == '자원봉사자')
                            this.jobBtn.expandList.push(code.valcdnm)
                    }
                })
            }
        },
        getTblEvt: function (obj) {
            this.$store.commit('setSubOutObj', obj)
            this.$store.commit('setSubRunEvt', true)
        },
        exBtnOff: function (idx) {
            switch(idx) {
                case 0: this.schChkField.target = 'jobCd'
                    break;
                default: this.schChkField.target = ''
                    break;
            }
        },
    },
    
    data: () => ({
        tab: 0,
        empLdr: false,
        frcRdr: 0,
        schField: '',
        schDummy: '',
        empList: [],
        extEmpList: [],
        jobBtn: { index:  0, btnTitle: '직종', btnIcon: 'business', exWidth: 134, exHeigth: 220, expandList: [], },
        schChkField: { wrkStcd: [], jobCd: [], target: 'jobCd', trgCd: '' },
        tabItems: [
            { index: 0, tab: '직원', class: 'fontOneDotTwoRem rounded-tl px-14', visible: true },
            { index: 1, tab: '강사/자원봉사', class: 'fontOneDotTwoRem rounded-tr px-8', visible: false },
        ],
        empHdr: [
            { text: '성명', value: 'name', align: 'center', on: false, sortable: true, width: '80', },
            { text: '성별', value: 'gndr', align: 'center', on: false, sortable: true, width: '80', },
            { text: '직종', value: 'jobNm', align: 'center', on: false, sortable: true, width: '100', },
            { text: '입사일', value: 'entDtDot', align: 'center', on: false, sortable: true, width: '100', },
            { text: '퇴사일', value: 'rtrDtDot', align: 'center', on: false, sortable: true, width: '100', },
            { text: '근무일정', value: 'wrkSchdl', align: 'center', on: false, sortable: true, width: '120', },
        ],
        extEmpHdr: [
            { text: '성명', value: 'name', align: 'center', on: false, sortable: true, width: '80', },
            { text: '직종', value: 'jobNm', align: 'center', on: false, sortable: true, width: '100', },
            { text: '입사일', value: 'entDtDot', align: 'center', on: false, sortable: true, width: '100', },
            { text: '퇴사일', value: 'rtrDtDot', align: 'center', on: false, sortable: true, width: '100', },
            { text: '전화번호', value: 'celphn', align: 'center', on: false, sortable: true, width: '120', },
        ],
    }),
};
</script>
