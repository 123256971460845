<template>
    <v-sheet>
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col class="d-flex align-center" cols="3">
                <span style="width:125px;">
                    <v-select
                        v-model="srchSlt"
                        :items="vCd124"
                        @change="setSearchSlt"
                        item-text="valcdnm" item-value="valcd"                    
                        dense hide-details outlined>
                    </v-select>               
                </span>
                <span class="pl-1">
                    <v-text-field
                        v-model="srchTxt" @input="setSearchText"
                        class="txt-Theme" height="34" label="제목 내용 조회"
                        background-color="white" prepend-inner-icon="mdi-magnify"
                        single-line outlined dense filled hide-details>
                    </v-text-field>
                </span>
            </v-col>
            <v-col class="d-flex justify-end align-center" cols="9">                 
                <v-btn v-for="(list, i) in filledBtn" :key="i" @click="onClickBtn(i)" :class="list.class" height="30"
                    :color="list.color" rounded :disabled="list.disabled" style="font-size: 14px;">
                    <v-icon class="mr-1" size="18">{{ list.icon }}</v-icon>
                    {{ list.text }}
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="mt-3 pa-0" no-gutters>
            <v-col class="" cols="12">
                <v-data-table
                    class="dft-hvr-tbl" locale="ko" height="450"
                    :headers="headers" :items="faqList"
                    :loading="loader" 
                    loading-text="Loading... Please wait"
                    hide-default-header hide-default-footer dense disable-pagination>
                    <template v-slot:header="{ props }">
                        <thead>
                            <tr class="">
                                <th class="px-2 py-2 ntcTbHeader" style="width: 8%;">
                                    <span class="black--text">{{ props.headers[0].text }}</span>
                                </th>
                                <th class="px-2 ntcTbHeader" style="width: 15%;">
                                    <span class="black--text">{{ props.headers[1].text }}</span>
                                </th>
                                <th class="px-2 ntcTbHeader" style="width: 43%;">
                                    <span class="black--text">{{ props.headers[2].text }}</span>
                                </th>
                                <th class="px-2 ntcTbHeader" style="width: 10%;">
                                    <span class="black--text">{{ props.headers[3].text }}</span>
                                </th>
                                <th class="px-2 ntcTbHeader" style="width: 15%;">
                                    <span class="black--text">{{ props.headers[4].text }}</span>
                                </th>
                                <th class="px-2 ntcTbHeader" style="width: 10%; border-right: initial !important;">
                                    <span class="black--text">{{ props.headers[5].text }}</span>
                                </th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item }'>
                        <tr :class="[sltRow.bbsPk == item.bbsPk ? 'onClr' : '']" @click="evtRow(item)">
                            <td 
                                class="hvrClr tableBR tableBB py-2"
                                style="text-align: center;">
                                
                                <span v-if="item.bbsNotiYn == '1'">
                                    <v-icon size="20" color="grey">mdi-bell-ring</v-icon>
                                </span>
                                <span v-else>{{ item.bbsPk }}</span>

                            </td>
                            <td 
                                class="hvrClr tableBR tableBB py-2">
                                <span class="fontOneRem">{{ item.bbsCtgryCdNm }}</span>
                            </td>
                            <td 
                                class="hvrClr tableBR tableBB py-2">
                                <div class="text-overflow fontOneRem" style="width:440px;">{{ item.bbsTitle }}</div>
                            </td>
                            <td 
                                class="hvrClr tableBR tableBB py-2"
                                style="text-align: center;">
                                <v-icon v-if="item.bbsAnswr !='' && item.bbsAnswr != null" color="blue">mdi-check-circle-outline</v-icon>
                                <v-icon v-else>mdi-minus-circle-outline</v-icon>
                            </td>
                            <td 
                                class="hvrClr tableBR tableBB py-2"
                                style="text-align: center;">
                                <span class="fontOneRem">{{ item.crtDt }}</span>
                            </td>
                            <td 
                                class="hvrClr tableBB py-2"
                                style="text-align: center;">
                                <span class="fontOneRem">{{ item.crtr }}</span>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row class="mt-2 pa-0" no-gutters>
            <v-col class="" cols="12">
                <v-pagination
                    v-model="page"
                    :length="pageCount"
                    @input="getPage"
                    total-visible="10">
                </v-pagination>
            </v-col>
        </v-row>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" max-width="1100">    
                <YysQnaViw 
                    v-if="dialog[0]"             
                    @isClose="modalEvent(0)" 
                    @restRow="restSltRow"
                    @isModify="onModify"
                    @modalEvent="modalEvent(99)" 
                ></YysQnaViw>                  
            </v-dialog>   
        </div>  
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[1]" max-width="1100">    
                <YysQnaWrt 
                    v-if="dialog[1]"             
                    @isClose="modalEvent(1)"
                    @restRow="restSltRow"
                    @modalEvent="modalEvent(99)" 
                ></YysQnaWrt>                  
            </v-dialog>   
        </div>    
    </v-sheet>
</template>

<script>
import { selQnaBbsList } from '../../api/othr.js';
import YysQnaViw from './YysQnaViw.vue';
import YysQnaWrt from './YysQnaWrt.vue';

export default {
    name: 'YysQna',

    props : {
   
    },
        
    components: {
        YysQnaViw,
        YysQnaWrt,
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.selQnaBbsList(0,this.itemsRows)
        })
    },

    computed: {

    },

    watch:{

    },
    
    methods: {
        onClickBtn:function(key){
            switch (key) {
                case 0:
                    this.dialog.splice(1, 1, true)
                    break;
            
                default:
                    break;
            }
        },
        selQnaBbsList: function (strt, lmt) {
            this.faqList.splice(0)
            this.vCd124.splice(0)
            this.vCd124.push({valcd:null,valcdnm: '전체'})
            this.loader = true
            let obj = {                
                rnStrt:strt,
                rnLmt:lmt, 
                srchWd1:this.srchSlt,
                srchWd2:this.srchTxt,
            }      

            selQnaBbsList(obj)
                .then((response) => (this.selQnaBbsListAfter(response.data)))
                .catch((error) => console.log('connect error othr/selQnaBbsList : ' + error))
        },
        selQnaBbsListAfter :function (res){
            console.log(res)
            this.loader = false
            let obj = {}    
            if(res.statusCode == 200){ 

                if(res.result.code.vCd124 !='' && res.result.code.vCd124 != null){ 
                    res.result.code.vCd124.forEach(cd => {
                        this.vCd124.push(cd)
                    })
                }

                if(res.result.list !='' && res.result.list != null){  
                    this.pageCount = Math.ceil(Number(res.result.rnTot)/this.itemsRows)
                    res.result.list.forEach(el => {

                        this.vCd124.forEach(code => {
                            if(el.bbsCtgryCd == code.valcd) el.bbsCtgryCdNm = code.valcdnm
                        });

                        this.faqList.push(el)
                    });

                }
            } else {
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },
        getPage:function(){
            let strt = ((this.page-1)*this.itemsRows)
            this.selQnaBbsList(strt,this.itemsRows)
        },
        setSearchSlt:function(){            
            this.page = 1
            this.selQnaBbsList(0,this.itemsRows)
        },
        setSearchText:function(){
            let pattern = /([^가-힣a-z\x20])/i
            
            if(this.srchTxt != ''){
                if(!pattern.test(this.srchTxt)) {
                    this.page = 1
                    this.selQnaBbsList(0,this.itemsRows)
                }
            } else {
                this.page = 1
                this.selQnaBbsList(0,this.itemsRows)
            }
        },

        // 테이블 행 클릭 이벤트
        evtRow: function (obj){
            if(this.sltRow.bbsPk == obj.bbsPk){
               this.restSltRow()
            }
            else{
                this.sltRow.bbsPk = obj.bbsPk
                this.sltRow.bbsTitle = obj.bbsTitle 
                this.sltRow.bbsCntnt = obj.bbsCntnt 
                this.sltRow.bbsCntntHtml = obj.bbsCntntHtml
                this.sltRow.crtr = obj.crtr
                this.sltRow.crtDt = obj.crtDt
                this.sltRow.bbsNotiYn = obj.bbsNotiYn
                this.sltRow.bbsAnswr = obj.bbsAnswr
                this.sltRow.bbsCtgryCd = obj.bbsCtgryCd
                this.sltRow.bbsCtgryCdNm = obj.bbsCtgryCdNm
                this.dialog.splice(0, 1, true)
            }
        },

        restSltRow:function(){
            this.sltRow.bbsPk = -1
            this.sltRow.bbsTitle = '' 
            this.sltRow.bbsCntnt = '' 
            this.sltRow.bbsCntntHtml = ''
            this.sltRow.crtr = ''
            this.sltRow.crtDt = ''
            this.sltRow.bbsNotiYn = ''
            this.sltRow.bbsAnswr = ''
            this.sltRow.bbsCtgryCd = ''
            this.sltRow.bbsCtgryCdNm = ''
        },

        modalEvent:function(key){
            if(key == 99){
                this.page = 1
                this.selQnaBbsList(0,this.itemsRows)       
            } else {
                this.dialog.splice(key, 1, false)
            }   
        },

        onModify:function(){
            this.dialog.splice(1, 1, true)
        }
    },

    data: () => ({
        dialog:[false,false],
        page: 1,
        pageCount: 0,
        totCnt: 0,
        itemsRows: 10,
        sltYysBbsPk: -1,
        srchSlt: null,
        srchTxt: '',
        sltRow:{bbsPk:-1, bbsTitle:'', bbsCntnt:'', bbsAnswr:'',bbsCntntHtml:'',crtr:'',crtDt:'',bbsNotiYn:'',bbsCtgryCd:'',bbsCtgryCdNm:'',},
        loader:false,        
        vCd124:[],
        faqList: [],
        headers: [
            { text: '번호', value: 'rn', sortable: true, align: 'center', class: 'black--text' },
            { text: '분류', value: 'bbsCtgryCdNm', sortable: true, align: 'center', class: 'black--text' },
            { text: '제목', value: 'bbsTitle', sortable: true, align: 'center', class: 'black--text' },
            { text: '답변여부', value: 'bbsAnswr', sortable: true, align: 'center', class: 'black--text' },
            { text: '등록일', value: 'crtDt', sortable: true, align: 'center', class: 'black--text' },
            { text: '작성자', value: 'crtr', sortable: true, align: 'center', class: 'black--text' },
        ],
        filledBtn: [
            { icon: 'mdi-pencil-outline', class: 'ml-1 white--text', color: 'blueBtnColor', text: '글쓰기', },
        ],
    }),
};
</script>