<template>
    <v-sheet class="ma-0 pa-0" rounded="lg">
        <v-row class="pa-0 justify-center align-center" no-gutters>
            <span class="text-h4 font-weight-bold">인정등급 등록/수정</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="$parent.$parent.$parent.$parent.mdlOff" x-large>mdi-close</v-icon>
        </v-row>
        <v-row class="pa-0 mt-8 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">수급자 기본정보</span>
            <v-spacer></v-spacer>
            <span class="d-inline-block rounded white" style="width: 220px;">
                <v-select
                    v-model="sltBnfcrPk"
                    :items="bnfcrPkList" 
                    item-text="viewTxt" item-value="bnfcrPk"
                    dense hide-details outlined>
                </v-select>
            </span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">수급자</span>
            </v-col>
            <v-col class="px-4 py-1" cols="7">
                <span class="mr-2">{{ bscsInf.name }}</span>
                &#40;
                <span v-if="bscsInf.gndr == 'M'">남</span>
                <span v-else-if="bscsInf.gndr == 'F'">여</span>
                <span class="ml-1">/ 만 {{ bscsInf.age }}세</span>
                &#41;
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">생년월일</span>
            </v-col>
            <v-col class="px-4 py-1" cols="7">
                <span>{{ $moment( bscsInf.rsdntBirth, 'YYYYMMDD' ).format('YYYY.MM.DD') }}</span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">입소일</span>
            </v-col>
            <v-col class="" cols="9">
                <v-row
                    class="pa-0"
                    v-for="(itm, i) in bnfcrPkList.filter( v => v.bnfcrPk == sltBnfcrPk )" :key="i"
                    no-gutters>
                    <v-col class="tableBR px-4 py-1" cols="4">
                        {{ $moment( itm.entDt, 'YYYYMMDD' ).format('YYYY.MM.DD') }}
                    </v-col>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="4">
                        <span class="font-weight-medium">퇴소일</span>
                    </v-col>
                    <v-col class="px-4 py-1" cols="4">
                        <span v-if="itm.lvDt == null || itm.lvDt == ''"></span>
                        <span v-else>
                            {{ $moment( itm.lvDt, 'YYYYMMDD' ).format('YYYY.MM.DD') }}
                        </span>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">인정번호</span>
            </v-col>
            <v-col class="d-flex align-center px-4 py-1" cols="6">
                <span v-if="acknwList.length == 0" class="mr-5">해당없음</span>
                <span v-else class="mr-5">{{ acknwList[0].acknwNum1 }}</span>
                <v-btn
                    class="crsShp-Default white--text" style="padding: 9px 7px 8px 5px !important;"
                    color="blueBtnColor" @click="onAcknwNumSavMdl"
                    min-width="20" height="20" small outlined>
                    <span class="fontNineThRem" style="padding-bottom: 2px;">인정번호 수정</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">인정등급/기간</span>
            </v-col>
            <v-col class="px-4 py-1" cols="7">
                <span
                    v-for="(itm, i) in acknwList.filter( v => v.isNow == true )" :key="i">
                    <span>{{ itm.acknwRtngNm }}</span>
                    <span class="mx-2">&#47;</span>
                    <span>{{ $moment( itm.acknwPrdStrt, 'YYYYMMDD' ).format('YYYY.MM.DD') }}</span>
                    <span class="mx-2">&#126;</span>
                    <span>{{ $moment( itm.acknwPrdEnd, 'YYYYMMDD' ).format('YYYY.MM.DD') }}</span>
                </span>
                <span v-if="acknwList.filter( v => v.isNow == true ).length == 0">해당없음</span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mt-8 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">인정등급 변경 내역</span>
            <v-spacer></v-spacer>
            <v-btn
                class="crsShp-Default white--text" style="padding: 9px 7px 8px 5px !important;"
                color="blueBtnColor" @click="onAcknwSavMdl('갱신/등급변경')"
                min-width="20" height="20" small outlined>
                <span class="fontNineThRem" style="padding-bottom: 2px;">인정등급 갱신/등급변경</span>
            </v-btn>
            <v-btn
                class="crsShp-Default white--text mx-1" style="padding: 9px 7px 8px 5px !important;"
                color="blackBtnColor" @click="onAcknwLogMdl()"
                min-width="20" height="20" small outlined>
                <span class="fontNineThRem" style="padding-bottom: 2px;">변경 이력</span>
            </v-btn>
        </v-row>
        <v-divider :class="[acknwList.length > 5 ? 'mr-2__5' : 'mr-1']" color="black"></v-divider>
        <v-row :class="[acknwList.length > 5 ? 'mr-2__5' : 'mr-1']" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="6">
                <span class="font-weight-medium">기간</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="2">
                <span class="font-weight-medium">등급</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="2">
                <span class="font-weight-medium">순번</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 py-2" cols="2">
            </v-col>
        </v-row>
        <v-divider :class="[acknwList.length > 5 ? 'mr-2__5' : 'mr-1']"></v-divider>
        <div v-if="acknwList.length > 0" class="overflow-y-auto" style="width: 100%; height: 150px;">
            <v-row
                :class="['tableBB', acknwList.length > 5 ? '' : 'mr-1']"
                v-for="(itm, i) in acknwList" :key="i"
                no-gutters>
                <v-col class="posn-rltv txtAlgnMid tableBR" style="padding-top: 2px;" cols="6">
                    <span
                        v-if="itm.isNow"
                        class="d-inline-block"
                        style="position: absolute; top: 0; left: 20px;">
                        <v-btn
                            class="crsShp-Default white--text" style="padding: 9px 6px 8px 5px !important;"
                            :color="itm.isPast ? 'grey006' : 'blueBtnColor'"  :ripple="false"
                            min-width="20" height="20" small outlined>
                            <span v-if="itm.isPast" class="fontNineThRem" style="padding-bottom: 2px;">과거</span>
                            <span v-else class="fontNineThRem" style="padding-bottom: 2px;">현재</span>
                        </v-btn>
                    </span>
                    <span
                        :class="['fontOneRem', itm.isNow ? 'ml-8' : '']">
                        {{ $moment( itm.acknwPrdStrt, 'YYYYMMDD' ).format('YYYY.MM.DD') }}
                    </span>
                    <span class="mx-2">&#126;</span>
                    <span class="fontOneRem">{{ $moment( itm.acknwPrdEnd, 'YYYYMMDD' ).format('YYYY.MM.DD') }}</span>
                </v-col>
                <v-col class="fontOneRem txtAlgnMid tableBR py-1" cols="2">
                    <span>{{ itm.acknwRtngNm }}</span>
                </v-col>
                <v-col class="fontOneRem txtAlgnMid overflow-text-hidden tableBR pa-1" cols="2">
                    <span>{{ itm.acknwNum2 }}</span>
                </v-col>
                <v-col class="px-3" cols="2">
                    <v-btn
                        class="nmlBfClrImp-white ml-1"
                        color="blueBtnColor" min-width="20" height="20"
                        @click="onAcknwSavMdl('수정/연장', itm)"
                        small icon>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" size="18">mdi-square-edit-outline</v-icon>
                            </template>
                            <span>수정</span>
                        </v-tooltip>
                    </v-btn>
                    <v-btn
                        v-if="itm.bnfcrCntrcInfPk == null"
                        class="nmlBfClrImp-white ml-1"
                        color="blackBtnColor" min-width="20" height="20"
                        @click="rmAcknwBtn(i)"
                        small icon>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" size="18">mdi-trash-can-outline</v-icon>
                            </template>
                            <span>삭제</span>
                        </v-tooltip>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <v-row v-else class="tableBB pa-0" no-gutters>
            <v-col class="txtAlgnMid py-2" cols="12">
                <span class="fontOneRem grey006--text">※ 변경내역이 존재하지 않습니다.</span>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

export default {
    name: 'AcknwChngMdl',
    
    components: {

    },

    props : { 
        prtCd62: { type: Array, default: () => { return [] } },
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            let obj = this.$store.getters.getRootInObj
            this.aplctInf(obj)

            if(this.bscsInf.bnMmbrPk > -1)
                this.getPksList(this.bscsInf.bnMmbrPk)
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
        getSubActv: function () {
            return this.$store.getters.getSubActv
        },
    },

    watch: {
        getSubEvt: function (v) {
            if(v && this.runAble == '2'){
                let chc = this.acknwList.findIndex( v => v.rmPosn == true )
                if(chc > -1)
                    this.rmAcknwyInf(this.acknwList[chc])

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                delete this.acknwList[chc].rmPosn
                this.runAble = '0'
            }
        },
        getSubActv: function (v) {
            if(this.runAble == '1' && !v && this.bscsInf.bnMmbrPk > -1){
                this.getAcknwList(this.bscsInf.bnMmbrPk, this.sltBnfcrPk)
                this.runAble = '0'
            }
        },
       'sltBnfcrPk': function(v){
            if(this.sltBnfcrPk > -1)
                this.getAcknwList(this.bscsInf.bnMmbrPk, v)
        },
    },
        
    methods: {
        // 수급자 구성원(bnfcrPk) 고유번호 조회
        getPksList: function (mbr){
            let obj = {}
            obj.bnMmbrPk = mbr
            //obj.fcltyNum = this.$store.getters.getFcltyNum

            http.post('bnfcrAuth/selBnfcrPkList', obj)
                .then((response) => ( this.pksRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/selBnfcrPkList : ' + error))
        },

        pksRst: function (res){
            if(res.statusCode == '200'){
                if(Array.isArray(res.result)){
                    res.result.forEach((itm, i) => {
                        let obj = ''
                        if(i == 0)
                            obj += '현재 '
                        else
                            obj += '과거 '

                        if(itm.lvDt == null || itm.lvDt == '')
                            obj += this.$moment(itm.entDt, 'YYYYMMDD').format('( YY.MM.DD ~ )')
                        else{
                            obj += this.$moment(itm.entDt, 'YYYYMMDD').format('( YY.MM.DD ')
                            obj += this.$moment(itm.lvDt, 'YYYYMMDD').format('~ YY.MM.DD ) ')
                        }

                        itm.viewTxt = obj
                    });
                    
                    let arr = res.result.filter( v => v.entDt.slice(0, 4) == this.prtYear )
                    if(arr.length > 0)
                        this.sltBnfcrPk = arr[0].bnfcrPk
                    else
                        this.sltBnfcrPk = res.result[0].bnfcrPk

                    this.bnfcrPkList = res.result
                }
            }
            else{
                let obj = {}
                obj.actv = true                             // 경고 노출여부
                obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
                obj.type = 'warning'                        // 경고 유형 (success, info, warning, error)
                obj.title = res.message                     // 경고 타이틀
                obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        // 수급자 인정등급 조회
        getAcknwList: function (mbr, fcr){
            this.acknwList.splice(0)

            let obj = {}
            obj.bnMmbrPk = mbr
            obj.bnfcrPk = fcr
            //obj.fcltyNum = this.$store.getters.getFcltyNum

            http.post('/bnfcrAuth/selBnfcrAcknwRtng', obj)
                .then((response) => ( this.acknwRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/selBnfcrAcknwRtng : ' + error))
        },

        acknwRst: function (res){
            if(res.result?.length > 0){
                let isPast = this.bnfcrPkList.findIndex( v => v.bnfcrPk == this.sltBnfcrPk )
                let nowDt = this.$moment()

                res.result.forEach(itm => {
                    if(itm.mdfRsn == null) itm.mdfRsn = ''
                    if(isPast == 0) itm.isPast = false
                    else if(isPast > 0) itm.isPast = true

                    if(itm.isPast && this.bnfcrPkList[isPast].lvDt != null){
                        let ent = this.$moment(this.bnfcrPkList[isPast].entDt, 'YYYYMMDD')
                        let lv = this.$moment(this.bnfcrPkList[isPast].lvDt, 'YYYYMMDD')
                        if( ent.isSameOrBefore(this.$moment(itm.acknwPrdStrt, 'YYYYMMDD')) &&
                            lv.isSameOrAfter(this.$moment(itm.acknwPrdEnd, 'YYYYMMDD')) )
                            itm.isNow = true
                        else
                            itm.isNow = false
                    }
                    else{
                        if( nowDt.isSameOrAfter(this.$moment(itm.acknwPrdStrt, 'YYYYMMDD')) && 
                            nowDt.isSameOrBefore(this.$moment(itm.acknwPrdEnd, 'YYYYMMDD')) )
                            itm.isNow = true
                        else
                            itm.isNow = false
                    }
                });
                this.acknwList = res.result
            }
        },

        // 수급자 인정등급 삭제
        rmAcknwyInf: function (obj){
            obj.bnMmbrPk = this.bscsInf.bnMmbrPk
            obj.bnfcrPk = this.sltBnfcrPk

            http.post('bnfcrAuth/delBnfcrAcknwRtng', obj)
                .then((response) => ( this.rmAcknwyRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/delBnfcrAcknwRtng : ' + error))
        },

        rmAcknwyRst: function (res){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'warning'                        // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == '200'){
                obj.type = 'info'
                
                this.getAcknwList(this.bscsInf.bnMmbrPk, this.sltBnfcrPk)
            }

            this.$store.commit('setWrnnInf', obj)
        },

        // 기본정보 적용
        aplctInf: function (obj) {
            this.bscsInf.bnMmbrPk = obj.bnMmbrPk
            this.bscsInf.name = obj.name
            this.bscsInf.gndr = obj.gndr
            this.bscsInf.age = obj.age
            this.bscsInf.rsdntBirth = obj.rsdntBirth
            this.bscsInf.acknwRtng = obj.acknwRtng
            this.bscsInf.acknwRtngNm = obj.acknwRtngNm
            this.bscsInf.acknwNum1 = obj.acknwNum1
            this.bscsInf.acknwNum2 = obj.acknwNum2
            this.bscsInf.acknwPrdStrt = obj.acknwPrdStrt
            this.bscsInf.acknwPrdEnd = obj.acknwPrdEnd
        },

        // 인정등급 저장 팝업 오픈
        onAcknwSavMdl: function (txt, obj) {
            let tmp = Object.assign({}, this.bscsInf)
            tmp.bnfcrPk = this.sltBnfcrPk
            tmp.subTtl = txt

            let chc = this.acknwList.findIndex( v => v.isNow )
            if(chc > -1)
                tmp.isNowObj = this.acknwList[chc]
            else
                tmp.isNowObj = {}
       
            if(txt == '수정/연장'){
                tmp.bnfcrAcknwRtngPk = obj.bnfcrAcknwRtngPk
                tmp.bnfcrCntrcInfPk = obj.bnfcrCntrcInfPk
                tmp.cntrcDtNms = obj.cntrcDtNms
                tmp.acknwRtng = obj.acknwRtng
                tmp.acknwPrdStrt = obj.acknwPrdStrt
                tmp.acknwPrdEnd = obj.acknwPrdEnd
                tmp.acknwNum2 = obj.acknwNum2
                tmp.mdfRsn = obj.acknwNum2
            }

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 600, prsst: true, typ: '2004', inObj: tmp, outObj: null, runEvt: false,
            });

            this.runAble = '1'
        },

        // 인정번호 수정 팝업 오픈
        onAcknwNumSavMdl: function () {
            let tmp = Object.assign({}, this.bscsInf)
            tmp.bnfcrPk = this.sltBnfcrPk
            if(this.acknwList.length == 0)
                tmp.acknwNum1 = ''
            else
                tmp.acknwNum1 = this.acknwList[0].acknwNum1

            let chc = this.acknwList.findIndex( v => v.isNow )
            if(chc > -1)
                tmp.isNowObj = this.acknwList[chc]
            else
                tmp.isNowObj = {}
            
            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 600, prsst: true, typ: '2008', inObj: tmp, outObj: null, runEvt: false,
            });

            this.runAble = '1'
        },

        // 인정등급 변경이력 팝업 오픈
        onAcknwLogMdl: function () {
            let tmp = Object.assign({}, this.bscsInf)
            tmp.bnfcrPk = this.sltBnfcrPk
            
            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 1100, prsst: true, typ: '2005', inObj: tmp, outObj: null, runEvt: false,
            });
        },

        // 인정등급 삭제 확인
        rmAcknwBtn: function (idx) {
            this.acknwList[idx].rmPosn = true
            this.runAble = '2'

            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            obj.title = '기간: ' + this.$moment(this.acknwList[idx].acknwPrdStrt, 'YYYYMMDD').format('YY.MM.DD') + ' ~ ' + 
                this.$moment(this.acknwList[idx].acknwPrdEnd, 'YYYYMMDD').format('YY.MM.DD')
            obj.cntnt = ['인정등급을(를) 삭제하시겠습니까?']
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 삭제하겠습니다.'

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
            });   
        },
    },

    data: () => ({
        sltBnfcrPk: -1,
        runAble: '0',
        bnfcrPkList: [],
        acknwList: [],
        bscsInf: {
            bnMmbrPk: -1,  name: '', gndr: '', age: '', rsdntBirth: '',
            acknwRtng: '', acknwRtngNm: '', acknwNum1: '', acknwNum2: '', acknwPrdStrt: '', acknwPrdEnd: ''
        },
    }),
};
</script>