<template>
    <v-sheet class="rounded-lg rounded-l-0 overflow-auto pa-2" color="" :width="areaWidth" >
        <div class="d-flex mb-2 mr-1">
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn v-if="list.type == 'action'" @click="onClickBtn(list.text)" :class="list.class" samll height="30"
                    :color="list.color" :disabled="list.disabled" rounded>
                    <v-icon small>{{ list.icon }}</v-icon>
                    <span style="font-size: 1rem;">{{ list.text }}</span>
                </v-btn>
                <v-dialog v-else-if="list.type == 'dialogue'" v-model="dialog[i]" :max-width="list.width">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled" :class="list.class" samll height="30"
                            :color="list.color" rounded>
                            <v-icon small>{{ list.icon }}</v-icon>
                            <span style="font-size: 1rem;">{{ list.text }}</span>
                        </v-btn>
                    </template>
                </v-dialog>
            </span>
        </div>
        <v-divider class="mb-3"></v-divider>
        <div>
            <v-tabs class="tab-dft-Theme" active-class="black" center-active hide-slider >
                <v-tab
                    v-for="item in tabItems" :key="item.tab"
                    :class="item.class" :disabled="item.enter" :to="item.to">
                    {{ item.tab }}
                </v-tab>
            </v-tabs>

            <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>

            <div>
                <router-view ref="PrpBnfcrGrp"></router-view>
            </div>

        </div>
        <v-dialog v-model="menu[0]" eager content-class="round" persistent max-width="800">
            <PrgrmgrMultiSelector ref="pMSelector" @isClose="isModal(0)" @reload="reset()" ></PrgrmgrMultiSelector>
        </v-dialog>
    </v-sheet>
</template>

<script>
import PrgrmgrMultiSelector from '../../components/prgrm/PrgrmgrMultiSelector.vue'

export default {
    name: 'PrgBnfcrGrpMgmt',

    components: {
        PrgrmgrMultiSelector,
    },

    props: {

    },

    created: function () {
        for (let index = 0; index < (this.filledBtn.length + this.outlinedBtn.length); index++) {
            this.dialog.push(false)
        }
    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.areaWidth = 1260 - Number(this.$parent.$parent.midAreaWidth)
        })
    },

    computed: {
        getSelectedItem() {
            return this.$parent.$parent.selectedItem1
        },

    },

    watch: {
        getSelectedItem(value) {
            this.$refs.PrpBnfcrGrp.setPrpInf(value)
        },
    },

    methods: {
        reset: function () {
            this.$emit('reload', true)
        },
        clearForm: function () {
            this.tab = 0
            // this.tabItems[1].enter = true
            // this.tabItems[2].enter = true
            this.$refs.PrpBnfcrGrp.clearFilds()
        },
        onClickBtn: function (text) {
            if (text == "저장") {
                this.$refs.PrpBnfcrGrp.onShow()
            } else if (text == "추가") {
                this.onModal()
                this.$refs.pMSelector.btnShowMethod2()
            }
        },
        onModal: function (key) {
            this.menu.splice(key, 1, true)
        },
        isModal: function (key) {
            this.menu.splice(key, 1, false)
        },
        
    },
    data: () => ({
        tab: 0,
        dialog: [false],
        dialogTab: [false],
        menu: [false],
        // areaWidth: 708,
        areaWidth: '800',
        tabItems: [
            { index: 0, tab: '수급자별 그룹설정', class: 'fontOneDotTwoRem px-15', enter: false, to: '/tpl002/bnfcrAdv1/prgBnfcrGrpMgmt/grpstRcpnt' },
            { index: 1, tab: '그룹별 수급자설정', class: 'fontOneDotTwoRem px-15', enter: false, to: '/tpl002/bnfcrAdv2/prgBnfcrGrpMgmt/prpBnfcrGrp' },
        ],
        filledBtn: [
            { icon: 'mdi-plus-box-multiple', class: 'ml-1 white--text', color: 'blueBtnColor', text: '추가', type: 'action', width: '1200px', disabled: false, },
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color: 'blueBtnColor', text: '저장', type: 'action', width: '600px', disabled: false, },
        ],
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color: 'grey006', text: '출력', width: '1000px', disabled: true, },
        ],

    }),
};
</script>