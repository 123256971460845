<template>
    <v-sheet class="d-flex rounded-lg rounded-l-0" color="" min-width="1260" max-width="1560" height="835">
        <v-sheet class="d-inline-block" :width="midAreaWidth" height="835" color="midAreaColor">
            <div class="text-h4 px-2">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">
                    <span v-if="routerRocation=='WrkngSchdl'">7-2. 근무일정현황</span>
                </span>
                <div class="d-flex align-center mt-3">
                    <!-- <exBtn class="mr-1" :btnData="exBtn[0]" @btnClosed="expandClose" @changeCheck="filterSearch" /> -->
                    <exBtn class="mr-1" :btnData="exBtn[1]" @btnClosed="expandClose" @changeCheck="filterSearch" />
                    <exBtn class="mr-1" :btnData="exBtn[2]" :sltElmnt="sltElmnt" @btnClosed="expandClose" @changeCheck="fltGrpChc" />
                
                    <v-spacer></v-spacer>
                    
                    <span class="d-inline-block mt-1" style="width: 130px;">
                        <v-text-field class="ma-0 pa-0 nameSearch" height="33px" background-color="white" append-icon="mdi-magnify"
                            single-line rounded outlined dense filled hide-details label="이름조회" @input="setSearchText">
                        </v-text-field>
                    </span>
                </div>
                <div class="mt-2 rounded-lg">
                    <v-data-table class="msclnTable empCheckTable pt-1" height="657" locale="ko"
                        :headers="headerList" :items="empList" :search="schDummy" :custom-filter="customFilter" :key="forceRender"
                        v-model="tableSelected" item-key="mmbrPk" checkbox-color="primary"
                        show-select fixed-header disable-pagination hide-default-footer dense>
                        <template v-slot:[`header.data-table-select`]="{ props, on }" >
                            <tr>
                                <td v-if="routerRocation=='WrkngSchdl'" class="">
                                    <span class="d-inline-block pl-1" style="width: 24px; height: 28px;">
                                        <v-checkbox
                                            class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                            :input-value="props.value"
                                            @change="on.input($event)"
                                            hide-details>
                                        </v-checkbox>
                                    </span>
                                </td>
                            </tr>
                        </template>
                        <template v-slot:item='{ item, isSelected, select }'>
                            <tr>
                                <td v-if="routerRocation=='WrkngSchdl'" class="py-1">
                                    <span class="d-inline-block" style="width: 24px; height: 31px; padding-left: 5px;">
                                        <v-checkbox
                                            class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                            :input-value="isSelected"
                                            @change="select($event)"
                                            hide-details>
                                        </v-checkbox>
                                    </span>
                                </td>
                                <td class="" style="text-align: center;">
                                    <span class="cmmFontSizeOne">{{ item.name }}</span>
                                    <span class="d-inline-block" style="width: 18px;"></span>
                                </td>
                                <td class="" style="text-align: center;">
                                    <span class="cmmFontSizeOne">{{ item.jobCd }}</span>
                                    <span class="d-inline-block" style="width: 18px;"></span>
                                </td>
                                <td class="" style="text-align: center;">
                                    <span class="cmmFontSizeOne">{{ item.wrkCtCnt }}</span>
                                </td>
                                <td class="" style="text-align: center;">
                                    <span class="cmmFontSizeOne">{{ item.actWrkHr }}</span>
                                </td>
                                <td class="" style="text-align: center;">
                                    <v-icon v-if="item.vctnSbstYn == '1'" color="blueBtnColor" size="18">mdi-check-bold</v-icon>
                                    <v-icon v-else size="18">mdi-minus-thick</v-icon>
                                </td>
                                <td class="" style="text-align: center;">
                                    <span v-if="$refs.empChild.tab == 1">
                                        <v-btn
                                            @click="$refs.empChild.$refs.schdlMgmt.setDlyMdlProp({mPk: item.mmbrPk, ePk: item.empPk})"
                                            icon>
                                            <v-icon size="18">mdi-square-edit-outline</v-icon>
                                        </v-btn>
                                    </span>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </div>
                <div class="d-flex align-center px-1">
                    <v-spacer></v-spacer>
                    <span class="font-weight-medium red--text fontOneRem" v-if="stdWrkHr != ''">
                        ※기준근무시간&#58;&nbsp;{{ stdWrkHr }}시간
                    </span>
                    <v-btn
                        class="ml-1" width="20" height="20" @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 7)" icon>
                        <v-icon size="20" style="padding-top: 3px;">mdi-help-circle-outline</v-icon>
                    </v-btn>
                </div>
            </div>
        </v-sheet>
        <router-view ref="empChild"></router-view>
    </v-sheet>
</template>

<script>
import exBtn from '../../components/commons/expandButtonCustom.vue';

import { getMultiStcd } from '../../api/index.js';
import { selEmpWrkSchdHrlList, selStdWrkHrInfo, selEmpGrpList } from '../../api/emp.js';

export default {
    name: 'EmpUnqCnfgr',
        
    components: {
        exBtn,
    },

    created: function(){
        this.getCode(['42', '70', '73', '107'])
        this.getEmpGrpList()
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.routerRocation = this.$route.name
        })
    },

    computed: {

    },

    watch:{ 
        '$route' (to, from) {
            this.routerRocation = to.name
            console.log('Url Event from: ' + from.name)
        },
        'routerRocation': function (){
            this.getTableList()
        },
        'sltDt': function (){
            let trgDt = ''
            if(this.sltDt == '')
                trgDt = this.$moment().format('YYYYMM')
            else
                trgDt = this.$moment(this.sltDt, 'YYYYMMDD').format('YYYYMM')

            if(this.schChkField.wrkStcd.length == 0)
                this.schChkField.wrkStcd.push('재직')
 
            if(this.schChkField.wrkStcd.length > 0){
                this.vCd70.forEach(code => {
                    if(this.schChkField.wrkStcd[0] == code.valcdnm)
                        this.getEmpWrkSchdHrlList(trgDt, code.valcd)
                })
            }

            this.getStdWrkHrInfo(trgDt)
        },
    },
        
    methods: {
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        getStdWrkHrInfo: function (yymm) {
            selStdWrkHrInfo(yymm)
                .then((response) => ( this.getStdWrkHrInfoAfter(response.data) ))
                .catch((error) => console.log('connect error /impExp/selStdWrkHrInfo : ' + error))
        },
        //근무일정현황 조회
        getEmpWrkSchdHrlList: function (date, code) {
            selEmpWrkSchdHrlList(this.$store.getters.getFcltyNum, code, date)
                .then((response) => ( this.getEmpWrkSchdHrlListAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/selEmpWrkSchdHrlList : ' + error))
        },
        //직원 조 편성 관리 조회
        getEmpGrpList: function () {
            selEmpGrpList(this.$store.getters.getFcltyNum)
                .then((response) => ( this.getEmpGrpListAfter(response.data) ))
                .catch((error) => console.log('upload error /emp/selEmpGrpList : ' + error))
        },
        stCdMapping: function (res) {
            this.vCd42 = res.vCd42
            this.vCd70 = res.vCd70
            this.vCd73 = res.vCd73
            this.vCd107 = res.vCd107.filter( v => v.valcd != '90' )
           
            this.vCd42.forEach(code => {
                this.exBtn[1].expandList.push(code.valcdnm)
            })
            this.vCd70.forEach(code => {
                this.exBtn[0].expandList.push(code.valcdnm)
            })
        },
        getEmpWrkSchdHrlListAfter: function (res) {
            this.empList.splice(0)
       
            res.forEach(items => {
                this.vCd42.forEach(item => {
                    if(items.jobCd == item.valcd)
                        items.jobCd = item.valcdnm
                });
                this.vCd70.forEach(item => {
                    if(items.wrkStcd == item.valcd)
                        items.wrkStcd = item.valcdnm
                });
                //items.isSelected = false
            });
   
            this.empList = res
        },
        getStdWrkHrInfoAfter: function (res) {
            if(typeof res.stdWrkHr != 'undefined')
                this.stdWrkHr = res.stdWrkHr
            else
                this.stdWrkHr = ''
        },
        getEmpGrpListAfter: function (res) {
            this.exBtn[2].expandList.splice(0)
            res.unshift({ dscp: '', empGrpPk: 0, empItm: [], grpNm: '선택 안함' })

            res.forEach(code => {
                this.exBtn[2].expandList.push(code.grpNm)
            })

            this.grpList = res
        },
        getTableList: function(){
            // let dt = ''
            // if(this.$refs.empChild.$refs.empWrkngSchdl.start == '')
            //     dt = this.$moment().format('YYYY-MM-DD')
            // else
            //     dt = this.$refs.empChild.$refs.empWrkngSchdl.start
           
            switch(this.routerRocation) {
                case 'WrkngSchdl':
                    this.empList.splice(0)
                    this.midAreaWidth = '410'
                    this.headerList.forEach(items => {
                        this.groupList.WrkngSchdl.forEach(element => {
                            if(items.text == element) items.on = true
                        });
                    });

                    if(this.sltDt == '')
                        this.getEmpWrkSchdHrlList(this.$moment().format('YYYYMM'), '1')

                    break;
                default:
                    break;
            }
        },
        checkBoxMessage: function () {
     
            if(this.allChecker == true){
                this.empList.forEach(item => {
                    item.isSelected = true
                })
            }
            else if(this.allChecker == false){
                this.empList.forEach(item => {
                    item.isSelected = false
                })
            }
        },
        checkBoxIndex: function () {
            let chk = []
            this.empList.forEach(items => {
                if(typeof items.isSelected == 'undefined') chk.push(false)
                else chk.push(items.isSelected)
            });

            let result = chk.every((item) => item==true)
            if(result) this.allChecker = true
            else this.allChecker = false
        },
        setSearchText: function (value) {
            this.schField = value
            this.tableSelected.splice(0)
            this.schDummy = this.schDummy + '.'
        },
        fltGrpChc: function (chc) {
            this.tableSelected.splice(0)
            if(chc.length == 1){
                let rst = this.grpList.findIndex( v => v.grpNm == chc[0] )

                if(rst != -1){
                    this.grpList[rst].empItm.forEach(el => {
                        let inRst = this.empList.findIndex( v => v.empPk == el.emppk )
                
                        if( inRst != -1 ){

                            this.empList[inRst].isSelected = true
                            this.tableSelected.push(this.empList[inRst])
                        }
                    });
                }
            }
            this.forceRender += 1
        },
        filterSearch: function (sch) {
            this.tableSelected.splice(0)
            switch(this.schChkField.target) {
                case 'wrkStcd': this.schChkField.wrkStcd.splice(0)
                sch.forEach((item) => {
                        this.schChkField.wrkStcd.push(item)
                        this.vCd70.forEach(code => {
                            if(item == code.valcdnm){
                                this.getEmpWrkSchdHrlList(this.$moment(this.sltDt, 'YYYY-MM-DD', true).format('YYYYMM'), code.valcd)
                                this.sltEmpPk = -1
                            }
                        })
                    });
                    break;
                case 'jobCd': this.schChkField.jobCd.splice(0)
                    sch.forEach((item) => { this.schChkField.jobCd.push(item) });
                    break;
            }
          
            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column
            let col0 = filter.name.includes(this.schField)
            
            let col1 = true
            if(this.schChkField.wrkStcd.length != 0){
                this.schChkField.wrkStcd.forEach((stcd, i) => {
                    if(i == 0) col1 = filter.wrkStcd.includes(stcd)
                    else col1 = col1 || filter.wrkStcd.includes(stcd)
                });
            }

            let col2 = true
            if(this.schChkField.jobCd.length != 0){
                this.schChkField.jobCd.forEach((stcd, i) => {
                    if(i == 0) col2 = filter.jobCd.includes(stcd)
                    else col2 = col2 || filter.jobCd.includes(stcd)
                });
            }

            return col0 && col1 && col2;
        },
        expandClose: function (index) {
            switch(index) {
                case 0: this.schChkField.target = 'wrkStcd'
                    break;
                case 1: this.schChkField.target = 'jobCd'
                    break;
                default: this.schChkField.target = ''
                    break;
            }
        },
    },
    
    data: () => ({
        allChecker: false,
        stdWrkHr: 0,
        sltElmnt: '',
        sltDt: '',
        vCd42: [],      //직원 직종 코드
        vCd70: [],      //직원 상태 코드
        vCd73: [],      //근무 상태 코드
        vCd107: [],     //휴가 상태 코드
        tableSelected: [],
        empList: [],
        grpList: [],
        schField: '',
        schDummy: '',
        schChkField: { wrkStcd: [], jobCd: [], target: '' },
        forceRender: 0,
        routerRocation: '',
        midAreaWidth: '380',
        headerList: [
            { text: '직원명', value: 'name', align: 'center', on: false, sortable: true, width: '80', class: 'black--text sftyHeader py-2'},
            { text: '직종', value: 'jobCd', align: 'center', on: false, sortable: true, width: '80', class: 'black--text sftyHeader'},
            { text: '근무일수', value: 'wrkCtCnt', align: 'center', on: false, sortable: false, width: '30', class: 'black--text sftyHeader txtHgt pb-1'},
            { text: '근무시간', value: 'actWrkHr', align: 'center', on: false, sortable: false, width: '30', class: 'black--text sftyHeader txtHgt pb-1'},
            { text: '대체가능', value: 'actWrkHr', align: 'center', on: false, sortable: false, width: '30', class: 'black--text sftyHeader txtHgt pb-1'},
            { text: '일정', value: 'actWrkHr', align: 'center', on: false, sortable: false, width: '40', class: 'black--text sftyHeader txtHgt pb-1'},
        ],
        groupList:{
            WrkngSchdl: ['직원명', '직종', '근무일수', '근무시간', '대체가능', '일정'],
        },
        exBtn: [
            { index:  0, btnTitle: '상태', btnIcon: 'house', mode: 'single', exWidth: 94, exHeigth: 100, expandList: [], },
            { index:  1, btnTitle: '직종', btnIcon: 'business', exWidth: 134, exHeigth: 220, expandList: [], },
            { index:  2, btnTitle: '그룹', mode:'single', btnIcon: 'group', exWidth: 148, exHeigth: 140, expandList: [], },
        ],
    }),
};
</script>

<style>
    .empCheckTable .v-data-table__wrapper table .v-data-table-header tr th { border-bottom: 2px solid black !important;}
    .empCheckTable .v-data-table__wrapper table tbody tr td { font-size: 0.975rem !important; }
    .txtHgt { line-height: 18px; }
</style>