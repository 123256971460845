<template>
    <v-sheet class="ma-0" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <span class="text-h4 font-weight-bold">생활실&nbsp;{{ bscsInf.ttl }}</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="$parent.$parent.$parent.$parent.mdlOff" x-large>mdi-close</v-icon>
        </v-row>
        <v-row class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">기존 정보</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">수급자</span>
            </v-col>
            <v-col class="px-4 py-1" cols="7">
                <span class="mr-2">{{ bscsInf.name }}</span>
                &#40;
                <span v-if="bscsInf.gndr == 'M'">남</span>
                <span v-else-if="bscsInf.gndr == 'F'">여</span>
                <span class="ml-1">/ 만 {{ bscsInf.age }}세</span>
                &#41;
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">생활실</span>
            </v-col>
            <v-col class="px-4 py-1" cols="7">
                <span class="mr-2">{{ bscsInf.lvnRmNm }}</span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">변경 정보</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="3">
                <span class="font-weight-medium posn-rltv">
                    적용시작일
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="d-flex align-center px-4" cols="9">
                <span class="d-inline-block" style="width: 134px; padding-top: 1px;">
                    <CmmDateComp
                        v-model="inpInf.aplStrt"
                        :required="true" :minDt="bscsInf.entDt" :maxDt="bscsInf.lvDt"
                        @input="aplctSavBtn">
                    </CmmDateComp>
                </span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="3">
                <span class="font-weight-medium posn-rltv">
                    생활실
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="d-flex align-center px-4" cols="9">
                <span class="d-inline-block" style="width: 100px; padding-top: 1px;">
                    <v-text-field
                        v-model="inpInf.lvnRmNm" @input="aplctSavBtn"
                        class="" height="28" color="blue"
                        hide-details outlined readonly dense>
                    </v-text-field>
                </span>
                <v-btn
                    class="ml-1" color="#FFB6B9" style="padding: 0px 1px 0px 2px !important;"
                    min-width="30" height="26" @click="isLvmSltMdl = true"
                    dark small>
                    <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-bed-king-outline</v-icon>
                        </template>
                        <span>생활실선택</span>
                    </v-tooltip>
                </v-btn>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 tableBR d-flex align-center justify-center" cols="3">
                <span class="font-weight-medium posn-rltv">
                    변경사유
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="px-4 py-1" cols="9">
                <v-text-field
                    v-model="inpInf.mdfRsn" @input="aplctSavBtn"
                    class="" height="28" color="blue"
                    hide-details outlined dense>
                </v-text-field>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-divider class="white my-2"></v-divider>
        <v-row class="pa-0 justify-center" no-gutters>
            <v-btn
                class="" color="blueBtnColor" min-width="110" height="36"
                :disabled="savBtn" @click="dcsnSavBtn">
                <span class="white--text fontOneDotTwoRem">저장</span>
            </v-btn>
        </v-row>
        <v-divider class="white mt-1"></v-divider>

        <v-dialog v-model="isLvmSltMdl" max-width="800" persistent eager>
            <LvnRmMdl
                @modalClose="isLvmSltMdl = false" @sltLvnRmInf="rflLvnRmInf">
            </LvnRmMdl>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

import CmmDateComp from '../../commons/CmmDateComp.vue';
import LvnRmMdl from '../../opr/LvnRmMdl.vue';

export default {
    name: 'LvnSavMdl',

    components: {
        CmmDateComp,
        LvnRmMdl,
    },

    props : {

    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            let obj = this.$store.getters.getSubInObj
            this.aplctInf(obj)
        })
    },

    computed: {
      
    },

    watch: {

    },

    methods:{
        // 생활실 변경내역 신규 등록
        setLvnInf: function (obj){
            this.savBtn = true

            http.post('bnfcrAuth/insBnfcrLvnRm', obj)
                .then((response) => ( this.insLvnRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/insBnfcrLvnRm : ' + error))
        },

        insLvnRst: function (res){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == '200')
                this.savBtn = false
            else
                obj.type = 'warning'

            this.$store.commit('setWrnnInf', obj)
        },

        // 생활실 변경내역 수정
        updLvnInf: function (obj){
            this.savBtn = true

            http.post('bnfcrAuth/updBnfcrLvnRm', obj)
                .then((response) => ( this.insLvnRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/updBnfcrLvnRm : ' + error))
        },

        // 기본정보 적용
        aplctInf: function (obj) {
            this.lvnHisList = obj.lvnHisList

            this.bscsInf.bnMmbrPk = obj.bnMmbrPk
            this.bscsInf.bnfcrPk = obj.bnfcrPk
            this.bscsInf.name = obj.name
            this.bscsInf.gndr = obj.gndr
            this.bscsInf.age = obj.age
            this.bscsInf.rsdntBirth = obj.rsdntBirth
            this.bscsInf.ttl = obj.subTtl
            this.bscsInf.lvnRmInfPk = obj.isNowObj?.lvnRmInfPk
            this.bscsInf.lvnRmNm = obj.isNowObj?.lvnRmNm
            this.bscsInf.entDt = this.$moment(obj.entDt, 'YYYYMMDD').format('YYYY-MM-DD')
            if(obj.lvDt == null || obj.lvDt == '')
                this.bscsInf.lvDt = '2099-12-31'
            else
                this.bscsInf.lvDt = this.$moment(obj.lvDt, 'YYYYMMDD').format('YYYY-MM-DD')

            if(obj.subTtl == '정보 수정'){
                this.inpInf.bnfcrLvnRmPk = obj.bnfcrLvnRmPk
                this.inpInf.lvnRmInfPk = obj.lvnRmInfPk
                this.inpInf.aplStrt = this.$moment(obj.aplStrt, 'YYYYMMDD').format('YYYY-MM-DD')
                this.inpInf.lvnRmNm = obj.lvnRmNm
                this.inpInf.mdfRsn = obj.mdfRsn
            }
            else{
                delete this.inpInf.bnfcrLvnRmPk
                this.rstFld()
            }

            this.aplctSavBtn()
        },

        // 저장버튼 분기
        dcsnSavBtn: function () {
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = '변경 정보를 입력해주세요.'     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(this.inpInf.aplStrt == '' || this.inpInf.lvnRmNm == '' || this.inpInf.mdfRsn == ''){
                this.$store.commit('setWrnnInf', obj)
                return 0
            }

            let tmpDt = this.$moment(this.inpInf.aplStrt, 'YYYY-MM-DD')
            let chc = this.lvnHisList.filter( v => v.aplStrt == tmpDt.format('YYYYMMDD') )
            if(chc.length > 0 && this.$store.getters.getSubInObj.aplStrt != tmpDt.format('YYYYMMDD') ){
                obj.title = '중복된 적용시작일이 존재합니다.'
                obj.type = 'warning'
                this.$store.commit('setWrnnInf', obj)
                return 0
            }

            if( tmpDt.isBefore(this.$moment(this.bscsInf.entDt, 'YYYY-MM-DD'))
                || tmpDt.isAfter(this.$moment(this.bscsInf.lvDt, 'YYYY-MM-DD')) ){
                    obj.title = '적용시작일은 입소일 이후 퇴소일 이전만 입력 가능합니다.'
                    obj.type = 'warning'
                    this.$store.commit('setWrnnInf', obj)
                    return 0
            }

            let insObj = Object.assign({}, this.inpInf)
            insObj.aplStrt = this.$moment(insObj.aplStrt, 'YYYY-MM-DD').format('YYYYMMDD')
            insObj.bnMmbrPk = this.bscsInf.bnMmbrPk
            insObj.bnfcrPk = this.bscsInf.bnfcrPk

            if(this.bscsInf.ttl == '신규 등록')
                this.setLvnInf(insObj)
            else if(this.bscsInf.ttl == '정보 수정')
                this.updLvnInf(insObj)
        },

        // 저장버튼 활성 여부 체크
        aplctSavBtn: function () {
            this.savBtn = true

            if(this.inpInf.lvnRmInfPk == -1)
                return 0
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.inpInf.aplStrt) == false)
                return 0
            if(this.inpInf.lvnRmNm == '')
                return 0
            if(this.inpInf.mdfRsn == '')
                return 0

            this.savBtn = false
        },

        // 입력필드 초기화
        rstFld: function () {
            this.inpInf.lvnRmInfPk = -1
            this.inpInf.aplStrt = ''
            this.inpInf.lvnRmNm = ''
            this.inpInf.mdfRsn = ''
        },

        // 생활실 선택 적용
        rflLvnRmInf: function (obj) {
            this.inpInf.lvnRmNm = obj.lvnRmNm
            this.inpInf.lvnRmInfPk = obj.lvnRmInfPk
        },
    },

    data: () => ({
        savBtn: true,
        isLvmSltMdl: false,
        lvnHisList: [],
        bscsInf: {
            bnMmbrPk: -1, bnfcrPk: -1, name: '', gndr: '', age: '', rsdntBirth: '',
            lvnRmInfPk: -1,  lvnRmNm: '', ttl: '', entDt: '', lvDt: ''
        },
        inpInf: {
            bnfcrLvnRmPk: -1, lvnRmInfPk: -1,  lvnRmNm: '', aplStrt: '', mdfRsn: ''
        },
    })
}
</script>