<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" style="position:relative">
        <div class="d-flex justify-center align-center">
            <span class="text-h4 font-weight-bold">인건비반영&nbsp;급여비용&nbsp;설정&nbsp;안내</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <div>
            <v-img min-width="94%" max-width="96%" src="../../assets/Lbrex.png" eager>
            </v-img>
        </div>
    </v-sheet>
</template>

<script>
export default {
    name: 'LbrexImp',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        onClose: function () {
            this.$emit('isClose', '.')
        },
    },
};
</script>

<style lang="scss" scoped>

</style>