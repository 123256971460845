<template>
    <v-sheet class="pt-4">
        <v-row class="pa-0" no-gutters>
            <v-col class="d-flex align-center" cols="6">
                <v-icon style="font-size: 28px; width: 8px;" color="black">mdi-chevron-right</v-icon>
                <span class="text-h6 font-weight-bold ml-3">퇴소관리</span>
            </v-col>
            <v-col class="d-flex justify-end align-center" cols="6">
                <span>
                    <v-btn
                        class="white--text" height="26" color="blueBtnColor"
                        @click="pre_LvInf" :disabled="saveBtn && sltItm != -1" rounded samll>
                        <v-icon small>mdi-content-save-outline</v-icon>
                        <span class="fontOneRem">저장</span>
                    </v-btn>
                </span>
            </v-col>
        </v-row>
        
        <v-form ref="form" lazy-validation>            
            <v-divider class="mt-2"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-2" cols="2">
                    <span class="black--text font-weight-medium">퇴소일</span>
                    <v-btn
                        class="ml-1" width="20" height="20" @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 26)" icon>
                        <v-icon size="20" style="padding-top: 2px;">mdi-help-circle-outline</v-icon>
                    </v-btn>
                </v-col>
                <v-col class="d-flex align-center tableBL" cols="10">
                    <div>
                        <span class="d-inline-block pl-2" style="width: 140px;">
                            <CmmDateComp
                                v-model="lvInf.lvDt"
                                :disabled="sltItm != -1"
                                :minDt="
                                    sltItm == -1 ? $moment(this.$parent.$parent.$parent.$parent.entDt, 'YYYYMMDD').add(1, 'days').format('YYYY-MM-DD') : '' "
                                :required="true">
                            </CmmDateComp>
                        </span>
                        <span class="d-inline-block ml-2" style="width: 80px;">
                            <CmmTimeField v-model="lvInf.lvTm" :required="true"></CmmTimeField>
                        </span>
                    </div>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 txtAlgnMid py-2" cols="2">
                    <span class="black--text font-weight-medium">퇴소정산</span>
                    <v-btn
                        class="ml-1" width="20" height="20" @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 32)" icon>
                        <v-icon size="20" style="padding-top: 2px;">mdi-help-circle-outline</v-icon>
                    </v-btn>
                </v-col>
                <v-col class="d-flex align-center tableBL px-2" cols="4">
                    <span class="d-inline-block" style="width: 160px; height: 24px;">
                        <v-checkbox
                            v-model="lvInf.lvCalcYn" max-width="20"
                            :disabled="visibleLvCalc"
                            class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                            true-value="1" false-value="2" label="퇴소정산 청구서 생성"
                            on-icon="mdi-check-circle-outline" off-icon="mdi-circle-outline"
                            hide-details>
                        </v-checkbox>
                    </span>
                </v-col>
                <v-col v-if="lvInf.lvCalcYn == '1'" class="greyE01 txtAlgnMid py-2" cols="2">
                    <span class="black--text font-weight-medium">납부기한</span>
                </v-col>
                <v-col v-if="lvInf.lvCalcYn == '1'" class="d-flex align-center tableBL px-2" cols="4">
                    <span
                        class="d-inline-block" style="width: 140px;">
                        <CmmDateComp v-model="lvInf.dueDt" :minDt="lvInf.lvDt" :required="true"></CmmDateComp>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 txtAlgnMid py-2" cols="2">
                    <span class="black--text font-weight-medium">동행인</span>
                </v-col>
                <v-col class="d-flex justify-left align-center tableBL" cols="4">
                    <span class="d-inline-block ml-2" style="width:100px;">
                        <v-text-field
                            v-model="lvInf.cmpny" :rules="[rules.required]"
                            height="28" readonly hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <v-btn 
                        class="ml-1 white--text darken-1" style="padding:0px 1px 0px 2px !important"
                        color="bckgrColor" min-width="30" height="26" @click="onModal"
                        dark small>
                        <v-tooltip top >
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                            </template>
                            <span>보호자 선택</span>
                        </v-tooltip>
                    </v-btn>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center py-2" cols="2">
                    <span class="black--text font-weight-medium">관계</span>
                </v-col>
                <v-col class="d-flex align-center tableBL" cols="4">
                    <span class="d-inline-block ml-2" style="width:100px;">
                        <v-text-field
                            v-model="lvInf.rltns" :rules="[rules.required]"
                            height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-2" cols="2">
                    <span class="black--text font-weight-medium">퇴소사유</span>
                </v-col>
                <v-col class="tableBL pa-2" cols="10">
                    <span class="d-inline-block" style="width:100%;">
                        <v-text-field
                            v-model="lvInf.lvRsn" :rules="[rules.required]"
                            height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-2" cols="2">
                    <span class="black--text font-weight-medium">비고</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBL pa-2" cols="10">
                    <span class="d-inline-block" style="width:100%;">
                        <v-text-field
                            v-model="lvInf.rmrks"
                            height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider style="margin-bottom: 177px;"></v-divider>
        </v-form>

        <v-divider class="bodyColor my-2 mb-4" style="padding:1px 0px;"></v-divider>

        <v-row class="align-center" no-gutters>
            <v-icon style="font-size: 28px; width: 8px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold ml-3">퇴소&nbsp;이력</span>
            <v-spacer></v-spacer>
            <span class="grey006--text fontEighthRem">&#8251;&nbsp;퇴소이력 삭제는 퇴소 미정산시에만 가능합니다.</span>
        </v-row>

        <v-divider :class="['mt-2', lvList.length > 2 ? 'mr-2__5' : '']"></v-divider>
        <v-row :class="['pa-0 greyE01', lvList.length > 2 ? 'mr-2__5' : '']" no-gutters>           
            <v-col class="txtAlgnMid py-2" cols="3">
                <span class="black--text font-weight-medium">퇴소일&nbsp;&#40;&nbsp;시간&nbsp;&#41;</span>
            </v-col>
            <v-col class="txtAlgnMid tableBL py-2" cols="4">
                <span class="black--text font-weight-medium">퇴소사유</span>
            </v-col>
            <v-col class="txtAlgnMid tableBL py-2" cols="3">
                <span class="black--text font-weight-medium">비고</span>
            </v-col>
            <v-col class="txtAlgnMid tableBL py-2" cols="1">
                <span class="black--text font-weight-medium">퇴소정산</span>
            </v-col>
            <v-col class="txtAlgnMid tableBL py-2" cols="1">
                <span class="black--text font-weight-medium">처리</span>
            </v-col>
        </v-row>
        <v-divider :class="['', lvList.length > 2 ? 'mr-2__5' : '']"></v-divider>

        <div
            class="overflow-auto d-inline-block"
            style="width: 100%; height: 74px;"
            v-if="lvList?.length > 0">
            <v-row
                class="tableBB pa-0"
                v-for="(list, i) in lvList" :key="i"
                no-gutters>
                <!-- @click="tblEvt(i)" -->
                <v-col :class="['d-flex justify-center align-center py-1', sltItm == i ? 'sltRow' : '' ]" cols="3">
                    <span class="">{{ $moment(list.lvDt, 'YYYYMMDD').format('YYYY.MM.DD') }}&nbsp;&#40;&nbsp;{{ list.lvTmHh }}&#58;{{ list.lvTmMm }}&nbsp;&#41;</span>
                </v-col>
                <v-col :class="['tableBL txtAlgnMid py-1', sltItm == i ? 'sltRow' : '' ]" style="line-height: 1.2;" cols="4">
                    <span class="black--text">{{ list.lvRsn }}</span>
                </v-col>
                <v-col :class="['d-flex justify-center align-center tableBL py-1', sltItm == i ? 'sltRow' : '' ]" cols="3">
                    <span class="black--text">{{ list.rmrks }}</span>
                </v-col>
                <v-col :class="['d-flex justify-center align-center tableBL py-1', sltItm == i ? 'sltRow' : '' ]" cols="1">
                    <!-- copayClmHisPk -->
                    <v-btn
                        v-if="list.lvCalcYn == '1'"
                        class="white--text" color="grey006" min-width="30" height="25"
                        style="padding: 6px 6px 4px 2px !important;"
                        @click="onRtrmnStlmn(list)"
                        x-small outlined>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <span
                                    class="fontEighthRem ml-1"
                                    style="padding-bottom: 2px;"
                                    v-bind="attrs" v-on="on">
                                    정산내역
                                </span>
                            </template>
                            <span>퇴소정산 청구서 조회</span>
                        </v-tooltip>
                    </v-btn>
                    <span v-else>미정산</span>
                </v-col>
                <v-col :class="['d-flex justify-center align-center tableBL py-1', sltItm == i ? 'sltRow' : '' ]" cols="1">
                    <span v-if="i == 0" class="mr-1">
                        <v-btn
                            color="grey006" height="26"
                            @click="rmLvInf(list.bnfcrPk, list.lvInfPk)"
                            :disabled="list.lvCalcYn == '1' || $store.getters.getEntStcd != '4'"
                            icon>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                        <v-icon size="20">mdi-trash-can-outline</v-icon>
                                    </div>
                                </template>
                                <span>퇴소이력 삭제</span>
                            </v-tooltip>
                        </v-btn>
                    </span>
                </v-col>
            </v-row>
        </div>
        <v-row v-else class="pa-0 tableBB" no-gutters>
            <v-col class="txtAlgnMid py-2" cols="12">
                <span class="grey006--text" style="font-size: 0.925rem;">&#8251;&nbsp;조회된&nbsp;항목이&nbsp;없습니다.</span>
            </v-col>
        </v-row>
    
        <v-dialog v-model="menu[2]" content-class="round" max-width="600" persistent eager>
            <fmlySelector
                :prtBnMmbrPk="$store.getters.getBnfcrState.bnmmbr"
                @isClose="isModal" @outFmly="getFmlyInfo">
            </fmlySelector>
        </v-dialog>
        <v-dialog v-model="menu[3]" content-class="round" max-width="600" persistent>
            <CmmCnfmMdl v-if="menu[3]" ref="cmmCnfmMdl" :compTtl="compTtl" :compCntnt="compCntnt" @isClose="onCnfm" @isSave="onCnfm"></CmmCnfmMdl>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { selLvInf, sellvInfList, insLvInf, delLvInf } from '../../api/bnfcr.js';

import fmlySelector from '../commons/FmlySelector.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';
import CmmCnfmMdl from '../commons/CmmCnfmMdl.vue';

export default {

    name: 'BnfcrExtLinkRecRtrmn',
            
    components: {
        fmlySelector,
        CmmDateComp,
        CmmTimeField,
        CmmCnfmMdl,
    },
    props: {
        
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.lvInf.lvDt = this.$moment().format('YYYY-MM-DD')
          
            this.getLvList()
        }) 
    },

    computed: {
        getBnMmbrPk: function(){
            return this.$store.getters.getBnfcrState.bnmmbr
        },
    },

    watch:{
        getBnMmbrPk: function(v){
            this.clearInf()
            this.sltItm = -1

            if(v > 0)
                this.getLvList()
        },
        'sltItm': function(){
            if(this.sltItm == -1)
                this.clearInf()
            else
                this.getLvInf(this.lvList[this.sltItm].lvInfPk)
        },
        'lvInf.lvDt': function(){
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.lvInf.lvDt)){
                if(this.$moment(this.lvInf.lvDt, 'YYYY-MM-DD').isAfter(this.$moment())){
                    this.lvInf.lvCalcYn = '2'
                    this.lvInf.dueDt = ''
                    this.visibleLvCalc = true
                }
                else
                    this.visibleLvCalc = false
            }
        },
    },
        
    methods: {
        // 퇴소 이력 단건 조회
        getLvInf: function (lvPk) {
            this.saveBtn = true

            selLvInf(this.$store.getters.getFcltyNum, this.$store.getters.getBnfcrState.bnmmbr, lvPk)
                .then((response) => ( this.getLvLnfAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selLvInf : ' + error))
        },
        // 퇴소 이력 다건 조회
        getLvList: function () {
            this.saveBtn = true
            this.lvList.splice(0)

            sellvInfList(this.$store.getters.getFcltyNum, this.$store.getters.getBnfcrState.bnmmbr)
                .then((response) => ( this.getLvListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selLvInfList : ' + error))
        },
        // 퇴소 이력 저장
        setLvInf: function (obj) {
            this.compCntnt.splice(0)

            insLvInf(obj)
                .then((response) => ( this.lvInfRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/insLvInf : ' + error))
        },
        // 퇴소 이력 삭제
        rmLvInf: function (pk, lvInfPk) {
            delLvInf(pk, lvInfPk, this.$store.getters.getUserNm)
                .then((response) => ( this.lvInfRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/delLvInf : ' + error))
        },

        getLvLnfAfter: function (res) {
            if(Object.keys(res).length > 0)
                this.setInf(res)

            this.saveBtn = false
        },

        getLvListAfter: function (res) {
            if(res.length > 0)
                this.lvList = res

            this.saveBtn = false
        },

        lvInfRst: function (res) {            
            if(res.statusCode == 200){
                if(this.sltItm == -1)
                    this.clearInf()

                this.sltItm = -1
                this.getLvList()
                this.$parent.$parent.$parent.$parent.onClose()
                this.$parent.$parent.$parent.$parent.updtTrg()
                this.$parent.$parent.$parent.$parent.onMsg(res.message)
            } else if(res.statusCode == 203){
                this.compCntnt.push('퇴소일('+this.lvInf.lvDt+' '+this.lvInf.lvTm+')로')
                this.compCntnt.push('외박 복귀처리 되었습니다.')
                this.menu.splice(3,1,true)
            }
            else
                this.$parent.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])
        },

        onAction: function (index) {
            switch(index) {
                case 0: 
                    this.newLvInf()
                    break;
                case 1: if(this.$refs.form.validate()){
                            let copyObj = Object.assign({}, this.lvInf)
                            copyObj.fcltyNum = this.$store.getters.getFcltyNum
                            copyObj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
                            copyObj.bnfcrPk = this.$store.getters.getBnfcrState.bnfcr
                            copyObj.userNm = this.$store.getters.getUserNm
                            copyObj.lvDt = copyObj.lvDt.afterDateFormatHyp()
                            if(this.lvTm != null || this.lvTm != ''){
                                let splitTime = this.lvTm?.split(':', 2)
                                copyObj.lvTmHh = splitTime[0]
                                copyObj.lvTmMm = splitTime[1]
                            }
                        this.setLvInfo(copyObj)
                        }
                    break;
                case 2: this.$emit('undo', 0)
                    break;
                default: 
                     break;
            }
        },

        pre_LvInf: function () {
            this.saveBtn = true

            let obj = Object.assign({}, this.lvInf)
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            if(this.sltItm != -1)
                obj.bnfcrPk = this.lvList[this.sltItm].bnfcrPk
            else
                obj.bnfcrPk = this.$store.getters.getBnfcrState.bnfcr
            obj.userNm = this.$store.getters.getUserNm
            obj.prcClcd = '7'
            obj.lvDt = this.$moment(obj.lvDt, 'YYYY-MM-DD', true).format('YYYYMMDD')
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(obj.dueDt)){
                obj.dueDt = this.$moment(obj.dueDt, 'YYYY-MM-DD').format('YYYYMMDD')
            }
            if(obj.lvTm != ''){
                let splitTime = obj.lvTm?.split(':', 2)
                obj.lvTmHh = splitTime[0]
                obj.lvTmMm = splitTime[1]
            }

            if(this.$refs.form.validate())
                this.setLvInf(obj)
            else{
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, '필수 항목을 작성해 주세요.', 'warning'])
                this.saveBtn = false
            }
        },

        tblEvt: function (idx) {
            if(this.sltItm == idx)
                this.sltItm = -1
            else
                this.sltItm = idx
        },

        newLvInf: function () {
            if(this.sltItm == -1)
                this.clearInf()
            else
                this.sltItm = -1
        },

        setInf: function (obj) {
            this.lvInf.bnMmbrPk = obj.bnMmbrPk
            this.lvInf.bnfcrPk = obj.bnfcrPk
            this.lvInf.lvInfPk = obj.lvInfPk

            if(this.lvInf.lvDt != '')
                this.lvInf.lvDt = this.$moment(obj.lvDt, 'YYYYMMDD').format('YYYY-MM-DD')
            this.lvInf.lvTm = obj.lvTmHh + ':' + obj.lvTmMm
            this.lvInf.lvTmHh = obj.lvTmHh
            this.lvInf.lvTmMm = obj.lvTmMm
            this.lvInf.cmpny = obj.cmpny
            this.lvInf.lvRsn = obj.lvRsn            
            this.lvInf.rltns = obj.rltns
            this.lvInf.rmrks = obj.rmrks
            this.lvInf.lvCalcYn = obj.lvCalcYn
            if(this.lvInf.dueDt != '')
                this.lvInf.dueDt = this.$moment(obj.dueDt, 'YYYYMMDD').format('YYYY-MM-DD')
        },

        clearInf: function () {
            this.lvInf.bnMmbrPk = 0
            this.lvInf.bnfcrPk = 0
            this.lvInf.lvInfPk = 0

            this.lvInf.lvDt = this.$moment().format('YYYY-MM-DD')
            this.lvInf.lvTm = ''
            this.lvInf.lvTmHh = ''
            this.lvInf.lvTmMm = ''
            this.lvInf.cmpny = ''
            this.lvInf.lvRsn = ''            
            this.lvInf.rltns = ''
            this.lvInf.rmrks = ''
            this.lvInf.lvCalcYn = '2'
            this.lvInf.dueDt = ''

            this.$refs.form.resetValidation()
        },

        onModal: function () {
            this.menu.splice(2, 1, true)
        },

        isModal: function () {
            this.menu.splice(2, 1, false)
        },

        onRtrmnStlmn: function (tmp) {
            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 800, prsst: true, typ: '2001', inObj: tmp, outObj: null, runEvt: false,
            });
        },

        getFmlyInfo: function (obj) {
            this.lvInf.cmpny = ''
            this.lvInf.cmpny = obj.name
        },

        onCnfm: function(){
            if(this.sltItm == -1)
                this.clearInf()

            this.sltItm = -1
            this.getLvList()
            this.$parent.$parent.$parent.$parent.onClose()
            this.$parent.$parent.$parent.$parent.updtTrg()

            this.menu.splice(3, 1, false)
        },
    },
    
    data: () => ({
        menu: [false, false, false, false],
        forceRender: 0,
        saveBtn: true,
        visibleLvCalc: false,
        sltItm: -1,
        lvList: [],
        lvInf: {
            bnMmbrPk: 0, fcltyNum: 0, bnfcrPk: 0, lvInfPk: 0, lvCalcYn: '2', dueDt: '',
            lvDt: '', lvTm: '', lvTmHh: '', lvTmMm: '', cmpny: '', lvRsn: '', rltns: '', rmrks: '',
        },
        filledBtn: [
            { icon: 'mdi-pencil-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '초기화', disabled: false },
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', disabled: false },
            { icon: 'mdi-undo', class: 'ml-1 white--text', color:'grey006', text: '취소', type: 'dialogue', disabled: false },
        ],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
        },
        compTtl: '외출·외박 복귀',
        compCntnt: [],
    }),
};
</script>