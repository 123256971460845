<template>
    <v-sheet>
        <v-form ref="tabform" lazy-validation>
            <div class="mb-3 d-flex justify-start">
                <span>※&nbsp;도움필요도 표기 : 상&#40;전적인 도움&#41;, 중&#40;수행도움&#41;, 하&#40;준비ㆍ지켜보기 도움&#41;, 최하&#40;혼자수행&#41;</span>
            </div>   
            <div class="d-flex">     
                <div class="d-inline-block pa-0 ma-0 align-self-start" style="width:450px;border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;">            
                    <v-row class="pa-0 ma-0" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-2 ma-0" cols="4">                                
                            <span class="black--text font-weight-medium">기본동작 항목</span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center pa-2 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="6">
                            <span class="black--text font-weight-medium">확인</span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center pa-2 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="2">                                
                            <span class="black--text font-weight-medium">처리</span>
                        </v-col>
                    </v-row>
                    <v-row v-for="(item, i) in basicItems" :key="i" class="pa-0 ma-0" no-gutters>                        
                        <v-col class="d-flex justify-center align-center pa-1 ma-0" style="border-top: 1px solid #dbdbdb !important;font-size:1rem" cols="4">                                
                            <span class="black--text font-weight-medium">{{item.itmCdnm}}</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center pa-1 ma-0"  style="border-top: 1px solid #dbdbdb !important; border-left: 1px solid #dbdbdb !important;" cols="6">
                            <span class="d-inline-block" style="width: 210px;">
                                <v-radio-group v-model="item.itmVal" class="radio-dft-Theme mt-0" hide-details row :key="forceRender">
                                    <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="상" value="1"></v-radio>
                                    <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="중" value="2"></v-radio>
                                    <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="하" value="3"></v-radio>
                                    <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="최하" value="4"></v-radio>
                                </v-radio-group>
                            </span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center pa-1 ma-0" style="border-top: 1px solid #dbdbdb !important; border-left: 1px solid #dbdbdb !important;" cols="2">                                
                            <v-btn
                                color='grey006'
                                @click="delRow(0,i)"
                                icon>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon size="20" v-bind="attrs" v-on="on">mdi-eraser</v-icon>
                                    </template>
                                    <span>지우기</span>
                                </v-tooltip>
                            </v-btn>
                        </v-col>
                    </v-row>               
                </div>
                <div class="d-inline-block pa-0 ml-3 align-self-start" style="width:450px;border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;">            
                    <v-row class="pa-0 ma-0" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-2 ma-0" cols="4">                                
                            <span class="black--text font-weight-medium">신체기능 항목</span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center pa-2 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="6">
                            <span class="black--text font-weight-medium">확인</span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center pa-2 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="2">                                
                            <span class="black--text font-weight-medium">처리</span>
                        </v-col>
                    </v-row>
                    <v-row v-for="(item, i) in bodyItems" :key="i" class="pa-0 ma-0" no-gutters>                        
                        <v-col class="d-flex justify-center align-center pa-1 ma-0" style="border-top: 1px solid #dbdbdb !important;font-size:1rem;" cols="4">                                
                            <span class="black--text font-weight-medium">{{item.itmCdnm}}</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center pa-1 ma-0"  style="border-top: 1px solid #dbdbdb !important; border-left: 1px solid #dbdbdb !important;" cols="6">
                            <span class="d-inline-block" style="width: 250px;">
                                <v-radio-group v-model="item.itmVal" class="radio-dft-Theme mt-0" hide-details row :key="forceRender">
                                    <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="상" value="1"></v-radio>
                                    <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="중" value="2"></v-radio>
                                    <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="하" value="3"></v-radio>  
                                    <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="최하" value="4"></v-radio>                                  
                                </v-radio-group>
                            </span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center pa-1 ma-0" style="border-top: 1px solid #dbdbdb !important; border-left: 1px solid #dbdbdb !important;" cols="2">                                
                            <v-btn
                                color='grey006'
                                @click="delRow(1,i)"
                                icon>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon size="20" v-bind="attrs" v-on="on">mdi-eraser</v-icon>
                                    </template>
                                    <span>지우기</span>
                                </v-tooltip>
                            </v-btn>
                        </v-col>
                    </v-row>       
                </div>
                <div class="d-inline-block pa-0 ml-3 align-self-start" style="width:450px;border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;">            
                    <v-row class="pa-0 ma-0" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-2 ma-0" cols="4">                                
                            <span class="black--text font-weight-medium">일상생활동작 항목</span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center pa-2 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="6">
                            <span class="black--text font-weight-medium">확인</span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center pa-2 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="2">                                
                            <span class="black--text font-weight-medium">처리</span>
                        </v-col>
                    </v-row>
                    <v-row v-for="(item, i) in moveItems" :key="i" class="pa-0 ma-0" no-gutters>                       
                        <v-col class="d-flex justify-center align-center pa-1 ma-0" style="border-top: 1px solid #dbdbdb !important;font-size:1rem;" cols="4">                                
                            <span class="black--text font-weight-medium">{{item.itmCdnm}}</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center pa-1 ma-0"  style="border-top: 1px solid #dbdbdb !important; border-left: 1px solid #dbdbdb !important;" cols="6">
                            <span class="d-inline-block" style="width: 250px;">
                                <v-radio-group v-model="item.itmVal" class="radio-dft-Theme mt-0" hide-details row :key="forceRender">
                                    <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="상" value="1"></v-radio>
                                    <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="중" value="2"></v-radio>
                                    <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="하" value="3"></v-radio>
                                    <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="최하" value="4"></v-radio>
                                </v-radio-group>
                            </span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center pa-1 ma-0" style="border-top: 1px solid #dbdbdb !important; border-left: 1px solid #dbdbdb !important;" cols="2">                                
                            <v-btn
                                color='grey006'
                                @click="delRow(2,i)"
                                icon>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon size="20" v-bind="attrs" v-on="on">mdi-eraser</v-icon>
                                    </template>
                                    <span>지우기</span>
                                </v-tooltip>
                            </v-btn>
                        </v-col>
                    </v-row>      
                </div>        
            </div>
            <div class="d-flex align-center mt-2">
                <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                <span class="text-h6 font-weight-bold">판단근거</span>
            </div>
            <v-row class="pa-0 ma-0" no-gutters>            
                <v-col class="pa-2 ma-0" cols="12">
                    <v-textarea v-model="judgItems.evltn" class="rmrksArea" no-resize  
                        rows="5" hide-details outlined dense :key="forceRender">
                    </v-textarea>
                </v-col>
            </v-row>
        </v-form>        
    </v-sheet>
</template>
<script>
import { insBnfcrExamHis,} from '../../api/bnftSrv.js';

export default {
    name: 'DsrEvltnTab1',

    props : {       
        tab : {type:[String, Number, Object, Array], default:''}, 
    },
            
    components: {
    },

    computed: {
        
    },  

    watch:{        
    },

    created:function(){ 
        this.$nextTick(function () { 
            // let value = this.$store.getters.getBnfcrExamHisPk;
            // this.onShow(value);  
        })
    },
    
    mounted: function(){
        
    },

    
        
    methods: {
        onShow(){           
        },        
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
        setData(data){     
            this.basicItems = []     
            this.bodyItems = []
            this.moveItems = []
            this.judgItems = {}

            data.map(x=>{                
                if(x.clsfc3Cd == '1'){
                    this.basicItems.push(x)
                }else if(x.clsfc3Cd == '2'){
                    this.bodyItems.push(x)
                }else if(x.clsfc3Cd == '3'){
                    this.moveItems.push(x)
                }else if(x.clsfc3Cd == '4'){
                   this.judgItems = x
                }
            })
            this.$refs.tabform.resetValidation();
        },
        insBnfcrExam(data){
            if(this.$refs.tabform.validate()){
                
                //수급자평가 내역 setting
                let obj = {
                    fcltyNum:this.$store.getters.getFcltyNum,
                    userNm:this.$store.getters.getUserNm,
                    bnMmbrPk:data.bnMmbrPk,
                    bnfcrExamHisPk:data.bnfcrExamHisPk,
                    examClcd:'101',
                    evltn:this.evltn,
                    examRsncd : data.examRsncd,
                    examDt : data.examDt.afterDateFormatHyp(),
                    wrtr : data.wrtr,
                    examYyyy : data.inqYYYY,
                }

                //욕구사정평가 setting
                let examList = []

                this.basicItems.map(x=>{
                    x.itmValCd = x.valClcd
                    examList.push(x)
                })
                this.bodyItems.map(x=>{
                    x.itmValCd = x.valClcd
                    examList.push(x)
                })
                this.moveItems.map(x=>{
                    x.itmValCd = x.valClcd
                    examList.push(x)
                })
                
                examList.push(this.judgItems)

                insBnfcrExamHis(obj,examList)
                .then((response) => ( this.insBnfcrExamAfter(response.data)))
                .catch((error) => console.log(error))
            }
        },
        insBnfcrExamAfter: function (res) {            
            this.$emit('dsrEvltnTrigger', res)            
        },        
        selExamList:function(){
            //욕구사정평가 setting
            let examList = []

            this.basicItems.map(x=>{
                x.itmValCd = x.valClcd
                examList.push(x)
            })
            this.bodyItems.map(x=>{
                x.itmValCd = x.valClcd
                examList.push(x)
            })
            this.moveItems.map(x=>{
                x.itmValCd = x.valClcd
                examList.push(x)
            })

            examList.push(this.judgItems)
            
            return examList
        },
        delRow:function(key, idx){
            if(key == 0){
                this.basicItems[idx].itmVal = ''
            }else if(key == 1){
                this.bodyItems[idx].itmVal = ''
            }else if(key == 2){
                this.moveItems[idx].itmVal = ''
            }
        }
    },
    
    data: () => ({ 
        obj:{},
        basicItems:[],        
        bodyItems:[],
        moveItems:[],    
        judgItems:{},    
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
        },
        forceRender:0,
    }),
};
</script>