<template>
    <v-sheet style="position:relative;height:668px;">
        <div class="d-flex align-center">
            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold">진료기록</span>
            <v-spacer></v-spacer>            
            <!-- <v-btn v-if="btnVisible"
                class="ml-1" color="blueBtnColor" style="padding: 13px 12px 12px 10px !important"
                min-width="30" height="25"
                @click="getPreClncRecOutHisInfo"
                small outlined>
                <v-icon size="14">mdi-text-box-search-outline</v-icon>
                <span class="fontOneRem ml-1" style="padding-bottom: 2px;">전일자료</span>                
            </v-btn> -->            
            <v-btn
                class="ml-1" color="blueBtnColor" style="padding: 13px 12px 12px 10px !important"
                min-width="30" height="25"
                @click="dialog.splice(3,1,true), dctrCarerCntrcAllPk=1"
                small outlined>
                <v-icon size="14">mdi-text-box-search-outline</v-icon>
                <span class="fontOneRem ml-1" style="padding-bottom: 2px;">계약의사 진료내역 일괄처리</span>                
            </v-btn>            
        </div>
        <div style="height:375px;">
            <v-form ref="form" lazy-validation>
                <v-row class="pa-0 mt-1" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                        <span class="black--text font-weight-medium">진료일</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4">
                        <span class="white d-inline-block pa-0" style="width: 154px;">
                            <CmmDateComp v-model="items.clncDt" :required="true"></CmmDateComp>                            
                        </span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                        <span class="black--text font-weight-medium">진료구분</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4">
                        <span class="black--text font-weight-medium" style="width:241px;" >
                            <v-radio-group
                                v-model="items.clncClcd" :rules="[rules.required]"
                                class="radio-dft-Theme mt-0"
                                hide-details row>
                                <v-radio :ripple="false" label="계약의사진료"  value="1"></v-radio>
                                <v-radio :ripple="false" label="외래진료"  value="2"></v-radio>
                            </v-radio-group>
                        </span>
                    </v-col>
                </v-row>
                <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                        <span v-if="items.clncClcd == '2'" class="black--text font-weight-medium">병의원명</span>
                        <span v-else class="black--text font-weight-medium">계약의사명</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4">
                        <span class="d-inline-block" style="width: 200px;">
                            <v-text-field v-model="items.hospNm" color="blue" height="28" ref="info"
                                :rules="[rules.required]" hide-details outlined dense :key="forceRender"></v-text-field>
                        </span>
                        <v-btn 
                            v-if="items.clncClcd == '1'"
                            class="ml-1 green lighten-3"  
                            x-small 
                            min-width="30"
                            height="25"                         
                            style="padding:0px 1px 0px 2px !important"
                            @click="onModal(4)"
                            >
                            <v-tooltip top >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon class="white--text" size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                </template>
                                <span>계약의사 선택</span>
                            </v-tooltip> 
                        </v-btn>
                        <v-btn 
                            v-if="items.clncClcd == '2'"
                            class="ml-1 green lighten-3"  
                            x-small 
                            min-width="30"
                            height="25"                         
                            style="padding:0px 1px 0px 2px !important"
                            @click="onModal(2)"
                            >
                            <v-tooltip top >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon class="white--text" size="20" v-bind="attrs" v-on="on">mdi-hospital-building</v-icon>
                                </template>
                                <span>병의원선택</span>
                            </v-tooltip> 
                        </v-btn>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                        <span class="black--text font-weight-medium">작성자</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4">
                        <span class="d-inline-block" style="width: 100px;">
                            <v-text-field v-model="items.wrtr" color="blue" height="28" ref="info" readonly
                                :rules="[rules.required]" hide-details outlined dense :key="forceRender"></v-text-field>
                        </span>
                        <v-btn 
                            class="ml-1 brown lighten-3" 
                            small 
                            min-width="30"
                            height="26"  
                            style="padding:0px 1px 0px 2px !important"
                            @click="onModal(0,'1')"
                            >
                            <v-icon size="20" class="white--text">mdi-account-edit</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                        <span class="black--text font-weight-medium">진료비</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4">
                        <span class="d-inline-block" style="width: 130px;">                        
                            <v-text-field v-model="items.clncFee" type="number" height="28" hide-details outlined dense></v-text-field>
                        </span>
                        <span class="d-inline-block px-1">원</span>
                        <span class="ml-2 black--text font-weight-medium" style="width:200px;" >
                            <v-radio-group
                                v-model="items.clncFeeClmYn" :rules="[rules.required]"
                                class="radio-dft-Theme mt-0"
                                hide-details row>
                                <v-radio :ripple="false" label="청구"  value="1"></v-radio>
                                <v-radio :ripple="false" label="미청구"  value="2"></v-radio>
                            </v-radio-group>
                        </span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                        <span class="black--text font-weight-medium">약제비</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4">
                        <span class="d-inline-block" style="width: 130px;">                        
                            <v-text-field v-model="items.drugFee" type="number" height="28" hide-details outlined dense></v-text-field>
                        </span>
                        <span class="d-inline-block px-1">원</span>
                        <span class="ml-2 black--text font-weight-medium" style="width:200px;" >
                            <v-radio-group
                                v-model="items.drugFeeClmYn" :rules="[rules.required]"
                                 class="radio-dft-Theme mt-0" hide-details row>
                                <v-radio :ripple="false" label="청구"  value="1"></v-radio>
                                <v-radio :ripple="false" label="미청구"  value="2"></v-radio>
                            </v-radio-group>
                        </span>
                    </v-col>
                </v-row>
                <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                        <span class="black--text font-weight-medium">진료내용</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="10">
                        <v-textarea v-model="items.clncCntnt" class="rmrksArea" style="font-size:0.9rem !important;"
                            rows="3" outlined dense hide-details no-resize>
                        </v-textarea>
                    </v-col>                
                </v-row>
                <v-row v-if="items.clncClcd == '2'" class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                        <span class="black--text font-weight-medium">외출기록</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="10">
                        <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="items.outHisYn" true-value="1" false-value="2" hide-details></v-checkbox>
                    </v-col>                
                </v-row>
                <v-row v-if="items.clncClcd == '2' && items.outHisYn=='1'" class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                        <span class="black--text font-weight-medium">외출시간</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4">
                        <span class="d-inline-block" style="width: 60px;">
                            <CmmTimeField v-model="items.outTmStrt" :required="true"></CmmTimeField>                            
                        </span>
                        <span class="white d-inline-block pa-1" style="width: 14px;">  
                            ~
                        </span>
                        <span class="d-inline-block" style="width: 60px;">
                            <CmmTimeField v-model="items.outTmEnd" :required="true"></CmmTimeField>
                        </span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                        <span class="black--text font-weight-medium">보호자명</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4">
                        <span class="d-inline-block" style="width: 100px;">
                            <v-text-field v-model="items.aplcn" color="blue" height="28" ref="info"
                                :rules="[rules.required]" :disabled="!(items.outHisYn == '1')" hide-details outlined dense :key="forceRender"></v-text-field>
                        </span>
                        <v-btn 
                            class="ml-1 white--text darken-1" 
                            small 
                            min-width="30"
                            height="26"  
                            color="bckgrColor"
                            style="padding:0px 1px 0px 2px !important"
                            @click="onModal(1)"
                            >
                            <v-tooltip top >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon size="20" v-bind="attrs" v-on="on" class="white--text">mdi-account-edit</v-icon>
                                </template>
                                <span>보호자선택</span>
                            </v-tooltip>                            
                        </v-btn>
                        <v-btn 
                            class="ml-1 brown lighten-3" 
                            small 
                            min-width="30"
                            height="26"  
                            style="padding:0px 1px 0px 2px !important"
                            @click="onModal(0,'2')"
                            >
                            <v-tooltip top >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon size="20" v-bind="attrs" v-on="on" class="white--text">mdi-account-edit</v-icon>
                                </template>
                                <span>직원선택</span>
                            </v-tooltip>                             
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="items.clncClcd == '1'" class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                        <span class="black--text font-weight-medium">계약의사 진찰비</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4">
                        <span class="d-inline-block" style="width: 130px;">                        
                            <v-text-field v-model="items.dctrFee" type="number" height="28" hide-details outlined dense></v-text-field>
                        </span>
                        <span class="d-inline-block px-1">원</span>
                        <span class="ml-2 black--text font-weight-medium" style="width:200px;" >
                            <v-radio-group
                                v-model="items.dctrFeeClmYn" :rules="[rules.required]"
                                class="radio-dft-Theme mt-0"
                                hide-details row>
                                <v-radio :ripple="false" label="청구"  value="1"></v-radio>
                                <v-radio :ripple="false" label="미청구"  value="2"></v-radio>
                            </v-radio-group>
                        </span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                        <span class="black--text font-weight-medium">초진&#47;재진</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4">
                        <span class="ml-2 black--text font-weight-medium" style="width:200px;" >
                            <v-radio-group
                                v-model="items.frstYn" :rules="[rules.required]"
                                class="radio-dft-Theme mt-0"
                                hide-details row>
                                <v-radio :ripple="false" label="초진"  value="1"></v-radio>
                                <v-radio :ripple="false" label="재진"  value="2"></v-radio>
                            </v-radio-group>
                        </span>
                    </v-col>
                </v-row>    
                <v-row v-if="items.clncClcd == '2' && items.outHisYn=='1'" class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                        <span class="black--text font-weight-medium">관계</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4">
                        <span class="d-inline-block" style="width: 120px;">
                            <!-- 관계 area -->
                            <v-text-field v-model="items.rltns" :disabled="!(items.outHisYn == '1')" :rules="[rules.required]" height="28" hide-details outlined dense></v-text-field>
                        </span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                        <span class="black--text font-weight-medium">연락처</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4">
                    <span class="d-inline-block" style="width: 47px;">
                            <v-text-field v-model="items.tel_1" :disabled="!(items.outHisYn == '1')" :rules="[rules.required, rules.numberCheck, rules.numberSizeCheck]" height="28" hide-details outlined dense></v-text-field>
                        </span>
                        <span class="d-inline-block text-h5 font-weight-bold pa-0 pl-1" style="width: 15px;">
                        &#45;
                        </span>
                        <span class="d-inline-block" style="width: 56px;">
                            <v-text-field v-model="items.tel_2" :disabled="!(items.outHisYn == '1')" :rules="[rules.required, rules.numberCheck, rules.numberSizeCheck]" height="28" hide-details outlined dense></v-text-field>
                        </span>
                        <span class="d-inline-block text-h5 font-weight-bold pa-0 pl-1" style="width: 15px;">
                        &#45;
                        </span>
                        <span class="d-inline-block" style="width: 56px;">
                            <v-text-field v-model="items.tel_3" :disabled="!(items.outHisYn == '1')" :rules="[rules.required, rules.numberCheck, rules.numberSizeCheck]" height="28" hide-details outlined dense></v-text-field>
                        </span>
                    </v-col>
                </v-row>
                <v-row v-if="items.clncClcd == '2' && items.outHisYn=='1'" class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                        <span class="black--text font-weight-medium">행선지</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4">
                        <v-text-field v-model="items.dstnt" :disabled="!(items.outHisYn == '1')" :rules="[rules.required]" height="28" hide-details outlined dense></v-text-field>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                        <span class="black--text font-weight-medium">사유</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4">
                        <v-text-field v-model="items.rsn" :disabled="!(items.outHisYn == '1')" :rules="[rules.required]" height="28" hide-details outlined dense></v-text-field>
                    </v-col>
                </v-row>
                <v-row v-if="items.clncClcd == '2' && items.outHisYn=='1'" class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                        <span class="black--text font-weight-medium">비고</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="10">
                        <v-textarea v-model="items.rmrks" class="rmrksArea" style="font-size:0.9rem !important;"
                            rows="1" outlined dense hide-details no-resize>
                        </v-textarea>
                    </v-col>  
                </v-row>
                            
            </v-form>
        </div>        
        <div :class="hstStyle" style="background-color:#FFFFFF">
            <div class="d-flex align-center mt-2">
                <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                <span class="text-h6 font-weight-bold">진료기록 내역</span>
                <v-spacer></v-spacer>
                <v-btn                
                    class="ml-1" color="grey006" style="padding: 13px 12px 12px 10px !important"
                    min-width="30" height="25"                    
                    @click="prtDctrCare()"                    
                    small outlined>
                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                            <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                <v-icon size="20">mdi-printer-outline</v-icon>
                                <span class="fontOneRem ml-1" style="padding-bottom: 2px;">출력</span>
                            </div>
                        </template>
                        <span>진료기록 출력</span>
                    </v-tooltip>
                </v-btn>
                <v-btn
                    class="ml-1" color="blueBtnColor" style="padding: 13px 12px 12px 10px !important"
                    min-width="30" height="25"
                    @click="chnTblHei(!typ)"
                    small outlined>
                    <v-icon size="14">mdi-plus-minus-variant</v-icon>
                    <span class="fontOneRem ml-1" style="padding-bottom: 2px;">확대/축소</span>                
                </v-btn>
            </div>
        
            <v-row class="ma-0 pa-0 mt-2" no-gutters>
                <v-data-table
                    class="dft-hvr-tbl"
                    :height="tblHeight" locale="ko"
                    :headers="headers" :items="clncRecList" fixed-header
                    hide-default-header hide-default-footer dense disable-pagination>
                    <template v-slot:header="{ props }" >
                        <thead>
                            <tr> 
                                <th class="black--text clmsFixedHeader tableBR" style="width:90px !important;">{{props.headers[0].text}}</th>
                                <th class="black--text clmsFixedHeader tableBR" style="width:100px !important;">{{props.headers[1].text}}</th>
                                <th class="black--text clmsFixedHeader tableBR" style="width:120px !important;">{{props.headers[2].text}}</th>
                                <th class="black--text clmsFixedHeader tableBR" style="width:350px !important;">{{props.headers[3].text}}</th>
                                <th class="black--text clmsFixedHeader tableBR" style="width:90px !important;">{{props.headers[4].text}}</th>
                                <th class="black--text clmsFixedHeader tableBR" style="width:90px !important;">{{props.headers[5].text}}</th>
                                <th class="black--text clmsFixedHeader tableBR" style="width:120px !important;">{{props.headers[6].text}}</th>
                                <th class="black--text clmsFixedHeader" style="width:90px !important;">{{props.headers[7].text}}</th> 
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item }'>
                        <tr :class="[items.clncRecPk == item.clncRecPk ? 'onClr' : '']"
                            @click="tableClickEvent(item.clncDt, item.clncRecPk)">
                            <td class="clmsBodyCommon tableBR">{{item.clncDt?.beforeDateFormatDot()}}</td> 
                            <td class="clmsBodyCommon tableBR">{{item.clncClcd}}</td>      
                            <td class="clmsBodyCommon tableBR">
                                <div style="width:100px;" class="overflow-text-hidden">{{item.hospNm}}</div>
                            </td>   
                            <td class="clmsBodyCommon tableBR">
                                <div style="width:300px;" class="overflow-text-hidden">{{item.clncCntnt}}</div>
                            </td>      
                            <td class="clmsBodyCommon tableBR">{{item.clncFee?.toLocaleString('ko-KR')}}</td>      
                            <td class="clmsBodyCommon tableBR">{{item.drugFee?.toLocaleString('ko-KR')}}</td>   
                            <td class="clmsBodyCommon tableBR">{{item.dctrFee?.toLocaleString('ko-KR')}}</td>    
                            <td class="clmsBodyCommon">{{item.wrtr}}</td>      
                        </tr>
                    </template>
                    <template v-slot:[`footer`]="{ props }">
                        <tfoot>
                            <tr>
                                <td class="clmsDtlHeaderCommon tableBT tableBR py-1 black--text" style="width:660px !important;" colspan="4">합계</td>
                                <td class="clmsDtlHeaderCommon tableBT tableBR black--text" style="width:90px !important;">{{itemsSum.clncFeeSum?.toLocaleString('ko-KR')}}</td>
                                <td class="clmsDtlHeaderCommon tableBT tableBR black--text" style="width:90px !important;">{{itemsSum.drugFeeSum?.toLocaleString('ko-KR')}}</td>
                                <td class="clmsDtlHeaderCommon tableBT tableBR black--text" style="width:120px !important;">{{itemsSum.dctrFeeSum?.toLocaleString('ko-KR')}}</td>
                                <td class="clmsDtlHeaderCommon tableBT tableBR black--text" style="width:90px !important;">{{props.page}}</td>
                            </tr>
                        </tfoot>
                    </template>
                </v-data-table>
            </v-row>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
                    <EmpSelector @isClose="isModal(0)" @outEmp="getEmpInfo" :prtWrtDt="items.clncDt"></EmpSelector>
            </v-dialog>  
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[1]" eager content-class="round" persistent max-width="600">
                <fmlySelector @isClose="isModal(1)" @outFmly="getFmlyInfo" :prtBnMmbrPk="$parent.$parent.$parent.$parent.bnMmbrPk"></fmlySelector>
            </v-dialog>
         </div>
         <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[2]" eager content-class="round" persistent max-width="800">
                <HsptlMdf @isClose="isModal(2)" @outEmp="getHsptlInfo"></HsptlMdf>
            </v-dialog>
         </div>
         <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[3]" eager content-class="round" persistent max-width="1460">
                <dctrCarerCntrcAllWrt 
                    :dctrCarerCntrcAllPk="dctrCarerCntrcAllPk"
                    :inqYMD="$parent.$parent.$parent.$parent.inqYMD" 
                    :bnMmbrPk="$parent.$parent.$parent.$parent.bnMmbrPk"
                    @modelEvent="reLoad"
                    @isClose="isModal(3)"></dctrCarerCntrcAllWrt>
            </v-dialog>
         </div>
         <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[4]" eager content-class="round" persistent max-width="800">
                <DctrCarerCntrcMdf 
                    @outDct="getDctInfo"
                    @isClose="isModal(4)"></DctrCarerCntrcMdf>
            </v-dialog>
         </div>
         <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="'진료기록'" @madalState="onDelete"></btnModalDelete>  
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import CmmDateComp from '../commons/CmmDateComp.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';
import EmpSelector from '../commons/EmpSelector.vue';
import FmlySelector from '../../components/commons/FmlySelector.vue';
import HsptlMdf from '../../components/bnftrcrd/HsptlMdf.vue';
import DctrCarerCntrcMdf from '../../components/bnftrcrd/DctrCarerCntrcMdf.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import dctrCarerCntrcAllWrt from './DctrCarerCntrcAllWrt.vue';


import { selClncRecList, selClncRecOutHisInfo, delClncRec, insClncRecOutHis } from '../../api/bnftrcrd.js';

export default {
    name: 'DctrCareTab',
    
    components: {  
        CmmDateComp,
        EmpSelector,
        FmlySelector,
        HsptlMdf,
        DctrCarerCntrcMdf,
        btnModalDelete,
        dctrCarerCntrcAllWrt,
        CmmTimeField,
    },

    props : {
        
    },

    computed: {
        getYmd:function(){
            return this.$parent.$parent.$parent.$parent.inqYMD
        }
    },

    created: function(){ 
        
    },

    mounted: function(){
        this.$nextTick(function () {
            this.inqYMD = this.$parent.$parent.$parent.$parent.inqYMD
            this.items.clncDt = this.inqYMD.beforeDateFormatHyp()
        })
    },

    watch: {
        'getYmd':function(value){
            this.inqYMD = value 
            this.clearItm()
            this.items.clncDt = this.inqYMD.beforeDateFormatHyp()
            this.btnVisible = true
            this.isPreData = false 
        }
    },

    methods: {   
        onLoad:function(){  
            this.btnVisible = true
            this.isPreData = false 
            this.clearItm()          
            this.getClncRecOutHisInfo(this.pk)
            this.getClncRecList()
            this.$refs.form.resetValidation()
        },
        reLoad:function(){     
            this.btnVisible = true
            this.isPreData = false 
            this.clearItm()       
            this.getClncRecOutHisInfo(this.pk)
            this.getClncRecList()
            this.$refs.form.resetValidation()
        },
        clearItm:function(){
            this.items.clncRecPk = 0
            this.items.clncClcd = '1'
            this.items.clncDt = ''
            this.items.outHisPk = 0
            this.items.hospInfPk = 0
            this.items.dctInfPk = 0
            this.items.hospNm = ''
            this.items.clncCntnt = ''
            this.items.clncFee = 0
            this.items.clncFeeClmYn = ''            
            this.items.drugFee = 0
            this.items.drugFeeClmYn = ''
            this.items.frstYn = ''
            this.items.dctrFee = 0
            this.items.dctrFeeClmYn = ''
            this.items.outHisYn = '2'
            this.items.wrtr = ''
            this.items.aplcn = ''
            this.items.wrtrMmbrPk = 0
            this.items.outTmStrt = ''
            this.items.outTmEnd = ''
            this.items.rltns = ''            
            this.items.tel_1 = ''
            this.items.tel_2 = ''
            this.items.tel_3 = ''
            this.items.dstnt = ''
            this.items.rsn = ''
            this.items.rmrks = ''            
        },
        clearItmSum:function(){
            this.itemsSum.clncFeeSum = 0
            this.itemsSum.drugFeeSum = 0
            this.itemsSum.dctrFeeSum = 0
        },
        onNewRec:function(){            
            this.clearItm()
            this.items.clncDt = this.inqYMD.beforeDateFormatHyp()
            this.$refs.form.resetValidation()
        },
        onSave:function(){
            let gFcltyNum = this.$store.getters.getFcltyNum
            let gBnMmbrPk = this.$parent.$parent.$parent.$parent.bnMmbrPk
            let gUserNm = this.$store.getters.getUserNm

            if(gBnMmbrPk > 0){
                if(this.$refs.form.validate()){

                    let obj = {
                        clncRecPk:this.items.clncRecPk,
                        clncClcd:this.items.clncClcd,
                        clncDt:this.items.clncDt.afterDateFormatHyp(),
                        outHisPk:this.items.outHisPk,
                        hospInfPk:this.items.hospInfPk,
                        dctInfPk:this.items.dctInfPk,
                        hospNm:this.items.hospNm,
                        clncCntnt:this.items.clncCntnt,
                        clncFee:this.items.clncFee,
                        clncFeeClmYn:this.items.clncFeeClmYn,
                        drugFee:this.items.drugFee,
                        drugFeeClmYn:this.items.drugFeeClmYn,
                        frstYn:this.items.frstYn,
                        dctrFee:this.items.dctrFee,
                        dctrFeeClmYn:this.items.dctrFeeClmYn,
                        outHisYn:this.items.outHisYn,
                        wrtr:this.items.wrtr,
                        aplcn:this.items.aplcn,
                        wrtrMmbrPk:this.items.wrtrMmbrPk,
                        outTmStrt:this.items.outTmStrt,
                        outTmEnd:this.items.outTmEnd,
                        rltns:this.items.rltns,
                        tel_1:this.items.tel_1,
                        tel_2:this.items.tel_2,
                        tel_3:this.items.tel_3,
                        fcltyNum:gFcltyNum,
                        bnMmbrPk:gBnMmbrPk,
                        userNm:gUserNm,
                        outTmHhStrt:'',
                        outTmMmStrt:'',
                        outTmHhEnd:'',
                        outTmMmEnd:'',
                        aplctDt:this.inqYMD,
                        dstnt:'',
                        rsn:'',
                        rmrks:'',
                    }
                    
                    if(obj.clncClcd == '2' && obj.outHisYn=='1') {
                        let outTmStrt = this.items.outTmStrt.split(":")
                        let outTmEnd = this.items.outTmEnd.split(":")
                        obj.outTmHhStrt = outTmStrt[0]
                        obj.outTmMmStrt = outTmStrt[1]
                        obj.outTmHhEnd = outTmEnd[0]
                        obj.outTmMmEnd = outTmEnd[1]
                        obj.dstnt   = this.items.dstnt
                        obj.rsn     = this.items.rsn  
                        obj.rmrks   = this.items.rmrks
                    }

                    insClncRecOutHis(obj)
                        .then((response) => ( this.onSaveAfter(response.data) ))
                        .catch((error) => console.log('upload error /bnftrcrd/insClncRecOutHis : ' + error))
                }
            }else{
                let error = {
                    statusCode:500,
                    message:'수급자를 선택해주세요.'
                }
                this.$emit("hlthBlnftTrigger",error)
            }
        },
        onSaveAfter:function(res){            
            this.$emit("hlthBlnftTrigger",res)
            
            if(res.statusCode == 200){      
                this.reLoad()
            }

            this.btnVisible = false
        }, 
        delAll:function(){
            let clncRecPk = this.items.clncRecPk
            if(clncRecPk > 0){
                this.dialogDelete = true
            }else{
                let error = {
                    statusCode:500,
                    message:'삭제 대상이 존재하지 않습니다.'
                }
                this.$emit("hlthBlnftTrigger",error)
            }
        },
        //기록 DB 삭제
        onDelete:function(res){
            let fcltyNum = this.$store.getters.getFcltyNum    
            let userNm = this.$store.getters.getUserNm
            let bnMmbrPk = this.$parent.$parent.$parent.$parent.bnMmbrPk

            if(res == 9990){
                let clncRecPk = this.items.clncRecPk
                if(clncRecPk > 0){
                    let obj = {
                        fcltyNum: fcltyNum,
                        bnMmbrPk : bnMmbrPk,
                        userNm : userNm,
                        clncRecPk : this.items.clncRecPk,
                        outHisPk : this.items.outHisPk
                    }
                    delClncRec(obj)
                        .then((response) => ( this.onDeleteAfter(response.data)))
                        .catch((error) => console.log('upload error /bnftrcrd/delHlthVtlRec : ' + error))
                }
            }else if(res == 7){
                this.dialogDelete = false
            }

        },
        onDeleteAfter:function(res){           
            this.dialogDelete = false
            this.$emit("hlthBlnftTrigger",res)
            this.reLoad()
        },
        onModal: function (value, btntype='') {
            this.btnType = btntype
            this.dialog.splice(value, 1, true)
        },
        isModal: function (value) {
            if(value == 3) this.dctrCarerCntrcAllPk = -1
            this.dialog.splice(value, 1, false)
        },
        getPreClncRecOutHisInfo:function(){
            this.isPreData = true
            let date = new Date(this.$parent.$parent.$parent.$parent.inqYMD?.beforeDateFormatHyp())
            let dateArr = new Date(date.setDate(date.getDate()-1)).toISOString().split('T',2)
            date = dateArr[0]?.afterDateFormatHyp()    
            this.preymd = date
            this.getClncRecOutHisInfo()
        },
        getClncRecOutHisInfo:function(value=null){
            let gFcltyNum = this.$store.getters.getFcltyNum
            let gBnMmbrPk = this.$parent.$parent.$parent.$parent.bnMmbrPk
            let gInqYMD = this.$parent.$parent.$parent.$parent.inqYMD

            let obj = {
                fcltyNum:gFcltyNum,
                bnMmbrPk:gBnMmbrPk,
                inqYMD:null,
                clncRecPk:null,
            }

            if(value != null && value > 0){
                obj.clncRecPk = value
            }else{
                obj.inqYMD = gInqYMD

                if(this.isPreData) obj.inqYMD = this.preymd
            }

            selClncRecOutHisInfo(obj)
                .then((response) => ( this.getClncRecOutHisInfoAfter(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/insClncRecOutHis : ' + error))
        },
        getClncRecOutHisInfoAfter:function(res){ 

            this.pk = null
            if(res.clncRec != null){                
                if(!this.isPreData) this.items.clncDt = res.clncRec.clncDt?.beforeDateFormatHyp()
                this.items.clncRecPk = res.clncRec.clncRecPk
                this.items.clncClcd = res.clncRec.clncClcd
                this.items.outHisPk = res.clncRec.outHisPk
                this.items.hospInfPk = res.clncRec.hospInfPk
                this.items.dctInfPk = res.clncRec.dctInfPk
                this.items.hospNm = res.clncRec.hospNm
                this.items.clncCntnt = res.clncRec.clncCntnt
                this.items.clncFee = res.clncRec.clncFee
                this.items.clncFeeClmYn = res.clncRec.clncFeeClmYn
                this.items.drugFee = res.clncRec.drugFee
                this.items.drugFeeClmYn = res.clncRec.drugFeeClmYn
                this.items.frstYn = res.clncRec.frstYn
                this.items.dctrFee = res.clncRec.dctrFee
                this.items.dctrFeeClmYn = res.clncRec.dctrFeeClmYn
                this.items.wrtr = res.clncRec.wrtr
                if(this.items.outHisPk > 0){           
                    this.items.outHisYn= '1'
                    this.items.aplcn = res.outHis.aplcn
                    if(res.outHis.outTmHhStrt !='' && res.outHis.outTmMmStrt != '')
                        this.items.outTmStrt = res.outHis.outTmHhStrt+":"+res.outHis.outTmMmStrt
                    if(res.outHis.outTmHhEnd !='' && res.outHis.outTmMmEnd != '')
                        this.items.outTmEnd = res.outHis.outTmHhEnd+":"+res.outHis.outTmMmEnd
                    this.items.rltns = res.outHis.rltns
                    this.items.tel_1 = res.outHis.tel_1
                    this.items.tel_2 = res.outHis.tel_2
                    this.items.tel_3 = res.outHis.tel_3
                    this.items.dstnt    = res.outHis.dstnt
                    this.items.rsn      = res.outHis.rsn
                    this.items.rmrks    = res.outHis.rmrks   
                }else{
                    this.items.outHisYn= '2'
                }

                if(this.isPreData) {                    
                    this.items.clncRecPk = 0
                }else{
                    this.btnVisible = false
                }
            }
            
            if(this.isPreData) this.isPreData = false
        },
        getClncRecList:function(){
            let gFcltyNum = this.$store.getters.getFcltyNum
            let gBnMmbrPk = this.$parent.$parent.$parent.$parent.bnMmbrPk
            let ym = this.$parent.$parent.$parent.$parent.inqYMD.substr(0,6)

            selClncRecList(gFcltyNum, gBnMmbrPk, null, ym)
                .then((response) => ( this.getClncRecListAfter(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/selClncRecList : ' + error))
        },
        getClncRecListAfter:function(res){

            this.clearItmSum()
            // this.clncRecList=res
            let me = this

            this.clncRecList.splice(0)

            res.forEach(e => {
                if(e.clncClcd=='1')
                    e.clncClcd= '계약의사진료'
                else
                    e.clncClcd= '외래진료'

                me.itemsSum.clncFeeSum += e.clncFee
                me.itemsSum.drugFeeSum += e.drugFee
                me.itemsSum.dctrFeeSum += e.dctrFee

                this.clncRecList.push(e)
            });

            this.chnTblHei(false)
        },
        getEmpInfo: function (obj) {
            if(this.btnType == '1'){

                this.items.wrtr = obj[0].name
                this.items.wrtrMmbrPk = obj[0].mmbrPk
            }else if(this.btnType == '2'){
                this.items.aplcn = ''
                this.items.aplcn = obj[0].name
            }else if(this.btnType == '3'){
                this.items.hospNm = ''
                this.items.hospNm = obj[0].name
            }
        },
        getFmlyInfo: function (obj) {
            this.items.aplcn = ''
            this.items.aplcn = obj.name
        },
        getHsptlInfo: function (obj) {
            this.items.hospInfPk = obj.hospInfPk
            this.items.hospNm = obj.hospNm
            this.items.dctInfPk = 0 
        },
        getDctInfo:function(obj){
            this.items.dctInfPk = obj.dctInfPk
            this.items.hospNm = obj.hospNm
            this.items.hospInfPk = 0
        },
        tableClickEvent:function (ymd, pk){              
            this.pk = pk
            if(ymd == this.inqYMD){
                this.onLoad()
            }else{
                this.$emit("setDate",ymd)                    
            }
            this.typ = this.chnTblHei(false)
        },
        onforce:function(){
            this.forceRender++
        },
        chnTblHei:function(val){
            this.typ = val

            if(this.typ){
                this.tblHeight = "400"
                this.hstStyle = 'dctrcare_stly2'
            }else{
                this.hstStyle = 'dctrcare_stly1'
                this.tblHeight = "170"
            }
        },
        prtDctrCare:function(){
            this.prtThrowObj.list[0].bzPk.splice(0)
            this.prtThrowObj.list[0].bzClcd.splice(0)

            let start = this.$moment(this.$parent.$parent.$parent.$parent.inqYMD,'YYYYMMDD').format('YYYYMM')
            let end = this.$moment(this.$parent.$parent.$parent.$parent.inqYMD,'YYYYMMDD').endOf('month').format('YYYYMMDD');

            this.prtThrowObj.inqYmdStart = start+'01'
            this.prtThrowObj.inqYmdEnd = end
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
            this.prtThrowObj.bnMmbrPk = this.$parent.$parent.$parent.$parent.bnMmbrPk
            this.prtThrowObj.prtCd = '50'

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
            });
        }
    },
    data: () => ({ 
        dctrCarerCntrcAllPk:-1,
        pk:null,
        inqYMD:'',
        btnType:'',
        forceRender:0, 
        items:{clncRecPk:0,clncClcd:'1',clncDt:'',outHisPk:0,hospInfPk:0,dctInfPk:0,hospNm:'',clncCntnt:'',clncFee:0,clncFeeClmYn:'',
            drugFee:0,drugFeeClmYn:'',frstYn:'',dctrFee:0,dctrFeeClmYn:'',outHisYn:'2',wrtr:'',aplcn:'',wrtrMmbrPk:0,outTmStrt:'',outTmEnd:'',rltns:'',
            tel_1:'',tel_2:'',tel_3:'',dstnt:'',rsn:'',rmrks:'',}, 
        itemsSum:{clncFeeSum:0,drugFeeSum:0,dctrFeeSum:0,},
        headers:[
            { text: '진료일', value: '', align: 'center', },
            { text: '진료구분', value: '', align: 'center', },
            { text: '계약의사/병의원', value: '', align: 'center', },
            { text: '진료내용', value: '', align: 'center', },
            { text: '진료비', value: '', align: 'center', },
            { text: '약제비', value: '', align: 'center', },
            { text: '계약의사진찰비', value: '', align: 'center', },
            { text: '작성자', value: '', align: 'center', },  
        ],
        clncRecList:[],
        menu:[false,], 
        dialog:[false,false,false,false,false],
        rtimes:false,
        mtimes:false,
        snackOptions: [false, 3000, '', 'info'],  
        dialogDelete:false,
        btnVisible:true,
        preymd : '',
        isPreData:false,
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'white--text', color:'blueBtnColor', text: '저장', disabled: true,},            
        ],    
        tblHeight:'170',
        hstStyle:'dctrcare_stly1',
        typ:false,
        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '29', inqYmdStart: '', inqYmdEnd: '',
            rptTyp: '1', mode: '2', list: [ { bzClcd: [], bzPk: [] } ],
        },
    }),
};
</script>
<style>
    .dctrcare_stly1 {position:absolute;top:415px;}
    .dctrcare_stly2 {position:absolute;top:190px;z-index:7}
</style>