<template>
    <v-sheet>
        <v-row class="pa-0 ma-0" no-gutters style="width:870px;border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                    
            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0"  cols="12">                                
                <span class="black--text font-weight-medium">물리(작업) 치료평가</span>
            </v-col>
        </v-row>
        <div v-if="examlist.length>0" style="height:300px;overflow-y:auto">
            <v-row v-for="(item, i) in examlist" :key="i" 
                class="pa-0 ma-0" no-gutters  style="width:870px;border-bottom: 1px solid #dbdbdb !important;">
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0"  cols="3">                                
                    <span class="black--text font-weight-medium">{{item.clsfc2Cdnm}}</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="9">                                
                    {{item.evltn}}
                </v-col>
            </v-row>
        </div>
        <div v-else >
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                <v-col class="d-flex pa-0 ma-0" cols="12">
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%">                                
                        데이터가 없습니다.
                    </div>                                
                </v-col>
            </v-row>
        </div>  
    </v-sheet>
</template>
<script>
import { http } from '@/api/baseAxios';

export default {
    name: 'PhyscThrpyInspcInf',
    
    props : {        
        bnMmbrPk            : { type: Number, default: -1 },
        bnfcrPk             : { type: Number, default: -1 },
        inqYYYY             : { type: Number, default: 0 },
    },

    components: {
    },

    computed: {
        
    },  

    created: function(){
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getPhyThrExamList()
        })
    },

    watch: {
        'bnMmbrPk':function(){
            this.getPhyThrExamList()      
        },
        'inqYYYY':function(){
            this.getPhyThrExamList()      
        },
    },

    methods: {        
        reload:function(){
            this.getPhyThrExamList()
        },
        //물리(작업) 치료평가
        getPhyThrExamList: function(){
            this.examlist.splice(0)                          

            let obj = {
                fcltyNum : this.$store.getters.getFcltyNum,
                bnMmbrPk : this.bnMmbrPk,
                inqYYYY : this.inqYYYY,
                bnfcrPk :this.bnfcrPk,
            }
            
            http.post('bnftoffer/selBnfcrPhyThrExamInfList', obj)
                .then((response) => ( this.getPhyThrExamRst(response.data) ))
                .catch((error) => console.log('connect error /bnftoffer/selBnfcrPhyThrExamInfList : ' + error))
            
        },

        getPhyThrExamRst:function(res){

            if(res != null){
                res.forEach(el => {
                    this.examlist.push(el)
                });
            }
        }
    },

    data: () => ({ 
        examlist:[],          
    }),
};
</script>