<template>
    <v-sheet class="ml-0">
        <v-row class="mt-3" no-gutters>
            <v-col cols="6" class="ma-0 pa-0">
                <div class="d-flex justify-start align-center">
                    <span class="d-inline-block pr-3" style="width: 110px;">
                        <v-select v-model="inqCd" :items="inqItems" item-text="label" item-value="value" dense hide-details outlined />
                    </span>    
                    <span>
                        <expandMonthPicker @nowYearWithMonth="getPickerDate"></expandMonthPicker>
                    </span>
                </div>
            </v-col>
            <v-col cols="6" class="ma-0 pr-4">
                <div class="d-flex justify-end align-center">
                    <v-checkbox
                        class="dftChcBox-Theme pt-0" style="margin-top: 4px !important;"
                        v-model="allChecker" @click="onAllAct"
                        :color="allChecker == '1' ? 'blue' : ''"  :ripple="false" 
                        label="전체" true-value="1" false-value="2"     
                        on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                        hide-details>
                    </v-checkbox>
                    <span class="d-inline-block ml-2" style="width:120px;">
                        <v-text-field v-model="bnName" height="28" hide-details readonly outlined dense></v-text-field>
                    </span>
                    <v-btn class="ml-1 deep-orange lighten-3" small min-width="30" height="25" dark
                        style="padding:0px 1px 0px 2px !important" @click="onModal(0)"><v-icon
                            size="20">mdi-account-multiple</v-icon>
                    </v-btn>
                </div>
            </v-col>
        </v-row>

        <v-row class="mt-3" no-gutters>
            <v-col cols="12" class="ma-0 pa-0">
                 <v-data-table 
                    class="msclnTable" 
                    height="660" locale="ko" 
                    :headers="batCopayHeaders" 
                    :items="batCopayList"
                    fixed-header 
                    hide-default-header 
                    hide-default-footer 
                    disable-pagination
                    dense>
                    <template v-slot:header="{ props }">
                        <thead>
                            <tr>
                                <th style="border-bottom: 1px solid #dbdbdb; font-size: 0.9rem !important;" class="black--text clmsHeaderCommon tableBR tableBT" rowspan='2'>
                                    {{ props.headers[0].text }}
                                </th>
                                <th style="border-bottom: 1px solid #dbdbdb; font-size: 0.9rem !important;" class="black--text clmsHeaderCommon tableBR tableBT" rowspan='2'>
                                    {{ props.headers[1].text }}
                                </th>
                                <th style="border-bottom: 1px solid #dbdbdb; font-size: 0.9rem !important;" class="black--text clmsHeaderCommon tableBR tableBT" rowspan='2'>
                                    {{ props.headers[2].text }}
                                </th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="font-size: 0.9rem !important;" colspan='1'>
                                    {{ props.headers[3].text }}
                                </th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="font-size: 0.9rem !important;" colspan='8'>
                                    {{ props.headers[4].text }}
                                </th>
                                <th style="border-bottom: 1px solid #dbdbdb; font-size: 0.9rem !important;" class="black--text clmsHeaderCommon tableBR tableBT" rowspan='2'>
                                    {{ props.headers[5].text }}
                                </th>
                                <th style="border-bottom: 1px solid #dbdbdb; font-size: 0.9rem !important;" class="black--text clmsHeaderCommon tableBR tableBT" rowspan='2'>
                                    {{ props.headers[6].text }}
                                </th>
                                <th style="border-bottom: 1px solid #dbdbdb; font-size: 0.9rem !important;" class="black--text clmsHeaderCommon tableBR tableBT" rowspan='2'>
                                    {{ props.headers[7].text }}
                                </th>
                                <th style="border-bottom: 1px solid #dbdbdb; font-size: 0.9rem !important;" class="black--text clmsHeaderCommon tableBR tableBT" rowspan='2'>
                                    {{ props.headers[8].text }}
                                </th>
                                <th style="border-bottom: 1px solid #dbdbdb; font-size: 0.9rem !important;" class="black--text clmsHeaderCommon tableBR tableBT" rowspan='2'>
                                    {{ props.headers[9].text }}
                                </th>
                                <th style="border-bottom: 1px solid #dbdbdb; font-size: 0.9rem !important;" class="black--text clmsHeaderCommon tableBR tableBT" rowspan='2'>
                                    {{ props.headers[10].text }}
                                </th>
                                <th style="border-bottom: 1px solid #dbdbdb; font-size: 0.9rem !important;" class="black--text clmsHeaderCommon tableBT"
                                    rowspan='2'>{{ props.headers[11].text }}</th>
                            </tr>
                            <tr style="border-top: 1px solid #dbdbdb !important;">
                                <th class="black--text clmsHeaderCommon tableBR " style="font-size: 0.9rem !important;">{{ props.headers[12].text }}</th>
                                <th class="black--text clmsHeaderCommon tableBR " style="font-size: 0.9rem !important;">{{ props.headers[13].text }}</th>
                                <th class="black--text clmsHeaderCommon tableBR " style="font-size: 0.9rem !important;">{{ props.headers[14].text }}</th>
                                <th class="black--text clmsHeaderCommon tableBR " style="font-size: 0.9rem !important;">{{ props.headers[15].text }}</th>
                                <th class="black--text clmsHeaderCommon tableBR " style="font-size: 0.9rem !important;">{{ props.headers[16].text }}</th>
                                <th class="black--text clmsHeaderCommon tableBR " style="font-size: 0.9rem !important;">{{ props.headers[17].text }}</th>
                                <th class="black--text clmsHeaderCommon tableBR " style="font-size: 0.9rem !important;">{{ props.headers[18].text }}</th>
                                <th class="black--text clmsHeaderCommon tableBR " style="font-size: 0.9rem !important;">{{ props.headers[19].text }}</th>
                                <th class="black--text clmsHeaderCommon tableBR " style="font-size: 0.9rem !important;">{{ props.headers[20].text }}</th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item }'>
                        <tr>
                            <td class="tableBR tableBB clmsBodyCommon" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{ item.rn }}</span>
                            </td>
                            <td class="tableBR tableBB clmsBodyCommon" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{ item.name }}</span>
                            </td>
                            <td class="tableBR tableBB clmsBodyCommon" style="height:40px !important; text-align: center;">
                                <span :class="'ic-level level0' + item.acknwRtng" style="margin-left: 7px;">
                                {{ item.acknwRtng == '99' ? '외' : item.acknwRtng }}</span>
                            </td>
                            <td class="tableBR tableBB clmsBodyCommon" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{ item.copay?.toLocaleString('ko-KR') }}</span>
                            </td>
                            <td class="tableBR tableBB clmsBodyCommon" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{ item.mealCost?.toLocaleString('ko-KR') }}</span>
                            </td>
                            <td class="tableBR tableBB clmsBodyCommon" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{ item.upBedFee?.toLocaleString('ko-KR') }}</span>
                            </td>
                            <td class="tableBR tableBB clmsBodyCommon" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{ item.lqdFood?.toLocaleString('ko-KR') }}</span>
                            </td>
                            <td class="tableBR tableBB clmsBodyCommon" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{ item.snackFee?.toLocaleString('ko-KR') }}</span>
                            </td>
                            <td class="tableBR tableBB clmsBodyCommon" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{ item.btyFee?.toLocaleString('ko-KR') }}</span>
                            </td>
                            <td class="tableBR tableBB clmsBodyCommon" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{ item.clncPhrmcFee?.toLocaleString('ko-KR') }}</span>
                            </td>
                            <td class="tableBR tableBB clmsBodyCommon" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{ item.cntrcDctrFee?.toLocaleString('ko-KR') }}</span>
                            </td>
                            <td class="tableBR tableBB clmsBodyCommon" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{ item.msclnFee?.toLocaleString('ko-KR') }}</span>
                            </td>
                            <td class="tableBR tableBB clmsBodyCommon" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{ item.prpymApl?.toLocaleString('ko-KR') }}</span>
                            </td>
                            <td class="tableBR tableBB clmsBodyCommon" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{ item.unpdAmt?.toLocaleString('ko-KR') }}</span>
                            </td>
                            <td class="tableBR tableBB clmsBodyCommon" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{ item.clmTot?.toLocaleString('ko-KR') }}</span>
                            </td>
                            <td class="tableBR tableBB clmsBodyCommon" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{ item.clmDt }}</span>
                            </td>
                            <td class="tableBR tableBB clmsBodyCommon" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{ item.dpsDt }}</span>
                            </td>
                            <td class="tableBR tableBB clmsBodyCommon" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{ item.dpsAmt?.toLocaleString('ko-KR') }}</span>
                            </td>
                            <td class="tableBB clmsBodyCommon" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{ item.dpsMthdNm }}</span>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:[`body.append`]="{ item }">
                        <tr style="z-index: 4 !important; position: sticky; bottom: 0px;">
                            <td class="fixedTable fixedPstn0 clmsHeaderCommon black--text tableBR tableBT fixedTable py-2" style="" colspan="3">합계</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{Math.floor(batCopayListSum.copay)?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{Math.floor(batCopayListSum.mealCost)?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{Math.floor(batCopayListSum.upBedFee)?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{Math.floor(batCopayListSum.lqdFood)?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{Math.floor(batCopayListSum.snackFee)?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{Math.floor(batCopayListSum.btyFee)?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{Math.floor(batCopayListSum.clncPhrmcFee)?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{Math.floor(batCopayListSum.cntrcDctrFee)?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{Math.floor(batCopayListSum.msclnFee)?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{Math.floor(batCopayListSum.prpymApl)?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{Math.floor(batCopayListSum.unpdAmt)?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{Math.floor(batCopayListSum.clmTot)?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{item}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{item}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{Math.floor(batCopayListSum.dpsAmt)?.toLocaleString('ko-KR')}}</td>
                            <td class="fixedTable clmsHeaderCommon black--text tableBT" style="right: 0px; z-index: 2; font-size: 0.875rem !important; width:52px !important;" colspan="1">{{item}}</td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[0]" max-width="700" content-class="round" persistent eager>           
                <IntgrSlctn
                    :mdlTtl="'참석자 선택'" :tabInfList="['1']" :fixList="exclsList" mode="1"
                    :cd41="$parent.$parent.$parent.$parent.vCd41"
                    :cd42="$parent.$parent.$parent.$parent.vCd42"
                    :cd65="$parent.$parent.$parent.$parent.vCd65"
                    :prtWrtDt = "''"
                    @intgrResList="getAplyList" @onClose="isModal(0)">
                </IntgrSlctn>
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import expandMonthPicker from '../../components/commons/expandMonthPicker.vue';
import IntgrSlctn from '../../components/commons/IntgrSlctn.vue';
import { selBatCopayClmDpsMgmt, selBatCopayClmDpsList, selBatCopayClmDps1List } from '../../api/bnfcrStts';
import { dblHdrXlsxPrdc } from '../../api/global/cmmExcelJS.js';
export default {
    name: 'CopaySttsTab1',

    props: {
        
    },
    components: {
        expandMonthPicker,
        IntgrSlctn,
    },

    computed: {
        
    },
    
    watch: {
        'yymm':function(){                     
            this.onCopayClmDpsLoad()
        },

        'inqCd':function(){
            this.onCopayClmDpsLoad()
        },
    },

    created: function () {
    },

    mounted: function () {
        this.$nextTick(function () {
            this.exclsList.splice(0)
            this.dataCall  = true
            this.getBatCopayClmDpsMgmt()
        })
    },

    methods: {

         onCopayClmDpsLoad:function(){
            
            if(!this.dataCall){
                //청구기준
                if(this.inqCd == '1')
                    this.getBatCopayClmDps1List()
                //수납기준
                else if(this.inqCd == '2') 
                    this.getBatCopayClmDpsList()
            }
        },

         //본인부담금 수납내역 조회 (기본 수납목록조회, 공통코드)
        getBatCopayClmDpsMgmt: function () {

            this.batCopayList.splice(0)
            this.clearTot()
            
            let obj = {
                fcltyNum : this.$store.getters.getFcltyNum,
                inqYYMM  : this.$moment().format('YYYYMM'),
                valClcdS : ['41','42','65','70'],
            }

            selBatCopayClmDpsMgmt(obj)
                .then((response) => ( this.getBatCopayClmDpsMgmtAfter(response.data) ))
                .catch((error) => console.log('connect error /report/selBatCopayClmDpsMgmt : ' + error))
        },

        //본인부담금 수납내역 다건 조회 (수납기준)
        getBatCopayClmDpsList: function () {
            this.dataCall = true
            this.batCopayList.splice(0)
            this.clearTot()

            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum,
                inqYYMM     : this.yymm,
                bnMmbrPk    : this.bnMmbrPk,
            }
            selBatCopayClmDpsList(obj)
                .then((response) => ( this.getBatCopayClmDpsListAfter(response.data) ))
                .catch((error) => console.log('connect error /report/selBatCopayClmDpsList : ' + error))
        },

        //본인부담금 수납내역 다건 조회 (청구기준)
        getBatCopayClmDps1List: function () {
            this.dataCall = true
            this.batCopayList.splice(0)
            this.clearTot()

            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum,                
                inqYYMM     : this.yymm,
                bnMmbrPk    : this.bnMmbrPk,
            }

            selBatCopayClmDps1List(obj)
                .then((response) => ( this.getBatCopayClmDps1ListAfter(response.data) ))
                .catch((error) => console.log('connect error /report/selBatCopayClmDps1List : ' + error))
        },


        getBatCopayClmDpsMgmtAfter:function(res){
            this.dataCall = false

            if(res.statusCode == '200'){
                
                if(res.result.code != undefined && res.result.code != null){
                    this.$parent.$parent.$parent.$parent.vCd41 = res.result.code.vCd41
                    this.$parent.$parent.$parent.$parent.vCd42 = res.result.code.vCd42
                    this.$parent.$parent.$parent.$parent.vCd65 = res.result.code.vCd65
                    this.$parent.$parent.$parent.$parent.vCd70 = res.result.code.vCd70
                }

                if(res.result.list != undefined && res.result.list != null){
                   this.setCopayClmDpsList(res.result.list)
                }

            }else{
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'error'])
            }
        },

        getBatCopayClmDpsListAfter:function(res){
            this.dataCall = false
            if(res.statusCode == '200'){
                if(res.result != undefined && res.result != null){
                    this.setCopayClmDpsList(res.result)
                }
            }else{
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'error'])
            }
        },

        getBatCopayClmDps1ListAfter:function(res){
            this.dataCall = false
            if(res.statusCode == '200'){
                if(res.result != undefined && res.result != null){
                    this.setCopayClmDpsList(res.result)
                }
            }else{
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'error'])
            }
        },
        
        //목록 설정
        setCopayClmDpsList(list){
            list.forEach(el => {
                this.$parent.$parent.$parent.$parent.vCd42.forEach(item => {
                    if (el.jobCd == item.valcd)
                        el.jobCd = item.valcdnm
                });
                this.$parent.$parent.$parent.$parent.vCd70.forEach(item => {
                    if (el.wrkStcd == item.valcd)
                        el.wrkStcd = item.valcdnm
                });

                if(el.clmDt != null && el.clmDt != '') el.clmDt = this.$moment(el.clmDt,'YYYYMMDD').format('YYYY.MM.DD')
                if(el.dpsDt != null && el.dpsDt != '') el.dpsDt = this.$moment(el.dpsDt,'YYYYMMDD').format('YYYY.MM.DD')
                
                this.batCopayList.push(el)
            });

            //합계 계산 호출
            this.setCalcTotAmt()
        },

        //합계 계산
        setCalcTotAmt:function(){            
            this.batCopayList.forEach(itm => {
                this.batCopayListSum.copay           += itm.copay
                this.batCopayListSum.nhisPay         += itm.nhisPay
                this.batCopayListSum.mealCost        += itm.mealCost
                this.batCopayListSum.lqdFood         += itm.lqdFood
                this.batCopayListSum.upBedFee        += itm.upBedFee
                this.batCopayListSum.btyFee          += itm.btyFee
                this.batCopayListSum.snackFee        += itm.snackFee
                this.batCopayListSum.clncPhrmcFee    += itm.clncPhrmcFee
                this.batCopayListSum.cntrcDctrFee    += itm.cntrcDctrFee
                this.batCopayListSum.msclnFee        += itm.msclnFee    
                this.batCopayListSum.copayAmt        += itm.copayAmt
                this.batCopayListSum.unpdAmt         += itm.unpdAmt
                this.batCopayListSum.prpymApl        += itm.prpymApl
                this.batCopayListSum.clmTot          += itm.clmTot
                this.batCopayListSum.dpsAmt          += itm.dpsAmt
            });    
            
            if(this.batCopayList.length == 0) {
                this.$parent.$parent.$parent.$parent.outlinedBtn[0].disabled = true
                this.$parent.$parent.$parent.$parent.outlinedBtn[1].disabled = true
            } 
            else {
                this.$parent.$parent.$parent.$parent.outlinedBtn[0].disabled = false            
                this.$parent.$parent.$parent.$parent.outlinedBtn[1].disabled = false            
            } 
        },

        clearTot:function(){
            this.batCopayListSum.copay            = 0
            this.batCopayListSum.nhisPay          = 0
            this.batCopayListSum.mealCost         = 0
            this.batCopayListSum.lqdFood          = 0
            this.batCopayListSum.upBedFee         = 0
            this.batCopayListSum.btyFee           = 0
            this.batCopayListSum.snackFee         = 0
            this.batCopayListSum.clncPhrmcFee     = 0
            this.batCopayListSum.cntrcDctrFee     = 0
            this.batCopayListSum.msclnFee         = 0
            this.batCopayListSum.copayAmt         = 0
            this.batCopayListSum.unpdAmt          = 0
            this.batCopayListSum.prpymApl         = 0
            this.batCopayListSum.clmTot           = 0
            this.batCopayListSum.dpsAmt           = 0
        },


        getPickerDate: function (val) {
           this.yymm   = val
        },
        onModal:function(key){
            this.dialog.splice(key, 1 , true)
        },
        isModal:function(key){
            this.dialog.splice(key, 1 , false)
        },
        getAplyList:function(arr){
            arr.forEach(e => {  
               this.bnMmbrPk    = e.bnMmbrPk
               this.bnName      = e.name
            })

            if(this.bnMmbrPk != null){                
                this.allChecker = '2'
                this.onCopayClmDpsLoad()
            }
        },

        onAllAct:function(){

            this.bnMmbrPk   = null
            this.bnName     = ''

            if(this.allChecker == '1') {
                this.exclsList.splice(0)                
            }

            this.onCopayClmDpsLoad()
        },

        prtCopaySttsReport:function(){
            this.$parent.$parent.$parent.$parent.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
            this.$parent.$parent.$parent.$parent.prtThrowObj.bnMmbrPk = null
            this.$parent.$parent.$parent.$parent.prtThrowObj.inqYYMM = null
            this.$parent.$parent.$parent.$parent.prtThrowObj.inqYYYY = null

            this.$parent.$parent.$parent.$parent.prtThrowObj.inqYYMM = this.yymm
            //청구기준
            if (this.inqCd=='1') {
                this.$parent.$parent.$parent.$parent.prtThrowObj.prtCd = '19'
            //수납기준
            } else if (this.inqCd == '2') {
                this.$parent.$parent.$parent.$parent.prtThrowObj.prtCd = '21'
            }

            if(this.bnMmbrPk != null && this.bnMmbrPk != '') this.$parent.$parent.$parent.$parent.prtThrowObj.bnMmbrPk = this.bnMmbrPk
            this.$parent.$parent.$parent.$parent.prtReport()
        },
        getExcelCrtDwnl: function (){
            let mrgList = [ { colStrt: 'D1', colEnd: 'D1', colNm: '급여' }, { colStrt: 'E1', colEnd: 'L1', colNm: '비급여' } ]

            if(this.inqCd=='1'){
                let shetNm = '본인부담금(청구기준)'
                let title = shetNm + '_' + this.$store.getters.getFcltyNum + '_' + this.$moment().format('YYYYMMDD') 
    
                // 엑셀 생성 및 다운로드 ( 컬럼리스트, 데이터, 헤더 병합, 시트명, 파일명, 작성자, 합계 )
                dblHdrXlsxPrdc(this.exlHeader, this.batCopayList, mrgList, shetNm, title, this.$store.getters.getUserNm, 'Y')

                this.exlBtn = true
            }else if(this.inqCd=='2'){
                let shetNm = '본인부담금(수납기준)'
                let title = shetNm + '_' + this.$store.getters.getFcltyNum + '_' + this.$moment().format('YYYYMMDD') 
    
                // 엑셀 생성 및 다운로드 ( 컬럼리스트, 데이터, 헤더 병합, 시트명, 파일명, 작성자, 합계 )
                dblHdrXlsxPrdc(this.exlHeader, this.batCopayList, mrgList, shetNm, title, this.$store.getters.getUserNm, 'Y')
                this.exlBtn = true
            }
        },
    },
    data: () => ({
        dataCall    : false,
        allChecker  : '1',
        bnMmbrPk    : '',
        bnName      : '',
        inqCd       : '1',
        yymm        : '',
        inqItems    : [
            { label: '청구기준', value: '1' },
            { label: '수납기준', value: '2' },
        ],
        exclsList   : [],
        dialog      : [false,],

         batCopayHeaders: [
            { text: '연번', value: 'name', align: 'center', on: false, sortable: true, class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '수급자명', value: 'jobCd', align: 'center', on: false, sortable: true, class: ' black--text clmsHeaderCommon tableBR tableBT' },
            { text: '등급', value: 'wrkCtCnt', align: 'center', on: false, sortable: true, class: ' black--text clmsHeaderCommon tableBR tableBT' },
            { text: '급여', value: 'actWrkHr', align: 'center', on: false, sortable: true, class: ' black--text clmsHeaderCommon tableBR tableBT' },
            { text: '비급여', value: 'actWrkHr', align: 'center', on: false, sortable: true, class: ' black--text clmsHeaderCommon tableBR tableBT' },
            { text: '선납적용금액', value: 'actWrkHr', align: 'center', on: false, sortable: true, class: ' black--text clmsHeaderCommon tableBR tableBT' },
            { text: '미납금액', value: 'actWrkHr', align: 'center', on: false, sortable: true, class: ' black--text clmsHeaderCommon tableBR tableBT' },
            { text: '청구금액', value: 'actWrkHr', align: 'center', on: false, sortable: true, class: ' black--text clmsHeaderCommon tableBR tableBT' },
            { text: '청구일자', value: 'actWrkHr', align: 'center', on: false, sortable: true, class: ' black--text clmsHeaderCommon tableBR tableBT' },
            { text: '입금일자', value: 'actWrkHr', align: 'center', on: false, sortable: true, class: ' black--text clmsHeaderCommon tableBR tableBT' },
            { text: '입금액', value: 'actWrkHr', align: 'center', on: false, sortable: true, class: ' black--text clmsHeaderCommon tableBR tableBT' },
            { text: '입금방법', value: 'actWrkHr', align: 'center', on: false, sortable: true, class: ' black--text clmsHeaderCommon tableBR tableBT' },
            { text: '본인부담금', value: 'actWrkHr', align: 'center', on: false, sortable: true, class: ' black--text clmsHeaderCommon tableBR tableBT' },
            { text: '식사재료비', value: 'actWrkHr', align: 'center', on: false, sortable: true, class: ' black--text clmsHeaderCommon tableBR tableBT' },
            { text: '상급침실비', value: 'actWrkHr', align: 'center', on: false, sortable: true, class: ' black--text clmsHeaderCommon tableBR tableBT' },
            { text: '경관유동식비', value: 'actWrkHr', align: 'center', on: false, sortable: true, class: ' black--text clmsHeaderCommon tableBR tableBT' },
            { text: '간식비', value: 'actWrkHr', align: 'center', on: false, sortable: true, class: ' black--text clmsHeaderCommon tableBR tableBT' },
            { text: '이미용비', value: 'actWrkHr', align: 'center', on: false, sortable: true, class: ' black--text clmsHeaderCommon tableBR tableBT' },
            { text: '진료약제비', value: 'actWrkHr', align: 'center', on: false, sortable: true, class: ' black--text clmsHeaderCommon tableBR tableBT' },
            { text: '계약의사진찰비', value: 'actWrkHr', align: 'center', on: false, sortable: true, class: ' black--text clmsHeaderCommon tableBR tableBT' },
            { text: '기타비용', value: 'actWrkHr', align: 'center', on: false, sortable: true, class: ' black--text clmsHeaderCommon tableBR tableBT' },
        ],
        batCopayList    : [],
        batCopayListSum: {
            copay: 0, nhisPay: 0, mealCost: 0, lqdFood: 0, upBedFee: 0, btyFee: 0, snackFee: 0, clncPhrmcFee: 0,
            cntrcDctrFee: 0, msclnFee: 0, copayAmt: 0, unpdAmt: 0, prpymApl: 0, clmTot: 0, dpsAmt: 0
        },
        exlHeader: [
            { header: '연번', key: 'rn', width: 8, },
            { header: '수급자명', key: 'name', width: 12, },
            { header: '등급', key: 'acknwRtngNm', width: 12, },
            { header: '본인부담금', key: 'copay', width: 12, style: { numFmt: '#,##0' } },
            { header: '식사재료비', key: 'mealCost', width: 16, style: { numFmt: '#,##0' } },
            { header: '상급침실비', key: 'upBedFee', width: 16, style: { numFmt: '#,##0' } },
            { header: '경관유동식비', key: 'lqdFood', width: 16, style: { numFmt: '#,##0' } },
            { header: '간식비', key: 'snackFee', width: 12, style: { numFmt: '#,##0' } },
            { header: '이미용비', key: 'btyFee', width: 12, style: { numFmt: '#,##0' } },
            { header: '진료약제비', key: 'clncPhrmcFee', width: 12, style: { numFmt: '#,##0' } },
            { header: '계약의사진찰비', key: 'cntrcDctrFee', width: 16, style: { numFmt: '#,##0' } },
            { header: '기타비용', key: 'msclnFee', width: 12, style: { numFmt: '#,##0' } },
            { header: '선납적용금액', key: 'prpymApl', width: 16, style: { numFmt: '#,##0' } },
            { header: '미납금액', key: 'unpdAmt', width: 16, style: { numFmt: '#,##0' } },
            { header: '청구금액', key: 'clmTot', width: 16, style: { numFmt: '#,##0' } },
            { header: '청구일자', key: 'clmDt', width: 16, },
            { header: '입금일자', key: 'dpsDt', width: 16, },
            { header: '입금액', key: 'dpsAmt', width: 16, style: { numFmt: '#,##0' } },
            { header: '입금방법', key: 'dpsMthdNm', width: 16, },
        ],
    }),
}
</script>