<template>
    <v-sheet class="ma-0" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <span class="text-h4 font-weight-bold">{{ agrTtl }}</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="$parent.$parent.$parent.$parent.mdlOff" x-large>mdi-close</v-icon>
        </v-row>
        <v-row class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">작성 정보</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 6px;" cols="3">
                <span class="font-weight-medium posn-rltv">
                    작성일
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -22px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="px-2 pb-1" style="padding-top: 3px;" cols="7">
                <span class="d-inline-block" style="width: 134px; padding-top: 1px;">
                    <CmmDateComp
                        v-model="bscsInf.agrDt" @input="aplctSavBtn" :required="true">
                    </CmmDateComp>
                </span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pt-2" cols="3">
                <span class="font-weight-medium posn-rltv">
                    동의여부
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -22px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="px-2 pb-1" style="padding-top: 3px;" cols="7">
                <v-radio-group
                    v-model="bscsInf.agrYn" @change="aplctSavBtn"
                    class="radio-dft-Theme mt-0" ref="info" hide-details row>
                    <v-radio
                        class="px-2" style="padding-bottom: 2px !important;"
                        :ripple="false" label="예" value="1">
                    </v-radio>
                    <v-radio
                        class="px-2" style="padding-bottom: 2px !important;"
                        :ripple="false" label="아니오" value="2">
                    </v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row v-if="bscsInf.clsfcCode != '1'" class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR pt-2" cols="3">
                <span v-if="bscsInf.clsfcCode == '2'" class="font-weight-medium">제공</span>
                <span v-else-if="bscsInf.clsfcCode == '3'" class="font-weight-medium">통지</span>
                <span class="font-weight-medium"> 받은자</span>
            </v-col>
            <v-col class="px-2 pb-1" style="padding-top: 3px;" cols="5">
                <v-radio-group
                    v-model="bscsInf.rcvPstnCd" @change="aplctSavBtn"
                    class="radio-dft-Theme mt-0" ref="info" hide-details row>
                    <v-radio
                        class="px-2" style="padding-bottom: 2px !important;"
                        :ripple="false" label="수급자" value="1">
                    </v-radio>
                    <v-radio
                        class="px-2" style="padding-bottom: 2px !important;"
                        :ripple="false" label="보호자" value="2">
                    </v-radio>
                </v-radio-group>
            </v-col>
            <v-col class="px-2 pt-1" cols="3">
                <v-text-field
                    v-model="bscsInf.rcvNm" @input="aplctSavBtn"
                    class="" height="28" color="blue"
                    hide-details outlined dense>
                </v-text-field>
            </v-col>
        </v-row>
        <v-divider v-if="bscsInf.clsfcCode != '1'" class="mr-1"></v-divider>
        <v-divider class="white my-2"></v-divider>
        <v-row class="pa-0 justify-center" no-gutters>
            <v-btn
                class="" color="blueBtnColor" min-width="110" height="36"
                :disabled="savBtn" @click="dcsnSavBtn">
                <span class="white--text fontOneDotTwoRem">저장</span>
            </v-btn>
        </v-row>
        <v-divider class="white mt-1"></v-divider>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

import CmmDateComp from '../../commons/CmmDateComp.vue';

export default {
    name: 'AgrSavMdl',

    components: {
        CmmDateComp,
    },

    props : {

    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            let obj = this.$store.getters.getRootInObj
            this.aplctInf(obj)
        })
    },

    computed: {
      
    },

    watch: {
        
    },

    methods:{
        // 동의정보 저장
        setAgrOfrInf: function (obj){
            this.savBtn = true

            http.post('bnfcrAuth/insAgrOfr', obj)
                .then((response) => ( this.agrRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/insAgrOfr : ' + error))
        },

        agrRst: function (res){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == '200')
                this.savBtn = false
            else
                obj.type = 'warning'

            this.$store.commit('setWrnnInf', obj)
        },

        // 기본정보 적용
        aplctInf: function (obj) {
            this.bnMmbrPk = obj.bnMmbrPk
            this.bnfcrPk = obj.bnfcrPk
            this.bscsInf.clsfcCode = obj.clsfcCode

            switch (obj.clsfcCode) {
                case '1':
                    this.agrTtl = '개인정보 수집 및 이용동의 ' + obj.subTtl
                    break;
                case '2':
                    this.agrTtl = '노인인권 보호지침 ' + obj.subTtl
                    break;
                case '3':
                    this.agrTtl = '수급자 안전관리 통지 ' + obj.subTtl
                    break;
                default:
                    break;
            }

            if(obj.subTtl == '수정'){
                this.bscsInf.agrOfrPk = obj.agrOfrPk
                if(obj.clsfcCode != '1'){
                    this.bscsInf.agrDt = this.$moment(obj.ofrDt, 'YYYYMMDD').format('YYYY-MM-DD')
                    this.bscsInf.rcvPstnCd = obj.rcvPstnCd
                    this.bscsInf.rcvNm = obj.rcvNm
                }
                else
                    this.bscsInf.agrDt = this.$moment(obj.agrDt, 'YYYYMMDD').format('YYYY-MM-DD')

                this.bscsInf.agrYn = obj.agrYn
            }
            else
                this.rstFld()

            this.aplctSavBtn()
        },

        // 저장버튼 활성 여부 체크
        aplctSavBtn: function () {
            this.savBtn = true

            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.bscsInf.agrDt) == false)
                return 0
            if(this.bscsInf.agrYn == '')
                return 0

            if(this.bscsInf.clsfcCode == '2' || this.bscsInf.clsfcCode == '3'){
                if(this.bscsInf.rcvPstnCd == '')
                    return 0
                if(this.bscsInf.rcvPstnCd == '2' &&this.bscsInf.rcvNm == '')
                    return 0
            }

            this.savBtn = false
        },

        // 저장버튼 분기
        dcsnSavBtn: function () {
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = '변경할 정보를 입력해주세요.'     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(this.bscsInf.agrDt == '' || this.bscsInf.agrYn == ''){
                this.$store.commit('setWrnnInf', obj)
                return 0
            }

            let insObj = Object.assign({}, this.bscsInf)
            insObj.bnMmbrPk = this.bnMmbrPk
            insObj.bnfcrPk = this.bnfcrPk
            insObj.agrDt = this.$moment(insObj.agrDt, 'YYYY-MM-DD').format('YYYYMMDD')
            insObj.ofrDt = insObj.agrDt

            this.setAgrOfrInf(insObj)
        },

        // 입력필드 초기화
        rstFld: function () {
            this.bscsInf.agrOfrPk = -1
            this.bscsInf.agrDt = ''
            this.bscsInf.agrYn = ''
        }
    },

    data: () => ({
        savBtn: true,
        bnMmbrPk: -1,
        bnfcrPk: -1,
        agrTtl: '',
        agrTyp: '',
        bscsInf: {
            agrOfrPk: -1, clsfcCode: '', agrDt: '', ofrDt: '', agrYn: '', rcvPstnCd: '', rcvNm: '',
            bnfcrSigYn: '', grdnSigYn: ''
        },
    })
}
</script>