<template>
    <v-sheet class="rounded-lg rounded-l-0 overflow-auto pa-2" color="" :min-width="areaWidth" :max-width="areaWidth">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>     
        <div class="d-flex justify-end pl-1 pr-3 pt-2">
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn v-if="list.type == 'action' && list.visible" @click ="onClickBtn(i)"
                    :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                    <v-icon small>{{list.icon}}</v-icon>
                    <span style="font-size: 1rem;">{{list.text}}</span>
                </v-btn>
            </span>
            <v-btn v-for="(list, j) in outlinedBtn" :key="filledBtn.length + j"
                :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded outlined>
                <v-icon small>{{list.icon}}</v-icon>
                <span style="font-size: 1rem;">{{list.text}}</span>
            </v-btn>
        </div>
        <div class="mt-3" style="height:750px;overflow-y:auto;">
            <div class="pr-1 tableBL">
                <v-divider></v-divider>
                <v-row no-gutters>
                    <v-col v-for="(item, i) in authViewList" :key="i" cols="3" class="tableBR tableBB">
                        <div>
                            <v-row no-gutters style="border-bottom: 1px solid #dbdbdb !important;" >  
                                <v-col class="pl-1 py-3 d-flex align-center greyE01" cols="12">
                                    <span class="d-inline-block" style="width: 34px; height: 34px; padding-left: 7px;">
                                        <v-checkbox
                                            class="dftChcBox-Theme pt-0" style="margin-top: 5px !important;"
                                            v-model="item.useYn" @click="onCateChk(i)"
                                            value="1" hide-details>
                                        </v-checkbox>
                                    </span>
                                    <span class="black--text font-weight-medium">{{item.viewNm}}</span>
                                </v-col>
                            </v-row>
                            <v-row class="pt-2 pb-5" no-gutters> 
                                <v-col class="pl-3" v-for="(list, j) in item.list" :key="j" cols="12">                                    
                                    <v-checkbox
                                        class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                        v-model="list.useYn" @click="onItemChk(i)" :label="list.viewNm"
                                        value="1" hide-details>
                                    </v-checkbox>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog eager v-model="modal[0]" persistent max-width="700" min-width="700">                
                <cmmAuthWrt 
                    ref="cmmAuthWrt"
                    @modalEvent="modalEvent"></cmmAuthWrt>
            </v-dialog>
        </div> 
    </v-sheet>
</template>

<script>
import cmmAuthWrt from '../../components/othr/CmmAuthWrt.vue';                  // 그룹관리
import { selCmnAuthViewList, insCmnAuthGrpViewMap } from '../../api/cmm.js';

export default {
    name: 'CmmAuthMgmt',
    
    components: {
        cmmAuthWrt,
    },

    created: function(){
        
    },

    computed: {
        getAreaWidth: function () {
            return this.$parent.$parent.midAreaWidth
        },
    },

    mounted: function(){
        this.$nextTick(function () {
            this.areaWidth = 1260 - Number(this.$parent.$parent.midAreaWidth)       
        })
    },

    watch: {
        getAreaWidth: function (value) {
            this.areaWidth = 1260 - Number(value)
        },
    },
    
    methods: {
        onLoad:function(pk, code){
            if(this.authGrpPk == pk){
                this.authGrpPk = 0
                this.filledBtn[1].disabled = true
                this.filledBtn[2].disabled = true
            }else{
                this.authGrpPk = pk
                if(this.authGrpPk > 0) {
                    this.filledBtn[1].disabled = false
                    this.filledBtn[2].disabled = false
                }else{
                    this.filledBtn[1].disabled = true
                    this.filledBtn[2].disabled = true
                }
    
                if(code == '10000000000'){
                    this.filledBtn[1].disabled = true
                    this.filledBtn[2].disabled = true
                }
            }

            this.getCmnAuthViewList()
        },
        onClickBtn: function (key) {
            switch (key) {
                case 0:
                    this.$refs.cmmAuthWrt.onLoad(0)
                    this.modal.splice(0, 1, true)
                    break;  
                case 1:
                    this.onShowGrp()
                    break;     
                case 2:
                    this.onSave()
                    break;            
                default:
                    break;
            }
        },
        onSave:function(){
            let obj = {list:[]}
            
            this.authViewList.forEach(e => {
                let item = {
                    fcltyNum            : this.$store.getters.getFcltyNum,
                    userNm              : this.$store.getters.getUserNm,
                    cmnAuthGrpPk        : this.authGrpPk,
                    cmmViewPk           : e.cmmViewPk,
                    cmnAuthGrpViewPk    : e.cmnAuthGrpViewPk,
                    useYn               : e.useYn
                }

                obj.list.push(item)

                e.list.forEach(x => {
                    let sitem = {
                        fcltyNum            : this.$store.getters.getFcltyNum,
                        userNm              : this.$store.getters.getUserNm,
                        cmnAuthGrpPk        : this.authGrpPk,
                        cmmViewPk           : x.cmmViewPk,
                        cmnAuthGrpViewPk    : x.cmnAuthGrpViewPk,
                        useYn               : x.useYn
                    }

                    obj.list.push(sitem)
                });
            });

            insCmnAuthGrpViewMap(obj)
                .then((response) => ( this.onSaveAfter(response.data) ))
                .catch((error) => console.log('connect error /cmm/insCmnAuthGrpViewMap : ' + error))
        },
        onSaveAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.getCmnAuthViewList()
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onCateChk:function(idx){            
            let useYn = this.authViewList[idx].useYn            
            this.authViewList[idx].list.forEach(item => {      
                if(useYn == '1') {
                    item.useYn ='1'
                }else{
                    item.useYn ='2'
                }
            })

        },
        onItemChk:function(idx){            
            let useCnt = 0            
            this.authViewList[idx].list.forEach(item => {      
                if(item.useYn == '1') useCnt++
            })

            if(useCnt > 0) this.authViewList[idx].useYn='1' 
            else this.authViewList[idx].useYn='2' 
        },        
        snackControll: function (options) {
            this.snackOptions = options
        },
        modalEvent: function (event) { 
            if(event == 99){
                this.$parent.$parent.getCmnAuthGrpList()
            }else{
                this.modal.splice(event, 1, false)
            }      
        },
        onShowGrp:function(){
            this.$refs.cmmAuthWrt.onLoad(this.authGrpPk)
            this.modal.splice(0, 1, true)
        },        
        getCmnAuthViewList: function () {
            selCmnAuthViewList(this.$store.getters.getFcltyNum, this.authGrpPk)
                .then((response) => ( this.getCmnAuthViewListAfter(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnAuthViewList : ' + error))
        },
        getCmnAuthViewListAfter:function(res){            
            this.authViewList.splice(0)
            this.authViewList = res
        },
    },

    data: () => ({    
        areaWidth:708,   
        authGrpPk:0,
        modal:[false,false],
        authViewList:[],
        filledBtn: [            
            { icon: 'mdi-pencil-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '신규 그룹', type: 'action', width:'600px', visible: true, disabled: false, },
            { icon: 'mdi-square-edit-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '그룹 상세', type: 'action', width:'600px', visible: true, disabled: true, },
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type: 'action', width:'500px', visible: true, disabled: true, },
        ],
        outlinedBtn: [
            
        ],  
        snackOptions: [false, 3000, '', 'info'],   
    }),
};
</script>