<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div> 
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold  blue--text">{{bizMsgTitle}}</span>
            <span class="text-h4 font-weight-bold">&nbsp;전자발송&nbsp;관리</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>        
        <div class="mt-2" style="height:650px;">
            <v-tabs
                v-model="tab" class="tab-dft-Theme" active-class="black"
                center-active hide-slider>
                <v-tab class="fmlySend" v-for="item in tabItems" :key="item.tab" :class="item.class" :disabled="item.enter">{{item.tab}}</v-tab>
            </v-tabs>
            <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
            <div>
                <v-tabs-items v-model="tab">
                    <v-tab-item eager transition="fade-transition">
                        <bizMsgAlimTalk ref="bizMsgAlimTalk" @msgTrigger="msgTrigger" @mdlTrigger="mdlTrigger" @onSignTrigger="onSignTrigger"></bizMsgAlimTalk>
                    </v-tab-item>
                    <v-tab-item eager transition="fade-transition">
                        <bizMsgSms ref="bizMsgSms" @msgTrigger="msgTrigger" @mdlTrigger="mdlTrigger" @onSignTrigger="onSignTrigger"></bizMsgSms>
                    </v-tab-item>
                    <v-tab-item eager transition="fade-transition">
                        <bizMsgEmail ref="bizMsgEmail" @msgTrigger="msgTrigger" @mdlTrigger="mdlTrigger" @onSignTrigger="onSignTrigger"></bizMsgEmail>
                    </v-tab-item>
                    <v-tab-item transition="fade-transition">
                        <bizMsgHist ref="bizMsgHist" v-if="tab == 3" :eDocClcd="bizMsgDocClcd"></bizMsgHist>
                    </v-tab-item>
                    <v-tab-item transition="fade-transition">
                        <bizMsgDirct ref="bizMsgDirct" v-if="tab == 4"  @msgTrigger="msgTrigger" @mdlTrigger="mdlTrigger"></bizMsgDirct>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="menu[0]" content-class="round" max-width="500" persistent eager>
                <bizMsgSign 
                    ref="reportMgmt" 
                    :bizMsgSignPk="bizMsgSignPk"
                    :bzClcd="bizMsgDocClcd"
                    :mmbrPk="signMmbrPk"
                    @isClose="modalEvent(0)"
                ></bizMsgSign>
            </v-dialog>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[0]" max-width="333" content-class="round" eager>
                <bizMsgAlimTalkViw ref="bizMsgAlimTalkViw" @modalClose="isCloseViw(0)"></bizMsgAlimTalkViw>
            </v-dialog>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[1]" max-width="333" content-class="round" eager>
                <bizMsgSmsViw ref="bizMsgSmsViw" @modalClose="isCloseViw(1)"></bizMsgSmsViw>
            </v-dialog>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[2]" max-width="800" content-class="round" eager>
                <bizMsgEmailViw ref="bizMsgEmailViw" @modalClose="isCloseViw(2)"></bizMsgEmailViw>
            </v-dialog>
        </div>
    </v-sheet>
</template>

<script>
import bizMsgAlimTalk from './BizMsgAlimTalk.vue'
import bizMsgSms from './BizMsgSms.vue'
import bizMsgEmail from './BizMsgEmail.vue'
import bizMsgHist from './BizMsgHist.vue'
import bizMsgSign from './BizMsgSign.vue'; //전자서명
import bizMsgAlimTalkViw from './BizMsgAlimTalkViw.vue'
import bizMsgSmsViw from './BizMsgSmsViw.vue'
import bizMsgEmailViw from './BizMsgEmailViw.vue'
import bizMsgDirct from './BizMsgDirct.vue'


export default {
    name: 'BizMsgMdl',

    props : {        
        bizMsgData: {type:Array, default: () => { return [] }},             //대상자(mmbrpk) 데이터 예) [1,2,3]
        bizMsgDocClcd: { type: Number, default: 0 },                //공통코드 108 
        bizMsgBzPk: { type: Number, default: 0 },                   //bzPk
        bizMsgTitle:{ type: String, default: '' },                  //메세지 제목
        bizMsgCntnt:{ type: String, default: '' },                  //메세지 내용
        bizMsgShow: { type: Boolean, default: false },              //v-model ON/OFF
        bizMsgItmCtt4:{ type: String, default: '' },                
        bizMsgItmCtt5:{ type: String, default: '' },                
        bizMsgItmCtt8:{ type: String, default: '' },                
        bizMsgPrtCdAr :{ type: Array, default: () => { return [] }},                
        bizMsgVal1:{ type: String, default: '' },                
        bizMsgVal2:{ type: String, default: '' },                
    },
            
    components: {
       bizMsgAlimTalk,
       bizMsgSms,
       bizMsgEmail,
       bizMsgSign,
       bizMsgHist,
       bizMsgAlimTalkViw,
       bizMsgSmsViw,
       bizMsgEmailViw,
       bizMsgDirct,
    },

    created: function(){
       
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {    
             this.onBizMsgLoad()     
        })
    },

    computed: {

    },

    watch: {
        'bizMsgShow':function(value){
            if(value) this.onBizMsgLoad()
        }
    },
        
    methods: {
        setCorps: function () {
        },
        onClose: function () {
            this.tab = 0
            this.$emit('madalState')
        },
        onBizMsgLoad:function(){
            switch (this.bizMsgDocClcd) {
                case 1:
                    this.title    = '장기요양급여 표준약관'                    
                    this.bizMsgClcdAr   = ['1','2']
                    this.bizMsgCnfmAr   = []
                    this.bizMsgBzClcd   = '22'              
                    this.bizPrtCd       = '6',
                    this.bizNtcClcd     = '1'
                    break;
                case 2:
                    this.title    = '개인정보 수집 및 동의서'                    
                    this.bizMsgClcdAr   = ['1','2']
                    this.bizMsgCnfmAr   = []
                    this.bizMsgBzClcd   = '32'              
                    this.bizPrtCd       = '25'
                    this.bizNtcClcd     = '1'
                    break;
                case 3:
                    this.title    = '노인인권보호지침 제공'                    
                    this.bizMsgClcdAr   = ['2']
                    this.bizMsgCnfmAr   = []
                    this.bizMsgBzClcd   = '22'              
                    this.bizPrtCd       = '201'
                    this.bizNtcClcd     = '2'
                    break;
                case 4:
                    this.title    = '급여제공계획서'                    
                    this.bizMsgClcdAr   = ['1','2']
                    this.bizMsgCnfmAr   = []
                    this.bizMsgBzClcd   = '16'              
                    this.bizPrtCd       = '7'
                    this.bizNtcClcd     = '1'
                    break;
                case 5:
                    this.title    = '가정통신문'                    
                    this.bizMsgClcdAr   = ['3']
                    this.bizMsgCnfmAr   = []
                    this.bizMsgBzClcd   = '2'              
                    this.bizPrtCd       = '1'
                    this.bizNtcClcd     = '2'
                    break;
                case 6:
                    this.title    = '본인부담금 청구서'                    
                    this.bizMsgClcdAr   = ['2']
                    this.bizMsgCnfmAr   = []
                    this.bizMsgBzClcd   = '20'              
                    this.bizPrtCd       = '5'
                    this.bizNtcClcd     = '2'
                    break;
                case 7:
                    this.title    = '본인부담금 영수증'                    
                    this.bizMsgClcdAr   = ['2']
                    this.bizMsgCnfmAr   = []
                    this.bizMsgBzClcd   = '21'              
                    this.bizPrtCd       = '9'
                    this.bizNtcClcd     = '2'
                    break;
                case 8:
                    this.title    = '교육 대상자'                    
                    this.bizMsgClcdAr   = ['1']
                    this.bizMsgCnfmAr   = []
                    this.bizMsgBzClcd   = '10'
                    this.bizPrtCd       = ''
                    this.bizNtcClcd     = '1'
                    break;
                case 9:
                    this.title    = '회의기록'                    
                    this.bizMsgClcdAr   = ['1']
                    this.bizMsgCnfmAr   = []
                    this.bizMsgBzClcd   = '3'
                    this.bizPrtCd       = ''
                    this.bizNtcClcd     = '1'
                    break;
                case 13:
                    this.title    = '연계기록지'                    
                    this.bizMsgClcdAr   = ['1']
                    this.bizMsgCnfmAr   = []
                    this.bizMsgBzClcd   = '13'
                    this.bizPrtCd       = '3'
                    this.bizNtcClcd     = '1'
                    break;
                case 23:
                    this.title    = 'CCTV 침실 촬영 동의'                    
                    this.bizMsgClcdAr   = ['1']
                    this.bizMsgCnfmAr   = []
                    this.bizMsgBzClcd   = '29'
                    this.bizPrtCd       = '37'
                    this.bizNtcClcd     = '1'
                    break;
                case 24:
                    this.title    = '어르신 개인 현황 안내'                    
                    this.bizMsgClcdAr   = ['1']
                    this.bizMsgCnfmAr   = []
                    this.bizMsgBzClcd   = '1'
                    this.bizPrtCd       = ''
                    this.bizNtcClcd     = '1'
                    break;
                default:
                    break;
            }

            if(this.bizMsgDocClcd == 13){
                this.tabItems[4].enter = false
            }else{
                this.tabItems[4].enter = true
            }

            this.onLoad()
        },        
        onLoad:function(){                        
            let setObj = {                              
                bizMsgDocClcd: this.bizMsgDocClcd,
                bizMsgClcdAr: this.bizMsgClcdAr,
                bizMsgCnfmAr: this.bizMsgCnfmAr,
                bizMsgBzClcd: this.bizMsgBzClcd,
                bizMsgBzPk: this.bizMsgBzPk,
                bizMsgTitle:this.bizMsgTitle,
                bizMsgCntnt: this.bizMsgCntnt,
                bizMsgData: this.bizMsgData,
                bizPrtCd:this.bizPrtCd,
                bizMsgItmCtt4:this.bizMsgItmCtt4,
                bizMsgItmCtt5:this.bizMsgItmCtt5,
                bizMsgItmCtt8:this.bizMsgItmCtt8,
                bizMsgPrtCdAr :this.bizMsgPrtCdAr,                
                bizMsgVal1:this.bizMsgVal1,                
                bizMsgVal2:this.bizMsgVal2,
                bizNtcClcd:this.bizNtcClcd,
            }
            
            this.$refs.bizMsgAlimTalk.setData(setObj)
            this.$refs.bizMsgSms.setData(setObj)
            this.$refs.bizMsgEmail.setData(setObj)
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        msgTrigger:function(msg){
            this.snackControll([true, 2500, msg, 'warning'])
        },
        mdlTrigger:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onSignTrigger:function(mmbr){
            this.signMmbrPk = mmbr
            this.bizMsgSignPk = this.bizMsgBzPk
            this.menu.splice(0,1,true)
        },
        modalEvent:function(key){
            this.signMmbrPk = -1
            this.bizMsgSignPk = -1
            this.menu.splice(key,1,false)
        },
        //Url Encrypt
        encUrlObj: function(mmbrpk, nm, bzpk){
            let linkURI = ''
            let contrl  = ''
            let bzClcd  = ''
            let ttl     = this.title
            
            if(this.bizMsgDocClcd == '13'){
                contrl = 'out/rptMgmt?dstnt='
            } else if(this.bizMsgDocClcd == '1'){
                contrl = 'out/dcmntAgr?dstnt='
            } else if(this.bizMsgDocClcd == '2'){
                contrl = 'out/privacyAgr?dstnt='
            } else if(this.bizMsgDocClcd == '4') {
                contrl = 'out/signMgmt?dstnt='
                bzClcd = '16'
            } else if(this.bizMsgDocClcd == '8') {
                contrl = 'out/signMgmt?dstnt='
                bzClcd = '8'
                ttl = this.bizMsgCntnt
            } else if(this.bizMsgDocClcd == '9') {
                contrl = 'out/signMgmt?dstnt='
                ttl = this.bizMsgCntnt
                bzClcd = '7'
            } else if(this.bizMsgDocClcd == '23') {
                contrl = 'out/cnsnFlmn?dstnt='
                bzClcd = '29'
            } else if(this.bizMsgDocClcd == '24') {
                contrl = 'out/prsnlStts?dstnt='
                bzClcd = '1'
            } 

            //개발, 운영 모드에 따라 링크 URL을 환경변수에서 불러옴
            switch (process.env.NODE_ENV) {
                case 'development':
                    linkURI = process.env.VUE_APP_MOBILE_DEV_URI
                    break;
                case 'production':
                    linkURI = process.env.VUE_APP_MOBILE_PROD_URI
                    break;
                default:
                    break;
            }

            let obj = {}
            obj.fcltyNum    = this.$store.getters.getFcltyNum
            obj.mmbrPk      = mmbrpk
            obj.bzPk        = bzpk
            obj.clcd        = this.bizMsgDocClcd
            obj.bzClcd      = bzClcd
            obj.prtCd       = this.bizPrtCd
            obj.name        = nm
            obj.title       = ttl

            // console.log(obj)
            //오브젝트를 JSON 직렬화를 사용해 암호화 (문자열만 암호화 가능)
            let encrypted = this.$crypto.AES.encrypt(JSON.stringify(obj), this.$store.getters.getCrypKey)
            let bs64 = this.$crypto.enc.Base64.stringify(this.$crypto.enc.Utf8.parse(encrypted.toString()))
            return linkURI + contrl + bs64
        },
        onModalViw:function(key, obj, items){
            switch (key) {
                case 0:
                    this.$refs.bizMsgAlimTalkViw.onLoad(obj,items)                    
                    break;
                case 1:
                    this.$refs.bizMsgSmsViw.onLoad(obj,items)                    
                    break;     
                case 2:
                    this.$refs.bizMsgEmailViw.onLoad(obj,items)                    
                    break;   
                default:
                    break;
            }

            this.dialog.splice(key,1,true)
        },
        isCloseViw:function(key){
            this.dialog.splice(key,1,false)
        } ,
    },
    
    data: () => ({
        tab: 0,
        title:'',
        bizMsgBzClcd:'',
        bizMsgClcdAr : [],
        bizMsgCnfmAr : [],
        bizNtcClcd:'',
        bizPrtCd:'',
        tabItems: [
            { index: 0, tab: '알림톡', class: 'fontOneDotTwoRem px-15', enter: false },
            { index: 1, tab: '문자', class: 'fontOneDotTwoRem px-15', enter: false },
            { index: 2, tab: 'E-MAIL', class: 'fontOneDotTwoRem px-15', enter: false },
            { index: 3, tab: '발송내역', class: 'fontOneDotTwoRem px-15', enter: false },
            { index: 4, tab: '직접수령', class: 'fontOneDotTwoRem px-15', enter: true },
        ],
        menu:[false,],
        dialog:[false,false,false,false,],
        signMmbrPk:-1,
        bizMsgSignPk:-1,
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>