<template>
    <v-sheet>
        <v-row class="pa-0 ma-0" no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                    
            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0"  cols="12">                                
                <span class="black--text font-weight-medium">물리(작업)치료 계획</span>
            </v-col>
        </v-row>
            
        <v-row class="pa-0 ma-0" no-gutters  style="border-bottom: 1px solid #dbdbdb !important;">
            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0"  cols="2">                                
                <span class="black--text font-weight-medium">치료기간</span>
            </v-col>
            <v-col class="d-flex justify-start align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="4">
                {{phyThrPlnItems.thrStrt}} ~ {{phyThrPlnItems.thrEnd}}
            </v-col>                    
            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;"  cols="2">                                
                <span class="black--text font-weight-medium">치료횟수</span>
            </v-col>
            <v-col class="d-flex justify-start align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="4">
                {{phyThrPlnItems.thrTimes}}
            </v-col>
        </v-row>    
        <v-row class="pa-0 ma-0 mt-2" style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;" no-gutters>
            <v-col class="greyE01 d-flex pa-0 ma-0" cols="12">
                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:17%">
                    <span class="black--text font-weight-medium">치료구분</span>
                </div>
                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium">치료방법</span>
                </div>
                <div class="pa-0 ma-0" style="width:48%;border-left: 1px solid #dbdbdb !important;">
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;">
                        <span class="black--text font-weight-medium">치료일정</span>
                    </div>
                    <div class="d-flex pa-0 ma-0" style="width:100%;border-top: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;">
                            <span class="black--text font-weight-medium">월</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">화</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">수</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">목</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">금</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">토</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">일</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:37%;border-left: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">치료시간</span>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>
        <div v-if="phyThrPlnList.length>0" >
            <v-row v-for="(item, j) in phyThrPlnList" :key="j" class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                <v-col class="d-flex pa-0 ma-0" cols="12">
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:17%">                                    
                            {{item.thrClcd?.aftValCdToNm(cd37)}}
                    </div>
                    <div class="d-flex align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;">  
                        <span v-if="item.thrClcd=='1'">                                                                                
                            <v-btn v-for="(cdItme, n) in item.thrMthdcd" :key="n" 
                                color="grey006" class="mr-1" samll height="26" outlined
                                :ripple="false"     
                            >
                                <span style="font-size: 0.9rem;">{{cdItme?.aftValCdToNm(cd92)}}</span>
                            </v-btn>
                        </span>
                        <span v-if="item.thrClcd=='2'">                                           
                            <v-btn v-for="(cdItme, n) in item.thrMthdcd" :key="n" 
                                color="grey006" class="mr-1" samll height="26" outlined
                                :ripple="false"
                            >
                                <span style="font-size: 0.9rem;">{{cdItme?.aftValCdToNm(cd93)}}</span>
                            </v-btn>
                        </span>                                    
                    </div>
                    <div class="pa-0 ma-0" style="width:48%;height:100% !important;border-left: 1px solid #dbdbdb !important;">                                
                        <div class="d-flex pa-0 ma-0" style="width:100%;height:100% !important;">
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;">                                            
                                <span v-if="item.thrMonYn == '1'">
                                    <v-icon color="blue">mdi-checkbox-marked</v-icon>
                                </span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">                                            
                                <span v-if="item.thrTueYn == '1'">
                                    <v-icon color="blue">mdi-checkbox-marked</v-icon>
                                </span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">                                            
                                <span v-if="item.thrWedYn == '1'">
                                    <v-icon color="blue">mdi-checkbox-marked</v-icon>
                                </span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">                                            
                                <span v-if="item.thrThuYn == '1'">
                                    <v-icon color="blue">mdi-checkbox-marked</v-icon>
                                </span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">                                            
                                <span v-if="item.thrFriYn == '1'">
                                    <v-icon color="blue">mdi-checkbox-marked</v-icon>
                                </span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">                                            
                                <span v-if="item.thrSatYn == '1'">
                                    <v-icon color="blue">mdi-checkbox-marked</v-icon>
                                </span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">                                            
                                <span v-if="item.thrSunYn == '1'">
                                    <v-icon color="blue">mdi-checkbox-marked</v-icon>
                                </span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:37%;border-left: 1px solid #dbdbdb !important;">
                                    {{item.thrTmStrt}} ~ {{item.thrTmEnd}}
                            </div>
                        </div>
                    </div>                       
                </v-col>
            </v-row>
        </div>
        <div v-else >
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                <v-col class="d-flex pa-0 ma-0" cols="12">
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%">                                
                        데이터가 없습니다.
                    </div>                                
                </v-col>
            </v-row>
        </div> 
    </v-sheet>
</template>
<script>
import { http } from '@/api/baseAxios';

export default {
    name: 'PhyscThrpyPlnInf',
    
    props : {        
        bnMmbrPk            : { type: Number, default: -1 },
        bnfcrPk             : { type: Number, default: -1 },
        inqYYYY             : { type: Number, default: 0 },
        bnftOfrPlnHisPk     : { type: Number, default: -1 },
        phyThrPlnPk         : { type: Number, default: -1 },
        cd37                : { type: Array, default: () => { return [] } },
        cd92                : { type: Array, default: () => { return [] } },
        cd93                : { type: Array, default: () => { return [] } },
    },

    components: {
    },

    computed: {
        
    },  

    created: function(){
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getPhyThrPln()
            this.getPlnDtlList()
        })
    },

    watch: {
        'bnMmbrPk':function(){            
            this.getPhyThrPln()
            this.getPlnDtlList()      
        },        
    },

    methods: {        
        reload:function(){
            this.getPhyThrPln()
            this.getPlnDtlList()
        },
        //물리(작업) 치료계획 정보 조회
        getPhyThrPln: function(){       
            this.rstData()     
            let obj = {
                fcltyNum        : this.$store.getters.getFcltyNum,
                bnMmbrPk        : this.bnMmbrPk,
                bnftOfrPlnHisPk : this.bnftOfrPlnHisPk,
            }
            
            http.post('bnftoffer/selPhyThrPlnInfo', obj)
                .then((response) => ( this.getPhyThrPlnRst(response.data) ))
                .catch((error) => console.log('connect error /bnftoffer/selPhyThrPlnInfo : ' + error))
            
        },

        getPhyThrPlnRst:function(res){

            if(typeof res == 'object'){
                this.phyThrPlnItems.phyThrPlnPk = res.phyThrPlnPk
                this.phyThrPlnItems.mdfRsn      = res.mdfRsn
                this.phyThrPlnItems.prblm       = res.prblm
                this.phyThrPlnItems.thrStrt     = res.thrStrt?.beforeDateFormatHyp()
                this.phyThrPlnItems.thrEnd      = res.thrEnd?.beforeDateFormatHyp()
                this.phyThrPlnItems.thrGls      = res.thrGls
                this.phyThrPlnItems.thrPart     = res.thrPart
                this.phyThrPlnItems.thrTimes    = res.thrTimes
            }
        },

        //물리치료계획상세 조회
        getPlnDtlList:function(){
            this.phyThrPlnList.splice(0)

            let obj = {
                fcltyNum        : this.$store.getters.getFcltyNum,
                bnMmbrPk        : this.bnMmbrPk,
                phyThrPlnPk     : this.phyThrPlnPk,
            }
            
            http.post('bnftoffer/selPhyThrPlnDtlList', obj)
                .then((response) => ( this.getPlnDtlListRst(response.data) ))
                .catch((error) => console.log('connect error /bnftoffer/selPhyThrPlnInfo : ' + error))
        },

        getPlnDtlListRst:function(res){
            if(res != null && res != ''){
                res.forEach(el => {
                    el.thrTmStrt = el.thrTmHhStrt+':'+el.thrTmMmStrt
                    el.thrTmEnd = el.thrTmHhEnd+':'+el.thrTmMmEnd
                    this.phyThrPlnList.push(el)
                });
            }
        },

        rstData:function(){
            this.phyThrPlnItems.phyThrPlnPk = -1
            this.phyThrPlnItems.mdfRsn      = ''
            this.phyThrPlnItems.prblm       = ''
            this.phyThrPlnItems.thrStrt     = ''
            this.phyThrPlnItems.thrEnd      = ''
            this.phyThrPlnItems.thrGls      = ''
            this.phyThrPlnItems.thrPart     = ''
            this.phyThrPlnItems.thrTimes    = ''
        },
    },

    data: () => ({ 
        phyThrPlnItems:{
            phyThrPlnPk : -1,
            mdfRsn      : '',
            prblm       : '',
            thrEnd      : '',
            thrGls      : '',
            thrPart     : '',
            thrStrt     : '',
            thrTimes    : '',
        },
        phyThrPlnList:[],  
    }),
};
</script>