<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div> 
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold">{{yyyy}}년 급여수가&nbsp;변경내역&nbsp;발송&nbsp;관리</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>        
        <v-row no-gutters>
            <v-col class="ma-0 pa-0" cols="12">
                <expandYearOnlyPicker :prtYear="yyyy" @nowYear="getYear"></expandYearOnlyPicker>                                        
            </v-col>
        </v-row>
        <div class="mt-2" style="height:650px;">
            <v-tabs
                v-model="tab"
                class="tab-dft-Theme" active-class="black"
                center-active hide-slider>
                <v-tab class="fmlySend" v-for="item in tabItems" :key="item.tab" :class="item.class" :disabled="item.enter">{{item.tab}}</v-tab>
            </v-tabs>
            <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
            <div>
                <v-tabs-items v-model="tab">
                    <v-tab-item eager transition="fade-transition">
                        <OprFeeInfESndAlim ref="oprFeeInfESndAlim" @msgTrigger="msgTrigger" @mdlTrigger="mdlTrigger"></OprFeeInfESndAlim>
                    </v-tab-item>
                    <v-tab-item eager transition="fade-transition">
                        <OprFeeInfESndSms ref="oprFeeInfESndSms" @msgTrigger="msgTrigger" @mdlTrigger="mdlTrigger"></OprFeeInfESndSms>
                    </v-tab-item>
                    <v-tab-item eager transition="fade-transition">
                        <OprFeeInfESndEmail ref="oprFeeInfESndEmail" @msgTrigger="msgTrigger" @mdlTrigger="mdlTrigger"></OprFeeInfESndEmail>
                    </v-tab-item>
                    <v-tab-item transition="false">
                        <OprFeeInfESndHist v-if="tab == 3" ref="copayClmsBillHist"></OprFeeInfESndHist>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </div>   
    </v-sheet>
</template>

<script>
import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';
import OprFeeInfESndAlim from './OprFeeInfESndAlim.vue'
import OprFeeInfESndSms from './OprFeeInfESndSms.vue'
import OprFeeInfESndEmail from './OprFeeInfESndEmail.vue'
import OprFeeInfESndHist from './OprFeeInfESndHist.vue'
import { selOprFeeInfEsndInfo } from '../../api/opr.js';

export default {
    name: 'OprFeeInfESndMdl',

    props : {                  
    },
            
    components: {
        expandYearOnlyPicker,
        OprFeeInfESndAlim,
        OprFeeInfESndSms,
        OprFeeInfESndEmail,
        OprFeeInfESndHist,
    },

    created: function(){
       
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {  
            let nowMth = this.$moment().format('MM')
            if(nowMth == '12'){
                this.yyyy = Number(this.$moment().add(1,'month').format('YYYY'))
            }

        })
    },

    computed: {

    },

    watch: {
        'yyyy':function(){
            this.getOprFeeInfEsndInfo()
        }
    },
        
    methods: {
        getYear: function (year) {
            this.yyyy = year            
        },
        getOprFeeInfEsndInfo: function () {  
            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum,
                bzPk        : this.yyyy,
                bzClcd      : '30',
                eDocClcd    : '25',
                valClcdS    : ['62', '63', '64', '65', '109']
            }   

            selOprFeeInfEsndInfo(obj)
                .then((response) => ( this.getOprFeeInfEsndInfoAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selCopayEsndList : ' + error))
        },
        getOprFeeInfEsndInfoAfter: function (res) {
            if(res.statusCode == 200){    
                this.vCd109 = res.result.codes.vCd109

                // this.copayEsndList.splice(0)
                res.result.list.forEach(el => {
                    el.lnkUrl = this.encUrlObj(el.bnMmbrPk)                
                    // this.copayEsndList.push(el)
                    el.bzPk     = this.yyyy
                    el.title    = this.yyyy+".01.01"+" 급여수가 변경 안내"
                    el.itmCtt4  = res.result.fclyInfo.cllTel1+'-'+res.result.fclyInfo.cllTel2+'-'+res.result.fclyInfo.cllTel3
                    el.itmCtt5  = this.yyyy+".01.01"
                    el.itmCtt9  = this.yyyy+"년 01월"

                    res.result.codes.vCd62.forEach((cd) => {
                        if(el.acknwRtng == cd.valcd) el.acknwRtngNm = cd.valcdnm
                    })

                    res.result.codes.vCd65.forEach(code => {
                        if(el.entStcd == code.valcd) el.entStcdNm = code.valcdnm
                    });
                });
                
                this.$refs.oprFeeInfESndAlim.setData(res.result.list)
                this.$refs.oprFeeInfESndSms.setData(res.result.list)
                this.$refs.oprFeeInfESndEmail.setData(res.result.list)
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        msgTrigger:function(msg){
            this.snackControll([true, 2500, msg, 'warning'])
        },
        mdlTrigger:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.getOprFeeInfEsndInfo()
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onClose: function () {
            this.tab = 0
            this.$emit('modalClose')
        },     
        snackControll: function (options) {
            this.snackOptions = options
        },   
        //Url Encrypt
        encUrlObj: function(pk){
            let linkURI = ''

            //개발, 운영 모드에 따라 링크 URL을 환경변수에서 불러옴
            switch (process.env.NODE_ENV) {
                case 'development':
                    linkURI = process.env.VUE_APP_MOBILE_DEV_URI
                    break;
                case 'production':
                    linkURI = process.env.VUE_APP_MOBILE_PROD_URI
                    break;
                default:
                    break;
            }

            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnMmbrPk = pk
            obj.year     = this.yyyy

            //오브젝트를 JSON 직렬화를 사용해 암호화 (문자열만 암호화 가능)
            let encrypted = this.$crypto.AES.encrypt(JSON.stringify(obj), this.$store.getters.getCrypKey)
            let bs64 = this.$crypto.enc.Base64.stringify(this.$crypto.enc.Utf8.parse(encrypted.toString()))
            return linkURI + 'out/blnftAmntAnnc?dstnt=' + bs64
        },
        
    },
    
    data: () => ({
        tab:0,
        yyyy:0,
        copayEsndList:[],
        tabItems: [
            { index: 0, tab: '알림톡', class: 'fontOneDotTwoRem px-15', enter: false },
            { index: 1, tab: '문자', class: 'fontOneDotTwoRem px-15', enter: false },
            { index: 2, tab: 'E-MAIL', class: 'fontOneDotTwoRem px-15', enter: false },
            { index: 3, tab: '발송내역', class: 'fontOneDotTwoRem px-15', enter: false },
        ],
        vCd109:[],
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>