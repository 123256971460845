<template>
    <router-view></router-view>
</template>

<script>
import '@mdi/font/css/materialdesignicons.css'

require('./styles/css/common.css')
require('./styles/css/cmm.css')

export default {
    name: 'App',

    components: {

    },

    beforeCreate: function(){
    
    },

    created: function() {
      
    },

    computed:{

    },

    mounted: function() {
        let elHtml = document.getElementsByTagName('html')[0]
        elHtml.style.overflowY = 'auto'
    },

    watch:{

    },
    
    destroyed: function() {
        let elHtml = document.getElementsByTagName('html')[0]
        elHtml.style.overflowY = null
    },

    methods: {
      
    },

    data: () => ({
        forceRender: 0,
    }),
};
</script>