<template>
    <v-sheet class="rounded-lg rounded-l-0 pa-2" color="" min-width="1260" max-width="1560" height="835">
        <div class="px-2 mr-2 d-flex align-center">
            <div class="text-h4">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">
                    <span>6-2. 생활실 현황</span>
                </span>
            </div>
            <v-spacer></v-spacer>
        </div>
        <v-row class="justify-left mt-3 mx-3">
            <exBtn class="ml-1" :btnData="exBtn[0]" @btnClosed="expandClose" ref="exBtnClose1"
                @changeCheck="filterSearchD1" />
        </v-row>
        <div class="mt-5 ml-3 mr-2">
            <v-data-table         
                class=""  locale="ko" height="670"                                         
                :headers="headers" 
                :items="items" 
                hide-default-header 
                fixed-header 
                item-key="rn" 
                :search="schDummy"
                :custom-filter="customFilter" 
                hide-default-footer dense
                disable-pagination>
            <!-- <v-data-table 
                hide-default-footer 
                dense locale="ko" 
                :headers="headers" 
                :items='items' :search="schDummy"
                item-key="rn" 
                :custom-filter="customFilter" 
                class="" 
                show-select 
                hide-default-header 
                disable-pagination> -->
                <template v-slot:header="{ props }">
                    <thead>
                        <tr>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width:15%;">
                                {{ props.headers[0].text }}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width:5%;">
                                {{ props.headers[1].text }}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width:8%;">
                                {{ props.headers[2].text }}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width:10%;">
                                {{ props.headers[3].text }}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width:8%;">
                                {{ props.headers[4].text }}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width:8%;">
                                {{ props.headers[5].text }}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width:7%;">
                                {{ props.headers[6].text }}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width:42%;">
                                {{ props.headers[7].text }}</th>
                        </tr>
                    </thead>
                </template>
                <template v-slot:item='{ item }'>
                    <tr>
                        <td class="clmsBodyCommon tableBR">
                            {{ item.bldNm }}</td>
                        <td class="clmsBodyCommon tableBR">
                            {{ item.bldFlr }}</td>
                        <td class="clmsBodyCommon tableBR">
                            {{ item.lvnRmNm }}</td>
                        <td class="clmsBodyCommon tableBR">
                            {{ item.lvnRmTypNm }}</td>
                        <td class="clmsBodyCommon tableBR">
                            {{ item.fxNum }}</td>
                        <td class="clmsBodyCommon tableBR">
                            {{ item.lvnRmCnt }}</td>
                        <td class="clmsBodyCommon tableBR">
                            {{ item.rmnCnt }}</td>
                        <td class="clmsBodyCommon pa-1">
                            <v-row no-gutters>
                                <v-col cols="12" class="overflow-y-auto" style="height:43px;">
                                    <div class="d-inline-block" style="width:100px;" v-for="(cdItme, n) in item.bnfcrs" :key="n">
                                        <div class="d-flex">
                                            <span>{{ cdItme.name }}</span>
                                            <span>&#40;</span>                                            
                                            <span>
                                                <v-img class="ic-sex" v-if="cdItme.gndr == 'F'" style=""
                                                src="../../assets/icon/ic-female.svg"></v-img>
                                                <v-img class="ic-sex" v-else-if="cdItme.gndr == 'M'"
                                                src="../../assets/icon/ic-male.svg"></v-img>
                                            </span>
                                            <span>{{ cdItme.age }}</span>
                                            <span>&#41;</span>
                                        </div>                                        
                                    </div>                                    
                                </v-col>
                            </v-row >
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </div>

    </v-sheet>
</template>

<script>
import { selLvnRmStsList, } from '../../api/opr.js';
import { selLvnRmInf } from '../../api/opr.js';
import exBtn from '../../components/commons/expandButtonCustom.vue';
export default {
    name: 'LvnRmStts',

    components: {
        exBtn
    },

    created: function () {
        this.getLvnRmInf()
    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.selLvnRmStsList()
        })
    },

    computed: {

    },

    watch: {
    },

    methods: {
        selLvnRmStsList: function () {
            selLvnRmStsList(this.$store.getters.getFcltyNum)
                .then((response) => (this.selLvnRmStsListAfter(response.data)))
                .catch((error) => console.log('connect error opr/selLvnRmStsList : ' + error))
        },
        selLvnRmStsListAfter: function (res) {
            this.items = res
            this.remainder = 0
            res.forEach(e => {
                e.bnfcrs = []
                if (e.jsonBnfcr != null) {
                    let jsonBnfcr = JSON.parse(e.jsonBnfcr.value)
                    jsonBnfcr.forEach(x => {
                        e.bnfcrs.push(x)
                    });
                }
            });
        },
        expandClose: function (index) {
            switch (index) {
                case 0: this.$refs.exBtnClose1.isClose()
                    break;
                default: this.$refs.exBtnClose1.isClose()
                    break;
            }
        },
        filterSearchD1: function (sch) {

            if (sch.length == 0) this.schChkField1.push('')

            this.schChkField1 = []
            sch.forEach((item) => {
                this.schChkField1.push(item)
            });

            //배열 공백 제거
            this.schChkField1 = this.schChkField1.filter(function (item) {
                return item !== null && item !== undefined && item !== '';
            });
            //배열 중복 값 제거
            this.schChkField1 = this.schChkField1.filter((val, idx) => {
                return this.schChkField1.indexOf(val) === idx
            });

            this.schDummy = this.schDummy + '.'
        },
        setSearchText: function (value) {
            this.schField = value

            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column
            let col0 = filter.lvnRmNm.includes(this.schField)

            let col1 = true
            if (this.schChkField1.length != 0) {
                this.schChkField1.forEach((lvnRmNm, i) => {
                    if (i == 0) col1 = filter.lvnRmNm == lvnRmNm
                    else col1 = col1 || filter.lvnRmNm == lvnRmNm
                });
            }

            return col0 && col1;
        },
        getLvnRmInf: function () {
            selLvnRmInf(this.$store.getters.getFcltyNum)
                .then((response) => ( this.getLvnRmInfAfter(response.data) ))
                .catch((error) => console.log('connect error /opr/selLvnRmInf : ' + error))
        },

        getLvnRmInfAfter: function (res){
            this.lvnRmInf = res

            this.lvnRmInf.forEach((code) => {
                this.exBtn[0].expandList.push(code.lvnRmNm)
            });//end foreach
        },
    },

    data: () => ({
        headers: [
            { text: '건물', value: 'rn', sortable: true, align: 'center', class: 'black--text' },
            { text: '층', value: 'name', sortable: true, align: 'center', class: 'black--text' },
            { text: '생활실', value: 'age', sortable: true, align: 'center', class: 'black--text' },
            { text: '생활실구분', value: 'entDt', sortable: true, align: 'center', class: 'black--text' },
            { text: '정원수', value: 'acknwPrdEnd', sortable: true, align: 'center', class: 'black--text' },
            { text: '입실인원', value: 'gndr', sortable: true, align: 'center', class: 'black--text' },
            { text: '잔여수', value: 'entStcd', sortable: true, align: 'center', class: 'black--text' },
            { text: '수급자', value: 'acknwRtng', sortable: true, align: 'center', class: 'black--text' },
        ],
        exBtn: [
            {
                index: 0,
                btnTitle: '생활실',
                btnIcon: 'bed',
                exWidth: 124,
                exHeigth: 100,
                expandList: [],
            },
        ],
        schDummy: '',
        schField: '',
        items: [],
        bnfcrs: [],
        schChkField1: [],
    }),
};
</script>
<style>.fmlyLeterHeader {
    border-top: 1px solid #dbdbdb !important;
    border-right: 1px solid #dbdbdb !important;
    background-color: #ededed;
    text-align: center !important;
    font-size: 1rem !important;
}

.fmlyHeaderCheck div i {
    font-size: 20px !important;
}

.fmlyHeaderCheck div div div i {
    color: #666 !important;
}

.fmlyHeaderCheck .v-input__control {
    width: 25px !important;
}

.fmlyHeaderCheck label {
    color: rgba(0, 0, 0) !important;
}

.sltRow {
    background: #ebf6fe !important;
}

.disSltRow {
    background: white !important;
}
</style>