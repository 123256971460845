<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" style="position:relative">
        <v-form ref="mdlform" lazy-validation>
            <div class="d-flex justify-left align-center mb-3">
                <span class="text-h4 font-weight-bold">회의기록&nbsp;상세</span>
                <v-spacer></v-spacer>
                <v-btn
                    class="white--text mr-1" height="30" color="blueBtnColor"
                    @click="onClickBtn('저장')" :disabled="filledBtn[0].disabled"
                    rounded samll>
                    <v-icon small>mdi-clipboard-text-outline</v-icon>
                    <span class="fontOneRem">저장</span>
                </v-btn>
                <v-btn
                    class="mr-4 white--text" height="30" color="grey006"
                    @click="onClickBtn('삭제')" :disabled="filledBtn[1].disabled"
                    rounded samll>
                    <v-icon small>mdi-trash-can-outline</v-icon>
                    <span class="fontOneRem">삭제</span>
                </v-btn>
                <v-icon color="black" @click="onClose" x-large>mdi-close</v-icon>
            </div>
            <v-row style="border-top: 1px solid #dbdbdb; height: 50px;" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01" cols="2">
                    <span class="black--text font-weight-medium py-2">회의구분</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-left align-center" cols="5">
                    <!-- 프로그램명 textfield -->
                    <span class="d-inline-block ml-2" style="width:40%;">
                        <v-select dense hide-details outlined v-model="items.mtngTypcd" :items="codeItem"
                            item-text="valcdnm" item-value="valcd" style="font-size: 13px;"></v-select>
                    </span>
                    <span class="d-inline-block" style="width: 400px;">
                        <!-- <v-radio-group v-if="items.mtngTypcd == '2'" v-model="items.mtngTopcClcd" class="pl-2 pt-1"
                            hide-details row>
                            <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="직원처우개선" value="1"></v-radio>
                            <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="노인학대예방" value="2"></v-radio>
                            <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="기타" value="3"></v-radio>
                        </v-radio-group> -->
                        <span class="d-inline-block ml-2" style="width: 102px;" v-if="items.mtngTypcd == '2'">
                            <v-checkbox
                                v-model="items.topcClcd1Yn" :ripple="false"
                                class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                label="직원처우개선" true-value="1" false-value="2" hide-details>
                            </v-checkbox>
                        </span>
                        <span class="d-inline-block ml-2" style="width: 102px;" v-if="items.mtngTypcd == '2'">
                            <v-checkbox
                                v-model="items.topcClcd2Yn" :ripple="false"
                                class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                label="노인학대예방" true-value="1" false-value="2"
                                hide-details>
                            </v-checkbox>
                        </span>
                        <span class="d-inline-block ml-2" style="width: 82px;" v-if="items.mtngTypcd == '2'">
                            <v-checkbox
                                v-model="items.topcClcd3Yn" :ripple="false"
                                class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                label="기타" true-value="1" false-value="2"
                                hide-details>
                            </v-checkbox>
                        </span>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center greyE01" cols="2">
                    <span class="black--text font-weight-medium py-2">작성자</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-left align-center" cols="3">
                    <!-- 작성자 textfield -->
                    <span class="d-inline-block py-1 pl-2" style="width: 120px;">
                        <v-text-field
                            v-model="items.wrtr" color="blue" height="28" ref="info"
                            :rules="[rules.required]" :key="forceRender"
                            hide-details outlined dense readonly>
                        </v-text-field>
                    </span>
                    <v-btn class="ml-1 blueBtnColor" small min-width="30" height="25" dark
                        style="padding:0px 1px 0px 2px !important" @click="onModal(0)">
                        <v-icon size="20">mdi-account-search</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row style="border-top: 1px solid #dbdbdb;" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01" cols="2">
                    <span class="black--text font-weight-medium py-2">회의주제</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-left align-center" cols="10">
                    <span class="d-inline-block py-1 pl-2" style="width: 100%;">
                        <v-text-field
                            v-model="items.mtngTopc"
                            color="blue" height="28" ref="info"
                            :rules="[rules.required]" :key="forceRender"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-row style="border-top: 1px solid #dbdbdb;" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01" cols="2">
                    <span class="black--text font-weight-medium py-2">회의일시</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-left align-center" cols="5">
                    <span class="white d-inline-block py-1 ml-2" style="width: 150px;">
                        <CmmDateComp v-model="items.mtngDt" :required="true"></CmmDateComp>
                    </span>
                    <span class="d-inline-block ml-1 mr-1" style="width: 60px;">
                        <CmmTimeField v-model="items.mtngTmStrt" :required="true"></CmmTimeField>
                    </span>
                    ~
                    <span class="d-inline-block ml-1" style="width: 60px;">
                        <CmmTimeField v-model="items.mtngTmEnd" :required="true"></CmmTimeField>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center greyE01" cols="2">
                    <span class="black--text font-weight-medium py-2">회의장소</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-left align-center" cols="3">
                    <!-- 회의장소 textfield -->
                    <span class="d-inline-block ml-2" style="width:100%;">
                        <v-text-field
                            v-model="items.mtngPlc"
                            color="blue" height="28" ref="info"
                            :rules="[rules.required]" :key="forceRender"
                            outlined hide-details dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-row style="border-top: 1px solid #dbdbdb;" no-gutters>
                <v-col class="justify-center align-center greyE01" cols="2">
                    <div class="d-flex justify-center align-center" style="margin-top: 20px;">
                        <div>
                            <span class="black--text font-weight-medium py-2">참석자</span>
                        </div>

                    </div>
                </v-col>
                <v-col class="pa-1 ml-1 ma-0 d-flex justify-left align-center overflow-y-auto" style="height: 70px ">
                    <div style="width:5%">
                        <v-btn v-if="items.mtngTypcd != '2'"
                            class="ml-1 brown lighten-3" 
                            small 
                            min-width="30"
                            height="25"  
                            dark
                            style="padding:0px 1px 0px 2px !important"
                            @click="onIntgrSlctn(1)"
                            >
                            <v-icon size="20">mdi-account-multiple</v-icon>
                        </v-btn>
                        <v-btn v-else
                            class="ml-1 brown lighten-3" 
                            small 
                            min-width="30"
                            height="25"  
                            dark
                            style="padding:0px 1px 0px 2px !important"
                            @click="onIntgrSlctn(2)"
                            >
                            <v-icon size="20">mdi-account-multiple</v-icon>
                        </v-btn>
                    </div>
                    <div style="width:95%">
                        <v-chip 
                            v-for="(cdItme, j) in exclsList" :key="j" 
                            class="pa-2 mr-1"
                            close
                            label                            
                            color="blue"
                            outlined
                            small      
                            @click:close="delEmp(j)"                                
                            >
                            {{cdItme.atndNm}}
                        </v-chip>
                    </div>
                    
                </v-col>
            </v-row>
            <v-row style="border-top: 1px solid #dbdbdb;" no-gutters v-if="items.mtngTypcd != '2'">
                <v-col class="d-flex justify-center align-center greyE01" cols="2">
                    <span class="black--text font-weight-medium py-2">외부참석자</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-left align-center" cols="10">
                    <span class="d-inline-block py-1 pl-2" style="width: 85px;">
                        <v-text-field
                            v-model="outAtnd" :key="forceRender"
                            color="blue" height="28" ref="info"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <v-btn class="ml-1 blueBtnColor" small min-width="30" height="25" dark
                        style="padding:0px 1px 0px 2px !important" @click="plusOutAtnd">
                        <v-icon size="20">mdi-account-plus</v-icon>
                    </v-btn>
                    <div style="width:90%" class="ml-1 ">
                        <v-chip 
                            v-for="(cdItme, j) in outAtndPlus" :key="j" 
                            class="pa-2 mr-1"
                            close
                            label                            
                            color="blue"
                            outlined
                            small      
                            @click:close="delOut(j)"                                
                            >
                            {{cdItme.atndNm}}
                        </v-chip>
                    </div>
                </v-col>
            </v-row>
            <v-row style="border-top: 1px solid #dbdbdb;" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01" cols="2">
                    <span class="black--text font-weight-medium py-2">안건</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-left align-center" style="height: 100px">
                    <span class="d-inline-block ml-2" style="width:100%;">
                        <!-- 목표 textfield -->
                        <v-textarea dense outlined hide-details rows="4" class="rmrksArea" no-resize  v-model="items.mtngAgnd"
                            :rules="[rules.required]"></v-textarea>
                    </span>
                </v-col>
            </v-row>
            <v-row style="border-top: 1px solid #dbdbdb;" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01" cols="2">
                    <span class="black--text font-weight-medium py-2" style="text-align:center">회의내용</span>

                </v-col>
                <v-col class="py-2 ma-0 d-flex justify-left align-center">
                    <span class="d-inline-block ml-2" style="width:100%;">
                        <!-- 프로그램 내용 textfield -->
                        <v-textarea dense outlined hide-details rows="7" class="rmrksArea" no-resize  v-model="items.mtngCntnt"
                            :rules="[rules.required]"></v-textarea>
                    </span>
                </v-col>
            </v-row>
            <v-row style="border-top: 1px solid #dbdbdb;" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01" cols="2">
                    <span class="black--text font-weight-medium py-2">회의결과 반영</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-left align-center" style="height: 120px">
                    <span class="d-inline-block ml-2" style="width:100%;">
                        <!-- 기대효과 textfield -->
                        <v-textarea dense outlined hide-details rows="6" v-model="items.mtngRslt"></v-textarea>
                    </span>
                </v-col>
            </v-row>
            <v-row style="border-top: 1px solid #dbdbdb; border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class=" justify-center align-center greyE01" cols="2">
                    <div class="d-flex justify-center align-center" style="margin-top: 25px;">

                        <div style="text-align: center; ">
                            <span class="black--text font-weight-medium py-2">회의자료첨부</span>
                        </div>
                        <div style="text-align: center;">
                            <v-file-input v-model="files" dense hide-details truncate-length="5" multiple counter hide-input
                                outlined @change="upFile()"></v-file-input>
                        </div>
                    </div>
                </v-col>
                <v-col class="justify-center align-center" style="" cols="5">
                    <!-- 회의자료첨부 v-chip -->
                    <div style="width:100%;min-height:100px;">
                        <v-chip v-for="(item, j) in fileRes" :key="j" label close class="ma-1" outlined
                            @click="fileDownload(j,1)" @click:close="delfiles(j)">
                            <v-icon v-if="item.ext == 'pdf' || item.ext == 'PDF'" color='pink' left>
                                mdi-file-pdf-box
                            </v-icon>
                            <v-icon v-else-if="item.ext == 'xlsx' || item.ext == 'xls'" color='green' left>
                                mdi-microsoft-excel
                            </v-icon>
                            <v-icon v-else-if="item.ext == 'pptx' || item.ext == 'ppt'" color='red' left>
                                mdi-file-powerpoint
                            </v-icon>
                            <v-icon v-else-if="item.ext == 'doc' || item.ext == 'docx'" color='blue' left>
                                mdi-file-word
                            </v-icon>
                            <v-icon v-else color='cyan' left>
                                mdi-file-document
                            </v-icon>
                            {{ item.orgFileNm }}
                        </v-chip>
                    </div>
                </v-col>
                <v-col class=" justify-center align-center greyE01" cols="2">
                    <div class="d-flex justify-center align-center" style="margin-top: 25px;">

                        <div style="text-align: center; margin-top: 1%;">
                            <span class="black--text font-weight-medium py-2">회의사진첨부</span>
                        </div>
                        <div style="text-align: center;">
                            <v-file-input class="pl-1" v-model="images" :accept="imgAccept" dense hide-details
                                truncate-length="5" multiple counter prepend-icon="mdi-camera-outline" hide-input
                                @change="upImgFile()"></v-file-input>
                        </div>
                    </div>
                </v-col>
                <v-col class="justify-center align-center overflow-y-auto" cols="3" style="min-height:100px;max-height:100px;">
                    <!-- 회의사진첨부 checkbox -->
                    <span class="ma-1 d-inline-block" v-for="(item, i) in imageRes" :key="i">
                        <imageCard ref="imageCard" :imgSrc="item.src" :imgWidth="'120'" @isEvent="deleteImg(i)" @onDown="fileDownload(i,2)"></imageCard>
                    </span>
                </v-col>
            </v-row>
        </v-form>
        <v-snackbar v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500"
            class="pa-0" :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
            <div style="text-align: center;">
                <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{ snackOptions[2] }}</span>
            </div>
        </v-snackbar>
        <v-dialog v-model="menu[0]" eager content-class="round" persistent max-width="800">
            <EmpSelector @isClose="isModal(0)" @outEmp="getEmpInfo" :prtWrtDt="''"></EmpSelector>
        </v-dialog>
        <v-dialog v-model="menu[1]" max-width="700" content-class="round" persistent eager>           
            <SelectAll
                :mdlTtl="'참석자 선택'" :tabInfList="['1','2']" :fixList="exclsList" mode="2"
                :cd41="vCd41"
                :cd42="vCd42"
                :cd65="vCd65"
                @intgrResList="getAplyList" @onClose="isModal(1)">
            </SelectAll>
        </v-dialog>
        <v-dialog v-model="menu[2]" max-width="700" content-class="round" persistent eager>           
            <SelectAll
                :mdlTtl="'참석자 선택'" :tabInfList="['3']" :fixList="exclsList" mode="2"
                :cd41="vCd41"
                :cd42="vCd42"
                :cd65="vCd65"
                @intgrResList="getAplyList" @onClose="isModal(2)"
                ref="SelectAll">
            </SelectAll>
        </v-dialog>
        <v-dialog v-model="dialogDelete1" max-width="500">
            <btnModalDelete :title="title" @madalState="delMtngAtnd" @reload="resetPage()"></btnModalDelete>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500">
            <btnModalDelete :title="title" @madalState="delAtchdFile"></btnModalDelete>
        </v-dialog>
        <v-dialog v-model="dialogDelete2" max-width="500">
            <btnModalDelete :title="title" @madalState="delMtngRcrd"></btnModalDelete>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { getMultiStcd } from '../../api/index.js';
import { selMtngRcrdInfo, insMtngRcrd, delMtngRcrd } from '../../api/opr.js';
import { remoteURL } from '@/api/baseAxios';
import { selMtngAtndList, delMtngAtnd } from '../../api/bnftSrv.js';
import { tmpFileUpload, selAtchdFileList, fileDownload, delAtchdFile } from '../../api/index.js';
import SelectAll from '../opr/SelectAll.vue';
import EmpSelector from '../../components/commons/EmpSelector.vue';
import imageCard from '../commons/imageCard.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';
export default {
    name: 'oprMtngRcrd',
    components: {
        EmpSelector,
        SelectAll,
        imageCard,
        btnModalDelete,
        CmmDateComp,
        CmmTimeField,
    },
    watch: {
    },
    created: function () {
        this.getCode(['41','42','43', '46','65'])
    },
    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            // this.gelMtngAtndList()

        })
    },

    methods: {
        plusOutAtnd: function(){
            if(this.outAtnd!=''){
                let obj = {
                    atndNm:this.outAtnd,
                    pstnCd:'5'
                }
                this.outAtndPlus.push(obj)
                this.outAtnd = ''
            }
        },
        getAplyList: function (arr){
            this.exclsList.splice(0)
            this.mtngAtndItems2.forEach(e => {
                let obj = {
                    mtngAtndPk:e.mtngAtndPk,
                    atndNm:e.atndNm,
                    mmbrpk:e.mmbrPk,
                    disChekr:true,
                    checkr:'1'
                }
                if(e.pstnCd=='1'){
                    obj.checkr = '1'
                }else if(e.pstnCd=='2'){
                    obj.checkr = '2'
                }else if(e.pstnCd=='4'){
                    obj.checkr = '3'
                }
                this.exclsList.push(obj)
            });
            arr.forEach(e => {  
                let mmbrpk = 0
                let cd = ''
                let pstnCd = ''

                switch (e.dtTyp) {
                    case 'bnfcr':
                        cd = '1'
                        mmbrpk = e.bnMmbrPk
                        pstnCd = '1'
                        break;
                    case 'emp':
                        cd = '2'
                        mmbrpk = e.mmbrPk
                        pstnCd = '3'
                        break;
                    case 'cmte':
                        cd = '3'
                        mmbrpk = e.mmbrPk
                        pstnCd = '4'
                        break;
                    default:
                        break;
                }            

                if(this.exclsList.findIndex( v => v.mmbrpk == mmbrpk) == -1)    {
                    let obj = {
                        atndNm:e.name,
                        mmbrpk:mmbrpk,
                        pstnCd:pstnCd,
                        mtngAtndPk:e.mtngAtndPk,
                        disChekr:false,
                        checkr:cd
                    }

                    this.exclsList.push(obj)
                }
            });   
        },
        onIntgrSlctn:function(key){
            if(key==2){
                if(this.items.mtngDt!=''){
                    this.$refs.SelectAll.inqYMD(this.items.mtngDt?.afterDateFormatHyp())
                    this.menu.splice(2, 1, true)
                }
            }else{
                this.menu.splice(key, 1, true)
            }
        },
        selMtngRcrdInfo: function (value) {
            if (value != '' && Number(value) > 0) {
                selMtngRcrdInfo(this.$store.getters.getFcltyNum, value)
                    .then((response) => (this.selMtngRcrdInfoAfter(response.data)))
                    .catch((error) => console.log('connect error opr/selMtngRcrdInfo : ' + error))
            }
        },
        selMtngRcrdInfoAfter: function (res) {
            let cache = Object.assign({}, res)
            this.chkHisPk = Number(cache.mtngRcrdPk)
            this.items = cache
            this.items.mtngDt = this.items.mtngDt?.beforeDateFormatHyp()
            this.items.mtngTmStrt = this.items.mtngTmHhStrt + ":" + this.items.mtngTmMmStrt
            this.items.mtngTmEnd = this.items.mtngTmHhEnd + ":" + this.items.mtngTmMmEnd
           
        },
        gelMtngAtndList(value) {
            if (value > 0) {
                selMtngAtndList(this.$store.getters.getFcltyNum, value)
                    .then((response) => (this.gelMtngAtndListAfter(response.data)))
                    .catch((error) => console.log('upload error /opr/selMtngAtndList : ' + error))
            }
        },
        gelMtngAtndListAfter(res) {
            this.exclsList.splice(0)
            this.outAtndPlus.splice(0)
            this.mtngAtndItems2.splice(0)
            res.forEach(x => {
                if(x.pstnCd!='5'){
                    this.mtngAtndItems2.push(x)
                }else{
                    this.outAtndPlus.push(x)
                }
            })
            this.mtngAtndItems2.forEach(e => {
                let obj = {
                    mtngAtndPk:e.mtngAtndPk,
                    atndNm:e.atndNm,
                    mmbrpk:e.mmbrPk,
                    disChekr:true,
                    checkr:'1'
                }
                if(e.pstnCd=='1'){
                    obj.checkr = '1'
                }else if(e.pstnCd=='3'){
                    obj.checkr = '2'
                }else if(e.pstnCd=='4'){
                    obj.checkr = '3'
                }
                this.exclsList.push(obj)
            });
        },
        onClose: function () {
            this.$emit('isClose', '.')
            this.items = Object.assign({}, {
                mtngAgnd: '', mtngCntnt: '', mtngDt: '', mtngPlc: '', mtngRcrdPk: 0, mtngRslt: '', mtngTmHhEnd: '', mtngTmHhStrt: '', mtngTmMmEnd: ''
                , mtngTmMmStrt: '', mtngTopc: '', mtngTopcClcd: '', mtngTypcd: '', wrtr: ''
            })
            this.$refs.mdlform.resetValidation();
            this.empItems.splice(0)
            this.exclsList.splice(0)
        },
        onModal: function (key) {
            this.menu.splice(key, 1, true)
        },
        setEmp: function (emps) {
            let mmbrs = []
            this.mtngAtndItems2.forEach(x => { mmbrs.push(x.mmbrPk) })

            emps.forEach(x => {
                let bl = mmbrs.includes(x.mmbrPk)

                if (!bl) {
                    let obj = {
                        atndNm: x.name,
                        mmbrPk: x.mmbrPk,
                        pstnCd: '3',
                        mtngAtndPk: 0,
                    }
                    this.mtngAtndItems2.push(obj)
                }
            })
        },
        delEmp: function (idx) {
            if (this.exclsList[idx].mtngAtndPk > 0) {
                this.title = '참석자'
                this.idx = idx
                this.key = 2
                this.pk = this.exclsList[idx].mtngAtndPk
                this.dialogDelete1 = true
            } else {
                this.exclsList.splice(idx, 1)
            }
        },
        delOut: function (idx) {
            if (this.outAtndPlus[idx].mtngAtndPk > 0) {
                this.title = '외부참석자'
                this.idx = idx
                this.key = 2
                this.pk = this.outAtndPlus[idx].mtngAtndPk
                this.dialogDelete1 = true
            } else {
                this.outAtndPlus.splice(idx, 1)
            }
        },
        delMtngAtnd: function (res) {
            if (res == 9990) {
                if (this.pk > 0) {
                    delMtngAtnd(this.$store.getters.getFcltyNum, this.pk,this.$store.getters.getUserNm)
                        .then((response) => (this.delMtngAtndAfter(response.data)))
                        .catch((error) => console.log('upload error /opr/delMtngAtnd : ' + error))
                }
            } else if (res == 7) {
                this.dialogDelete1 = false
            }
        },
        delMtngAtndAfter: function (res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.onShow(this.items.mtngRcrdPk)
                this.$emit('reload', true)
                this.idx = 0
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.dialogDelete1 = false
        },
        getEmpInfo: function (obj) {
            this.items.wrtr = obj[0].name
        },
        isModal: function (key) {
            this.menu.splice(key, 1, false)
        },
        onShow(pk) {
            
            this.selMtngRcrdInfo(pk)
            this.getAtchdFileList(pk)
            this.gelMtngAtndList(pk)

        },
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => (this.stCdMapping(response.data)))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        stCdMapping: function (res) {
            this.vCd41 = res.vCd41
            this.vCd42 = res.vCd42
            this.vCd43 = res.vCd43
            this.vCd65 = res.vCd65
            this.codeItem = res.vCd46.slice(1,4)
            // console.log(res.vCd46.slice(1,4))
        },
        datePickClose: function (value) {
            this.menu.splice(value, 2, false)
        },
        upFile: function () {
            let formData = new FormData();

            if (this.files != null) {
                this.files.forEach(element => {
                    formData.append("files", element)
                })

                if (this.files.length > 0) {
                    formData.append("files", this.files);
                    tmpFileUpload(formData)
                        .then((response) => (this.upFileAfter(response.data)))
                        .catch((error) => console.log('upload error /opr/tmpFileUpload : ' + error))
                }
            }
        },
        upFileAfter: function (res) {

            res.files.forEach(x => {
                let fileNm = x.fileNm.split(".")
                x.ext = fileNm[1]
                this.fileRes.push(x)
            })

            this.files = null
        },
        upImgFile: function () {
            let formData = new FormData();
            if (this.images != null) {
                this.images.forEach(element => {
                    formData.append("files", element)
                })

                if (this.images.length > 0) {
                    tmpFileUpload(formData)
                        .then((response) => (this.upImgFileAfter(response.data)))
                        .catch((error) => console.log('upload error /opr/tmpFileUpload : ' + error))
                }
            }

        },
        upImgFileAfter: function (res) {
            res.files.forEach(x => {
                x.src = remoteURL + 'opr/imgPrview?fileNm=' + x.fileNm + '&filePth=' + x.filePth + '&fileKndCd=' + x.fileKndCd
                this.imageRes.push(x)
            })
            this.images = null
        },
        deleteImg(idx) {
            if (this.imageRes[idx].atchdFilePk != undefined) {
                this.key = 2
                this.idx = idx
                this.title = '사진'
                this.dialogDelete = true
            } else {
                this.imageRes.splice(idx, 1)
            }
        },
        delfiles: function (idx) {
            if (this.fileRes[idx].atchdFilePk != undefined) {
                this.key = 1
                this.idx = idx
                this.title = '파일'
                this.dialogDelete = true
            } else {
                this.fileRes.splice(idx, 1)
            }
        },
        fileDownload:function(idx, type){
            if(type == 1){
                if(this.fileRes[idx] != undefined){
                    fileDownload(this.fileRes[idx])
                        .then((response) => ( this.fileDownLoaderAfter(response) ))
                        .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
                }
            }else if(type==2){
                if(this.imageRes[idx] != undefined){
                    fileDownload(this.imageRes[idx])
                        .then((response) => ( this.fileDownLoaderAfter(response) ))
                        .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
                }
            }
        },
        fileDownLoaderAfter: function (res) {
            let contentDisposition = decodeURI(res.headers['content-disposition'])

            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [fileNameMatch] = contentDisposition.split(';').filter(str => str.includes('filename'));
                if (fileNameMatch)
                    [, fileName] = fileNameMatch.split('=');
            }
            link.href = url;

            fileName = fileName.replaceAll('"', '')
            link.setAttribute('download', `${fileName}`);
            link.style.cssText = 'display:none';
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
        getAtchdFileList: function (value) {
            let fcltyNum = this.$store.getters.getFcltyNum
            let clcd = '3'
            if (value > 0) {
                selAtchdFileList(fcltyNum, clcd, value)
                    .then((response) => (this.getAtchdFileListAfter(response.data)))
                    .catch((error) => console.log('upload error /opr/selAtchdFileList : ' + error))
            } else {
                this.files = null
                this.images = null
                this.fileRes.splice(0)
                this.imageRes.splice(0)
            }
        },
        getAtchdFileListAfter: function (res) {
            this.files = null
            this.images = null
            this.fileRes.splice(0)
            this.imageRes.splice(0)

            res.forEach(x => {
                if (x.fileKndCd == "2") {
                    x.src = remoteURL + 'opr/imgView?fileNm=' + x.fileNm + '&filePth=' + x.filePth + '&fileKndCd=' + x.fileKndCd
                    this.imageRes.push(x)
                } else {
                    let fileNm = x.fileNm.split(".")
                    x.ext = fileNm[1]
                    this.fileRes.push(x)
                }
            })

        },
        delMtngRcrd(res) {
            if (res == 9990) {

                if (this.items.mtngRcrdPk != undefined) {
                    let obj = {
                        fcltyNum: this.$store.getters.getFcltyNum,
                        mtngRcrdPk: this.items.mtngRcrdPk,
                        userNm: this.$store.getters.getUserNm,
                    }
                    delMtngRcrd(obj)
                        .then((response) => (this.delMtngRcrdAfter(response.data)))
                        .catch((error) => console.log(error))
                }
            } else if (res == 7) {
                this.dialogDelete2 = false
            }
        },
        delMtngRcrdAfter(res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.$emit('reload', true)
                this.$emit('isClose', true)
                this.dialogDelete2 = false
            }
            else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        delAtchdFile: function (res) {
            if (res == 9990) {
                let pk = 0
                if (this.key == 1) {
                    pk = this.fileRes[this.idx].atchdFilePk
                } else {
                    pk = this.imageRes[this.idx].atchdFilePk
                }

                if (pk > 0) {
                    let fcltyNum = this.$store.getters.getFcltyNum
                    delAtchdFile(fcltyNum, pk)
                        .then((response) => (this.delAtchdFileAfter(response.data)))
                        .catch((error) => console.log('upload error /opr/insMtngRcrdFile : ' + error))
                }
            } else if (res == 7) {
                this.dialogDelete = false
            }
        },
        delAtchdFileAfter: function (res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.onShow(this.items.mtngRcrdPk)
                this.idx = 0
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.dialogDelete = false
        },
        onClickBtn: function (text) {
            if (text == "저장") {
                this.insMtngRcrd()
                this.$emit('reload', true);
            } else if (text == "신규") {
                this.$emit('setSelected', 0);
            } else if (text == "삭제") {
                if (this.items.mtngRcrdPk != undefined) {
                    this.title = '회의기록 삭제'
                    this.dialogDelete2 = true
                } else {
                    this.imageRes.splice(1)
                }
            }
        },
        insMtngRcrd: function () {
            if (this.$refs.mdlform.validate()) {
                let mtngTmStrt = this.items.mtngTmStrt.split(":")
                let mtngTmEnd = this.items.mtngTmEnd.split(":")
                let obj = {
                    mtngRcrdPk: this.items.mtngRcrdPk,
                    mtngAgnd: this.items.mtngAgnd,
                    mtngCntnt: this.items.mtngCntnt,
                    mtngDt: this.items.mtngDt?.afterDateFormatHyp(),
                    mtngPlc: this.items.mtngPlc,
                    mtngRslt: this.items.mtngRslt,
                    mtngTmHhStrt: mtngTmStrt[0],
                    mtngTmMmStrt: mtngTmStrt[1],
                    mtngTmHhEnd: mtngTmEnd[0],
                    mtngTmMmEnd: mtngTmEnd[1],
                    mtngTopcClcd: this.items.mtngTopcClcd,
                    mtngTopc: this.items.mtngTopc,
                    mtngTypcd: this.items.mtngTypcd,
                    wrtr: this.items.wrtr,
                    fcltyNum: this.$store.getters.getFcltyNum,
                    userNm: this.$store.getters.getUserNm,
                    files: [],
                    mtngAtnds: [],
                    topcClcd1Yn: this.items.topcClcd1Yn,
                    topcClcd2Yn: this.items.topcClcd2Yn,
                    topcClcd3Yn: this.items.topcClcd3Yn,
                }
                let mtngAtnds = []
                this.exclsList.forEach(x => {
                    if(x.pstnCd=='1'||x.pstnCd=='3'){
                        let atnd = {
                            fcltyNum:this.$store.getters.getFcltyNum,
                            userNm:this.$store.getters.getUserNm,
                            atndNm:x.atndNm,
                            mmbrPk:x.mmbrpk,
                            mtngAtndPk:x.mtngAtndPk,
                            atndClcd:x.pstnCd,
                            pstnCd:x.pstnCd
                        }
                        mtngAtnds.push(atnd)
                    }else if(x.pstnCd=='4'){
                        let atnd = {
                            fcltyNum:this.$store.getters.getFcltyNum,
                            userNm:this.$store.getters.getUserNm,
                            atndNm:x.atndNm,
                            mmbrPk:x.mmbrpk,
                            mtngAtndPk:x.mtngAtndPk,
                            atndClcd:x.pstnCd,
                            pstnCd:x.pstnCd,
                        }
                        mtngAtnds.push(atnd)
                    }
                })
                this.outAtndPlus.forEach(x => {
                    let atnd = {
                        fcltyNum:this.$store.getters.getFcltyNum,
                        userNm:this.$store.getters.getUserNm,
                        atndNm:x.atndNm,
                        mmbrPk:x.mmbrpk,
                        mtngAtndPk:x.mtngAtndPk,
                        pstnCd:'4',
                        atndClcd:'5',
                    }
                    mtngAtnds.push(atnd)
                })
                obj.mtngAtnds = mtngAtnds
                
                this.imageRes.forEach(x => {
                    if (x.atchdFilePk == undefined) {
                        obj.files.push(x)
                    }
                })
                
                this.fileRes.forEach(x => {
                    if (x.atchdFilePk == undefined) {
                        x.fileKndCd = '1'
                        obj.files.push(x)
                    }
                })
                insMtngRcrd(obj)
                    .then((response) => (this.insMtngRcrdAfter(response.data)))
                    .catch((error) => console.log('connect error opr/insMtngRcrd : ' + error))
            }else{
                this.snackControll([true, 2500, '필수 항목을 입력해주세요.', 'warning'])
            }
        },
        insMtngRcrdAfter: function (res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.onShow(res.result)
                this.$emit('reload', true)
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        reset: function () {
            this.items = Object.assign({}, { wrtr: '', mtngDt: '', mtngTopc: '', mtngPlc: '', mtngAgnd: '', mtngCntnt: '', mtngRslt: '', })
            this.fileRes.splice(0)
            this.imageRes.splice(0)
            this.files = null
            this.images = null
            this.mtngAtndItems2.splice(0)
            this.exclsList.splice(0)
            this.$refs.mdlform.resetValidation();


        },
        snackControll: function (options) {
            this.snackOptions = options
            //this.snackOptions[0] = true
            //this.snackOptions[1] = 2500
            //this.snackOptions[2] = '신규 작성 모드'
            //info, success, warning, error
            //this.snackOptions[3] = 'info'
        },
        onforce() {
            ++this.forceRender
        },
        resetPage: function () {
            this.$emit('reload', true)
        }

    },
    data: () => ({
        snackOptions: [false, 3000, '', 'info'],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !(value == '선택' || value == '') || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },
        filledBtn: [
            { icon: 'mdi-clipboard-text-outline', class: 'ml-1 white--text', color: 'blueBtnColor', text: '저장', type: 'action', disabled: false, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color: 'grey006', text: '삭제', type: 'action', disabled: false, },
        ],
        outlinedBtn: [
            // { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color: 'grey006', text: '출력', type: 'action', disabled: false, },
        ],
        items: {
            mtngRcrdPk: 0,
            mtngAgnd: [],
            mtngCntnt: '',
            mtngDt: '',
            mtngPlc: '',
            mtngRslt: '',
            mtngTmHhEnd: '',
            mtngTmHhStrt: '',
            mtngTmMmEnd: '',
            mtngTmMmStrt: '',
            mtngTopcClcd: '',
            mtngTypcd: '',
            files: [],
            mtngTmStrt: '',
            mtngTmEnd: '',
            mtngTopc: '',
            wrtr: '',
            topcClcd1Yn: '',
            topcClcd2Yn: '',
            topcClcd3Yn: '',
        },
        prgChkItems: [],
        prgNoItems: [],
        forceRender: 0,
        MtngRcrdList: [],
        exclsList: [],
        codeItem: [{ valcd: '', valcdnm: '' }],
        vCd43: [],
        vCd46: [],
        menu: [false, false, false],
        item: [],
        files: null,
        fileRes: [],
        images: null,
        imageRes: [],
        imgAccept: 'image/*',
        mtngAtndItems2: [],
        empItems: [],
        rtimes1: '',
        mtimes1: '',
        rtimes2: '',
        mtimes2: '',
        dialogDelete: false,
        dialogDelete1: false,
        dialogDelete2: false,
        dialogDelete3: false,
        nowDate: '',
        mdlTtl: '',
        tabInfList: [],
        // key:0,
        // idx:0,
        vCd41: [],
        vCd42: [],
        vCd65: [],
        title: '사진',
        empChkItems:[],
        outAtnd:'',
        outAtndPlus:[],
        prtWrtDt:'',
        // dialogDelete:false,
    }),
};
</script>