<template>
    <div class="d-inline-block d-flex">
        <v-sheet class="d-inline-block" width="450" height="835" color="midAreaColor">
            <div class="text-h4 px-2">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">
                    <span>2-3. 물리&#40;작업&#41;치료&nbsp;평가&nbsp;및&nbsp;계획</span>
                </span>
            </div>
            <expandYearOnlyPicker @nowYear = "getYear" class="my-5"></expandYearOnlyPicker>
            <v-row class="justify-left mt-3 mx-3">             
                <CmmExpnBtn
                    v-model="isEntStt"
                    :btnNm="'입소현황'" vTyp="1" :bImg="'ic-house'"
                    :iList="vCd65" @onList="dmyTxt = String(Number(dmyTxt)+1)">
                </CmmExpnBtn>
                <CmmLvnExpnBtn
                    v-model="isLvnStt"
                    class="ml-1"
                    @onList="lvnSttApply">
                </CmmLvnExpnBtn>
                <v-spacer></v-spacer>
                <span class="d-inline-block" style="width: 106px; height: 34px;">
                    <v-text-field
                        v-model="srchTxt" @input="dmyTxt = String(Number(dmyTxt)+1)"
                        class="txt-Theme" height="34" label="이름조회"
                        background-color="white" prepend-inner-icon="mdi-magnify"
                        single-line outlined dense filled hide-details>
                    </v-text-field>
                </span>             
            </v-row>
            <div class="mt-5 ml-3 mr-2">                
                <v-data-table
                    class="bnfcrMidTable-Theme rounded" height="630" locale="ko"
                    :headers="allPhyThrHeaders" :items="allPhyThrList" item-key="rn"
                    :loading="isLoad" :loading-text="loadMsg"
                    :search="dmyTxt" :custom-filter="srchFltr"
                    fixed-header disable-pagination hide-default-footer dense>

                    <template v-slot:item='{ item, index }'>
                        <tr :class="[sltRow.bnMmbrPk == item.bnMmbrPk ? 'onClr' : '']" @click="evtRow(item)">                               
                            <td class="hvrClr">{{index+1}}</td>
                            <td class="hvrClr">{{item.bnfcrNm}}</td>
                            <td class="hvrClr">                                
                                <span v-if="item.lstExamDt != null && item.lstExamDt != undefined">{{$moment(item.lstExamDt,'YYYYMMDD').format('YYYY.MM.DD')}}</span>                                
                            </td>
                            <td class="hvrClr">                                
                                <span :class="['', item.examDt == '미작성' ? 'red--text' : '']">{{item.examDt}}</span>
                            </td>
                            <td class="hvrClr">
                                <span :class="['', item.wrtDt == '미작성' ? 'red--text' : '']">{{item.wrtDt}}</span>
                            </td>
                            <td class="hvrClr">{{item.entStcdNm}}</td>
                            <td class="hvrClr">
                                <v-img class="ic-sex ml-1" v-if="item.gndr == 'F'" src="../../assets/icon/ic-female.svg"></v-img>
                                <v-img class="ic-sex ml-1" v-else-if="item.gndr == 'M'" src="../../assets/icon/ic-male.svg"></v-img>
                            </td> 
                            <td class="hvrClr">{{item.age}}</td>
                            <td class="hvrClr">{{item.lvnRmNm}}</td>
                        </tr>                        

                    </template>


                    <template v-slot:foot='{ items, pagination }'>
                        <div class="bnfcrMidTable-Foot d-inline-block">
                            <v-row class="pa-0 lign-center" no-gutters>
                                <span class="fontOneRem mr-2">전체: {{ pagination.itemsLength }}명,</span>
                                <span class="fontOneRem mr-2">남: {{ items.filter( v => v.gndr == 'M').length }}명,</span>
                                <span class="fontOneRem">여: {{ items.filter( v => v.gndr == 'F').length }}명</span>
                            </v-row>
                        </div>
                    </template>
                </v-data-table>
            </div>
        </v-sheet>
        <v-sheet class="d-inline-block rounded-lg rounded-l-0 overflow-auto" min-width="600px" max-width="900px">
            <router-view ref="physcThrpyInspcPln"></router-view>
        </v-sheet>
    </div>
</template>

<script>
import { http } from '@/api/baseAxios';
import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';
import CmmExpnBtn from '../../components/commons/CmmExpnBtn';
import CmmLvnExpnBtn from '../../components/commons/CmmLvnExpnBtn';

export default {
    name: 'BnftSrvPlnCnfgr',
    
    components: {
        expandYearOnlyPicker,
        CmmExpnBtn,
        CmmLvnExpnBtn
    },

    created: function(){
         
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.areaWidth = 1260 - Number(this.$parent.$parent.midAreaWidth)
            this.routeLctnNm = this.$route.name

            this.getCode(['37','65','92','93'])
        })
    },

    computed: {
        getRouteLoc(){
            return this.$route
        },
    },

    watch: {
        getRouteLoc: function (obj) {
            this.routeLctnNm = obj.name
        },
        'isEntStt': function (v) {
            if(v) this.isLvnStt = false
        },
        'isLvnStt': function (v) {
            if(v) this.isEntStt = false
        }
    },

    methods: {
        getYear: function (yyyy){
            this.inqYYYY = yyyy
            this.getAllPhyThrList()
        },
        
        // 공통 코드 다중 조회
        getCode: function (arr) {
            let obj = {}
            obj.valClcdS = arr
            obj.fcltyNum = this.$store.getters.getFcltyNum

            http.post('cmm/selCmnItmValCdJsn', obj)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },

        //물리(작업)치료 평가및계획 현황 조회
        getAllPhyThrList:function(){
            this.allPhyThrList.splice(0)
            let obj = {
                inqYYYY:this.inqYYYY
            }

            http.post('bnftoffer/selAllPhyThrList', obj)
                .then((response) => ( this.getAllPhyThrListRst(response.data) ))
                .catch((error) => console.log('connect error /bnftoffer/selAllPhyThrList : ' + error))
        },

        stCdMapping: function (res){
            if(res.vCd65.length > 0)
                res.vCd65[0].isOn = true
            this.vCd37 = res.vCd37
            this.vCd65 = res.vCd65
            this.vCd92 = res.vCd92
            this.vCd93 = res.vCd93

            this.valCdApply()
        },
        

        getAllPhyThrListRst:function(res){
            this.isLoad = false

            let obj = {}    
            if(res.statusCode == 200){ 
                if(res.result != null) {
                    res.result.forEach(el => {

                        this.vCd65.forEach(code => {
                            if(el.entStcd == code.valcd) el.entStcdNm = code.valcdnm
                        });

                        this.allPhyThrList.push(el)

                        //state save value check
                        if(this.$store.getters.getBnfcrState.bnmmbr != 0){
                            if(this.$store.getters.getBnfcrState.bnmmbr == el.bnMmbrPk){
                                this.sltRow.bnMmbrPk            = this.$store.getters.getBnfcrState.bnmmbr
                                this.sltRow.bnfcrPk             = this.$store.getters.getBnfcrState.bnfcr
                                this.sltRow.bnfcrExamHisPk      = el.bnfcrExamHisPk
                                this.sltRow.bnftOfrPlnHisPk     = el.bnftOfrPlnHisPk
                                this.sltRow.phyThrPlnPk         = el.phyThrPlnPk
                            }
                        }
                    });


                    
                }
            } else {
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        // 테이블 행 클릭 이벤트
        evtRow: function (obj){
            if(this.sltRow.bnfcrPk == obj.bnfcrPk){
                this.sltRow.bnMmbrPk        = -1
                this.sltRow.bnfcrPk         = -1
                this.sltRow.bnfcrExamHisPk  = -1
                this.sltRow.bnftOfrPlnHisPk = -1
                this.sltRow.phyThrPlnPk     = -1

                this.$store.commit('setBnfcrState', {
                    bnmmbr: 0, bnfcr: 0, name: '', entStcd: '',  entDt: '', lvDt: ''
                });
            }
            else{
                this.sltRow.bnMmbrPk        = obj.bnMmbrPk
                this.sltRow.bnfcrPk         = obj.bnfcrPk
                this.sltRow.bnfcrExamHisPk  = obj.bnfcrExamHisPk
                this.sltRow.bnftOfrPlnHisPk = obj.bnftOfrPlnHisPk
                this.sltRow.phyThrPlnPk     = obj.phyThrPlnPk

                this.$store.commit('setBnfcrState', {
                    bnmmbr: obj.bnMmbrPk, bnfcr: obj.bnfcrPk, name: obj.bnfcrNm, entStcd: obj.entStcd, 
                    entDt: obj.entDt, lvDt: obj.lvDt == null ? '' : obj.lvDt
                });
            }

            // this.$refs.physcThrpyInspcPln.onShow(selObj)
        },


        // 테이블 검색 필터 (value: list, search: search key, filter: array column)
        srchFltr: function (value, search, filter){
            
            let field1 = false
            let field2 = true
            let field3 = true

            // 이름 검색
            field1 = filter.bnfcrNm.includes(this.srchTxt)

            // 입소현황 검색
            let entRunChc = this.vCd65.filter( v => v.isOn == true )
            if(entRunChc.length == 0)
                field2 = false
            else{
                entRunChc.forEach((itm, i) => {
                    if(i == 0) field2 = filter.entStcd == itm.valcd
                    else field2 = field2 || filter.entStcd == itm.valcd
                });
            }

            // 생활실 검색
            let lvnRunChc = this.aslLvnStt.filter( v => v.isOn == true )
            if(lvnRunChc.length == 0)
                field3 = false
            else{
                lvnRunChc.forEach((itm, i) => {
                    if(i == 0) field3 = filter.lvnRmInfPk == itm.lvnRmInfPk
                    else field3 = field3 || filter.lvnRmInfPk == itm.lvnRmInfPk
                });
            }

            return field1 && field2 && field3
        },

        // 테이블 데이터 코드 맵핑
        valCdApply: function (){
            // 추가 코드 맵핑 작성...

            if(this.allPhyThrList.length > 0){
                this.allPhyThrList.forEach(el => {
                    this.vCd65.forEach(code => {
                        if(el.entStcd == code.valcd) el.entStcdNm = code.valcdnm
                    });
                })
            }
        },

        // 생활실 선택 컴포넌트 변경 이벤트
        lvnSttApply: function (arr){
            this.aslLvnStt = arr

            this.dmyTxt = String(Number(this.dmyTxt)+1)
        },
    },

    data: () => ({
        isLoad              : true,
        loadMsg             : '잠시만 기다려주세요.',
        routeLctnNm         : '',                               // 라우터 경로
        isEntStt            : false,                            // 현황 확장 활성화 여부
        isLvnStt            : false,                            // 생활실 확장 활성화 여부
        areaWidth           : 0,
        inqYYYY             : 0,        
        srchTxt             : '',                               // 테이블 이름 검색 필드
        dmyTxt              : '',                               // 테이블 반응 더미(검색용)
        vCd37               : [],                               
        vCd65               : [],                               // 입소현황
        vCd92               : [],                               
        vCd93               : [],                               
        sltRow              : { bnMmbrPk: -1, bnfcrPk: -1, bnfcrExamHisPk:-1, bnftOfrPlnHisPk:-1, phyThrPlnPk:-1},
        aslLvnStt           : [],
        allPhyThrHeaders    : [                        
            { text: '연번', value: 'rn', sortable: true, width: '70' },
            { text: '수급자', value: 'bnfcrNm', sortable: true, width: '80' },
            { text: '최종평가일', value: 'lstExamDt', sortable: true, width: '100' },
            { text: '평가일', value: 'examDt', sortable: true, width: '100' },
            { text: '계획일', value: 'wrtDt', sortable: true, width: '100' },
            { text: '현황', value: 'entStcdNm', sortable: true, width: '80' },
            { text: '성별', value: 'gndr', sortable: true, width: '60' },
            { text: '나이', value: 'age', sortable: true, width: '60' },
            { text: '생활실', value: 'lvnRmNm', sortable: true, width: '80' },
        ],
        allPhyThrList   : [],
    }),
};
</script>