<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">   
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>       
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">계약의사 진료내역 일괄처리</span>            
            <v-spacer></v-spacer> 
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <div class="mx-2 mt-2 d-flex">
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn v-if="list.type == 'action' && list.visible" @click = "onClickBtn(list.text)"
                    :class="list.class" height="30" :color="list.color" rounded
                    style="font-size: 14px;">
                    <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                    {{list.text}}
                </v-btn>
            </span>
        </div>
        <div class="mx-2 mt-2 d-flex justify-center align-center">
             <div>
                <expandNowDatePicker :prtDate="ymd" @nowDate="getDate"></expandNowDatePicker>        
            </div>
        </div>
        <v-form ref="form" lazy-validation>
        <div class="mx-2 mt-2 d-flex justify-left align-center">
            <div class="d-flex">
                <exBtn class="mr-1" :btnData="exBtn[0]" @btnClosed="expandClose" @changeCheck="filterSearch" />
                <exBtn v-if="routerRocation != 'EmrgnRcrd' && routerRocation != 'SpclBdrmUseRcrd'"
                    class="mr-1" :btnData="exBtn[1]" @btnClosed="expandClose" @changeCheck="filterSearch" />            
                <span class="d-inline-block" style="width: 130px;">
                    <v-text-field class="ma-0 pa-0 nameSearch" height="35px" background-color="white" append-icon="mdi-magnify"
                        single-line rounded outlined dense filled hide-details label="이름조회" @input="setSearchText">
                    </v-text-field>
                </span>
            </div>           
            <v-spacer></v-spacer>
            <div class="d-flex justify-center align-center pr-4">
                <span class="black--text font-weight-medium">계약의사</span>
                <span class="px-1">&#58;</span>
                <span class="d-inline-block" style="width: 200px;">
                    <v-text-field v-model="hospNm" color="blue" height="28" ref="info" :rules="[rules.required]" readonly
                        hide-details outlined dense :key="forceRender"></v-text-field>
                </span>
                <span>
                    <v-btn                         
                        class="ml-1 green lighten-3"  
                        x-small 
                        min-width="30"
                        height="25"                         
                        style="padding:0px 1px 0px 2px !important"
                        @click="menu.splice(1,1,true)"
                        >
                        <v-tooltip top >
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon class="white--text" size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                            </template>
                            <span>계약의사 선택</span>
                        </v-tooltip> 
                    </v-btn>
                </span>
            </div>
            <div class="d-flex justify-center align-center pr-4">
                <span class="black--text font-weight-medium">작성자</span>
                <span class="px-1">&#58;</span>
                <span class="d-inline-block" style="width: 70px;">
                    <v-text-field v-model="allWrtr" color="blue" height="28" ref="info" readonly
                        hide-details outlined dense :key="forceRender"></v-text-field>
                </span>
                <v-btn 
                    class="ml-1 brown lighten-3" 
                    small 
                    min-width="30"
                    height="26"  
                    style="padding:0px 1px 0px 2px !important"
                    @click="onModal(1,0)"
                    >
                    <v-icon size="20" class="white--text">mdi-account-edit</v-icon>
                </v-btn>
            </div>   
        </div>        
        <div class="mx-2 mt-2">
            <v-data-table 
                class=""
                ref="dctrTbl"
                locale="ko" height="570"
                :headers="dctrHeader" 
                :items="dctrList" 
                hide-default-footer dense 
                hide-default-header 
                fixed-header 
                disable-pagination
                :search="schDummy" 
                :custom-filter="customFilter"
                >
                <template v-slot:header="{ props }" >
                    <thead>
                        <tr>                                                        
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 6%">{{props.headers[0].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 5%">{{props.headers[1].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 6%">{{props.headers[2].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 4%">{{props.headers[3].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 7%">{{props.headers[4].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT py-1" style="width: 8%">
                                {{props.headers[5].text}}
                                <v-select 
                                    v-model="frstAllYn"
                                    :items="codeItem2"
                                    item-text="valcdnm"
                                    item-value="valcd"
                                    @change="onAllSelcter(3)"
                                    dense hide-details outlined
                                /> 
                            </th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT py-1" style="width: 8%">                                    
                                {{props.headers[6].text}}
                                <v-select 
                                    v-model="clncFeeClmAllYn"
                                    :items="codeItem1"
                                    item-text="valcdnm"
                                    item-value="valcd"
                                    @change="onAllSelcter(0)"
                                    dense hide-details outlined
                                />                                    
                            </th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT py-1" style="width: 8%">
                                {{props.headers[7].text}}
                                <v-select 
                                    v-model="drugFeeClmAllYn"
                                    :items="codeItem1"
                                    item-text="valcdnm"
                                    item-value="valcd"
                                    @change="onAllSelcter(1)"
                                    dense hide-details outlined
                                /> 
                            </th>  
                            <th class="black--text clmsHeaderCommon tableBR tableBT py-1" style="width: 8%">
                                {{props.headers[8].text}}
                                <v-select 
                                    v-model="dctrFeeClmAllYn"
                                    :items="codeItem1"
                                    item-text="valcdnm"
                                    item-value="valcd"
                                    @change="onAllSelcter(2)"
                                    dense hide-details outlined
                                /> 
                            </th>                                                         
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 15%">{{props.headers[9].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 10%">{{props.headers[10].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBT" style="width: 4%">{{props.headers[11].text}}</th>
                        </tr>  
                    </thead>
                </template>

                <template v-slot:item='{ item }'>
                    <tr class="fixedCol">
                        <td class="fixedCol0 clmsBodyCommon tableBR">{{item.name}}</td>                   
                        <td class="fixedCol0 clmsBodyCommon tableBR">{{item.entStcd}}</td>
                        <td class="fixedCol0 clmsBodyCommon tableBR">{{item.lvnRmNm}}</td>
                        <td class="fixedCol0 clmsBodyCommon tableBR" >
                            <div class="d-flex justify-center align-center"><span :class="'ic-level level0'+item.acknwRtng">{{item.acknwRtng}}</span></div>
                        </td>   
                        <td class="fixedCol0 clmsBodyCommon tableBR">
                            {{item.copayRateNm}}
                        </td>                            
                        <td class="fixedCol0 clmsBodyCommon tableBR">
                            <div class="d-flex justify-center align-center py-1">                                    
                                <div style="width:100px;">
                                    <v-select 
                                        v-model="item.frstYn"                            
                                        :items="codeItem2"
                                        :key="forceRender"
                                        item-text="valcdnm"
                                        item-value="valcd"
                                        dense hide-details outlined
                                        @change="onFrstSelcter(item)"
                                    />
                                </div>
                            </div>
                        </td>                         
                        <td class="fixedCol0 clmsBodyCommon tableBR">
                            <div class="py-1 ">
                                <div class="d-flex justify-center align-center">
                                    <span class="d-inline-block" style="width: 70px;">                        
                                        <v-text-field v-model="item.clncFee" height="28" hide-details outlined dense></v-text-field>
                                    </span>
                                    <div class="d-inline-block px-1">원</div>
                                </div>
                                <div class="d-flex justify-center align-center mt-1" style="width:100px;">
                                    <v-select 
                                        v-model="item.clncFeeClmYn"                            
                                        :items="codeItem1"
                                        :key="forceRender"
                                        item-text="valcdnm"
                                        item-value="valcd"
                                        dense hide-details outlined
                                    />
                                </div>
                            </div>
                        </td> 
                        <td class="fixedCol0 clmsBodyCommon tableBR">
                            <div class="py-1">                                    
                                <div class="d-flex justify-center align-center">
                                    <span class="d-inline-block" style="width: 70px;">                        
                                        <v-text-field v-model="item.drugFee" height="28" hide-details outlined dense></v-text-field>
                                    </span>
                                    <div class="d-inline-block px-1">원</div>
                                </div>
                                <div class="d-flex justify-center align-center mt-1" style="width:100px;">
                                    <v-select 
                                        v-model="item.drugFeeClmYn"                            
                                        :items="codeItem1"
                                        :key="forceRender"
                                        item-text="valcdnm"
                                        item-value="valcd"
                                        dense hide-details outlined
                                    />
                                </div>
                            </div>
                        </td>        
                        <td class="fixedCol0 clmsBodyCommon tableBR">
                            <div class="py-1">                                    
                                <div class="d-flex justify-center align-center">
                                    <span class="d-inline-block" style="width: 70px;">                        
                                        <v-text-field v-model="item.dctrFee" height="28" hide-details outlined dense></v-text-field>
                                    </span>
                                    <div class="d-inline-block px-1">원</div>
                                </div>
                                <div class="d-flex justify-center align-center mt-1" style="width:100px;">
                                    <v-select 
                                        v-model="item.dctrFeeClmYn"                            
                                        :items="codeItem1"
                                        :key="forceRender"
                                        item-text="valcdnm"
                                        item-value="valcd"
                                        dense hide-details outlined                                            
                                    />
                                </div>
                            </div>
                        </td>                         
                        <td class="fixedCol0 clmsBodyCommon tableBR pa-1">
                            <v-textarea v-model="item.clncCntnt" class="rmrksArea" style="font-size:0.9rem !important;"
                                rows="3" outlined dense hide-details no-resize>
                            </v-textarea>
                        </td>                  
                        <td class="fixedCol0 clmsBodyCommon tableBR">  
                            <div class="d-flex justify-center align-center pa-1">
                                <span class="d-inline-block" style="width: 70px;">
                                    <v-text-field v-model="item.wrtr" color="blue" height="28" ref="info" readonly :rules="[rules.required]"
                                        hide-details outlined dense :key="forceRender"></v-text-field>
                                </span>
                                <v-btn 
                                    class="ml-1 brown lighten-3" 
                                    small 
                                    min-width="30"
                                    height="26"  
                                    style="padding:0px 1px 0px 2px !important"
                                    @click="onModal(0,item.bnMmbrPk)"
                                    >
                                    <v-icon size="20" class="white--text">mdi-account-edit</v-icon>
                                </v-btn>
                            </div>                          
                        </td>
                        <td class="fixedCol0 clmsBodyCommon">
                            <div class="d-flex justify-center align-center pa-1">
                                <v-btn  icon color='grey006' @click="delList(item)">                            
                                    <v-tooltip top >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-if="item.clncRecPk > 0" size="20" v-bind="attrs" v-on="on">mdi-trash-can-outline</v-icon>
                                            <v-icon v-else size="20" v-bind="attrs" v-on="on">mdi-minus-circle-outline</v-icon>
                                        </template>
                                        <span>삭제</span>
                                    </v-tooltip>
                                </v-btn>
                            </div>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </div>
        </v-form>
        <div class="ma-0 pa-0">
            <v-dialog v-model="menu[0]" eager content-class="round" persistent max-width="800">
                    <EmpSelector @isClose="isModal(0)" @outEmp="getEmpInfo" :prtWrtDt = ymd></EmpSelector>
            </v-dialog>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="menu[1]" eager content-class="round" persistent max-width="800">
                <DctrCarerCntrcMdf 
                    @outDct="getDctInfo"
                    @isClose="isModal(1)"></DctrCarerCntrcMdf>
            </v-dialog>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="'진료기록'" @madalState="onDelete"></btnModalDelete>  
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import exBtn from '../../components/commons/expandButtonCustom.vue';
import expandNowDatePicker from '../../components/commons/expandNowDatePicker.vue';
import EmpSelector from '../../components/commons/EmpSelector.vue';
import DctrCarerCntrcMdf from '../../components/bnftrcrd/DctrCarerCntrcMdf.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import { getMultiStcd } from '../../api/index.js';
import { selLvnRmInf } from '../../api/opr.js';
import { selAllClncRecList, insMultiClncRecOutHis, delClncRec} from '../../api/bnftrcrd.js';

export default {
    name: 'DctrCarerCntrcAllWrt',
    
    components: {  
        exBtn,
        expandNowDatePicker,
        EmpSelector,
        DctrCarerCntrcMdf,
        btnModalDelete,
    },

    props : {
        dctrCarerCntrcAllPk:{type:Number, default:0},        
        inqYMD:{type:String, default:''}
    },

    computed: {
        
    },

    created: function(){       
       this.getCode(['65'])   
       this.getLvnRmInf() 
    },

    mounted: function(){
        this.$nextTick(function () {
            if(this.dctrCarerCntrcAllPk > 0){
                this.ymd = this.inqYMD
                this.dctInfPk = 0
                this.hospNm = ''
                this.dctrList.splice(0)
                this.$refs.form.resetValidation()
            }
        })
    },

    watch: {
        'dctrCarerCntrcAllPk':function(value){
            if(value>0){         
                this.ymd = this.inqYMD
                this.dctInfPk = 0
                this.hospNm = ''
                this.dctrList.splice(0)
                this.$refs.form.resetValidation()
            }
        }
    },

    methods: {  
        getDate: function (value) {            
            this.ymd = value.date  
            if(this.dctInfPk > 0) this.getAllClncRecList()
        },
        getAllClncRecList: function () {   
            
            this.allWrtr = ''
            this.clncFeeClmAllYn = ''
            this.drugFeeClmAllYn = ''
            this.dctrFeeClmAllYn = ''
            this.frstAllYn = ''

            let obj = {
                fcltyNum:this.$store.getters.getFcltyNum,
                clncClcd:'1',
                dctInfPk:this.dctInfPk,
                inqYMD:this.ymd
            }    

            selAllClncRecList(obj)
                .then((response) => ( this.getAllClncRecListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/selAllClncRecList : ' + error))
        },
        getAllClncRecListAfter:function(res){ 
            this.dctrList.splice(0)
            res.forEach(el => {
                this.cdItems.forEach((cd) => {
                    if(el.entStcd == cd.valcd) el.entStcd = cd.valcdnm
                });//end foreach

                if(!el.clncFeeClmYn?.isEmptyVal()){
                    el.clncFeeClmYn = ''
                }
                if(!el.drugFeeClmYn?.isEmptyVal()){
                    el.drugFeeClmYn = ''
                }
                if(!el.dctrFeeClmYn?.isEmptyVal()){
                    el.dctrFeeClmYn = ''
                }
                if(!el.frstYn?.isEmptyVal()){
                    el.frstYn = ''
                }                
                
                if(el.clncRecPk == null){
                    el.frstYn = el.frstClncYn

                    if(el.frstClncYn == '1'){                        
                        el.dctrFee = this.setDctrFeeCalc(el.frstClncFee,el.copRto)
                    }else{
                        el.dctrFee = this.setDctrFeeCalc(el.calcClncFee,el.copRto)
                    }

                    el.clncCntnt = this.setClncCntnt(el.frstYn)
                }
                
                this.dctrList.push(el)
            });           

            this.$refs.form.resetValidation()
        },

        onClickBtn:function(text){            
            if(text == "전일자료") { 
                
                
                let date = new Date(this.ymd?.beforeDateFormatHyp())
                let dateArr = new Date(date.setDate(date.getDate()-1)).toISOString().split('T',2)
                date = dateArr[0]?.afterDateFormatHyp()                
                this.preymd = date
                // this.getPreAllHlthVtlRecList()
            } else if(text == "저장") { 
                this.onSave()
            }
        },
        onSave:function(){
            
            if(this.$refs.form.validate()){
                let obj = {
                    list:[],
                }
                
                // this.getSelctList()

                if(this.$refs.dctrTbl != undefined){
                    this.$refs.dctrTbl.selectableItems.forEach(e => {
                        let itm = {
                            clncRecPk:e.clncRecPk,
                            clncClcd:'1',
                            clncDt:this.ymd.afterDateFormatHyp(),
                            outHisPk:e.outHisPk,
                            dctInfPk:this.dctInfPk,
                            hospNm:this.hospNm,
                            clncCntnt:e.clncCntnt,
                            clncFee:e.clncFee,
                            clncFeeClmYn:e.clncFeeClmYn,
                            drugFee:e.drugFee,
                            drugFeeClmYn:e.drugFeeClmYn,
                            frstYn:e.frstYn,
                            dctrFee:e.dctrFee,
                            dctrFeeClmYn:e.dctrFeeClmYn,
                            outHisYn:e.outHisYn,
                            wrtr:e.wrtr,
                            aplcn:e.aplcn,
                            wrtrMmbrPk:e.wrtrMmbrPk,
                            outTmStrt:e.outTmStrt,
                            outTmEnd:e.outTmEnd,
                            rltns:e.rltns,
                            tel_1:e.tel_1,
                            tel_2:e.tel_2,
                            tel_3:e.tel_3,
                            fcltyNum:this.$store.getters.getFcltyNum,
                            bnMmbrPk:e.bnMmbrPk,
                            userNm:this.$store.getters.getUserNm,
                            aplctDt:this.ymd.afterDateFormatHyp(),
                        }
                        obj.list.push(itm)
                    });

                    insMultiClncRecOutHis(obj)
                        .then((response) => ( this.onSaveAfter(response.data) ))
                        .catch((error) => console.log('upload error /bnftrcrd/insMultiClncRecOutHis : ' + error))
    
                    
                }
            }
        },
        onSaveAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.getAllClncRecList()
                this.$emit('modelEvent')                
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        delList:function(obj){
            if(obj.clncRecPk>0){               
                this.targetObj = Object.assign(obj)
                this.dialogDelete = true
            }else{
                obj.hospNm          = ''
                obj.dctInfPk        = 0
                obj.clncCntnt       = ''
                obj.clncFee         = ''
                obj.clncFeeClmYn    = ''
                obj.drugFee         = ''
                obj.drugFeeClmYn    = ''
                obj.dctrFee         = ''
                obj.dctrFeeClmYn    = ''
                obj.frstYn          = ''
                obj.wrtr          = ''
            }
        },
        //기록 DB 삭제
        onDelete:function(res){

            if(res == 9990){
                let clncRecPk = this.targetObj.clncRecPk
                if(clncRecPk > 0){
                    let obj = {
                        fcltyNum: this.$store.getters.getFcltyNum,
                        bnMmbrPk : this.targetObj.bnMmbrPk,
                        userNm : this.$store.getters.getUserNm,
                        clncRecPk : this.targetObj.clncRecPk,
                        outHisPk : null
                    }
                    delClncRec(obj)
                        .then((response) => ( this.onDeleteAfter(response.data)))
                        .catch((error) => console.log('upload error /bnftrcrd/delHlthVtlRec : ' + error))
                }
            }else if(res == 7){
                this.dialogDelete = false
            }

        },
        onDeleteAfter:function(res){           
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.getAllClncRecList()
                this.dialogDelete = false
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onClose:function(){
            this.$emit('isClose')
        },
        expandClose: function (index) {
            switch(index) {
                case 0: this.schChkField.target = 'entStcd'
                    break;
                case 1: this.schChkField.target = 'lvnRmNm'
                    break;
                default: this.schChkField.target = ''
                    break;
            }
        },
        setSearchText: function (value) {
            this.schField = value

            this.schDummy = this.schDummy + '.'
        },
        filterSearch: function (sch) {
            switch(this.schChkField.target) {
                case 'entStcd': this.schChkField.entStcd.splice(0)
                    sch.forEach((item) => { this.schChkField.entStcd.push(item) });
                    break;
                case 'lvnRmNm': this.schChkField.lvnRmNm.splice(0)
                    sch.forEach((item) => { this.schChkField.lvnRmNm.push(item) });
                    break;
            }
          
            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column
            let col0 = filter.name.includes(this.schField)
            
            let col1 = true
            if(this.schChkField.entStcd.length != 0){
                this.schChkField.entStcd.forEach((stcd, i) => {
                    if(i == 0) col1 = filter.entStcd.includes(stcd)
                    else col1 = col1 || filter.entStcd.includes(stcd)
                });
            }

            let col2 = true
            if(this.schChkField.lvnRmNm.length != 0){
                this.schChkField.lvnRmNm.forEach((stcd, i) => {
                    if(i == 0) col2 = filter.lvnRmNm.includes(stcd)
                    else col2 = col2 || filter.lvnRmNm.includes(stcd)
                });
            }

            return col0 && col1 && col2;
        },
        getLvnRmInf: function () {
            selLvnRmInf(this.$store.getters.getFcltyNum)
                .then((response) => ( this.getLvnRmInfAfter(response.data) ))
                .catch((error) => console.log('connect error /opr/selLvnRmInf : ' + error))
        },

        getLvnRmInfAfter: function (res){
            this.lvnRmInf = res

            this.lvnRmInf.forEach((code) => {
                this.exBtn[1].expandList.push(code.lvnRmNm)
            });//end foreach
        },
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        stCdMapping: function (res){

            this.cdItems = res.vCd65

            res.vCd65.forEach((code) => {
                this.exBtn[0].expandList.push(code.valcdnm)
            });//end foreach
        },
        //작업자선택
        onModal: function (key, idx) {  
            this.key = key
            this.idx = idx
            this.menu.splice(0, 1, true)
        },
        isModal: function (key) {
            this.menu.splice(key, 1, false)
        },   
        getEmpInfo: function (obj) {
            if(this.key == 0){
                let target = this.dctrList.filter(x=> x.bnMmbrPk == this.idx)
                target[0].wrtr = obj[0].name
            }else{
                this.allWrtr = obj[0].name
                this.dctrList.forEach(el => {
                    el.wrtr = obj[0].name
                });
            }

            this.key = 0
            this.idx = 0
        },
        onFrstSelcter:function(obj){
            obj.clncCntnt = this.setClncCntnt(obj.frstYn)
        },
        setClncCntnt:function(frstYn){
            let frstYnNm = ''

            if(frstYn == '1') frstYnNm='초진'
            else if(frstYn == '2') frstYnNm='재진'

            let clncCntnt = '계약의사진료('+this.hospNm+' '+frstYnNm+')'

           return clncCntnt
        },
        onAllSelcter:function(key){
            this.dctrList.forEach(el => {
                
                switch (key) {
                    case 0:
                        el.clncFeeClmYn = this.clncFeeClmAllYn
                        break;
                    case 1:
                        el.drugFeeClmYn = this.drugFeeClmAllYn
                        break;
                    case 2:
                        el.dctrFeeClmYn = this.dctrFeeClmAllYn
                        break;
                    case 3:
                        el.frstYn = this.frstAllYn
                        break;
                    default:
                        break;
                }

                if(key == 3) el.clncCntnt = this.setClncCntnt(el.frstYn)
            });
            
            ++this.forceRender
        },
        getSelctList:function(){            
            this.selctList.splice(0)
            this.dctrList.forEach(el => {
                let bl1 = true
                let bl2 = true
                let bl3 = true

                if(this.schChkField.lvnRmNm.length > 0){
                    if(this.schChkField.lvnRmNm.indexOf(el.lvnRmNm) == -1) bl1 = false
                }

                if(this.schChkField.entStcd.length > 0){
                    if(this.schChkField.entStcd.indexOf(el.entStcd) == -1) bl2 = false
                }
                
                if(this.schField != ''){
                    if(!el.name.includes(this.schField)) bl3 = false
                }

                if(bl1 && bl2 && bl3) this.selctList.push(el.bnMmbrPk)
            })             
        },
        getDctInfo:function(obj){            
            this.dctInfPk = obj.dctInfPk
            this.hospNm = obj.hospNm
            this.getAllClncRecList()
        },
        //진찰비 계산
        setDctrFeeCalc:function(clncFee,copRto){
            let dctrFee = Number(clncFee)*Number(copRto)/100
            dctrFee = Math.floor(dctrFee/10)*10 //100단위 버림
            return dctrFee
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    data: () => ({          
        dctInfPk:0, 
        hospNm:'',
        forceRender:0,        
        targetObj:{},
        key:0,
        idx:0,
        allWrtr:'',
        menu:[false,false,],
        clncFeeClmAllYn:'',
        drugFeeClmAllYn:'',
        dctrFeeClmAllYn:'',
        frstAllYn:'',
        codeItem1:[ { valcd: '', valcdnm: '선택' },{ valcd: '1', valcdnm: '청구' }, { valcd: '2', valcdnm: '미청구' }],
        codeItem2:[ { valcd: '', valcdnm: '선택' },{ valcd: '1', valcdnm: '초진' }, { valcd: '2', valcdnm: '재진' }],
        routerRocation: '',
        ymd:'',  
        preymd:'',
        cdItems:[],
        lvnRmInf:[],
        schField: '',
        schDummy: '', 
        dctrHeader:[
            { text: '수급자', align: 'center'},            
            { text: '현황', align: 'center'},
            { text: '생활실', align: 'center'},
            { text: '등급', align: 'center'},
            { text: '본인부담률', align: 'center'},            
            { text: '초진/재진', align: 'center'},
            { text: '진료비', align: 'center'},
            { text: '약제비', align: 'center'},
            { text: '진찰비', align: 'center'},
            { text: '진료내용', align: 'center'},
            { text: '작성자', align: 'center'},
            { text: '처리', align: 'center'},
        ],
        dctrList:[],
        selctList:[],
        schChkField: { entStcd: [], lvnRmNm: [], target: '' },
        exBtn: [
            { index:  0, btnTitle: '입소현황', btnIcon: 'house', exWidth: 104, exHeigth: 130, expandList: [], },
            { index:  1, btnTitle: '생활실', btnIcon: 'bed', exWidth: 124, exHeigth: 300, expandList: [], },
        ],
        filledBtn: [
            // { icon: 'mdi-text-box-search-outline', class: 'white--text', color:'#3C9099', text: '전일자료', type: 'action', visible: true, disabled: false, },
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장',type: 'action', visible: true,disabled: false, },                        
        ],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
        snackOptions: [false, 3000, '', 'info'],   
        dialogDelete:false,
    }),
};
</script>