<template>
    <v-sheet class="rounded-lg rounded-l-0" color="" min-width="900px" max-width="900px">
        <div class="d-flex justify-end pl-1 pr-3 pt-2">
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn v-if="list.type == 'action' && list.visible" @click = "onClickBtn(list.text)"
                    :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                    <v-icon small>{{list.icon}}</v-icon>
                    <span style="font-size: 1rem;">{{list.text}}</span>
                </v-btn>
            </span>
            <!-- <v-btn v-for="(list, j) in outlinedBtn" :key="filledBtn.length + j"
                :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded outlined>
                <v-icon small>{{list.icon}}</v-icon>
                <span style="font-size: 1rem;">{{list.text}}</span>
            </v-btn> -->
        </div>
        <v-divider class="my-2"></v-divider>
        <basicInfo class="pl-2 pr-2" :bnMmbrPk="$parent.$parent.sltRow.bnMmbrPk"></basicInfo> 

        <div class="px-2 mt-3" >
            <v-tabs
                v-model="ftab" class="tab-dft-Theme" active-class="black"
                center-active hide-slider>
                <v-tab v-for="item in ftabItems" :key="item.tab" :class="item.class">{{item.tab}}</v-tab>
            </v-tabs>
            <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
            <div class="overflow-y-auto" style="height:350px;">
                <v-tabs-items v-model="ftab">
                    <v-tab-item transition="fade-transition">
                        <PhyscThrpyInspcInf
                            ref="physcThrpyInspcInf" 
                            v-if="ftab==0"
                            :bnMmbrPk="$parent.$parent.sltRow.bnMmbrPk"
                            :bnfcrPk="$parent.$parent.sltRow.bnfcrPk"
                            :inqYYYY="$parent.$parent.inqYYYY"
                        ></PhyscThrpyInspcInf>
                    </v-tab-item>
                    <v-tab-item transition="fade-transition">
                        <PhyscThrpyInspcRst 
                            ref="physcThrpyInspcRst"
                            v-if="ftab==1"
                            :bnMmbrPk="$parent.$parent.sltRow.bnMmbrPk"
                            :bnfcrPk="$parent.$parent.sltRow.bnfcrPk"
                            :inqYYYY="$parent.$parent.inqYYYY"
                            @isPhyscThrpyEvltn="onPhyscThrpyEvltn"
                        ></PhyscThrpyInspcRst>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </div>

        <div class="px-2 mt-3">
            <v-tabs
                v-model="ptab" class="tab-dft-Theme" active-class="black"
                center-active hide-slider>
                <v-tab v-for="item in ptabItems" :key="item.tab" :class="item.class">{{item.tab}}</v-tab>
            </v-tabs>
            <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
            <div class="overflow-y-auto" style="height:250px;">
                <v-tabs-items v-model="ptab">
                    <v-tab-item transition="fade-transition">                        
                        <PhyscThrpyPlnInf 
                            ref="physcThrpyPlnInf"
                            v-if="ptab==0"
                            :bnMmbrPk           ="$parent.$parent.sltRow.bnMmbrPk"
                            :bnfcrPk            ="$parent.$parent.sltRow.bnfcrPk"
                            :inqYYYY            ="$parent.$parent.inqYYYY"
                            :bnftOfrPlnHisPk    ="$parent.$parent.sltRow.bnftOfrPlnHisPk"
                            :phyThrPlnPk        ="$parent.$parent.sltRow.phyThrPlnPk"
                            :cd37="$parent.$parent.vCd37"
                            :cd92="$parent.$parent.vCd92"
                            :cd93="$parent.$parent.vCd93"                            
                        ></PhyscThrpyPlnInf>
                    </v-tab-item>
                    <v-tab-item transition="fade-transition">
                        <PhyscThrpyPlnRst 
                            ref="physcThrpyPlnRst"
                            v-if="ptab==1"
                            :bnMmbrPk           ="$parent.$parent.sltRow.bnMmbrPk"
                            :bnfcrPk            ="$parent.$parent.sltRow.bnfcrPk"
                            :inqYYYY            ="$parent.$parent.inqYYYY"  
                            @isPhyscThrpyPln="onPhyscThrpyPln"                        
                        ></PhyscThrpyPlnRst>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </div>

        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[0]" persistent max-width="1400" min-width="1400">                
                <PhyscThrpyEvltnComp 
                    v-if="dialog[0]"
                    ref="physcThrpyEvltn"
                    :bnMmbrPk="$parent.$parent.sltRow.bnMmbrPk"
                    :inqYYYY="$parent.$parent.inqYYYY"
                    :physcThrpyEvltnPk="physcThrpyEvltnPk"
                    @modalEvent="modalEvent"></PhyscThrpyEvltnComp>
            </v-dialog>
        </div>  
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[1]" persistent max-width="1470" min-width="1470">                
                <PhyscThrpyPlnComp
                    v-if="dialog[1]"
                    ref="physcThrpyPln"
                    :bnMmbrPk="$parent.$parent.sltRow.bnMmbrPk" 
                    :bnfcrPk="$parent.$parent.sltRow.bnfcrPk"
                    :inqYYYY="$parent.$parent.inqYYYY"
                    :physcThrpyPlnPk="physcThrpyPlnPk"
                    @modalEvent="modalEvent"></PhyscThrpyPlnComp>
            </v-dialog>
        </div>       
    </v-sheet>
</template>

<script>
import basicInfo from '../../components/bnfcr/BnfcrBasicInfo.vue';
import PhyscThrpyEvltnComp from '../../components/bnftSrv/PhyscThrpyEvltn.vue';
import PhyscThrpyPlnComp from '../../components/bnftSrv/PhyscThrpyPln.vue';
import PhyscThrpyInspcInf from '../../components/bnftSrv/PhyscThrpyInspcInf.vue';
import PhyscThrpyInspcRst from '../../components/bnftSrv/PhyscThrpyInspcRst.vue';
import PhyscThrpyPlnInf from '../../components/bnftSrv/PhyscThrpyPlnInf.vue';
import PhyscThrpyPlnRst from '../../components/bnftSrv/PhyscThrpyPlnRst.vue';

export default {
    name: 'PhyscThrpyInspcPln',
    
    components: {
        basicInfo,
        PhyscThrpyEvltnComp,
        PhyscThrpyPlnComp,
        PhyscThrpyInspcInf,
        PhyscThrpyInspcRst,
        PhyscThrpyPlnInf,
        PhyscThrpyPlnRst
    },

    computed: {
        getSelectedItem(){
            return this.$parent.$parent.sltRow
        },
    },

    watch: {
        getSelectedItem: {
            deep: true,
            handler: function (value){
                if(value.bnMmbrPk == -1)
                    this.rstData()
                else{
                    //버튼 초기화
                    this.onChkBtn()
                    this.physcThrpyEvltnPk  =  this.$parent.$parent.sltRow.bnfcrExamHisPk
                    this.physcThrpyPlnPk    =  this.$parent.$parent.sltRow.bnftOfrPlnHisPk
                }
            },
        },
    },

    created: function(){
        
    },

    mounted: function(){
       
    },

    methods: {
        onClickBtn: function(text) {              
            if(text == '물리치료평가'){                
                this.dialog.splice(0, 1, true) 
            }else if(text == '물리치료계획') {
                this.dialog.splice(1, 1, true) 
            }
        },        

        onChkBtn:function(){     
                 
            if(this.$parent.$parent.sltRow.bnMmbrPk != 0){
                this.filledBtn[0].disabled = false
                this.filledBtn[1].disabled = false
                // this.outlinedBtn[0].disabled = false
                // this.outlinedBtn[1].disabled = false
            }else{
                this.filledBtn[0].disabled = true
                this.filledBtn[1].disabled = true
                // this.outlinedBtn[0].disabled = true
                // this.outlinedBtn[1].disabled = true
            }
        },
        
        rstData:function(){
            this.physcThrpyEvltnPk  = -1
            this.physcThrpyPlnPk    = -1
        },

        modalEvent: function (event) { 
            if(event == 99){
                this.reload()
            }else if(event == 5){
                this.dialog.splice(0, 1, false)
            }else if(event == 6){
                this.dialog.splice(1, 1, false)
            }
        },

        //리로드
        reload:function(){
            if(this.ftab == 0){
                this.$refs.physcThrpyInspcInf.reload()
            } else if(this.ftab == 1){
                this.$refs.physcThrpyInspcRst.reload()
            }

            if(this.ptab == 0){
                this.$refs.physcThrpyPlnInf.reload()
            } else if(this.ptab == 1){
                this.$refs.physcThrpyPlnRst.reload()
            }
        },

        onPhyscThrpyEvltn:function(pk){
            this.physcThrpyEvltnPk = pk
            this.dialog.splice(0, 1, true)
        },

        onPhyscThrpyPln:function(pk){
            this.physcThrpyPlnPk = pk
            this.dialog.splice(1, 1, true)
        }
    },

    data: () => ({
        dialog              : [false,false],        
        stab                : 0,
        physcThrpyEvltnPk   :-1,
        physcThrpyPlnPk     : -1,
        filledBtn       : [            
            { icon: 'mdi-text-box-edit', class: 'ml-1 white--text', color:'blue004', text: '물리치료평가', type: 'action', width:'1100px', visible: true, disabled: true, },
            { icon: 'mdi-text-box-edit', class: 'ml-1 white--text', color:'blue004', text: '물리치료계획', type: 'action', width:'1100px', visible: true, disabled: true, },
        ],
         outlinedBtn    : [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006 ', text: '양식 출력', disabled: true, },
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '평가내용 출력', disabled: true, },
        ],
        ftab            : 0,
        ftabItems        : [
            { index: 0, tab: '평가정보', class: 'fontOneDotTwoRem px-15',},
            { index: 1, tab: '평가내역', class: 'fontOneDotTwoRem px-15',},
        ],
        ptab            : 0,
        ptabItems        : [
            { index: 0, tab: '계획정보', class: 'fontOneDotTwoRem px-15',},
            { index: 1, tab: '계획내역', class: 'fontOneDotTwoRem px-15',},
        ],
    }),
};
</script>