<template>
    <v-sheet class="bathSchdl rounded-lg rounded-l-0" color="" min-width="1260" max-width="1560" height="835">
        <div class="px-2 mt-2 mb-2 mr-2">
            <div class="text-h4 d-flex align-center">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">2-5. 목욕일정</span>
                <v-spacer></v-spacer>                
                <span class="d-inline pr-2" v-for="(list, i) in filledBtn" :key="i">
                    <v-btn v-if="list.type == 'action' && list.visible" @click = "onClickBtn(list.text)"
                        :class="list.class" height="30" :color="list.color" rounded
                        style="font-size: 14px;">
                        <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                        {{list.text}}
                    </v-btn>
                </span>
                <span class="d-inline" v-for="(list, j) in outlinedBtn" :key="filledBtn.length + j">
                    <v-btn :disabled="list.disabled"
                        :class="list.class" samll height="30" :color="list.color" rounded outlined @click ="onClickBtn(list.text)">
                        <v-icon small>{{list.icon}}</v-icon>
                        <span style="font-size: 1rem;">{{list.text}}</span>
                    </v-btn>
                </span>
            </div>
        </div>
        <expandWeekOnlyPicker :prtDate="''" @nowWeek = "getWeek" class="mt-2"></expandWeekOnlyPicker>
        <v-form ref="form" lazy-validation>
        <div class="pa-2">            
            <div class="d-flex pa-0 ma-0" no-gutters 
                style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">            
                <div v-for="(item, i) in dayitems" :key="i" :class="{left:i!=0}" style="width:14.3%;">                                
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0">
                        <span class="black--text font-weight-medium">{{item.date}}</span>
                        <span class="black--text font-weight-medium">&#40;{{item.day}}&#41;</span>                        
                        <v-btn icon @click="showDialog(i)">
                            <v-tooltip bottom >
                                <template v-slot:activator="{ on, attrs }">                                    
                                    <v-icon class="orange--text" v-bind="attrs" v-on="on">mdi-account-multiple</v-icon>
                                </template>
                                <span>수급자추가</span>
                            </v-tooltip>
                        </v-btn>
                    </div>
                    <div :class="['', (item.date == today)?'green lighten-5':'']" style="min-height:650px;max-height:690px;border-top: 1px solid #dbdbdb !important;overflow-y:auto;">
                        <div v-for="(mmbr, j) in item.list" :key="j" class="py-1 px-2">                            
                            <v-chip                              
                                label
                                close                           
                                color="blue"  
                                style="width:100%;height:43px !important;"                                
                                @click:close="delMmbr(mmbr,i,j)"  
                                :key="forceRender"                                
                                outlined>
                                <span class="font-weight-medium" style="width:55px;margin-top:1px !important">{{mmbr.name}}</span>
                                <span style="width: 70px; padding: 0 2px;">
                                    <CmmTimeField v-model="mmbr.bathTm" :required="true"></CmmTimeField>
                                </span>                                
                            </v-chip>
                        </div>
                    </div>
                </div>
            </div>            
        </div>       
        </v-form>
        <!-- <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[outlinedBtn.length]" max-width="700" content-class="round" persistent>           
                <BnfcrOnlySelector
                    v-if="dialog[outlinedBtn.length]"
                    :fixList="exclsList"
                    :cd41="vCd41"
                    :cd42="vCd42"
                    :cd65="vCd65"
                    :lvnRmList="lvnRmInf"
                    :prtWrtDt = "prtWrtDt"
                    @intgrResList="getAplyList" @onClose="isDialog(outlinedBtn.length)">
                </BnfcrOnlySelector>
            </v-dialog>
        </div> -->

        <v-dialog v-model="dialog[outlinedBtn.length]" max-width="700" content-class="round" persistent eager>
            <!-- 
                mdlTtl: 팝업 타이틀(누락시 = 참석자 선택), tabInfList: 활성화 탭 배열 ( '1': 수급자, '2': 직원 ),
                cd41, cd42: 직원 탭에서 사용하는 공통코드 (필수), intgrResList: Array - 팝업에서 선택된 목록
                mode: String - 단일 / 멀티 선택 ( '1': 단일, '2': 멀티, default: '2' )
                fixList: 선택을 제한할 목록, onClose: String - 결과 값 0 고정
                * fixList: 오브젝트에 다음 키를 추가 해야함 ( disChekr:boolean, checkr:String )
                ** disChekr: 체크 가능여부, checkr: 체크박스 모델
             -->
            <IntgrSlctn
                :mdlTtl="'수급자 선택'" :tabInfList="['1']"
                :fixList="exclsList" :mode="'2'" :rtrInc="true" :rtrTyp="'1'"
                :cd41="vCd41"
                :cd42="vCd42"
                :cd65="vCd65"
                :prtWrtDt = "prtWrtDt"
                @intgrResList="getAplyList" @onClose="isDialog(outlinedBtn.length)">
            </IntgrSlctn>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500">    
            <btnModalDelete :title="'목욕일정'" @madalState="delMmbrCfrm"></btnModalDelete>  
        </v-dialog>
        <v-snackbar
            v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
            :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
            <div style="text-align: center;">
                <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
            </div>
        </v-snackbar> 
    </v-sheet>
</template>

<script>
import { getMultiStcd } from '../../api/index.js';
import { selLvnRmInf, } from '../../api/opr.js';
import { selBathSchdlPlnList, insBathSchdlPln, delBathSchdlPlnRec } from '../../api/bnftSrv.js';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import expandWeekOnlyPicker from '../../components/commons/expandWeekOnlyPicker.vue';
import CmmTimeField from '../../components/commons/CmmTimeField.vue';
import IntgrSlctn from '../../components/commons/IntgrSlctn.vue';
export default {
    name: 'BathSchdl',
        
    components: {
        expandWeekOnlyPicker,
        btnModalDelete,
        CmmTimeField,
        IntgrSlctn,
    },

    created: function(){
        this.onCodeload() 
        this.getLvnRmInf()
        this.getToday()
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            
        })
    },

    computed: {

    },

    watch:{ 
        'startDay':function(){
            this.onShow()
        }
    },
        
    methods: {
        onCodeload:function(){
            getMultiStcd(['41', '42', '65'])
                .then((response) => ( this.codeMapping(response.data) ))
                .catch((error) => console.log(error))
        },

        codeMapping:function(res){
            this.vCd41 = res.vCd41
            this.vCd42 = res.vCd42
            this.vCd65 = res.vCd65
        },
        getToday:function(){
            let date = new Date()
            this.today = date.getFullYear()+'.'+('00'+(date.getMonth()+1).toString()).slice(-2)+'.'+('00'+(date.getDate()).toString()).slice(-2)
        },
        onShow:function(){
            this.getPreData = true
            this.filledBtn[0].visible = true
            this.setDays()
            this.getBathSchdlPlnList()
        },
        setDays(){
            this.dayitems = []     
            for(let i=0;i<7;i++){
                let date = new Date(this.startDay.beforeDateFormatHyp());
                let arrDate = new Date(date.setDate(date.getDate()+i)).toISOString().split('T',2)
                let fdate = arrDate[0].afterDateFormatHyp().beforeDateFormatDot()
                let obj = {
                    day:this.days[i],
                    date:fdate,
                    idx:arrDate[0].afterDateFormatHyp(),
                    list:[],
                }                
                this.dayitems.push(obj)
            }
        },
        onforce(){
            ++this.forceRender
        },
        getWeek : function(ws, we){            
            this.startDay = ws
            this.endDay = we
            this.onShow()
        },
        getBathSchdlPlnList:function(){
            selBathSchdlPlnList(this.$store.getters.getFcltyNum, this.startDay, this.endDay)
                .then((response) => ( this.getBathSchdlPlnListAfter(response.data) ))
                .catch((error) => console.log('connect error bnftoffer/selBathSchdlPlnList : ' + error))
        },
        getBathSchdlPlnListAfter:function(res){
            this.dayitems.forEach(el => {
                el.list = res.list.filter(x=>x.bathDt === el.idx)

                if(el.list.length > 0){
                    if(this.filledBtn[0].visible) this.filledBtn[0].visible = false
                    if(this.getPreData) this.getPreData = false
                }
            });
        },  
        getPreBathSchdlPlnList:function(){
            let preStartDay = this.$moment(this.startDay.beforeDateFormatMonthDHyp(), 'YYYY-MM-DD').subtract(7, 'day').format('YYYYMMDD')            
            let preEndDay = this.$moment(this.endDay.beforeDateFormatMonthDHyp(), 'YYYY-MM-DD').subtract(7, 'day').format('YYYYMMDD')
            selBathSchdlPlnList(this.$store.getters.getFcltyNum, preStartDay, preEndDay)
                .then((response) => ( this.getPreBathSchdlPlnListAfter(response.data) ))
                .catch((error) => console.log('connect error bnftoffer/selBathSchdlPlnList : ' + error))
        },
        getPreBathSchdlPlnListAfter:function(res){
            this.dayitems.forEach(el => {
                let preidx = this.$moment(el.idx.beforeDateFormatMonthDHyp(), 'YYYY-MM-DD').subtract(7, 'day').format('YYYYMMDD')
                el.list = res.list.filter(x=>x.bathDt === preidx)

                el.list.forEach(itm => {
                    itm.bathDt = el.idx
                });
            });  
        },  
        getLvnRmInf: function () {
            selLvnRmInf(this.$store.getters.getFcltyNum)
                .then((response) => ( this.getLvnRmInfAfter(response.data) ))
                .catch((error) => console.log('connect error /opr/selLvnRmInf : ' + error))
        },

        getLvnRmInfAfter: function (res){
            this.lvnRmInf = res
        },

        onSave:function(){
            let list = []
            if(this.$refs.form.validate()){
                this.dayitems.forEach(x=>{
                    x.list.forEach(k=>{
                            
                        let item = {
                            bathDt:k.bathDt,
                            bathSchdlPk:k.bathSchdlPk,
                            bathTmHh:'',
                            bathTmMm:'',
                            bnMmbrPk:k.bnMmbrPk,
                            name:k.name,
                        }


                        if(k.bathTm.isEmptyVal()){
                            let bathTm = k.bathTm.split(":")
                            item.bathTmHh = bathTm[0]
                            item.bathTmMm = bathTm[1]                            
                        }

                        //이전자료일 경우
                        if(this.getPreData) item.bathSchdlPk = 0


                        list.push(item)
                    })
                })
                insBathSchdlPln(this.$store.getters.getFcltyNum, this.$store.getters.getUserNm, this.startDay, this.endDay, list)
                    .then((response) => ( this.onSaveAfter(response.data) ))
                    .catch((error) => console.log('connect error bnftoffer/insBathSchdlPln : ' + error))
                
            }else{
                this.snackControll([true, 2500, '필수항목을 입력해주세요.', 'warning'])
            }
        },
        onSaveAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.onShow()  
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        }, 
        onClickBtn:function(text){
            if(text=="이전자료") this.getPreBathSchdlPlnList()
            else if(text=="저장") this.onSave()
            else if(text=="삭제") this.onDel()
            else if(text=="목욕일정 출력") this.prtBathSchdl()
        },
        showDialog:function(key){                         
            if(this.dayitems[key].idx != undefined) this.prtWrtDt = this.dayitems[key].idx
            else this.prtWrtDt = ''

            this.key = key
            this.getMmbr(key)
            this.dialog.splice(this.outlinedBtn.length, 1, true)
        },
        isDialog: function (key) { 
            this.dialog.splice(key, 1, false)
        },
       
        getMmbr:function(key){
            this.exclsList.splice(0)
            this.dayitems[key].list.forEach(x=>{
                
                let obj = {                    
                    name:x.name,
                    mmbrpk:x.bnMmbrPk,
                    disChekr:true,
                    checkr:'1'
                }
                this.exclsList.push(obj)
            })
        },
        
         getAplyList: function (bnfcrs){

            this.exclsList.splice(0)
            
            bnfcrs.forEach(x => {
                let obj = {
                    bathDt:this.dayitems[this.key].idx,
                    bathTm:'',
                    bathTmHh:'',
                    bathTmMm:'',
                    bnMmbrPk:x.bnMmbrPk,
                    name:x.name,
                    bathSchdlPk:0,
                }
                
                this.dayitems[this.key].list.push(obj)
            });

        },

        setBnfcr(bnfcrs){
            let date = 'C'+this.dayitems[this.key].idx
            let arr = this.groups[date]

            if(arr != undefined){
                bnfcrs.forEach(x => {
                    let obj = {
                        bathDt:this.dayitems[this.key].idx,
                        bathTm:'',
                        bathTmHh:'',
                        bathTmMm:'',
                        bnMmbrPk:x.bnMmbrPk,
                        name:x.name,
                        bathSchdlPk:0,
                    }
                    this.groups[date].push(obj)
                });
            }else{                
                if(bnfcrs.length > 0){
                    this.groups[date] = new Array()

                    bnfcrs.forEach(x => {
                        let obj = {
                            bathDt:this.dayitems[this.key].idx,
                            bathTm:'',
                            bathTmHh:'',
                            bathTmMm:'',
                            bnMmbrPk:x.bnMmbrPk,
                            name:x.name,
                            bathSchdlPk:0,
                        }
                        this.groups[date].push(obj)
                    });
                    
                }
            }

        },
        delMmbr(mmbr,key,idx){
            this.key = key
            this.idx = idx
            if(mmbr.bathSchdlPk > 0){
                this.trgtMmmbr = Object.assign({},mmbr)                
                this.dialogDelete = true
            }else{
                this.dayitems[key].list.splice(idx, 1)
                this.onforce()
            }
        },
        delMmbrCfrm:function(res){
            if(res == 9990){
                
                if(this.trgtMmmbr.bathSchdlPk > 0){
                    let obj = {
                        fcltyNum: this.$store.getters.getFcltyNum, 
                        bathSchdlPk: this.trgtMmmbr.bathSchdlPk, 
                        userNm:this.$store.getters.getUserNm,
                        bnMmbrPk: this.trgtMmmbr.bnMmbrPk,
                        recDt:this.trgtMmmbr.bathDt,
                        clsfc1Cd:'301',
                        clsfc2Cd:'5',
                        clsfc3CdS:['1','2']
                    }

                    delBathSchdlPlnRec(obj)
                            .then((response) => ( this.delMmbrCfrmAfter(response.data) ))
                            .catch((error) => console.log('connect error bnftoffer/delBathSchdlPlnRec : ' + error))
                }
                
            }else if(res == 7){
                this.dialogDelete = false
            }
        },
        delMmbrCfrmAfter:function(res){
            if(res.statusCode == 200){
                
                if(this.deltype!='all'){                                            
                    this.dayitems[this.key].list.splice(this.idx, 1)
                    this.onforce()
                    this.key = 0
                    this.idx = 0
                }else{
                    this.deltype='sel'
                    this.title = '목욕일정'
                }

                this.snackControll([true, 2500, res.message, 'info'])
                this.onShow()
                this.dialogDelete = false

            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
                this.dialogDelete = false
            }
        },
        onDel:function(){            
            this.title = '기간 목욕일정'
            this.deltype='all'
            this.dialogDelete = true
        },
        snackControll: function (options) {
            this.snackOptions = options
        },

        prtBathSchdl:function(){
            this.prtThrowObj.list[0].bzPk.splice(0)
            this.prtThrowObj.list[0].bzClcd.splice(0)
            this.prtThrowObj.inqYmdStart = this.$moment(this.startDay.beforeDateFormatMonthDHyp(), 'YYYY-MM-DD').format('YYYYMMDD')
            this.prtThrowObj.inqYmdEnd = this.$moment(this.endDay.beforeDateFormatMonthDHyp(), 'YYYY-MM-DD').format('YYYYMMDD')
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
            this.prtThrowObj.bnMmbrPk = ''
            this.prtThrowObj.prtCd = '78'

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
            });
        }
    },
    
    data: () => ({
        getPreData:false,
        today:'',
        prtWrtDt:'',
        trgtMmmbr:{},
        key:0,
        idx:0,
        deltype:'sel',
        forceRender:0,
        startDay:'',
        endDay:'',
        vCd41:[],
        vCd42:[],
        vCd65:[],  
        lvnRmInf:[],
        exclsList:[],      
        xclsList:[],
        title:'목욕일정',
        groups:{},
        days:['일','월','화','수','목','금','토'],
        dayitems:[{day:'',date:'',idx:'',list:[],}],
        dialog: [false, false],
        outlinedBtn: [
            { icon: 'mdi-microsoft-excel', class:'', color:'grey006', text: '목욕일정 출력', width:'1000px', disabled: false, },
        ],
        filledBtn: [                        
            { icon: 'mdi-text-box-search-outline', class: 'white--text', color:'#3C9099', text: '이전자료', type:'action', visible: true,},
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type: 'action', width:'500px', visible: true, disabled: true, },
            // { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type: 'dialogue', width:'500px', visible: true, disabled: true, },
        ],
        snackOptions: [false, 3000, '', 'info'],
        dialogDelete:false,
        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '29', inqYmdStart: '', inqYmdEnd: '',
            rptTyp: '1', mode: '2', list: [ { bzClcd: [], bzPk: [] } ],
        },
    }),
};
</script>
<style>
.bathSchdl .left {border-left: 1px solid #dbdbdb !important;}
.bathSchdl .v-chip .v-icon--right {margin-top: 1px !important;margin-left: 0px !important;} 
</style>