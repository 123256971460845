<template>
    <v-sheet>
        <v-form ref="tabform" lazy-validation>            
            <div class="d-flex">       
                
                <div class="d-inline-block pa-0 ma-0 align-self-start" style="width:700px;">            
                     <v-row class="pa-0 mr-2__5" no-gutters style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;">
                        <v-col class="greyE01 d-flex justify-center align-center py-1 ma-0" cols="3">                                
                            <span class="black--text font-weight-medium">항목</span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center py-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="9">
                          <span class="black--text font-weight-medium">확인</span>
                        </v-col>
                    </v-row>
                    <div class="overflow-y-auto" style="height:434px;">
                    <v-row class="pa-0 ma-0" no-gutters>
                        <v-col class="greyE01 pa-0 ma-0" cols="3"> 
                            <v-row class="pa-0 ma-0" no-gutters>
                                <v-col class="d-flex justify-center align-center py-1 ma-0" cols="5" style="border-bottom: 1px solid #dbdbdb;border-right: 1px solid #dbdbdb !important;">
                                    <div class="black--text font-weight-medium" style="text-align: center;"> 영양</div>
                                </v-col>
                                <v-col class="pa-0 ma-0" cols="7" >
                                    <div v-for="(title, i) in subTles" :key="i">
                                        <div v-if="i == 0" class="d-flex justify-center align-center" style="height:90px;text-align: center; border-bottom: 1px solid #dbdbdb;">
                                            <div class="black--text font-weight-medium" style="text-align: center;">{{title}}</div>
                                        </div>
                                        <!-- <div v-else-if="i == 1 || i == 2" class="d-flex justify-center align-center" style="height:63px;text-align: center; border-bottom: 1px solid #dbdbdb;">
                                            <div class="black--text font-weight-medium" style="text-align: center;">{{title}}</div>
                                        </div> -->
                                        <div v-else class="d-flex justify-center align-center" style="height:63px;text-align: center; border-bottom: 1px solid #dbdbdb;">
                                            <div class="black--text font-weight-medium" style="text-align: center;">{{title}}</div>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col class="pa-0 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="9"> 
                            <v-row class="pa-0 ma-0" no-gutters>                               
                                <v-col class="justify-center align-center pa-0 ma-0" cols="12" >
                                    <div v-for="(item, i) in dsrEvltnList" :key="i">
                                        <div v-if="item.clsfc3Cd == '2' || item.clsfc3Cd == '3'" style="height:63px;border-bottom: 1px solid #dbdbdb;">
                                            <div v-for="(list, i) in item.list" :key="i" class="d-inline-block pl-2">
                                                <div v-if="list.valClcd=='1'" style="width:100px;">
                                                    <v-checkbox
                                                        v-model="list.itmVal"
                                                        class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                        :ripple="false" :label="list.itmCdnm" value="1"
                                                        hide-details>
                                                    </v-checkbox>
                                                </div>
                                                <div v-if="list.valClcd=='2'" class="d-flex" style="width:300px">
                                                    <span>
                                                        <v-checkbox
                                                            v-model="list.itmVal"
                                                            class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                            :ripple="false" :label="list.itmCdnm" value="1"
                                                            hide-details>
                                                        </v-checkbox>
                                                    </span>
                                                    <span>&#40;</span>
                                                    <span class="d-inline-block pa-0 ma-0" style="width:220px">
                                                        <v-text-field v-model="list.evltn" height="28" hide-details outlined dense></v-text-field>
                                                    </span>           
                                                    <span>&#41;</span> 
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else-if="item.clsfc3Cd == '3' || item.clsfc3Cd == '4' || item.clsfc3Cd == '5'">                                            
                                            <div class="" style="height:63px;text-align: center; border-bottom: 1px solid #dbdbdb;">
                                                <v-radio-group v-model="item.clsfc3Val" class="radio-dft-Theme mt-0 pl-2" hide-details row>                                            
                                                    <div v-for="(list, i) in item.list" :key="i">
                                                        <span class="d-inline-block pa-0 ma-0" >
                                                            <v-radio :ripple="false" :label="list.itmCdnm" :value="list.itmCd"></v-radio>
                                                        </span>
                                                        <span v-if="list.valClcd=='2'">&#40;</span>
                                                        <span class="d-inline-block pa-0 ma-0" style="width:220px" v-if="list.valClcd=='2'">
                                                            <v-text-field v-model="list.evltn" height="28" hide-details outlined dense></v-text-field>
                                                        </span>           
                                                        <span v-if="list.valClcd=='2'">&#41;</span>                               
                                                    </div>
                                                </v-radio-group>
                                            </div>
                                        </div>
                                        <div v-else-if="item.clsfc3Cd == '1'">
                                            <div class="py-1" style="height:90px;border-bottom: 1px solid #dbdbdb;">                                        
                                                <v-radio-group v-model="item.clsfc3Val" class="radio-dft-Theme mt-0 pl-2" hide-details row>                                            
                                                    <span v-for="(list, i) in item.list" :key="i">
                                                        <span class="d-inline-block pa-0 ma-0 mr-2" style="width:100px;"><v-radio :ripple="false" :label="list.itmCdnm" :value="list.itmCd"></v-radio></span>
                                                        <span class="px-1">&#58;</span>
                                                        <span class="d-inline-block pa-0 ma-0" style="font-size:0.875rem">{{list.itmDesc}}</span>
                                                    </span>
                                                </v-radio-group>
                                            </div>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>                    
                    </v-row>
                    <div v-for="(item, i) in dsrEvltnList" :key="i">   
                        <v-row v-if="item.clsfc3Cd > 7 && item.clsfc3Cd < 11" class="pa-0 ma-0" no-gutters>
                            <v-col class="greyE01 d-flex justify-center align-center py-1 ma-0" style="border-bottom: 1px solid #dbdbdb !important;" cols="3"> 
                                <span class="black--text font-weight-medium">{{item.clsfc3Cdnm}}</span>
                            </v-col>
                            <v-col class="py-1 ma-0" cols="9" style="border-left: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">                              
                                
                                <div v-if="item.clsfc3Cd == '8'" class="d-inline-block" style="width: 492px;">                                        
                                    <v-radio-group v-model="item.clsfc3Val" class="radio-dft-Theme mt-0 pl-2" hide-details row> 
                                        <v-radio class="pa-0 ma-0 mr-2" :ripple="false" :label="item.list[0].itmCdnm" value="1"></v-radio>                                    
                                        <span class="d-inline-block" style="padding-top:1px">보조기 사용</span>
                                        <span>&#40;</span>
                                        <v-radio class="pa-0 ma-0 mr-2" :ripple="false" :label="item.list[1].itmCdnm" value="2"></v-radio>
                                        <v-radio class="pa-0 ma-0 mr-2" :ripple="false" :label="item.list[2].itmCdnm" value="3"></v-radio>
                                        <v-radio class="pa-0 ma-0 mr-2" :ripple="false" :label="item.list[3].itmCdnm" value="4"></v-radio>                                                                        
                                        <span>&#41;</span>
                                        <v-radio class="pa-0 ma-0 mr-2" :ripple="false" :label="item.list[4].itmCdnm" value="5"></v-radio>
                                        <v-radio class="pa-0 ma-0 mr-2" :ripple="false" :label="item.list[5].itmCdnm" value="6"></v-radio>
                                        <v-radio class="pa-0 ma-0 mr-2" :ripple="false" :label="item.list[6].itmCdnm" value="7"></v-radio>
                                        <span>&#40;</span>
                                        <span class="d-inline-block" style="width:180px">
                                            <v-text-field v-model="item.list[6].evltn" height="28" hide-details outlined dense></v-text-field>
                                        </span>
                                        <span>&#41;</span>
                                    </v-radio-group>                                
                                </div>
                                <div v-else-if="item.clsfc3Cd == '10'" class="d-inline-block" style="width: 492px;">                                        
                                    <div v-for="(list, i) in item.list" :key="i" class="d-inline-block pl-2">
                                        <div v-if="list.valClcd=='1'" style="width:100px;">
                                            <v-checkbox
                                                v-model="list.itmVal"
                                                class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                :ripple="false" :label="list.itmCdnm" value="1"
                                                hide-details>
                                            </v-checkbox>
                                        </div>
                                        <div v-if="list.valClcd=='2'" class="d-flex" style="width:300px">
                                            <span>
                                                <v-checkbox
                                                    v-model="list.itmVal"
                                                    class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                    :ripple="false" :label="list.itmCdnm" value="1"
                                                    hide-details>
                                                </v-checkbox>
                                            </span>
                                            <span>&#40;</span>
                                            <span class="d-inline-block pa-0 ma-0" style="width:220px">
                                                <v-text-field v-model="list.evltn" height="28" hide-details outlined dense></v-text-field>
                                            </span>           
                                            <span>&#41;</span> 
                                        </div>
                                    </div>                                
                                </div>
                                <div v-else>
                                    <v-radio-group v-model="item.clsfc3Val" class="radio-dft-Theme mt-0 pl-2" hide-details row>                                            
                                        <div v-for="(list, i) in item.list" :key="i">
                                            <span class="d-inline-block pa-0 ma-0 mr-2"><v-radio :ripple="false" :label="list.itmCdnm" :value="list.itmCd"></v-radio></span>
                                            <span v-if="list.valClcd=='2'">&#40;</span>
                                            <span class="d-inline-block"  style="width:250px" v-if="list.valClcd=='2'">
                                                <v-text-field v-model="list.evltn" height="28" hide-details outlined dense></v-text-field>
                                            </span>           
                                            <span v-if="list.valClcd=='2'">&#41;</span>                               
                                        </div>
                                    </v-radio-group>
                                </div>
                            </v-col>                    
                        </v-row>           
                    </div>
                    </div>
                </div>

                <div class="d-inline-block pa-0 ml-3 align-self-start" style="width:660px;border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;">            
                    <v-row class="pa-0 ma-0" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="3">                                
                            <span class="black--text font-weight-medium">항목</span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="9">
                          <span class="black--text font-weight-medium">확인</span>
                        </v-col>
                    </v-row>
                    <div v-for="(item, i) in dsrEvltnList" :key="i">  
                        <v-row v-if="item.clsfc3Cd > 10 && item.clsfc3Cd < 16" class="pa-0 ma-0" no-gutters style="border-top: 1px solid #dbdbdb !important;">
                            <v-col class="greyE01 d-flex justify-center align-center py-1 ma-0" cols="3"> 
                                <span class="black--text font-weight-medium">{{item.clsfc3Cdnm}}</span>
                            </v-col>
                            <v-col class="py-1 ma-0" cols="9" style="border-left: 1px solid #dbdbdb !important;">                              
                                <div v-if="item.clsfc3Cd < 15"> 
                                    <v-radio-group v-model="item.clsfc3Val"  class="radio-dft-Theme mt-0 pl-2" hide-details row>                                            
                                        <div v-for="(list, i) in item.list" :key="i">
                                            <v-radio class="d-inline-block pa-0 ma-0 mr-2" :ripple="false" :label="list.itmCdnm" :value="list.itmCd"></v-radio>                                                  
                                            <div class="d-inline-block" v-if="list.valClcd=='2'">
                                                <v-text-field v-model="list.evltn" height="28" hide-details outlined dense></v-text-field>
                                            </div>                                          
                                        </div>
                                    </v-radio-group>
                                </div>
                                <div v-else class="pa-1">
                                    <span v-for="(list, j) in item.list" :key="j">                                                                        
                                        <v-textarea v-model="list.evltn" class="rmrksArea"
                                            rows="4" outlined dense hide-details no-resize>
                                        </v-textarea>
                                    </span>
                                </div>
                            </v-col>                    
                        </v-row>   
                    </div>
                </div>  
            </div>
        </v-form>      
    </v-sheet>
</template>
<script>
import { groupBy } from '../../api/common.js';
import { insBnfcrExamHis} from '../../api/bnftSrv.js';

export default {
    name: 'DsrEvltnTab4',

    props : {
    },

    computed: {
        
    },  

    watch:{
        
    },

    created:function(){
        this.$nextTick(function () { 
        })
    },
    
    mounted: function(){
       
    },
        
    methods: {
        onShow(value){
            this.getData(value);  
        },
        
        onClose: function () {            
            this.$emit('modalClose', this.dsrEvltn)
        },

        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },

        
        setData(data){  
            this.subTles.splice(0)
            this.dsrEvltnList.splice(0)
            let groups = groupBy(data, 'clsfc3Cd')
            
            Object.keys(groups).forEach((key)  => {
                if(key < 6) this.subTles.push(groups[key][0].clsfc3Cdnm)

                let obj = {
                    clsfc3Cd : key,
                    clsfc3Cdnm : groups[key][0].clsfc3Cdnm,
                    clsfc3Val:'',
                    list : groups[key]
                }

                obj.clsfc3Val = this.getRadioData(groups[key])

                this.dsrEvltnList.push(obj)
            }); 
            this.$refs.tabform.resetValidation();
        },

        insBnfcrExam(data){
            
            if(this.$refs.tabform.validate()){
                //수급자평가 내역 setting
                
                let obj = {
                    fcltyNum:this.$store.getters.getFcltyNum,
                    userNm:this.$store.getters.getUserNm,
                    bnMmbrPk:data.bnMmbrPk,
                    bnfcrExamHisPk:data.bnfcrExamHisPk,
                    examClcd:'101',                    
                    examRsncd : data.examRsncd,
                    examDt : data.examDt.afterDateFormatHyp(),
                    wrtr : data.wrtr,
                    examYyyy : data.inqYYYY,
                }
                
                let examList = []
            
                this.dsrEvltnList.forEach(x => {
                    x.list.forEach(item => { 
                        //기타 선택 안될경우 
                        let bl = (item.clsfc3Cd == '15')

                        
                        if(!bl){
                            if(item.itmCd == x.clsfc3Val) item.itmVal = '1'
                            else item.itmVal = '2'

                            if(item.valClcd =='2' && item.itmVal != '1' && !bl) item.evltn=''
                        }


                        examList.push(item)
                    });
                })

                insBnfcrExamHis(obj,examList)
                .then((response) => ( this.insBnfcrExamAfter(response.data)))
                .catch((error) => console.log(error))
            }
        },
        insBnfcrExamAfter: function (res) {
            this.$emit('dsrEvltnTrigger', res)            
        },   
        setRadioData(key){            
            this.items[key].map(x=>{
                if(this.itmVal[key] == x.itmCd){
                    x.itmVal = "1"
                }else{
                    x.itmVal = ""
                }
            })
        },            
        getRadioData(data){     
            let itmVal = ''       
            data.forEach(item => {
                if(item.itmVal == '1') itmVal = item.itmCd
            });

            return itmVal
        }, 
        selExamList:function(){
            //욕구사정평가 setting
            let examList = []

            this.dsrEvltnList.forEach(x => {
                x.list.forEach(item => { 
                    if(item.clsfc3Cd == '2' && item.valClcd =='2' && item.itmVal != '1') item.evltn=''
                    if(item.clsfc3Cd == '3' && item.valClcd =='2' && item.itmVal != '1') item.evltn=''

                    //type != radio
                    let bl = (item.clsfc3Cd == '2' || item.clsfc3Cd == '3' || item.clsfc3Cd == '10' || item.clsfc3Cd == '15' )
                    
                    if(!bl){
                        if(item.itmCd == x.clsfc3Val) item.itmVal = '1'
                        else item.itmVal = '2'

                        if(item.valClcd =='2' && item.itmVal != '1' && !bl) item.evltn=''
                    }


                    examList.push(item)
                });
            })
            
            return examList
        }
    },

    
    
    data: () => ({ 
        obj:{},
        dsrEvltnList:[],
        subTles:[],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
        },
        
    }),
};
</script>