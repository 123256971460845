<template>
    <!-- <v-hover v-slot="{ hover }">
        <v-card class="d-inline">
            <v-img :src="imgSrc" :width="imgWidth">
                <v-expand-transition>
                    <div
                        v-if="hover"
                        class="d-flex justify-center align-center transition-fast-in-fast-out grey v-card--reveal"
                        style="height: 100%;opacity: 0.9;"
                    >
                        <v-btn 
                        icon
                        @click="modelEvent"
                        ><v-icon color="red">mdi-trash-can</v-icon>
                        </v-btn>
                    </div>
                </v-expand-transition>
            </v-img>
        </v-card>    
    </v-hover>   -->
    <v-card class="" style="border: 1px solid #dbdbdb; padding: 1px;" width="100%" elevation="0">
        <div style="position: relative;" @mouseover="cardOver=true" @mouseleave="cardOver=false">
            <v-row class="pa-0 crsShp-Pointer" @click="menu.splice(0, 1, true)" no-gutters>
                <v-col cols="12">
                    <v-img
                        v-if="cstmFit == 'vrtclAspct'"
                        :src="imgSrc" :aspect-ratio="3/4"
                        class="rounded" min-height="200" max-height="228"
                        lazy-src="../../assets/bnfcrNoImg.png" contain>
                    </v-img>
                    <v-img v-else :src="imgSrc" :width="imgWidth"></v-img>
                </v-col>
            </v-row>
            <v-expand-transition>
                <div
                    v-if="cardOver"
                    class="blue500 d-inline-block d-flex"
                    style="position: absolute; bottom: 0px; left: 0px; width: 100%; height: 36px; border-radius: 5px; opacity: 0.9;">
                    <v-btn @click="downAction" icon>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon color="white" size="18" v-bind="attrs" v-on="on">mdi-download</v-icon>
                            </template>
                            <span>다운로드</span>
                        </v-tooltip>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="modelEvent" icon>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon color="white" size="18" v-bind="attrs" v-on="on">mdi-trash-can</v-icon>
                            </template>
                            <span>삭제</span>
                        </v-tooltip>
                    </v-btn>
                </div>
            </v-expand-transition>
        </div>
        <v-dialog v-model="menu[0]" max-width="70%" content-class="round-md">
            <v-sheet class="px-10 py-6" rounded="lg" style="position: relative;">
                <v-img
                    :src="imgSrc" :height="$vuetify.breakpoint.height * 0.87"
                    :max-height="$vuetify.breakpoint.height * 0.87 -80"
                    contain>
                </v-img>
                <span style="position: absolute; bottom: 10px; left: 50%; transform: translateX(-50%);">
                    <v-btn class="rounded-circle" color="blue600" height="66" @click="menu.splice(0, 1, false)">
                        <v-icon color="white" size="38">mdi-close</v-icon>
                    </v-btn>
                </span>
            </v-sheet>
        </v-dialog>
    </v-card>
</template>
<script>
export default {
    name: 'imageCard',
    
    components: {
        
    },

    props : {
        imgSrc: { type: String, default: '' },
        imgWidth: { type: String, default: '' },
        cstmFit: { type: String, default: 'contain' },
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    watch: {
    },

    methods: {
        modelEvent: function(){
            this.$emit('isEvent')
        },
        downAction: function(){
            this.$emit('onDown')
        },
    },
    data: () => ({ 
        src:'12312',
        menu: [ false, ],
        cardOver: false,
    }),
};
</script>

<style>
 
</style>