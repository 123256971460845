<template>
    <v-sheet class="physcThrpyRcrdWeek">
        <expandWeekOnlyPicker :prtDate="''" @nowWeek="getWeek" class="my-1"></expandWeekOnlyPicker>
        <div class="pa-2">            
            <div class="d-flex pa-0 ma-0" no-gutters 
                style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">            
                <div v-for="(item, i) in dayitems" :key="i" :class="{left:i!=0}" style="width:14.3%;">                                
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0">
                        <span class="black--text font-weight-medium">{{item.date}}</span>
                        <span class="black--text font-weight-medium">&#40;{{item.day}}&#41;</span>                                                
                    </div>
                    <div :class="['', (item.date == today)?'green lighten-5':'']" style="overflow-y:auto;min-height:634px;max-height:634px;border-top: 1px solid #dbdbdb !important;overflow-y:auto;font-size:0.9rem">
                        <span v-for="(list, k) in item.list" :key="k" class="d-flex justify-center align-center pa-1 ma-0">
                            {{list.bnfcrNm}}&nbsp;{{list.thrTm}}
                        </span>
                    </div>
                </div>
            </div>            
        </div>
    </v-sheet>
</template>
<script>
import expandWeekOnlyPicker from '../commons/expandWeekOnlyPicker.vue';
import { selPhyThrRecWkList, } from '../../api/bnftrcrd.js';
export default {
    name: 'PhyscThrpyRcrdDay',
        
    components: {
        expandWeekOnlyPicker
    },

    created: function(){
        this.getToday()
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {            
        })
    },

    computed: {

    },

    watch:{ 
        'startDay':function(){
            this.getPhyThrRecWkList()
        }
    },
        
    methods: {
        getToday:function(){         
            let date = new Date()
            this.today = date.getFullYear()+'.'+('00'+(date.getMonth()+1).toString()).slice(-2)+'.'+('00'+(date.getDate()).toString()).slice(-2)
        },
        getWeek : function(ws, we){
            this.startDay = ws
            this.endDay = we
        },
        setDays(res){
            this.dayitems.splice(0) 

            for(let i=0;i<7;i++){                
                let date = new Date(this.startDay.beforeDateFormatHyp());
                let arrDate = new Date(date.setDate(date.getDate()+i)).toISOString().split('T',2)
                let fdate = arrDate[0].afterDateFormatHyp().beforeDateFormatDot()
                
                let obj = {
                    day:this.days[i],
                    date:fdate,
                    idx:arrDate[0].afterDateFormatHyp(),
                    list:[]
                } 
                
                this.dayitems.push(obj)
            }

            if(res.length > 0){
                res.forEach(e => {
                    let target = this.dayitems.find(x=>x.idx==e.recDt)
                    target.list.push(e)
                });
            }

        },
        getPhyThrRecWkList(){
            let obj = {
                fcltyNum: this.$store.getters.getFcltyNum,               
                inqYmdStart: this.startDay,
                inqYmdEnd: this.endDay,
            }
            
            selPhyThrRecWkList(obj)
            .then((response) => ( this.getPhyThrRecWkListAfter(response.data)))
            .catch((error) => `console.log`('upload error /bnftrcrd/selPhyThrRecDdList : ' + error))
        },
        getPhyThrRecWkListAfter:function(res){   
            this.setDays(res)
        },
    },
    
    data: () => ({
        startDay:'',
        endDay:'',
        today:'',
        dayitems:[],
        days:['일','월','화','수','목','금','토'],
    }),
};
</script>
<style>
.physcThrpyRcrdWeek .left {border-left: 1px solid #dbdbdb !important;}
</style>