<template>
    <v-sheet>
        <v-form ref="tabform" lazy-validation>   
            <div class="d-flex pa-0 ma-0 mb-2" style="justify-content: right;">               
                <v-btn 
                    class="white--text" height="30" color="blueBtnColor" rounded
                    style="font-size: 14px;" @click="setAngle()">
                    <v-icon class="mr-1" size="18">mdi-text-box-search-outline</v-icon>
                    정상각도
                </v-btn>
            </div>           
            <div class="overflow-y-auto" style="height:520px;">                
                <div class="d-flex" >       
                    <div class="d-inline-block pa-0 ma-0 align-self-start" style="width:50%;"> 
                        <v-row class="pa-0 ma-0" no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">                                
                                <span class="black--text font-weight-medium">부위</span>
                            </v-col>
                            <v-col class="greyE01 d-flex pa-0 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="10"> 
                                <div class="d-flex justify-center align-center pa-0 ma-0" style="width:35%;" >
                                    <span class="black--text font-weight-medium">운동범위</span>
                                </div>
                                <div class="d-flex justify-center align-center pa-0 ma-0" style="width:15%;border-left: 1px solid #dbdbdb !important;" >
                                    <span class="black--text font-weight-medium">각도</span>
                                </div>
                                <div class="d-flex justify-center align-center pa-0 ma-0" style="width:25%;border-left: 1px solid #dbdbdb !important;" >
                                    <span class="black--text font-weight-medium">Rt</span>
                                </div>
                                <div class="d-flex justify-center align-center pa-0 ma-0" style="width:25%;border-left: 1px solid #dbdbdb !important;" >
                                    <span class="black--text font-weight-medium">Lt</span>
                                </div> 
                            </v-col>
                        </v-row>
                        <v-row v-for="(item, i) in groups1" :key="i" class="pa-0 ma-0" no-gutters>
                            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="border-bottom: 1px solid #dbdbdb !important;" cols="2">                                
                                <span class="black--text font-weight-medium">{{item.itmCdNm}}</span>
                            </v-col>
                            <v-col class="pa-0 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="10"> 
                                <v-row class="pa-0 ma-0" v-for="(itm, j) in items[item.itmCd]" :key="j" style="border-bottom: 1px solid #dbdbdb !important;">
                                    <v-col class="pa-0 ma-0 d-flex" cols="12">
                                        <div class="d-flex justify-start align-center pa-1 ma-0" style="width:35%;" >
                                            {{itm.itmCdNm}}
                                        </div>
                                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:15%;border-left: 1px solid #dbdbdb !important;" >
                                            {{itm.angle}}
                                        </div>
                                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:25%;border-left: 1px solid #dbdbdb !important;" >                                        
                                            <span v-if="item.itmCd=='1'" class="d-inline-block" style="width:80px">
                                                <v-text-field v-model="itmVal1[itm.rtCd]" height="28" hide-details outlined dense></v-text-field>
                                            </span>
                                            <span v-if="item.itmCd=='2'" class="d-inline-block" style="width:80px">
                                                <v-text-field v-model="itmVal2[itm.rtCd]" height="28" hide-details outlined dense></v-text-field>
                                            </span>
                                            <span v-if="item.itmCd=='3'" class="d-inline-block" style="width:80px">
                                                <v-text-field v-model="itmVal3[itm.rtCd]" height="28" hide-details outlined dense></v-text-field>
                                            </span>
                                        </div>
                                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:25%;border-left: 1px solid #dbdbdb !important;" >
                                            <span v-if="item.itmCd=='1'" class="d-inline-block" style="width:80px">
                                                <v-text-field v-model="itmVal1[itm.ltCd]" height="28" hide-details outlined dense></v-text-field>
                                            </span>
                                            <span v-if="item.itmCd=='2'" class="d-inline-block" style="width:80px">
                                                <v-text-field v-model="itmVal2[itm.ltCd]" height="28" hide-details outlined dense></v-text-field>
                                            </span>
                                            <span v-if="item.itmCd=='3'" class="d-inline-block" style="width:80px">
                                                <v-text-field v-model="itmVal3[itm.ltCd]" height="28" hide-details outlined dense></v-text-field>
                                            </span>
                                        </div> 
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="d-inline-block pa-0 ml-3 align-self-start" style="width:50%;">  
                        <v-row class="pa-0 ma-0" no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">                                
                                <span class="black--text font-weight-medium">부위</span>
                            </v-col>
                            <v-col class="greyE01 d-flex pa-0 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="10"> 
                                <div class="d-flex justify-center align-center pa-0 ma-0" style="width:35%;" >
                                    <span class="black--text font-weight-medium">운동범위</span>
                                </div>
                                <div class="d-flex justify-center align-center pa-0 ma-0" style="width:15%;border-left: 1px solid #dbdbdb !important;" >
                                    <span class="black--text font-weight-medium">각도</span>
                                </div>
                                <div class="d-flex justify-center align-center pa-0 ma-0" style="width:25%;border-left: 1px solid #dbdbdb !important;" >
                                    <span class="black--text font-weight-medium">Rt</span>
                                </div>
                                <div class="d-flex justify-center align-center pa-0 ma-0" style="width:25%;border-left: 1px solid #dbdbdb !important;" >
                                    <span class="black--text font-weight-medium">Lt</span>
                                </div> 
                            </v-col>
                        </v-row>
                        <v-row v-for="(item, i) in groups2" :key="i" class="pa-0 ma-0" no-gutters>
                            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="border-bottom: 1px solid #dbdbdb !important;" cols="2">                                
                                <span class="black--text font-weight-medium">{{item.itmCdNm}}</span>
                            </v-col>
                            <v-col class="pa-0 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="10"> 
                                <v-row class="pa-0 ma-0" v-for="(itm, j) in items[item.itmCd]" :key="j" style="border-bottom: 1px solid #dbdbdb !important;">
                                    <v-col class="pa-0 ma-0 d-flex" cols="12">
                                        <div class="d-flex justify-start align-center pa-1 ma-0" style="width:35%;" >
                                            {{itm.itmCdNm}}
                                        </div>
                                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:15%;border-left: 1px solid #dbdbdb !important;" >
                                            {{itm.angle}}
                                        </div>
                                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:25%;border-left: 1px solid #dbdbdb !important;" >
                                            <span v-if="item.itmCd=='4'" class="d-inline-block" style="width:80px">
                                                <v-text-field v-model="itmVal4[itm.rtCd]" height="28" hide-details outlined dense></v-text-field>
                                            </span>
                                            <span v-if="item.itmCd=='5'" class="d-inline-block" style="width:80px">
                                                <v-text-field v-model="itmVal5[itm.rtCd]" height="28" hide-details outlined dense></v-text-field>
                                            </span>
                                            <span v-if="item.itmCd=='6'" class="d-inline-block" style="width:80px">
                                                <v-text-field v-model="itmVal6[itm.rtCd]" height="28" hide-details outlined dense></v-text-field>
                                            </span>
                                        </div>
                                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:25%;border-left: 1px solid #dbdbdb !important;" >
                                            <span v-if="item.itmCd=='4'" class="d-inline-block" style="width:80px">
                                                <v-text-field v-model="itmVal4[itm.ltCd]" height="28" hide-details outlined dense></v-text-field>
                                            </span>
                                            <span v-if="item.itmCd=='5'" class="d-inline-block" style="width:80px">
                                                <v-text-field v-model="itmVal5[itm.ltCd]" height="28" hide-details outlined dense></v-text-field>
                                            </span>
                                            <span v-if="item.itmCd=='6'" class="d-inline-block" style="width:80px">
                                                <v-text-field v-model="itmVal6[itm.ltCd]" height="28" hide-details outlined dense></v-text-field>
                                            </span>
                                        </div> 
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>                   
                    </div>
                </div>
                <div class="mt-2">
                    <v-row class="pa-0 ma-0" no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">                                
                            <span class="black--text font-weight-medium">평가</span>
                        </v-col>
                        <v-col class="d-flex justify-start align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="10">                                
                                <v-textarea
                                    v-model="evltn" class="rmrksArea" rows="12"
                                    no-resize outlined dense hide-details>
                            </v-textarea> 
                        </v-col>
                    </v-row>
                </div>
            </div>          
        </v-form>   
    </v-sheet>
</template>
<script>
import { insBnfcrExamHis} from '../../api/bnftSrv.js';

export default {
    name: 'PhyscThrpyEvltnTab4',

    props : {
        
    },

    computed: {
        
    },  

    watch:{        
        
    },

    created:function(){  
       
    },

    components: {
    },
    
    mounted: function(){ 
        this.$nextTick(function () { 
            
        }) 
    },
        
    methods: {            
        onShow(){
            
        },
        setStyle:function(value){
            let style = "";
            if(value > 13){
                style="width:200px;"
            }else if(value > 10 && value <= 13){
                style="width:160px;"
            }else if(value > 5 && value <= 10){
                style="width:130px;"
            }else if(value == 2){
                style="width:90px;"
            }else{
                style="width:100px;"
            }
            return style;
        },   
        
        onClose: function () {
            this.$emit('modalClose', this.dsrEvltn)
        },

        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },

        setData(data){  
            this.data = data   
            this.group1 = []
            this.group2 = []
            this.group3 = []
            this.group4 = []

            this.data.map(x=>{
                x.itmValCd = x.valClcd
                if(x.clsfc3Cd=='1'){
                    this.itmVal1[x.itmCd] = x.itmVal 
                }else if(x.clsfc3Cd=='2'){
                    this.itmVal2[x.itmCd] = x.itmVal 
                }else if(x.clsfc3Cd=='3'){
                    this.itmVal3[x.itmCd] = x.itmVal 
                }else if(x.clsfc3Cd=='4'){
                    this.itmVal4[x.itmCd] = x.itmVal 
                }else if(x.clsfc3Cd=='5'){
                    this.itmVal5[x.itmCd] = x.itmVal 
                }else if(x.clsfc3Cd=='6'){
                    this.itmVal6[x.itmCd] = x.itmVal 
                }else if(x.clsfc3Cd=='999'){
                    this.evltn = x.evltn
                }
            });  

        },
        setAngle(){
            this.itmVal1 = []
            this.itmVal2 = []
            this.itmVal3 = []
            this.itmVal4 = []
            this.itmVal5 = []
            this.itmVal6 = []

            this.items[1].map(x=>{
                this.itmVal1[x.ltCd] = x.angle
                this.itmVal1[x.rtCd] = x.angle
            })
            this.items[2].map(x=>{
                this.itmVal2[x.ltCd] = x.angle
                this.itmVal2[x.rtCd] = x.angle
            })
            this.items[3].map(x=>{
                this.itmVal3[x.ltCd] = x.angle
                this.itmVal3[x.rtCd] = x.angle
            })
            this.items[4].map(x=>{
                this.itmVal4[x.ltCd] = x.angle
                this.itmVal4[x.rtCd] = x.angle
            })
            this.items[5].map(x=>{
                this.itmVal5[x.ltCd] = x.angle
                this.itmVal5[x.rtCd] = x.angle
            })
            this.items[6].map(x=>{
                this.itmVal6[x.ltCd] = x.angle
                this.itmVal6[x.rtCd] = x.angle
            })
        },
        reset(){
            this.$refs.tabform.reset()
        },

        insBnfcrExam(data){
            if(this.$refs.tabform.validate()){
                //수급자평가 내역 setting

                let obj = {
                    fcltyNum:this.$store.getters.getFcltyNum,
                    userNm:this.$store.getters.getUserNm,
                    bnMmbrPk:data.bnMmbrPk,
                    bnfcrExamHisPk:data.bnfcrExamHisPk,
                    examClcd:'106',                    
                    examRsncd : data.examRsncd,
                    examDt : data.examDt.afterDateFormatHyp(),
                    wrtr : data.wrtr,
                    examYyyy : data.inqYYYY,
                }

                //욕구사정평가 setting
                let examList = []
                this.data.map(x=>{
                    if(x.clsfc3Cd=='1'){
                        x.itmVal = this.itmVal1[x.itmCd]
                    }else if(x.clsfc3Cd=='2'){
                        x.itmVal = this.itmVal2[x.itmCd]
                    }else if(x.clsfc3Cd=='3'){
                        x.itmVal = this.itmVal3[x.itmCd]
                    }else if(x.clsfc3Cd=='4'){
                        x.itmVal = this.itmVal4[x.itmCd]
                    }else if(x.clsfc3Cd=='5'){
                        x.itmVal = this.itmVal5[x.itmCd]
                    }else if(x.clsfc3Cd=='6'){
                        x.itmVal = this.itmVal6[x.itmCd]
                    }else if(x.clsfc3Cd=='999'){
                        x.evltn = this.evltn
                    }
                    examList.push(x)
                });  
                
                insBnfcrExamHis(obj,examList)
                .then((response) => ( this.insBnfcrExamAfter(response.data)))
                .catch((error) => console.log(error))
            }
        },
        insBnfcrExamAfter: function (res) {
            this.$emit('physcThrpyEvltnTrigger', res)            
        },        
        selExamList:function(){
            //욕구사정평가 setting
            let examList = []
            this.data.map(x=>{
                if(x.clsfc3Cd=='1'){
                    x.itmVal = this.itmVal1[x.itmCd]
                }else if(x.clsfc3Cd=='2'){
                    x.itmVal = this.itmVal2[x.itmCd]
                }else if(x.clsfc3Cd=='3'){
                    x.itmVal = this.itmVal3[x.itmCd]
                }else if(x.clsfc3Cd=='4'){
                    x.itmVal = this.itmVal4[x.itmCd]
                }else if(x.clsfc3Cd=='5'){
                    x.itmVal = this.itmVal5[x.itmCd]
                }else if(x.clsfc3Cd=='6'){
                    x.itmVal = this.itmVal6[x.itmCd]
                }else if(x.clsfc3Cd=='999'){
                    x.evltn = this.evltn
                }
                examList.push(x)
            });  

            return examList
        }, 
    },
    
    data: () => ({    
        loading:true, 
        showdiv:false,   
        selected:'',
        data:[],
        obj:{},
        itmVal1:[],
        itmVal2:[],
        itmVal3:[],
        itmVal4:[],
        itmVal5:[],
        itmVal6:[],
        groups1:[
            {itmCdNm:'어깨',itmCd:'1'}
            ,{itmCdNm:'팔꿈치 및 전완',itmCd:'2'}
            ,{itmCdNm:'손목',itmCd:'3'}
        ],
        groups2:[
            {itmCdNm:'엉덩이 관절',itmCd:'4'}
            ,{itmCdNm:'무릎',itmCd:'5'}
            ,{itmCdNm:'발목',itmCd:'6'}
        ],
        items:{
            '1':[    
                {itmCdNm:'굴곡(Flexion)', angle:'0-180', rtCd:'1', ltCd:'2'},
                {itmCdNm:'신전(Extension)', angle:'0-60', rtCd:'3', ltCd:'4'},
                {itmCdNm:'외전(abduction)', angle:'0-180', rtCd:'5', ltCd:'6'},
                {itmCdNm:'내회전(Internal Rotation)', angle:'0-70', rtCd:'7', ltCd:'8'},
                {itmCdNm:'외회전(External Rotation)', angle:'0-90', rtCd:'9', ltCd:'10'},
            ],
            '2':[    
                {itmCdNm:'굴곡~신전(Flexion-Extension)', angle:'0-150', rtCd:'1', ltCd:'2'},
                {itmCdNm:'회외(Supination)', angle:'0-80', rtCd:'3', ltCd:'4'},
                {itmCdNm:'회내(Pronation)', angle:'0-80', rtCd:'5', ltCd:'6'},
            ],
            '3':[    
                {itmCdNm:'굴곡(Flexion)', angle:'0-80', rtCd:'1', ltCd:'2'},
                {itmCdNm:'신전(Extension)', angle:'0-70', rtCd:'3', ltCd:'4'},
                {itmCdNm:'척골편향(Ulnar Deviation)', angle:'0-30', rtCd:'5', ltCd:'6'},
                {itmCdNm:'요골편향(Radial Deviation)', angle:'0-20', rtCd:'7', ltCd:'8'},
            ],
            '4':[    
                {itmCdNm:'굴곡(Flexion)', angle:'0-120', rtCd:'1', ltCd:'2'},
                {itmCdNm:'신전(Extension)', angle:'0-30', rtCd:'3', ltCd:'4'},
                {itmCdNm:'외전(abduction)', angle:'0-45', rtCd:'5', ltCd:'6'},
                {itmCdNm:'내전(adduction)', angle:'0-30', rtCd:'7', ltCd:'8'},
                {itmCdNm:'내회전(Internal Rotation)', angle:'0-45', rtCd:'9', ltCd:'10'},
                {itmCdNm:'외회전(External Rotation)', angle:'0-45', rtCd:'11', ltCd:'12'},
            ],
            '5':[    
                {itmCdNm:'굴곡~신전(Flexion-Extension)', angle:'135-0', rtCd:'1', ltCd:'2'},
            ],
            '6':[    
                {itmCdNm:'족배굴곡(DorsiFlexion)', angle:'0-20', rtCd:'1', ltCd:'2'},
                {itmCdNm:'족저굴곡(PlanterFlexion)', angle:'0-50', rtCd:'3', ltCd:'4'},
                {itmCdNm:'내반(Inversion)', angle:'0-35', rtCd:'5', ltCd:'6'},
                {itmCdNm:'외반(Eversion)', angle:'0-15', rtCd:'7', ltCd:'8'},
            ],
        },
        evltn:'',
        loops:{},
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' || value == undefined) || 'Required.',
        },
        
    }),
};
</script>