<template>
    <v-sheet class="d-inline-block rounded-lg rounded-l-0" min-width="1260" max-width="1460" height="835">
        <div class="px-2 mt-2 mr-2 d-flex align-center">
            <div class="text-h4">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">4-1. 프로그램 운영기록</span>
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex justify-center pl-1 pr-3">
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn v-if="list.type == 'action'" @click="onClickBtn(list.text)" :class="list.class" samll height="30" :color="list.color"
                        :disabled="list.disabled" rounded>
                        <v-icon small>{{ list.icon }}</v-icon>
                        <span style="font-size: 1rem;">{{ list.text }}</span>
                    </v-btn>
                    <v-dialog v-else-if="list.type == 'dialogue'" @click="onModalType(list.text)" v-model="dialog[i]"
                        :max-width="list.width">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled" :class="list.class" samll
                                height="30" :color="list.color" rounded>
                                <v-icon small>{{ list.icon }}</v-icon>
                                <span style="font-size: 1rem;">{{ list.text }}</span>
                            </v-btn>
                        </template>
                        <!-- <PrgrmNew v-if="list.text == '프로그램 일정등록'" ></PrgrmNew> -->
                        <!-- <div >개발중</div> -->
                    </v-dialog>
                    <v-btn
                        class="ml-2" height="30" color="grey006"
                        @click="onClickBtn('출력')"
                        :disabled="prtBtn"
                        samll rounded outlined>
                        <v-icon style="padding-top: 2px;" small>mdi-printer-outline</v-icon>
                        <span class="fontOneRem ml-1">출력</span>
                    </v-btn>
                </span>
            </div>
        </div>
        <div class="mt-5 mb-2 mx-3">
            <expandWeekOnlyPicker @nowWeek="getWeek" class="my-1"></expandWeekOnlyPicker>
        </div>
        <div class="mt-5 mx-3 overflow-y-auto">
            <v-data-table 
                class="" locale="ko" height="700" 
                :headers="headers" :items="items"
                disable-pagination hide-default-footer hide-default-header fixed-header>
                <template v-slot:header="{ props }">
                    <thead>
                        <tr>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width:100px;">
                                {{ props.headers[0].text }}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width:110px;">
                                {{ props.headers[1].text }}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width:270px;">
                                {{ props.headers[2].text }}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width:140px;">
                                {{ props.headers[3].text }}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width:100px;">
                                {{ props.headers[4].text }}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width:140px; line-height: 1.2;">
                                프로그램<br />수급자그룹</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width:80px; line-height: 1.2;">
                                참여<br />인원수
                            </th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width:200px;">
                                {{ props.headers[5].text }}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width:100px;">
                                {{ props.headers[6].text }}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width:100px;">
                                {{ props.headers[7].text }}</th>
                            <th class="black--text clmsHeaderCommon tableBT" style="width:100px;">
                                {{ props.headers[8].text }}</th>
                        </tr>
                    </thead>
                </template>
                <template v-slot:item='{ item, index }'>
                    <tr>
                        <td class="clmsBodyCommon tableBR">{{ item.opDt?.beforeDateFormatDot() }}</td>
                        <td class="clmsBodyCommon tableBR">{{ item.opTm }} </td>
                        <td class="clmsBodyCommon tableBR">{{ item.prgNm }} </td>
                        <td class="clmsBodyCommon tableBR">{{ item.prgTypNm }}</td>
                        <td class="clmsBodyCommon tableBR">{{ item.idxCdnm }} </td>
                        <td class="clmsBodyCommon tableBR py-1"> 
                            <div class="mt-1">
                                <v-chip 
                                    v-for="(itm, j) in item.bnfcrgrNm" :key="j"
                                    class="white pa-2 mr-1 mb-1"             
                                    color="grey"                          
                                    small label outlined>
                                    {{itm}}
                                </v-chip>
                            </div>                           
                        </td>
                        <td class="clmsBodyCommon tableBR">{{ item.prtcpNum }}</td>
                        <td class="clmsBodyCommon tableBR">{{ item.plc }} </td>
                        <td class="clmsBodyCommon tableBR">{{ item.mc }} </td>
                        <td class="clmsBodyCommon tableBR">
                           <v-icon
                                :color="item.prgOpRecPk == null ? 'blue200' : ''"
                                @click="showDialog(item.prgOpPlnPk, item.prgOpRecPk, item.prgInfPk)">
                                mdi-square-edit-outline
                            </v-icon>
                        </td>
                        <td class="clmsBodyCommon">
                            <v-icon
                                v-if="item.prgOpRecPk != null"
                                @click="mtngRcrdPrt(item.prgOpRecPk)"
                                class="mr-2">
                                mdi-printer-outline
                            </v-icon>
                            <v-icon @click="onDelete(item.prgOpPlnPk, index)">mdi-trash-can-outline</v-icon>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </div>
        <v-dialog v-model="dialog[0]" persistent max-width="1200">
            <PrgrmNew 
                ref="PrgrmNew"                 
                @isClose="modalEvent(0)" 
                @modalEvent="selPrgOpRecWrtList"
            ></PrgrmNew>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500">    
            <btnModalDelete :title="'프로그램 평가'" @madalState="onDeleteBtn"></btnModalDelete>  
        </v-dialog>  
    </v-sheet>  
</template>

<script>
import expandWeekOnlyPicker from '../../components/commons/expandWeekOnlyPicker.vue';
import PrgrmNew from '../../components/prgrm/PrgrmNew.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';

import { selPrgOpRecWrtList, delPrgOpPln } from '../../api/prgrm.js';
import { getStcd } from '../../api/index.js';

export default {
    name: 'PrgSrvRcrd',

    components: {
        expandWeekOnlyPicker,
        PrgrmNew,
        btnModalDelete,
    },
    created: function () {
        getStcd('40')
            .then((response) => (this.stCdMapping(response.data)))
            .catch((error) => console.log(error))
    },
    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            // this.selPrgOpRecWrtList()
        })
    },

    computed: {
        getRootEvt: function () {
            return this.$store.getters.getRootRunEvt
        },
    },

    watch: {
        getRootEvt: function (bol) {
            if(bol && this.$store.getters.getRootInObj == null){
                this.selPrgOpRecWrtList()
                this.$store.commit('setRootRunEvt', false)
            }
            else if(bol && this.$store.getters.getRootInObj != null){
                if(Object.keys(this.$store.getters.getRootInObj).length == 3){
                    this.selPrgOpRecWrtList()
                    this.$store.commit('setRootRunEvt', false)
                }
            }
        },
        'startWeek': function () {
            this.selPrgOpRecWrtList()
        },
        'cdItems': function () {
            if(this.items.length > 0){
                this.items.forEach((item) => {
                    this.cdItems.forEach((cd) => {
                        if (item.idxCd == cd.valcd) item.idxCdnm = cd.valcdnm
                    });
                });
            }
        }
    },

    methods: {
        stCdMapping: function (res) {
            this.cdItems = res
        },
        getWeek: function (startWeek, endWeek) {
            this.startWeek = startWeek
            this.endWeek = endWeek
        },
        selPrgOpRecWrtList: function () {
            selPrgOpRecWrtList(this.$store.getters.getFcltyNum, this.startWeek, this.endWeek)
                .then((response) => (this.selPrgOpRecWrtListAfter(response.data)))
                .catch((error) => console.log('connect error prgrm/selPrgOpRecWrtList : ' + error))
        },
        selPrgOpRecWrtListAfter: function (res) {
            this.opDt = res.opDt
            this.opTm = res.opTm
            this.items = res

            this.items.forEach((item) => {
                this.cdItems.forEach((cd) => {
                    if (item.idxCd == cd.valcd) item.idxCdnm = cd.valcdnm
                });
            });

            let chc = this.items.filter( v => v.prgOpRecPk == null )
            if(this.items.length == 0)
                this.prtBtn = true
            else if(this.items.length == chc.length)
                this.prtBtn = true
            else
                this.prtBtn = false
        },
        isModalType: function () {
            this.dialog.splice(0, 1, false)
            this.dialogTab.splice(0, 1, false)
            this.selPrgOpRecWrtList()
        },
        onDelete(pk, key) {
            this.key = key
            this.plnPk = pk
            this.dialogDelete = true
        },
        delPrgOpPlnAfter(res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.selPrgOpRecWrtList()
                this.dialogDelete = false
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        mtngRcrdPrt:function(pk){
            this.prtThrowObj.list[0].bzPk.splice(0)
            this.prtThrowObj.list[0].bzPk.push(pk)
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
            });
        },
        snackControll: function (options) {
            this.snackOptions = options
            //this.snackOptions[0] = true
            //this.snackOptions[1] = 2500
            //this.snackOptions[2] = '신규 작성 모드'
            //info, success, warning, error
            //this.snackOptions[3] = 'info'
        },
        onClickBtn:function(text){
            if(text == "프로그램 일정등록"){
                this.prgrmNewPk = 1
                this.dialog.splice(0, 1, true)
            } else if (text == "삭제"){
                this.onDelete()
            } else if (text == "출력"){
                this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
                this.prtThrowObj.inqYmdStart = this.startWeek?.afterDateFormatHyp()
                this.prtThrowObj.inqYmdEnd = this.endWeek?.afterDateFormatHyp()
                this.prtThrowObj.list[0].bzPk.splice(0)
                
                this.$store.commit('setRootInf', {
                    actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
                });
            } 
        },
        showDialog: function (plnPk, recPk, prgInfPk) {
            let obj = {}
            obj.prgOpPlnPk = plnPk
            obj.prgOpRecPk = recPk
            obj.prgInfPk = prgInfPk

            this.plnPk = plnPk
            this.recPk = recPk
            this.prgInfPk = prgInfPk

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1300, prsst: true, typ: '4-01', inObj: obj, outObj: null, runEvt: false,
            });
        },
        modalEvent: function (event) { 
            this.dialog.splice(event, 1, false)
        },
        onDeleteBtn: function (res) {
            if (res == 9990) {
                if (this.plnPk > 0) {
                    if (this.items[this.key].prgOpPlnPk != undefined) {
                        let obj = {
                            fcltyNum: this.$store.getters.getFcltyNum,
                            prgOpPlnPk: this.items[this.key].prgOpPlnPk,
                            userNm: this.$store.getters.getUserNm,
                        }
                        delPrgOpPln(obj)
                            .then((response) => (this.delPrgOpPlnAfter(response.data)))
                            .catch((error) => console.log(error))
                    } else {
                        this.items.splice(this.key, 1)
                    }
                }
            } else if (res == 7) {
                this.dialogDelete = false
            }

        },
    },

    data: () => ({
        snackOptions: [false, 3000, '', 'info'],
        dialog: [ false, false ],
        prtBtn: true,
        filledBtn: [
            { icon: 'mdi-clipboard-text-outline', class: 'ml-1 white--text', color: 'blueBtnColor', text: '프로그램 일정등록', type: 'action', },            
        ],
        headers: [
            { text: '운영일', value: 'opDt', align: 'center', sortable: true, width: '70', class: 'black--text text-h6' },
            { text: '운영시간', value: 'opTm', align: 'center', sortable: true, width: '70', class: 'black--text text-h6' },
            { text: '프로그램', value: 'prgNm', align: 'center', sortable: true, width: '70', class: 'black--text text-h6' },
            { text: '프로그램 유형', value: 'prgTypNm', align: 'center', sortable: true, width: '70', class: 'black--text text-h6' },
            { text: '지표구분', value: 'idxCd', align: 'center', sortable: true, width: '70', class: 'black--text text-h6' },
            { text: '장소', value: 'plc', align: 'center', sortable: true, width: '70', class: 'black--text text-h6' },
            { text: '진행자', value: 'mc', align: 'center', sortable: true, width: '70', class: 'black--text text-h6' },
            { text: '기록', value: 'rn', align: 'center', sortable: true, width: '70', class: 'black--text text-h6' },
            { text: '처리', value: 'rn', align: 'center', sortable: true, width: '70', class: 'black--text text-h6' },
        ],
        items: [],
        startWeek: 0,
        menu: [false, false],
        dialogTab: [false],
        plnPk : 0,
        recPk : 0, 
        prgInfPk : 0,
        opDt: '',
        opTm: '',
        key: 0,
        dialogDelete: false,
        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '16', inqYYYY: '',
            inqYmdStart: '', inqYmdEnd: '',
            rptTyp: '1', mode: '2', list: [ { bzClcd: [], bzPk: [] } ], 
        },
        cdItems: [],
    }),
};
</script>