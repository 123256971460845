<template>
    <v-sheet class="pr-1 ma-0">
        <expandYearOnlyPicker
            v-if="$store.getters.getBnfcrState.bnmmbr != 0"
            class="mt-4" @nowYear="getYear">
        </expandYearOnlyPicker>
        <v-row class="pa-0 mt-2 mb-2 align-center posn-rltv" no-gutters>
            <span
                class="d-inlne-block"
                style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">CCTV 침실 촬영 동의</span>
            <v-spacer></v-spacer>
            <span class="mr-3" v-if="this.cctvInfo.cctvBdrmInfPk < 1">
                <v-icon color="red">mdi-alert-circle-outline</v-icon>
                전자서명은 CCTV 침실 촬영 동의 저장 후 이용하실 수 있습니다.
            </span>
            <v-btn
                class="white--text mr-3" style="padding: 9px 7px 8px 5px !important;"
                color="blueBtnColor" @click="cctvBdrmMsg()"
                :disabled="this.cctvInfo.cctvBdrmInfPk < 1"
                min-width="20" height="20" small outlined>
                <v-icon class="mr-1" size="14">mdi-arrow-expand-right</v-icon>
                <span class="fontNineThRem" style="padding-bottom: 2px;">서명요청</span>
            </v-btn>
            <v-btn
                class="white--text" style="padding: 9px 7px 8px 5px !important;"
                color="blueBtnColor" @click="preCctvBdrm()"
                min-width="20" height="20" small outlined>
                <v-icon class="mr-1" size="14">mdi-content-save-edit-outline</v-icon>
                <span class="fontNineThRem" style="padding-bottom: 2px;">저장</span>
            </v-btn>
        </v-row>
        <v-form ref="cctvBdrmInfo" lazy-validation>
            <v-divider class="mt-2"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 tableBR txtAlgnMid" style="padding-top: 6px;" cols="1">
                    <span class="black--text font-weight-medium">작성일</span>
                </v-col>
                <v-col class="tableBR py-1 px-2" cols="2">
                    <CmmDateComp
                        v-model="cctvInfo.wrtDt" :required="true"
                        :disabled="$store.getters.getBnfcrState.bnmmbr == 0">
                    </CmmDateComp>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 tableBR txtAlgnMid" style="padding-top: 6px;" cols="1">
                    <span class="black--text font-weight-medium">동의여부</span>
                </v-col>
                <v-col class="tableBR" style="padding-top: 6px;" cols="2">
                    <v-radio-group
                        v-model="cctvInfo.agrYn" class="radio-dft-Theme mt-0 pl-2 pt-0"
                        :disabled="$store.getters.getBnfcrState.bnmmbr == 0"
                        hide-details row>
                        <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="예" value="1"></v-radio>
                        <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="아니오" value="2"></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 tableBR txtAlgnMid" style="padding-top: 6px;" cols="1">
                    <span class="black--text font-weight-medium">동의기간</span>
                </v-col>
                <v-col class="py-1 px-2" cols="5">
                    <span class="d-inline-block" style="width: 134px;">
                        <CmmDateComp
                            v-model="cctvInfo.agrDtStrt" :required="true"
                            :disabled="$store.getters.getBnfcrState.bnmmbr == 0">
                        </CmmDateComp>
                    </span>
                    <span class="mx-2">
                        <v-icon class="" size="14">mdi-tilde</v-icon>
                    </span>
                    <span class="d-inline-block" style="width: 134px;">
                        <CmmDateComp
                            v-model="cctvInfo.agrDtEnd" :required="true"
                            :disabled="$store.getters.getBnfcrState.bnmmbr == 0">
                        </CmmDateComp>
                    </span>
                </v-col> 
            </v-row>
            <v-divider></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="1">
                    <span class="font-weight-medium">보호자</span>
                </v-col>
                <v-col class="tableBR py-1 px-2" cols="2">
                    <span class="fontOneRem">{{ cctvInfo.agrRcvNm }}</span>
                </v-col>   
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="1">
                    <span class="font-weight-medium">관계</span>
                </v-col>
                <v-col class="tableBR py-1 px-4" cols="2">
                    <span class="fontOneRem">{{ cctvInfo.agrRcvRltns }}</span>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 tableBR txtAlgnMid py-1" cols="1">
                    <span class="font-weight-medium">연락처</span>
                </v-col>
                <v-col class="py-1 px-4" cols="5">
                    <span class="fontOneRem">{{ cctvInfo.celphn1 }}</span>
                    <span class="px-1">&#45;</span>
                    <span class="fontOneRem">{{ cctvInfo.celphn2 }}</span>
                    <span class="px-1">&#45;</span>
                    <span class="fontOneRem">{{ cctvInfo.celphn3 }}</span>
                </v-col>           
            </v-row>
            <v-divider></v-divider>
        </v-form>
        <v-row class="pa-0 mt-8 mb-2 align-center posn-rltv" no-gutters>
            <span
                class="d-inlne-block"
                style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">CCTV 침실 촬영 동의 내역</span>
            <v-spacer></v-spacer>
        </v-row>
        <v-divider :class="['mt-3', cctvBdrmList.length > 4 ? 'mr-2__5' : '']"></v-divider>
        <v-row :class="['greyE01 pa-0', cctvBdrmList.length > 4 ? 'mr-2__5' : '']" no-gutters>
            <v-col class="txtAlgnMid py-2 tableBR" cols="3">
                <span class="font-weight-medium">동의기간</span>
            </v-col>
            <v-col class="txtAlgnMid py-2 tableBR" cols="1">
                <span class="font-weight-medium">동의여부</span>
            </v-col>
            <v-col class="txtAlgnMid py-2 tableBR" cols="1">
                <span class="font-weight-medium">보호자</span>
            </v-col>
            <v-col class="txtAlgnMid py-2 tableBR" cols="2">
                <span class="font-weight-medium">수급자서명</span>
            </v-col>
            <v-col class="txtAlgnMid py-2 tableBR" cols="2">
                <span class="font-weight-medium">보호자서명</span>
            </v-col>
            <v-col class="txtAlgnMid py-2 tableBR" cols="2">
                <span class="font-weight-medium">연락처</span>
            </v-col>
            <v-col class="txtAlgnMid py-2" cols="1">
                <span class="font-weight-medium">처리</span>
            </v-col>
        </v-row>
        <v-divider :class="[cctvBdrmList.length > 4 ? 'mr-2__5' : '']"></v-divider>
        <v-row class="py-4 tableBB" v-if="cctvBdrmList.length == 0" no-gutters>
            <v-col class="txtAlgnMid" cols="12">
                <span class="grey006--text">※ 조회된 목록이 없습니다.</span>
            </v-col>
        </v-row>
        <div
            class="d-inlice-block overflow-y-auto" style="width: 100%; height: 160px;"
            v-else>
            <v-row
                class="pa-0 tableBB"
                v-for="(list, i) in cctvBdrmList" :key="i"
                no-gutters>
                <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="3">
                    <span class="fontOneRem">{{ list.agrDtStrt?.beforeDateFormatDot() }}</span>
                    <span class="px-1">&#126;</span>
                    <span class="fontOneRem">{{ list.agrDtEnd?.beforeDateFormatDot() }}</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="1">
                    <span class="fontOneRem">{{ list.agrYnNm }}</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="1">
                    <span class="fontOneRem">{{ list.agrRcvNm }}</span>
                </v-col>
                <v-col class="txtAlgnMid py-1 tableBR" cols="2">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <span
                                class="crsShp-Pointer"
                                @click="onSigMdl('12', list)"
                                v-bind="attrs" v-on="on">
                                <v-img
                                    :src="list.bnfcrSrc" :aspect-ratio="4/3"
                                    :class="['rounded', list.bnfcrSigYn != '1' ? 'my-1 mx-4' : '']"
                                    :min-height="list.bnfcrSigYn != '1' ? 22 : 30"
                                    :max-height="list.bnfcrSigYn != '1' ? 23 : 31"
                                    :contain="list.bnfcrSigYn == '1' ? true : false">
                                </v-img>
                            </span>
                        </template>
                        <span>수급자 직접서명</span>
                    </v-tooltip>
                </v-col>
                <v-col class="txtAlgnMid py-1 tableBR" cols="2">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <span
                                class="crsShp-Pointer"
                                @click="onSigMdl('13', list)"
                                v-bind="attrs" v-on="on">
                                <v-img
                                    :src="list.grdnSrc" :aspect-ratio="4/3"
                                    :class="['rounded', list.grdnSigYn != '1' ? 'my-1 mx-4' : '']"
                                    :min-height="list.grdnSigYn != '1' ? 22 : 30"
                                    :max-height="list.grdnSigYn != '1' ? 23 : 31"
                                    :contain="list.grdnSigYn == '1' ? true : false">
                                </v-img>
                            </span>
                        </template>
                        <span>보호자 직접서명</span>
                    </v-tooltip>
                </v-col>
                <v-col class="txtAlgnMid tableBR" style="padding-top: 6px;" cols="2">
                    <span class="fontOneRem">{{ list.celphn1 }}</span>
                    <span v-if="list.celphn2 != ''" class="px-1">&#45;</span>
                    <span class="fontOneRem">{{ list.celphn2 }}</span>
                    <span v-if="list.celphn3 != ''" class="px-1">&#45;</span>
                    <span class="fontOneRem">{{ list.celphn3 }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-1" cols="1">
                    <v-btn
                        class="nmlBfClrImp-white"
                        color="grey006" min-width="20" height="20"
                        @click="rcrdPrt(list)"
                        small icon>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" size="18">mdi-printer-outline</v-icon>
                            </template>
                            <span>CCTV촬영동의 출력</span>
                        </v-tooltip>
                    </v-btn>
                    <v-btn
                        class="nmlBfClrImp-white ml-1"
                        color="grey006" min-width="20" height="20"
                        @click="rmAgrBtn(list.cctvBdrmInfPk)"
                        small icon>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" size="18">mdi-trash-can-outline</v-icon>
                            </template>
                            <span>CCTV촬영동의 삭제</span>
                        </v-tooltip>
                    </v-btn>
                </v-col>
            </v-row>
        </div>

        <!-- 팝업 영억 -->
        <v-dialog
            v-model="lclMdlInf.isOn"
            content-class="round" :max-width="lclMdlInf.maxWidth"
            persistent>
            <SignComp
                v-if="lclMdlInf.typ == '17'"
                @modalClose="rstSig">
            </SignComp>
        </v-dialog>
    </v-sheet>
</template>

<script>
import {
    galFmlyList, selCctvBdrmList, selCctvBdrmInfo, bnfcrInfoDetail, insCctvBdrmInf, delCctvBdrmInf
} from '../../api/bnfcr.js';
import { remoteURL } from '@/api/baseAxios';

import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';
import SignComp from '../commons/SignModel.vue';              // 수급자 보호자 서명

export default {
    name: 'BnfcrCctvBdrmMgmt',
            
    components: {
        expandYearOnlyPicker,
        CmmDateComp,
        SignComp,
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.$store.getters.getBnfcrState.bnmmbr != 0)
                this.getCctvBdrmList()
        })
    },

    computed: {
        getSelectedItem(){
            return this.$store.getters.getBnfcrState.bnmmbr
        },
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch:{
        getSelectedItem: function(v){
            if(v == 0){
                this.clearForm()
                this.cctvBdrmList.splice(0)
            }
            else{
                this.getCctvBdrmList()
                this.getYear(this.year)
            }
        },
        getSubEvt: function (v) {
            if(v && this.runAble == '2'){
                let oobj = this.$store.getters.getSubOutObj
                this.rmCctvBdrmInf(oobj.cctvBdrmInfPk)

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.runAble = '0'
            }
        },
    },

    destroyed: function(){

    },
        
    methods: {
        preCctvBdrm: function () {
            if(this.cctvInfo.agrRcvNm != ''){
                let chk = this.$refs.cctvBdrmInfo.validate()
    
                if(chk){
                    let obj = {
                        fcltyNum        : this.$store.getters.getFcltyNum,
                        bnMmbrPk        : this.$store.getters.getBnfcrState.bnmmbr,
                        cctvBdrmInfPk   : this.cctvInfo.cctvBdrmInfPk,
                        grdnMmbrPk      : this.cctvInfo.grdnMmbrPk,
                        agrYyyy         : this.$moment(this.cctvInfo.agrDtStrt, 'YYYY-MM-DD').format('YYYY'),
                        agrYn           : this.cctvInfo.agrYn,
                        agrDtStrt       : this.cctvInfo.agrDtStrt?.afterDateFormatHyp(),
                        agrDtEnd        : this.cctvInfo.agrDtEnd?.afterDateFormatHyp(),
                        agrRcvNm        : this.cctvInfo.agrRcvNm,
                        agrRcvRltns     : this.cctvInfo.agrRcvRltns,
                        celphn1         : this.cctvInfo.celphn1,
                        celphn2         : this.cctvInfo.celphn2,
                        celphn3         : this.cctvInfo.celphn3,
                        userNm          : this.$store.getters.getUserNm,
                        wrtr            : this.cctvInfo.wrtr,
                        wrtDt           : this.cctvInfo.wrtDt?.afterDateFormatHyp(),
                    }
                    
                    this.setCctvBdrmInf(obj)
                }
            }
            else{
                let obj = {}
                obj.actv = true                                          // 경고 노출여부
                obj.tmr = 3500                                           // 경고 노출시간 (-1: 무제한)
                obj.type = 'warning'                                     // 경고 유형 (success, info, warning, error)
                obj.title = '수급자 관리에서 주 보호자를 저장해주세요.'     // 경고 타이틀
                obj.cntnt = []                                           // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },
        getCctvBdrmList: function (){
            this.cctvBdrmList.splice(0)

            selCctvBdrmList(this.$store.getters.getFcltyNum, this.$store.getters.getBnfcrState.bnmmbr)
                .then((response) => ( this.getCctvBdrmListAfter(response.data) ))
                .catch((error) => console.log('upload error /bnfcr/selCctvBdrmList : ' + error))
        },     
        getFmlyList: function (){
            let obj = {
                bnfcr:this.$store.getters.getBnfcrState.bnfcr
            }
            galFmlyList(this.$store.getters.getFcltyNum, obj)
                .then((response) => ( this.getFmlyListAfter(response.data) ))
                .catch((error) => console.log('upload error /bnfcr/galFmlyList : ' + error))
        },      
        getCctvBdrmInfo:function(obj){            
            selCctvBdrmInfo(obj)
                .then((response) => ( this.getCctvBdrmInfoAfter(response.data) ))
                .catch((error) => console.log('upload error /bnfcr/selCctvBdrmInfo : ' + error))
        },
        getBnfcrInfoDetail:function(){
            let obj = {
                bnmmbr:this.$store.getters.getBnfcrState.bnmmbr,
                bnfcr:this.$store.getters.getBnfcrState.bnfcr
            }
            bnfcrInfoDetail(this.$store.getters.getFcltyNum,obj)
                .then((response) => ( this.getBnfcrInfoDetailAfter(response.data) ))
                .catch((error) => console.log('upload error /bnfcr/selCctvBdrmInfo : ' + error))
        },
        setCctvBdrmInf:function(obj){
            insCctvBdrmInf(obj)
                .then((response) => ( this.setCctvBdrmInfAfter(response.data) ))
                .catch((error) => console.log('upload error /bnfcr/insCctvBdrmInf : ' + error))
        },
        // CCTV 동의 내역 삭제
        rmCctvBdrmInf: function(pk){
            let obj = {
                fcltyNum:  this.$store.getters.getFcltyNum,
                cctvBdrmInfPk: pk,
                userNm: this.$store.getters.getUserNm
            }

            delCctvBdrmInf(obj)
                .then((response) => ( this.cctvBdrmRst(response.data) ))
                .catch((error) => console.log('upload error /bnfcr/delCctvBdrmInf : ' + error))
        },
        cctvBdrmRst: function(res){
            let obj = {}
            obj.actv = true                     // 경고 노출여부
            obj.tmr = 2500                      // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                   // 경고 유형 (success, info, warning, error)
            obj.title = res.message             // 경고 타이틀
            obj.cntnt = []                      // 경고 내용 (arr만 출력됨!)

            this.$store.commit('setWrnnInf', obj)

             if(res.statusCode == 200){                                                
                let obj = {
                    fcltyNum    : this.$store.getters.getFcltyNum,
                    bnMmbrPk    : this.$store.getters.getBnfcrState.bnmmbr,
                    agrYyyy     : this.year
                }

                this.getCctvBdrmInfo(obj)
                this.getCctvBdrmList()
            }
            else{
                obj.tmr = 3500
                obj.type = 'warning'
            }

            this.$store.commit('setWrnnInf', obj)
        },
        getCctvBdrmInfoAfter:function(res){
            this.clearForm()

            if(res !='' && res !=null && res !=undefined){
                this.cctvInfo.agrYyyy           = res.agrYyyy
                this.cctvInfo.agrYn             = res.agrYn
                this.cctvInfo.agrDtStrt         = res.agrDtStrt?.beforeDateFormatHyp() 
                this.cctvInfo.agrDtEnd          = res.agrDtEnd?.beforeDateFormatHyp()
                this.cctvInfo.agrRcvNm          = res.agrRcvNm
                this.cctvInfo.agrRcvRltns       = res.agrRcvRltns
                this.cctvInfo.celphn1           = res.celphn1
                this.cctvInfo.celphn2           = res.celphn2
                this.cctvInfo.celphn3           = res.celphn3
                this.cctvInfo.wrtr              = res.wrtr 
                this.cctvInfo.wrtDt             = res.wrtDt?.beforeDateFormatHyp() 
                this.cctvInfo.cctvBdrmInfPk     =  res.cctvBdrmInfPk
                this.cctvInfo.bnMmbrPk          =  res.bnMmbrPk
                this.cctvInfo.grdnMmbrPk        =  res.grdnMmbrPk

                this.bnfcrCntrc = Object.assign({}, this.prtBnfcrCntrc)
            }else{
                this.getFmlyList()
                this.getBnfcrInfoDetail()
                //선택 년도 마지막날
                let lastYearDay = this.$moment(this.year+'1201','YYYYMMDD').endOf('month').format('YYYY-MM-DD')
                this.cctvInfo.agrDtEnd          = lastYearDay
            }     
        },
        getCctvBdrmListAfter: function (res){
            let path = remoteURL + 'cmm/eSigImgDownload?'
            let fcltyNum = 'fcltyNum=' + this.$store.getters.getFcltyNum

            res.forEach(el => {
                if(el.agrYn == '1')
                    el.agrYnNm = '예'
                else if(el.agrYn == '2')
                    el.agrYnNm = '아니오'
                
                el.bnfcrSrc = path + fcltyNum + '&eSigClcd=12&bzClcdPk=' + el.cctvBdrmInfPk + '&t=' + new Date().getTime()
                el.grdnSrc = path + fcltyNum + '&eSigClcd=13&bzClcdPk=' + el.cctvBdrmInfPk + '&t=' + new Date().getTime()

                this.cctvBdrmList.push(el)
            });
        },  
        getFmlyListAfter:function(res){
            if(res != undefined && res != '' && res != null){
                this.cctvInfo.grdnMmbrPk    = res.mmbrPk
                this.cctvInfo.agrRcvNm      = res.name
                this.cctvInfo.agrRcvRltns   = res.rltns
                this.cctvInfo.celphn1       = res.celphn_1
                this.cctvInfo.celphn2       = res.celphn_2
                this.cctvInfo.celphn3       = res.celphn_3
            }
        },
        getBnfcrInfoDetailAfter:function(res){
            let entYear = res.entDt.slice(0,4)
            if(this.year == entYear) this.cctvInfo.agrDtStrt = res.entDt?.beforeDateFormatHyp()
            else  this.cctvInfo.agrDtStrt = this.year+'-01-01'
        },
        setCctvBdrmInfAfter:function(res){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200){
                let obj = {
                    fcltyNum    : this.$store.getters.getFcltyNum,
                    bnMmbrPk    : this.$store.getters.getBnfcrState.bnmmbr,
                    cctvBdrmInfPk     : res.result
                }
                this.getCctvBdrmInfo(obj)
                this.getCctvBdrmList()
            }
            else{
                obj.tmr = 3500
                obj.type = 'warning'
            }

            this.$store.commit('setWrnnInf', obj)
        },
        clearForm: function () {
            this.cctvInfo.wrtDt = this.$moment().format('YYYY-MM-DD')
            this.cctvInfo.agrYn     = ''
            this.cctvInfo.agrYyyy   = ''
            this.cctvInfo.agrDtStrt = '' 
            this.cctvInfo.agrDtEnd  = ''
            this.cctvInfo.agrRcvNm  = ''
            this.cctvInfo.agrRcvRltns  = ''
            this.cctvInfo.celphn1   = ''
            this.cctvInfo.celphn2   = ''
            this.cctvInfo.celphn3   = ''
            this.cctvInfo.userNm    = '' 
            this.cctvInfo.wrtr      = '' 
            this.cctvInfo.cctvBdrmInfPk =  0
            this.cctvInfo.bnMmbrPk    =  0
            this.cctvInfo.grdnMmbrPk  =  0

            this.$refs.cctvBdrmInfo.resetValidation()
        },
        tblEvt: function (idx) {
            if(this.sltCctvIndex == idx){
                this.sltCctvIndex = -1                
            } else {
                this.sltCctvIndex = idx

                let obj = {
                    fcltyNum    : this.$store.getters.getFcltyNum,
                    bnMmbrPk    : this.$store.getters.getBnfcrState.bnmmbr,
                    cctvBdrmInfPk     : this.sltCctvIndex
                }
                
                this.getCctvBdrmInfo(obj)
            }

        },
        getYear: function (yyyy){
            this.year = yyyy
             
            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum,
                bnMmbrPk    : this.$store.getters.getBnfcrState.bnmmbr,
                agrYyyy     : yyyy
            }

            this.getCctvBdrmInfo(obj)
        },   
        cctvBdrmMsg:function(){            
            this.$parent.$parent.$parent.$parent.bizMsgInfo.bzTitle = 'CCTV 침실 촬영 동의'
            this.$parent.$parent.$parent.$parent.bizMsgInfo.docClcd = 23
            this.$parent.$parent.$parent.$parent.sendMsgAct(this.cctvInfo.cctvBdrmInfPk)
        },
        rcrdPrt:function(obj){
            this.$parent.$parent.$parent.$parent.cnsnFlmnPrt(obj.cctvBdrmInfPk, obj.agrYyyy)
        },

        // 동의 내역 삭제
        rmAgrBtn: function (pk) {
            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            obj.title = 'CCTV 침실 촬영 동의를 삭제합니다.'
            obj.cntnt = ['삭제된 정보는 복구가 불가능하며 영구적으로 제거됩니다.']
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 삭제하겠습니다.'

            let exObj = {}
            exObj.cctvBdrmInfPk = pk

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 600, prsst: true, typ: '1001', inObj: obj, outObj: exObj, runEvt: false,
            });

            this.runAble = '2'
        },

        // 서명 팝업 오픈
        onSigMdl: function (clcd, itm) {
            let obj = {}
            
            if(clcd == '12')
                obj.mmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            else if(clcd == '13')
                obj.mmbrPk = itm.grdnMmbrPk

            obj.bzClcdPk = itm.cctvBdrmInfPk
            obj.eSigClcd = clcd

            this.$store.commit('setTmpObj', obj)

            this.onLclMdl('17', 450)
        },

        // 서명 팝업 초기화
        rstSig: function () {
            this.rstLclMdlInf()

            if(this.$store.getters.getBnfcrState.bnmmbr != 0)
                this.getCctvBdrmList()
        },

        // 통합 팝업 오픈
        onLclMdl: function (txt, wdt) {
            this.lclMdlInf.typ = txt
            this.lclMdlInf.maxWidth = wdt
            this.lclMdlInf.isOn = true
        },

        // 통합 팝업 초기화
        rstLclMdlInf: function () {
            this.lclMdlInf.typ = ''
            this.lclMdlInf.isOn = false
        },
    },
    
    data: () => ({
        runAble: '2',
        year: '',
        cctvBdrmList: [],
        cctvInfo: { 
            fcltyNum: 0, 
            cctvBdrmInfPk : 0, 
            bnMmbrPk      : 0,
            grdnMmbrPk    : 0,
            agrYyyy       : '',
            agrDtStrt     : '', 
            agrDtEnd      : '',
            agrRcvNm      : '',
            agrRcvRltns   : '',
            celphn1       : '',
            celphn2       : '',
            celphn3       : '',
            userNm        : '', 
            wrtr          : '', 
            wrtDt         : '', 
        },
        lclMdlInf: { isOn: false, maxWidth: 800, typ: '' },     // 통합 팝업 제어 (17: 서명)
    }),
};
</script>