<template>
    <v-sheet class="ma-0 pa-7" rounded="md">
        <v-row class="mb-4 pa-0" no-gutters>
            <v-col class="d-flex justify-start align-center" cols="8">
                <span class="text-h4 font-weight-bold">1:1 문의하기</span>
                <span class="ml-2">
                    <v-btn
                        width="20" height="20" @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 36)" icon>
                        <v-icon size="20">mdi-help-circle-outline</v-icon>
                    </v-btn>
                </span>
            </v-col>
            <v-spacer></v-spacer>            
            <v-col class="d-flex justify-end align-center" cols="2">
                <v-spacer></v-spacer>
                <span v-if="progress">
                    <v-progress-circular            
                    :size="30"
                    color="primary"
                    indeterminate
                    ></v-progress-circular>
                </span>
                <span v-if="!progress">
                    <v-btn v-for="(list, i) in filledBtn" :key="i" @click="onClickBtn(i)" :class="list.class" height="30"
                        :color="list.color" rounded :disabled="list.disabled" style="font-size: 14px;">
                        <v-icon class="mr-1" size="18">{{ list.icon }}</v-icon>
                        {{ list.text }}
                    </v-btn>
                </span>
                <v-icon class="ml-3" color="black" x-large @click="onClose">mdi-close</v-icon>
            </v-col>
        </v-row>    
        
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mr-1" no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center pa-2 ma-0" cols="2">
                <span class="black--text font-weight-medium">분류</span>
            </v-col>
            <v-col class="pa-2 ma-0" cols="2">
                <v-select
                    v-model="bbsInf.bbsCtgryCd"
                    :key="forceRender"
                    :items="$parent.$parent.$parent.$parent.vCd124"
                    item-text="valcdnm" item-value="valcd"
                    dense hide-details outlined>
                </v-select>
            </v-col>
            <v-col class="greyE01 d-flex justify-center align-center pa-2 ma-0" cols="2">
                <span class="black--text font-weight-medium">제목</span>
            </v-col>
            <v-col class="pa-2 ma-0" cols="6">
                <v-text-field v-model="bbsInf.bbsTitle" class="ml-1" hide-details outlined dense></v-text-field>
            </v-col>
        </v-row>        
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mr-1" no-gutters>
            <v-col class="pa-2 ma-0 overflow-y-auto edt-resize" cols="12">
                <span v-if="bbsInf.bbsCtgryCd == '3'" class="fontEighthRem">※ 환불요청시에는 환불받으실 수 있는 은행명, 계좌번호, 예금주를 남겨주시기 바랍니다.</span>
                <TipTapEditor @editorToHtml="getTipTapHtml" @editorToJson="getTipTapJson" :editorCnt="cntnt" ></TipTapEditor>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mr-1" no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center pa-2 ma-0" cols="2">
                <span class="black--text font-weight-medium">첨부파일</span>
                <span class="d-inline-block">
                    <v-file-input
                        class="athFileInput" v-model="files" truncate-length="5" @change="upFile()"
                        :disabled="fileList.length < 5 ? false : true"
                        prepend-icon="mdi-upload" multiple counter hide-input outlined dense hide-details>
                    </v-file-input>
                </span>
            </v-col>
            <v-col class="pa-2 ma-0" cols="10">
                <div style="width:100%;height:40px;">                      
                    <v-chip v-for="(item, j) in fileList" :key="j"                        
                        label                        
                        close
                        class="ma-1"
                        outlined
                        @click="onDownload(j)"
                        @click:close="isDelete(j)"
                    >
                        <v-icon v-if="item.ext=='pdf' || item.ext=='PDF'" color='pink' left>
                            mdi-file-pdf-box
                        </v-icon>
                        <v-icon v-else-if="item.ext=='xlsx' || item.ext=='xls'" color='green' left>
                            mdi-microsoft-excel
                        </v-icon>
                        <v-icon v-else-if="item.ext=='pptx' || item.ext=='ppt'" color='red' left>
                            mdi-file-powerpoint
                        </v-icon>
                        <v-icon v-else-if="item.ext=='doc' || item.ext=='docx'" color='blue' left>
                            mdi-file-word
                        </v-icon>
                        <v-icon v-else color='cyan' left>
                            mdi-file-document
                        </v-icon>
                        {{ item.bbsFileOrgNm }}
                    </v-chip> 
                </div>
            </v-col>
        </v-row>        
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mr-1" no-gutters>
            <v-col class="pa-2 ma-0 fontOneRem" cols="12">                
                <p class="ma-0 pa-0">※ 1:1 답변안내.</p>
                <p class="ma-0 pa-0 pl-2">1. 근무일 기준 1~2일 이내로 답변됩니다. 접수 순서대로 순차적으로 답변이 진행됩니다. (같은 날 여러 건의 글을 남기시면 답변 순서가 미뤄질 수 있습니다)</p>
                <p class="ma-0 pa-0 pl-2">2. 정확한 답변을 받으시려면 최대한 <b>구체적으로 자세히</b> 적어주셔야 합니다. <b>문의하시려는 화면을 첨부</b>하시면 문의사항 파악에 많은 도움이 됩니다.</p>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
    </v-sheet>
</template>

<script>
import { insQnaBbs, } from '../../api/othr.js';
import { selHomBbsInfo, selHomBbsFileList, tmpHomBbsUpload, homFileDownload, delHomBbsFile } from '../../api/hom.js';
import TipTapEditor from '../../components/commons/TipTapEditor.vue';

export default {
    name: 'YysQnaWrt',

    props : {
   
    },
        
    components: {
        TipTapEditor,
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.$parent.$parent.$parent.$parent.sltRow.bbsPk > 0) {
                this.bbsInf.bbsPk = this.$parent.$parent.$parent.$parent.sltRow.bbsPk
                this.preGetHomBbsInfo()
            } else {
                this.bbsInf.bbsCtgryCd = this.$parent.$parent.$parent.$parent.srchSlt
            }
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch:{
        getSubEvt: function (bol) {
            if(bol && this.rmQnaBbsFile){
                this.onDelete()
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.rmQnaBbsFile = false
            }
        },
        'cntnt': function () {
            this.tiptapValue = this.cntnt
        },
    },
    
    methods: { 
        onInfoMsg:function(){
            let obj = {}
            obj.actv = true  
            obj.tmr = 10000                  // 경고 노출시간(-1: 무제한)
            obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
            obj.title = '1:1 문의 답변안내'
            obj.cntnt = [
                '1. 근무일 기준 1~2일 이내로 답변됩니다.'
                ,'접수 순서대로 순차적으로 답변이 진행됩니다.'
                ,'(같은 날 여러 건의 글을 남기시면 답변 순서가 미뤄질 수 있습니다)'
                ,'2. 정확한 답변을 받으시려면 최대한 구체적으로 자세히 적어주셔야'
                ,' 합니다. '
                ,'문의하시려는 화면을 첨부하시면 문의사항 파악에 많은 도움이 됩니다.'
            ]
            this.$store.commit('setWrnnInf', obj)
        },

        onClose: function () {
            this.$emit('isClose')
        },

        preGetHomBbsInfo:function(){
            let obj = {                
                bbsPk  : this.bbsInf.bbsPk,
            }

            this.getHomBbsInfo(obj)
        },

        onClickBtn:function(key){
            switch (key) {
                case 0:
                    this.preQnaBbs()
                    break;
            
                default:
                    break;
            }
        },

        //질문하기
        preQnaBbs:function(){

            if(this.bbsInf.bbsCtgryCd == ''){
                let obj = {}
                obj.actv = true  
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = '분류를 선택해주세요.'         // 경고 타이틀
                this.$store.commit('setWrnnInf', obj)

            } else {
                let obj = {                
                    bbsPk               : this.bbsInf.bbsPk,
                    bbsTitle            : this.bbsInf.bbsTitle,
                    bbsCntnt            : this.tiptapValue,
                    bbsCntntHtml        : this.tiptapValue,
                    bbsNotiYn           : '2',
                    bbsCtgryCd          : this.bbsInf.bbsCtgryCd,
                    files               : [],
                }
                
                if(this.fileList.length > 0){
                    this.fileList.forEach(el => {
                        if(el.bbsFilePk == 0) obj.files.push(el)
                    });
                }
                this.progress = true
                this.setQnaBbs(obj)
            }


        },

        //첨부파일업로드
        upFile:function(){
            let formData = new FormData()
            let len      = 0
            
            if(this.files != null){
                this.files.forEach(element => {
                    formData.append("files", element)
                    len++
                })
                
            }

            if(len > 0){
                this.setTmpHomBbsUpload(formData)
            }
        },

        //파일다운로드
        onDownload:function(idx){
            let obj = {
                bbsFilePk:0,
                bbsFilePth:'',
                bbsFileNm:'',
                bbsFileOrgNm:'',
            }
            obj.bbsFilePk       =  this.fileList[idx].bbsFilePk
            obj.bbsFilePth      =  this.fileList[idx].bbsFilePth
            obj.bbsFileNm       =  this.fileList[idx].bbsFileNm
            obj.bbsFileOrgNm    =  this.fileList[idx].bbsFileOrgNm
            
            this.getHomFileDownload(obj)
        },

        //첨부파일 삭제 여부
        isDelete:function(idx){
            this.sltFile.bbsFilePk      = this.fileList[idx].bbsFilePk
            this.sltFile.bbsFilePth     = this.fileList[idx].bbsFilePth
            this.sltFile.bbsFileNm      = this.fileList[idx].bbsFileNm
            this.sltFile.bbsFileOrgNm   = this.fileList[idx].bbsFileOrgNm

            if(this.sltFile.bbsFilePk > 0){
                
                let obj = {}
                obj.icon = 'mdi-alert'
                obj.iconClr = 'red'
                obj.title = '첨부파일'
                obj.cntnt = ['첨부파일 '+this.fileList[idx].bbsFileOrgNm+'을(를) 삭제하시겠습니까?']

                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 삭제하겠습니다.'

                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });

                this.rmQnaBbsFile = true

            } else {
                this.fileList.splice(idx,1)
            }   
        },

        onDelete :function(){
            if(this.sltFile.bbsFilePk > 0){

                let obj = {
                    fcltyNum        : this.$store.getters.getFcltyNum,
                    userNm          : this.$store.getters.getUserNm,
                    bbsFilePk       : this.sltFile.bbsFilePk
                }

                this.setDelHomBbsFile(obj)
            }else{
                let obj = {}
                obj.actv = true  
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = '대상을 찾을 수 없습니다.'         // 경고 타이틀
                this.$store.commit('setWrnnInf', obj)
            }
        },

        getHomBbsInfo:function(obj){
            selHomBbsInfo(obj)
                .then((response) => ( this.getHomBbsInfoAfter(response.data) ))
                .catch((error) => console.log('upload error /hominf/selHomBbsInfo : ' + error))
        },

        getHomBbsFileList:function(){
            this.fileList.splice(0)

            let obj = {
                fcltyNum            : this.$store.getters.getFcltyNum,
                bbsPk               : this.bbsInf.bbsPk,
            }

            selHomBbsFileList(obj)
                .then((response) => ( this.getHomBbsFileListAfter(response.data) ))
                .catch((error) => console.log('upload error /hominf/selHomBbsFileList : ' + error))
        },

        

        setQnaBbs:function(obj){
            insQnaBbs(obj)
                .then((response) => ( this.setQnaBbsAfter(response.data) ))
                .catch((error) => console.log('upload error /hominf/insQnaBbs : ' + error))
        },

        setTmpHomBbsUpload:function(formData){
            tmpHomBbsUpload(formData)
                .then((response) => ( this.setTmpHomBbsUploadAfter(response.data) ))
                .catch((error) => console.log('upload error /hominf/tmpHomBbsUpload : ' + error))
        },

        getHomFileDownload:function(obj){
            homFileDownload(obj)
                .then((response) => ( this.getHomFileDownloadAfter(response) ))
                .catch((error) => console.log('upload error /hominf/homFileDownload : ' + error))
        },

        setDelHomBbsFile:function(obj){
            delHomBbsFile(obj)
                .then((response) => ( this.setDelHomBbsFileAfter(response.data) ))
                .catch((error) => console.log('upload error /hominf/delHomBbsFile : ' + error))
        },
        
        getHomBbsInfoAfter:function(res){
            console.log(res)
            let obj = {}
            obj.actv = true   
            
            if(res.statusCode == 200){     
                if(res.result != null){
                    this.bbsInf.bbsTitle            = res.result.bbsTitle
                    this.bbsInf.bbsCntnt            = res.result.bbsCntnt
                    this.bbsInf.bbsCntntHtml        = res.result.bbsCntntHtml
                    this.bbsInf.bbsNotiYn           = res.result.bbsNotiYn
                    this.bbsInf.bbsCtgryCd           = res.result.bbsCtgryCd
                    this.bbsInf.bbsAnswr           = res.result.bbsAnswr
                    
                    this.cntnt = res.result.bbsCntntHtml
                }
                
            }
            else{                
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
                this.$store.commit('setWrnnInf', obj)
            }

        },

        setQnaBbsAfter:function(res){
            this.progress = false
            let obj = {}
            obj.actv = true   
            
            if(res.statusCode == 200){                                                         
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!) 
                this.$emit('modalEvent')
                this.$emit('restRow')
                this.$emit('isClose')
            }
            else{                
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
            }

            this.$store.commit('setWrnnInf', obj)
        },

        setTmpHomBbsUploadAfter:function(res){
            this.progress = false
            let obj = {}
            obj.actv = true   
            
            if(res.statusCode == 200){                                               
                             
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)   

                if(res.files != null && res.files.length > 0){
                    res.files.forEach(el => {
                        el.bbsFilePk = 0 
                        this.fileList.push(el)
                    });                    
                }

                this.files = null

            }
            else{                
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
                this.$store.commit('setWrnnInf', obj)
            }
            this.key = ''
        },

        getHomFileDownloadAfter:function(res){
            
            let contentDisposition = decodeURI(res.headers['content-disposition'])
       
            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'));
                if (fileNameMatch)
                    [ , fileName ] = fileNameMatch.split('=');
            }
            link.href = url;
 
            fileName = fileName.replaceAll('"', '')
            link.setAttribute('download', `${fileName}`);
            link.style.cssText = 'display:none';
            document.body.appendChild(link);
            link.click();
            link.remove();
        },

        setDelHomBbsFileAfter:function(res){
            let obj = {}
            obj.actv = true   
            
            if(res.statusCode == 200){                                         
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)     
                this.getHomBbsFileList()            
            }
            else{                
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
            }

            this.$store.commit('setWrnnInf', obj)
        },

        getHomBbsFileListAfter:function(res){
            let obj = {}
            obj.actv = true   
            
            if(res.statusCode == 200){                                         
                if(res.result != null){
                    res.result.forEach(el => {
                        this.fileList.push(el)
                    });
                }
            }
            else{                
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
                this.$store.commit('setWrnnInf', obj)
            }
        },
        
        getTipTapHtml: function (html) {
            this.tiptapValue = html
        },
        getTipTapJson: function (json) {
            this.tiptapJSON = json
        },
    },

    data: () => ({
        forceRender:0,
        progress:false,
        rmQnaBbsFile:false,
        bbsInf:{
            bbsPk:0,
            bbsId:'',
            bbsTitle:'',
            bbsCntnt:'',
            bbsCntntHtml:'',
            bbsNotiYn:'',
            bbsCtgryCd:'',
            bbsAnswr:'',
        },
        sltFile:{
            bbsFilePk:0,
            bbsPk:0,
            bbsFilePth:'',
            bbsFileNm:'',
            bbsFileOrgNm:'',
        },
        files:null,
        fileList:[],
        cntnt: '',
        tiptapValue: '',
        tiptapJSON: '',        
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'white--text', color:'blueBtnColor', text: '저장', disabled: false, }, 
        ],
    }),
};
</script>

<style>
    .edt-resize div .tiptap .tiptapEditor .ProseMirror { height: 400px !important; }
</style>