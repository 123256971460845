<template>
    <v-sheet>
        <v-form ref="tabform" lazy-validation>
            
            <div class="d-flex">       
                
                <div class="d-inline-block pa-0 ma-0 align-self-start" style="width:680px;border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;">            
                     <v-row class="pa-0 ma-0" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">                                
                            <span class="black--text font-weight-medium">항목</span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="10">
                          <span class="black--text font-weight-medium">확인</span>
                        </v-col>
                    </v-row>
                    <v-row v-for="(item, i) in dsrEvltnListL" :key="i" class="pa-0 ma-0" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="border-top: 1px solid #dbdbdb !important;" cols="2">                                
                            <span class="black--text font-weight-medium">{{item.clsfc3Cdnm}}</span>
                        </v-col>
                        <v-col style="border-top: 1px solid #dbdbdb;border-left: 1px solid #dbdbdb !important;" cols="10">
                            <!-- <div v-if="item.clsfc3Cd=='21'">
                                <div v-for="(list, h) in item.list" :key="h" class="d-inline-block">
                                    <div v-if="list.itmCd =='3'" class="py-1" style="width:120px">
                                        <v-checkbox v-model="list.itmVal"  hide-details :ripple="false" :label="list.itmCdnm" value="1"></v-checkbox>
                                    </div>
                                    <div v-else-if="list.itmCd =='4'" class="py-1" style="width:50px">
                                        <v-checkbox v-model="list.itmVal"  hide-details :ripple="false" :label="list.itmCdnm" value="1"></v-checkbox>
                                    </div>
                                    <div v-else-if="list.itmCd =='5'" class="d-flex align-center" style="width:180px">                                        
                                        <span>&#40;</span>
                                        <span style="width:150px"><v-text-field v-model="list.evltn" hide-details outlined dense></v-text-field></span>
                                        <span>&#41;</span>
                                    </div>
                                    <div v-else class="py-1" style="width:80px">
                                        <v-checkbox v-model="list.itmVal"  hide-details :ripple="false" :label="list.itmCdnm" value="1"></v-checkbox>
                                    </div>                                    
                                </div>
                            </div> -->
                            <div v-if="item.clsfc3Cd=='27'">
                                <div v-for="(list, h) in item.list" :key="h" class="d-inline-block">
                                    <div v-if="list.itmCd =='1'" class="py-1" style="width:120px">
                                        <v-checkbox
                                            class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                            v-model="list.itmVal" :ripple="false" :label="list.itmCdnm" value="1"
                                            hide-details>
                                        </v-checkbox>
                                    </div>
                                    <div v-else-if="list.itmCd =='2'" class="d-flex align-center" style="width:90px">
                                        <span>&#40;</span>
                                        <span style="width:80px">
                                            <v-checkbox
                                                class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                v-model="list.itmVal"
                                                :ripple="false" :label="list.itmCdnm" value="1"
                                                hide-details>
                                            </v-checkbox>
                                        </span>
                                    </div>
                                    <div v-else-if="list.itmCd =='3'" class="d-flex align-center" style="width:90px">  
                                        <span style="width:80px">
                                            <v-checkbox
                                                v-model="list.itmVal"
                                                class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                :ripple="false" :label="list.itmCdnm" value="1"
                                                hide-details>
                                            </v-checkbox>
                                        </span>
                                        <span>&#41;</span>
                                    </div>
                                    <div v-else class="d-flex py-1 align-center" style="width:180px">
                                        <span>
                                            <v-checkbox
                                            v-model="list.itmVal"
                                            class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                            :ripple="false" :label="list.itmCdnm" value="1"
                                            hide-details>
                                        </v-checkbox>
                                        </span>
                                        <span>&#40;</span>
                                        <span style="width:118px">
                                            <v-text-field v-model="list.evltn" height="28" hide-details outlined dense></v-text-field>
                                        </span>
                                        <span>&#41;</span>
                                    </div>                                    
                                </div>
                            </div>
                            <!-- <div v-else-if="item.clsfc3Cd=='28'">
                                <div v-for="(list, h) in item.list" :key="h" class="d-inline-block">
                                    <div v-if="list.itmCd =='1'" class="py-1" style="width:80px">
                                        <v-checkbox v-model="list.itmVal"  hide-details :ripple="false" :label="list.itmCdnm" value="1"></v-checkbox>
                                    </div>                                    
                                    <div v-else-if="list.itmCd =='2' || list.itmCd =='4'" class="py-1" style="width:55px">
                                        <v-checkbox v-model="list.itmVal"  hide-details :ripple="false" :label="list.itmCdnm" value="1"></v-checkbox>
                                    </div>
                                    <div v-else-if="list.itmCd =='3' || list.itmCd =='5'" class="d-flex align-center" style="width:180px">                                        
                                        <span>&#40;</span>
                                        <span style="width:150px"><v-text-field v-model="list.evltn" hide-details outlined dense></v-text-field></span>
                                        <span>&#41;</span>
                                    </div>
                                    <div v-else class="d-flex py-1 align-center" style="width:180px">
                                        <span><v-checkbox v-model="list.itmVal"  hide-details :ripple="false" :label="list.itmCdnm" value="1"></v-checkbox></span>
                                        <span>&#40;</span>
                                        <span style="width:150px"><v-text-field v-model="list.evltn" hide-details outlined dense></v-text-field></span>
                                        <span>&#41;</span>
                                    </div>                               
                                </div>
                            </div> -->
                            <div v-else>
                                <div v-for="(list, h) in item.list" :key="h" class="d-inline-block">
                                    <div v-if="list.valClcd =='1'" class="py-1" style="width:120px">
                                        <v-checkbox
                                            v-model="list.itmVal"
                                            class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                            :ripple="false" :label="list.itmCdnm" value="1"
                                            hide-details>
                                        </v-checkbox>
                                    </div>
                                    <div v-else class="d-flex py-1" style="width:200px">
                                        <span>
                                            <v-checkbox
                                                v-model="list.itmVal"
                                                class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                :ripple="false" :label="list.itmCdnm" value="1"
                                                hide-details>
                                            </v-checkbox>
                                        </span>
                                        <span>&#40;</span>
                                        <span style="width:138px">
                                            <v-text-field v-model="list.evltn" height="28" hide-details outlined dense></v-text-field>
                                        </span>
                                        <span>&#41;</span>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </div>

                <div class="d-inline-block pa-0 ml-3 align-self-start" style="width:680px;border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;">            
                    <v-row class="pa-0 ma-0" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">                                
                            <span class="black--text font-weight-medium">항목</span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="10">
                          <span class="black--text font-weight-medium">확인</span>
                        </v-col>
                    </v-row>

                    <v-row v-for="(item, j) in dsrEvltnListR" :key="j" class="pa-0 ma-0" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="border-top: 1px solid #dbdbdb !important;" cols="2">                                
                            <span class="black--text font-weight-medium">{{item.clsfc3Cdnm}}</span>
                        </v-col>
                        <v-col class="pa-1" style="border-top: 1px solid #dbdbdb;border-left: 1px solid #dbdbdb !important;" cols="10">                            
                            <div v-if="item.clsfc3Cd=='28'">
                                <div v-for="(list, k) in item.list" :key="k" class="d-inline-block">
                                    <div v-if="list.itmCd =='9'" class="d-inline-block">
                                        <div class="d-flex py-1">
                                            <span>
                                                <v-checkbox
                                                    v-model="list.itmVal"
                                                    class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                    :ripple="false" :label="list.itmCdnm" value="1"
                                                    hide-details>
                                                </v-checkbox>
                                            </span>
                                            <span>&#40;</span>
                                            <span style="width:300px">
                                                <v-text-field v-model="list.evltn" height="28" hide-details outlined dense></v-text-field>
                                            </span>
                                            <span>&#41;</span>
                                        </div>
                                    </div>
                                    <div v-else class="d-inline-block">
                                        <div v-if="list.valClcd =='1'" class="py-1" style="width:120px">
                                            <v-checkbox
                                                v-model="list.itmVal"
                                                class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                :ripple="false" :label="list.itmCdnm" value="1"
                                                hide-details>
                                            </v-checkbox>
                                        </div>
                                        <div v-else class="d-flex py-1" style="width:200px">
                                            <span>
                                                <v-checkbox
                                                    v-model="list.itmVal"
                                                    class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                    :ripple="false" :label="list.itmCdnm" value="1"
                                                    hide-details>
                                                </v-checkbox>
                                            </span>
                                            <span>&#40;</span>
                                            <span style="width:138px">
                                                <v-text-field v-model="list.evltn" height="28" hide-details outlined dense></v-text-field>
                                            </span>
                                            <span>&#41;</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="item.clsfc3Cd=='11' || item.clsfc3Cd=='12'">
                                <div v-for="(list, k) in item.list" :key="k">  
                                    <v-textarea v-model="list.evltn" class="rmrksArea"
                                        rows="3" outlined dense hide-details no-resize>
                                    </v-textarea>
                                </div>
                            </div>
                            <div v-else>
                                <div v-for="(list, k) in item.list" :key="k">  
                                    <v-textarea v-model="list.evltn" class="rmrksArea"
                                        rows="12" outlined dense hide-details no-resize>
                                    </v-textarea>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </v-form>        
    </v-sheet>
</template>
<script>
import { groupBy } from '../../api/common.js';
import { insBnfcrExamHis} from '../../api/bnftSrv.js';

export default {
    name: 'DsrEvltnTab2',

    props : {
          
    },

    computed: {
       
    },  

    watch:{
       
    },

    created:function(){
        this.$nextTick(function () { 
            // let value = this.$store.getters.getBnfcrExamHisPk;
            // this.onShow(value);  
        })   
    },

    components: {
    },
    
    mounted: function(){
       
    },
        
    methods: {
        onShow(value){
            this.getData(value);  
        },
        setStyle:function(value){
            let style = "";
            if(value > 13){
                style="width:400px;"
            }else if(value > 10 && value <= 13){
                style="width:160px;"
            }else if(value > 5 && value <= 10){
                style="width:130px;"
            }else if(value == 2){
                style="width:80px;"
            }else{
                style="width:100px;"
            }
            return style;
        },        
        onClose: function () {
            this.$emit('modalClose', this.dsrEvltn)
        },

        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },

        setData(data){       
            this.dsrEvltnListL.splice(0)
            this.dsrEvltnListR.splice(0)

            let groups = groupBy(data, 'clsfc3Cd')

            Object.keys(groups).forEach((key)  => {
                let obj = {
                    clsfc3Cd : key,
                    clsfc3Cdnm : groups[key][0].clsfc3Cdnm,
                    list : groups[key]
                }

                switch (key) {
                    case '28':                        
                        this.dsrEvltnListR.splice(0,0,obj)
                        break;
                    case '11':                        
                        this.dsrEvltnListR.push(obj)
                        break;
                    case '12':                        
                        this.dsrEvltnListR.push(obj)
                        break;
                    case '13':                        
                        this.dsrEvltnListR.push(obj)
                        break;
                    default:
                        this.dsrEvltnListL.push(obj)
                        break;
                }
            }); 

            this.$refs.tabform.resetValidation();
        },

        insBnfcrExam(data){
            if(this.$refs.tabform.validate()){
                //수급자평가 내역 setting

                let obj = {
                    fcltyNum:this.$store.getters.getFcltyNum,
                    userNm:this.$store.getters.getUserNm,
                    bnMmbrPk:data.bnMmbrPk,
                    bnfcrExamHisPk:data.bnfcrExamHisPk,
                    examClcd:'101',                    
                    examRsncd : data.examRsncd,
                    examDt : data.examDt.afterDateFormatHyp(),
                    wrtr : data.wrtr,
                    examYyyy : data.inqYYYY,
                }

                //욕구사정평가 setting
                let examList = []
            
                this.dsrEvltnListL.forEach(x => {
                    x.list.forEach(item => { 
                        //선택되지 않을경우                       
                        if(item.itmVal != '1') item.itmVal = '2'

                        if(item.valClcd =='2' && item.itmVal != '1') item.evltn=''

                        examList.push(item)
                    });
                })

                this.dsrEvltnListR.forEach(x => {
                    x.list.forEach(item => { 
                        //선택되지 않을경우                       
                        if(item.itmVal != '1') item.itmVal = '2'


                        //기타 선택 안될경우 
                        let bl = (item.clsfc3Cd == '11' || item.clsfc3Cd == '12' || item.clsfc3Cd == '13')

                        if(item.valClcd =='2' && item.itmVal != '1' && !bl) item.evltn=''

                        examList.push(item)
                    });
                })
                
                insBnfcrExamHis(obj,examList)
                    .then((response) => ( this.insBnfcrExamAfter(response.data)))
                    .catch((error) => console.log(error))                
            }
        },
        insBnfcrExamAfter: function (res) {
            this.$emit('dsrEvltnTrigger', res)            
        },        
        selExamList:function(){
            //욕구사정평가 setting
            let examList = []            
            this.dsrEvltnListL.forEach(x => {
                x.list.forEach(item => { 
                    //선택되지 않을경우                       
                    if(item.itmVal != '1') item.itmVal = '2'

                    if(item.valClcd =='2' && item.itmVal != '1') item.evltn=''

                    examList.push(item)
                });
            })

            this.dsrEvltnListR.forEach(x => {
                x.list.forEach(item => { 
                    //선택되지 않을경우                       
                    if(item.itmVal != '1') item.itmVal = '2'


                    //기타 선택 안될경우 
                    let bl = (item.clsfc3Cd == '11' || item.clsfc3Cd == '12' || item.clsfc3Cd == '13')

                    if(item.valClcd =='2' && item.itmVal != '1' && !bl) item.evltn=''

                    examList.push(item)
                });
            })
            return examList
        }
    },
    
    data: () => ({ 
        obj:{},
        dsrEvltnListL:[],  
        dsrEvltnListR:[],         
        pHist:{}, //과거병력
        crnDgn:{}, //현 진단명
        jdgBss:{}, //판단근거
        items:[],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
        },
        
    }),
};
</script>