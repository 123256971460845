<template>
    <v-sheet>        
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col class="d-flex" cols="12">
                <v-spacer></v-spacer>
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn v-if="list.type == 'action'" @click="onClickBtn(i)"
                        :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                        <v-icon small>{{list.icon}}</v-icon>
                        <span style="font-size: 1rem;">{{list.text}}</span>
                    </v-btn>
                </span>
            </v-col>
        </v-row>
        <div class="mt-2 pa-0">
            <div class="d-flex greyE01 fontOneRem mr-2__5">
                <div class="py-2 d-flex justify-center align-center tableBT tableBB" style="width:5%;">
                    <span class="d-inline-block ml-1" style="width:26px;">
                        <v-checkbox
                            class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                            v-model="allChckr" :key="forceRender" @click="allChnger"
                            true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                            hide-details>
                        </v-checkbox>
                    </span>
                </div>
                <div class="py-2 d-flex justify-center align-center tableBT tableBB tableBL" style="width:10%;">
                    <span class="black--text font-weight-medium">수급자</span>
                </div>
                <div class="py-2 d-flex justify-center align-center tableBT tableBB tableBL" style="width:10%;">
                    <span class="black--text font-weight-medium">현황</span>
                </div>
                <div class="py-2 d-flex justify-center align-center tableBT tableBB tableBL" style="width:10%;">
                    <span class="black--text font-weight-medium">동의여부</span>
                </div>                
                <div class="py-2 d-flex justify-center align-center tableBT tableBB tableBL" style="width:10%;">
                    <span class="black--text font-weight-medium">작성일</span>
                </div>
                <div class="py-2 d-flex justify-center align-center tableBT tableBB tableBL" style="width:10%;">
                    <span class="black--text font-weight-medium">보호자</span>
                </div>
                <div class="py-2 d-flex justify-center align-center tableBT tableBB tableBL" style="width:10%;">
                    <span class="black--text font-weight-medium">관계</span>
                </div>
                <div class="py-2 d-flex justify-center align-center tableBT tableBB tableBL" style="width:10%;">
                    <span class="black--text font-weight-medium">연락처</span>
                </div>
                <div class="py-2 d-flex justify-center align-center tableBT tableBB tableBL" style="width:10%;">
                    <span class="black--text font-weight-medium">발송일</span>
                </div>
                <div class="py-2 d-flex justify-center align-center tableBT tableBB tableBL" style="width:10%;">
                    <span class="black--text font-weight-medium">발송결과</span>
                </div>                
                <div class="py-2 d-flex justify-center align-center tableBT tableBB tableBL" style="width:5%;">                    
                    <span class="black--text font-weight-medium">미리보기</span>
                </div>
            </div>
            <div v-if="cctvSndList.length > 0" style="height:510px;overflow-y:scroll">
                <div v-for="(item, i) in cctvSndList" :key="i" class="d-flex" style="font-size:1rem;">
                    <div class="d-flex justify-center align-center tableBB" style="width:5%;">
                        <span class="d-inline-block ml-1" style="width:26px;">
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                v-model="item.checkr" :key="forceRender" :disabled="item.disChekr"
                                true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline" @change="allChekrFunc"
                                hide-details>
                            </v-checkbox>
                        </span>
                    </div>
                    <div class="d-flex justify-center align-center tableBB tableBL" style="width:10%;">
                        {{item.name}}
                    </div>
                    <div class="d-flex justify-center align-center tableBB tableBL" style="width:10%;">
                        {{item.entStcd}}
                    </div>
                    <div class="d-flex justify-center align-center tableBB tableBL" style="width:10%;">
                        <v-icon v-if="item.agrYn == '1'" color="blue">mdi-check</v-icon>
                    </div>                
                    <div class="d-flex justify-center align-center tableBB tableBL" style="width:10%;">
                        {{ item.wrtDt?.beforeDateFormatDot() }}
                    </div>
                    <div class="d-flex justify-center align-center tableBB tableBL" style="width:10%;">
                        {{ item.agrRcvNm }}
                    </div>
                    <div class="d-flex justify-center align-center tableBB tableBL" style="width:10%;">
                        {{ item.agrRcvRltns }}
                    </div>
                    <div class="d-flex justify-center align-center tableBB tableBL" style="width:10%;">
                        <span>{{item.celphn1}}</span>
                        <span class="px-1">&#45;</span>
                        <span>{{item.celphn2}}</span>
                        <span class="px-1">&#45;</span>
                        <span>{{item.celphn3}}</span>
                    </div>
                    <div class="d-flex justify-center align-center tableBB tableBL" style="width:10%;">
                        {{item.smsSndSttsNm}}
                    </div>
                    <div class="d-flex justify-center align-center tableBB tableBL" style="width:10%;">
                        {{item.smsSndDt?.beforeDateFormatDot()}}
                    </div>                
                    <div class="d-flex justify-center align-center tableBB tableBL" style="width:5%;">                    
                        <div class="d-flex justify-center align-center">   
                            <v-btn icon @click="onModal(item)">
                                <v-icon>mdi-text-box-outline</v-icon>
                            </v-btn>                              
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;">
                    <span class="black--text font-weight-medium">※ 목록이 없습니다.</span>
                </div>
            </div>
        </div>
        <v-dialog v-model="dialog[0]" max-width="333" content-class="round" eager>
            <BnfcrCctvSmsViw ref="bnfcrCctvSmsViw" @modalClose="isClose"></BnfcrCctvSmsViw>
        </v-dialog>
    </v-sheet>
</template>
<script>
import BnfcrCctvSmsViw from './BnfcrCctvSmsViw.vue'
import { insBizMsgNoSnd } from '../../api/othr.js'

export default {
    name: 'BnfcrCctvSms',

    props : {       
        
    },
            
    components: {      
        BnfcrCctvSmsViw  
    },

    computed: {
       
    },  

    watch:{        
    },

    created:function(){ 
        this.$nextTick(function () { 
        })
    },
    
    mounted: function(){
        
    },

    methods: {
        onClickBtn:function(key){
            switch (key) {
                case 0:
                    this.onSend()
                    break;
            
                default:
                    break;
            }
        },
        onSend:function(){            
            let pObj = {  
                fcltyNum:this.$store.getters.getFcltyNum,
                userNm:this.$store.getters.getUserNm,                
                bizMsglist:[]
            }
            this.cctvSndList.forEach(item => {
                if(item.cctvBdrmInfPk != null && item.cctvBdrmInfPk > 0){

                    if(item.checkr == '1'){    
                        let obj = {
                            sndMda:'2',
                            fcltyNum:this.$store.getters.getFcltyNum,
                            userNm:this.$store.getters.getUserNm,
                            bzClcd:'29',
                            bzPk:item.cctvBdrmInfPk,
                            eDocClcd:'23',      
                            prcClcdAr:['1'],                  
                            prcCnfmAr:[],
                            prtCdAr:[], 
                            prtCd:'37',
                            ntcClcd:'1',   
                            rcvCelphn1:item.grdCelphn_1,
                            rcvCelphn2:item.grdCelphn_2,
                            rcvCelphn3:item.grdCelphn_3,
                            mmbrPk:item.grdnMmbrPk,
                            bnMmbrPk:item.bnMmbrPk,
                            rcvNm:item.agrRcvNm,
                            rcvInfm:item.name+' 보호자',
                            pstnCd:'2',
                            lnkUrl:item.lnkUrl
                        }
    
                        pObj.bizMsglist.push(obj)
                    }
                }
            });

            if(pObj.bizMsglist.length > 0){
                insBizMsgNoSnd(pObj)
                    .then((response) => ( this.onSendAfter(response.data) ))
                    .catch((error) => console.log('connect error /othr/insBizMsgNoSnd : ' + error))
            }else{
                this.$emit('msgTrigger','대상자를 선택해주세요.')
            }
        },
        onSendAfter:function(res){
            this.$emit('mdlTrigger',res)            
        },        
        setData:function(data){        
            this.cctvSndList.splice(0)
            /*
            this.cctvSndList = JSON.parse(JSON.stringify(data))

            this.cctvSndList.forEach(el => {
                el.checkr = '2'
                el.disChekr = false
            });
            */
           data.forEach(el => {
                if(el.msClcd == '2') {
                    el.checkr = '2'
                    el.disChekr = false
                    this.cctvSndList.push(el)
                }
            });
        },
        allChnger: function () {
            if(this.allChckr == '1') {
                this.cctvSndList.forEach(item => {
                    if(item.disChekr == false) item.checkr = '1'
                });
            }
            else if(this.allChckr == '2') {
                this.cctvSndList.forEach(item => {
                    if(item.disChekr == false) item.checkr = '2'
                });
            }
        },
        onModal:function(obj){            
            this.$refs.bnfcrCctvSmsViw.onLoad(obj)
            this.dialog.splice(0,1,true)
        },
        isClose:function(){
            this.dialog.splice(0,1,false)
        } ,
        allChekrFunc: function () {
            let tCh = '1'
            this.cctvSndList.forEach(el => {
                if(el.checkr == '2') tCh = '2'
            });
            this.allChckr = tCh
        },        
    },
    
    data: () => ({         
        forceRender:0,
        allChckr:false,
        cctvSndList:[],
        dialog:[false,],
        filledBtn: [
            { icon: 'mdi-send-variant', class: 'ml-1 white--text', color:'blueBtnColor', text: '문자 발송', type: 'action', width:'600px', disabled: false, },
        ],
    }),
};
</script>