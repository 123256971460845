<template>
    <v-sheet class="ma-0 overflow-y-auto" rounded="md">
        <v-form ref="sdlform" lazy-validation>
            <v-divider class=""></v-divider>
            <v-row no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="font-weight-medium">작성자</span>
                </v-col>
                <v-col class="d-flex justify-left align-center" cols="10">
                    <span class="d-inline-block pa-1" style="width:120px;">
                        <v-text-field
                            v-model="wrtrS" :rules="[rules.required]"
                            height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <v-btn 
                        class="brown lighten-3 d-inline-block" style="padding:0px 1px 0px 2px !important"
                        min-width="30" height="28" @click="dialog.splice(0, 1, true)"
                        dark small depressed>
                        <v-tooltip top >
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                            </template>
                            <span>직원선택</span>
                        </v-tooltip>
                    </v-btn>
                    <span class="red--text font-weight-mideum ml-5">※&nbsp;주&nbsp;1회&nbsp;작성</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="greyE01" no-gutters>
                <v-col class="d-flex justify-center align-center py-1 tableBR" cols="2">
                    <span class="font-weight-medium">구분</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-1 tableBR" cols="4">
                    <span class="font-weight-medium">세부점검사항</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-1 tableBR" cols="1">
                    <span class="d-inline-block ngh" style="text-align: center;">
                        <v-icon color="black" small>mdi-checkbox-blank-circle-outline</v-icon>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-1 tableBR" cols="1">
                    <span class="d-inline-block ngh" style="text-align: center;">
                        <v-icon color="black" small>mdi-triangle-outline</v-icon>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-1 tableBR" cols="1">
                    <span class="d-inline-block ngh" style="text-align: center;">
                        <v-icon color="black" small>mdi-close</v-icon>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="3">
                    <span class="font-weight-medium">소독방법</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="" v-for="n in inspTitle.length" :key="n" no-gutters>
                <v-col class="d-flex justify-center align-center py-1 tableBR tableBB" cols="2">
                    <span class="">{{inspTitle[n-1]}}</span>
                </v-col>
                <v-col class="tableBR" cols="4">
                    <div v-for="(list, i) in inspRecDtllist" :key="i">
                        <div v-if="list.clsfc3CdNm == inspTitle[n-1]">
                            <div v-if="((list.clsfc3Cd == '1' && list.itm1Cd == '4') || (list.clsfc3Cd == '1' && list.itm1Cd == '6') || (list.clsfc3Cd == '1' && list.itm1Cd == '8'))">
                            </div>
                            <div v-else class="tableBB pl-2">
                                <div v-if="((list.clsfc3Cd == '1' && list.itm1Cd == '3') || (list.clsfc3Cd == '1' && list.itm1Cd == '5') || (list.clsfc3Cd == '1' && list.itm1Cd == '7'))">
                                    <span>{{ list.itm1CdNm }}</span>
                                    <span class="d-inline-block pl-2" style="padding: 1.57px 0px;">
                                        <v-text-field
                                            v-model="list.itm1Val" type="number" height="28"
                                            hide-details outlined dense>
                                        </v-text-field>
                                    </span>
                                    <span class="pl-1">℃</span>
                                </div>
                                <div class="py-1" v-else>
                                    {{ list.itm1CdNm }}
                                </div>
                            </div>
                        </div>
                    </div>
                </v-col>
                <v-col class="" cols="3">
                    <div v-for="(list, i) in inspRecDtllist" :key="i">
                        <div v-if="!((list.clsfc3Cd == '1' && list.itm1Cd == '3') || (list.clsfc3Cd == '1' && list.itm1Cd == '5') || (list.clsfc3Cd == '1' && list.itm1Cd == '7'))"
                        class="">
                            <v-radio-group
                                v-if="list.clsfc3CdNm == inspTitle[n-1]"
                                v-model="list.itm1Val" :key="forceRender"
                                class="radio-dft-Theme ma-0 pa-0 d-flex justify-center align-center d-inline-block tableBB"
                                style="width: 100%;"
                                hide-details row>
                                <v-radio class="d-inline-block ma-0 tableBR d-flex justify-center" style="width:33.3333%; height: 31.11px;"
                                    on-icon="mdi-circle-outline" off-icon="mdi-minus-circle-outline" :ripple="false" value="1"></v-radio>
                                <v-radio class="d-inline-block ma-0 tableBR d-flex justify-center" style="width:33.3333%;"
                                    on-icon="mdi-triangle-outline" off-icon="mdi-minus-circle-outline" :ripple="false" value="2"></v-radio>
                                <v-radio class="d-inline-block ma-0 d-flex justify-center tableBR" style="width:33.3333%;"
                                    on-icon="mdi-close" off-icon="mdi-minus-circle-outline" :ripple="false" value="3"></v-radio>
                            </v-radio-group>
                        </div>
                    </div>
                </v-col>
                <v-col class="" cols="3">
                    <div v-for="(list, i) in inspRecDtllist" :key="i">
                        <div class="d-flex justify-center align-center tableBB"
                            v-if="!((list.clsfc3Cd == '1' && list.itm1Cd == '3') || (list.clsfc3Cd == '1' && list.itm1Cd == '5') || (list.clsfc3Cd == '1' && list.itm1Cd == '7'))">
                            <span :class="['d-inline-block d-flex justify-center align-center', list.itm2Val == '3' ? 'halfPerWidth' : 'fullPerWidth']" style="height: 31.11px;">
                                <v-select class="hsptlSelect" 
                                    v-model="list.itm2Val" height="28" :key="forceRender"
                                    item-text="valcdnm" item-value="valcd" :items="$parent.$parent.$parent.$parent.vCd48"
                                    dense hide-details outlined>
                                </v-select>
                            </span>
                            <span v-if="list.itm2Val == '3'" class="d-inline-block pl-1" style="width:48%;">
                                <v-text-field
                                    v-model="list.itm3Val" height="28" hide-details outlined dense>
                                </v-text-field>
                            </span>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-form>
        <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
            <IntgrSlctn
                :mdlTtl="'직원 선택'" :tabInfList="['2']"
                :fixList="[]" :mode="'1'" :rtrInc="true" :rtrTyp="'1'"
                :cd41="$parent.$parent.$parent.$parent.vCd41"
                :cd42="$parent.$parent.$parent.$parent.vCd42"
                :cd65="$parent.$parent.$parent.$parent.vCd65"
                @intgrResList="getEmpInfo" @onClose="dialog.splice(0, 1, false)">
            </IntgrSlctn>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { selInspRecDtl1list, insInspRecDtl, delInspRecDtl } from '../../api/sfty.js';

import IntgrSlctn from '../commons/IntgrSlctn.vue';

export default {
    name: 'SntDsnLog',

    props : {
       
    },
        
    components: {
        IntgrSlctn,
    },

    created: function(){
       
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.$refs.sdlform.resetValidation()
        })
    },

    computed: {
        getSltItem: function () {
            return this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem
        },
    },

    watch:{
        getSltItem: function (value) {
            let sch = this.$parent.$parent.$parent.$parent.$parent.$parent.sftyList.findIndex(v => v.inspDt == value)
            if(sch != -1){
                let pkkk = this.$parent.$parent.$parent.$parent.$parent.$parent.sftyList[sch].inspRecPkS
                this.$parent.$parent.$parent.$parent.btnControll(1, false, 'fill')
                this.getInspRecDtllist(pkkk)
            }
            else if(sch == 1)
                this.$parent.$parent.$parent.$parent.btnControll(1, true, 'fill')
        },
    },
    
    methods: {
        //위생소독일지 조회
        getInspRecDtllist: function (pk){
            selInspRecDtl1list(this.$store.getters.getFcltyNum, pk, this.$parent.$parent.$parent.$parent.$parent.$parent.sltDate, 504, 1)
                .then((response) => ( this.getInspRecDtllistAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/selInspRecDtl1list : ' + error))
        },
        //위생소독일지 저장
        setInspRecDtl: function (obj){
            this.$parent.$parent.$parent.$parent.btnControll(1, true, 'fill')
            this.$parent.$parent.$parent.$parent.btnControll(0, true, 'fill')
            
            insInspRecDtl(obj)
                .then((response) => ( this.setInspRecAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/insInspRecDtl : ' + error))
        },
        //위생소독일지 삭제
        removeInspRecDtl: function (pk){
            this.$parent.$parent.$parent.$parent.btnControll(1, true, 'fill')
            this.$parent.$parent.$parent.$parent.btnControll(0, true, 'fill')

            delInspRecDtl(this.$store.getters.getFcltyNum, pk, this.$store.getters.getUserNm )
                .then((response) => ( this.setInspRecAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/delInspRecDtl : ' + error))
        },
        getInspRecDtllistAfter: function (res){
            this.inspTitle.splice(0)
            this.inspRecDtllist.splice(0)

            let keyList = []
            res.forEach(items => {
                if(keyList.length == 0)
                    keyList.push(items.clsfc3CdNm)
                else{
                    let tmp = keyList.find(v => v === items.clsfc3CdNm)
                    if(tmp == undefined) keyList.push(items.clsfc3CdNm)
                }

                if(items.itm2Val == null)
                    items.itm2Val = '3'
            });

            this.$parent.$parent.$parent.$parent.$parent.$parent.sftyList.forEach(items => {
                if(this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem == items.inspDt){
                    this.wrtrS = items.wrtrS
                    this.mmbrS = items.wrtrMmbrPkS
                }
            });

            this.inspTitle = keyList
            this.inspRecDtllist = res

            this.$refs.sdlform.resetValidation()
        },
        setInspRecAfter: function (res){
            if(res.statusCode == 200){
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])
                // this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk = 0
                // this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem = -1
                this.$parent.$parent.$parent.$parent.$parent.$parent.getTableList()
                this.$parent.$parent.$parent.$parent.tab = 0
                
                this.clearForm()
                this.$parent.$parent.$parent.$parent.btnControll(1, false, 'fill')
                this.$parent.$parent.$parent.$parent.btnControll(0, false, 'fill')
            }
            else
                this.$parent.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])
        },
        preInspRecDtllist: function (){
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm
            obj.clsfc1Cd = 504
            obj.clsfc2Cd = 1
            let sss = this.$parent.$parent.$parent.$parent.$parent.$parent.sftyList.findIndex(v => v.inspRecPk == this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk)
            if(sss == -1)
                obj.inspRecPk = 0
            else
                obj.inspRecPk = this.$parent.$parent.$parent.$parent.$parent.$parent.sftyList[sss].inspRecPkS
            obj.inspDt = this.$parent.$parent.$parent.$parent.$parent.$parent.sltDate
            obj.list = []

            this.inspRecDtllist.forEach(items => {               
                items.inspDtlDt = this.$parent.$parent.$parent.$parent.$parent.$parent.sltDate
                items.inspDt = this.$parent.$parent.$parent.$parent.$parent.$parent.sltDate
                items.wrtr = this.wrtrS
                items.wrtrMmbrPk = this.mmbrS
                items.inspTmHh = '00'
                items.inspTmMm = '00'
  
                obj.list.push(items)
            });

            this.setInspRecDtl(obj)
        },  
        clearForm: function (){
            this.inspTitle.splice(0)
            this.inspRecDtllist.splice(0)
            this.wrtrS = ''
            this.mmbrS = 0
            this.$refs.sdlform.resetValidation()

            this.forceRender += 1
        },
        setDftVal: function () {
            this.inspRecDtllist.forEach(rtl => {
                if(rtl.itm1Val == null || rtl.itm1Val == '0') rtl.itm1Val = '1'
            });

            let chc = this.inspRecDtllist.findIndex( v => v.itm1CdNm == '아침 소독기 온도' )
            if(chc > -1)
                this.inspRecDtllist[chc].itm1Val = 0

            chc = this.inspRecDtllist.findIndex( v => v.itm1CdNm == '점심 소독기 온도' )
            if(chc > -1)
                this.inspRecDtllist[chc].itm1Val = 0

            chc = this.inspRecDtllist.findIndex( v => v.itm1CdNm == '저녁 소독기 온도' )
            if(chc > -1)
                this.inspRecDtllist[chc].itm1Val = 0
        },
        getEmpInfo: function (obj) {
            this.wrtrS = obj[0].name
            this.mmbrS = obj[0].mmbrPk
            this.setDftVal()
        },
    },

    data: () => ({
        dialog: [false],
        inspTitle: [],
        inspRecDtllist: [],
        wrtrS: '',
        mmbrS: 0,
        forceRender: 0,
        rules: {
            required: value => !!value || 'Required.',
        },
    }),
};
</script>

<style>
    .fullPerWidth { width: 98%; }
    .halfPerWidth { width: 48%; }
</style>