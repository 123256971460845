<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" min-height="700">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">급여제공&nbsp;결과평가</span>
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn v-if="list.visible" @click = "onClickBtn(list.text)"
                    :class="list.class" height="30" :color="list.color" rounded
                    :disabled="btnDisabled"
                    style="font-size: 14px;">
                    <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                    {{list.text}}
                </v-btn>
            </span>
            <v-btn v-for="(list, j) in outlinedBtn" :key="filledBtn.length + j"
                :disabled="list.disabled"
                :class="list.class" samll height="30" :color="list.color" rounded outlined>
                <v-icon small>{{list.icon}}</v-icon>
                {{list.text}}
            </v-btn>
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <basicInfo :bnMmbrPk="bnMmbrPk"></basicInfo>
        <v-form ref="form" lazy-validation>
            <v-row class="pa-0 my-2" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="1">
                    <span class="black--text font-weight-medium">평가연도</span>
                </v-col>
                <v-col class="py-1 pl-2 d-flex justify-center align-center" cols="1">
                    {{items.plnYyyy}}
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="1">
                    <span class="black--text font-weight-medium">평가일</span>
                </v-col>
                <v-col class="pa-1 ma-0 d-flex justify-start align-center" cols="2">
                    <span class="white d-inline-block py-1 pl-2" style="width: 154px;"> 
                        <CmmDateComp v-model="items.wrtDt" :required="true" :minDt="minDate" :maxDt="maxDate"></CmmDateComp>                        
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="1">
                    <span class="black--text font-weight-medium">작성자</span>
                </v-col>
                <v-col class="pa-1 ma-0 d-flex justify-start align-center" cols="6">
                    <span class="d-inline-block py-1 pl-2" style="width: 120px;">
                        <v-text-field
                            v-model="items.wrtr" :rules="[rules.required]" :key="forceRender"
                            color="blue" height="28" ref="info"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <v-btn 
                        class="ml-1 brown lighten-3" 
                        small 
                        min-width="30"
                        height="25"  
                        dark
                        style="padding:0px 1px 0px 2px !important"
                        @click="onModal"
                        >
                        <v-icon size="20">mdi-account-edit</v-icon>
                    </v-btn>
                </v-col>            
            </v-row>

            <div class="d-flex"> 
                <div class="d-inline-block pa-0 ma-0 align-self-start" style="width:480px;"> 
                    <div class="d-flex align-center mt-2">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">총평&#40;종합소견&#41;</span>
                    </div>
                    <v-row class="pa-0 my-0" no-gutters>
                        <v-col class="pa-2 d-flex justify-center align-center" cols="12">
                            <v-textarea
                                v-model="items.cmnts"
                                :rules="[rules.required]" class="rmrksArea" no-resize  
                                rows="20" outlined dense hide-details>
                            </v-textarea>
                        </v-col>
                    </v-row>
                </div>
                <div class="d-inline-block pa-0 ml-3 align-self-start" style="width:1050px;">
                    <div class="d-flex align-center mt-2">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">급여제공계획</span>
                    </div>
                    <div class="mt-2" style="font-size:0.9rem !important;">
                        <div class="d-flex greyE01 pa-0 mr-2__5">
                            <div class="d-flex justify-center align-center tableBT tableBB pa-1" style="width:15%;" >
                                <exBtn class="mr-1" :btnData="exBtn[0]" @btnClosed="expandClose" ref="exBtnClose0" @changeCheck="filterSearch"></exBtn>
                                <span class="black--text font-weight-medium">장기요양 필요영역</span>
                            </div>
                            <div class="tableBT tableBL" style="width:85%;">
                                <div class="d-flex">
                                    <div class="d-flex py-2 justify-center align-center tableBB" style="width:35%">
                                        <span class="black--text font-weight-medium">장기요양 세부목표</span>
                                    </div>
                                    <div class="tableBL" style="width:65%">
                                        <div class="d-flex tableBB">
                                            <div class="d-flex py-2 justify-center align-center" style="width:23%">
                                                <span class="black--text font-weight-medium">장기요양 필요내용</span>
                                            </div>
                                            <div class="tableBL d-flex py-2 justify-center align-center" style="width:47%">
                                                <span class="black--text font-weight-medium">세부 제공내용</span>
                                            </div>
                                            <div class="tableBL d-flex py-2 justify-center align-center" style="width:10%">
                                                <span class="black--text font-weight-medium">주기</span>
                                            </div>
                                            <div class="tableBL d-flex py-2 justify-center align-center" style="width:10%">
                                                <span class="black--text font-weight-medium">횟수</span>
                                            </div>
                                            <div class="tableBL d-flex py-2 justify-center align-center" style="width:10%">
                                                <span class="black--text font-weight-medium">시간(분)</span>
                                            </div>
                                            <div class="tableBL d-flex py-2 justify-center align-center" style="width:10%">
                                                <span class="black--text font-weight-medium">작성자</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="height:455px;overflow-y:scroll;" class="ma-0 pa-0">
                            <div v-if="listPln.length > 0">
                                <div v-for="(item, i) in listPln"  :key="i">
                                    <div v-if="(srhObj != '') ? (srhObj.findIndex(x=>x == item.clsfc2Cdnm) != -1) : true" class="d-flex pa-0 ma-0">
                                        <div class="d-flex justify-center align-center tableBB pa-1" style="width:15%;" >
                                            <span class="black--text font-weight-medium">{{item.clsfc2Cdnm}}</span>
                                        </div>
                                        <div class="tableBL" style="width:85%;">
                                            <div class="d-flex" v-for="(list,j) in item.list" :key="j" >
                                                <div class="d-flex py-1 justify-center align-center tableBB" style="width:35%;">
                                                    {{list.dtldGls}}
                                                </div>
                                                <div class="tableBL" style="width:65%">
                                                    <div class="d-flex tableBB" v-for="(subitm,k) in list.subList" :key="k">
                                                        <div class="d-flex py-2 justify-center align-center" style="width:23%">
                                                            {{subitm.clsfc3Cdnm}}
                                                        </div>
                                                        <div class="tableBL d-flex py-2 justify-center align-center" style="width:47%;">
                                                            <div class="px-1">{{subitm.itmCdnm}}</div>
                                                        </div>
                                                        <div class="tableBL d-flex py-2 justify-center align-center" style="width:10%">
                                                            {{subitm.itmVal1}}
                                                        </div>
                                                        <div class="tableBL d-flex py-2 justify-center align-center" style="width:10%">
                                                            {{subitm.itmVal2}}
                                                        </div>
                                                        <div class="tableBL d-flex py-2 justify-center align-center" style="width:10%">
                                                            {{subitm.itmVal3}}
                                                        </div>
                                                        <div class="tableBL d-flex py-2 justify-center align-center" style="width:10%">
                                                            {{subitm.wrtr}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else style="height:400px;overflow-y:auto;">
                                <div class="d-flex pa-0 ma-0">
                                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;height:380px;" >
                                        <span class="black--text font-weight-medium">※ 목록이 없습니다.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <v-divider class="mr-2__5"></v-divider>
                    </div>  
                </div>
            </div>
        </v-form>
        
        <v-dialog v-model="dialogDelete" max-width="500">    
            <btnModalDelete :title="'급여제공 결과평가'" @madalState="onDelete"></btnModalDelete>  
        </v-dialog>   
        <v-dialog v-model="menu[1]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="isModal" @outEmp="getEmpInfo" :prtWrtDt = "prtWrtDt"></EmpSelector>
        </v-dialog>   
    </v-sheet>
</template>
<script>
import CmmDateComp from '../commons/CmmDateComp.vue';
import basicInfo from '../bnfcr/BnfcrBasicInfo.vue';
import {getBnftOfrPlnHisInfo,  insBnftOfrPlnHis, delBnftOfrPlnHis, selBnftOfrPlnList} from '../../api/bnftSrv.js';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import EmpSelector from '../commons/EmpSelector.vue';
import exBtn from '../commons/expandButtonCustom.vue';

export default {
    name: 'BnftSrvRsltEvltn',

    props : {
        inqYYYY: Number,        
        bnMmbrPk : { type: Number, default: -1 },
        bnfcrPk : { type: Number, default: -1 },
        bnftSrvRsltEvltnPk : { type: Number, default: -1 },
        btnDisabled : { type: Boolean, default: false },
    },
        
    components: {
        CmmDateComp,
        basicInfo,
        btnModalDelete,
        EmpSelector,
        exBtn,
    },
    

    watch: {     
        'bnftSrvRsltEvltnPk':function(value){            
            if(value > -1){
                console.log(this.bnfcrPk)
                this.bnftOfrPlnHisPk = value
                this.onShow()
            }
        }
    },

    mounted: function(){             
        this.$nextTick(function () {              
            this.bnftOfrPlnHisPk = this.bnftSrvRsltEvltnPk
            this.onShow()
        })
    },
    
    methods: {
        onShow : function(){
            this.getInit()
            this.getOfrPlnHisInfo()
            this.getBnftOfrPlnList()
            
        },
        onClose: function () {
            this.bnftOfrPlnHisPk=0
            this.close()
            this.$emit('modalEvent', 9)
        },

        getInit(){
            this.items = Object.assign({})
            let date = new Date()                                    
            let dateStr = date.toISOString().split('T',2)[0]
            this.items.wrtDt = dateStr
            this.items.wrtr = this.$store.getters.getUserNm;
            this.minDate = this.inqYYYY+'-'+'01'+'-'+'01'
            this.maxDate = this.inqYYYY+'-'+'12'+'-'+'31'
            this.forceRender += 1
        },

        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },

        onClickBtn: function(text) {
            if(text=="급여제공결과 평가반영"){
                this.$parent.$parent.$parent.$parent.plnBzPk = this.bnftOfrPlnHisPk
                this.onClose()
                this.$parent.$parent.$parent.$parent.showDialog(7,0)
            } else if(text=="저장"){
                this.insBnftOfrPlnHis()                               
            } else if(text=="삭제"){
                if(this.bnftOfrPlnHisPk > 0){
                    this.dialogDelete = true
                }
            }
        },
        setStyle:function(value){
            let style = "";
            if(value > 1){
                style=""            
            }else{
                style="height:100% !important;"
            }
            return style;
        },  

        getOfrPlnHisInfo(){

            if(this.bnftOfrPlnHisPk !='' && this.bnftOfrPlnHisPk > 0){    
                //결과평가 반영 오픈 
                this.filledBtn[0].visible = true           
                let fcltyNum = this.$store.getters.getFcltyNum
                let state = {
                    bnMmbrPk :  this.bnMmbrPk,
                    inqYYYY : this.inqYYYY,
                    bnftOfrPlnHisPk : this.bnftOfrPlnHisPk,
                }

                getBnftOfrPlnHisInfo(fcltyNum, state)
                .then((response) => ( this.getOfrPlnHisInfoAfter(response.data)))
                .catch((error) => console.log(error))
            }else{                
                this.items.plnYyyy = this.inqYYYY
                this.filledBtn[0].visible = false
            }
        },
        getOfrPlnHisInfoAfter(data){
            data.wrtDt = data.wrtDt?.beforeDateFormatHyp()
            this.items = data

            this.$refs.form.resetValidation();
        },

        getBnftOfrPlnList: function(){              
            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum,
                bnMmbrPk    : this.bnMmbrPk,
                inqYYYY     : this.inqYYYY,
                bnfcrPk     : this.bnfcrPk,
            }

            selBnftOfrPlnList(obj)
            .then((response) => ( this.getBnftOfrPlnListAfter(response.data)))
            .catch((error) => console.log(error))
        },

        getBnftOfrPlnListAfter(data){   
            this.listPln.splice(0) 
            this.exBtn[0].expandList.splice(0)

            data.outList.forEach(el => {
                el.visible = true
                this.listPln.push(el)
                this.exBtn[0].expandList.push(el.clsfc2Cdnm)
            });
            this.$refs.form.resetValidation();
        },
        

        insBnftOfrPlnHis(){
            if(this.$refs.form.validate()){
                let ofrPlnHisInfo = {
                    fcltyNum : this.$store.getters.getFcltyNum,
                    userNm : this.$store.getters.getUserNm,
                    bnftOfrPlnHisPk:this.bnftOfrPlnHisPk,
                    bnMmbrPk : this.bnMmbrPk,
                    wrtDt :  this.items.wrtDt?.afterDateFormatHyp(),
                    wrtr : this.items.wrtr,
                    mdfClcd : '3',
                    plnClcd:'201',
                    cmnts : this.items.cmnts,
                    aplStrt: this.items.plnYyyy,
                }

                insBnftOfrPlnHis(ofrPlnHisInfo, null)
                    .then((response) => ( this.insBnftOfrPlnHisAfter(response.data)))
                    .catch((error) => console.log(error))
            }
           
        },

        insBnftOfrPlnHisAfter: function (res) {
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.bnftOfrPlnHisPk = Number(res.result)
                this.getOfrPlnHisInfo();
                //수급자 평가내역 Reload
                this.$emit('modalEvent', 99)
            }
            else{
                 let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
                // this.snackControll([true, 5000, res.message, 'error'])
            }        
        },

        onDelete:function(res){
            if(res == 9990){
                if(this.bnftOfrPlnHisPk > 0){
                    let code = this.$store.getters.getFcltyNum;
                    let value = this.bnftOfrPlnHisPk;
                    let user = this.$store.getters.getUserNm
                    delBnftOfrPlnHis(code, value, user)
                    .then((response) => ( this.delBnftOfrPlnHisAfter(response.data)))
                    .catch((error) => console.log(error))
                }
            }else if(res == 7){
                this.dialogDelete = false
            }

        },

        delBnftOfrPlnHisAfter:function(res){
            if(res.statusCode == 200){                                                
                this.$emit('modalEvent', 99)
                this.onClose()
            }
            else{
                this.$store.commit('setBnftOfrPlnHisPk', '')
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },

        close(){
            this.bnftOfrPlnHisPk = 0
            this.srhObj = []
            this.exBtn[0].expandList.splice(0)
        },

        snackControll: function (options) {
            this.snackOptions = options
        },
        
        getEmpInfo: function (obj) {
            this.items.wrtr = obj[0].name
        },
        onModal: function () {
            this.prtWrtDt = this.items.wrtDt
            this.menu.splice(1, 1, true)
        },
        isModal: function () {
            this.menu.splice(1, 1, false)
        },
        expandClose: function (index) {
            switch(index) {
                case 0: 
                    break;
                case 1: this.$refs.exBtnClose0.isClose()
                    break;
                default: this.$refs.exBtnClose0.isClose()
                    break;
            }
        },
        filterSearch:function(srh){ 
            this.srhObj = srh     
        }
    },
    
    data: () => ({   
        minDate:'',
        maxDate:'',
        prtWrtDt:'',         
        items:{},
        menu: [false,false,false],
        bnftOfrPlnHisPk : 0,
        groups:[],
        tmpgroups:[],
        listPln:[], 
        srhObj:[],
        exBtn: [
            {
                index:  0,
                btnTitle: '',
                btnIcon: 'listChecks',
                exWidth: 154,
                exHeigth: 300,
                expandList: [],
            },
        ],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },
        filledBtn: [
            { icon: 'mdi-pencil-outline', class: 'white--text', color:'blueBtnColor', text: '급여제공결과 평가반영', visible: false, },
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', visible: true, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type: 'dialogue', visible: true, },
        ],
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006 ', text: '출력', disabled:true  },
        ],
        snackOptions: [false, 3000, '', 'info'],
        forceRender:0,
        dialogDelete:false,
    }),
};
</script>