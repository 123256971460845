<template>
    <v-sheet class="ma-0" rounded="md">
        <div class="d-flex mx-2">
            <v-spacer></v-spacer>
            <span v-if="progress">
                <v-progress-circular            
                :size="30"
                color="primary"
                indeterminate
                ></v-progress-circular>
            </span>
            <span v-if="!progress">
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn :class="list.class" height="30" :color="list.color"
                        :disabled="list.disabled" @click="onClickBtn(i)" samll rounded>
                        <v-icon small>{{list.icon}}</v-icon>
                        <span style="font-size: 1rem;">{{list.text}}</span>
                    </v-btn>
                </span>
            </span>
        </div>
        <v-row class="pa-2" no-gutters>
            <v-col class="pa-0 ma-0" cols="6">
                <v-divider class=""></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center" cols="3">
                        <span class="black--text font-weight-medium">템플릿</span>
                    </v-col>
                    <v-col class="pa-1" cols="9">
                        <span class="d-inline-block" style="width: 132px;">
                            <v-select
                                v-model="hominf.fcltyHomTmpltCd" :key="forceRender"
                                :items="TmpltCode"
                                item-text="valcdnm" item-value="valcd"
                                dense hide-details outlined>
                            </v-select>
                        </span>
                    </v-col>                    
                </v-row>
                <v-divider class=""></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center" cols="3">
                        <span class="black--text font-weight-medium">상담시간</span>
                    </v-col>
                    <v-col class="pa-1" cols="9">
                        <span class="d-inline-block ml-1" style="width: 80px;">
                            <CmmTimeField v-model="hominf.srvStartTm" :required="true"></CmmTimeField>
                        </span>
                        <span class="d-inline-block ml-1">&#126;</span>
                        <span class="d-inline-block ml-1" style="width: 80px;">
                            <CmmTimeField v-model="hominf.srvEndTm" :required="true"></CmmTimeField>
                        </span>
                    </v-col>                    
                </v-row>
                <v-divider class=""></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center" cols="3">
                        <span class="black--text font-weight-medium">시설소개 제목</span>
                    </v-col>
                    <v-col class="pa-1" cols="9">
                        <v-text-field v-model="hominf.fcltyHomIntroTitle" height="28" hide-details outlined dense></v-text-field>
                    </v-col>                    
                </v-row>
                <v-divider class=""></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center" cols="3">
                        <span class="black--text font-weight-medium">시설소개</span>
                    </v-col>
                    <v-col class="pa-1" cols="9">
                        <v-textarea
                            class="rmrksArea" rows="10"
                            v-model="hominf.fcltyHomIntroCntnt"
                            no-resize hide-details outlined dense>
                        </v-textarea>
                    </v-col>                    
                </v-row>
                <v-divider class=""></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center" cols="3">
                        <span class="black--text font-weight-medium">Blog Link</span>
                    </v-col>
                    <v-col class="pa-1" cols="9">
                        <v-text-field v-model="hominf.fcltyHomSocialUrl1" height="28" hide-details outlined dense></v-text-field>
                    </v-col>                    
                </v-row>
                <v-divider class=""></v-divider>
                <!-- <v-row class="pa-0" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center" cols="3">
                        <span class="black--text font-weight-medium">소셜 링크2</span>
                    </v-col>
                    <v-col class="pa-1" cols="9">
                        <v-text-field v-model="hominf.fcltyHomSocialUrl2"  hide-details outlined dense></v-text-field>
                    </v-col>                    
                </v-row>
                <v-divider class=""></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center" cols="3">
                        <span class="black--text font-weight-medium">소셜 링크3</span>
                    </v-col>
                    <v-col class="pa-1" cols="9">
                        <v-text-field v-model="hominf.fcltyHomSocialUrl3"  hide-details outlined dense></v-text-field>
                    </v-col>                    
                </v-row> -->
                <v-divider class=""></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center" cols="3">
                        <span class="black--text font-weight-medium">오시는길</span>
                        <v-btn
                            class="ml-1" color="#3C9099" style="padding: 0px 1px 0px 2px !important"
                            min-width="30" height="25" @click="dialog.splice(0, 1, true)" dark small>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon size="20" v-bind="attrs" v-on="on">mdi-map-search-outline</v-icon>
                                </template>
                                <span>주소검색</span>
                            </v-tooltip>
                        </v-btn>
                    </v-col>
                    <v-col class="pa-1 d-flex justify-start align-center" cols="9">
                        <span>위도</span>
                        <span class="pl-1" style="width:170px;">
                            <v-text-field v-model="hominf.fcltyHomWayLttd" height="28" readonly hide-details outlined dense></v-text-field>
                        </span>
                        <span class="pl-1">/</span>
                        <span class="pl-1">경도</span>
                        <span class="pl-1" style="width:170px;">
                            <v-text-field v-model="hominf.fcltyHomWayLngtd" height="28" readonly hide-details outlined dense></v-text-field>
                        </span>
                    </v-col>                    
                </v-row>
                <v-divider class=""></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center" cols="3">
                        <span class="black--text font-weight-medium">오시는길 방법</span>
                    </v-col>
                    <v-col class="pa-1" cols="9">
                        <v-textarea
                            class="rmrksArea" rows="10"
                            v-model="hominf.fcltyHomWayCntnt"
                            no-resize hide-details outlined dense>
                        </v-textarea>
                    </v-col>                    
                </v-row>
                <v-divider class=""></v-divider>
            </v-col>
            <v-col class="pa-0 ma-0" cols="6">
                <v-row class="pl-2" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center py-2" cols="12">
                        <span class="black--text font-weight-medium">인사말</span>
                    </v-col>
                    <v-col class="pa-0 ma-0" cols="12">
                        <TipTapEditor @editorToHtml="getTipTapHtml" @editorToJson="getTipTapJson" :editorCnt="cntnt" ></TipTapEditor>
                    </v-col>
                </v-row>                
            </v-col>
        </v-row>
        <v-dialog v-model="dialog[0]" content-class="round" max-width="500" persistent eager>
            <AdrsSrch ref="adrsSrch" :geoAct="true" @onDataThr="onAddressComp" @isClose="dialog.splice(0, 1, false)"></AdrsSrch>
        </v-dialog>
    </v-sheet>    
</template>
<script>
import { selFcltyHomInf, insFcltyHomInf } from '../../api/hom.js';
import TipTapEditor from '../../components/commons/TipTapEditor.vue';
import AdrsSrch from '../commons/AdrsSrch.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';

export default {
    name: 'HomIntro',

    props : {
       
    },
        
    components: {
        TipTapEditor,
        AdrsSrch,
        CmmTimeField,
    },

    created: function(){
        this.tiptapValue = ''
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getFcltyHomInf()
        })
    },

    computed: {
        
    },

    watch:{
        'cntnt': function () {
            this.tiptapValue = this.cntnt
        },
    },
    
    methods: {
        preSetFcltyHomGrtng:function(){
            let obj = {
                fcltyNum            : this.$store.getters.getFcltyNum,
                userNm              : this.$store.getters.getUserNm,
                fcltyHomInfPk       : this.hominf.fcltyHomInfPk,
                fcltyHomGrtng       : this.tiptapValue,
                fcltyHomSocialUrl1  : this.hominf.fcltyHomSocialUrl1,
                fcltyHomSocialUrl2  : this.hominf.fcltyHomSocialUrl2,
                fcltyHomSocialUrl3  : this.hominf.fcltyHomSocialUrl3,
                fcltyHomIntroTitle  : this.hominf.fcltyHomIntroTitle,
                fcltyHomIntroCntnt  : this.hominf.fcltyHomIntroCntnt,
                fcltyHomWayCntnt    : this.hominf.fcltyHomWayCntnt,
                fcltyHomWayLttd     : this.hominf.fcltyHomWayLttd,
                fcltyHomWayLngtd    : this.hominf.fcltyHomWayLngtd,
                fcltyHomTmpltCd     : this.hominf.fcltyHomTmpltCd,
                srvStartTm          : this.hominf.srvStartTm,
                srvEndTm            : this.hominf.srvEndTm,
            }
            // console.log(obj)
            this.setFcltyHomInf(obj)
        },

        getFcltyHomInf: function () {
            selFcltyHomInf(this.$store.getters.getFcltyNum)
                .then((response) => ( this.getFcltyHomInfAfter(response.data) ))
                .catch((error) => console.log('connect error /hominf/selFcltyHomInf : ' + error))
        },

        setFcltyHomInf: function (obj) {
            this.progress = true
            insFcltyHomInf(obj)
                .then((response) => ( this.setFcltyHomInfAfter(response.data) ))
                .catch((error) => console.log('connect error /hominf/insFcltyHomInf : ' + error))
        },

        getFcltyHomInfAfter:function(res){            
            if(res.statusCode == 200){
                if(res.result != null && res.result != undefined){
                    this.hominf.fcltyHomInfPk       = res.result.fcltyHomInfPk
                    this.hominf.fcltyHomGrtng       = res.result.fcltyHomGrtng
                    this.hominf.fcltyHomSocialUrl1  = res.result.fcltyHomSocialUrl1
                    this.hominf.fcltyHomSocialUrl2  = res.result.fcltyHomSocialUrl2
                    this.hominf.fcltyHomSocialUrl3  = res.result.fcltyHomSocialUrl3
                    this.hominf.fcltyHomIntroTitle  = res.result.fcltyHomIntroTitle
                    this.hominf.fcltyHomIntroCntnt  = res.result.fcltyHomIntroCntnt
                    this.hominf.fcltyHomWayLttd     = res.result.fcltyHomWayLttd
                    this.hominf.fcltyHomWayLngtd    = res.result.fcltyHomWayLngtd
                    this.hominf.fcltyHomWayCntnt    = res.result.fcltyHomWayCntnt
                    this.hominf.fcltyHomTmpltCd     = res.result.fcltyHomTmpltCd
                    this.hominf.srvStartTm          = res.result.srvStartTm
                    this.hominf.srvEndTm            = res.result.srvEndTm

                    this.cntnt = this.hominf.fcltyHomGrtng
                }
            } else {
                // this.snackControll([true, 5000, res.message, 'error'])
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }

            ++this.forceRender
        },

        setFcltyHomInfAfter:function(res){
            this.progress = false
            let obj = {}
            obj.actv = true   
            
            if(res.statusCode == 200){                                               
                             
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)     
                this.getFcltyHomInf()
            }
            else{
                
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

            }

            this.$store.commit('setWrnnInf', obj)
        },
        getTipTapHtml: function (html) {            
            this.tiptapValue = html
        },
        getTipTapJson: function (json) {
            this.tiptapJSON = json
        },
        onAddressComp:function(result){            
            this.hominf.fcltyHomWayLttd = result.x
            this.hominf.fcltyHomWayLngtd = result.y
            this.dialog.splice(0, 1, false)
        },
        onClickBtn:function(key){
            switch(key) {
                case 0:     //저장
                    this.preSetFcltyHomGrtng()
                break;
            default:
                break;
            }
        }
    },

    data: () => ({
        forceRender: 0,
        progress:false,
        dialog: [false],
        cntnt: '',
        tiptapValue:'',
        tiptapJSON:'',
        TmpltCode:[
            {valcdnm:'샘플01',valcd:'01'},
            {valcdnm:'샘플02',valcd:'02'},
            {valcdnm:'샘플03',valcd:'03'},
            {valcdnm:'샘플04',valcd:'04'},
        ],
        hominf:{
            fcltyHomInfPk:0,
            fcltyHomGrtng:'',
            fcltyHomSocialUrl1:'',
            fcltyHomSocialUrl2:'',
            fcltyHomSocialUrl3:'',
            fcltyHomIntroTitle:'',
            fcltyHomIntroCntnt:'',
            fcltyHomWayLttd:'',
            fcltyHomWayLngtd:'',
            fcltyHomWayCntnt:'',
            fcltyHomTmpltCd:'',
            srvStartTm:'',
            srvEndTm:'',
        },     
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'white--text', color:'blueBtnColor', text: '저장', disabled: false, },           
        ],
    }),
};
</script>