<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col cols="4">
                <span class="text-h4 font-weight-bold">수신자 목록</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex justify-right" cols="4">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </v-col>
        </v-row>
        <v-row class="mt-5 pa-0" no-gutters>
            <v-col cols="12">
                <v-tabs
                    v-model="tab" class="tab-dft-Theme" active-class="black"
                    center-active hide-slider>
                    <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class">{{item.tab}}</v-tab>
                </v-tabs>

                <v-divider class="bodyColor mb-2 mr-2__5" style="padding:1px 0px;"></v-divider>

                <v-tabs-items v-model="tab">
                    <v-tab-item eager :transition="false">
                        <SmsSndBnfcrList ref="smsSndBnfcrList" ></SmsSndBnfcrList>
                    </v-tab-item>
                    <v-tab-item eager :transition="false">
                        <SmsSndFmlyList ref="smsSndFmlyList" ></SmsSndFmlyList>
                    </v-tab-item>
                    <v-tab-item eager :transition="false">
                        <SmsSndEmpList ref="smsSndEmpList" ></SmsSndEmpList>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
        <v-row class="mt-4 pa-0" no-gutters>
            <v-col class="d-flex justify-center align-center" cols="12">
                <v-btn
                    class="white--text" color="blueBtnColor" @click="onAction" height="30" samll rounded>
                    <v-icon>mdi-account-multiple-plus</v-icon>
                    <span class="ml-2" style="font-size: 1.1rem; padding-bottom: 2px;"> 선택</span>
                </v-btn>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import SmsSndEmpList from './SmsSndEmpList.vue';
import SmsSndBnfcrList from './SmsSndBnfcrList.vue';
import SmsSndFmlyList from './SmsSndFmlyList.vue';

import { getMultiStcd } from '../../api/index.js';
export default {
    name: 'SmsMmbrListMgmt',

    props : {        
        smsMmbrListMgmt : {type:Number, default: -1},
        rcvList:{type:Array, default: new Array()},
    },
            
    components: {
        SmsSndEmpList,
        SmsSndBnfcrList,
        SmsSndFmlyList,
    },

    created: function(){
        this.getCode(['65'])
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            
        })
    },

    computed: {

    },

    watch: {
        'smsMmbrListMgmt':function(value){
            if(value > 0) this.chngInfList()
        }
    },
        
    methods: {   
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },     
        stCdMapping: function (res){
            this.vCd65 = res.vCd65
        },
        onClose: function () {
            this.$emit('onClose', '0')
        },
        onAction: function(){
            let list = []
            this.$refs.smsSndBnfcrList.fltrThrw().forEach(el => {
                list.push(el)
            });
            this.$refs.smsSndFmlyList.fltrThrw().forEach(el => {
                list.push(el)
            });

             this.$refs.smsSndEmpList.fltrThrw().forEach(el => {
                list.push(el)
            });

            this.$emit('smsMmbrList', list)
            this.onClose()
        },   

        chngInfList:function(){
            this.tab=0
            let exChekrbnfcr = []
            let exChekrFmly = []
            let exChekrEmp = []

            this.rcvList.forEach(item => {

                switch (item.dtTyp) {
                    case 'bnfcr':
                        exChekrbnfcr.push(item)
                        break;
                    case 'fmly':
                        exChekrFmly.push(item)
                        break;
                    case 'emp':
                        exChekrEmp.push(item)
                        break;
                    default:
                        break;
                }

            });

            this.$refs.smsSndBnfcrList.chngInfrm(exChekrbnfcr)
            this.$refs.smsSndFmlyList.chngInfrm(exChekrFmly)
            this.$refs.smsSndEmpList.chngInfrm(exChekrEmp)

        },
    },
    
    data: () => ({
        vCd65   : [],
        exclsBnfcrList: [],     //수급자 선택 제외 리스트
        exclsEmpList: [],       //직원 선택 제외 리스트
        exclsFmlyList: [],       //보호자 선택 제외 리스트
        tab: 0,
        tabItems: [
            { index: 0, tab: '수급자', class: 'fontOneDotTwoRem px-15', enter: true },
            { index: 1, tab: '보호자', class: 'fontOneDotTwoRem px-15', enter: true },
            { index: 2, tab: '직원', class: 'fontOneDotTwoRem px-15', enter: true },
        ],
    }),
};
</script>