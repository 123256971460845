<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left align-center">
            <span class="text-h4 font-weight-bold">
                {{ $moment(date, 'YYYYMM').format('YYYY년 MM월') }}&nbsp;정산내역
            </span>
            <v-spacer></v-spacer>
            <v-btn
                v-if="$parent.$parent.$parent.$parent.copayClmDtlInfo.cnfrmYn == '1' ? false : true"
                class="mr-8 white--text" height="30" color="blueBtnColor"
                @click="preCopayClmIns()"
                :disabled="copayPreClmHisList.length > 0 ? false : true"
                samll rounded>
                <v-icon small>mdi-content-save-outline</v-icon>
                <span style="font-size: 1rem;">저장</span>
            </v-btn>
            <v-icon color="black" x-large @click="onClose()">mdi-close</v-icon>
        </div>
        <v-row class="pa-0 mt-5" no-gutters>
            <v-col cols="12">
                <v-divider></v-divider>
                <v-data-table class="clmsClcCmmtbl cpDtlTbl" locale="ko" height="582"
                    :headers="headers" :items="copayPreClmHisList" :loading="cpyClmClcLoader"
                    fixed-header hide-default-header hide-default-footer dense disable-pagination> 
                    <template v-slot:header="{ props }" >
                        <thead>
                            <tr>
                                <th class="clmsClcCmm tableBR" style="width: 120px;" rowspan="2">{{props.headers[0].text}}</th>
                                <th class="clmsClcCmm tableBR" style="width: 160px;" colspan="2">급여비용</th>
                                <th class="clmsClcCmm tableBR" style="width: 160px;" colspan="2">식사재료비</th>
                                <th class="clmsClcCmm tableBR" style="width: 150px;" colspan="2">경관유동식</th>
                                <th class="clmsClcCmm tableBR" style="width: 150px;" colspan="2">간식비</th>
                                <th class="clmsClcCmm tableBR" style="width: 150px;" colspan="2">상급침실비</th>
                                <th class="clmsClcCmm" style="width: 120px;" rowspan="2">{{props.headers[11].text}}</th> 
                            </tr>
                            <tr>
                                <th class="clmsClcCmm tableBR">{{props.headers[1].text}}</th>
                                <th class="clmsClcCmm tableBR">{{props.headers[2].text}}</th>
                                <th class="clmsClcCmm tableBR">{{props.headers[3].text}}</th>
                                <th class="clmsClcCmm tableBR">{{props.headers[4].text}}</th>
                                <th class="clmsClcCmm tableBR">{{props.headers[5].text}}</th>
                                <th class="clmsClcCmm tableBR">{{props.headers[6].text}}</th>
                                <th class="clmsClcCmm tableBR">{{props.headers[7].text}}</th>
                                <th class="clmsClcCmm tableBR">{{props.headers[8].text}}</th>
                                <th class="clmsClcCmm tableBR">{{props.headers[9].text}}</th>
                                <th class="clmsClcCmm tableBR">{{props.headers[10].text}}</th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item }'>
                        <tr class="" style="padding: 0 4px !important;">
                            <td class="clmsBodyCommon tableBR">{{item.ofrDt}}</td>
                            <td class="clmsBodyCommon tableBR">{{item.bnftFee?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsBodyCommon tableBR">
                                <v-text-field
                                    class="clmsTxt" height="28"
                                    v-model="item.bnftFeeExp" color="blue" type="number"
                                    @input="copayClmsCalcSum"
                                    :rules="[rules.numberCheck]" hide-details outlined dense>
                                </v-text-field>
                            </td>
                            <td class="clmsBodyCommon tableBR">{{item.mealCost?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsBodyCommon tableBR">
                                <v-text-field
                                    class="clmsTxt" height="28"
                                    v-model="item.mealCostExp" color="blue" type="number"
                                    @input="copayClmsCalcSum"
                                    :rules="[rules.numberCheck]" hide-details outlined dense>
                                </v-text-field>
                            </td>
                            <td class="clmsBodyCommon tableBR">{{item.lqdFood?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsBodyCommon tableBR">
                                <v-text-field
                                    class="clmsTxt" height="28"
                                    v-model="item.lqdFoodExp" color="blue" type="number"
                                    @input="copayClmsCalcSum"
                                    :rules="[rules.numberCheck]" hide-details outlined dense>
                                </v-text-field>
                            </td>
                            <td class="clmsBodyCommon tableBR">{{item.snackFee?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsBodyCommon tableBR">
                                <v-text-field
                                    class="clmsTxt" height="28"
                                    v-model="item.snackFeeExp" color="blue" type="number"
                                    @input="copayClmsCalcSum"
                                    :rules="[rules.numberCheck]" hide-details outlined dense>
                                </v-text-field>
                            </td>
                            <td class="clmsBodyCommon tableBR">{{item.upBedFee?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsBodyCommon tableBR">
                                <v-text-field
                                    class="clmsTxt" height="28"
                                    v-model="item.upBedFeeExp" color="blue" type="number"
                                    @input="copayClmsCalcSum"
                                    :rules="[rules.numberCheck]" hide-details outlined dense>
                                </v-text-field>
                            </td>
                            <td class="clmsBodyCommon">{{item.totCalc?.toLocaleString('ko-KR')}}</td>
                        </tr>
                    </template>
                    <template v-slot:[`foot`]="{ props }">
                        <tfoot>
                            <tr>
                                <td class="clmsDtlHeaderCommon font-weight-bold tableBT tableBR py-1 black--text">합계</td>
                                <td class="clmsDtlHeaderCommon tableBT tableBR black--text">{{copayPreClmHisListSum.bnftFeeSum?.toLocaleString('ko-KR')}}</td>
                                <td class="clmsDtlHeaderCommon tableBT tableBR black--text">{{copayPreClmHisListSum.bnftFeeExpSum?.toLocaleString('ko-KR')}}</td>
                                <td class="clmsDtlHeaderCommon tableBT tableBR black--text">{{copayPreClmHisListSum.mealCostSum?.toLocaleString('ko-KR')}}</td>
                                <td class="clmsDtlHeaderCommon tableBT tableBR black--text">{{copayPreClmHisListSum.mealCostExpSum?.toLocaleString('ko-KR')}}</td>
                                <td class="clmsDtlHeaderCommon tableBT tableBR black--text">{{copayPreClmHisListSum.lqdFoodSum?.toLocaleString('ko-KR')}}</td>
                                <td class="clmsDtlHeaderCommon tableBT tableBR black--text">{{copayPreClmHisListSum.lqdFoodExpSum?.toLocaleString('ko-KR')}}</td>
                                <td class="clmsDtlHeaderCommon tableBT tableBR black--text">{{copayPreClmHisListSum.snackFeeSum?.toLocaleString('ko-KR')}}</td>
                                <td class="clmsDtlHeaderCommon tableBT tableBR black--text">{{copayPreClmHisListSum.snackFeeExpSum?.toLocaleString('ko-KR')}}</td>
                                <td class="clmsDtlHeaderCommon tableBT tableBR black--text">{{copayPreClmHisListSum.upBedFeeSum?.toLocaleString('ko-KR')}}</td>
                                <td class="clmsDtlHeaderCommon tableBT tableBR black--text">{{copayPreClmHisListSum.upBedFeeExpSum?.toLocaleString('ko-KR')}}</td>
                                <td class="clmsDtlHeaderCommon tableBT black--text" style="width:120px !important; border-right: initial !important;">{{copayPreClmHisListSum.totCalcSum?.toLocaleString('ko-KR')}}{{props}}</td>
                            </tr>
                        </tfoot>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { selCopayClmCalcList, updMultiCopayClm } from '../../api/bnfcr.js';

export default {
    name: 'CopayCalcDtl',

    components: {

    },

    props : {
        yyMm: { type: String, default: ''},
        bnmmbrPk: { type: Number, default: 0 },
        bnfcrPk: { type: Number, default: 0 },
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            //console.log(this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.date)

            this.date = this.$moment(this.yyMm, 'YYYYMM').subtract(1, 'months').format('YYYYMM')
        })
    },

    computed: {

    },

    watch: {
        'yyMm': function(){
            this.date = this.$moment(this.yyMm, 'YYYYMM').subtract(1, 'months').format('YYYYMM')

            this.getCopayClmCalcList(this.date ,this.bnmmbrPk, this.bnfcrPk)
        },

        'bnmmbrPk': function(){
            if(this.bnmmbrPk != -1)
                this.getCopayClmCalcList(this.date ,this.bnmmbrPk, this.bnfcrPk)
        },
    },

    methods:{
        getCopayClmCalcList: function (yyyyMm, bnmmbrPk, bnfcr) {
            this.copayPreClmHisList.splice(0)
            this.cpyClmClcLoader = true

            selCopayClmCalcList(this.$store.getters.getFcltyNum, bnmmbrPk, bnfcr, yyyyMm)
                .then((response) => ( this.getCopayClmCalcListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selCopayClmCalcList : ' + error))
        },
        getCopayClmCalcListAfter: function (res) {
            this.cpyClmClcLoader = false
            //console.log(res)
            this.copayPreClmHisList = res
            this.copayPreClmHisList.forEach(arr => {
                if(arr.bnftFee == null) arr.bnftFee = 0
                if(arr.bnftFeeExp == null) arr.bnftFeeExp = 0
            });

            // 조회 목록의 합계를 별도 저장
            this.orgCpySum.bnftFeeExpSum = 
                this.copayPreClmHisList.reduce((acc, cur) => { return acc += cur.bnftFeeExp }, 0)
            this.orgCpySum.mealCostExpSum = 
                this.copayPreClmHisList.reduce((acc, cur) => { return acc += cur.mealCostExp }, 0)
            this.orgCpySum.lqdFoodExpSum = 
                this.copayPreClmHisList.reduce((acc, cur) => { return acc += cur.lqdFoodExp }, 0)
            this.orgCpySum.snackFeeExpSum = 
                this.copayPreClmHisList.reduce((acc, cur) => { return acc += cur.snackFeeExp }, 0)
            this.orgCpySum.upBedFeeExpSum = 
                this.copayPreClmHisList.reduce((acc, cur) => { return acc += cur.upBedFeeExp }, 0)

            this.copayClmsCalcSum()
        },
        setMultiCopayClm: function (obj) {
            this.copayPreClmHisList.splice(0)
            this.cpyClmClcLoader = true

            updMultiCopayClm(obj)
                .then((response) => ( this.setMultiCopayClmAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/updMultiCopayClm : ' + error))
        },
        setMultiCopayClmAfter: function (res) {
            this.cpyClmClcLoader = false

            if(res.statusCode == '200'){
                this.snackControll([true, 2500, res.message, 'info'])

                this.getCopayClmCalcList(this.date ,this.bnmmbrPk, this.bnfcrPk)
                
                this.$parent.$parent.$parent.$parent.getCopayClmHisInfo(
                    this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.date,
                    this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.bnmmbr,
                    this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.bnfcrPk
                )
                this.$parent.$parent.$parent.$parent.getCopayClmRmrkInfo(
                    this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.date, this.$parent.$parent.$parent.$parent.clmHisPk
                )
                this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.getCopayClmHisList(
                    this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.date
                )
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        copayClmsCalcSum: function () {
            this.copayPreClmHisListSum = Object.assign({}, this.dummyCopayPreClmHisListSum)

            this.copayPreClmHisList.forEach(items => {
                items.totCalc = ( Number(items.bnftFeeExp) - Number(items.bnftFee) )
                    + ( Number(items.mealCostExp) - Number(items.mealCost) )
                    + ( Number(items.lqdFoodExp) - Number(items.lqdFood) )
                    + ( Number(items.snackFeeExp) - Number(items.snackFee) )
                    + ( Number(items.upBedFeeExp) - Number(items.upBedFee) )

                this.copayPreClmHisListSum.bnftFeeExpSum += Number(items.bnftFeeExp)
                this.copayPreClmHisListSum.bnftFeeSum += Number(items.bnftFee)
                this.copayPreClmHisListSum.copayExpSum += Number(items.copayExp)
                this.copayPreClmHisListSum.copaySum += Number(items.copay)
                this.copayPreClmHisListSum.nhisPayExpSum += Number(items.nhisPayExp)
                this.copayPreClmHisListSum.nhisPaySum += Number(items.nhisPay)
                this.copayPreClmHisListSum.mealCostExpSum += Number(items.mealCostExp)
                this.copayPreClmHisListSum.mealCostSum += Number(items.mealCost)
                this.copayPreClmHisListSum.lqdFoodExpSum += Number(items.lqdFoodExp)
                this.copayPreClmHisListSum.lqdFoodSum += Number(items.lqdFood)
                this.copayPreClmHisListSum.snackFeeExpSum += Number(items.snackFeeExp)
                this.copayPreClmHisListSum.snackFeeSum += Number(items.snackFee)
                this.copayPreClmHisListSum.upBedFeeExpSum += Number(items.upBedFeeExp)
                this.copayPreClmHisListSum.upBedFeeSum += Number(items.upBedFee)
                this.copayPreClmHisListSum.totCalcSum += Number(items.totCalc)
            });

            // updCopayClm
            // insCopayClmHis4Crt

        },
        preCopayClmIns: function () {
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.inqYYMM = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.date
            obj.bnMmbrPk = this.bnmmbrPk
            obj.bnfcrPk = this.bnfcrPk
            obj.prcClcd = '5'
            obj.prcCdAr = []
            obj.userNm = this.$store.getters.getUserNm
            obj.list = Object.assign([], this.copayPreClmHisList)

            obj.list.forEach(lll => {
                lll.inqYYMM = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.date
                lll.userNm = this.$store.getters.getUserNm
                lll.fcltyNum = this.$store.getters.getFcltyNum
                lll.bnMmbrPk = this.bnmmbrPk
            });

            if(this.copayPreClmHisListSum.bnftFeeExpSum != this.orgCpySum.bnftFeeExpSum)
                obj.prcCdAr.push('1')
            if(this.copayPreClmHisListSum.mealCostExpSum != this.orgCpySum.mealCostExpSum)
                obj.prcCdAr.push('4')
            if(this.copayPreClmHisListSum.lqdFoodExpSum != this.orgCpySum.lqdFoodExpSum)
                obj.prcCdAr.push('7')
            if(this.copayPreClmHisListSum.snackFeeExpSum != this.orgCpySum.snackFeeExpSum)
                obj.prcCdAr.push('8')
            if(this.copayPreClmHisListSum.upBedFeeExpSum != this.orgCpySum.upBedFeeExpSum)
                obj.prcCdAr.push('5')

            //console.log(obj)
            this.setMultiCopayClm(obj)
        },
        onClose: function () {
            this.$emit('isClose', '.')
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        date: '',
        cpyClmClcLoader: true,
        copayPreClmHisList: [],
        orgCpySum: {
            bnftFeeExpSum: 0, nhisPayExpSum: 0,
            mealCostExpSum: 0, lqdFoodExpSum: 0, snackFeeExpSum: 0, upBedFeeExpSum: 0,
        },
        copayPreClmHisListSum: {
            bnftFeeSum: 0, bnftFeeExpSum: 0, copayExpSum: 0, copaySum: 0, nhisPayExpSum: 0, nhisPaySum: 0,
            mealCostExpSum: 0, mealCostSum: 0, lqdFoodExpSum: 0, lqdFoodSum: 0, snackFeeExpSum: 0, snackFeeSum: 0,
            upBedFeeExpSum: 0, upBedFeeSum: 0, totCalcSum: 0
        },
        dummyCopayPreClmHisListSum: {
            bnftFeeSum: 0, bnftFeeExpSum: 0, copayExpSum: 0, copaySum: 0, nhisPayExpSum: 0, nhisPaySum: 0,
            mealCostExpSum: 0, mealCostSum: 0, lqdFoodExpSum: 0, lqdFoodSum: 0, snackFeeExpSum: 0, snackFeeSum: 0,
            upBedFeeExpSum: 0, upBedFeeSum: 0, totCalcSum: 0
        },
        headers: [
            { text: '급여제공일', value: '', align: 'center'},
            { text: '청구금액', value: '', align: 'center'},
            { text: '정산금액', value: '', align: 'center'},
            { text: '청구금액', value: '', align: 'center'},
            { text: '정산금액', value: '', align: 'center'},
            { text: '청구금액', value: '', align: 'center'},
            { text: '정산금액', value: '', align: 'center'},
            { text: '청구금액', value: '', align: 'center'},
            { text: '정산금액', value: '', align: 'center'},
            { text: '청구금액', value: '', align: 'center'},
            { text: '정산금액', value: '', align: 'center'},
            { text: '정산가감액 ', value: '', align: 'center'},
        ],
        rules: {
            numberCheck: value => /^[0-9]*$/.test(value) || '숫자만 입력 가능합니다.',
        },
        snackOptions: [false, 3000, '', 'info'],
    })
}
</script>

<style>
    .tableFixedElm div table { border-collapse: separate !important; border-spacing: 0px !important; position: sticky !important;
        z-index: 3 !important; }

    .clmsFixedHeader{ border-collapse: separate !important; 
        background-color: #ededed !important; text-align: center !important; font-size: 1rem !important; padding: 0 11px !important; }

    .clmsClcCmmtbl .v-data-table__wrapper table { border-collapse: separate !important; border-spacing: 0px !important; }
    .clmsClcCmm {
        color:black !important;
        text-align: center !important; font-size: 1rem !important; padding: 0 4px !important;
        background-color: #ededed !important; z-index: 2;
    }

    .clmsTxt .v-input__control .v-input__slot { padding: 0 4px !important; }
</style>