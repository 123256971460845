<template>
    <v-sheet class="rounded-lg rounded-l-0 pa-2" color="" min-width="1260" max-width="1560" height="835">
       <div class="text-h4 px-1 mb-3">
            <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
            <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">3-7. 물리&#40;작업&#41;치료기록</span>
        </div>
        <v-row class="pa-0 ma-0" no-gutters>
            <v-col cols="12">
                <v-tabs
                    v-model="tab" class="tab-dft-Theme" active-class="black"
                    center-active hide-slider>
                    <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class" :disabled="item.enter">{{item.tab}}</v-tab>
                </v-tabs>
                <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
                <v-tabs-items v-model="tab">            
                    <v-tab-item transition="fade-transition">
                        <tab1 v-if="tab==0" ref="tab1"></tab1>
                    </v-tab-item>
                    <v-tab-item transition="fade-transition">
                        <tab2 v-if="tab==1" ref="tab2"></tab2>
                    </v-tab-item>       
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import tab1 from '../../components/bnftrcrd/PhyscThrpyRcrdDay.vue';     // 일일치료
import tab2 from '../../components/bnftrcrd/PhyscThrpyRcrdWeek.vue';    // 주간치료

export default {
    name: 'PhyscThrpyRcrd',
        
    components: {
        tab1,
        tab2,
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch:{ 

    },
        
    methods: {
        dlyThrPrt: function (pk){
            this.prtThrowObj.bnMmbrPk = pk
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
            this.prtThrowObj.inqYmdStart = this.$refs.tab1.inqYMD
            this.prtThrowObj.inqYmdEnd = this.$refs.tab1.inqYMD

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
            });
        },
    },
    
    data: () => ({
        tab:0,
        menu: [ false, ],
        tabItems: [
            { index: 0, tab: '일일치료', class: 'fontOneDotTwoRem px-15', enter:false},
            { index: 1, tab: '주간치료', class: 'fontOneDotTwoRem px-15', enter:false},
        ],
        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '15', inqYmdStart: '', inqYmdEnd: '',
            rptTyp: '1', mode: '2', list: [ { bzClcd: [], bzPk: [] } ],
        },
    }),
};
</script>