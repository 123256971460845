<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <v-row class="pa-0  justify-center align-center" no-gutters>
            <span class="text-h4 font-weight-bold">프로그램&nbsp;선택</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="onClose" x-large>mdi-close</v-icon>
        </v-row>
        <v-row class="pa-0 mt-5 mr-1" no-gutters>
            <CmmExpnBtn
                v-model="isMtrcStt"
                :btnNm="'지표구분'" vTyp="2" :bIcon="'mdi-shape-outline'"
                :iList="cdItems" @onList="dmyTxt = String(Number(dmyTxt)+1)">
            </CmmExpnBtn>
            <v-spacer></v-spacer>
            <span class="d-inline-block" style="width: 146px; height: 34px;">
                <v-text-field
                    v-model="srchTxt" @input="dmyTxt = String(Number(dmyTxt)+1)"
                    class="txt-Theme" height="34" label="프로그램 검색"
                    background-color="white" prepend-inner-icon="mdi-magnify"
                    single-line outlined dense filled hide-details>
                </v-text-field>
            </span>
        </v-row>
        <v-divider class="mt-2 mr-1" color="black"></v-divider>
        <v-data-table
            class="dftTbl mr-1" height="378" locale="ko"
            :headers="headers" :items="allPrgIMList"
            :loading="isLoad" :loading-text="loadMsg"
            :search="dmyTxt" :custom-filter="srchFltr"
            fixed-header disable-pagination hide-default-footer dense>
            <template v-slot:item='{ item }'>
                <tr @click="onAction(item)">
                    <td>{{ item.prgTypNm }}</td>
                    <td>{{ item.idxCdnm }}</td>
                    <td class="posn-rltv">
                        <div class="overflow-text-hidden txtAlgnFst" style="position: absolute; left: 16px; top: 6px; width: 90%;">
                            {{ item.prgNm }}
                        </div>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <!-- <v-row class="pa-0 justify-end" no-gutters>
            <span class="fontEighthRem grey006--text">※ 테이블을 클릭해서 적용.</span>
        </v-row> -->
    </v-sheet>
</template>

<script>
import CmmExpnBtn from '../commons/CmmExpnBtn';

import { getStcd } from '../../api/index.js';
import { getAllPrgIM } from '../../api/prgrm.js';

export default {
    name: 'PrgrmSelect',

    props:{
        prgrmSelectPk: { type: Number, default: -1 },
    },

    components: {
        CmmExpnBtn,
    },

    created: function () {
        getStcd('40')
            .then((response) => ( this.stCdMapping(response.data)) )
            .catch((error) => console.log(error))
    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getAllPrgIM()
        })
    },

    computed: {

    },

    watch: {
        'prgrmSelectPk':function(value){
            if(value > 0)
                this.getAllPrgIM()
        },
        'isMtrcStt': function (v) {
            if(v) this.isLvnStt = false
        },
    },

    methods: {
        onClose: function () {
            this.$emit('isClose', '.')
            // this.$emit('modalEvent', 1)
        },
        onAction:function(obj){
            this.$emit("outPrgrm",obj)
            this.onClose()
        },
        reset: function () {
            this.getAllPrgIM()
        },
        getAllPrgIM: function () {
            this.isLoad = true
            this.allPrgIMList.splice(0)

            getAllPrgIM(this.$store.getters.getFcltyNum)
                .then((response) => (this.getAllPrgIMAfter(response.data)))
                .catch((error) => console.log('connect error prgrm/selPrgInfInfo : ' + error))
        },
        getAllPrgIMAfter: function (res) {
            if(Array.isArray(res)){
                res.forEach(el => {
                    this.cdItems.forEach((cd) => {
                        if (el.idxCd == cd.valcd) el.idxCdnm = cd.valcdnm
                    });
                    this.allPrgIMList.push(el)
                });
            }
            this.isLoad = false
        },              
        stCdMapping: function (res) {
            if(Array.isArray(res)){
                res.forEach(itm => {
                    itm.isOn = true
                });
                this.cdItems = res

                if(this.allPrgIMList.length > 0)
                    this.allPrgIMList.forEach(el => {
                        this.cdItems.forEach((cd) => {
                            if (el.idxCd == cd.valcd) el.idxCdnm = cd.valcdnm
                        });
                    });
            }
        },

        // 테이블 검색 필터 (value: list, search: search key, filter: array column)
        srchFltr: function (value, search, filter){
            let field1 = false
            let field2 = true

            // 이름 검색
            field1 = filter.prgNm.includes(this.srchTxt)

            // 지표구분 검색
            let mtrcRunChc = this.cdItems.filter( v => v.isOn == true )
            if(mtrcRunChc.length == 0)
                field2 = false
            else{
                mtrcRunChc.forEach((itm, i) => {
                    if(i == 0) field2 = filter.idxCd == itm.valcd
                    else field2 = field2 || filter.idxCd == itm.valcd
                });
            }

            return field1 && field2
        },
    },

    data: () => ({
        isLoad: true,
        isMtrcStt: false,                        // 지표구분 확장 활성화 여부
        loadMsg: '잠시만 기다려주세요.',
        srchTxt: '',                            // 테이블 이름 검색 필드
        dmyTxt: '',                             // 테이블 반응 더미(검색용)
        selectedItemIndex: [],
        selectedItem: -1,
        allPrgIMList: [],
        cdItems: [],                            // 지표구분
        headers: [
            { text: '프로그램 유형', value: 'prgTypNm', sortable: true, width: '180', class: '', align: 'center', },
            { text: '지표구분', value: 'idxCdnm', sortable: true, width: '160', class: '', align: 'center', },
            { text: '프로그램', value: 'prgNm', sortable: true, width: '260', class: '', align: 'center', }
        ],
    }),
};
</script>