<template>
    <v-sheet class="ma-0 pa-0" rounded="lg">
        <v-row class="pa-0 justify-center align-center" no-gutters>
            <span class="text-h4 font-weight-bold">수급자 기본정보 수정</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="offMdl" x-large>mdi-close</v-icon>
        </v-row>
        <v-row class="pa-0 mt-8 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">기본항목</span>
            <v-spacer></v-spacer>
            <span class="d-inline-block rounded white" style="width: 220px;">
                <v-select
                    v-model="sltBnfcrPk"
                    :items="bnfcrPkList" 
                    item-text="viewTxt" item-value="bnfcrPk"
                    dense hide-details outlined>
                </v-select>
            </span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-form ref="bnfcrInf" lazy-validation>
            <v-row class="pa-0" no-gutters>
                <v-col class="tableBR px-1 pt-5" cols="2">
                    <v-row class="pa-0" no-gutters>
                        <imageCard
                            :cstmFit="'vrtclAspct'" :imgSrc="imgInf.src"
                            @onDown="getAthFileInf" @isEvent="delImgfiles()">
                        </imageCard>
                    </v-row>
                    <v-row class="pa-0 justify-center" no-gutters>
                        <span class="pl-2">
                            <v-file-input
                                v-model="inpFile"
                                class="fileImgAdd pt-0" color="blueBtnColor" accept="image/*" label="사진등록"
                                prepend-icon="mdi-image-plus-outline"
                                @change="upImgFile"
                                hide-input hide-details>
                            </v-file-input>
                        </span>
                    </v-row>
                </v-col>
                <v-col class="" cols="10">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tblHdr-greyE01 tableBR lnHgt1-7 py-2" cols="2">
                            <span class="fontOneDotOneRem font-weight-medium posn-rltv">
                                수급자명
                                <span class="d-inline-block" style="position: absolute; top: -4px; right: -20px;">
                                    <v-icon color="red">mdi-star-four-points-small</v-icon>
                                </span>
                            </span>
                        </v-col>
                        <v-col class="tableBR py-2 px-2" cols="3">
                            <span class="d-inline-block" style="width: 101px; height: 100%">
                                <v-text-field
                                    v-model="bscsInf.name" @input="aplctSavBtn"
                                    height="28" hide-details outlined dense>
                                </v-text-field>
                            </span>
                        </v-col>
                        <v-col class="tblHdr-greyE01 tableBR lnHgt1-7 py-2" cols="2">
                            <span class="fontOneDotOneRem font-weight-medium">
                                입소일/시간
                            </span>
                        </v-col>
                        <v-col class="py-2 px-3" cols="5">
                            <span class="fontOneRem mr-2">{{ $moment(bscsInf.entDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                            <span class="fontOneRem">&#40; {{ bscsInf.entTm }} &#41;</span>
                            <!-- <span class="d-inline-block" style="width: 134px; height: 100%">
                                <CmmDateComp
                                    v-model="bscsInf.entDt" :required="false">
                                </CmmDateComp>
                            </span>
                            <span class="d-inline-block ml-2" style="width: 80px;">
                                <CmmTimeField v-model="bscsInf.entTm" :required="false"></CmmTimeField>
                            </span> -->
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tblHdr-greyE01 tableBR lnHgt1-7 pa-2" cols="2">
                            <span class="fontOneDotOneRem font-weight-medium posn-rltv">
                                생년월일
                                <span class="d-inline-block" style="position: absolute; top: -4px; right: -20px;">
                                    <v-icon color="red">mdi-star-four-points-small</v-icon>
                                </span>
                            </span>
                        </v-col>
                        <v-col class="tableBR pa-2" cols="3">
                            <span class="d-inline-block" style="width: 134px; height: 100%">
                                <CmmDateComp
                                    v-model="bscsInf.rsdntBirth" :required="true" @input="aplctSavBtn">
                                </CmmDateComp>
                            </span>
                            <v-btn
                                class="ml-1 pb-1" width="20" height="20"
                                @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 10)"
                                icon>
                                <v-icon size="20" style="padding-top: 2px;">mdi-help-circle-outline</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col class="tblHdr-greyE01 tableBR lnHgt1-7 py-2" cols="2">
                            <span class="fontOneDotOneRem font-weight-medium posn-rltv">
                                생신일
                                <span class="d-inline-block" style="position: absolute; top: -4px; right: -20px;">
                                    <v-icon color="red">mdi-star-four-points-small</v-icon>
                                </span>
                            </span>
                        </v-col>
                        <v-col class="" cols="5">
                            <v-row class="pa-0" no-gutters>
                                <v-col class="py-2 px-2" cols="6">
                                    <span class="d-inline-block" style="width: 134px; height: 100%">
                                        <CmmDateComp
                                            v-model="bscsInf.birth" :required="true" @input="aplctSavBtn">
                                        </CmmDateComp>
                                    </span>
                                </v-col>
                                <v-col style="padding-top: 6px;" cols="6">
                                    <v-radio-group
                                        v-model="bscsInf.lnrYn"
                                        class="radio-dft-Theme mt-0"
                                        @change="aplctSavBtn"
                                        hide-details row>
                                        <v-radio
                                            class="mr-2" style="padding-bottom: 2px !important;"
                                            :ripple="false" label="양력" value="1">
                                        </v-radio>
                                        <v-radio
                                            class="mr-2" style="padding-bottom: 2px !important;"
                                            :ripple="false" label="음력" value="2">
                                        </v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tblHdr-greyE01 tableBR lnHgt1-7 py-2" cols="2">
                            <span class="fontOneDotOneRem font-weight-medium posn-rltv">
                                성별
                                <span class="d-inline-block" style="position: absolute; top: -4px; right: -20px;">
                                    <v-icon color="red">mdi-star-four-points-small</v-icon>
                                </span>
                            </span>
                        </v-col>
                        <v-col class="tableBR px-2" style="padding-top: 6px;" cols="3">
                            <v-radio-group
                                v-model="bscsInf.gndr"
                                @change="aplctSavBtn"
                                class="radio-dft-Theme mt-0"
                                hide-details row>
                                <v-radio
                                    class="mr-2" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="남" value="M">
                                </v-radio>
                                <v-radio
                                    class="mr-2" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="여" value="F">
                                </v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col class="tblHdr-greyE01 tableBR lnHgt1-7 py-2" cols="2">
                            <span class="fontOneDotOneRem">전화번호</span>
                        </v-col>
                        <v-col class="pa-2" cols="5">
                            <span class="d-inline-block" style="width: 50px; height: 100%">
                                <v-text-field
                                    v-model="bscsInf.tel_1" @input="aplctSavBtn"
                                    height="28" hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="mx-1">
                                <v-icon style="padding-bottom: 2px;" size="16">mdi-minus</v-icon>
                            </span>
                            <span class="d-inline-block" style="width: 60px; height: 100%">
                                <v-text-field
                                    v-model="bscsInf.tel_2" @input="aplctSavBtn"
                                    height="28" hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="mx-1">
                                <v-icon style="padding-bottom: 2px;" size="16">mdi-minus</v-icon>
                            </span>
                            <span class="d-inline-block" style="width: 60px; height: 100%">
                                <v-text-field
                                    v-model="bscsInf.tel_3" @input="aplctSavBtn"
                                    height="28" hide-details outlined dense>
                                </v-text-field>
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tblHdr-greyE01 tableBR lnHgt1-7 py-2" cols="2">
                            <span class="fontOneDotOneRem">건강검진 제출일</span>
                        </v-col>
                        <v-col class="tableBR py-2 px-2" cols="3">
                            <span class="d-inline-block" style="width: 134px; height: 100%">
                                <CmmDateComp
                                    v-model="bscsInf.entHlthChdt" :required="true" @input="aplctSavBtn">
                                </CmmDateComp>
                            </span>
                        </v-col>
                        <v-col class="tblHdr-greyE01 tableBR lnHgt1-7 py-2" cols="2">
                            <span class="fontOneDotOneRem">휴대폰</span>
                        </v-col>
                        <v-col class="pa-2" cols="5">
                            <span class="d-inline-block" style="width: 50px; height: 100%">
                                <v-text-field
                                    v-model="bscsInf.celphn_1" @input="aplctSavBtn"
                                    height="28" hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="mx-1">
                                <v-icon style="padding-bottom: 2px;" size="16">mdi-minus</v-icon>
                            </span>
                            <span class="d-inline-block" style="width: 60px; height: 100%">
                                <v-text-field
                                    v-model="bscsInf.celphn_2" @input="aplctSavBtn"
                                    height="28" hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="mx-1">
                                <v-icon style="padding-bottom: 2px;" size="16">mdi-minus</v-icon>
                            </span>
                            <span class="d-inline-block" style="width: 60px; height: 100%">
                                <v-text-field
                                    v-model="bscsInf.celphn_3" @input="aplctSavBtn"
                                    height="28" hide-details outlined dense>
                                </v-text-field>
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tblHdr-greyE01 tableBR lnHgt1-7 py-2" cols="2">
                            <span class="fontOneDotOneRem">주소</span>
                        </v-col>
                        <v-col class="d-flex align-center pa-2" cols="10">
                            <span class="d-inline-block" style="width: 70px; height: 100%">
                                <v-text-field
                                    v-model="bscsInf.zip" @input="aplctSavBtn"
                                    height="28" readonly hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <v-btn
                                class="white--text ml-1" color="#3C9099" style="padding: 0px 1px 0px 2px !important"
                                min-width="30" height="26" @click="onLclMdl('01', 600)" small>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon size="20" v-bind="attrs" v-on="on">mdi-map-search-outline</v-icon>
                                    </template>
                                    <span>주소검색</span>
                                </v-tooltip>
                            </v-btn>
                            <span class="d-inline-block ml-2" style="width: 45%; height: 100%">
                                <v-text-field
                                    v-model="bscsInf.addr" @input="aplctSavBtn"
                                    height="28" readonly hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="d-inline-block ml-1" style="width: 35%; height: 100%">
                                <v-text-field
                                    v-model="bscsInf.addrDtl" height="28" hide-details outlined dense>
                                </v-text-field>
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tblHdr-greyE01 tableBR lnHgt1-7 py-2" cols="2">
                            <span class="fontOneDotOneRem">주요질환</span>
                        </v-col>
                        <v-col class="pa-2" cols="10">
                            <v-textarea
                                class="rmrksArea" rows="1" 
                                v-model="bscsInf.majorDsss" @input="aplctSavBtn"
                                no-resize hide-details outlined dense>
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0 mt-8 mb-3 posn-rltv" no-gutters>
                <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                    <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                </span>
                <span class="fontOneDotTwoRem font-weight-medium ml-6">추가항목</span>
            </v-row>
            <v-divider class="mr-1" color="black"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="tblHdr-greyE01 tableBR d-flex align-center justify-center" cols="2">
                    <span class="fontOneDotOneRem">비고</span>
                </v-col>
                <v-col class="px-2 py-1" cols="10">
                    <v-textarea
                        class="rmrksArea" rows="3"
                        v-model="bscsInf.rmrks"
                        no-resize hide-details outlined dense>
                    </v-textarea>
                </v-col>
            </v-row>
        </v-form>
        <v-divider class="mb-4"></v-divider>
        <v-row class="pa-0 justify-center" no-gutters>
            <v-btn
                class="" color="blueBtnColor" min-width="110" height="36"
                :disabled="savBtn" @click="dcsnSavBtn">
                <span class="white--text fontOneDotTwoRem">저장</span>
            </v-btn>
        </v-row>
        <v-divider class="white mt-1"></v-divider>
        <!-- 팝업 영역 -->
        <v-dialog
            v-model="lclMdlInf.isOn"
            content-class="round" :max-width="lclMdlInf.maxWidth"
            persistent>
            <AdrsSrch v-if="lclMdlInf.typ == '01'" @onDataThr="rflAddrInf" @isClose="rstLclMdlInf"></AdrsSrch>
            <LvnRmMdl
                v-else-if="lclMdlInf.typ == '02'"
                @modalClose="rstLclMdlInf" @sltLvnRmInf="rflLvnRmInf">
            </LvnRmMdl>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { http, remoteURL } from '@/api/baseAxios';

import { uploadToFile, delAtchdFile } from '../../../api/index.js';

import imageCard from '../../commons/imageCard.vue';             // 이미지 로드 (다운로드)
import CmmDateComp from '../../commons/CmmDateComp.vue';         // 날짜 입력
import AdrsSrch from '../../commons/AdrsSrch.vue';               // 주소 검색

export default {
    name: 'BscInfSavMdl',
    
    components: {
        imageCard,
        CmmDateComp,
        AdrsSrch,
    },

    props : { 
 
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            let obj = this.$store.getters.getRootInObj
            this.bscsInf.bnMmbrPk = obj.bnMmbrPk

            if(this.bscsInf.bnMmbrPk > -1)
                this.getPksList(this.bscsInf.bnMmbrPk)
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch: {
        getSubEvt: function (v) {
            if(v && this.runAble == '1'){
                this.$parent.$parent.$parent.$parent.mdlOff()

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
                
                this.runAble = '0'
            }
        },
        'sltBnfcrPk': function(v){
            if(this.sltBnfcrPk > -1)
                this.getBnfcrInf(this.bscsInf.bnMmbrPk, v)
        },
    },
        
    methods: {
        // 수급자 구성원(bnfcrPk) 고유번호 조회
        getPksList: function (mbr){
            let obj = {}
            obj.bnMmbrPk = mbr
            //obj.fcltyNum = this.$store.getters.getFcltyNum

            http.post('bnfcrAuth/selBnfcrPkList', obj)
                .then((response) => ( this.pksRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/selBnfcrPkList : ' + error))
        },

        pksRst: function (res){
            if(res.statusCode == '200'){
                if(Array.isArray(res.result)){
                    res.result.forEach((itm, i) => {
                        let obj = ''
                        if(i == 0)
                            obj += '현재 '
                        else
                            obj += '과거 '

                        if(itm.lvDt == null || itm.lvDt == '')
                            obj += this.$moment(itm.entDt, 'YYYYMMDD').format('( YY.MM.DD ~ )')
                        else{
                            obj += this.$moment(itm.entDt, 'YYYYMMDD').format('( YY.MM.DD ')
                            obj += this.$moment(itm.lvDt, 'YYYYMMDD').format('~ YY.MM.DD ) ')
                        }

                        itm.viewTxt = obj
                    });
                    
                    let arr = res.result.filter( v => v.entDt.slice(0, 4) == this.prtYear )
                    if(arr.length > 0)
                        this.sltBnfcrPk = arr[0].bnfcrPk
                    else
                        this.sltBnfcrPk = res.result[0].bnfcrPk

                    this.bnfcrPkList = res.result
                }
            }
            else{
                let obj = {}
                obj.actv = true                             // 경고 노출여부
                obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
                obj.type = 'warning'                        // 경고 유형 (success, info, warning, error)
                obj.title = res.message                     // 경고 타이틀
                obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        // 수급자 기본정보 조회
        getBnfcrInf: function (mbr, fcr){
            let obj = {}
            obj.bnMmbrPk = mbr
            obj.bnfcrPk = fcr
            //obj.fcltyNum = this.$store.getters.getFcltyNum
            this.inpFile = null

            http.post('bnfcrAuth/selBnfcrInfo', obj)
                .then((response) => ( this.bnfcrRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/selBnfcrInfo : ' + error))
        },

        bnfcrRst: function (res){
            if(res.result.bnfcr != null){
                this.bnfcrInf = res.result.bnfcr
                this.aplctInf(this.bnfcrInf)
            }
            
            if(res.result.fileList.length == 1){
                let iList = res.result.fileList.filter(v => v.fileKndCd == '2')
                iList.forEach(itm => {
                    itm.src = remoteURL + 'opr/imgView?fileNm='+itm.fileNm+'&filePth='+itm.filePth+'&fileKndCd='+itm.fileKndCd
                    this.imgInf = itm
                })
            }
            else
                this.imgInf.src = remoteURL + 'opr/imgView'
        },

        // 수급자 기본정보 수정
        updBnfcrInf: function (inf){
            let obj = {}
            obj.bnfcrInfo = inf
            obj.files = this.imgInf
            //obj.fcltyNum = this.$store.getters.getFcltyNum
   
            http.post('bnfcrAuth/updBnfcr', obj)
                .then((response) => ( this.updBnfcr(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/updBnfcr : ' + error))
        },

        updBnfcr: function (res){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == '200'){
                this.getBnfcrInf(this.bscsInf.bnMmbrPk, this.sltBnfcrPk)
                this.savBtn = false
            }
            else
                obj.type = 'warning'

            this.$store.commit('setWrnnInf', obj)
        },

        // 이미지 다운로드
        getAthFileInf: function () {
            http({
                method: 'post', url: 'cmm/fileDownload',
                responseType: 'arraybuffer', data: this.imgInf })
                .then((response) => ( this.createFile(response) ))
                .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
        },

        createFile: function (res) {
            let contentDisposition = decodeURI(res.headers['content-disposition'])

            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'))
                if (fileNameMatch)
                    [ , fileName ] = fileNameMatch.split('=')
            }

            link.href = url

            fileName = fileName.replaceAll('"', '')
            link.setAttribute('download', `${fileName}`)
            link.style.cssText = 'display:none'

            document.body.appendChild(link)

            link.click()
            link.remove()
        },

        // 이미지 업로드
        upImgFile: function () {
            let formData = new FormData()

            if(this.inpFile != null){
                if(this.inpFile?.size > 0){
                    formData.append("files", this.inpFile)
                    uploadToFile(formData)
                        .then((response) => ( this.cvPreview(response.data) ))
                        .catch((error) => console.log('upload error /opr/atchdFileUpload : ' + error))
                }
            }
        },

        cvPreview: function (res) {
            if(res.statusCode == '200'){
                res.src = remoteURL + 'opr/imgPrview?fileNm='+res.fileNm+'&filePth='+res.filePth+'&fileKndCd=2'
                this.imgInf = res
            }
        },

        // 첨부파일 삭제
        removeAthFile: function (pk) {
            delAtchdFile(this.$store.getters.getFcltyNum, pk)
                .then((response) => ( this.updBnfcr(response.data) ))
                .catch((error) => console.log('upload error /opr/delAtchdFile : ' + error))
        },

        // 수급자 이미지 삭제
        delImgfiles: function () {
            this.imgInf.filePth = ''
            this.imgInf.fileNm = ''
            this.imgInf.src = remoteURL + 'opr/imgView'

            if(typeof this.imgInf.atchdFilePk != 'undefined' || this.imgInf.atchdFilePk != '')
                this.removeAthFile(this.imgInf.atchdFilePk)
        },

        // 기본정보 적용
        aplctInf: function (obj) {
            this.bscsInf.bnMmbrPk = obj.bnMmbrPk
            this.bscsInf.name = obj.name
            // this.bscsInf.entDt = this.$moment(obj.entDt, 'YYYYMMDD').format('YYYY-MM-DD')
            this.bscsInf.entDt = obj.entDt
            this.bscsInf.entTm = obj.entTmHh + ':' + obj.entTmMm
            this.bscsInf.entTmHh = obj.entTmHh
            this.bscsInf.entTmMm = obj.entTmMm
            this.bscsInf.rsdntBirth = this.$moment(obj.rsdntBirth, 'YYYYMMDD').format('YYYY-MM-DD')
            this.bscsInf.birth = this.$moment(obj.birth, 'YYYYMMDD').format('YYYY-MM-DD')
            this.bscsInf.lnrYn = obj.lnrYn
            this.bscsInf.gndr = obj.gndr
            if(obj.entHlthChdt == '' || obj.entHlthChdt == null)
                this.bscsInf.entHlthChdt = ''
            else
                this.bscsInf.entHlthChdt = this.$moment(obj.entHlthChdt, 'YYYYMMDD').format('YYYY-MM-DD')
            this.bscsInf.tel_1 = obj.tel_1
            this.bscsInf.tel_2 = obj.tel_2
            this.bscsInf.tel_3 = obj.tel_3
            this.bscsInf.celphn_1 = obj.celphn_1
            this.bscsInf.celphn_2 = obj.celphn_2
            this.bscsInf.celphn_3 = obj.celphn_3
            this.bscsInf.zip = obj.zip
            this.bscsInf.addr = obj.addr
            this.bscsInf.addrDtl = obj.addrDtl
            this.bscsInf.majorDsss = obj.majorDsss
            this.bscsInf.rmrks = obj.rmrks

            this.aplctSavBtn()
        },

        // 저장버튼 활성 여부 체크
        aplctSavBtn: function () {
            this.savBtn = true

            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.bscsInf.rsdntBirth) == false)
                return 0
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.bscsInf.birth) == false)
                return 0
            if(this.bscsInf.name == '')
                return 0
            if(this.bscsInf.lnrYn == '')
                return 0
            if(this.bscsInf.gndr == '')
                return 0

            this.savBtn = false
        },

        // 저장버튼 동작
        dcsnSavBtn: function () {
            let obj = {}
            obj.actv = true                                 // 경고 노출여부
            obj.tmr = 3000                                  // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                               // 경고 유형 (success, info, warning, error)
            obj.title = '수정된 항목이 존재하지 않습니다.'     // 경고 타이틀
            obj.cntnt = []                                  // 경고 내용 (arr만 출력됨!)

            let bnfcrInf = Object.assign({}, this.bscsInf)
            bnfcrInf.bnfcrPk = this.sltBnfcrPk

            if(this.chngInpChc() == 0){
                obj.cntnt.push('기존과 같은 정보로는 저장이 불가합니다.')
                this.$store.commit('setWrnnInf', obj)
                return 0
            }

            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.bscsInf.rsdntBirth))
                bnfcrInf.rsdntBirth = this.$moment(bnfcrInf.rsdntBirth, 'YYYY-MM-DD').format('YYYYMMDD')
            else
                bnfcrInf.rsdntBirth = ''
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.bscsInf.birth))
                bnfcrInf.birth = this.$moment(bnfcrInf.birth, 'YYYY-MM-DD').format('YYYYMMDD')
            else
                bnfcrInf.birth = ''
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.bscsInf.entHlthChdt))
                bnfcrInf.entHlthChdt = this.$moment(bnfcrInf.entHlthChdt, 'YYYY-MM-DD').format('YYYYMMDD')
            else
                bnfcrInf.entHlthChdt = ''
            
            if(bnfcrInf.rsdntBirth != '' && bnfcrInf.birth != '')
                this.updBnfcrInf(bnfcrInf)
            else{
                let obj = {}
                obj.actv = true                             // 경고 노출여부
                obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
                obj.type = 'warning'                        // 경고 유형 (success, info, warning, error)
                obj.title = '필수항목을 입력해주세요.'        // 경고 타이틀
                obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        // 입력항목 변경여부 확인
        chngInpChc: function () {
            let cnt = 0
            if(this.bnfcrInf.name == this.bscsInf.name)
                cnt += 1
            if(this.$moment(this.bnfcrInf.rsdntBirth, 'YYYYMMDD').format('YYYY-MM-DD') == this.bscsInf.rsdntBirth)
                cnt += 1
            if(this.$moment(this.bnfcrInf.birth, 'YYYYMMDD').format('YYYY-MM-DD') == this.bscsInf.birth)
                cnt += 1
            if(this.bnfcrInf.lnrYn == this.bscsInf.lnrYn)
                cnt += 1
            if(this.bnfcrInf.gndr == this.bscsInf.gndr)
                cnt += 1
            if(this.bnfcrInf.tel_1 == this.bscsInf.tel_1)
                cnt += 1
            if(this.bnfcrInf.tel_2 == this.bscsInf.tel_2)
                cnt += 1
            if(this.bnfcrInf.tel_3 == this.bscsInf.tel_3)
                cnt += 1
            if(this.bscsInf.entHlthChdt == ''){
                if(this.bnfcrInf.entHlthChdt == this.bscsInf.entHlthChdt)
                    cnt += 1
            }
            else if(this.$moment(this.bnfcrInf.entHlthChdt, 'YYYYMMDD').format('YYYY-MM-DD') == this.bscsInf.entHlthChdt)
                cnt += 1
            if(this.bnfcrInf.celphn_1 == this.bscsInf.celphn_1)
                cnt += 1
            if(this.bnfcrInf.celphn_2 == this.bscsInf.celphn_2)
                cnt += 1
            if(this.bnfcrInf.celphn_3 == this.bscsInf.celphn_3)
                cnt += 1
            if(this.bnfcrInf.zip == this.bscsInf.zip)
                cnt += 1
            if(this.bnfcrInf.addr == this.bscsInf.addr)
                cnt += 1
            if(this.bnfcrInf.addrDtl == this.bscsInf.addrDtl)
                cnt += 1
            if(this.bnfcrInf.majorDsss == this.bscsInf.majorDsss)
                cnt += 1
            if(this.bnfcrInf.rmrks == this.bscsInf.rmrks)
                cnt += 1
            if(this.inpFile == null)
                cnt += 1

            if(cnt == 18)
                return 0
            else
                return 1
        },

        // 팝업 닫기/확인
        offMdl: function () {
            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            obj.title = '저장되지 않은 작성항목이 존재합니다.'
            obj.cntnt = ['저장하지 않은 항목은 손실됩니다. 창을 닫을까요?']
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 창을 닫겠습니다.'

            if(this.chngInpChc() == 1){
                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 600, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });
                this.runAble = '1'
            }
            else if(this.chngInpChc() == 0)
                this.$parent.$parent.$parent.$parent.mdlOff()
        },

        // 통합 팝업 오픈
        onLclMdl: function (txt, wdt) {
            this.lclMdlInf.typ = txt
            this.lclMdlInf.maxWidth = wdt
            this.lclMdlInf.isOn = true
        },

        // 통합 팝업 초기화
        rstLclMdlInf: function () {
            this.lclMdlInf.typ = ''
            this.lclMdlInf.isOn = false
        },

        // 주소 검색 내용 적용
        rflAddrInf: function (obj) {
            this.bscsInf.zip = obj.zonecode
            this.bscsInf.addr = obj.address

            this.rstLclMdlInf()
        },
    },

    data: () => ({
        savBtn: true,
        inpFile: null,
        runAble: '0',
        sltBnfcrPk: -1,
        bnfcrPkList: [],
        bscsInf: {                                                      // 수정 정보
            bnMmbrPk: -1, name: '', gndr: '', rsdntBirth: '',
            birth: '', lnrYn: '', entHlthChdt: '', entDt: '', entTm: '', entTmHh: '', entTmMm: '',
            celphn_1: '', celphn_2: '', celphn_3: '', tel_1: '', tel_2: '', tel_3: '',
            zip: '', addr: '', addrDtl: '', majorDsss: '', rmrks: ''
        },
        lclMdlInf: { isOn: false, maxWidth: 600, typ: '' },             // 통합 팝업 제어 (01: 주소검색)
        bnfcrInf: {},                                                   // 수급자 기본정보
        imgInf: {},                                                     // 수급자 사진정보
    }),
};
</script>