<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">   
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>       
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">본인부담금 초기 선납금 관리</span>
            <v-spacer></v-spacer> 
            <span v-if="progress">
                <v-progress-circular            
                :size="25"
                color="primary"
                indeterminate
                ></v-progress-circular>
            </span>
            <span v-if="!progress">              
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn v-if="list.type == 'action' && list.visible" @click = "onClickBtn(list.text)"
                        :class="list.class" height="30" :color="list.color" rounded
                        :disabled="list.disabled"
                        style="font-size: 14px;">
                        <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                        {{list.text}}
                    </v-btn>
                </span>
            </span>
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div> 
        <v-form ref="form" lazy-validation>
        <div>
            <v-divider class="mt-3"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 py-1" style="text-align: center;" cols="2">
                    <span class="black--text font-weight-medium py-1">수급자</span>
                </v-col>
                <v-col class="d-flex py-1 tableBL align-center" cols="4">
                    <span class="d-inline-block ml-2" style="width:120px;">
                        <v-text-field
                            v-model="copayClmUnpdInfo.name" :rules="[rules.required]"
                            class="txtCtm" height="28"
                            hide-details readonly outlined dense>
                        </v-text-field>
                    </span>
                    <v-btn
                        class="ml-2 deep-orange lighten-3"
                        style="padding: 0px 1px 0px 2px !important; margin-bottom: 2px !important;"
                        min-width="30" height="26"  
                        @click="dialog.splice(0,1,true)"
                        dark small>
                        <v-icon size="20">mdi-account-multiple</v-icon>
                    </v-btn>
                </v-col>                
                <v-col class="py-1" style="text-align: center;" cols="6">                    
                </v-col>
            </v-row>            
            <v-divider class=""></v-divider>
            <v-divider class="mt-3"></v-divider>
            <v-divider class="mt-3"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2" style="border-right: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium py-1">입금일</span>
                </v-col>
                <v-col class="d-flex justify-left align-center" cols="4" style="border-right: 1px solid #dbdbdb !important;">
                    <span class="white d-inline-block py-1 pl-2" style="width: 147px;">
                        <CmmDateComp v-model="copayClmUnpdInfo.dpsDt" :required="true"></CmmDateComp>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2" style="border-right: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium">입금금액</span>
                </v-col>
                <v-col class="d-flex justify-center align-center" cols="4">
                    <span class="d-inline-block pl-2 py-1" style="width: 50%;">
                        <v-text-field
                            v-model="copayClmUnpdInfo.dpsTot"
                            :rules="[rules.required]" :disabled="copayClmUnpdInfo.dpsPrcHisPk>0"
                            type="number" height="28"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <v-spacer></v-spacer>
                    <span class="px-2 py-1">
                        {{ Number(copayClmUnpdInfo.dpsTot).toLocaleString('ko-KR') }} 원
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2" style="border-right: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium py-1">입금자명</span>
                </v-col>
                <v-col class="d-flex justify-center align-center" cols="4" style="border-right: 1px solid #dbdbdb !important;">
                    <span class="d-inline-block px-2 py-1" style="width: 100%;">
                        <v-text-field
                            v-model="copayClmUnpdInfo.dpsNm" :rules="[rules.required]"
                            height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2" style="border-right: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium">입금방법</span>
                </v-col>
                <v-col class="d-flex justify-left align-center" cols="4">
                    <span class="d-inline-block px-2 py-1" style="width: 150px;">
                        <v-select class="hsptlSelect"
                            v-model="copayClmUnpdInfo.dpsMthdCd" height="28"
                            item-text="valcdnm" item-value="valcd" :items="this.$parent.$parent.$parent.$parent.vCd84"
                            dense hide-details outlined>
                        </v-select>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2" style="border-right: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium py-1">입금비고</span>
                </v-col>
                <v-col class="d-flex justify-center align-center" cols="10">
                    <span class="d-inline-block px-2 py-1" style="width: 100%;">
                        <v-text-field
                            v-model="copayClmUnpdInfo.rmrks"
                            height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider class="mb-4"></v-divider>
        </div>
        </v-form>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" max-width="700" content-class="round" persistent eager>   
                <IntgrSlctn
                    :mdlTtl="'수급자 선택'" 
                    :tabInfList="['1']" 
                    :fixList="$parent.$parent.$parent.$parent.fixList" 
                    :mode="'1'"
                    :prtWrtDt="$parent.$parent.$parent.$parent.inqYMD"
                    :cd41="$parent.$parent.$parent.$parent.vCd41" 
                    :cd42="$parent.$parent.$parent.$parent.vCd42" 
                    :cd65="$parent.$parent.$parent.$parent.vCd65" 
                    @intgrResList="getAplyList" @onClose="dialog.splice(0,1,false)">
                </IntgrSlctn> 
            </v-dialog>   
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[1]" max-width="500">    
                <btnModalDelete :title="delTitle" @madalState="onDeleteAct"></btnModalDelete>  
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import IntgrSlctn from '../commons/IntgrSlctn.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import { insDpsPrcHis, delDpsPrcHis } from '../../api/bnfcr.js';

export default {
    name: 'FacInfoPrpymWrt',
    
    components: {  
       IntgrSlctn,
       CmmDateComp,
       btnModalDelete,
    },

    props : {
        
    },

    computed: {
        getSltPrpPk:function(){
            return this.$parent.$parent.$parent.$parent.sltPrpPk
        }
    },

    created: function(){  
    },

    mounted: function(){
        this.$nextTick(function () {   
            
        })
    },

    watch: {
        'getSltPrpPk':function(value){
            // console.log('sltPrpPk : '+value)
            if(value > -1){
                this.clearFrm()
                if(value > 0) this.setPrpymItm()
            }
        }
    },

    methods: {  
        onClickBtn:function(text){
            switch (text) {
                case '저장':
                    this.setDpsPrcHis()
                    break;
                case '삭제':
                    this.delPrpym()
                    break;
                default:
                    break;
            }
        },
        setPrpymItm:function(){
            let pk      = this.$parent.$parent.$parent.$parent.sltPrpPk
            let idx     = this.$parent.$parent.$parent.$parent.initPrpymList.findIndex(v=>v.dpsPrcHisPk == pk)
            let target  = this.$parent.$parent.$parent.$parent.initPrpymList[idx]
            
            this.copayClmUnpdInfo.dpsPrcHisPk   = target.dpsPrcHisPk
            this.copayClmUnpdInfo.bnMmbrPk      = target.bnMmbrPk
            this.copayClmUnpdInfo.name          = target.name
            this.copayClmUnpdInfo.dpsDt         = this.$moment(target.dpsDt,'YYYYMMDD').format('YYYY-MM-DD'),
            this.copayClmUnpdInfo.dpsTot        = target.dpsTot
            this.copayClmUnpdInfo.dpsNm         = target.dpsNm
            this.copayClmUnpdInfo.dpsMthdCd     = target.dpsMthdCd
            this.copayClmUnpdInfo.rmrks         = target.rmrks

            if(target.mdfAblYn != '1') {
                this.filledBtn[0].disabled = true
                this.filledBtn[1].disabled = true
            } else {
                this.filledBtn[1].disabled = false
            }
        },
        setDpsPrcHis:function(){
            if(this.$refs.form.validate()){                
                
                let obj = {
                    fcltyNum        : this.$store.getters.getFcltyNum,
                    userNm          : this.$store.getters.getUserNm,
                    fcltyInitPk     : this.$parent.$parent.$parent.$parent.initItm.fcltyInitPk,
                    dpsPrcHisPk     : this.copayClmUnpdInfo.dpsPrcHisPk,
                    clmYymm         :'299912',
                    bnMmbrPk        : this.copayClmUnpdInfo.bnMmbrPk,
                    bnfcrPk         : this.copayClmUnpdInfo.bnfcrPk,
                    dpsDt           : this.$moment(this.copayClmUnpdInfo.dpsDt,'YYYY-MM-DD').format('YYYYMMDD'), 
                    dpsTot          : this.copayClmUnpdInfo.dpsTot, 
                    dpsNm           : this.copayClmUnpdInfo.dpsNm, 
                    dpsClcd         : '1',
                    preDpsYn        : '1',
                    dpsMthdCd       : this.copayClmUnpdInfo.dpsMthdCd,  
                    rmrks           : this.copayClmUnpdInfo.rmrks,
                }

                console.log(obj)

                this.progress = true
                
                insDpsPrcHis(obj)
                    .then((response) => (this.setDpsPrcHisAfter(response.data)))
                    .catch((error) => console.log('connect error /bnfcr/insCopayClmHis : ' + error))
                
            }else{
                this.snackControll([true, 5000, '필수 항목을 확인해주세요.', 'error'])
            }
        },


        //미납금 삭제
        onDeleteAct:function(res){
            if(res == 9990){
                let obj = {
                    fcltyNum        : this.$store.getters.getFcltyNum,
                    userNm          : this.$store.getters.getUserNm,
                    dpsPrcHisPk     : this.copayClmUnpdInfo.dpsPrcHisPk,
                }

                if(this.copayClmUnpdInfo.dpsPrcHisPk > 0){
                    this.progress = true
                    delDpsPrcHis(obj)
                        .then((response) => (this.onDeleteActAfter(response.data)))
                        .catch((error) => console.log('connect error /bnfcr/delDpsPrcHis : ' + error))
                }
            }else if(res == 7){
                this.dialog.splice(1, 1, false)
            }
        },

        setDpsPrcHisAfter:function(res){
            if (res.statusCode == 200) {
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])
                this.$emit('reLoad')
                this.onClose()
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }

            this.progress = false
        },

        onDeleteActAfter:function(res){
            if (res.statusCode == 200) {
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])
                this.$emit('reLoad')
                this.onClose()
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.dialog.splice(1, 1, false)
            this.progress = false
        },
        
        getAplyList:function(res){
            res.forEach(el => {                   
                this.copayClmUnpdInfo.name       = el.name
                this.copayClmUnpdInfo.bnMmbrPk   = el.bnMmbrPk  
                this.copayClmUnpdInfo.bnfcrPk    = el.bnfcrPk 
            });
        },
        
        //선납금 삭제
        delPrpym:function(){
            if(this.copayClmUnpdInfo.dpsPrcHisPk > 0){                
                this.delTitle   = this.copayClmUnpdInfo.name+'님의 '+this.$moment(this.copayClmUnpdInfo.dpsDt,'YYYYMMDD').format('YYYY.MM.DD')+' 선납금'
                this.dialog.splice(1, 1, true)
            }
        }, 

        onClose: function () {
            this.$emit('isClose')            
        },
        clearFrm:function(){
            //값 초기화
            this.copayClmUnpdInfo.dpsPrcHisPk = 0       
            this.copayClmUnpdInfo.name     = ''
            this.copayClmUnpdInfo.bnMmbrPk = 0
            this.copayClmUnpdInfo.bnfcrPk = 0
            this.copayClmUnpdInfo.dpsDt =  ''
            this.copayClmUnpdInfo.dpsTot =  0 
            this.copayClmUnpdInfo.dpsNm =  '' 
            this.copayClmUnpdInfo.dpsMthdCd =  '1' 
            this.copayClmUnpdInfo.rmrks =  '초기셋팅 - 시스템 초기 선납금' 
            this.$refs.form.resetValidation()
            this.filledBtn[0].disabled = false
            this.filledBtn[1].disabled = true
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    data: () => ({     
        ymd:'',     
        delTitle:'',
        progress:false,
        copayClmUnpdInfo:{
            dpsPrcHisPk : 0,
            clmYymm     :'299912',
            bnMmbrPk    : 0,
            bnfcrPk     : 0,
            dpsDt       : '', 
            dpsTot      : 0, 
            dpsNm       : '', 
            dpsClcd     : '1',
            preDpsYn    : '1',
            dpsMthdCd   : '1',  
            rmrks       : '초기셋팅 - 시스템 초기 선납금' ,             
        },
        mdlTtl: '',
        tabInfList: [],        
        rtrInc:false,
        dialog:[false,false,],
        filledBtn: [            
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type:'action', visible: true, disabled: false,},
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type:'action', visible: true, disabled: true,},
        ],          
        snackOptions: [false, 3000, '', 'info'],    
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },         
    }),
};
</script>