<template>
    <v-sheet>
        <v-snackbar v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500"
            class="pa-0" :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top style="top: 55px;">
            <div style="text-align: center; ">
                <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{ snackOptions[2] }}</span>
            </div>
        </v-snackbar>
        <div class="d-flex pl-1 pr-3" style="margin-bottom: 5px; justify-content: right !important;">
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn v-if="list.type == 'action' && list.visible" @click="onClickBtn(list.text)" :class="list.class"
                    samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                    <v-icon small>{{ list.icon }}</v-icon>
                    <span style="font-size: 1rem;">{{ list.text }}</span>
                </v-btn>
                <v-dialog v-if="list.type == 'dialogue' && list.visible" 
                    v-model="dialog[i]" :max-width="list.width">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled" :class="list.class" samll height="30"
                                :color="list.color" rounded>
                                <v-icon small>{{ list.icon }}</v-icon>
                                <span style="font-size: 1rem;">{{ list.text }}</span>
                            </v-btn>
                        </template>
                        
                </v-dialog>
            </span>
        </div>
        <v-form ref="form" lazy-validation>
            <v-row style="border-top: 1px solid #dbdbdb;" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01" cols="2">
                    <span class="black--text font-weight-medium py-2" style="right: 1px;">프로그램</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-left align-center">
                    <!-- 프로그램명 textfield -->
                    <span class="d-inline-block ml-2" style="width:100%;">
                        <v-text-field
                            v-model="prgInfInfo.prgNm" :rules="[rules.required]"
                            height="28" dense outlined hide-details>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-row style="border-top: 1px solid #dbdbdb;" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01" cols="2">
                    <span class="black--text font-weight-medium py-2">프로그램 유형</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-left align-center" cols="4">
                    <span class="d-inline-block ml-2 d-flex flex-row" style="width:100%; ">
                        <!-- 프로그램 유형 select -->
                        <v-select dense hide-details outlined
                        v-model="prgInfInfo.prgTypMngPk"
                        :items="prgTyitems"
                        item-text="prgTypNm"
                        item-value="prgTypMngPk"
                        :rules="[rules.required]"></v-select>
                        <!-- 프로그램 유형추가 btn -->
                        <v-btn
                            @click="onModalType(0)"
                            style="margin-top: 2px;"
                            class="ml-1 mr-1" color="blue200" height="26" icon>
                            <v-icon>mdi-plus-circle-outline</v-icon>
                        </v-btn>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center greyE01" cols="2">
                    <span class="black--text font-weight-medium py-2">지표</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-left align-center" cols="4">
                    <!-- 지표 -->
                    <span class="d-inline-block ml-2" style="width:75px;">
                        <v-text-field
                            v-model="idxCdNm" :rules="[rules.required]" :key="forceRender"
                            color="blue" height="28" ref="info"
                            hide-details outlined dense readonly>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-row style="border-top: 1px solid #dbdbdb;" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01" cols="2">
                    <span class="black--text font-weight-medium py-2">장소</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-left align-center" cols="4">
                    <span class="d-inline-block ml-2" style="width:85%;">
                        <!-- 장소 textfield -->
                        <v-text-field
                            v-model="prgInfInfo.plc" :rules="[rules.required]"
                            height="28" dense hide-details outlined>
                        </v-text-field>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center greyE01" cols="2">
                    <span class="black--text font-weight-medium py-2">진행자</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-start align-center" cols="4">
                    <span class="d-inline-block py-1 pl-2" style="width: 100px;">
                        <v-text-field
                            v-model="prgInfInfo.mc" :key="forceRender"
                            color="blue" height="28" ref="info"
                            hide-details outlined readonly dense>
                        </v-text-field>
                    </span>
                    <v-btn
                        class="brown lighten-3 d-inline-block ml-1" style="padding:0px 1px 0px 2px !important" 
                        @click="onEmpSlct" min-width="30" height="28" dark small depressed>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                            </template>
                            <span>직원선택</span>
                        </v-tooltip>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row style="border-top: 1px solid #dbdbdb;" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01" cols="2">
                    <span class="black--text font-weight-medium py-2">준비물</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-left align-center" style="height: 100px">
                    <span class="d-inline-block ml-2" style="width:100%;">
                        <!-- 준비물 textfield -->
                        <v-textarea dense outlined hide-details rows="5"
                        v-model="prgInfInfo.mtrl" height="90"
                        class="rmrksArea" no-resize  
                        :rules="[rules.required]"></v-textarea>
                    </span>
                </v-col>
            </v-row>
            <v-row style="border-top: 1px solid #dbdbdb;" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01" cols="2">
                    <span class="black--text font-weight-medium py-2">목표</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-left align-center" style="height: 100px">
                    <span class="d-inline-block ml-2" style="width:100%;">
                        <!-- 목표 textfield -->
                        <v-textarea dense outlined hide-details rows="5"
                        v-model="prgInfInfo.goal" height="90"
                        class="rmrksArea" no-resize  
                        :rules="[rules.required]"></v-textarea>
                    </span>
                </v-col>
            </v-row>
            <v-row style="border-top: 1px solid #dbdbdb;" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01" style="line-height: 1.2;" cols="2">
                    <span class="black--text font-weight-medium py-2" style="text-align:center">프로그램 내용<br>(진행과정)</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-left align-center py-1">
                    <span class="d-inline-block ml-2" style="width:100%;">
                        <!-- 프로그램 내용 textfield -->
                        <v-textarea dense outlined hide-details rows="14"
                            v-model="prgInfInfo.prgCntnt"
                            class="rmrksArea" no-resize  
                            :rules="[rules.required]"></v-textarea>
                    </span>
                </v-col>
            </v-row>
            <v-row style="border-top: 1px solid #dbdbdb;" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01" cols="2">
                    <span class="black--text font-weight-medium py-2">기대효과</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-left align-center" style="height: 85px">
                    <span class="d-inline-block ml-2" style="width:100%;">
                        <!-- 기대효과 textfield -->
                        <v-textarea dense outlined hide-details rows="4"
                        v-model="prgInfInfo.expEfc" height="75"
                        class="rmrksArea" no-resize  
                        :rules="[rules.required]"></v-textarea>
                    </span>
                </v-col>
            </v-row>
            <v-row style="border-top: 1px solid #dbdbdb; border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01" style="line-height: 1.2;" cols="2">
                    <span class="black--text font-weight-medium py-2" style="text-align:center">기능회복훈련<br>(급여제공기록지포함)</span>
                </v-col>
                <v-col class="px-2 pt-3 pb-2">
                    <!-- 기능회복훈련 checkbox -->
                    <span
                        class="d-inline-block justify-center align-center"
                        v-for="(item,i) in codeItem38" :key="i">
                        <span class="d-inline-block" style="width: 140px; height: auto;">
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                v-model="prgInfInfo.fncRcvCd" 
                                :label="item.valcdnm" :value="item.valcd" 
                                :rules="[rules.selectCheck]"
                                hide-details>
                            </v-checkbox>
                        </span>
                    </span>
                </v-col>
            </v-row>
        </v-form>
        <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="600">
            <PrgrmCategory ref="PrgrmCategory" @isClose="isModalType(0)" @reload="reset()"></PrgrmCategory>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500">    
            <btnModalDelete :title="'프로그램 평가'" @madalState="onDeleteBtn"></btnModalDelete>  
        </v-dialog>
    </v-sheet>
</template>

<script>
import { getMultiStcd } from '../../api/index.js';
import { getStcd } from '../../api/index.js';
import { selPrgInfInfo, getAllPrgIM, insPrgInf, selPrgTypMnglist, delPrgInf } from '../../api/prgrm.js';

import PrgrmCategory from '../../components/prgrm/PrgrmCategory.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';

export default {
    name: 'PrgInfrmMgmt',

    components: {
        PrgrmCategory,
        btnModalDelete,

    },
    computed: {
        getPrgTypMngPk() {
            return this.prgInfInfo.prgTypMngPk;
        },
        getSubRunEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },
    watch: {
        getPrgTypMngPk: function (value) {
            //지표구분코드값
            let idxCd = 0
            //프로그램 유형 목록에서 지표구분 값 조회
            this.prgTyitems.forEach(element => {
                if (element.prgTypMngPk == value) idxCd = element.idxCd
            })
            //공통코드에서 지표구분 코드 값으로 지표명 조회
            this.prgCode.forEach(element => {
                if (element.valcd == idxCd) this.idxCdNm = element.valcdnm
            })
        },
        getSubRunEvt: function (bol) {
            if(bol){
                let obj = this.$store.getters.getSubOutObj
                this.prgInfInfo.mc = obj.name
                this.prgInfInfo.mcMmbrPk = obj.mmbrPk
                
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
            }
        },
    },
    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
           this.getPrgTypMng()
        })
    },
    created: function () {
        this.$nextTick(function () {
            this.onCodeload()
        })
        getStcd('40')
            .then((response) => (this.stCdMapping(response.data)))
            .catch((error) => console.log(error))
    },
    methods: {
        onClickBtn: function (text) {
            if (text == '저장') {
                this.setPrgInfRec()
                this.$emit('trColorChange', true)
            }
            else if (text == '신규') {
                this.resetPage()
                this.idxCdNm = ''
                this.prgInfPk = 0
                this.filledBtn[1].disabled = false
                this.$emit('trColorChange', true)
            }
            else if (text == '삭제') {
                this.dialogDelete = true
            }
        },
        resetPage: function () {
            this.prgInfInfo = Object.assign({}, { prgTypMngPk: 0, prgNm: '', plc: '', mc: '', mtrl: '', goal: '', expEfc: '', fncRcvCd: [], prgCntnt: '' })
            this.$refs.form.resetValidation()
            this.idxCdNm= ''
            this.filledBtn[2].disabled = true
            this.btnDisabled = true
        },
        getPrgInfInfo: function (obj) {
            if(obj > 0){
                selPrgInfInfo(this.$store.getters.getFcltyNum, obj)
                    .then((response) => (this.getPrgInfInfoAfter(response.data)))
                    .catch((error) => console.log('connect error prgrm/selPrgInfInfo : ' + error))
            }else{
                    selPrgInfInfo(this.$store.getters.getFcltyNum, obj.prgInfPk)    
                        .then((response) => (this.getPrgInfInfoAfter(response.data)))
                        .catch((error) => console.log('connect error prgrm/selPrgInfInfo : ' + error))
            }
        },
        getPrgInfInfoAfter: function (res) {
            let cache = Object.assign({}, res)
            this.chkHisPk = Number(cache.prgInfPk)
            this.prgInfInfo = cache
        },
        getAllPrgIM: function (obj) {
            getAllPrgIM(this.$store.getters.getFcltyNum, obj.prgInfPk)
                .then((response) => (this.getAllPrgIMAfter(response.data)))
                .catch((error) => console.log('connect error prgrm/selPrgInflist : ' + error))
        },
        getAllPrgIMAfter: function (res) {
            let cache = Object.assign({}, res)
            this.chkHisPk = Number(cache.prgInfPk)
            this.items = cache
        },
        getPrgTypMng() {
            let fcltyNum = this.$store.getters.getFcltyNum
            this.valClcd = '40'
            this.valClcdnm = '지표구분'
            selPrgTypMnglist(fcltyNum, '40')
                .then((response) => (this.resData(response.data)))
                .catch((error) => console.log(error))
        },
        resData(data) {
            this.prgTyitems = data
        },
        getBnfcrCntrcInfPk(value) {
            if (value == 0) this.tabItems[2].enter = true
            else this.tabItems[2].enter = false
        },
        setPrgInfRec: function () {
            if (this.$refs.form.validate()) {
                let obj = {
                    prgTypMngPk: this.prgInfInfo.prgTypMngPk,
                    prgInfPk: this.prgInfPk,
                    prgNm: this.prgInfInfo.prgNm,
                    plc: this.prgInfInfo.plc,
                    mc: this.prgInfInfo.mc,
                    mtrl: this.prgInfInfo.mtrl,
                    goal: this.prgInfInfo.goal,
                    expEfc: this.prgInfInfo.expEfc,
                    prgCntnt: this.prgInfInfo.prgCntnt,
                    idxCdNm: this.idxCdNm,
                    fncRcvCd: this.prgInfInfo.fncRcvCd,
                    fcltyNum: this.$store.getters.getFcltyNum,
                    mcMmbrPk: this.prgInfInfo.mcMmbrPk,
                    userNm: this.$store.getters.getUserNm,
                }
                insPrgInf(obj)
                    .then((response) => (this.setPrgInfAfter(response.data)))
                    .catch((error) => console.log('connect error prgrm/insPrgInf : ' + error))
            }else{
                this.snackControll([true, 2500, '필수 항목을 입력해주세요.', 'warning'])
            }
        },
        setPrgInfAfter: function (res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.getPrgInfInfo(res.result)
                this.$emit('reload', true)
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        
        onModalType(vlaue) {
            this.$refs.PrgrmCategory.onShow()
            this.dialog.splice(vlaue, 1, true)
        },  
        onShow(obj) {
            this.prgInfPk = obj.prgInfPk
            this.getPrgInfInfo(obj)
            this.getAllPrgIM(obj)
            this.filledBtn[2].disabled = false
            this.btnDisabled = false
        },
        isModalType: function (event = 1) {
            if (event == 99) {
                this.onCodeload()
            } else {
                this.dialog.splice(0, 1, false)
            }
        },
        reset: function () {
            this.getPrgTypMng()
        },
        stCdMapping: function (res) {
            this.prgCode = res
        },
        onCodeload: function () {
            let fcltyNum = this.$store.getters.getFcltyNum
            getMultiStcd(['40','38'], fcltyNum)
            .then((response) => (this.codeMapping(response.data)))
            .catch((error) => console.log(error))
        },
        codeMapping: function (res) {
            this.codeItem40 = res["vCd40"]
            this.codeItem38 = res["vCd38"]
        },
        onEmpSlct: function () {
            let obj = {}
            obj.empTab = true
            obj.extEmpTab = true
            obj.wrtDt = this.$moment().format('YYYYMMDD')

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 700, prsst: true, typ: '3001', inObj: obj, outObj: null, runEvt: false,
            });
        },
        // preSetOutHis: function() {
        //     let vali = this.$refs.form.validate()
        //     if(!vali) return
        //     let data = Object.assign({}, this.items)
        //     data.fcltyNum = this.$store.getters.getFcltyNum
        //     this.setPrgInfRec()
        // },
        snackControll: function (options) {
            this.snackOptions = options
        },
        onDeleteBtn: function (res) {
            
            if (res == 9990) {
                if (this.prgInfPk != undefined) {
                    let obj = {
                        fcltyNum: this.$store.getters.getFcltyNum,
                        prgInfPk: this.prgInfPk,
                        userNm: this.$store.getters.getUserNm,
                    }
                    delPrgInf(obj)
                        .then((response) => (this.delPrgInfAfter(response.data)))
                        .catch((error) => console.log(error))
                }
            } else if (res == 7) {
                this.dialogDelete = false
            }
        },
        delPrgInfAfter(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])                
                this.$emit('reload', true)
                this.resetPage()
                this.dialogDelete = false
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },    
        onPrintBtn: function () {
            // this.$refs.PrgrmPrint.onShow()
            this.menu.splice(1, 1, true)
        },
    },

    data: () => ({
        forceRender:0,
        filledBtn: [
            { icon: 'mdi-pencil-outline', class: 'ml-1 white--text', color: 'blueBtnColor', text: '신규', type: 'action', width: '600px', visible: true, disabled: false, },
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color: 'blueBtnColor', text: '저장', type: 'action', width: '500px', visible: true, disabled: false, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color: 'grey006', text: '삭제', type: 'action', width: '500px', visible: true, disabled: true, },
        ],
        dialog: [false],
        idxCdNm: '',
        prgInfInfo: { prgTypMngPk: 0, prgNm: '', plc: '', mc: '', mtrl: '', goal: '', expEfc: '', fncRcvCd: [], prgCntnt: '' },
        prgInfPk: 0,
        prgTypMngPk: [{prgTypNm: '선택',prgTypMngPk: 0},],
        prgTyitems: [],
        items: [],
        item: [],
        codeItem40: [],
        codeItem38: [],
        rules: {
            required: value => !!value || 'Required.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            selectCheck: value => value?.length > 0 || 'Required.',
        },
        icon: 'mdi-account-outline',
        btnDisabled: true,
        snackOptions: [false, 3000, '', 'info'],
        dialogDelete: false,
    })
}
</script>