<template>
    <v-sheet class="rounded-lg rounded-l-0" color="" min-width="890" max-width="890">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-end pl-1 pr-3">
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn v-if="list.type == 'action' && list.visible" @click = "onClickBtn(list.text)"
                    :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                    <v-icon small>{{list.icon}}</v-icon>
                    <span style="font-size: 1rem;">{{list.text}}</span>
                </v-btn>
            </span>
            <v-btn v-for="(list, j) in outlinedBtn" :key="filledBtn.length + j" @click="mtngRcrdPrt()"
                :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded outlined>
                <v-icon small>{{list.icon}}</v-icon>
                <span style="font-size: 1rem;">{{list.text}}</span>
            </v-btn>
        </div>
        <v-divider class="my-2"></v-divider>
        <v-tabs
            v-model="tab" class="tab-dft-Theme" active-class="black"
            center-active hide-slider>
            <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class" :disabled="item.enter">{{item.tab}}</v-tab>
        </v-tabs>
        <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
        <v-tabs-items v-model="tab">            
            <v-tab-item eager transition="fade-transition">
                <CaseMgmtTab1 ref="caseMgmtTab1" :tab="tab" @caseMgmtTrigger="caseMgmtReLoad" ></CaseMgmtTab1>
            </v-tab-item>
            <v-tab-item eager transition="fade-transition">
                <CaseMgmtTab2 ref="caseMgmtTab2" :tab="tab" :year="year" @caseMgmtTrigger="caseMgmtReLoad"></CaseMgmtTab2>
            </v-tab-item>            
        </v-tabs-items>
        <v-dialog v-model="dialogDelete" max-width="500">    
            <btnModalDelete title="사례관리" @madalState="onDelete"></btnModalDelete>  
        </v-dialog>
    </v-sheet>
</template>

<script>
import { selAtchdFileList } from '../../api/index.js';
import { selMtngRcrdInfo, selMtngAtndList, selMtngCaseBnftList, delMtngRcrd } from '../../api/bnftSrv.js';

import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import CaseMgmtTab1 from '../../components/bnftSrv/CaseMgmtTab1.vue';
import CaseMgmtTab2 from '../../components/bnftSrv/CaseMgmtTab2.vue';

export default {
    name: 'CaseMgmt',
    
    components: {
        CaseMgmtTab1,
        CaseMgmtTab2,
        btnModalDelete,
    },

    props : {
        caseMgmtPk : {type:Number, default:0},
        year : {type:Number, default:0}
    },

    mounted: function(){             
        this.$nextTick(function () {  
            this.mtngRcrdPk = this.caseMgmtPk
            this.onShow()
        })
    },

    watch: {
        'caseMgmtPk': function (value) {  
            this.onInit()

            if(value > 0) {
                this.mtngRcrdPk = this.caseMgmtPk
                this.filledBtn[2].disabled = false
                this.outlinedBtn[0].disabled = false
                this.tabItems[1].enter = false
                this.onShow()
            }else{
                this.mtngRcrdPk = 0
                this.filledBtn[2].disabled = true
                this.tabItems[1].enter = true
            }
        },
    },

    methods: {
        onClickBtn:function(text){
            if(text == "저장"){
                if(this.tab == 0){
                    this.$refs.caseMgmtTab1.onSave(this.mtngRcrdPk)
                }else if(this.tab == 1){
                    this.$refs.caseMgmtTab2.onSave(this.mtngRcrdPk)
                }
            }else if(text == "신규"){
                this.$emit('setSelected', 0);
            }else if(text == "삭제"){
                this.dialogDelete = true
            }
        },

        onShow:function(){
            this.getMtngRcrdInfo()
            this.getMtngAtndList()
            this.getlMtngCaseBnftList()
            this.getAtchdFileList()
        },
        //초기화
        onInit:function(){
            this.tab =0
            this.$refs.caseMgmtTab1.onReset()
        },

        //회의 정보
        getMtngRcrdInfo(){

            selMtngRcrdInfo(this.$store.getters.getFcltyNum,this.mtngRcrdPk)
                    .then((response) => ( this.$refs.caseMgmtTab1.getMtngRcrdInfoAfter(response.data) ))
                    .catch((error) => console.log('upload error /opr/selMtngRcrdInfo : ' + error))
        },
        //참석자 조회
        getMtngAtndList(){            
            selMtngAtndList(this.$store.getters.getFcltyNum,this.mtngRcrdPk)
                    .then((response) => ( this.$refs.caseMgmtTab1.getMtngAtndListAfter(response.data) ))
                    .catch((error) => console.log('upload error /opr/selMtngAtndList : ' + error))
        },

        //급여제공반영정보
        getlMtngCaseBnftList:function(){
            let fcltyNum  = this.$store.getters.getFcltyNum
            if(this.mtngRcrdPk > 0){
                selMtngCaseBnftList(fcltyNum, this.mtngRcrdPk)
                    .then((response) => ( this.$refs.caseMgmtTab2.getlMtngCaseBnftListAfter(response.data) ))
                    .catch((error) => console.log('upload error /opr/selMtngCaseBnftList : ' + error))
            }
        },

        //급여제공 관련 자료
        getAtchdFileList:function(){
            let fcltyNum  = this.$store.getters.getFcltyNum            
            let clcd = '3'
            if(this.mtngRcrdPk > 0){
                selAtchdFileList(fcltyNum, clcd,this.mtngRcrdPk)
                    .then((response) => ( this.$refs.caseMgmtTab2.getAtchdFileListAfter(response.data) ))
                    .catch((error) => console.log('upload error /opr/selAtchdFileList : ' + error))
            }
        },

        onforce(){
            ++this.forceRender
        },
        onDelete:function(res){
            if(res == 9990){
                let fcltyNum = this.$store.getters.getFcltyNum
                let clcd = "1";
                if(this.mtngRcrdPk > 0){
                    delMtngRcrd(fcltyNum,clcd,this.mtngRcrdPk,this.$store.getters.getUserNm)
                                .then((response) => ( this.onDeleteAfter(response.data) ))
                                .catch((error) => console.log('upload error /opr/delMtngRcrd : ' + error))
                }
            }else if(res == 7){
                this.dialogDelete = false
            }
        },
        onDeleteAfter:function(res){
             if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.$emit('setSelected',0);
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.dialogDelete = false
        },
        caseMgmtReLoad:function(res=null){
            if(res != null){
                if(res.statusCode == 200){                                                
                    this.snackControll([true, 2500, res.message, 'info'])
                    this.$emit('setSelected', res.result);
                    this.onInit()
                    this.onShow()
                }else{
                    this.snackControll([true, 5000, res.message, 'error'])
                }
            }else{
                this.$emit('setSelected', this.mtngRcrdPk);
                this.onInit()
                this.onShow()
            }
        },
        snackControll: function (options) {
            this.snackOptions = options
        },      
        mtngRcrdPrt:function(){
            this.inqBzPk[0] = this.mtngRcrdPk
            
            this.prtThrowObj.prtCd = '35'
            this.prtThrowObj.list[0].bzPk = this.inqBzPk
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
            });
        },  
    },
    data: () => ({   
        tab:0,     
        mtngRcrdPk:0,
        inqBzPk: [],
        filledBtn: [            
            { icon: 'mdi-pencil-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '신규', type: 'action', width:'600px', visible: true, disabled: false, },
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type: 'action', width:'500px', visible: true, disabled: false, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type: 'action', width:'500px', visible: true, disabled: true, },
        ],
         outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006 ', text: '출력', disabled: true, },
        ],  
        snackOptions: [false, 3000, '', 'info'],
        tabItems: [
            { index: 0, tab: '사례관리 회의 상세정보', class: 'fontOneDotTwoRem px-15', enter:false},
            { index: 1, tab: '급여제공반영 정보·관련자료', class: 'fontOneDotTwoRem px-15', enter:true},
        ],     
        forceRender:0,
        dialogDelete : false,
        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '', inqYmdStart: '', inqYmdEnd: '',
            rptTyp: '1', mode: '2', list: [ { bzClcd: [], bzPk: [] } ],
        },
        menu:[false],
    }),
};
</script>