<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <v-snackbar v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500"
            class="pa-0" :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
            <div style="text-align: center;">
                <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{ snackOptions[2] }}</span>
            </div>
        </v-snackbar>
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold">문자&nbsp;발신번호&nbsp;등록</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <div class="mt-3" style="border: 1px solid #dbdbdb !important;"> 
            <div class="d-flex">
                <div class=" pa-0 ma-0" no-gutters style="width: 100%;">
                    <div class=" d-flex tableBB">
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:30%">
                            <span class="black--text font-weight-medium">문자 발신번호
                            </span>
                            <span style="" class="topTitleColor--text">
                                ({{ data1 }})
                            </span>
                        </div>
                        <div class=" d-flex align-center pa-1 ma-0"
                            style="width:70%;border-left: 1px solid #dbdbdb !important;">
                            <span style="width: 7.5%; margin-left: 8px;">
                                <v-text-field hide-details outlined dense v-model="items.cllTel1">
                                </v-text-field>
                            </span>
                            -
                            <span style="width: 8.5%">
                                <v-text-field hide-details outlined dense v-model="items.cllTel2">
                                </v-text-field>
                            </span>
                            -
                            <span style="width: 8.5%">
                                <v-text-field hide-details outlined dense v-model="items.cllTel3">
                                </v-text-field>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex tableBB" style="">
                <div class=" pa-0 ma-0" no-gutters style="width: 100%;">
                    <div class=" d-flex">
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:30%;  height: 410px;">
                            <span class="black--text font-weight-medium">전화번호 가입증명서
                            </span>
                        </div>
                        <div class="pa-1"
                            style="width:70%;border-left: 1px solid #dbdbdb !important;">
                            <v-radio-group v-model="radioGroup" style=" width: 100%;" row class="ml-1">
                                <v-radio
                                    :label="'파일첨부'"
                                    :value="'0'"
                                ></v-radio>
                                <v-radio
                                    :label="'팩스'"
                                    :value="'1'"
                                ></v-radio>
                            </v-radio-group>
                            <div style="border: 1px solid #dbdbdb; height: 350px;" v-if="radioGroup == '0'">
                                <div style="border: 1px solid #dbdbdb; width: 400px; height: 193px; margin: 0 auto;">
                                    <span>
                                        <v-chip v-for="(item, j) in fileRes" :key="j"                        
                                        label                        
                                        close
                                        class="ma-1"
                                        outlined
                                        @click="fileDownload(j)"
                                        @click:close="delfiles(j)"
                                        >
                                            <v-icon v-if="item.ext=='pdf' || item.ext=='PDF'" color='pink' left>
                                                mdi-file-pdf-box
                                            </v-icon>
                                            <v-icon v-else-if="item.ext=='xlsx' || item.ext=='xls'" color='green' left>
                                                mdi-microsoft-excel
                                            </v-icon>
                                            <v-icon v-else-if="item.ext=='pptx' || item.ext=='ppt'" color='red' left>
                                                mdi-file-powerpoint
                                            </v-icon>
                                            <v-icon v-else-if="item.ext=='doc' || item.ext=='docx'" color='blue' left>
                                                mdi-file-word
                                            </v-icon>
                                            <v-icon v-else color='cyan' left>
                                                mdi-file-document
                                            </v-icon>
                                            {{ item.orgFileNm }}
                                        </v-chip> 
                                    </span>
                                </div>
                                <div style="width: 15%; height: 30px; margin: 0 auto;" class="d-flex align-center justify-center">
                                    <span class="black--text font-weight-medium">자료첨부</span>
                                    <v-file-input 
                                        v-model="files" 
                                        dense hide-details truncate-length="5" 
                                        multiple counter
                                        hide-input
                                        outlined
                                        @change="upFile()"
                                    ></v-file-input>
                                </div>
                                <div class="greyE01 pa-1 ma-0" style="width:100%;  height: 125px;">
                                    <div>
                                        <span class="ml-4 black--text font-weight-medium">발신번호 서류 인증을 위해 다음과 같은 파일이 필요합니다.</span>
                                    </div>
                                    <div class="ml-7">
                                        <div>1. 사업자등록증.</div>
                                        <div>2. 발신번호의 통신서비스 이용 증명원.</div>
                                        <div>3. 해당 업무를 위임하는 위임.</div>
                                        <div>4. 위임장에 날인한 인감의 인감증명서.</div>
                                    </div>
                                </div>
                            </div>
                            <div style="border: 1px solid #dbdbdb;" v-if="radioGroup == '1'">
                                <div class="greyE01 d-flex pa-1 ma-0" style="width:100%;  height: 125px;">
                                        <span class="ml-7">
                                            1. 팩스번호 : {{ data2 }} 로 전화번호 가입증명서를 보내주십시오.<br>
                                            2. 아래 [문자 발신번호 등록] 버튼을 눌러 등록 절차를 완료해주십시오.<br>
                                            2. 1, 2번의 절차 완료 후 사용하시고자 하는<br>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;문자 발신번호의 심사가 완료되어야<br>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;해당 발신번호로 분자 발신이 가능합니다.<br>
                                        </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="greyE01 d-flex pa-1 ma-0" style="width:100%;  height: 140px;">
                <span class="ml-7">
                    발신번호 사전등록제는 전기 통신사업법 제 84조에 의거 거짓으로 표시된 전화번호로 인한 이용자 피해 예방을 위해서 이용고객이 사전등록된 발신번호로만 문자발송이 가능하도록 하는 제도입니다. <br />
                    발신번호는 반드시 기업 또는 본인이 소유한 번호로 등록해야 하며, KISA가 발신번호 변조 의심으로 소명자료를 요청할 경우, 3일 이내에 제출하여야 합니다.
                </span>
            </div>
        </div>
        <div class="d-flex align-center justify-center pa-1 ma-0" style="width:100%;  height: 50px; margin: 0 auto;">
            <v-btn
                class="white--text" color="blueBtnColor" height="30" @click="insSnsNum()" samll rounded>
                <span class="ml-2" style="font-size: 1.1rem; padding-bottom: 2px;">문자발신번호 등록</span>
            </v-btn>
        </div>
        <v-dialog v-model="dialogDelete" max-width="500">    
            <btnModalDelete :title="'목욕급여 관찰기록'" @madalState="onDelete"></btnModalDelete>  
        </v-dialog> 
    </v-sheet>
</template>

<script>
import { tmpFileUpload, fileDownload, delAtchdFile } from '../../api/index.js';
import { selAtchdFileList } from '../../api/opr.js';
import { selFcltyInfInfo, updFcltyCllTel } from '../../api/opr.js';
import { getMultiStcd } from '../../api/index.js';
import btnModalDelete from '../bnfcr/BnfcrDeleteConfirm.vue';
export default {
    name: 'SnsNumSave',

    components: {
        btnModalDelete,

    },

    props: {

    },

    created: function () {
        getMultiStcd(['89', '64'])
            .then((response) => (this.codeMapping(response.data)))
            .catch((error) => console.log(error))
    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.selFcltyInfInfo()
        })
    },

    computed: {

    },

    watch: {
    },

    methods: {
        selFcltyInfInfo: function () {
            this.valClcd = '89'
            this.valClcdnm = '시설구분'
            selFcltyInfInfo(this.$store.getters.getFcltyNum)
                .then((response) => (this.selFcltyInfInfoAfter(response.data)))
                .catch((error) => console.log('connect error opr/selFcltyInfInfo : ' + error))
        },
        selFcltyInfInfoAfter: function (res) {
            this.items = res
            this.getAtchdFileList(res.fcltyInfPk)
        },
        insSnsNum: function () {
            let obj = {
                cllTel1: this.items.cllTel1,
                cllTel2: this.items.cllTel2,
                cllTel3: this.items.cllTel3,
                cllCnfmYn: '2',
                fcltyInfPk: this.items.fcltyInfPk,
                fcltyNum: this.$store.getters.getFcltyNum,
                userNm: this.$store.getters.getUserNm,
                files:[],
            }
            this.fileRes.forEach(x=>{
                if(x.atchdFilePk == undefined){
                    obj.files.push(x)
                }
            })

            updFcltyCllTel(obj)
                .then((response) => (this.updFcltyCllTelAfter(response.data)))
                .catch((error) => console.log('connect error /opr/updFcltyCllTel : ' + error))
        },
        updFcltyCllTelAfter(res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.selFcltyInfInfo()
                this.$parent.$parent.$parent.$parent.items.cllTel1 = this.items.cllTel1
                this.$parent.$parent.$parent.$parent.items.cllTel2 = this.items.cllTel2
                this.$parent.$parent.$parent.$parent.items.cllTel3 = this.items.cllTel3
            }
            else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        codeMapping: function (res) {
            this.codeItem = res.vCd89
            this.emailCode = res.vCd64
            this.emailCode.forEach((cd) => {
                this.email_list.push(cd.valcdnm)
            });
        },
        
        onClose: function () {
            this.$emit('isClose', '.')
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        getAtchdFileList:function(value){
            let fcltyNum  = this.$store.getters.getFcltyNum            
            let clcd = '25'
            if(value > 0){
                selAtchdFileList(fcltyNum, clcd, value, '7')
                .then((response) => ( this.getAtchdFileListAfter(response.data) ))
                .catch((error) => console.log('upload error /opr/selAtchdFileList : ' + error))
            }else{
                this.files = null
                this.images = null
                this.fileRes.splice(0)
                this.imageRes.splice(0)
            }
        },
        getAtchdFileListAfter:function(res){
            this.files = null
            this.images = null
            this.fileRes.splice(0)
            this.imageRes.splice(0)
            
            res.forEach(x=>{
                let fileNm = x.fileNm.split(".")
                x.ext = fileNm[1]  
                x.fileKndCd = '1'
                x.fileClcd = '7'
                this.fileRes.push(x)
            })
        },
        upFile: function () {            
            let formData = new FormData();
            
            if(this.files != null){
                this.files.forEach(element => {
                    formData.append("files", element)
                })

                if(this.files.length > 0){
                    formData.append("files", this.files);
                    tmpFileUpload(formData)
                        .then((response) => ( this.upFileAfter(response.data) ))
                        .catch((error) => console.log('upload error /opr/tmpFileUpload : ' + error))
                }
            }            
        },
        upFileAfter:function(res){
            res.files.forEach(x=>{
                let fileNm = x.fileNm.split(".")
                x.ext = fileNm[1]
                this.fileRes.push(x)
                x.fileClcd = '7'
                x.fileKndCd = '1'
            })
            this.files = null
        },
        fileDownload:function(idx){
            if(this.fileRes[idx] != undefined){
                fileDownload(this.fileRes[idx])
                    .then((response) => ( this.fileDownLoaderAfter(response) ))
                    .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
            }
        },
        fileDownLoaderAfter: function (res) {
            let contentDisposition = decodeURI(res.headers['content-disposition'])
       
            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'));
                if (fileNameMatch)
                    [ , fileName ] = fileNameMatch.split('=');
            }
            link.href = url;
 
            fileName = fileName.replaceAll('"', '')
            link.setAttribute('download', `${fileName}`);
            link.style.cssText = 'display:none';
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
        delfiles:function(idx){
            if(this.fileRes[idx].atchdFilePk != undefined){
                this.key = 1
                this.idx = idx
                this.title = '파일'
                this.dialogDelete = true
            }else{
                this.fileRes.splice(idx,1)
            }
        },   
        onDelete: function (res) {
            if (res == 9990) {
                let pk = 0
                if (this.key == 1) {
                    pk = this.fileRes[this.idx].atchdFilePk
                } else {
                    pk = this.imageRes[this.idx].atchdFilePk
                }

                if (pk > 0) {
                    let fcltyNum = this.$store.getters.getFcltyNum
                    delAtchdFile(fcltyNum, pk)
                        .then((response) => (this.delAtchdFileAfter(response.data)))
                        .catch((error) => console.log('upload error /opr/insMtngRcrdFile : ' + error))
                }
            } else if (res == 7) {
                this.dialogDelete = false
            }
        },
        delAtchdFileAfter: function (res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.getAtchdFileList(this.items.fcltyInfPk)
                this.key = 0
                this.idx = 0
                this.dialogDelete = false
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.dialogDelete = false
        },
    },
    data: () => ({
        data1: '심사중',
        data2: '02-6008-2514',
        radioGroup: '0',
        key:0,
        idx:0,
        title:'게시글',
        files:null,
        fileRes:[],
        images:null, 
        imageRes:[],  
        imgAccept:'image/*', 
        disabled:false,
        dialogDelete:false,
        email_list: ['직접입력',],
        snackOptions: [false, 3000, '', 'info'],
        items:
        {
            addr: '', addrDtl: '', bsnsCndtn: '', bsnsItm: '', bsnsRgst: '', ceo: '', dmntTrtYn: '', drcMmbr: '', drcMmbrPk: 0, drccClphn1: '', drccClphn2: '', drccClphn3: '', emailDmn: '', emailId: '',
            entCap: 0, fcltyClcd: '', fcltyClnm: '', fcltyFax1: '', fcltyFax2: '', fcltyFax3: '', fcltyTel1: '', fcltyTel2: '', fcltyTel3: '', mngr: '', mngrEmailDmn: '', mngrEmailId: '', mngrTel1: '',
            mngrTel2: '', mngrTel3: '', zip: ''
        },
    }),
};
</script>

<style>
.imgheight{
    height: 160px;
}
</style>