<template>
    <v-sheet class="ma-0 pa-0" rounded="lg">
        <v-row class="pa-0 justify-center align-center" no-gutters>
            <span class="text-h4 font-weight-bold">수급자 신규등록</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="offMdl()" x-large>mdi-close</v-icon>
        </v-row>
        <v-row class="pa-0 mt-3 mb-1 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">수급자 기본정보</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-form ref="bnfcrInf" lazy-validation>
            <v-row class="pa-0" no-gutters>
                <v-col class="tableBR px-1 pt-7" cols="2">
                    <v-row class="pa-0" no-gutters>
                        <imageCard
                            :cstmFit="'vrtclAspct'" :imgSrc="imgInf.src"
                            @onDown="getAthFileInf" @isEvent="delImgfiles()">
                        </imageCard>
                    </v-row>
                    <v-row class="pa-0 justify-center" no-gutters>
                        <span class="pl-2">
                            <v-file-input
                                v-model="inpFile"
                                class="fileImgAdd pt-0" color="blueBtnColor" accept="image/*" label="사진등록"
                                prepend-icon="mdi-image-plus-outline"
                                @change="upImgFile"
                                hide-input hide-details>
                            </v-file-input>
                        </span>
                    </v-row>
                </v-col>
                <v-col class="" cols="10">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tblHdr-greyE01 tableBR lnHgt1-7 py-1" cols="2">
                            <span class="fontOneDotOneRem font-weight-medium posn-rltv">
                                수급자명
                                <span class="d-inline-block" style="position: absolute; top: -4px; right: -20px;">
                                    <v-icon color="red">mdi-star-four-points-small</v-icon>
                                </span>
                            </span>
                        </v-col>
                        <v-col class="tableBR py-1 px-2" cols="3">
                            <span class="d-inline-block" style="width: 101px; height: 100%">
                                <v-text-field
                                    v-model="bscsInf.name" @input="aplctSavBtn"
                                    height="28" hide-details outlined dense>
                                </v-text-field>
                            </span>
                        </v-col>
                        <v-col class="tblHdr-greyE01 tableBR lnHgt1-7 py-1" cols="2">
                            <span class="fontOneDotOneRem font-weight-medium posn-rltv">
                                입소일/시간
                                <span class="d-inline-block" style="position: absolute; top: -4px; right: -20px;">
                                    <v-icon color="red">mdi-star-four-points-small</v-icon>
                                </span>
                            </span>
                        </v-col>
                        <v-col class="px-2 py-1" cols="5">
                            <span class="d-inline-block" style="width: 134px; height: 100%">
                                <CmmDateComp
                                    v-model="bscsInf.entDt" :required="false" @input="aplctSavBtn">
                                </CmmDateComp>
                            </span>
                            <span class="d-inline-block ml-2" style="width: 80px;">
                                <CmmTimeField v-model="bscsInf.entTm" :required="false" @input="aplctSavBtn"></CmmTimeField>
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tblHdr-greyE01 tableBR lnHgt1-7 px-2 py-1" cols="2">
                            <span class="fontOneDotOneRem font-weight-medium posn-rltv">
                                생년월일
                                <span class="d-inline-block" style="position: absolute; top: -4px; right: -20px;">
                                    <v-icon color="red">mdi-star-four-points-small</v-icon>
                                </span>
                            </span>
                        </v-col>
                        <v-col class="tableBR px-2 py-1" cols="3">
                            <span class="d-inline-block" style="width: 134px; height: 100%">
                                <CmmDateComp
                                    v-model="bscsInf.rsdntBirth" :required="true" @input="aplctSavBtn">
                                </CmmDateComp>
                            </span>
                            <v-btn
                                class="ml-1 pb-1" width="20" height="20"
                                @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 10)"
                                icon>
                                <v-icon size="20" style="padding-top: 2px;">mdi-help-circle-outline</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col class="tblHdr-greyE01 tableBR lnHgt1-7 py-1" cols="2">
                            <span class="fontOneDotOneRem font-weight-medium posn-rltv">
                                생신일
                                <span class="d-inline-block" style="position: absolute; top: -4px; right: -20px;">
                                    <v-icon color="red">mdi-star-four-points-small</v-icon>
                                </span>
                            </span>
                        </v-col>
                        <v-col class="" cols="5">
                            <v-row class="pa-0" no-gutters>
                                <v-col class="py-1 px-2" cols="6">
                                    <span class="d-inline-block" style="width: 134px; height: 100%">
                                        <CmmDateComp
                                            v-model="bscsInf.birth" :required="true" @input="aplctSavBtn">
                                        </CmmDateComp>
                                    </span>
                                </v-col>
                                <v-col style="padding-top: 2px;" cols="6">
                                    <v-radio-group
                                        v-model="bscsInf.lnrYn"
                                        class="radio-dft-Theme mt-0"
                                        @change="aplctSavBtn"
                                        hide-details row>
                                        <v-radio
                                            class="mr-2" style="padding-bottom: 2px !important;"
                                            :ripple="false" label="양력" value="1">
                                        </v-radio>
                                        <v-radio
                                            class="mr-2" style="padding-bottom: 2px !important;"
                                            :ripple="false" label="음력" value="2">
                                        </v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tblHdr-greyE01 tableBR lnHgt1-7 py-1" cols="2">
                            <span class="fontOneDotOneRem font-weight-medium posn-rltv">
                                성별
                                <span class="d-inline-block" style="position: absolute; top: -4px; right: -20px;">
                                    <v-icon color="red">mdi-star-four-points-small</v-icon>
                                </span>
                            </span>
                        </v-col>
                        <v-col class="tableBR px-2" style="padding-top: 2px;" cols="3">
                            <v-radio-group
                                v-model="bscsInf.gndr"
                                @change="aplctSavBtn"
                                class="radio-dft-Theme mt-0"
                                hide-details row>
                                <v-radio
                                    class="mr-2" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="남" value="M">
                                </v-radio>
                                <v-radio
                                    class="mr-2" style="padding-bottom: 2px !important;"
                                    :ripple="false" label="여" value="F">
                                </v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col class="tblHdr-greyE01 tableBR lnHgt1-7 py-1" cols="2">
                            <span class="fontOneDotOneRem">전화번호</span>
                        </v-col>
                        <v-col class="px-2 py-1" cols="5">
                            <span class="d-inline-block" style="width: 50px; height: 100%">
                                <v-text-field
                                    v-model="bscsInf.tel_1" @input="aplctSavBtn"
                                    height="28" hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="mx-1">
                                <v-icon style="padding-bottom: 2px;" size="16">mdi-minus</v-icon>
                            </span>
                            <span class="d-inline-block" style="width: 60px; height: 100%">
                                <v-text-field
                                    v-model="bscsInf.tel_2" @input="aplctSavBtn"
                                    height="28" hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="mx-1">
                                <v-icon style="padding-bottom: 2px;" size="16">mdi-minus</v-icon>
                            </span>
                            <span class="d-inline-block" style="width: 60px; height: 100%">
                                <v-text-field
                                    v-model="bscsInf.tel_3" @input="aplctSavBtn"
                                    height="28" hide-details outlined dense>
                                </v-text-field>
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tblHdr-greyE01 tableBR lnHgt1-7 py-1" cols="2">
                            <span class="fontOneDotOneRem">건강검진 제출일</span>
                        </v-col>
                        <v-col class="tableBR py-1 px-2" cols="3">
                            <span class="d-inline-block" style="width: 134px; height: 100%">
                                <CmmDateComp
                                    v-model="bscsInf.entHlthChdt" :required="false">
                                </CmmDateComp>
                            </span>
                        </v-col>
                        <v-col class="tblHdr-greyE01 tableBR lnHgt1-7 py-1" cols="2">
                            <span class="fontOneDotOneRem">휴대폰</span>
                        </v-col>
                        <v-col class="px-2 py-1" cols="5">
                            <span class="d-inline-block" style="width: 50px; height: 100%">
                                <v-text-field
                                    v-model="bscsInf.celphn_1" @input="aplctSavBtn"
                                    height="28" hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="mx-1">
                                <v-icon style="padding-bottom: 2px;" size="16">mdi-minus</v-icon>
                            </span>
                            <span class="d-inline-block" style="width: 60px; height: 100%">
                                <v-text-field
                                    v-model="bscsInf.celphn_2" @input="aplctSavBtn"
                                    height="28" hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="mx-1">
                                <v-icon style="padding-bottom: 2px;" size="16">mdi-minus</v-icon>
                            </span>
                            <span class="d-inline-block" style="width: 60px; height: 100%">
                                <v-text-field
                                    v-model="bscsInf.celphn_3" @input="aplctSavBtn"
                                    height="28" hide-details outlined dense>
                                </v-text-field>
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tblHdr-greyE01 tableBR lnHgt1-7 py-1" cols="2">
                            <span class="fontOneDotOneRem font-weight-medium">생활실</span>
                        </v-col>
                        <v-col class="d-flex align-center tableBR py-1 pl-2" cols="3">
                            <span class="d-inline-block" style="width: 100px; padding-top: 1px;">
                                <v-text-field
                                    v-model="bscsInf.lvnRmNm"
                                    class="" height="28" color="blue"
                                    hide-details outlined readonly dense>
                                </v-text-field>
                            </span>
                            <v-btn
                                class="ml-1" color="#FFB6B9" style="padding: 0px 1px 0px 2px !important;"
                                min-width="30" height="26" @click="onLclMdl('02', 800)"
                                dark small>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon size="20" v-bind="attrs" v-on="on">mdi-bed-king-outline</v-icon>
                                    </template>
                                    <span>생활실선택</span>
                                </v-tooltip>
                            </v-btn>
                        </v-col>
                        <v-col class="tblHdr-greyE01 tableBR lnHgt1-7 py-1" cols="2">
                            <span class="fontOneDotOneRem font-weight-medium">보조금</span>
                        </v-col>
                        <v-col class="py-1 pl-2" cols="5">
                            <v-btn
                                class="white--text mr-1" style="padding: 9px 7px 8px 5px !important;"
                                :color="bscsInf.snackSprtYn ? 'blueBtnColor' : 'greyC00'"
                                @click="bscsInf.snackSprtYn = !bscsInf.snackSprtYn"
                                min-width="20" height="20" small outlined>
                                <span class="fontNineThRem" style="padding-bottom: 2px;">간식비</span>
                            </v-btn>
                            <v-btn
                                class="white--text" style="padding: 9px 7px 8px 5px !important;"
                                :color="bscsInf.mealSprtYn ? 'blueBtnColor' : 'greyC00'"
                                @click="bscsInf.mealSprtYn = !bscsInf.mealSprtYn"
                                min-width="20" height="20" small outlined>
                                <span class="fontNineThRem" style="padding-bottom: 2px;">식사재료비</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tblHdr-greyE01 tableBR lnHgt1-7 py-1" cols="2">
                            <span class="fontOneDotOneRem font-weight-medium posn-rltv">
                                본인부담률
                                <span class="d-inline-block" style="position: absolute; top: -4px; right: -20px;">
                                    <v-icon color="red">mdi-star-four-points-small</v-icon>
                                </span>
                            </span>
                        </v-col>
                        <v-col class="tableBR py-1 pl-2 pr-16" cols="3">
                            <v-select
                                v-model="bscsInf.copayRate"
                                :items="prtCd63" @change="aplctSavBtn"
                                item-text="valcdnm" item-value="valcd"
                                dense hide-details outlined>
                            </v-select>
                        </v-col>
                        <v-col class="tblHdr-greyE01 tableBR lnHgt1-7 py-1" cols="2">
                            <span class="fontOneDotOneRem font-weight-medium posn-rltv">
                                인정번호
                                <span class="d-inline-block" style="position: absolute; top: -4px; right: -20px;">
                                    <v-icon color="red">mdi-star-four-points-small</v-icon>
                                </span>
                            </span>
                        </v-col>
                        <v-col class="py-1 px-2" cols="5">
                            <span class="white d-inline-block" style="width: 135px;">
                                <v-text-field
                                    v-model="bscsInf.acknwNum1" @input="aplctSavBtn"
                                    height="28" hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="mx-1">
                                <v-icon size="16">mdi-minus</v-icon>
                            </span>
                            <span class="white d-inline-block" style="width: 68px;">
                                <v-text-field
                                    v-model="bscsInf.acknwNum2"
                                    height="28" hide-details outlined dense>
                                </v-text-field>
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tblHdr-greyE01 tableBR lnHgt1-7 py-1 pr-2" cols="2">
                            <span class="fontOneDotOneRem font-weight-medium posn-rltv">
                                인정등급/기간
                                <span class="d-inline-block" style="position: absolute; top: -4px; right: -20px;">
                                    <v-icon color="red">mdi-star-four-points-small</v-icon>
                                </span>
                            </span>
                        </v-col>
                        <v-col class="py-1 pl-2" cols="10">
                            <span class="d-inline-block mr-2" style="width: 101px; height: 100%;">
                                <v-select
                                    v-model="bscsInf.acknwRtng"
                                    :items="prtCd62" @change="aplctSavBtn"
                                    item-text="valcdnm" item-value="valcd"
                                    dense hide-details outlined>
                                </v-select>
                            </span>
                            <span class="d-inline-block" style="width: 134px; height: 100%">
                                <CmmDateComp
                                    v-model="bscsInf.acknwPrdStrt" :required="true" @input="aplctSavBtn">
                                </CmmDateComp>
                            </span>
                            <span class="mx-3">
                                <v-icon color="greyE00" size="14">mdi-tilde</v-icon>
                            </span>
                            <span class="d-inline-block" style="width: 134px; height: 100%">
                                <CmmDateComp
                                    v-model="bscsInf.acknwPrdEnd" :required="true" @input="aplctSavBtn">
                                </CmmDateComp>
                            </span>
                            <span class="d-inline-block ml-1" style="width: 70px;">
                                <v-select
                                    v-model="sltPrd"
                                    :items="yrdPrd" @change="prdCalc"
                                    item-text="valcdnm" item-value="valcd"
                                    dense hide-details outlined>
                                </v-select>
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tblHdr-greyE01 tableBR lnHgt1-7 py-1" cols="2">
                            <span class="fontOneDotOneRem">주소</span>
                        </v-col>
                        <v-col class="d-flex align-center px-2 py-1" cols="10">
                            <span class="d-inline-block" style="width: 70px; height: 100%">
                                <v-text-field
                                    v-model="bscsInf.zip"
                                    height="28" readonly hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <v-btn
                                class="white--text ml-1" color="#3C9099" style="padding: 0px 1px 0px 2px !important"
                                min-width="30" height="26" @click="onLclMdl('01', 600)" small>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon size="20" v-bind="attrs" v-on="on">mdi-map-search-outline</v-icon>
                                    </template>
                                    <span>주소검색</span>
                                </v-tooltip>
                            </v-btn>
                            <span class="d-inline-block ml-2" style="width: 45%; height: 100%">
                                <v-text-field
                                    v-model="bscsInf.addr"
                                    height="28" readonly hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="d-inline-block ml-1" style="width: 35%; height: 100%">
                                <v-text-field
                                    v-model="bscsInf.addrDtl" height="28" hide-details outlined dense>
                                </v-text-field>
                            </span>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="tblHdr-greyE01 tableBR lnHgt1-7 py-1" cols="2">
                    <span class="fontOneDotOneRem">주요질환</span>
                </v-col>
                <v-col class="px-2 py-1" cols="10">
                    <v-textarea
                        class="rmrksArea" rows="1" 
                        v-model="bscsInf.majorDsss" @input="aplctSavBtn"
                        no-resize hide-details outlined dense>
                    </v-textarea>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="tblHdr-greyE01 tableBR" style="padding-top: 15px;" cols="2">
                    <span class="fontOneDotOneRem font-weight-medium">비고</span>
                </v-col>
                <v-col class="px-2 py-1" cols="10">
                    <v-textarea
                        class="rmrksArea" rows="2" 
                        v-model="bscsInf.rmrks" @input="aplctSavBtn"
                        no-resize hide-details outlined dense>
                    </v-textarea>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0 mt-4 mb-1 posn-rltv" no-gutters>
                <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                    <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                </span>
                <span class="fontOneDotTwoRem font-weight-medium ml-6">보호자 정보</span>
            </v-row>
            <v-divider class="mr-1" color="black"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col cols="6">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tblHdr-greyE01 lnHgt1-7 tableBR py-1" cols="3">
                            <span class="fontOneDotOneRem font-weight-medium posn-rltv">
                                보호자명
                                <span class="d-inline-block" style="position: absolute; top: -4px; right: -20px;">
                                    <v-icon color="red">mdi-star-four-points-small</v-icon>
                                </span>
                            </span>
                        </v-col>
                        <v-col class="tableBR py-1 px-2" cols="3">
                            <v-text-field
                                v-model="galFmlyInf.name" @input="aplctSavBtn"
                                class="" height="28" color="blue"
                                hide-details outlined dense>
                            </v-text-field>
                        </v-col>
                        <v-col class="tblHdr-greyE01 lnHgt1-7 tableBR py-1" cols="3">
                            <span class="fontOneDotOneRem font-weight-medium posn-rltv">
                                관계
                                <span class="d-inline-block" style="position: absolute; top: -4px; right: -20px;">
                                    <v-icon color="red">mdi-star-four-points-small</v-icon>
                                </span>
                            </span>
                        </v-col>
                        <v-col class="tableBR py-1 px-2" cols="3">
                            <v-text-field
                                v-model="galFmlyInf.rltns" @input="aplctSavBtn"
                                class="" height="28" color="blue"
                                hide-details outlined dense>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="6">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tblHdr-greyE01 lnHgt1-7 tableBR py-1" cols="3">
                            <span class="fontOneDotOneRem font-weight-medium">전화번호</span>
                        </v-col>
                        <v-col class="py-1 px-2" cols="9">
                            <span class="d-inline-block" style="width: 58px; height: 100%;">
                                <v-text-field
                                    v-model="galFmlyInf.tel_1"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="mx-1">
                                <v-icon size="16">mdi-minus</v-icon>
                            </span>
                            <span class="d-inline-block" style="width: 66px; height: 100%;">
                                <v-text-field
                                    v-model="galFmlyInf.tel_2"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="mx-1">
                                <v-icon size="16">mdi-minus</v-icon>
                            </span>
                            <span class="d-inline-block" style="width: 66px; height: 100%;">
                                <v-text-field
                                    v-model="galFmlyInf.tel_3"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col cols="6">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tblHdr-greyE01 lnHgt1-7 tableBR py-1" cols="3">
                            <span class="fontOneDotOneRem font-weight-medium">청구서수신</span>
                        </v-col>
                        <v-col class="tableBR py-1 px-2" cols="3">
                            <v-select
                                v-model="galFmlyInf.msClcd"
                                :items="billRcvCd" @change="aplctSavBtn"
                                item-text="valcdnm" item-value="valcd"
                                dense hide-details outlined>
                            </v-select>
                        </v-col>
                        <v-col class="tblHdr-greyE01 lnHgt1-7 tableBR py-1" cols="3">
                            <span class="fontOneDotOneRem font-weight-medium posn-rltv">
                                생년월일
                                <span class="d-inline-block" style="position: absolute; top: -4px; right: -20px;">
                                    <v-icon color="red">mdi-star-four-points-small</v-icon>
                                </span>
                            </span>
                        </v-col>
                        <v-col class="tableBR py-1 px-2" cols="3">
                            <CmmDateComp
                                v-model="galFmlyInf.rsdntBirth"
                                :required="true" :btnActv="false"
                                @input="aplctSavBtn">
                            </CmmDateComp>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="6">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tblHdr-greyE01 lnHgt1-7 tableBR py-1" cols="3">
                            <span class="fontOneDotOneRem font-weight-medium posn-rltv">
                                휴대폰
                                <span class="d-inline-block" style="position: absolute; top: -4px; right: -20px;">
                                    <v-icon
                                        v-if="galFmlyInf.msClcd == '1' || galFmlyInf.msClcd == '2'"
                                        color="red">
                                        mdi-star-four-points-small
                                    </v-icon>
                                </span>
                            </span>
                        </v-col>
                        <v-col class="py-1 px-2" cols="9">
                            <span class="d-inline-block" style="width: 58px; height: 100%;">
                                <v-text-field
                                    v-model="galFmlyInf.celphn_1" @input="aplctSavBtn"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="mx-1">
                                <v-icon size="16">mdi-minus</v-icon>
                            </span>
                            <span class="d-inline-block" style="width: 66px; height: 100%;">
                                <v-text-field
                                    v-model="galFmlyInf.celphn_2" @input="aplctSavBtn"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="mx-1">
                                <v-icon size="16">mdi-minus</v-icon>
                            </span>
                            <span class="d-inline-block mr-3" style="width: 66px; height: 100%;">
                                <v-text-field
                                    v-model="galFmlyInf.celphn_3" @input="aplctSavBtn"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <v-btn
                                class="white--text mr-1"
                                style="padding: 9px 7px 8px 5px !important; margin-bottom: 3px;"
                                :color="galFmlyInf.isPay ? 'blueBtnColor' : 'greyC00'"
                                @click="galFmlyInf.isPay = !galFmlyInf.isPay"
                                min-width="20" height="20" small outlined>
                                <span class="fontNineThRem" style="padding-bottom: 2px;">급여</span>
                            </v-btn>
                            <v-btn
                                class="white--text"
                                style="padding: 9px 7px 8px 5px !important; margin-bottom: 3px;"
                                :color="galFmlyInf.isBill ? 'blueBtnColor' : 'greyC00'"
                                @click="galFmlyInf.isBill = !galFmlyInf.isBill"
                                min-width="20" height="20" small outlined>
                                <span class="fontNineThRem" style="padding-bottom: 2px;">청구</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col cols="6">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tblHdr-greyE01 lnHgt1-7 tableBR py-1" cols="3">
                            <span class="fontOneDotOneRem font-weight-medium posn-rltv">
                                이메일
                                <span class="d-inline-block" style="position: absolute; top: -4px; right: -20px;">
                                    <v-icon
                                        v-if="galFmlyInf.msClcd == '3'"
                                        color="red">
                                        mdi-star-four-points-small
                                    </v-icon>
                                </span>
                            </span>
                        </v-col>
                        <v-col class="py-1 pl-2" cols="9">
                            <span class="d-inline-block" style="width: 152px; height: 100%;">
                                <v-text-field
                                    v-model="galFmlyInf.emailId" @input="aplctSavBtn"
                                    class="" height="28" color="blue"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <v-icon class="mx-2" style="padding-top: 1px;" size="20" color="greyE00">mdi-at</v-icon>
                            <span class="d-inline-block" style="width: 152px; height: 100%;">
                                <v-text-field
                                    v-model="galFmlyInf.emailDmn"
                                    class="" height="28" color="blue"
                                    @input="aplctSavBtn" :disabled="galFmlyInf.emailDmnClcd != '0'"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="6">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="py-1" cols="3">
                            <v-select
                                v-model="galFmlyInf.emailDmnClcd"
                                :items="vCd64" 
                                item-text="valcdnm" item-value="valcd"
                                dense hide-details outlined>
                            </v-select>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col cols="6">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="tblHdr-greyE01 lnHgt1-7 tableBR py-1" cols="3">
                            <span class="fontOneDotOneRem font-weight-medium posn-rltv">
                                주소
                                <span class="d-inline-block" style="position: absolute; top: -4px; right: -20px;">
                                    <v-icon color="red">mdi-star-four-points-small</v-icon>
                                </span>
                            </span>
                        </v-col>
                        <v-col class="d-flex align-center py-1 pl-2" cols="9">
                            <span class="d-inline-block" style="width: 70px; height: 100%;">
                                <v-text-field
                                    v-model="galFmlyInf.zip"
                                    class="" height="28" color="blue"
                                    hide-details outlined readonly dense>
                                </v-text-field>
                            </span>
                            <v-btn
                                class="white--text ml-1 mr-2" color="#3C9099" style="padding: 0px 1px 0px 2px !important"
                                min-width="30" height="26" @click="onLclMdl('01', 601)" small>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon size="20" v-bind="attrs" v-on="on">mdi-map-search-outline</v-icon>
                                    </template>
                                    <span>주소검색</span>
                                </v-tooltip>
                            </v-btn>
                            <span class="d-inline-block mr-1" style="width: 228px; height: 100%;">
                                <v-text-field
                                    v-model="galFmlyInf.addr"
                                    class="" height="28" color="blue"
                                    hide-details outlined readonly dense>
                                </v-text-field>
                            </span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="6">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="py-1" cols="9">
                            <v-text-field
                                v-model="galFmlyInf.addrDtl"
                                class="" height="28" color="blue"
                                hide-details outlined dense>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <v-divider class="white my-2"></v-divider>
            <v-row class="pa-0 justify-center" no-gutters>
                <v-btn
                    class="" color="blueBtnColor" min-width="110" height="36"
                    :disabled="savBtn" @click="dcsnSavBtn">
                    <span class="white--text fontOneDotTwoRem">저장</span>
                </v-btn>
            </v-row>
            <v-divider class="white mt-1"></v-divider>
        </v-form>
        
        <!-- 팝업 영역 -->
        <v-dialog
            v-model="lclMdlInf.isOn"
            content-class="round" :max-width="lclMdlInf.maxWidth"
            persistent>
            <AdrsSrch v-if="lclMdlInf.typ == '01'" @onDataThr="rflAddrInf" @isClose="rstLclMdlInf"></AdrsSrch>
            <LvnRmMdl
                v-if="lclMdlInf.typ == '02'"
                @sltLvnRmInf="rflLvnRmInf" @modalClose="rstLclMdlInf">
            </LvnRmMdl>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { http, remoteURL } from '@/api/baseAxios';
import { uploadToFile, delAtchdFile } from '../../../api/index.js';

import imageCard from '../../commons/imageCard.vue';             // 이미지 로드 (다운로드)
import CmmDateComp from '../../commons/CmmDateComp.vue';         // 날짜 입력
import CmmTimeField from '../../commons/CmmTimeField.vue';       // 시간 입력
import AdrsSrch from '../../commons/AdrsSrch.vue';               // 주소 검색
import LvnRmMdl from '../../opr/LvnRmMdl.vue';                   // 생활실 선택

export default {
    name: 'BscInfAddMdl',
    
    components: {
        imageCard,
        CmmDateComp,
        CmmTimeField,
        AdrsSrch,
        LvnRmMdl,
    },

    props : {
        prtCd62: { type: Array, default: () => { return [] } },     // 인정등급
        prtCd63: { type: Array, default: () => { return [] } },     // 본인부담률
        prtCd64: { type: Array, default: () => { return [] } },     // 이메일
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.vCd64 = [...this.prtCd64]
            this.vCd64.unshift({ valcd: '0', valcdnm: '직접입력' })
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch: {
        getSubEvt: function (v) {
            if(v && this.runAble == '1'){
                this.$parent.$parent.$parent.$parent.mdlOff()

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
                
                this.runAble = '0'
            }
        },

        'galFmlyInf.emailDmnClcd': function (){
            if(this.galFmlyInf.emailDmnClcd == '0')
                this.galFmlyInf.emailDmn = ''
            else{
                let emlChc = this.prtCd64.findIndex( v => v.valcd == this.galFmlyInf.emailDmnClcd )
                if(emlChc > -1)
                    this.galFmlyInf.emailDmn = this.prtCd64[emlChc].valcdnm
                else
                    this.galFmlyInf.emailDmn = ''
            }
        },

        'prtCd64': function(v){
            this.vCd64.splice(0)
            this.vCd64 = [...v]
            this.vCd64.unshift({ valcd: '0', valcdnm: '직접입력' })
        },
    },
        
    methods: {
        // 수급자 신규등록
        addBnfcrInf: function (inf, gal){
            let obj = {}
            obj.bnfcrInfo = inf
            obj.galFmlyInfo = gal
            obj.files = this.imgInf
            //obj.fcltyNum = this.$store.getters.getFcltyNum
            
            http.post('bnfcrAuth/insBnfcrInfo', obj)
                .then((response) => ( this.insBnfcr(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/insBnfcrInfo : ' + error))
        },

        insBnfcr: function (res){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == '200'){
                this.savBtn = false
                this.rstInp()
            }
            else
                obj.type = 'warning'

            this.$store.commit('setWrnnInf', obj)
        },

        // 이미지 다운로드
        getAthFileInf: function () {
            http({
                method: 'post', url: 'cmm/fileDownload',
                responseType: 'arraybuffer', data: this.imgInf })
                .then((response) => ( this.createFile(response) ))
                .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
        },

        createFile: function (res) {
            let contentDisposition = decodeURI(res.headers['content-disposition'])

            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'))
                if (fileNameMatch)
                    [ , fileName ] = fileNameMatch.split('=')
            }

            link.href = url

            fileName = fileName.replaceAll('"', '')
            link.setAttribute('download', `${fileName}`)
            link.style.cssText = 'display:none'

            document.body.appendChild(link)

            link.click()
            link.remove()
        },

        // 이미지 업로드
        upImgFile: function () {
            let formData = new FormData()

            if(this.inpFile != null){
                if(this.inpFile?.size > 0){
                    formData.append("files", this.inpFile)
                    uploadToFile(formData)
                        .then((response) => ( this.cvPreview(response.data) ))
                        .catch((error) => console.log('upload error /opr/atchdFileUpload : ' + error))
                }
            }
        },

        cvPreview: function (res) {
            if(res.statusCode == '200'){
                res.src = remoteURL + 'opr/imgPrview?fileNm='+res.fileNm+'&filePth='+res.filePth+'&fileKndCd=2'
                this.imgInf = res
            }
        },

        // 첨부파일 삭제
        removeAthFile: function (pk) {
            delAtchdFile(this.$store.getters.getFcltyNum, pk)
                .then((response) => ( this.insBnfcr(response.data) ))
                .catch((error) => console.log('upload error /opr/delAtchdFile : ' + error))
        },

        // 수급자 이미지 삭제
        delImgfiles: function () {
            this.imgInf.filePth = ''
            this.imgInf.fileNm = ''
            this.imgInf.src = remoteURL + 'opr/imgView'

            if(typeof this.imgInf.atchdFilePk != 'undefined' || this.imgInf.atchdFilePk != '')
                this.removeAthFile(this.imgInf.atchdFilePk)
        },

        // 저장버튼 활성 여부 체크
        aplctSavBtn: function () {
            this.savBtn = true
            
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.bscsInf.rsdntBirth) == false)
                return 0
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.bscsInf.birth) == false)
                return 0
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.bscsInf.entDt) == false)
                return 0
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.bscsInf.acknwPrdStrt) == false)
                return 0
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.bscsInf.acknwPrdEnd) == false)
                return 0
            if(/^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(this.bscsInf.entTm) == false)
                return 0
            if(this.bscsInf.name == '')
                return 0
            if(this.bscsInf.lnrYn == '')
                return 0
            if(this.bscsInf.gndr == '')
                return 0
            if(this.bscsInf.copayRate == '')
                return 0
            if(this.bscsInf.acknwRtng == '')
                return 0
            if(this.bscsInf.acknwNum1 == '')
                return 0

            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.galFmlyInf.rsdntBirth) == false)
                return 0
            if(this.galFmlyInf.name == '')
                return 0
            if(this.galFmlyInf.rltns == '')
                return 0
            if(this.galFmlyInf.zip == '' || this.galFmlyInf.addr == '')
                return 0
            if(this.galFmlyInf.msClcd != '0'){
                switch (this.galFmlyInf.msClcd) {
                    case '1':
                    case '2':
                        if(this.galFmlyInf.celphn_1 == '' || this.galFmlyInf.celphn_2 == '' || this.galFmlyInf.celphn_3 == '')
                            return 0
                        break;
                    case '3':
                        if(this.galFmlyInf.emailDmn == '' || this.galFmlyInf.emailDmnClcd == '')
                            return 0
                        break;
                    default:
                        break;
                }
            }

            this.savBtn = false
        },

        // 저장버튼 작동
        dcsnSavBtn: function () {
            let obj = {}
            obj.actv = true                                 // 경고 노출여부
            obj.tmr = 3000                                  // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                               // 경고 유형 (success, info, warning, error)
            obj.title = '수정된 항목이 존재하지 않습니다.'     // 경고 타이틀
            obj.cntnt = []                                  // 경고 내용 (arr만 출력됨!)

            let bnfcrInf = Object.assign({}, this.bscsInf)
            if(bnfcrInf.snackSprtYn)
                bnfcrInf.snackSprtYn = '1'
            else
                bnfcrInf.snackSprtYn = '2'
            if(bnfcrInf.mealSprtYn)
                bnfcrInf.mealSprtYn = '1'
            else
                bnfcrInf.mealSprtYn = '2'

            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.bscsInf.entDt))
                bnfcrInf.entDt = this.$moment(bnfcrInf.entDt, 'YYYY-MM-DD').format('YYYYMMDD')
            else
                bnfcrInf.entDt = ''
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.bscsInf.rsdntBirth))
                bnfcrInf.rsdntBirth = this.$moment(bnfcrInf.rsdntBirth, 'YYYY-MM-DD').format('YYYYMMDD')
            else
                bnfcrInf.rsdntBirth = ''
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.bscsInf.birth))
                bnfcrInf.birth = this.$moment(bnfcrInf.birth, 'YYYY-MM-DD').format('YYYYMMDD')
            else
                bnfcrInf.birth = ''
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.bscsInf.entHlthChdt))
                bnfcrInf.entHlthChdt = this.$moment(bnfcrInf.entHlthChdt, 'YYYY-MM-DD').format('YYYYMMDD')
            else
                bnfcrInf.entHlthChdt = ''
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.bscsInf.acknwPrdStrt))
                bnfcrInf.acknwPrdStrt = this.$moment(bnfcrInf.acknwPrdStrt, 'YYYY-MM-DD').format('YYYYMMDD')
            else
                bnfcrInf.acknwPrdStrt = ''
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.bscsInf.acknwPrdEnd))
                bnfcrInf.acknwPrdEnd = this.$moment(bnfcrInf.acknwPrdEnd, 'YYYY-MM-DD').format('YYYYMMDD')
            else
                bnfcrInf.acknwPrdEnd = ''

            if(/^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(this.bscsInf.entTm)){
                let splitTime = this.bscsInf.entTm?.split(':', 2)
                bnfcrInf.entTmHh = splitTime[0]
                bnfcrInf.entTmMm = splitTime[1]
            }
            else{
                bnfcrInf.entTmHh = ''
                bnfcrInf.entTmMm = ''
            }

            let cpyChc = this.prtCd63.findIndex( v => v.valcd == this.bscsInf.copayRate )
            if(cpyChc > -1){
                bnfcrInf.copayRateNm = this.prtCd63[cpyChc].valcdnm
                bnfcrInf.copayRateVal = this.prtCd63[cpyChc].valdesc
            }
            else{
                obj.cntnt.push('본인부담률 코드 맵핑 실패 등록을 중단합니다.')
                this.$store.commit('setWrnnInf', obj)
                return 0
            }

            let acknwChc = this.prtCd62.findIndex( v => v.valcd == this.bscsInf.acknwRtng )
            if(acknwChc > -1)
                bnfcrInf.acknwRtngNm = this.prtCd62[acknwChc].valcdnm
            else{
                obj.cntnt.push('인정등급 코드 맵핑 실패 등록을 중단합니다.')
                this.$store.commit('setWrnnInf', obj)
                return 0
            }

            let galFmlyInf = Object.assign({}, this.galFmlyInf)
            if(this.galFmlyInf.isPay)
                galFmlyInf.cellRcvYn.push('1')
            if(this.galFmlyInf.isBill)
                galFmlyInf.cellRcvYn.push('2')

            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.galFmlyInf.rsdntBirth))
                galFmlyInf.rsdntBirth = this.$moment(galFmlyInf.rsdntBirth, 'YYYY-MM-DD').format('YYYYMMDD')
            else
                galFmlyInf.rsdntBirth = ''

            this.addBnfcrInf(bnfcrInf, galFmlyInf)

        },

        // 입력항목 변경여부 확인
        chngInpChc: function () {
            let cnt = 0

            if(this.bscsInf.name == '')
                cnt += 1
            if(this.bscsInf.gndr == '')
                cnt += 1
            if(this.bscsInf.rsdntBirth == '')
                cnt += 1
            if(this.bscsInf.birth == '')
                cnt += 1
            if(this.bscsInf.lnrYn == '')
                cnt += 1
            if(this.bscsInf.entHlthChdt == '')
                cnt += 1
            if(this.bscsInf.entDt == '')
                cnt += 1
            if(this.bscsInf.entTm == '')
                cnt += 1
            if(this.bscsInf.celphn_1 == '')
                cnt += 1
            if(this.bscsInf.celphn_2 == '')
                cnt += 1
            if(this.bscsInf.celphn_3 == '')
                cnt += 1
            if(this.bscsInf.tel_1 == '')
                cnt += 1
            if(this.bscsInf.tel_2 == '')
                cnt += 1
            if(this.bscsInf.tel_3 == '')
                cnt += 1
            if(this.bscsInf.zip == '')
                cnt += 1
            if(this.bscsInf.addr == '')
                cnt += 1
            if(this.bscsInf.addrDtl == '')
                cnt += 1
            if(this.bscsInf.majorDsss == '')
                cnt += 1
            if(this.bscsInf.rmrks == '')
                cnt += 1
            if(this.bscsInf.acknwPrdStrt == '')
                cnt += 1
            if(this.bscsInf.acknwPrdEnd == '')
                cnt += 1
            if(this.bscsInf.acknwNum1 == '')
                cnt += 1
            if(this.bscsInf.acknwNum2 == '')
                cnt += 1
            if(this.bscsInf.lvnRmNm == '')
                cnt += 1

            if(this.galFmlyInf.name == '')
                cnt += 1
            if(this.galFmlyInf.rltns == '')
                cnt += 1
            if(this.galFmlyInf.msClcd == '0')
                cnt += 1
            if(this.galFmlyInf.rsdntBirth == '')
                cnt += 1
            if(this.galFmlyInf.emailId == '')
                cnt += 1
            if(this.galFmlyInf.emailDmn == '')
                cnt += 1
            if(this.galFmlyInf.zip == '')
                cnt += 1
            if(this.galFmlyInf.addr == '')
                cnt += 1
            if(this.galFmlyInf.addrDtl == '')
                cnt += 1
            if(this.galFmlyInf.tel_1 == '')
                cnt += 1
            if(this.galFmlyInf.tel_2 == '')
                cnt += 1
            if(this.galFmlyInf.tel_3 == '')
                cnt += 1
            if(this.galFmlyInf.celphn_1 == '')
                cnt += 1
            if(this.galFmlyInf.celphn_2 == '')
                cnt += 1
            if(this.galFmlyInf.celphn_3 == '')
                cnt += 1

            if(cnt == 39)
                return 0
            else
                return 1
        },

        // 팝업 닫기/확인
        offMdl: function () {
            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            obj.title = '저장되지 않은 작성항목이 존재합니다.'
            obj.cntnt = ['저장하지 않은 항목은 손실됩니다. 창을 닫을까요?']
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 창을 닫겠습니다.'

            if(this.chngInpChc() == 1){
                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 600, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });
                this.runAble = '1'
            }
            else if(this.chngInpChc() == 0)
                this.$parent.$parent.$parent.$parent.mdlOff()
        },

        // 인정기간 계산
        prdCalc: function () {
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.bscsInf.acknwPrdStrt) == false)
                return 0

            if(this.sltPrd == '0')
                this.bscsInf.acknwPrdEnd = ''
            else{
                let strt = this.$moment(this.bscsInf.acknwPrdStrt, 'YYYY-MM-DD')
                this.bscsInf.acknwPrdEnd = strt.add(Number(this.sltPrd), 'years').subtract(1, 'days').format('YYYY-MM-DD')
            }
        },

        // 주소 검색 내용 적용
        rflAddrInf: function (obj) {
            switch (this.lclMdlInf.maxWidth) {
                case 600:
                    this.bscsInf.zip = obj.zonecode
                    this.bscsInf.addr = obj.address
                    break;
                case 601:
                    this.galFmlyInf.zip = obj.zonecode
                    this.galFmlyInf.addr = obj.address
                    break;
                default:
                    break;
            }

            this.aplctSavBtn()
            this.rstLclMdlInf()
        },

        // 입력필드 초기화
        rstInp: function () {
            this.sltPrd = '0'
            this.inpFile = null
            this.imgInf = {}
            
            let bscsBnfcr = {
                bnMmbrPk: -1, name: '', gndr: '', rsdntBirth: '',
                birth: '', lnrYn: '', entHlthChdt: '', entDt: '', entTm: '', entTmHh: '', entTmMm: '',
                celphn_1: '', celphn_2: '', celphn_3: '', tel_1: '', tel_2: '', tel_3: '',
                zip: '', addr: '', addrDtl: '', majorDsss: '', rmrks: '', snackSprtYn: false, mealSprtYn: false,
                copayRate: '99', copayRateNm: '', copayRateVal: '', acknwRtng: '99', acknwRtngNm: '',
                acknwPrdStrt: '', acknwPrdEnd: '', acknwNum1: '', acknwNum2: '', lvnRmNm: '', lvnRmInfPk: -1,
            }

            let bscsGalFmly = {
                name: '', rltns: '', msClcd: '0', rsdntBirth: '', isPay: false, isBill: false, cellRcvYn:[],
                emailId: '', emailDmn: '', emailDmnClcd: '0',  zip: '', addr: '', addrDtl: '',
                tel_1: '', tel_2: '', tel_3: '', celphn_1: '',  celphn_2: '',  celphn_3: '',
            }

            let bscsFmly = {
                name: '', rltns: '', msClcd: '0', celphn_1: '',  celphn_2: '',  celphn_3: '',
                cellRcvYn:[], isPay: false, isBill: false,
            }

            this.bscsInf = Object.assign(bscsBnfcr)
            this.galFmlyInf = Object.assign(bscsGalFmly)
            this.fmlyInf1 = Object.assign(bscsFmly)
            this.fmlyInf2 = Object.assign(bscsFmly)

            this.$refs.bnfcrInf.resetValidation()
        },

        // 생활실 선택 적용
        rflLvnRmInf: function (obj) {
            this.bscsInf.lvnRmNm = obj.lvnRmNm
            this.bscsInf.lvnRmInfPk = obj.lvnRmInfPk
        },

        // 통합 팝업 오픈
        onLclMdl: function (txt, wdt) {
            this.lclMdlInf.typ = txt
            this.lclMdlInf.maxWidth = wdt
            this.lclMdlInf.isOn = true
        },

        // 통합 팝업 초기화
        rstLclMdlInf: function () {
            this.lclMdlInf.typ = ''
            this.lclMdlInf.isOn = false
        },
    },

    data: () => ({
        savBtn: true,
        sltPrd: '0',
        inpFile: null,
        imgInf: {},                                                     // 수급자 사진정보
        lclMdlInf: { isOn: false, maxWidth: 600, typ: '' },             // 통합 팝업 제어 (01: 주소검색)
        bscsInf: {                                                      // 수정 정보
            bnMmbrPk: -1, name: '', gndr: '', rsdntBirth: '',
            birth: '', lnrYn: '', entHlthChdt: '', entDt: '', entTm: '', entTmHh: '', entTmMm: '',
            celphn_1: '', celphn_2: '', celphn_3: '', tel_1: '', tel_2: '', tel_3: '',
            zip: '', addr: '', addrDtl: '', majorDsss: '', rmrks: '', snackSprtYn: false, mealSprtYn: false,
            copayRate: '99', copayRateNm: '', copayRateVal: '', acknwRtng: '99', acknwRtngNm: '',
            acknwPrdStrt: '', acknwPrdEnd: '', acknwNum1: '', acknwNum2: '', lvnRmNm: '', lvnRmInfPk: -1,
        },
        galFmlyInf: {
            name: '', rltns: '', msClcd: '0', rsdntBirth: '', isPay: false, isBill: false, cellRcvYn:[],
            emailId: '', emailDmn: '', emailDmnClcd: '0',  zip: '', addr: '', addrDtl: '',
            tel_1: '', tel_2: '', tel_3: '', celphn_1: '',  celphn_2: '',  celphn_3: '',
        },
        fmlyInf1 : {
            name: '', rltns: '', msClcd: '0', celphn_1: '',  celphn_2: '',  celphn_3: '',
            cellRcvYn:[], isPay: false, isBill: false,
        },
        fmlyInf2 : {
            name: '', rltns: '', msClcd: '0', celphn_1: '',  celphn_2: '',  celphn_3: '',
            cellRcvYn:[], isPay: false, isBill: false,
        },
        yrdPrd: [       // 년도
            { valcd: '0', valcdnm: '선택' }, { valcd: '1', valcdnm: '1년' }, { valcd: '2', valcdnm: '2년' },
            { valcd: '3', valcdnm: '3년' }, { valcd: '4', valcdnm: '4년' }, { valcd: '5', valcdnm: '5년' }
        ],
        billRcvCd: [
            { valcd: '0', valcdnm: '선택' }, { valcd: '1', valcdnm: '알림톡' },
            { valcd: '2', valcdnm: '문자' }, { valcd: '3', valcdnm: '이메일' },
        ],
        vCd64: [],
    }),
};
</script>