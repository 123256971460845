<template>
    <v-sheet rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <v-row no-gutters>
            <v-col class="pa-6 ma-0 greyF01" cols="4">
                <div class="d-flex justify-left">
                    <span class="text-h4 font-weight-bold">상담일지</span>
                    <v-spacer></v-spacer>
                </div>
                <div class="mt-6">
                    <expandQtrPicker :inQtr="prtQtr" @nowQtr="getQtr"></expandQtrPicker>
                </div>
                <div class="mt-3">
                    <v-row class="white rounded rounded-b-0" no-gutters>
                        <v-col class="txtAlgnMid py-4" cols="3">
                            <span class="black--text font-weight-medium">상담일</span>
                        </v-col>
                        <v-col class="txtAlgnMid py-4" cols="3">
                            <span class="black--text font-weight-medium">상담자</span>
                        </v-col>
                        <v-col class="txtAlgnMid py-4" cols="3">
                            <span class="black--text font-weight-medium">보호자</span>
                        </v-col>
                        <v-col class="txtAlgnMid py-2" style="line-height: 1.2;" cols="3">
                            <div class="black--text font-weight-medium">급여제공</div>
                            <div class="black--text font-weight-medium">반영일</div>
                        </v-col>
                    </v-row>
                    <v-divider color="black"></v-divider>
                    <div class="white rounded rounded-t-0 pb-2 mb-3" style="height: 394px;">
                        <v-row
                            :class="[
                                'fixedCol qtrListRow crsShp-Pointer tableBB',
                                sltCnslnHisPk == list.cnslnHisPk ? 'sltRow' : 'disSltRow'
                            ]"
                            ref="qtrRow"
                            v-for="(list, i) in cnslnHisQrtList" :key="i"
                            @click="tableClickEvent(list.cnslnHisPk, list.bnftOfrPlnHisPk)"
                            no-gutters>
                            <v-col class="fixedCol0 txtAlgnMid py-2" cols="3">
                                <span class="black--text">{{list.cnslnDt?.beforeDateFormatDot()}}</span>
                            </v-col>
                            <v-col class="fixedCol0 txtAlgnMid py-2" cols="3">
                                <span class="black--text">{{list.cnslr}}</span>
                            </v-col>
                            <v-col class="fixedCol0 txtAlgnMid py-2" style="vertical-align: middle;" cols="3">
                                <span class="black--text">{{list.cnslnTrgt}}</span>
                            </v-col>
                            <v-col class="fixedCol0 txtAlgnMid py-2" cols="3">
                                <span class="black--text">{{list.bnftOfrDt?.beforeDateFormatDot()}}</span>
                            </v-col>
                        </v-row>
                        <v-row v-if="cnslnHisQrtList?.length == 0" no-gutters>
                            <v-col class="txtAlgnMid py-2" cols="12">
                                <span class="grey006--text" style="font-size: 0.925rem;">&#8251; 상담내역이 존재하지 않습니다.</span>
                            </v-col>
                        </v-row>
                    </div>
                    <v-row class="pa-0" no-gutters>
                        <v-spacer></v-spacer>
                        <span class="d-inline-block rounded white" style="width: 220px;">
                            <v-select
                                v-model="sltBnfcrPk" :key="forceRender"
                                :items="bnfcrPkList" 
                                item-text="viewTxt" item-value="bnfcrPk"
                                dense hide-details outlined>
                            </v-select>
                        </span>
                    </v-row>
                </div>
            </v-col>
            <v-col class="pa-6 ma-0" cols="8">
                <div class="d-flex justify-left">
                    <v-spacer></v-spacer>
                    <v-icon color="black" x-large @click="isClose()">mdi-close</v-icon>
                </div>
                <div class="d-flex justify-right mt-4">
                    <v-icon style="font-size: 30px; width: 8px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h5 font-weight-bold ml-3">상담일지&nbsp;상세정보</span>
                    <v-spacer></v-spacer>
                    <span v-for="(list, i) in filledBtn" :key="i">
                        <v-btn
                            :class="list.class" height="30"
                            :color="list.color" :disabled="list.disabled"
                            @click="onClickBtn(list.text)"
                            rounded samll>
                            <v-icon small>{{list.icon}}</v-icon>
                            <span class="fontOneRem">{{list.text}}</span>
                        </v-btn>
                    </span>
                </div>
                <v-divider class="mt-6"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                        <span class="black--text font-weight-medium py-2">수급자명</span>
                    </v-col> 
                    <v-col class="d-flex justify-left align-center" cols="5">
                        <span class="txtAlgnMid py-2 pl-2">
                            {{bnfcrBasicInfo.name}}
                        </span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                        <span class="black--text font-weight-medium py-2">성별&#40;나이&#41;</span>
                    </v-col>
                    <v-col class="d-flex justify-left align-center" cols="3">
                        <span class="py-2 pl-2">
                            <span v-if="bnfcrBasicInfo.gndr == 'F'">여</span>
                            <span v-else-if="bnfcrBasicInfo.gndr == 'M'">남</span>
                            &#40;{{bnfcrBasicInfo.age}}세&#41;
                        </span>
                    </v-col>
                </v-row>
                <v-form ref="form" lazy-validation>
                    <v-divider></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                            <span class="black--text font-weight-medium py-2">상담일시</span>
                        </v-col>
                        <v-col class="d-flex justify-left align-center" cols="5">
                            <span class="d-inline-block py-2 pl-2" style="width: 143px;">
                                <CmmDateComp v-model="cnslnInf.cnslnDt" :required="true"></CmmDateComp>
                            </span>
                            <span class="d-inline-block ml-2" style="width: 60px;">
                                <CmmTimeField v-model="cnslnStrt" :required="true" :maxTm="cnslnEnd"></CmmTimeField>
                            </span>
                            <span class="d-flex align-center ml-1">
                                <v-icon size="16" color="greyE00">mdi-tilde</v-icon>
                            </span>
                            <span class="d-inline-block ml-1" style="width: 60px;">
                                <CmmTimeField v-model="cnslnEnd" :required="true" :minTm="cnslnStrt"></CmmTimeField>
                            </span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                            <span class="black--text font-weight-medium py-2">상담불가</span>
                        </v-col>
                        <v-col class="pa-0 ma-0 d-flex justify-left align-center" cols="3">
                            <span class="d-inline-block py-1 pl-1" style="width: 90px;">
                                <v-checkbox
                                    v-model="cnslnInf.cnslnImpsbYn" :ripple="false"
                                    class="dftChcBox-Theme"
                                    label="상담불가" value="1" 
                                    ide-details>
                                </v-checkbox>
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                            <span class="black--text font-weight-medium py-2">상담대상</span>
                        </v-col> 
                        <v-col class="d-flex justify-left align-center" cols="5">
                            <span class="d-inline-block ml-2" style="width: 100px;">
                                <v-text-field
                                    v-model="cnslnInf.cnslnTrgt"
                                    :rules="[rules.required]" height="28"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <v-btn 
                                class="ml-1 white--text darken-1" style="padding: 0px 1px 0px 2px !important"
                                color="bckgrColor" min-width="30" height="25" @click="dialog.splice(1, 1, true)"
                                dark small>
                                <v-tooltip top >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                    </template>
                                    <span>보호자선택</span>
                                </v-tooltip>
                            </v-btn>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                            <span class="black--text font-weight-medium py-2">관계</span>
                        </v-col>
                        <v-col class="d-flex justify-left align-center" cols="3">
                            <span class="d-inline-block ml-2" style="width: 100px;">
                                <v-text-field
                                    v-model="cnslnInf.rltns"
                                    :rules="[rules.required]" height="28"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="greyE01 txtAlgnMid py-2" cols="2">
                            <span class="black--text font-weight-medium">상담방법</span>
                        </v-col> 
                        <v-col class="d-flex justify-left align-center" cols="10">     
                            <span class="d-inline-block ml-2" style="width: 140px;">
                                <v-select
                                    v-model="cnslnInf.cnslnMthdCd"
                                    :items="cjMtd" :key="forceRender"
                                    item-text="valcdnm" item-value="valcd"
                                    dense hide-details outlined>
                                </v-select>
                            </span>
                            <span class="d-inline-block mx-2" style="width:100%">
                                <v-text-field
                                    v-model="cnslnInf.cnslnMthd" :disabled="readable"
                                    :rules="cnslnInf.cnslnMthdCd == '0' ? [rules.required] : []"
                                    height="28"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="greyE01 py-2 d-flex align-center" cols="2">
                            <div class="txtAlgnMid" style="width: 100%; line-height: 1.2;">
                                <span class="black--text font-weight-medium">상담내용</span>
                                <br/>
                                <span class="black--text font-weight-medium">&#40;상담불가사유&#41;</span>
                            </div>
                        </v-col> 
                        <v-col class="d-flex justify-left align-center" cols="10">     
                            <v-textarea class="pa-2 rmrksArea" v-model="cnslnInf.cnslnCntnt" :rules="[rules.required]"
                                rows="5" hide-details no-resize outlined dense>
                            </v-textarea>   
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="greyE01 py-2 d-flex justify-center align-center" cols="2">
                            <span class="black--text font-weight-medium">조치내용</span>
                        </v-col> 
                        <v-col class="d-flex justify-left align-center" cols="10">
                            <v-textarea
                                class="pa-2 rmrksArea" rows="5"
                                v-model="cnslnInf.actnCntnt" :rules="[rules.required]"
                                hide-details no-resize outlined dense>
                            </v-textarea> 
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="greyE01 txtAlgnMid py-2" cols="2">
                            <span class="black--text font-weight-medium">상담자</span>
                        </v-col> 
                        <v-col class="d-flex justify-left align-center" cols="10">
                            <span class="d-inline-block ml-2 py-2" style="width: 100px;">
                                <v-text-field
                                    v-model="cnslnInf.cnslr"
                                    :rules="[rules.required]" height="28"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <v-btn 
                                class="ml-1 brown lighten-3" style="padding:0px 1px 0px 2px !important"
                                min-width="30" height="25" @click="dialog.splice(0, 1, true)"
                                dark small>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                    </template>
                                    <span>직원선택</span>
                                </v-tooltip>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-divider class="mb-6"></v-divider>
                </v-form>
                <v-row class="pa-0" no-gutters>
                    <v-icon style="font-size: 30px; width: 8px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h5 font-weight-bold ml-3">급여제공반영&nbsp;정보</span>
                    <v-spacer></v-spacer>
                </v-row>
                <v-divider class="mt-2"></v-divider>
                <v-row class="greyE01 pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR py-2" cols="2">
                        <span class="black--text font-weight-medium">반영일</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR py-2" cols="8">
                        <span class="black--text font-weight-medium">급여내용</span>
                    </v-col>
                    <v-col class="txtAlgnMid py-2" cols="2">
                        <span class="black--text font-weight-medium">처리</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <div v-if="bnftOfrPlnHisList.length == 0">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="txtAlgnMid py-2" cols="12">
                            <span class="grey006--text" style="font-size: 0.925rem;">
                                &#8251; 상담일지를 저장 후 급여제공반영 정보를 등록할 수 있습니다.
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                </div>
                <div v-else>
                    <v-row class="pa-0" v-for="(list, i) in bnftOfrPlnHisList" :key="i" no-gutters>
                        <v-col class="d-flex justify-center tableBR tableBB py-2" cols="2">
                            <span class="">{{ list.bnftOfrDt?.beforeDateFormatDot() }}</span>
                        </v-col>
                        <v-col class="d-flex justify-center tableBR tableBB py-2" cols="8">
                            <span class="">{{ list.aftBnftCntnt }}</span>
                        </v-col>
                        <v-col class="d-flex justify-center tableBB" cols="2">
                            <span class="">
                                <v-btn icon @click="bnftSrvPlnPk = list.bnftOfrPlnHisPk, dialog.splice(2, 1, true)">
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>
                            </span>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
        <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
            <EmpSelector :prtWrtDt="cnslnInf.cnslnDt" @isClose="dialog.splice(0, 1, false)" @outEmp="getEmpInfo"></EmpSelector>
        </v-dialog>
        <v-dialog v-model="dialog[1]" eager content-class="round" persistent max-width="600">
            <fmlySelector :prtBnMmbrPk="prtBnMmbrPk" @isClose="dialog.splice(1, 1, false)" @outFmly="getFmlyInfo"></fmlySelector>
        </v-dialog>
        <v-dialog v-model="dialog[2]" eager content-class="round" persistent max-width="1400">
            <BnftSrvPlnMdfComp
                ref="bnftSrvPlnMdf" @isClose="dialog.splice(2, 1, false)"
                @modalEvent="bnftSrvPlnMdfEvn()"
                :bnMmbrPk="prtBnMmbrPk"
                :inqYYYY="prtYear"
                :bnftSrvPlnPk="bnftSrvPlnPk"
                :bnftSrvPlnBzClcd="'18'"
                :bnftSrvPlnBzPk="sltCnslnHisPk"
                :mdfRsncd="'5'">
            </BnftSrvPlnMdfComp>
        </v-dialog>
    </v-sheet>
</template>

<script>
import {
    getBnfcrBasicInfo, setCnslnHis, getCnslnHisQrtList, getCnslnHisInf, delCnslnHis, selBnfcrPkList
} from '../../api/bnfcr.js';
import { selBnftOfrPlnHisList } from '../../api/bnftSrv.js';

import EmpSelector from '../commons/EmpSelector.vue';
import FmlySelector from '../commons/FmlySelector.vue';
import BnftSrvPlnMdfComp from '../bnftSrv/BnftSrvPlnMdf.vue'; 
import expandQtrPicker from '../../components/commons/expandQtrPicker.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';

export default {
    name: 'CnslnJrnlsModal',

    components: {
        EmpSelector,
        FmlySelector,
        BnftSrvPlnMdfComp,
        expandQtrPicker,
        CmmDateComp,
        CmmTimeField,
    },

    props : {
        prtBnMmbrPk: { type: Number, default: 0 },
        prtYear: { type: Number, default: 1900 },
        prtQtr: { type: Number, default: 1 },
        cjMtd: { type: Array, default: () => { return [] } },
    },

    created: function(){
        this.dummyCnslnInf = this.$moment().format('YYYY-MM-DD')
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.qtr = this.prtQtr

            if(this.prtBnMmbrPk > 0){
                this.getInfo()
                this.getBnfcrPkList()
            }
        })
    },

    watch:{
        'prtBnMmbrPk': function(){
            this.clearForm()

            if(this.prtBnMmbrPk > 0){
                this.getInfo()
                this.getBnfcrPkList()
            }
        },
        'sltCnslnHisPk': function(){
            if(this.sltCnslnHisPk == -1){
                this.clearForm()
                this.filledBtn[1].disabled = true
                this.filledBtn[2].disabled = true
            }
            else{
                this.filledBtn[1].disabled = false
                this.filledBtn[2].disabled = false
                this.getCnslnInf(this.sltCnslnHisPk)
                this.getBnftOfrPlnHisList()
            }
        },
        'sltBnfcrPk': function(){
            this.getCnslnHisQrtList()
        },
        'prtQtr': function(){
            this.qtr = this.prtQtr
        },
        'qtr': function(){
            this.getCnslnHisQrtList()
        },
    },
    
    methods: {
        getInfo: function (){   
            getBnfcrBasicInfo(this.$store.getters.getFcltyNum, this.prtBnMmbrPk)
                .then((response) => ( this.bnfcrBasicInfo = response.data ))
                .catch((error) => console.log('connect error /bnfcr/selBnfcrBsInfo : ' + error))
        },

        getBnftOfrPlnHisList: function (){
            selBnftOfrPlnHisList(
                this.$store.getters.getFcltyNum,
                { bnMmbrPk: this.prtBnMmbrPk,
                    bzPk: this.sltCnslnHisPk, mdfClcd: '2', mdfRsncd: '5',
                    inqYYYY: this.prtYear,})
                .then((response) => ( this.bnftOfrPlnHisList = response.data ))
                .catch((error) => console.log('connect error /bnftSrv/selBnftOfrPlnHisList : ' + error))
        },

        insCnslnHis: function (obj){ 
            setCnslnHis(obj)
                .then((response) => ( this.insCnslnHisAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/insCnslnHis : ' + error))
        },

        getCnslnHisQrtList: function (){
            getCnslnHisQrtList(this.$store.getters.getFcltyNum, this.prtBnMmbrPk, this.sltBnfcrPk, this.qtr, this.prtYear)
                .then((response) => ( this.cnslnHisQrtList = response.data ))
                .catch((error) => console.log('connect error /bnfcr/selCnslnHisQrtList : ' + error))
        },

        getCnslnInf: function (pk){   
            getCnslnHisInf(this.$store.getters.getFcltyNum, pk)
                .then((response) => ( this.getCnslnInfAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selCnslnHisInf : ' + error))
        },

        removeCnslnHis: function (){
            delCnslnHis(this.$store.getters.getFcltyNum, this.cnslnInf.cnslnHisPk, this.$store.getters.getUserNm, this.bnftSrvPlnPk)
                .then((response) => ( this.removeCnsHisAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/delCnslnHis : ' + error))
        },

        // 수급자 구성원(bnfcrPk) 고유번호 조회
        getBnfcrPkList: function (){
            this.sltBnfcrPk = -1
            this.bnfcrPkList.splice(0)

            selBnfcrPkList(this.$store.getters.getFcltyNum, this.prtBnMmbrPk)
                .then((response) => ( this.bnfcrPkRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selBnfcrPkList : ' + error))
        },

        preInsCnsln: function (){
            let copyObj = Object.assign({}, this.cnslnInf)
            this.qtr = Math.ceil( (new Date(copyObj.cnslnDt).getMonth() + 1) / 3 )

            copyObj.fcltyNum = this.$store.getters.getFcltyNum
            copyObj.bnMmbrPk = this.prtBnMmbrPk
            copyObj.userNm = this.$store.getters.getUserNm
            
            copyObj.cnslnDt = copyObj.cnslnDt.afterDateFormatHyp()

            if(this.cnslnStrt != null || this.cnslnStrt != ''){
                let splitTime = this.cnslnStrt?.split(':', 2)
                copyObj.cnslnTmHhStrt = splitTime[0]
                copyObj.cnslnTmMmStrt = splitTime[1]
            }

            if(this.cnslnEnd != null || this.cnslnEnd != ''){
                let splitTime = this.cnslnEnd?.split(':', 2)
                copyObj.cnslnTmHhEnd = splitTime[0]
                copyObj.cnslnTmMmEnd = splitTime[1]
            }

            if(copyObj.cnslnImpsbYn == null) copyObj.cnslnImpsbYn = '2'

            copyObj.name = this.bnfcrBasicInfo.name

            this.insCnslnHis(copyObj)
        },

        insCnslnHisAfter: function (res){
            if(res.statusCode == 200){
                this.$emit('reload', true)
                this.clearForm()
                this.snackControll([true, 2500, res.message, 'info'])
                this.getBnftOfrPlnHisList()
                this.getCnslnHisQrtList()
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        getCnslnInfAfter: function (res){
            res.cnslnDt = this.$moment(res.cnslnDt, 'YYYYMMDD').format('YYYY-MM-DD')
            this.cnslnStrt = res.cnslnTmHhStrt + ':' + res.cnslnTmMmStrt
            this.cnslnEnd = res.cnslnTmHhEnd + ':' + res.cnslnTmMmEnd
            this.cnslnInf = Object.assign({}, res)
        },

        removeCnsHisAfter: function (res){
            if(res.statusCode == 200){
                this.$emit('reload', true)
                this.getCnslnHisQrtList()
                this.getBnftOfrPlnHisList()

                this.clearForm()
                this.filledBtn[2].disabled = true

                this.snackControll([true, 2500, res.message, 'info'])
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },

        bnfcrPkRst: function (res){
            if(res.statusCode == '200'){
                if(Array.isArray(res.result)){
                    res.result.forEach((itm, i) => {
                        let obj = ''
                        if(i == 0)
                            obj += '현재 '
                        else
                            obj += '과거 '

                        if(itm.lvDt == null || itm.lvDt == '')
                            obj += this.$moment(itm.entDt, 'YYYYMMDD').format('( YY.MM.DD ~ )')
                        else{
                            obj += this.$moment(itm.entDt, 'YYYYMMDD').format('( YY.MM.DD ')
                            obj += this.$moment(itm.lvDt, 'YYYYMMDD').format('~ YY.MM.DD ) ')
                        }

                        itm.viewTxt = obj
                    });
                    
                    let arr = res.result.filter( v => v.entDt.slice(0, 4) == this.prtYear )
                    if(arr.length > 0)
                        this.sltBnfcrPk = arr[0].bnfcrPk
                    else
                        this.sltBnfcrPk = res.result[0].bnfcrPk

                    this.bnfcrPkList = res.result
                }
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },

        bnftSrvPlnMdfEvn: function () {
            this.clearForm()
            this.getBnftOfrPlnHisList()
            this.getCnslnHisQrtList()
        },

        // sltCnslnQrtRow: function (index) {
        //     this.$refs.qtrRow.forEach(element => {
        //         element.style="border-bottom: 1px solid #dbdbdb !important;"
        //     })
        //     this.$refs.qtrRow[index].style = 'border-bottom: 1px solid #dbdbdb !important; background: #ebf6fe !important;'
        //     this.filledBtn[2].disabled = false

        //     this.getCnslnInf(this.cnslnHisQrtList[index].cnslnHisPk)
        // },

        isClose: function () {
            this.clearForm()
            this.$emit('isClose', 9995)
        },

        getQtr: function (value) {
            this.clearForm()
            this.qtr = value

            this.cnslnInf = Object.assign({}, this.dummyCnslnInf)
            this.cnslnStrt = ''
            this.cnslnEnd = ''

            this.filledBtn[2].disabled = true
            this.$refs.form.resetValidation()
        },

        tableClickEvent: function (pk, bnftPk){
            if(this.sltCnslnHisPk == pk){
                this.sltCnslnHisPk = -1
                this.bnftSrvPlnPk = -1
            }
            else{
                this.sltCnslnHisPk = pk
                this.bnftSrvPlnPk = bnftPk
            }
        },

        clearForm: function() {
            // let timezoneOffset = new Date().getTimezoneOffset() * 60000
            // let timezoneDate = new Date(Date.now() - timezoneOffset)
            this.cnslnInf = Object.assign({}, this.dummyCnslnInf)
            
            this.cnslnStrt = ''
            this.cnslnEnd = ''
            this.bnftOfrPlnHisList.splice(0)
            this.sltCnslnHisPk = -1
            this.bnftSrvPlnPk = -1
            this.$refs.form.resetValidation()
            this.forceRender += 1
        },

        getEmpInfo: function (obj) {
            this.cnslnInf.cnslr = obj[0].name
        },

        getFmlyInfo: function (obj) {
            this.cnslnInf.cnslnTrgt = obj.name
        },

        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },

        onClickBtn: function(text) {
            if(text == '저장'){
                if(this.$refs.form.validate())
                    this.preInsCnsln()
            }
            else if(text == '급여제공반영'){
                if(this.bnftSrvPlnPk == null)
                    this.bnftSrvPlnPk = 0
                this.dialog.splice(2, 1, true)
            }
            else if(text == '삭제'){
                this.removeCnslnHis()
            }
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        qtr: 0,
        menu: [false, false, false],
        dialog: [false, false, false],
        sltBnfcrPk: -1,
        sltCnslnHisPk : -1,
        bnftSrvPlnPk: -1,
        readable: false,
        cnslnStrt: '',
        cnslnEnd: '',
        bnfcrBasicInfo: {
            majorDsss: '', bnMmbrPk: 0, gndr: '', name: '', entDt: '', age: 0
        },
        cnslnInf: {
            fcltyNum: 0, bnMmbrPk: 0, cnslnHisPk: 0, name: '', cnslnDt: '', cnslnImpsbYn: '2', cnslnTrgt: '', rltns: '', cnslnMthdCd: '4',
            cnslnMthd: '', cnslnCntnt: '', actnCntnt: '', cnslnTmHhStrt: '', cnslnTmMmStrt: '', cnslnTmHhEnd: '', cnslnTmMmEnd: '', cnslr: ''
        },
        dummyCnslnInf: {
            fcltyNum: 0, bnMmbrPk: 0, cnslnHisPk: 0, name: '', cnslnDt: '', cnslnImpsbYn: '2', cnslnTrgt: '', rltns: '', cnslnMthdCd: '4',
            cnslnMthd: '', cnslnCntnt: '', actnCntnt: '', cnslnTmHhStrt: '', cnslnTmMmStrt: '', cnslnTmHhEnd: '', cnslnTmMmEnd: '', cnslr: ''
        },
        cnslnHisQrtList: [
            { cnslnHisPk: 0, cnslnTrgt: '', cnslr: '', bnftOfrDt: '', cnslnDt: '' }
        ],
        bnfcrPkList: [],
        bnftOfrPlnHisList: [],
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', disabled: false, },
            { icon: 'mdi-text-box-edit-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '급여제공반영', disabled: true, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', disabled: true, },
        ],
        rules: {
            required: value => !!value || '필수 입력 항목입니다.',
            selCheck: value => !( value == '직접입력' || value == '' ) || '필수 입력 항목입니다.',
        },
        forceRender: 0,
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>

<style>
    .qtrListRow:hover { background: #ebf6fe !important; }
</style>