<template>
    <v-sheet class="ma-0" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <span class="text-h4 font-weight-bold">보조금&nbsp;변경이력</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="$parent.$parent.$parent.$parent.mdlOff" x-large>mdi-close</v-icon>
        </v-row>
        <v-divider :class="['mt-8', grantsLogList.length > 10 ? 'mr-2__5' : 'mr-1']" color="black"></v-divider>
        <v-row :class="[grantsLogList.length > 10 ? 'pr-2__5' : 'pr-1']" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="1">
                <span class="font-weight-medium"></span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="1">
                <span class="font-weight-medium">상태</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="4">
                <span class="font-weight-medium">보조금</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="3">
                <span class="font-weight-medium">변경사유</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="1">
                <span class="font-weight-medium">수정자</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid py-2" cols="2">
                <span class="font-weight-medium">수정일시</span>
            </v-col>
        </v-row>
        <v-divider :class="[grantsLogList.length > 10 ? 'mr-2__5' : 'mr-1']"></v-divider>
        <div v-if="grantsLogList.length > 0" class="overflow-y-auto" style="width: 100%; height: 321px;">
            <v-row
                :class="[grantsLogList.length > 10 ? '' : 'pr-1', i % 2 == 1 ? 'nmlBfClrImp-yellow001' : '']"
                v-for="(itm, i) in grantsPkList" :key="i" no-gutters>
                <v-col
                    v-for="(log, i) in grantsLogList.filter( v => v.bnfcrGvrnmSprtPk == itm )" :key="i"
                    cols="12">
                    <v-row class="pa-0" no-gutters>
                        <v-col
                            :class="[
                                'txtAlgnMid tableBR posn-rltv py-1',
                                grantsLogList.filter( v => v.bnfcrGvrnmSprtPk == itm ).length-1 == i ? 'tableBB' : '']"
                            cols="1">
                            <span v-if="i==0">
                                <span
                                    class="d-inline-block greyE00"
                                    style="width: 11px; height: 11px;">
                                    <span
                                        v-if="grantsLogList.filter( v => v.bnfcrGvrnmSprtPk == itm ).length > 1"
                                        class="d-inline-block"
                                        style="position: absolute; top: 11px; left: 28px; width: 12px; height: 12px;">
                                        <v-divider class="greyE00" vertical></v-divider>
                                    </span>
                                </span>
                            </span>
                            <span
                                v-else-if="grantsLogList.filter( v => v.bnfcrGvrnmSprtPk == itm ).length-1 == i"
                                class="d-inline-block">
                                <span
                                    class="d-inline-block"
                                    style="position: absolute; top: -7px; left: 26px; width: 16px; height: 16px;">
                                    <v-divider class="greyE00" vertical></v-divider>
                                </span>
                                <span
                                    class="d-inline-block"
                                    style="position: absolute; top: 16px; left: 33px; width: 50%; height: 2px;">
                                    <v-divider class="greyE00"></v-divider>
                                </span>
                            </span>
                            <span v-else class="d-inline-block">
                                <span
                                    class="d-inline-block"
                                    style="position: absolute; top: 0px; left: 18px; width: 32px; height: 32px;">
                                    <v-divider class="greyE00" vertical></v-divider>
                                </span>
                                <span
                                    class="d-inline-block"
                                    style="position: absolute; top: 16px; left: 33px; width: 50%; height: 2px;">
                                    <v-divider class="greyE00"></v-divider>
                                </span>
                            </span>
                        </v-col>
                        <v-col class="txtAlgnMid tableBR tableBB py-1" cols="1">
                            <span v-if="log.mdfClcd == '1'">신규</span>
                            <span v-else-if="log.mdfClcd == '2'">수정</span>
                            <span v-else-if="log.mdfClcd == '3'">삭제</span>
                        </v-col>
                        <v-col class="txtAlgnMid tableBR tableBB py-1" cols="4">
                            <span v-if="log.snackSprtYn == '2' && log.mealSprtYn == '2' ">해당없음</span>
                            <span v-if="log.snackSprtYn == '1'">간식비</span>
                            <span v-if="log.snackSprtYn == '1' && log.mealSprtYn == '1' ">, </span>
                            <span v-if="log.mealSprtYn == '1'">식사재료비</span>
                        </v-col>
                        <v-col class="overflow-text-hidden txtAlgnMid tableBR tableBB py-1" cols="3">{{ log.mdfRsn }}</v-col>
                        <v-col class="txtAlgnMid tableBR tableBB py-1" cols="1">{{ log.mdfr }}</v-col>
                        <v-col class="txtAlgnMid tableBB py-1" cols="2">{{ log.mdfDt }}</v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
        <v-row v-else class="tableBB pa-0" no-gutters>
            <v-col class="txtAlgnMid py-2" cols="12">
                <span class="fontOneRem grey006--text">※ 보조금 변경 이력이 존재하지 않습니다.</span>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

export default {
    name: 'GrantsLogMdl',

    components: {
       
    },

    props : {
        
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            let obj = this.$store.getters.getSubInObj
            this.getGrantsLogList(obj.bnMmbrPk, obj.bnfcrPk)
        })
    },

    computed: {
      
    },

    watch: {
     
    },

    methods:{
       // 보조금 변경이력 조회
       getGrantsLogList: function (mbr, fcr){
            this.grantsLogList.splice(0)
            this.grantsPkList.splice(0)

            let obj = {}
            obj.bnMmbrPk = mbr
            obj.bnfcrPk = fcr
            //obj.fcltyNum = this.$store.getters.getFcltyNum

            http.post('bnfcrAuth/selBnfcrGvrnmSprtHis', obj)
                .then((response) => ( this.grantLogRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/selBnfcrGvrnmSprtHis : ' + error))
        },

        grantLogRst: function (res){
            if(res.result.length > 0){
                res.result.forEach(itm => {
                    let chc = this.grantsPkList.findIndex( v => v == itm.bnfcrGvrnmSprtPk )
                    if(chc == -1)
                        this.grantsPkList.push(itm.bnfcrGvrnmSprtPk)
                });
                this.grantsLogList = res.result
            }
                
        },
    },

    data: () => ({
        grantsLogList: [],
        grantsPkList: [],
    })
}
</script>