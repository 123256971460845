<template>
    <v-sheet class="ma-0" rounded="md">
        <div class="d-flex mx-2">
           
        </div>
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col class="" cols="2">
                <v-text-field
                    v-model="bbsParams.srchWd2"
                    class="ma-0 pa-0 ntcChc" background-color="white" append-icon="mdi-magnify"
                    @input="reLoad"
                    width="100px" height="37px" single-line rounded outlined dense filled hide-details label="제목">
                </v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex justify-end align-center" cols="2">
                <span v-if="loader">
                    <v-progress-circular            
                    :size="30"
                    color="primary"
                    indeterminate
                    ></v-progress-circular>
                </span>
                <span v-if="!loader">
                    <span v-for="(list, i) in filledBtn" :key="i">
                        <v-btn :class="list.class" height="30" :color="list.color"
                            :disabled="list.disabled" @click="onClickBtn(i)" samll rounded>
                            <v-icon small>{{list.icon}}</v-icon>
                            <span style="font-size: 1rem;">{{list.text}}</span>
                        </v-btn>
                    </span>
                </span>
            </v-col>
        </v-row>
        <v-row class="pa-0 mt-3" no-gutters>
            <v-col cols="12">
                <v-data-table
                    :headers="bbsHeaders"
                    :items="bbsList"
                    :loading="loader" loading-text="Loading... Please wait"                    
                    fixed-header hide-default-header hide-default-footer dense disable-pagination                    
                    height='580'
                >
                    <template v-slot:header="{ props }" >
                        <thead>
                            <tr>
                                <th class="black--text clmsDtlHeaderCommon tableBT tableBR" style="width:7%;">{{props.headers[0]?.text}}</th>
                                <th class="black--text clmsDtlHeaderCommon tableBT tableBR" style="width:33%;">{{props.headers[1]?.text}}</th>
                                <th class="black--text clmsDtlHeaderCommon tableBT tableBR" style="width:10%;">{{props.headers[2]?.text}}</th>
                                <th class="black--text clmsDtlHeaderCommon tableBT tableBR" style="width:10%;">{{props.headers[3]?.text}}</th>
                                <th class="black--text clmsDtlHeaderCommon tableBT tableBR" style="width:15%;">{{props.headers[4]?.text}}</th>
                                <th class="black--text clmsDtlHeaderCommon tableBT tableBR" style="width:15%;">{{props.headers[5]?.text}}</th>
                                <th class="black--text clmsDtlHeaderCommon tableBT" style="width:10%;">{{props.headers[6]?.text}}</th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item }'>
                        <tr>
                            <td class="clmsBodyCommon tableBR pa-0">
                                {{item.bbsPk}}
                            </td>
                            <td class="clmsBodyCommon tableBR py-2">
                                <div class="text-overflow" style="width:500px;text-align:left">{{item.bbsTitle}}</div>
                            </td>
                            <td class="clmsBodyCommon tableBR py-2">
                                <v-icon v-if="item.bbsNotiYn == '1'" color="blue" small>mdi-check-circle-outline</v-icon>
                                <v-icon v-else color="" small>mdi-minus-circle-outline</v-icon>
                            </td>
                            <td class="clmsBodyCommon tableBR py-2">
                                {{item.crtr}}
                            </td>
                            <td class="clmsBodyCommon tableBR py-2">{{ item.crtDt }}</td>
                            <td class="clmsBodyCommon tableBR py-2">{{ item.mdfDt }}</td>
                            <td class="clmsBodyCommon py-2">
                                <v-btn icon @click="onEdit(item)">                                                
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on">mdi-square-edit-outline</v-icon>
                                        </template>
                                        <span>수정</span>
                                    </v-tooltip>
                                </v-btn>
                                <v-btn icon class="" @click="isDelete(item)">                                                
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on">mdi-trash-can-outline</v-icon>
                                        </template>
                                        <span>삭제</span>
                                    </v-tooltip>
                                </v-btn>
                            </td>  
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row class="mt-2 pa-0" no-gutters>
            <v-col class="" cols="12">
                <v-pagination
                    v-model="page"
                    :length="pageCount"
                    @input="onPage"
                    total-visible="5">
                </v-pagination>
            </v-col>
        </v-row>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[0]" max-width="800" content-class="round" persistent>           
                <HomBbsWrtMdl
                    v-if="dialog[0]"
                    :mdlTtl="mdlTtl"
                    :bbsPk="sltBbsPk"
                    :bbsId="bbsParams.bbsId"
                    @modalEvent="reLoad"
                    @onClose="isDialog(0)">
                </HomBbsWrtMdl>
            </v-dialog>
        </div>
    </v-sheet>    
</template>
<script>
import { selHomBbsList, delHomBbs } from '../../api/hom.js';

import HomBbsWrtMdl from '../../components/hom/HomBbsWrtMdl.vue';

export default {
    name: 'HomBbs',

    props : {
       
    },
        
    components: {
        HomBbsWrtMdl,
    },

    created: function(){
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
                        
            let obj = {
                page : 1,
                itemsPerPage : this.bbsParams.rnLmt,
            }

            this.getHomBbsList(obj)
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch:{
        getSubEvt: function (bol) {
            if(bol && this.rmHomBbs){
                this.onDelete()
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.rmHomBbs = false
            }else{
                this.sltBbsPk = 0
            }
        },
    },
    
    methods: {
        
        onClickBtn:function(key){
            switch (key) {
                case 0:
                    this.mdlTtl = (this.bbsParams.bbsId == 'hmpgnot')?'공지사항 글쓰기':'겔러리 글쓰기'
                    this.sltBbsPk = 0
                    this.dialog.splice(0,1,true)
                    break;
            
                default:
                    break;
            }
        },
        onEdit:function(obj){
            this.mdlTtl = (this.bbsParams.bbsId == 'hmpgnot')?'공지사항 수정':'겔러리 수정'
            this.sltBbsPk = Number(obj.bbsPk)
            this.dialog.splice(0, 1, true)
        },

        onPage:function(){
            let obj = {
                page : this.page,
                itemsPerPage : this.bbsParams.rnLmt,
            }
            this.getHomBbsList(obj)
        },

        isDelete:function(itm){
            
            if(itm.bbsPk > 0){
                this.sltBbsPk = itm.bbsPk
                let obj = {}
                obj.icon = 'mdi-alert'
                obj.iconClr = 'red'
                obj.title = '공지사항'
                obj.cntnt = [itm.bbsTitle+'을(를) 삭제하시겠습니까?']

                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 삭제하겠습니다.'

                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });

                this.rmHomBbs = true

            } else {
                let obj = {}
                obj.actv = true  
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = '대상을 찾을 수 없습니다.'         // 경고 타이틀
                this.$store.commit('setWrnnInf', obj)
            }   
        },  

        onDelete:function(){
            let obj = {
                fcltyNum : this.$store.getters.getFcltyNum,
                bbsPk : this.sltBbsPk,
            }
            this.setDelHomBbs(obj)
        },

        getHomBbsList:function(obj){ 
            this.bbsList.splice(0)
            this.loader = true
            this.bbsParams.rnStrt = ((obj.page-1) * obj.itemsPerPage)
            this.bbsParams.fcltyNum = this.$store.getters.getFcltyNum
            selHomBbsList(this.bbsParams)
                .then((response) => ( this.getHomBbsAfter(response.data) ))
                .catch((error) => console.log('connect error /hominf/selHomBbsList : ' + error))
        },

        setDelHomBbs:function(obj){
            delHomBbs(obj)
                .then((response) => ( this.setDelHomBbsAfter(response.data) ))
                .catch((error) => console.log('connect error /hominf/delHomBbs : ' + error))
        },

        getHomBbsAfter:function(res){
            this.loader = false            
            if(res.statusCode == 200){
                if(res.result != null && res.result != undefined){
                    this.totCnt = res.result.rnTot
                    this.pageCount = Math.ceil(Number(res.result.rnTot)/this.itemsRows)
                    res.result.list.forEach(el => {
                        this.bbsList.push(el)
                    });
                }
            } else {
                // this.snackControll([true, 5000, res.message, 'error'])
                let obj = {
                    actv : true,                // 경고 노출여부
                    tmr : 5000,                 // 경고 노출시간(-1: 무제한)
                    type: 'warning',            // 경고 유형 (success, info, warning, error)
                    title : res.message,         // 경고 타이틀
                    cntnt : res.messagesList,    // 경고 내용 (arr만 출력됨!)
                }

                this.$store.commit('setWrnnInf', obj)
            }

        },

        setDelHomBbsAfter:function(res){
            let obj = {}
            obj.actv = true   
            
            if(res.statusCode == 200){                                         
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)     
                this.reLoad()            
            }
            else{                
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
            }

            this.$store.commit('setWrnnInf', obj)
        },
        isDialog:function(key){
            this.dialog.splice(key, 1, false)
        },
        reLoad:function(){
            let obj = {
                page : 1,
                itemsPerPage : this.bbsParams.rnLmt,
            }
            this.page=1
            this.getHomBbsList(obj)
        },
    },

    data: () => ({
        loader:false,
        rmHomBbs:false,
        page:1,
        pageCount:0,
        itemsRows: 10,
        totCnt: 0,
        dialog:[false],
        mdlTtl:'',
        sltBbsPk:0,
        bbsParams:{
            bbsId:'hmpgnot',
            rnStrt:0,
            rnLmt : 10,
            srchWd1:null,
            srchWd2:'',
        },
        bbsHeaders: [            
            { text: '번호', value: 'bbsPk', sortable: true, width: '', class: 'black--text bottomBorder fixedPstn1 bnfcrMidIndex'},
            { text: '제목', value: 'bbsTitle', sortable: true, width: '', class: 'black--text bottomBorder'},
            { text: '공지여부', value: 'bbsNotiYn', sortable: true, width: '', class: 'black--text bottomBorder'},
            { text: '작성자', value: 'crtr', sortable: true, width: '', class: 'black--text bottomBorder'},
            { text: '작성일', value: 'crtDt', sortable: true, width: '', class: 'black--text bottomBorder'},
            { text: '수정일', value: 'mdfDt', sortable: true, width: '', class: 'black--text bottomBorder'},
            { text: '처리', value: 'lvnRmNm', sortable: true, width: '', class: 'black--text bottomBorder'},
        ],
        bbsList:[],
        filledBtn: [
            { icon: 'mdi-pencil-outline', class: 'white--text', color:'blueBtnColor', text: '글쓰기', disabled: false, },           
        ],
    }),
};
</script>