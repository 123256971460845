<template>
    <v-sheet class="ma-0 overflow-y-auto" rounded="md" style="height: 710px;">
        <v-form ref="nwlForm" lazy-validation>
            <v-divider></v-divider>
            <v-row class="pa-0 ma-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center font-weight-bold py-2 tableBR" cols="2">
                    <span class="">작성자</span>
                </v-col>
                <v-col class="d-flex align-center pl-1" cols="10">
                    <span class="d-inline-block pa-1" style="width:120px;">
                        <v-text-field
                            v-model="inspRec.wrtr" :rules="[rules.required]" height="28"
                            readonly hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <v-btn 
                        class="brown lighten-3 d-inline-block" style="padding:0px 1px 0px 2px !important"
                        min-width="30" height="27" @click="dialog.splice(0, 1, true)"
                        dark small depressed>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                            </template>
                            <span>직원선택</span>
                        </v-tooltip>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pa-0 ma-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center font-weight-bold py-2 tableBR" cols="2">
                    <span class="">특이사항</span>
                </v-col>
                <v-col class="" cols="10">
                    <div class="d-flex justify-center align-center pa-2">
                        <v-textarea v-model="inspRec.cntnt1"
                            class="rmrksArea" no-resize hide-details outlined dense>
                        </v-textarea>
                    </div>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pa-0 ma-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center font-weight-bold py-2 tableBR" cols="2">
                    <span class="">조치사항</span>
                </v-col>
                <v-col class="" cols="10">
                    <div class="d-flex justify-center align-center pa-2">
                        <v-textarea v-model="inspRec.cntnt2"
                            class="rmrksArea" no-resize hide-details outlined dense>
                        </v-textarea>
                    </div>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pa-0 ma-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center font-weight-bold py-2 tableBR" cols="2">
                    <span class="">전달사항</span>
                </v-col>
                <v-col class="" cols="10">
                    <div class="d-flex justify-center align-center pa-2">
                        <v-textarea v-model="inspRec.cntnt3"
                            class="rmrksArea" no-resize hide-details outlined dense>
                        </v-textarea>
                    </div>
                </v-col>
            </v-row>
            <v-divider></v-divider>
        </v-form>
        <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
            <EmpSelector @isClose="dialog.splice(0, 1, false)" @outEmp="getEmpInfo" :prtWrtDt="$parent.$parent.$parent.$parent.$parent.$parent.sltDate"></EmpSelector>
        </v-dialog>
    </v-sheet>
</template>

<script>
import EmpSelector from '../commons/EmpSelector.vue';

import { selInspRecInfo, insInspRec, } from '../../api/sfty.js';

export default {
    name: 'NghWrkLog',

    props : {
       
    },
        
    components: {
        EmpSelector,
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.$parent.$parent.$parent.$parent.btnControll(1, true, 'fill')
            this.clearForm()
            this.onLoad()
        })
    },

    computed: {
        getSltInspRecPk: function () {
            return this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk
        },
    },

    watch:{
       getSltInspRecPk:function(){
            this.onLoad()
        }
    },
    
    methods: {
        onLoad:function(){
            if(this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk != 0) this.getInspRecInfo()
        },
        //야간근무일지 조회
        getInspRecInfo: function (){            
            selInspRecInfo(this.$store.getters.getFcltyNum, this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk, this.$parent.$parent.$parent.$parent.$parent.$parent.sltDate, 501, 1)
                .then((response) => ( this.getInspRecInfoAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/selInspRecInfo : ' + error))
        },
        //야간근무일지 저장
        setInspRec: function (obj){
            this.$parent.$parent.$parent.$parent.btnControll(0, true, 'fill')

            insInspRec(obj)
                .then((response) => ( this.setInspRecAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/insInspRec : ' + error))
        },
        setInspRecAfter: function (res){
            if(res.statusCode == 200){
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])

                this.$parent.$parent.$parent.$parent.$parent.$parent.getTableList()
                // this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk = 0
                // this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem = -1
                // this.$parent.$parent.$parent.$parent.tab = 0
                
                this.$parent.$parent.$parent.$parent.btnControll(0, false, 'fill')
                this.$parent.$parent.$parent.$parent.btnControll(0, true, 'out')

                this.getInspRecInfo()
            }
            else
                this.$parent.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])
        },
        getInspRecInfoAfter: function (res){
            if(typeof res == 'object'){
                res.inspPl = ''
                this.inspRec = res
            }
        },
        preInspRec: function () {
            let obj = Object.assign({}, this.inspRec)
            if(this.$refs.nwlForm.validate()){
                obj.fcltyNum = this.$store.getters.getFcltyNum
                obj.userNm = this.$store.getters.getUserNm
                obj.inspRecPk = this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk
                obj.inspDt = this.$parent.$parent.$parent.$parent.$parent.$parent.sltDate
               
                this.setInspRec(obj)
            }
            else
                this.$parent.$parent.$parent.$parent.snackControll([true, 2000, '필수 항목을 입력해주세요.', 'info'])
        },
        getEmpInfo: function (obj) {
            this.inspRec.wrtr = obj[0].name
            this.inspRec.wrtrMmbrPk = obj[0].mmbrPk
        },
        clearForm: function (){
            this.$refs.nwlForm.reset()
        },
    },

    data: () => ({
        dialog: [false],
        inspRec: {
            //점검기록 고유번호, 점검일자, 대분류코드, 중분류코드, 특이사항, 조치사항, 전달사항, 작성자
            inspRecPk: 0, inspDt: '', clsfc1Cd: 501, clsfc2Cd: 1, cntnt1: '', cntnt2: '', cntnt3: '', wrtr: '', wrtrMmbrPk : 0
        },
        rules: {
            required: value => !!value || 'Required.',
        },
    }),
};
</script>