<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">        
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">집중배설관찰 작성</span>
            <v-spacer></v-spacer>            
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <div class="d-flex justify-left mb-2 align-center">
            <v-spacer></v-spacer> 
            <span v-if="progress">
                <v-progress-circular            
                :size="50"
                color="primary"
                indeterminate
                ></v-progress-circular>
            </span>
            <span v-if="!progress">
                <v-btn v-for="(list, i) in filledBtn" :key="i"  @click = "onClickBtn(list.text)"
                    :class="list.class" height="30" :color="list.color" rounded
                    style="font-size: 14px;">
                    <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                    {{list.text}}
                </v-btn>
            </span>
        </div>
        <basicInfo :bnMmbrPk="bnMmbrPk"></basicInfo>
        <v-form ref="form" lazy-validation>
            <v-row class="pa-0 mt-1" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">관찰일</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="10">                
                    {{ymd?.beforeDateFormatDot()}}           
                </v-col>                
            </v-row>  
            <div class="mt-5" style="position:relative;">
                <div v-if="preDataChk != '1'" style="position:absolute;top:0px;left:0px;padding:0px 2px;width:100%;height:100%;z-index:7;">
                    <div style="width:100%;height:100%;background-color:black;opacity: 0.2;"></div>
                    <v-icon size="100" style="position:absolute;top:30%;left:47%;">mdi-pencil-off-outline</v-icon>
                    <div style="position:absolute;top:53%;left:30%;">
                        <span class="font-weight-bold" style="font-size:2rem;color:rgba(0, 0, 0, 0.54)">
                            {{preData?.beforeDateFormatDot()}} 집중배설 관찰기록을 작성해주세요.
                        </span>
                    </div>
                </div>
                <div class="pa-0 mt-1 d-flex mr-2__5" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;">
                    <div class="d-flex justify-center align-center pa-1 ma-0 greyE01" style="width:10%;">
                        <span class="black--text font-weight-medium">시간</span>
                    </div>
                    <div class="d-flex justify-center align-center pa-1 ma-0 greyE01" style="width:17%;border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">섭취량</span>
                    </div>
                    <div class="pa-0 ma-0 greyE01" style="width:15%;border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center py-2" style="height:50%">
                            <span class="black--text font-weight-medium">기저귀착용</span>
                        </div>
                        <div class="d-flex" style="border-top: 1px solid #dbdbdb;height:50%">
                            <div class="d-flex justify-center align-center pa-1" style="width:50%;">
                                <span class="black--text font-weight-medium">대변</span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium">소변</span>
                            </div>
                        </div>
                    </div>
                    <div class="pa-0 ma-0 greyE01" style="width:15%;border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center py-2" style="height:50%">
                            <span class="black--text font-weight-medium">스스로 이동</span>
                        </div>
                        <div class="d-flex" style="height:50%;border-top: 1px solid #dbdbdb;">
                            <div class="d-flex justify-center align-center pa-1" style="width:50%">
                                <span class="black--text font-weight-medium">대변</span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1" style="width:50%;border-left: 1px solid #dbdbdb;">
                                <span class="black--text font-weight-medium">소변</span>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-center align-center pa-0 ma-0 greyE01" style="width:8%;border-left: 1px solid #dbdbdb !important;">                        
                        <div>
                            <div class="d-flex justify-center align-center">
                                <span class="black--text font-weight-medium">장루&#40;요루&#41;</span>
                            </div>
                            <div class="d-flex justify-center align-center">
                                <span class="black--text font-weight-medium">도뇨관&#40;ml&#41;</span>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-center align-center pa-0 ma-0 greyE01" style="width:5%;border-left: 1px solid #dbdbdb !important;">
                        <div>
                            <div class="d-flex justify-center align-center">
                                <span class="black--text font-weight-medium">기저기</span>
                            </div>
                            <div class="d-flex justify-center align-center">
                                <span class="black--text font-weight-medium">교환</span>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-center align-center pa-0 ma-0 greyE01" style="width:5%;border-left: 1px solid #dbdbdb !important;">
                        <div>
                            <div class="d-flex justify-center align-center">
                                <span class="black--text font-weight-medium">옷</span>
                            </div>
                            <div class="d-flex justify-center align-center">
                                <span class="black--text font-weight-medium">교환</span>
                            </div>
                        </div>
                    </div>
                    <div class="pa-0 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center pa-1 greyE01">
                            <span class="black--text font-weight-medium">
                                담당자 선택
                            </span>
                            <v-btn              
                                class="pt-1"           
                                icon     
                                color="grey lighten-1"      
                                @click="onModal(0)">
                                <v-icon size="20">mdi-magnify</v-icon>
                            </v-btn>
                        </div>      
                        <div class="d-flex" style="border-top: 1px solid #dbdbdb;">
                            <div class="d-inline-block pl-1" style="width: 100%;">
                            <v-radio-group
                                class="radio-dft-Theme mt-0 pl-2 pt-1"
                                :key="forceRender"
                                hide-details row>
                                <v-radio 
                                    v-for="(list, k) in mmbrList" :key="k" 
                                    class="pa-0 mr-1"                                     
                                    :ripple="false" 
                                    :label="list.wrtr"                                     
                                    :value="list.wrtrpk"
                                    @click="allChecker(list)">
                                    <template v-slot:label>
                                        <span class="black--text">{{list.wrtr}}</span>
                                    </template>
                                </v-radio>                                
                            </v-radio-group>
                            </div>
                        </div>                  
                    </div>
                    <div class="d-flex justify-center align-center pa-1 ma-0 greyE01" style="width:5%;border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">처리</span>
                    </div>
                </div>
                <div style="height:457px;overflow-y:scroll;">
                    <div v-for="(item, i) in rec2List" :key="i">                        
                        <div v-if="item.srnum == '1'">
                            <div class="d-flex justify-center align-center pa-1 ma-0 excrtObsrv-blue-lighten-5" style="border-bottom: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium">※ 집중배설관찰 시작</span>
                            </div>
                        </div>
                        <div :class="['pa-0 ma-0 d-flex', item.class]" style="border-bottom: 1px solid #dbdbdb;">
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:10%;">
                                <span style="width:25px">{{item.ofrTmHhStrt}}</span>
                                <span style="width:10px">&#58;</span>
                                <span style="width:50px" class="ml-1">
                                    <v-text-field
                                        v-model="item.ofrTmMmStrt" :rules="[rules.minute]"
                                        @focus="item.ofrTmMmStrt=''"
                                        height="28" hide-details outlined dense>
                                    </v-text-field>
                                </span>  
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:17%;border-left: 1px solid #dbdbdb !important;">
                                <v-text-field
                                    v-model="item.itm6Val"
                                    color="blue" height="28"
                                    @focus="item.itm6Val=''"
                                    hide-details outlined dense>
                                </v-text-field>
                            </div>
                            <div class="pa-0 ma-0" style="width:15%;border-left: 1px solid #dbdbdb !important;">                            
                                <div class="d-flex" style="height:100%;">
                                    <div class="d-flex justify-center align-center pa-1" style="width:50%;height:100%;">
                                        <span class="d-inline-block" style="width: 110px;">
                                            <v-select 
                                                v-model="item.itm1Val"                               
                                                :items="codeItem68"
                                                item-text="valcdnm"
                                                item-value="valcd"
                                                dense hide-details outlined
                                            /> 
                                        </span>
                                    </div>
                                    <div class="d-flex justify-center align-center pa-1" style="width:50%;height:100%;border-left: 1px solid #dbdbdb !important;">
                                    <span class="d-inline-block" style="width: 110px;">
                                            <v-select 
                                                v-model="item.itm2Val"                               
                                                :items="codeItem68"
                                                item-text="valcdnm"
                                                item-value="valcd"
                                                dense hide-details outlined
                                            /> 
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="pa-0 ma-0" style="width:15%;border-left: 1px solid #dbdbdb !important;">
                                <div class="d-flex" style="height:100%">
                                    <div class="d-flex justify-center align-center pa-1" style="width:50%;height:100%">
                                        <span class="d-inline-block" style="width: 110px;">
                                            <v-select 
                                                v-model="item.itm3Val"                               
                                                :items="codeItem68"
                                                item-text="valcdnm"
                                                item-value="valcd"
                                                dense hide-details outlined
                                            /> 
                                        </span>
                                    </div>
                                    <div class="d-flex justify-center align-center pa-1" style="width:50%;height:100%;border-left: 1px solid #dbdbdb !important;">
                                    <span class="d-inline-block" style="width: 110px;">
                                            <v-select 
                                                v-model="item.itm4Val"                               
                                                :items="codeItem68"
                                                item-text="valcdnm"
                                                item-value="valcd"
                                                dense hide-details outlined
                                            /> 
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:8%;border-left: 1px solid #dbdbdb !important;">                        
                                <v-text-field
                                    v-model="item.itm5Val"
                                    color="blue" height="28"
                                    hide-details outlined dense>
                                </v-text-field>
                            </div>
                            <div class="d-flex justify-center align-center pa-0 ma-0" style="width:5%;border-left: 1px solid #dbdbdb !important;">
                                <v-checkbox
                                    class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="item.itm7Val"
                                    true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                    hide-details>
                                </v-checkbox>
                            </div>
                            <div class="d-flex justify-center align-center pa-0 ma-0" style="width:5%;border-left: 1px solid #dbdbdb !important;">
                                <v-checkbox
                                    class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="item.itm8Val"
                                    true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                    hide-details>
                                </v-checkbox>
                            </div>
                            <div class="pa-0 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;">
                                <div class="d-inline-block pl-1" style="width: 100%;">
                                    <v-radio-group
                                        v-model="item.wrtrMmbrPk" :key="forceRender"
                                        class="radio-dft-Theme mt-2 pl-2 pt-1"
                                        hide-details row>
                                        <v-radio 
                                            v-for="(list, k) in mmbrList" :key="k" 
                                            class="pa-0 mr-1"                                     
                                            :ripple="false" 
                                            :label="list.wrtr" 
                                            :value="list.wrtrpk"
                                            @click="onChecker(i,list)">
                                            <template v-slot:label>
                                                <span class="black--text">{{list.wrtr}}</span>
                                            </template>
                                        </v-radio>                                
                                    </v-radio-group>
                                </div>             
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:5%;border-left: 1px solid #dbdbdb !important;">
                                <v-btn
                                    color='grey006'
                                    @click="delRow(i)"
                                    icon>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-eraser</v-icon>
                                        </template>
                                        <span>지우기</span>
                                    </v-tooltip>
                                </v-btn>
                            </div>
                        </div>
                        <div v-if="item.srnum == '72'">
                            <div class="d-flex justify-center align-center pa-1 ma-0 excrtObsrv-blue-lighten-5" style="border-bottom: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium">※ 집중배설관찰 종료</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>          
        </v-form>
        <div class="ma-0 pa-0">
            <v-dialog v-model="menu[0]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="isModal(0)" @outEmp="getEmpInfo" :prtWrtDt="prtWrtDt"></EmpSelector>
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import { getMultiStcd } from '../../api/index.js';
import { selExcrtObsrvRecInfo, insObsExBnftOfrMultiRec } from '../../api/bnftrcrd.js';
import basicInfo from '../bnfcr/BnfcrBasicInfo.vue';
import EmpSelector from '../commons/EmpSelector.vue';

export default {
    name: 'CncntExcrtObsrvRcrdWrt',
    
    components: {  
        basicInfo,
        EmpSelector,
    },

    props : {
        sltBnftOfrRecPk : {type:Number, default:0},
        ymd             : { type: String, default: ''},
        startDt         : { type: String, default: ''},
        bnMmbrPk        : {type:Number, default:0},
    },

    computed: {
        
    },

    created: function(){ 
        this.onCodeload()  
    },

    mounted: function(){
        this.$nextTick(function () {
            this.onLoad()
        })
    },

    watch: {
        'sltBnftOfrRecPk':function(value){
            if(value > -1){                
                this.onLoad()
            }
        }
    },

    methods: {   
        onLoad(){     
            this.progress = false
            this.mmbrList.splice(0)    
            this.getExcrtObsrvRecInfo()
            // this.$refs.form.resetValidation();
        },     
        onSave:function(){
            if(this.bnMmbrPk > 0){
                if(this.$refs.form.validate()){

                    let obj = {     
                        fcltyNum                : this.$store.getters.getFcltyNum,
                        userNm                  : this.$store.getters.getUserNm,                  
                        bnMmbrPk                : this.bnMmbrPk,
                        recDt                   : this.$moment(this.rec1Items.recDt,'YYYY-MM-DD').format('YYYYMMDD'),
                        inqYMD                  : this.ymd,
                        clsfc1Cd                : '301',
                        clsfc2Cd                : '3',
                        clsfc3Cd                : '2',                        
                        list                    : [],
                        hisList                 : []
                    }


                    let hisObj = {                  
                        clsfc1Cd        : '301',
                        clsfc2Cd        : '3',
                        clsfc3Cd        : '5',
                        recDt           : this.ymd,
                        wrtrGr          : [],
                        wrtrGrMmbrPk    : [],
                        recList         : [],
                    }

                    this.rec2List.forEach(el => {
                        let rec2Item  = {   
                            excrObsrRecPk       : el.excrObsrRecPk,                   
                            clsfc1Cd            : '301',
                            clsfc2Cd            : '3',
                            clsfc3Cd            : '2',       
                            itm1Cd              : '1',
                            itm1Val             : el.itm1Val,
                            itm2Cd              : '2',
                            itm2Val             : el.itm2Val,
                            itm3Cd              : '3',
                            itm3Val             : el.itm3Val,
                            itm4Cd              : '4',
                            itm4Val             : el.itm4Val,
                            itm5Cd              : '5',
                            itm5Val             : el.itm5Val,
                            itm6Cd              : '6',
                            itm6Val             : el.itm6Val,
                            itm7Cd              : '7',
                            itm7Val             : el.itm7Val,
                            itm8Cd              : '8',
                            itm8Val             : el.itm8Val,
                            ofrTmHhStrt         : el.ofrTmHhStrt,
                            ofrTmMmStrt         : el.ofrTmMmStrt,
                            recDt               : this.ymd,
                            wrtr                : el.wrtr,
                            wrtrMmbrPk          : el.wrtrMmbrPk,
                        }

                        
                        if(el.wrtrMmbrPk > 0 && el.wrtrMmbrPk != null ){
                            obj.list.push(rec2Item)
                        }

                        if(el.itm1Val == '2' || el.itm1Val =='3' || el.itm2Val == '2' || el.itm2Val =='3'){
                            let recItem = {                             
                                ofrTmHhStrt : el.ofrTmHhStrt,
                                ofrTmMmStrt : el.ofrTmMmStrt,
                                wrtr        : el.wrtr,
                                wrtrMmbrPk  : el.wrtrMmbrPk,  
                            }

                            hisObj.recList.push(recItem)
                        }                        
                    });
                    
                    if(hisObj.recList.length > 0) obj.hisList.push(hisObj)
                    
                    if(obj.list.length > 0) {
                        this.progress = true
                        insObsExBnftOfrMultiRec(obj)
                            .then((response) => ( this.onSaveAfter(response.data) ))
                            .catch((error) => console.log('upload error /bnftrcrd/insObsExBnftOfrMultiRec : ' + error))
                    }else{                        
                        let obj = {}
                        obj.actv = true                 // 경고 노출여부
                        obj.title = '대상을 찾을 수 없습니다.'        // 경고 타이틀                
                        obj.tmr = 5000      
                        obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                        this.$store.commit('setWrnnInf', obj)
                    }
                }

            }else{                
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.title = '수급자를 선택해주세요.'        // 경고 타이틀                
                obj.tmr = 5000      
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                this.$store.commit('setWrnnInf', obj)
                
            }
        },
        onSaveAfter:function(res){            
            this.progress = false

            let obj = {}  
            obj.title = res.message         // 경고 타이틀
            obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200){                                                
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.$emit("cncntExcrtTrigger")
                this.onLoad()
            } else if(res.statusCode == 701) {                                
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = '기저귀 교체 및 확인 기록 · 담당자 시간이 중복되었습니다.'        // 경고 타이틀                
                this.$store.commit('setWrnnInf', obj)
            }
            else{
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
            }
            this.$store.commit('setWrnnInf', obj)
        },  
        getExcrtObsrvRecInfo:function(){     
            this.rec1Items.recDt            = ''
            this.rec1Items.ofrTmHhStrt      = ''
            this.rec1Items.itm1Val          = ''
            this.rec1Items.excrObsrRecPk  = 0
            this.rec2List.splice(0)

            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum,
                bnMmbrPk    : this.bnMmbrPk,                                
                clsfc1Cd    : '301',
                clsfc2Cd    : '3',
                clsfc3Cd    : '2',
                inqYMD      : this.ymd,                
            }
            
            this.progress = true
            selExcrtObsrvRecInfo(obj) //이부분부터 작업 시작
                .then((response) => ( this.getExcrtObsrvRecInfoAfter(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/selExcrtObsrvRecInfo : ' + error))
        },  
        getExcrtObsrvRecInfoAfter:function(res){  
            
            if(res.statusCode == 200){
                //소분류2 PK
                this.rec1Items.recDt            = res.result.rec1Items.recDt
                this.rec1Items.ofrTmHhStrt      = res.result.rec1Items.ofrTmHhStrt                    
                this.rec1Items.itm1Val          = res.result.rec1Items.itm1Val
                this.rec1Items.bnftOfrRec301Pk  = res.result.rec1Items.bnftOfrRec301Pk
                
                let startDate   = this.$moment(this.rec1Items.recDt+' '+this.rec1Items.ofrTmHhStrt, 'YYYYMMDD HH')
                let endDate = this.$moment(this.rec1Items.recDt+' '+this.rec1Items.ofrTmHhStrt, 'YYYY-MM-DD HH').add(71, 'hours')   


                for(let i=0;i<24;i++){

                    let obj  = {
                        class:'',
                        excrObsrRecPk:0,                    
                        ofrTmHhStrt:'',
                        ofrTmMmStrt:'',
                        srnum:'',
                        itm1Val:'',
                        itm2Val:'',
                        itm3Val:'',
                        itm4Val:'',
                        itm5Val:'',
                        itm6Val:'',
                        itm7Val:'',
                        itm8Val:'',
                        wrtrMmbrPk:0,
                        wrtr:'',                    
                    }



                    let hour = '0'+i
                    if(hour.length == 3) hour = hour.substr(1,2)                
                    
                    obj.ofrTmHhStrt = hour

                    if(res.result.rec2List.length > 0){
                        let idx = res.result.rec2List.findIndex(x=>x.ofrTmHhStrt == hour)
                        if(idx > -1){
                            
                            let data = res.result.rec2List[idx]
                            if(data != undefined){
                                obj.excrObsrRecPk = data.excrObsrRecPk
                                obj.ofrTmHhStrt     = data.ofrTmHhStrt
                                obj.ofrTmMmStrt     = data.ofrTmMmStrt
                                obj.itm1Val         = data.itm1Val
                                obj.itm2Val         = data.itm2Val
                                obj.itm3Val         = data.itm3Val
                                obj.itm4Val         = data.itm4Val
                                obj.itm5Val         = data.itm5Val
                                obj.itm6Val         = data.itm6Val
                                obj.itm7Val         = data.itm7Val
                                obj.itm8Val         = data.itm8Val
                                obj.wrtr            = data.wrtr
                                obj.wrtrMmbrPk      = data.wrtrMmbrPk
                            }

                        }
                    }

                    // let recDt2 = this.ymd.beforeDateFormatHyp() + " "+ obj.ofrTmHhStrt+":00:00" 
                    
                    if(obj.ofrTmMmStrt == null || obj.ofrTmMmStrt == '') obj.ofrTmMmStrt = '00'

                    let tm = this.$moment(this.ymd+' '+obj.ofrTmHhStrt, 'YYYYMMDD HH').unix()
                    
                    if(tm == startDate.unix()) obj.srnum = '1'

                    if(tm == endDate.unix()) obj.srnum = '72'

                    if(tm >= startDate.unix() && tm <= endDate.unix()){
                        obj.class="excrtObsrv-blue-lighten-5"
                    }

                    if(obj.wrtrMmbrPk != null && obj.wrtrMmbrPk != '' ){
                        obj.class="bathblnft-green-lighten-5"
                        this.setMmbrList(obj.wrtr, obj.wrtrMmbrPk)
                    }
                    
                    this.rec2List.push(obj)
                }

                this.preDataChk = res.result.preDataChk
                this.preData    = res.result.preData
                this.progress = false
                
            } else {                
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                this.$store.commit('setWrnnInf', obj)
            }
        },               
        onClickBtn:function(text){
            if(text=="저장"){
                this.onSave()
            }
        },
        onClose: function () {
            this.$emit('isClose', 2)
        },
        onCodeload(){            
            getMultiStcd(['67', '68'])
                .then((response) => ( this.codeMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        codeMapping(res){
            this.codeItem67.splice(0)
            this.codeItem68.splice(0)
            let obj = {
                valcd: "",
                valcdnm: "선택",
                valclcd: "",
                valclcdnm: "",
            }

            this.codeItem67.push(obj)
            this.codeItem68.push(obj)

            res.vCd67.forEach(e => {
                this.codeItem67.push(e)
            });

            res.vCd68.forEach(e => {
                this.codeItem68.push(e)
            });
            

        },
       
        getEmpInfo: function (obj) {
            this.setMmbrList(obj[0].name,obj[0].mmbrPk)
        },
        setMmbrList:function(wrtr,wrtrpk){
            let addChk = true
        
            this.mmbrList.forEach(x=>{
                if(x.wrtrpk == wrtrpk)
                addChk = false
            })

            if(addChk)
                this.mmbrList.push({wrtr:wrtr,wrtrpk:wrtrpk})

            //담당자 가,나,다 정렬
            this.mmbrList.sort(
                function (a, b) {
                    let x = a.wrtr?.toLowerCase()
                    let y = b.wrtr?.toLowerCase()

                    if (x < y)
                        return -1
                    if (x > y)
                        return 1

                    return 0
            })
        },
        delRow:function(idx){
            this.rec2List[idx].ofrTmMmStrt  = '00'
            this.rec2List[idx].itm1Val  = ''
            this.rec2List[idx].itm2Val  = ''
            this.rec2List[idx].itm3Val  = ''
            this.rec2List[idx].itm4Val  = ''
            this.rec2List[idx].itm5Val  = ''
            this.rec2List[idx].itm6Val  = ''
            this.rec2List[idx].itm7Val  = ''
            this.rec2List[idx].itm8Val  = ''
            this.rec2List[idx].wrtrMmbrPk   = 0
            this.rec2List[idx].wrtr = ''
        },
        allChecker:function(obj){
            this.rec2List.forEach(el => {
                  el.wrtr = obj.wrtr
                  el.wrtrMmbrPk = obj.wrtrpk
            });
        },
        onChecker:function(idx, obj){
            this.rec2List[idx].wrtr = obj.wrtr
        },
        onModal:function(value){
            if(value == 0) this.prtWrtDt = this.ymd
            this.menu.splice(value, 1, true)
        },
        isModal: function (value) {
            this.menu.splice(value, 1, false)
        },  
    },
    data: () => ({ 
        prtWrtDt:'',
        preDataChk:'',
        preData:'',
        forceRender:0,
        idx:0,
        items:{},   
        menu:[false,],         
        codeItem67:[],
        codeItem68:[],  
        rec1Items:{
            recDt:'',
            ofrTmHhStrt:'',
            endDate:'',
            thrTmHhEnd:'',
            itm1Val:'1',
            excrObsrRecPk:0,
        },
        bnftOfrRecHisPk:0,
        rec2List:[],
        dummyRec2List:[],
        wrtrAllMmbrPk:0,
        mmbrList:[{wrtr:'',wrtrpk:''}],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            minute: value => !(value < 0 || value > 59) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'white--text', color:'blueBtnColor', text: '저장', disabled: true,},            
        ],            
        progress:false
    }),
};
</script>
<style>
.excrtObsrv-blue-lighten-5{background-color: #F9FBE7 !important;}
</style>