<template>
    <v-sheet class="ma-0 pa-7" rounded="md">
        <v-row class="mb-4 pa-0" no-gutters>
            <v-col cols="4">
                <span class="text-h4 font-weight-bold">고객센터</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="1">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </v-col>
        </v-row>
        <v-row class="mb-4 pa-0" no-gutters>
            <v-col cols="12">
                <v-tabs
                    v-model="tab" class="tab-dft-Theme" active-class="black"
                    center-active hide-slider>
                    <v-tab class="fmlySend" v-for="item in tabItems" :key="item.tab" :class="item.class" :disabled="item.enter">
                        <v-icon>{{item.icon}}</v-icon>
                        <span class="pl-2">{{item.tab}}</span>
                    </v-tab>
                </v-tabs>
                <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
                <div class="mt-3" style="height:550px;">
                    <v-tabs-items v-model="tab">
                        <v-tab-item transition="fade-transition">
                            <YysAllNotice v-if="tab==0"></YysAllNotice>
                        </v-tab-item>
                        <v-tab-item transition="fade-transition">                            
                            <YysNotice v-if="tab==1"></YysNotice>
                        </v-tab-item>
                        <v-tab-item transition="fade-transition">
                            <YysFaq v-if="tab==2"></YysFaq>
                        </v-tab-item>
                        <v-tab-item transition="fade-transition">
                            <YysQna v-if="tab==3"></YysQna>
                        </v-tab-item>
                    </v-tabs-items>
                </div>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import YysAllNotice from './YysAllNotice.vue';
import YysNotice from './YysNotice.vue';
import YysFaq from './YysFaq.vue';
import YysQna from './YysQna.vue';

export default {
    name: 'YysBbsMgmt',

    props : {
        sltTab:{type:Number, default: 0}
    },
        
    components: {
        YysAllNotice,
        YysNotice,
        YysFaq,
        YysQna,
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.sltTab > 0){
                this.tab = this.sltTab
            }
        })
    },

    computed: {

    },

    watch:{

    },
    
    methods: { 
        onClose: function () {
            this.tab = -1
            this.$emit('modalClose', '1')
        },
    },

    data: () => ({
        tab:0,
        tabItems: [
            { index: 0, tab: '공지사항', class: 'fontOneDotTwoRem px-15', enter: false, icon:'mdi-microphone' },
            { index: 1, tab: '기관 공지사항', class: 'fontOneDotTwoRem px-15', enter: false, icon:'mdi-bullhorn'  },
            { index: 2, tab: '자주 묻는 질문', class: 'fontOneDotTwoRem px-15', enter: false, icon:'mdi-message'  },
            { index: 3, tab: '1:1 문의하기', class: 'fontOneDotTwoRem px-15', enter: false, icon:'mdi-chat-question'  },
        ],
    }),
};
</script>