<template>
    <v-sheet class="ma-0" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <span class="text-h4 font-weight-bold">보조금&nbsp;{{ bscsInf.ttl }}</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="$parent.$parent.$parent.$parent.mdlOff" x-large>mdi-close</v-icon>
        </v-row>
        <v-row class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">기존 정보</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">수급자</span>
            </v-col>
            <v-col class="px-4 py-1" cols="9">
                <span class="mr-2">{{ bscsInf.name }}</span>
                &#40;
                <span v-if="bscsInf.gndr == 'M'">남</span>
                <span v-else-if="bscsInf.gndr == 'F'">여</span>
                <span class="ml-1">/ 만 {{ bscsInf.age }}세</span>
                &#41;
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">보조금</span>
            </v-col>
            <v-col class="px-4 py-1" cols="9">
                <v-btn
                    class="crsShp-Default white--text mr-1" style="padding: 9px 7px 8px 5px !important;"
                    color="blueBtnColor" :ripple="false" :disabled="bscsInf.nowSnackSprtYn == '2'"
                    min-width="20" height="20" small outlined>
                    <span class="fontNineThRem" style="padding-bottom: 2px;">간식비</span>
                </v-btn>
                <v-btn
                    class="crsShp-Default white--text" style="padding: 9px 7px 8px 5px !important;"
                    color="blueBtnColor" :ripple="false" :disabled="bscsInf.nowMealSprtYn == '2'"
                    min-width="20" height="20" small outlined>
                    <span class="fontNineThRem" style="padding-bottom: 2px;">식사재료비</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <div
            v-if="bscsInf.bnfcrCntrcInfPk > 0"
            class="brdSurrd mt-6 mr-1 px-6 py-2"
            style="background-color: #f4f4f4 !important;">
            <p class="fontOneDotOneRem red--text mb-1">
                ※ 보조금 변경 적용기간에 계약건이 존재합니다.
            </p>
            <p class="fontOneRem grey006--text ma-0 pl-5" style="line-height: 1.2;">
                해당 계약의 계약일자: {{ bscsInf.cntrcDtNms }}
                <br>
                변경된 보조금은 표준약관에 반영됩니다.
            </p>
        </div>
        <v-row class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">변경 정보</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="3">
                <span class="font-weight-medium posn-rltv">
                    적용시작일
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="d-flex align-center px-4" cols="9">
                <span class="d-inline-block" style="width: 134px; padding-top: 1px;">
                    <CmmDateComp
                        v-model="bscsInf.aplStrt"
                        :required="true" :minDt="bscsInf.entDt" :maxDt="bscsInf.lvDt"
                        @input="aplctSavBtn">
                    </CmmDateComp>
                </span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 tableBR d-flex align-center justify-center" cols="3">
                <span class="font-weight-medium posn-rltv">
                    보조금
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="px-4 py-1" cols="9">
                <v-btn
                    class="white--text mr-2" style="padding: 9px 7px 8px 5px !important;"
                    :color="bscsInf.snackSprtYn ? 'blueBtnColor' : 'greyC00'"
                    @click="bscsInf.snackSprtYn = !bscsInf.snackSprtYn"
                    min-width="20" height="20" small outlined>
                    <span class="fontNineThRem" style="padding-bottom: 2px;">간식비</span>
                </v-btn>
                <v-btn
                    class="white--text" style="padding: 9px 7px 8px 5px !important;"
                    :color="bscsInf.mealSprtYn ? 'blueBtnColor' : 'greyC00'"
                    @click="bscsInf.mealSprtYn = !bscsInf.mealSprtYn"
                    min-width="20" height="20" small outlined>
                    <span class="fontNineThRem" style="padding-bottom: 2px;">식사재료비</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 tableBR d-flex align-center justify-center" cols="3">
                <span class="font-weight-medium posn-rltv">
                    변경사유
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="px-4 py-1" cols="9">
                <v-text-field
                    v-model="bscsInf.mdfRsn" @input="aplctSavBtn"
                    class="" height="28" color="blue"
                    hide-details outlined dense>
                </v-text-field>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-divider class="white my-2"></v-divider>
        <v-row class="pa-0 justify-center" no-gutters>
            <v-btn
                class="" color="blueBtnColor" min-width="110" height="36"
                :disabled="savBtn" @click="dcsnSavBtn">
                <span class="white--text fontOneDotTwoRem">저장</span>
            </v-btn>
        </v-row>
        <v-divider class="white mt-1"></v-divider>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

import CmmDateComp from '../../commons/CmmDateComp.vue';

export default {
    name: 'GrantsSavMdl',

    components: {
        CmmDateComp,
    },

    props : {

    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            let obj = this.$store.getters.getSubInObj
            this.aplctInf(obj)
        })
    },

    computed: {
      
    },

    watch: {
        
    },

    methods:{
        // 보조금 신규 등록
        setGrantsInf: function (obj){
            this.savBtn = true

            http.post('bnfcrAuth/insBnfcrGvrnmSprt', obj)
                .then((response) => ( this.insGrantsRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/insBnfcrGvrnmSprt : ' + error))
        },

        insGrantsRst: function (res){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == '200')
                this.savBtn = false
            else
                obj.type = 'warning'

            this.$store.commit('setWrnnInf', obj)
        },

        // 보조금 수정
        setGrantsUpd: function (obj){
            this.savBtn = true

            http.post('bnfcrAuth/updBnfcrGvrnmSprt', obj)
                .then((response) => ( this.insGrantsRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/updBnfcrGvrnmSprt : ' + error))
        },

        // 기본정보 적용
        aplctInf: function (obj) {
            this.grantsHisList = obj.grantsHisList

            this.bscsInf.bnMmbrPk = obj.bnMmbrPk
            this.bscsInf.bnfcrPk = obj.bnfcrPk
            this.bscsInf.name = obj.name
            this.bscsInf.gndr = obj.gndr
            this.bscsInf.age = obj.age
            this.bscsInf.nowSnackSprtYn = obj.isNowObj?.snackSprtYn
            this.bscsInf.nowMealSprtYn = obj.isNowObj?.mealSprtYn
            this.bscsInf.entDt = this.$moment(obj.entDt, 'YYYYMMDD').format('YYYY-MM-DD')
            if(obj.lvDt == null || obj.lvDt == '')
                this.bscsInf.lvDt = '2099-12-31'
            else
                this.bscsInf.lvDt = this.$moment(obj.lvDt, 'YYYYMMDD').format('YYYY-MM-DD')
            this.bscsInf.ttl = obj.subTtl

            if(obj.subTtl == '정보 수정'){
                this.bscsInf.bnfcrGvrnmSprtPk = obj.bnfcrGvrnmSprtPk
                this.bscsInf.bnfcrCntrcInfPk = obj.bnfcrCntrcInfPk
                this.bscsInf.cntrcDtNms = obj.cntrcDtNms
                this.bscsInf.aplStrt = this.$moment(obj.aplStrt, 'YYYYMMDD').format('YYYY-MM-DD')
                if(obj.snackSprtYn == '1') this.bscsInf.snackSprtYn = true
                else this.bscsInf.snackSprtYn = false
                if(obj.mealSprtYn == '1') this.bscsInf.mealSprtYn = true
                else this.bscsInf.mealSprtYn = false
                this.bscsInf.mdfRsn = obj.mdfRsn
            }
            else{
                delete this.bscsInf.bnfcrGvrnmSprtPk
                this.rstFld()
            }

            this.aplctSavBtn()
        },

        // 저장버튼 활성 여부 체크
        aplctSavBtn: function () {
            this.savBtn = true

            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.bscsInf.aplStrt) == false)
                return 0
            if(this.bscsInf.mdfRsn == '')
                return 0

            this.savBtn = false
        },

        // 저장버튼 분기
        dcsnSavBtn: function () {
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = '변경할 정보를 입력해주세요.'     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(this.bscsInf.aplStrt == '' || this.bscsInf.mdfRsn == ''){
                this.$store.commit('setWrnnInf', obj)
                return 0
            }

            let tmpDt = this.$moment(this.bscsInf.aplStrt, 'YYYY-MM-DD')
            let chc = this.grantsHisList.filter( v => v.aplStrt == tmpDt.format('YYYYMMDD') )
            if(chc.length > 0 && this.$store.getters.getSubInObj.aplStrt != tmpDt.format('YYYYMMDD') ){
                obj.title = '중복된 적용시작일이 존재합니다.'
                obj.type = 'warning'
                this.$store.commit('setWrnnInf', obj)
                return 0
            }

            if( tmpDt.isBefore(this.$moment(this.bscsInf.entDt, 'YYYY-MM-DD'))
                || tmpDt.isAfter(this.$moment(this.bscsInf.lvDt, 'YYYY-MM-DD')) ){
                    obj.title = '적용시작일은 입소일 이후 퇴소일 이전만 입력 가능합니다.'
                    obj.type = 'warning'
                    this.$store.commit('setWrnnInf', obj)
                    return 0
            }

            let insObj = Object.assign({}, this.bscsInf)
            insObj.aplStrt = this.$moment(insObj.aplStrt, 'YYYY-MM-DD').format('YYYYMMDD')
            if(insObj.snackSprtYn) insObj.snackSprtYn = '1'
            else insObj.snackSprtYn = '2'
            if(insObj.mealSprtYn) insObj.mealSprtYn = '1'
            else insObj.mealSprtYn = '2'

            if(this.bscsInf.ttl == '신규 등록')
                this.setGrantsInf(insObj)
            else if(this.bscsInf.ttl == '정보 수정')
                this.setGrantsUpd(insObj)
        },

        // 입력필드 초기화
        rstFld: function () {
            this.bscsInf.aplStrt = ''
            this.bscsInf.mealSprtYn = false
            this.bscsInf.snackSprtYn = false
            this.bscsInf.mdfRsn = ''
        }
    },

    data: () => ({
        savBtn: true,
        sltPrd: '1',
        bscsInf: {
            bnMmbrPk: -1, bnfcrPk: -1, name: '', gndr: '', age: '', entDt: '', lvDt: '',
            nowMealSprtYn: '2', nowSnackSprtYn: '2', bnfcrCntrcInfPk: -1, cntrcDtNms: '',
            aplStrt: '', mealSprtYn: false, snackSprtYn: false, mdfRsn: '', ttl: '',
        },
        grantsHisList: [],
    })
}
</script>