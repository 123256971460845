<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <v-row class="pa-0 justify-left" no-gutters>
            <span class="text-h4 font-weight-bold">유급휴일&nbsp;작성</span>
            <span class="ml-3" style="padding-top: 7px;">
                <v-btn
                    class="" width="30" height="30"
                    @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 30)"
                    icon>
                    <v-icon class="pb-1" size="30">mdi-help-circle-outline</v-icon>
                </v-btn>
            </span>
            <v-spacer></v-spacer>
            <v-icon @click="onClose()" color="black" x-large>mdi-close</v-icon>
        </v-row>
        <v-row class="pa-0 mt-5 align-center" no-gutters>
            <v-icon style="font-size: 26px; width: 8px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold ml-3">근무정보</span>
        </v-row>
        <v-divider class="mt-2"></v-divider>
        <v-row class="pa-0 greyE01" no-gutters>
            <v-col class="textCenter tableBR py-1" cols="3">
                <span class="font-weight-bold" style="font-size: 1.1rem;">근무일자</span>
            </v-col>
            <v-col class="textCenter tableBR py-1" cols="3">
                <span class="font-weight-bold" style="font-size: 1.1rem;">근무시간</span>
            </v-col>
            <v-col class="textCenter tableBR py-1" cols="3">
                <span class="font-weight-bold" style="font-size: 1.1rem;">휴게시간&nbsp;&#40;&nbsp;주,&nbsp;야&nbsp;&#41;</span>
            </v-col>
            <v-col class="textCenter py-1" cols="3">
                <span class="font-weight-bold" style="font-size: 1.1rem;">실근무시간</span>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="textCenter tableBR py-1" cols="3">
                <span class="font-weight-medium fontOneRem">{{ $moment(wrkHldyInf.wrkDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
            </v-col>
            <v-col class="textCenter tableBR py-1" cols="3">
                <span class="font-weight-medium fontOneRem">{{ wrkHldyInf.wrkTmHhStrt }}</span>
                <span class="font-weight-medium fontOneRem">:</span>
                <span class="font-weight-medium fontOneRem">{{ wrkHldyInf.wrkTmMmStrt }}</span>
                <span class="mx-1">
                    <v-icon class="black--text" style="margin-bottom: 1px;" size="16">mdi-tilde</v-icon>
                </span>
                <span class="font-weight-medium fontOneRem">{{ wrkHldyInf.wrkTmHhEnd }}</span>
                <span class="font-weight-medium fontOneRem">:</span>
                <span class="font-weight-medium fontOneRem">{{ wrkHldyInf.wrkTmMmEnd }}</span>
            </v-col>
            <v-col class="textCenter tableBR py-1" cols="3">
                <span class="font-weight-medium fontOneRem">{{ wrkHldyInf.brkDay }}분,&nbsp;</span>
                <span class="font-weight-medium fontOneRem">{{ wrkHldyInf.brkNgt }}분</span>
            </v-col>
            <v-col class="textCenter py-1" cols="3">
                <span class="font-weight-medium fontOneRem">{{ Math.floor(wrkHldyInf.actWrkHr / 60) }}시간&nbsp;</span>
                <span class="font-weight-medium fontOneRem">{{ wrkHldyInf.actWrkHr % 60 }}분</span>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row class="pa-0 mt-5 align-center" no-gutters>
            <v-icon style="font-size: 26px; width: 8px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold ml-3">유급휴일&nbsp;대체정보</span>
        </v-row>
        <v-form ref="hldyFrm" lazy-validation>
            <v-divider class="mt-2"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 textCenter py-1" cols="2">
                    <span class="font-weight-bold" style="font-size: 1.1rem;">이월가능시간</span>
                </v-col>
                <v-col class="textCenter tableBL tableBR py-1" cols="2">
                    <span
                        v-if="( wrkHldyInf.wrtnWrkHr - wrkHldyInf.stdWrkHr ) / 60 > 7"
                        class="font-weight-medium fontOneRem">
                        8시간
                    </span>
                    <span
                        v-else
                        class="font-weight-medium fontOneRem">
                        {{ paidHldyFrm.hldyLmtHh }}시간&nbsp;
                        {{ paidHldyFrm.hldyLmtMm }}분
                    </span>
                </v-col>
                <v-col class="greyE01 textCenter py-1" cols="2">
                    <span class="font-weight-bold" style="font-size: 1.1rem;">이월시간</span>
                </v-col>
                <v-col class="tableBL tableBR px-1" cols="2">
                    <span class="white d-inline-block" style="width: 62px; margin-top: 2px; margin-bottom: 2px;">
                        <CmmTimeField v-model="paidHldyFrm.hldyTm" :maxTm="paidHldyFrm.hldyLmtTm" :required="true" focusClear></CmmTimeField>
                    </span>
                </v-col>
                <v-col class="greyE01 textCenter py-1" cols="2">
                    <span class="font-weight-bold" style="font-size: 1.1rem;">작성자</span>
                </v-col>
                <v-col class="tableBL px-1" style="padding-top: 2px;" cols="2">
                    <span class="d-inline-block" style="width: 66px;">
                        <v-text-field
                            class="numArea mr-1" height="28"
                            v-model="paidHldyFrm.wrtr"
                            :rules="[rules.required]"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <v-btn 
                        class="mb-1 brown lighten-3"
                        style="padding: 0px 1px 0px 2px !important;"
                        min-width="30" height="25" @click="empSltMdl = true"
                        dark small>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                            </template>
                            <span>직원선택</span>
                        </v-tooltip>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex align-center justify-center" cols="2">
                    <span class="font-weight-bold" style="font-size: 1.1rem;">비고</span>
                </v-col>
                <v-col class="tableBL pa-1" cols="10">
                    <v-text-field
                        class="numArea mr-1" height="28"
                        v-model="paidHldyFrm.rmrks"
                        hide-details outlined dense>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
        </v-form>
        <v-row class="pa-0 mt-5 justify-center" no-gutters>
            <v-btn
                class="white--text" height="30" color="blueBtnColor"
                style="padding: 0 12px 0px 12px !important;"
                @click="preVctnSbst()"
                :disabled="savBtn"
                rounded samll>
                <v-icon style="margin-top: 1px;" small>mdi-content-save-outline</v-icon>
                <span class="fontOneRem ml-1" style="margin-bottom: 1px;">저장</span>
            </v-btn>
        </v-row>
        <v-dialog v-model="empSltMdl" content-class="round" max-width="800" persistent eager>
            <EmpSelector @isClose="empSltMdl = false" @outEmp="getEmpInfo"></EmpSelector>
        </v-dialog>
      
    </v-sheet>
</template>

<script>
import { insVctnSbst } from '../../api/emp.js';

import CmmTimeField from '../commons/CmmTimeField.vue';
import EmpSelector from '../commons/EmpSelector.vue';

export default {
    name: 'PaidHldyMdl',

    props : {
        wrkHldyInf: { type: Object, default: Object },
    },
        
    components: {
        CmmTimeField,
        EmpSelector,
    },

    created: function(){
        
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if((this.wrkHldyInf.wrtnWrkHr - this.wrkHldyInf.stdWrkHr) > 480){
                this.paidHldyFrm.hldyLmtHh = '08'
                this.paidHldyFrm.hldyLmtMm = '00'
            }
            else{
                this.paidHldyFrm.hldyLmtHh = ('00' + (( this.wrkHldyInf.wrtnWrkHr - this.wrkHldyInf.stdWrkHr ) / 60)).slice(-2)
                this.paidHldyFrm.hldyLmtMm = ('00' + (( this.wrkHldyInf.wrtnWrkHr - this.wrkHldyInf.stdWrkHr ) % 60)).slice(-2)
            }
            this.paidHldyFrm.hldyLmtTm = this.paidHldyFrm.hldyLmtHh + ':' + this.paidHldyFrm.hldyLmtMm
            this.paidHldyFrm.hldyTm = this.paidHldyFrm.hldyLmtHh + ':' + this.paidHldyFrm.hldyLmtMm
            this.paidHldyFrm.wrtr = this.$store.getters.getUserNm
        })
    },

    computed: {

    },

    watch:{

    },
    
    methods: {
        //유급휴일 이월
        setVctnSbst: function (obj) {
            insVctnSbst(obj)
                .then((response) => ( this.asyncRst(response.data) ))
                .catch((error) => console.log('connect error /emp/insWrkPtn : ' + error))
        },

        asyncRst: function (res) {
            if(res.statusCode == 200){
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])
                this.$parent.$parent.$parent.$parent.rclngWrkShdl()
                this.onClose()
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },

        preVctnSbst: function () {
            let tmpTm = this.paidHldyFrm.hldyTm.split(':', 2)
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.empPk = this.wrkHldyInf.empPk
            obj.mmbrPk = this.wrkHldyInf.mmbrPk
            obj.vctnWrkHr = Number(tmpTm[0]) * 60 + Number(tmpTm[1])
            obj.vctnSbstDt = this.wrkHldyInf.wrkDt
            obj.userNm = this.$store.getters.getUserNm
            obj.wrtr = this.paidHldyFrm.wrtr
            obj.rmrks = this.paidHldyFrm.rmrks

            if(this.$refs.hldyFrm.validate())
                this.setVctnSbst(obj)
            else
                this.snackControll([true, 2500, '필수 항목을 입력해주세요.', 'info'])
        },

        getEmpInfo: function (obj) {
            this.paidHldyFrm.wrtr = obj[0].name
        },
    
        onClose: function () {
            this.$emit('isClose', '.')
        },

        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        savBtn: false,
        empSltMdl: false,
        paidHldyFrm: {
            hldyLmtHh: '', hldyLmtMm: '', hldyLmtTm: '', hldyTm: '', rmrks: '', wrtr: '',
        },
        rules: {
            required: value => !!value || '필수 입력 항목입니다.',
        },
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>