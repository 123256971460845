<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <v-col cols="5">
                <span class="text-h4 font-weight-bold">{{ mdlTtl }}</span>                
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex justify-right" cols="4">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </v-col>
        </v-row>
        <v-row class="mt-4 pa-0" no-gutters>
            <v-spacer></v-spacer>
            <span v-if="progress">
                <v-progress-circular            
                :size="30"
                color="primary"
                indeterminate
                ></v-progress-circular>
            </span>
            <span v-if="!progress">
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn :class="list.class" height="30" :color="list.color"
                        :disabled="list.disabled" @click="onClickBtn(i)" samll rounded>
                        <v-icon small>{{list.icon}}</v-icon>
                        <span style="font-size: 1rem;">{{list.text}}</span>
                    </v-btn>
                </span>
            </span>
        </v-row>
        
        <v-row class="mt-4 pa-0" no-gutters>
            <v-col class="d-flex justify-start align-center" cols="12">
                <span class="black--text font-weight-medium">제목</span>
            </v-col>
        </v-row>
        <v-row class="pa-0" no-gutters>
            <v-col class="pa-1" cols="12">                   
                <v-text-field v-model="bbsInf.bbsTitle" height="28" hide-details outlined dense></v-text-field>
            </v-col>                    
        </v-row>
        <v-row class="pa-0" no-gutters>
            <v-col class="" cols="12">                   
                <span class="d-inline-block py-1" style="width: 80px;">
                    <v-checkbox
                        v-model="bbsInf.bbsNotiYn"
                        class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                        :ripple="false" label="공지" value="1" hide-details>
                    </v-checkbox>
                </span>
            </v-col>                    
        </v-row>
        <div >
            <v-row class="mt-4 pa-0" no-gutters>
                <v-col class="d-flex justify-start align-center" cols="12">
                    <span class="black--text font-weight-medium">내용</span>
                </v-col>
            </v-row>
            <v-row class="pa-0" no-gutters>            
                <v-col class="pa-1 overflow-y-auto" style="height:400px;" cols="12">                   
                    <div>
                        <TipTapEditor @editorToHtml="getTipTapHtml" @editorToJson="getTipTapJson" :editorCnt="cntnt" ></TipTapEditor>
                    </div>
                </v-col>                    
            </v-row>
        </div>
        <v-row class="mt-4 pa-0" no-gutters>
            <v-col class="d-flex justify-start align-center" cols="12">
                <span class="black--text font-weight-medium">첨부파일</span>
                <span class="d-inline-block ml-1" style="padding-top: 2px;">
                    <v-file-input
                        class="athFileInput" v-model="files" truncate-length="5" @change="upFile()"                            
                        multiple prepend-icon="mdi-upload" counter hide-input outlined dense hide-details>
                    </v-file-input>
                </span>
            </v-col>
        </v-row>
        <v-row class="pa-0" no-gutters>           
            <v-col class="pa-1" cols="9">                   
                <div class="overflow-y-auto" style="width:100%;height:50px;">                      
                    <v-chip v-for="(item, j) in fileList" :key="j"                        
                        label                        
                        close
                        class="ma-1"
                        outlined
                        @click="onDownload(j)"
                        @click:close="isDelete(j)"
                    >
                        <v-icon v-if="item.ext=='pdf' || item.ext=='PDF'" color='pink' left>
                            mdi-file-pdf-box
                        </v-icon>
                        <v-icon v-else-if="item.ext=='xlsx' || item.ext=='xls'" color='green' left>
                            mdi-microsoft-excel
                        </v-icon>
                        <v-icon v-else-if="item.ext=='pptx' || item.ext=='ppt'" color='red' left>
                            mdi-file-powerpoint
                        </v-icon>
                        <v-icon v-else-if="item.ext=='doc' || item.ext=='docx'" color='blue' left>
                            mdi-file-word
                        </v-icon>
                        <v-icon v-else color='cyan' left>
                            mdi-file-document
                        </v-icon>
                        {{ item.bbsFileOrgNm }}
                    </v-chip> 
                </div>
            </v-col>                    
        </v-row>
    </v-sheet>
</template>

<script>
import TipTapEditor from '../../components/commons/TipTapEditor.vue';
import { selHomBbsInfo, selHomBbsFileList, tmpHomBbsUpload, homFileDownload, insHomBbs, delHomBbsFile } from '../../api/hom.js';

export default {
    name: 'HomBbsWrtMdl',

    props : {
        mdlTtl      : { type: String, default: '게시판 저장' },
        bbsId       : { type: String, default: 'hmpgnot' },
        bbsPk       : { type: Number, default: 0 },
    },
        
    components: {
        TipTapEditor
    },

    created: function(){
        this.tiptapValue = ''
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {            
            if(this.bbsPk > 0) {
                this.bbsInf.bbsPk = this.bbsPk
                this.preGetHomBbsInfo()
                this.getHomBbsFileList()
            }
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch:{
        getSubEvt: function (bol) {
            if(bol && this.rmBbsFile){
                this.onDelete()
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.rmBbsFile = false
            }
        },
        'cntnt': function () {
            this.tiptapValue = this.cntnt
        },
    },
    
    methods: {
        onClose: function () {
            this.$emit('onClose', '0')
        },
        onClickBtn(key){
            switch (key) {
                case 0:
                    this.preInsHomBbs()
                    break;
            
                default:
                    break;
            }
        },

        preInsHomBbs:function(){
            let obj = {
                fcltyNum            : this.$store.getters.getFcltyNum,
                userNm              : this.$store.getters.getUserNm,
                bbsPk               : this.bbsInf.bbsPk,
                bbsTitle            : this.bbsInf.bbsTitle,
                bbsCntnt            : this.tiptapValue,
                bbsCntntHtml        : this.tiptapValue,
                bbsNotiYn           : (this.bbsInf.bbsNotiYn == '1')?this.bbsInf.bbsNotiYn:'2',
                files               : [],
            }
            
            if(this.fileList.length > 0){
                this.fileList.forEach(el => {
                    if(el.bbsFilePk == 0) obj.files.push(el)
                });
            }
            this.progress = true
            this.setInsHomBbs(obj)
        },

        preGetHomBbsInfo:function(){
            let obj = {
                fcltyNum            : this.$store.getters.getFcltyNum,
                bbsPk               : this.bbsInf.bbsPk,
            }

            this.getHomBbsInfo(obj)
        },

        upFile:function(){
            let formData = new FormData()
            let len      = 0
            
            if(this.files != null){
                this.files.forEach(element => {
                    formData.append("files", element)
                    len++
                })
                
            }

            if(len > 0){
                this.setTmpHomBbsUpload(formData)
            }
        },

        
        onDownload:function(idx){
            let obj = {
                bbsFilePk:0,
                bbsFilePth:'',
                bbsFileNm:'',
                bbsFileOrgNm:'',
            }
            obj.bbsFilePk       =  this.fileList[idx].bbsFilePk
            obj.bbsFilePth      =  this.fileList[idx].bbsFilePth
            obj.bbsFileNm       =  this.fileList[idx].bbsFileNm
            obj.bbsFileOrgNm    =  this.fileList[idx].bbsFileOrgNm
            
            this.getHomFileDownload(obj)
        },

        isDelete:function(idx){
            this.sltFile.bbsFilePk      = this.fileList[idx].bbsFilePk
            this.sltFile.bbsFilePth     = this.fileList[idx].bbsFilePth
            this.sltFile.bbsFileNm      = this.fileList[idx].bbsFileNm
            this.sltFile.bbsFileOrgNm   = this.fileList[idx].bbsFileOrgNm

            if(this.sltFile.bbsFilePk > 0){
                
                let obj = {}
                obj.icon = 'mdi-alert'
                obj.iconClr = 'red'
                obj.title = '첨부파일'
                obj.cntnt = ['첨부파일 '+this.fileList[idx].bbsFileOrgNm+'을(를) 삭제하시겠습니까?']

                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 삭제하겠습니다.'

                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });

                this.rmBbsFile = true

            } else {
                this.fileList.splice(idx,1)
            }   
        },

        onDelete :function(){
            if(this.sltFile.bbsFilePk > 0){

                let obj = {
                    fcltyNum        : this.$store.getters.getFcltyNum,
                    userNm          : this.$store.getters.getUserNm,
                    bbsFilePk       : this.sltFile.bbsFilePk
                }

                this.setDelHomBbsFile(obj)
            }else{
                let obj = {}
                obj.actv = true  
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = '대상을 찾을 수 없습니다.'         // 경고 타이틀
                this.$store.commit('setWrnnInf', obj)
            }
        },

        getHomBbsInfo:function(obj){
            selHomBbsInfo(obj)
                .then((response) => ( this.getHomBbsInfoAfter(response.data) ))
                .catch((error) => console.log('upload error /hominf/selHomBbsInfo : ' + error))
        },
        
        setInsHomBbs:function(obj){
            insHomBbs(obj)
                .then((response) => ( this.setInsHomBbsAfter(response.data) ))
                .catch((error) => console.log('upload error /hominf/insHomBbs : ' + error))
        },

        setTmpHomBbsUpload:function(formData){
            tmpHomBbsUpload(formData)
                .then((response) => ( this.setTmpHomBbsUploadAfter(response.data) ))
                .catch((error) => console.log('upload error /hominf/tmpHomBbsUpload : ' + error))
        },

        getHomFileDownload:function(obj){
            homFileDownload(obj)
                .then((response) => ( this.getHomFileDownloadAfter(response) ))
                .catch((error) => console.log('upload error /hominf/homFileDownload : ' + error))
        },

        setDelHomBbsFile:function(obj){
            delHomBbsFile(obj)
                .then((response) => ( this.setDelHomBbsFileAfter(response.data) ))
                .catch((error) => console.log('upload error /hominf/delHomBbsFile : ' + error))
        },

        
        getHomBbsFileList:function(){
            this.fileList.splice(0)

            let obj = {
                fcltyNum            : this.$store.getters.getFcltyNum,
                bbsPk               : this.bbsInf.bbsPk,
            }

            selHomBbsFileList(obj)
                .then((response) => ( this.getHomBbsFileListAfter(response.data) ))
                .catch((error) => console.log('upload error /hominf/selHomBbsFileList : ' + error))
        },

        getHomBbsInfoAfter:function(res){
            let obj = {}
            obj.actv = true   
            
            if(res.statusCode == 200){     
                if(res.result != null){
                    this.bbsInf.bbsTitle            = res.result.bbsTitle
                    this.bbsInf.bbsCntnt            = res.result.bbsCntnt
                    this.bbsInf.bbsCntntHtml        = res.result.bbsCntntHtml
                    this.bbsInf.bbsNotiYn           = res.result.bbsNotiYn

                    this.cntnt = res.result.bbsCntntHtml
                }
                
            }
            else{                
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
                this.$store.commit('setWrnnInf', obj)
            }

        },

        setInsHomBbsAfter:function(res){
            this.progress = false
            let obj = {}
            obj.actv = true   
            
            if(res.statusCode == 200){                                                         
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!) 
                this.$emit('modalEvent','99')
                this.preGetHomBbsInfo()
                this.getHomBbsFileList()
            }
            else{                
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
            }

            this.$store.commit('setWrnnInf', obj)
        },

        setTmpHomBbsUploadAfter:function(res){
            this.progress = false
            let obj = {}
            obj.actv = true   
            
            if(res.statusCode == 200){                                               
                             
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)   

                if(res.files != null && res.files.length > 0){
                    res.files.forEach(el => {
                        el.bbsFilePk = 0 
                        this.fileList.push(el)
                    });                    
                }

                this.files = null

            }
            else{                
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
                this.$store.commit('setWrnnInf', obj)
            }
            this.key = ''
        },

        getHomFileDownloadAfter:function(res){
            
            let contentDisposition = decodeURI(res.headers['content-disposition'])
       
            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'));
                if (fileNameMatch)
                    [ , fileName ] = fileNameMatch.split('=');
            }
            link.href = url;
 
            fileName = fileName.replaceAll('"', '')
            link.setAttribute('download', `${fileName}`);
            link.style.cssText = 'display:none';
            document.body.appendChild(link);
            link.click();
            link.remove();
        },

        setDelHomBbsFileAfter:function(res){
            let obj = {}
            obj.actv = true   
            
            if(res.statusCode == 200){                                         
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)     
                this.getHomBbsFileList()            
            }
            else{                
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
            }

            this.$store.commit('setWrnnInf', obj)
        },

        getHomBbsFileListAfter:function(res){
            let obj = {}
            obj.actv = true   
            
            if(res.statusCode == 200){                                         
                if(res.result != null){
                    res.result.forEach(el => {
                        this.fileList.push(el)
                    });
                }
            }
            else{                
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
                this.$store.commit('setWrnnInf', obj)
            }
        },

        getTipTapHtml: function (html) {            
            this.tiptapValue = html
        },
        getTipTapJson: function (json) {
            this.tiptapJSON = json
        },
        
    },

    data: () => ({
        progress:false,
        rmBbsFile:false,
        cntnt: '',
        tiptapValue:'',
        tiptapJSON:'',
        bbsInf:{
            bbsPk:0,
            bbsId:'',
            bbsTitle:'',
            bbsCntnt:'',
            bbsCntntHtml:'',
            bbsNotiYn:'',
        },
        sltFile:{
            bbsFilePk:0,
            bbsPk:0,
            bbsFilePth:'',
            bbsFileNm:'',
            bbsFileOrgNm:'',
        },
        files:null,
        fileList:[],
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'white--text', color:'blueBtnColor', text: '저장', disabled: false, }, 
        ],
    }),
};
</script>