<template>
    <v-sheet>
        <v-form ref="tabform" lazy-validation>
            <div class="pa-0 ma-0">
                <v-row class="pa-0 ma-0" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1">
                        <span class="black--text font-weight-medium">조치자명</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="5">
                        <span class="d-inline-block" style="width: 100px;">
                            <v-text-field
                                v-model="items.worker" color="blue" ref="info" height="28"
                                :rules="[rules.required, rules.strCheck]" :key="forceRender"
                                hide-details outlined readonly dense>
                            </v-text-field>
                            </span>
                            <v-btn 
                                class="ml-1 brown lighten-3" 
                                small 
                                min-width="30"
                                height="26"  
                                style="padding:0px 1px 0px 2px !important"
                                @click="onModal(0)"
                                >
                                <v-icon size="20" class="white--text">mdi-account-edit</v-icon>
                            </v-btn>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1" style="border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">조치일자</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 pl-2 ma-0" cols="5" style="border-left: 1px solid #dbdbdb !important;">
                        <span class="white d-inline-block" style="width: 154px;">
                            <CmmDateComp v-model="items.wrkDt" :required="true"></CmmDateComp>                                  
                        </span>
                    </v-col>    
                </v-row>
                <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1">
                        <span class="black--text font-weight-medium">조치내용</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="11">
                        <v-textarea v-model="items.wrkCntnt" class="rmrksArea" style="font-size:0.9rem !important;"
                                rows="30" outlined dense hide-details no-resize>
                        </v-textarea>                       
                    </v-col>
                     
                </v-row>
            </div>
        </v-form>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="isModal(0)" @outEmp="getEmpInfo" :prtWrtDt="sltDate"></EmpSelector>
            </v-dialog>  
        </div>
    </v-sheet>
</template>
<script>
import EmpSelector from '../commons/EmpSelector.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';
import { insMscldChckpDtl } from '../../api/emp.js';
export default {
    name: 'MsclsSystmChkWrkTab3',
    
    components: {
        EmpSelector,
        CmmDateComp
    },

    props : {
        
    },

    created: function(){    
        let timezoneOffset = new Date().getTimezoneOffset() * 60000
        let timezoneDate = new Date(Date.now() - timezoneOffset)

        this.sltDate = timezoneDate.toISOString().split('T',2)[0].afterDateFormatHyp()   
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
        })
    },

    computed: {
       
    },  

    watch: {
        
    },

    methods: {
        onSave:function(){
            let gFcltyNum = this.$store.getters.getFcltyNum           
            let gUserNm = this.$store.getters.getUserNm

            if(this.$refs.tabform.validate()){
                
                let obj = {
                    fcltyNum:gFcltyNum,
                    userNm:gUserNm,
                    mscldChckpPk:this.items.mscldChckpPk,
                    empPk:this.items.empPk,
                    mrgYn:this.items.mrgYn,
                    crntCarer:this.items.crntCarer,
                    wrtDt:this.items.wrtDt?.afterDateFormatHyp(),
                    crntWrkCntnt:this.items.crntWrkCntnt,
                    wrkPrd:this.items.wrkPrd,
                    wrkHr1d:this.items.wrkHr1d,
                    brkHr1d:this.items.brkHr1d,
                    bfWrkCntnt:this.items.bfWrkCntnt,
                    bfWrkPrd:this.items.bfWrkPrd,
                    worker:this.items.worker,
                    wrkDt:this.items.wrkDt?.afterDateFormatHyp(),
                    wrkCntnt:this.items.wrkCntnt,
                    wrtr:this.items.wrtr,
                    list : []
                }

                insMscldChckpDtl(obj)
                    .then((response) => ( this.onSaveAfter(response.data) ))
                    .catch((error) => console.log('upload error /emp/insMscldChckpDtl : ' + error))
            }
            

        },
        onSaveAfter:function(res){
            this.$emit('empMsysTrigger', res)
        },
        onModal: function (value) {
            this.dialog.splice(value, 1, true)
        },
        isModal: function (value) {
            this.dialog.splice(value, 1, false)
        },
        getEmpInfo: function (obj) {
            this.items.worker = obj[0].name
        },
        setItems:function(item){
            this.items  = Object.assign({})
            if(item != null && item != ''){ 
                this.items = item
                this.items.wrtDt = item.wrtDt?.beforeDateFormatHyp()
                this.items.wrkDt = item.wrkDt?.beforeDateFormatHyp()
            }

            this.$refs.tabform.resetValidation()
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
    },
    
    data: () => ({   
        sltDate:'',
        items:{},
        dialog:[false,false],
        forceRender:0, 
        menu:[false,], 
        rtimes:false,
        mtimes:false,
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },
    }),
};
</script>