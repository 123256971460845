<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div style="position:relative;">            
            <v-snackbar
                v-model="altOptions[0]" :timeout="altOptions[1]" :value="true" class="pa-0"
                :color="altOptions[2]" absolute top>
                <v-alert                    
                    :type="altOptions[2]"
                >
                    <div class="text-h6 white--text font-weight-bold">{{altOptions[3]}}</div>
                    <div class="text-h6 white--text font-weight-bold" v-html="altOptions[4]"></div>
                </v-alert>
            </v-snackbar>
        </div> 
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">체위변경 관찰기록</span>
            <v-spacer></v-spacer>    
            <span v-if="progress">
                <v-progress-circular            
                :size="25"
                color="primary"
                indeterminate
                ></v-progress-circular>
            </span>
            <span v-if="!progress">           
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn v-if="list.type == 'action' && list.visible" @click = "onClickBtn(list.text)"
                        :class="list.class" height="30" :color="list.color" rounded
                        :disabled="list.disabled"
                        style="font-size: 14px;">
                        <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                        {{list.text}}
                    </v-btn>
                </span>
                <span v-for="(list, j) in outlinedBtn" :key="filledBtn.length + j">
                    <v-btn :disabled="list.disabled"
                        :class="list.class" @click = "onClickBtn(list.text)"
                        samll height="30" :color="list.color" rounded outlined>
                        <v-icon small>{{list.icon}}</v-icon>
                        {{list.text}}
                    </v-btn>
                </span>
            </span>
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <basicInfo :bnMmbrPk="bnMmbrPk"></basicInfo>
        <v-row class="pa-0 mt-2" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1"> 
                <span class="black--text font-weight-medium">관찰일자</span>
            </v-col>
            <v-col class="d-flex justify-start align-center pl-2 py-1 ma-0" cols="2">   
                {{inqYMD?.beforeDateFormatDot()}}
            </v-col>
            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2"> 
                <span class="black--text font-weight-medium">욕창평가 점수&#47;활동</span>
            </v-col>
            <v-col class="d-flex justify-start align-center pl-2 py-1 ma-0" cols="3">   
                {{items.valScre}}&#47;{{items.bdSre}}
            </v-col>
            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2"> 
                <span class="black--text font-weight-medium">욕창평가 움직임</span>
            </v-col>
            <v-col class="d-flex justify-start align-center pl-2 py-1 ma-0" cols="2">   
                {{items.mvmt}}
            </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2"> 
                <span class="black--text font-weight-medium">전날 마지막 체위변경</span>
            </v-col>
            <v-col class="d-flex justify-start align-center pl-2 py-1 ma-0" cols="3">   
                {{items.preOfrTm}}
            </v-col>
            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2"> 
                <span class="black--text font-weight-medium">최근욕창간호</span>
            </v-col>
            <v-col class="d-flex justify-start align-center pl-2 py-1 ma-0" cols="5"> 
                {{items.bdsrNrs}}  
            </v-col>
        </v-row>
        <v-form ref="tabform" lazy-validation>
            <div class="d-flex align-center mt-3">
                <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                <span class="text-h6 font-weight-bold">체위변경 기록</span>
                <v-spacer></v-spacer>
                <v-btn
                    class="ml-1" color="blueBtnColor" style="padding: 13px 12px 12px 10px !important"
                    min-width="30" height="25"
                    @click="onShowList(1)"
                    small outlined>
                    <v-icon size="14">mdi-text-box-search-outline</v-icon>
                    <span class="fontOneRem ml-1" style="padding-bottom: 2px;">입력목록조회</span>                
                </v-btn>
                <v-btn
                    class="ml-1" color="blueBtnColor" style="padding: 13px 12px 12px 10px !important"
                    min-width="30" height="25"
                    @click="onShowList(0)"
                    small outlined>
                    <v-icon size="14">mdi-text-box-search-outline</v-icon>
                    <span class="fontOneRem ml-1" style="padding-bottom: 2px;">전체목록조회</span>                
                </v-btn>
            </div>
            <div class="d-flex mr-2__5 mt-2" no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:13%;"> 
                    <span class="black--text font-weight-medium">시간대</span>
                </div>
                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:55%;border-left: 1px solid #dbdbdb !important;"> 
                    <span class="black--text font-weight-medium">변경체위자세</span>
                </div>
                <div style="width:25%;border-left: 1px solid #dbdbdb !important;"> 
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:100%">
                        <span class="black--text font-weight-medium">
                            담당자 선택
                        </span>
                        <v-btn              
                            class="pt-1"           
                            icon     
                            color="grey lighten-1"             
                            @click="onModal(0)"
                            >
                            <v-icon size="20">mdi-magnify</v-icon>
                        </v-btn>
                    </div>
                    <div class="d-flex justify-start align-center pl-0 py-1 ma-0" style="width:100%;min-height:30px;border-top: 1px solid #dbdbdb !important;">
                        <!-- <v-chip 
                            v-for="(list, k) in mmbrList" :key="k"
                            class="pa-2 mr-1"
                            label            
                            color="blue" 
                            outlined                           
                            small        
                            style="background-color: white !important;"                                                          
                            >
                            {{list.wrtr}}
                        </v-chip> -->
                        <div class="d-inline-block pl-4 d-flex" style="width: 100%;">
                            <div style="width:90%">
                                <v-radio-group
                                    v-model="wrtrAllMmbrPk" :key="forceRender"
                                    class="radio-dft-Theme mt-0 pl-2 pt-1" hide-details row>
                                    <v-radio 
                                        v-for="(list, k) in mmbrList" :key="k" 
                                        class="pa-0 mr-1"                                     
                                        :ripple="false" 
                                        :label="list.wrtr"                                     
                                        :value="list.wrtrpk"
                                        @click="allChecker(list)">
                                    </v-radio>                                
                                </v-radio-group>
                            </div>

                           <div style="width:10%">
                                <v-btn
                                    class="pr-1"
                                    color='grey006'   
                                     @click="allNonChecker()"                                 
                                    icon>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-eraser</v-icon>
                                        </template>
                                        <span>전체 선택 해제</span>
                                    </v-tooltip>
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:7%;border-left: 1px solid #dbdbdb !important;"> 
                    <span class="black--text font-weight-medium">처리</span>
                </div>
            </div>
            <div style="height:357px;overflow-y:scroll;">
                <div v-for="(item, i) in recDtlList" :key="i">
                    <div v-if="item.isShow" :class="['d-flex pa-0', (item.bnftOfrRec301Pk > 0)?'bathblnft-green-lighten-5':'']" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:13%;"> 
                            <span style="width:25px">{{item.ofrTmHhStrt}}</span>
                            <span style="width:10px">&#58;</span>
                            <span style="width:60px" class="ml-1">
                                <v-text-field v-model="item.ofrTmMmStrt" :rules="minute" height="28" hide-details outlined dense :key="forceRender" counter="2"></v-text-field>
                            </span>                        
                        </div>
                        <div class="d-flex pa-1 ma-0" style="width:55%;border-left: 1px solid #dbdbdb !important;"> 
                            <div class="d-inline-block pl-4" style="width: 100%;">
                                <v-radio-group
                                    v-model="item.itm1Val" :key="forceRender"
                                    class="radio-dft-Theme mt-0 pl-2 pt-1" hide-details row>
                                    <v-radio 
                                        v-for="(list, j) in codeItem" :key="j" 
                                        class="pa-0 mr-1"                                     
                                        :ripple="false" 
                                        :label="list.valcdnm" 
                                        :value="list.valcd">
                                    </v-radio>                                
                                </v-radio-group>
                            </div>
                        </div>
                        <div style="width:25%;border-left: 1px solid #dbdbdb !important;"> 
                            <div class="d-inline-block pl-4" style="width: 100%;">
                                <v-radio-group
                                    v-model="item.wrtrMmbrPk" :key="forceRender"
                                    class="radio-dft-Theme mt-0 pl-2 pt-1" hide-details row>
                                    <v-radio 
                                        v-for="(list, k) in mmbrList" :key="k" 
                                        class="pa-0 mr-1"                                     
                                        :ripple="false" 
                                        :label="list.wrtr" 
                                        :value="list.wrtrpk">
                                    </v-radio>                                
                                </v-radio-group>
                            </div>
                        </div>
                        <div class=" d-flex justify-center align-center pa-1 ma-0" style="width:7%;border-left: 1px solid #dbdbdb !important;">                                                                        
                            <v-btn  icon color='grey006' @click="delRow(i)"> 
                                <v-icon v-if="item.bnftOfrRec301Pk > 0">mdi-trash-can-outline</v-icon>                                           
                                <v-icon v-else>mdi-minus-circle-outline</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </div>
        </v-form>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
                    <EmpSelector @isClose="isModal(0)" @outEmp="getEmpInfo" :prtWrtDt = "prtWrtDt"></EmpSelector>
            </v-dialog>  
        </div>        
    </v-sheet>
</template>
<script>
import basicInfo from '../bnfcr/BnfcrBasicInfo.vue';
import EmpSelector from '../commons/EmpSelector.vue';
import { selBnfcrChgPosInfo, selBnftOfrRecList, selPreBnftOfrRecList, insMultiBnftRcrd, delAllBnftOfrRec, delBnftOfrRec } from '../../api/bnftrcrd.js';
import { getStcd } from '../../api/index.js';

export default {
    name: 'ChgPosObsrvRcrd',
    
    components: {  
        EmpSelector,
        basicInfo,
    },

    props : {
        inqYMD              : { type: String, default: '' },  
        bnMmbrPk            : { type: Number, default: -1 },
        chgPosObsrvRcrdPk   : { type: Number, default: -1 },
        empWrtr             : { type: String, default: '' }, 
        empWrtrpk           : { type: Number, default: -1 },  
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
        getSubActv: function () {
            return this.$store.getters.getSubActv
        },
    },

    created: function(){        
        
    },

    mounted: function(){
        this.$nextTick(function () {
            this.frmReset()
            this.onCodeload()
        })
    },

    watch: {
        getSubEvt: function (bol) {
            if(bol && this.chgPosCnfm){
                this.onSave('1')
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
                this.chgPosCnfm = false
            }

            if(bol && this.rmChgPosCnfm){
                this.rmBnftOfrRec()
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
                this.rmChgPosCnfm = false
            }

            if(bol && this.rmAllChgPosCnfm){
                this.rmAllBnftOfrRec()
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
                this.rmAllChgPosCnfm = false
            }
        },

        getSubActv:function (bol){
            if(!bol && this.chgPosCnfm){
                this.frmReset()
                this.onCodeload()
                this.chgPosCnfm = false
            }
        }
    },

    methods: {  
        onCodeload(){
            getStcd('29')
                .then((response) => ( this.codeMapping(response.data)))
                .catch((error) => console.log(error))
        },
        codeMapping(res){
            this.codeItem = res
            this.onLoad()
        },
        onLoad(){             
            this.wrtr = this.empWrtr
            this.wrtrMmbrPk = this.empWrtrpk     
            this.getBnfcrChgPosInfo() 

            if(this.wrtr !='')  
                this.setMmbrList(this.wrtr,this.wrtrMmbrPk)

            if(this.bnMmbrPk > 0 && this.inqYMD !=''){                                
                this.getData()                 
            }else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.title = '수급자를 선택해주세요.'        // 경고 타이틀                
                obj.tmr = 2500      
                obj.type = 'info'            // 경고 유형 (success, info, warning, error)
                this.$store.commit('setWrnnInf', obj)
            }
        },
        setMmbrList:function(wrtr,wrtrpk){
            let addChk = true
        
            this.mmbrList.forEach(x=>{
                if(x.wrtrpk == wrtrpk)
                addChk = false
            })

            if(addChk)
                this.mmbrList.push({wrtr:wrtr,wrtrpk:wrtrpk})
        },
        onClose: function () {
            this.isPreData = false
            this.$emit('modalEvent', 2)
        },
        onClickBtn:function(text){
            if(text == "전일자료"){
                this.isPreData = true                
                this.getPreData()
            }else if(text == "저장"){
                this.onSave()
            }else if(text == "삭제"){
                let obj = {}
                obj.icon = 'mdi-alert'
                obj.iconClr = 'red'
                obj.title = '체위변경 관찰기록'
                obj.cntnt = [this.$moment(this.inqYMD,'YYYYMMDD').format('YYYY.MM.DD')+'의 체위변경 관찰기록을(를) 삭제하시겠습니까?']
                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 삭제하겠습니다.'

                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });   

                this.rmAllChgPosCnfm = true
            }else if(text == "출력"){
                this.chgPosObsrvRcrdPrt()
            }
        },        
        reLoad(){
            this.getData()
        },
        frmReset(){     
            this.isPreData = false
            this.filledBtn[0].visible = true
            this.filledBtn[2].disabled = true
            this.outlinedBtn[0].disabled = true
            this.prtWrtDt=''
            this.items = Object.assign({bnftOfrRecHisPk:0,mdfDt:'',recDt:'',wrtr:'',wrtrMmbrPk:0,wrtrGr:[],wrtrGrMmbrPk:[],spclNote:'',bdSre:'',bdsrNrs:'',mvmt:'',preOfrTm:'',valScre:'',list:[]})
            this.recDtlList.splice(0)
            this.mmbrList.splice(0)
            this.$refs.tabform.resetValidation();
        },
        getData(){
            let fcltyNum = this.$store.getters.getFcltyNum
            let obj = {
                fcltyNum: fcltyNum, 
                inqTFclsfc1Cd: true, 
                inqTFclsfc2Cd: true, 
                inqTFclsfc3Cd: true, 
                inqTFitmCd: true, 
                clsfc1Cd: '301',
                clsfc2Cd: '4',
                clsfc3Cd:'1',
                bnMmbrPk: this.bnMmbrPk,
                inqYMD:this.inqYMD,
            }
            this.progress = true
            selBnftOfrRecList(obj)
                .then((response) => ( this.resData(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/selBnftOfrRecList : ' + error))
        },
        resData(res){                
            this.recDtlList.splice(0)   
            if(res.statusCode == 200){

                if(res.result.length > 0){
                    
                    this.filledBtn[0].visible = false
                    this.filledBtn[2].disabled = false 
                    this.outlinedBtn[0].disabled = false

                    if(res.result[0].bnftOfrRecHisPk > 0){
                        this.items.bnftOfrRecHisPk = res.result[0].bnftOfrRecHisPk
                        this.items.mdfDt = res.result[0].mdfDt
                        this.filledBtn[1].disabled = false 
                    }
                
                    this.mmbrList.splice(0)

                    res.result.forEach(el => {                    
                        this.setMmbrList(el.wrtr,el.wrtrMmbrPk)
                    })
                    
                }else{
                    this.items.bnftOfrRecHisPk = 0
                    this.filledBtn[2].disabled = true
                }

                for(let i=0;i<24;i++){

                    let itm  = {
                        isShow:true,
                        wrtrMmbrPk:0,
                        bnftOfrRecHisPk:0,
                        bnftOfrRec301Pk:0,
                        ofrTmHhStrt:'',
                        ofrTmMmStrt:'',
                        itm1Cd:'29',                    
                        itm1Val:'',
                        wrtr:'',
                        mdfDt:'',
                    }

                    let hour = '0'+i
                    if(hour.length == 3) hour = hour.slice(-2)                
                    
                    itm.ofrTmHhStrt = hour

                    if(res.result.length > 0){
                        
                        let idx = res.result.findIndex(x=>x.ofrTmHhStrt == hour)
                        let recItem = Object.assign({},res.result[idx])

                        if(typeof recItem.ofrTmMmStrt != 'undefined'){
                            itm.ofrTmMmStrt     = recItem.ofrTmMmStrt
                            itm.itm1Val         = recItem.itm1Val
                            itm.wrtr            = recItem.wrtr
                            itm.bnftOfrRecHisPk = recItem.bnftOfrRecHisPk
                            itm.bnftOfrRec301Pk    = recItem.bnftOfrRec301Pk                            
                            itm.wrtrMmbrPk      = Number(recItem.wrtrMmbrPk)                        
                        }

                    }

                    this.recDtlList.push(itm)
                }

                ++this.forceRender
            } else {               
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!) 
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                
                this.$store.commit('setWrnnInf', obj)
            }

            this.progress = false
        }, 

        getPreData(){
            let fcltyNum = this.$store.getters.getFcltyNum
            let obj = {
                fcltyNum: fcltyNum, 
                inqTFclsfc1Cd: true, 
                inqTFclsfc2Cd: true, 
                inqTFclsfc3Cd: true, 
                inqTFitmCd: true, 
                clsfc1Cd: '301',
                clsfc2Cd: '4',
                clsfc3Cd:'1',
                bnMmbrPk: this.bnMmbrPk,
                inqYMD:this.inqYMD,
            }
            this.progress = true
            selPreBnftOfrRecList(obj)
            .then((response) => ( this.getPreDataAfter(response.data)))
            .catch((error) => console.log(error))
        },
        getPreDataAfter(res){
            this.recDtlList.splice(0)
            this.items.bnftOfrRecHisPk = 0

            if(res.length > 0){       
                this.mmbrList.splice(0)
            //     if(res[0].wrtrGr != null){
            //         for(let i=0;i<res[0].wrtrGr.length;i++){   
            //             this.setMmbrList(res[0].wrtrGr[i],res[0].wrtrGrMmbrPk[i])
            //         }
            //     }
            }
            

            for(let i=0;i<24;i++){
                let obj  = {
                    isShow:true,
                    bnftOfrRecHisPk:0,
                    bnftOfrRecPk:0,
                    ofrTmHhStrt:'',
                    ofrTmMmStrt:'',
                    itm1Val:'',
                    wrtrMmbrPk:0,
                    wrtr:'',
                    clsfc1Cd:this.clsfc1Cd,clsfc2Cd:this.clsfc2Cd,clsfc3Cd:this.clsfc3Cd,itm1Cd:'29',
                    codeItem:this.codeItem
                }

                let hour = '0'+i
                if(hour.length == 3) hour = hour.substr(1,2)                
                
                obj.ofrTmHhStrt = hour

                if(res.length > 0){
                    let data = res.filter(x=>x.ofrTmHhStrt == hour)
                    if(data[0] != undefined){
                        obj.ofrTmMmStrt = data[0].ofrTmMmStrt
                        obj.itm1Val = data[0].itm1Val
                        obj.wrtr = data[0].wrtr
                        obj.wrtrMmbrPk = data[0].wrtrMmbrPk
                        obj.bnftOfrRecHisPk = 0
                        obj.bnftOfrRecPk = 0
                        this.setMmbrList(data[0].wrtr,data[0].wrtrMmbrPk)
                    }
                }

                this.recDtlList.push(obj)
            }
            this.progress = false
        }, 

        onSave:function(dupYn = '2'){
            
            if(this.bnMmbrPk > 0){
                if(this.$refs.tabform.validate()) {
                    let obj = {
                        fcltyNum    : this.$store.getters.getFcltyNum,
                        bnMmbrPk    : this.bnMmbrPk,
                        userNm      : this.$store.getters.getUserNm,
                        ignrYn      : dupYn,
                        hisList     : []
                    }

                    let hisObj = {                        
                        clsfc1Cd        :'301', 
                        clsfc2Cd        :'4',
                        clsfc3Cd        :'1',
                        mdfDt           : this.items.mdfDt,
                        recDt           : this.inqYMD,
                        wrtr            : this.wrtr,
                        wrtrMmbrPk      : this.wrtrMmbrPk,
                        wrtrGr          : [],
                        wrtrGrMmbrPk    : [],
                        recList         : [],
                    }
                    
                    this.mmbrList.forEach(x => {
                        hisObj.wrtrGr.push(x.wrtr)
                        hisObj.wrtrGrMmbrPk.push(x.wrtrpk)
                    });

                    this.recDtlList.forEach(x => {
                        let mmbrInfo = this.mmbrList.filter(e=> e.wrtrpk == x.wrtrMmbrPk)
                        
                        if(mmbrInfo[0] !=undefined && x.ofrTmMmStrt !='' && x.itm1Val !=''){

                            let recItem = {
                                bnftOfrRec301Pk : x.bnftOfrRec301Pk,
                                ofrTmHhStrt : x.ofrTmHhStrt,
                                ofrTmMmStrt : x.ofrTmMmStrt,
                                itm1Cd      : '29',
                                itm1Val     : x.itm1Val,
                                wrtr        : mmbrInfo[0].wrtr,
                                wrtrMmbrPk  : x.wrtrMmbrPk,
                            }
                            hisObj.recList.push(recItem)
                        }
                    });

                    obj.hisList.push(hisObj)
                    
                    if(hisObj.recList.length > 0){
                        this.progress = true
                        insMultiBnftRcrd(obj)
                            .then((response) => ( this.onSaveAfter(response.data) ))
                            .catch((error) => console.log('upload error /bnftrcrd/insMultiBnftRcrd : ' + error))
                    }else{
                        let obj = {}
                        obj.actv = true                 // 경고 노출여부
                        obj.title = '담당자를 선택해주세요.'        // 경고 타이틀                
                        obj.tmr = 5000      
                        obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                        this.$store.commit('setWrnnInf', obj)
                    }
                }else{                    
                    let obj = {}
                    obj.actv = true                 // 경고 노출여부
                    obj.title = '시간대 항목 유효한 값이 아닙니다. 확인해주세요.'        // 경고 타이틀                
                    obj.tmr = 5000      
                    obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                    this.$store.commit('setWrnnInf', obj)
                }
            }
        },
        onSaveAfter:function(res){
            
            let obj = {}  

            if(res.statusCode == 200){                                                
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
                this.$emit('modalEvent', 99)
                this.reLoad()
                this.isPreData = false
            }else if(res.statusCode == 205) {
                obj.icon = 'mdi-alert'
                obj.iconClr = 'red'
                obj.title = res.message
                obj.cntnt = []

                if(res.messagesList != null && res.messagesList != undefined){
                    res.messagesList.forEach(el => {
                        obj.cntnt.push(el)
                    });
                }

                obj.cntnt.push('등록된 기록을 삭제하고 저장하시겠습니까?')
                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 저장하겠습니다.'

                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });   

                this.chgPosCnfm = true
            }else if(res.statusCode == 701) {        
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = '체위변경 관찰기록 · 담당자 시간이 중복되었습니다.'        // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)        
            }else{
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
            this.progress = false
        },        
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
        getEmpInfo: function (obj) {
            this.setMmbrList(obj[0].name,obj[0].mmbrPk)
        },
        getBnfcrChgPosInfo:function(){
            let fcltyNum = this.$store.getters.getFcltyNum

            let obj = {                 
                fcltyNum : fcltyNum,
                bnMmbrPk: this.bnMmbrPk,
                inqYMD: this.inqYMD,
            }
            selBnfcrChgPosInfo(obj)
                .then((response) => ( this.getBnfcrChgPosInfoAfter(response.data)))
                .catch((error) => console.log(error))
        },
        getBnfcrChgPosInfoAfter:function(res){
            this.items.bdSre = res.bdSre
            this.items.bdsrNrs = res.bdsrNrs
            this.items.mvmt = res.mvmt
            this.items.preOfrTm = res.preOfrTm
            this.items.valScre = res.valScre         
        },
        onModal: function (value) {
            this.prtWrtDt=this.inqYMD
            this.dialog.splice(value, 1, true)
        },
        isModal: function (value) {
            this.dialog.splice(value, 1, false)
        },
        delRow:function(key){
            if(this.recDtlList[key].bnftOfrRec301Pk > 0){
                this.idx = key                
                let obj = {}
                obj.icon = 'mdi-alert'
                obj.iconClr = 'red'
                obj.title = '체위변경 기록'
                obj.cntnt = [this.recDtlList[key].ofrTmHhStrt+":"+this.recDtlList[key].ofrTmMmStrt+' 을(를) 삭제하시겠습니까?']
                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 삭제하겠습니다.'

                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });   

                this.rmChgPosCnfm = true
            }else{
                this.recDtlList[key].itm1Val = ''
                this.recDtlList[key].wrtrMmbrPk = ''
                this.recDtlList[key].wrtr = ''
            }
        },
        rmBnftOfrRec:function(){
            if(this.idx > -1){
                let obj = {
                    fcltyNum        : this.$store.getters.getFcltyNum,
                    clsfc1Cd        : '301',              
                    bnftOfrRec301Pk : this.recDtlList[this.idx].bnftOfrRec301Pk,
                }
                console.log(obj)
                this.progress = true

                delBnftOfrRec(obj)
                    .then((response) => ( this.rmBnftOfrRecAfter(response.data) ))
                    .catch((error) => console.log('upload error /bnftrcrd/delBnftOfrRec : ' + error))
            }
        },

        rmBnftOfrRecAfter:function(res){
            this.progress = false

            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀
            obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200){
                obj.tmr = 2500      
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.frmReset()
                this.onCodeload()
            } else {                
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
            }
            
            this.$store.commit('setWrnnInf', obj)
        },

        rmAllBnftOfrRec:function(){
            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum,                 
                bnMmbrPk    : this.bnMmbrPk,
                recDt       : this.inqYMD,
                clsfc1Cd    : '301',
                clsfc2Cd    : '4',
                clsfc3CdS   : ['1'],
            }
            console.log(obj)
            this.progress = true

            delAllBnftOfrRec(obj)
                .then((response) => ( this.rmAllBnftOfrRecAfter(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/delAllBnftOfrRec : ' + error))
        },
        rmAllBnftOfrRecAfter:function(res){
            this.progress = false

            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀
            obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200){
                obj.tmr = 2500      
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.$emit('modalEvent', 99)
                this.onClose()
            } else {                
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
            }
            
            this.$store.commit('setWrnnInf', obj)
        },
        onShowList:function(key){
            this.recDtlList.forEach(el => {
                el.isShow = true

                if(key == 1){
                    if(el.bnftOfrRec301Pk == 0 || el.bnftOfrRec301Pk == null) el.isShow = false                    
                }             
            });
        },
        allChecker:function(obj){
            this.recDtlList.forEach(el => {
                  el.wrtr = obj.wrtr
                  el.wrtrMmbrPk = obj.wrtrpk
            });
        },
        allNonChecker:function(){
            this.recDtlList.forEach(el => {
                  el.wrtr = ''
                  el.wrtrMmbrPk = 0
            });
            this.wrtrAllMmbrPk = 0
        },        
        altControll: function (options) {
            this.altOptions = options
        },
        minuteCheck:function(value){
            console.log(value)
            return true
        },
        chgPosObsrvRcrdPrt :function(){
            let obj = {}
            obj.rptTyp = '1'
            obj.mode = '2'
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnMmbrPk = this.bnMmbrPk            
            obj.prtClcd = '1'
            obj.inqClcd = 2
            obj.prtCd = '61'
            obj.inqYYYY = null
            obj.inqYmdStart = this.inqYMD
            obj.inqYmdEnd = this.inqYMD
            obj.list = [ { bzClcd: [], bzPk: [] } ]
            
            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: obj, outObj: null, runEvt: true,
            });
        }
    },
    data: () => ({      
        chgPosCnfm :false,         
        rmChgPosCnfm :false,
        rmAllChgPosCnfm :false,  
        isPreData:false,
        preymd:'',
        prtWrtDt:'',
        type:'all',
        idx:0,
        codeItem:[],
        wrtrAllMmbrPk:0,
        wrtr:'' ,
        wrtrMmbrPk:0,
        forceRender:0,                  
        items:{bnftOfrRecHisPk:0,mdfDt:'',recDt:'',wrtr:'',wrtrMmbrPk:0,wrtrGr:[],wrtrGrMmbrPk:[],spclNote:'',bdSre:'',bdsrNrs:'',mvmt:'',preOfrTm:'',valScre:'',list:[]},      
        recDtlList:[{
            bnftOfrRecHisPk:0,bnftOfrRec301Pk:0,recDt:'',ofrTmHhStrt:'',ofrTmMmStrt:'',
            clsfc1Cd:'301',clsfc2Cd:'4',clsfc3Cd:'1',itm1Cd:'29',itm1Val:'',wrtrMmbrPk:0,wrtr:'',codeItem:[]}],
        mmbrList:[{wrtr:'',wrtrpk:''}],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',            
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',            
        }, 
        minute: [
            value => {
                if(!(value < 0 || value > 59)) return true 
                return 'Number Validate.'
            },
            value => {
                if(/\s/g.test(value) == false) return true 
                return 'Space Validate.'
            },
        ],
        filledBtn: [
            { icon: 'mdi-text-box-search-outline', class: 'white--text', color:'#3C9099', text: '전일자료', type:'action', visible: true, disabled: false,},
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장',type:'action', visible: true, disabled: false,},
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type: 'action', visible: true, disabled: true,},
        ],    
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006 ', text: '출력', disabled: true},
        ],
        allMmbrPk:0,
        dialog:[false,false,false,false,],        
        progress:false,
        altOptions: [false, 3000, 'info', '', ''],
    }),
};
</script>