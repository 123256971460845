<template>
    <v-sheet class="rounded-lg rounded-l-0 pa-2" color="" min-width="1280" max-width="1280" height="835">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>

        <div class="">
            <div class="text-h4 d-flex align-center pr-2">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">9-3. 본인부담금</span>
                <v-spacer></v-spacer>
                <v-btn v-for="(list, j) in outlinedBtn" :key="j" @click = "onClickBtn(list.text)"
                    :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded outlined>
                    <v-icon style="padding-top: 2px;" small>{{list.icon}}</v-icon>
                    <span class="ml-1" style="font-size: 1rem;">{{list.text}}</span>
                </v-btn>
            </div>
        </div>
        <div class="mt-3">
            <v-tabs
                v-model="tab" class="tab-dft-Theme" active-class="black"
                center-active hide-slider>
                <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class">{{ item.tab }}</v-tab>
            </v-tabs>
            <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
            <v-tabs-items v-model="tab">
                <v-tab-item transition="fade-transition">
                    <copaySttsTab1 ref="tab1" :tab="tab"></copaySttsTab1>
                </v-tab-item>
                <v-tab-item transition="fade-transition">
                    <copaySttsTab2 ref="tab2" :tab="tab"></copaySttsTab2>
                </v-tab-item>
            </v-tabs-items>
        </div>
    </v-sheet>
</template>

<script>
import copaySttsTab1 from '../../components/btcInq/CopaySttsTab1.vue';
import copaySttsTab2 from '../../components/btcInq/CopaySttsTab2.vue';

export default {
    name: 'CopayStts',

    components: {           
        copaySttsTab1,
        copaySttsTab2,
    },

    created: function () {
        
    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {    
            
        })
    },

    computed: {

    },

    watch: {
        
    },

    methods: {        
        onClickBtn:function(text){            
            switch (text) {
                case '출력':
                    this.prtCopaySttsReport()
                    break;
                case '본인부담금 엑셀 다운로드':
                    this.getExcelCrtDwnl()
                    break;
                default:
                    break;
            }
        },
        
        snackControll: function (options) {
            this.snackOptions = options
        },
        prtCopaySttsReport:function(){
            if(this.tab == 0) this.$refs.tab1.prtCopaySttsReport()
            else if(this.tab == 1) this.$refs.tab2.prtCopaySttsReport()
        },
        getExcelCrtDwnl:function(){
            if(this.tab == 0) this.$refs.tab1.getExcelCrtDwnl()
            else if(this.tab == 1) this.$refs.tab2.getExcelCrtDwnl()
        },
        prtReport:function(){
            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
            });
        },
    },

    data: () => ({        
        tab         : 0,
        tabItems    : [
            { index: 0, tab: '월별', class: 'fontOneDotTwoRem px-15'}, 
            { index: 1, tab: '연별', class: 'fontOneDotTwoRem px-15'},
        ],        
        vCd41       : [],  
        vCd42       : [],  
        vCd65       : [],  
        vCd70       : [],  
        excelInfo   : { filePth: '', fileNm: '', orgFileNm: '' },
        prtThrowObj : {
            fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '19',
            rptTyp: '1', mode: '2', list: [{ bzClcd: [], bzPk: [] }],
        },                               
        exclsList       : [],
        snackOptions    : [false, 3000, '', 'info'],
        outlinedBtn: [
            { icon: 'mdi-microsoft-excel', class: 'ml-1 font-weight-bold', color: 'grey006', text: '본인부담금 엑셀 다운로드', type: 'action', width: '500px', visible: true, disabled: true, },
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color: 'grey006', text: '출력', type: 'action', width: '500px', visible: true, disabled: true, },
        ],
    }),
};
</script>