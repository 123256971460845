<template>
    <v-sheet>
        <v-form ref="tabform" lazy-validation>
            <div class="d-flex">
                <div class="d-inline-block pa-0 ma-0 align-self-start" style="width:830px;font-size:0.9rem !important;">
                    <v-row class="pa-0 ma-0" style="border-top: 1px solid #dbdbdb !important;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                            <span class="black--text font-weight-medium">치료기간</span>
                        </v-col>
                        <v-col class="d-flex justify-start align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="5">
                            <span class="white d-inline-block pa-1" style="width: 154px;">     
                                <CmmDateComp v-model="phyThrPlnItems.thrStrt" :required="true" :maxDt="phyThrPlnItems.thrEnd"></CmmDateComp>
                            </span>
                            <span class="white d-inline-block pa-1" style="width: 20px;">  
                                ~
                            </span>
                            <span class="white d-inline-block pa-1" style="width: 154px;">
                                <CmmDateComp v-model="phyThrPlnItems.thrEnd" :required="true" :minDt="phyThrPlnItems.thrStrt"></CmmDateComp>                                
                            </span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="2">
                            <span class="black--text font-weight-medium">치료횟수/시간</span>
                        </v-col>
                        <v-col class="d-flex justify-left align-center pa-0 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="3">
                            <span class="white d-inline-block pa-1" style="width: 150px;">  
                            <v-text-field
                                v-model="phyThrPlnItems.thrTimes" :rules="[rules.required]"
                                color="blue" height="28" ref="info"
                                hide-details outlined dense>
                            </v-text-field>
                            </span>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 ma-0" style="border-top: 1px solid #dbdbdb !important;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                            <span class="black--text font-weight-medium">치료목표</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="5">
                            <v-textarea v-model="phyThrPlnItems.thrGls" class="rmrksArea" no-resize  
                                rows="2" outlined dense hide-details>
                            </v-textarea> 
                        </v-col> 
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                            <span class="black--text font-weight-medium">문제점</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="3">
                            <v-textarea v-model="phyThrPlnItems.prblm" class="rmrksArea" no-resize  
                                rows="2" outlined dense hide-details>
                            </v-textarea> 
                        </v-col>                       
                    </v-row>
                    <v-row class="pa-0 ma-0" style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                            <span class="black--text font-weight-medium">치료부위</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="5">
                            <v-textarea v-model="phyThrPlnItems.thrPart" class="rmrksArea" no-resize  
                                rows="2" outlined dense hide-details>
                            </v-textarea> 
                        </v-col>   
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                            <span class="black--text font-weight-medium">제공계획변경내역</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="3">
                            <v-textarea v-model="mdfRsn" class="rmrksArea" no-resize  
                                rows="2" outlined dense hide-details>
                            </v-textarea> 
                        </v-col>                       
                    </v-row>
                    <v-row class="pa-0 ma-0 mt-2" no-gutters>
                        <v-col cols="12">
                            <div class="greyE01 d-flex pa-0 mr-2__5" style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:17%">
                                    <span class="black--text font-weight-medium">치료구분</span>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:27%;border-left: 1px solid #dbdbdb !important;">
                                    <span class="black--text font-weight-medium">치료방법</span>
                                </div>
                                <div class="pa-0 ma-0" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;">
                                        <span class="black--text font-weight-medium">치료일정</span>
                                    </div>
                                    <div class="d-flex pa-0 ma-0" style="width:100%;border-top: 1px solid #dbdbdb !important;">
                                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;">
                                            <span class="black--text font-weight-medium">월</span>
                                        </div>
                                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">
                                            <span class="black--text font-weight-medium">화</span>
                                        </div>
                                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">
                                            <span class="black--text font-weight-medium">수</span>
                                        </div>
                                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">
                                            <span class="black--text font-weight-medium">목</span>
                                        </div>
                                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">
                                            <span class="black--text font-weight-medium">금</span>
                                        </div>
                                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">
                                            <span class="black--text font-weight-medium">토</span>
                                        </div>
                                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">
                                            <span class="black--text font-weight-medium">일</span>
                                        </div>
                                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:37%;border-left: 1px solid #dbdbdb !important;">
                                            <span class="black--text font-weight-medium">치료시간</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">                                
                                    <v-btn icon class="mr-1" color="blue200" height="26" @click="addRow">
                                        <v-icon>mdi-plus-circle-outline</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    <div v-if="phyThrPlnDtlItems.length>0" style="height:230px;overflow-y:scroll;">
                        <v-row v-for="(item, j) in phyThrPlnDtlItems" :key="j" class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                            <v-col class="d-flex pa-0 ma-0" cols="12">
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:17%">
                                    <span class="d-inline-block" style="width: 110px;">
                                        <v-select 
                                            v-model="item.thrClcd"                               
                                            :items="codeItem37"
                                            item-text="valcdnm"
                                            item-value="valcd"
                                            dense hide-details outlined
                                            @change="resetType(j)"
                                        />     
                                    </span> 
                                </div>
                                <div class="d-flex align-center pa-1 ma-0" style="width:27%;border-left: 1px solid #dbdbdb !important;">                                    
                                    <div class="d-flex" style="width:86%">
                                        <span v-if="item.thrClcd=='1'">                                                                                
                                            <v-chip v-for="(cdItme, n) in item.thrMthdcd" :key="n" 
                                                style="margin:2px !important;padding:0px 5px; margin:2px !important;font-size:0.8rem !important;"
                                                label outlined
                                            >
                                                {{cdItme?.aftValCdToNm(codeItem92)}}
                                            </v-chip>
                                        </span>
                                        <span v-if="item.thrClcd=='2'">   
                                            <v-chip v-for="(cdItme, n) in item.thrMthdcd" :key="n" 
                                                style="margin:2px !important;padding:0px 5px; margin:2px !important;font-size:0.8rem !important;"
                                                label outlined>                                            
                                                {{cdItme?.aftValCdToNm(codeItem93)}}
                                            </v-chip>
                                        </span>
                                    </div>
                                    <div style="width:14%">
                                        <v-btn class="" color="blue200" height="26" @click="onModalType(j,item.thrClcd, item.thrMthdcd)" icon>
                                            <v-icon>mdi-plus-circle-outline</v-icon>
                                        </v-btn>
                                    </div>
                                    <!-- <span style="color:red" v-if="item.thrMthdcd.length < 1">
                                        <v-chip
                                            color="blue200"
                                            style="margin:2px !important;padding:0px 5px maring:2px !important;"
                                            label outlined>                                            
                                            선택
                                        </v-chip>
                                    </span> -->
                                    <!-- <v-btn class="mr-1" style="background-color: white; min-width: 36px;padding:0px 10px !important;" height="26" @click="onModalType(j,item.thrClcd, item.thrMthdcd)" outlined>선택</v-btn> -->
                                </div>
                                <div class="pa-0 ma-0" style="width:50%;height:100% !important;border-left: 1px solid #dbdbdb !important;">                                
                                    <div class="d-flex pa-0 ma-0" style="width:100%;height:100% !important;">
                                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;">
                                            <v-checkbox v-model="item.thrMonYn" class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" hide-details :ripple="false" value="1"></v-checkbox>
                                        </div>
                                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">
                                            <v-checkbox v-model="item.thrTueYn" class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" hide-details :ripple="false" value="1"></v-checkbox>
                                        </div>
                                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">
                                            <v-checkbox v-model="item.thrWedYn" class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" hide-details :ripple="false" value="1"></v-checkbox>
                                        </div>
                                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">
                                            <v-checkbox v-model="item.thrThuYn" class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" hide-details :ripple="false" value="1"></v-checkbox>
                                        </div>
                                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">
                                            <v-checkbox v-model="item.thrFriYn" class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" hide-details :ripple="false" value="1"></v-checkbox>
                                        </div>
                                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">
                                            <v-checkbox v-model="item.thrSatYn" class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" hide-details :ripple="false" value="1"></v-checkbox>
                                        </div>
                                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">
                                            <v-checkbox v-model="item.thrSunYn" class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" hide-details :ripple="false" value="1"></v-checkbox>
                                        </div>
                                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:37%;border-left: 1px solid #dbdbdb !important;">
                                            <span class="d-inline-block" style="width: 60px;">
                                                <CmmTimeField v-model="item.thrTmStrt" :required="true"></CmmTimeField>                                                
                                            </span>
                                            <span class="white d-inline-block pa-1" style="width: 14px;">  
                                                ~
                                            </span>
                                            <span class="d-inline-block" style="width: 60px;">
                                                <CmmTimeField v-model="item.thrTmEnd" :required="true"></CmmTimeField>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">                                    
                                    <v-btn icon color='grey006' @click="delRow(j)"> 
                                        <v-icon>mdi-trash-can-outline</v-icon>                                           
                                    </v-btn> 
                                </div>                            
                            </v-col>
                        </v-row>
                    </div>
                    <div v-else >
                        <v-row class="pa-0 mr-2__5" style="border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                            <v-col class="d-flex pa-0 ma-0" cols="12">
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%">                                
                                    데이터가 없습니다.
                                </div>                                
                            </v-col>
                        </v-row>
                    </div>
                </div>
                <div class="d-inline-block pa-0 ml-3 align-self-start" style="width:600px;font-size:0.9rem !important;">  
                    <div class="d-flex align-center mt-2">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">전체 치료 주간 현황</span>
                    </div>
                    <v-row class="pa-0 ma-0" no-gutters>
                        <v-col cols="12">
                            <div class="d-flex pa-0 mr-2__5" style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:16%">                                
                                    <span class="black--text font-weight-medium">치료시간</span>
                                </div>
                                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:12%;border-left: 1px solid #dbdbdb !important;">                                
                                    <span class="black--text font-weight-medium">월</span>
                                </div>
                                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:12%;border-left: 1px solid #dbdbdb !important;">                                
                                    <span class="black--text font-weight-medium">화</span>
                                </div>
                                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:12%;border-left: 1px solid #dbdbdb !important;">                                
                                    <span class="black--text font-weight-medium">수</span>
                                </div>
                                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:12%;border-left: 1px solid #dbdbdb !important;">                                
                                    <span class="black--text font-weight-medium">목</span>
                                </div>
                                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:12%;border-left: 1px solid #dbdbdb !important;">                                
                                    <span class="black--text font-weight-medium">금</span>
                                </div>
                                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:12%;border-left: 1px solid #dbdbdb !important;">                                
                                    <span class="black--text font-weight-medium">토</span>
                                </div>
                                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:12%;border-left: 1px solid #dbdbdb !important;">                                
                                    <span class="black--text font-weight-medium">일</span>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    <div v-if="allThrWkList.length>0" style="height:383px;overflow-y:scroll">
                        <v-row v-for="(item, k) in allThrWkList" :key="phyThrPlnDtlItems.length+k" class="pa-0 ma-0" no-gutters>
                            <v-col class="d-flex pa-0 ma-0" cols="12" style="border-bottom: 1px solid #dbdbdb !important;">
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:16%">                                
                                    {{item.thrTm}}
                                </div>
                                <div class="pa-1 ma-0" style="width:12%;border-left: 1px solid #dbdbdb !important;">                                                                    
                                    <div v-for="(list, a) in item.thrMonNm" :key="a">
                                        {{list}}
                                    </div>
                                </div>
                                <div class="pa-1 ma-0" style="width:12%;border-left: 1px solid #dbdbdb !important;">                                                                   
                                    <div v-for="(list, b) in item.thrTueNm" :key="b">
                                        {{list}}
                                    </div>
                                </div>
                                <div class="pa-1 ma-0" style="width:12%;border-left: 1px solid #dbdbdb !important;"> 
                                    <div v-for="(list, c) in item.thrWedNm" :key="c">
                                        {{list}}
                                    </div>
                                </div>
                                <div class="pa-1 ma-0" style="width:12%;border-left: 1px solid #dbdbdb !important;"> 
                                    <div v-for="(list, d) in item.thrThuNm" :key="d">
                                        {{list}}
                                    </div>
                                </div>
                                <div class="pa-1 ma-0" style="width:12%;border-left: 1px solid #dbdbdb !important;"> 
                                    <div v-for="(list, e) in item.thrFriNm" :key="e">
                                        {{list}}
                                    </div>
                                </div>
                                <div class="pa-1 ma-0" style="width:12%;border-left: 1px solid #dbdbdb !important;">  
                                    <div v-for="(list, f) in item.thrSatNm" :key="f">
                                        {{list}}
                                    </div>
                                </div>
                                <div class="pa-1 ma-0" style="width:12%;border-left: 1px solid #dbdbdb !important;">
                                    <div v-for="(list, g) in item.thrSatNm" :key="g">
                                        {{list}}
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-else >
                        <v-row class="pa-0 ma-0" no-gutters>
                            <v-col class="d-flex pa-0 ma-0" cols="12" style="border-bottom: 1px solid #dbdbdb !important;">
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%">                                
                                    데이터가 없습니다.
                                </div>                                
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </div>
        </v-form> 
        <v-dialog v-model="dialog[0]" content-class="round" persistent max-width="600">
            <PhyscThrpyType 
                ref="physcThrpyType" 
                :physcThrpyTypePk="physcThrpyTypePk"
                :thrClcd="thrClcd"
                :thrMthdcd="thrMthdcd"
                @cdReload="cdReload"
                @isClose="isModalType" 
                @onApply="onModalApply">
            </PhyscThrpyType>
        </v-dialog>   
    </v-sheet>
</template>
<script>
import CmmDateComp from '../commons/CmmDateComp.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';

import { getMultiStcd } from '../../api/index.js';
import {getPhyThrPlnDtlList, insBnftOfrPhyThrPln} from '../../api/bnftSrv.js';
import PhyscThrpyType from './PhyscThrpyType.vue';

export default {
    name: 'PhyscThrpyPln2',

    props : {        
        inqYYYY: Number,
    },

    components: {
        CmmDateComp,
        CmmTimeField,
        PhyscThrpyType
    },

    computed: {        
    },  

    watch:{
    },

    created:function(){  
    },
    
    mounted: function(){
        
    },
        
    methods: {   
        onShow(){  
               
        },
        setStyle:function(value){
            let style = "";
            if(value > 13){
                style="width:200px;"
            }else if(value > 10 && value <= 13){
                style="width:160px;"
            }else if(value > 5 && value <= 10){
                style="width:130px;"
            }else if(value == 2){
                style="width:80px;"
            }else{
                style="width:100px;"
            }
            return style;
        },   
        
        getPhyThrPlnInfoAfter(data){ 
            this.onCodeload()
            this.phyThrPlnPk = 0 
            this.phyThrPlnItems = Object.assign({})
            this.phyThrPlnDtlItems.splice(0)

            if(data != undefined && data != ''){
                
                this.phyThrPlnItems = data        

                if(this.$parent.$parent.$parent.$parent.getPreData){    
                    this.phyThrPlnItems.thrStrt = ''
                    this.phyThrPlnItems.thrEnd  = ''
                }else{
                    this.phyThrPlnItems.thrStrt = data.thrStrt?.beforeDateFormatHyp()
                    this.phyThrPlnItems.thrEnd = data.thrEnd?.beforeDateFormatHyp()  
                }

                this.phyThrPlnPk =  data.phyThrPlnPk 
                this.mdfRsn = data.mdfRsn
                this.getPlnDtlList()
            }else{
                this.$parent.$parent.$parent.$parent.getPreData = false
            }

            this.$refs.tabform.resetValidation();
        },

        getAllThrWkListAfter(data){            
            this.allThrWkList.splice(0)
            data.forEach(el => {                
                this.allThrWkList.push(el)
            });
            this.$refs.tabform.resetValidation();
        },

        getPlnDtlList(){
            
            if(this.phyThrPlnPk !='' && this.phyThrPlnPk > 0){                
                let fcltyNum = this.$store.getters.getFcltyNum
                let state = {
                    phyThrPlnPk : this.phyThrPlnPk,
                }

                getPhyThrPlnDtlList(fcltyNum,state)
                .then((response) => ( this.getPlnDtlListAfter(response.data)))
                .catch((error) => console.log(error))
            }
        },

        getPlnDtlListAfter(data){
            this.phyThrPlnDtlItems = data
            this.phyThrPlnDtlItems.map((x,k)=>{
                x.thrTmStrt = x.thrTmHhStrt+':'+x.thrTmMmStrt
                x.thrTmEnd = x.thrTmHhEnd+':'+x.thrTmMmEnd     
                this.rtimes1[k] = false
                this.mtimes1[k] = false
                this.rtimes2[k] = false
                this.mtimes2[k] = false
            })

            this.$refs.tabform.resetValidation();

            if(this.$parent.$parent.$parent.$parent.getPreData) this.phyThrPlnItems.phyThrPlnPk = 0
            if(this.$parent.$parent.$parent.$parent.getPreData) this.phyThrPlnPk = 0
            if(this.$parent.$parent.$parent.$parent.getPreData) this.$parent.$parent.$parent.$parent.getPreData = false
        },
        
        onClose: function () {
            this.$emit('modalClose', this.dsrEvltn)
        },

        onCodeload:function(){
            let fcltyNum = this.$store.getters.getFcltyNum
            getMultiStcd(['37','92','93'], fcltyNum)
                .then((response) => ( this.codeMapping(response.data) ))
                .catch((error) => console.log(error))
        },

        onforce(key, idx){
            if(key == 1) this.mtimes1[idx] = false
            else if(key == 2) this.mtimes2[idx] = false
            ++this.forceRender
        },

        codeMapping:function(res){
            this.codeItem37 = res["vCd37"]
            this.codeItem92 = res["vCd92"]
            this.codeItem93 = res["vCd93"]
        },

        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },

        reset(){
            this.$refs.tabform.reset()
        },

        insOfrPhyThrPln(data){
            if(this.$refs.tabform.validate()){
                let ofrPlnHisInfo = {
                    wrtDt :  data.wrtDt?.afterDateFormatHyp(),
                    fcltyNum : this.$store.getters.getFcltyNum,
                    userNm:this.$store.getters.getUserNm,
                    bnMmbrPk : data.bnMmbrPk,
                    plnYyyy: data.inqYYYY,
                    bnftOfrPlnHisPk: data.bnftOfrPlnHisPk,
                    wrtr : data.wrtr,
                    mdfClcd : data.mdfClcd,
                    aplStrt : data.aplStrt?.afterDateFormatHyp(), 
                    aplEnd : data.aplEnd?.afterDateFormatHyp(),
                    plnClcd:'202',
                    mdfRsn      : this.mdfRsn,
                }


                let phyThrPlnInfo = {
                    fcltyNum    : this.$store.getters.getFcltyNum,
                    userNm      :this.$store.getters.getUserNm,
                    bnMmbrPk    : data.bnMmbrPk,
                    wrtDt       :  data.wrtDt?.afterDateFormatHyp(),
                    wrtr        : data.wrtr,
                    thrStrt     : this.phyThrPlnItems.thrStrt?.afterDateFormatHyp(), 
                    thrEnd      : this.phyThrPlnItems.thrEnd?.afterDateFormatHyp(),
                    thrTimes    : this.phyThrPlnItems.thrTimes,
                    thrGls      : this.phyThrPlnItems.thrGls,
                    thrPart     : this.phyThrPlnItems.thrPart,
                    phyThrPlnPk : this.phyThrPlnItems.phyThrPlnPk,
                    prblm       : this.phyThrPlnItems.prblm
                }

                if(this.phyThrPlnDtlItems.length > 0){
                    this.phyThrPlnDtlItems.forEach(x=>{                        
                        if(x.thrTmStrt != ""){
                            let thrTmStrt = x.thrTmStrt.split(":")
                            x.thrTmHhStrt = thrTmStrt[0]
                            x.thrTmMmStrt = thrTmStrt[1]
                        }

                        if(x.thrTmEnd != ""){
                            let thrTmEnd = x.thrTmEnd.split(":")
                            x.thrTmHhEnd = thrTmEnd[0]
                            x.thrTmMmEnd = thrTmEnd[1]                            
                        }
                    })
                }

                insBnftOfrPhyThrPln(ofrPlnHisInfo,phyThrPlnInfo,this.phyThrPlnDtlItems)
                    .then((response) => ( this.insOfrPhyThrPlnAfter(response.data)))
                    .catch((error) => console.log(error))
            }
            
        },
        insOfrPhyThrPlnAfter: function (res) {
            this.$emit('physcThrpyPlnTrigger', res)            
        },
        selFormValidate:function(){
            return this.$refs.tabform.validate()
        },
        setMdfRsn:function(value){            
            this.mdfRsn = value
        },
        selMdfRsn:function(){            
            return this.mdfRsn
        },
        selPhyThrPlnInfo:function(){
            let phyThrPlnInfo = {
                fcltyNum : this.$store.getters.getFcltyNum,
                thrStrt : this.phyThrPlnItems.thrStrt?.afterDateFormatHyp(), 
                thrEnd : this.phyThrPlnItems.thrEnd?.afterDateFormatHyp(),
                thrTimes : this.phyThrPlnItems.thrTimes,
                thrGls : this.phyThrPlnItems.thrGls,
                thrPart : this.phyThrPlnItems.thrPart,
                phyThrPlnPk : this.phyThrPlnItems.phyThrPlnPk,
                prblm       : this.phyThrPlnItems.prblm,
            }
            return phyThrPlnInfo
        },
        selPhyThrPlnDtlList:function(){
            let phyThrPlnDtlList = []

            if(this.phyThrPlnDtlItems.length > 0){
                this.phyThrPlnDtlItems.forEach(x=>{                        
                    if(x.thrTmStrt != ""){
                        let thrTmStrt = x.thrTmStrt.split(":")
                        x.thrTmHhStrt = thrTmStrt[0]
                        x.thrTmMmStrt = thrTmStrt[1]
                    }

                    if(x.thrTmEnd != ""){
                        let thrTmEnd = x.thrTmEnd.split(":")
                        x.thrTmHhEnd = thrTmEnd[0]
                        x.thrTmMmEnd = thrTmEnd[1]                            
                    }

                    phyThrPlnDtlList.push(x)
                })
            }

            return phyThrPlnDtlList
        },

        addRow(){
            let obj = {                
                thrClcd : '1',
                thrFriYn : '',
                thrMonYn : '',
                thrMthdcd : [],
                thrSatYn : '',
                thrSunYn : '',
                thrThuYn : '',
                thrTmEnd : '',
                thrTmHhEnd : '',
                thrTmHhStrt : '',
                thrTmMmEnd : '',
                thrTmMmStrt : '',
                thrTmStrt : '',
                thrTueYn : '',
                thrWedYn : '',
            }
            this.phyThrPlnDtlItems.push(obj)
        },
        delRow(key){
            this.phyThrPlnDtlItems.splice(key, 1)
        },
        onModalType(key, typeVal, selecter){
            this.key = key
            this.thrClcd = typeVal

            this.thrMthdcd.splice(0)
            selecter.forEach(el => {
                this.thrMthdcd.push(el)
            });
            this.physcThrpyTypePk = 1
            this.dialog.splice(0, 1, true)
        },
        isModalType: function () {
            this.thrClcd = ''
            this.thrMthdcd.splice(0)
            this.physcThrpyTypePk = -1
            this.dialog.splice(0, 1, false)
        },
        resetType:function(key){
            this.phyThrPlnDtlItems[key].thrMthdcd.splice(0)
        },
        cdReload:function(){     
            let fcltyNum = this.$store.getters.getFcltyNum
            getMultiStcd(['92','93'], fcltyNum)
                    .then((response) => ( this.cdReloadAfter(response.data) ))
                    .catch((error) => console.log(error))
        },
        cdReloadAfter:function(res){
            this.codeItem92 = res["vCd92"]
            this.codeItem93 = res["vCd93"]
            this.getPlnDtlList()
        },
        onModalApply(selecter){            
            this.phyThrPlnDtlItems[this.key].thrMthdcd = selecter
        }
    },
    
    data: () => ({ 
        key:0,
        entTm:'',
        mdfRsn:'',                      //변경사유
        data:[], 
        menu: [false,false,false,false],
        menu1: false,
        menu2: false,
        dialog:[false],
        rtimes1:[],
        mtimes1:[],
        rtimes2:[],
        mtimes2:[],
        phyThrPlnDtlItems:[],
        phyThrPlnItems:{},
        allThrWkList:[], 
        codeItem37:[],  
        codeItem92:[], //물리치료코드
        codeItem93:[], //작업치료코드
        phyThrPlnPk:0,
        physcThrpyTypePk:-1,
        thrClcd:'',
        thrMthdcd:[],
        forceRender:0,
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },           
    }),
};
</script>