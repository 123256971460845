<template>
    <v-sheet class="" rounded="lg" width="100%">
        <v-form ref="form" lazy-validation>
            <v-row style="border-top: 1px solid #dbdbdb !important; height: 50px;" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01" cols="2">
                    <span class="black--text font-weight-medium ">운영일시</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-left align-center" cols="3">
                    <span class="d-inline-block pl-2" style="width: 100%; ">
                        <span v-if="items.opDt != null">{{opDt}} / {{opTmStrt}} ~ {{ opTmEnd }}</span>

                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center greyE01" cols="2">
                    <span class="black--text font-weight-medium py-2">프로그램명</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-left align-center" cols="5">
                    <!-- 지표 textfield -->
                    <span class="d-inline-block ml-2" style="width:80%;">
                        {{ items.prgNm }}
                    </span>
                </v-col>
            </v-row>
            <v-row style="border-top: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;"
                no-gutters>
                <v-col class="d-flex justify-center align-center greyE01" cols="2">
                    <span class="black--text font-weight-medium ">프로그램 수급자 그룹</span>
                </v-col>
                <v-col class="pa-1 ma-0 justify-left align-center" cols="3">
                    <div class="pt-1 overflow-y-auto" style="height:50px;">
                        <v-chip v-for="(itm, j) in items.bnfcrgrNm" :key="j" class="pa-2 mr-1" label color="grey" outlined
                            small style="background-color: white !important;">
                            {{ itm }}
                        </v-chip>
                    </div>
                </v-col>
                <v-col class="d-flex justify-center align-center greyE01" cols="2">
                    <span class="black--text font-weight-medium py-2">참여/등록인원(참여율)</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-left align-center" cols="2">
                    <!-- 지표 textfield -->
                    <span class="d-inline-block ml-2" style="width:150px;">
                        {{ items.prtCpDesc }}
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center greyE01" cols="1">
                    <span class="black--text font-weight-medium py-2">진행자</span>
                </v-col>
                <v-col class="d-flex justify-left align-center" cols="2">
                    <span class="ml-2">{{ items.wrtr }}</span>
                    <!-- <span class="d-inline-block pl-2" style="width: 90px;">
                        <v-text-field v-model="items.wrtr" color="blue" ref="info" :rules="[rules.required]" hide-details
                            outlined dense :key="forceRender" readonly></v-text-field>
                    </span>
                    <v-btn
                        class="ml-1 brown lighten-3 " min-width="30" height="26"
                        style="padding: 0px 1px 0px 2px !important; margin-bottom: 2px;" @click="onEmpSlct" small>
                        <v-icon color="white" size="20">mdi-account-edit</v-icon>
                    </v-btn> -->
                </v-col>
            </v-row>
            <v-row :class="['pa-0 mt-5', items1.length > 14 ? 'mr-2__5' : '']"
                style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important; height: 66px;"
                no-gutters>
                <v-col class="greyE01 d-flex pa-0 ma-0" cols="12">
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:4%">
                        <span class="black--text font-weight-medium">연번</span>
                    </div>
                    <div class="d-flex justify-center align-center pa-1 ma-0"
                        style="width:9%;border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">수급자</span>
                    </div>
                    <div class="d-flex justify-center align-center pa-1 ma-0"
                        style="width:4%; border-left: 1px solid #dbdbdb !important; line-height: 1.2;">
                        <span class="black--text font-weight-medium">참여<br />여부</span>
                    </div>
                    <div class="pa-0 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;">
                            <span class="black--text font-weight-medium">참여도</span>
                        </div>
                        <v-divider></v-divider>
                        <v-row v-if="$parent.$parent.$parent.$parent.$parent.prtCd3.length > 0" class="pa-0" no-gutters>
                            <v-col
                                v-for="i in 3" :key="i"
                                :class="['txtAlgnMid py-1', i < 3 ? 'tableBR' : '' ]"
                                cols="4">
                                <span class="black--text font-weight-medium">
                                    {{ $parent.$parent.$parent.$parent.$parent?.prtCd3[i-1].valcdnm }}
                                </span>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="pa-0 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;">
                            <span class="black--text font-weight-medium">만족도</span>
                        </div>
                        <v-divider></v-divider>
                        <v-row v-if="$parent.$parent.$parent.$parent.$parent.prtCd3.length > 0" class="pa-0" no-gutters>
                            <v-col
                                v-for="i in 3" :key="i"
                                :class="['txtAlgnMid py-1', i < 3 ? 'tableBR' : '' ]"
                                cols="4">
                                <span class="black--text font-weight-medium">
                                    {{ $parent.$parent.$parent.$parent.$parent?.prtCd3[i-1].valcdnm }}
                                </span>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="pa-0 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;">
                            <span class="black--text font-weight-medium">수행도</span>
                        </div>
                        <v-divider></v-divider>
                        <v-row v-if="$parent.$parent.$parent.$parent.$parent.prtCd3.length > 0" class="pa-0" no-gutters>
                            <v-col
                                v-for="i in 3" :key="i"
                                :class="['txtAlgnMid py-1', i < 3 ? 'tableBR' : '' ]"
                                cols="4">
                                <span class="black--text font-weight-medium">
                                    {{ $parent.$parent.$parent.$parent.$parent?.prtCd3[i-1].valcdnm }}
                                </span>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="d-flex justify-center align-center pa-1 ma-0"
                        style="width:56%; border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">특이사항</span>
                    </div>
                </v-col>
            </v-row>
            <div class="overflow-y-auto" style="height: 424px;">
                <v-row v-for="(item, i) in items1" :key="i" class="pa-0"
                    style="border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                    <v-col class=" d-flex pa-0 ma-0" cols="12" style="height: 33px;">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:4%">
                            {{ item.rn }}
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0"
                            style="width:9%;border-left: 1px solid #dbdbdb !important;">
                            <v-text-field v-model="item.name" color="blue" height="28" hide-details outlined dense></v-text-field>
                        </div>
                        <div class="d-flex justify-center align-center pl-3 py-1 ma-0"
                            style="width:4%; border-left: 1px solid #dbdbdb !important;">
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                v-model="item.prtcpYn" true-value="1" false-value="2"
                                @click="radio(i)" hide-details>
                            </v-checkbox>
                        </div>
                        <div class="pa-0 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">
                            <v-radio-group v-model="item.prtcpCd" row class="d-flex radio-dft-Theme pa-0 ma-0"
                                style="width:100%; height: 100%;" :disabled="disabled[i]" hide-details>
                                <v-radio value="1" class="d-flex justify-center align-center pa-1 ma-0"
                                    style="width:33.3333333333%; border-right: 1px solid #dbdbdb !important;"></v-radio>
                                <v-radio value="2" class="d-flex justify-center align-center pa-1 ma-0"
                                    style="width:33.3333333333%; border-right: 1px solid #dbdbdb !important;"></v-radio>
                                <v-radio value="3" class="d-flex justify-center align-center pa-1 ma-0"
                                    style="width:33.3333333333%;"></v-radio>
                            </v-radio-group>
                        </div>
                        <div class="pa-0 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">
                            <v-radio-group v-model="item.stsfcCd" row class="d-flex radio-dft-Theme pa-0 ma-0"
                                style="width:100%; height: 100%;" :disabled="disabled[i]" hide-details>
                                <v-radio value="1" class="d-flex justify-center align-center pa-1 ma-0"
                                    style="width:33.3333333333%; border-right: 1px solid #dbdbdb !important;"></v-radio>
                                <v-radio value="2" class="d-flex justify-center align-center pa-1 ma-0"
                                    style="width:33.3333333333%; border-right: 1px solid #dbdbdb !important;"></v-radio>
                                <v-radio value="3" class="d-flex justify-center align-center pa-1 ma-0"
                                    style="width:33.3333333333%;"></v-radio>
                            </v-radio-group>
                        </div>
                        <div class="pa-0 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">
                            <v-radio-group v-model="item.perfCd" row class="d-flex radio-dft-Theme pa-0 ma-0"
                                style="width:100%; height: 100%;" :disabled="disabled[i]" hide-details>
                                <v-radio value="1" class="d-flex justify-center align-center pa-1 ma-0"
                                    style="width:33.3333333333%; border-right: 1px solid #dbdbdb !important;"></v-radio>
                                <v-radio value="2" class="d-flex justify-center align-center pa-1 ma-0"
                                    style="width:33.3333333333%; border-right: 1px solid #dbdbdb !important;"></v-radio>
                                <v-radio value="3" class="d-flex justify-center align-center pa-1 ma-0"
                                    style="width:33.3333333333%;"></v-radio>
                            </v-radio-group>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0"
                            style="width:56%; border-left: 1px solid #dbdbdb !important;">
                            <v-text-field v-model="item.spclNote" color="blue" height="28" hide-details outlined dense></v-text-field>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-form>

        <v-dialog v-model="menu[1]" eager content-class="round" persistent max-width="800">
            <EmpSelector @isClose="isModal(1)" @outEmp="getEmpInfo" :prtWrtDt="prtWrtDt"></EmpSelector>
        </v-dialog>
    </v-sheet>
</template>

<script>
import EmpSelector from '../../components/commons/EmpSelector.vue';

import {
    selPrgOpRecBnfcrInfo, selPrgOpRecBnfcrList, insPrgPrtcpBnfcrMulti, delPrgPrtcpBnfcr, selPrgOpRecInfo
} from '../../api/prgrm.js';

export default {
    name: 'PrgrmRecTab2',

    components: {
        EmpSelector,
    },

    props: {
  
    },

    created: function () {

    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch: {
        getSubEvt: function (bol) {
            if(bol && this.empChc){
                let obj = this.$store.getters.getSubOutObj
                this.items.wrtr = obj.name
                
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
                this.empChc = false
            }
        },
    },

    methods: {
        // 프로그램 운영기록 한건 조회
        selPrgOpRecInfo: function (pk) {
            selPrgOpRecInfo(this.$store.getters.getFcltyNum, pk)
                .then((response) => (this.selPrgOpRecInfoAfter(response.data)))
                .catch((error) => console.log('connect error prgrm/selPrgOpRecInfo : ' + error))
        },
        selPrgOpRecInfoAfter: function (res) { 
            this.opDt = res.opDt?.beforeDateFormatDot()
            this.opTmStrt = res.opTmHhStrt+':'+res.opTmMmStrt
            this.opTmEnd = res.opTmHhEnd+':'+res.opTmMmEnd
        },
        //프로그램운영 기록 수급자별 기본 조회
        selPrgOpRecBnfcrInfo: function (pk) {
            selPrgOpRecBnfcrInfo(this.$store.getters.getFcltyNum, pk)
                .then((response) => (this.selPrgOpRecBnfcrInfoAfter(response.data)))
                .catch((error) => console.log('connect error prgrm/selPrgOpRecBnfcrInfo : ' + error))
        },
        selPrgOpRecBnfcrInfoAfter: function (res) {
            if(typeof res.opDt != 'undefined'){
                this.prtWrtDt = res.opDt
                res.opDtDot = this.$moment(res.opDt, 'YYYYMMDD').format('YYYY.MM.DD')
            }
            this.items = res

            this.selPrgOpRecBnfcrList(
                this.$parent.$parent.$parent.$parent.$parent.prgOpRecPk,
                this.$parent.$parent.$parent.$parent.$parent.prgInfPk
            )  
        },
        //프로그램운영 기록 수급자평가 다건 조회
        selPrgOpRecBnfcrList: function (recpk, prgInfPk) {
            this.items1.splice(0)

            selPrgOpRecBnfcrList(
                this.$store.getters.getFcltyNum, prgInfPk, recpk, this.items.opDt, this.items.opTmHhStrt, this.items.opTmMmStrt
            )
                .then((response) => (this.selPrgOpRecBnfcrListAfter(response.data)))
                .catch((error) => console.log('connect error prgrm/selPrgOpRecBnfcrList : ' + error))
        },
        selPrgOpRecBnfcrListAfter: function (res) {
            if(res?.length > 0){
                res.forEach(e => {
                    if (e.prtcpYn == "1") 
                        this.disabled[e.rn - 1] = false
                    else if (e.prtcpYn == null)
                        this.disabled[e.rn - 1] = true

                    if(e.spclNote == null){
                        if (e.aplctClcd == "1")
                            e.spclNote = '외출'
                        else if (e.aplctClcd == "2")
                            e.spclNote = '외박'
                    }
                });

                this.items1 = res
            }
        },
        onSave: function () {
            if (this.$refs.form.validate()) {
                let obj = {
                    list: [],
                }
                this.items1.forEach(e => {
                    this.$emit('progressOff')
                    let item = {
                        prgOpRecPk: this.$parent.$parent.$parent.$parent.$parent.prgOpRecPk,
                        bnMmbrPk: e.bnMmbrPk,
                        prtcpYn: e.prtcpYn,
                        prtcpCd: e.prtcpCd,
                        stsfcCd: e.stsfcCd,
                        perfCd: e.perfCd,
                        spclNote: e.spclNote,
                        userNm: this.$store.getters.getUserNm,
                        fcltyNum: this.$store.getters.getFcltyNum,
                        prgPrtcpBnfcrPk: e.prgPrtcpBnfcrPk,
                    }
                    obj.list.push(item)
                });
    
                insPrgPrtcpBnfcrMulti(obj)
                    .then((response) => (this.insPrgPrtcpBnfcrMultiAfter(response.data)))
                    .catch((error) => console.log('connect error prgrm/insPrgPrtcpBnfcrMulti : ' + error))
            }else{
                let obj = {}
                obj.actv = true                             // 경고 노출여부
                obj.tmr = 2500                              // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'                        // 경고 유형 (success, info, warning, error)
                obj.title = '필수 항목을 입력해주세요.'       // 경고 타이틀
                obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },
        insPrgPrtcpBnfcrMultiAfter: function (res) {
            this.$emit('prgrmRecTrigger', 'ins', res)
        },
        //프로그램 참여 수급자 삭제
        onDelete() {
            if (this.$parent.$parent.$parent.$parent.$parent.prgOpRecPk != undefined) {
                let obj = {
                    fcltyNum: this.$store.getters.getFcltyNum,
                    prgOpRecPk: this.$parent.$parent.$parent.$parent.$parent.prgOpRecPk,
                    userNm: this.$store.getters.getUserNm,
                }
                delPrgPrtcpBnfcr(obj)
                    .then((response) => (this.delPrgPrtcpBnfcrAfter(response.data)))
                    .catch((error) => console.log(error))
            }
        },
        delPrgPrtcpBnfcrAfter(res) {
            this.$emit('prgrmRecTrigger', 'delRe', res)
        },
        onModal: function (key) {
            this.menu.splice(key, 1, true)
        },
        isModal: function (key) {
            this.menu.splice(key, 1, false)
        },
        getEmpInfo: function (obj) {
            this.items.wrtr = obj[0].name
        },
        onEmpSlct: function () {
            let obj = {}
            obj.empTab = true
            obj.extEmpTab = false
            obj.wrtDt = this.$moment().format('YYYYMMDD')

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 700, prsst: true, typ: '3001', inObj: obj, outObj: null, runEvt: false,
            });

            this.empChc = true
        },
        resetPage: function () {
            this.items1 = [Object.assign({}, { bnfcrgrNms: [], prgNm: '', fncRcvCd: [], plc: "", mc: "", mtrl: "", prgCntnt: "", imprvRfl: "", imprvOpn: "" })]
            this.opDt = ''
            this.opTmStrt = ''
            this.opTmEnd = ''
            this.idxCdNm = ''
            this.idxCd = ''
            this.images = null
            this.eval = ''
            this.$refs.form.resetValidation()
        },
        radio: function (key) {
            if (this.items1[key].prtcpYn == 1) {
                this.disabled[key] = false
                this.items1[key].prtcpCd = '1'
                this.items1[key].stsfcCd = '1'
                this.items1[key].perfCd = '1'
            } else if (this.items1[key].prtcpYn == 2) {
                this.disabled[key] = true
                this.items1[key].prtcpCd = ''
                this.items1[key].stsfcCd = ''
                this.items1[key].perfCd = ''
            }
        },
    },

    data: () => ({
        prtWrtDt: '',
        empChc: false,
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !(value == '선택' || value == '') || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },
        headers: [
            { text: '연번', value: '', align: 'center' },
            { text: '수급자', value: '', align: 'center' },
            { text: '참여 여부', value: '', align: 'center' },
            { text: '상', value: '', align: 'center' },
            { text: '중', value: '', align: 'center' },
            { text: '하', value: '', align: 'center' },
            { text: '상', value: '', align: 'center' },
            { text: '중', value: '', align: 'center' },
            { text: '하', value: '', align: 'center' },
            { text: '상', value: '', align: 'center' },
            { text: '중', value: '', align: 'center' },
            { text: '하', value: '', align: 'center' },
            { text: '특이사항', value: '', align: 'center' },
        ],
        items: {},
        items1: [{
            bnMmbrPk: 0,
            fcltyNum: 0,
            perfCd: '',
            prgOpRecPk: 0,
            prgPrtcpBnfcrPk: 0,
            prtcpCd: '',
            prtcpYn: '',
            spclNote: '',
            stsfcCd: '',
            userNm: '',
        }],
        forceRender: 0,
        dialog: [false,],
        index: {},
        menu: [false],
        disabled: [],
        opDt:'',
        opTmStrt: '',
        opTmEnd: '',
    }),
};
</script>