<template>
    <v-sheet class="rounded-lg rounded-l-0 pa-2" color="" min-width="1280" max-width="1280" height="835">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="">
            <div class="text-h4 d-flex align-center pr-2">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">1-5. 전체수급자현황</span>
                <v-spacer></v-spacer>
                <v-btn v-for="(list, j) in outlinedBtn" :key="j" @click = "onClickBtn(list.text)"
                    :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded outlined>
                    <v-icon style="padding-top: 2px;" small>{{list.icon}}</v-icon>
                    <span class="ml-1" style="font-size: 1rem;">{{list.text}}</span>
                </v-btn>
            </div>
        </div>
        <div class="pl-1 mt-5">
            <v-tabs
                v-model="tab"
                class="tab-dft-Theme" active-class="black"
                center-active hide-slider>
                <v-tab v-for="item in tabItems" :class="item.class" :key="item.tab" :disabled="item.enter">{{item.tab}}</v-tab>
            </v-tabs>

            <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>

            <v-tabs-items v-model="tab">
                <v-tab-item eager transition="fade-transition">
                    <v-row class="mt-3 mx-3">
                        <exBtn :btnData="exBtn[0]" @btnClosed="expandClose" ref="exBtnClose0" @changeCheck="filterSearchD1" />
                        <exBtn class="ml-1" :btnData="exBtn[1]" @btnClosed="expandClose" ref="exBtnClose1" @changeCheck="filterSearchD2" />
                        <exBtn class="ml-1" :btnData="exBtn[2]" @btnClosed="expandClose" ref="exBtnClose2" @changeCheck="filterSearchD3" />
                        <span class="d-inline-block ml-2" style="width: 130px;">
                            <v-text-field class="ma-0 pa-0 nameSearch" background-color="white" append-icon="mdi-magnify" width="100px" height="35px"
                                hide-details single-line rounded outlined dense filled label="이름조회" @input="setSearchText">
                            </v-text-field>
                        </span>
                        <v-spacer></v-spacer>
                        <span class="blue004--text font-weight-medium">※추가적인 항목은 하단 스크롤을 이용해주세요.</span>     
                    </v-row>
                    <v-row class="ma-0 pa-0 mt-3 mx-3">
                        <v-data-table
                            class="mt-2 stts" locale="ko" height="660" item-key="rn"
                            :search="schDummy" :custom-filter="customFilter" :headers="bnfcrHeaders" :items="bnfcrAll"
                            fixed-header disable-pagination hide-default-footer dense>

                            <template v-slot:item='{ item }'>
                        
                                <tr class="fixedCol">
                                    <td class="fixedTable txtAlgnMid fontOneRem removeBB fixPstn0 zPstn2">
                                        <span>{{item.rn}}</span>
                                        <span class="d-inline-block" style="width:18px"></span>
                                    </td>
                                    <td class="fixedTable txtAlgnMid fontOneRem removeBB fixPstn1 zPstn2">
                                        <span class="d-inline-block overflow-text-hidden" style="width:60px">{{item.name}}</span>
                                        <span class="d-inline-block" style="width:18px"></span>
                                    </td>
                                    <td class="fixedTable removeBB fixPstn3 zPstn2">
                                        <v-img class="ic-sex ml-1" v-if="item.gndr == 'F'" src="../../assets/icon/ic-female.svg"></v-img>
                                        <v-img class="ic-sex ml-1" v-else-if="item.gndr == 'M'" src="../../assets/icon/ic-male.svg"></v-img>
                                    </td> 
                                    <td class="txtAlgnMid fontOneRem removeBB">
                                        <span>{{item.age}}</span>
                                        <span class="d-inline-block" style="width:22px"></span>
                                    </td>
                                    <td class="txtAlgnMid fontOneRem removeBB">
                                        <span>{{item.entStcd}}</span>
                                        <span class="d-inline-block" style="width:18px"></span>
                                    </td>
                                    <td class="txtAlgnMid fontOneRem removeBB">
                                        <span>{{item.rsdntBirth}}</span>
                                        <span class="d-inline-block" style="width:18px"></span>
                                    </td>
                                    <td class="txtAlgnMid fontOneRem removeBB">
                                        <span>{{item.lvnRmNm}}</span>
                                        <span class="d-inline-block" style="width:18px"></span>
                                    </td>
                                    <td class="txtAlgnMid fontOneRem removeBB">
                                        <span :class="'d-inline-block ic-level level0'+item.acknwRtng">
                                            {{ item.acknwRtng == '99' ? '외' : item.acknwRtng }}
                                        </span>
                                        <span class="d-inline-block" style="width:18px"></span>
                                    </td>
                                    <td class="txtAlgnMid fontOneRem removeBB">
                                        <span class="d-inline-block overflow-text-hidden" style="width:118px">
                                            {{item.acknwNum1}}&nbsp;&#45;&nbsp;{{item.acknwNum2}}
                                        </span>
                                        <span class="d-inline-block" style="width:18px"></span>
                                    </td>
                                    <td class="txtAlgnMid fontOneRem removeBB">
                                        <span>{{item.acknwPrdStrt}}</span>
                                        <span class="d-inline-block" style="width:16px"></span>
                                    </td>
                                    <td class="txtAlgnMid fontOneRem removeBB">
                                        <span>{{item.acknwPrdEnd}}</span>
                                        <span class="d-inline-block" style="width:16px"></span>
                                    </td>
                                    <td class="txtAlgnMid fontOneRem removeBB">
                                        <span>{{item.entDt}} ({{ item.entTmHh }}:{{ item.entTmMm }})</span>
                                        <span class="d-inline-block" style="width:10px"></span>
                                    </td>
                                    <td class="txtAlgnMid fontOneRem removeBB">
                                        <span>{{item.entStcd == '퇴소' ? item.lvDt + ' (' + item.lvTmHh + ':' + item.lvTmMm + ')' : ''}}</span>
                                        <span class="d-inline-block" style="width:10px"></span>
                                    </td>
                                    <td class="txtAlgnMid fontOneRem removeBB">
                                        <span>{{item.copayRateNm}}</span>
                                        <span class="d-inline-block" style="width:10px"></span>
                                    </td>
                                    <td class="fontOneRem removeBB">
                                        <span>{{item.majorDsss}}</span>
                                    </td>
                                    <td class="txtAlgnMid fontOneRem removeBB">
                                        <span>{{item.grdName}}</span>
                                        <span class="d-inline-block" style="width:18px"></span>
                                    </td>
                                    <td class="txtAlgnMid fontOneRem removeBB">
                                        <span>{{item.rltns}}</span>
                                        <span class="d-inline-block" style="width:18px"></span>
                                    </td>
                                    <td class="txtAlgnMid fontOneRem removeBB">
                                        <span>{{item.grdCelphn_1}}&#45;{{item.grdCelphn_2}}&#45;{{item.grdCelphn_3}}</span>
                                        <span class="d-inline-block" style="width:10px"></span>
                                    </td>
                                </tr>
                            </template>
                            
                        </v-data-table>
                    </v-row>
                </v-tab-item>
                <v-tab-item eager transition="fade-transition">
                    <v-row class="mt-3 pa-0" style="margin-left: 1px;">
                        <exBtn :btnData="exBtn[0]" @btnClosed="expandClose" ref="exBtnClose0" @changeCheck="filterSearchD1" />
                        <exBtn class="ml-1" :btnData="exBtn[1]" @btnClosed="expandClose" ref="exBtnClose1" @changeCheck="filterSearchD2" />
                        <exBtn class="ml-1" :btnData="exBtn[2]" @btnClosed="expandClose" ref="exBtnClose2" @changeCheck="filterSearchD3" />
                        <exBtn class="ml-1" :btnData="exBtn[3]" @btnClosed="expandClose" ref="exBtnClose3" @changeCheck="filterSearchD4" />
                        <exBtn class="ml-1" :btnData="exBtn[4]" @btnClosed="expandClose" ref="exBtnClose4" @changeCheck="filterSearchD5" />
                        <span class="d-inline-block ml-2" style="width: 130px;">
                            <v-text-field class="ma-0 pa-0 nameSearch" background-color="white" append-icon="mdi-magnify" width="100px" height="35px"
                                hide-details single-line rounded outlined dense filled label="이름조회" @input="setSearchText">
                            </v-text-field>
                        </span>
                    </v-row>
                    <v-row class="ma-0 pa-0 mt-3">
                        <v-data-table hide-default-footer dense locale="ko" height="660"
                            class="tableBB mt-2" disable-pagination
                            :search="schDummy" :custom-filter="customFilterE2" :headers="elderHeaders" :items="hmnHltList">

                            <template v-slot:header="props" >
                                <thead>
                                    <th v-for="(list, i) in props.headers" :key="i">{{list.text}}</th>
                                </thead>
                            </template>

                            <template v-slot:item='{ item, index }'>
                        
                                <tr class="fixedCol">
                                    <td class="fixedTable fixedPstn0 txtAlgnMid fontOneRem removeBB">
                                        <span>{{item.rn}}</span>
                                        <span class="d-inline-block" style="width:18px"></span>
                                    </td>
                                    <td class="fixedTable fixedPstn0 txtAlgnMid fontOneRem removeBB">
                                        <span>{{item.name}}</span>
                                        <span class="d-inline-block" style="width:18px"></span>
                                    </td>
                                    <td class="fixedTable fixedPstn2 removeBB">
                                        <v-img class="ic-sex ml-1" v-if="item.gndr == 'F'" src="../../assets/icon/ic-female.svg"></v-img>
                                        <v-img class="ic-sex ml-1" v-else-if="item.gndr == 'M'" src="../../assets/icon/ic-male.svg"></v-img>
                                    </td> 
                                    <td class="txtAlgnMid fontOneRem removeBB">
                                        <span>{{item.age}}</span>
                                        <span class="d-inline-block" style="width:22px"></span>
                                    </td>
                                    <td class="txtAlgnMid fontOneRem removeBB">
                                        <span>{{item.entStcd}}</span>
                                        <span class="d-inline-block" style="width:18px"></span>
                                    </td>
                                    <td class="txtAlgnMid fontOneRem removeBB">
                                        <span>{{item.rsdntBirth}}</span>
                                        <span class="d-inline-block" style="width:18px"></span>
                                    </td>
                                    <td class="txtAlgnMid fontOneRem removeBB">
                                        <span>{{item.lvnRmNm}}</span>
                                        <span class="d-inline-block" style="width:18px"></span>
                                    </td>
                                    <td class="txtAlgnMid fontOneRem removeBB">
                                        <span :class="'d-inline-block ic-level level0'+item.acknwrtng">
                                            {{ item.acknwrtng == '99' ? '외' : item.acknwrtng }}
                                        </span>
                                        <span class="d-inline-block" style="width:18px"></span>
                                    </td>
                                    <td class="txtAlgnMid fontOneRem removeBB">
                                        <span v-if="item.eHmnOfrDt == null || item.eHmnOfrDt == ''" style="color:red;">
                                            <v-btn min-width="56" width="56" height="26" color="red" samll outlined>
                                                <span style="font-size: 0.9rem;" @click="menu.splice(0, 1, true), throwObj=hmnHltList[index]">미작성</span>
                                            </v-btn>
                                        </span>
                                        <span v-else @click="menu.splice(0, 1, true), throwObj=hmnHltList[index]">{{item.eHmnOfrDt}}&nbsp;&#40;{{item.eHmnRcvNm}}&#41;</span>
                                        <span class="d-inline-block" style="width:18px"></span>
                                    </td>
                                    <td class="txtAlgnMid fontOneRem removeBB">
                                        <span>{{item.entHlthChdt}}</span>
                                        <span class="d-inline-block" style="width:18px"></span>
                                    </td>
                                    <td class="txtAlgnMid fontOneRem removeBB">
                                        <span v-if="item.hltChcInfPk == null || item.hltChcInfPk == ''">
                                            <v-btn min-width="56" width="56" height="26" color="red" samll outlined>
                                                <span style="font-size: 0.9rem;" @click="menu.splice(1, 1, true), throwObj=hmnHltList[index]">미작성</span>
                                            </v-btn>
                                        </span>
                                        <span v-else @click="menu.splice(1, 1, true), throwObj=hmnHltList[index]">{{item.hltChcDt}}</span>
                                        <span class="d-inline-block" style="width:18px"></span>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-row>
                </v-tab-item>
                <v-tab-item transition="fade-transition">
                    <BnfcrCntrcStts ref="bnfcrCntrcStts" v-if="tab==2" style="max-height: 721px;"></BnfcrCntrcStts>
                </v-tab-item>                
                <v-tab-item transition="fade-transition">
                    <BnfcrCctvBdrmAllMgmt ref="bnfcrCctvBdrmAllMgmt" v-if="tab==3" style="max-height: 721px;"></BnfcrCctvBdrmAllMgmt>
                </v-tab-item>
            </v-tabs-items>
        </div>
        <v-dialog v-model="menu[0]" eager content-class="round" persistent max-width="600">
            <EldHmnOfrInfo :bnfcrInfo="throwObj" @isClose="menu.splice(0, 1, false), throwObj = { mmbrPk: 0 }"></EldHmnOfrInfo>
        </v-dialog>
        <v-dialog v-model="menu[1]" eager content-class="round" persistent max-width="600">
            <HltChcOfrInfo :bnfcrInfo="throwObj" @isClose="menu.splice(1, 1, false), throwObj = { mmbrPk: 0 }"></HltChcOfrInfo>
        </v-dialog>
        <v-dialog v-model="menu[3]" max-width="1100" persistent>    
            <BizMsgMdl 
                ref="bizMsgMdl"
                :bizMsgData="bizMsgInfo.mmbrPk"
                :bizMsgDocClcd="bizMsgInfo.docClcd"
                :bizMsgBzPk="bizMsgInfo.bzPk"
                :bizMsgShow="menu[3]"
                :bizMsgTitle="bizMsgInfo.bzTitle"
                :bizMsgCntnt="bizMsgInfo.bzCntnt"
                @madalState="onReLoadCntrc">
            </BizMsgMdl>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { getMultiStcd, excelDownload, pdfDownload } from '../../api/index.js';
import { selAllBnfcrList, selHmnHltList } from '../../api/bnfcr.js';
import { selLvnRmInf } from '../../api/opr.js';
import { snglHdrXlsxPrdc } from '../../api/global/cmmExcelJS.js';

import EldHmnOfrInfo from '../../components/bnfcr/EldHmnOfrInfo.vue';
import HltChcOfrInfo from '../../components/bnfcr/HltChcOfrInfo.vue';

import exBtn from '../../components/commons/expandButtonCustom.vue';
import BnfcrCctvBdrmAllMgmt from '../../components/bnfcr/BnfcrCctvBdrmAllMgmt.vue';
import BnfcrCntrcStts from '../../components/bnfcr/BnfcrCntrcStts.vue';
import BizMsgMdl from '../../components/commons/BizMsgMdl.vue';

export default {
    name: 'AllBnfcrStts',
        
    components: {
        exBtn,
        EldHmnOfrInfo,
        HltChcOfrInfo,
        BnfcrCctvBdrmAllMgmt,
        BnfcrCntrcStts,
        BizMsgMdl,
    },

    created: function(){
        this.getCode(['62', '63', '65'])
        this.getLvnRmInf()
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getAllBnfcrList()
            this.getHmnHltList()
            //this.getExcelInfo()
        })
    },

    computed: {

    },

    watch:{ 
        'tab': function () {
            if(this.tab == 0){
                this.outlinedBtn.forEach(items => {
                    items.disabled = false
                });
            }
            else{
                this.outlinedBtn.forEach(items => {
                    items.disabled = true
                });
            }
        },
    },
        
    methods: {
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },

        getLvnRmInf: function () {
            selLvnRmInf(this.$store.getters.getFcltyNum)
                .then((response) => ( this.getLvnRmInfAfter(response.data) ))
                .catch((error) => console.log('connect error /opr/selLvnRmInf : ' + error))
        },

        getExcelInfo: function () {
            excelDownload(this.$store.getters.getFcltyNum, '1', null, null, 0 )
                .then((response) => ( this.getExcelInfoAfter(response.data) ))
                .catch((error) => console.log('connect error /report/excelDownload : ' + error))
        },

        fileDownLoader: function (pth, nm) {
            pdfDownload(pth, nm)
                .then((response) => ( this.fileDownLoaderAfter(response) ))
                .catch((error) => console.log('connect error /cmm/fileTempDownload : ' + error))
        },
        
        getAllBnfcrList: function () {
            selAllBnfcrList(this.$store.getters.getFcltyNum)
                .then((response) => ( this.getAllBnfcrListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selAllBnfcrList : ' + error))
        },

        getHmnHltList: function () {
            selHmnHltList(this.$store.getters.getFcltyNum)
                .then((response) => ( this.getHmnHltListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selHmnHltList : ' + error))
        },

        stCdMapping: function (res) {

            res.vCd62.forEach((code) => {
                this.exBtn[2].expandList.push(code.valcdnm)
            });//end foreach
            res.vCd65.forEach((code) => {
                this.exBtn[0].expandList.push(code.valcdnm)
            });//end foreach
            
            this.vCd62 = res.vCd62
            this.vCd63 = res.vCd63
            this.vCd65 = res.vCd65
        },

        getLvnRmInfAfter: function (res){
            this.lvnRmInf = res

            this.lvnRmInf.forEach((code) => {
                this.exBtn[1].expandList.push(code.lvnRmNm)
            });//end foreach
        },

        getExcelInfoAfter: function (res) {
            this.excelInfo.filePth = res.filePth
            this.excelInfo.fileNm = res.fileNm
            this.excelInfo.orgFileNm = res.orgFileNm
        },

        getAllBnfcrListAfter: function (res) {
            this.bnfcrAll = res
   
            this.bnfcrAll.forEach(items => {
                items.rsdntBirth = items.rsdntBirth?.beforeDateFormatDot()
                items.acknwPrdStrt = items.acknwPrdStrt?.beforeDateFormatDot()
                items.acknwPrdEnd = items.acknwPrdEnd?.beforeDateFormatDot()
                items.entDt = items.entDt?.beforeDateFormatDot()
                items.lvDt = items.lvDt?.beforeDateFormatDot()
                items.acknwNumNm = items.acknwNum1 + ' - ' + items.acknwNum2
                items.ageNm = items.age + '세'
                if(items.acknwRtng == '99')
                    items.acknwRtngNm = '등급외'
                else
                    items.acknwRtngNm = items.acknwRtng + '등급'

                this.vCd65.forEach(code => {
                    if(items.entStcd == code.valcd) items.entStcd = code.valcdnm
                });
            });
            
        },

        getHmnHltListAfter: function (res) {
            this.hmnHltList = res
    
            this.hmnHltList.forEach(items => {
                items.rsdntBirth = items.rsdntBirth.beforeDateFormatDot()
                if(items.eHmnOfrDt != null)
                    items.eHmnOfrDt = items.eHmnOfrDt.beforeDateFormatDot()
                items.entHlthChdt = items.entHlthChdt.beforeDateFormatDot()
                if(items.hltChcDt != null)
                    items.hltChcDt = items.hltChcDt.beforeDateFormatDot()

                this.vCd65.forEach(code => {
                    if(items.entStcd == code.valcd) items.entStcd = code.valcdnm
                });
            });
 
        },

        fileDownLoaderAfter: function (res) {
            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))

            link.href = url
            link.setAttribute('download', `${this.excelInfo.orgFileNm}`)
            link.style.cssText = 'display:none'

            document.body.appendChild(link)

            link.click()
            link.remove()
        },

        expandClose: function (index) {
         
            switch(index) {
                case 0: this.$refs.exBtnClose0.isClose()
                    break;
                case 1: this.$refs.exBtnClose1.isClose()
                    break;
                case 2: this.$refs.exBtnClose2.isClose()
                    break;
                case 3: this.$refs.exBtnClose3.isClose()
                    break;
                case 4: this.$refs.exBtnClose4.isClose()
                    break;
                default:
                    this.$refs.exBtnClose0.isClose()
                    this.$refs.exBtnClose1.isClose()
                    this.$refs.exBtnClose2.isClose()
                    this.$refs.exBtnClose3.isClose()
                    this.$refs.exBtnClose4.isClose()
                    break;
            }

        },

        setSearchText: function (value) {
            this.schField = value

            this.schDummy = this.schDummy + '.'
        },

        filterSearchD1: function (sch) {
                
            if(sch.length == 0) this.schChkField1.push('')

            this.schChkField1 = []
            sch.forEach((item) => {
                this.schChkField1.push(item)
            });

            //배열 공백 제거
            this.schChkField1 = this.schChkField1.filter(function(item) {
                return item !== null && item !== undefined && item !== '';
            });
            //배열 중복 값 제거
            this.schChkField1 = this.schChkField1.filter((val, idx) => {
                return this.schChkField1.indexOf(val) === idx
            });

            this.schDummy = this.schDummy + '.'
        },

        filterSearchD2: function (sch) {
                
            if(sch.length == 0) this.schChkField2.push('')

            this.schChkField2 = []
            sch.forEach((item) => {
                this.schChkField2.push(item)
            });

            //배열 공백 제거
            this.schChkField2 = this.schChkField2.filter(function(item) {
                return item !== null && item !== undefined && item !== '';
            });
            //배열 중복 값 제거
            this.schChkField2 = this.schChkField2.filter((val, idx) => {
                return this.schChkField2.indexOf(val) === idx
            });

            this.schDummy = this.schDummy + '.'
        },

        filterSearchD3: function (sch) {
                
            if(sch.length == 0) this.schChkField3.push('')

            this.schChkField3 = []
            sch.forEach((item) => {
                this.vCd62.forEach((code) => {
                    if(item == code.valcdnm) this.schChkField3.push(code.valcd)
                });
            });

            //배열 공백 제거
            this.schChkField3 = this.schChkField3.filter(function(item) {
                return item !== null && item !== undefined && item !== '';
            });
            //배열 중복 값 제거
            this.schChkField3 = this.schChkField3.filter((val, idx) => {
                return this.schChkField3.indexOf(val) === idx
            });

            this.schDummy = this.schDummy + '.'
        },

        filterSearchD4: function (sch) {
            if(sch == '완료') this.schChkField4 = 'string'
            else if(sch == '미완료') this.schChkField4 = 'object'
            else this.schChkField4 = ''

            this.schDummy = this.schDummy + '.'
        },

        filterSearchD5: function (sch) {
            if(sch == '완료') this.schChkField5 = 'string'
                else if(sch == '미완료') this.schChkField5 = 'object'
                else this.schChkField5 = ''

            this.schDummy = this.schDummy + '.'
        },

        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column

            let col0 = filter.name.includes(this.schField)
            //console.log('text : ' + this.schField)
            
            let col1 = true
            if(this.schChkField1.length != 0){
                this.schChkField1.forEach((stcd, i) => {
                    if(i == 0) col1 = filter.entStcd.includes(stcd)
                    else col1 = col1 || filter.entStcd.includes(stcd)
                    //console.log('array1 : ' + this.schChkField1)
                });
            }

            let col2 = true
            if(this.schChkField2.length != 0){
                this.schChkField2.forEach((lvnRm, i) => {
                    if(i == 0) col2 = filter.lvnRmNm == lvnRm
                    else col2 = col2 || filter.lvnRmNm == lvnRm
                    //console.log('array2 : ' + this.schChkField2)
                });
            }

            let col3 = true
            if(this.schChkField3.length != 0){
                this.schChkField3.forEach((acknwRtng, i) => {
                    if(i == 0) col3 = filter.acknwRtng.includes(acknwRtng)
                    else col3 = col3 || filter.acknwRtng.includes(acknwRtng)
                    //console.log('array3 : ' + this.schChkField3)
                });
            }

            return col0 && col1 && col2 && col3;
        },

        customFilterE2: function (value, search, filter) {
            //value: items, search: search key, filter: array column

            let col0 = filter.name.includes(this.schField)
            //console.log('text : ' + this.schField)
            
            let col1 = true
            if(this.schChkField1.length != 0){
                this.schChkField1.forEach((stcd, i) => {
                    if(i == 0) col1 = filter.entStcd.includes(stcd)
                    else col1 = col1 || filter.entStcd.includes(stcd)
                    //console.log('array1 : ' + this.schChkField1)
                });
            }

            let col2 = true
            if(this.schChkField2.length != 0){
                this.schChkField2.forEach((lvnRm, i) => {
                    if(i == 0) col2 = filter.lvnRmNm == lvnRm
                    else col2 = col2 || filter.lvnRmNm == lvnRm
                    //console.log('array2 : ' + this.schChkField2)
                });
            }

            let col3 = true
            if(this.schChkField3.length != 0){
                this.schChkField3.forEach((acknwrtng, i) => {
                    if(i == 0) col3 = filter.acknwrtng.includes(acknwrtng)
                    else col3 = col3 || filter.acknwrtng.includes(acknwrtng)
                    //console.log('array3 : ' + this.schChkField3)
                });
            }

            let col4 = true
            if(this.schChkField4 == '')
                col4 = true
            else if(typeof filter.eHmnOfrDt == this.schChkField4)
                col4 = true
            else
                col4 = false

            let col5 = true
            if(this.schChkField5 == '')
                col5 = true
            else if(typeof filter.hltChcDt == this.schChkField5)
                col5 = true
            else
                col5 = false

            return col0 && col1 && col2 && col3 && col4 && col5;
        },

        onClickBtn: function (value){
            switch (value) {
                case '엑셀':
                    this.xlsxProd()
                    break;
                case '출력':
                    this.rprtActCall()
                    break;
                default:
                    break;
            }
        },
        xlsxProd: function (){
            let shetNm = '전체수급자현황'
            let title = shetNm + '_' + this.$store.getters.getFcltyNum + '_' + this.$moment().format('YYYYMMDD') 

            // 엑셀 생성 및 다운로드 ( 컬럼리스트, 데이터, 시트명, 파일명 )
            snglHdrXlsxPrdc(this.exlHeader, this.bnfcrAll, shetNm, title)
            this.exlBtn = true
        },
        rprtActCall: function (){
            this.prtThrowObj.list[0].bzClcd.splice(0)
            this.prtThrowObj.list[0].bzPk.splice(0)
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
            this.prtThrowObj.inqClcd = 2
            this.prtThrowObj.bnMmbrPk = 0
            this.prtThrowObj.prtCd = '17'

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
            });
        },
        cnsnFlmnPrt: function (bnPk, bPk, yyyy){
            this.prtThrowObj.list[0].bzPk.splice(0)
            this.prtThrowObj.list[0].bzClcd.splice(0)
            this.prtThrowObj.bnMmbrPk = bnPk
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
            this.prtThrowObj.prtCd = '37'
            this.prtThrowObj.inqYYYY = yyyy
            this.prtThrowObj.list[0].bzPk.push(bPk)

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
            });
        },

        sttrmCndtnPrt: function (bnPk, pk){
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
            this.prtThrowObj.bnMmbrPk = bnPk
            this.prtThrowObj.list[0].bzPk.splice(0)
            this.prtThrowObj.list[0].bzClcd.splice(0)
            this.prtThrowObj.inqYYYY = ''
            this.prtThrowObj.prtCd = '6'

            if(pk != 0)
                this.prtThrowObj.list[0].bzPk.push(pk)

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
            });
        },

        sendMsgAct: function (bnPk,bnName, pk){
            this.bizMsgInfo.mmbrPk.splice(0)
            this.bizMsgInfo.mmbrPk.push(bnPk)
            this.bizMsgInfo.bzCntnt = bnName
            this.bizMsgInfo.bzPk = pk

            this.menu.splice(3, 1, true)
        },

        onReLoadCntrc:function(){
            this.$refs.bnfcrCntrcStts.getAllBnfcrCntrcList()
            this.menu.splice(3, 1, false)
        },

        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    
    data: () => ({
        throwObj: null,
        excelInfo: { filePth: '', fileNm: '', orgFileNm: '' },
        menu: [false, false, false],
        schField: '',
        schDummy: '',
        schChkField1: [],
        schChkField2: [],
        schChkField3: [],
        schChkField4: '',
        schChkField5: '',
        bnfcrAll: [],
        hmnHltList: [],
        vCd62: [],
        vCd63: [],
        vCd65: [],
        lvnRmInf: [],
        tab: 0,
        dialog: [false, false],
        exBtn: [ 
            { index:  0, btnTitle: '입소현황', btnIcon: 'house', exWidth: 104, exHeigth: 130, expandList: [], },
            { index:  1, btnTitle: '생활실', btnIcon: 'bed', exWidth: 124, exHeigth: 100, expandList: [], },
            { index:  2, btnTitle: '등급', btnIcon: 'wheelchair', exWidth: 104, exHeigth: 194, expandList: [], },
            { index:  3, btnTitle: '노인인권제공', btnIcon: 'listStatus', exWidth: 104, exHeigth: 66, expandList: ['완료', '미완료'], },
            { index:  3, btnTitle: '건강검진', btnIcon: 'listStatus', exWidth: 104, exHeigth: 66, expandList: ['완료', '미완료'], },
        ],
        tabItems: [
            { index: 0, tab: '수급자 현황', class: 'fontOneDotTwoRem px-10', enter: false },
            { index: 1, tab: '노인인권, 건강검진', class: 'fontOneDotTwoRem px-10', enter: false },
            { index: 2, tab: '수급자 계약 현황', class: 'fontOneDotTwoRem px-10', enter: false },
            { index: 3, tab: 'CCTV 동의', class: 'fontOneDotTwoRem px-10', enter: false },
        ],
        outlinedBtn: [
            { icon: 'mdi-microsoft-excel', class:'', color:'grey006', text: '엑셀', width:'1000px', disabled: false, },
            { icon: 'mdi-printer-outline', class:'ml-2', color:'grey006', text: '출력', width:'1000px', disabled: false, },
        ],
        bnfcrHeaders: [
            { text: '연번', value: 'rn', sortable: true, width: '80', align: 'center', class: 'black--text py-4 greyE01 fontOneRem bnfcrMidIndex fixPstn0 zPstn3'},
            { text: '수급자', value: 'name', sortable: true, width: '110', align: 'center', class: 'black--text greyE01 fontOneRem bnfcrMidIndex fixPstn1 zPstn3'},
            { text: '성별', value: 'gndr', sortable: true, width: '80', align: 'center', class: 'black--text greyE01 fontOneRem bnfcrMidIndex fixPstn3 zPstn3'},
            { text: '나이', value: 'age', sortable: true, width: '80', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '현황', value: 'entStcd', sortable: true, width: '100', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '생년월일', value: 'rsdntBirth', sortable: true, width: '120', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '생활실', value: 'lvnRmNm', sortable: true, width: '110', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '등급', value: 'acknwRtng', sortable: true, width: '80', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '인정번호', value: '', sortable: true, width: '170', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '인정시작일', value: 'acknwPrdStrt', sortable: true, align: 'center', width: '130', class: 'black--text greyE01 fontOneRem'},
            { text: '인정종료일', value: 'acknwPrdEnd', sortable: true, align: 'center', width: '130', class: 'black--text greyE01 fontOneRem'},
            { text: '입소일(시간)', value: 'entDt', sortable: true, width: '180', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '퇴소일(시간)', value: 'lvDt', sortable: true, width: '180', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '본인부담률', value: 'copayRate', sortable: true, width: '120', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '주요질환', value: 'majorDsss', sortable: true, width: '300', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '보호자', value: 'grdName', sortable: true, width: '130', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '관계', value: 'rltns', sortable: true, width: '160', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '보호자 연락처', value: '', sortable: true, width: '150', align: 'center', class: 'black--text greyE01 fontOneRem'},
        ],
        elderHeaders: [
            { text: '연번', value: 'rn', sortable: true, width: '80', align: 'center', class: 'black--text py-4 greyE01 fontOneRem'},
            { text: '수급자', value: 'name', sortable: true, width: '110', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '성별', value: 'gndr', sortable: true, width: '80', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '나이', value: 'age', sortable: true, width: '80', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '현황', value: 'entStcd', sortable: true, width: '90', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '생년월일', value: 'rsdntBirth', sortable: true, width: '120', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '생활실', value: 'lvnRmNm', sortable: true, width: '120', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '등급', value: 'acknwrtng', sortable: true, width: '78', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '노인인권보호지침제공', value: '', sortable: true, width: '198', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '입소전 건강검진', value: 'entHlthChdt', sortable: true, width: '150', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '건강검진 (연1회)', value: '', sortable: true, width: '154', align: 'center', class: 'black--text greyE01 fontOneRem'},
        ],
        exlHeader: [
            { header: '연번', key: 'rn', width: 8, }, { header: '수급자', key: 'name', width: 12, },
            { header: '성별', key: 'gndrNm', width: 10, }, { header: '나이', key: 'ageNm', width: 10, },
            { header: '현황', key: 'entStcd', width: 12, }, { header: '생년월일', key: 'rsdntBirth', width: 16, },
            { header: '생활실', key: 'lvnRmNm', width: 12, }, { header: '등급', key: 'acknwRtngNm', width: 10, },
            { header: '인정번호', key: 'acknwNumNm', width: 20, }, { header: '인정시작일', key: 'acknwPrdStrt', width: 12, },
            { header: '인정종료일', key: 'acknwPrdEnd', width: 12, }, { header: '입소일(시간)', key: 'entDtNm', width: 16, },
            { header: '퇴소일(시간)', key: 'lvDtNm', width: 16, }, { header: '본인부담률', key: 'copayRateNm', width: 12, },
            { header: '주요질환', key: 'majorDsss', width: 50, }, { header: '보호자', key: 'grdName', width: 14, },
            { header: '관계', key: 'rltns', width: 16, }, { header: '보호자 연락처', key: 'grdCelphnNm', width: 16, },
        ],
        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '17', inqYYYY: '',
            rptTyp: '1', mode: '2', list: [ { bzClcd: [], bzPk: [] } ],
        },
        snackOptions: [false, 3000, '', 'info'],
        bizMsgInfo: {
            mmbrPk: [], docClcd: 1, bzPk: 0, bzTitle: '', bzCntnt: '', bizMsgVal1: '',
        },
    }),
};
</script>

<style>
    .stts .v-data-table--fixed-header{
        position: sticky !important; z-index: 1;
    }
</style>