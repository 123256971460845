<template>
    <v-sheet class="ma-0" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <span class="text-h4 font-weight-bold">인정등급&nbsp;{{ bscsInf.ttl }}</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="$parent.$parent.$parent.$parent.mdlOff" x-large>mdi-close</v-icon>
        </v-row>
        <v-row class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">기본 정보</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">수급자</span>
            </v-col>
            <v-col class="px-4 py-1" cols="9">
                <span class="mr-2">{{ bscsInf.name }}</span>
                &#40;
                <span v-if="bscsInf.gndr == 'M'">남</span>
                <span v-else-if="bscsInf.gndr == 'F'">여</span>
                <span class="ml-1">/ 만 {{ bscsInf.age }}세</span>
                &#41;
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">인정등급 / 기간</span>
            </v-col>
            <v-col class="px-4 py-1" cols="9">
                <span v-if="bscsInf.nowAcknwRtng != null">
                    <span v-if="bscsInf.nowAcknwRtng == '99'">등급외</span>
                    <span v-else>{{ bscsInf.nowAcknwRtng }}등급</span>
                    <span class="mx-2">&#47;</span>
                    <span>{{ $moment( bscsInf.nowAcknwPrdStrt, 'YYYYMMDD' ).format('YYYY.MM.DD') }}</span>
                    <span class="mx-2">&#126;</span>
                    <span>{{ $moment( bscsInf.nowAcknwPrdEnd, 'YYYYMMDD' ).format('YYYY.MM.DD') }}</span>
                </span>
                <span v-else>해당없음</span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <div
            v-if="bscsInf.bnfcrCntrcInfPk > 0"
            class="brdSurrd mt-6 mr-1 px-6 py-2"
            style="background-color: #f4f4f4 !important;">
            <p class="fontOneDotOneRem red--text mb-1">
                ※ 인정등급 변경 적용기간에 계약건이 존재합니다.
            </p>
            <p class="fontOneRem grey006--text ma-0 pl-5" style="line-height: 1.2;">
                해당 계약의 계약일자: {{ bscsInf.cntrcDtNms }}
                <br>
                변경된 인정등급 및 인정기간은 표준약관에 반영됩니다.
            </p>
        </div>
        <v-row class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">변경 정보</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 tableBR d-flex align-center justify-center" cols="3">
                <span class="font-weight-medium posn-rltv">
                    인정등급
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="px-2 py-1" cols="9">
                <v-radio-group
                    v-model="bscsInf.acknwRtng" @change="aplctSavBtn"
                    class="radio-dft-Theme mt-0" ref="info" hide-details row>
                    <v-radio
                        v-for="(itm, i) in prtCd62" :key="i"
                        class="px-2" style="padding-bottom: 2px !important;"
                        :ripple="false" :label="itm.valcdnm" :value="itm.valcd">
                    </v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="3">
                <span class="font-weight-medium posn-rltv">
                    인정기간
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="d-flex align-center px-4" cols="9">
                <span class="d-inline-block" style="width: 134px; padding-top: 1px;">
                    <CmmDateComp
                        v-model="bscsInf.acknwPrdStrt"
                        :maxDt="bscsInf.acknwPrdEnd"
                        :required="true" @input="aplctSavBtn(), prdCalc()">
                    </CmmDateComp>
                </span>
                <span class="mx-2">
                    <v-icon size="16" style="color: #dbdbdb;">mdi-tilde</v-icon>
                </span>
                <span class="d-inline-block" style="width: 134px; padding-top: 1px;">
                    <CmmDateComp
                        v-model="bscsInf.acknwPrdEnd"
                        :minDt="bscsInf.acknwPrdStrt"
                        :required="true" @input="aplctSavBtn">
                    </CmmDateComp>
                </span>
                <span class="d-inline-block ml-1" style="width: 70px;">
                    <v-select
                        v-model="sltPrd"
                        :items="prtYearPeriod" @change="prdCalc"
                        item-text="valcdnm" item-value="valcd"
                        dense hide-details outlined>
                    </v-select>
                </span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="3">
                <span class="font-weight-medium posn-rltv">
                    인정번호
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="d-flex align-center px-4" cols="9">
                <span>{{ bscsInf.nowAcknwNum1 }}</span>
                <span class="mx-2" style="padding-bottom: 2px;">
                    <v-icon size="16">mdi-minus</v-icon>
                </span>
                <span class="white d-inline-block" style="width: 68px;">
                    <v-text-field
                        v-model="bscsInf.acknwNum2" @input="aplctSavBtn"
                        height="28" type="number" hide-details outlined dense>
                    </v-text-field>
                </span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 tableBR d-flex align-center justify-center" cols="3">
                <span class="font-weight-medium posn-rltv">
                    변경사유
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="px-4 py-1" cols="9">
                <v-text-field
                    v-model="bscsInf.mdfRsn" @input="aplctSavBtn"
                    class="" height="28" color="blue"
                    hide-details outlined dense>
                </v-text-field>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-divider class="white my-2"></v-divider>
        <v-row class="pa-0 justify-center" no-gutters>
            <v-btn
                class="" color="blueBtnColor" min-width="110" height="36"
                :disabled="savBtn" @click="dcsnSavBtn">
                <span class="white--text fontOneDotTwoRem">저장</span>
            </v-btn>
        </v-row>
        <v-divider class="white mt-1"></v-divider>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

import CmmDateComp from '../../commons/CmmDateComp.vue';

export default {
    name: 'AcknwSavMdl',

    components: {
        CmmDateComp,
    },

    props : {
        prtCd62: { type: Array, default: () => { return [] } },
        prtYearPeriod: { type: Array, default: () => { return [] } },
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            let obj = this.$store.getters.getSubInObj
            this.aplctInf(obj)
        })
    },

    computed: {
      
    },

    watch: {
        
    },

    methods:{
        // 인정등급 신규 등록
        setAcknwInf: function (obj){
            this.savBtn = true

            http.post('bnfcrAuth/insBnfcrAcknwRtng', obj)
                .then((response) => ( this.insAcknwRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/insBnfcrAcknwRtng : ' + error))
        },

        insAcknwRst: function (res){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == '200')
                this.savBtn = false
            else{
                res.dupList.forEach(itm => {
                    let txt = '기간: '
                    txt += this.$moment(itm.acknwPrdStrt, 'YYYYMMDD').format('YYYY.MM.DD')
                    txt += ' ~ '
                    txt += this.$moment(itm.acknwPrdEnd, 'YYYYMMDD').format('YYYY.MM.DD')
                    txt += '와(과) 중복입니다.'

                    obj.cntnt.push(txt)
                });
                obj.type = 'warning'
            }

            this.$store.commit('setWrnnInf', obj)
        },

        // 인정등급 수정
        setAcknwUpd: function (obj){
            this.savBtn = true

            http.post('bnfcrAuth/updBnfcrAcknwRtng', obj)
                .then((response) => ( this.insAcknwRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/updBnfcrAcknwRtng : ' + error))
        },

        // 기본정보 적용
        aplctInf: function (obj) {
            this.bscsInf.bnMmbrPk = obj.bnMmbrPk
            this.bscsInf.bnfcrPk = obj.bnfcrPk
            this.bscsInf.name = obj.name
            this.bscsInf.gndr = obj.gndr
            this.bscsInf.age = obj.age
            this.bscsInf.ttl = obj.subTtl
            this.bscsInf.nowAcknwRtng = obj.isNowObj?.acknwRtng
            this.bscsInf.nowAcknwNum1 = obj.isNowObj?.acknwNum1
            this.bscsInf.nowAcknwPrdStrt = obj.isNowObj?.acknwPrdStrt
            this.bscsInf.nowAcknwPrdEnd = obj.isNowObj?.acknwPrdEnd
            this.bscsInf.nowAcknwRtngNm = obj.isNowObj?.acknwRtngNm

            if(obj.subTtl == '수정/연장'){
                this.bscsInf.bnfcrAcknwRtngPk = obj.bnfcrAcknwRtngPk
                this.bscsInf.acknwRtng = obj.acknwRtng
                this.bscsInf.bnfcrCntrcInfPk = obj.bnfcrCntrcInfPk
                this.bscsInf.cntrcDtNms = obj.cntrcDtNms
                this.bscsInf.acknwPrdStrt = this.$moment(obj.acknwPrdStrt, 'YYYYMMDD').format('YYYY-MM-DD')
                this.bscsInf.acknwPrdEnd = this.$moment(obj.acknwPrdEnd, 'YYYYMMDD').format('YYYY-MM-DD')
                this.bscsInf.acknwNum2 = obj.acknwNum2
                this.bscsInf.mdfRsn = obj.acknwNum2
            }
            else{
                delete this.bscsInf.bnfcrAcknwRtngPk
                this.rstFld()
            }

            this.aplctSavBtn()
        },
       
        // 저장버튼 활성 여부 체크
        aplctSavBtn: function () {
            this.savBtn = true

            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.bscsInf.acknwPrdStrt) == false)
                return 0
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.bscsInf.acknwPrdEnd) == false)
                return 0
            if(this.$moment(this.bscsInf.acknwPrdStrt).isAfter(this.$moment(this.bscsInf.acknwPrdEnd)))
                return 0
            if(this.bscsInf.acknwRtng == '')
                return 0
            if(this.bscsInf.acknwNum2 == '')
                return 0
            if(this.bscsInf.mdfRsn == '')
                return 0

            this.savBtn = false
        },

        // 저장버튼 분기
        dcsnSavBtn: function () {
            let insObj = Object.assign({}, this.bscsInf)
            insObj.acknwNum1 = insObj.nowAcknwNum1
            this.prtCd62.forEach(itm => {
                if(itm.valcd == insObj.acknwRtng)
                    insObj.acknwRtngNm = itm.valcdnm
            });
            insObj.acknwPrdStrt = this.$moment(insObj.acknwPrdStrt, 'YYYY-MM-DD').format('YYYYMMDD')
            insObj.acknwPrdEnd = this.$moment(insObj.acknwPrdEnd, 'YYYY-MM-DD').format('YYYYMMDD')

            if(this.bscsInf.ttl == '갱신/등급변경')
                this.setAcknwInf(insObj)
            else if(this.bscsInf.ttl == '수정/연장')
                this.setAcknwUpd(insObj)
        },

        // 인정기간 계산
        prdCalc: function () {
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.bscsInf.acknwPrdStrt) == false)
                return 0

            let strt = this.$moment(this.bscsInf.acknwPrdStrt, 'YYYY-MM-DD') 
            this.bscsInf.acknwPrdEnd = strt.add(Number(this.sltPrd), 'years').subtract(1, 'days').format('YYYY-MM-DD')
        },

        // 입력필드 초기화
        rstFld: function () {
            this.bscsInf.acknwRtng = ''
            this.bscsInf.acknwNum2 = ''
            this.bscsInf.acknwPrdStrt = ''
            this.bscsInf.acknwPrdEnd = ''
            this.bscsInf.mdfRsn = ''
        }
    },

    data: () => ({
        savBtn: true,
        sltPrd: '1',
        bscsInf: {
            bnMmbrPk: -1, bnfcrPk: -1, name: '', gndr: '', age: '',
            nowAcknwRtng: '', nowAcknwNum1: '', nowAcknwPrdStrt: '', nowAcknwPrdEnd: '',
            acknwRtng: '', acknwNum2: '', acknwPrdStrt: '', acknwPrdEnd: '', mdfRsn: '',
            ttl: '', nowAcknwRtngNm: '', bnfcrCntrcInfPk: -1, cntrcDtNms: '',
        },
    })
}
</script>