<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" min-height="700">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">물리&#40;작업&#41; 치료평가</span>
            <v-spacer></v-spacer>
            <span v-if="progress">
                <v-progress-circular            
                :size="50"
                color="primary"
                indeterminate
                ></v-progress-circular>
            </span>
            <span v-if="!progress">
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn v-if="list.type == 'action' && list.visible" @click = "onClickBtn(list.text)"
                        :class="list.class" height="30" :color="list.color" rounded
                        :disabled="btnDisabled"
                        style="font-size: 14px;">
                        <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                        {{list.text}}
                    </v-btn>
                </span>
            </span>
            <v-btn v-for="(list, j) in outlinedBtn" :key="filledBtn.length + j" @click = "onClickBtn(list.text)"
                :class="list.class" samll height="30" :color="list.color" rounded outlined
                :disabled="list.disabled">
                <v-icon small>{{list.icon}}</v-icon>
                {{list.text}}
            </v-btn>

            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>        
        <basicInfo :bnMmbrPk="bnMmbrPk"></basicInfo>
        <v-form ref="form" lazy-validation>
            <v-row class="pa-0 my-2" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="1">
                    <span class="black--text font-weight-medium">조사사유</span>
                </v-col>
                <v-col class="pl-2 d-flex justify-center align-center" cols="3">
                    <span class="d-inline-block" style="width: 250px;">
                        <v-radio-group
                            v-model="items.examRsncd" :rules="[rules.required]"
                            class="radio-dft-Theme mt-0 pl-2 pb-1" hide-details row>
                            <v-radio class="pa-0 mr-1" :ripple="false" label="신규" value="1"></v-radio>
                            <v-radio class="pa-0 mr-1" :ripple="false" label="재사정" value="2"></v-radio>
                            <v-radio class="pa-0 ma-0" :ripple="false" label="상태변화" value="3"></v-radio>
                        </v-radio-group>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="1">
                    <span class="black--text font-weight-medium">작성일</span>
                </v-col>
                <v-col class="ma-0 d-flex justify-start align-center" cols="2">
                    <span class="white d-inline-block py-1 pl-2" style="width: 154px;">    
                        <CmmDateComp v-model="items.examDt" :required="true" :minDt="inqYYYY+'-01-01'" :maxDt="inqYYYY+'-12-31'"></CmmDateComp>                        
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="1">
                    <span class="black--text font-weight-medium">작성자</span>
                </v-col>
                <v-col class="ma-0 d-flex justify-start align-center" cols="4">
                    <span class="d-inline-block py-1 pl-2" style="width: 120px;">
                        <v-text-field
                            v-model="items.wrtr" :rules="[rules.required]" :key="forceRender"
                            color="blue" height="28" ref="info"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <v-btn 
                        class="ml-1 brown lighten-3" 
                        small 
                        min-width="30"
                        height="25"  
                        dark
                        style="padding:0px 1px 0px 2px !important"
                        @click="onModal"
                        >
                        <v-icon size="20">mdi-account-edit</v-icon>
                    </v-btn>
                </v-col>            
            </v-row>
        </v-form>
        <div class="pa-0 ma-0">
            <v-tabs
                v-model="tab" class="tab-dft-Theme" active-class="black"
                center-active hide-slider>
                <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class">{{item.tab}}</v-tab>
            </v-tabs>
            <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
            <div style="height:560px;">
                <v-tabs-items v-model="tab">            
                    <v-tab-item eager transition="fade-transition">
                        <tab1 ref="tab1"                     
                            @physcThrpyEvltnTrigger="physcThrpyEvltnReLoad" 
                            ></tab1>
                    </v-tab-item>                    
                    <v-tab-item eager transition="fade-transition">
                        <tab4  ref="tab4" 
                            :tab="tab"                    
                            @physcThrpyEvltnTrigger="physcThrpyEvltnReLoad" 
                            ></tab4>
                    </v-tab-item>
                    <v-tab-item eager transition="fade-transition">
                        <tab5  ref="tab5" 
                            :tab="tab"                    
                            @physcThrpyEvltnTrigger="physcThrpyEvltnReLoad" 
                            ></tab5>
                    </v-tab-item>
                    <v-tab-item eager transition="fade-transition">
                        <tab2  ref="tab2" 
                            :tab="tab"                    
                            @physcThrpyEvltnTrigger="physcThrpyEvltnReLoad" 
                            ></tab2>
                    </v-tab-item>
                    <v-tab-item eager transition="fade-transition">
                        <tab3  ref="tab3" 
                            :tab="tab"                    
                            @physcThrpyEvltnTrigger="physcThrpyEvltnReLoad" 
                            ></tab3>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </div>

        <v-dialog v-model="dialogDelete" max-width="500">    
            <btnModalDelete :title="'물리(작업) 치료평가'" @madalState="onDelete"></btnModalDelete>  
        </v-dialog> 
        <v-dialog v-model="menu[1]" eager content-class="round" persistent max-width="800">
            <EmpSelector @isClose="isModal" @outEmp="getEmpInfo" :prtWrtDt = "prtWrtDt"></EmpSelector>
        </v-dialog>    
    </v-sheet>
</template>
<script>
import CmmDateComp from '../commons/CmmDateComp.vue';
import basicInfo from '../bnfcr/BnfcrBasicInfo.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import tab1 from './PhyscThrpyEvltnTab1.vue';
import tab2 from './PhyscThrpyEvltnTab2.vue';
import tab3 from './PhyscThrpyEvltnTab3.vue';
import tab4 from './PhyscThrpyEvltnTab4.vue';
import tab5 from './PhyscThrpyEvltnTab5.vue';
import {getBnfcrExamHisInfo, getBnfcrExamList, insBnfcrExamHis, delBnfcrExamHis} from '../../api/bnftSrv.js';
import EmpSelector from '../commons/EmpSelector.vue';

export default {
    name: 'PhyscThrpyEvltn',

    props : {   
        inqYYYY: Number,  
        bnMmbrPk : { type: Number, default: -1 },
        physcThrpyEvltnPk : { type: Number, default: -1 },
        btnDisabled : { type: Boolean, default: false },     
    },
        
    components: {
        CmmDateComp,
        basicInfo,
        tab1,
        tab2,
        tab3,
        tab4,
        tab5,
        btnModalDelete,
        EmpSelector,
    },
    
    computed: {
        
    },  

    watch: {     
        'physcThrpyEvltnPk':function(value){    
            this.filledBtn[0].visible = false        
            if(value > -1){
                this.bnfcrExamHisPk = value
                this.onShow()
                if(value == 0) this.filledBtn[0].visible = true
            }
        }
    },

    mounted: function(){             
        this.$nextTick(function () {  
            this.bnfcrExamHisPk = this.physcThrpyEvltnPk
            this.onShow()
            this.filledBtn[0].visible = false
            if(this.bnfcrExamHisPk == 0) this.filledBtn[0].visible = true
        })
    },
    
    methods: {
        onShow : function(){
            this.getInit()
            this.getExamHisInfo()
            this.getBnfcrExamList()
        },
        onClose: function () {
            this.bnfcrExamHisPk=-1
            this.frmReset();
            this.$emit('modalEvent', 5)
        },
        getInit(){
            // let date = new Date()                                    
            // let dateStr = date.toISOString().split('T',2)[0]
            
            let timezoneOffset = new Date().getTimezoneOffset() * 60000
            let timezoneDate = new Date(Date.now() - timezoneOffset)
            let dateStr = timezoneDate.toISOString().split('T',2)[0]
            this.items.examDt = dateStr
            this.items.wrtr = this.$store.getters.getUserNm;
            this.minDate = this.inqYYYY+'-'+'01'+'-'+'01'
            this.maxDate = this.inqYYYY+'-'+'12'+'-'+'31'

            this.forceRender += 1
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },

        onClickBtn: function(text) {
            if(text == "이전자료"){
                this.getPreData = true
                this.getBnfcrExamList()
            }else if(text=="저장"){                
                this.insBnfcrExam()
                // this.items.inqYYYY = this.inqYYYY    
                // this.items.bnfcrExamHisPk = this.bnfcrExamHisPk 
                // this.items.bnMmbrPk = this.bnMmbrPk 

                // if(this.tab == 0){
                //     if(this.$refs.form.validate()){
                //         this.$refs.tab1.insBnfcrExam(this.items);
                //     }
                // }else if(this.tab == 1){
                //     if(this.$refs.form.validate()){
                //         this.$refs.tab2.insBnfcrExam(this.items);
                //     }
                // }else if(this.tab == 2){
                //     if(this.$refs.form.validate()){
                //         this.$refs.tab3.insBnfcrExam(this.items);
                //     }
                // }else if(this.tab == 3){
                //     if(this.$refs.form.validate()){
                //         this.$refs.tab4.insBnfcrExam(this.items);
                //     }
                // }else if(this.tab == 4){
                //     if(this.$refs.form.validate()){
                //         this.$refs.tab5.insBnfcrExam(this.items);
                //     }
                // }                  
            } else if(text=="삭제"){
                if(this.bnfcrExamHisPk > 0){
                    this.dialogDelete = true
                }
            } else if(text=="출력"){
                this.physcThrpyPrt()
            }
        },

        insBnfcrExam(){
            if(this.$refs.form.validate()){
                //수급자평가 내역 setting                
                let obj = {
                    fcltyNum:this.$store.getters.getFcltyNum,
                    userNm:this.$store.getters.getUserNm,
                    bnMmbrPk:this.bnMmbrPk,
                    bnfcrExamHisPk:this.bnfcrExamHisPk,
                    examClcd:'106',                    
                    examRsncd : this.items.examRsncd,
                    examDt : this.items.examDt.afterDateFormatHyp(),
                    wrtr : this.items.wrtr,
                    examYyyy : this.inqYYYY,
                }

                let  examList = []

                this.$refs.tab1.selExamList().forEach(el => {
                    examList.push(el)
                });

                 this.$refs.tab2.selExamList().forEach(el => {
                    examList.push(el)
                });

                 this.$refs.tab3.selExamList().forEach(el => {
                    examList.push(el)
                });

                 this.$refs.tab4.selExamList().forEach(el => {
                    examList.push(el)
                });

                this.$refs.tab5.selExamList().forEach(el => {
                    examList.push(el)
                });

                this.progress = true

                insBnfcrExamHis(obj,examList)
                .then((response) => ( this.insBnfcrExamAfter(response.data)))
                .catch((error) => console.log(error))
            }
        },
        insBnfcrExamAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])                
                this.bnfcrExamHisPk = res.result
                this.getExamHisInfo()
                this.getBnfcrExamList()   
                this.$emit('modalEvent', 99)   
                 this.filledBtn[0].visible = false         
            }
            else{
                // this.snackControll([true, 5000, res.message, 'error'])
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        getExamHisInfo(){
            this.progress = false
            if(this.bnfcrExamHisPk !='' && this.bnfcrExamHisPk > 0){
                let code = this.$store.getters.getFcltyNum;
                getBnfcrExamHisInfo(code, this.bnfcrExamHisPk)
                .then((response) => ( this.getExamHisInfoAfter(response.data)))
                .catch((error) => console.log(error))
            }
        },
        getExamHisInfoAfter(data){
            data.examDt = data.examDt.beforeDateFormatHyp()
            this.items = data
        },

        getBnfcrExamList(){
            if(this.getPreData){
                this.obj = {
                    code: this.$store.getters.getFcltyNum, 
                    inqTFclsfc1Cd: true, 
                    inqTFclsfc2Cd: true, 
                    inqTFclsfc3Cd: true, 
                    inqTFitmCd: true, 
                    clsfc1Cd: '106',
                    bnfcrExamHisPk:null,
                    bnMmbrPk:this.bnMmbrPk
                }
            }else{      
                this.obj = {
                    code: this.$store.getters.getFcltyNum, 
                    inqTFclsfc1Cd: true, 
                    inqTFclsfc2Cd: true, 
                    inqTFclsfc3Cd: true, 
                    inqTFitmCd: true, 
                    clsfc1Cd: '106',
                    bnfcrExamHisPk:this.bnfcrExamHisPk
                }
            }
            
            getBnfcrExamList(this.obj)
                .then((response) => ( this.getBnfcrExamListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnftoffer/selBnfcrExamList : ' + error))
        },

        getBnfcrExamListAfter(res){
             let physcThrpyData1 = []
             let physcThrpyData2 = []
             let physcThrpyData3 = []
             let physcThrpyData4 = []
             let physcThrpyData5 = []

              res.forEach(item => {
                if(this.getPreData) item.bnfcrExamPk = 0

                switch (item.clsfc2Cd) {
                    case '1':
                        physcThrpyData1.push(item)
                        break;
                    case '2':
                        physcThrpyData1.push(item)
                        break;
                    case '3':
                        physcThrpyData1.push(item)
                        break;
                    case '4':
                        physcThrpyData2.push(item)
                        break;
                    case '5':
                        physcThrpyData3.push(item)
                        break;
                    case '6':
                        physcThrpyData4.push(item)
                        break;
                    case '7':
                        physcThrpyData5.push(item)
                        break;
                    case '999':
                        physcThrpyData5.push(item)
                        break;
                    default:
                        break;
                }
            });

            this.$refs.tab1.setData(physcThrpyData1)
            this.$refs.tab2.setData(physcThrpyData2)
            this.$refs.tab3.setData(physcThrpyData3)
            this.$refs.tab4.setData(physcThrpyData4)
            this.$refs.tab5.setData(physcThrpyData5)

            if(this.getPreData) this.getPreData = false
        },

        onDelete:function(res){
            if(res == 9990){
                if(this.bnfcrExamHisPk > 0){
                    let code = this.$store.getters.getFcltyNum;
                    let value = this.bnfcrExamHisPk;
                    let user = this.$store.getters.getUserNm

                    delBnfcrExamHis(code, value, user)
                    .then((response) => ( this.delBnfcrExamHisAfter(response.data)))
                    .catch((error) => console.log(error))
                }
            }else if(res == 7){
                this.dialogDelete = false
            }

        },

        delBnfcrExamHisAfter:function(res){
            if(res.statusCode == 200){                                                
                this.$emit('modalEvent', 99)
                this.onClose()
            }
            else{
                this.$store.commit('setBnfcrExamHisPk', 0)
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },

        frmReset:function(){ 
            this.tab = 0
            this.items.examRsncd = ''
            this.items.examDt = ''
            this.items.wrtr = ''
            this.$refs.form.resetValidation()
        },

        snackControll: function (options) {
            this.snackOptions = options
        },

        physcThrpyEvltnReLoad:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])                
                this.bnfcrExamHisPk = res.result
                this.getExamHisInfo()
                this.getBnfcrExamList()   
                this.$emit('modalEvent', 99)
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },

        //물리치료평가 양식 출력
        physcThrpyPrt: function (){
            let obj = {}
            obj.rptTyp = '1'
            obj.mode = '2'
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnMmbrPk = this.$parent.$parent.$parent.$parent.bnMmbrPk
            obj.bnfcrPk = this.$parent.$parent.$parent.$parent.bnfcrPk
            obj.prtClcd = '1'
            obj.inqClcd = 2
            obj.prtCd = '44'
            obj.inqYYYY = this.inqYYYY
            obj.inqYmdStart = ''
            obj.inqYmdEnd = ''
            obj.list = [ { bzClcd: [], bzPk: [] } ]
            obj.list[0].bzPk.push(this.bnfcrExamHisPk)

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: obj, outObj: null, runEvt: true,
            });
        },

        getEmpInfo: function (obj) {
            this.items.wrtr = obj[0].name
        },
        onModal: function () {
            this.prtWrtDt = this.items.examDt
            this.menu.splice(1, 1, true)
        },
        isModal: function () {
            this.menu.splice(1, 1, false)
        },
    },
    
    data: () => ({  
        getPreData:false, 
        minDate:'',
        maxDate:'',
        prtWrtDt:'',
        tab: 0,           
        bnfcrExamHisPk : 0,
        items:{examRsncd:'',examDt:'',wrtr:''},
        menu: [false,false],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },
        filledBtn: [
            { icon: 'mdi-text-box-search-outline', class: 'white--text', color:'#3C9099', text: '이전자료', type:'action', visible: false, },
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type:'action', visible: true, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type:'action', visible: true, },
        ],
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006 ', text: '출력', disabled: false, },
        ],
        snackOptions: [false, 3000, '', 'info'],
        forceRender:0,
        tabItems: [
            { index: 0, tab: '운동장애/근력/기본동작', class: 'fontOneDotTwoRem px-12'},
            { index: 1, tab: 'ROM(신체관절가동범위)', class: 'fontOneDotTwoRem px-12'},
            { index: 2, tab: 'ADL수행능력', class: 'fontOneDotTwoRem px-12'},
            { index: 3, tab: '통증강도&위치사정', class: 'fontOneDotTwoRem px-12'},
            { index: 4, tab: '균형감&걸음걸이사정', class: 'fontOneDotTwoRem px-12'},
        ],
        dialogDelete:false,
        progress:false,
    }),
};
</script>