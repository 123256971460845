<template>
    <v-sheet class="pa-0 ma-0">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" :color="snackOptions[3]"
                class="pa-0" min-width="200" max-width="500" rounded="pill" transition="slide-y-transition"
                absolute top>
                <div class="txtAlgnMid">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{ snackOptions[2] }}</span>
                </div>
            </v-snackbar>
        </div>
        <v-row class="pa-0" no-gutters>
            <v-col class="midAreaColor pl-7 py-7 pr-5" cols="5">
                <div class="d-flex justify-left mb-5">
                    <span class="text-h4 font-weight-bold">기타비용&nbsp;입력</span>
                    <v-spacer></v-spacer>
                </div>
                <div class="d-flex">
                    <exBtn class="mr-1" :btnData="exBtn[0]" @btnClosed="expandClose" @changeCheck="filterSearch" />
                    <exBtn class="mr-1" :btnData="exBtn[1]" @btnClosed="expandClose" @changeCheck="filterSearch" />
                    <exBtn :btnData="exBtn[2]" @btnClosed="expandClose" @changeCheck="filterSearch" />
                
                    <v-spacer></v-spacer>
                    
                    <span class="d-inline-block" style="width: 130px;">
                        <v-text-field
                            class="ma-0 pa-0 nameSearch" height="35px"
                            background-color="white" append-icon="mdi-magnify" label="이름조회"
                            @input="setSearchText"
                            single-line hide-details rounded outlined filled dense>
                        </v-text-field>
                    </span>
                </div>
                <v-data-table
                    class="bnfcrMidTable-Theme mt-2" height="500" locale="ko"
                    :headers="headers" :items="bnfcrList" :search="schDummy" :custom-filter="customFilter"
                    fixed-header disable-pagination hide-default-footer dense>
                    <template v-slot:item='{ item }'>
                        <tr
                            :class="[selectedItem == item.rn ? 'onClr' : '']" @click="tableClickEvent(item.rn)">
                            <td class="hvrClr">{{item.name}}</td>
                            <td class="nmlBfClrImp-white hvrClr" style="left: 80px !important;">
                                <span class="cmmFontSizeOne d-inline-block" style="width: 100%;">
                                    <span class="d-inline-block pt-1">
                                        <v-img class="ic-sex" v-if="item.gndr == 'F'" src="../../assets/icon/ic-female.svg"></v-img>
                                        <v-img class="ic-sex" v-else-if="item.gndr == 'M'" src="../../assets/icon/ic-male.svg"></v-img>
                                    </span>
                                    <span class="d-inline-block" style="width:18px;"></span>
                                </span>
                            </td>
                            <td
                                class="allHdrFix nmlBfClrImp-white hvrClr"
                                style="left: 140px !important; padding-right: 18px;">
                                {{item.age}}
                            </td>
                            <td>
                                <span
                                    :class="'ic-level level0'+item.acknwRtng"
                                    style="margin-left: 3px; line-height: 0;">
                                    {{ item.acknwRtng == '99' ? '외' : item.acknwRtng }}
                                </span>
                            </td>
                            <td>{{item.lvnRmNm}}</td>
                            <td>{{item.entStcd}}</td>
                            <td>{{item.acknwPrdEnd}}</td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
            <v-col class="pl-5 py-7 pr-7" cols="7">
                <div class="d-flex justify-left mb-1">
                    <v-spacer></v-spacer>
                    <v-icon color="black" x-large @click="onClose()">mdi-close</v-icon>
                </div>
                <div class="">
                    <expandMonthPicker
                        :prtDate="this.$parent.$parent.$parent.$parent.date"
                        @nowYearWithMonth="getPickerDate">
                    </expandMonthPicker>
                </div>
                <v-row class="pa-0 mt-3 tableBT" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center py-1" cols="1">
                        <span class="black--text font-weight-medium fontOneDotOneRem">수급자명</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center py-1" cols="2">
                        <span class="black--text fontOneDotOneRem">
                            {{selectedItem != -1 ? bnfcrList[selectedItem-1].name : '' }}
                        </span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                        <span class="black--text font-weight-medium fontOneDotOneRem">성별&nbsp;&#40;나이&#41;</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center py-1" cols="2">
                        <span v-if="selectedItem != -1" class="d-inline-block pt-1">
                            <v-img class="ic-sex" v-if="bnfcrList[selectedItem-1].gndr == 'F'" src="../../assets/icon/ic-female.svg"></v-img>
                            <v-img class="ic-sex" v-else-if="bnfcrList[selectedItem-1].gndr == 'M'" src="../../assets/icon/ic-male.svg"></v-img>
                        </span>
                        <span v-if="selectedItem != -1">
                            <span class="black--text fontOneDotOneRem">&nbsp;&#40;{{ bnfcrList[selectedItem-1].age }}&#41;</span>
                        </span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                        <span class="black--text font-weight-medium fontOneDotOneRem">생년월일</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center py-1" cols="3">
                        <span v-if="selectedItem != -1" class="black--text fontOneDotOneRemt">
                            <span>{{bnfcrList[selectedItem-1].birth.beforeDateFormatDot()}}</span>
                            <span v-if="bnfcrList[selectedItem-1].lnryn == '1'">&nbsp;&#40;양력&#41;</span>
                            <span v-else-if="bnfcrList[selectedItem-1].lnryn == '2'">&nbsp;&#40;음력&#41;</span>
                        </span>
                    </v-col>
                </v-row>
                <v-row class="pa-0 tableBT tableBB" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center py-1" cols="1">
                        <span class="black--text font-weight-medium fontOneDotOneRem">생활실</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center py-1" cols="2">
                        <span class="black--text fontOneDotOneRem">
                            {{selectedItem != -1 ? bnfcrList[selectedItem-1].lvnRmNm : '' }}
                        </span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                        <span class="black--text font-weight-medium fontOneDotOneRem">등급</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center py-1" cols="2">
                        <span :class="'ic-level level0'+(selectedItem != -1 ? bnfcrList[selectedItem-1].acknwRtng : '')">
                            {{selectedItem != -1 ? bnfcrList[selectedItem-1].acknwRtng : '' }}
                        </span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                        <span class="black--text font-weight-medium fontOneDotOneRem">본인부담률</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center py-1" cols="3">
                        <span v-if="selectedItem != -1" class="black--text fontOneDotOneRem">
                            <span v-if="bnfcrList[selectedItem-1].copayRate != '0'">
                                {{ bnfcrList[selectedItem-1].copayNm }}
                                &#40;{{ bnfcrList[selectedItem-1].copayRateDesc }}&#41;
                            </span>
                        </span>
                    </v-col>
                </v-row>
                <div class="mt-1">
                    <div class="d-flex align-center mt-4">
                        <v-icon style="font-size: 28px; width: 8px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold ml-3">기타비용</span>
                        <v-spacer></v-spacer>
                        <span v-for="(list, i) in filledBtn" :key="i">
                            <v-btn
                                :class="list.class" height="26" :color="list.color" :disabled="list.disabled"
                                @click="onAdd(selectedItem)"
                                rounded samll>
                                <v-icon small>{{list.icon}}</v-icon>
                                <span class="fontOneRem">{{list.text}}</span>
                            </v-btn>
                        </span>
                    </div>
                </div>
                <v-row class="pa-0 tableBT tableBB mt-3" no-gutters>
                    <v-col class="tableBR greyE01 d-flex justify-center align-center py-1" cols="2">
                        <span class="black--text font-weight-medium fontOneDotOneRem">급여제공일</span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                        <span class="black--text font-weight-medium fontOneDotOneRem">비용&nbsp;항목</span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                        <span class="black--text font-weight-medium fontOneDotOneRem">금액</span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                        <span class="black--text font-weight-medium fontOneDotOneRem">상세내용</span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                        <span class="black--text font-weight-medium fontOneDotOneRem">처리자</span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                        <span class="black--text font-weight-medium fontOneDotOneRem">비고</span>
                    </v-col>
                </v-row>
                <div class="" style="height: 348px;">
                    <v-row class="pa-0 tableBB"
                        v-for="(list, i) in msclnFeeDtlList" :key="i" no-gutters>
                        <v-col class="d-flex justify-center align-center py-1" cols="2">
                            <span class="black--text fontOneDotOneRem">{{list.bnftOfrDt?.beforeDateFormatDot()}}</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center py-1" cols="2">
                            <span class="black--text fontOneDotOneRem">{{list.costItm}}</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center py-1" cols="2">
                            <span class="black--text fontOneDotOneRem">{{list.amt?.toLocaleString('ko-KR')}}</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center py-1" cols="2">
                            <span class="black--text fontOneDotOneRem">{{list.dtlCntnt}}</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center py-1" cols="2">
                            <span class="black--text fontOneDotOneRem">{{list.wrtr}}</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center py-1" cols="2">
                            <v-btn
                                class="" min-width="44" width="44" height="24" color="blueBtnColor"
                                @click="onEdit(i)"
                                samll outlined>
                                <span style="font-size: 0.9rem; padding-bottom: 1px !important;">저장</span>
                            </v-btn>
                            <v-btn
                                class="ml-1" min-width="44" width="44" height="24" color="grey006"
                                @click="removeMsclnFeeDtl(list.msclnFeeDtlPk)"
                                samll outlined>
                                <span style="font-size: 0.9rem; padding-bottom: 1px !important;">삭제</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 tableBB" v-if="msclnFeeDtlList.length == 0" no-gutters>
                        <v-col class="d-flex justify-center align-center py-1" cols="12">
                            <span class="grey006--text fontOneRem">※목록이&nbsp;없습니다&#46;</span>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
         </v-row>
         <v-dialog
            v-model="menu[0]" eager content-class="round" max-width="900"
            persistent>
            <MsclnExpnsInsPrc ref="msclnEdit" @isClose="menu.splice(0, 1, false)"></MsclnExpnsInsPrc>
        </v-dialog>
    </v-sheet>
</template>

<script>
import exBtn from '../commons/expandButtonCustom.vue';
import expandMonthPicker from '../../components/commons/expandMonthPicker.vue';
import MsclnExpnsInsPrc from './MsclnExpnsInsPrc.vue';

import { bnfcrList, selBnfcrCntctList, selMsclnFeeDtlList, delMsclnFeeDtl } from '../../api/bnfcr.js';
import { selLvnRmInf } from '../../api/opr.js';

export default {
    name: 'MsclnExpnsIns',

    props : {

    },
        
    components: {
        exBtn,
        expandMonthPicker,
        MsclnExpnsInsPrc,
    },

    created: function(){
        this.getLvnRmInf()
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getBnfcrList(null)
            
            if( this.$parent.$parent.$parent.$parent.vCd62.length > 0 ){
                this.$parent.$parent.$parent.$parent.vCd62.forEach((code) => {
                    this.exBtn[2].expandList.push(code.valcdnm)
                });
            }
            if( this.$parent.$parent.$parent.$parent.vCd65.length > 0  ){
                this.$parent.$parent.$parent.$parent.vCd65.forEach((code) => {
                    this.exBtn[0].expandList.push(code.valcdnm)
                });
            }
        })
    },

    computed: {
        getCd62: function () {
            return this.$parent.$parent.$parent.$parent.vCd62
        },
        getCd63: function () {
            return this.$parent.$parent.$parent.$parent.vCd63
        },
        getCd65: function () {
            return this.$parent.$parent.$parent.$parent.vCd65
        },
    },

    watch:{
        getCd62: function (arr) {
            if(arr.length > 0){
                this.$parent.$parent.$parent.$parent.vCd62.forEach((code) => {
                    this.exBtn[2].expandList.push(code.valcdnm)
                });
            }
        },
        getCd63: function (arr) {
            if(arr.length > 0 && this.bnfcrList.length > 0){
                this.bnfcrList.forEach(itm => {
                    arr.forEach(code => {
                        if(itm.entStcd == code.valcd) itm.entStcd = code.valcdnm
                    });
                });
            }
        },
        getCd65: function (arr) {
            if(arr.length > 0){
                this.$parent.$parent.$parent.$parent.vCd65.forEach((code) => {
                    this.exBtn[0].expandList.push(code.valcdnm)
                });

                if(this.bnfcrList.length > 0){
                    this.bnfcrList.forEach(itm => {
                        arr.forEach(code => {
                            if(itm.entStcd == code.valcd) itm.entStcd = code.valcdnm
                        });
                    });
                }
            }
        },
        'selectedItem': function () {
            if(this.selectedItem != -1){
                this.getMsclnFeeDtlList(this.bnfcrList[this.selectedItem-1].bnMmbrPk)
                this.filledBtn[0].disabled = false
            }
            else
                this.filledBtn[0].disabled = true
        },
    },
    
    methods: {
        getLvnRmInf: function () {
            selLvnRmInf(this.$store.getters.getFcltyNum)
                .then((response) => ( this.getLvnRmInfAfter(response.data) ))
                .catch((error) => console.log('connect error /opr/selLvnRmInf : ' + error))
        },
        // 수급자 입소현황 별 조회
        getBnfcrList: function (stCd){
            this.bnfcrList.splice(0)

            bnfcrList(this.$store.getters.getFcltyNum, stCd)
                .then((response) => ( this.getBnfcrListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selBnfcrList : ' + error))
        },
        // 수급자 전체 조회
        getnfcrCntctList: function (){
            this.bnfcrList.splice(0)

            selBnfcrCntctList({ fcltyNum: this.$store.getters.getFcltyNum })
                .then((response) => ( this.getBnfcrListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selBnfcrCntctList : ' + error))
        },
        getMsclnFeeDtlList: function (pk){
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnMmbrPk = pk
            obj.inqYYMM = this.yymm

            selMsclnFeeDtlList(obj)
                .then((response) => ( this.getMsclnFeeDtlListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selMsclnFeeDtlList : ' + error))
        },
        removeMsclnFeeDtl: function (pk){
            delMsclnFeeDtl(this.$store.getters.getFcltyNum, pk, this.$store.getters.getUserNm)
                .then((response) => ( this.removeMsclnFeeDtlAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/delMsclnFeeDtl : ' + error))
        },
        getBnfcrListAfter: function (res){
            res.forEach(items => {
                items.acknwPrdEnd = items.acknwPrdEnd.beforeDateFormatDot()
              
                this.$parent.$parent.$parent.$parent.vCd63.forEach(code => {
                    if(items.copayRate == code.valcd){
                        items.copayNm = code.valcdnm?.slice(0, 3)?.replaceAll('(', '')
                        items.copayRateDesc = code.valdesc + '%'
                    }
                });
                
                this.$parent.$parent.$parent.$parent.vCd65.forEach(code => {
                    if(items.entStcd == code.valcd) items.entStcd = code.valcdnm
                });
            });

            this.bnfcrList = res
        },
        getMsclnFeeDtlListAfter: function (res){
            this.msclnFeeDtlList = res
        },
        removeMsclnFeeDtlAfter: function (res){
            if(res.statusCode == 200){
                this.reloadList()
                this.snackControll([true, 2500, res.message, 'info'])
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        getLvnRmInfAfter: function (res){
            this.lvnRmInf = res

            this.lvnRmInf.forEach((code) => {
                this.exBtn[1].expandList.push(code.lvnRmNm)
            });//end foreach
        },
        getPickerDate: function (date) {
            this.yymm = date
            this.reloadList()
        },
        reloadList: function () {
            if(this.selectedItem != -1)
                this.getMsclnFeeDtlList(this.bnfcrList[this.selectedItem-1].bnMmbrPk) 
        },
        tableClickEvent: function (index){
            if(this.selectedItem == -1){
                this.selectedItem = index
            }
            else if(this.selectedItem != index){
                this.selectedItem = index
            }
            else{
                //Form Clear
                this.selectedItem = -1
                this.msclnFeeDtlList.splice(0)
            }
        },
        setSearchText: function (value) {
            this.schField = value

            this.schDummy = this.schDummy + '.'
        },
        filterSearch: function (sch) {
            let chc = '2'

            switch(this.schChkField.target) {
                case 'entStcd': this.schChkField.entStcd.splice(0)
                    if(sch.length == 4) chc = '2'
                    else if(sch.indexOf('퇴소') == -1) chc = '2'
                    else chc = '1'

                    if(chc != this.schChkField.rtrYN){
                        if(chc == '2'){
                            this.getBnfcrList(null)
                            this.schChkField.rtrYN = '2'
                        }
                        else{
                            this.getnfcrCntctList()
                            this.schChkField.rtrYN = '1'
                        }
                    }

                    sch.forEach((item) => { this.schChkField.entStcd.push(item) });
                    break;
                case 'lvnRmNm': this.schChkField.lvnRmNm.splice(0)
                    sch.forEach((item) => { this.schChkField.lvnRmNm.push(item) });
                    break;
                case 'acknwRtng': this.schChkField.acknwRtng.splice(0)
                    sch.forEach((item) => {
                        if(item == '등급외')
                            this.schChkField.acknwRtng.push('99')
                        else
                            this.schChkField.acknwRtng.push(item.replaceAll('등급', ''))
                    });
                    break;
            }
          
            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column
            let col0 = filter.name.includes(this.schField)
            
            let col1 = true
            if(this.schChkField.entStcd.length != 0){
                this.schChkField.entStcd.forEach((stcd, i) => {
                    if(i == 0) col1 = filter.entStcd.includes(stcd)
                    else col1 = col1 || filter.entStcd.includes(stcd)
                });
            }

            let col2 = true
            if(this.schChkField.lvnRmNm.length != 0){
                this.schChkField.lvnRmNm.forEach((stcd, i) => {
                    if(i == 0) col2 = filter.lvnRmNm == stcd
                    else col2 = col2 || filter.lvnRmNm == stcd
                });
            }

            let col3 = true
            if(this.schChkField.acknwRtng.length != 0){
                this.schChkField.acknwRtng.forEach((stcd, i) => {
                    if(i == 0) col3 = filter.acknwRtng.includes(stcd)
                    else col3 = col3 || filter.acknwRtng.includes(stcd)
                });
            }

            return col0 && col1 && col2 && col3;
        },
        onAdd: function (index) {
            this.menu.splice(0, 1, true)

            this.$refs.msclnEdit.msclnFeeDtl.bnMmbrPk = this.bnfcrList[index-1].bnMmbrPk
            this.$refs.msclnEdit.msclnFeeDtl.wrtr = this.$store.getters.getUserNm
            this.$refs.msclnEdit.inqYYMM = this.yymm.beforeDateFormatMonthDot()
            this.$refs.msclnEdit.preYYMMSetter()
        },
        onEdit: function (index) {
            this.menu.splice(index, 1, true)
            let tmp = Object.assign({}, this.msclnFeeDtlList[index])
            tmp.bnftOfrDt = tmp.bnftOfrDt.beforeDateFormatHyp()

            this.$refs.msclnEdit.inqYYMM = this.yymm.beforeDateFormatMonthDot()
            this.$refs.msclnEdit.msclnFeeDtl = tmp
        },
        expandClose: function (index) {
            switch(index) {
                case 0: this.schChkField.target = 'entStcd'
                    break;
                case 1: this.schChkField.target = 'lvnRmNm'
                    break;
                case 2: this.schChkField.target = 'acknwRtng'
                    break;
                default: this.schChkField.target = ''
                    break;
            }
        },
        onClose: function () {
            this.$parent.$parent.$parent.$parent.dialog.splice(0, 1, false)
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        selectedItem: -1,
        yymm: '',
        bnfcrList: [],
        msclnFeeDtlList: [],
        lvnRmInf: [],
        schField: '',
        schDummy: '',
        schChkField: { entStcd: [], lvnRmNm: [], acknwRtng: [], rtrYN: '2', target: '' },
        menu: [false],
        headers: [
            { text: '수급자', value: 'name', sortable: true, align:'center', width: '80'},
            { text: '성별', value: 'gndr', sortable: true, align:'center', width: '60', class: 'fixPstnImp1'},
            { text: '나이', value: 'age', sortable: true, align:'center', width: '60' , class: 'fixPstnImp2'},
            { text: '등급', value: 'acknwRtng', sortable: true, align:'center', width: '60'},
            { text: '생활실', value: 'lvnRmNm', sortable: true, align:'center', width: '90'},
            { text: '현황', value: 'entStcd', sortable: true, align:'center', width: '80'},
            { text: '인정종료일', value: 'acknwPrdEnd', sortable: true, align:'center', width: '100'},
        ],
        exBtn: [
            { index:  0, btnTitle: '입소현황', btnIcon: 'house', exWidth: 104, exHeigth: 130, expandList: [], },
            { index:  1, btnTitle: '생활실', btnIcon: 'bed', exWidth: 124, exHeigth: 100, expandList: [], },
            { index:  2, btnTitle: '등급', btnIcon: 'wheelchair', exWidth: 104, exHeigth: 198, expandList: [], },
        ],
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '입력', disabled: true },
        ],
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>

<style>
    .msclnTable div table tr th { padding: 0 2px !important; }
    .msclnTable div table tr td { padding: 0 2px !important; }

    .msclnExpTbl .v-data-table__wrapper > table > thead > tr > th {
        border-bottom: 2px solid !important; font-size: 0.975rem !important;
    }
</style>