<template>
    <v-sheet>
        <v-form ref="tabform" lazy-validation>
            
            <div class="d-flex">       
                
                <div class="d-inline-block pa-0 ma-0 align-self-start" style="width:680px;border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;">            
                     <v-row class="pa-0 ma-0" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="3">                                
                            <span class="black--text font-weight-medium">항목</span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="9">
                          <span class="black--text font-weight-medium">확인</span>
                        </v-col>
                    </v-row>
                    
                    <div v-for="(item, i) in dsrEvltnList" :key="i">
                        <v-row v-if="item.clsfc3Cd < 11" class="pa-0 ma-0" no-gutters>
                            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="border-top: 1px solid #dbdbdb !important;" cols="3">                                
                                <span class="black--text font-weight-medium">{{item.clsfc3Cdnm}}</span>
                            </v-col>
                            <v-col style="border-top: 1px solid #dbdbdb;border-left: 1px solid #dbdbdb !important;" cols="9">
                                <div v-for="(list, i) in item.list" :key="i" class="d-inline-block">
                                    <div v-if="item.clsfc3Cd =='6' || item.clsfc3Cd =='7' || item.clsfc3Cd =='10'" class="py-1" style="width:480px">
                                        <v-checkbox
                                            v-model="list.itmVal"
                                            class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                            :ripple="false" :label="list.itmCdnm" value="1"
                                            hide-details>
                                        </v-checkbox>
                                    </div>
                                    <div v-else>
                                        <div v-if="list.valClcd =='1'" class="py-1" style="width:163px">
                                            <v-checkbox
                                                v-model="list.itmVal"
                                                class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                :ripple="false" :label="list.itmCdnm" value="1"
                                                hide-details>
                                            </v-checkbox>
                                        </div>
                                        <div v-else class="d-flex py-1" style="width:163px">
                                            <span>
                                                <v-checkbox
                                                    v-model="list.itmVal"
                                                    class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                    :ripple="false" :label="list.itmCdnm" value="1"
                                                    hide-details>
                                                </v-checkbox>
                                            </span>
                                            <span>&#40;</span>
                                            <span style="width:100px">
                                                <v-text-field v-model="list.evltn" height="28" hide-details outlined dense></v-text-field>
                                            </span>
                                            <span>&#41;</span>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>                
                    </div>             
                </div>

                <div class="d-inline-block pa-0 ml-3 align-self-start" style="width:680px;border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;">            
                    <v-row class="pa-0 ma-0" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="3">                                
                            <span class="black--text font-weight-medium">항목</span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="9">
                          <span class="black--text font-weight-medium">확인</span>
                        </v-col>
                    </v-row>
                    
                    <div v-for="(item, i) in dsrEvltnList" :key="i">
                        <v-row v-if="item.clsfc3Cd > 10" class="pa-0 ma-0" no-gutters>
                            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="border-top: 1px solid #dbdbdb !important;" cols="3">                                
                                <span class="black--text font-weight-medium">{{item.clsfc3Cdnm}}</span>
                            </v-col>
                            <v-col style="border-top: 1px solid #dbdbdb;border-left: 1px solid #dbdbdb !important;" cols="9">
                                <div v-if="item.clsfc3Cd < 19">                                    
                                    <div v-for="(list, i) in item.list" :key="i" class="d-inline-block">
                                        <div v-if="item.clsfc3Cd =='16'" class="py-1" style="width:480px">
                                            <v-checkbox
                                                v-model="list.itmVal"
                                                class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                :ripple="false" :label="list.itmCdnm" value="1"
                                                hide-details>
                                            </v-checkbox>
                                        </div>
                                        <div v-else>
                                            <div v-if="list.valClcd =='1'" class="py-1" style="width:163px">
                                                <v-checkbox
                                                    v-model="list.itmVal"
                                                    class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                    :ripple="false" :label="list.itmCdnm" value="1"
                                                    hide-details>
                                                </v-checkbox>
                                            </div>
                                            <div v-else class="d-flex py-1" style="width:163px">
                                                <span>
                                                    <v-checkbox
                                                        v-model="list.itmVal"
                                                        class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                        :ripple="false" :label="list.itmCdnm" value="1"
                                                        hide-details>
                                                    </v-checkbox>
                                                </span>
                                                <span>&#40;</span>
                                                <span style="width:100px">
                                                    <v-text-field v-model="list.evltn" height="28" hide-details outlined dense></v-text-field>
                                                </span>
                                                <span>&#41;</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="pa-1">
                                    <span v-for="(list, j) in item.list" :key="j">                                                                        
                                        <v-textarea v-model="list.evltn" class="rmrksArea"
                                            rows="3" outlined dense hide-details no-resize>
                                        </v-textarea>
                                    </span>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
              
                </div>  
            </div>
        </v-form>        
    </v-sheet>
</template>
<script>
import { groupBy } from '../../api/common.js';
import { insBnfcrExamHis } from '../../api/bnftSrv.js';

export default {
    name: 'DsrEvltnTab3',

    props : {
        
    },

    computed: {
        
    },  

    watch:{
        
    },

    created:function(){ 
        this.$nextTick(function () { 
        })          
    },
    
    mounted: function(){
       
    },
        
    methods: {             
        onClose: function () {
            this.$emit('modalClose', this.dsrEvltn)
        },

        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },

        setData(data){   
           this.dsrEvltnList.splice(0)
            let groups = groupBy(data, 'clsfc3Cd')
            
            Object.keys(groups).forEach((key)  => {
                let obj = {
                    clsfc3Cd : key,
                    clsfc3Cdnm : groups[key][0].clsfc3Cdnm,
                    list : groups[key]
                }
                this.dsrEvltnList.push(obj)
            }); 
            this.$refs.tabform.resetValidation();
        },

        insBnfcrExam(data){
            if(this.$refs.tabform.validate()){
                //수급자평가 내역 setting

                let obj = {
                    fcltyNum:this.$store.getters.getFcltyNum,
                    userNm:this.$store.getters.getUserNm,
                    bnMmbrPk:data.bnMmbrPk,
                    bnfcrExamHisPk:data.bnfcrExamHisPk,
                    examClcd:'101',                    
                    examRsncd : data.examRsncd,
                    examDt : data.examDt.afterDateFormatHyp(),
                    wrtr : data.wrtr,
                    examYyyy : data.inqYYYY,
                }

                let examList = []

                this.dsrEvltnList.forEach(x => {
                    x.list.forEach(item => { 
                        //선택되지 않을경우                       
                        if(item.itmVal != '1') item.itmVal = '2'

                        //기타 선택 안될경우 
                        let bl = (item.clsfc3Cd == '19')

                        if(item.valClcd =='2' && item.itmVal != '1' && !bl) item.evltn=''

                        examList.push(item)
                    });
                })
                
                insBnfcrExamHis(obj,examList)
                .then((response) => ( this.insBnfcrExamAfter(response.data)))
                .catch((error) => console.log(error))
            }
        },
        insBnfcrExamAfter: function (res) {
            this.$emit('dsrEvltnTrigger', res)            
        },        
        selExamList:function(){
            //욕구사정평가 setting
            let examList = []

            this.dsrEvltnList.forEach(x => {
                x.list.forEach(item => { 
                    //선택되지 않을경우                       
                    if(item.itmVal != '1') item.itmVal = '2'

                    //기타 선택 안될경우 
                    let bl = (item.clsfc3Cd == '19')

                    if(item.valClcd =='2' && item.itmVal != '1' && !bl) item.evltn=''

                    examList.push(item)
                });
            })
            return examList
        }
    },
    
    data: () => ({ 
        obj:{},
        dsrEvltnList:[],
        items:[],
        jdgBss:{},
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
        },
        
    }),
};
</script>