<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" style="position:relative">
        <v-form ref="mdlform" lazy-validation>
            <div class="d-flex justify-center align-center">
                <span class="text-h4 font-weight-bold">프로그램&nbsp;유형관리</span>
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </div>
            <div class="pa-0 ma-0 mt-1">
                <v-row class="pa-0 ma-0 mt-2"
                    style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;"
                    no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0"
                        style="border-left: 1px solid #dbdbdb !important;" cols="6">
                        <span class="black--text font-weight-medium">프로그램 유형</span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0"
                        style="border-left: 1px solid #dbdbdb !important;" cols="4">
                        <span class="black--text font-weight-medium">지표구분</span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0"
                        style="border-left: 1px solid #dbdbdb !important;" cols="2">
                        <v-btn icon color="blue200" @click="addRow()"><v-icon>mdi-plus-circle-outline</v-icon></v-btn>
                    </v-col>
                </v-row>
                <v-row v-for="(item, i) in items" :key="i" class="pa-0 ma-0 ma-0"
                    style="border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                    <v-col class="d-flex justify-start align-center pa-1 ma-0"
                        style="border-left: 1px solid #dbdbdb !important;" cols="6">
                        <v-text-field v-model="item.prgTypNm" color="blue" height="28" hide-details outlined dense :rules="[rules.required]"></v-text-field>
                    </v-col>
                    <v-col class="d-flex justify-center align-center pa-1 ma-0"
                        style="border-left: 1px solid #dbdbdb !important;" cols="4">
                        <v-select v-model="item.idxCd" :items="cdItems" item-text="valcdnm" item-value="valcd" dense :rules="[rules.selectCheck]"
                            hide-details outlined />
                    </v-col>
                    <v-col class="d-flex justify-center align-center pa-1 ma-0"
                        style="border-left: 1px solid #dbdbdb !important;" cols="2">
                        <v-btn class="mr-2" icon color='blue200'
                            @click="onSave(i)"><v-icon>mdi-content-save-outline</v-icon></v-btn>
                        <v-btn class="mr-1" icon color='grey006' v-if="item.prgTypMngPk > 0"
                            @click="onDelete(i)"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
                        <v-btn class="mr-1" color="grey006" icon height="26" v-else
                            @click="onDelete1(i)"><v-icon>mdi-minus-circle-outline</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-form>
        <v-snackbar v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500"
            class="pa-0" :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
            <div style="text-align: center;">
                <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{ snackOptions[2] }}</span>
            </div>
        </v-snackbar>
        <v-dialog v-model="dialogDelete" max-width="500">
            <btnModalDelete :title="'프로그램 유형관리'" @madalState="onDeleteBtn"></btnModalDelete>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { selPrgTypMnglist, insPrgTypMng, delPrgTypMng } from '../../api/prgrm.js';
import { getStcd } from '../../api/index.js';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
export default {
    name: 'PrgrmCategory',
    components: {
        btnModalDelete,
    },
    watch: {
        'items': function () {
            if (this.items.length < 1) console.log('code Mapping Failed')
            else {
                this.items.forEach((item) => {
                    this.cdItems.forEach((cd) => {
                        if (item.idxCd == cd.valcd) item.idxCdnm = cd.valcdnm
                    });//end foreach
                });//end foreach
            }//end else

        },
    },
    created: function () {
        getStcd('40')
            .then((response) => (this.stCdMapping(response.data)))
            .catch((error) => console.log(error))
    },
    methods: {
        stCdMapping: function (res) {
            this.cdItems = res
        },
        onShow() {
            this.getPrgTypMng()
            this.$refs.mdlform.resetValidation()
        },
        onClose: function () {
            this.$emit('isClose', '.')
            this.$emit('modalEvent', 1)
        },
        getPrgTypMng() {
            let fcltyNum = this.$store.getters.getFcltyNum
            this.valClcd = '40'
            this.valClcdnm = '지표구분'
            selPrgTypMnglist(fcltyNum, '40')
                .then((response) => (this.resData(response.data)))
                .catch((error) => console.log(error))
        },
        resData(data) {
            this.items = data
        },
        addRow() {
            let addObj = {
                idxCd: '',
                prgTypNm: '',
                fcltyNum: '',
                prgTypMngPk: '',
                useYn: '1',
            }
            this.items.push(addObj)
        },
        onSave(key) {
            if (this.$refs.mdlform.validate()) {
                let obj = {
                    idxCd: this.items[key].idxCd,
                    prgTypNm: this.items[key].prgTypNm,
                    fcltyNum: this.$store.getters.getFcltyNum,
                    userNm: this.$store.getters.getUserNm,
                    prgTypMngPk: this.items[key].prgTypMngPk,
                    useYn: '1',
                }
                insPrgTypMng(obj)
                    .then((response) => (this.insPrgTypMngAfter(response.data)))
                    .catch((error) => console.log(error))
            }
        },
        insPrgTypMngAfter(res) {

            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.onShow()
                this.$emit('reload', true)
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        delPrgTypMngAfter(res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.onShow()
                this.$emit('reload', true)
                this.dialogDelete = false
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onDelete1(key) {
            this.items.splice(key, 1)
        },
        onDelete(key) {
            if (key > -1) {
                this.key = key
                this.dialogDelete = true
            } else {
                if (key != 0) {
                    this.items.splice(key, 1)
                }
            }
        },
        onDeleteBtn: function (res) {

            if (res == 9990) {
                if (this.items[this.key].prgTypMngPk != undefined) {
                    let obj = {
                        fcltyNum: this.$store.getters.getFcltyNum,
                        prgTypMngPk: this.items[this.key].prgTypMngPk,
                        userNm: this.$store.getters.getUserNm,
                    }
                    delPrgTypMng(obj)
                        .then((response) => (this.delPrgTypMngAfter(response.data)))
                        .catch((error) => console.log(error))
                } else {
                    this.dialogDelete = false
                }
            }
        },
        snackControll: function (options) {
            this.snackOptions = options
            //this.snackOptions[0] = true
            //this.snackOptions[1] = 2500
            //this.snackOptions[2] = '신규 작성 모드'
            //info, success, warning, error
            //this.snackOptions[3] = 'info'
        },

    },
    data: () => ({
        key: 0,
        items: [],
        cdItems: [],
        dialogDelete: false,
        snackOptions: [false, 3000, '', 'info'],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !(value == '선택' || value == '') || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },
    }),
};
</script>