<template>
    <v-sheet class="ma-0" rounded="md" color="#F2F2F2">
        <div class="ma-0 px-7 py-3">
            <v-row class="mb-4 pa-0" no-gutters>            
                <v-spacer></v-spacer>
                <v-col class="d-flex" cols="1">
                    <v-spacer></v-spacer>
                    <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
                </v-col>
            </v-row>
            <v-row class="" no-gutters>
                <v-col cols="6" class="pr-2" style="">                    
                    <v-card
                        class="pt-10 pl-16"
                        color="#FFFFFF;"
                        height="100%"
                    >
                        <div><img src='https://harrygnd.co.kr/publicAccess/img/yysmail-logo.png' width="300" /></div>
                        <div class="d-inline-block" style="width: 100%; height: 30px;"></div>
                        <div>
                            <h1 class="fontTwoRem mb-3 font-weight-bold">요양시스에 오신 것을 환영합니다!</h1>
                            <p class="pa-0 mb-3 fontOneDotOneRem">요양시스를 선택해 주셔서 감사합니다.</p>
                            <p class="pa-0 mb-3 fontOneDotOneRem font-weight-bold">&#34;무료&#40;2달&#41; 기간&#34;중에 등록하신 모든 정보는</p>
                            <p class="pa-0 mb-3 fontOneDotOneRem"><strong>무료 기간이 종료 된 후에도 삭제되지 않고 그대로 유지</strong>됩니다.</p>
                            <div class="d-inline-block" style="width: 100%; height: 30px;"></div>
                            <p class="pa-0 mb-3 fontOneDotOneRem">사용 중 문의사항은 <strong>요양시스 고객센터&#40;031&#45;206&#45;1256&#41;</strong>나</p>
                            <p class="pa-0 mb-3 fontOneDotOneRem">프로그램 상단의 <strong>고객센터&#40;1:1문의&#41;</strong>를 이용해 주세요.</p>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="6" class="pl-2">
                    <v-card
                        class="py-5 px-16"
                        color="#FFFFFF;"
                        height="100%"
                    >
                        <h1 class="fontTwoRem txtAlgnMid mb-8 font-weight-bold">요양시스 이용안내</h1>
                        <p class="pa-0 mb-3 fontOneDotOneRem">아래 안내된 단계에 따라</p>
                        <p class="pa-0 mb-5 fontOneDotOneRem"><strong>기관의 기초 정보</strong>를 먼저 생성해 주시기 바랍니다.</p>                        
                        <p class="pa-0 mb-3 fontOneDotOneRem">기초 정보를 생성하신 후 각 메뉴별로 준비된</p>
                        <div class="mb-5 d-flex align-center">
                            <v-btn 
                                height="28"
                                @click="goPage('https://www.yoyangsys.co.kr/help')"
                                style="background-color: #10357D !important;padding: 11px 7px !important">
                                <img src='https://harrygnd.co.kr/publicAccess/img/joinimg05.png' />
                                <span class="white--text">메뉴얼</span>
                            </v-btn>
                            <p class="pl-2 mb-0 fontOneDotOneRem"><strong>참조</strong>하셔서 업무별로 기능을 익히시길 바랍니다.</p>
                        </div>
                        <h4 class="fontOneDotOneRem mb-3 font-weight-bold">&#60;기관 기초 정보 설정 순서&#62;</h4>
                        <v-row no-gutters>
                            <v-col class="d-flex" cols="12">
                                <div class="rounded px-4 py-2" style="width:200px;background-color: #004DC1;">
                                    <div class="white--text fontEighthRem mb-1">Step 1 (6-4)</div>
                                    <div class="white--text fontOneDotOneRem ">시설정보 설정</div>
                                </div>
                                <div class="px-4 d-flex align-center">
                                    <v-icon color="black" size="40">mdi-arrow-right-bold</v-icon>
                                </div>
                                <div class="rounded px-4 py-2" style="width:200px;background-color: #004DC1;">
                                    <div class="white--text fontEighthRem mb-1">Step 2 (6-4)</div>
                                    <div class="white--text fontOneDotOneRem ">건물 및 생활실 설정</div>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-spacer></v-spacer>
                            <v-col class="d-flex py-2" cols="3">
                                <v-icon color="black" size="40">mdi-arrow-down-bold</v-icon>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col class="d-flex" cols="12">
                                <div class="rounded px-4 py-2" style="width:200px;background-color: #004DC1;">
                                    <div class="white--text fontEighthRem mb-1">Step 4</div>
                                    <div class="white--text fontOneDotOneRem ">기초작업 및 기초설정</div>
                                </div>
                                <div class="px-4 d-flex align-center">
                                    <v-icon color="black" size="40">mdi-arrow-left-bold</v-icon>
                                </div>
                                <div class="rounded px-4 py-2" style="width:200px;background-color: #004DC1;">
                                    <div class="white--text fontEighthRem mb-1">Step 3 (6-5)</div>
                                    <div class="white--text fontOneDotOneRem ">비급여 수가 설정</div>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col class="d-flex justify-end py-2" cols="3">
                                <v-icon color="black" size="40">mdi-arrow-down-bold</v-icon>
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                        <v-row no-gutters>
                            <v-col class="d-flex" cols="12">
                                <div class="rounded px-4 py-2" style="width:100%;background-color: #004DC1;">
                                    <v-row no-gutters>
                                        <v-col class="" cols="2">
                                            <div class="white--text fontEighthRem mb-1">Step 5</div>
                                            <div class="white--text fontOneDotOneRem ">기초평가</div>
                                        </v-col>
                                        <v-col class="pr-4 d-flex justify-end align-center" cols="8">
                                            <span class="white--text fontOneDotOneRem">기초 정보 설정 매뉴얼 보러가기</span>
                                        </v-col>
                                        <v-col class="d-flex align-center" cols="1">
                                            <v-icon color="white" size="35">mdi-arrow-right-bold</v-icon>
                                        </v-col>
                                        <v-col class="pl-1 d-flex align-center" cols="1">
                                            <v-btn
                                                @click="goPage('https://www.yoyangsys.co.kr/help')"
                                                style="width:34px;height:34px;padding:0px 0px 0px 0px !important"
                                                fab
                                                color="white">
                                                <img src='https://harrygnd.co.kr/publicAccess/img/joinimg05.png' />
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>                                
                            </v-col>
                        </v-row>
                        <h4 class="mt-3 fontOneDotOneRem mb-3 font-weight-bold">&#60;2개월 무료체험 홈페이지 제작 안내서&#62;</h4>
                        <v-row no-gutters>
                            <v-col class="" cols="8">
                                <div class="rounded px-4 py-2" style="width:100%;background-color: #FC8611;">
                                    <v-row no-gutters>
                                        <v-col class="" cols="10">
                                            <v-row no-gutters>
                                                <div class="white--text mb-1" style="font-size: 0.5rem;">안내서 다운로드 하시고, 2개월 홈페이지 제작에 이용해보세요.</div>
                                            </v-row>
                                            <v-row no-gutters>
                                                <div class="white--text fontOneDotOneRem ">PDF 다운로드</div>
                                                <v-icon color="white" size="35">mdi-arrow-right-bold</v-icon>
                                            </v-row>
                                        </v-col>
                                        <v-col class="pl-1 d-flex align-center" cols="2">
                                            <v-btn
                                                @click="goPage('https://harrygnd.co.kr/publicAccess/pdf/2개월 무료 체험 홈페이지 제작 안내서 VOL.2.pdf')"
                                                style="padding:0px 0px 0px 0px !important"
                                                icon
                                                color="white">
                                                <img src='https://harrygnd.co.kr/publicAccess/img/img-pdf-icon.png' width="30"/>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>       
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </v-sheet>
</template>

<script>
export default {
    name: 'WelcomeMdl',

    props : {
    },
        
    components: {
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
        })
    },

    computed: {

    },

    watch:{

    },
    
    methods: { 
        onClose: function () {
            this.tab = -1
            this.$emit('modalClose', '2')
        },

        goPage:function (url) {
            window.open(url, '_blank')
        }
    },

    data: () => ({
    }),
};
</script>