<template>
    <v-sheet class="ma-0 pa-0" rounded="lg">
        <v-form ref="mdlform" lazy-validation>
            <div class="d-flex justify-center align-center">
                <v-col cols="4">
                    <span class="text-h4 font-weight-bold">프로그램&nbsp;운영기록&nbsp;관리</span>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="d-flex" cols="2">
                    <v-spacer></v-spacer>
                    <span v-if="progress">
                        <v-progress-circular            
                        :size="50"
                        color="primary"
                        indeterminate
                        ></v-progress-circular>
                    </span>
                    <span v-if="!progress">
                        <span v-for="(list, i) in filledBtn" :key="i">
                            <v-btn
                                v-if="list.type == 'action'"
                                :class="list.class" height="30" :color="list.color"
                                @click="onClickBtn(list.text)" :disabled="list.disabled"
                                rounded samll>
                                <v-icon small>{{ list.icon }}</v-icon>
                                <span class="fontOneRem">{{ list.text }}</span>
                            </v-btn>
                        </span>
                    </span>
                </v-col>
                <v-col class="d-flex" cols="1">
                    <v-spacer></v-spacer>
                    <v-icon color="black" @click="onClose" x-large>mdi-close</v-icon>
                </v-col>
            </div>
            <div class="pr-2 mt-2" style="height: 672px;">
                <v-tabs v-model="tab" class="tab-dft-Theme" active-class="black" center-active  hide-slider>
                    <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class" :disabled="item.enter">{{ item.tab }}</v-tab>
                </v-tabs>
                <v-divider class="bodyColor mb-2" style="padding: 1px 0px;"></v-divider>
                <v-tabs-items v-model="tab">
                    <v-tab-item transition="false" eager>
                        <tab1 ref="tab1" :tab="tab" @prgrmRecTrigger="prgrmRecLoad" @progressOff="progressOff"></tab1>
                    </v-tab-item>
                    <v-tab-item transition="false" eager>
                        <tab2 ref="tab2" :tab="tab" @prgrmRecTrigger="prgrmRecLoad" :recpk="prgOpRecPk" @progressOff="progressOff"></tab2>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </v-form>
    </v-sheet>
</template>

<script>
import tab1 from '../../components/prgrm/PrgrmRecTab1.vue';
import tab2 from '../../components/prgrm/PrgrmRecTab2.vue';

export default {
    name: 'PrgrmRec',
    
    components: {
        tab1,
        tab2,
    },

    props : { 
        prtCd3:{ type: Array, default: () => { return [] } },
        prtCd38:{ type: Array, default: () => { return [] } },
        prtCd40:{ type: Array, default: () => { return [] } },
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.$store.getters.getRootInObj != null){
                let obj = this.$store.getters.getRootInObj
                this.onShow(obj.prgOpPlnPk, obj.prgOpRecPk, obj.prgInfPk)
            }
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch: {
        getSubEvt: function (bol) {
            if(bol && this.rmCnfm){
                if(this.tab == 0)
                    this.$refs.tab1.onDelete()
                else if(this.tab == 1)
                    this.$refs.tab2.onDelete()

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.rmCnfm = false
            }
        },
    },
        
    methods: {
        onClose: function () {
            this.$store.commit('setRootInf', {
                actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
            });
            //this.$refs.tab1.resetPage()
            this.$refs.tab2.resetPage()
            this.tab = 0
        },
        onShow: function(plnPk, recpk, prgInfPk){
            this.prgOpPlnPk = plnPk
            this.prgOpRecPk = recpk
            this.prgInfPk = prgInfPk

            this.$refs.tab1.getPrgOpPlnInfo(plnPk)      //프로그램 계획(프로그램 일정등록)

            if(this.prgOpRecPk != null){
                this.$refs.tab1.selPrgOpRecInfo(recpk)  //프로그램 기록
                this.$refs.tab2.selPrgOpRecInfo(recpk)
            }
            // else                                        // 24.09.06 프로그램 명 수정 관련 상무님 요청하신 부분
            //     this.$refs.tab1.getPrgInfInfo()         // 프로그램 정보(4-2 프로그램정보관리)

            this.$refs.tab2.selPrgOpRecBnfcrInfo(plnPk)

            if(this.prgOpRecPk!=null){
                this.filledBtn[1].disabled = false
                this.tabItems[1].enter = false
            }else{
                this.filledBtn[1].disabled = true
                this.tabItems[1].enter = true
            }
        },
        reLoad: function(){
            this.$refs.tab1.getPrgOpPlnInfo(this.prgOpPlnPk)

            if(this.prgOpRecPk != null){
                this.$refs.tab1.selPrgOpRecInfo(this.prgOpRecPk)
                this.$refs.tab2.selPrgOpRecInfo(this.prgOpRecPk)
            }
            // else                                         // 24.09.06 프로그램 명 수정 관련 상무님 요청하신 부분
            //     this.$refs.tab1.getPrgInfInfo()

            this.$refs.tab2.selPrgOpRecBnfcrInfo(this.prgOpPlnPk)
            this.$refs.tab2.selPrgOpRecBnfcrList(this.prgOpRecPk, this.prgInfPk)

            if(this.prgOpRecPk!=null){
                this.filledBtn[1].disabled = false
                this.tabItems[1].enter = false
            }else{
                this.filledBtn[1].disabled = true
                this.tabItems[1].enter = true
            }
        },
        onClickBtn: function (text) {
            if(text == "저장"){
                if(this.tab == 0)
                    this.$refs.tab1.onSave()
                else if(this.tab == 1)
                    this.$refs.tab2.onSave()
            }else if(text == "삭제"){
                let obj = {}
                obj.icon = 'mdi-alert'
                obj.iconClr = 'red'
                if(this.tab == 0){
                    obj.title = '프로그램 운영기록 삭제'
                    obj.cntnt = ['프로그램 운영기록을 삭제하시겠습니까?']
                }
                else{
                    obj.title = '수급자별 평가 삭제'
                    obj.cntnt = ['프로그램 수급자별 평가를 삭제하시겠습니까?']
                }
                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 삭제하겠습니다.'

                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });

                this.rmCnfm = true
            }
        },
        prgrmRecLoad: function (type, res) {
            if(type == "ins"){
                if(res.statusCode == 200){
                    let obj = {}
                    obj.actv = true              // 경고 노출여부
                    obj.tmr = 2500               // 경고 노출시간(-1: 무제한)
                    obj.type = 'info'            // 경고 유형 (success, info, warning, error)
                    obj.title = res.message      // 경고 타이틀
                    obj.cntnt = []               // 경고 내용 (arr만 출력됨!)

                    this.$store.commit('setWrnnInf', obj)
    
                    if(this.tab == 0){
                        this.filledBtn[1].disabled = false
                        this.tabItems[1].enter = false
                        this.prgOpRecPk = parseInt(res.result)
                        this.reLoad()
                    }
                    else
                        this.reLoad()

                    this.$store.commit('setRootRunEvt', true)
                }else{
                    let obj = {}
                    obj.actv = true              // 경고 노출여부
                    obj.tmr = 5000               // 경고 노출시간(-1: 무제한)
                    obj.type = 'error'           // 경고 유형 (success, info, warning, error)
                    obj.title = res.message      // 경고 타이틀
                    obj.cntnt = []               // 경고 내용 (arr만 출력됨!)

                    this.$store.commit('setWrnnInf', obj)
                }
            }else {
                if(res.statusCode == 200){
                    let obj = {}
                    obj.actv = true              // 경고 노출여부
                    obj.tmr = 2500               // 경고 노출시간(-1: 무제한)
                    obj.type = 'info'            // 경고 유형 (success, info, warning, error)
                    obj.title = res.message      // 경고 타이틀
                    obj.cntnt = []               // 경고 내용 (arr만 출력됨!)

                    this.$store.commit('setWrnnInf', obj)

                    if(type == "del")
                        this.onClose()
                    else if(type == "delRe")
                        this.reLoad()
                    
                    this.$store.commit('setRootRunEvt', true)
                }
                else{
                    let obj = {}
                    obj.actv = true              // 경고 노출여부
                    obj.tmr = 5000               // 경고 노출시간(-1: 무제한)
                    obj.type = 'error'           // 경고 유형 (success, info, warning, error)
                    obj.title = res.message      // 경고 타이틀
                    obj.cntnt = []               // 경고 내용 (arr만 출력됨!)

                    this.$store.commit('setWrnnInf', obj)
                }
            }

            this.progress = false
            this.filledBtn[0].visible = false
        },
        progressOff: function(){
            this.progress = true
        },
    },
    data: () => ({
        tab:0,
        prgOpPlnPk:0,
        prgOpRecPk:0,
        prgInfPk:0,
        PrgOpRec: [],
        index: [],
        rmCnfm: false,
        progress: false,
        tabItems: [
            { index: 0, tab: '프로그램 운영기록', class: 'fontOneDotTwoRem px-15', enter:false},
            { index: 1, tab: '수급자별 평가', class: 'fontOneDotTwoRem px-15', enter:false},
        ],
        filledBtn: [
            { icon: 'mdi-clipboard-text-outline', class: 'white--text', color: 'blueBtnColor', text: '저장', type: 'action' , disabled: false, visible: true,},
            { icon: 'mdi-trash-can-outline', class: 'ml-2 white--text', color: 'grey006', text: '삭제', type: 'action' , disabled: false, visible: true,},
        ],    
    }),
};
</script>