<template>
    <v-sheet class="rounded-lg rounded-l-0" min-width="1260" max-width="1560" height="835" color="">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>  
        <div class="px-2 mt-2 mr-2 d-flex align-center">
            <div class="text-h4">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-1 hamletFont text-h5 topTitleColor--text font-weight-black">8-4. 요양급여가산관리</span>
            </div>
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn v-if="list.type == 'action'" @click="onClickBtn(i)"
                    :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                    <v-icon small>{{list.icon}}</v-icon>
                    <span style="font-size: 1rem;">{{list.text}}</span>
                </v-btn>
                <v-dialog v-else-if="list.type == 'dialogue'"
                    v-model="dialog[i]" :max-width="list.width" content-class="round">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                :class="list.class" samll height="30" :color="list.color" rounded>
                                <v-icon small>{{list.icon}}</v-icon>
                                <span style="font-size: 1rem;">{{list.text}}</span>
                            </v-btn>
                        </template>
                        <MsclnExpnsIns v-if="list.text == '기타비용 입력'"></MsclnExpnsIns>
                        <div v-else>개발중</div>
                </v-dialog>
            </span>
            <span class="d-inline" v-for="(list, j) in outlinedBtn" :key="filledBtn.length+j">
                <v-dialog
                    v-model="dialog[filledBtn.length + j]" :max-width="list.width">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                :class="list.class" samll height="30" :color="list.color" rounded outlined>
                                <v-icon small>{{list.icon}}</v-icon>
                                <span style="font-size: 1rem;">{{list.text}}</span>
                            </v-btn>
                        </template>
                        <div>개발중</div>  
                </v-dialog>
            </span>
        </div>
        <div class="mt-5">
            <expandMonthPicker :prtDate="date" @nowYearWithMonth="getPickerDate"></expandMonthPicker>
        </div>
        <div class="d-flex mt-3 px-3">
            <div class="d-inline-block pa-0 ma-0 align-self-start" style="width:40%;">
                <div class="d-flex align-center">
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold">기본정보</span>
                    <v-btn
                        class="ml-1" width="20" height="20" @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 8)" icon>
                        <v-icon size="20" style="padding-top: 3px;">mdi-help-circle-outline</v-icon>
                    </v-btn>
                </div>
                <div class="mt-2" style="border-top: 1px solid #dbdbdb;">
                    <v-row v-for="(list, h) in bnftAddScrBasic" :key="h" class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="7">
                            {{list.title}}
                        </v-col>     
                        <v-col class="d-flex justify-end align-center pa-1 ma-0" cols="5" style="border-left: 1px solid #dbdbdb !important;">
                            <span class="txtfldRT" style="width:80%">
                                <v-text-field color="blue" v-model="list.val" type="number" height="28" hide-details outlined dense></v-text-field>
                            </span>
                        </v-col>             
                    </v-row>   
                    <!-- <v-row v-for="(list, k) in bnftAddScrBasic2" :key="bnftAddScrBasic.length+k" class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="7">
                            {{list.title}}
                        </v-col>     
                        <v-col class="d-flex justify-end align-center pa-1 ma-0" cols="5" style="border-left: 1px solid #dbdbdb !important;">
                            <span class="txtfldRT" style="width:80%">
                                <v-text-field color="blue" v-model="list.val" type="number" hide-details outlined dense></v-text-field>
                            </span>
                        </v-col>             
                    </v-row>  -->
                    <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="7">
                            {{bnftAddScrBasic2[0].title}}
                        </v-col>     
                        <v-col class="d-flex justify-end align-center pa-1 ma-0" cols="5" style="border-left: 1px solid #dbdbdb !important;">
                            <span class="txtfldRT" style="width:80%">
                                <v-text-field color="blue" v-model="bnftAddScrBasic2[0].val" type="number" height="28" hide-details outlined dense></v-text-field>
                            </span>
                        </v-col>             
                    </v-row> 
                    <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb; height: 60px;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="7">
                            {{bnftAddScrBasic2[1].title}}
                        </v-col>     
                        <v-col class="" cols="5" style="border-left: 1px solid #dbdbdb !important;">
                            <div class="d-flex justify-end align-center pa-1 ma-0" >
                                <span class="txtfldRT" style="width:80%">
                                    <v-text-field color="blue" v-model="bnftAddScrBasic2[1].val" type="number" height="28" hide-details outlined dense></v-text-field>
                                </span>
                            </div>
                            <div class="d-flex justify-end align-center ma-0" style="font-size:0.9rem">
                                    {{bnftAddScrBasic2[1].val?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}}
                            </div>
                        </v-col>             
                    </v-row> 
                    <!-- <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="8">
                            주야비 야간근무시간
                        </v-col>     
                        <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="4" style="border-left: 1px solid #dbdbdb !important;">
                            <span class="txtfldRT" style="width:80px">
                                <v-text-field color="blue" type="number" hide-details outlined dense></v-text-field>
                            </span>
                        </v-col>             
                    </v-row> 
                    <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="8">
                            퐁당당 야간근무시간
                        </v-col>     
                        <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="4" style="border-left: 1px solid #dbdbdb !important;">
                            <span class="txtfldRT" style="width:80px">
                                <v-text-field color="blue" type="number" hide-details outlined dense></v-text-field>
                            </span>
                        </v-col>             
                    </v-row> 
                    <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="8">
                            야간전담 야간근무시간
                        </v-col>     
                        <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="4" style="border-left: 1px solid #dbdbdb !important;">
                            <span class="txtfldRT" style="width:80px">
                                <v-text-field color="blue" type="number" hide-details outlined dense></v-text-field>
                            </span>
                        </v-col>             
                    </v-row>  -->
                </div>
                <div class="d-flex mt-7 align-center">
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold">가산현황</span>
                </div>
                <div class="mt-2" style="border-top: 1px solid #dbdbdb;">
                    <v-row v-for="(list, l) in bnftAddScrResult" :key="l" class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="7">
                            {{list.title}}
                        </v-col>     
                        <v-col class="d-flex justify-end align-center py-1 px-2 ma-0" cols="5" style="border-left: 1px solid #dbdbdb !important;">                            
                            <span v-if="list.key === 'rqrdYN'">{{list.val}}</span>
                            <span v-else>{{list.val?.toLocaleString('ko-KR')}}</span>
                        </v-col>             
                    </v-row>  
                </div>
            </div>            
            <div class="d-inline-block pa-0 ma-0 ml-10 align-self-start" style="width:60%;">
                <div class="d-flex align-center">
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold">인력배치 가산점수</span>
                </div>
                <v-row class="pa-0 ma-0 mt-2" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="4">
                        <span class="black--text font-weight-medium">직종</span>
                    </v-col>  
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">의무인원</span>
                    </v-col>   
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">인원</span>
                    </v-col>              
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="4" style="border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">가산점수</span>
                    </v-col>              
                </v-row>
                <v-row v-for="list in bnftAddBscItemTop" :key="list.no" class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="4">
                        {{list.title}}
                    </v-col>   
                    <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">
                            {{list.val1}}
                    </v-col>     
                    <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">
                        <span class="txtfldRT" style="width:80px">
                            <v-text-field color="blue" v-model="list.val2" type="number" height="28" hide-details outlined dense></v-text-field>
                        </span>
                    </v-col>              
                    <v-col class="d-flex justify-end align-center py-1 px-2 ma-0" cols="4" style="border-left: 1px solid #dbdbdb !important;">
                        {{list.val3?.toLocaleString('ko-KR')}}
                    </v-col>              
                </v-row>    
                <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="pa-0 ma-0" cols="4">
                        <v-row class="pa-0 ma-0" no-gutters>
                            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="12">
                                <div style="height:28px;line-height:28px;">{{bnftAddBscItemMdl[0].title}}</div>
                            </v-col>
                            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="12" style="border-top: 1px solid #dbdbdb !important;">
                                <div style="height:28px;line-height:28px;">{{bnftAddBscItemMdl[1].title}}</div>
                            </v-col>
                        </v-row>
                    </v-col> 
                     <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">
                        {{bnftAddBscItemMdl[0].val1}}
                    </v-col>    
                    <v-col class="pa-0 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">
                        <v-row class="pa-0 ma-0" no-gutters>
                            <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="12">
                                <span class="txtfldRT" style="width:80px;">
                                    <v-text-field color="blue" v-model="bnftAddBscItemMdl[0].val2" type="number" height="28" hide-details outlined dense></v-text-field>
                                </span>
                            </v-col>
                            <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="12" style="border-top: 1px solid #dbdbdb !important;">
                                <span class="txtfldRT" style="width:80px">
                                    <v-text-field color="blue" v-model="bnftAddBscItemMdl[1].val2" type="number" height="28" hide-details outlined dense></v-text-field>
                                </span>
                            </v-col>
                        </v-row>
                    </v-col>     
                    <v-col class="pa-0 ma-0 ma-0" cols="4" style="border-left: 1px solid #dbdbdb !important;">
                        <v-row class="pa-0 ma-0" no-gutters>
                            <v-col class="d-flex justify-end align-center py-1 px-2 ma-0" cols="12">
                                <div style="height:28px;line-height:28px;">{{bnftAddBscItemMdl[0].val3?.toLocaleString('ko-KR')}}</div>
                            </v-col>
                            <v-col class="d-flex justify-end align-center py-1 px-2 ma-0" cols="12" style="border-top: 1px solid #dbdbdb !important;">
                                <div style="height:28px;line-height:28px;">{{bnftAddBscItemMdl[1].val3?.toLocaleString('ko-KR')}}</div>
                            </v-col>
                        </v-row>
                    </v-col>              
                </v-row>     
                <!-- <v-row v-for="list in bnftAddBscItemMdl" :key="list.no" class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="4">
                        {{list.title}}
                    </v-col>   
                    <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">
                            {{list.val1}}
                    </v-col>     
                    <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">
                        <span class="txtfldRT" style="width:80px">
                            <v-text-field color="blue" v-model="list.val2" type="number" hide-details outlined dense></v-text-field>
                        </span>
                    </v-col>              
                    <v-col class="d-flex justify-end align-center py-1 px-2 ma-0" cols="4" style="border-left: 1px solid #dbdbdb !important;">
                        {{list.val3?.toLocaleString('ko-KR')}}
                    </v-col>              
                </v-row>      -->
                <v-row v-for="list in bnftAddBscItemBtm" :key="list.no" class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center py-1 ma-0" cols="4">
                        <span>{{list.title}}</span>                        
                        
                    </v-col>   
                    <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">
                            {{list.val1}}
                    </v-col>     
                    <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">
                        <span class="txtfldRT" style="width:80px">
                            <v-text-field color="blue" v-model="list.val2" type="number" height="28" hide-details outlined dense></v-text-field>
                        </span>
                    </v-col>              
                    <v-col class="d-flex justify-center align-center py-1 px-2 ma-0" cols="4" style="border-left: 1px solid #dbdbdb !important;">
                        <span class="careBlnftAdd-check" v-if="list.key1 == 'empRqrd9' || list.key1 == 'empRqrd10' || list.key1 == 'empRqrd12'">
                            <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;" v-model="list.cnsgnYn" hide-details :ripple="false" label="위탁" true-value="1" false-value="2"></v-checkbox>
                        </span>
                        <v-spacer></v-spacer>
                        <span>{{list.val3?.toLocaleString('ko-KR')}}</span>
                    </v-col>              
                </v-row>           
            </div>
            <!-- <div class="d-inline-block pa-0 ma-0 ml-3 align-self-start" style="width:380px;">
                
            </div> -->
        </div>
    </v-sheet>
</template>
<script>
import expandMonthPicker from '../../components/commons/expandMonthPicker.vue';
import { selBnftAddBscInfo, selBnftAddScrInfo } from '../../api/impExp.js';

export default {
    name: 'CareBlnftAddMgmt',
        
    components: {
        expandMonthPicker
    },

    created: function(){
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
        })
    },

    watch:{
    },

    methods: {        
        onClickBtn: function (key) {
            switch (key) {
                case 0:        
                    this.getBnftAddScrInfo()
                    break;
                default:
                    break;
            }
        },
        getPickerDate: function (date) {
            this.date = date
            this.getBnftAddBscInfo(date)
        },
        //요양급여가산관리 기본정보 조회 
        getBnftAddBscInfo: function (date) {
            selBnftAddBscInfo(this.$store.getters.getFcltyNum, date)
                .then((response) => ( this.getBnftAddBscInfoAfter(response.data) ))
                .catch((error) => console.log('connect error /impExp/selBnftAddBscInfo : ' + error))
        },
        getBnftAddBscInfoAfter: function (res) {
            
            this.bnftAddBscItemTop.forEach(item => {
                item.val2 = res[item.key2]
                if(item.val2 == null) item.val2 = 0
            });

            this.bnftAddBscItemMdl.forEach(item => {
                item.val2 = res[item.key2]
                if(item.val2 == null) item.val2 = 0
            });

            this.bnftAddBscItemBtm.forEach(item => {
                item.val2 = res[item.key2]
                if(item.val2 == null) item.val2 = 0
            });

            this.bnftAddScrBasic.forEach(item => {                           
                item.val =  res[item.key]
                if(item.val == null) item.val = 0
            });
        },
        //가산점수 계산
        getBnftAddScrInfo:function(){
            if(this.bnftAddScrBasic[1].val > 0){
                let obj = {
                    bnfcrCnt:this.bnftAddScrBasic[0].val,
                    stdWrkHr:this.bnftAddScrBasic[1].val,
                    dayEmpCnt:this.bnftAddScrBasic[2].val,
                    ngtEmpCnt:this.bnftAddScrBasic[3].val,
                    dayWrkHr:this.bnftAddScrBasic[4].val,
                    ngtWrkHr:this.bnftAddScrBasic[5].val,
                    // dayWrkHr:this.bnftAddScrBasic2[0].val,
                    // ngtWrkHr:this.bnftAddScrBasic2[1].val,
                    prgOfrCnt:this.bnftAddScrBasic2[0].val,
                    bnftClmAmt:this.bnftAddScrBasic2[1].val,

                    empCnt1:this.bnftAddBscItemTop[0].val2,
                    empCnt2:this.bnftAddBscItemTop[1].val2,
                    empCnt3:this.bnftAddBscItemTop[2].val2,

                    empCnt4:this.bnftAddBscItemMdl[0].val2,
                    empCnt5:this.bnftAddBscItemMdl[1].val2,

                    empCnt6:this.bnftAddBscItemBtm[0].val2,
                    empCnt8:this.bnftAddBscItemBtm[1].val2,
                    empCnt9:this.bnftAddBscItemBtm[2].val2,
                    cnsgnYn9:this.bnftAddBscItemBtm[2].cnsgnYn,
                    empCnt10:this.bnftAddBscItemBtm[3].val2,
                    cnsgnYn10:this.bnftAddBscItemBtm[3].cnsgnYn,
                    empCnt11:this.bnftAddBscItemBtm[4].val2,
                    empCnt12:this.bnftAddBscItemBtm[5].val2,
                    cnsgnYn12:this.bnftAddBscItemBtm[5].cnsgnYn,
                    empCnt13:this.bnftAddBscItemBtm[6].val2,
                    empCnt18:this.bnftAddBscItemBtm[7].val2,
                    inqYYMM:this.date,
                }
                selBnftAddScrInfo(obj)
                    .then((response) => ( this.getBnftAddScrInfoAfter(response.data) ))
                    .catch((error) => console.log('connect error /impExp/selBnftAddScrInfo : ' + error))
            }else{
                this.snackControll([true, 3500, '월 기준 근무시간을 확인해주세요! (입력 값 0 이상)', 'warning'])
            }
        },
        getBnftAddScrInfoAfter:function(res){
            this.bnftAddBscItemTop.forEach(item => {
                item.val1 = res[item.key1]
                if(item.val1 == null) item.val1 = 0

                item.val3 = res[item.key3]
                if(item.val3 == null) item.val3 = 0
            });

            this.bnftAddBscItemMdl.forEach(item => {
                item.val1 = res[item.key1]
                if(item.val1 == null) item.val1 = 0

                item.val3 = res[item.key3]
                if(item.val3 == null) item.val3 = 0
            });

            this.bnftAddBscItemBtm.forEach(item => {
                item.val1 = res[item.key1]
                if(item.val1 == null) item.val1 = 0

                item.val3 = res[item.key3]
                if(item.val3 == null) item.val3 = 0
            });

            this.bnftAddScrResult.forEach(item => {
                item.val = res[item.key]
            })
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        
    },
    data: () => ({      
        date:'',
        dialog:[],  
        bnftAddBscItemTop:[
            {no:0,key1:'empRqrd1',key2:'empCnt1',key3:'addScr1',title:'시설장',cnsgnYn:'',val1:0,val2:0,val3:0}
            ,{no:1,key1:'empRqrd2',key2:'empCnt2',key3:'addScr2',title:'사무국장',cnsgnYn:'',val1:0,val2:0,val3:0}
            ,{no:2,key1:'empRqrd3',key2:'empCnt3',key3:'addScr3',title:'사회복지사',cnsgnYn:'',val1:0,val2:0,val3:0}           
        ],
        bnftAddBscItemMdl:[
            {no:3,key1:'empRqrd4',key2:'empCnt4',key3:'addScr4',title:'간호사',cnsgnYn:'',val1:0,val2:0,val3:0}
            ,{no:4,key1:'empRqrd5',key2:'empCnt5',key3:'addScr5',title:'간호조무사',cnsgnYn:'',val1:0,val2:0,val3:0}         
        ],
        bnftAddBscItemBtm:[
            {no:5,key1:'empRqrd6',key2:'empCnt6',key3:'addScr6',title:'물리(작업)치료사',cnsgnYn:'',val1:0,val2:0,val3:0}
            ,{no:6,key1:'empRqrd8',key2:'empCnt8',key3:'addScr8',title:'요양보호사',cnsgnYn:'',val1:0,val2:0,val3:0}
            ,{no:7,key1:'empRqrd9',key2:'empCnt9',key3:'addScr9',title:'영양사',cnsgnYn:'',val1:0,val2:0,val3:0}
            ,{no:8,key1:'empRqrd10',key2:'empCnt10',key3:'addScr10',title:'조리원',cnsgnYn:'',val1:0,val2:0,val3:0}
            ,{no:9,key1:'empRqrd11',key2:'empCnt11',key3:'addScr11',title:'사무원',cnsgnYn:'',val1:0,val2:0,val3:0}
            ,{no:10,key1:'empRqrd12',key2:'empCnt12',key3:'addScr12',title:'위생원',cnsgnYn:'',val1:0,val2:0,val3:0}
            ,{no:11,key1:'empRqrd13',key2:'empCnt13',key3:'addScr13',title:'관리인',cnsgnYn:'',val1:0,val2:0,val3:0}
            ,{no:12,key1:'empRqrd18',key2:'empCnt18',key3:'addScr18',title:'의사',cnsgnYn:'',val1:0,val2:0,val3:0}            
        ],
        bnftAddScrBasic:[
            {key:'bnfcrCnt',title:'총 입소인원수',val:0}
            ,{key:'stdWrkHr',title:'월 기준 근무시간',val:0}
            ,{key:'dayEmpCnt',title:'주간 인력수',val:0}
            ,{key:'ngtEmpCnt',title:'야간 인력수',val:0}
            ,{key:'dayWrkHr',title:'일기준 야간근무자 주간근무시간(06-22)',val:0}
            ,{key:'ngtWrkHr',title:'일기준 야간근무자 야간근무시간(22-06)',val:0}
        ],
        bnftAddScrBasic2:[
            // {key:'dayWrkHr',title:'주간 월기준 근무시간',val:0}
            // ,{key:'ngtWrkHr',title:'야간 월기준 근무시간',val:0}
            {key:'prgOfrCnt',title:'맞춤형 프로그램 제공 횟수',val:0}
            ,{key:'bnftClmAmt',title:'가산 전 급여비용 청구액',val:0}
        ],
        bnftAddScrResult:[
            {key:'rqrdYN',title:'필수인력배치 여부',val:''}
            ,{key:'addEmpScr',title:'인력 추가배치 가산점수',val:0}
            ,{key:'ngtAddScr',title:'야간직원 배치 가산점수',val:0}
            ,{key:'nrsAddScr',title:'간호사 배치 가산점수',val:0}
            ,{key:'prgAddScr',title:'맞춤형 서비스 제공 가산점수',val:0}
            ,{key:'totAddScr',title:'총 가산점수',val:0}
            ,{key:'totAddAmt',title:'총 가산금액',val:0}
        ],
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '계산', type: 'action', width:'800px', disabled: false, },            
        ],
        outlinedBtn: [
            // { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '출력', width:'1000px', disabled: false, },
        ],
        snackOptions: [false, 3000, '', 'info'],
    })
}
</script>
<style>
    .txtfldRT .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot input {
        text-align: right !important;
    }
    .careBlnftAdd-check .v-input--checkbox .v-icon.v-icon { font-size: 20px; color: #0c0c0c; }
</style>