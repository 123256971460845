<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">   
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>       
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">지급 항목 관리</span>
            <v-spacer></v-spacer>            
            <v-btn v-for="(list, i) in filledBtn" :key="i"  @click = "onClickBtn(i)"
                :class="list.class" height="30" :color="list.color" :disabled="list.disabled" rounded
                style="font-size: 14px;">
                <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                {{list.text}}
            </v-btn>
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <v-form ref="tabform" lazy-validation>       
            <div class="d-flex" style="width:100%">
                <div class="d-inline-block ma-0 align-self-start" style="width:50%">
                    <div class="d-flex align-center">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">지급 항목</span>
                        <v-spacer></v-spacer>                                
                    </div>
                    <v-divider :class="['mt-2', empWgePymList.length > 7 ? 'mr-2__5' : '']"></v-divider>
                    <div :class="['greyE01 d-flex pa-0', empWgePymList.length > 7 ? 'mr-2__5' : '']">
                        <div class="pa-1 ma-0" style="width:35%; text-align: center;">                                
                            <span class="black--text font-weight-medium">급여내역</span>
                        </div>
                        <div class="pa-1 ma-0 tableBL" style="width:30%; text-align: center;">                                
                            <span class="black--text font-weight-medium">과세구분</span>
                        </div>
                        <div class="pa-1 ma-0 tableBL" style="width:35%; text-align: center;">                                
                            <span class="black--text font-weight-medium">소득액</span>
                        </div>
                    </div>
                    <v-divider :class="[empWgePymList.length > 7 ? 'mr-2__5' : '']"></v-divider>
                    <div class="overflow-y-auto" style="height:370px;">
                        <div v-if="empWgePymList.length > 0">
                            <div v-for="(item,m) in empWgePymList" :key="m" class="d-flex pa-0 tableBB">
                                <div class="d-flex justify-center align-center py-1 pl-2 ma-0" style="width:35%;">                                
                                    {{item.itmNm}}
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0 tableBL" style="width:30%;">                                
                                    {{item.txClNm}}
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0 tableBL" style="width:35%;">                                                                            
                                    <v-row no-gutters>
                                        <v-col class="txtfldRT" cols="12">
                                            <v-text-field
                                                v-model="item.amt" color="blue" type="number" height="28" min="0" @input="calcTotPym()"
                                                hide-details outlined dense>
                                            </v-text-field>
                                        </v-col>
                                        <v-col class="d-flex justify-end pr-2" cols="12" style="font-size:0.75rem !important">
                                            {{item.amt?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}}
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </div>
                        <div v-else class="d-flex pa-0 tableBB" style="width:100%;">
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%">                                
                                데이터가 없습니다.
                            </div> 
                        </div>
                    </div>
                    <v-divider class="mt-4"></v-divider>
                    <div class="d-flex pa-0" style="width:100%;">
                        <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:65%;">                                
                            <span class="black--text font-weight-medium">과세 합계</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0 tableBL" style="width:35%;">                                
                            {{empWgePymTaxAmt?.toLocaleString('ko-KR')}}
                        </div>
                    </div>
                    <v-divider class=""></v-divider>
                    <div class="d-flex pa-0 ma-0" no-gutters style="width:100%;">
                        <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:65%;">                                
                            <span class="black--text font-weight-medium">비과세 합계</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0 tableBL" style="width:35%;">                                
                            {{empWgePymFreeAmt?.toLocaleString('ko-KR')}}
                        </div>
                    </div>
                    <v-divider class=""></v-divider>
                    <div class="d-flex pa-0 ma-0" no-gutters style="width:100%;">
                        <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:65%;">                                
                            <span class="black--text font-weight-medium">소득액 합계</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0 tableBL" style="width:35%;">                                
                            {{empWgePymTotAmt?.toLocaleString('ko-KR')}}
                        </div>
                    </div>
                    <v-divider class=""></v-divider>
                </div>                
                <div class="d-inline-block pl-2 ma-0 align-self-start" style="width:50%">  
                    <div class="d-flex align-center">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">명세서 산출식 또는 산출방법</span>
                    </div>
                    <div class="pa-0 ma-0 mt-2">
                        <v-row style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="4">
                                <span class="black--text font-weight-medium">구분</span>
                            </v-col>
                            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="8" style="border-left: 1px solid #dbdbdb !important">
                                <span class="black--text font-weight-medium">산출식 또는 산출방법</span>
                            </v-col>
                        </v-row>
                        <div v-for="(item, i) in wgeStmtList" :key="i">
                            <v-row v-if="item.view" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                                <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="4">
                                    {{item.wgeItmClnm}}
                                </v-col>
                                <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="8" style="border-left: 1px solid #dbdbdb !important">                                
                                    <v-text-field
                                        v-model="item.calcCntnt" 
                                        :placeholder="item.placeholder" 
                                        :disabled="item.disabled"
                                        height="28" hide-details outlined dense>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </div>
                        <div v-if="wgeStmtListLen == 0">
                            <v-row style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                                <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="12">
                                ※ 목록이 없습니다.
                                </v-col>                                
                            </v-row>
                        </div>
                    </div>
                </div>     
            </div>                    
        </v-form>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="'공제항목 재계산후 저장됩니다.'" :contents="''" @madalState="onEmpWgeSave"></btnModalDelete>  
            </v-dialog>
            <v-dialog v-model="menu" content-class="round" max-width="600" persistent>
                <AddPymItm ref="addPymItm" :rmList="empWgePymList" @modalClose="menu=false" @sltList="addWgePymList"></AddPymItm>
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import AddPymItm from './AddPymItm.vue';

import { updWgePymMulti } from '../../api/impExp.js';

export default {
    name: 'EmpWageStmtPymWrt',
    
    components: {  
        btnModalDelete,
        AddPymItm,
    },

    props : {
        
    },

    computed: {
        
    },

    created: function(){   
    },

    mounted: function(){
        this.$nextTick(function () {
        })
    },

    watch: {
        
    },

    methods: {  
       
        onClickBtn: function (key) {
            switch (key) {
                case 0:
                    this.dialogDelete = true
                    break;                
                default:
                    break;
            }
        },
        onEmpWgeSave:function(res){
            if(res == 9990){
                let amtValidate = true
                let snacktitle  = ''

                let pObj = {
                    fcltyNum:this.$store.getters.getFcltyNum,
                    empPks:[this.$parent.$parent.$parent.$parent.empPk],
                    empPk: this.$parent.$parent.$parent.$parent.empPk,
                    userNm:this.$store.getters.getUserNm,
                    inqYYMM:this.$parent.$parent.$parent.$parent.date,
                    stdYymm:this.$parent.$parent.$parent.$parent.date,
                    mmbrPk: this.$parent.$parent.$parent.$parent.mmbrPk,
                    rmrks:this.$parent.$parent.$parent.$parent.empItems.rmrks,
                    pymDt: this.$moment(this.$parent.$parent.$parent.$parent.empWgeItems.pymDt, 'YYYY-MM-DD', true).format('YYYYMMDD'),
                    list:[],
                }
                
                if(!this.$parent.$parent.$parent.$parent.cnfrm){

                    this.empWgePymList.forEach(item => {
                        let obj = {
                            wgePymPk:item.wgePymPk,
                            wgePymHisPk:item.wgePymHisPk,
                            pymDt:item.pymDt,
                            trgt:'2',
                            txClcd:item.txClcd,
                            trgtxClcdt:item.txClcd,
                            calcClcd:item.calcClcd,
                            txClNm:item.txClNm,
                            wgeItmPk:item.wgeItmPk,
                            wgeItmClcd:item.wgeItmClcd,
                            amt:item.amt,
                            calcCntnt:'',
                        }

                        let idx = this.wgeStmtList.findIndex(x=>x.wgeItmClcd == item.wgeItmClcd)

                        if(idx != -1){
                            obj.calcCntnt = this.wgeStmtList[idx].calcCntnt

                            if(item.amt > 0 && obj.calcCntnt == ''){
                                amtValidate = false 
                                snacktitle  = this.wgeStmtList[idx].wgeItmClnm
                            }
                        }

                        pObj.list.push(obj)
                    })

                    
                    if(amtValidate){
                        updWgePymMulti(pObj)
                            .then((response) => ( this.onEmpWgeSaveAfter(response.data) ))
                            .catch((error) => console.log('connect error /impExp/updWgePymMulti : ' + error))
                    }else{
                        this.dialogDelete = false
                        this.snackControll([true, 2500, "'"+snacktitle+"' 산출식 또는 산출방법을 입력해주세요.", 'warning'])
                    }
                }
                

            }else if(res == 7){
                this.dialogDelete = false
            }
        },
        onEmpWgeSaveAfter:function(res){
            this.$emit('mdlTrigger', res)
            this.onClose()
            this.dialogDelete = false
        },
       
        setData: function(data){   
            
            if(this.$parent.$parent.$parent.$parent.cnfrm) this.filledBtn[0].disabled = true 
            else  this.filledBtn[0].disabled = false 

            this.empWgePymList.splice(0)      
            let list  = JSON.parse(JSON.stringify(data))
            this.resetWgeStmt()

            list.forEach(item => {
                if(item.amt == '' || item.amt == null) item.amt = 0
                if(item.calcClcd == '1'){    
                    if(item.txClcd == '1')
                        item.txClNm = '과세'
                    else if(item.txClcd == '2')
                        item.txClNm = '비과세'

                    this.empWgePymList.push(item)
                }               
                
            })

            this.empWgePymList.forEach(item => {                
                switch (item.wgeItmClcd) {
                    case '18':
                        this.setWgeStmt(item)
                        break;
                    case '19':
                        this.setWgeStmt(item)
                        break;
                    case '20':
                        this.setWgeStmt(item)
                        break;                
                    default:
                        break;
                }
            })

            this.calcTotPym()   
            
        },        
        //지급, 공제 항목 합계 계산
        calcTotPym: function () {
            let totAmt = 0
            let taxAmt = 0
            let freeAmt = 0

            this.empWgePymList.forEach(pym => {                
                totAmt += Number(pym.amt)
    
                if(pym.txClcd == '1')
                    taxAmt += Number(pym.amt)
                else if(pym.txClcd == '2')
                    freeAmt += Number(pym.amt)


                switch (pym.wgeItmClcd) {
                    case '18':
                        this.setWgeStmtUse(pym)
                        break;
                    case '19':
                        this.setWgeStmtUse(pym)
                        break;
                    case '20':
                        this.setWgeStmtUse(pym)
                        break;                
                    default:
                        break;
                }
            });

            this.empWgePymTotAmt = totAmt
            this.empWgePymTaxAmt = taxAmt
            this.empWgePymFreeAmt = freeAmt
        },
        addWgePymList: function (sltList) {
            let tmpArr = this.empWgePymList.filter( v => v.wgePymPk > 0 )
            this.empWgePymList.splice(0)
            this.empWgePymList = tmpArr

            //trgt: 1(기관), 2(직원), pymDt 지급일자, wgeItmClcd 임금항목구분, 
            sltList.forEach(list => {
                list.trgt = '2'
                list.wgePymPk = 0
                if(this.empWgePymList.length > 0){
                    list.wgePymHisPk = this.empWgePymList[0].wgePymHisPk
                    list.pymDt = this.empWgePymList[0].pymDt
                }
                else{
                    list.wgePymHisPk = 0
                    list.pymDt = this.date
                }

                list.amt = 0
                list.txClNm = list.txClcdNm
                this.empWgePymList.push(list)

                switch (list.wgeItmClcd) {
                    case '18':
                        this.setWgeStmt(list)
                        break;
                    case '19':
                        this.setWgeStmt(list)
                        break;
                    case '20':
                        this.setWgeStmt(list)
                        break;                
                    default:
                        break;
                }
            });
        },
        resetWgeStmt:function(){
            this.wgeStmtListLen = 0
            this.wgeStmtList.forEach(el => {
                el.disabled     = true
                el.view         = false
                el.calcCntnt    = ''
            });
        },
        setWgeStmt:function(obj){                        
            let idx = this.wgeStmtList.findIndex(x=>x.wgeItmClcd == obj.wgeItmClcd)
            this.wgeStmtList[idx].calcCntnt     = obj.calcCntnt
            this.wgeStmtList[idx].placeholder   = "0시간 × "+this.cmnHrlWgs?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')+" × 0.5"
            this.wgeStmtList[idx].view          = true
            this.wgeStmtListLen++
        },
        setWgeStmtUse:function(obj){
            let idx = this.wgeStmtList.findIndex(x=>x.wgeItmClcd == obj.wgeItmClcd)
            if(obj.amt != '' && Number(obj.amt) > 0){
                this.wgeStmtList[idx].disabled      = false
            }else{
                this.wgeStmtList[idx].calcCntnt     = ''
                this.wgeStmtList[idx].disabled      = true
            }
        },
        onClose: function () {
            this.$emit('modalClose', 0)
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    data: () => ({ 
        menu: false,
        forceRender:0,
        empPk:0,
        cmnHrlWgs:0,
        empItems:{ pymDt: '', rmrks:'' },
        empWgeItems:{},
        empWgeWrkList:[],
        empWgePymTotAmt:0,
        empWgePymTaxAmt:0,
        empWgePymFreeAmt:0,
        empWgePymRealAmt:0,
        empWgePymList:[],
        empWgePymDctnList:[],
        wgeStmtList:[
            {wgeItmClnm:'연장가산수당', wgeItmClcd:'18',calcCntnt:'', placeholder:'', disabled:true, view:false},
            {wgeItmClnm:'야간가산수당', wgeItmClcd:'19',calcCntnt:'', placeholder:'', disabled:true, view:false},
            {wgeItmClnm:'휴일가산수당', wgeItmClcd:'20',calcCntnt:'', placeholder:'', disabled:true, view:false},
        ],
        wgeStmtListLen:0,
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type: 'action', width:'800px', disabled: false, },            
        ],
        outlinedBtn: [            
        ],
        snackOptions: [false, 3000, '', 'info'],
        dialogDelete:false,
    }),
};
</script>