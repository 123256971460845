<template>
    <v-sheet class="ml-0">
        <v-snackbar v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500"
            class="pa-0" :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
            <div style="text-align: center;">
                <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{ snackOptions[2] }}</span>
            </div>
        </v-snackbar>        
        <v-row class="mt-2 align-center" no-gutters>
            <v-col cols='4'>
                <exBtn class="mr-1" :btnData="exBtn[0]" @btnClosed="expandClose" @changeCheck="filterSearch" />
                <exBtn class="mr-1" :btnData="exBtn[1]" @btnClosed="expandClose" @changeCheck="filterSearch" />
                <span class="d-inline-block mt-1 mr-1" style="width: 130px;">
                    <v-text-field class="ma-0 pa-0 nameSearch" height="33px" background-color="white"
                        append-icon="mdi-magnify" single-line rounded outlined dense filled hide-details label="이름조회"
                        @input="setSearchText">
                    </v-text-field>
                </span>
            </v-col>
            <v-col cols='4'>
                <v-spacer></v-spacer>
                <span>
                    <expandNowDatePicker @nowDate="getDate"></expandNowDatePicker>
                </span>
                <v-spacer></v-spacer>
            </v-col>
            <v-col cols='4'>

            </v-col>
        </v-row>
        <div class="mt-2 rounded-lg">
            <v-data-table
                class="msclnTable" height="622" locale="ko"
                :headers="headers" 
                :items="empList" 
                :search="schDummy" 
                :custom-filter="customFilter"
                fixed-header disable-pagination hide-default-footer hide-default-header dense>
                <template v-slot:header="{ props }">
                        <thead>
                            <tr>
                                <th class="black--text clmsHeaderCommon tableBR tableBT">{{props.headers[0].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT">{{props.headers[1].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT">{{props.headers[2].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT">{{props.headers[3].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT">{{props.headers[4].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT">{{props.headers[5].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT">{{props.headers[6].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT">{{props.headers[7].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT">{{props.headers[8].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT">{{props.headers[9].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBT">{{props.headers[10].text}}</th>
                            </tr>
                        </thead>
                    </template>
                <template v-slot:item='{ item }'>
                    <tr>                        
                        <td class="tableContentCommon tableBR">{{item.rn}}</td>
                        <td class="tableContentCommon tableBR">{{item.wrkTyNm}}</td>
                        <td class="tableContentCommon tableBR">{{item.wrkStcd}}</td>
                        <td class="tableContentCommon tableBR">{{item.name}}</td>
                        <td class="tableContentCommon tableBR">
                            <div>
                                <v-img class="d-inline-block" style="vertical-align: middle;" v-if="item.gndr == 'F'" src="../../assets/icon/ic-female.svg" width="24"></v-img>
                                <v-img class="d-inline-block" style="vertical-align: middle;" v-else-if="item.gndr == 'M'" src="../../assets/icon/ic-male.svg" width="24"></v-img>
                            </div>
                        </td>
                        <td class="tableContentCommon tableBR">{{item.birth}}</td>
                        <td class="tableContentCommon tableBR">{{item.jobCd}}</td>
                        <td class="tableContentCommon tableBR">{{item.entDt}}</td>
                        <td class="tableContentCommon tableBR">{{item.rtrDt}}</td>
                        <td class="tableContentCommon tableBR">{{item.wrkMnt}}</td>
                        <td class="tableContentCommon">{{ item.celphn_1 }}&#45;{{ item.celphn_2 }}&#45;{{item.celphn_3}}</td>
                    </tr>
                </template>
            </v-data-table>
        </div>
    </v-sheet>
</template>
<script>
import expandNowDatePicker from '../../components/commons/expandNowDatePicker.vue';
import exBtn from '../../components/commons/expandButtonCustom.vue';
import { getMultiStcd } from '../../api/index.js';
import { selBtcInqEmpList } from '../../api/bnfcrStts';
import { snglHdrXlsxPrdc } from '../../api/global/cmmExcelJS.js';
export default {
    name: 'EmpInfSttsTab1',

    props: {
        tab: Number,
    },
    components: {
        exBtn,
        expandNowDatePicker,
    },

    computed: {
    },

    watch: {
    },

    created: function () {
        this.getCode(['42', '70', '73', '107'])
    },

    mounted: function () {
        this.$nextTick(function () {
        })
    },

    methods: {
        xlsxProd: function (){
            let shetNm = '직원현황'
            let title = shetNm + '_' + this.$store.getters.getFcltyNum + '_' + this.$moment().format('YYYYMMDD') 

            // 엑셀 생성 및 다운로드 ( 컬럼리스트, 데이터, 시트명, 파일명 )
            snglHdrXlsxPrdc(this.exlHeader, this.empList, shetNm, title)
            this.exlBtn = true
        },
        selBtcInqEmpList: function (obj) {
            selBtcInqEmpList(obj)
                .then((response) => (this.selBtcInqEmpListAfter(response.data)))
                .catch((error) => console.log('connect error /emp/selEmpWrkSchdHrlList : ' + error))
        },
        selBtcInqEmpListAfter: function (res) {
            res.forEach(items => {
                this.vCd42.forEach(item => {
                    if (items.jobCd == item.valcd)
                        items.jobCd = item.valcdnm
                });
                this.vCd70.forEach(item => {
                    if (items.wrkStcd == item.valcd)
                        items.wrkStcd = item.valcdnm
                });
                items.isSelected = false
            });
            this.empList.splice(0)   
            if(res != null){
                res.forEach(element => {
                    element.birth = element.birth?.beforeDateFormatDot()
                    element.entDt = element.entDt?.beforeDateFormatDot()
                    element.rtrDt = element.rtrDt?.beforeDateFormatDot()
                    this.empList.push(element)
                });
            }
        },
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => (this.stCdMapping(response.data)))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        stCdMapping: function (res) {
            this.vCd42 = res.vCd42
            this.vCd70 = res.vCd70
            this.vCd73 = res.vCd73
            this.vCd107 = res.vCd107

            this.vCd42.forEach(code => {
                this.exBtn[1].expandList.push(code.valcdnm)
            })
            this.vCd70.forEach(code => {
                this.exBtn[0].expandList.push(code.valcdnm)
            })
        },
        setSearchText: function (value) {
            this.schField = value

            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column
            let col0 = filter.name.includes(this.schField)

            let col1 = true
            if (this.schChkField.wrkStcd.length != 0) {
                this.schChkField.wrkStcd.forEach((stcd, i) => {
                    if (i == 0) col1 = filter.wrkStcd.includes(stcd)
                    else col1 = col1 || filter.wrkStcd.includes(stcd)
                });
            }

            let col2 = true
            if (this.schChkField.jobCd.length != 0) {
                this.schChkField.jobCd.forEach((stcd, i) => {
                    if (i == 0) col2 = filter.jobCd.includes(stcd)
                    else col2 = col2 || filter.jobCd.includes(stcd)
                });
            }

            return col0 && col1 && col2;
        },
        filterSearch: function (sch) {
            switch (this.schChkField.target) {
                case 'wrkStcd': this.schChkField.wrkStcd.splice(0)
                    sch.forEach((item) => { this.schChkField.wrkStcd.push(item) });
                    break;
                case 'jobCd': this.schChkField.jobCd.splice(0)
                    sch.forEach((item) => { this.schChkField.jobCd.push(item) });
                    break;
            }

            this.schDummy = this.schDummy + '.'
        },
        expandClose: function (index) {
            switch (index) {
                case 0: this.schChkField.target = 'wrkStcd'
                    break;
                case 1: this.schChkField.target = 'jobCd'
                    break;
                default: this.schChkField.target = ''
                    break;
            }
        },
        getDate: function (obj) {
            this.date = obj.date
            let pobj = {
                inqYMD: this.date,
                fcltyNum: this.$store.getters.getFcltyNum
            }
            this.selBtcInqEmpList(pobj)
        },
        getPickerDate: function (obj) {
            this.mDate = obj
        },
        getYear: function (obj) {
            this.yDate = obj
        },
        selectedDate: function () {
            if (this.srchCd == 0) {
                let obj = {
                    inqYMD: this.date,
                    fcltyNum: this.$store.getters.getFcltyNum
                }
                this.selBtcInqEmpList(obj)
            } else if (this.srchCd == 1) {
                let obj = {
                    inqYYMM: this.mDate,
                    fcltyNum: this.$store.getters.getFcltyNum
                }
                this.selBtcInqEmpList(obj)
            } else {
                let obj = {
                    inqYYYY: this.yDate,
                    fcltyNum: this.$store.getters.getFcltyNum
                }
                this.selBtcInqEmpList(obj)
            }
        },
    },
    data: () => ({
        vCd42: [],  //직원 직종 코드
        vCd70: [],  //직원 상태 코드
        empList: [],
        schField: '',
        schDummy: '',
        schChkField: { wrkStcd: [], jobCd: [], target: '' },
        exBtn: [
            { index: 0, btnTitle: '상태', btnIcon: 'house', exWidth: 84, exHeigth: 100, expandList: [], },
            { index: 1, btnTitle: '직종', btnIcon: 'business', exWidth: 134, exHeigth: 220, expandList: [], },
        ],
        headers: [
            { text: '연번', value: 'rn', sortable: true, align: 'center', },
            { text: '근무현황', value: 'aplctNm', sortable: true, align: 'center', },
            { text: '상태', value: 'name', sortable: true, align: 'center', },
            { text: '직원명', value: 'acknwRtng', sortable: true, align: 'center', },
            { text: '성별', value: 'outDtStrt', sortable: true, align: 'center', },
            { text: '생년월일', value: 'outTmStrt', sortable: true, align: 'center', },
            { text: '담당직종', value: 'jobCd', sortable: true, align: 'center', },
            { text: '입사일', value: 'outTmEnd', sortable: true, align: 'center', },
            { text: '퇴사일', value: 'dstnt', sortable: true, align: 'center', },
            { text: '근무개월수', value: 'rsn', sortable: true, align: 'center', },
            { text: '휴대폰', value: 'rsn', sortable: true, align: 'center',},
        ],
        exlHeader: [
            { header: '연번', key: 'rn', width: 8, },
            { header: '근무현황', key: 'wrkTyNm', width: 12, },
            { header: '상태', key: 'wrkStNm', width: 12, },
            { header: '직원명', key: 'name', width: 12, },
            { header: '성별', key: 'gndrNm', width: 10, },
            { header: '생년월일', key: 'birth', width: 16, },
            { header: '담당직종', key: 'jobNm', width: 16, },
            { header: '입사일', key: 'entDt', width: 12, },
            { header: '퇴사일', key: 'rtrDt', width: 12, },
            { header: '근무개월수', key: 'wrkMnt', width: 16, },
            { header: '휴대폰', key: 'celphnNm', width: 16, },
        ],
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>
