<template>
    <v-sheet class="rounded-lg rounded-l-0 overflow-auto pa-2" color="" :min-width="areaWidth+88" :max-width="areaWidth+100">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <v-row class="px-2 mt-10" no-gutters>
            <expandYearOnlyPicker @nowYear="getYear"></expandYearOnlyPicker>
            <v-spacer></v-spacer>
            <v-btn
                :class="outlinedBtn[0].class"
                :color="outlinedBtn[0].color" :disabled="empAnlWageist.length == 0"
                @click="getEmplWgePymExlList(year, empPk)"
                height="30" samll rounded outlined>
                <v-icon style="padding-top: 2px;" small>{{outlinedBtn[0].icon}}</v-icon>
                <span class="fontOneRem ml-1">{{outlinedBtn[0].text}}</span>
            </v-btn>
            <v-btn
                :class="outlinedBtn[1].class"
                :color="outlinedBtn[1].color" :disabled="empAnlWageist.length == 0"
                @click="empAnlWageStmtPrt"
                height="30" samll rounded outlined>
                <v-icon style="padding-top: 2px;" small>{{outlinedBtn[1].icon}}</v-icon>
                <span class="fontOneRem ml-1">{{outlinedBtn[1].text}}</span>
            </v-btn>
        </v-row>
        <div class="px-2 mt-3">
            <v-data-table
                class="" locale="ko" height="665" min-width="900"
                :headers="headers" :items="empAnlWageist"
                @current-items="calcTotAmt"
                hide-default-header fixed-header hide-default-footer disable-pagination dense>

                <template v-slot:header="{ props }" >
                    <thead>
                        <tr>                            
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 70px !important;" colspan="1" rowspan='2'>{{props.headers[0].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="" colspan="3">{{props.headers[1].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 250px !important;" colspan="4">{{props.headers[2].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 80px !important;" colspan="1" rowspan='2'>{{props.headers[3].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBT" style="width: 100px !important;" colspan="2">{{props.headers[4].text}}</th>
                        </tr>
                        <tr style="border-top: 1px solid #dbdbdb !important;">
                            <th class="black--text clmsHeaderCommon tableBR py-1" style="width: 80px !important;">{{props.headers[5].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR py-1" style="width: 95px !important;">{{props.headers[6].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR py-1" style="width: 80px !important;">{{props.headers[7].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR py-1" style="width: 80px !important;">{{props.headers[8].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR py-1" style="width: 80px !important;">{{props.headers[9].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR py-1" style="width: 80px !important;">{{props.headers[10].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR py-1" style="width: 80px !important;">{{props.headers[11].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR py-1" style="width: 80px !important;">{{props.headers[12].text}}</th>
                            <th class="black--text clmsHeaderCommon py-1" style="width: 100px !important;">{{props.headers[13].text}}</th>
                        </tr>
                    </thead>
                </template>                
                <template v-slot:item='{ item }'>
                    <tr>                        
                        <td class="clmsBodyCommon tableBR">{{item.stdYymmFm}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.amt1Tx?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.amt1Ntx?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.amt1Tot?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.amt2Ins?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.amt2Tx?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.amt2Msc?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.amt2Tot?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.amtRealPym?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.amtSclInsrn?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsBodyCommon">{{item.amtRtr?.toLocaleString('ko-KR')}}</td>                        
                    </tr>
                </template>

                <template v-slot:[`body.append`]="{ item }">
                    <tr style="z-index: 4 !important; position: sticky; bottom: 0px;">
                        <td class="clmsHeaderCommon black--text tableBR fixedTable py-2" style="" colspan="1">합계</td>                            
                        <td class="clmsHeaderCommon black--text tableBR" style="font-size: 0.875rem !important;" colspan="1">{{totItems.amt1Tx?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsHeaderCommon black--text tableBR" style="font-size: 0.875rem !important;" colspan="1">{{totItems.amt1Ntx?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsHeaderCommon black--text tableBR" style="font-size: 0.875rem !important;" colspan="1">{{totItems.amt1Tot?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsHeaderCommon black--text tableBR" style="font-size: 0.875rem !important;" colspan="1">{{totItems.amt2Ins?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsHeaderCommon black--text tableBR" style="font-size: 0.875rem !important;" colspan="1">{{totItems.amt2Tx?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsHeaderCommon black--text tableBR" style="font-size: 0.875rem !important;" colspan="1">{{totItems.amt2Msc?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsHeaderCommon black--text tableBR" style="font-size: 0.875rem !important;" colspan="1">{{totItems.amt2Tot?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsHeaderCommon black--text tableBR" style="font-size: 0.875rem !important;" colspan="1">{{totItems.amtRealPym?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsHeaderCommon black--text tableBR" style="font-size: 0.875rem !important;" colspan="1">{{totItems.amtSclInsrn?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsHeaderCommon black--text" style="font-size: 0.875rem !important;" colspan="1">{{totItems.amtRtr?.toLocaleString('ko-KR')}}{{item}}</td>
                    </tr>
                </template>
            </v-data-table>
        </div>
    </v-sheet>
</template>
<script>
import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';

import { selEmplWgePymList, selExlEmplWgeList2 } from '../../api/impExp.js';
import { empWageStmtXlsxPrdc } from '../../api/global/cmmExcelJS.js';

export default {
    name: 'EmpAnlWageStmtMgmt',
        
    components: {
        expandYearOnlyPicker,
    },

    created: function(){
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.areaWidth = 1260 - Number(this.$parent.$parent.midAreaWidth)
            this.year  = this.$moment().format('YYYY')
        })
    },

    computed: {
        getAreaWidth: function () {
            return this.$parent.$parent.midAreaWidth
        },
        getSltEmpPk: function () {
            return this.$parent.$parent.sltEmpPk
        },
    },  

    watch:{
        getAreaWidth: function (value) {
            this.areaWidth = 1260 - Number(value)
        },
        getSltEmpPk: function (value) {
            this.empPk = value
            if(value == -1){
                this.empAnlWageist.splice(0)
            }
            else {
                if(!this.dataCall) this.preEmplWgePymList()
            }
        },
    },

    methods: {        
        getYear: function (yyyy){
            this.year = yyyy
            if(!this.dataCall) this.preEmplWgePymList()
        },        
        preEmplWgePymList(){
            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum,
                inqYYYY     : this.year,
                empPk       : this.empPk,
            }
            this.getEmplWgePymList(obj)
        },
        getEmplWgePymList: function (obj) {
            this.dataCall = true
            selEmplWgePymList(obj)
                .then((response) => ( this.getEmplWgePymListAfter(response.data) ))
                .catch((error) => console.log('connect error /impExp/selEmplWgePymList : ' + error))
        },
        getEmplWgePymExlList: function (yyyy, pk) {
            this.dataCall = true

            selExlEmplWgeList2(this.$store.getters.getFcltyNum, yyyy, pk)
                .then((response) => ( this.emplWgePymExlRst(response.data) ))
                .catch((error) => console.log('connect error /impExp/selExlEmplWgeList2 : ' + error))
        },
        getEmplWgePymListAfter: function (res) {
            this.empAnlWageist.splice(0)
  
            if(res.statusCode == '200'){                                                                
                res.result?.forEach(el => {
                    el.stdYymmFm = this.$moment(el.stdYymm,'YYYYMM').format('MM')
                    this.empAnlWageist.push(el)
                });
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])

            this.dataCall = false
        },
        emplWgePymExlRst: function (res) {
            if(Object.keys(res.result).length > 0){
                if(res.result.list.length > 0){
                    // 연월 형식 변경
                    res.result.list.forEach(itm => {
                        itm.stdYymm = this.$moment(itm.stdYymm, 'YYYYMM').format('YYYY.MM')
                    });

                    // 엑셀 헤더 정보 작성
                    let exlColPrvsEtc = []
                    let exlColDdctEtc = []
                    let exlColPrvsTot = [{ header: '합계', key: 'pAmtTot', width: 20 }]
                    let exlColDdctTot = [{ header: '합계', key: 'dAmtTot', width: 20 }]
                    let actTot = [{ header: '실수령액', key: 'actAmtTot', width: 20 }]
                    res.result.ptAmtNmsList.forEach(itm => {
                        let obj = {}
                        obj.header = itm.val
                        obj.key = itm.col
                        obj.width = 22

                        exlColPrvsEtc.push(obj)
                    });
                    res.result.dtAmtNmsList.forEach(itm => {
                        let obj = {}
                        obj.header = itm.val
                        obj.key = itm.col
                        obj.width = 22

                        exlColDdctEtc.push(obj)
                    });
                    
                    // 동적 컬럼 갯수 정보 작성
                    let colLengObj = {}
                    colLengObj.dft = this.exlColDft.length
                    colLengObj.prvs = this.exlColPrvs.length
                    colLengObj.prvsEtc = exlColPrvsEtc.length
                    colLengObj.ddct = this.exlColDdct.length
                    colLengObj.ddctEtc = exlColDdctEtc.length
                    colLengObj.fclty = this.exlColFclty.length

                    // 헤더 정보를 1차원 배열로 합성
                    let hArr = [
                        ...this.exlColDft,
                        ...this.exlColPrvs, ...exlColPrvsEtc, ...exlColPrvsTot,
                        ...this.exlColDdct, ...exlColDdctEtc, ...exlColDdctTot,
                        ...actTot, ...this.exlColFclty
                    ]

                    // 엑셀 생성 함수 호출 ( 연간 임금 내역 )
                    let ttl = this.$moment(this.year, 'YYYY').format('YYYY년') + ' ' + res.result.list[0].name
                    let subTtl = this.$moment(this.year, 'YYYY').format('YYYY.MM.DD') + ' ~ ' +
                        this.$moment(this.year, 'YYYY').endOf('year').format('YYYY.MM.DD')
                    empWageStmtXlsxPrdc( hArr, res.result.list, colLengObj, ttl, subTtl, this.$store.getters.getUserNm )
                }
                this.dataCall = false
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        // 합계 계산
        calcTotAmt: function (arr) {
            this.totItems.amt1Tx        = 0
            this.totItems.amt1Ntx       = 0
            this.totItems.amt1Tot       = 0
            this.totItems.amt2Ins       = 0
            this.totItems.amt2Tx        = 0
            this.totItems.amt2Msc       = 0
            this.totItems.amt2Tot       = 0
            this.totItems.amtRealPym    = 0
            this.totItems.amtSclInsrn   = 0
            this.totItems.amtRtr        = 0
            
            arr.forEach(itm => {
                itm.amt1Tot = Number(itm.amt1Tx)+Number(itm.amt1Ntx)
                itm.amt2Tot = Number(itm.amt2Tx)+Number(itm.amt2Ins)+Number(itm.amt2Msc)

                this.totItems.amt1Tx        += Number(itm.amt1Tx)
                this.totItems.amt1Ntx       += Number(itm.amt1Ntx)
                this.totItems.amt1Tot       += Number(itm.amt1Tot)
                this.totItems.amt2Ins       += Number(itm.amt2Ins)
                this.totItems.amt2Tx        += Number(itm.amt2Tx)
                this.totItems.amt2Msc       += Number(itm.amt2Msc)
                this.totItems.amt2Tot       += Number(itm.amt2Tot)
                this.totItems.amtRealPym    += Number(itm.amtRealPym)
                this.totItems.amtSclInsrn   += Number(itm.amtSclInsrn)
                this.totItems.amtRtr        += Number(itm.amtRtr)
            });

        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        empAnlWageStmtPrt: function (){
            this.prtThrowObj.list[0].bzPk.splice(0)
            this.prtThrowObj.prtCd = '56'
            this.prtThrowObj.inqYYYY = this.year
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
            this.prtThrowObj.list[0].bzPk.push(this.empPk)

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
            });
        },
    },
    data: () => ({  
        year:'', 
        empPk:-1,
        dataCall:false,
        areaWidth: 708,
        empAnlWageist:[],
        snackOptions: [false, 3000, '', 'info'],
        totItems:{
            amt1Tx:0,
            amt1Ntx:0,
            amt1Tot:0,
            amt2Ins:0,
            amt2Tx:0,
            amt2Msc:0,
            amt2Tot:0,
            amtRealPym:0,
            amtSclInsrn:0,
            amtRtr:0,
        },
        exlColDft: [
            { header: '', key: 'empty', width: 5, },
            { header: '연월', key: 'stdYymm', width: 12, }, { header: '직원명', key: 'name', width: 12, },
            { header: '생년월일', key: 'rsdntBirth', width: 12, }, { header: '입사일', key: 'entDt', width: 12, },
            { header: '직책', key: 'jobNm', width: 12, }, { header: '급여구분', key: 'wgeTypNm', width: 12, },
            { header: '근무시간', key: 'actWrkHr', width: 12, }, { header: '근무일수', key: 'actWrkDd', width: 12, },
        ],
        exlColPrvs:[
            { header: '기본급', key: 'pAmt1', width: 14, }, { header: '장기근속장려금', key: 'pAmt2', width: 14, },
            { header: '주휴수당', key: 'pAmt3', width: 14, }, { header: '시간외수당', key: 'pAmt4', width: 14, },
            { header: '야간근무수당', key: 'pAmt5', width: 14, }, { header: '휴일근무수당', key: 'pAmt6', width: 14, },
            { header: '연장근무수당', key: 'pAmt7', width: 14, }, { header: '연차수당', key: 'pAmt8', width: 14, },
            { header: '연말정산', key: 'pAmt9', width: 14, }, { header: '식대(비과세)', key: 'pAmt10', width: 14, },
        ],
        exlColDdct: [
            { header: '국민연금', key: 'dAmt1', width: 16, }, { header: '고용보험', key: 'dAmt2', width: 16, },
            { header: '건강보험', key: 'dAmt3', width: 16, }, { header: '장기요양보험', key: 'dAmt4', width: 16, },
            { header: '소득세', key: 'dAmt5', width: 16, }, { header: '지방소득세', key: 'dAmt6', width: 16, },
        ],
        exlColFclty:[
            { header: '국민연금', key: 'fAmt1', width: 16, }, { header: '고용보험', key: 'fAmt2', width: 16, },
            { header: '건강보험', key: 'fAmt3', width: 16, }, { header: '장기요양보험', key: 'fAmt4', width: 16, },
            { header: '산재보험', key: 'fAmt5', width: 16, }, { header: '퇴직적립금', key: 'dAmtRtr', width: 16, },
        ],
        headers: [
            { text: '월', value: '', align: 'center'},
            { text: '지급내역', value: '', align: 'center'},
            { text: '공제내역', value: '', align: 'center'},
            { text: '실지급액', value: '', align: 'center'},
            { text: '기관부담금', value: '', align: 'center'},
            { text: '과세합계', value: '', align: 'center'},
            { text: '비과세합계', value: '', align: 'center'},
            { text: '합계', value: '', align: 'center'},
            { text: '4대보험', value: '', align: 'center'},
            { text: '소득세', value: '', align: 'center'},
            { text: '기타공제', value: '', align: 'center'},            
            { text: '합계', value: '', align: 'center'},            
            { text: '사회보험', value: '', align: 'center'},            
            { text: '퇴직적립금', value: '', align: 'center'},            
        ],
        outlinedBtn: [
            { icon: 'mdi-microsoft-excel', class: 'ml-1 font-weight-bold', color:'grey006', text: '엑셀', width:'1000px', disabled: true, },
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '출력', width:'1000px', disabled: false, },
        ],
        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '56', inqYmdStart: '', inqYmdEnd: '',
            rptTyp: '1', mode: '2', list: [ { bzClcd: [], bzPk: [] } ],
        },
    })
}
</script>