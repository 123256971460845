<template>
    <v-sheet class="rounded-lg rounded-l-0 overflow-auto pa-2" color="" :min-width="areaWidth+80" :max-width="areaWidth+80">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex mx-2 mt-2">
            <div class="d-flex justify-center align-center">
                <!--
                <v-icon style="font-size: 26px;" color="black">mdi-chevron-down</v-icon>
                -->
                <span class="d-inline-block text-h5 font-weight-bold" style="height:30px; color: #004584;">{{subTitle}}</span>
            </div>
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn :class="list.class" height="30" :color="list.color"
                    :disabled="list.disabled" @click="onClickBtn(i)" samll rounded>
                    <v-icon small>{{list.icon}}</v-icon>
                    <span style="font-size: 1rem;">{{list.text}}</span>
                </v-btn>
            </span>
            <span v-for="(list, j) in outlinedBtn" :key="filledBtn.length + j">
                <v-btn :disabled="list.disabled"
                    :class="list.class" @click = "onPrtBtn()"
                    samll height="30" :color="list.color" rounded outlined>
                    <v-icon small>{{list.icon}}</v-icon>
                    {{list.text}}
                </v-btn>
            </span>   
        </div>
        <v-divider class="my-2"></v-divider>
        <div>
            <v-tabs
                v-model="tab" class="tab-dft-Theme"
                :active-class="tab > 5 ? '' : 'black'" center-active hide-slider>
                <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class" :disabled="item.enter">{{item.tab}}</v-tab>
            </v-tabs>

            <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>

            <v-tabs-items v-model="tab">
                <v-tab-item eager transition="fade-transition">
                    <DrugInspc ref="drugInspc"></DrugInspc>
                </v-tab-item>
                <v-tab-item eager transition="fade-transition">
                    <PrdcDsnfc ref="prdcDsnfc"></PrdcDsnfc>
                </v-tab-item>
                <v-tab-item eager transition="fade-transition">
                    <EmrMdcDvcIns ref="emrMdcDvcIns"></EmrMdcDvcIns>
                </v-tab-item>
                <v-tab-item eager transition="fade-transition">
                    <MdcWstIns ref="mdcWstIns"></MdcWstIns>
                </v-tab-item>
                <v-tab-item eager transition="fade-transition">
                    <InfDssMng ref="infDssMng"></InfDssMng>
                </v-tab-item>
            </v-tabs-items>
        </div>
        <v-dialog v-model="menu[0]" max-width="500">    
            <btnModalDelete :title="delTitle" @madalState="delModalAct"></btnModalDelete>  
        </v-dialog>
    </v-sheet>
</template>

<script>
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import DrugInspc from '../../components/sfty/DrugInspc.vue';
import PrdcDsnfc from '../../components/sfty/PrdcDsnfc.vue';
import EmrMdcDvcIns from '../../components/sfty/EmrMdcDvcIns.vue';
import MdcWstIns from '../../components/sfty/MdcWstIns.vue';
import InfDssMng from '../../components/sfty/InfDssMng.vue';

export default {
    name: 'InfctCntrlInspc',
    
    components: {
        btnModalDelete,
        DrugInspc,
        PrdcDsnfc,
        EmrMdcDvcIns,
        MdcWstIns,
        InfDssMng,
    },

    props : {

    },

    created: function(){
        for (let index = 0; index < this.outlinedBtn.length+1; index++) {
            this.dialog.push(false)      
        }
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.areaWidth = 1260 - Number(this.$parent.$parent.midAreaWidth)
        })
    },

    computed: {
        getSltInspRecPk: function () {
            return this.$parent.$parent.sltInspRecPk
        },
        getSelectedItem: function () {
            return this.$parent.$parent.selectedItem
        },
        getAreaWidth: function () {
            return this.$parent.$parent.midAreaWidth
        },
    },  

    watch: {
        getSltInspRecPk: function (value) {
            if(value == 0){
                this.btnControll(1, true, 'fill')
                this.btnControll(0, true, 'out')
            }
            else if(value != 0){
                this.btnControll(1, false, 'fill')
                this.btnControll(0, false, 'out')
            }
        },
        getSelectedItem: function (value) {
            if(value == -1){
                this.tab = this.tabItems.length
                this.tabItems.forEach(items => {
                    items.enter = true
                });
                this.btnControll(0, true, 'fill')
            }
            else{
                let itmPos = value.substring(value.length-2, value.length)

                this.tab = Number(itmPos.replaceAll('0', ''))-1
                this.tabItems.forEach(items => {
                    if(items.index != this.tab) items.enter = true
                    else if(items.index == this.tab) items.enter = false
                });
                
                this.btnControll(0, false, 'fill')
            }
        },
        getAreaWidth: function (value) {
            this.areaWidth = 1260 - Number(value)
        },
    },

    methods: {
        clearForm: function () {
            if(this.tab == 0)
                this.$refs.drugInspc.clearForm()
            else if(this.tab == 1)
                this.$refs.prdcDsnfc.clearForm()
            else if(this.tab == 2)
                this.$refs.emrMdcDvcIns.clearForm()
            else if(this.tab == 3)
                this.$refs.mdcWstIns.clearForm()
            else if(this.tab == 4)
                this.$refs.infDssMng.clearForm()
        },
        delModalAct: function (res) {
            this.delTitle = ''
            if(res == 9990){    //OK
                if(this.tab == 0)
                    this.$refs.drugInspc.removeInspRecDtl()
                else if(this.tab == 1)
                    this.$refs.prdcDsnfc.removeInspRecDtl()
                else if(this.tab == 2)
                    this.$refs.emrMdcDvcIns.removeInspRecDtl()
                else if(this.tab == 3)
                    this.$refs.mdcWstIns.removeInspRecDtl()
                else if(this.tab == 4)
                    this.$refs.infDssMng.removeInspRecDtl()

                this.menu.splice(0, 1, false)
            }
            else if(res == 7)   //Cancel
                this.menu.splice(0, 1, false)
        },
        onClickBtn: function (index) {
            switch(index) {
                case 0:     //저장
                    if(this.tab == 0)
                        this.$refs.drugInspc.preInspRecDtllist()
                    else if(this.tab == 1)
                        this.$refs.prdcDsnfc.preInspRecDtllist()
                    else if(this.tab == 2)
                        this.$refs.emrMdcDvcIns.preInspRecDtllist()
                    else if(this.tab == 3)
                        this.$refs.mdcWstIns.preInspRecDtllist()
                    else if(this.tab == 4)
                        this.$refs.infDssMng.preInspRecDtllist()
                    break;
                case 1:     //삭제
                    if(this.tab == 0)
                        this.delTitle = '약품점검내용'
                    else if(this.tab == 1)
                        this.delTitle = '정기소독내용'
                    else if(this.tab == 2)
                        this.delTitle = '응급의료기기점검내용'
                    else if(this.tab == 3)
                        this.delTitle = '의료폐기물점검내용'
                    else if(this.tab == 4)
                        this.delTitle = '감염병관리내용'
                    this.menu.splice(0, 1, true)
                    break;
                default:
                    break;
            }
        },
         //출력
         onPrtBtn:function(){
            let prtCd = ''
            let date = ''

            switch (this.tab) {
                case 0:
                    prtCd = '73'
                    date = this.$moment(this.$refs.drugInspc.inspDt, 'YYYY-MM-DD').format('YYYYMMDD')
                    break;
                case 1:
                    prtCd = '74'
                    date = this.$moment(this.$refs.prdcDsnfc.inspDt, 'YYYY-MM-DD').format('YYYYMMDD')
                    break;
                case 2:
                    prtCd = '75'
                    date = this.$moment(this.$refs.emrMdcDvcIns.inspDt, 'YYYY-MM-DD').format('YYYYMMDD')
                    break;
                case 3:
                    prtCd = '76'
                    date = this.$moment(this.$refs.mdcWstIns.inspDt, 'YYYY-MM-DD').format('YYYYMMDD')
                    break;
                case 4:
                    prtCd = '77'
                    date = this.$moment(this.$refs.infDssMng.inspDt, 'YYYY-MM-DD').format('YYYYMMDD')
                    break;
                default:
                    break;
            }

            let obj = {}
            obj.rptTyp = '1'
            obj.mode = '2'
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnMmbrPk = null            
            obj.prtClcd = '1'
            obj.inqClcd = 2
            obj.prtCd = prtCd
            obj.inqYYYY = null
            obj.inqYmdStart = date
            obj.inqYmdEnd = date
            obj.list = [ { bzClcd: [], bzPk: [] } ]
      
            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: obj, outObj: null, runEvt: true,
            });

        },
        btnControll: function (index, dis, type) {
            switch (type) {
                case 'out':
                    this.outlinedBtn[index].disabled = dis
                    break;
                case 'fill':
                    this.filledBtn[index].disabled = dis
                    break;
                default:
                    break;
            }
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    data: () => ({
        tab: 0,
        dialog: [],
        menu: [false],
        delTitle: '',
        subTitle: '',
        areaWidth: 708,
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'white--text', color:'blueBtnColor', text: '저장', disabled: false, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', disabled: true, },
        ],
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '출력', width:'1000px', disabled: true, },
        ],
        tabItems: [
            { index: 0, tab: '약품점검', class: 'fontOneDotTwoRem px-7', enter: true },
            { index: 1, tab: '정기소독', class: 'fontOneDotTwoRem px-7', enter: true },
            { index: 2, tab: '응급의료기기', class: 'fontOneDotTwoRem px-7', enter: true },
            { index: 3, tab: '의료폐기물', class: 'fontOneDotTwoRem px-7', enter: true },
            { index: 4, tab: '감염병관리', class: 'fontOneDotTwoRem px-7', enter: true },
        ],
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>