<template>
    <v-sheet class="ma-0 overflow-y-auto" rounded="md">
        <v-divider class=""></v-divider>
        <v-row no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                <span class="font-weight-medium">점검일시</span>
            </v-col>
            <v-col class="pa-1" cols="10">
                <span class="d-inline-block mr-1" style="width: 133px;">
                    <CmmDateComp
                        v-model="inspDt" :required="true"
                        :disabled="$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk > 0">
                    </CmmDateComp>
                </span>
                <span class="white d-inline-block pa-1" style="width: 80px;">
                    <CmmTimeField
                    v-model="inspTm" :required="false"></CmmTimeField>                                     
                </span>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                <span class="font-weight-medium">점검기관</span>
            </v-col>
            <v-col class="pa-1" cols="10">
                <span class="d-inline-block" style="width:100%;">
                    <v-text-field v-model="inspAgcy" height="28" hide-details outlined dense></v-text-field>
                </span>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                <div class="d-flex align-center">
                    <span class="font-weight-medium">자료첨부</span>
                    <v-file-input 
                        class="athFileInput" v-model="files" truncate-length="5" @change="upFile()"
                        prepend-icon="mdi-upload" multiple counter hide-input outlined dense hide-details>
                    </v-file-input>
                </div>
            </v-col>
            <v-col class="ma-0 pl-1 pb-1" cols="10">
                <span class="mr-1" v-for="(item, j) in fileList" :key="j">
                    <v-chip            
                        class="mt-1" label close outlined
                        @click="fileDownload(j)" @click:close="delfiles(j)">
                        <v-icon v-if="item.ext=='pdf' || item.ext=='PDF'" color='pink' left>
                            mdi-file-pdf-box
                        </v-icon>
                        <v-icon v-else-if="item.ext=='xlsx' || item.ext=='xls'" color='green' left>
                            mdi-microsoft-excel
                        </v-icon>
                        <v-icon v-else-if="item.ext=='pptx' || item.ext=='ppt'" color='red' left>
                            mdi-file-powerpoint
                        </v-icon>
                        <v-icon v-else-if="item.ext=='doc' || item.ext=='docx'" color='blue' left>
                            mdi-file-word
                        </v-icon>
                        <v-icon v-else color='cyan' left>
                            mdi-file-document
                        </v-icon>
                        <span>{{ item.orgFileNm }}</span>
                    </v-chip> 
                </span>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-dialog v-model="dialogDelete" max-width="500">    
            <btnModalDelete :title="title" @madalState="removeFileItm"></btnModalDelete>  
        </v-dialog>
    </v-sheet>
</template>

<script>
import btnModalDelete from '../bnfcr/BnfcrDeleteConfirm.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';

import { tmpFileUpload, fileDownload, delAtchdFile, selAtchdFileList } from '../../api/index.js';
import { selInspRecDtl1list, insInspRecDtl, delInspRecDtl } from '../../api/sfty.js';

export default {
    name: 'FcsftInspcYear',

    props : {
        clsfcMode: { type: Number, default: 0 }
    },
        
    components: {
        btnModalDelete,
        CmmDateComp,
        CmmTimeField,
    },

    created: function(){
       
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
        
        })
    },

    computed: {
        getSltItem: function () {
            return this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem
        },
    },

    watch:{
        getSltItem: function (value) {
            if(value == -1) {
                this.clearForm()
                return
            }
            let sltInspDate = value.substring(0, 4)
            let insPos = this.$parent.$parent.$parent.$parent.$parent.$parent.sftyList.findIndex(v => v.inspPrd == sltInspDate)
            let inspDate= this.$parent.$parent.$parent.$parent.$parent.$parent.sftyList[insPos]

            if(this.$parent.$parent.$parent.$parent.tab == 0 && this.clsfcMode == 2){
                let sltInspDate = 0
                this.getAtchdFileList()
                if(typeof inspDate.itm1 != 'undefined')
                    sltInspDate = inspDate.itm1.afterDateFormatDot()
                this.getInspRecDtllist(sltInspDate, this.clsfcMode)
            }
            else if(this.$parent.$parent.$parent.$parent.tab == 1 && this.clsfcMode == 4){
                let sltInspDate = 0
                this.getAtchdFileList()
                if(typeof inspDate.itm2 != 'undefined')
                    sltInspDate = inspDate.itm2.afterDateFormatDot()
                this.getInspRecDtllist(sltInspDate, this.clsfcMode)
            }
            else if(this.$parent.$parent.$parent.$parent.tab == 2 && this.clsfcMode == 6){
                let sltInspDate = 0
                this.getAtchdFileList()
                if(typeof inspDate.itm3 != 'undefined')
                    sltInspDate = inspDate.itm3.afterDateFormatDot()
                this.getInspRecDtllist(sltInspDate, this.clsfcMode)
            }
            else if(this.$parent.$parent.$parent.$parent.tab == 3 && this.clsfcMode == 8){
                let sltInspDate = 0
                this.getAtchdFileList()
                if(typeof inspDate.itm5 != 'undefined')
                    sltInspDate = inspDate.itm5.afterDateFormatDot()
                this.getInspRecDtllist(sltInspDate, this.clsfcMode)
            }
        },
    },
    
    methods: {
        //시설안전점검 조회
        getInspRecDtllist: function (date, clsfc2Cd){
            selInspRecDtl1list(this.$store.getters.getFcltyNum, this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk, date, 506, clsfc2Cd)
                .then((response) => ( this.getInspRecDtllistAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/selInspRecDtl1list : ' + error))
        },
        //시설안전점검 저장
        setInspRecDtl: function (obj){
            insInspRecDtl(obj)
                .then((response) => ( this.setInspRecAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/insInspRecDtl : ' + error))
        },
        //시설안전점검 삭제
        removeInspRecDtl: function (){
            delInspRecDtl(this.$store.getters.getFcltyNum, this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk, this.$store.getters.getUserNm, '5' )
                .then((response) => ( this.setInspRecAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/delInspRecDtl : ' + error))
        },
        //첨부파일 목록 조회
        getAtchdFileList: function (){
            selAtchdFileList(this.$store.getters.getFcltyNum, 5, this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk)
                .then((response) => ( this.getAtchdFileListAfter(response.data) ))
                .catch((error) => console.log('upload error /opr/insMtngRcrdFile : ' + error))
        },
        //첨부파일 목록 조회
        athFileUpload: function (formData) {
            tmpFileUpload(formData)
                .then((response) => ( this.upFileAfter(response.data) ))
                .catch((error) => console.log('upload error /opr/tmpFileUpload : ' + error))
        },
        //첨부파일 다운로드
        athFileDownload: function (index) {
            fileDownload(this.fileList[index])
                .then((response) => ( this.fileDownLoaderAfter(response) ))
                .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
        },
        //첨부파일 삭제
        removeAthFile: function (pk) {
            delAtchdFile(this.$store.getters.getFcltyNum, pk)
                .then((response) => ( this.delAtchdFileAfter(response.data) ))
                .catch((error) => console.log('upload error /opr/insMtngRcrdFile : ' + error))
        },
        getInspRecDtllistAfter: function (res){
            this.inspTitle.splice(0)
            this.inspRecDtllist.splice(0)

            let yyyyList = res.filter(v => v.clsfc3Cd == '1')
            let keyList = []
            yyyyList.forEach(items => {
                items.itm1Cd = Number(items.itm1Cd)

                if(keyList.length == 0)
                    keyList.push(items.clsfc3CdNm)
                else{
                    let tmp = keyList.find(v => v === items.clsfc3CdNm)
                    if(tmp == undefined) keyList.push(items.clsfc3CdNm)
                }

                this.wrtr = items.wrtr

                if(items.inspDt != null)
                    this.inspAgcy = items.itm1Val

                if(items.inspDt == null)
                    this.inspDt = (this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem+'01').beforeDateFormatHyp()
                else
                    this.inspDt = items.inspDt?.beforeDateFormatHyp()

                if(items.inspTmHh == null)
                    this.inspTm = '12:00'
                else
                    this.inspTm = items?.inspTmHh + ':' + items?.inspTmMm
  
                if(items.itm1Val == null)
                    items.itm1Val = '0'

            });

            //res.sort((x, y) => x.itm1Cd - y.itm1Cd)

            this.inspTitle = keyList
            this.inspRecDtllist = yyyyList
        },
        setInspRecAfter: function (res){
            if(res.statusCode == 200){
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])
                this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk = 0
                this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem = -1
                this.$parent.$parent.$parent.$parent.$parent.$parent.getTableList()
                this.$parent.$parent.$parent.$parent.tab = 0
                
                this.clearForm()
                this.$parent.$parent.$parent.$parent.btnControll(1, true, 'fill')
                this.$parent.$parent.$parent.$parent.btnControll(0, true, 'out')
            }
            else
                this.$parent.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])
        },
        getAtchdFileListAfter:function(res){
            this.files = null
            this.fileList.splice(0)
            
            res.forEach( items => {
                let fileNm = items.fileNm.split(".")
                items.ext = fileNm[1]  
                this.fileList.push(items)
            })
        },
        delAtchdFileAfter: function (res) {
            if(res.statusCode == 200){
                this.fileList.splice(0)
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])
                this.getAtchdFileList()
            }
            else {
                this.$parent.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])
            }

            this.dialogDelete = false
        },
        preInspRecDtllist: function (){
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm
            obj.clsfc1Cd = 506
            obj.clsfc2Cd = this.clsfcMode
            obj.bzClcd = '5'
            obj.inspRecPk = this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk
            obj.inspDt = this.inspDt.afterDateFormatHyp()
            obj.files = []
            this.fileList.forEach( items => {
                if(items.atchdFilePk == undefined){
                    obj.files.push(items)
                }
            })

            obj.list = []

            this.inspRecDtllist.forEach(items => {               
                items.inspDtlDt = this.inspDt.afterDateFormatHyp()
                items.inspDt = this.inspDt.afterDateFormatHyp()
                items.itm1Val = this.inspAgcy
                let tmp = this.inspTm.split(':', 2)
                items.inspTmHh = tmp[0]
                items.inspTmMm = tmp[1]
  
                obj.list.push(items)
            });

            //console.log(obj)
            this.setInspRecDtl(obj)
        }, 
        upFileAfter: function (res) {

            res.files.forEach(items => {
                let fileNm = items.fileNm.split(".")
                items.ext = fileNm[1]
                this.fileList.push(items)
            });

            this.files = null
        },
        fileDownLoaderAfter: function (res) {
            let contentDisposition = decodeURI(res.headers['content-disposition'])
       
            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'))
                if (fileNameMatch)
                    [ , fileName ] = fileNameMatch.split('=')
            }

            link.href = url
 
            fileName = fileName.replaceAll('"', '')
            link.setAttribute('download', `${fileName}`)
            link.style.cssText = 'display:none'

            document.body.appendChild(link)

            link.click()
            link.remove()
        },
        upFile: function () {
            let formData = new FormData()
            
            if(this.files != null){
                this.files.forEach(element => {
                    formData.append("files", element)
                })

                if(this.files.length > 0){
                    formData.append("files", this.files)
                    this.athFileUpload(formData)
                }
            }            
        },
        fileDownload: function (idx) {
            if(this.fileList[idx] != undefined){
                this.athFileDownload(idx)
            }
        },
        delfiles: function (idx) {
            if(this.fileList[idx].atchdFilePk != undefined){
                this.idx = idx
                if(this.fileList[idx].orgFileNm.length > 20)
                    this.title = this.fileList[idx].orgFileNm.substring(0, 20) + '...'
                else
                    this.title = this.fileList[idx].orgFileNm
                this.dialogDelete = true
            }
            else {
                this.fileList.splice(idx, 1)
            }
        },
        removeFileItm: function(res) {
            if(res == 9990){
                let pk = 0

                pk = this.fileList[this.idx].atchdFilePk

                if(pk > 0)
                    this.removeAthFile(pk)
            }
            else if(res == 7){
                this.dialogDelete = false
            }
        },
        clearForm: function (){
            this.inspTitle.splice(0)
            this.inspRecDtllist.splice(0)
            this.fileList.splice(0)
            this.inspAgcy = ''
            this.inspDt = ''
            this.inspTm = ''
            this.idx = 0
           
            this.forceRender += 1
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
    },

    data: () => ({
        menu: [false],
        dialog: [false],
        inspTitle: [],
        inspRecDtllist: [],
        inspAgcy: '',
        inspDt : '',
        inspTm: '',
        files: null,
        fileList: [],
        idx: 0,
        title: '',
        dialogDelete: false,
        forceRender: 0,
        rules: {
            required: value => !!value || 'Required.',
        },
    }),
};
</script>

<style>
    .athFileInput .v-input__prepend-outer { margin: 3px 0 0 4px !important; }
    .athFileInput .v-input__prepend-outer .v-input__icon button { color: #2a9bed; }
    .athFileInput div div { height: 20px !important; }
    .athFileInput div div button { font-size: 20px !important; }
</style>