<template>
    <v-sheet>
        <div>
            <v-data-table
                height="300" locale="ko"
                :headers="headers"
                :items="examList"
                hide-default-header
                hide-default-footer 
                disable-pagination
                dense
                class="elevation-0">

                <template v-slot:header="{ props }" >
                    <thead>
                        <tr> 
                            <th class="black--text clmsFixedHeader tableBR" style="width:110px !important;">{{props.headers[0].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:610px !important;">{{props.headers[1].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:110px !important;">{{props.headers[2].text}}</th>
                            <th class="black--text clmsFixedHeader" style="width:90px !important;">{{props.headers[3].text}}</th>
                        </tr>
                    </thead>
                </template>

                <template v-slot:item='{ item }'>                            
                    <tr>
                        <td class="clmsBodyCommon tableBR fixedCol0" >{{item.examDt}}</td>
                        <td class="clmsBodyCommon tableBR fixedCol0" ><div style="width:600px" class="text-overflow">{{item.evltn}}</div></td>
                        <td class="clmsBodyCommon tableBR fixedCol0" >{{item.wrtr}}</td>
                        <td class="clmsBodyCommon fixedCol0" >
                            <v-btn icon :color="item.color" @click.stop="showDialog(item.bnfcrExamHisPk)">
                                <v-icon>mdi-square-edit-outline</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table>   
        </div>
    </v-sheet>
</template>
<script>
import { http } from '@/api/baseAxios';

export default {
    name: 'PhyscThrpyInspcRst',
    
    props : {        
        bnMmbrPk            : { type: Number, default: -1 },
        bnfcrPk             : { type: Number, default: -1 },
        inqYYYY             : { type: Number, default: 0 },
    },

    components: {
    },

    computed: {
        
    },  

    created: function(){
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getPhyThrExamList()
        })
    },

    watch: {
        'bnMmbrPk':function(){
            this.getPhyThrExamList()      
        },
        'inqYYYY':function(){
            this.getPhyThrExamList()      
        },
    },

    methods: {     
        reload:function(){
            this.getPhyThrExamList()
        },
        showDialog:function(examPk){
            this.$emit("isPhyscThrpyEvltn",examPk)
        },   
        //물리(작업)치료 평가및계획 현황 조회
        getPhyThrExamList: function(){
            this.examList.splice(0)                          

            let obj = {
                fcltyNum : this.$store.getters.getFcltyNum,
                bnMmbrPk : this.bnMmbrPk,
                inqYYYY : this.inqYYYY,
                bnfcrPk :this.bnfcrPk,
                rnStrt: 0, 
                rnLmt: 10
            }
            
            http.post('bnftoffer/selBnfcrPhyThrExamList', obj)
                .then((response) => ( this.getPhyThrExamRst(response.data) ))
                .catch((error) => console.log('connect error /bnftoffer/selBnfcrPhyThrExamList : ' + error))
            
        },

        getPhyThrExamRst:function(res){
            if(res.list != null){
                res.list.forEach(el => {
                    el.examDt = el.examDt?.beforeDateFormatHyp()
                    this.examList.push(el)
                });
            }
        }
    },

    data: () => ({ 
        examList:[],          
        headers: [
            { text: '작성일', value: '', align: 'center', width: '90', class: 'black--text greyE01 tableHeaderSize'},
            { text: '평가내용', value: 'aplctClcd', align: 'center', width: '300', class: 'black--text greyE01 tableHeaderSize'},
            { text: '작성자', value: '', align: 'center', width: '70', class: 'black--text greyE01 tableHeaderSize'},
            { text: '상세', value: '', align: 'center', width: '70', class: 'black--text greyE01 tableHeaderSize'},
        ],   
    }),
};
</script>