<template>
    <v-sheet>
        <v-data-table
            height="260" locale="ko"
            :headers="headersPln"
            :items="phyThrPlnHisList"
            hide-default-header
            hide-default-footer 
            disable-pagination
            dense
            class="elevation-0">

            <template v-slot:header="{ props }" >
                <thead>
                    <tr> 
                        <th class="black--text clmsFixedHeader tableBR" style="width:110px !important;">{{props.headers[0].text}}</th>
                        <th class="black--text clmsFixedHeader tableBR" style="width:110px !important;">{{props.headers[1].text}}</th>
                        <th class="black--text clmsFixedHeader tableBR" style="width:110px !important;">{{props.headers[2].text}}</th>
                        <th class="black--text clmsFixedHeader tableBR" style="width:110px !important;">{{props.headers[3].text}}</th>
                        <th class="black--text clmsFixedHeader" style="width:90px !important;">{{props.headers[4].text}}</th>
                    </tr>
                </thead>
            </template>

            <template v-slot:item='{ item }'>
                
                <tr>
                    <td class="clmsBodyCommon tableBR fixedCol0" style="text-align: center;">{{item.wrtDt}}</td>
                    <td class="clmsBodyCommon tableBR fixedCol0" style="text-align: center;">{{item.thrStrt}} ~ {{item.thrEnd}}</td>
                    <td class="clmsBodyCommon tableBR fixedCol0" style="text-align: center;">{{item.thrTimes}}</td>
                    <td class="clmsBodyCommon tableBR fixedCol0" style="text-align: center;">{{item.wrtr}}</td>
                    <td class="clmsBodyCommon fixedCol0" style="text-align: center;">
                        <v-btn icon :color="item.color" @click.stop="showDialog(item.bnftOfrPlnHisPk)">
                            <v-icon>mdi-square-edit-outline</v-icon>
                        </v-btn>
                    </td>
                </tr>                            
            </template>
        </v-data-table>
    </v-sheet>
</template>
<script>
import { http } from '@/api/baseAxios';

export default {
    name: 'PhyscThrpyPlnRst',
    
    props : {        
        bnMmbrPk            : { type: Number, default: -1 },
        inqYYYY             : { type: Number, default: 0 },
    },

    components: {
    },

    computed: {
        
    },  

    created: function(){
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getPhyThrPlnList()
        })
    },

    watch: {
        'bnMmbrPk':function(){            
            this.getPhyThrPlnList()    
        },
        'inqYYYY':function(){            
            this.getPhyThrPlnList()    
        },
    },

    methods: {                
        reload:function(){
            this.getPhyThrPlnList()
        },
        showDialog:function(hisPk){
            this.$emit("isPhyscThrpyPln",hisPk)
        },

        //물리치료계획상세 조회
        getPhyThrPlnList:function(){
            this.phyThrPlnHisList.splice(0)

            let obj = {
                fcltyNum        : this.$store.getters.getFcltyNum,
                bnMmbrPk        : this.bnMmbrPk,
                inqYYYY         : this.inqYYYY,
            }
            
            http.post('bnftoffer/selPhyThrPlnList', obj)
                .then((response) => ( this.getPhyThrPlnListRst(response.data) ))
                .catch((error) => console.log('connect error /bnftoffer/selPhyThrPlnList : ' + error))
        },

        getPhyThrPlnListRst:function(res){
            if(res != null && res != ''){
                res.forEach(el => {
                    el.thrStrt = el.thrStrt?.beforeDateFormatHyp()
                    el.thrEnd = el.thrEnd?.beforeDateFormatHyp()
                    el.wrtDt = el.wrtDt?.beforeDateFormatHyp()
                    this.phyThrPlnHisList.push(el)
                });
            }
        },
    },

    data: () => ({ 
        headersPln: [
            { text: '작성일', value: '', align: 'center', width: '90', class: 'black--text greyE01 tableHeaderSize'},
            { text: '치료기간', value: 'aplctClcd', align: 'center', width: '200', class: 'black--text greyE01 tableHeaderSize'},
            { text: '치료횟수/시간', value: '', align: 'center', width: '100', class: 'black--text greyE01 tableHeaderSize'},
            { text: '작성자', value: '', align: 'center', width: '70', class: 'black--text greyE01 tableHeaderSize'},
            { text: '상세', value: '', align: 'center', width: '70', class: 'black--text greyE01 tableHeaderSize'},
        ],
        phyThrPlnHisList:[],
    }),
};
</script>