<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" min-height="410">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold"> 물리&#40;작업&#41;치료기록 등록</span>
            <v-spacer></v-spacer>
                     
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>       
        <div class="d-flex justify-left align-center mb-2">
            <v-spacer></v-spacer> 
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn v-if="list.type == 'action' && list.visible" @click = "onClickBtn(list.text)"
                    :class="list.class" height="30" :color="list.color" rounded                    
                    style="font-size: 14px;">
                    <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                    {{list.text}}
                </v-btn>
            </span>   
        </div>
        <v-form ref="form" lazy-validation>
            <v-row style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01" cols="2">
                    <span class="black--text font-weight-medium py-2">수급자</span>
                </v-col>
                <v-col class="d-flex pl-2 justify-start align-center" cols="4" style="border-left: 1px solid #dbdbdb;">
                    <span class="d-inline-block" style="width: 80px;">
                        <v-text-field v-model="items.bnfcrNm" color="blue" height="28" ref="info" readonly :rules="[rules.required]"
                            hide-details outlined dense :key="forceRender"></v-text-field>
                    </span>
                    <v-btn 
                        class="ml-1 brown lighten-3" 
                        small 
                        min-width="30"
                        height="26"  
                        style="padding:0px 1px 0px 2px !important"
                        @click="onModal(1)"
                        >
                        <v-icon size="20" class="white--text">mdi-account-edit</v-icon>
                    </v-btn>
                </v-col>           
                <v-col class="d-flex justify-center align-center greyE01" cols="2" style="border-left: 1px solid #dbdbdb;">
                    <span class="black--text font-weight-medium py-2">담당자</span>
                </v-col>
                <v-col class="d-flex pl-2 justify-start  align-center" cols="4" style="border-left: 1px solid #dbdbdb;">
                    <span style="width:70px;">
                        <v-text-field v-model="items.wrtr" color="blue" height="28" :rules="[rules.required]" hide-details outlined dense readonly :key="forceRender"></v-text-field>
                    </span>
                    <v-btn 
                        class="ml-1 brown lighten-3" 
                        small 
                        min-width="30"
                        height="26"  
                        style="padding:0px 1px 0px 2px !important"
                        @click="onModal(0)"
                        >                                    
                        <v-tooltip top >
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon size="20" class="white--text" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                            </template>
                            <span>직원선택</span>
                        </v-tooltip>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row style="border-bottom: 1px solid #dbdbdb;" no-gutters>            
                <v-col class="d-flex justify-center align-center greyE01" cols="2">
                    <span class="black--text font-weight-medium py-2">치료시간</span>
                </v-col>
                <v-col class="d-flex pl-2 justify-start align-center" cols="10" style="border-left: 1px solid #dbdbdb;">
                    <span style="width:60px;">
                        <CmmTimeField v-model="items.ofrTmStrt" :required="true"></CmmTimeField>                                     
                    </span>                                
                    <span class="px-1">&#126;</span>
                    <span style="width:60px;">
                        <CmmTimeField v-model="items.ofrTmEnd" :required="true"></CmmTimeField> 
                    </span>
                </v-col>            
            </v-row>
            <v-row style="border-bottom: 1px solid #dbdbdb;" no-gutters>            
                <v-col class="d-flex justify-center align-center greyE01" cols="2">
                    <span class="black--text font-weight-medium py-2">치료구분</span>
                </v-col>
                <v-col class="d-flex pl-2 justify-start align-center" cols="10" style="border-left: 1px solid #dbdbdb;">
                    <span class="d-inline-block" style="width: 110px;">
                        <v-select 
                            v-model="items.thrClcd"                               
                            :items="vCd37"
                            item-text="valcdnm"
                            item-value="valcd"
                            dense hide-details outlined
                            :rules="[rules.required]"
                            @change="resetType"
                        />     
                    </span> 
                </v-col>       
            </v-row>     
            <v-row style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01" cols="2" style="border-left: 1px solid #dbdbdb;">
                    <span class="black--text font-weight-medium py-2">치료방법</span>
                    <v-btn class="" color="blue200" height="26" @click="onModalType()" icon>
                        <v-icon>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                </v-col>
                <v-col class="d-flex pl-2 justify-start align-center" cols="10" style="border-left: 1px solid #dbdbdb;">
                    <span v-if="items.thrClcd=='1'">                                                                                
                        <v-chip v-for="(cdItme, n) in items.thrMthdcd" :key="n" 
                            style="margin:2px !important;padding:0px 5px; margin:2px !important;font-size:0.8rem !important;"
                            label outlined
                        >
                            {{cdItme?.aftValCdToNm(vCd92)}}
                        </v-chip>
                    </span>
                    <span v-if="items.thrClcd=='2'">   
                        <v-chip v-for="(cdItme, n) in items.thrMthdcd" :key="n" 
                            style="margin:2px !important;padding:0px 5px;margin:2px !important;font-size:0.8rem !important;"
                            label outlined>                                            
                            {{cdItme?.aftValCdToNm(vCd93)}}
                        </v-chip>
                    </span>               
                </v-col>            
            </v-row>
            <v-row style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01" cols="2" style="border-left: 1px solid #dbdbdb;">
                    <span class="black--text font-weight-medium py-2">특이사항</span>
                </v-col>
                <v-col class="d-flex pa-2 justify-start align-center" cols="10" style="border-left: 1px solid #dbdbdb;">
                    <v-textarea v-model="items.cntnt" class="rmrksArea" style="font-size:0.9rem !important;"
                        rows="4" outlined dense hide-details no-resize>
                    </v-textarea>             
                </v-col>            
            </v-row>
        </v-form>
        
        <div class="ma-0 pa-0">
            <v-dialog v-model="menu[0]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="isModal(0)" @outEmp="getEmpInfo" :prtWrtDt="inqYMD"></EmpSelector>
            </v-dialog>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="menu[1]" max-width="700" content-class="round" persistent eager>           
                <IntgrSlctn
                    :mdlTtl="'수급자 선택'" :tabInfList="['1']" :fixList="exclsList" mode="1"
                    :cd41="vCd41"
                    :cd42="vCd42"
                    :cd65="vCd65"
                    :prtWrtDt = "inqYMD"
                    @intgrResList="getAplyList" @onClose="isModal(1)">
                </IntgrSlctn>
            </v-dialog>
        </div>    
        <div class="pa-0 ma-0">
            <v-dialog v-model="menu[2]" content-class="round" persistent max-width="600">
                <PhyscThrpyType ref="physcThrpyType" 
                :physcThrpyTypePk="physcThrpyTypePk"
                :thrClcd="thrClcd"
                :thrMthdcd="thrMthdcd"
                @cdReload="cdReload"
                @isClose="isModal(2)" 
                @onApply="onModalApply"></PhyscThrpyType>
            </v-dialog>       
        </div>
    </v-sheet>
</template>
<script>
import IntgrSlctn from '../commons/IntgrSlctn.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';
import EmpSelector from '../../components/commons/EmpSelector.vue';
import { getMultiStcd } from '../../api/index.js';
import PhyscThrpyType from '../bnftSrv/PhyscThrpyType.vue';
import { insPhythrBnftOfrRecMulti } from '../../api/bnftrcrd.js';

export default {
    name: 'PhyscThrpyRcrdDayDirt',

    props : {        
        inqYMD: { type: String, default: '' },       
        physcDayDirtPk : { type: Number, default: -1 }, 
    },
        
    components: {
       IntgrSlctn,
       CmmTimeField,
       EmpSelector,
       PhyscThrpyType,
    },
    

    watch: {     
        'physcDayDirtPk':function(value){    
            if(value > 0) {
                this.items = Object.assign({},this.dummyItems)
                this.$refs.form.resetValidation()
            }
        }
    },

    created: function(){
         this.$nextTick(function () { 
            this.onCodeload()
         })
    },

    mounted: function(){             
        this.$nextTick(function () {  
            
        })
    },
    
    methods: {
        onClickBtn:function(text){
            if(text == '저장') this.onSave()
        },
        onClose:function(){
            this.$emit("isClose",2)
        },
        onSave: function(){        
            if(this.$refs.form.validate())    {

                let ofrTmHhStrt = ''
                let ofrTmMmStrt = ''
                let ofrTmHhEnd = ''
                let ofrTmMmEnd = ''
    
                if(this.items.bnMmbrPk > 0){
                    
                    if(this.items.ofrTmStrt?.isEmptyVal()){
                        let ofrTmStrt = this.items.ofrTmStrt.split(":")
                        ofrTmHhStrt = ofrTmStrt[0]
                        ofrTmMmStrt = ofrTmStrt[1]
                    }
    
                    if(this.items.ofrTmEnd?.isEmptyVal()){
                        let ofrTmEnd = this.items.ofrTmEnd.split(":")
                        ofrTmHhEnd = ofrTmEnd[0]
                        ofrTmMmEnd = ofrTmEnd[1]
                    }
    
                    let recItems = {
                        fcltyNum: this.$store.getters.getFcltyNum,
                        bnMmbrPk : this.items.bnMmbrPk,
                        userNm : this.$store.getters.getUserNm,
                        bnftOfrRecHisPk:0,
                        phyThrPlnPk:0,
                        recDt:this.inqYMD,
                        wrtr:this.items.wrtr,
                        wrtrMmbrPk:this.items.wrtrMmbrPk,
                        cntnt:this.items.cntnt,                    
                        clsfc1Cd:'304',
                        clsfc2Cd:'1',
                        clsfc3Cd:this.items.thrClcd,
                        listDtl :[{
                            bnftOfrRecPk:0,
                            ofrTmHhStrt:ofrTmHhStrt,
                            ofrTmMmStrt:ofrTmMmStrt,
                            ofrTmHhEnd:ofrTmHhEnd,
                            ofrTmMmEnd:ofrTmMmEnd,
                            itm1ValAr:this.items.thrMthdcd,
                            wrtr:this.items.wrtr,
                            wrtrMmbrPk:this.items.wrtrMmbrPk,
                        }]
                    }
    
    
                    let obj = {list:[recItems]}

                    insPhythrBnftOfrRecMulti(obj)
                        .then((response) => ( this.onSaveAfter(response.data) ))
                        .catch((error) => console.log('upload error /bnftrcrd/insPhythrBnftOfrRecMulti : ' + error))
                }
            }
            
        },
        onSaveAfter:function(res){
            if(res.statusCode == 200){                                                                
                this.$emit("modalEvent",99)
                this.onClose()
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }            
        },
        onModal: function(key){
            this.menu.splice(key, 1, true)

            if(key == 1){
                this.exclsList.splice(0)
                
                let obj = {                    
                    name:this.items.bnfcrNm,
                    mmbrpk:this.items.bnMmbrPk,
                    disChekr:true,
                    checkr:'1'
                }
    
                this.exclsList.push(obj)
            }
        },
        isModal: function (key) {
            if(key == 2){
                this.thrClcd = ''
                this.thrMthdcd.splice(0)
                this.physcThrpyTypePk = -1
            }
            this.menu.splice(key, 1, false)
        },
        onModalType(){
            this.thrClcd = this.items.thrClcd

            this.thrMthdcd.splice(0)
            this.items.thrMthdcd.forEach(el => {
                this.thrMthdcd.push(el)
            });
            this.physcThrpyTypePk = 1
            this.menu.splice(2, 1, true)
        },
        onModalApply(selecter){
            this.items.thrMthdcd = selecter
            this.menu.splice(2, 1, false)
        },
        resetType:function(){
            this.items.thrMthdcd.splice(0)
        },
        getAplyList: function (arr){
            this.exclsList.splice(0)
            arr.forEach(e => {
                this.items.bnfcrNm = e.name
                this.items.bnMmbrPk = e.bnMmbrPk
            })     
        },
        getEmpInfo: function (obj) {
            this.items.wrtr = obj[0].name
            this.items.wrtrMmbrPk = obj[0].mmbrPk
        },
        onCodeload:function(){
            let fcltyNum = this.$store.getters.getFcltyNum
            getMultiStcd(['37','41', '42', '65', '92', '93'], fcltyNum)
                    .then((response) => ( this.codeMapping(response.data) ))
                    .catch((error) => console.log(error))
        },
        codeMapping: function (res){            
            this.vCd37 = res.vCd37
            this.vCd41 = res.vCd41
            this.vCd42 = res.vCd42
            this.vCd65 = res.vCd65
            this.vCd92 = res.vCd92
            this.vCd93 = res.vCd93
        },
        cdReload:function(){               
            let fcltyNum = this.$store.getters.getFcltyNum
            getMultiStcd(['92','93'], fcltyNum)
                    .then((response) => ( this.cdReloadAfter(response.data) ))
                    .catch((error) => console.log(error))
        },
        cdReloadAfter:function(res){
            this.vCd92 = res["vCd92"]
            this.vCd93 = res["vCd93"]
            this.items.thrMthdcd.splice(0)
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    
    data: () => ({     
        forceRender:0,
        items:{
            bnfcrNm:'',
            bnMmbrPk:0,
            wrtr:'',
            wrtrMmbrPk:0,
            thrClcd:'2',
            thrMthdcd:[],
        },
        dummyItems:{
            bnfcrNm:'',
            bnMmbrPk:0,
            wrtr:'',
            wrtrMmbrPk:0,
            thrClcd:'2',
            thrMthdcd:[],
        },
        vCd37:[],
        vCd41:[],
        vCd42:[],
        vCd65:[],    
        menu:[false,false,false],
        exclsList:[],
        physcThrpyTypePk:-1,
        thrClcd:'',
        thrMthdcd:[],
        filledBtn: [            
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type:'action', visible: true, },
        ],
        rules: {
            fcltyConfirm: value => value.length == 11 || '잘못된 기관코드 입니다.',
            numberCheck: value => /^[0-9]*$/.test(value) || '숫자만 입력 가능합니다.',
            required: value => !!value || '필수입력 항목 입니다.',
        },
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>