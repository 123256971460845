<template>
    <v-sheet class="d-flex rounded-lg rounded-l-0" color="" min-width="1260" max-width="1560" height="835">
        <v-sheet class="d-inline-block" :width="midAreaWidth" height="835" color="midAreaColor">
            <div class="text-h4 px-2 mt-1">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">
                    <span>4-4. 프로그램&nbsp;수급자&nbsp;그룹관리</span>
                </span>
            </div>
            <div class="d-flex mx-3 mt-5">
                <exBtn class="mr-1" :btnData="exBtn[0]" @btnClosed="expandClose" @changeCheck="filterSearch" />
                <exBtn class="mr-1" :btnData="exBtn[1]" @btnClosed="expandClose" @changeCheck="filterSearch" />
                <exBtn :btnData="exBtn[2]" @btnClosed="expandClose" @changeCheck="filterSearch" />
            
                <v-spacer></v-spacer>
                
                <span class="d-inline-block" style="width: 130px;">
                    <v-text-field class="ma-0 pa-0 nameSearch" height="35px" background-color="white" append-icon="mdi-magnify"
                        single-line rounded outlined dense filled hide-details label="이름조회" @input="setSearchText">
                    </v-text-field>
                </span>
            </div>
            <div class="mt-2 mx-3 rounded-lg">
                <v-data-table class="dft-hvr-tbl rounded" height="720" locale="ko"
                    :headers="headers" :items="bnfcrList" :search="schDummy" :custom-filter="customFilter"
                    fixed-header disable-pagination hide-default-footer dense>
                    <template v-slot:item='{ item }'>
                        <tr :class="[selectedItem == item.bnMmbrPk ? 'onClr' : '']" @click="tableClickEvent(item.bnMmbrPk)">
                            <td
                                class="fontOneRem txtAlgnMid allHdrFix hvrClr fixPstn0 zPstn2 py-2 pl-2 pr-6"
                                style="background-color: white;">
                                {{item.rn}}
                            </td>
                            <td
                                class="fontOneRem txtAlgnMid allHdrFix hvrClr fixPstn8 zPstn2 pl-2 pr-6"
                                style="background-color: white;">
                                <span class="overflow-text-hidden d-inline-block" style="width: 51px;">{{item.name}}</span>
                            </td>
                            <td class="px-3">
                                <span class="d-inline-block" style="width: 100%;">
                                    <span class="d-inline-block pt-1">
                                        <v-img class="ic-sex" v-if="item.gndr == 'F'" src="../../assets/icon/ic-female.svg"></v-img>
                                        <v-img class="ic-sex" v-else-if="item.gndr == 'M'" src="../../assets/icon/ic-male.svg"></v-img>
                                    </span>
                                </span>
                            </td>
                            <td class="fontOneRem txtAlgnMid pl-2 pr-6">
                                {{item.age}}
                            </td>
                            <td class="px-3">
                                <span :class="'ic-level level0'+item.acknwRtng">
                                    {{ item.acknwRtng == '99' ? '외' : item.acknwRtng }}
                                </span>
                            </td>
                            <td class="fontOneRem txtAlgnMid pl-2 pr-6">
                                <span class="overflow-text-hidden d-inline-block" style="width: 51px;">{{item.lvnRmNm}}</span>
                            </td>
                            <td class="fontOneRem txtAlgnMid pl-2 pr-6">
                                {{item.entStcd}}
                            </td>
                            <td class="fontOneRem txtAlgnMid pl-2 pr-5">
                                {{item.acknwPrdEnd}}
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </div>
        </v-sheet>
        <router-view ref="prgmChild"></router-view>
    </v-sheet>
</template>

<script>
import { getMultiStcd } from '../../api/index.js';
import { bnfcrList } from '../../api/bnfcr.js';
import exBtn from '../../components/commons/expandButtonCustom.vue';

export default {
    name: 'BnfcrAdv1',
    
    components: {
        exBtn,
    },

    props : {

    },

    created: function(){
        this.getCode(['62', '65'])
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.$store.getters.getBnfcrState.bnmmbr != 0){
                this.selectedItem = this.$store.getters.getBnfcrState.bnmmbr
            }
            this.getBnfcrList()
        })
    },

    computed: {
    
    },  

    watch: {
        'selectedItem': function () {
            if(this.selectedItem == -1){
                this.$store.commit('setBnfcrState', {
                    bnmmbr: 0, bnfcr: 0, name: '', entStcd: '',  entDt: '', lvDt: ''
                });
            }
            else{
                let chc = this.bnfcrList.findIndex( v => v.bnMmbrPk == this.selectedItem )
                if(chc > -1){
                    this.$store.commit('setBnfcrState', {
                        bnmmbr: this.selectedItem,
                        bnfcr: this.bnfcrList[chc].bnfcrPk,
                        name: this.bnfcrList[chc].name,
                        entStcd: this.bnfcrList[chc].entStcd,
                        entDt: this.bnfcrList[chc].entDt,
                        lvDt: ''
                    });
                }
            }
        },
    },

    methods: {
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        getBnfcrList: function (){
            bnfcrList(this.$store.getters.getFcltyNum)
                .then((response) => ( this.getBnfcrListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selBnfcrList : ' + error))
        },
        stCdMapping: function (res) {

            res.vCd62.forEach((code) => {
                this.exBtn[2].expandList.push(code.valcdnm)
            });//end foreach
            res.vCd65.forEach((code) => {
                this.exBtn[0].expandList.push(code.valcdnm)
            });//end foreach

            this.vCd62 = res.vCd62
            this.vCd65 = res.vCd65

        },
        getBnfcrListAfter: function (res){
            let me = this
            res.forEach(items => {
                items.acknwPrdEnd = items.acknwPrdEnd.beforeDateFormatDot()
              
                me.vCd65.forEach(code => {
                    if(items.entStcd == code.valcd) items.entStcd = code.valcdnm
                });
            });

            this.bnfcrList = res
        },
        tableClickEvent: function (index){
            if(this.selectedItem == -1)
                this.selectedItem = index
            else if(this.selectedItem != index)
                this.selectedItem = index
            else
                this.selectedItem = -1
        },
        setSearchText: function (value) {
            this.schField = value

            this.schDummy = this.schDummy + '.'
        },
        filterSearch: function (sch) {
            switch(this.schChkField.target) {
                case 'entStcd': this.schChkField.entStcd.splice(0)
                    sch.forEach((item) => { this.schChkField.entStcd.push(item) });
                    break;
                case 'lvnRmNm': this.schChkField.lvnRmNm.splice(0)
                    sch.forEach((item) => { this.schChkField.lvnRmNm.push(item) });
                    break;
                case 'acknwRtng': this.schChkField.acknwRtng.splice(0)
                    sch.forEach((item) => {
                        if(item == '등급외')
                            this.schChkField.acknwRtng.push('99')
                        else
                            this.schChkField.acknwRtng.push(item.replaceAll('등급', ''))
                    });
                    break;
            }
          
            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column
            let col0 = filter.name.includes(this.schField)
            
            let col1 = true
            if(this.schChkField.entStcd.length != 0){
                this.schChkField.entStcd.forEach((stcd, i) => {
                    if(i == 0) col1 = filter.entStcd.includes(stcd)
                    else col1 = col1 || filter.entStcd.includes(stcd)
                });
            }

            let col2 = true
            if(this.schChkField.lvnRmNm.length != 0){
                this.schChkField.lvnRmNm.forEach((stcd, i) => {
                    if(i == 0) col2 = filter.lvnRmNm == stcd
                    else col2 = col2 || filter.lvnRmNm == stcd
                });
            }

            let col3 = true
            if(this.schChkField.acknwRtng.length != 0){
                this.schChkField.acknwRtng.forEach((stcd, i) => {
                    if(i == 0) col3 = filter.acknwRtng.includes(stcd)
                    else col3 = col3 || filter.acknwRtng.includes(stcd)
                });
            }

            return col0 && col1 && col2 && col3;
        },
        expandClose: function (index) {
            switch(index) {
                case 0: this.schChkField.target = 'entStcd'
                    break;
                case 1: this.schChkField.target = 'lvnRmNm'
                    break;
                case 2: this.schChkField.target = 'acknwRtng'
                    break;
                default: this.schChkField.target = ''
                    break;
            }
        },
    },
    data: () => ({
        midAreaWidth: '550',
        selectedItem: -1,
        bnfcrList: [],
        vCd62: [],
        vCd65: [],
        schField: '',
        schDummy: '',
        schChkField: { entStcd: [], lvnRmNm: [], acknwRtng: [], target: '' },
        headers: [
            { text: '연번', value: 'rn', sortable: true, align:'center', width: '70', class: 'black--text bottomBorder allHdrFix fixPstn0 zPstn3 py-3 px-2'},
            { text: '수급자', value: 'name', sortable: true, align:'center', width: '80', class: 'black--text bottomBorder allHdrFix fixPstn8 zPstn3 px-2'},
            { text: '성별', value: 'gndr', sortable: true, align:'center', width: '60', class: 'black--text bottomBorder px-2'},
            { text: '나이', value: 'age', sortable: true, align:'center', width: '60', class: 'black--text bottomBorder px-2'},
            { text: '등급', value: 'acknwRtng', sortable: true, align:'center', width: '60', class: 'black--text bottomBorder px-2'},
            { text: '생활실', value: 'lvnRmNm', sortable: true, align:'center', width: '80', class: 'black--text bottomBorder px-2'},
            { text: '현황', value: 'entStcd', sortable: true, align:'center', width: '80', class: 'black--text bottomBorder px-2'},
            { text: '인정종료일', value: 'acknwPrdEnd', sortable: true, align:'center', width: '99', class: 'black--text bottomBorder px-2'},
        ],
        exBtn: [
            { index:  0, btnTitle: '입소현황', btnIcon: 'house', exWidth: 104, exHeigth: 130, expandList: [], },
            { index:  1, btnTitle: '생활실', btnIcon: 'bed', exWidth: 104, exHeigth: 100, expandList: ['바나나', '사과', '오렌지'], },
            { index:  2, btnTitle: '등급', btnIcon: 'wheelchair', exWidth: 104, exHeigth: 198, expandList: [], },
        ],
    }),
};
</script>