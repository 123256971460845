<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" min-height="700">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">물리&#40;작업&#41; 치료 계획</span>
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn v-if="list.type == 'action' && list.visible" @click = "onClickBtn(list.text)"
                    :class="list.class" height="30" :color="list.color" rounded
                    :disabled="btnDisabled"
                    style="font-size: 14px;">
                    <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                    {{list.text}}
                </v-btn>
            </span>
            <v-btn v-for="(list, j) in outlinedBtn" :key="filledBtn.length + j" @click = "onClickBtn(list.text)"
                :class="list.class" samll height="30" :color="list.color" rounded outlined
                :disabled="list.disabled">
                <v-icon small>{{list.icon}}</v-icon>
                {{list.text}}
            </v-btn>
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <basicInfo :bnMmbrPk="bnMmbrPk"></basicInfo>
        <v-form ref="form" lazy-validation>
            <v-row class="pa-0 my-2" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="d-flex pa-0 ma-0" cols="12">
                    <div class="greyE01 d-flex justify-center align-center pa-2 ma-0" style="width:8%;">
                        <span class="black--text font-weight-medium">조사사유</span>
                    </div>
                    <div class="pa-1 d-flex justify-center align-center" style="width:12%;">
                        <span class="d-inline-block" style="width: 130px;">
                            <v-radio-group
                                class="radio-dft-Theme mt-0"
                                v-model="items.mdfClcd" :rules="[rules.required]" hide-details row>
                                <v-radio class="pa-0 mr-1" :ripple="false" label="신규" value="1"></v-radio>
                                <v-radio class="pa-0 ma-0" :ripple="false" label="재사정" value="2"></v-radio>
                            </v-radio-group>
                        </span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-2 ma-0" style="width:8%;">
                        <span class="black--text font-weight-medium">적용기간</span>
                    </div>
                    <div class="pa-1 d-flex justify-center align-center" style="width:26%;">
                        <span class="white d-inline-block pa-1" style="width: 154px;">   
                            <CmmDateComp v-model="items.aplStrt" :required="true" :maxDt="items.aplEnd"></CmmDateComp>
                        </span>
                        <span class="white d-inline-block pa-1" style="width: 20px;">  
                            ~
                        </span>
                        <span class="white d-inline-block pa-1" style="width: 154px;">  
                            <CmmDateComp v-model="items.aplEnd" :required="true" :minDt="items.aplStrt"></CmmDateComp>                                                     
                        </span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-0 ma-0" style="width:8%;">
                        <span class="black--text font-weight-medium">작성일</span>
                    </div>
                    <div class="pa-1 ma-0 d-flex justify-start align-center" style="width:16%;">
                        <span class="white d-inline-block py-1 pl-2" style="width: 154px;"> 
                            <CmmDateComp v-model="items.wrtDt" :required="true" :minDt="inqYYYY+'-01-01'" :maxDt="inqYYYY+'-12-31'"></CmmDateComp>                           
                        </span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-0 ma-0" style="width:7%;">
                        <span class="black--text font-weight-medium">작성자</span>
                    </div>
                    <div class="pa-1 ma-0 d-flex justify-start align-center" style="width:15%;">
                        <span class="d-inline-block py-1 pl-2" style="width: 120px;">
                            <v-text-field
                                v-model="items.wrtr" :key="forceRender" :rules="[rules.required]"
                                color="blue" height="28" ref="info"
                                hide-details outlined dense>
                            </v-text-field>
                        </span>
                        <v-btn 
                            class="ml-1 brown lighten-3" 
                            small 
                            min-width="30"
                            height="25"  
                            dark
                            style="padding:0px 1px 0px 2px !important"
                            @click="onModal"
                            >
                            <v-icon size="20">mdi-account-edit</v-icon>
                        </v-btn>
                    </div>   
                </v-col>      
            </v-row>
        </v-form>
        <v-tabs
            v-model="tab" class="tab-dft-Theme" active-class="black"
            center-active hide-slider>
            <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class">{{item.tab}}</v-tab>
        </v-tabs>
        <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
        <v-tabs-items v-model="tab">
            <v-tab-item eager transition="fade-transition">
                <tab2 ref="tab2" 
                    :inqYYYY="inqYYYY"                  
                    @physcThrpyPlnTrigger="physcThrpyPlnReLoad" 
                    style="height: 450px;"></tab2>
            </v-tab-item>  
            <v-tab-item eager transition="fade-transition">
                <tab1 ref="tab1"   
                    :inqYYYY="inqYYYY"                  
                    @physcThrpyPlnTrigger="physcThrpyPlnReLoad" 
                    style="height: 450px;"></tab1>
            </v-tab-item>                      
        </v-tabs-items>

        <v-dialog v-model="dialogDelete" max-width="500">    
            <btnModalDelete :title="'물리(작업)치료 계획'" @madalState="onDelete"></btnModalDelete>  
        </v-dialog>   
        <v-dialog v-model="menu[1]" eager content-class="round" persistent max-width="800">
            <EmpSelector @isClose="isModal" @outEmp="getEmpInfo" :prtWrtDt = "prtWrtDt"></EmpSelector>
        </v-dialog> 
    </v-sheet>
</template>
<script>
import CmmDateComp from '../commons/CmmDateComp.vue';
import basicInfo from '../bnfcr/BnfcrBasicInfo.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import tab1 from './PhyscThrpyPln1.vue';
import tab2 from './PhyscThrpyPln2.vue';
import {getBnftOfrPlnHisInfo, insBnftOfrPhyThrPln, delBnftOfrPhyThrPln, 
        getBnfcrPhyThrExamInfList, getPhyThrPlnInfo,getAllThrWkList} from '../../api/bnftSrv.js';
import EmpSelector from '../commons/EmpSelector.vue';

export default {
    name: 'physcThrpyPln',

    props : {
        inqYYYY: Number,       
        bnMmbrPk : { type: Number, default: -1 },
        bnfcrPk : { type: Number, default: -1 },
        physcThrpyPlnPk : { type: Number, default: -1 }, 
        btnDisabled : { type: Boolean, default: false },  
    },
        
    components: {
        CmmDateComp,
        basicInfo,
        tab1,
        tab2,
        btnModalDelete,
        EmpSelector,
    },
    
    computed: {
        
    },  

    watch: {     
        'physcThrpyPlnPk':function(value){  
            this.filledBtn[0].visible = false    
            if(value > -1){
                this.bnftOfrPlnHisPk = value
                this.onShow()
                if(value == 0) this.filledBtn[0].visible = true
            }
            //출력버튼 반응
            // if(value > 0)
            //     this.outlinedBtn[0].disabled = false
            // else
            //     this.outlinedBtn[0].disabled = true
        },
        'items.wrtDt':function(){
            if(!this.noRstWrtr) {
                this.items.wrtr = ''
            }
            
            this.noRstWrtr = false
        }
    },

    mounted: function(){             
        this.$nextTick(function () {  
            this.bnftOfrPlnHisPk = this.physcThrpyPlnPk
            this.onShow()
            this.filledBtn[0].visible = false
            if(this.bnftOfrPlnHisPk == 0) this.filledBtn[0].visible = true
        })
    },
    
    methods: {
        onShow : function(){    
            this.getInit()
            this.getOfrPlnHisInfo()
            this.getBnfcrPhyThrExamInfList()     
            this.getPhyThrPlnInfo()
            this.getAllThrWkList()
        },
        onClose: function () {
            this.bnftOfrPlnHisPk=0
            this.frmReset();
            this.$emit('modalEvent', 6)
        },

        onClickBtn: function(text) {
            if(text == "이전자료"){
                this.getPreData = true
                this.getPhyThrPlnInfo()
            }else if(text=="저장"){
                this.insOfrPhyThrPln()
                // this.items.inqYYYY = this.inqYYYY                
                // this.items.bnftOfrPlnHisPk = this.bnftOfrPlnHisPk
                // this.items.bnMmbrPk = this.bnMmbrPk

                // if(this.tab == 0){
                //     this.$refs.tab1.insOfrPhyThrPln(this.items);
                // }else if(this.tab == 1){
                //     if(this.$refs.form.validate()){
                //         this.$refs.tab2.insOfrPhyThrPln(this.items);
                //     }
                // }                 
            } else if(text=="삭제"){
                if(this.bnftOfrPlnHisPk > 0){
                    this.dialogDelete = true
                }
            }  else if(text=="출력"){
                this.physcThrpyPlnPrt()
            }
        },

        getInit(){
            // let date = new Date()                                    
            // let dateStr = date.toISOString().split('T',2)[0]
            
            let timezoneOffset = new Date().getTimezoneOffset() * 60000
            let timezoneDate = new Date(Date.now() - timezoneOffset)
            let dateStr = timezoneDate.toISOString().split('T',2)[0]
            
            this.items.wrtDt = dateStr
            this.items.wrtr = this.$store.getters.getUserNm;
            this.minDate = this.inqYYYY+'-'+'01'+'-'+'01'
            this.maxDate = this.inqYYYY+'-'+'12'+'-'+'31'
            this.$refs.tab2.setMdfRsn('')

            this.forceRender += 1

            this.getPreData = false
        },

        getOfrPlnHisInfo(){
            if(this.bnftOfrPlnHisPk > 0){        
                let fcltyNum = this.$store.getters.getFcltyNum
                let state = {
                    bnMmbrPk : this.bnMmbrPk,
                    inqYYYY : this.inqYYYY,
                    bnftOfrPlnHisPk : this.bnftOfrPlnHisPk,
                }
                getBnftOfrPlnHisInfo(fcltyNum, state)
                .then((response) => ( this.getOfrPlnHisInfoAfter(response.data)))
                .catch((error) => console.log(error))
            }
        },
        getOfrPlnHisInfoAfter(data){
            this.noRstWrtr = true
            data.wrtDt = data.wrtDt?.beforeDateFormatHyp()
            data.aplStrt = data.aplStrt?.beforeDateFormatHyp()
            data.aplEnd = data.aplEnd?.beforeDateFormatHyp()
            this.items = data            
        },
        //물리치료 평가정보
        getBnfcrPhyThrExamInfList: function(){     

             let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum,
                bnMmbrPk    : this.bnMmbrPk,
                inqYYYY     : this.inqYYYY,
                bnfcrPk     : this.bnfcrPk,
            }

            getBnfcrPhyThrExamInfList(obj)
                .then((response) => (  this.$refs.tab1.setData(response.data) ))
                .catch((error) => console.log('connect error /bnftoffer/getBnfcrPhyThrExamInfList : ' + error))
        },

        //물리치료계획 조회
        getPhyThrPlnInfo: function(){  
            let state = {}

            if(this.getPreData){
                state = {
                    bnftOfrPlnHisPk : null,
                    bnMmbrPk : this.bnMmbrPk
                }
            }else{
                state = {
                    bnftOfrPlnHisPk : this.bnftOfrPlnHisPk,
                    bnMmbrPk : this.bnMmbrPk
                }
            }

            getPhyThrPlnInfo(this.$store.getters.getFcltyNum,state)
                .then((response) => ( this.$refs.tab2.getPhyThrPlnInfoAfter(response.data) ))
                .catch((error) => console.log('connect error /bnftoffer/getPhyThrPlnInfo : ' + error))
        },
        //전체치료주간현황
        getAllThrWkList:function(){
           
            let fcltyNum = this.$store.getters.getFcltyNum
            let state = {
                bnftOfrPlnHisPk : this.bnftOfrPlnHisPk,
                bnMmbrPk : this.bnMmbrPk
            }
            getAllThrWkList(fcltyNum,state)
            .then((response) => ( this.$refs.tab2.getAllThrWkListAfter(response.data) ))
                    .catch((error) => console.log('connect error /bnftoffer/getAllThrWkList : ' + error))
            
        },

        insOfrPhyThrPln(){
            if(this.$refs.tab2.selFormValidate()){
                if(this.$refs.form.validate()){
    
    
                    let ofrPlnHisInfo = {
                        wrtDt :  this.items.wrtDt?.afterDateFormatHyp(),
                        fcltyNum : this.$store.getters.getFcltyNum,
                        userNm:this.$store.getters.getUserNm,
                        bnftOfrPlnHisPk: this.bnftOfrPlnHisPk,
                        bnMmbrPk : this.bnMmbrPk,
                        plnYyyy: this.inqYYYY ,
                        wrtr : this.items.wrtr,
                        mdfClcd : this.items.mdfClcd,
                        aplStrt : this.items.aplStrt?.afterDateFormatHyp(), 
                        aplEnd : this.items.aplEnd?.afterDateFormatHyp(),
                        plnClcd:'202',
                        mdfRsn : this.$refs.tab2.selMdfRsn()
                    }
    
                    let phyThrPlnInfo = this.$refs.tab2.selPhyThrPlnInfo()                    
                    phyThrPlnInfo.bnMmbrPk = this.bnMmbrPk
                    phyThrPlnInfo.wrtDt = this.items.wrtDt?.afterDateFormatHyp()
                    phyThrPlnInfo.wrtr = this.items.wrtr

                    let phyThrPlnDtlList = this.$refs.tab2.selPhyThrPlnDtlList() 
    
                    insBnftOfrPhyThrPln(ofrPlnHisInfo,phyThrPlnInfo, phyThrPlnDtlList)
                        .then((response) => ( this.insOfrPhyThrPlnAfter(response.data)))
                        .catch((error) => console.log(error))
                }
            }else{
                this.snackControll([true, 5000, '치료세부계획 필수 항목을 체크해주세요.', 'error'])
            }

            
        },

        insOfrPhyThrPlnAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.bnftOfrPlnHisPk = res.result
                this.getOfrPlnHisInfo()
                this.getBnfcrPhyThrExamInfList()     
                this.getPhyThrPlnInfo()
                this.getAllThrWkList()
                this.$emit('modalEvent', 99)
                this.filledBtn[0].visible = false
            }else{
                // this.snackControll([true, 5000, res.message, 'error'])
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        onDelete:function(res){
            if(res == 9990){
                if(this.bnftOfrPlnHisPk > 0){
                    let code = this.$store.getters.getFcltyNum;
                    let state = {
                        bnMmbrPk : this.bnMmbrPk,
                        userNm : this.$store.getters.getUserNm,
                        bnftOfrPlnHisPk : this.bnftOfrPlnHisPk,
                    }
                    delBnftOfrPhyThrPln(code, state)
                    .then((response) => ( this.delBnftOfrPhyThrPlnAfter(response.data)))
                    .catch((error) => console.log(error))
                }
            }else if(res == 7){
                this.dialogDelete = false
            }

        },

        delBnftOfrPhyThrPlnAfter:function(res){
            if(res.statusCode == 200){                                                
                this.$emit('modalEvent', 99)
                this.$store.commit('setBnftOfrPlnHisPk', 0)
                this.onClose()
            }
            else{
                this.$store.commit('setBnftOfrPlnHisPk', 0)
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },

        physcThrpyPlnReLoad:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.bnftOfrPlnHisPk = res.result
                this.getOfrPlnHisInfo()
                this.getBnfcrPhyThrExamInfList()     
                this.getPhyThrPlnInfo()
                this.getAllThrWkList()
                this.$emit('modalEvent', 99)
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },

        frmReset:function(){ 
            this.tab = 0
            this.items.wrtDt = ''
            this.items.wrtr = ''
            this.items.mdfClcd = ''
            this.items.aplStrt = ''
            this.items.aplEnd = ''
            this.$refs.form.resetValidation()
        },

        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },

        snackControll: function (options) {
            this.snackOptions = options
        },

        getEmpInfo: function (obj) {
            this.items.wrtr = obj[0].name
        },
        onModal: function () {
            this.prtWrtDt = this.items.wrtDt
            this.menu.splice(1, 1, true)
        },
        isModal: function () {
            this.menu.splice(1, 1, false)
        },
        //물리치료계획 출력
        physcThrpyPlnPrt: function (){
            let obj = {}
            obj.rptTyp = '1'
            obj.mode = '2'
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnMmbrPk = this.$parent.$parent.$parent.$parent.bnMmbrPk
            obj.bnfcrPk = this.$parent.$parent.$parent.$parent.bnfcrPk
            obj.prtClcd = '1'
            obj.inqClcd = 2
            obj.prtCd = '45'
            obj.inqYYYY = this.inqYYYY
            obj.inqYmdStart = ''
            obj.inqYmdEnd = ''
            obj.list = [ { bzClcd: [], bzPk: [] } ]
            obj.list[0].bzPk.push(this.bnfcrExamHisPk)

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: obj, outObj: null, runEvt: true,
            });
        },
    },
    
    data: () => ({   
        getPreData:false,
        noRstWrtr:false,
        minDate:'',
        maxDate:'',
        prtWrtDt:'',
        tab: 0,           
        bnftOfrPlnHisPk : -1,
        items:{wrtDt:'',wrtr:'',mdfClcd:'',aplStrt:'',aplEnd:''},
        menu: [false,false,false,false],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },
        filledBtn: [
            { icon: 'mdi-text-box-search-outline', class: 'white--text', color:'#3C9099', text: '이전자료', type:'action', visible: false, },
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type:'action', visible: true,  },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type:'action', visible: true,  },
        ],
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006 ', text: '출력',visible: true, disabled: false, },
        ],
        snackOptions: [false, 3000, '', 'info'],
        forceRender:0,
        tabItems: [
            { index: 0, tab: '치료세부계획', class: 'fontOneDotTwoRem px-15',},
            { index: 1, tab: '수급자 평가 정보', class: 'fontOneDotTwoRem px-15',},
        ],
        dialogDelete:false,
    }),
};
</script>