<template>
    <v-sheet class="rounded-lg rounded-l-0" color="" min-width="1260" max-width="1560" height="835">
        <div class="px-2 mt-2 mr-2">
            <div class="text-h4 d-flex align-center">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">2-4. 주간식단표</span>
                <v-spacer></v-spacer>
                <span v-if="tab == 0" class="d-inline">
                    <v-btn v-for="(list, i) in filledBtn" :key="i"  @click = "onClickBtn(list.text)"
                        :class="list.class" height="30" :color="list.color" rounded
                        style="font-size: 14px;">
                        <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                        {{list.text}}
                    </v-btn>
                </span>
                <span>
                    <v-btn
                        class="font-weight-bold ml-1" height="30" color="grey006"
                        @click="prtWlyMenu()"
                        rounded outlined samll>
                        <v-icon small>mdi-printer-outline</v-icon>
                        <span class="fontOneRem ml-1">출력</span>
                    </v-btn>
                </span>
            </div>
        </div>
        
        <div class="pl-2 pr-2 mt-2">
            <v-tabs 
                v-model="tab" class="tab-dft-Theme" active-class="black"
                center-active hide-slider>
                <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class">{{item.tab}}</v-tab>
            </v-tabs>
            <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
            <v-tabs-items v-model="tab">                
                <v-tab-item transition="fade-transition">
                    <tab1 ref="tab1"   
                        :tab="tab"></tab1>
                </v-tab-item>
                <v-tab-item transition="fade-transition">
                    <tab2 v-if="tab == 1" ref="tab2" :tab="tab"></tab2>
                </v-tab-item>            
            </v-tabs-items>
        </div>
    </v-sheet>
</template>

<script>
import tab1 from '../../components/bnftSrv/WeklyMenuTab1.vue';
import tab2 from '../../components/bnftSrv/WeklyMenuTab2.vue';

export default {
    name: 'WeklyMenu',
        
    components: {
        tab1,
        tab2,
    },

    created: function(){
        
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
        })
    },

    computed: {

    },

    watch:{ 

    },
        
    methods: {
        prtWlyMenu: function (){
            this.prtThrowObj.inqYmdStart = this.$refs.tab1.startDay
            this.prtThrowObj.inqYmdEnd = this.$refs.tab1.endDay
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
            });
        },    
        onClickBtn: function(text) {
            if(text=="저장"){                
                if(this.tab == 0){
                    this.$refs.tab1.insMenu();
                }
            } else if(text=="삭제"){
                if(this.tab == 0){
                    this.$refs.tab1.delMenu()
                    // this.$refs.tab1.insOfrPhyThrPln(this.items);
                }   
            }
        },
    },
    
    data: () => ({
        tab:0,
        dialog: [false, false, false,],
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'white--text', color:'blueBtnColor', text: '저장', },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type: 'dialogue', visible: true, },
        ],
        tabItems: [
            { index: 0, tab: '주간식단표', class: 'fontOneDotTwoRem px-15',},
            { index: 1, tab: '식이정보', class: 'fontOneDotTwoRem px-15',},
        ],
        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '2', rptTyp: '1', mode: '2',
            inqYmdStart: '', inqYmdEnd: '', list: [ { bzClcd: [], bzPk: [] } ],
        },
    }),
};
</script>