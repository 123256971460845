<template>
    <v-sheet class="ml-0">
        <v-snackbar v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500"
            class="pa-0" :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
            <div style="text-align: center;">
                <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{ snackOptions[2] }}</span>
            </div>
        </v-snackbar>        
        <v-row class="mt-2 align-center" no-gutters>
            <v-col cols='4'>
                <exBtn class="mr-1" :btnData="exBtn[0]" @btnClosed="expandClose" @changeCheck="filterSearch" />
                <exBtn class="mr-1" :btnData="exBtn[1]" @btnClosed="expandClose" @changeCheck="filterSearch" />
                <span class="d-inline-block mt-1 mr-1" style="width: 130px;">
                    <v-text-field class="ma-0 pa-0 nameSearch" height="33px" background-color="white"
                        append-icon="mdi-magnify" single-line rounded outlined dense filled hide-details label="이름조회"
                        @input="setSearchText">
                    </v-text-field>
                </span>
            </v-col>
            <v-col cols='4'>
                <v-spacer></v-spacer>
                <span>
                    <expandNowDatePicker @nowDate="getDate"></expandNowDatePicker>
                </span>
                <v-spacer></v-spacer>
            </v-col>
            <v-col cols='4'>

            </v-col>
        </v-row>
        <div class="mt-2 rounded-lg">
            <v-data-table
                class="msclnTable" height="622" locale="ko"
                :headers="headers" 
                :items="empList" 
                :search="schDummy" 
                :custom-filter="customFilter"
                fixed-header disable-pagination hide-default-footer hide-default-header dense>
                <template v-slot:header="{ props }">
                        <thead>
                            <tr>
                                <th class="black--text clmsHeaderCommon tableBR tableBT">{{props.headers[0].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT">{{props.headers[1].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT">{{props.headers[2].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT">{{props.headers[3].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT">{{props.headers[4].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT">{{props.headers[5].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT">{{props.headers[6].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT">{{props.headers[7].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT">{{props.headers[8].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT">{{props.headers[9].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT">{{props.headers[10].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT">{{props.headers[11].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT">{{props.headers[12].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBT">{{props.headers[13].text}}</th>
                            </tr>
                        </thead>
                    </template>
                <template v-slot:item='{ item }'>
                    <tr>
                        <td class="tableContentCommon tableBR">{{item.rn}}</td>
                        <td class="tableContentCommon tableBR">{{item.wrkStCd}}</td>
                        <td class="tableContentCommon tableBR">{{item.name}}</td>
                        <td class="tableContentCommon tableBR">{{item.jobCd}}</td>
                        <td class="tableContentCommon tableBR">{{item.birth}}</td>
                        <td class="tableContentCommon tableBR">
                            <div>
                                <v-img class="d-inline-block" style="vertical-align: middle;" v-if="item.gndr == '여'" src="../../assets/icon/ic-female.svg" width="24"></v-img>
                                <v-img class="d-inline-block" style="vertical-align: middle;" v-else-if="item.gndr == '남'" src="../../assets/icon/ic-male.svg" width="24"></v-img>
                            </div>
                        </td>
                        <td class="tableContentCommon tableBR">{{item.entDt}}</td>
                        <td class="tableContentCommon tableBR">{{item.rtrDt}}</td>
                        <td class="tableContentCommon tableBR">{{item.wrkAnlDesc}}</td>
                        <td class="tableContentCommon tableBR">{{item.anlDys}}</td>
                        <td class="tableContentCommon tableBR">{{item.anlUseCnt}}</td>
                        <td class="tableContentCommon tableBR">{{item.ddcDys}}</td>
                        <td class="tableContentCommon tableBR">{{item.rmnAnl}}</td>                        
                        <td class="tableContentCommon">
                            <v-btn icon class=" rounded d-inline-block" @click="onClick(item)" >                                
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon size="20" v-bind="attrs" v-on="on">mdi-list-box-outline</v-icon>
                                    </template>
                                    <span>{{item.name}} 연차이력</span>
                                </v-tooltip>
                            </v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </div>
        <v-dialog v-model="menu[0]" max-width="900" content-class="round" persistent eager>
            <AnlHstr :sltEmpPk="sltEmpPk" :wrtMnt="useMnth" :mode="'rpt'" @modalClose="modalChanger" ></AnlHstr>
        </v-dialog>
    </v-sheet>
</template>
<script>
import expandNowDatePicker from '../../components/commons/expandNowDatePicker.vue';
import exBtn from '../../components/commons/expandButtonCustom.vue';
import AnlHstr from '../../components/emp/AnlHstrMdl.vue';

import { getMultiStcd } from '../../api/index.js';
import { selBtcInqEmpAnlList } from '../../api/bnfcrStts';
import { snglHdrXlsxPrdc } from '../../api/global/cmmExcelJS.js';
export default {
    name: 'EmpInfSttsTab2',

    props: {
        tab: Number,
    },
    components: {
        exBtn,
        expandNowDatePicker,
        AnlHstr,
    },

    computed: {
    },

    watch: {
    },

    created: function () {
        this.getCode(['42', '70'])
    },

    mounted: function () {
        this.$nextTick(function () {
        })
    },

    methods: {
        xlsxProd: function (){
            let shetNm = '연차사용내역'
            let title = shetNm + '_' + this.$store.getters.getFcltyNum + '_' + this.$moment().format('YYYYMMDD') 

            // 엑셀 생성 및 다운로드 ( 컬럼리스트, 데이터, 시트명, 파일명 )
            snglHdrXlsxPrdc(this.exlHeader, this.empList, shetNm, title)
            this.exlBtn = true
        },
        selBtcInqEmpAnlList: function () {

            let obj = {
                inqYMD: this.date,
                fcltyNum: this.$store.getters.getFcltyNum
            }

            selBtcInqEmpAnlList(obj)
                .then((response) => ( this.selBtcInqEmpAnlListAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/selBtcInqEmpAnlList : ' + error))
        },
        selBtcInqEmpAnlListAfter: function (res) {
            this.empList.splice(0)
            if(res != null){
                res.forEach(items => {
                    this.vCd42.forEach(item => {
                        if(items.jobCd == item.valcd)
                            items.jobCd = item.valcdnm
                    });
                    this.vCd70.forEach(item => {
                        if(items.wrkStCd == item.valcd)
                            items.wrkStCd = item.valcdnm
                    });

                    items.birth = items.birth?.beforeDateFormatDot()
                    items.entDt = items.entDt?.beforeDateFormatDot()
                    items.rtrDt = items.rtrDt?.beforeDateFormatDot()

                    this.empList.push(items)
                });
            }
        },
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => (this.stCdMapping(response.data)))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        stCdMapping: function (res) {
            this.vCd42 = res.vCd42
            this.vCd70 = res.vCd70

            this.vCd42.forEach(code => {
                this.exBtn[1].expandList.push(code.valcdnm)
            })
            this.vCd70.forEach(code => {
                this.exBtn[0].expandList.push(code.valcdnm)
            })
        },
        setSearchText: function (value) {
            this.schField = value

            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column
            let col0 = filter.name.includes(this.schField)

            let col1 = true
            if (this.schChkField.wrkStCd.length != 0) {
                this.schChkField.wrkStCd.forEach((stcd, i) => {
                    if (i == 0) col1 = filter.wrkStCd.includes(stcd)
                    else col1 = col1 || filter.wrkStCd.includes(stcd)
                });
            }

            let col2 = true
            if (this.schChkField.jobCd.length != 0) {
                this.schChkField.jobCd.forEach((stcd, i) => {
                    if (i == 0) col2 = filter.jobCd.includes(stcd)
                    else col2 = col2 || filter.jobCd.includes(stcd)
                });
            }

            return col0 && col1 && col2;
        },
        filterSearch: function (sch) {
            switch (this.schChkField.target) {
                case 'wrkStCd': this.schChkField.wrkStCd.splice(0)
                    sch.forEach((item) => { this.schChkField.wrkStCd.push(item) });
                    break;
                case 'jobCd': this.schChkField.jobCd.splice(0)
                    sch.forEach((item) => { this.schChkField.jobCd.push(item) });
                    break;
            }

            this.schDummy = this.schDummy + '.'
        },
        expandClose: function (index) {
            switch (index) {
                case 0: this.schChkField.target = 'wrkStCd'
                    break;
                case 1: this.schChkField.target = 'jobCd'
                    break;
                default: this.schChkField.target = ''
                    break;
            }
        },
        getDate: function (obj) {
            this.date = obj.date            
            this.selBtcInqEmpAnlList()
        },
        getPickerDate: function (obj) {
            this.mDate = obj
        },
        getYear: function (obj) {
            this.yDate = obj
        },        
        modalChanger: function() {
            this.selBtcInqEmpAnlList()
            this.menu.splice(0, 1, false)
            this.sltEmpPk   = -1
            this.useMnth    = 0
        },
        onClick: function(item) {
            this.useMnth = parseInt( Number(item.wrkMnthCalc))
            this.sltEmpPk = item.empPk            
            this.menu.splice(0, 1, true)
        },
    },
    data: () => ({
        
        exBtn: [
            { index: 0, btnTitle: '상태', btnIcon: 'house', exWidth: 84, exHeigth: 100, expandList: [], },
            { index: 1, btnTitle: '직종', btnIcon: 'business', exWidth: 134, exHeigth: 220, expandList: [], },
        ],
        exlHeader: [
        { header: '연번', key: 'rn', width: 8, },
        { header: '근무현황', key: 'wrkStCd', width: 12, },
        { header: '직원명', key: 'name', width: 12, },
        { header: '직종', key: 'jobCd', width: 12, },
        { header: '생년월일', key: 'birth', width: 16, },
        { header: '성별', key: 'gndr', width: 10, },
        { header: '입사일', key: 'entDt', width: 12, },
        { header: '퇴사일', key: 'rtrDt', width: 12, },
        { header: '근무년수', key: 'wrkAnlDesc', width: 16, },
        { header: '발생일수', key: 'anlDys', width: 16, },
        { header: '사용일수', key: 'anlUseCnt', width: 16, },
        { header: '차감일수', key: 'ddcDys', width: 16, },
        { header: '잔여일수', key: 'rmnAnl', width: 16, },
        ],
        headers: [
            { text: '연번', value: 'rn', sortable: true, align: 'center', class: 'black--text clmsHeaderCommon tableBR tableBT'},
            { text: '근무현황', value: 'wrkStcd', sortable: true, align: 'center', class: 'black--text clmsHeaderCommon tableBR tableBT'},
            { text: '직원명', value: 'name', sortable: true, align: 'center', class: 'black--text clmsHeaderCommon tableBR tableBT'},
            { text: '직종', value: 'jobCd', sortable: true, align: 'center', class: 'black--text clmsHeaderCommon tableBR tableBT'},
            { text: '생년월일', value: 'birth', sortable: true, align: 'center', class: 'black--text clmsHeaderCommon tableBR tableBT'},
            { text: '성별', value: 'gbdr', sortable: true, align: 'center', class: 'black--text clmsHeaderCommon tableBR tableBT'},
            { text: '입사일', value: 'entDt', sortable: true, align: 'center', class: 'black--text clmsHeaderCommon tableBR tableBT'},
            { text: '퇴사일', value: 'rtrDt', sortable: true, align: 'center', class: 'black--text clmsHeaderCommon tableBR tableBT'},
            { text: '근무년수', value: 'wrkAnlDesc', sortable: true,  align: 'center', class: 'black--text clmsHeaderCommon tableBR tableBT'},
            { text: '발생일수', value: 'anlDys', sortable: true,  align: 'center', class: 'black--text clmsHeaderCommon tableBR tableBT'},
            { text: '사용일수', value: 'anlUseCnt', sortable: true, align: 'center', class: 'black--text clmsHeaderCommon tableBR tableBT'},
            { text: '차감일수', value: 'ddcDys', sortable: true,  align: 'center', class: 'black--text clmsHeaderCommon tableBR tableBT'},
            { text: '잔여일수', value: 'rmnAnl', sortable: true,  align: 'center', class: 'black--text clmsHeaderCommon tableBT'},
            { text: '상세', value: '', sortable: true,  align: 'center', class: 'black--text clmsHeaderCommon tableBT'},
        ],
        srchCd:0,
        // selectItems: [{valcdnm: '일별',valcd: 0}, {valcdnm: '월별',valcd: 1},{valcdnm: '연별',valcd: 2}, ],
        date: 19900101,
        empList: [],
        vCd42: [],  //직원 직종 코드
        vCd70: [],  //직원 상태 코드
        snackOptions: [false, 3000, '', 'info'],
        schDummy: '',
        schField: '',
        schChkField: { wrkStCd: [], jobCd: [], target: '' },
        menu: [false,],
        useMnth: 0,
        sltEmpPk:-1,
    }),
};
</script>