<template>
    <v-sheet>
        <v-row no-gutters>
            <v-col cols="6" class="ma-0 py-0 pr-3">
                <v-row  class="tableBT tableBB" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="5">                         
                        <span class="black--text font-weight-medium">{{$moment(this.$parent.$parent.$parent.$parent.yymm,'YYYYMM').format('YYYY.MM')}} 손익</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center pa-1 ma-0 tableBL"  cols="7">                        
                        {{prfLosTot?.toLocaleString('ko-KR')}}
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="6" class="ma-0 pa-0"></v-col>
        </v-row>

        <v-row class="mt-2" no-gutters>
            <v-col cols="6" class="ma-0 py-0 pr-3">
                <v-row  class="tableBT tableBB" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="5">                         
                        <span class="black--text font-weight-medium">수입 합계</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center pa-1 ma-0 tableBL"  cols="7">                        
                        {{revTotL?.toLocaleString('ko-KR')}}      
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="6" class="ma-0 pa-0">
                <v-row  class="tableBT tableBB" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="5">                         
                        <span class="black--text font-weight-medium">지출 합계</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center pa-1 ma-0 tableBL"  cols="7">                        
                        {{expTot?.toLocaleString('ko-KR')}}
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row class="mt-2" no-gutters>
            <v-col cols="6" class="ma-0 py-0 pr-3">
                <v-row  :class="['tableBT tableBB', revList.length >  14 ? 'mr-2__5' : '']" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="6">                         
                        <span class="black--text font-weight-medium">항목</span>
                        <span>
                            <v-btn 
                                color="blue200" 
                                height="26" 
                                @click="onLosItm('1')"
                                icon>
                                <v-icon>mdi-playlist-plus</v-icon>
                            </v-btn>
                        </span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0"  style="border-left: 1px solid #dbdbdb !important;" cols="6">                          
                        <span class="black--text font-weight-medium">금액</span>
                    </v-col>
                </v-row>
                <div v-if="revList.length>0" style="height:550px;overflow-y:auto">
                    <v-row v-for="(item, j) in revList" :key="j" class="tableBB" no-gutters>
                        <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="6"> 
                            <div style="width:85%">{{item.itmNm}}</div>
                            <div style="width:15%">                                     
                                <v-btn v-if="item.dtlYn == '1'"  @click="onLosDtl(item)"
                                    class="" color="blue200" style="padding:0px 1px 0px 2px !important"
                                    min-width="40" height="28"
                                    icon>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="25" v-bind="attrs" v-on="on">mdi-square-edit-outline</v-icon>
                                        </template>
                                        <span>{{item.itmNm}} 손익항목상세관리</span>
                                    </v-tooltip>
                                </v-btn>
                            </div>
                        </v-col>
                        <v-col class="d-flex justify-center align-center pa-1 ma-0 tableBL" cols="6"> 
                            <v-row no-gutters>
                                <v-col cols="6">
                                    <span class="txtfldRT" v-if="item.autoCalcYn != '1'">
                                        <v-text-field color="blue" v-model="item.amt" type="number" height="28" @input="getPrlLosclclt" hide-details outlined dense :readonly="item.dtlYn == '1'"></v-text-field>
                                    </span>
                                    <div v-else style="width:100px;height:33px;"></div>
                                </v-col>
                                <v-col cols="6" class="pl-1 d-flex justify-end align-center">
                                    {{item.amt?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}}
                                </v-col>
                            </v-row>   
                        </v-col>
                    </v-row>
                </div>
                <div v-else>
                    <v-row  class="tableBB" no-gutters>
                        <v-col cols="12" class="d-flex justify-center align-center pa-1 ma-0">                                
                            <span class="black--text font-weight-medium">※ 목록이 없습니다.</span>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
            <v-col cols="6" class="ma-0 pa-0">
                <v-row  :class="['tableBT tableBB', expList.length >  14 ? 'mr-2__5' : '']" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="6">                         
                        <span class="black--text font-weight-medium">항목</span>
                        <span>
                            <v-btn 
                                color="blue200" 
                                height="26" 
                                @click="onLosItm('2')"
                                icon>
                                <v-icon>mdi-playlist-plus</v-icon>
                            </v-btn>
                        </span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0"  style="border-left: 1px solid #dbdbdb !important;" cols="6">                          
                        <span class="black--text font-weight-medium">금액</span>
                    </v-col>
                </v-row>
                <div v-if="expList.length>0" style="height:550px;overflow-y:auto">
                    <v-row v-for="(item, k) in expList" :key="k" class="tableBB" no-gutters>
                        <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="6"> 
                            <div style="width:85%">{{item.itmNm}}</div>
                            <div style="width:15%">                                     
                                <v-btn v-if="item.dtlYn == '1'"  @click="onLosDtl(item)"
                                    class="" color="blue200" style="padding:0px 1px 0px 2px !important"
                                    min-width="40" height="28"
                                    icon>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="25" v-bind="attrs" v-on="on">mdi-square-edit-outline</v-icon>
                                        </template>
                                        <span>{{item.itmNm}} 손익항목상세관리</span>
                                    </v-tooltip>
                                </v-btn>
                            </div>
                        </v-col>
                        <v-col class="d-flex justify-center align-center pa-1 ma-0 tableBL" cols="6"> 
                            <v-row no-gutters>
                                <v-col cols="6">
                                    <span class="txtfldRT" v-if="item.autoCalcYn != '1'">
                                        <v-text-field color="blue" v-model="item.amt" type="number" height="28" @input="getPrlLosclclt" hide-details outlined dense :readonly="item.dtlYn == '1'"></v-text-field>
                                    </span>
                                    <div v-else style="width:100px;height:33px;"></div>
                                </v-col>
                                <v-col cols="6" class="pl-1 d-flex justify-end align-center">
                                    {{item.amt?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}}
                                </v-col>
                            </v-row>   
                        </v-col>
                    </v-row>
                </div>
                <div v-else>
                    <v-row  class="tableBB" no-gutters>
                        <v-col cols="12" class="d-flex justify-center align-center pa-1 ma-0">                                
                            <span class="black--text font-weight-medium">※ 목록이 없습니다.</span>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
        
    </v-sheet>
</template>
<script>
import { selFcltyPrfLosList, insMultiFcltyPrfLos } from '../../api/impExp.js';

export default {
    name: 'MnthlPrlssTab2',
    
    components: {  
        
    },

    props : {
    },

    computed: {
        getStYymm:function(){
            return this.$parent.$parent.$parent.$parent.yymm
        }
    },

    created: function(){   
    },

    mounted: function(){
        this.$nextTick(function () {
            if(this.$parent.$parent.$parent.$parent.yymm != '') this.onLoad()
        })
    },

    watch: {
        'getStYymm':function () {
            this.onLoad()
        }
    },

    methods: {  
        onLoad:function(){
            this.getFcltyPrfLosList()
        },
        
        onSave:function(){
            let obj = {
                list:[],
            }

            this.revList.forEach(item => {
                if(item.autoCalcYn != '1') {
                    item.fcltyNum = this.$store.getters.getFcltyNum
                    item.userNm = this.$store.getters.getUserNm
                    item.stdYymm = this.$parent.$parent.$parent.$parent.yymm
                    item.stdClcd = this.stdClcd
                    obj.list.push(item)
                }
            });

            this.expList.forEach(item => {
                if(item.autoCalcYn != '1') {
                    item.fcltyNum = this.$store.getters.getFcltyNum
                    item.userNm = this.$store.getters.getUserNm
                    item.stdYymm = this.$parent.$parent.$parent.$parent.yymm
                    item.stdClcd = this.stdClcd
                    obj.list.push(item)
                }
            });
            
            insMultiFcltyPrfLos(obj)
                .then((response) => ( this.onSaveAfter(response.data) ))
                .catch((error) => console.log('connect error /impExp/insMultiFcltyPrfLos : ' + error))
        },
        onSaveAfter:function(res){
            this.$emit('trigger', res)

            if(res.statusCode == 200){                                                                
                this.getFcltyPrfLosList()
            }
        },
        modalEvent: function (event) { 
            if(event == 99){
                this.getFcltyPrfLosList()
            }else{
                this.modal.splice(event, 1, false)
            }      
        },
        getFcltyPrfLosList: function () {
            if(!this.dataCall){
                this.dataCall = true
                selFcltyPrfLosList(this.$store.getters.getFcltyNum, this.$parent.$parent.$parent.$parent.yymm, this.stdClcd)
                    .then((response) => ( this.getFcltyPrfLosListAfter(response.data) ))
                    .catch((error) => console.log('connect error /impExp/selFcltyPrfLosList : ' + error))   
            }
        },
        getFcltyPrfLosListAfter: function (res) {
            this.dataCall = false
            this.$parent.$parent.$parent.$parent.tab2Act = '1'
            this.revList.splice(0)
            this.expList.splice(0)

            res.forEach(item => {
                if(item.amt == '' || item.amt == null) item.amt = 0 

                switch (item.calcClcd) {
                    case '1':
                        this.revList.push(item)
                        break;
                    case '2':
                        this.expList.push(item)
                        break;
                    default:
                        break;
                }
            });

            this.getPrlLosclclt()
        },
        getPrlLosclclt:function(){
            this.prfLosTot = 0
            this.revTotL = 0
            this.expTot = 0

            this.revList.forEach(item => {
                this.revTotL += Number(item.amt)
            });

            this.expList.forEach(item => {
                this.expTot += Number(item.amt)
            });

            this.prfLosTot = Number(this.revTotL)-Number(this.expTot)
        },

        onLosItm:function(clcd){
            this.$parent.$parent.$parent.$parent.onModal(0)
            this.$parent.$parent.$parent.$parent.clcd = clcd            
        },

        onLosDtl:function(target){
            this.$parent.$parent.$parent.$parent.onModal(1)
            this.$parent.$parent.$parent.$parent.clcd       = target.calcClcd
            this.$parent.$parent.$parent.$parent.stdClcd    = '2'
            this.$parent.$parent.$parent.$parent.itmCd      = target.itmCd
            this.$parent.$parent.$parent.$parent.itmNm      = target.itmNm
        },
        setItmAmt:function(obj){
            if(obj.calcClcd == '1'){
                let idx = this.revList.findIndex(v=>v.itmCd == obj.itmCd)
                this.revList[idx].amt = obj.amt
            }else if(obj.calcClcd == '2'){
                let idx = this.expList.findIndex(v=>v.itmCd == obj.itmCd)
                this.expList[idx].amt = obj.amt
            }
            this.getPrlLosclclt()
        }
    },

    data: () => ({ 
        dataCall    : false,
        clcd:'',    
        stdClcd:'2',
        modal:[false,],
        prfLosTot:0,
        revTotL:0,
        revList:[],
        expTot:0,
        expList:[],
    }),
};
</script>