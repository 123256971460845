import { http } from '@/api/baseAxios';

//기관 홈페이지 정보 조회
function selFcltyHomInf(code) {
    return http.post('hominf/selFcltyHomInf', { fcltyNum: code })
}

//기관 홈페이지 파일 조회
function selFcltyHomFileList(code) {
    return http.post('hominf/selFcltyHomFileList', { fcltyNum: code })
}

//기관 홈페이지 인사말 저장
function insFcltyHomInf(obj){
    return http.post('hominf/insFcltyHomInf', obj)
}

// 홈페이지 이미지 temp upload
function tmpHomImgUpload(value) {
    return http.post('hominf/tmpHomImgUpload', value, { headers: { 'Content-Type': 'multipart/form-data' }, })
}

//홍페이지 파일 저장
function insFcltyHomFile(obj){
    return http.post('hominf/insFcltyHomFile', obj)
}

//홍페이지 파일 삭제
function delFcltyHomFile(obj){
    return http.post('hominf/delFcltyHomFile', obj)
}

//첨부파일 다운로드
function homImgDownload(data) {
    return http({
        method: 'post',
        url: 'hominf/homImgDownload',
        responseType: 'arraybuffer',
        data: data
    })
}

//홈페이지 관리 게시글 조회(목록)
function selHomBbsList(obj){
    return http.post('hominf/selHomBbsList', obj)
}

// 홈페이지 게시글 첨부파일 temp upload
function tmpHomBbsUpload(value) {
    return http.post('hominf/tmpHomBbsUpload', value, { headers: { 'Content-Type': 'multipart/form-data' }, })
}

//첨부파일 다운로드
function homFileDownload(data) {
    return http({
        method: 'post',
        url: 'hominf/homFileDownload',
        responseType: 'arraybuffer',
        data: data
    })
}

//게시글 조회
function selHomBbsInfo(obj){
    return http.post('hominf/selHomBbsInfo', obj)
}

//게시글 저장
function insHomBbs(obj){
    return http.post('hominf/insHomBbs', obj)
}

//시설둘러보기저장
function insHomPhtBbs(obj){
    return http.post('hominf/insHomPhtBbs', obj)
}

//게시글 삭제
function delHomBbs(obj){
    return http.post('hominf/delHomBbs', obj)
}

//첨부파일 목록 조회
function selHomBbsFileList(obj){
    return http.post('hominf/selHomBbsFileList', obj)
}

//게시글 첨부파일 
function delHomBbsFile(obj){
    return http.post('hominf/delHomBbsFile', obj)
}


export {
    selFcltyHomInf,
    selFcltyHomFileList,
    insFcltyHomInf,
    tmpHomImgUpload,
    insFcltyHomFile,
    delFcltyHomFile,
    homImgDownload,
    selHomBbsList,
    tmpHomBbsUpload,
    homFileDownload,
    selHomBbsInfo,
    insHomBbs,
    insHomPhtBbs,
    delHomBbs,
    selHomBbsFileList,
    delHomBbsFile,
}