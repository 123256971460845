<template>
    <v-sheet class="rounded-lg rounded-l-0 pa-3" color="" min-width="1260" max-width="1560" height="835">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="">
            <div class="text-h4 d-flex align-center">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">1-9. 본인부담금&nbsp;의료비공제</span>
                <v-spacer></v-spacer>
                <span class="">
                    <v-btn
                        class="font-weight-bold ml-2" height="30" color="grey006"
                        :disabled="savBtn" @click="getXlsx"
                        rounded outlined samll>
                        <v-icon small>mdi-microsoft-excel</v-icon>
                        <span class="fontOneRem ml-1">의료비공제 증빙자료 엑셀 생성</span>
                    </v-btn>
                </span>
            </div>
        </div>
        <v-row class="d-flex justify-center my-5 ml-3 mr-2" no-gutters>
            <expandYearOnlyPicker @nowYear="getYear"></expandYearOnlyPicker>
        </v-row>
        <v-divider class="mx-2"></v-divider>
        <v-row class="pa-0 mx-2" no-gutters>
            <v-col class="greyE01" style="line-height: 1.3;" cols="1">
                <div class="txtAlgnMid black--text font-weight-medium pt-2">사업자등록번호</div>
                <div class="txtAlgnMid black--text font-weight-medium pb-2">&#40;고유번호&#41;</div>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBL tableBR" cols="2">
                <span class="d-inline-block pa-2" style="width: 100%;">
                    <v-text-field v-model="mdcexDdctnInfo.bsnsRgst" :rules="[rules.numberCheck]" height="28" hide-details outlined dense></v-text-field>
                </span>
            </v-col>
            <v-col class="greyE01 d-flex justify-center align-center" cols="1">
                <span class="black--text font-weight-medium py-1">발급기관명칭</span>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBL tableBR" cols="5">
                <span class="d-inline-block px-2 py-1" style="width: 100%;">
                    <v-text-field
                        v-model="mdcexDdctnInfo.fcltyClnm"
                        height="28" hide-details outlined dense>
                    </v-text-field>
                </span>
            </v-col>
            <v-col class="greyE01 d-flex justify-center align-center" cols="1">
                <span class="black--text font-weight-medium py-1">대표자&#40;성명&#41;</span>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBL" cols="2">
                <span class="d-inline-block px-2 py-1" style="width: 100%;">
                    <v-text-field
                        v-model="mdcexDdctnInfo.ceo"
                        height="28" hide-details outlined dense>
                    </v-text-field>
                </span>
            </v-col>
        </v-row>
        <v-divider class="mx-2"></v-divider>
        <v-row class="pa-0 ma-0 mx-2" no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center" cols="1">
                <span class="black--text font-weight-medium py-2">전화번호</span>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBL tableBR" cols="2">
                <span class="d-inline-block pb-1">
                    <span class="d-inline-block" style="width: 50px;">
                        <v-text-field v-model="mdcexDdctnInfo.fcltyTel1" :rules="[rules.numberThreeCheck, rules.numberCheck]" height="28" hide-details outlined dense></v-text-field>
                    </span>
                    <span class="d-inline-block text-h5 font-weight-bold pa-0 pl-1" style="width: 15px;">
                    &#45;
                    </span>
                    <span class="d-inline-block" style="width: 55px;">
                        <v-text-field v-model="mdcexDdctnInfo.fcltyTel2" :rules="[rules.numberFourCheck, rules.numberCheck]" height="28" hide-details outlined dense></v-text-field>
                    </span>
                    <span class="d-inline-block text-h5 font-weight-bold pa-0 pl-1" style="width: 15px;">
                    &#45;
                    </span>
                    <span class="d-inline-block" style="width: 55px;">
                        <v-text-field v-model="mdcexDdctnInfo.fcltyTel3" :rules="[rules.numberFourCheck, rules.numberCheck]" height="28" hide-details outlined dense></v-text-field>
                    </span>
                </span>
            </v-col>
            <v-col class="greyE01 d-flex justify-center align-center tableBL tableBR" cols="1">
                <span class="black--text font-weight-medium py-1">담당부서</span>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBL tableBR" cols="2">
                <span class="d-inline-block px-2 py-1" style="width: 100%;">
                    <v-text-field
                        v-model="mdcexDdctnInfo.mngrDept"
                        height="28" hide-details outlined dense>
                    </v-text-field>
                </span>
            </v-col>
            <v-col class="greyE01 d-flex justify-center align-center" cols="1">
                <span class="black--text font-weight-medium py-1">담당자&nbsp;성명</span>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBL tableBR" cols="2">
                <span class="d-inline-block px-2 py-1" style="width: 100%;">
                    <v-text-field
                        v-model="mdcexDdctnInfo.mngr"
                        height="28" hide-details outlined dense>
                    </v-text-field>
                </span>
            </v-col>
            <v-col class="greyE01 d-flex justify-center align-center" cols="1">
                <span class="black--text font-weight-medium py-1">대표자&nbsp;연락처</span>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBL" cols="2">
                <span class="d-inline-block">
                    <span class="d-inline-block" style="width: 50px;">
                        <v-text-field v-model="mdcexDdctnInfo.ceoTel1" :rules="[rules.numberThreeCheck, rules.numberCheck]" height="28" hide-details outlined dense></v-text-field>
                    </span>
                    <span class="d-inline-block text-h5 font-weight-bold pa-0 pl-1" style="width: 15px;">
                    &#45;
                    </span>
                    <span class="d-inline-block" style="width: 55px;">
                        <v-text-field v-model="mdcexDdctnInfo.ceoTel2" :rules="[rules.numberFourCheck, rules.numberCheck]" height="28" hide-details outlined dense></v-text-field>
                    </span>
                    <span class="d-inline-block text-h5 font-weight-bold pa-0 pl-1" style="width: 15px;">
                    &#45;
                    </span>
                    <span class="d-inline-block" style="width: 55px;">
                        <v-text-field v-model="mdcexDdctnInfo.ceoTel3" :rules="[rules.numberFourCheck, rules.numberCheck]" height="28" hide-details outlined dense></v-text-field>
                    </span>
                </span>
            </v-col>
        </v-row>
        <v-divider class="mx-2"></v-divider>
        <v-row class="ma-0 pa-0 d-flex align-center mt-5 mx-2" no-gutters>
            <v-icon style="font-size: 28px; width: 12px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold ml-2">본인부담금&nbsp;입금내역</span>
        </v-row>
        <div class="mt-2 mr-2__5">
            <v-divider class="ml-2"></v-divider>
            <v-row class="greyE01 pa-0 ml-2" no-gutters>
                <v-col class="txtAlgnMid py-1 tableBR" cols="1">
                    <span class="black--text font-weight-medium">연번</span>
                </v-col>
                <v-col class="txtAlgnMid py-1 tableBR" cols="3">
                    <span class="black--text font-weight-medium">성명</span>
                </v-col>
                <v-col class="txtAlgnMid py-1 tableBR" cols="2">
                    <span class="black--text font-weight-medium">주민번호</span>
                </v-col>
                <v-col class="txtAlgnMid py-1 tableBR" cols="2">
                    <span class="black--text font-weight-medium">수납일</span>
                </v-col>
                <v-col class="txtAlgnMid py-1 tableBR" cols="2">
                    <span class="black--text font-weight-medium">수납금액&#40;원&#41;</span>
                </v-col>
                <v-col class="txtAlgnMid py-1" cols="2">
                    <span class="black--text font-weight-medium">청구연월&#40;참고&#41;</span>
                </v-col>
            </v-row>
            <v-divider class="ml-2"></v-divider>
        </div>
        <div v-if="bnNameCol.length > 0" style="height: 510px; overflow-y: scroll">
            <v-row class="pa-0 ml-2" v-for="(list, i) in bnNameCol" :key="i" no-gutters>
                <v-col cols="6">
                    <v-row class="tableBB" style="height: 100%;" no-gutters>
                        <v-col class="d-flex justify-center align-center tableBR" style="height:100%;" cols="2">{{ i+1 }}</v-col>
                        <v-col class="d-flex justify-center align-center tableBR" style="height:100%;" cols="6">
                            {{list.name}}&nbsp;&#40;{{list.birth?.beforeDateFormatDot()}}&#41;
                        </v-col>
                        <v-col class="d-flex justify-center align-center tableBR" style="height:100%;" cols="4">
                            <span class="d-inline-block">
                                <span class="d-inline-block mb-1" style="width: 80px;">
                                    <v-text-field v-model="list.rsdntRgnmb1" :rules="[rules.numberSixCheck, rules.numberCheck]" height="28" hide-details outlined dense></v-text-field>
                                </span>
                                <span class="d-inline-block text-h5 font-weight-bold pa-0 pl-1" style="width: 15px;">
                                &#45;
                                </span>
                                <span class="d-inline-block" style="width: 80px;">
                                    <v-text-field v-model="list.rsdntRgnmb2" :rules="[rules.numberSevenCheck, rules.numberCheck]" height="28" hide-details outlined dense></v-text-field>
                                </span>
                            </span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="txtAlgnMid" cols="6">
                    <v-row v-for="(list1, j) in bnColList[list.name]" :key="j" no-gutters>
                        <v-col class="tableBR tableBB py-2" v-if="(list.name == list1.name)" cols="4">{{$moment(list1.dpsDt, 'YYYYMMDD').format('YYYY.MM.DD')}}</v-col>
                        <v-col class="tableBR tableBB py-2" v-if="(list.name == list1.name)" cols="4">{{list1.dpsAmt?.toLocaleString('ko-KR')}}</v-col>
                        <v-col class="py-2 tableBB" v-if="(list.name == list1.name)" cols="4">{{list1.clmYymm?.beforeDateFormatMonthDot()}}</v-col>
                    </v-row>
                </v-col>
            </v-row>            
        </div>
        <div v-else>
            <v-row class="pa-0 ml-2" no-gutters>
                <v-col class="d-flex justify-center py-3" cols="12">
                    <span class="grey006--text">※ 조회된 항목이 없습니다.</span>
                </v-col>
            </v-row>
            <v-divider class="mx-2"></v-divider>
        </div>
        <v-divider class="mr-2__5" style="border: 1px dashed #dbdbdb"></v-divider>     
    </v-sheet>
</template>

<script>
import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';

import { selAllCopayClncList } from '../../api/bnfcr.js';
import { selFcltyInfInfo } from '../../api/opr.js';
import { groupBy } from '../../api/common.js';
import { mdcexXlsxPrdc } from '../../api/global/cmmExcelJS.js';

export default {
    name: 'CopaymdcexDdctn',
        
    components: {
        expandYearOnlyPicker,
    },

    created: function(){
        this.getFcltyInfInfo()
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch:{

    },
        
    methods: {
        getFcltyInfInfo: function (){
            selFcltyInfInfo(this.$store.getters.getFcltyNum)
                .then((response) => ( this.getFcltyInfInfoAfter(response.data) ))
                .catch((error) => console.log('connect error /opr/selFcltyInfInfo : ' + error))
        },
        getAllCopayClncList: function (yyyy){
            this.bnNameCol.splice(0)
            this.bnColspan.splice(0)
            this.savBtn = true

            selAllCopayClncList(this.$store.getters.getFcltyNum, yyyy)
                .then((response) => ( this.getAllCopayClncListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selAllCopayClncList : ' + error))
        },
        getFcltyInfInfoAfter: function (res) {
            if(Object.keys(res).length > 0){
                this.mdcexDdctnInfo.bsnsRgst = res.bsnsRgst
                this.mdcexDdctnInfo.fcltyClnm = res.fcltyClnm
                this.mdcexDdctnInfo.fcltyTel1 = res.fcltyTel1
                this.mdcexDdctnInfo.fcltyTel2 = res.fcltyTel2
                this.mdcexDdctnInfo.fcltyTel3 = res.fcltyTel3
                this.mdcexDdctnInfo.ceoTel1 = res.drccClphn1
                this.mdcexDdctnInfo.ceoTel2 = res.drccClphn2
                this.mdcexDdctnInfo.ceoTel3 = res.drccClphn3
                this.mdcexDdctnInfo.mngr = res.mngr
                this.mdcexDdctnInfo.ceo = res.ceo
            }
            else
                this.snackControll([true, 3000, '조회된 데이터가 없습니다.', 'error'])

            this.savBtn = false
        },
        getAllCopayClncListAfter: function (res) {
            if(res.length > 0){
                res.forEach(itm => {
                    itm.dpsDt = Number(itm.dpsDt)
                });
                this.allCopayClncList = res
                this.groupForCopayClncList(res)
            }
            else
                this.snackControll([true, 2500, '본인부담금 입금내역이 존재하지 않습니다.', 'error'])
        },
        groupForCopayClncList: function (list) {
            this.bnColList = Object.assign({})

            //청구년월 내림차순 정렬
            // list.sort(
            //     function (a, b) {
            //         let x = a.clmYymm?.toUpperCase()
            //         let y = b.clmYymm?.toUpperCase()

            //         if (x < y)
            //             return 1
            //         if (x > y)
            //             return -1

            //         return 0
            //     }
            // )

            this.bnColList = groupBy(list, 'name')

            list.forEach(item => {
                if(this.bnNameCol.length == 0) this.bnNameCol.push({ rn: item.rn, name: item.name, birth: item.birth, rsdntRgnmb1: item.birth.slice(2, 8), rsdntRgnmb2: '' })
                else {
                    let res = this.bnNameCol.some( x => {
                        return x.name == item.name
                    })
                    if(!res) this.bnNameCol.push({ rn: item.rn, name: item.name, birth: item.birth, rsdntRgnmb1: item.birth.slice(2, 8), rsdntRgnmb2: '' })
                }
            })

            this.bnNameCol.forEach(item => {
                let cnt = 0
                list.forEach(bn => {
                    if(item.name == bn.name) cnt += 1
                })
                this.bnColspan.push(cnt)
            })

            this.savBtn = false
        },
        getXlsx: function (){
            let ttl = this.year + '년_의료비공제_증빙자료(요양기관_' + this.$store.getters.getFcltyNum + ')'

            this.mdcexXlsxData.forEach((idx, i) => {
                switch (i) {
                    case 0:
                        idx.inData = Number(this.mdcexDdctnInfo.bsnsRgst)
                        break;
                    case 1:
                        idx.inData = this.mdcexDdctnInfo.fcltyClnm
                        break;
                    case 3:
                        idx.inData = this.mdcexDdctnInfo.ceo
                        break;
                    case 4:
                        idx.inData = this.mdcexDdctnInfo.fcltyTel1 + '-' + this.mdcexDdctnInfo.fcltyTel2 + '-' + this.mdcexDdctnInfo.fcltyTel3
                        break;
                    case 5:
                        idx.inData = this.mdcexDdctnInfo.mngrDept
                        break;
                    case 6:
                        idx.inData = this.mdcexDdctnInfo.mngr
                        break;
                    default:
                        break;
                }
            });

            this.allCopayClncList.forEach(itm => {
                let chc = this.bnNameCol.findIndex( v => v.birth == itm.birth && v.name == itm.name )
                if(chc > -1)
                    itm.rsdntRgnmb = this.bnNameCol[chc].rsdntRgnmb1 + '-' + this.bnNameCol[chc].rsdntRgnmb2
            });

            mdcexXlsxPrdc(ttl, this.mdcexXlsxData, this.allCopayClncList)
        },
        getYear: function (yyyy){
            this.year = yyyy
            this.getAllCopayClncList(yyyy)
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    
    data: () => ({
        year: '',
        savBtn: true,
        dialog: [false, false],
        allCopayClncList: [],
        bnNameCol: [],
        bnColspan: [],
        bnColList: [],
        mdcexDdctnInfo: {
            bsnsRgst: '', bsnsRgst1: '', bsnsRgst2: '', bsnsRgst3: '', fcltyClnm: '', ceo: '', fcltyTel1: '', fcltyTel2: '', fcltyTel3: '',
            mngr: '', mngrDept: '', ceoTel1: '', ceoTel2: '', ceoTel3: '', 
        },
        mdcexXlsxData:[
            { subTtl: '사업자등록번호', inData: '', cntnt: '사업자등록번호를 입력합니다.\n예1) 1234567890          예2) 123-45-67890' },
            { subTtl: '발급기관명칭', inData: '', cntnt: '발급기관명칭을 입력합니다.\n예) 국세의료원' },
            { subTtl: '요양기관기호', inData: '', cntnt: '심사평가원에서 부여받은 요양기관기호(8자리)를 입력합니다.\n※ 8자리 요양기관기호가 없는 경우 99999999를 입력합니다.\n예) 12345678' },
            { subTtl: '대표자 성명', inData: '', cntnt: '대표자 성명을 입력합니다.\n예) 홍길동' },
            { subTtl: '전화번호', inData: '', cntnt: '전화번호를 지역번호 포함하여 입력합니다.\n예) 02-1111-2222' },
            { subTtl: '담당부서', inData: '', cntnt: '의료비 자료제출을 담당하는 부서명을 입력합니다.\n(담당부서가 없는 경우는 상호명 기재)\n예) 원무과' },
            { subTtl: '담당자 성명', inData: '', cntnt: '의료비 자료제출을 담당하는 직원의 성명을 입력합니다.\n예) 홍길동' },
            { subTtl: '담당자 연락처', inData: '', cntnt: '의료비 자료제출 담당자의 전화번호를 지역번호 포함하여 입력합니다.\n예) 02-3333-4444' },
            { subTtl: '제출대상자료의 범위', inData: '1 : 보험 + 비보험 모두 제출', cntnt: '※ 2012년 의료비 자료는 [보험+비보험] 모두 제출해야 합니다.\n※ 장기요양기관은 노인장기요양보험법 제40조 제1항의 규정에 따라 실제 지출한 본인일부부담금 자료(12개월분)을 제출해야 합니다.\n"1 : 보험 + 비보험 모두 제출" 선택' },
        ],
        rules: {
            required: value => !!value || 'Required.',
            numberTwoCheck: value => !(value && value.length > 2) || 'size to bigger',
            numberThreeCheck: value => !(value && value.length > 3) || 'size to bigger',
            numberFourCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberFiveCheck: value => !(value && value.length > 5) || 'size to bigger',
            numberSixCheck: value => !(value && value.length > 6) || 'size to bigger',
            numberSevenCheck: value => !(value && value.length > 7) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
        },
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>