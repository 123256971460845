<template>
    <v-sheet class="rounded-lg rounded-l-0" min-width="1260" max-width="1560" height="835" color="">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>        
        <div class="px-2 mt-2 mr-2 d-flex align-center">
            <div class="text-h4">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">8-2. 기관&nbsp;부담금&nbsp;관리</span>
            </div>
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn v-if="list.type == 'action'" @click="onClickBtn(i)"
                    :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                    <v-icon small>{{list.icon}}</v-icon>
                    <span style="font-size: 1rem;">{{list.text}}</span>
                </v-btn>
                <v-dialog v-else-if="list.type == 'dialogue'"
                    v-model="dialog[i]" :max-width="list.width" content-class="round">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                :class="list.class" samll height="30" :color="list.color" rounded>
                                <v-icon small>{{list.icon}}</v-icon>
                                <span style="font-size: 1rem;">{{list.text}}</span>
                            </v-btn>
                        </template>
                        <MsclnExpnsIns v-if="list.text == '기타비용 입력'"></MsclnExpnsIns>
                        <div v-else>개발중</div>
                </v-dialog>
            </span>
            <span class="d-inline" v-for="(list, j) in outlinedBtn" :key="filledBtn.length+j">
                <v-dialog
                    v-model="dialog[filledBtn.length + j]" :max-width="list.width">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                :class="list.class" samll height="30" :color="list.color" rounded outlined>
                                <v-icon small>{{list.icon}}</v-icon>
                                <span style="font-size: 1rem;">{{list.text}}</span>
                            </v-btn>
                        </template>
                        <div>개발중</div>  
                </v-dialog>
            </span>
        </div>
        <div class="mt-5">
            <expandMonthPicker :prtDate="date" @nowYearWithMonth="getPickerDate"></expandMonthPicker>
        </div>
        <v-row class="ma-0 pa-0 mt-3 mx-3">           
            <exBtn class="mr-1" :btnData="exBtn[0]" @btnClosed="expandClose" @changeCheck="filterSearch" />        
            <span class="d-inline-block" style="width: 130px;">
                <v-text-field
                    class="ma-0 pa-0 nameSearch" height="33" background-color="white" append-icon="mdi-magnify"
                    single-line rounded outlined dense filled hide-details label="이름조회" @input="setSearchText">
                </v-text-field>
            </span>
        </v-row>
        <v-row class="ma-0 pa-0 mt-3 mx-3">
            <v-data-table hide-default-footer dense locale="ko" height="670" min-width="1360"
                hide-default-header fixed-header 
                :headers="headers" 
                :items="fcltylWgeList" 
                :search="schDummy"  
                :custom-filter="customFilter"               
                class="" disable-pagination>

                <template v-slot:header="{ props }" >
                    <thead>
                        <tr>                            
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 110px !important;" colspan="1" rowspan='2'>{{props.headers[0].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 110px !important;" colspan="1" rowspan='2'>{{props.headers[1].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 110px !important;" colspan="1" rowspan='2'>{{props.headers[2].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 110px !important;" colspan="1" rowspan='2'>{{props.headers[3].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 110px !important;" colspan="1" rowspan='2'>{{props.headers[4].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="" colspan="5">{{props.headers[5].text}}</th>                            
                            <!-- <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 100px !important;" rowspan="2">{{props.headers[10].text}}</th> -->
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 100px !important;" colspan="1" rowspan='2'>{{props.headers[11].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBT" style="width: 70px !important;" colspan="1" rowspan='2'>처리</th>
                        </tr>
                        <tr style="border-top: 1px solid #dbdbdb !important;">                            
                            <th class="black--text clmsHeaderCommon tableBR py-1" style="width: 100px !important;">{{props.headers[6].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR py-1" style="width: 100px !important;">{{props.headers[7].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR py-1" style="width: 110px !important;">{{props.headers[8].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR py-1" style="width: 100px !important;">{{props.headers[9].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR py-1" style="width: 100px !important;">{{props.headers[10].text}}</th>
                        </tr>
                    </thead>
                </template>                
                <template v-slot:item='{ item }'>
                    <tr>
                        <td class="clmsBodyCommon tableBR">{{item.name}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.jobNm}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.entDt?.beforeDateFormatDot()}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.wgeTypNm?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.amtIncm?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.amt1?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.amt2?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.amt3?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.amt4?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.amt5?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsBodyCommon tableBR">
                            <!-- {{item.amtRtr?.toLocaleString('ko-KR')}} -->
                            <v-text-field v-model="item.amtRtr" color="blue" height="28" hide-details outlined dense></v-text-field>
                        </td>                        
                        <td class="clmsBodyCommon">
                            <v-btn color='blue200' icon @click="setFcltyWgePym(item)">
                                <v-icon>mdi-content-save-outline</v-icon>
                            </v-btn>
                        </td>                        
                    </tr>
                </template>
            </v-data-table>
        </v-row>
    </v-sheet>
</template>
<script>
import expandMonthPicker from '../../components/commons/expandMonthPicker.vue';
import exBtn from '../../components/commons/expandButtonCustom.vue';
import { getMultiStcd } from '../../api/index.js';
import { selAllFcltylWgePymList, updFcltyWgePym } from '../../api/impExp.js';

export default {
    name: 'FcltylWgeMgmt',
        
    components: {
        expandMonthPicker,
        exBtn,
    },

    created: function(){
        this.getCode(['42'])
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
        })
    },

    watch:{
    },

    methods: {
        getPickerDate: function (date) {
            this.date = date   
            this.getAllFcltylWgePymList()
        },
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        //기관 부담금 조회
        getAllFcltylWgePymList: function () {
            selAllFcltylWgePymList(this.$store.getters.getFcltyNum, this.date)
                .then((response) => ( this.getAllFcltylWgePymListAfter(response.data) ))
                .catch((error) => console.log('connect error /impExp/selAllEmplWgePymList : ' + error))
        },

        setFcltyWgePym: function (item) {
            
            if(item.amtRtr == ''){
                this.snackControll([true, 2500, '퇴직적립금을 입력해주세요.', 'warning'])
                return
            }

            if(/^[0-9]*$/.test(item.amtRtr) == false){
                this.snackControll([true, 2500, '퇴직적립금을 숫자로 입력해주세요.', 'warning'])
                return
            }

            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum,
                userNm      : this.$store.getters.getUserNm,
                stdYymm     : this.date,
                amt         : item.amtRtr,
                empPk       : item.empPk,
                wgeItmClcd  : '16',
            }   
            
            updFcltyWgePym(obj)
                .then((response) => ( this.setFcltyWgePymAfter(response.data) ))
                .catch((error) => console.log('connect error /impExp/updFcltyWgePym : ' + error))            
        },

        stCdMapping: function (res) {
            this.vCd42 = res.vCd42

            this.vCd42.forEach(code => {
                this.exBtn[0].expandList.push(code.valcdnm)
            })
        },        
        getAllFcltylWgePymListAfter: function (res) {                        
            res.forEach(items => {
                this.vCd42.forEach(item => {
                    if(items.jobCd == item.valcd)
                        items.jobCd = item.valcdnm
                });                    
            });
            
            this.fcltylWgeList = res
        },
        setFcltyWgePymAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.getAllFcltylWgePymList()
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        filterSearch: function (sch) {            
            if(sch.length > 0){
                sch.forEach((item) => { this.schChkField.jobCd.push(item) });            
                this.schDummy = this.schDummy + '.'
            }else{
                this.schDummy= ''
            }
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column
            let col0 = filter.name.includes(this.schField)
            
            let col1 = true
            if(this.schChkField.jobCd.length != 0){
                this.schChkField.jobCd.forEach((stcd, i) => {
                    if(i == 0) col1 = filter.jobCd.includes(stcd)
                    else col1 = col1 || filter.jobCd.includes(stcd)
                });
            }

            return col0 && col1;
        },
        setSearchText: function (value) {
            this.schField = value
            this.schDummy = this.schDummy + '.'
        },
        onClickBtn: function () {            
        },        
        expandClose: function (index) {
            switch(index) {
                case 0: this.schChkField.target = 'jobCd'
                    break;
                default: this.schChkField.target = ''
                    break;
            }
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        
        checkBoxIndex: function () {
            let chk = []
            let pkList = []
            this.fcltylWgeList.forEach(items => {
                if(typeof items.isSelected == 'undefined') chk.push(false)
                else chk.push(items.isSelected)

                if(items.isSelected == true) pkList.push(items.empPk)
            });
            this.selectedList = pkList
            let result = chk.every((item) => item==true)

            if(result) this.allChecker = true
            else this.allChecker = false
        },
        checkBoxMessage: function () {
            if(this.allChecker == true){
                this.fcltylWgeList.forEach(item => {
                    item.isSelected = true
                })
            }
            else if(this.allChecker == false){
                this.fcltylWgeList.forEach(item => {
                    item.isSelected = false
                })
            }
            let pkList = []
            this.fcltylWgeList.forEach(items => {
                if(items.isSelected == true) pkList.push(items.empPk)
            });
            this.selectedList = pkList
        },
        modalEvent: function (event) { 
            if(event == 99){
                this.getAllEmplWgePymList(this.date)
            }else{
                this.modal.splice(event, 1, false)
            }      
        },
    },
    data: () => ({
        allChecker:false,
        date:'',
        type:'',
        delTitle:'',
        contents:'하시겠습니까',
        dialog: [],
        modal:[false,false],
        fcltylWgeList:[],
        schField: '',
        schDummy: '',
        schChkField: { jobCd: [], target: '' },        
        exBtn: [ 
            { index:  0, btnTitle: '직종', btnIcon: 'business', exWidth: 134, exHeigth: 220, expandList: [], },
        ],
        headers: [
            { text: '직원명', value: 'name', align: 'center'},
            { text: '직종', value: 'jobNm', align: 'center'},
            { text: '입사일', value: 'entDt', align: 'center'},
            { text: '임금형태', value: 'wgeTypNm', align: 'center'},
            { text: '직원임금', value: '', align: 'center'},
            { text: '4대보험', value: '', align: 'center'},
            { text: '국민연금', value: '', align: 'center'},
            { text: '건강보험', value: '', align: 'center'},
            { text: '장기요양보험', value: '', align: 'center'},
            { text: '고용보험', value: '', align: 'center'},
            { text: '산재보험', value: '', align: 'center'},
            { text: '퇴직적립금', value: '', align: 'center'},        
        ],
        filledBtn: [
            
        ],
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '출력', width:'1000px', disabled: true, },
        ],
        snackOptions: [false, 3000, '', 'info'],
        dialogDelete:false,
    })
}
</script>