<template>
    <v-sheet class="d-inline-block rounded-lg rounded-l-0 pa-4" min-width="1260" max-width="1560" height="835">
        <v-row class="pa-0 mb-6" no-gutters>
            <v-col cols="3">
                <div class="text-h4 d-flex align-center">
                    <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                    <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">10-2. 공지사항&nbsp;관리</span>
                    <v-spacer></v-spacer>
                    <!-- button area -->
                </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="3">
                <v-spacer></v-spacer>
                <v-btn v-for="(list, i) in filledBtn" :key="i" @click="onClickBtn(list.text)" :class="list.class" height="30"
                    :color="list.color" rounded :disabled="list.disabled" style="font-size: 14px;">
                    <v-icon class="mr-1" size="18">{{ list.icon }}</v-icon>
                    {{ list.text }}
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col class="" cols="2">
                <v-text-field
                    v-model="srchTxt" @input="setSearchText"
                    class="txt-Theme" height="34" label="제목 내용 조회"
                    background-color="white" prepend-inner-icon="mdi-magnify"
                    single-line outlined dense filled hide-details>
                </v-text-field>
                <v-spacer></v-spacer>
            </v-col>
        </v-row>

        <v-row class="mt-3 pa-0" no-gutters>
            <v-col class="" cols="12">
                <v-data-table
                    class="dft-hvr-tbl" locale="ko" height="450"
                    :headers="headers" :items="notices"
                    :loading="loader" 
                    loading-text="Loading... Please wait"
                    hide-default-header hide-default-footer dense disable-pagination>
                    <template v-slot:header="{ props }">
                        <thead>
                            <tr style="">
                                <th class="px-2 py-2 ntcTbHeader" style="width: 8%;">
                                    <span class="black--text">{{ props.headers[0].text }}</span>
                                </th>
                                <th class="px-2 ntcTbHeader" style="width: 72%;">
                                    <span class="black--text">{{ props.headers[1].text }}</span>
                                </th>
                                <th class="px-2 ntcTbHeader" style="width: 12%;">
                                    <span class="black--text">{{ props.headers[2].text }}</span>
                                </th>
                                <th class="px-2 ntcTbHeader" style="width: 8%; border-right: initial !important;">
                                    <span class="black--text">{{ props.headers[3].text }}</span>
                                </th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item, index }'>
                        <tr :class="[selectedItem == item.ntcBbsPk ? 'onClr' : '']" @click="tableClickEvent(index, item.ntcBbsPk)">
                            <td 
                                class="hvrClr tableBR tableBB py-2"
                                style="text-align: center;">
                                <span class="fontOneRem">{{item.rn}}</span>
                            </td>
                            <td 
                                class="hvrClr tableBR tableBB py-2">
                                <span class="fontOneRem">{{ item.title }}</span>
                            </td>
                            <td 
                                class="hvrClr tableBR tableBB py-2"
                                style="text-align: center;">
                                <span class="fontOneRem">{{ item.crtDt }}</span>
                            </td>
                            <td 
                                class="hvrClr tableBB py-2"
                                style="text-align: center;">
                                <span class="fontOneRem">{{ item.crtr }}</span>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <div class="mt-2">            
            <v-pagination
                v-model="page"
                :length="pageCount"
                @input="getPage"
                total-visible="10">
            </v-pagination>
        </div>
        <v-dialog eager v-model="dialog[0]" persistent max-width="1000" min-width="1000">
            <insNtsBbs ref="insNtsBbs" @isClose="isModalType" @reload="reset()"></insNtsBbs>
        </v-dialog>
    </v-sheet>
</template>

<script>
import insNtsBbs from '../../components/othr/insNtsBbs.vue';
import { selNtsBbsList, } from '../../api/othr.js';
export default {
    name: 'NtcMgmt',

    components: {
        insNtsBbs,

    },

    created: function () {
    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.selNtsBbsList(0,this.itemsRows)
        })
    },

    computed: {

    },

    watch: {
    },

    methods: {
        reset(){
            this.selNtsBbsList(0,this.itemsRows)
        },
        getPage:function(){
            let strt = ((this.page-1)*this.itemsRows)
            this.selNtsBbsList(strt,this.itemsRows)
        },
        selNtsBbsList: function (strt, lmt) {
            this.notices.splice(0)
            this.loader = true
            let obj = {                
                rnStrt:strt,
                rnLmt:lmt, 
                srchWd1:this.srchTxt,
                ntcYn:'2',
            }      

            selNtsBbsList(obj)
                .then((response) => (this.selNtsBbsListAfter(response.data)))
                .catch((error) => console.log('connect error othr/selNtsBbsList : ' + error))
        },
        selNtsBbsListAfter: function (res) {
            this.loader = false
            let obj = {}    
            if(res.statusCode == 200){ 
                if(res.result.list !='' && res.result.list != null){  
                    this.pageCount = Math.ceil(Number(res.result.rnTot)/this.itemsRows)
                    res.result.list.forEach(el => {
                        this.notices.push(el)
                    });

                }
            } else {
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },
        onClickBtn: function (text) {
            if (text == '신규') {
                this.dialog.splice(0, 1, true)
                this.$refs.insNtsBbs.resetPage()
            } 
        },
        isModalType: function () {
                this.dialog.splice(0, 1, false)
                this.selectedItem = -1
                this.reset()
        },
        tableClickEvent: function (rn, pk) {
            if (this.selectedItem == pk) {
                this.selectedItem = -1
                this.$refs.insNtsBbs.resetPage()
            }
            else {
                this.selectedItem = pk
                this.dialog.splice(0, 1, true)
                this.$refs.insNtsBbs.selNtsBbsInfo(pk)
            }
            
        },
        setSearchText:function(){
            let pattern = /([^가-힣a-z\x20])/i
            
            if(this.srchTxt != ''){
                if(!pattern.test(this.srchTxt)) {
                    this.page = 1
                    this.selNtsBbsList(0,this.itemsRows)
                }
            } else {
                this.page = 1
                this.selNtsBbsList(0,this.itemsRows)
            }
        },
        filterSearch: function (sch) {
            if (sch.length == 0) this.schChkField.push('')

            this.schChkField = []
            sch.forEach((item) => {
                this.schChkField.push(item)
            });

            //배열 공백 제거
            this.schChkField = this.schChkField.filter(function (item) {
                return item !== null && item !== undefined && item !== '';
            });
            //배열 중복 값 제거
            this.schChkField = this.schChkField.filter((val, idx) => {
                return this.schChkField.indexOf(val) === idx
            });

            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            // value: items, search: search key, filter: array column
            let col0 = filter.title.includes(this.schField)
            
            return col0;
        },
        
    },

    data: () => ({
        filledBtn: [
            { icon: 'mdi-pencil-outline', class: 'ml-1 white--text', color: 'blueBtnColor', text: '신규', },
        ],
        headers: [
            { text: '번호', value: 'rn', sortable: true, align: 'center', class: 'black--text' },
            { text: '제목', value: 'title', sortable: true, align: 'center', class: 'black--text' },
            { text: '등록일', value: 'crtDt', sortable: true, align: 'center', class: 'black--text' },
            { text: '작성자', value: 'crtr', sortable: true, align: 'center', class: 'black--text' },
        ],
        totCnt: 0,
        notices: [],
        itemsRows: 10,
        dialog: [false],
        page: 1,
        pageCount: 0,
        selectedItem: -1,
        schChkField: { valClcdnm: [], valCdDesc: [], target: '' },
        srchTxt: '',
        loader:false
    }),
};
</script>

<style>
    .ntcTbHeader {
        border-top: 1px solid #dbdbdb !important; border-right: 1px solid #dbdbdb !important;
        background-color: #ededed !important; text-align: center !important; font-size: 1.1rem !important;
    }
    .ntcChc .v-input__control .v-input__slot { padding: 1px 12px 0 16px;  }
</style>