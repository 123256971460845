<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">   
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>       
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">상급침실비 관리</span>
            <v-spacer></v-spacer> 
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <v-form ref="form" lazy-validation class="mt-1 mr-3">
        <v-divider class="mt-2"></v-divider>
        <v-row class="pa-0" no-gutters>          
            <v-col class="greyE01 py-2" style="text-align: center;" cols="2">
                <span class="black--text font-weight-medium">적용일</span>
            </v-col>
            <v-col class="d-flex align-center tableBL pl-2" cols="10">
                    {{$moment($parent.$parent.$parent.$parent.aplStrt,'YYYYMMDD').format('YYYY.MM.DD')}}
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row class="mt-2 pa-0" no-gutters>
            <v-col cols="12">
                <v-data-table
                    :headers="lvnRmTypHeader"
                    :items="lvnRmTypList"
                    :loading="lvnRmTypLoader"
                    :key="forceRender"
                    loading-text="Loading... Please wait"
                    fixed-header hide-default-header hide-default-footer dense disable-pagination                         
                    height='330'
                >
                    <template v-slot:header="{ props }" >
                        <thead>
                            <tr>
                                <th class="black--text clmsDtlHeaderCommon py-2 tableBT tableBR" style="width:50%;">{{props.headers[0]?.text}}</th>
                                <th class="black--text clmsDtlHeaderCommon tableBT" style="width:50%;">{{props.headers[1]?.text}}</th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item }'>
                        <tr>
                            <td class="clmsBodyCommon tableBR pa-0">
                                {{item.lvnRmTypNm}}
                            </td>
                            <td class="clmsBodyCommon pa-2">
                                <div class="d-flex justify-end align-center">                                            
                                    <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                        <v-text-field
                                            v-model="item.useFee"
                                            style="text-align: right !important;"
                                            type="number" height="28"
                                            dense outlined hide-details>
                                        </v-text-field>
                                    </span>
                                    <span class="pl-1">원</span>
                                </div>
                                <div class="d-flex justify-end align-center" style="font-size:0.9rem">{{item.useFee?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}}</div>
                            </td>                            
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row class="mt-4 pa-0" no-gutters>
            <v-col class="d-flex justify-center align-center" cols="12">
                <v-btn
                    class="white--text" color="blueBtnColor" @click="preLvnRmTyp" height="30" samll rounded>
                    <v-icon>mdi-content-save-edit-outline</v-icon>
                    <span class="ml-2" style="font-size: 1.1rem; padding-bottom: 2px;">저장</span>
                </v-btn>
            </v-col>
        </v-row>
        </v-form>
    </v-sheet>
</template>
<script>
import { selLvnRmTypList, updLvnRmTyp } from '../../api/opr.js';

export default {
    name: 'BlnftLvnRmTypMdl',
    
    components: { 
    },

    props : { 
    },

    computed: {
        
    },

    created: function(){ 
    },

    mounted: function(){
        this.$nextTick(function () {
            this.selCodeList()
        })
    },

    watch: {
    },

    methods: {  
        selCodeList:function(){
            this.lvnRmTypList.splice(0)
            this.$parent.$parent.$parent.$parent.vCd114.forEach(cd => {
                let obj = {
                    lvnRmTypPk:0,
                    lvnRmTypNm:cd.valcdnm,
                    aplStrt:'',
                    aplEnd:'',
                    upBedYn:cd.valcd,
                    useFee:0,
                }
                this.lvnRmTypList.push(obj)
            });
            this.selLvnRmTypList()
        },
        selLvnRmTypList: function () {
            selLvnRmTypList(this.$store.getters.getFcltyNum)
                .then((response) => (this.selLvnRmTypListAfter(response.data)))
                .catch((error) => console.log('connect error opr/selLvnRmTypList : ' + error))
        },
        setLvnRmTyp: function (obj) {
            updLvnRmTyp(obj)
                .then((response) => (this.setLvnRmTypAfter(response.data)))
                .catch((error) => console.log('connect error opr/updLvnRmTyp : ' + error))
        },        
        selLvnRmTypListAfter: function (res) {
            res.forEach(item => {
                let idx = this.lvnRmTypList.findIndex(x=>x.upBedYn == item.upBedYn)
                this.lvnRmTypList[idx].lvnRmTypPk = item.lvnRmTypPk
                this.lvnRmTypList[idx].aplStrt = item.aplStrt
                this.lvnRmTypList[idx].aplEnd = item.aplEnd
                this.lvnRmTypList[idx].useFee = item.useFee
            });
            ++this.forceRender;
        },
        setLvnRmTypAfter:function(res){
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.selLvnRmTypList()
                this.$emit('modalReload')
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        preLvnRmTyp:function(){
            if(this.$parent.$parent.$parent.$parent.items.nbnftFeeInfPk > 0){
                let obj = {
                    nbnftFeeInfPk   : this.$parent.$parent.$parent.$parent.items.nbnftFeeInfPk,
                    fcltyNum        : this.$store.getters.getFcltyNum,
                    userNm          : this.$store.getters.getUserNm,
                    upBedFee1       : 0,
                    upBedFee2       : 0,
                    list            : []
                }

                this.lvnRmTypList.forEach(el => {
                    let item = {
                        lvnRmTypPk  : el.lvnRmTypPk,
                        lvnRmTypNm  : el.lvnRmTypNm,
                        aplStrt     : el.aplStrt,
                        aplEnd      : el.aplEnd,
                        upBedYn     : el.upBedYn,
                        useFee      : el.useFee,
                    }

                    
                    if(item.lvnRmTypPk == 0) item.aplStrt = this.$parent.$parent.$parent.$parent.aplStrt
                    if(item.lvnRmTypPk == 0) item.aplEnd = this.$parent.$parent.$parent.$parent.aplEnd

                    //1인실 이용료
                    if(item.upBedYn == '1') obj.upBedFee1 = item.useFee
                    //2인실 이용료
                    if(item.upBedYn == '2') obj.upBedFee2 = item.useFee

                    obj.list.push(item)
                });

                console.log(obj)
                this.setLvnRmTyp(obj)                
            }else{
                this.snackControll([true, 5000, '잘못된 경로입니다.', 'warning'])
            }
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        onClose:function(){
            this.$emit('modalClose')
        },        
    },
    data: () => ({ 
        forceRender:0,    
        snackOptions: [false, 3000, '', 'info'],
        lvnRmTypLoader:false,
        lvnRmTypHeader  : [            
            { text: '상급침실구분', align:'center', value: 'lvnRmTypNm', filterable: false,},            
            { text: '이용료', align:'center', value: 'useFee', filterable: false,},
        ],
        lvnRmTypList    : [],
    }),
};
</script>