import { http } from '@/api/baseAxios';

//수급자정보 다건 조회
function bnfcrList(code, entCd = null, startDt = null, endDt = null) {
    return http.post('bnfcr/selBnfcrList', { fcltyNum: code, entStcd: entCd, inqYmdStart: startDt, inqYmdEnd: endDt,})
}
//수급자정보 단건 조회
function bnfcrInfoDetail(code, state) {
    return http.post('bnfcr/selBnfcrInfo', { fcltyNum: code, bnMmbrPk: state.bnmmbr, bnfcrPk: state.bnfcr })
}
//주 보호자 정보 조회
function galFmlyList(code, state) {
    return http.post('bnfcr/selGarnInfo', { fcltyNum: code, bnfcrPk: state.bnfcr })
}
//부 보호자 다건 조회
function fmlyList(code, state) {
    return http.post('bnfcr/selFmlyList', { fcltyNum: code, bnfcrPk: state.bnfcr })
}
//수급자 정보 저장
function setBnfcrFull(code, nm, bnfcr, galFmly, fmly, file, aplList) {
    return http.post('bnfcr/insBnfcr', { fcltyNum: Number(code), userNm: nm, bnfcrInfo: bnfcr, galFmlyInfo: galFmly, fmlyInfo: fmly, files: file, mdfhisList: aplList })
}
//수급자 정보 삭제
function delBnfcrInfo(code, state, nm) {
    return http.post('bnfcr/delBnfcr', { fcltyNum: code, bnfcrPk: state.bnfcr, bnMmbrPk: state.bnmmbr, userNm: nm })
}
//계약정보 단건 조회
function bnfcrCntrcDetail(code, state) {
    return http.post('bnfcr/selBnfcrCntrcInf', { fcltyNum: code, bnMmbrPk: state.bnmmbr, bnfcrPk: state.bnfcr })
}
//동의 및 제공서류 조회
function bnfcrCntrcAgr(code, state, pk) {
    return http.post('bnfcr/selBnfcrCntrcInf', { fcltyNum: code, bnMmbrPk: state.bnmmbr, bnfcrPk: state.bnfcr, bnfcrCntrcInfPk: pk })
}
//계약서 확인사항 조회
function bnfcrCntrcCnfmDetail(code, state, cd) {
    return http.post('bnfcr/selBnfcrCntrcCnfmList', { fcltyNum: code, bnfcrCntrcInfPk: state, cnfmClcd: cd })
}
//비급여수가정보 조회
function getPaymntFeeInf(code) {
    return http.post('opr/selNbnftFeeInf', { fcltyNum: code })
}
//급여수가금액 조회
function getBnftFeeInfo(fcCd, bnCd, ackRtng) {
    return http.post('opr/selBnftFeeInfo', { fcltyClcd: fcCd, bnftClcd: bnCd, acknwRtng: ackRtng })
}
//계약정보 저장
function setBnfcrCntrc(obj) {
    return http.post('bnfcr/insBnfcrCntrc', { bnfcrCntrc: obj })
}

function setCntrcCnfm(code, nm, cntrcPk, value) {
    return http.post('bnfcr/insBnfcrCntrcCnFm', { fcltyNum: code, userNm: nm, bnfcrCntrcInfPk: cntrcPk, cnfmCntnt: value })
}
//계약서 확인사항 저장
function setCntrcCnfmItm(code, value) {
    return http.post('bnfcr/insCntrcCnfmItm', { fcltyNum: code, cnfmCntnt: value })
}
//계약서 확인사항 삭제
function delCntrcCnfmItm(cntrcCnfmPk, nm) {
    return http.post('bnfcr/delCntrcCnfmItm', { cntrcCnfmItmPk: cntrcCnfmPk, userNm: nm })
}

//수급자 기초정보 한건 조회
function getBnfcrBasicInfo(code, mmbr) {
    return http.post('bnfcr/selBnfcrBsInfo', { fcltyNum: code, bnMmbrPk: mmbr })
}
//개인정보 수집 및 이용 동의서 저장
function setBnfcrCntrcAgr(obj) {
    return http.post('bnfcr/updBnfcrCntrcAgr', obj)
}
//노인 인권 보호 지침 제공 저장
function setBnfcrCntrcEld(obj) {
    return http.post('bnfcr/updBnfcrCntrcAgr', obj)
}
//수급자 안전관리 통지서 저장
function setBnfcrCntrcSft(obj) {
    return http.post('bnfcr/updBnfcrCntrcAgr', obj)
}
//퇴소, 연계이력 다건 조회
function selLvLinkList(code, bnmmbrPk) {
    return http.post('bnfcr/sellvlinkList', { fcltyNum: code, bnMmbrPk: bnmmbrPk })
}
//퇴소 이력 다건 조회
function sellvInfList(code, bnmmbrPk) {
    return http.post('bnfcr/sellvInfList', { fcltyNum: code, bnMmbrPk: bnmmbrPk })
}
//연계 이력 다건 조회
function sellinkRcrdList(code, bnmmbrPk) {
    return http.post('bnfcr/sellinkRcrdList', { fcltyNum: code, bnMmbrPk: bnmmbrPk })
}
//퇴소이력 한건 조회
function selLvInf(code, bnmmbrPk, lvinfoPk) {
    return http.post('bnfcr/selLvInf', { fcltyNum: code, bnMmbrPk: bnmmbrPk, lvInfPk: lvinfoPk })
}
//퇴소이력 저장
function insLvInf(obj) {
    return http.post('bnfcr/insLvInf', obj)
}
//퇴소이력 취소
function delLvInf(bnfcrPk, lvinfoPk, nm) {
    return http.post('bnfcr/delLvInf', { bnfcrPk: bnfcrPk, lvInfPk: lvinfoPk, userNm: nm })
}
//연계이력 한건 조회
function sellinkRcrdInfo(code, bnmmbrPk, lrPk) {
    return http.post('bnfcr/sellinkRcrdInfo', { fcltyNum: code, bnMmbrPk: bnmmbrPk, linkRcrdPk: lrPk })
}
//연계 기록지 저장
function insLinkRcrd(obj) {
    return http.post('bnfcr/inslinkRcrd', obj)
}

//연계 기록지 삭제
function delLinkRcrd(rcrdPk, nm) {
    return http.post('bnfcr/delLinkRcrd', { linkRcrdPk: rcrdPk, userNm: nm })
}

//정보변경 이력 조회(clsfcCode(1:수급자,2:수급자계약), rnStrt: 조회 시작 번호, rnLmt: 출력 갯수)
function getBnfcrCntrcHisList(code, bnmmbrPk, cfcCode, strtRn, rnCnt) {
    return http.post('bnfcr/selBnfcrCntrcHisList', { fcltyNum: code, bnMmbrPk: bnmmbrPk, clsfcCode: cfcCode, rnStrt: strtRn, rnLmt: rnCnt })
}

//수급자 재입소
function reEntBnfcr(obj) {
    return http.post('bnfcr/insReBnfcr', obj)
}

//상담일지 다건 조회
function getCnslnHisList(code, yyyy) {
    return http.post('bnfcr/selCnslnHisList', { fcltyNum: code, inqYYYY: yyyy })
}

//상담내역 저장
function setCnslnHis(obj) {
    return http.post('bnfcr/insCnslnHis', obj)
}

//상담일지 수급자별 분기 다건 조회
function getCnslnHisQrtList(code, bnmmbrPk, bnfcr, q, year) {
    return http.post('bnfcr/selCnslnHisQrtList', { fcltyNum: code, bnMmbrPk: bnmmbrPk, bnfcrPk: bnfcr, inqQtr: q, inqYYYY: year })
}

//상담일지 수급자별 한건 조회(상세조회)
function getCnslnHisInf(code, cnslnPk) {
    return http.post('bnfcr/selCnslnHisInf', { fcltyNum: code, cnslnHisPk: cnslnPk })
}

//상담내역 삭제
function delCnslnHis(code, cnslnPk, nm, bnftPk) {
    return http.post('bnfcr/delCnslnHis', { fcltyNum: code, cnslnHisPk: cnslnPk, userNm: nm, bnftOfrPlnHisPk: bnftPk })
}

//외출외박 다건 조회
function selOutHisList(code, bnmmbrPk, strt, lmt) {
    return http.post('bnfcr/selOutHisList', { fcltyNum: code, bnMmbrPk: bnmmbrPk, rnStrt: strt, rnLmt: lmt })
}

//외출외박 한건 조회
function selOutHisInfo(code, bnmmbrPk, outhisPk) {
    return http.post('bnfcr/selOutHisInfo', { fcltyNum: code, bnMmbrPk: bnmmbrPk, outHisPk: outhisPk })
}

//외출외박 저장
function insOutHis(obj) {
    return http.post('bnfcr/insOutHis', obj)
}

//외출외박 삭제
function delOutHis( code, outhisPk, nm ) {
    return http.post('bnfcr/delOutHis', { fcltyNum: code, outHisPk: outhisPk, userNm: nm })
}

//외출외박 저장(with. 진찰기록 내역)
function insOutHisClncRec(info, list) {
    return http.post('bnfcr/insOutHisClncRec', { outHisInfo: info, clncRecInfo: list })
}

//가정통신문 다건 조회
function selFamlLtrList(code, yyMm) {
    return http.post('bnfcr/selFamlLtrList', { fcltyNum: code, stdYymm: yyMm })
}

//가정통신문 한건 조회
function selFamlLtrInfo(code, famlPk, clcd, yymm, page ) {
    return http.post('bnfcr/selFamlLtrInfo', { fcltyNum: code, famlLtrPk: famlPk, cntntClcd: clcd, inqYYMM: yymm, pgeNum: page })
}

//가정통신문 저장
function insfamlLtr(obj) {
    return http.post('bnfcr/insfamlLtr', obj)
}

//가정통신문 삭제
function delfamlLtr(code, famlPk, nm) {
    return http.post('bnfcr/delfamlLtr', { fcltyNum: code, famlLtrPk: famlPk, userNm: nm })
}

//가정통신문 인사말 저장
function insfamlLtrGrt(obj) {
    return http.post('bnfcr/insfamlLtrGrt', obj)
}

//가정통신문 PDF 경로 조회
function selFamlLtrPrtInfo(obj) {
    return http.post('report/selFamlLtrPrtInfo', obj)
}

//전체 수급자 현황
function selAllBnfcrList(code) {
    return http.post('bnfcr/selAllBnfcrList', { fcltyNum: code })
}

//노인인권, 보호지침 제공
function selHmnHltList(code) {
    return http.post('bnfcr/selHmnHltList', { fcltyNum: code })
}

//본인부담금청구 목록 조회
function selCopayClmHisList(code, yyyymm) {
    return http.post('bnfcr/selCopayClmHisList', { fcltyNum: code, inqYYMM: yyyymm })
}

//청구 상세내역 조회
function selCopayClmList(code, yyyymm, bnmmbr, bnfcr) {
    return http.post('bnfcr/selCopayClmList', { fcltyNum: code, inqYYMM: yyyymm, bnMmbrPk: bnmmbr, bnfcrPk: bnfcr })
}

//청구내역 조회
function selCopayClmHisInfo(code, yyyymm, bnmmbr, bnfcr) {
    return http.post('bnfcr/selCopayClmHisInfo', { fcltyNum: code, inqYYMM: yyyymm, bnMmbrPk: bnmmbr, bnfcrPk: bnfcr })
}

//청구내역 저장
function insCopayClmHis(obj) {
    return http.post('bnfcr/updCopayClmHis', obj)
}

//청구상세내역 단건 저장
function updCopayClm(obj) {
    return http.post('bnfcr/updCopayClm', obj)
}

//전월 정산내역 저장
function updMultiCopayClm(obj) {
    return http.post('bnfcr/updMultiCopayClm', obj)
}

//기타비용 목록조회
function selMsclnFeeDtlList(obj) {
    return http.post('bnfcr/selMsclnFeeDtlList', obj)
}

//기타비용 저장
function insMsclnFeeDtl(obj) {
    return http.post('bnfcr/insMsclnFeeDtl', obj)
}

//기타비용 삭제
function delMsclnFeeDtl(code, pk, nm) {
    return http.post('bnfcr/delMsclnFeeDtl', { fcltyNum: code, msclnFeeDtlPk: pk, userNm: nm })
}

//본인부담금청구 생성
function insCopayClmCrt(code, arr, arr2, yymm, user) {
    return http.post('bnfcr/insCopayClmCrt', { fcltyNum: code, bnMmbrPks: arr, bnfcrPks: arr2, inqYYMM: yymm, prcClcd: '1', userNm: user })
}

//급여청구 확정
function updCopayClmHisCnfrm(code, arr, arr2, yn, yymm, user) {
    return http.post('bnfcr/updCopayClmHisCnfrm', { fcltyNum: code, bnMmbrPks: arr, bnfcrPks: arr2, cnfrmYn: yn, inqYYMM: yymm, userNm:user })
}

//급여청구 확정취소
function updCnclCopayClmCnfrm(code, arr, arr2, yymm, user) {
    return http.post('bnfcr/updCnclCopayClmCnfrm', { fcltyNum: code, bnMmbrPks: arr, bnfcrPks: arr2, inqYYMM: yymm, userNm:user })
}

//급여청구 삭제
function delCopayClmHis(code, arr, arr2, yymm, nm, clcd=null) {
    return http.post('bnfcr/delCopayClmHis', { fcltyNum: code, bnMmbrPks: arr, bnfcrPks: arr2, inqYYMM: yymm, userNm: nm, prcClcd:clcd })
}

//본인 부담금 청구 전월 정산내역 조회
function selCopayClmCalcList(code, bnmmbrPk, bnfcr, yymm) {
    return http.post('bnfcr/selCopayClmCalcList', { fcltyNum: code, bnMmbrPk: bnmmbrPk, bnfcrPk: bnfcr, inqYYMM: yymm })
}

//입금 현황조회
function selCopayDpsStsList(code, yymm) {
    return http.post('bnfcr/selCopayDpsStsList', { fcltyNum: code, inqYYYY: yymm })
}

//입금내역조회
function selCopayDpsList(code, bnmmbrPk, yyyy) {
    return http.post('bnfcr/selCopayDpsList', { fcltyNum: code, bnMmbrPk: bnmmbrPk, inqYYYY: yyyy })
}

//본인부담금 청구 입금내역
function selCopayDpsPrcList(obj) {
    return http.post('bnfcr/selCopayDpsPrcList', obj)
}

//입금처리 청구미납금액
function selCopayClmUnpdList(code, bnmmbrPk, bnfcr) {
    return http.post('bnfcr/selCopayClmUnpdList', { fcltyNum: code, bnMmbrPk: bnmmbrPk, bnfcrPk: bnfcr })
}

//입금처리
function insCopayDpsHis(obj) {
    return http.post('bnfcr/insCopayDpsHis', obj)
}

//입금 취소처리
function cnclCopayDps(obj) {
    return http.post('bnfcr/updCnclCopayDps', obj)
}

//의료비 공제 입금 내역 조회
function selAllCopayClncList(code, yyyy) {
    return http.post('bnfcr/selAllCopayClncList', { fcltyNum: code, inqYYYY: yyyy })
}

//본인부담금 입금관리 잔여금 환불
function updCopayDpsRfnd(obj) {
    return http.post('bnfcr/updCopayDpsRfnd', obj)
}

//수급자정보 연락처 다건 조회
function selBnfcrCntctList(obj) {
    return http.post('bnfcr/selBnfcrCntctList', obj)
}

//본인부담금 청구관리 상세내역 문구 조회
function selCopayClmRmrkInfo(code, yymm, pk) {
    return http.post('bnfcr/selCopayClmRmrkInfo', { fcltyNum: code, inqYYMM: yymm, copayClmHisPk: pk })
}

//본인부담금 현금영수증 내역
function selCopayDpsCashBillList(obj) {
    return http.post('bnfcr/selCopayDpsCashBillList', obj)
}

//본인부담금 현금영수증 발송
function insCopayDpsCashBill(obj) {
    return http.post('bnfcr/insCopayDpsCashBill', obj)
}

//본인부담금 현금영수증 출력
function selCashBillPopUpUrl(obj) {
    return http.post('bnfcr/selCashBillPopUpUrl', obj)
}

//본인부담금 현금영수증 취소
function insCancelCashBill(obj) {
    return http.post('bnfcr/insCancelCashBill', obj)
}

//본인부담금 현금영수증 발송 (다건)
function insMultiCashBill(obj) {
    return http.post('bnfcr/insMultiCopayDpsCashBill', obj)
}

//본인부담금 청구서발송 조회
function selCopayEsndList(code, ymd) {
    return http.post('bnfcr/selCopayEsndList', {fcltyNum:code,inqYYMM:ymd})
}

//본인부담금 입금내역 수정
function updCopayDpsHis(obj) {
    return http.post('bnfcr/updCopayDpsHis', obj)
}

//가정통신분 발송 목록 조회
function selESndFmlyLeterList(obj) {
    return http.post('bnfcr/selESndFmlyLeterList', obj)
}

//전월 재정산 가감액 삭제 처리
function updPreMCopayClm(code, yymm, bnPk, bnfcr, nm) {
    return http.post('bnfcr/updPreMCopayClm', { fcltyNum: code, inqYYMM: yymm, bnMmbrPk: bnPk, bnfcrPk: bnfcr, prcClcd: '6', userNm: nm })
}

//CCTV침실촬영동의 목록 조회
function selCctvBdrmList(code, bnPk) {
    return http.post('bnfcr/selCctvBdrmList', { fcltyNum: code, bnMmbrPk: bnPk})
}

//CCTV침실촬영동의 조회
function selCctvBdrmInfo(obj) {
    return http.post('bnfcr/selCctvBdrmInfo', obj)
}

//CCTV침실촬영동의 저장
function insCctvBdrmInf(obj) {
    return http.post('bnfcr/insCctvBdrmInf', obj)
}

//CCTV침실촬영동의 삭제
function delCctvBdrmInf(obj) {
    return http.post('bnfcr/delCctvBdrmInf', obj)
}

//수급자 CCTV침실촬영동의 전체 목록 조회
function selAllBnfcrCctvList(code, year) {
    return http.post('bnfcr/selAllBnfcrCctvList', {fcltyNum:code, agrYyyy:year})
}

//전체 수급자 CCTV침실촬영동의 전자발송 조회
function selAllBnfcrCctvSndList(code, year) {
    return http.post('bnfcr/selAllBnfcrCctvSndList', {fcltyNum:code, agrYyyy:year})
}

//수급자 CCTV침실촬영동의 일괄등록
function insAllCctvBdrmInf(obj) {
    return http.post('bnfcr/insAllCctvBdrmInf', obj)
}

//전체 본인부담금 미납금액 다건조회 (미납관리)
function selAllCopayClmUnpdList(obj) {
    return http.post('bnfcr/selAllCopayClmUnpdList', obj)
}
//본인부담금 초기 미납금(식사) 조회
function selCopayInitUnpdList(code) {
    return http.post('bnfcr/selCopayInitUnpdList', { fcltyNum: code})
}
//본인부담금 초기 선납금 조회
function selCopayInitPrpymList(code) {
    return http.post('bnfcr/selCopayInitPrpymList', { fcltyNum: code})
}
//본인부담금 초기 미납금(식사) 저장
function setCopayClmHis(obj) {
    return http.post('bnfcr/insCopayClmHis', obj)
}
//본인부담금 초기 미납금(식사) 다건 저장
function setCopayClmHisMulti(obj) {
    return http.post('bnfcr/insCopayClmHisMulti', obj)
}
//수급자기본정보현황 체크
function selBnfcrSttChkInfo(obj) {
    return http.post('bnfcr/selBnfcrSttChkInfo', obj)
}

//수급자정보 연락처 일자별 입소현황 다건 조회
function selBnfcrCntctSttList(obj) {
    return http.post('bnfcr/selBnfcrCntctSttList', obj)
}

//전체 수급자 계약현황 다건 조회(코드포함)
function selAllBnfcrCntrc(obj) {
    return http.post('bnfcr/selAllBnfcrCntrc', obj)
}

//전체 수급자 계약현황 다건 조회
function selAllBnfcrCntrcList(obj) {
    return http.post('bnfcr/selAllBnfcrCntrcList', obj)
}

//수급자 계약현황 조회
function selBnfcrCntrcSttsInfo(obj) {
    return http.post('bnfcr/selBnfcrCntrcSttsInfo', obj)
}

//입금처리내역 저장
function insDpsPrcHis(obj) {
    return http.post('bnfcr/insDpsPrcHis', obj)
}

//입금처리내역 삭제
function delDpsPrcHis(obj) {
    return http.post('bnfcr/delDpsPrcHis', obj)
}

// 퇴소정산 처리
function uptLvCalcDps(obj) {
    return http.post('bnfcr/uptLvCalcDps', obj)
}

//연계기록지 목록 조회(보고서)
function selBtcLinkRcrdList(obj) {
    return http.post('bnfcr/selBtcLinkRcrdList', obj)
}

//수급자 구성원 고유번호 체크 (fcltyNum, bnMmbrPk, inqYMD, inqYYYY, inqYYMM)
function chkBnfcrPkInfo(obj) {
    return http.post('bnfcr/chkBnfcrPkInfo', obj)
}

//수급자 구성원 고유번호 조회
function selBnfcrPkList(code, mmbrPk) {
    return http.post('bnfcr/selBnfcrPkList', { fcltyNum: code, bnMmbrPk: mmbrPk })
}

//현금영수증처리 다건 조회
function selCashBillHisIndvList(obj) {
    return http.post('bnfcr/selCashBillHisIndvList', obj)
}

//보호자 계정 생성
function insFmlyAcnt(obj) {
    return http.post('bnfcr/insFmlyAcnt', obj)
}

export {
    bnfcrList,
    galFmlyList,
    fmlyList,
    bnfcrInfoDetail,
    setBnfcrFull,
    delBnfcrInfo,
    bnfcrCntrcDetail,
    bnfcrCntrcAgr,
    bnfcrCntrcCnfmDetail,
    getPaymntFeeInf,
    getBnftFeeInfo,
    setBnfcrCntrc,
    setCntrcCnfm,
    setCntrcCnfmItm,
    delCntrcCnfmItm,
    getBnfcrBasicInfo,
    setBnfcrCntrcAgr,
    setBnfcrCntrcEld,
    setBnfcrCntrcSft,
    selLvLinkList,
    sellvInfList,
    sellinkRcrdList,
    selLvInf,
    insLvInf,
    delLvInf,
    sellinkRcrdInfo,
    insLinkRcrd,
    delLinkRcrd,
    getBnfcrCntrcHisList,
    reEntBnfcr,
    getCnslnHisList,
    setCnslnHis,
    getCnslnHisQrtList,
    getCnslnHisInf,
    delCnslnHis,
    selOutHisList,
    selOutHisInfo,
    insOutHis,
    delOutHis,
    insOutHisClncRec,
    selFamlLtrList,
    selFamlLtrInfo,
    insfamlLtr,
    delfamlLtr,
    insfamlLtrGrt,
    selFamlLtrPrtInfo,
    selAllBnfcrList,
    selHmnHltList,
    selCopayClmHisList,
    selCopayClmList,
    selCopayClmHisInfo,
    insCopayClmHis,
    updCopayClm,
    updMultiCopayClm,
    selMsclnFeeDtlList,
    insMsclnFeeDtl,
    delMsclnFeeDtl,
    insCopayClmCrt,
    updCopayClmHisCnfrm,
    updCnclCopayClmCnfrm,
    delCopayClmHis,
    selCopayClmCalcList,
    selCopayDpsStsList,
    selCopayDpsList,
    selCopayDpsPrcList,
    selCopayClmUnpdList,
    insCopayDpsHis,
    cnclCopayDps,
    selAllCopayClncList,
    updCopayDpsRfnd,
    selBnfcrCntctList,
    selCopayClmRmrkInfo,
    selCopayDpsCashBillList,
    insCopayDpsCashBill,
    selCashBillPopUpUrl,
    insCancelCashBill,
    insMultiCashBill,
    selCopayEsndList,
    updCopayDpsHis,
    selESndFmlyLeterList,
    updPreMCopayClm,
    selCctvBdrmList,
    selCctvBdrmInfo,
    insCctvBdrmInf,
    delCctvBdrmInf,
    selAllBnfcrCctvList,
    insAllCctvBdrmInf,
    selAllBnfcrCctvSndList,
    selAllCopayClmUnpdList,
    selCopayInitUnpdList,
    selCopayInitPrpymList,
    setCopayClmHis,
    setCopayClmHisMulti,
    selBnfcrSttChkInfo,
    selBnfcrCntctSttList,
    selAllBnfcrCntrc,
    selAllBnfcrCntrcList,
    selBnfcrCntrcSttsInfo,
    insDpsPrcHis,
    delDpsPrcHis,
    uptLvCalcDps,
    selBtcLinkRcrdList,
    chkBnfcrPkInfo,
    selBnfcrPkList,
    selCashBillHisIndvList,
    insFmlyAcnt,
}