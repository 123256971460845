<template>
    <v-form ref="authJoin" lazy-validation>
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>            
        </div>
        <div v-if="progress" style="height: 400px;">
            <v-row
                class="fill-height"
                align-content="center"
                justify="center"
            >
                <v-col
                class="text-subtitle-1 text-center"
                cols="12"
                >
                    <v-row  no-gutters>
                        <v-spacer></v-spacer>
                        <v-col cols="9" class="rounded-lg" style="padding:300px 50px;background-color: #faf8fd;">

                            <div class="d-flex justify-center align-center">
                                <v-icon color="rgb(251, 140, 0)" size="40">mdi-alert-outline</v-icon>
                                <h1 class="pl-4">잠시만 기달려주세요.</h1>                            
                                <h1 class="pl-4" style="color:red">페이지를 이동하지 마세요.</h1>
                            </div>
                            <div class="mt-3">
                                <v-progress-linear
                                    color="blue accent-3"
                                    indeterminate
                                    rounded
                                    height="6"
                                ></v-progress-linear>                         
                            </div>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                </v-col>
            </v-row>
        </div>
        <v-row v-else class="ma-0 pa-0" no-gutters>
            <v-spacer></v-spacer>
            <v-col class="" cols="9">
                <div class="d-flex">
                    <span
                        class="d-inline-block rounded-lg rounded-r-0 elevation-7 py-6 px-7"
                        style="width: 50%; height: 740px; background-color: #faf8fd; position: relative;">
                        
                        <v-row class="ma-0 pa-0" no-gutters>
                            <v-col class="d-flex align-center" cols="12">
                                <v-icon class="mr-4" style="padding-top: 2px;" color="grey006" size="26">mdi-circle-outline</v-icon>
                                <span class="font-weight-bold grey006--text" style="font-size: 1.8rem;">기관정보 입력</span>
                            </v-col>
                        </v-row>

                        <v-row class="mt-7 pa-0" no-gutters>
                            <v-col class="d-flex justify-center px-10" cols="12">
                                <v-text-field
                                    v-model="$parent.$parent.$parent.mmbrInfo.fcltyClnm"
                                    class="authTxtField rgstrWhClr" hint="요양시설 이름" label="기관명*"
                                    :rules="[rules.required]"
                                    outlined>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-2 pa-0" no-gutters>
                            <v-col class="d-flex justify-center px-10" cols="12">
                                <v-text-field
                                    v-model="$parent.$parent.$parent.mmbrInfo.fcltyNum"
                                    class="authTxtField rgstrWhClr" hint="'-' 를 제외한 11자리 숫자만 입력해주세요." label="기관번호*"
                                    :rules="[rules.required, rules.fcltyConfirm, rules.numberCheck]"
                                    :error="dplChcRes == '1' ? true : false"
                                    :append-icon="dplChcRes == '1' ? 'mdi-close-circle-outline' : 'mdi-check-circle-outline'"
                                    counter outlined>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-2 pa-0" no-gutters>
                            <v-col class="d-flex align-center px-10" cols="12">
                                <span class="d-inline-block pl-1" style="width: 30%;">
                                    <span style="font-size: 1.2rem;">시설구분(*):</span>
                                </span>
                                <span class="d-inline-block" style="width: 70%;">
                                    <v-select
                                        v-model="$parent.$parent.$parent.mmbrInfo.fcltyClcd"
                                        class="rgstrSlt" :items="fcltyCode"
                                        item-text="valcdnm" item-value="valcd" :rules="[rules.required]"
                                        dense outlined hide-details>
                                    </v-select>
                                </span>
                            </v-col>
                        </v-row>
                        <v-row class="mt-8 pa-0" no-gutters>
                            <v-col class="d-flex align-center px-10" cols="12">
                                <v-text-field
                                    v-model="$parent.$parent.$parent.mmbrInfo.bsnsRgst"
                                    class="authTxtField rgstrWhClr" hint="'-' 를 제외한 10자리 숫자만 입력해주세요." label="사업자 번호*"
                                    :rules="[rules.required, rules.bsnsConfirm, rules.numberCheck]"
                                    counter outlined>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-2 pa-0" no-gutters>
                            <v-col class="d-flex align-center px-10" cols="12">
                                <v-text-field
                                    v-model="$parent.$parent.$parent.mmbrInfo.fcltyTel1"
                                    class="authTxtField rgstrWhClr" hint="지역번호" label="전화번호"
                                    :rules="[rules.numberCheck]"
                                    hide-details outlined>
                                </v-text-field>
                                <v-icon class="mx-1" size="16">mdi-minus</v-icon>
                                <v-text-field
                                    v-model="$parent.$parent.$parent.mmbrInfo.fcltyTel2"
                                    class="authTxtField rgstrWhClr" hint="번호"
                                    :rules="[rules.numberCheck]"
                                    hide-details outlined>
                                </v-text-field>
                                <v-icon class="mx-1" size="16">mdi-minus</v-icon>
                                <v-text-field
                                    v-model="$parent.$parent.$parent.mmbrInfo.fcltyTel3"
                                    class="authTxtField rgstrWhClr" hint="번호"
                                    :rules="[rules.numberCheck]"
                                    hide-details outlined>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-8 pa-0" no-gutters>
                            <v-col class="d-flex align-center px-10" cols="12">
                                <span class="d-inline-block" style="width: 66px;">
                                    <v-text-field
                                        v-model="$parent.$parent.$parent.mmbrInfo.zip"
                                        class="authTxtField rgstrWhClr"
                                        :rules="[rules.numberCheck]"
                                        readonly hide-details outlined>
                                    </v-text-field>
                                </span>
                                <span class="ml-1">
                                    <v-btn
                                        class="" color="#3C9099" style="padding:0px 1px 0px 2px !important"
                                        min-width="40" height="40" @click="$parent.$parent.$parent.menu.splice(0, 1, true)"
                                        dark small>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon size="20" v-bind="attrs" v-on="on">mdi-map-search-outline</v-icon>
                                            </template>
                                            <span>주소검색</span>
                                        </v-tooltip>
                                    </v-btn>
                                </span>
                                <span class="d-inline-block ml-2" style="width: 48%;">
                                    <v-text-field
                                        v-model="$parent.$parent.$parent.mmbrInfo.addr"
                                        class="authTxtField rgstrWhClr"
                                        readonly hide-details outlined>
                                    </v-text-field>
                                </span>
                                <span class="d-inline-block ml-1" style="width: 23%">
                                    <v-text-field
                                        v-model="$parent.$parent.$parent.mmbrInfo.addrDtl"
                                        class="authTxtField rgstrWhClr"
                                        hide-details outlined>
                                    </v-text-field>
                                </span>
                            </v-col>
                        </v-row>
                        <v-row class="mt-8 pa-0" no-gutters>
                            <v-col class="d-flex align-center px-10" cols="12">
                                <!-- <span class="d-inline-block" style="width: 100%;">
                                    <v-text-field
                                        v-model="$parent.$parent.$parent.mmbrInfo.ceo"
                                        class="authTxtField rgstrWhClr" label="대표자명" hint="요양시설 대표자"
                                        outlined>
                                    </v-text-field>
                                </span> -->
                                <span class="d-inline-block pl-1" style="width: 30%;">
                                    <span style="font-size: 1.2rem;">유입경로(*):</span>
                                </span>
                                <span class="d-inline-block" style="width: 70%;">
                                    <v-select
                                        v-model="$parent.$parent.$parent.mmbrInfo.inflRout"
                                        class="rgstrSlt" :items="$parent.$parent.$parent.inflCode"
                                        item-text="valcdnm" item-value="valcd" :rules="[rules.required]"
                                        dense outlined hide-details>
                                    </v-select>
                                </span>
                            </v-col>
                        </v-row>
                        <!-- <v-row class="mt-4 mb-4 pa-0" no-gutters>
                            <v-col class="d-flex align-center px-10" cols="12">
                            </v-col>
                        </v-row> -->
                        <v-row class="mt-7 pa-0" no-gutters>
                            <v-col class="px-10" cols="12">
                                <v-btn class="py-6" width="100%" color="#3688c1" @click="$parent.$parent.$parent.authStep = 1">
                                    <span class="white--text" style="font-size: 1.3rem;">이전 단계</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </span>
                    <span
                        class="d-inline-block rounded-lg rounded-l-0 elevation-7 py-6 px-7"
                        style="width: 50%; height: 740px; background-color: white; position: relative;">
                        
                        <v-row class="ma-0 pa-0" no-gutters>
                            <v-col class="d-flex align-center" cols="12">
                                <v-icon class="mr-4" style="padding-top: 2px;" color="grey006" size="26">mdi-circle-outline</v-icon>
                                <span class="font-weight-bold grey006--text" style="font-size: 1.8rem;">
                                    관리자 정보 입력
                                </span>
                            </v-col>
                        </v-row>
                        <v-row class="mt-7 pa-0" no-gutters>
                            <v-col class="d-flex justify-center px-10" cols="12">
                                <v-text-field
                                    v-model="$parent.$parent.$parent.mmbrInfo.id"
                                    class="authTxtField rgstrWhClr" hint="2자리 이상 한글, 영문, 숫자만 입력 가능합니다." label="아이디*"
                                    :rules="[rules.required, rules.strIdSize, rules.regExp]"
                                    outlined>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-2 pa-0" no-gutters>
                            <v-col class="d-flex justify-center px-10" cols="12">
                                <v-text-field
                                    :class="['authTxtField', pswdShow[0]==true ? 'authTxtFieldPwdIcon' : '' ]"
                                    v-model="$parent.$parent.$parent.mmbrInfo.passwd"
                                    :append-icon="pswdShow[0] ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="pswdShow[0] ? 'text' : 'password'"
                                    @click:append="pswdShow.splice(0, 1, !pswdShow[0])" @input="$parent.$parent.$parent.mmbrInfo.passwdChk=''"
                                    :rules="[rules.required, rules.passwdReq]"
                                    label="비밀번호*" outlined>
                                </v-text-field>
                                <span class="mx-1">&nbsp;</span>
                                <v-text-field
                                    :class="['authTxtField', pswdShow[1]==true ? 'authTxtFieldPwdIcon' : '' ]"
                                    v-model="$parent.$parent.$parent.mmbrInfo.passwdChk"
                                    :append-icon="pswdShow[1] ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="pswdShow[1] ? 'text' : 'password'"
                                    @click:append="pswdShow.splice(1, 1, !pswdShow[1])"
                                    :rules="[confirmPasswd]"
                                    label="비밀번호 확인*" outlined>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-2 pa-0" no-gutters>
                            <v-col class="d-flex align-center justify-center px-10" cols="12">
                                <span class="d-inline-block" style="width: 50%">
                                    <v-text-field
                                        v-model="$parent.$parent.$parent.mmbrInfo.name"
                                        class="authTxtField rgstrWhClr" hint="2자리 이상만 입력 가능합니다." label="이름*"
                                        :rules="[rules.required, rules.strIdSize]"
                                        outlined>
                                    </v-text-field>
                                </span>
                                <span class="mx-1">&nbsp;</span>
                                <span class="d-inline-block pb-8" style="width: 49%">
                                    <v-radio-group
                                        v-model="$parent.$parent.$parent.mmbrInfo.gndr" class="radio-dft-Theme mt-0 pl-2 pt-1"
                                        :rules="[rules.required]" ref="info" hide-details row>
                                        <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="남" value="M"></v-radio>
                                        <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="여" value="F"></v-radio>
                                    </v-radio-group>
                                </span>
                            </v-col>
                        </v-row>
                        <v-row class="mt-2 pa-0" no-gutters>
                            <v-col class="d-flex align-center px-10" cols="12">
                                <span class="d-inline-block" style="width: 49%;">
                                    <v-menu
                                        ref="menu0" v-model="menu[0]"
                                        :close-on-content-click="false" :return-value.sync="$parent.$parent.$parent.mmbrInfo.rsdntBirth"
                                        transition="scale-transition" offset-y min-width="auto" dense>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                class="rfstrDatePicker" label="생년월일"
                                                v-model="$parent.$parent.$parent.mmbrInfo.rsdntBirth"
                                                readonly outlined dense v-bind="attrs" v-on="on">
                                            </v-text-field>
                                        </template>
                                        <v-date-picker :key="forceRender"
                                            v-model="$parent.$parent.$parent.mmbrInfo.rsdntBirth" no-title scrollable>
                                            <v-spacer></v-spacer>
                                            <v-btn class="font-weight-bold" color="blueBtnColor" text @click="datePickClose(0)">Cancel</v-btn>
                                            <v-btn class="font-weight-bold" color="blueBtnColor" text @click="$refs.menu0.save($parent.$parent.$parent.mmbrInfo.rsdntBirth)">OK</v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </span>
                            </v-col>
                        </v-row>
                        <v-row class="mt-2 pa-0" no-gutters>
                            <v-col class="d-flex align-center px-10" cols="12">
                                <span class="d-inline-block" style="width: 49%;">
                                    <v-menu
                                        ref="menu1" v-model="menu[1]"
                                        :close-on-content-click="false" :return-value.sync="$parent.$parent.$parent.mmbrInfo.birth"
                                        transition="scale-transition" offset-y min-width="auto" dense>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                class="rfstrDatePicker" label="생일"
                                                v-model="$parent.$parent.$parent.mmbrInfo.birth"
                                                readonly outlined dense v-bind="attrs" v-on="on">
                                            </v-text-field>
                                        </template>
                                        <v-date-picker :key="forceRender"
                                            v-model="$parent.$parent.$parent.mmbrInfo.birth" no-title scrollable>
                                            <v-spacer></v-spacer>
                                            <v-btn class="font-weight-bold" color="blueBtnColor" text @click="datePickClose(1)">Cancel</v-btn>
                                            <v-btn class="font-weight-bold" color="blueBtnColor" text @click="$refs.menu1.save($parent.$parent.$parent.mmbrInfo.birth)">OK</v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </span>
                                <span class="mx-1">&nbsp;</span>
                                <span class="d-inline-block pb-8" style="width: 49%">
                                    <v-radio-group
                                        v-model="$parent.$parent.$parent.mmbrInfo.lnrYn" class="radio-dft-Theme mt-0 pl-2 pt-1"
                                        hide-details row>
                                        <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="양력" value="1"></v-radio>
                                        <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="음력" value="2"></v-radio>
                                    </v-radio-group>
                                </span>
                            </v-col>
                        </v-row>
                        <v-row class="mt-2 pa-0" no-gutters>
                            <v-col class="d-flex align-center px-10" cols="12">
                                <v-text-field
                                    v-model="$parent.$parent.$parent.mmbrInfo.celphn_1"
                                    class="authTxtField rgstrWhClr" hint="번호" label="휴대폰 번호*"
                                    :rules="[rules.required, rules.numberCheck, rules.numberSizeCheck]"
                                    hide-details outlined>
                                </v-text-field>
                                <v-icon class="mx-1" size="16">mdi-minus</v-icon>
                                <v-text-field
                                    v-model="$parent.$parent.$parent.mmbrInfo.celphn_2"
                                    class="authTxtField rgstrWhClr" hint="번호"
                                    :rules="[rules.required, rules.numberCheck, rules.numberSizeCheck]"
                                    hide-details outlined>
                                </v-text-field>
                                <v-icon class="mx-1" size="16">mdi-minus</v-icon>
                                <v-text-field
                                    v-model="$parent.$parent.$parent.mmbrInfo.celphn_3"
                                    class="authTxtField rgstrWhClr" hint="번호"
                                    :rules="[rules.required, rules.numberCheck, rules.numberSizeCheck]"
                                    hide-details outlined>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-8 pa-0" no-gutters>
                            <v-col class="d-flex align-center px-10" cols="12">
                                <span class="d-inline-block" style="width: 29%;">
                                    <v-text-field
                                        v-model="$parent.$parent.$parent.mmbrInfo.emailId"
                                        class="authTxtField rgstrWhClr" label="이메일*"
                                        :rules="[rules.required]"
                                        hide-details outlined>
                                    </v-text-field>
                                </span>
                                <span class="d-inline-block d-flex justify-center align-center mx-2" style="width: 28px; height: 28px;">
                                    <v-icon size="22" style="color: #dbdbdb;">mdi-at</v-icon>
                                </span>
                                <span class="d-inline-block" style="width: 30%;">
                                    <v-text-field
                                        v-model="$parent.$parent.$parent.mmbrInfo.emailDmn"
                                        class="authTxtField rgstrWhClr" label="도메인"
                                        :rules="[rules.required]" :disabled="$parent.$parent.$parent.mmbrInfo.emailDmnSlt != '0'"
                                        hide-details outlined>
                                    </v-text-field>
                                </span>
                                <span class="d-inline-block ml-1" style="width: 29%;">
                                    <v-select
                                        v-model="$parent.$parent.$parent.mmbrInfo.emailDmnSlt"
                                        class="rgstrSlt" :items="$parent.$parent.$parent.emailCode" @change="emailSelected"
                                        item-text="valcdnm" item-value="valcd"
                                        dense outlined hide-details>
                                    </v-select>
                                </span>
                            </v-col>
                        </v-row>
                        <v-row class="mt-7 pa-0" no-gutters>
                            <v-col class="px-10" cols="12">
                                <v-btn class="py-6" width="100%" color="#2a9bed" @click="preMmbrInfo()">
                                    <span class="white--text" style="font-size: 1.3rem;">등록</span>
                                </v-btn>
                            </v-col>
                        </v-row>

                    </span>
                </div>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        
    </v-form>
</template>

<script>
import { http } from '@/api/baseAxios';
import VueJwtDecode from 'vue-jwt-decode'

export default {
    name: 'RgstrMmbr',

    components: {
     
    },

    computed:{
        getFcltyNum: function(){
            return this.$parent.$parent.$parent.mmbrInfo.fcltyNum
        }
    },

    watch:{
        getFcltyNum: function(value){
            if(value.length == 11)
                this.dplChc()
            else
                this.dplChcRes = '1'
        },
    },

    created: function(){
    
    },

    mounted: function(){
        this.$nextTick(function () {

        })
    },

    methods: {
        setMmbrInfo: function (obj){
            this.progress = true
            http.post('auth/fcltyJoin', obj)
                .then((response) => ( this.axiosResult(response.data) ))
                .catch((error) => console.log('axios error /auth/fcltyJoin : ' + error))
        },
        dplChc: async function (){
            await http.post('auth/selFcltyDplctSrch', { fcltyNum: this.$parent.$parent.$parent.mmbrInfo.fcltyNum })
                .then((response) => ( this.dplChcRes = response.data.result ))
                .catch((error) => console.log('axios error /auth/selFcltyDplctSrch : ' + error))
        },
        axiosResult: function (res){
            // console.log(res)
            if(res.statusCode == '200'){
                // this.$router.go(0) 로그인페이지 이동
                /* 메인페이지 이동 */
                let jwtDecode = null

                //로그인 인증 토큰 복호화
                let bytes = this.$crypto.AES.decrypt(res.result.token, this.$crypto.enc.Hex.parse(res.result.key), {
                    iv: this.$crypto.enc.Hex.parse("000102030405060708090A0B0C0D0E0F"),
                    padding: this.$crypto.pad.Pkcs7,
                    mode: this.$crypto.mode.CBC
                })

                //토큰정보를 얻어옴
                jwtDecode = VueJwtDecode.decode(bytes.toString(this.$crypto.enc.Utf8))

                //토큰정보를 vuex에 저장
                this.$store.commit('setAuthToken', bytes.toString(this.$crypto.enc.Utf8))
                this.$store.commit('setFcltyNum', jwtDecode.fcltyNum)
                this.$store.commit('setUserNm', decodeURI(jwtDecode.name))
                this.$store.commit('setSsMmbrPk', jwtDecode.mmbrPk)
                this.$store.commit('setExpTime', jwtDecode.exp)
                this.$store.commit('setJobNm', decodeURI(jwtDecode.jobNm))
                this.$store.commit('setNtyAct', true)

                //권한정보를 조회
                if(res.result.auths != null){
                    //권한정보를 vuex에 저장

                    this.$store.commit('setAcesAthrt', res.result.auths)

                    //페이지 이동
                    if(res.result.auths.length > 0)
                        this.$router.push({ name: res.result.auths[0].viewId })
                    else
                        this.$parent.$parent.$parent.snackControll([true, 5000, '이동할 페이지 정보가 없습니다.', 'error'])
                }  
                /* 메인페이지 이동 */
                this.progress = false
            } else {
                this.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])
                this.progress = false
            }
        },
        preMmbrInfo: function (){
            let obj = Object.assign({}, this.$parent.$parent.$parent.mmbrInfo)
            obj.ceo = obj.name
            obj.userNm = obj.name
            obj.birth = obj.birth.afterDateFormatHyp()
            obj.rsdntBirth = obj.rsdntBirth.afterDateFormatHyp()
            
            if(this.$refs.authJoin.validate())
                this.setMmbrInfo(obj)
            else
                this.snackControll([true, 2500, '필수 항목을 입력해주세요.', 'warning'])
        },
        confirmPasswd: function(v){
            if (!v) {
                return '필수입력 항목 입니다.';
            } 
            else if(v !== this.$parent.$parent.$parent.mmbrInfo.passwd )
                return '비밀번호가 일치하지 않습니다.'

            return true
        },
        dplChcRule: function(v){

            if(v.length == 11){
                if(this.dplChcRes == '1')
                    return '등록된 기관번호 입니다.'
                else
                    return '등록 가능한 기관번호 입니다.'
            }
            else if (this.dplChcData == '0') {
                return '중복검사를 진행해주세요';
            }
        },
        emailSelected: function () {
            if(this.$parent.$parent.$parent.mmbrInfo.emailDmnSlt == '0')
                this.$parent.$parent.$parent.mmbrInfo.emailDmn = ''
            else {
                this.$parent.$parent.$parent.emailCode.forEach(cd => {
                    if(this.$parent.$parent.$parent.mmbrInfo.emailDmnSlt == cd.valcd)
                        this.$parent.$parent.$parent.mmbrInfo.emailDmn = cd.valcdnm
                });
            }
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        progress:false,
        menu: [ false, false, ],
        dplChcData: 0,
        dplChcRes: '1',
        forceRender: 0,
        pswdShow: [ false, false, ],
        fcltyCode: [
            { valcd: '1', valcdnm: '노인요양시설' }
        ],
        rules: {
            fcltyConfirm: value => value.length == 11 || '잘못된 기관코드 입니다.',
            bsnsConfirm: value => value.length == 10 || '잘못된 사업자 번호 입니다.',
            numberCheck: value => /^[0-9]*$/.test(value) || '숫자만 입력 가능합니다.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            required: value => !!value || '필수입력 항목 입니다.',
            strIdSize: value => value.length > 1 || '2자리 이상 입력해주세요.',
            regExp: value => !(/[^\w\sㄱ-힣()0-9 ]/g.test(value)) || '특수문자는 입력이 불가능 합니다.',
            passwdReq: value => value.length > 3 || '비밀번호는 4자리 이상 입니다.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>

<style>
    .rgstrSlt .v-input__control { background-color: white; }
    .rgstrSlt .v-input__control .v-input__slot { min-height: 46px !important; padding-left: 18px !important; padding-right: 12px !important; }
    .rgstrSlt .v-input__control .v-input__slot .v-select__slot .v-input__append-inner { margin-top: 10px !important; }
    .rgstrWhClr .v-input__control .v-input__slot fieldset { background: white; }
    .rgstrRdGrp .v-input__control { width: 100%; }
    .rgstrRd label { padding-left: 10px; font-size: 1.1rem !important; }
    .rfstrDatePicker .v-input__control .v-input__slot { min-height: 46px !important; }
    .rfstrDatePicker .v-input__control .v-text-field__slot { overflow: initial; }
    .rfstrDatePicker .v-input__control .v-text-field__slot .v-label { font-size: 1.1rem; top: 10px !important; }
    .rfstrDatePicker .v-input__control .v-text-field__slot .v-label--active { font-size: 1.1rem; top: 2px !important; }
    .rfstrDatePicker .v-input__control .v-input__slot .v-input__append-inner .v-input__icon--append button {
        margin-top: 10px;
    }
    .rfstrDatePicker .v-input__control .v-input__slot .v-input__append-inner .v-input__icon--append button {
        margin-top: 14px !important;
    }
    .rgstrWhClr .v-input__control .v-input__slot .v-input__append-inner { padding-top: 8px !important; }
</style>