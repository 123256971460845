<template>
    <v-text-field
        v-model="sltTm" width="140" ref="cmmTimeField" height="28"
        :key="forceRender" :disabled="disabled" @keydown.enter="$emit('nxtFocus', '1')"
        :rules=" required == true ? [inRules.timeFrmCfm, tmChk] : (sltTm != '' ? [inRules.timeFrmCfm, tmChk] : [])"
        @input="chnTimeFrm" @focus="fldClr" hide-details outlined dense>
    </v-text-field>
</template>

<script>

export default {
    name: 'CmmTimeField',

    props : {
        value: { type: String, default: '' },
        required: { type: Boolean, default: false },
        minTm: { type: String, default: '' },
        maxTm: { type: String, default: '' },
        disabled: { type: Boolean, default: false },
        focusClear: { type: Boolean, default: false },
    },
            
    components: {

    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.value == null)
                this.sltTm = ''
            else
                this.sltTm = this.value
        })
    },

    computed: {

    },

    watch:{
        'value': function () {
            if(this.value == null)
                this.sltTm = ''
            else
                this.sltTm = this.value
        },
    },
        
    methods: {
        vmlRflt: function (v) {
            this.sltTm = v
            this.$emit('input', v)
        },
        chnTimeFrm: function () {
            let rst = ''
            
            if(this.sltTm != null)
                rst = this.sltTm.replace(/\s/gi, "")

            if(rst.length == 4)
                rst = rst.replace(/(\d{2})(\d{2})/, '$1:$2')


            if(/^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(rst))
                this.vmlRflt(rst)
            else
                this.vmlRflt(rst.replaceAll(':', ''))

        },
        fldClr: function () {
            if(this.focusClear){
                this.sltTm = ''
                this.$emit('input', this.sltTm)
            }
        },
        tmChk: function () {
            let cvtTm= 0
            
            if(/^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(this.sltTm)){
                cvtTm += Number(this.sltTm.substring(0, 2)) * 60
                cvtTm += Number(this.sltTm.substring(3, 5))

                if(/^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(this.maxTm)){
                    let cvtMax = 0
                    cvtMax += Number(this.maxTm.substring(0, 2)) * 60
                    cvtMax += Number(this.maxTm.substring(3, 5))

                    if(cvtTm <= cvtMax)
                        return true
                    else
                        return false
                }

                if(/^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(this.minTm)){
                    let cvtMin = 0
                    cvtMin += Number(this.minTm.substring(0, 2)) * 60
                    cvtMin += Number(this.minTm.substring(3, 5))

                    if(cvtTm >= cvtMin)
                        return true
                    else
                        return false
                }

                if(this.minTm == '' && this.maxTm == '')
                    return true
            }
            else
                return false
            
        },
    },
    
    data: () => ({
        sltTm: '',
        forceRender: 0,
        inRules: {
            timeFrmCfm: value => /^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(value) || 'Time Validate.',
        },
    }),
};
</script>