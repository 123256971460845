<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">   
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
             
            <v-snackbar
                v-model="altOptions[0]" :timeout="altOptions[1]" :value="true" class="pa-0"
                :color="altOptions[2]" absolute top>
                <v-alert                    
                    :type="altOptions[2]"
                >
                    <div class="text-h6 white--text font-weight-bold">{{altOptions[3]}}</div>
                    <div class="text-h6 white--text font-weight-bold" v-html="altOptions[4]"></div>
                </v-alert>
            </v-snackbar>
        </div>       
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">화장실이용기록</span>
            <v-spacer></v-spacer> 
            <span v-if="progress">
                <v-progress-circular            
                :size="25"
                color="primary"
                indeterminate
                ></v-progress-circular>
            </span>
            <span v-if="!progress">              
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn v-if="list.type == 'action' && list.visible" @click = "onClickBtn(list.text)"
                        :class="list.class" height="30" :color="list.color" rounded
                        :disabled="list.disabled"
                        style="font-size: 14px;">
                        <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                        {{list.text}}
                    </v-btn>
                </span>
            </span>
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <v-form ref="tabform" lazy-validation>
            <v-row class="pa-0 my-2" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1"> 
                    <span class="black--text font-weight-medium">기록일</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="3">  
                    <span class="white d-inline-block pa-1" style="width: 110px;">   
                        {{inqYMD?.beforeDateFormatDot()}}                        
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1">
                    <span class="black--text font-weight-medium">배설방법</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="7">  
                    {{exrtSt}}
                </v-col>
            </v-row>
            <basicInfo :bnMmbrPk="bnMmbrPk"></basicInfo>
            <div class="d-flex align-center mt-2">
                <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                <span class="text-h6 font-weight-bold">배설 관찰기록</span>
            </div>
            <v-row class="pa-0 ma-0" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2"> 
                    <span class="black--text font-weight-medium">대변</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">                             
                    <span style="width:60px" class="ml-1">
                        <v-text-field v-model="recItems1.itm1Val" height="28" type="number" min="0" hide-details outlined dense></v-text-field>
                    </span>               
                    <span class="ml-1">회</span>         
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2"> 
                    <span class="black--text font-weight-medium">소변</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">                             
                    <span style="width:60px" class="ml-1">
                        <v-text-field v-model="recItems1.itm2Val" height="28" type="number" min="0" hide-details outlined dense></v-text-field>
                    </span>     
                    <span class="ml-1">회</span>                            
                </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2"> 
                    <span class="black--text font-weight-medium">설사</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">                             
                    <span style="width:60px" class="ml-1">
                        <v-text-field v-model="recItems1.itm3Val" height="28" type="number" min="0" hide-details outlined dense></v-text-field>
                    </span>  
                    <span class="ml-1">회</span>         
                </v-col>
            </v-row> 
            <div class="d-flex mt-2">
                               
                <div v-if="cathYn == '1'" class="d-inline-block pa-0 ma-0 align-self-start" style="width:443px;">                    
                    <div class="d-flex align-center mt-2">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">소변량 기록&#40;유치도뇨관&#41;</span>
                    </div>
                    <v-row class="pa-0 mt-2" style="width:433px;border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2"> 
                            <span class="black--text font-weight-medium">횟수</span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="4" style="border-left: 1px solid #dbdbdb !important;"> 
                            <span class="black--text font-weight-medium">소변시간</span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="4" style="border-left: 1px solid #dbdbdb !important;"> 
                            <span class="black--text font-weight-medium">소변량</span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">                             
                            <v-btn icon class="mr-1" color="blue200" height="26" @click="addRow('rec1')">
                                <v-icon>mdi-plus-circle-outline</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <div style="height:450px;overflow-y:auto;">
                        <div v-if="recItems2.listDtl.length > 0">
                            <v-row v-for="(item, i) in recItems2.listDtl" :key="i" class="pa-0 ma-0" style="width:433px;border-bottom: 1px solid #dbdbdb;" no-gutters>
                                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2"> 
                                    <span class="black--text font-weight-medium">{{i+1}}</span>
                                </v-col>
                                <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="4" style="border-left: 1px solid #dbdbdb !important;"> 
                                    <span class="white d-inline-block pa-1" style="width: 80px;"> 
                                        <CmmTimeField v-model="item.ofrTmStrt" :required="false" @input="getTltCalcl"></CmmTimeField>
                                    </span>
                                </v-col>
                                <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="4" style="border-left: 1px solid #dbdbdb !important;"> 
                                    <span style="width:80px" class="ml-1">
                                        <v-text-field v-model="item.itm1Val" height="28" type="number" hide-details outlined dense></v-text-field>
                                    </span> 
                                </v-col>
                                <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">                                 
                                    <v-btn  icon color='grey006' @click="delRow('rec1',i)"> 
                                        <v-icon v-if="item.bnftOfrRec301Pk > 0">mdi-trash-can-outline</v-icon>                                           
                                        <v-icon v-else>mdi-minus-circle-outline</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>
                        <div v-else class="d-flex pa-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%">                                
                                데이터가 없습니다.
                            </div> 
                        </div>
                    </div>
                </div>
                <div v-if="dprYn == '1'" class="d-inline-block pl-2 ma-0 align-self-start" style="width:900px;">
                    <div class="d-flex align-center mt-2">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">기저귀 교체 및 확인 기록</span>
                    </div>
                    <div class="d-flex pa-0 mt-2" style="width:880px;border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:7%;"> 
                            <span class="black--text font-weight-medium">횟수</span>
                        </div>
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:15%;border-left: 1px solid #dbdbdb !important;"> 
                            <span class="black--text font-weight-medium">시간</span>
                        </div>
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:27%;border-left: 1px solid #dbdbdb !important;"> 
                            <span class="black--text font-weight-medium">배설구분</span>
                        </div>
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:17%;border-left: 1px solid #dbdbdb !important;"> 
                            <span class="black--text font-weight-medium">처리구분</span>
                        </div>
                        <div style="width:27%;border-left: 1px solid #dbdbdb !important;"> 
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:100%">
                                <span class="black--text font-weight-medium">
                                    담당자 선택
                                </span>
                                <v-btn              
                                    class="pt-1"           
                                    icon     
                                    color="grey lighten-1"             
                                    @click="onModal(0)"
                                    >
                                    <v-icon size="20">mdi-magnify</v-icon>
                                </v-btn>
                            </div>
                            <div class="greyE01 d-flex pa-1 ma-0" style="width:100%;border-top: 1px solid #dbdbdb !important;">
                                <div>
                                    <v-chip 
                                        v-for="(list, k) in mmbrList" :key="k"     
                                        class="pa-2 mr-1"    
                                        label            
                                        color="blue" 
                                        outlined                           
                                        small        
                                        style="background-color: white !important;"                                                          
                                        >
                                        {{list.wrtr}}
                                    </v-chip>
                                </div>
                            </div>
                        </div>
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:7%;border-left: 1px solid #dbdbdb !important;">                             
                            <v-btn icon class="mr-1" color="blue200" height="26" @click="addRow('rec2')">
                                <v-icon>mdi-plus-circle-outline</v-icon>
                            </v-btn>
                        </div>
                    </div>
                    <div style="height:405px;overflow-y:auto;">
                        <div v-if="recItems3.listDtl.length > 0">
                            <div v-for="(item, j) in recItems3.listDtl" :key="j" class="d-flex pa-0 ma-0" style="width:880px;border-bottom: 1px solid #dbdbdb;" no-gutters>
                                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:7%;"> 
                                    <span class="black--text font-weight-medium">{{j+1}}</span>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:15%;border-left: 1px solid #dbdbdb !important;"> 
                                    <span class="white d-inline-block pa-1" style="width: 80px;"> 
                                        <CmmTimeField v-model="item.ofrTmStrt" :required="false"></CmmTimeField>
                                    </span>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:27%;border-left: 1px solid #dbdbdb !important;"> 
                                    <span style="width:60px;">
                                        <v-checkbox
                                            class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                            v-model="item.itm1Val" label="대변"
                                            hide-details value="1" @click="getTltCalcl">
                                        </v-checkbox>
                                    </span>
                                    <span style="width:60px;">
                                        <v-checkbox
                                            class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                            v-model="item.itm2Val" label="소변"
                                            hide-details value="1" @click="getTltCalcl">
                                        </v-checkbox>
                                    </span>
                                    <span style="width:60px;">
                                        <v-checkbox
                                            class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                            v-model="item.itm3Val" label="설사"
                                            hide-details value="1" @click="getTltCalcl">
                                        </v-checkbox>
                                    </span>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:17%;border-left: 1px solid #dbdbdb !important;"> 
                                    <div class="d-inline-block pl-4" style="width: 100%;">
                                        <v-radio-group
                                            class="radio-dft-Theme mt-0"
                                            v-model="item.itm4Val" :key="forceRender" hide-details row>
                                            <v-radio
                                                v-for="(list, k) in item.codeItem" :key="k" 
                                                class="pa-0 mr-1"                                     
                                                :ripple="false" 
                                                :label="list.valcdnm" 
                                                :value="list.valcd">                    
                                            </v-radio>  
                                        </v-radio-group>
                                    </div>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:27%;border-left: 1px solid #dbdbdb !important;"> 
                                    <div class="d-inline-block pl-1" style="width: 100%;">
                                        <v-radio-group
                                            class="radio-dft-Theme mt-0"
                                            v-model="item.wrtrMmbrPk" :key="forceRender" hide-details row>
                                            <v-radio 
                                                v-for="(list, m) in item.mmbrList" :key="m" 
                                                class="pa-0 mr-1"                                     
                                                :ripple="false" 
                                                :label="list.wrtr" 
                                                :value="list.wrtrpk">
                                            </v-radio>                                
                                        </v-radio-group>
                                    </div>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:7%;border-left: 1px solid #dbdbdb !important;">                                 
                                    <v-btn  icon color='grey006' @click="delRow('rec2',j)"> 
                                        <v-icon v-if="item.bnftOfrRec301Pk > 0">mdi-trash-can-outline</v-icon>                                           
                                        <v-icon v-else>mdi-minus-circle-outline</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                        <div v-else class="d-flex pa-0" no-gutters style="width:880px;border-bottom: 1px solid #dbdbdb !important;">
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%">                                
                                데이터가 없습니다.
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </v-form>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
                    <EmpSelector @isClose="isModal(0)" @outEmp="getEmpInfo" :prtWrtDt = "prtWrtDt"></EmpSelector>
            </v-dialog>  
        </div>               
    </v-sheet>
</template>
<script>
import CmmTimeField from '../commons/CmmTimeField.vue';
import basicInfo from '../bnfcr/BnfcrBasicInfo.vue';
import EmpSelector from '../commons/EmpSelector.vue';
import { getStcd } from '../../api/index.js';
import { insMultiBnftRcrd, selBnftOfrRecList, selPreBnftOfrRecList, delTitBnftOfrRec, delAllBnftOfrRec } from '../../api/bnftrcrd.js';

export default {
    name: 'TltUseRcrd',
    
    components: {  
        CmmTimeField,
        EmpSelector,
        basicInfo,
    },

    props : {
        exrtSt              : { type: String, default: '' },  
        inqYMD              : { type: String, default: '' },  
        bnMmbrPk            : { type: Number, default: -1 },
        tltUseRcrdPk        : { type: Number, default: -1 },
        empWrtr             : { type: String, default: '' }, 
        empWrtrpk           : { type: Number, default: -1 },  
        cathYn              : { type: String, default: '' },  
        dprYn               : { type: String, default: '' },  
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
        getSubActv: function () {
            return this.$store.getters.getSubActv
        },
    },

    created: function(){  
        this.onCodeload()
    },

    mounted: function(){
        this.$nextTick(function () {
            this.frmReset()
            this.onLoad()
        })
    },

    watch: {
        getSubEvt: function (bol) {
            if(bol && this.tltUseCnfm){
                this.onSave('1')
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
                this.tltUseCnfm = false
            }

            if(bol && this.rmTltUseCnfm){
                this.rmBnftOfrRec()
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
                this.rmTltUseCnfm = false
            }

            if(bol && this.rmAllTltUseCnfm){
                this.rmAllBnftOfrRec()
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
                this.rmAllTltUseCnfm = false
            }
        },

        getSubActv:function (bol){
            if(!bol && this.tltUseCnfm){
                this.frmReset()
                this.onLoad()
                this.tltUseCnfm = false
            }
        }
    },

    methods: {  
        onCodeload(){
            getStcd('86')
                .then((response) => ( this.codeMapping(response.data)))
                .catch((error) => console.log(error))
        },
        codeMapping(res){
            this.codeItem = res
        },      
        onLoad(){          
            this.recItems1.recDt = this.inqYMD?.beforeDateFormatHyp()

            if(this.empWrtr !=''){
                this.recItems1.wrtr = this.empWrtr
                this.recItems1.wrtrMmbrPk = this.empWrtrpk
                this.setMmbrList(this.empWrtr,this.empWrtrpk)
            }

            if(this.bnMmbrPk > 0 && this.inqYMD !=''){
                this.filledBtn[0].disabled = false 
                this.getData()                 
            }

        },
        reLoad(){
            this.getData() 
        },
        frmReset(){        
            this.prtWrtDt = ''
            this.mmbrList.splice(0)           
            this.$refs.tabform.resetValidation();
            this.filledBtn[0].visible = true
            this.filledBtn[2].disabled = true
            this.isPreData = false 
        },
        onClickBtn:function(text){
            if(text == "전일자료"){
                this.isPreData = true                
                this.getPreData()
            }else if(text == "저장"){
                this.onSave()
            }else if(text == "삭제"){
                let obj = {}
                obj.icon = 'mdi-alert'
                obj.iconClr = 'red'
                obj.title = '화장실이용기록'
                obj.cntnt = [this.$moment(this.inqYMD,'YYYYMMDD').format('YYYY.MM.DD')+'의 식사급여기록을(를) 삭제하시겠습니까?']
                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 삭제하겠습니다.'

                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });   

                this.rmAllTltUseCnfm = true
            }
        },        
        onSave:function(dupYn = '2'){
            
            if(this.bnMmbrPk > 0 && this.$refs.tabform.validate()){
                
                let obj = {
                    fcltyNum: this.$store.getters.getFcltyNum,
                    bnMmbrPk : this.bnMmbrPk,
                    userNm : this.$store.getters.getUserNm,
                    ignrYn      : dupYn,
                    hisList     : []
                }

                let hisObj1 = {                    
                    clsfc1Cd        : '301',
                    clsfc2Cd        : '3',
                    clsfc3Cd        : '3',
                    mdfDt           : this.recItems1.mdfDt,
                    recDt           : this.inqYMD,
                    wrtr            : this.recItems1.wrtr,
                    wrtrMmbrPk      : this.recItems1.wrtrMmbrPk,
                    spclNote        : this.recItems1.spclNote,
                    recList         : [
                        {
                            bnftOfrRec301Pk:this.recItems1.bnftOfrRec301Pk,
                            itm1Cd:'1',
                            itm1Val:this.recItems1.itm1Val,
                            itm2Cd:'2',
                            itm2Val:this.recItems1.itm2Val,
                            itm3Cd:'3',
                            itm3Val:this.recItems1.itm3Val,
                            wrtr:this.recItems1.wrtr,
                            wrtrMmbrPk:this.recItems1.wrtrMmbrPk,
                        }
                    ],
                }

                obj.hisList.push(hisObj1)

                let hisObj2 = {                    
                    mdfDt           : this.recItems2.mdfDt,
                    clsfc1Cd        : '301',
                    clsfc2Cd        : '3',
                    clsfc3Cd        : '4',
                    recDt           : this.inqYMD,
                    wrtr            : this.recItems1.wrtr,
                    wrtrMmbrPk      : this.recItems1.wrtrMmbrPk,
                    recList         : [],
                }


                this.recItems2.listDtl.forEach(x => {
                    if(x.ofrTmStrt != ''){

                        let recItem2 = {     
                            bnftOfrRec301Pk : x.bnftOfrRec301Pk,
                            ofrTmHhStrt     : "",
                            ofrTmMmStrt     : "",                    
                            itm1Cd          : '2',
                            itm1Val         : x.itm1Val,
                            wrtr            : this.recItems1.wrtr,
                            wrtrMmbrPk      : this.recItems1.wrtrMmbrPk,
                        }

                        let ofrTmStrt = x.ofrTmStrt.split(":")
                        recItem2.ofrTmHhStrt = ofrTmStrt[0]
                        recItem2.ofrTmMmStrt = ofrTmStrt[1]

                        hisObj2.recList.push(recItem2)
                    }
                });


                if(hisObj2.recList.length > 0) {
                    obj.hisList.push(hisObj2)                    
                }
                
                let hisObj3 = {                    
                    mdfDt           : this.recItems3.mdfDt,
                    clsfc1Cd        : '301',
                    clsfc2Cd        : '3',
                    clsfc3Cd        : '5',
                    recDt           : this.inqYMD,
                    wrtr            : this.recItems1.wrtr,
                    wrtrMmbrPk      : this.recItems1.wrtrMmbrPk,
                    wrtrGr          : [],
                    wrtrGrMmbrPk    : [],
                    recList         : [],
                }

                this.recItems3.listDtl.forEach(x => {
                    if(x.ofrTmStrt != ''){

                        let recItem3 = {  
                            bnftOfrRec301Pk:x.bnftOfrRec301Pk,                               
                            ofrTmHhStrt : '',
                            ofrTmMmStrt : '',
                            wrtr        : '',
                            wrtrMmbrPk  : x.wrtrMmbrPk,                
                            itm1Cd      : '1',
                            itm1Val     : '',
                            itm2Cd      : '1',
                            itm2Val     : '',
                            itm3Cd      : '1',
                            itm3Val     : '',
                            itm4Cd      : '86',
                            itm4Val     : x.itm4Val,
                        }

                        let ofrTmStrt = x.ofrTmStrt.split(":")
                        recItem3.ofrTmHhStrt = ofrTmStrt[0]
                        recItem3.ofrTmMmStrt = ofrTmStrt[1]

                        if(x.wrtrMmbrPk != '' && x.wrtrMmbrPk >0){
                            let mmbrInfo = this.mmbrList.filter(e=> e.wrtrpk == x.wrtrMmbrPk)
                            
                            if(mmbrInfo[0] !=undefined){
                                recItem3.wrtr = mmbrInfo[0].wrtr
                            }
                        }

                        if(x.itm1Val != '1') x.itm1Val = '2'
                        if(x.itm2Val != '1') x.itm2Val = '2'
                        if(x.itm3Val != '1') x.itm3Val = '2'

                        recItem3.itm1Val = x.itm1Val
                        recItem3.itm2Val = x.itm2Val
                        recItem3.itm3Val = x.itm3Val

                        hisObj3.recList.push(recItem3)
                    }
                });

                
                if(hisObj3.recList.length > 0){
                    this.mmbrList.forEach(x => {
                        hisObj3.wrtrGr.push(x.wrtr)
                        hisObj3.wrtrGrMmbrPk.push(x.wrtrpk)
                    });

                    obj.hisList.push(hisObj3)
                } 
                
                this.progress = true

                insMultiBnftRcrd(obj)
                    .then((response) => ( this.onSaveAfter(response.data) ))
                    .catch((error) => console.log('upload error /bnftrcrd/insMultiBnftRcrd : ' + error))

            }
        },
        onSaveAfter:function(res){
            this.progress = false
            this.isPreData = false

            let obj = {}

            if(res.statusCode == 200){                                                
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)

                this.$emit('modalEvent', 99)
                this.reLoad()                
            }else if(res.statusCode == 205) {
                obj.icon = 'mdi-alert'
                obj.iconClr = 'red'
                obj.title = res.message
                obj.cntnt = []

                if(res.messagesList != null && res.messagesList != undefined){
                    res.messagesList.forEach(el => {
                        obj.cntnt.push(el)
                    });
                }

                obj.cntnt.push('등록된 기록을 삭제하고 저장하시겠습니까?')
                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 저장하겠습니다.'

                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });   

                this.tltUseCnfm = true

            }else if(res.statusCode == 701) {                                
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = '기저귀 교체 및 확인 기록 · 담당자 시간이 중복되었습니다.'        // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
                this.$store.commit('setWrnnInf', obj)
            }else{
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀

                this.$store.commit('setWrnnInf', obj)
            }

            
        },
        onDupSave:function(res){
            if(res == 9990){
                this.onSave('2')
            }else if(res == 7){
                this.$emit('modalEvent', 99)
                this.reLoad()
            }
             this.dialogDup = false
        },
        setMmbrList:function(wrtr,wrtrpk){
            let addChk = true
        
            this.mmbrList.forEach(x=>{
                if(x.wrtrpk == wrtrpk)
                addChk = false
            })

            if(addChk)
                this.mmbrList.push({wrtr:wrtr,wrtrpk:wrtrpk})
        },
        getData(){
            let fcltyNum = this.$store.getters.getFcltyNum
            let obj = {
                fcltyNum: fcltyNum, 
                inqTFclsfc1Cd: true, 
                inqTFclsfc2Cd: true, 
                inqTFclsfc3Cd: true, 
                inqTFitmCd: true, 
                clsfc1Cd: '301',
                clsfc2Cd: '3',
                bnMmbrPk: this.bnMmbrPk,
                inqYMD:this.inqYMD,
            }
            this.progress = true
            selBnftOfrRecList(obj)
                .then((response) => ( this.resData(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/selBnftOfrRecList : ' + error))
        },
        resData(res){         
            this.progress = false   
            this.recItems2.bnftOfrRecHisPk = 0
            this.recItems3.bnftOfrRecHisPk = 0
            this.recItems2.listDtl.splice(0)
            this.recItems3.listDtl.splice(0)

            if(res.statusCode == 200){

                res.result.forEach(e => {
                    if(e.clsfc3Cd == '3'){
                        this.recItems1.bnftOfrRecHisPk = e.bnftOfrRecHisPk
                        this.recItems1.bnftOfrRec301Pk = e.bnftOfrRec301Pk
                        this.recItems1.mdfDt = e.mdfDt
                        this.recItems1.spclNote = e.spclNote
                        this.recItems1.itm1Val = e.itm1Val
                        this.recItems1.itm2Val = e.itm2Val
                        this.recItems1.itm3Val = e.itm3Val                    
                        this.recItems1.wrtr = e.wrtr   
                        this.recItems1.wrtrMmbrPk = e.wrtrMmbrPk   
                    }else if(e.clsfc3Cd == '4'){                    
                        this.recItems2.bnftOfrRecHisPk = e.bnftOfrRecHisPk
                        this.recItems2.mdfDt = e.mdfDt
                        let ofrTmStrt = ''
                        if(e.ofrTmHhStrt != '' && e.ofrTmMmStrt !='') ofrTmStrt = e.ofrTmHhStrt+':'+e.ofrTmMmStrt
                        
                        let obj = {
                            bnftOfrRec301Pk:e.bnftOfrRec301Pk,                    
                            ofrTmStrt:ofrTmStrt,
                            ofrTmHhStrt:e.ofrTmHhStrt,
                            ofrTmMmStrt:e.ofrTmMmStrt,                    
                            itm1Cd:'2',
                            itm1Val:e.itm1Val,
                            wrtr:e.wrtr,
                            wrtrMmbrPk:e.wrtrMmbrPk,
                        }

                        this.recItems2.listDtl.push(obj)
                    }else if(e.clsfc3Cd == '5'){                    
                        this.recItems3.bnftOfrRecHisPk = e.bnftOfrRecHisPk
                        this.recItems3.mdfDt = e.mdfDt
                        let ofrTmStrt = ''

                        // for(let i=0;i<e.wrtrGr.length;i++){   
                        //     this.setMmbrList(e.wrtrGr[i],e.wrtrGrMmbrPk[i])
                        // }

                        this.setMmbrList(e.wrtr,e.wrtrMmbrPk)

                        if(e.ofrTmHhStrt != '' && e.ofrTmMmStrt !='') ofrTmStrt = e.ofrTmHhStrt+':'+e.ofrTmMmStrt
                        
                        let obj = {
                            bnftOfrRec301Pk:e.bnftOfrRec301Pk,                    
                            ofrTmStrt:ofrTmStrt,
                            ofrTmHhStrt:e.ofrTmHhStrt,
                            ofrTmMmStrt:e.ofrTmMmStrt, 
                            itm1Cd:'1',
                            itm1Val:e.itm1Val,
                            itm2Cd:'1',
                            itm2Val:e.itm2Val,
                            itm3Cd:'1',
                            itm3Val:e.itm3Val,
                            itm4Cd:'86',
                            itm4Val:e.itm4Val,
                            wrtr:e.wrtr,
                            wrtrMmbrPk:e.wrtrMmbrPk,
                            codeItem:this.codeItem,
                            mmbrList:this.mmbrList,
                        }

                        this.recItems3.listDtl.push(obj)
                    }
                });

                if(this.recItems2.listDtl.length == 0) this.addRow('rec1')
                if(this.recItems3.listDtl.length == 0) this.addRow('rec2')

                if(this.recItems1.bnftOfrRecHisPk > 0){
                    this.filledBtn[0].visible = false                 
                    this.filledBtn[2].disabled = false  
                }

            } else {               
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!) 
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)

                this.$store.commit('setWrnnInf', obj)
            }
            
        }, 
        getPreData(){
            let fcltyNum = this.$store.getters.getFcltyNum
            let obj = {
                fcltyNum: fcltyNum, 
                inqTFclsfc1Cd: true, 
                inqTFclsfc2Cd: true, 
                inqTFclsfc3Cd: true, 
                inqTFitmCd: true, 
                clsfc1Cd: '301',
                clsfc2Cd: '3',
                bnMmbrPk: this.bnMmbrPk,
                inqYMD:this.inqYMD,
            }
            
            this.progress = true

            selPreBnftOfrRecList(obj)
            .then((response) => ( this.getPreDataAfter(response.data)))
            .catch((error) => console.log(error))
        },
        getPreDataAfter(res){
            this.recItems2.bnftOfrRecHisPk = 0
            this.recItems3.bnftOfrRecHisPk = 0
            this.recItems2.listDtl.splice(0)
            this.recItems3.listDtl.splice(0)

            res.forEach(e => {
                if(e.clsfc3Cd == '3'){                    
                    this.recItems1.spclNote = e.spclNote
                    this.recItems1.itm1Val = e.itm1Val
                    this.recItems1.itm2Val = e.itm2Val
                    this.recItems1.itm3Val = e.itm3Val                    
                    this.recItems1.wrtr = e.wrtr   
                    this.recItems1.wrtrMmbrPk = e.wrtrMmbrPk   
                }else if(e.clsfc3Cd == '4'){                    
                    this.recItems2.bnftOfrRecHisPk = 0
                    let ofrTmStrt = ''
                    if(e.ofrTmHhStrt != '' && e.ofrTmMmStrt !='') ofrTmStrt = e.ofrTmHhStrt+':'+e.ofrTmMmStrt
                    
                    let obj = {
                        bnftOfrRec301Pk:0,                    
                        ofrTmStrt:ofrTmStrt,
                        ofrTmHhStrt:e.ofrTmHhStrt,
                        ofrTmMmStrt:e.ofrTmMmStrt,                    
                        itm1Cd:'2',
                        itm1Val:e.itm1Val,
                        wrtr:e.wrtr,
                        wrtrMmbrPk:e.wrtrMmbrPk,
                    }

                    this.recItems2.listDtl.push(obj)
                }else if(e.clsfc3Cd == '5'){                    
                    this.recItems3.bnftOfrRecHisPk = 0
                    let ofrTmStrt = ''

                    this.setMmbrList(e.wrtr,e.wrtrMmbrPk)

                    if(e.ofrTmHhStrt != '' && e.ofrTmMmStrt !='') ofrTmStrt = e.ofrTmHhStrt+':'+e.ofrTmMmStrt
                    
                    let obj = {
                        bnftOfrRec301Pk:0,                    
                        ofrTmStrt:ofrTmStrt,
                        ofrTmHhStrt:e.ofrTmHhStrt,
                        ofrTmMmStrt:e.ofrTmMmStrt, 
                        itm1Cd:'1',
                        itm1Val:e.itm1Val,
                        itm2Cd:'1',
                        itm2Val:e.itm2Val,
                        itm3Cd:'1',
                        itm3Val:e.itm3Val,
                        itm4Cd:'86',
                        itm4Val:e.itm4Val,
                        wrtr:e.wrtr,
                        wrtrMmbrPk:e.wrtrMmbrPk,
                        codeItem:this.codeItem,
                        mmbrList:this.mmbrList,
                    }

                    this.recItems3.listDtl.push(obj)
                }
            });

            if(this.recItems2.listDtl.length == 0) this.addRow('rec1')
            if(this.recItems3.listDtl.length == 0) this.addRow('rec2')

            this.progress = false
        },
        getTltCalcl:function(){            
            if(this.recItems3.listDtl != undefined){
                this.recItems1.itm1Val = 0
                this.recItems1.itm2Val = 0
                this.recItems1.itm3Val = 0

                this.recItems3.listDtl.forEach(el => {
                    if(el.itm1Val == '1') this.recItems1.itm1Val++
                    if(el.itm2Val == '1') this.recItems1.itm2Val++
                    if(el.itm3Val == '1') this.recItems1.itm3Val++
                });
            }

            this.recItems2.listDtl.forEach(x => {
                if(/^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(x.ofrTmStrt)) this.recItems1.itm2Val++
            })
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
        getEmpInfo: function (obj) {
            this.setMmbrList(obj[0].name,obj[0].mmbrPk)
        },
        onModal: function (value) {
            this.prtWrtDt = this.inqYMD
            this.dialog.splice(value, 1, true)
        },
        isModal: function (value) {
            this.dialog.splice(value, 1, false)
        },
        onforce(key){
            this.mtimes[key] = false
            ++this.forceRender
        },
        onforce2(key){
            this.mtimes1[key] = false
            ++this.forceRender
        },
        addRow(type){
          switch (type) {
            case 'rec1':
                this.recItems2.listDtl.push(
                            {
                                bnftOfrRec301Pk:0,
                                ofrTmStrt: "",
                                ofrTmHhStrt:"",
                                ofrTmMmStrt:"",
                                wrtr:'',
                                wrtrMmbrPk:0,
                                itm1Cd:'2',
                                itm1Val:'',
                            })
                break;
            case 'rec2':
                this.recItems3.listDtl.push(
                            {
                                bnftOfrRec301Pk:0,
                                ofrTmStrt: "",
                                ofrTmHhStrt:"",
                                ofrTmMmStrt:"",
                                wrtr:'',
                                wrtrMmbrPk:0,
                                itm1Cd:'1',
                                itm1Val:'',
                                itm2Cd:'1',
                                itm2Val:'',
                                itm3Cd:'1',
                                itm3Val:'',
                                itm4Cd:'86',
                                itm4Val:'',
                                codeItem:this.codeItem,
                                mmbrList:this.mmbrList
                            })
                break;
            default:
                break;
          }
        },
        delRow(type, key){
            switch (type) {
                case 'rec1':
                    if(this.recItems2.listDtl[key].bnftOfrRec301Pk > 0){
                        this.recPk=this.recItems2.listDtl[key].bnftOfrRec301Pk
                        
                        let obj = {}
                        obj.icon = 'mdi-alert'
                        obj.iconClr = 'red'
                        obj.title = '화장실이용기록'
                        obj.cntnt = [this.recItems2.listDtl[key].ofrTmStrt+' 소변량 기록을(를) 삭제하시겠습니까?']
                        obj.cnclTxt = '아니오'
                        obj.cnfrmTxt = '예, 삭제하겠습니다.'

                        this.$store.commit('setSubInf', {
                            actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                        });   

                        this.rmTltUseCnfm = true
                    }else{
                        this.recItems2.listDtl.splice(key,1)
                    }
                    break;
                case 'rec2':
                    if(this.recItems3.listDtl[key].bnftOfrRec301Pk > 0){
                        this.recPk=this.recItems3.listDtl[key].bnftOfrRec301Pk

                        let obj = {}
                        obj.icon = 'mdi-alert'
                        obj.iconClr = 'red'
                        obj.title = '화장실이용기록'
                        obj.cntnt = [this.recItems3.listDtl[key].ofrTmStrt+' 기저귀 교체 및 확인 기록을(를) 삭제하시겠습니까?']
                        obj.cnclTxt = '아니오'
                        obj.cnfrmTxt = '예, 삭제하겠습니다.'

                        this.$store.commit('setSubInf', {
                            actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                        });   

                        this.rmTltUseCnfm = true
                    }else{
                        this.recItems3.listDtl.splice(key,1)
                    }
                    break;
                default:
                    break;
           }
        },
        rmBnftOfrRec:function(){
            if(this.recPk > 0){
                let obj = {
                    fcltyNum        : this.$store.getters.getFcltyNum,    
                    bnMmbrPk        : this.bnMmbrPk,
                    userNm          : this.$store.getters.getUserNm,
                    bnftOfrRec301Pk : this.recPk,
                    clsfc1Cd        : '301',
                    clsfc2Cd        : '3',
                    inqYMD          : this.inqYMD,

                }
                // console.log(obj)
                this.progress = true

                delTitBnftOfrRec(obj)
                    .then((response) => ( this.rmBnftOfrRecAfter(response.data) ))
                    .catch((error) => console.log('upload error /bnftrcrd/delTitBnftOfrRec : ' + error))
            }
        },

        rmBnftOfrRecAfter:function(res){
            this.progress = false

            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀
            obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200){
                obj.tmr = 2500      
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.reLoad()
            } else {                
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
            }
            
            this.$store.commit('setWrnnInf', obj)
        },

        rmAllBnftOfrRec:function(){
            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum,                 
                bnMmbrPk    : this.bnMmbrPk,
                recDt       : this.inqYMD,
                clsfc1Cd    : '301',
                clsfc2Cd    : '3',
                clsfc3CdS   : ['3','4','5'],
            }
            console.log(obj)
            this.progress = true

            delAllBnftOfrRec(obj)
                .then((response) => ( this.rmAllBnftOfrRecAfter(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/delAllBnftOfrRec : ' + error))
        },

        rmAllBnftOfrRecAfter:function(res){
            this.progress = false

            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀
            obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200){
                obj.tmr = 2500      
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.$emit('modalEvent', 99)
                this.onClose()
            } else {                
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
            }
            
            this.$store.commit('setWrnnInf', obj)
        },        
        onClose: function () {
            this.$emit('modalEvent', 3)
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        altControll: function (options) {
            this.altOptions = options
        },
    },
    data: () => ({         
        tltUseCnfm :false,         
        rmTltUseCnfm :false,
        rmAllTltUseCnfm :false,
        isPreData:false,
        preymd:'',
        prtWrtDt:'',   
        idx:0,
        recPk:0,
        type:'all',
        forceRender:0,
        clsfc1Cd:'301',
        clsfc2Cd:'3',
        delModelTitle:'화장실이용기록',   
        codeItem:[],
        mmbrList:[{wrtr:'',wrtrpk:''}],     
        recItems1:{
            bnftOfrRecHisPk:0,
            bnftOfrRec301Pk:0,
            mdfDt:'',
            recDt:'',
            clsfc3Cd:'3',
            itm1Cd:'1',
            itm1Val:0,
            itm2Cd:'2',
            itm2Val:0,
            itm3Cd:'3',
            itm3Val:0,
            wrtr:'',
            spclNote:'',
            wrtrMmbrPk:0,
        },        
        recItems2:{
            bnftOfrRecHisPk:0,
            clsfc1Cd:'301',
            clsfc2Cd:'3',
            clsfc3Cd:'4',
            mdfDt:'',
            recDt:'',
            listDtl:[
                {
                    bnftOfrRec301Pk:0,                    
                    ofrTmStrt: "",
                    ofrTmHhStrt:"",
                    ofrTmMmStrt:"",                    
                    itm1Cd:'2',
                    itm1Val:'',
                    wrtr:'',
                    wrtrMmbrPk:0,
                }
            ]
        },
        recItems3:{
            bnftOfrRecHisPk:0,
            clsfc1Cd:'301',
            clsfc2Cd:'3',
            clsfc3Cd:'5',
            mdfDt:'',
            recDt:'',
            wrtr:'',
            wrtrMmbrPk:0,
            wrtrGr:[],
            wrtrGrMmbrPk:[],
            listDtl:[{
                bnftOfrRec301Pk:0,                
                ofrTmStrt: "",
                ofrTmHhStrt:"",
                ofrTmMmStrt:"",
                wrtr:'',
                wrtrMmbrPk:0,                
                itm1Cd:'1',
                itm1Val:'',
                itm2Cd:'1',
                itm2Val:'',
                itm3Cd:'1',
                itm3Val:'',
                itm4Cd:'86',
                itm4Val:'',
                codeItem:[],
            }
        ]},
        rcrdInfo : {},
        menu: [false],
        dialog : [false], 
        rtimes:[false,false,false,false,false,false,false,],
        mtimes:[false,false,false,false,false,false,false,],
        rtimes1:[false,false,false,false,false,false,false,],
        mtimes1:[false,false,false,false,false,false,false,],               
        snackOptions: [false, 3000, '', 'info'],    
        altOptions: [false, 3000, 'info', '', ''],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
        filledBtn: [
            { icon: 'mdi-text-box-search-outline', class: 'white--text', color:'#3C9099', text: '전일자료', type:'action', visible: true, disabled: false,},
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type:'action', visible: true, disabled: false,},
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type:'action', visible: true, disabled: true,},
        ],    
        progress:false,
    }),
};
</script>