<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">   
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>       
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">건강검진 관리</span>
            <v-spacer></v-spacer>            
            <v-btn v-for="(list, i) in filledBtn" :key="i"  @click = "onClickBtn(i)"
                :class="list.class" height="30" :color="list.color" :disabled="list.disabled" rounded
                style="font-size: 14px;">
                <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                {{list.text}}
            </v-btn>
            <v-icon class="pl-2" color="black" x-large @click="onClose()">mdi-close</v-icon>
        </div>
        <v-form ref="tabform" lazy-validation> 
        <div class="mt-3">
            <v-row class="pa-0 mt-1" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">건강검진일자</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4">
                    <span class="white d-inline-block pa-1" style="width: 154px;">
                        <CmmDateComp v-model="items.hltChcDt" :required="true"></CmmDateComp>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">작성자</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4">
                    <span class="d-inline-block" style="width: 100px;">
                            <v-text-field
                                v-model="items.wrtr" color="blue" ref="info" height="28"
                                :rules="[rules.required, rules.strCheck]" :key="forceRender"
                                hide-details outlined readonly dense >
                            </v-text-field>
                        </span>
                        <v-btn 
                            class="ml-1 brown lighten-3" 
                            small 
                            min-width="30"
                            height="26"  
                            style="padding:0px 1px 0px 2px !important"
                            @click="onModal(0)"
                            >
                            <v-icon size="20" class="white--text">mdi-account-edit</v-icon>
                        </v-btn>
                </v-col>
            </v-row>
            <!-- <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">검진장소</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="10">
                    <v-text-field v-model="items.hltChcPlc" :rules="[rules.required]" hide-details outlined dense></v-text-field>
                </v-col>                
            </v-row>
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">검진결과</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="10">
                    <v-textarea v-model="items.hltChcRst" class="rmrksArea" style="font-size:0.9rem !important;"
                        rows="7" outlined dense hide-details no-resize>
                    </v-textarea>
                </v-col>                
            </v-row>             -->
        </div>                
        </v-form>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
                    <EmpSelector @isClose="isModal(0)" @outEmp="getEmpInfo" :prtWrtDt="sltDate"></EmpSelector>
            </v-dialog>  
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete title="건강검진 관리" @madalState="onDelete"></btnModalDelete>  
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import EmpSelector from '../commons/EmpSelector.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';

import { selHltChcInf, insHltChcInf, delHltChcInf  } from '../../api/bnftrcrd.js';

export default {
    name: 'HlthChckWrt',
    
    components: {  
        btnModalDelete,
        CmmDateComp,
        EmpSelector
    },

    props : {
    },

    computed: {
        
    },

    created: function(){          
        this.sltDate = this.$moment().format('YYYY-MM-DD')
    },

    mounted: function(){
        this.$nextTick(function () {         
        })
    },

    watch: {
        
    },

    methods: { 
        onLoad: function(mmbr, pk){            
            this.mmbrPk = mmbr
            this.hltChcPk = pk
            this.getHltChcInf()
        },        
        onClickBtn: function (key) {
            switch (key) {
                case 0:
                    this.onSave()
                    break;  
                case 1:
                    this.dialogDelete = true
                    break;              
                default:
                    break;
            }
        },
        onClose: function (msg = null) {
            this.$emit('modalEvent', 0, msg)
        },
        onModal: function (value) {
            this.dialog.splice(value, 1, true)
        },
        isModal: function (value) {
            this.dialog.splice(value, 1, false)
        },
        getEmpInfo: function (obj) {
            this.items.wrtr = obj[0].name
            this.items.wrtrMmbrPk = obj[0].mmbrPk
        },
        onforce:function(){
            this.forceRender++
        },
        onSave:function(){
            let gFcltyNum = this.$store.getters.getFcltyNum           
            let gUserNm = this.$store.getters.getUserNm

            if(this.$refs.tabform.validate()){
                let obj = {
                    fcltyNum    : gFcltyNum,
                    mmbrPk      : this.mmbrPk,
                    userNm      : gUserNm,
                    hltChcInfPk : this.items.hltChcInfPk,
                    hltChcDt    : this.items.hltChcDt?.afterDateFormatHyp(),
                    hltChcPlc   : this.items.hltChcPlc,
                    hltChcRst   : this.items.hltChcRst,
                    wrtr        : this.items.wrtr,
                    stdYyyy     : this.$parent.$parent.$parent.$parent.year   
                }
                
                let bl =true
                if(bl) {
                    insHltChcInf(obj)
                        .then((response) => ( this.onSaveAfter(response.data) ))
                        .catch((error) => console.log('upload error /bnftrcrd/insHltChcInf : ' + error))
                }
            }
        },
        onSaveAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.$emit('modalEvent', 99)
                this.hltChcPk = res.result
                this.getHltChcInf()
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onDelete:function(res){            
            if(res == 9990){
                if(this.mmbrPk > 0){
                    let hltChcInfPk = this.items.hltChcInfPk
                if(hltChcInfPk > 0){
                    delHltChcInf(this.$store.getters.getFcltyNum, hltChcInfPk, this.$store.getters.getUserNm)
                        .then((response) => ( this.onDeleteAfter(response.data) ))
                        .catch((error) => console.log('connect error /bnftrcrd/delHltChcInf : ' + error))
                }
                }else{
                    this.snackControll([true, 3500, '삭제 할 대상을 선택해주세요.', 'warning'])
                    this.dialogDelete = false
                }
            }else if(res == 7){
                this.dialogDelete = false
            }
        },
        onDeleteAfter:function(res){
            this.dialogDelete = false
            this.authGrpPk = 0

            if(res.statusCode == 200){                               
                this.$emit('modalEvent', 99)
                this.onClose(res.message)
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        getHltChcInf:function(){
            let gFcltyNum = this.$store.getters.getFcltyNum
            let ghltChcInfPk = this.hltChcPk

            selHltChcInf(gFcltyNum, ghltChcInfPk)
                .then((response) => ( this.getHltChcInfAfter(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/selHltChcInf : ' + error))
        },
        getHltChcInfAfter:function(res){            
            if(res.hltChcDt != undefined){                
                this.items = res
                this.items.hltChcDt = this.items.hltChcDt.beforeDateFormatHyp()
                this.filledBtn[1].disabled = false
            }else{
                this.items = Object.assign({})
                this.filledBtn[1].disabled = true
            }
            this.$refs.tabform.resetValidation()
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
    },
    data: () => ({        
        mmbrPk:0,
        inqYmd:0,
        sltDate:'',
        hltChcPk:null,
        items:{},
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type: 'action', width:'800px', disabled: false, },            
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type: 'action', width:'500px', visible: true, disabled: true, },
        ],
        dialog:[false,false],
        forceRender:0, 
        menu:[false,], 
        rtimes:false,
        mtimes:false,
        snackOptions: [false, 3000, '', 'info'],
        dialogDelete:false,
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
    }),
};
</script>