<template>
    <v-sheet class="rounded-lg rounded-l-0 py-3 px-5" color="" min-width="1260" max-width="1560" height="835">
        <v-row class="pa-0 align-center" no-gutters>
            <span class="hamletFont text-h5 topTitleColor--text font-weight-black">9&#45;6&#46;&nbsp;시설운영일지</span>
            <v-spacer></v-spacer>
            <v-btn
                v-for="(list, j) in outlinedBtn" :key="j" @click="onClickBtn(list.text)"
                :class="list.class" height="30"
                :color="list.color" :disabled="chcActv.findIndex( v => v == true ) > -1"
                samll rounded outlined>
                <v-icon style="padding-top: 2px;" small>{{ list.icon }}</v-icon>
                <span class="ml-1 fontOneRem">{{ list.text }}</span>
            </v-btn>
        </v-row>
        <v-row class="py-3 justify-center" no-gutters>
            <expandNowDatePicker :disabled="chcActv.findIndex( v => v == true ) > -1" @nowDate="getDate"></expandNowDatePicker>
        </v-row>
        <v-row class="pa-0" no-gutters>
            <v-col cols="7">
                <v-row class="pa-0 align-center" no-gutters>
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold">수급자&nbsp;현황</span>
                    <v-spacer></v-spacer>
                    <span class="fontOneRem mr-1">&#40;&nbsp;단위&#58;&nbsp;명&nbsp;&#41;</span>
                </v-row>
                <v-divider class="mt-2"></v-divider>
                <v-row class="greyE01 pa-0" no-gutters>
                    <v-col class="d-flex justify-center align-center tableBR py-2" cols="1">
                        <span class="black--text font-weight-medium">현&nbsp;인원</span>
                    </v-col>
                    <v-col class="tableBR" cols="5">
                        <v-row class="pa-0" no-gutters>
                            <v-col class="txtAlgnMid py-1" cols="12">
                                <span class="black--text font-weight-medium">등급현황</span>
                            </v-col>
                        </v-row>
                        <v-divider class=""></v-divider>
                        <v-row class="pa-0" no-gutters>
                            <v-col
                                v-for="i in 5" :key="i"
                                class="txtAlgnMid tableBR py-2" cols="2">
                                <span class="black--text font-weight-medium">{{ i }}등급</span>
                            </v-col>
                            <v-col class="txtAlgnMid py-2" cols="2">
                                <span class="black--text font-weight-medium">등급외</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col class="tableBR" cols="4">
                        <v-row class="pa-0" no-gutters>
                            <v-col class="txtAlgnMid py-1" cols="12">
                                <span class="black--text font-weight-medium">연령별&nbsp;통계</span>
                            </v-col>
                        </v-row>
                        <v-divider class=""></v-divider>
                        <v-row class="pa-0" no-gutters>
                            <v-col class="txtAlgnMid tableBR py-2" cols="3">
                                <span class="black--text font-weight-medium">69이하</span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR py-2" cols="3">
                                <span class="black--text font-weight-medium">70&#126;79</span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR py-2" cols="3">
                                <span class="black--text font-weight-medium">80&#126;89</span>
                            </v-col>
                            <v-col class="txtAlgnMid py-2" cols="3">
                                <span class="black--text font-weight-medium">90이상</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col class="" cols="2">
                        <v-row class="pa-0" no-gutters>
                            <v-col class="txtAlgnMid py-1" cols="12">
                                <span class="black--text font-weight-medium">성별</span>
                            </v-col>
                        </v-row>
                        <v-divider class=""></v-divider>
                        <v-row class="pa-0" no-gutters>
                            <v-col class="txtAlgnMid tableBR py-2" cols="6">
                                <span class="black--text font-weight-medium">남</span>
                            </v-col>
                            <v-col class="txtAlgnMid py-2" cols="6">
                                <span class="black--text font-weight-medium">여</span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider class=""></v-divider>
                <v-row v-if="items != null" class="pa-0" no-gutters>
                    <v-col class="d-flex justify-center align-center tableBR py-2" cols="1">
                        <span class="black--text">{{ items.totCnt }}</span>
                    </v-col>
                    <v-col class="tableBR" cols="5">
                        <v-row class="pa-0" no-gutters>
                            <v-col class="txtAlgnMid tableBR py-2" cols="2">
                                <span class="black--text">{{ items.rtng1Cnt }}</span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR py-2" cols="2">
                                <span class="black--text">{{ items.rtng2Cnt }}</span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR py-2" cols="2">
                                <span class="black--text">{{ items.rtng3Cnt }}</span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR py-2" cols="2">
                                <span class="black--text">{{ items.rtng4Cnt }}</span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR py-2" cols="2">
                                <span class="black--text">{{ items.rtng5Cnt }}</span>
                            </v-col>
                            <v-col class="txtAlgnMid py-2" cols="2">
                                <span class="black--text">{{ items.rtng99Cnt }}</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col class="tableBR" cols="4">
                        <v-row class="pa-0" no-gutters>
                            <v-col class="txtAlgnMid tableBR py-2" cols="3">
                                <span class="black--text">{{ items.age69Cnt }}</span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR py-2" cols="3">
                                <span class="black--text">{{ items.age79Cnt }}</span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR py-2" cols="3">
                                <span class="black--text">{{ items.age89Cnt }}</span>
                            </v-col>
                            <v-col class="txtAlgnMid py-2" cols="3">
                                <span class="black--text">{{ items.age90Cnt }}</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col class="" cols="2">
                        <v-row class="pa-0" no-gutters>
                            <v-col class="txtAlgnMid tableBR py-2" cols="6">
                                <span class="black--text">{{ items.gndrM }}</span>
                            </v-col>
                            <v-col class="txtAlgnMid py-2" cols="6">
                                <span class="black--text">{{ items.gndrF }}</span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row v-else class="pa-0 justify-center" no-gutters>
                    <span class="grey006--text fontOneRem my-2">※ 조회된 항목이 없습니다.</span>
                </v-row>
                <v-divider class=""></v-divider>
            </v-col>
            <v-col class="pl-4" cols="5">
                <v-row class="pa-0 align-center" no-gutters>
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold">수급자&nbsp;업무&nbsp;현황</span>
                </v-row>
                <v-divider :class="['mt-2',items1.length > 2 ? 'mr-2__5' : '']"></v-divider>
                <v-row :class="['greyE01 pa-0', items1.length > 2 ? 'mr-2__5' : '']" no-gutters>
                    <v-col class="txtAlgnMid tableBR py-1" cols="3">
                        <span class="black--text font-weight-medium">업무구분</span>
                    </v-col>
                    <v-col class="txtAlgnMid py-1" cols="9">
                        <span class="black--text font-weight-medium">업무내용</span>
                    </v-col>
                </v-row>
                <v-divider :class="[items1.length > 2 ? 'mr-2__5' : '']"></v-divider>
                <div v-if="items1.length > 0" class="d-inline-block overflow-y-auto" style="width: 100%; height: 80px;">
                    <v-row
                        v-for="(list, k) in items1" :key="k"
                        class="pa-0 tableBB"
                        no-gutters>
                        <v-col class="txtAlgnMid tableBR py-2" cols="3">
                            <span class="black--text fontOneRem">{{ list.bzClNm }}</span>
                        </v-col>
                        <v-col class="txtAlgnMid py-2" cols="9">
                            <span class="black--text fontOneRem">{{ list.bzCntnt }}</span>
                        </v-col>
                    </v-row>
                </div>
                <v-row v-else class="pa-0 justify-center tableBB" no-gutters>
                    <span class="grey006--text fontOneRem my-2">※ 조회된 항목이 없습니다.</span>
                </v-row>
            </v-col>
        </v-row>
        <v-divider class="white my-2"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="" cols="7">
                <v-row class="pa-0 align-center" no-gutters>
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold">금일&nbsp;식단</span>
                </v-row>
                <v-divider class="mt-2"></v-divider>
                <v-row class="greyE01 pa-0" no-gutters>
                    <v-col class="tableBR" cols="2">
                        <v-row class="pa-0" no-gutters>
                            <v-col class="txtAlgnMid py-1" cols="12">
                                <span class="black--text font-weight-medium"></span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col class="" cols="10">
                        <v-row class="pa-0" no-gutters>
                            <v-col class="txtAlgnMid tableBR py-1" cols="4">
                                <span class="black--text font-weight-medium">조식</span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR py-1" cols="4">
                                <span class="black--text font-weight-medium">중식</span>
                            </v-col>
                            <v-col class="txtAlgnMid py-1" cols="4">
                                <span class="black--text font-weight-medium">석식</span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider class=""></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 tableBR d-flex justify-center align-center" cols="2">
                        <span class="black--text font-weight-medium">일반식</span>
                    </v-col>
                    <v-col class="" cols="10">
                        <v-row v-for="(mn, l) in items4" :key="l" class="pa-0" no-gutters>
                            <v-col class="txtAlgnMid tableBR py-1" cols="4">
                                <span class="black--text fontOneRem" v-html="mn.gnrlBrkfs"></span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR py-1" cols="4">
                                <span class="black--text fontOneRem" v-html="mn.gnrlLunch"></span>
                            </v-col>
                            <v-col class="txtAlgnMid py-1" cols="4">
                                <span class="black--text fontOneRem" v-html="mn.gnrlDiner"></span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider class=""></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 tableBR d-flex justify-center align-center" cols="2">
                        <span class="black--text font-weight-medium">죽식</span>
                    </v-col>
                    <v-col class="" cols="10">
                        <v-row v-for="(mn, l) in items4" :key="l" class="pa-0" no-gutters>
                            <v-col class="txtAlgnMid tableBR py-1" cols="4">
                                <span class="black--text fontOneRem" v-html="mn.papBrkfs"></span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBR py-1" cols="4">
                                <span class="black--text fontOneRem" v-html="mn.papLunch"></span>
                            </v-col>
                            <v-col class="txtAlgnMid py-1" cols="4">
                                <span class="black--text fontOneRem" v-html="mn.papDiner"></span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider class=""></v-divider>
                <v-row class="greyE01 pa-0" no-gutters>
                    <v-col class="tableBR d-flex justify-center align-center py-1" cols="2">
                        <span class="black--text font-weight-medium"></span>
                    </v-col>
                    <v-col class="" cols="10">
                        <v-row class="pa-0" no-gutters>
                            <v-col class="txtAlgnMid tableBR py-1" cols="6">
                                <span class="black--text font-weight-medium">오전</span>
                            </v-col>
                            <v-col class="txtAlgnMid py-1" cols="6">
                                <span class="black--text font-weight-medium">오후</span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider class=""></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 tableBR d-flex justify-center align-center" cols="2">
                        <span class="black--text font-weight-medium">간식</span>
                    </v-col>
                    <v-col class="" cols="10">
                        <v-row v-for="(mn, l) in items4" :key="l" class="pa-0" no-gutters>
                            <v-col class="txtAlgnMid tableBR py-1" cols="6">
                                <span class="black--text fontOneRem" v-html="mn.snckAm"></span>
                            </v-col>
                            <v-col class="txtAlgnMid py-1" cols="6">
                                <span class="black--text fontOneRem" v-html="mn.snckPm"></span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider class=""></v-divider>
            </v-col>
            <v-col class="pl-4" cols="5">
                <v-row class="pa-0 align-center" no-gutters>
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold">수급자&nbsp;급여제공일지</span>
                </v-row>
                <v-divider :class="['mt-2',items2.length > 6 ? 'mr-2__5' : '']"></v-divider>
                <v-row :class="['greyE01 pa-0', items2.length > 6 ? 'mr-2__5' : '']" no-gutters>
                    <v-col class="txtAlgnMid tableBR py-1" cols="4">
                        <span class="black--text font-weight-medium">업무구분</span>
                    </v-col>
                    <v-col class="txtAlgnMid py-1" cols="8">
                        <span class="black--text font-weight-medium">업무내용</span>
                    </v-col>
                </v-row>
                <v-divider :class="[items2.length > 6 ? 'mr-2__5' : '']"></v-divider>
                <div v-if="items2.length > 0" class="d-inline-block overflow-y-auto" style="width: 100%; height: 220px;">
                    <v-row v-for="(arr, m) in items2" :key="m" class="pa-0 tableBB" no-gutters>
                        <v-col class="txtAlgnMid tableBR py-1" cols="4">
                            <span class="black--text fontOneRem">{{ arr.bzClNm }}</span>
                        </v-col>
                        <v-col class="txtAlgnMid py-1" cols="8">
                            <span class="black--text fontOneRem">{{ arr.bzCntnt }}</span>
                        </v-col>
                    </v-row>
                </div>
                <v-row v-else class="pa-0 justify-center tableBB" no-gutters>
                    <span class="grey006--text fontOneRem my-2">※ 조회된 항목이 없습니다.</span>
                </v-row>
            </v-col>
        </v-row>
        <v-divider class="white my-2"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col cols="7">
                <v-row class="pa-0 align-center" no-gutters>
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold">직원&nbsp;업무일지</span>
                </v-row>
                <v-divider :class="['mt-2',items3.length > 5 ? 'mr-2__5' : '']"></v-divider>
                <v-row :class="['greyE01 pa-0', items3.length > 5 ? 'mr-2__5' : '']" no-gutters>
                    <v-col class="txtAlgnMid tableBR py-1" cols="3">
                        <span class="black--text font-weight-medium">업무구분</span>
                    </v-col>
                    <v-col class="txtAlgnMid py-1" cols="9">
                        <span class="black--text font-weight-medium">업무내용</span>
                    </v-col>
                </v-row>
                <v-divider :class="[items3.length > 5 ? 'mr-2__5' : '']"></v-divider>
                <div v-if="items3.length > 0" class="d-inline-block overflow-y-auto" style="width: 100%; height: 160px;">
                    <v-row v-for="(arr, n) in items3" :key="n" class="pa-0 tableBB" no-gutters>
                        <v-col class="d-flex justify-center align-center tableBR py-1" cols="3">
                            <span class="black--text fontOneRem">{{ arr.bzClNm }}</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center tableBR fontOneRem py-1" cols="6">
                            <span class="black--text fontOneRem">{{ arr.bzCntnt }}</span>
                        </v-col>
                        <v-col class="txtAlgnMid pa-1" style="line-height: 1.2;" cols="3">
                            <span class="black--text fontOneRem">{{ arr.bzCntntDtl }}</span>
                        </v-col>
                    </v-row>
                </div>
                <v-row v-else class="pa-0 justify-center tableBB" no-gutters>
                    <span class="grey006--text fontOneRem my-2">※ 조회된 항목이 없습니다.</span>
                </v-row>
            </v-col>
            <v-col class="pl-4" cols="5">
                <v-row class="pa-0 align-center" no-gutters>
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold">직원&nbsp;근무일정표</span>
                </v-row>
                <v-divider :class="['mt-2',items5.length > 5 ? 'mr-2__5' : '']"></v-divider>
                <v-row :class="['greyE01 pa-0', items5.length > 5 ? 'mr-2__5' : '']" no-gutters>
                    <v-col class="txtAlgnMid tableBR py-1" cols="3">
                        <span class="black--text font-weight-medium">업무구분</span>
                    </v-col>
                    <v-col class="txtAlgnMid py-1" cols="9">
                        <span class="black--text font-weight-medium">업무내용</span>
                    </v-col>
                </v-row>
                <v-divider :class="[items5.length > 5 ? 'mr-2__5' : '']"></v-divider>
                <div v-if="items5.length > 0" class="d-inline-block overflow-y-auto" style="width: 100%; height: 160px;">
                    <v-row v-for="(arr, o) in items5" :key="o" class="pa-0 tableBB" no-gutters>
                        <v-col class="d-flex justify-center align-center tableBR py-1" cols="3">
                            <span class="black--text fontOneRem">{{ arr.bzClNm }}</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center tableBR fontOneRem py-1" cols="7">
                            <span class="black--text fontOneRem">{{ arr.bzCntnt }}</span>
                        </v-col>
                        <v-col class="txtAlgnMid pa-1" style="line-height: 1.2;" cols="2">
                            <span class="black--text fontOneRem">{{ arr.bzCntntDtl }}</span>
                        </v-col>
                    </v-row>
                </div>
                <v-row v-else class="pa-0 justify-center tableBB" no-gutters>
                    <span class="grey006--text fontOneRem my-2">※ 조회된 항목이 없습니다.</span>
                </v-row>
            </v-col>
        </v-row>

    </v-sheet>
</template>

<script>
import {
    selFcltyJrnlBnfcrInfo,
    selFcltyJrnlBnfcrBzList,
    selFcltyJrnlBnftList,
    selFcltyJrnlEmpBzList,
    selMenuList
} from '../../api/bnfcrStts';

import expandNowDatePicker from '../../components/commons/expandNowDatePicker.vue';

export default {
    name: 'FcsftOprtnJrnl',

    components: {
        expandNowDatePicker,
    },

    created: function () {

    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch: {

    },

    methods: {
        // 수급자 현황 조회
        selFcltyJrnlBnfcrInfo: function (ymd) {
            this.items = null
            this.chcActv.splice(0, 1, true)

            selFcltyJrnlBnfcrInfo(this.$store.getters.getFcltyNum, ymd)
                .then((response) => ( this.bnfcrInfRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selFcltyJrnlBnfcrInfo : ' + error))
        },
        bnfcrInfRst: function (res) {
            if(res != null)
                this.items = res
            else
                this.items = null

            this.chcActv.splice(0, 1, false)
        },
        // 수급자 업무 현황 조회
        selFcltyJrnlBnfcrBzList: function (ymd) {
            this.items1.splice(0)
            this.chcActv.splice(1, 1, true)

            selFcltyJrnlBnfcrBzList(this.$store.getters.getFcltyNum, ymd)
                .then((response) => (this.bnfcrBzRst(response.data)))
                .catch((error) => console.log('connect error /bnfcr/selFcltyJrnlBnfcrBzList : ' + error))
        },
        bnfcrBzRst: function (res) {
            if(res.length > 0){
                let kindCd = []
                let resCd = null

                res.forEach(itm => {
                    kindCd.push(itm.bzClNm)
                });

                resCd = [...new Set(kindCd)]

                resCd.forEach(itm => {
                    let obj = {}
                    obj.bzClNm = itm
                    obj.bzCntnt = ''

                    res.filter( v => v.bzClNm == itm ).forEach(idx => {
                        obj.bzCntnt += idx.bzCntnt + ', '
                    });

                    obj.bzCntnt = obj.bzCntnt.slice(0, -2)

                    this.items1.push(obj)
                });
            }
            else
                this.items1.splice(0)

            this.chcActv.splice(1, 1, false)
        },
        // 수급자 급여제공일지
        selFcltyJrnlBnftList: function (ymd) {
            this.items2.splice(0)
            this.chcActv.splice(2, 1, true)

            selFcltyJrnlBnftList(this.$store.getters.getFcltyNum, ymd)
                .then((response) => (this.bnftListRst(response.data)))
                .catch((error) => console.log('connect error /bnfcr/selFcltyJrnlBnftList : ' + error))
        },
        bnftListRst: function (res) {
            if(res.length > 0)
                this.items2 = res
            else
                this.items2.splice(0)

            this.chcActv.splice(2, 1, false)
        },
        // 직원 업무일지 조회
        selFcltyJrnlEmpBzList: function (ymd) {
            this.items3.splice(0)
            this.items5.splice(0)
            this.chcActv.splice(3, 1, true)
            this.chcActv.splice(5, 1, true)

            selFcltyJrnlEmpBzList(this.$store.getters.getFcltyNum, ymd)
                .then((response) => (this.selFcltyJrnlEmpBzListAfter(response.data)))
                .catch((error) => console.log('connect error /bnfcr/selFcltyJrnlEmpBzList : ' + error))
        },
        selFcltyJrnlEmpBzListAfter: function (res) {
            if(res.length > 0){
                this.items3 = res.filter( v => v.bzClNm == '업무일지' )
                this.items5 = res.filter( v => v.bzClNm == '근무일정표' )
            }
            else{
                this.items3.splice(0)
                this.items5.splice(0)
            }

            this.chcActv.splice(3, 1, false)
            this.chcActv.splice(5, 1, false)
        },
        // 식단표 조회
        getMenuList: function(ymd){
            this.items4.splice(0)
            this.chcActv.splice(4, 1, true)

            selMenuList(this.$store.getters.getFcltyNum, ymd, ymd)
                .then((response) => ( this.getMenuListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnftoffer/selMenuList : ' + error))
        },
        getMenuListAfter: function(res){
            res.forEach(itm => {
                if(itm != null){
                    itm.gnrlBrkfs = itm.gnrlBrkfs?.replaceAll( '\n','<br>')
                    itm.gnrlLunch = itm.gnrlLunch?.replaceAll( '\n','<br>')
                    itm.gnrlDiner = itm.gnrlDiner?.replaceAll( '\n','<br>')
                    this.items4.push(itm)
                }
            });

            this.chcActv.splice(4, 1, false)
        },       
        getDate: function (obj) {
            this.date = obj.date

            this.selFcltyJrnlBnfcrInfo(obj.date)
            this.selFcltyJrnlBnfcrBzList(obj.date)
            this.selFcltyJrnlBnftList(obj.date)
            this.selFcltyJrnlEmpBzList(obj.date)
            this.getMenuList(obj.date)
        },
        onClickBtn: function (value){
            switch (value) {
                case '출력':
                    this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
                    this.prtThrowObj.inqYMD = this.date

                    this.$store.commit('setRootInf', {
                        actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
                    });
                    break;
                default:
                    break;
            }
        },
    },

    data: () => ({
        chcActv: [ false, false, false, false, false, false ],
        date: 19900101,
        items: null,
        items1: [],
        items2: [],
        items3: [],
        items4: [],
        items5: [],
        rules: {
            required: value => !!value || 'Required.',
        },
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class:'ml-2', color:'grey006', text: '출력', type: 'action', width: '500px', visible: true, disabled: false, },
        ],
        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '23', inqYMD: '',
            rptTyp: '1', mode: '2', list: [ { bzClcd: [], bzPk: [] } ],
        },
    }),
};
</script>