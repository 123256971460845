<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <v-col cols="6">
                <span class="text-h4 font-weight-bold">계약서&nbsp;확인사항&nbsp;관리</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="1">
                <v-spacer></v-spacer>
                <v-icon color="black" @click="onClose" x-large>mdi-close</v-icon>
            </v-col>
        </v-row>

        <v-divider class="mt-7"></v-divider>
        <v-row class="greyE01 pa-0" no-gutters>
            <v-col class="d-flex justify-center align-center tableBR" cols="10">
                <span class="black--text font-weight-medium py-2">확인&nbsp;내용</span>
            </v-col>
            <v-col class="d-flex justify-center align-center" cols="2">
                <span class="black--text font-weight-medium">처리</span>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="d-flex justify-center align-center pa-1 tableBR" cols="10">
                <v-textarea
                    class="rmrksArea" rows="5"
                    v-model="area"
                    no-resize hide-details outlined dense>
                </v-textarea>
            </v-col>
            <v-col class="d-flex justify-center align-center" cols="2">
                <v-btn
                    class="" color="blueBtnColor" height="26"
                    @click="setCntrcfmItem"
                    icon>
                    <v-icon>mdi-content-save-edit-outline</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        
        <div class="overflow-y-auto" style="height: 390px;">
            <v-divider></v-divider>
            <v-row
                class="pa-0 tableBB"
                v-for="(list, i) in cntrcCnfm" :key="i"
                no-gutters>
                <v-col class="d-flex justify-left align-center tableBR pa-2" cols="10">
                    <span class="black--text">{{ list.cnfmCntnt }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center" cols="2">
                    <v-btn
                        class="" color="grey006" height="26"
                        @click="delCntrcCnfmItm(list.cntrcCnfmItmPk)"
                        icon>
                        <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

export default {
    name: 'BnfcrBasicCnFmItm',
            
    components: {
        
    },

    props:{
        bnfcrCntrcInfPk: { type: Number, default: -1 },
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getCntrcfmList(this.bnfcrCntrcInfPk, '1')
        })
    },
 
    methods: {
        getCntrcfmList: function (pk, clcd) {
            this.cntrcCnfm.splice(0)

            let obj = {}
            obj.bnfcrCntrcInfPk = pk
            obj.cnfmClcd = clcd

            http.post('bnfcrAuth/selBnfcrCntrcCnfmList', obj)
                .then((response) => ( this.cntrcCnfm = response.data.result ))
                .catch((error) => console.log('connect error /bnfcrAuth/selBnfcrCntrcCnfmList : ' + error))
        },
        setCntrcfmItem: function () {
            let obj = {}
            obj.cnfmCntnt = this.area

            http.post('bnfcrAuth/insCntrcCnfmItm', obj)
                .then((response) => ( this.afterCntrcfmItem(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/insCntrcCnfmItm : ' + error))
        },
        delCntrcCnfmItm: function (pk) {
            let obj = {}
            obj.cntrcCnfmItmPk = pk

            http.post('bnfcrAuth/delCntrcCnfmItm', obj)
                .then((response) => ( this.afterCntrcfmItem(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/delCntrcCnfmItm : ' + error))
        },
        afterCntrcfmItem: function (res) {
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == '200'){
                this.getCntrcfmList(this.bnfcrCntrcInfPk, '1')
                this.area = ''
            }
            else
                obj.type = 'warning'

            this.$store.commit('setWrnnInf', obj)
        },
        onClose: function () {
            this.$emit('modalClose', 0)
        },
    },
    
    data: () => ({
        area: '',
        cntrcCnfm: [],
    }),
};
</script>
