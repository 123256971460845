<template>
    <v-sheet class="pt-2 ma-0">
        <v-row class="pa-0 mb-2 align-center posn-rltv" no-gutters>
            <span
                class="d-inlne-block"
                style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">서류목록</span>
            <v-spacer></v-spacer>
            <v-btn
                class="white--text" style="padding: 9px 7px 8px 5px !important;"
                color="blueBtnColor" @click="onLclMdl('21', 900)"
                :disabled="$store.getters.getBnfcrState.bnmmbr == 0"
                min-width="20" height="20" small outlined>
                <v-icon class="mr-1" size="14">mdi-content-save-outline</v-icon>
                <span class="fontNineThRem" style="padding-bottom: 2px;">서류등록</span>
            </v-btn>
        </v-row>
        <v-divider :class="['mt-3', docMngCntList.length > 10 ? 'mr-2__5' : '']"></v-divider>
        <v-row :class="['greyE01 pa-0', docMngCntList.length > 10 ? 'mr-2__5' : '']" no-gutters>
            <v-col class="txtAlgnMid py-1 tableBR" cols="5">
                <span class="font-weight-medium">서류명</span>
            </v-col>
            <v-col class="txtAlgnMid py-1 tableBR" cols="1">
                <span class="font-weight-medium">사진개수</span>
            </v-col>
            <v-col class="txtAlgnMid py-1 tableBR" cols="1">
                <span class="font-weight-medium">파일개수</span>
            </v-col>
            <v-col class="txtAlgnMid py-1 tableBR" cols="2">
                <span class="font-weight-medium">동록일</span>
            </v-col>
            <v-col class="txtAlgnMid py-1 tableBR" cols="1">
                <span class="font-weight-medium">등록자</span>
            </v-col>
            <v-col class="txtAlgnMid py-1" cols="2">
                <span class="font-weight-medium">처리</span>
            </v-col>
        </v-row>
        <v-divider :class="[docMngCntList.length > 10 ? 'mr-2__5' : '']"></v-divider>
        <v-row
            v-if="docMngCntList.length == 0"
            class="py-2 tableBB"
            no-gutters>
            <v-col class="txtAlgnMid" cols="12">
                <span class="grey006--text">※ 조회된 목록이 없습니다.</span>
            </v-col>
        </v-row>
        <div
            v-else
            class="d-inlice-block overflow-y-auto" style="width: 100%; height: 370px;">
            <v-row
                class="pa-0 tableBB"
                v-for="(list, i) in docMngCntList" :key="i"
                no-gutters>
                <v-col class="overflow-text-hidden txtAlgnMid tableBR py-1" cols="5">
                    <span class="">{{ list.docNm }}</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR py-1" cols="1">
                    <span class="">{{ list.cntPict }}개</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR py-1" cols="1">
                    <span class="">{{ list.cntMtrl }}개</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR py-1" cols="2">
                    <span class="">{{ list.wrtDt }}</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR py-1" cols="1">
                    <span class="">{{ list.wrtr }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center" cols="2">
                    <v-btn
                        class="nmlBfClrImp-white mr-2"
                        color="grey006" min-width="20" height="20"
                        @click="onLclMdl('22', 900), sltDocIndex = i"
                        small icon>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" size="18">mdi-square-edit-outline</v-icon>
                            </template>
                            <span>서류 수정</span>
                        </v-tooltip>
                    </v-btn>
                    <v-btn
                        class="nmlBfClrImp-white"
                        color="grey006" min-width="20" height="20"
                        @click="rmDocBtn(list, i)"
                        small icon>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" size="18">mdi-trash-can-outline</v-icon>
                            </template>
                            <span>서류 삭제</span>
                        </v-tooltip>
                    </v-btn>
                </v-col>
            </v-row>
        </div>

        <!-- 팝업 영억 -->
        <v-dialog
            v-model="lclMdlInf.isOn"
            content-class="round" :max-width="lclMdlInf.maxWidth"
            persistent>
            <EtcDocRgstrMdl v-if="lclMdlInf.typ == '21'" @modalClose="rstLclMdlInf"></EtcDocRgstrMdl>
            <EtcDocMdfMdl v-else-if="lclMdlInf.typ == '22'" @modalClose="rstLclMdlInf"></EtcDocMdfMdl>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { selDocMngCntList, delDocMng } from '../../api/cmm.js';

import EtcDocRgstrMdl from './EtcDocRgstrMdl.vue';
import EtcDocMdfMdl from './EtcDocMdfMdl.vue';

export default {
    name: 'BnfcrDocMgmt',
            
    components: {
        EtcDocRgstrMdl,
        EtcDocMdfMdl,
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.$store.getters.getBnfcrState.bnmmbr != 0)
                this.getDocMngCntList(this.$store.getters.getBnfcrState.bnmmbr)
        })
    },

    computed: {
        getBnMmbrPk: function(){
            return this.$store.getters.getBnfcrState.bnmmbr
        },
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch:{
        getBnMmbrPk: function(v){
            if( v == 0 ){
                this.sltDocIndex = -1
                this.docMngCntList.splice(0)
            }
            else
                this.getDocMngCntList(v)
        },
        getSubEvt: function (v) {
            if(v && this.runAble == '1'){
                if(this.sltDocIndex > -1)
                    this.removeDocMng(this.docMngCntList[this.sltDocIndex].docMngPk)

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.sltDocIndex = -1
                this.runAble = '0'
            }
        },
    },

    destroyed: function(){
     
    },
        
    methods: {
        // 서류목록 조회
        getDocMngCntList: function (pk){
            selDocMngCntList(this.$store.getters.getFcltyNum, 26, 8, pk)
                .then((response) => ( this.getDocMngCntListAfter(response.data) ))
                .catch((error) => console.log('upload error /cmm/selDocMngCntList : ' + error))
        },
        
        // 서류 삭제
        removeDocMng: function (pk){
            delDocMng(this.$store.getters.getFcltyNum, this.$store.getters.getUserNm, pk)
                .then((response) => ( this.asyncResult(response.data) ))
                .catch((error) => console.log('upload error /cmm/delDocMng : ' + error))
        },

        getDocMngCntListAfter: function (res){
            if(res.length != 0){
                res.forEach(items => {
                    items.wrtDt = items.wrtDt?.beforeDateFormatDot()
                });
            }
            this.docMngCntList = res
        },

        asyncResult: function (res){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 3500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'warning'                        // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200){
                obj.tmr = 2500
                obj.type = 'info'

                this.getDocMngCntList(this.$store.getters.getBnfcrState.bnmmbr)
            }
            
            this.$store.commit('setWrnnInf', obj)
        },

        // 서류 삭제
        rmDocBtn: function (itm, idx) {
            // list.docMngPk

            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            obj.title = itm.docNm
            obj.cntnt = ['기타서류와 첨부파일 모두를 삭제하시겠습니까?']
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 삭제하겠습니다.'

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
            });

            this.sltDocIndex = idx
            this.runAble = '1'
        },
        
        // 통합 팝업 오픈
        onLclMdl: function (txt, wdt) {
            this.lclMdlInf.typ = txt
            this.lclMdlInf.maxWidth = wdt
            this.lclMdlInf.isOn = true
        },

        // 통합 팝업 초기화
        rstLclMdlInf: function () {
            this.lclMdlInf.typ = ''
            this.lclMdlInf.isOn = false
            this.sltDocIndex = -1
        },
    },
    
    data: () => ({
        runAble: '0',
        sltDocIndex: -1,
        docMngCntList: [],
        lclMdlInf: { isOn: false, maxWidth: 800, typ: '' },             // 통합 팝업 제어 (21: 서류등록)
    }),
};
</script>