<template>
    <v-sheet class="ma-0 pa-0" rounded="lg">
        <v-row class="pa-0 justify-center align-center" no-gutters>
            <span class="text-h4 font-weight-bold">낙상위험 방지 관련 확인사항</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="$parent.$parent.$parent.$parent.mdlOff" x-large>mdi-close</v-icon>
        </v-row>
        <v-divider class="mt-8 mr-1" color="black"></v-divider>
        <v-row
            v-for="(itm, i) in riskrList" :key="i"
            class="pa-0 mr-1 tableBB"
            no-gutters>
            <v-col class="nmlBfClrImp-greyE01 d-flex align-center tableBR py-2 px-4" cols="3">
                <span class="d-inline-block mr-1" style="width: 26px;">
                    <v-checkbox
                        class="dftChcBox-Theme pt-0" style="margin-top: 1px !important;"
                        v-model="itm.cnfmYn" @change="aplctSavBtn()"
                        true-value="1" false-value="2"
                        on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                        hide-details>
                    </v-checkbox>
                </span>
                <span class="font-weight-medium">{{ itm.cnfmCntnt }}</span>
            </v-col>
            <v-col class="overflow-text-hidden px-4" style="padding-top: 9px;" cols="9">
                <span class="">{{ itm.cnfmCntntDtl }}</span>
            </v-col>
        </v-row>
        <v-divider class="white my-2"></v-divider>
        <v-row class="pa-0 justify-center" no-gutters>
            <v-btn
                class="" color="blueBtnColor" min-width="110" height="36"
                :disabled="savBtn" @click="dcsnSavBtn">
                <span class="white--text fontOneDotTwoRem">저장</span>
            </v-btn>
        </v-row>
        <v-divider class="white mt-1"></v-divider>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

export default {
    name: 'FallRiskrSavMdl',
    
    components: {

    },

    props : { 
 
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            let obj = this.$store.getters.getRootInObj
            this.agrOfrPk = obj.agrOfrPk

            this.getRiskrList()
        })
    },

    computed: {
        
    },

    watch: {
      
    },
        
    methods: {
        // 낙상위험 조회
        getRiskrList: function (){
            this.riskrList.splice(0)

            let obj = {}
            obj.cnfmClcd = '2'
            obj.agrOfrPk = this.agrOfrPk
            obj.fcltyNum = this.$store.getters.getFcltyNum

            http.post('bnfcr/selBnfcrCntrcCnfmList', obj)
                .then((response) => ( this.riskrRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selBnfcrCntrcCnfmList : ' + error))
        },

        riskrRst: function (res){
            if(Array.isArray(res))
                this.riskrList = res

            this.aplctSavBtn()
        },

        // 낙상위험 방지 저장
        setRiskrList: function (obj){
            this.savBtn = true

            http.post('bnfcrAuth/insBnfcrCntrcCnFm', obj)
                .then((response) => ( this.cntrcCnfmRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/insBnfcrCntrcCnFm : ' + error))
        },

        cntrcCnfmRst: function (res){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == '200')
                this.savBtn = false
            else
                obj.type = 'warning'

            this.$store.commit('setWrnnInf', obj)
        },

        // 저장버튼 활성 여부 체크
        aplctSavBtn: function () {
            this.savBtn = true

            if(this.riskrList.length == 0)
                return 0

            this.savBtn = false
        },

        // 저장버튼 분기
        dcsnSavBtn: function () {
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = '시스템 오류입니다.'             // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            let insObj = {}
            insObj.agrOfrPk = this.agrOfrPk
            insObj.cnfmCntnt = this.riskrList

            if(this.agrOfrPk != -1 && this.riskrList.length > 0)
                this.setRiskrList(insObj)
            else{
                obj.type = 'warning'
                this.$store.commit('setWrnnInf', obj)
            }
        },
    },

    data: () => ({
        savBtn: true,
        agrOfrPk: -1,
        riskrList: [],
    }),
};
</script>