<template>
    <v-sheet>
        <v-row class="ma-0 pa-0 mt-3">    
            <v-col class="d-flex pa-0 ma-0" cols="12">                               
                <exBtn class="mr-1" :btnData="exBtn[0]" @btnClosed="expandClose" @changeCheck="filterSearch" />        
                <span class="d-inline-block" style="width: 130px;">
                    <v-text-field class="ma-0 pa-0 nameSearch" height="33px" background-color="white" append-icon="mdi-magnify"
                        single-line rounded outlined dense filled hide-details label="이름조회" @input="setSearchText">
                    </v-text-field>
                </span>
                <v-spacer></v-spacer>
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn v-if="list.type == 'action'" @click="onClickBtn(i)"
                        :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                        <v-icon small>{{list.icon}}</v-icon>
                        <span style="font-size: 1rem;">{{list.text}}</span>
                    </v-btn>
                </span>
            </v-col>
        </v-row>        
        <v-data-table
            hide-default-footer dense locale="ko" height="528"
            hide-default-header fixed-header 
            :headers="empMsgHeaders" 
            :items="empMsgList" 
            :search="schDummy"  
            :custom-filter="customFilter"               
            :loading="empWageloader"
            class="mt-3" disable-pagination>

            <template v-slot:header="{ props }" >
                <thead>
                    <tr>
                        <th class="clmsHeaderCommon tableBR tableBT" style="width: 5% !important;">
                            <span class="d-inline-block" style="width: 28px; height: 28px; padding-left: 2px;">
                                <v-checkbox
                                    class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                    v-model="allChckr" :key="forceRender" @click="allChnger"
                                    true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                    hide-details>
                                </v-checkbox>
                            </span>
                        </th>
                        <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 8% !important;" colspan="1">{{props.headers[0].text}}</th>
                        <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 10% !important;" colspan="1">{{props.headers[1].text}}</th>
                        <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 10% !important;" colspan="1">{{props.headers[2].text}}</th>
                        <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 10% !important;" colspan="1">{{props.headers[3].text}}</th>
                        <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 10% !important;" colspan="1">{{props.headers[4].text}}</th>
                        <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 10% !important;" colspan="1">{{props.headers[5].text}}</th>
                        <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 12% !important;" colspan="1">{{props.headers[6].text}}</th>
                        <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 8% !important;" colspan="1">{{props.headers[7].text}}</th>
                        <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 8% !important;" colspan="1">{{props.headers[8].text}}</th>
                        <th class="black--text clmsHeaderCommon tableBT" style="width: 9% !important;" colspan="1">{{props.headers[9].text}}</th>
                        
                    </tr>                        
                </thead>
            </template>                
            <template v-slot:item='{ item }'>
                <tr>
                    <td class="clmsBodyCommon tableBR">
                        <span class="d-inline-block" style="width: 28px; height: 28px; padding-left: 2px;">
                            <v-checkbox
                                class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                v-model="item.checkr" :key="forceRender" :disabled="item.disChekr"
                                true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline" @change="allChekrFunc"
                                hide-details>
                            </v-checkbox>
                        </span>
                    </td>
                    <td class="clmsBodyCommon tableBR">{{item.name}}</td>
                    <td class="clmsBodyCommon tableBR">{{item.jobNm}}</td>                        
                    <td class="clmsBodyCommon tableBR">{{item.birth}}</td>
                    <td class="clmsBodyCommon tableBR">{{item.wgeTypNm}}</td>
                    <td class="clmsBodyCommon tableBR">{{item.wgeActSum?.toLocaleString('ko-KR')}}</td>
                    <td class="clmsBodyCommon tableBR">{{item.pymDt?.beforeDateFormatDot()}}</td>                    
                    <td class="clmsBodyCommon tableBR">{{item.rcvAddr}}</td>
                    <td class="clmsBodyCommon tableBR">{{item.sndDt3?.beforeDateFormatDot()}}</td>
                    <td class="clmsBodyCommon tableBR">{{item.sndSttsNm3}}</td>
                    <td class="clmsBodyCommon">
                        <v-btn icon @click="onModal(item)">
                            <v-icon>mdi-clipboard-text-outline</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[0]" max-width="800" content-class="round" eager>
                <EmpWageStmtMsgEmailViw ref="empWageStmtMsgEmailViw" @modalClose="isClose"></EmpWageStmtMsgEmailViw>
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import exBtn from '../../components/commons/expandButtonCustom.vue';
import EmpWageStmtMsgEmailViw from './EmpWageStmtMsgEmailViw.vue'
import { insBizMsgNoSnd } from '../../api/othr.js'

export default {
    name: 'EmpWageStmtMsgSms',

    props : {       
        empWageloader:{type:Boolean, default:false}
    },
            
    components: {      
        exBtn,
        EmpWageStmtMsgEmailViw,
    },

    computed: {
       
    },  

    watch:{        
    },

    created:function(){ 
        this.$nextTick(function () { 
        })
    },
    
    mounted: function(){
        
    },

    methods: {
        onClickBtn:function(key){
            switch (key) {
                case 0:
                    this.onSend()
                    break;
            
                default:
                    break;
            }
        },
        setData:function(data){
            this.allChckr = false
            
            //직종코드
            this.$parent.$parent.$parent.$parent.vCd42.forEach(code => {
                this.exBtn[0].expandList.push(code.valcdnm)
            })

            this.empMsgList.splice(0)
            this.empMsgList = JSON.parse(JSON.stringify(data))

            this.empMsgList.forEach(el => {
                el.checkr   = '2'
                el.disChekr = false
                el.rcvAddr  = el.emailId+'@'+el.emailDmn
            });
        },
        onSend:function(){            
            let pObj = {  
                fcltyNum:this.$store.getters.getFcltyNum,
                userNm:this.$store.getters.getUserNm,                
                bizMsglist:[]
            }
            this.empMsgList.forEach(item => {

                if(item.checkr == '1'){
                    let title = this.$moment(item.yymm, 'YYYYMM', true).format('YYYY년MM월') +' 직원급여명세서'

                    let obj = {
                        sndMda:'3',
                        fcltyNum:this.$store.getters.getFcltyNum,
                        userNm:this.$store.getters.getUserNm,
                        bzClcd:'27',
                        bzPk:item.wgePymHisPk,
                        eDocClcd:'10',
                        val1:item.yymm,
                        itmCtt1: title,
                        itmCtt3:item.name,
                        itmCtt4:this.$moment(item.yymm, 'YYYYMM', true).format('YYYY년MM월'),                    
                        rcvCelphn1:item.celphn_1,
                        rcvCelphn2:item.celphn_2,
                        rcvCelphn3:item.celphn_3,
                        mmbrPk:item.mmbrPk,                            
                        rcvNm:item.name,
                        emailId:item.emailId,
                        emailDmn:item.emailDmn,
                        pstnCd:'3',
                        lnkUrl:item.lnkUrl
                    }

                    pObj.bizMsglist.push(obj)          
                }
            });
            if(pObj.bizMsglist.length > 0){
                insBizMsgNoSnd(pObj)
                    .then((response) => ( this.onSendAfter(response.data) ))
                    .catch((error) => console.log('connect error /othr/insBizMsgNoSnd : ' + error))
            }else{
                this.$emit('msgTrigger','대상자를 선택해주세요.')
            }
        },
        onSendAfter:function(res){
            this.$emit('mdlTrigger',res)            
        },        
        
        allChnger: function () {
            if(this.allChckr == '1') {
                this.empMsgList.forEach(item => {
                    if(item.disChekr == false) item.checkr = '1'
                });
            }
            else if(this.allChckr == '2') {
                this.empMsgList.forEach(item => {
                    if(item.disChekr == false) item.checkr = '2'
                });
            }
        },
        expandClose: function (index) {
            switch(index) {
                case 0: this.schChkField.target = 'jobNm'
                    break;
                default: this.schChkField.target = ''
                    break;
            }
        },
        filterSearch: function (sch) {       
            this.schChkField.jobNm.splice(0)

            if(sch.length > 0){
                sch.forEach((item) => { this.schChkField.jobNm.push(item) });            
                this.schDummy = this.schDummy + '.'
            }else{
                this.schDummy= ''
            }
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column
            let col0 = filter.name.includes(this.schField)
            
            let col1 = true
           
            if(this.schChkField.jobNm.length != 0){
                this.schChkField.jobNm.forEach((stcd, i) => {
                    if(i == 0) col1 = filter.jobNm.includes(stcd)
                    else col1 = col1 || filter.jobNm.includes(stcd)
                });
            }
          
            return col0 && col1;
        },
        setSearchText: function (value) {
            this.schField = value
            this.schDummy = this.schDummy + '.'
        },
        onModal:function(obj){         
            this.$refs.empWageStmtMsgEmailViw.onLoad(obj)
            this.dialog.splice(0,1,true)
        },
        isClose:function(){
            this.dialog.splice(0,1,false)
        } ,
        allChekrFunc: function () {
            let tCh = '1'
            this.empMsgList.forEach(el => {
                if(el.checkr == '2') tCh = '2'
            });
            this.allChckr = tCh
        },        
    },
    
    data: () => ({         
        forceRender:0,
        allChckr:false,
        schField: '',
        schDummy: '',
        schChkField: { jobNm: [], target: '' },  
        exBtn: [ 
            { index:  0, btnTitle: '직종', btnIcon: 'business', exWidth: 134, exHeigth: 220, expandList: [], },
        ],
        empMsgHeaders:[
            { text: '직원명', value: 'name', align: 'center'},
            { text: '직종', value: 'jobNm', align: 'center'},
            { text: '생년월일', value: 'birth', align: 'center'},
            { text: '임금형태', value: 'wgeTypNm', align: 'center'},
            { text: '실수령액', value: 'wgeActSum', align: 'center'},
            { text: '지급일자', value: 'pymDt', align: 'center'},
            { text: '연락처', value: 'rcvAddr', align: 'center'},
            { text: '발송일자', value: 'sndDt', align: 'center'},
            { text: '발송결과', value: 'sndSttsNm', align: 'center'},
            { text: '미리보기', value: '', align: 'center'},
            { text: '', value: '', align: 'center'},
        ],
        empMsgList:[],
        dialog:[false,],
        filledBtn: [
            { icon: 'mdi-send-variant', class: 'ml-1 white--text', color:'blueBtnColor', text: 'E-MAIL 발송', type: 'action', width:'600px', disabled: false, },
        ],
    }),
};
</script>