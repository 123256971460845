import axios from 'axios';

let prtcl = 'http'
let prt = 80
let dmn = 'dapi.kakao.com/'
let key = 'd43e435c503345086e6a13ec8024ef1b'

if (process.env.NODE_ENV == 'production'){
    prtcl = 'https'
    prt = 443
    key = '61254aaa684980b3343f4ddf82412882'
}

const geoCoder = axios.create({
    baseURL: prtcl + '://' + dmn + 'v2/local/search/address.json',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'KakaoAK ' + key
    },
    
    timeout: 0,
    withCredentials: false,

    proxy: {
        protocol: prtcl,
  
        port: prt,
        changeOrigin: true,
    },
})

export{
    geoCoder
}