<template>
    <v-sheet class="ma-0 pa-7" rounded="md">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col cols="7">
                <span class="text-h4 font-weight-bold">연차차감정보&nbsp;등록</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="1">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </v-col>
        </v-row>
        <v-form ref="ddctForm" lazy-validation>
            <v-divider class="mt-5"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="3">
                    <span class="black--text font-weight-medium">근무년차</span>
                </v-col>
                <v-col class="px-2 py-1 tableBL" cols="9">
                    <span class="d-inline-block" style="width: 100px;">
                        <v-select class="wrkSelect"
                            v-model="ddctInfo.wrkAnlMnt" height="28" :key="forceRender" :disabled="false"
                            item-text="valcdnm" item-value="valcd" :items="$parent.$parent.$parent.$parent.ddctMnthCode"
                            dense hide-details outlined>
                        </v-select>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="3">
                    <span class="black--text font-weight-medium">차감일자</span>
                </v-col>
                <v-col class="px-2 py-1 tableBL" cols="9">
                    <span class="d-inline-block" style="width: 133px;">
                        <CmmDateComp v-model="ddctInfo.ddcDt" :required="true"></CmmDateComp>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="3">
                    <span class="black--text font-weight-medium">잔여연차</span>
                </v-col>
                <v-col class="px-2 py-1 tableBL" cols="9">

                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="3">
                    <span class="black--text font-weight-medium">차감일수</span>
                </v-col>
                <v-col class="px-2 py-1 tableBL" cols="9">
                    <span class="d-inline-block py-1" style="width:60px;">
                        <v-text-field
                            class="numArea"
                            v-model="ddctInfo.ddcDys" :rules="[rules.required]"
                            height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="ml-2">일</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="3">
                    <span class="black--text font-weight-medium">비고</span>
                </v-col>
                <v-col class="px-2 py-1 tableBL" cols="9">
                    <span class="d-inline-block py-1" style="width: 100%">
                        <v-text-field
                            class="numArea" v-model="ddctInfo.rmrks"
                            height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
        </v-form>
        <v-row class="pa-0 mt-4" no-gutters>
            <v-col class="d-flex justify-center" cols="12">
                <v-btn 
                    class="ml-1 white--text" color="blueBtnColor" height="30"
                    @click="preEmpAnlDdc()"
                    samll rounded>
                    <v-icon class="mr-1" small>mdi-content-save-outline</v-icon>
                    <span class="mr-1" style="font-size: 1rem;">저장</span>
                </v-btn>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { insEmpAnlDdc } from '../../api/emp.js';

import CmmDateComp from '../commons/CmmDateComp.vue';

export default {
    name: 'AnlRgstMdl',

    props : {
   
    },
        
    components: {
        CmmDateComp,
    },

    created: function(){
   
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.ddctInfo.ddcDt = this.$moment().format('YYYY-MM-DD')

            this.$parent.$parent.$parent.$parent.ddctMnthCode.sort(
                function (a, b) {
                    let x = a.valcd
                    let y = b.valcd

                    if (x < y)
                        return 1
                    if (x > y)
                        return -1

                    return 0
            })
            if(this.$parent.$parent.$parent.$parent.ddctMnthCode.length > 0)
                this.ddctInfo.wrkAnlMnt = this.$parent.$parent.$parent.$parent.ddctMnthCode[0].valcd
        })
    },

    computed: {
        getWrkMnth: function () {
            return this.$parent.$parent.$parent.$parent.wrkMnth
        },
    },

    watch:{
        getWrkMnth: function () {
            this.$parent.$parent.$parent.$parent.ddctMnthCode.sort(
                function (a, b) {
                    let x = a.valcd
                    let y = b.valcd

                    if (x < y)
                        return 1
                    if (x > y)
                        return -1

                    return 0
            })
            if(this.$parent.$parent.$parent.$parent.ddctMnthCode.length > 0)
                this.ddctInfo.wrkAnlMnt = this.$parent.$parent.$parent.$parent.ddctMnthCode[0].valcd
        },
    },
    
    methods: {
        setEmpAnlDdc: function (obj) {
            insEmpAnlDdc(obj)
                .then((response) => ( this.asyncRstAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/insEmpAnlDdc : ' + error))
        },
        asyncRstAfter: function (res) {
            if(res.statusCode == 200){
                this.clearForm()
                this.$parent.$parent.$parent.$parent.getEmpAnlUseList(
                    this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk
                )
                this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.getEmpAnlUseInfo(
                    this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk,
                    this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.date
                )
                this.onClose()
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        preEmpAnlDdc: function () {
            let obj = Object.assign({}, this.ddctInfo)
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm
            obj.empPk = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk
            obj.wrkAnlMnt = this.$parent.$parent.$parent.$parent.wrkMnth
            obj.ddcDt = this.$moment(obj.ddcDt, 'YYYY-MM-DD', true).format('YYYYMMDD')

            if(this.$refs.ddctForm.validate())
                this.setEmpAnlDdc(obj)
            else
                this.snackControll([true, 3000, '필수 항목을 입력해주세요.', 'warning'])
        },
        clearForm: function () {
            this.ddctInfo.empPk = 0
            this.ddctInfo.empAnlDdcPk = 0
            this.ddctInfo.ddcDt = ''
            this.ddctInfo.rmnAnl = 0
            this.ddctInfo.ddcDys = 0
            this.ddctInfo.rmrks = ''

            this.$refs.ddctForm.resetValidation()

            this.forceRender += 1
        },
        onClose: function () {
            this.$emit('modalClose', '0')
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        forceRender: 0,
        ddctInfo: {
            // 직원정보, 연차차감정보, 기관코드, 작성자 
            empPk: 0, empAnlDdcPk: 0, fcltyNum: 0, userNm: '',
            // 근무월수, 차감일자, 잔여연차, 차감일수, 비고
            wrkAnlMnt: 1, ddcDt: '', rmnAnl: 0, ddcDys: 0, rmrks: '',
        },
        rules: {
            required: value => !!value || 'Required.',
            nbrChk: value => /^[0-9]*$/.test(value) || '숫자만 입력 가능합니다.',
        },
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>