<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div> 
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold">{{yyyyMm?.beforeDateFormatMonthDot()}}&nbsp;청구서&nbsp;발송&nbsp;관리</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>        
        <div class="mt-2" style="height: 650px;">
            <v-tabs
                v-model="tab" class="tab-dft-Theme" active-class="black"
                center-active hide-slider>
                <v-tab v-for="item in tabItems" :class="item.class" :key="item.tab" :disabled="item.enter">{{item.tab}}</v-tab>
            </v-tabs>
            <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
            <div>
                <v-tabs-items v-model="tab">
                    <v-tab-item eager transition="fade-transition">
                        <CopayClmsBillAlimTalk ref="copayClmsBillAlimTalk" @msgTrigger="msgTrigger" @mdlTrigger="mdlTrigger"></CopayClmsBillAlimTalk>
                    </v-tab-item>
                    <v-tab-item eager transition="fade-transition">
                        <CopayClmsBillSms ref="copayClmsBillSms" @msgTrigger="msgTrigger" @mdlTrigger="mdlTrigger"></CopayClmsBillSms>
                    </v-tab-item>
                    <v-tab-item eager transition="fade-transition">
                        <CopayClmsBillEmail ref="copayClmsBillEmail" @msgTrigger="msgTrigger" @mdlTrigger="mdlTrigger"></CopayClmsBillEmail>
                    </v-tab-item>
                    <v-tab-item eager transition="fade-transition">
                        <CopayClmsBillAll ref="copayClmsBillAll" @msgTrigger="msgTrigger" @mdlTrigger="mdlTrigger"></CopayClmsBillAll>
                    </v-tab-item>
                    <v-tab-item transition="false">
                        <CopayClmsBillHist v-if="tab == 4" ref="copayClmsBillHist"></CopayClmsBillHist>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </div>   
    </v-sheet>
</template>

<script>
import CopayClmsBillAlimTalk from './CopayClmsBillAlimTalk.vue'
import CopayClmsBillSms from './CopayClmsBillSms.vue'
import CopayClmsBillEmail from './CopayClmsBillEmail.vue'
import CopayClmsBillAll from './CopayClmsBillAll.vue'
import CopayClmsBillHist from './CopayClmsBillHist.vue'
import { selCopayEsndList } from '../../api/bnfcr.js';

export default {
    name: 'CopayClmsBillMdl',

    props : {        
        copayBillPk: { type: Number, default: 0 },                   //bzPk       
        yyyyMm:{type:String, default:''}          
    },
            
    components: {
        CopayClmsBillAlimTalk,
        CopayClmsBillSms,
        CopayClmsBillEmail,
        CopayClmsBillAll,
        CopayClmsBillHist,
    },

    created: function(){
       
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {  
            this.getCopayEsndList()      
        })
    },

    computed: {

    },

    watch: {
        'copayBillPk':function(value){
            if(value > 0){
                this.getCopayEsndList()
            }
        }
    },
        
    methods: {
        getCopayEsndList: function () {            
            selCopayEsndList(this.$store.getters.getFcltyNum, this.yyyyMm)
                .then((response) => ( this.getCopayEsndListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selCopayEsndList : ' + error))
        },
        getCopayEsndListAfter: function (res) {         
            // this.copayEsndList.splice(0)
            res.forEach(el => {                
                el.lnkUrl = this.encUrlObj(el.bnMmbrPk, el.bnfcrPk, el.clmYymm, el.copayClmHisPk)                
                // this.copayEsndList.push(el)
            });
            
            this.$refs.copayClmsBillAlimTalk.setData(res)
            this.$refs.copayClmsBillSms.setData(res)
            this.$refs.copayClmsBillEmail.setData(res)
            this.$refs.copayClmsBillAll.setData(res)
        },
        msgTrigger:function(msg){
            this.snackControll([true, 2500, msg, 'warning'])
        },
        mdlTrigger:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.getCopayEsndList()
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onClose: function () {
            this.tab = 0
            this.$emit('madalState')
        },     
        snackControll: function (options) {
            this.snackOptions = options
        },   
        //Url Encrypt
        encUrlObj: function(pk, bnfcr, yymm, bzPk){
            let linkURI = ''

            //개발, 운영 모드에 따라 링크 URL을 환경변수에서 불러옴
            switch (process.env.NODE_ENV) {
                case 'development':
                    linkURI = process.env.VUE_APP_MOBILE_DEV_URI
                    break;
                case 'production':
                    linkURI = process.env.VUE_APP_MOBILE_PROD_URI
                    break;
                default:
                    break;
            }

            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum,
                bnMmbrPk    : pk,
                bnfcrPk     : bnfcr,
                inqYYMM     : yymm,
                bzPk        : bzPk,
            }

            //오브젝트를 JSON 직렬화를 사용해 암호화 (문자열만 암호화 가능)
            let encrypted = this.$crypto.AES.encrypt(JSON.stringify(obj), this.$store.getters.getCrypKey)
            let bs64 = this.$crypto.enc.Base64.stringify(this.$crypto.enc.Utf8.parse(encrypted.toString()))
            return linkURI + 'out/payExpStt?dstnt=' + bs64
        },
        
    },
    
    data: () => ({
        tab:0,
        copayEsndList:[],
        tabItems: [
            { index: 0, tab: '알림톡', class: 'fontOneDotTwoRem px-15', enter: false },
            { index: 1, tab: '문자', class: 'fontOneDotTwoRem px-15', enter: false },
            { index: 2, tab: 'E-MAIL', class: 'fontOneDotTwoRem px-15', enter: false },
            { index: 3, tab: '일괄발송', class: 'fontOneDotTwoRem px-15', enter: false },
            { index: 4, tab: '발송내역', class: 'fontOneDotTwoRem px-15', enter: false },
        ],
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>