<template>
    <v-sheet class="ma-0 pa-7" rounded="md">
        <v-row class="mb-4 pa-0" no-gutters>
            <v-col cols="8">
                <span class="text-h4 font-weight-bold">{{item.title}}</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="1">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </v-col>
        </v-row>    
        
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mr-1" no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center pa-2 ma-0" cols="2">
                <span class="black--text font-weight-medium">제목</span>
            </v-col>
            <v-col class="pa-2 ma-0" cols="10">
                {{item.title}}
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mr-1" no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center pa-2 ma-0" cols="2">
                <span class="black--text font-weight-medium">작성자</span>
            </v-col>
            <v-col class="pa-2 ma-0" cols="4">
                {{item.crtr}}
            </v-col>
            <v-col class="greyE01 d-flex justify-center align-center pa-2 ma-0" cols="2">
                <span class="black--text font-weight-medium">작성일</span>
            </v-col>
            <v-col class="pa-2 ma-0" cols="4">
                {{item.crtDt}}
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mr-1" no-gutters>
            <v-col class="pa-2 ma-0 overflow-y-auto" cols="12" style="height:500px">
                <div v-html="item.cntntHtml"></div>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mr-1" no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center pa-2 ma-0" cols="2">
                <span class="black--text font-weight-medium">첨부파일</span>
            </v-col>
            <v-col class="pa-2 ma-0" cols="10">
                <div style="width:100%;min-height:50px;">                      
                    <v-chip v-for="(item, j) in fileRes" :key="j"                        
                        label                     
                        class="ma-1"
                        outlined
                        @click="fileDownload(j)"
                    >
                        <v-icon v-if="item.ext=='pdf' || item.ext=='PDF'" color='pink' left>
                            mdi-file-pdf-box
                        </v-icon>
                        <v-icon v-else-if="item.ext=='xlsx' || item.ext=='xls'" color='green' left>
                            mdi-microsoft-excel
                        </v-icon>
                        <v-icon v-else-if="item.ext=='pptx' || item.ext=='ppt'" color='red' left>
                            mdi-file-powerpoint
                        </v-icon>
                        <v-icon v-else-if="item.ext=='doc' || item.ext=='docx'" color='blue' left>
                            mdi-file-word
                        </v-icon>
                        <v-icon v-else color='cyan' left>
                            mdi-file-document
                        </v-icon>
                        {{ item.orgFileNm }}
                    </v-chip> 
                </div>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
    </v-sheet>
</template>

<script>
import { selAllYysBbsFileList } from '../../api/othr.js';
import {  fileDownload } from '../../api/index.js';

export default {
    name: 'YysNoticePop',

    props : {
        item:{type:Object, default:new Object}
    },
        
    components: {
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {            
            this.selYysBbsFileList()
        })
    },

    computed: {

    },

    watch:{

    },
    
    methods: { 
        onClose: function () {
            this.$emit('isClose')
        },

        selYysBbsFileList: function () {
            this.fileRes.splice(0)
            
            if(this.item.ntcBbsPk > 0){
                let obj = {                
                    bzPk:this.item.ntcBbsPk
                }      
    
                selAllYysBbsFileList(obj)
                    .then((response) => (this.selYysBbsFileListAfter(response.data)))
                    .catch((error) => console.log('connect error othr/selAllYysBbsFileList : ' + error))
            }
        },

        selYysBbsFileListAfter:function(res){            
            let obj = {}
            if(res.statusCode == 200){ 
                if(res.result !='' && res.result != null){  
                    res.result.forEach(el => {
                        let fileNm = el.fileNm.split(".")
                        el.ext = fileNm[1]  
                        this.fileRes.push(el)
                    });

                }
            } else {
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },
        fileDownload:function(idx){
            if(this.fileRes[idx] != undefined){
                fileDownload(this.fileRes[idx])
                    .then((response) => ( this.fileDownLoaderAfter(response) ))
                    .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
            }else{
                this.snackControll([true, 3000, '선택된 파일이 없습니다.', 'warning'])
            }
        },
        fileDownLoaderAfter: function (res) {
            let contentDisposition = decodeURI(res.headers['content-disposition'])
       
            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'));
                if (fileNameMatch)
                    [ , fileName ] = fileNameMatch.split('=');
            }
            link.href = url;
 
            fileName = fileName.replaceAll('"', '')
            link.setAttribute('download', `${fileName}`);
            link.style.cssText = 'display:none';
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
    },

    data: () => ({
        fileRes:[],
    }),
};
</script>