<template>
    <v-sheet
        class="d-inline-block rounded-lg rounded-l-0 pa-4"
        min-width="1260" max-width="1560" height="835">
        <v-row class="pa-0 mb-6" no-gutters>
            <v-col cols="3">
                <div class="text-h4 d-flex align-center">
                    <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                    <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">10-5. 이용요금 결제</span>
                    <v-spacer></v-spacer>
                    <!-- button area -->
                </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="3">
                <v-spacer></v-spacer>
                <v-btn
                    class="mr-1 fontOneRem white--text"
                    height="30" color="blueBtnColor"
                    :disabled="true"
                    @click="openPayments()"
                    rounded>
                    <v-icon class="mr-2" style="margin-top: 1px;" size="16">mdi-network-pos</v-icon>
                   <span>결제창 열기</span>
                </v-btn>
            </v-col>
        </v-row>
        <div class="d-flex justify-center align-center" style="height: 740px;">
            <div class="txtAlgnMid">
                <h1 class="font-weight-medium mb-2">현재 개발중입니다.</h1>
                <p>불편 드려 죄송합니다.</p>
            </div>
        </div>
        <!-- <div>{{ payResult }}</div> -->
    </v-sheet>
</template>

<script>
import * as PortOne from "@portone/browser-sdk/v2"

export default {
    name: 'PaymentMgmt',

    components: {

    },

    created: function () {

    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
          
        })
    },

    computed: {

    },

    watch: {

    },

    methods: {
        openPayments: async function(){
            let uuid = crypto.randomUUID()

            this.payResult = await PortOne.requestPayment({
                // Store ID
                storeId: 'store-63dcc3da-b89f-4475-beca-767639801f57',
                // 채널 키
                // 정식(카드) - channel-key-daed861f-70c4-4c4e-bf3c-12522a231013
                // 정식(계좌) - channel-key-3a9bdb6a-bea6-450e-9e5b-ee71a112f7aa
                // 테스트 - channel-key-58bdfbf6-0c51-462f-a778-c5976beafbe4
                channelKey: 'channel-key-58bdfbf6-0c51-462f-a778-c5976beafbe4',
                // 주문 고유 번호
                paymentId: 'payment-' + uuid,
                // 주문명
                orderName: '요양시스 이용권 구매',
                // 결제 금액
                totalAmount: 1000,
                // 결제통화
                currency: 'KRW',
                // 결제수단 구분코드 (CARD-카드, VIRTUAL_ACCOUNT-가상계좌, TRANSFER-계좌이체)
                payMethod: 'CARD',
                // 상품 유형
                productType: 'PRODUCT_TYPE_DIGITAL',
                country : 'KR',
                customer: {
                    customerId: '1',
                    fullName: '홍길동',
                    phoneNumber: '031-206-1256',
                    email: 'info@harrygnd.com',
                },
                storeDetails: {

                },
                // bypass: {
                //     ksnet: { sndQpayType: '0' }
                // },
                // virtualAccount: {
                //     cashReceiptType: 'CORPORATE',
                //     accountExpiry: { validHours: 1 }
                // },
                card: {
                    useFreeInterestFromMall: false,
                    installment: {
                        monthOption: { availableMonthList: [0, 2, 3, 4, 5] }
                    },
                },
                // transfer: {
                //     cashReceiptType: 'ANONYMOUS',
                // },
                confirmUrl: 'http://121.168.112.242/pym/complete',
            });
        },
    },

    data: () => ({
        payResult: null,
    }),
};
</script>