<template>
    <v-sheet class="rounded-lg rounded-l-0 pa-1" color="" min-width="1260" max-width="1560">
        <!-- contents -->
        <div class=" mt-2 d-flex align-center pr-2">
            <div class="text-h4 px-2">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">
                    <span>9-2. 직원현황</span>
                </span>
            </div>
            <v-spacer></v-spacer>
            <v-btn v-for="(list, j) in outlinedBtn" :key="j" @click = "onClickBtn(list.text)"
                :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded outlined>
                <v-icon style="padding-top: 2px;" small>{{list.icon}}</v-icon>
                <span class="ml-1" style="font-size: 1rem;">{{list.text}}</span>
            </v-btn>
        </div>
        <div class=" pr-2 mt-2 px-2">
            <v-tabs
                v-model="tab" class="tab-dft-Theme" active-class="black"
                center-active hide-slider>
                <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class">{{ item.tab }}</v-tab>
            </v-tabs>
            <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
            <v-tabs-items v-model="tab">
                <v-tab-item transition="fade-transition">
                    <tab1 ref="tab1" :tab="tab"></tab1>
                </v-tab-item>
                <v-tab-item transition="fade-transition">
                    <tab2 ref="tab2" :tab="tab"></tab2>
                </v-tab-item>
                <v-tab-item transition="fade-transition">
                    <tab3 ref="tab3" :tab="tab"></tab3>
                </v-tab-item>
            </v-tabs-items>
        </div>
    </v-sheet>
</template>

<script>
import tab1 from '../../components/btcInq/EmpInfSttsTab1.vue';
import tab2 from '../../components/btcInq/EmpInfSttsTab2.vue';
import tab3 from '../../components/btcInq/EmpInfSttsTab3.vue';

export default {
    name: 'EmpInfStts',
        
    components: {
        tab1,
        tab2,
        tab3,
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            // this.getExcelInfo()
        })
    },

    computed: {

    },

    watch:{ 
        'tab': function () {
            if(this.tab == 0){
                this.outlinedBtn.forEach(items => {
                    items.disabled = false
                });
            }else if (this.tab == 2){
                this.outlinedBtn.forEach(items => {
                    items.disabled = false
                });
            }else{
                this.outlinedBtn.forEach(items => {
                    items.disabled = false
                });
            }
        },
    },
        
    methods: {
        onClickBtn: function (value){
            switch (value) {
                case '엑셀':
                    if(this.tab == 0){
                        this.$refs.tab1.xlsxProd()
                    }else if (this.tab == 1){
                        this.$refs.tab2.xlsxProd()
                    }else if (this.tab == 2){
                        this.$refs.tab3.xlsxProd()
                    }
                    break;
                case '출력':
                    if(this.tab == 0){
                        this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
                        this.prtThrowObj.prtCd = '18'
                        this.prtThrowObj.inqYMD= this.$refs.tab1.date

                        this.$store.commit('setRootInf', {
                            actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
                        });
                    }else if (this.tab == 1){
                        this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
                        this.prtThrowObj.prtCd = '38'
                        this.prtThrowObj.inqYmdStart = this.$moment(this.$refs.tab2.date).startOf('month').format('YYYYMMDD')
                        this.prtThrowObj.inqYmdEnd = this.$moment(this.$refs.tab2.date).endOf('month').format('YYYYMMDD')
                     
                        this.$store.commit('setRootInf', {
                            actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
                        });
                    }else if (this.tab == 2){
                        this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
                        this.prtThrowObj.prtCd = '36'
                        if(this.$refs.tab3.srchCd  == 0){
                            this.prtThrowObj.inqYmdStart = this.$refs.tab3.date
                            this.prtThrowObj.inqYmdEnd = this.$refs.tab3.date
                        }else if (this.$refs.tab3.srchCd == 1){
                            this.prtThrowObj.inqYmdStart = this.$refs.tab3.mDate + '01'
                            this.prtThrowObj.inqYmdEnd = this.$moment(this.$refs.tab3.mDate).endOf('month').format('YYYYMMDD')
                        }else if (this.$refs.tab3.srchCd == 2){
                            this.prtThrowObj.inqYmdStart = this.$moment(this.$refs.tab3.yDate, 'YYYY').startOf('year').format('YYYYMMDD')
                            this.prtThrowObj.inqYmdEnd = this.$moment(this.$refs.tab3.yDate, 'YYYY').endOf('year').format('YYYYMMDD')
                        }

                        this.$store.commit('setRootInf', {
                            actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
                        });
                    }
                    break;
                default:
                    break;
            }
        },
        
    },
    
    data: () => ({
        tab:0,
        tabItems: [
            { index: 0, tab: '직원현황', class: 'fontOneDotTwoRem px-15'}, 
            { index: 1, tab: '연차내역', class: 'fontOneDotTwoRem px-15'},
            { index: 2, tab: '휴가사용내역', class: 'fontOneDotTwoRem px-15'},
        ],
        outlinedBtn: [
            { icon: 'mdi-microsoft-excel', class:'', color:'grey006', text: '엑셀', type: 'action', width: '500px', visible: true, disabled: false, },
            { icon: 'mdi-printer-outline', class:'ml-2', color:'grey006', text: '출력', type: 'action', width: '500px', visible: true, disabled: false, },
        ],
        dialog: [false, false],
        excelInfo: { filePth: '', fileNm: '', orgFileNm: '' },
        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '', inqYmdStart: '', inqYmdEnd: '', inqYMD: '',
            rptTyp: '1', mode: '2', list: [ { bzClcd: [], bzPk: [] } ],
        },
    }),
};
</script>