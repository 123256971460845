<template>
    <v-sheet>
        <div class="d-flex justify-center align-center" style="position:relative;"> 
            <div>
                <expandNowDatePicker @nowDate="getDate"></expandNowDatePicker>
            </div>
            <div style="position:absolute;right:10px;">
                <v-btn
                    class="ml-1" color="blueBtnColor" style="padding: 13px 12px 12px 10px !important"   
                    @click="menu.splice(0, 1, true)"                 
                    min-width="30" height="25" small outlined>
                    <v-icon size="20">mdi-text-box-outline</v-icon>
                    <span class="fontOneRem ml-1" style="padding-bottom: 2px;">수급자 특이사항</span>                    
                </v-btn>
            </div>
        </div>
        <div>
            <div class="mt-3">
                <div class="mr-2__5">
                    <v-divider class=""></v-divider>
                    <v-row class="greyE01" no-gutters>                    
                        <v-col class="d-flex py-2 justify-center align-center " cols="2">
                            <span class="black--text font-weight-medium">식사</span>
                        </v-col>
                        <v-col class="tableBL" cols="10">
                            <v-row no-gutters>
                                <v-col class="d-flex py-2 justify-center align-center" cols="2">
                                    <span class="black--text font-weight-medium">시간대</span>
                                </v-col>
                                <v-col class="d-flex py-2 justify-center align-center tableBL" cols="10">
                                    <span class="black--text font-weight-medium">수급자</span>
                                </v-col>
                            </v-row>
                        </v-col>            
                    </v-row>
                    <v-divider class=""></v-divider> 
                </div>
                <div style="height:360px;overflow-y:scroll">
                    <div v-for="(item ,i) in dietsList" :key="i">
                        <v-row no-gutters>
                            <v-col class="d-flex py-1 justify-center align-center " cols="2">
                                <span class="black--text font-weight-medium">{{item.valcdnm}}</span>
                            </v-col>
                            <v-col class="tableBL" cols="10">
                                <div v-for="(list, j) in item.list" :key="j">
                                    <v-divider v-if="j != 0" class=""></v-divider>  
                                    <v-row no-gutters>
                                        <v-col class="d-flex py-1 justify-center align-center" cols="2">
                                            <span>{{list.mlClcdNm}}</span>
                                            <span class="pl-1">&#40;</span>
                                            <span>{{list.mlTmHh}}</span>
                                            <span>&#58;</span>
                                            <span>{{list.mlTmMm}}</span>
                                            <span>&#41;</span>
                                        </v-col>
                                        <v-col class="d-flex tableBL" cols="10">
                                            <div style="width:95%">
                                                <span class="pa-1" v-for="(bnfcr, n) in list.mlBnfcr" :key="n">{{bnfcr.name}}</span>
                                            </div>
                                            <div class="d-flex justify-center align-center red--text" style="width:5%;font-size:0.9rem">
                                                &#40;{{list.mlBnfcr.length}} 명&#41;
                                            </div>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                        <v-divider class=""></v-divider>               
                    </div>
                </div>
            </div>
            <v-divider class="mt-1 mr-2__5" style="border:1px dashed #dbdbdb"></v-divider>
            <div v-if="initList.length > 0" class="mt-3">
                <v-divider class="mr-2__5"></v-divider>
                <v-row class="greyE01 mr-2__5" no-gutters>                
                    <v-col class="d-flex py-2 justify-center align-center " cols="1">
                        <span class="black--text font-weight-medium">치료식이</span>
                    </v-col>
                    <v-col class="d-flex py-2 justify-center align-center tableBL" cols="11">
                        <span class="black--text font-weight-medium">수급자</span>
                    </v-col>                     
                </v-row>
                <v-divider class="mr-2__5"></v-divider>
                <div class="overflow-y-auto" style="height:120px;overflow-y:scroll">
                    <div v-for="(item, j) in initList" :key="j" >
                        <v-row no-gutters>                
                            <v-col class="d-flex py-2 justify-center align-center greyE01" cols="1">
                                <span class="black--text font-weight-medium">{{item.itmVal1}}</span>
                            </v-col>
                            <v-col class="d-flex pa-2 justify-left align-center tableBL" cols="11">
                                <div style="width:95%">
                                    <span v-for="(cdItme, m) in item.mlBnfcrs" :key="m">
                                        <span v-if="m > 0" class="pr-1">,</span>
                                        <span>{{cdItme.name}}</span>
                                    </span>
                                </div>
                                <div v-if="item.mlBnfcrs != undefined" class="d-flex justify-center align-center red--text" style="width:5%;font-size:0.9rem">
                                    &#40;{{item.mlBnfcrs.length}} 명&#41;
                                </div>
                            </v-col>
                            <v-divider class=""></v-divider>                       
                        </v-row>
                        <v-divider class=""></v-divider>
                    </div>
                </div>
                <v-divider class="mt-1 mr-2__5" style="border:1px dashed #dbdbdb"></v-divider>
            </div>
            <v-row class="mt-3" style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;" no-gutters>                
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1">
                    <span class="black--text font-weight-medium">일반식</span>
                </v-col>
                <v-col class="pa-1 ma-0" cols="1">
                    <div class="d-flex justify-center align-center">
                        아침 : {{totArr['clcd11']}} 명
                    </div>
                    <div class="d-flex justify-center align-center">
                        점심 : {{totArr['clcd12']}} 명
                    </div>
                    <div class="d-flex justify-center align-center">
                        저녁 : {{totArr['clcd13']}} 명
                    </div>
                </v-col>  
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1">
                    <span class="black--text font-weight-medium">다진식</span>
                </v-col>
                <v-col class="pa-1 ma-0" cols="1">
                    <div class="d-flex justify-center align-center">
                        아침 : {{totArr['clcd21']}} 명
                    </div>
                    <div class="d-flex justify-center align-center">
                        점심 : {{totArr['clcd22']}} 명
                    </div>
                    <div class="d-flex justify-center align-center">
                        저녁 : {{totArr['clcd23']}} 명
                    </div>
                </v-col>           
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1">
                    <span class="black--text font-weight-medium">죽식</span>
                </v-col>
                <v-col class="pa-1 ma-0" cols="1">
                    <div class="d-flex justify-center align-center">
                        아침 : {{totArr['clcd31']}} 명
                    </div>
                    <div class="d-flex justify-center align-center">
                        점심 : {{totArr['clcd32']}} 명
                    </div>
                    <div class="d-flex justify-center align-center">
                        저녁 : {{totArr['clcd33']}} 명
                    </div>
                </v-col>             
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1">
                    <span class="black--text font-weight-medium">미음</span>
                </v-col>
                <v-col class="pa-1 ma-0" cols="1">
                    <div class="d-flex justify-center align-center">
                        아침 : {{totArr['clcd61']}} 명
                    </div>
                    <div class="d-flex justify-center align-center">
                        점심 : {{totArr['clcd62']}} 명
                    </div>
                    <div class="d-flex justify-center align-center">
                        저녁 : {{totArr['clcd63']}} 명
                    </div>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1">
                    <div>
                        <div><span class="black--text font-weight-medium">유동식</span></div>
                        <div><span class="black--text font-weight-medium">&#40;경관식&#41;</span></div>
                    </div>
                </v-col>
                <v-col class="pa-1 ma-0" cols="1">
                    <div class="d-flex justify-center align-center">
                        아침 : {{totArr['clcd41']}} 명
                    </div>
                    <div class="d-flex justify-center align-center">
                        점심 : {{totArr['clcd42']}} 명
                    </div>
                    <div class="d-flex justify-center align-center">
                        저녁 : {{totArr['clcd43']}} 명
                    </div>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1">
                    <span class="black--text font-weight-medium">간식</span>
                </v-col>
                <v-col class="pa-1 ma-0" cols="1">
                    <div class="d-flex justify-center align-center">
                        오전 : {{totArr['clcd54']}} 명
                    </div>
                    <div class="d-flex justify-center align-center">
                        오후 : {{totArr['clcd55']}} 명
                    </div>
                </v-col>
            </v-row>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="menu[0]" content-class="round" max-width="600" persistent>
                <WeklyMenuDietsSpc 
                    v-if="menu[0]" 
                    ref="weklyMenuDietsSpc" 
                    @isClose="menu.splice(0, 1, false)"></WeklyMenuDietsSpc>
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import { getMultiStcd } from '../../api/index.js';
import { selBnfcrDietsThrpList, selBnfcrDietsList, selBnfcrDietsSpclList } from '../../api/bnftSrv.js';
import { selFcltyInitList } from '../../api/opr.js';

import expandNowDatePicker from '../../components/commons/expandNowDatePicker.vue';
import WeklyMenuDietsSpc from '../../components/bnftSrv/WeklyMenuDietsSpc.vue';     //수급자 식이정보 특이사항


export default {
    name: 'WeklyMenuTab2',

    props : {
        tab:Number,
    },
    
    components: {
       expandNowDatePicker,
       WeklyMenuDietsSpc,
    },
    
    computed: {        
    },  

    watch:{
    },

    created:function(){
        this.onCodeload()
        this.getFcltyInitList()      
    },

    mounted: function(){
        this.$nextTick(function () { 
           
        })
    },
        
    methods: {   
        getDate: function (value) {
            this.sltDate = value.date     
            this.getBnfcrDietsList()       
            this.getBnfcrDietsThrpList()
            this.getBnfcrDietsSpclList()
        },
        //식사종류 조회
        onCodeload:function(){
            getMultiStcd(['14'], this.$store.getters.getFcltyNum)
                .then((response) => ( this.codeMapping(response.data) ))
                .catch((error) => console.log(error))
        },
        //식이정보 조회
        getBnfcrDietsList:function(){
            selBnfcrDietsList(this.$store.getters.getFcltyNum, this.sltDate)
                    .then((response) => ( this.getBnfcrDietsListAfter(response.data) ))
                    .catch((error) => console.log('upload error /opr/selBnfcrDtryList : ' + error))
        },
        //치료식이조회
        getFcltyInitList:function(){            
            selFcltyInitList(this.$store.getters.getFcltyNum, ['3'])
                    .then((response) => ( this.getFcltyInitListAfter(response.data) ))
                    .catch((error) => console.log('upload error /opr/selFcltyInitList : ' + error))
        },
        //수급자식이정보 치료식이 다건 조회
        getBnfcrDietsThrpList:function(){
            selBnfcrDietsThrpList(this.$store.getters.getFcltyNum, this.sltDate)
                    .then((response) => ( this.getBnfcrDietsThrpListAfter(response.data) ))
                    .catch((error) => console.log('upload error /opr/selBnfcrDietsThrpList : ' + error))
        },
        //수급자식이정보 특이사항 다건 조회
        getBnfcrDietsSpclList:function(){
            let obj = {
                fcltyNum : this.$store.getters.getFcltyNum, 
                inqYMD : this.sltDate,
            }
            selBnfcrDietsSpclList(obj)
                    .then((response) => ( this.getBnfcrDietsSpclListAfter(response.data) ))
                    .catch((error) => console.log('upload error /opr/selBnfcrDietsThrpList : ' + error))
        },
        
        codeMapping:function(res){
            this.vCd14 = res["vCd14"]
        },
        getBnfcrDietsListAfter:function(res){
            console.log(res)
            this.dietsList.splice(0)
            this.dietsTot   = 0

            this.vCd14.forEach(el => {
                let obj = {
                    valcdnm     : el.valcdnm,
                    valcd       : el.valcd, 
                    list        : []
                }

                let list = res.filter(x=>x.mlTycd == el.valcd)
                if(list != null){                    
                    list.forEach(itm => {
                        this.dietsTot++
                        let mlBnfcr = {}

                        if(itm.mlBnfcr != null){
                            mlBnfcr = JSON.parse(itm.mlBnfcr.value)
                        } 
    
                        let item = {
                            mlClcdNm    : '',
                            mlClcd      : itm.mlClcd,
                            mlTmHh      : itm.mlTmHh,
                            mlTmMm      : itm.mlTmMm,
                            mlBnfcr     : mlBnfcr,
                        }
    
                        switch (itm.mlClcd) {
                            case '1':
                                item.mlClcdNm = '아침'
                                obj.list.push(item)
                                break;
                            case '2':
                                item.mlClcdNm = '점심'
                                obj.list.push(item)
                                break;
                            case '3':
                                item.mlClcdNm = '저녁'
                                obj.list.push(item)
                                break;
                            case '4':
                                item.mlClcdNm = '오전'
                                obj.list.push(item)
                                break;
                            case '5':
                                item.mlClcdNm = '오후'
                                obj.list.push(item)
                                break;
                            default:
                                break;
                        }
                    });
                }
                
                this.dietsList.push(obj)
            });

            this.getBnfcrDietsCalcl()
        },
        getFcltyInitListAfter:function(res){
            this.initList.splice(0)
            res.forEach(el => {
                this.initList.push(el)
            });
        },
        getBnfcrDietsThrpListAfter:function(res){
            this.dtryThrpList.splice(0)
            if(res != '' && res != null){
                res.forEach(el => {
                    if(el.mlBnfcr != null){
                        if(el.mlBnfcr.value != undefined && el.mlBnfcr.value != null) {
                            el.mlBnfcrs = JSON.parse(el.mlBnfcr.value)
                        }
                    }
    
                    this.dtryThrpList.push(el)
                });
                
                this.initList.forEach(el => {
                    let idx = this.dtryThrpList.findIndex(x=>x.thrpCd == el.itmCd1)
                    el.mlBnfcrs = []
    
                    if(idx > -1){
                        if(this.dtryThrpList[idx].mlBnfcrs != null){
                            this.dtryThrpList[idx].mlBnfcrs.forEach(itm => {
                                el.mlBnfcrs.push(itm)
                            });
                        }
                    }
                });
            }
            this.forceRender++
        },
        getBnfcrDietsSpclListAfter:function(res){            
            this.spclList.splice(0)
            res.forEach(el => {
                this.spclList.push(el)
            });            
        },
        getBnfcrDietsCalcl:function(){
            this.totArr = Object.assign({
                'clcd11':0,
                'clcd12':0,
                'clcd13':0,
                'clcd21':0,
                'clcd22':0,
                'clcd23':0,
                'clcd31':0,
                'clcd32':0,
                'clcd33':0,
                'clcd41':0,
                'clcd42':0,
                'clcd43':0,
                'clcd54':0,
                'clcd55':0,
                'clcd61':0,
                'clcd62':0,
                'clcd63':0,
            })

            this.dietsList.forEach(el => {
                el.list.forEach(itm => {
                    if(itm.mlClcd != 0){
                        if(itm.mlBnfcr.length != undefined) this.totArr['clcd'+el.valcd+itm.mlClcd] += itm.mlBnfcr.length
                    }
                });
            });
        },
    },
    
    data: () => ({ 
        forceRender:0,
        menu:[false,],
        sltDate:'',
        vCd14:[],
        initList:[],
        spclList:[],
        dietsTot:0,
        dietsList:[{valcdnm : '', valcd:'', list:[{mlClcdNm:'', mlClcd:'', mlTmHh:'', mlTmMm:'', mlBnfcr :[],}]}],
        dtryThrpList:[],
        totArr:{'clcd11':0,'clcd12':0,'clcd13':0,'clcd21':0,'clcd22':0,'clcd23':0,'clcd31':0,'clcd32':0,'clcd33':0,'clcd41':0,'clcd42':0,'clcd43':0,'clcd54':0,'clcd55':0,'clcd61':0,'clcd62':0,'clcd63':0,}
    }),
};
</script>