<template>
    <v-sheet class="d-flex rounded-lg rounded-l-0" color="" min-width="1260" max-width="1560" height="835">
        <v-sheet class="d-inline-block" :width="midAreaWidth" height="835" color="midAreaColor">
            <div class="text-h4 px-2">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">
                    <span v-if="routerRocation == 'AllBlnftSrvRcrd'">3-1. 전체&nbsp;급여제공기록</span>
                    <span v-else-if="routerRocation == 'CareBlnftSrvRcrd'">3-2. 요양&nbsp;급여&nbsp;제공기록</span>
                    <span v-else-if="routerRocation == 'CncntExcrtObsrvRcrd'">3-3. 집중&nbsp;배설&nbsp;관찰기록</span>
                    <span v-else-if="routerRocation == 'HlthBlnftSrvRcrd'">3-5. 건강&nbsp;급여&nbsp;제공기록</span>
                    <span v-else-if="routerRocation == 'NsgCareRcrd'">3-6. 간호&nbsp;처치기록</span>
                    <span v-else-if="routerRocation == 'PhyscSnctnRcrd'">3-8. 신체제재기록</span>
                    <span v-else-if="routerRocation == 'SpclBdrmUseRcrd'">3-9. 특별침실사용기록</span>
                    <span v-else-if="routerRocation == 'EmrgnRcrd'">3-10. 응급상황기록</span>
                    <span v-else-if="routerRocation == 'NsgRcrdStts'">9-4. 간호기록현황</span>
                </span>
            </div>
            <div class="mt-4 mx-3">
                <div v-if="routerRocation == 'AllBlnftSrvRcrd'" class="d-flex justify-center mb-2">
                    <expandNowDatePicker @nowDate="getDate"></expandNowDatePicker>
                </div>
                <div class="d-flex">
                    <exBtn class="mr-1" :btnData="exBtn[0]" @btnClosed="expandClose" @changeCheck="filterSearch" />
                    <!-- <exBtn v-if="routerRocation != 'EmrgnRcrd' && routerRocation != 'SpclBdrmUseRcrd'"
                        class="mr-1" :btnData="exBtn[1]" @btnClosed="expandClose" @changeCheck="filterSearch" /> -->
                    <exBtn v-if="routerRocation == 'NsgRcrdStts'"
                        class="mr-1" :btnData="exBtn[1]" @btnClosed="expandClose" @changeCheck="filterSearch" />
                    <CmmLvnExpnBtn
                        v-if="routerRocation != 'EmrgnRcrd' && routerRocation != 'SpclBdrmUseRcrd'"
                        v-model="isLvnStt"
                        class="ml-1"
                        @onList="lvnSttApply">
                    </CmmLvnExpnBtn>
                    <v-spacer></v-spacer>
                    
                    <span class="d-inline-block" style="width: 130px;">
                        <v-text-field class="ma-0 pa-0 nameSearch" height="35px" background-color="white" append-icon="mdi-magnify"
                            single-line rounded outlined dense filled hide-details label="이름조회" @input="setSearchText">
                        </v-text-field>
                    </span>
                </div>
            </div>
            <div class="mt-2 mx-3 rounded-lg">
                <v-data-table class="dft-hvr-tbl pt-1" style="position: relative;" locale="ko"
                    :height="routerRocation == 'AllBlnftSrvRcrd' ? 650 : 690" :key="forceRender"
                    :headers="headerList" :items="bnftrcrdList" :search="schDummy" :custom-filter="customFilter"
                    disable-pagination fixed-header hide-default-header hide-default-footer dense>
                    <template v-slot:header="{ props }" >
                        <thead>
                            <tr>
                                <th v-if="props.headers[0].on" :class="props.headers[0].class" :style="props.headers[0].style">{{props.headers[0].text}}</th>
                                <th v-if="props.headers[1].on" :class="props.headers[1].class" :style="props.headers[1].style">{{props.headers[1].text}}</th>
                                <th v-if="props.headers[2].on" :class="props.headers[2].class" :style="props.headers[2].style">{{props.headers[2].text}}</th>
                                <th v-if="props.headers[3].on" :class="props.headers[3].class" :style="props.headers[3].style">{{props.headers[3].text}}</th>
                                <th v-if="props.headers[4].on" :class="props.headers[4].class" :style="props.headers[4].style">{{props.headers[4].text}}</th>
                                <th v-if="props.headers[5].on" :class="props.headers[5].class" :style="props.headers[5].style">{{props.headers[5].text}}</th>
                                <th v-if="props.headers[6].on" :class="props.headers[6].class" :style="props.headers[6].style">{{props.headers[6].text}}</th>
                                <th v-if="props.headers[7].on" :class="props.headers[7].class" :style="props.headers[7].style">{{props.headers[7].text}}</th>
                                <th v-if="props.headers[8].on" :class="props.headers[8].class" :style="props.headers[8].style">{{props.headers[8].text}}</th>
                                <th v-if="props.headers[9].on" :class="props.headers[9].class" :style="props.headers[9].style">{{props.headers[9].text}}</th>
                                <th v-if="props.headers[10].on" :class="props.headers[10].class" :style="props.headers[10].style">{{props.headers[10].text}}</th>
                                <th v-if="props.headers[11].on" :class="props.headers[11].class" :style="props.headers[11].style">{{props.headers[11].text}}</th>
                                <th v-if="props.headers[12].on" :class="props.headers[12].class" :style="props.headers[12].style">{{props.headers[12].text}}</th>
                                <th v-if="props.headers[13].on" :class="props.headers[13].class" :style="props.headers[13].style">{{props.headers[13].text}}</th>
                                <th v-if="props.headers[14].on" :class="props.headers[14].class" :style="props.headers[14].style">{{props.headers[14].text}}</th>
                                <th v-if="props.headers[15].on" :class="props.headers[15].class" :style="props.headers[15].style">{{props.headers[15].text}}</th>
                                <th v-if="props.headers[16].on" :class="props.headers[16].class" :style="props.headers[16].style">{{props.headers[16].text}}</th>
                                <th v-if="props.headers[17].on" :class="props.headers[17].class" :style="props.headers[17].style">{{props.headers[17].text}}</th>
                                <th v-if="props.headers[18].on" :class="props.headers[18].class" :style="props.headers[18].style">{{props.headers[18].text}}</th>
                                <th v-if="props.headers[19].on" :class="props.headers[19].class" :style="props.headers[19].style">{{props.headers[19].text}}</th>
                                <th v-if="props.headers[20].on" :class="props.headers[20].class" :style="props.headers[20].style">{{props.headers[20].text}}</th>
                                <th v-if="props.headers[21].on" :class="props.headers[21].class" :style="props.headers[21].style">{{props.headers[21].text}}</th>
                                <th v-if="props.headers[22].on" :class="props.headers[22].class" :style="props.headers[22].style">{{props.headers[22].text}}</th>
                                <th v-if="props.headers[23].on" :class="props.headers[23].class" :style="props.headers[23].style">{{props.headers[23].text}}</th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item }'>
                        <tr
                            :class="['fixedCol', sltBnMmBrPk == item.bnMmbrPk ? 'sltRow' : 'disSltRow']"
                            @click="tableClickEvent(item.bnMmbrPk, item.bnftOfrRecHisPk, item.bnfcrPk)">
                            <td class="fixedCol0 tableBT removeTableBB pa-0" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{item.rn}}</span>
                            </td>
                            <td class="fixedCol0 tableBT removeTableBB pa-0" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{item.name}}</span>
                            </td>
                            <td v-if="routerRocation != 'PhyscSnctnRcrd' && routerRocation != 'SpclBdrmUseRcrd' && routerRocation != 'EmrgnRcrd' && routerRocation != 'HlthBlnftSrvRcrd' && routerRocation != 'NsgCareRcrd'"
                                class="fixedCol0 tableBT removeTableBB pa-0" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne d-inline-block" style="width: 100%;">
                                    <span class="d-inline-block pt-1">
                                        <v-img class="ic-sex" v-if="item.gndr == 'F'" src="../../assets/icon/ic-female.svg"></v-img>
                                        <v-img class="ic-sex" v-else-if="item.gndr == 'M'" src="../../assets/icon/ic-male.svg"></v-img>
                                    </span>
                                </span>
                            </td>
                            <td v-if="routerRocation != 'PhyscSnctnRcrd' && routerRocation != 'SpclBdrmUseRcrd' && routerRocation != 'EmrgnRcrd' && routerRocation != 'HlthBlnftSrvRcrd' && routerRocation != 'NsgCareRcrd'"
                                class="fixedCol0 tableBT removeTableBB pa-0" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{item.age}}</span>
                            </td>
                            <td v-if="routerRocation == 'AllBlnftSrvRcrd' || routerRocation == 'NsgRcrdStts'"
                                class="fixedCol0 tableBT removeTableBB d-flex justify-center align-center pa-0" style="height:40px !important; text-align: center;">
                                <span :class="'ic-level level0'+item.acknwRtng">
                                    {{ item.acknwRtng == '99' ? '외' : item.acknwRtng }}
                                </span>
                            </td>
                            <td class="fixedCol0 tableBT removeTableBB pa-0" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{item.entStcd}}</span>
                            </td>
                            <td v-if="routerRocation != 'SpclBdrmUseRcrd' && routerRocation != 'EmrgnRcrd'"
                                class="fixedCol0 tableBT removeTableBB pa-0" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{item.lvnRmNm}}</span>
                            </td>
                            <td v-if="routerRocation == 'CncntExcrtObsrvRcrd'"
                                class="fixedCol0 tableBT removeTableBB pa-0" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{item.retColTxt}}</span>
                            </td>
                            <td v-if="routerRocation == 'NsgCareRcrd'"
                                class="fixedCol0 tableBT removeTableBB pa-0" style="width:45px;height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{item.falRsk}}</span>
                            </td>
                            <td v-if="routerRocation == 'PhyscSnctnRcrd'"
                                class="fixedCol0 tableBT removeTableBB pa-0" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{item.snctCnt}}</span>
                            </td>
                            <td v-if="routerRocation == 'PhyscSnctnRcrd'"
                                class="fixedCol0 tableBT removeTableBB pa-0" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{item.snctStt}}</span>
                            </td>
                            <td v-if="routerRocation == 'SpclBdrmUseRcrd'"
                                class="fixedCol0 tableBT removeTableBB pa-0" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{item.useCnt}}</span>
                            </td>
                            <td v-if="routerRocation == 'EmrgnRcrd'"
                                class="fixedCol0 tableBT removeTableBB pa-0" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{item.emrgCnt}}</span>
                            </td>
                            <td v-if="routerRocation == 'AllBlnftSrvRcrd'"
                                class="fixedCol0 tableBT removeTableBB pa-0" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{item.entDt}}</span>
                            </td>
                            <td v-if="routerRocation == 'AllBlnftSrvRcrd'"
                                class="fixedCol0 tableBT removeTableBB pa-0" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{item.retColTxt1}}</span>
                            </td>
                            <td v-if="routerRocation == 'AllBlnftSrvRcrd'"
                                class="fixedCol0 tableBT removeTableBB pa-0" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{item.retColTxt2}}</span>
                            </td>
                            <td v-if="routerRocation == 'AllBlnftSrvRcrd'"
                                class="fixedCol0 tableBT removeTableBB pa-0" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{item.retColTxt3}}</span>
                            </td>
                            <td v-if="routerRocation == 'AllBlnftSrvRcrd'"
                                class="fixedCol0 tableBT removeTableBB pa-0" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{item.retColTxt4}}</span>
                            </td>
                            <td v-if="routerRocation == 'HlthBlnftSrvRcrd'"
                                class="fixedCol0 tableBT removeTableBB pa-0" style="height:40px !important; text-align: center;">                                                                               
                                <span v-if="item.wrtYn == '1'">
                                    <span v-if="item.recYn == '1'">
                                        <v-icon color="blue">mdi-check-circle</v-icon>
                                    </span>
                                    <span v-else>
                                        <v-icon color="">mdi-minus-circle-outline</v-icon>
                                    </span>
                                </span>
                                <span v-else>                                    
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon color="" v-bind="attrs" v-on="on">mdi-minus-circle-off-outline</v-icon>
                                        </template>
                                        <span>해당사항없습니다.</span>
                                    </v-tooltip>
                                </span>
                            </td>
                            <td v-if="routerRocation == 'HlthBlnftSrvRcrd'"
                                class="fixedCol0 tableBT removeTableBB pa-0" style="height:40px !important; text-align: center;">  
                                <span v-if="item.wrtYn == '1'">
                                    <span v-if="item.doseYn == '1'">
                                        <v-icon color="blue">mdi-check-circle</v-icon>
                                    </span>
                                    <span v-else>
                                        <v-icon color="">mdi-minus-circle-outline</v-icon>
                                    </span>
                                </span>
                                <span v-else>                                    
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon color="" v-bind="attrs" v-on="on">mdi-minus-circle-off-outline</v-icon>
                                        </template>
                                        <span>해당사항없습니다.</span>
                                    </v-tooltip>
                                </span>
                            </td>
                            <td v-if="routerRocation == 'NsgCareRcrd'"
                                class="fixedCol0 tableBT removeTableBB pa-0" style="height:40px !important; text-align: center;">  
                                <span v-if="item.wrtYn == '1'">                              
                                    <span v-if="item.bdSrYn == '1'">
                                        <v-icon color="blue" small>mdi-check-circle</v-icon>
                                    </span>
                                    <span v-else>
                                        <v-icon color="" small>mdi-minus-circle-outline</v-icon>
                                    </span>
                                </span>
                                <span v-else>                                    
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon color="" v-bind="attrs" v-on="on" small>mdi-minus-circle-off-outline</v-icon>
                                        </template>
                                        <span>해당사항없습니다.</span>
                                    </v-tooltip>
                                </span>
                            </td>
                            <td v-if="routerRocation == 'NsgCareRcrd'"
                                class="fixedCol0 tableBT removeTableBB pa-0" style="height:40px !important; text-align: center;"> 
                                <span v-if="item.wrtYn == '1'">                               
                                    <span v-if="item.tbYn == '1'">
                                        <v-icon color="blue" small>mdi-check-circle</v-icon>
                                    </span>
                                    <span v-else>
                                        <v-icon color="" small>mdi-minus-circle-outline</v-icon>
                                    </span>
                                </span>
                                <span v-else>                                    
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon color="" v-bind="attrs" v-on="on" small>mdi-minus-circle-off-outline</v-icon>
                                        </template>
                                        <span>해당사항없습니다.</span>
                                    </v-tooltip>
                                </span>
                            </td>
                            <td v-if="routerRocation == 'NsgCareRcrd'"
                                class="fixedCol0 tableBT removeTableBB pa-0" style="height:40px !important; text-align: center;">                                
                                <span v-if="item.wrtYn == '1'"> 
                                    <span v-if="item.excrtYn == '1'">
                                        <v-icon color="blue" small>mdi-check-circle</v-icon>
                                    </span>
                                    <span v-else>
                                        <v-icon color="" small>mdi-minus-circle-outline</v-icon>
                                    </span>
                                </span>
                                <span v-else>                                    
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon color="" v-bind="attrs" v-on="on" small>mdi-minus-circle-off-outline</v-icon>
                                        </template>
                                        <span>해당사항없습니다.</span>
                                    </v-tooltip>
                                </span>
                            </td>
                            <td v-if="routerRocation == 'NsgCareRcrd'"
                                class="fixedCol0 tableBT removeTableBB pa-0" style="height:40px !important; text-align: center;">                                
                                <span v-if="item.wrtYn == '1'"> 
                                    <span v-if="item.cthYn == '1'">
                                        <v-icon color="blue" small>mdi-check-circle</v-icon>
                                    </span>
                                    <span v-else>
                                        <v-icon color="" small>mdi-minus-circle-outline</v-icon>
                                    </span>
                                </span>
                                <span v-else>                                    
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon color="" v-bind="attrs" v-on="on" small>mdi-minus-circle-off-outline</v-icon>
                                        </template>
                                        <span>해당사항없습니다.</span>
                                    </v-tooltip>
                                </span>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:foot='{ items, pagination }'>
                        <div class="px-1 d-inline-block" style="width: 100%; position: absolute; bottom: -34px; left: 0;">
                            <v-row class="pa-0" no-gutters>
                                <v-col class="d-flex align-center" cols="7">
                                    <span class="fontOneRem">전체: {{ pagination.itemsLength }}명,&nbsp;&nbsp;</span>
                                    <span class="fontOneRem">남: {{ items.filter( v => v.gndr == 'M').length }}명&nbsp;&nbsp;</span>
                                    <span class="fontOneRem">여: {{ items.filter( v => v.gndr == 'F').length }}명</span>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col class="d-flex" cols="4">
                                    <v-spacer></v-spacer>
                                    <span class="d-inline-block" style="width: 100px;">
                                        <v-checkbox
                                            v-model="entChgBox" :ripple="false"
                                            class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                            true-value="1" false-value="2" label="퇴소자 조회"
                                            on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                            hide-details>
                                        </v-checkbox>
                                    </span>
                                </v-col>
                            </v-row>
                        </div>
                    </template>
                </v-data-table>
            </div>
        </v-sheet>
        <router-view ref="bnftrcrdChild"></router-view>
    </v-sheet>
</template>

<script>

import { bnfcrList } from '../../api/bnfcr.js';
import { selAllBnftRecList, selAllExcrtObsRecList, selAllNsgCareList, selAllHlthCareList, selAllPhysSnctList, selAllSpclbdUseList, selAllEmergRecList } from '../../api/bnftrcrd.js';
import { getMultiStcd } from '../../api/index.js';

import exBtn from '../../components/commons/expandButtonCustom.vue';
import CmmLvnExpnBtn from '../../components/commons/CmmLvnExpnBtn';
import expandNowDatePicker from '../../components/commons/expandNowDatePicker.vue';

export default {
    name: 'BnftrcrdCnfgr',
        
    components: {
        exBtn,
        CmmLvnExpnBtn,
        expandNowDatePicker,
    },

    created: function(){
        this.getCode(['23', '62', '65'])
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.routerRocation = this.$route.name
        })
    },

    computed: {

    },

    watch:{ 
        '$route' (to, from) {
            this.routerRocation = to.name
            this.selectedItem = -1
            console.log('Url Event from: ' + from.name)
        },
        'routerRocation': function (){
            this.schChkField.entStcd.splice(0)
            this.schChkField.lvnRmNm.splice(0)
            this.schChkField.acknwRtng.splice(0)
            this.schChkField.target = ''
            
            this.headerList.forEach(items => {
                items.on = false
            });

            this.entChgBox = 2
            this.getTableList()
        },
        'selectedItem': function (){
            if(this.selectedItem == -1)
                this.$refs.sftyChild.clearForm()
        },
        'sltBnMmBrPk': function (){
            if(this.sltBnMmBrPk == -1)
                this.$store.commit('setMmbrPk', 0)
            else
                this.$store.commit('setMmbrPk', this.sltBnMmBrPk)
                
        },
        'entChgBox': function () {
            this.$store.commit('setMmbrPk', 0)
            this.tableClickEvent(this.sltBnMmBrPk , this.sltBnftrcrdPk, this.sltBnfcrPk)

            this.getTableList()
        },        
    },
        
    methods: {
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        //전체 급여제공기록
        getAllBnftRecList: function (yymmdd, stCd){
            selAllBnftRecList(this.$store.getters.getFcltyNum, yymmdd, stCd)
                .then((response) => ( this.getAllBnftRecListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/selAllBnftRecList : ' + error))
        },
        //요양 급여제공기록, 건강 급여제공기록
        getBnfcrList: function (stCd, startDt, endDt){           
            bnfcrList(this.$store.getters.getFcltyNum, stCd, startDt, endDt)
                .then((response) => ( this.getBnfcrListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selBnfcrList : ' + error))
        },
        //집중 배설 관찰기록
        getAllExcrtObsRecList: function (stCd){
            selAllExcrtObsRecList(this.$store.getters.getFcltyNum, stCd)
                .then((response) => ( this.getAllExcrtObsRecListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/selAllExcrtObsRecList : ' + error))
        },
        //간호 처치기록
        getAllNsgCareList: function (ymd, stCd){
            selAllNsgCareList(this.$store.getters.getFcltyNum, ymd, stCd)
                .then((response) => ( this.getAllExcrtObsRecListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/selAllNsgCareList : ' + error))
        },
        //건강 급여제공기록
        getAllHlthCareList: function (ymd, stCd){
            selAllHlthCareList(this.$store.getters.getFcltyNum, ymd, stCd)
                .then((response) => ( this.getAllExcrtObsRecListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/selAllHlthCareList : ' + error))
        },
        //신체제재기록
        getAllPhysSnctList: function (yyyy, stCd){            
            selAllPhysSnctList(this.$store.getters.getFcltyNum, yyyy, stCd)
                .then((response) => ( this.getAllExcrtObsRecListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/selAllPhysSnctList : ' + error))
        },
        //특별침실사용기록
        getAllSpclbdUseList: function (yyyy, stCd){
            selAllSpclbdUseList(this.$store.getters.getFcltyNum, yyyy, stCd)
                .then((response) => ( this.getAllExcrtObsRecListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/selAllSpclbdUseList : ' + error))
        },
        //응급상황기록
        getAllEmergRecList: function (yyyy, stCd){
            selAllEmergRecList(this.$store.getters.getFcltyNum, yyyy, stCd)
                .then((response) => ( this.getAllExcrtObsRecListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/selAllEmergRecList : ' + error))
        },
        stCdMapping: function (res) {
            res.vCd62.forEach((code) => {
                this.exBtn[1].expandList.push(code.valcdnm)
            });//end foreach

            res.vCd65.forEach((code) => {
                this.exBtn[0].expandList.push(code.valcdnm)
            });//end foreach

            this.vCd23 = res.vCd23
            this.vCd62 = res.vCd62
            this.vCd65 = res.vCd65
        },
        getAllBnftRecListAfter: function (res){
            this.bnftrcrdList.splice(0)

            let me = this
            res.forEach(items => {
                me.vCd65.forEach(code => {
                    if(items.entStcd == code.valcd) items.entStcd = code.valcdnm
                });
                items.entDt = items.entDt.beforeDateFormatDot()
            });

            this.bnftrcrdList = res

            if(this.$store.getters.getBnfcrState.bnmmbr != 0){
                if( this.$store.getters.getBnfcrState.bnmmbr != this.sltBnMmBrPk ){
                    this.bnftrcrdList.forEach(e => {
                        if(e.bnMmbrPk == this.$store.getters.getBnfcrState.bnmmbr){
                            this.tableClickEvent(e.bnMmbrPk, e.bnftOfrRecHisPk)
                        }
                    });
                }
            }
        },
        getBnfcrListAfter: function (res){
            this.bnftrcrdList.splice(0)

            let me = this
            res.forEach(items => {
                items.entDt = this.$moment(items.entDt, 'YYYYMMDD').format('YYYY.MM.DD')
                me.vCd65.forEach(code => {
                    if(items.entStcd == code.valcd) items.entStcd = code.valcdnm
                });
            });

            this.bnftrcrdList = res
            
            if(this.$store.getters.getBnfcrState.bnmmbr != 0){
                if( this.$store.getters.getBnfcrState.bnmmbr != this.sltBnMmBrPk ){
                    this.bnftrcrdList.forEach(e => {
                        if(e.bnMmbrPk == this.$store.getters.getBnfcrState.bnmmbr){
                            this.tableClickEvent(e.bnMmbrPk, e.bnftOfrRecHisPk)
                        }
                    });
                }
            }

            //선택사항이 없을경우
            // if(this.bnftrcrdList.findIndex(x=>x.bnMmbrPk == this.sltBnMmBrPk) == -1){
            //     this.tableClickEvent(this.bnftrcrdList[0].bnMmbrPk, this.bnftrcrdList[0].bnftOfrRecHisPk)
            // }
        },
        getAllExcrtObsRecListAfter: function (res){            
            this.bnftrcrdList.splice(0)
         
            let me = this
            res.forEach(items => {
                me.vCd65.forEach(code => {
                    if(items.entStcd == code.valcd) items.entStcd = code.valcdnm
                });
            });

            this.bnftrcrdList = res

            if(this.$store.getters.getBnfcrState.bnmmbr != 0){
                if( this.$store.getters.getBnfcrState.bnmmbr != this.sltBnMmBrPk ){
                    this.bnftrcrdList.forEach(e => {
                        if(e.bnMmbrPk == this.$store.getters.getBnfcrState.bnmmbr){
                            this.tableClickEvent(e.bnMmbrPk, e.bnftOfrRecHisPk, e.bnfcrPk)
                        }
                    });
                }
            }

            //선택된 항목이 목록에 없을 경우
            // if(this.bnftrcrdList.findIndex(x=>x.bnMmbrPk == this.sltBnMmBrPk) == -1){
            //     this.tableClickEvent(this.bnftrcrdList[0].bnMmbrPk, this.bnftrcrdList[0].bnftOfrRecHisPk)
            // }
        },
        setSearchText: function (value) {
            this.schField = value

            this.schDummy = this.schDummy + '.'
        },
        filterSearch: function (sch) {
            this.$store.commit('setMmbrPk', 0)
            this.tableClickEvent(this.sltBnMmBrPk , this.sltBnftrcrdPk)

            switch(this.schChkField.target) {
                case 'entStcd': this.schChkField.entStcd.splice(0)
                    sch.forEach((item) => { this.schChkField.entStcd.push(item) });
                    break;
                case 'acknwRtng': this.schChkField.acknwRtng.splice(0)
                    sch.forEach((item) => {
                        if(item == '등급외')
                            this.schChkField.acknwRtng.push('99')
                        else
                            this.schChkField.acknwRtng.push(item.replaceAll('등급', ''))
                    });
                    break;
            }
            
            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column
            let col0 = filter.name.includes(this.schField)
            
            let col1 = true
            if(this.schChkField.entStcd.length != 0){
                this.schChkField.entStcd.forEach((stcd, i) => {
                    if(i == 0) col1 = filter.entStcd.includes(stcd)
                    else col1 = col1 || filter.entStcd.includes(stcd)
                });
            }

            let col2 = true
            if(this.schChkField.acknwRtng.length != 0){
                this.schChkField.acknwRtng.forEach((stcd, i) => {
                    if(i == 0) col2 = filter.acknwRtng.includes(stcd)
                    else col2 = col2 || filter.acknwRtng.includes(stcd)
                });
            }

            let col3 = true
            // 생활실 검색
            let lvnRunChc = this.aslLvnStt.filter( v => v.isOn == true )
            if(lvnRunChc.length == 0)
                col3 = false
            else{
                lvnRunChc.forEach((itm, i) => {
                    if(i == 0) col3 = filter.lvnRmInfPk == itm.lvnRmInfPk
                    else col3 = col3 || filter.lvnRmInfPk == itm.lvnRmInfPk
                });
            }

            return col0 && col1 && col2 && col3;
        },
        getDate: function (value) {
            this.sltDate = value.date
            if(this.routerRocation == 'AllBlnftSrvRcrd')
                this.getTableList()
        },
        getTableList: function(){
            let entStcd = null
            if(this.entChgBox == 1)
                entStcd = '4'
            let this_monday = this.$moment().day(1)
            let startDt = this_monday.format('YYYYMMDD');
            let endDt = this_monday.add(6,'days').format('YYYYMMDD');
            let sltYear = ''

            switch(this.routerRocation) {
                case 'AllBlnftSrvRcrd':
                    this.bnftrcrdList.splice(0)
                    this.midAreaWidth = '680'
                    this.headerList.forEach(items => {
                        this.groupList.AllBlnftSrvRcrd.forEach(element => {
                            if(items.text == element) items.on = true
                        });
                    });
                    if(this.sltDate != '')
                        this.getAllBnftRecList(this.sltDate, entStcd)
                    break;
                case 'CareBlnftSrvRcrd':
                    this.bnftrcrdList.splice(0)
                    this.midAreaWidth = '380'
                    this.headerList.forEach(items => {
                        this.groupList.CareBlnftSrvRcrd.forEach(element => {
                            if(items.text == element) items.on = true
                        });
                    });                    
                    this.getBnfcrList(entStcd, startDt, endDt)
                    break;
                case 'CncntExcrtObsrvRcrd':
                    this.bnftrcrdList.splice(0)
                    this.midAreaWidth = '420'
                    this.headerList.forEach(items => {
                        this.groupList.CncntExcrtObsrvRcrd.forEach(element => {
                            if(items.text == element) items.on = true
                        });
                    });
                    this.getAllExcrtObsRecList(entStcd)
                    break;
                case 'HlthBlnftSrvRcrd':
                    this.bnftrcrdList.splice(0)
                    this.midAreaWidth = '380'
                    this.headerList.forEach(items => {
                        this.groupList.HlthBlnftSrvRcrd.forEach(element => {
                            if(items.text == element) items.on = true
                        });
                    });
                    this.getAllHlthCareList(this.$store.getters.getPrsntDt('yyyymmdd'),entStcd)
                    break;
                case 'NsgCareRcrd':
                    this.bnftrcrdList.splice(0)
                    this.midAreaWidth = '450'
                    this.headerList.forEach(items => {
                        this.groupList.NsgCareRcrd.forEach(element => {
                            if(items.text == element) items.on = true
                        });
                    });
                    this.getAllNsgCareList(this.$store.getters.getPrsntDt('yyyymmdd'), entStcd)
                    break;
                case 'PhyscSnctnRcrd':
                    this.bnftrcrdList.splice(0)
                    this.midAreaWidth = '380'
                    this.headerList.forEach(items => {
                        this.groupList.PhyscSnctnRcrd.forEach(element => {
                            if(items.text == element) items.on = true
                        });
                    });
                                  
                    if(this.$refs.bnftrcrdChild.year != undefined && this.$refs.bnftrcrdChild.year != null) sltYear= this.$refs.bnftrcrdChild.year
                    else sltYear = this.$store.getters.getPrsntDt('yyyy')
                    
                    this.getAllPhysSnctList(sltYear, entStcd)
                    break;
                case 'SpclBdrmUseRcrd':
                    this.bnftrcrdList.splice(0)
                    this.midAreaWidth = '380'
                    this.headerList.forEach(items => {
                        this.groupList.SpclBdrmUseRcrd.forEach(element => {
                            if(items.text == element) items.on = true
                        });
                    });
                    
                    if(this.$refs.bnftrcrdChild.year != undefined && this.$refs.bnftrcrdChild.year != null) sltYear= this.$refs.bnftrcrdChild.year
                    else sltYear = this.$store.getters.getPrsntDt('yyyy')

                    this.getAllSpclbdUseList(sltYear, entStcd)
                    break;
                case 'EmrgnRcrd':
                    this.bnftrcrdList.splice(0)
                    this.midAreaWidth = '380'
                    this.headerList.forEach(items => {
                        this.groupList.EmrgnRcrd.forEach(element => {
                            if(items.text == element) items.on = true
                        });
                    });
                    
                    if(this.$refs.bnftrcrdChild.year != undefined && this.$refs.bnftrcrdChild.year != null) sltYear= this.$refs.bnftrcrdChild.year
                    else sltYear = this.$store.getters.getPrsntDt('yyyy')

                    this.getAllEmergRecList(sltYear, entStcd)
                    break;
                case 'NsgRcrdStts':
                    this.bnftrcrdList.splice(0)
                    this.midAreaWidth = '430'
                    this.headerList.forEach(items => {
                        this.groupList.NsgRcrdStts.forEach(element => {
                            if(items.text == element) items.on = true
                        });
                    });                                     
                    this.getBnfcrList(entStcd, startDt, endDt)
                    break;
                default:
                    break;
            }
            this.forceRender += 1
        },
        getTableLastOn: function(){
            let obj = this.headerList.filter(v => v.on === true)
            let index = this.headerList.lastIndexOf(obj[obj.length-1])
            return index
        },
        tableClickEvent: function (bnmmbr, pk, bnfcr = -1){
            if(this.sltBnMmBrPk == bnmmbr){
                this.sltBnMmBrPk    = -1
                this.sltBnftrcrdPk  = -1        
                this.sltBnfcrPk     = -1        
            }
            else if(this.sltBnMmBrPk != bnmmbr){
                this.sltBnMmBrPk    = bnmmbr
                this.sltBnftrcrdPk  = pk
                this.sltBnfcrPk     = bnfcr
            }
            
            if(typeof pk == 'undefined' || pk == null)
                this.sltBnftrcrdPk = -1
        },
        expandClose: function (index) {
            switch(index) {
                case 0: this.schChkField.target = 'entStcd'
                    break;                
                case 1: this.schChkField.target = 'acknwRtng'
                    break;
                default: this.schChkField.target = ''
                    break;
            }
        },        

        snackControll: function (options) {
            this.snackOptions = options
        },

        // 생활실 선택 컴포넌트 변경 이벤트
        lvnSttApply: function (arr){
            this.aslLvnStt = arr
            this.schDummy = String(Number(this.schDummy)+1)
        },
    },
    
    data: () => ({
        entChgBox: 2,
        routerRocation: '',
        selectedItem: -1,
        sltBnMmBrPk: -1,
        sltBnfcrPk: -1,
        sltBnftrcrdPk: -1,
        sltDate: '',
        vCd23: [],
        vCd62: [],
        vCd65: [],
        bnftrcrdList: [],
        midAreaWidth: '280',
        schField: '',
        schDummy: '',
        schChkField: { entStcd: [], lvnRmNm: [], acknwRtng:[], target: '' },
        forceRender: 0,
        headerList: [
            { text: '연번', value: 'rn', align: 'center', on: false, sortable: true, width: '40', class: 'txtAlgnMidImp black--text bottomBorder py-2 px-1' },
            { text: '수급자', value: 'name', align: 'center', on: false, sortable: true, width: '60', class: 'txtAlgnMidImp black--text bottomBorder py-2 px-1' },
            { text: '성별', value: 'gndr', align: 'center', on: false, sortable: true, width: '60', class: 'txtAlgnMidImp black--text bottomBorder py-2 px-1' },
            { text: '나이', value: 'age', align: 'center', on: false, sortable: true, width: '60', class: 'txtAlgnMidImp black--text bottomBorder py-2 px-1' },
            { text: '등급', value: 'acknwRtng', align: 'center', on: false, sortable: true, width: '100', class: 'txtAlgnMidImp black--text bottomBorder py-2 px-1' },
            { text: '현황', value: 'entStcd', align: 'center', on: false, sortable: true, width: '80', class: 'txtAlgnMidImp black--text bottomBorder py-2 px-1' },
            { text: '생활실', value: 'lvnRmNm', align: 'center', on: false, sortable: true, width: '80', class: 'txtAlgnMidImp black--text bottomBorder py-2 px-1' },
            { text: '입소일', value: 'entDt', align: 'center', on: false, sortable: true, width: '90', class: 'txtAlgnMidImp black--text bottomBorder py-2 px-1' },
            { text: '신체활동', value: 'retColTxt1', align: 'center', on: false, sortable: true, width: '100', class: 'txtAlgnMidImp black--text bottomBorder py-2 px-1' },
            { text: '인지관리', value: 'retColTxt1', align: 'center', on: false, sortable: true, width: '100', class: 'txtAlgnMidImp black--text bottomBorder py-2 px-1' },
            { text: '기능회복', value: 'retColTxt1', align: 'center', on: false, sortable: true, width: '100', class: 'txtAlgnMidImp black--text bottomBorder py-2 px-1' },
            { text: '건강및간호', value: 'retColTxt1', align: 'center', on: false, sortable: true, width: '100', class: 'txtAlgnMidImp black--text bottomBorder py-2 px-1' },
            { text: '관찰상태', value: '', align: 'center', on: false, sortable: true, width: '100', class: 'txtAlgnMidImp black--text bottomBorder py-2 px-1' },
            { text: '욕창점수', value: 'falRsk', align: 'center', on: false, sortable: true, width: '80', class: 'txtAlgnMidImp black--text bottomBorder py-2 px-1' },
            { text: '응급상황건수', value: 'emrgCnt', align: 'center', on: false, sortable: true, width: '140', class: 'txtAlgnMidImp black--text bottomBorder py-2 px-1' },
            { text: '건수', value: 'useCnt', align: 'center', on: false, sortable: true, width: '80', class: 'txtAlgnMidImp black--text bottomBorder py-2 px-1' },
            { text: '제재', value: 'snctStt', align: 'center', on: false, sortable: true, width: '80', class: 'txtAlgnMidImp black--text bottomBorder py-2 px-1' },
            { text: '사용건수', value: 'useCnt', align: 'center', on: false, sortable: true, width: '100', class: 'txtAlgnMidImp black--text bottomBorder py-2 px-1' },
            { text: '일지', value: 'recYn', align: 'center', on: false, sortable: true, width: '60', class: 'txtAlgnMidImp black--text bottomBorder py-2 px-1' },
            { text: '투약', value: 'doseYn', align: 'center', on: false, sortable: true, width: '60', class: 'txtAlgnMidImp black--text bottomBorder py-2 px-1' },
            { text: '욕창', value: 'bdSrYn', align: 'center', on: false, sortable: true, width: '60', class: 'txtAlgnMidImp black--text bottomBorder py-2 px-1' },
            { text: '경관', value: 'tbYn', align: 'center', on: false, sortable: true, width: '60', class: 'txtAlgnMidImp black--text bottomBorder py-2 px-1' },
            { text: '배설', value: 'excrtYn', align: 'center', on: false, sortable: true, width: '60', class: 'txtAlgnMidImp black--text bottomBorder py-2 px-1' },
            { text: '도뇨', value: 'cthYn', align: 'center', on: false, sortable: true, width: '60', class: 'txtAlgnMidImp black--text bottomBorder py-2 px-1' },            
        ],
        exBtn: [
            { index:  0, btnTitle: '입소현황', btnIcon: 'house', exWidth: 104, exHeigth: 130, expandList: [], },            
            { index:  1, btnTitle: '등급', btnIcon: 'wheelchair', exWidth: 104, exHeigth: 198, expandList: [], },
        ],
        groupList:{
            CareBlnftSrvRcrd: ['연번', '현황', '수급자', '성별', '나이', '생활실'], CncntExcrtObsrvRcrd: ['연번', '현황', '수급자', '성별', '나이', '생활실', '관찰상태'],
            HlthBlnftSrvRcrd: ['연번', '현황', '수급자', '생활실', '일지', '투약'], NsgCareRcrd: ['연번', '수급자', '욕창점수', '현황', '생활실', '욕창', '경관', '배설', '도뇨'],
            PhyscSnctnRcrd: ['연번', '현황', '수급자', '생활실', '건수', '제재'], SpclBdrmUseRcrd: ['연번', '현황', '수급자', '사용건수',],
            EmrgnRcrd: ['연번', '현황', '수급자', '응급상황건수'], AllBlnftSrvRcrd: ['연번', '수급자', '현황', '성별', '나이', '등급', '생활실', '입소일', '신체활동', '인지관리', '건강및간호', '기능회복',],
            NsgRcrdStts: ['연번', '현황', '수급자', '성별', '나이', '등급', '생활실'],
        },
        snackOptions: [false, 3000, '', 'info'],
        isLvnStt            : false,                            // 생활실 확장 활성화 여부
        aslLvnStt           : [],
    }),
};
</script>