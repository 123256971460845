<template>
    <v-sheet class="rounded-lg rounded-l-0 overflow-auto py-3 px-2" color="" :min-width="areaWidth" :max-width="areaWidth">
        <div style="position: relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <v-row class="align-center justify-start" no-gutters>
            <span v-if="tab != 2" class="d-inline-block mr-3" style="width: 70px;">
                <v-select
                    v-model="srchCd" :items="selectItems"
                    item-text="valcdnm" item-value="valcd"
                    dense hide-details outlined>
                </v-select>
            </span>
            <expandNowDatePicker v-if="srchCd == 0 && tab != 2" @nowDate="getDate"></expandNowDatePicker>
            <expandMonthPicker v-else-if="srchCd == 1" @nowYearWithMonth="getPickerDate"></expandMonthPicker>
            <expandYearOnlyPicker v-else-if="srchCd == 2 && tab != 2" @nowYear="getYear"></expandYearOnlyPicker>
            <v-spacer></v-spacer>
            <v-btn
                v-if="tab == 1"
                class="font-weight-bold mr-1" color="grey006" height="30"
                :disabled="$store.getters.getBnfcrState.bnmmbr < 1 || totCnt[1] < 1"
                @click="nsgRcrdStts()"
                rounded outlined samll>
                <v-icon small>mdi-printer-outline</v-icon>
                <span class="fontOneRem ml-1">출력</span>
            </v-btn>
            <v-btn
                v-if="tab == 2"
                class="font-weight-bold mr-2" color="grey006" height="30"
                :disabled="$store.getters.getBnfcrState.bnmmbr < 1 || totCnt[2] < 1"
                @click="mdctnPrt(1)"
                rounded outlined samll>
                <v-icon small>mdi-printer-outline</v-icon>
                <span class="fontOneRem ml-1">기록지 출력</span>
            </v-btn>
            <v-btn
                v-if="tab == 2"
                class="font-weight-bold mr-1" color="grey006" height="30"
                :disabled="$store.getters.getBnfcrState.bnmmbr < 1 || totCnt[2] < 1"
                @click="mdctnPrt(2)"
                rounded outlined samll>
                <v-icon small>mdi-printer-outline</v-icon>
                <span class="fontOneRem ml-1">품목 출력</span>
            </v-btn>
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-tabs
            v-model="tab" class="tab-dft-Theme" active-class="black"
            center-active hide-slider>
            <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class">{{ item.tab }}</v-tab>
        </v-tabs>
        <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
        <v-tabs-items v-model="tab">
            <v-tab-item transition="fade-transition"> 
                <tab1 ref="tab1" :tab="tab"></tab1>
            </v-tab-item>
            <v-tab-item transition="fade-transition">
                <tab2 ref="tab2" :tab="tab"></tab2>
            </v-tab-item>
            <v-tab-item transition="fade-transition">
                <tab3 ref="tab3" :tab="tab"></tab3>
            </v-tab-item>
        </v-tabs-items>
    </v-sheet>
</template>

<script>
import tab1 from '../../components/btcInq/NsgRcrdSttsTab1.vue';
import tab2 from '../../components/btcInq/NsgRcrdSttsTab2.vue';
import tab3 from '../../components/btcInq/NsgRcrdSttsTab3.vue';

import expandNowDatePicker from '../../components/commons/expandNowDatePicker.vue';
import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';
import expandMonthPicker from '../../components/commons/expandMonthPicker.vue';

export default {
    name: 'NsgRcrdStts',
        
    components: {
        tab1,
        tab2,
        tab3,
        expandNowDatePicker,
        expandYearOnlyPicker,
        expandMonthPicker,
    },

    created: function(){
      
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.areaWidth = 1260 - Number(this.$parent.$parent.midAreaWidth)

            this.aplStrt = this.$moment().format('YYYY-MM-DD')
            this.aplEnd = this.$moment().format('YYYY-MM-DD')
        })
    },

    computed: {
        getAreaWidth: function () {
            return this.$parent.$parent.midAreaWidth
        },
    },

    watch:{ 
        getAreaWidth: function (value) {
            this.areaWidth = 1260 - Number(value)
        },
        'tab': function (v) {
            if(v == 2)
                this.srchCd = 1
            else
                this.srchCd = 0
        },
    },
        
    methods: {
        nsgRcrdStts: function (){
            this.prtThrowObj.prtCd = '53'
            this.prtThrowObj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum

            if( this.srchCd == 0 ){
                this.prtThrowObj.inqYmdStart = this.sltYmd
                this.prtThrowObj.inqYmdEnd = this.sltYmd
            }
            else if( this.srchCd == 1 ){
                this.prtThrowObj.inqYmdStart = this.$moment(this.sltYymm, 'YYYYMM').format('YYYYMMDD')
                this.prtThrowObj.inqYmdEnd = this.$moment(this.sltYymm, 'YYYYMM').endOf('month').format('YYYYMMDD')
            }
            else if( this.srchCd == 2 ){
                this.prtThrowObj.inqYmdStart = this.$moment(this.sltYyyy, 'YYYY').startOf('year').format('YYYYMMDD')
                this.prtThrowObj.inqYmdEnd = this.$moment(this.sltYyyy, 'YYYY').endOf('year').format('YYYYMMDD')
            }
         
            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
            });
        },
        mdctnPrt: function (mode){
            if(mode == 1)           // 투약기록지
                this.prtThrowObj.prtCd = '32'
            else if(mode == 2)      // 투약품목
                this.prtThrowObj.prtCd = '29'

            this.prtThrowObj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
            this.prtThrowObj.inqYmdStart = this.$moment(this.sltYymm, 'YYYYMM').startOf('month').format('YYYYMMDD')
            this.prtThrowObj.inqYmdEnd = this.$moment(this.sltYymm, 'YYYYMM').endOf('month').format('YYYYMMDD')

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
            });
        },
        getYear: function (obj) {
            this.sltYyyy = obj
        },
        getPickerDate: function (obj) {
            this.sltYymm = obj
        },
        getDate: function (obj) {
            this.sltYmd = obj.date
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    
    data: () => ({
        tab: 0,
        srchCd: 0,
        vsbMdctnBtn: true,
        sltYyyy: '',
        sltYymm: '',
        sltYmd: '',
        totCnt: [0, 0, 0],
        areaWidth: 708,
        tabItems: [
            { index: 0, tab: '건강기록', class: 'fontOneDotTwoRem px-15',},
            { index: 1, tab: '간호처치 기록', class: 'fontOneDotTwoRem px-15',},
            { index: 2, tab: '투약관리', class: 'fontOneDotTwoRem px-15',},
        ],
        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '', inqYYMM: null,
            rptTyp: '1', mode: '2', list: [ { bzClcd: [], bzPk: [] } ],
        },
        selectItems: [
            { valcdnm: '일별', valcd: 0 },
            { valcdnm: '월별', valcd: 1 },
            { valcdnm: '연별', valcd: 2 },
        ],
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>