<template>
    <v-sheet class="rounded-lg rounded-l-0" color="" min-width="1260" max-width="1560" height="835">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="px-2 mt-2 mr-2 d-flex align-center">
            <div class="text-h4">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">1-4. 가정통신문</span>
            </div>
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn v-if="list.type == 'action'" @click = "onClickBtn(list.text)"
                    :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                    <v-icon small>{{list.icon}}</v-icon>
                    <span class="fontOneRem">{{list.text}}</span>
                </v-btn>
                <v-dialog v-else-if="list.type == 'dialogue'"
                    v-model="dialog[i]" :max-width="list.width">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                :class="list.class" samll height="30" :color="list.color" rounded>
                                <v-icon small>{{list.icon}}</v-icon>
                                <span class="fontOneRem">{{list.text}}</span>
                            </v-btn>
                        </template>
                        <btnModalDelete v-if="list.text == '삭제'" :title="'가정통신문 수급자 상태'" @madalState="onModal"></btnModalDelete>
                        <!-- <FmlyLeterSnd v-else-if="list.text == '가정통신문 발송'" @madalState="onModal" :prtCorsp="selectedFmlyLeter"></FmlyLeterSnd> -->
                        <div v-else>개발중</div>
                </v-dialog>
            </span>
            <span>
                <v-btn
                    :class="outlinedBtn[0].class" :color="outlinedBtn[0].color"
                    :disabled="outlinedBtn[0].disabled" @click="preFamlLtrPdfPath()"
                    height="30" samll rounded outlined>
                    <v-icon style="padding-top: 2px;" small>{{outlinedBtn[0].icon}}</v-icon>
                    <span class="ml-1 fontOneRem">{{outlinedBtn[0].text}}</span>
                </v-btn>
            </span>
        </div>
        <div class="mt-5 mb-2 mx-3">
            <expandMonthPicker @nowYearWithMonth="getPickerDate"></expandMonthPicker>
        </div>
        <v-divider class="mx-3"></v-divider>
        <v-row class="pa-0 mx-3" no-gutters>
            <v-col class="" cols="8">
                <div class="mt-2 d-flex">
                    <exBtn class="ml-1" ref="exBtnClose" :btnData="exBtn[0]" @btnClosed="expandClose" @changeCheck="filterSearchD1" />
                    <span class="d-inline-block ml-3" style="width:120px; height:35px;">
                        <v-text-field class="ma-0 pa-0 nameSearch" background-color="white" append-icon="mdi-magnify" width="100px" height="35px"
                            hide-details single-line rounded outlined dense filled label="이름조회" @input="setSearchText">
                        </v-text-field>
                    </span>
                </div>
                <div class="mt-2">
                    <v-divider></v-divider>
                    <v-data-table
                        :headers="headers" :items="famlLtrList" :search="schDummy" :custom-filter="customFilter"
                        class="fmlyLt" locale="ko" item-key="rn" height="672"
                        fixed-header show-select hide-default-header disable-pagination hide-default-footer dense>
                        <template v-slot:header="{ props }" >
                            <thead>
                                <tr>
                                    <th class="nmlBfClrImp-greyE01 tableBR" style="width:4%; padding: 0 14px 0 17px;" rowspan='2'>
                                        <span class="d-inline-block" style="width: 28px; height: 28px;">
                                            <v-checkbox
                                                class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                v-model="allChecker"
                                                @change="checkBoxMessage"
                                                hide-details>
                                            </v-checkbox>
                                        </span>
                                    </th>
                                    <th class="px-2 black--text fontOneRem txtAlgnMidImp nmlBfClrImp-greyE01 tableBR" style="width:14%;" rowspan='2'>{{props.headers[1].text}}</th>
                                    <th class="px-2 black--text fontOneRem txtAlgnMidImp nmlBfClrImp-greyE01 tableBR" style="width:7%;" rowspan='2'>{{props.headers[2].text}}</th>
                                    <th class="px-2 black--text fontOneRem txtAlgnMidImp nmlBfClrImp-greyE01 tableBR" style="width:7%;" rowspan='2'>{{props.headers[3].text}}</th>
                                    <th class="px-2 black--text fontOneRem txtAlgnMidImp nmlBfClrImp-greyE01 tableBR" style="width:14%;" rowspan='2'>{{props.headers[4].text}}</th>
                                    <th class="px-2 black--text fontOneRem txtAlgnMidImp nmlBfClrImp-greyE01" style="width:55%;" colspan='4'>가정통신문&nbsp;수급자별&nbsp;내용&nbsp;&#40;전월&#41;</th>
                                </tr>
                                <tr>
                                    <th class="black--text nmlBfClrImp-greyE01 tblIntBT pa-1" style="width: 12%;">
                                        <v-checkbox
                                            class="dftChcBox-Theme pt-0 mt-0" @change="sltFmlyLeter('1')"
                                            hide-details>
                                            <template v-slot:label>
                                                <span class="black--text">{{ props.headers[5].text }}</span>
                                            </template>
                                        </v-checkbox>
                                    </th>
                                    <th class="black--text nmlBfClrImp-greyE01 tblIntBT pa-1" style="width: 12%;">
                                        <v-checkbox
                                            class="dftChcBox-Theme pt-0 mt-0" @change="sltFmlyLeter('14')"
                                            hide-details>
                                            <template v-slot:label>
                                                <span class="black--text">{{ props.headers[6].text }}</span>
                                            </template>
                                        </v-checkbox>
                                    </th>
                                    <th class="black--text nmlBfClrImp-greyE01 tblIntBT pa-1" style="width: 15%;">
                                        <v-checkbox
                                            class="dftChcBox-Theme pt-0 mt-0" @change="sltFmlyLeter('27')"
                                            hide-details>
                                            <template v-slot:label>
                                                <span class="black--text">{{ props.headers[7].text }}</span>
                                            </template>
                                        </v-checkbox>
                                    </th>
                                    <th class="black--text nmlBfClrImp-greyE01 tblIntBT tblIntBR pa-1" style="width: 16%;" rowspan='2'>
                                        <v-checkbox
                                            class="dftChcBox-Theme pt-0 mt-0" @change="sltFmlyLeter('8')"
                                            hide-details>
                                            <template v-slot:label>
                                                <span class="black--text">{{ props.headers[8].text }}</span>
                                            </template>
                                        </v-checkbox>
                                    </th>
                                </tr>
                            </thead>
                        </template>
                        <template v-slot:item='{ item, index }'>
                            <tr class="fixedCol">
                                <td class="tableContentCommon tableBR">
                                    <span class="d-inline-block" style="width: 28px; height: 28px;">
                                        <v-checkbox
                                            class="dftChcBox-Theme pt-0" style="margin: 3px 0 0 1px !important;"
                                            v-model="item.isSelected" @change="checkBoxIndex()"
                                            hide-details>
                                        </v-checkbox>
                                    </span>
                                </td>
                                <td class="tableContentCommon py-2 tableBR">{{item.name}}</td>
                                <td class="tableContentCommon tableBR">
                                    <v-img class="ic-sex ml-1" v-if="item.gndr == 'F'" src="../../assets/icon/ic-female.svg"></v-img>
                                    <v-img class="ic-sex ml-1" v-else-if="item.gndr == 'M'" src="../../assets/icon/ic-male.svg"></v-img>
                                </td>
                                <td class="tableContentCommon tableBR">{{item.age}}</td>
                                <td class="tableContentCommon tableBR">{{item.lvnRm}}</td>
                                <td
                                    :class="['tableContentCommon tableBR fixedCol0', selectedItem == index ? 'sltRow' : 'disSltRow']"
                                    @click="tableClickEvent(index)">
                                    <span v-if="item.famlLtrPk == null || item.famlLtrPk == '' || item.famlLtrPk == 0">미작성</span>
                                    <span v-else>작성</span>
                                </td>
                                <td class="tableContentCommon tableBR">
                                    <span>
                                        <v-btn color="grey006" height="26" @click="prtPyrSpc(item.bnMmbrPk)" icon>
                                            <v-icon class="font-weight-thin">mdi-printer-outline</v-icon>
                                        </v-btn>
                                    </span>
                                </td>
                                <td class="tableContentCommon tableBR">
                                    <span>
                                        <v-btn color="grey006" height="26" @click="prtPrgrPrtcp(item.bnMmbrPk)" icon>
                                            <v-icon>mdi-printer-outline</v-icon>
                                        </v-btn>
                                    </span>
                                </td>  
                                <td class="tableContentCommon">
                                    <span>
                                        <v-btn
                                            color="grey006" height="26"
                                            :disabled="item.copayClmHisPk == null"
                                            @click="prtCstStt(item.bnMmbrPk, item.bnfcrPk)"
                                            icon>
                                            <v-icon>mdi-printer-outline</v-icon>
                                        </v-btn>
                                    </span>
                                </td>  
                            </tr>
                        </template>
                    </v-data-table>
                </div>
            </v-col>
            <v-col cols="4">
                <v-row no-gutters>
                    <v-col class="px-2 mt-3">
                        <div class="d-flex align-center">
                            <v-icon style="font-size: 28px; width: 8px;" color="black">mdi-chevron-right</v-icon>
                            <span class="text-h6 font-weight-bold ml-3">가정통신문&nbsp;공통&nbsp;내용</span>
                        </div>
                        <v-divider class="greyE00" style="margin-top: 11px;"></v-divider>
                        <v-row no-gutters>
                            <v-col class="greyE01 pa-0 ma-0" cols="5">
                                <div class="d-flex justify-center align-center tableBB" style="height: 35px;">
                                    <v-checkbox class="dftChcBox-Theme" style="margin:0 auto; width: 135px;" hide-details @change="sltFmlyLeter('0')">
                                        <template v-slot:label>
                                            <span class="black--text font-weight-bold d-inline-block ml-1" style="width: 100px;">가정통신문</span>
                                        </template>
                                    </v-checkbox>
                                </div>
                                <div class="d-flex justify-center align-center tableBB" style="height: 35px;">
                                    <v-checkbox class="dftChcBox-Theme" style="margin:0 auto; width: 135px;" hide-details @change="sltFmlyLeter('13')">
                                        <template v-slot:label>
                                            <span class="black--text font-weight-bold d-inline-block ml-1" style="width: 100px;">프로그램&nbsp;일정표</span>
                                        </template>
                                    </v-checkbox>
                                </div>
                                <div class="d-flex justify-center align-center" style="height: 35px;">
                                    <v-checkbox class="dftChcBox-Theme" style="margin:0 auto; width: 135px;" hide-details @change="sltFmlyLeter('2')">
                                        <template v-slot:label>
                                            <span class="black--text font-weight-bold d-inline-block ml-1" style="width: 100px;">식단표</span>
                                        </template>
                                    </v-checkbox>
                                </div>
                            </v-col>
                            <v-col class="pa-0 ma-0" cols="7">
                                <div class="d-flex justify-center align-center tableBB" style="height: 35px;">
                                    <v-btn class="white--text" height="26" style="min-width: 46px; width: 154px;" color="blueBtnColor" @click="menu.splice(0, 1, true)" rounded depressed>
                                        <v-icon small>mdi-content-save-outline</v-icon>
                                        <span class="ml-1">{{sltDate}}&nbsp;작성</span>
                                    </v-btn>
                                </div>
                                <div class="d-flex justify-center align-center tableBB" style="height: 35px;">
                                    <v-btn class="" height="26" style="min-width: 46px; width: 154px;" color="grey006"
                                        @click="prtPrgrSchd()" outlined rounded depressed>
                                        <v-icon small>mdi-printer-outline</v-icon>
                                        <span class="ml-1">{{sltDate}}&nbsp;보기</span>
                                    </v-btn>
                                </div>
                                <div class="d-flex justify-center align-center" style="height: 35px;">
                                    <v-btn class="" height="26" style="min-width: 46px; width: 154px;" color="grey006"
                                        @click="prtMealTbl()" outlined rounded depressed>
                                        <v-icon small>mdi-printer-outline</v-icon>
                                        <span class="ml-1">{{sltDate}}&nbsp;보기</span>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                        <v-divider class="greyE00"></v-divider>
                        <div class="d-flex align-center mt-4">
                            <v-icon style="font-size: 28px; width: 8px;" color="black">mdi-chevron-right</v-icon>
                            <span class="text-h6 font-weight-bold ml-3">
                                시설&nbsp;생활&nbsp;및&nbsp;건강상태&nbsp;
                                {{selectedItem != -1 ? '( 수급자: ' + famlLtrList[selectedItem].name + ' )' : ''}}
                            </span>
                        </div>
                        <v-divider class="greyE00" style="margin-top: 14px;"></v-divider>
                        <v-textarea class="my-2 rmrksArea" row-height="1" height="485" v-model="cntnt" no-resize outlined hide-details></v-textarea>
                        <v-divider class="greyE00"></v-divider>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-dialog v-model="menu[0]" content-class="round" persistent max-width="1200">
            <FmlyLeterWrt @isClose="menu.splice(0, 1, false)" :prtDate="date"></FmlyLeterWrt>
        </v-dialog>
        <v-dialog v-model="menu[2]" max-width="1400" persistent>    
            <FmlyLeterSnd 
                ref="fmlyLeterSnd"                                
                :bzPk="bizMsgInfo.bzPk"
                :mdlShow="menu[2]"
                :title="bizMsgInfo.bzTitle"
                :cntnt="bizMsgInfo.bzCntnt"
                :prtCdAr="selectedFmlyLeter"
                :yymm="date"
                @madalState="menu.splice(2, 1, false)">
            </FmlyLeterSnd>  
        </v-dialog>
        <!-- <v-dialog v-model="menu[2]" max-width="1100" persistent>    
            <bizMsgMdl 
                ref="bizMsgMdl"
                :bizMsgData="bizMsgInfo.mmbrPk"
                :bizMsgDocClcd="bizMsgInfo.docClcd"
                :bizMsgBzPk="bizMsgInfo.bzPk"
                :bizMsgShow="menu[2]"
                :bizMsgTitle="bizMsgInfo.bzTitle"
                :bizMsgCntnt="bizMsgInfo.bzCntnt"
                :bizMsgPrtCdAr="selectedFmlyLeter"
                :bizMsgVal1="date"
                @madalState="menu.splice(2, 1, false)">
            </bizMsgMdl>  
        </v-dialog> -->
    </v-sheet>
</template>

<script>
import expandMonthPicker from '../../components/commons/expandMonthPicker.vue';
import exBtn from '../../components/commons/expandButtonCustom.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import FmlyLeterWrt from '../../components/bnfcr/FmlyLeterWrt.vue';
import FmlyLeterSnd from '../../components/bnfcr/FmlyLeterSnd.vue';
// import bizMsgMdl from '../../components/commons/BizMsgMdl.vue';

import { selFamlLtrList, selFamlLtrInfo, insfamlLtr, delfamlLtr, selFamlLtrPrtInfo } from '../../api/bnfcr.js';
import { selLvnRmInf } from '../../api/opr.js';
import { remoteURL } from '@/api/baseAxios';

export default {
    name: 'FmlyLeter',
        
    components: {
        expandMonthPicker,
        exBtn,
        btnModalDelete,
        FmlyLeterWrt,
        FmlyLeterSnd,
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getLvnRmInf()
        })
    },

    computed: {

    },

    watch:{ 
        dialog: {
            async handler() {
                await this.$nextTick();
                if(typeof this.$refs['fmlyLtrReport'] != 'undefined')
                    this.$refs['fmlyLtrReport'].setCorps()
            }
        },
    },
        
    methods: {
        getLvnRmInf: async function () {
            await selLvnRmInf(this.$store.getters.getFcltyNum)
                .then((response) => ( this.getLvnRmInfAfter(response.data) ))
                .catch((error) => console.log('connect error /opr/selLvnRmInf : ' + error))
        },
        getFamlLtrList: function (yyMm) {
            selFamlLtrList(this.$store.getters.getFcltyNum, yyMm)
                .then((response) => ( this.famlLtrList = response.data ))
                .catch((error) => console.log('connect error /bnfcr/selFamlLtrList : ' + error))
        },
        getFamlLtrInfo: function (famlPk) {
            selFamlLtrInfo(this.$store.getters.getFcltyNum, famlPk, 2, this.date)
                .then((response) => ( this.getFamlLtrInfoAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selFamlLtrInfo : ' + error))
        },
        getFamlLtrPdfPath: function (obj) {
            selFamlLtrPrtInfo(obj)
                .then((response) => ( this.getFamlLtrPdfPathAfter(response.data) ))
                .catch((error) => console.log('connect error /report/selFamlLtrPrtInfo : ' + error))
        },
        setFamlLtr: function (obj) {
            insfamlLtr(obj)
                .then((response) => ( this.setFamlLtrAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/insfamlLtr : ' + error))
        },
        removeFamlLtr: function (famlPk) {
            delfamlLtr(this.$store.getters.getFcltyNum, famlPk, this.$store.getters.getUserNm)
                .then((response) => ( this.setFamlLtrAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/delfamlLtr : ' + error))
        },
        getLvnRmInfAfter: function (res){
            this.lvnRmInf = res

            this.lvnRmInf.forEach((code) => {
                this.exBtn[0].expandList.push(code.lvnRmNm)
            });//end foreach
        },
        getFamlLtrInfoAfter: function (res) {
            if(Object.keys(res).length > 0){
                this.cntnt = res.cntnt
                this.$store.commit('setFamlLtrPk', res.famlLtrPk)
            }
            else{
                this.cntnt = ''
                this.$store.commit('setFamlLtrPk', 0)
            }
        },
        getFamlLtrPdfPathAfter: function (res) {
            let path = remoteURL + 'opr/imgPrview?'
            let fileName = 'fileNm=' + res.fileNm
            let filePath = 'filePth=' + res.filePth
            let fileCode = 'fileKndCd=2'

            this.pdfUrl = path + fileName + '&' + filePath + '&' + fileCode + '&' + 't=' + Math.random()

            this.pdfPath.filePth = res.filePth
            this.pdfPath.fileNm = res.fileNm
            this.pdfPath.orgFileNm = res.orgFileNm

            this.prtFmlyLeter(this.pdfUrl, this.pdfPath)
        },
        setFamlLtrAfter: function (res) {
            if(res.statusCode == 200){
                this.snackControll([true, 2500, res.message, 'info'])
                this.cntnt = ''
                this.selectedItem = -1
                this.getFamlLtrList(this.date)
                //this.$emit('reload', true)
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        preSetFamlLtr: function () {
            if(this.selectedItem != -1){
                let tmp = Object.assign({}, this.famlLtrList[this.selectedItem])
                tmp.fcltyNum = this.$store.getters.getFcltyNum
                //tmp.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
                tmp.cntntClcd = '2'
                tmp.stdYymm = this.date
                tmp.cntnt = this.cntnt
                tmp.userNm = this.$store.getters.getUserNm
                this.setFamlLtr(tmp)
            }
            else
                this.snackControll([true, 3000, '수급자를 선택하고 저장해주세요.', 'info'])
        },
        preFamlLtrPdfPath: function () {
            let items = Object.assign([], this.selectedFmlyLeter)
            items.sort((x, y) => x.toLowerCase() - y.toLowerCase())
            let chc = items.findIndex( v => v == '8')
            if(chc > -1){
                items.splice(chc, 1)
                items.push('8')
            }
            
            let sltList = this.famlLtrList.filter( v => v.isSelected == true)

            //기관코드, 연월도, 수급자명, 수급자pk, 패밀리 pk, cntKind,
            let tmp = {}
            tmp.fcltyNum = this.$store.getters.getFcltyNum
            tmp.inqYYMM = this.date
            tmp.userNm = this.$store.getters.getUserNm

            sltList.forEach(item => {
                tmp.name = item.name
                tmp.bnMmbrPk = item.bnMmbrPk
                tmp.bnfcrPk = item.bnfcrPk
                tmp.famlLtrPk = item.famlLtrPk
            });
            tmp.clsfc = items

            this.getFamlLtrPdfPath(tmp)

        },
        expandClose: function () {
            this.$refs.exBtnClose.isClose()
        },
        getPickerDate: function (date) {
            this.date = date    
            this.sltDate = date.substr(0, 4) + '년 ' + date.substr(4, 2) + '월'
            this.getFamlLtrList(date)
        },
        checkBoxMessage: function () {
            
            if(this.allChecker == true){
                this.famlLtrList.forEach(item => {
                    item.isSelected = true
                })
                //this.filledBtn[1].disabled = false
                this.outlinedBtn[0].disabled = true
            }
            else if(this.allChecker == false){
                this.famlLtrList.forEach(item => {
                    item.isSelected = false
                })
                //this.filledBtn[1].disabled = true
                this.outlinedBtn[0].disabled = true
            }
        },
        checkBoxIndex: function () {            
            let chk = []
            
            this.famlLtrList.forEach(items => {                
                if(typeof items.isSelected == 'undefined'){
                    chk.push(false)
                } else {
                    chk.push(items.isSelected)
                }
            });
            
            let vbeBtn = chk.filter( v => v == true )
            if(vbeBtn.length == 1){
                if(this.selectedFmlyLeter.length > 0){
                    this.outlinedBtn[0].disabled = false
                    //this.preFamlLtrPdfPath()
                }
                else
                    this.outlinedBtn[0].disabled = true
            }
            else
                this.outlinedBtn[0].disabled = true

            if(this.selectedFmlyLeter.length > 0)
                this.filledBtn[1].disabled = false
            else
                this.filledBtn[1].disabled = true

            let result = chk.every((item) => item==true)
            if(result) this.allChecker = true
            else this.allChecker = false
        },
        sltFmlyLeter: function (text) {
            if(!this.selectedFmlyLeter.includes(text))
                this.selectedFmlyLeter.push(text)
            else {
                this.selectedFmlyLeter.forEach((item, i) => {
                    if(item == text) this.selectedFmlyLeter.splice(i, 1)
                })
            }

            let vbeBtn = this.famlLtrList.filter( v => v.isSelected == true )

            if(vbeBtn.length == 1){
                if(this.selectedFmlyLeter.length > 0){
                    //this.preFamlLtrPdfPath()
                    this.outlinedBtn[0].disabled = false
                }
                else
                    this.outlinedBtn[0].disabled = true
            }
            else
                this.outlinedBtn[0].disabled = true

            if(this.selectedFmlyLeter.length > 0)
                this.filledBtn[1].disabled = false
            else
                this.filledBtn[1].disabled = true
        },
        tableClickEvent: function (index){
            let sltItm = this.famlLtrList[index].famlLtrPk
            if(sltItm == null)
                sltItm = 0
            
            if(this.selectedItem == -1){
                if(this.selectedFmlyLeter.length != 0)
                    this.outlinedBtn[0].disabled = false
                else
                    this.outlinedBtn[0].disabled = true

                this.selectedItem = index

                if(this.famlLtrList[index].famlLtrPk == null)
                    this.cntnt = ''
                else
                    this.getFamlLtrInfo(this.famlLtrList[index].famlLtrPk)
            }
            else if(this.selectedItem != index){
                if(this.selectedFmlyLeter.length != 0)
                    this.outlinedBtn[0].disabled = false
                else
                    this.outlinedBtn[0].disabled = true

                this.selectedItem = index

                if(this.famlLtrList[index].famlLtrPk == null)
                    this.cntnt = ''
                else
                    this.getFamlLtrInfo(this.famlLtrList[index].famlLtrPk)
            }
            else{
                this.selectedItem = -1
                this.cntnt = ''
                this.outlinedBtn[0].disabled = true
                this.$store.commit('setFamlLtrPk', 0)
            }
        },
        onClickBtn: function (index){
            if(index == '저장'){
                this.preSetFamlLtr()
            }
            else if(index == '가정통신문 발송'){
                this.sendMsgAct()
            }
        },
        onModal: function(chk) {
            if(chk == 6)
                this.dialog.splice(1, 1, false)
            else if(chk == 7)
                this.dialog.splice(2, 1, false)
            else if(chk == 8)
                this.dialog.splice(3, 1, false)
            else if(chk == 9990){
                if(this.selectedItem != -1){
                    this.dialog.splice(2, 1, false)
                    this.removeFamlLtr(this.famlLtrList[this.selectedItem].famlLtrPk)
                }
                else{
                    this.dialog.splice(2, 1, false)
                    this.snackControll([true, 3000, '수급자를 선택하고 삭제해주세요.', 'info'])
                }
            }
        },
        setSearchText: function (value) {
            this.schField = value

            this.schDummy = this.schDummy + '.'
        },
        filterSearchD1: function (sch) {
                
            if(sch.length == 0) this.schChkField1.push('')

            this.schChkField1 = []
            sch.forEach((item) => {
                this.schChkField1.push(item)
            });

            //배열 공백 제거
            this.schChkField1 = this.schChkField1.filter(function(item) {
                return item !== null && item !== undefined && item !== '';
            });
            //배열 중복 값 제거
            this.schChkField1 = this.schChkField1.filter((val, idx) => {
                return this.schChkField1.indexOf(val) === idx
            });

            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column

            let col0 = filter.name.includes(this.schField)      

            let col1 = true
            if(this.schChkField1.length != 0){
                this.schChkField1.forEach((stcd, i) => {
                    if(i == 0) col1 = filter.lvnRm == stcd
                    else col1 = col1 || filter.lvnRm == stcd
                });
            }

            return col0 && col1
        },
        prtFmlyLeter: function (){
            let obj = {}
            obj.rptTyp = '3'
            obj.mode = '1'
            obj.pdfUrl = this.pdfUrl
            obj.pdfPath = this.pdfPath
          
            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: obj, outObj: null, runEvt: true,
            });
        },
        prtPyrSpc: function (pk){
            this.rptMode = '2'

            let obj = {}
            obj.rptTyp = '1'
            obj.mode = '2'
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnMmbrPk = pk
            obj.bnfcrPk = null
            obj.prtClcd = '1'
            obj.inqClcd = 2
            obj.prtCd = '14'
            obj.inqYYMM = this.$moment(this.date, 'YYYYMM', true).subtract(1, 'months').format('YYYYMM')
            obj.inqYmdStart = ''
            obj.inqYmdEnd = ''
            obj.list = [ { bzClcd: [], bzPk: [] } ]

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: obj, outObj: null, runEvt: true,
            });
        },
        prtCstStt: function (bnmm, bnfcr){
            this.rptMode = '2'

            let obj = {}
            obj.rptTyp = '1'
            obj.mode = '2'
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnMmbrPk = bnmm
            obj.bnfcrPk = bnfcr
            obj.prtClcd = '1'
            obj.inqClcd = 2
            obj.prtCd = '8'
            obj.inqYYMM = this.$moment(this.date, 'YYYYMM', true).subtract(1, 'months').format('YYYYMM')
            obj.inqYmdStart = ''
            obj.inqYmdEnd = ''
            obj.list = [ { bzClcd: [], bzPk: [] } ]

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: obj, outObj: null, runEvt: true,
            });
        },
        prtPrgrPrtcp: function (pk){
            this.rptMode = '2'

            let obj = {}
            obj.rptTyp = '1'
            obj.mode = '2'
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnMmbrPk = pk
            obj.bnfcrPk = null
            obj.prtClcd = '1'
            obj.inqClcd = 2
            obj.prtCd = '27'
            obj.inqYYMM = this.$moment(this.date, 'YYYYMM', true).subtract(1, 'months').format('YYYYMM')
            obj.inqYmdStart = ''
            obj.inqYmdEnd = ''
            obj.list = [ { bzClcd: [], bzPk: [] } ]

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: obj, outObj: null, runEvt: true,
            });
        },
        prtPrgrSchd: function (){
            this.rptMode = '2'

            let obj = {}
            obj.rptTyp = '1'
            obj.mode = '2'
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnMmbrPk = null
            obj.bnfcrPk = null
            obj.prtClcd = '1'
            obj.inqClcd = 2
            obj.prtCd = '13'
            obj.inqYYMM = this.date
            obj.inqYmdStart = ''
            obj.inqYmdEnd = ''
            obj.list = [ { bzClcd: [], bzPk: [] } ]

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: obj, outObj: null, runEvt: true,
            });
        },
        prtMealTbl: function (){
            this.rptMode = '2'

            let obj = {}
            obj.rptTyp = '1'
            obj.mode = '2'
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnMmbrPk = null
            obj.bnfcrPk = null
            obj.prtClcd = '1'
            obj.inqClcd = 2
            obj.prtCd = '2'
            obj.inqYYMM = this.date
            obj.inqYmdStart = ''
            obj.inqYmdEnd = ''
            obj.list = [ { bzClcd: [], bzPk: [] } ]

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: obj, outObj: null, runEvt: true,
            });
        },
        sendMsgAct: function (){
            this.bizMsgInfo.mmbrPk.splice(0)

            this.famlLtrList.forEach(items => {
                if(items.isSelected == true)
                    this.bizMsgInfo.mmbrPk.push(items.bnMmbrPk)
            });

            this.bizMsgInfo.bzPk = Number(this.date)
            this.bizMsgInfo.bzCntnt = this.sltDate
            this.bizMsgInfo.bzTitle = this.sltDate+" 가정통신문"

            this.menu.splice(2, 1, true)
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    
    data: () => ({
        menu: [false, false, false, false],
        rptMode: '2',
        pdfUrl: '',
        pdfPath: { filePth: '', fileNm: '', orgFileNm: '' },
        cntnt: '',
        selectedItem: -1,
        selectedFmlyLeter: [],
        date: '',
        sltDate: '',
        schField: '',
        schDummy: '',
        schChkField1: [],
        famlLtrList : [],
        lvnRmInf: [],
        allChecker: false,
        dialog:[false, false, false, false],
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type: 'action', width:'600px', disabled: false, },
            { icon: 'mdi-arrow-expand-right', class: 'ml-1 white--text', color:'blueBtnColor', text: '가정통신문 발송', type: 'action', width:'1200px', disabled: true, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type: 'dialogue', width:'500px', disabled: false, },
        ],
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '가정통신문 출력', width:'1000px', disabled: true, },
        ],
        exBtn: [
            {
                index:  0,
                btnTitle: '생활실',
                btnIcon: 'bed',
                exWidth: 124,
                exHeigth: 100,
                expandList: [],
            },
        ],
        headers: [
            { text: '수급자명', value: 'name', align: 'center', sortable: true, width: '80', class: 'black--text'},
            { text: '성별', value: 'gndr', align: 'center', sortable: true, width: '70', class: 'black--text'},
            { text: '나이', value: 'age', align: 'center', sortable: true, width: '70', class: 'black--text'},
            { text: '생활실', value: 'lvnRm', align: 'center', sortable: true, width: '80', class: 'black--text'},
            { text: '개인현황', value: 'famlLtrPk', align: 'center', sortable: true, width: '100', class: 'black--text'},
            { text: '급여제공', value: '', align: 'center', sortable: true, width: '70', class: 'black--text'},
            { text: '프로그램참여', value: '', align: 'center', sortable: true, width: '70', class: 'black--text'},
            { text: '급여비용명세', value: '', align: 'center', groupable: true, sortable: true, width: '70', class: 'black--text'},
            { text: '발송일', value: '', align: 'center', groupable: true, sortable: true, width: '70', class: 'black--text'},
        ],
        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: 0, bnfcrPk: null, prtClcd: '1', inqClcd: 2, prtCd: '',
            inqYmdStart: '', inqYmdEnd: '', inqYYMM: '',
            list: [ { bzClcd: [], bzPk: [] } ],
        },
        bizMsgInfo: {
            mmbrPk: [], docClcd: 5, bzPk: 0, bzTitle: '', bzCntnt: '',
        },
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>

<style>
    .sltRow { background: #ebf6fe !important; }
    .disSltRow { background: white !important; }

    .fmlyLt div table { border-collapse: separate !important; border-spacing: 0px !important; position: sticky !important;
        z-index: 3 !important; }
</style>