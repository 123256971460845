<template>
    <v-sheet class="ma-0 pa-7" rounded="md">
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col cols="4">
                <span class="text-h4 font-weight-bold">외부직원&nbsp;추가</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="1">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </v-col>
        </v-row>
        <v-row class="mt-5 pa-0" no-gutters>
            <v-col cols="12">
                <v-tabs
                    v-model="tab" class="tab-dft-Theme" active-class="black"
                    center-active hide-slider>
                    <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class" :disabled="item.enter">{{item.tab}}</v-tab>
                </v-tabs>
                <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
                <v-tabs-items v-model="tab">
                    <v-tab-item eager :transition="false">
                        <ExtInstr></ExtInstr>
                    </v-tab-item>
                    <v-tab-item eager :transition="false">
                        <ExtVlntr></ExtVlntr>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import ExtInstr from './ExtInstr.vue';
import ExtVlntr from './ExtVlntr.vue';

export default {
    name: 'ExtEmpMdl',

    props : {
   
    },
        
    components: {
        ExtInstr,
        ExtVlntr,
    },

    created: function(){
   
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
     
        })
    },

    computed: {

    },

    watch:{

    },
    
    methods: {
        onClose: function () {
            this.$emit('modalClose', '0')
        },
    },

    data: () => ({
        tab: 0,
        tabItems: [
            { index: 0, tab: '외부강사', class: 'fontOneDotTwoRem px-15', enter: false },
            { index: 1, tab: '자원봉사자', class: 'fontOneDotTwoRem px-15', enter: false },
        ],
    }),
};
</script>