<template>
    <v-sheet class="rounded-lg rounded-l-0 overflow-auto pa-2" color="" :min-width="areaWidth+100" :max-width="areaWidth+300">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        
        <v-row class="pa-0 mt-3" no-gutters>
            <v-col cols="12">
                <v-tabs
                    v-model="tab" class="tab-dft-Theme" active-class="black"
                    center-active hide-slider>
                    <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class" :disabled="item.enter">{{item.tab}}</v-tab>
                </v-tabs>
                <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
                <v-tabs-items v-model="tab">
                    <v-tab-item eager :transition="false">
                        <WrkSttEmp ref="wrkSttEmp"></WrkSttEmp>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import WrkSttEmp from '../../components/emp/WrkSttEmp.vue';

export default {
    name: 'WrkngJrnl',
    
    components: {
        WrkSttEmp,
    },

    props : {

    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.areaWidth = 1260 - Number(this.$parent.$parent.midAreaWidth)
        })
    },

    computed: {

    },  

    watch: {
        'tab': function() {
            if(this.tab == 1)
                this.$router.push({ name: 'DlyWrkStt', })
        }
    },

    methods: {
        rprtActCall: function (){
            this.prtThrowObj.list[0].bzPk.splice(0)
            this.prtThrowObj.list[0].bzPk.push(this.$parent.$parent.sltEmpPk)
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
            this.prtThrowObj.inqYYMM = this.$refs.wrkSttEmp.sltDate

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
            });
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    data: () => ({
        tab: 0,
        areaWidth: 708,
        snackOptions: [false, 3000, '', 'info'],
        tabItems: [
            { index: 0, tab: '직원별 근무현황', class: 'fontOneDotTwoRem px-15', enter: false, },
            { index: 1, tab: '일별 근무현황', class: 'fontOneDotTwoRem px-15', enter: false, to: '/' },
        ],
        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '11', inqYYMM: '',
            rptTyp: '1', mode: '2', list: [ { bzClcd: [], bzPk: [] } ],
        },
    }),
};
</script>