<template>
    <v-sheet class="pa-7 ma-0" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold">본인&nbsp;부담금&nbsp;입금&nbsp;취소처리</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose()">mdi-close</v-icon>
        </div>
        <div class="mt-7">
            <basicInfo :bnMmbrPk="this.$parent.$parent.$parent.$parent.bnMmbrPk" ref="basicInfo"></basicInfo>
        </div>
        <v-form ref="dpstCnclForm" lazy-validation>
            <v-divider class="mt-3"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                    <span class="black--text font-weight-medium py-1">입금취소일</span>
                </v-col>
                <v-col class="d-flex justify-left align-center tableBL" cols="10">
                    <span class="white d-inline-block py-1 pl-2" style="width: 147px;">
                        <CmmDateComp v-model="copayClmCnclInfo.dpsCnclDt" :required="true"></CmmDateComp>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                    <span class="black--text font-weight-medium py-1">처리자</span>
                </v-col>
                <v-col class="d-flex justify-left align-center tableBL" cols="10">
                    <span class="d-inline-block d-flex justify-left align-center px-2 py-1" style="width: 155px;">
                        <v-text-field
                            v-model="copayClmCnclInfo.offcr" :rules="[rules.required]"
                            height="28" readonly hide-details outlined dense>
                        </v-text-field>
                        <v-btn 
                            class="ml-1 brown lighten-3" style="padding:0px 1px 0px 2px !important"
                            min-width="30" height="25" @click="menu.splice(1, 1, true)"
                            dark small>
                            <v-icon size="20">mdi-account-edit</v-icon>
                        </v-btn>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                    <span class="black--text font-weight-medium py-1">취소사유</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBL" cols="10">
                    <span class="d-inline-block px-2 py-1" style="width: 100%;">
                        <v-text-field
                            v-model="copayClmCnclInfo.rmrks" :rules="[rules.required]"
                            height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <!-- <div v-if="copayClmCnclInfo.cashBillHisPk > 0 || copayClmCnclInfo.prpymRfndAmt > 0">
                <v-divider class="mt-3"></v-divider>
                <v-row class="greyE01 pa-0" no-gutters>
                    <v-col class="d-flex justify-center tableBR" cols="10">
                        <span class="black--text font-weight-medium py-1">※&nbsp;확인사항</span>
                    </v-col>
                    <v-col class="d-flex justify-center" cols="2">
                    </v-col>
                </v-row>
                <v-divider class=""></v-divider>
                <v-row v-if="copayClmCnclInfo.cashBillHisPk > 0" class="pa-0" no-gutters>
                    <v-col
                        class="d-flex justify-center tableBB tableBR py-1"
                        cols="10">
                        <div>
                            <div>현금영수증 발행내역이 있습니다. 입금 취소하시면 현금영수증 발행도 함께 취소됩니다.</div>
                            <div>입금 취소 하시겠습니까?</div>
                        </div>
                    </v-col>
                    <v-col class="d-flex justify-center align-center tableBB" cols="2">
                        <span class="d-inline-block" style="width: 80px">
                            <v-checkbox
                                class="checkIcon cnsntIcon"
                                v-model="copayClmCnclInfo.cashYn"
                                @change="aplyBtnAct"
                                true-value="1" false-value="2" label="동의"
                                on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                hide-details>
                            </v-checkbox>
                        </span>
                    </v-col>
                </v-row>
                <v-row v-if="copayClmCnclInfo.prpymRfndAmt > 0" class="pa-0" no-gutters>
                    <v-col
                        class="d-flex justify-center tableBB tableBR py-1"
                        cols="10">
                        <span>잔여 선납금 환불 이력이 있습니다. 입금 취소 하시겠습니까?</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center tableBB" cols="2">
                        <span class="d-inline-block" style="width: 80px">
                            <v-checkbox
                                class="checkIcon cnsntIcon"
                                v-model="copayClmCnclInfo.rfndYn"
                                @change="aplyBtnAct"
                                true-value="1" false-value="2" label="동의"
                                on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                hide-details>
                            </v-checkbox>
                        </span>
                    </v-col>
                </v-row>
            </div> -->
            <v-row class="pa-0 mt-4" no-gutters>
                <v-col class="d-flex justify-center" cols="12">
                    <v-btn
                        class="white--text" color="blueBtnColor" height="30"
                        @click="cnclCfm"
                        samll rounded>
                        <v-icon small>mdi-content-save-outline</v-icon>
                        <span style="font-size: 1rem;">입금취소</span>
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
       
        <v-dialog v-model="menu[1]" content-class="round" max-width="800" persistent eager>
            <EmpSelector @isClose="menu.splice(1, 1, false)" @outEmp="getEmpInfo" :prtWrtDt="''"></EmpSelector>
        </v-dialog>
    </v-sheet>
</template>

<script>
import basicInfo from '../../components/bnfcr/BnfcrBasicInfo.vue';
import EmpSelector from '../commons/EmpSelector.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';

import { cnclCopayDps } from '../../api/bnfcr.js';

export default {
    name: 'CopayDpstCncl',

    props : {

    },
        
    components: {
        basicInfo,
        EmpSelector,
        CmmDateComp,
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.copayClmCnclInfo.offcr = this.$store.getters.getUserNm
            this.copayClmCnclInfo.dpsCnclDt = this.$moment().format('YYYY-MM-DD')
        })
    },

    computed: {

    },

    watch:{

    },
    
    methods: {
        setCnclCopayDps: function (obj){
            cnclCopayDps(obj)
                .then((response) => ( this.setCnclCopayDpsAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/updCnclCopayDps : ' + error))
        },
        setCnclCopayDpsAfter: function (res){
            if(res.statusCode == 200) {
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])
                this.$parent.$parent.$parent.$parent.getCopayDpsList()
                this.$parent.$parent.$parent.$parent.$parent.$parent.reloadList()
                this.clearForm()
                this.onClose()
            }
            else
                this.$parent.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])
        },
        preCnclCopayDps: function (){
            let obj = Object.assign({}, this.copayClmCnclInfo)
            let chk = this.$refs.dpstCnclForm.validate()

            obj.dpsCnclDt = obj.dpsCnclDt.afterDateFormatHyp()
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm
            obj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            
            if(chk){
                // this.saveBtn = true
                this.setCnclCopayDps(obj)
            }
            else
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, '필수 항목을 입력해주세요.', 'info'])
        },
        cnclCfm: function () {
            this.$parent.$parent.$parent.$parent.cfmInf.ttl = ''
            this.$parent.$parent.$parent.$parent.cfmInf.cntnt.splice(0)

            this.$parent.$parent.$parent.$parent.cfmInf.ttl = '본인부담금 입금취소 확인'
            if( this.copayClmCnclInfo.prpymRfndAmt > 0 )
                this.$parent.$parent.$parent.$parent.cfmInf.cntnt.push('잔여 선납금 환불 이력이 있습니다.')
            if( this.copayClmCnclInfo.cashBillHisPk > 0 )
                this.$parent.$parent.$parent.$parent.cfmInf.cntnt.push(
                    '현금영수증 발행내역이 있습니다. 입금 취소하시면 현금영수증 발행도 취소됩니다.'
                )

            this.$parent.$parent.$parent.$parent.cfmInf.cntnt.push('입금 취소 하시겠습니까?')

            this.$parent.$parent.$parent.$parent.menu.splice(5, 1, true)
        },
        aplyBtnAct: function () {
            let onCash = false
            let onRfnd = false

            if(this.copayClmCnclInfo.cashBillHisPk > 0 ){
                if(this.copayClmCnclInfo.cashYn == '1')
                    onCash = true
            }
            else
                onCash = true
            if(this.copayClmCnclInfo.prpymRfndAmt > 0 ){
                if(this.copayClmCnclInfo.rfndYn == '1')
                    onRfnd = true
            }
            else
                onRfnd = true

 
            if(onCash == true && onRfnd == true)
                this.saveBtn = false
            else
                this.saveBtn = true
        },
        getEmpInfo: function (obj) {
            this.copayClmCnclInfo.offcr = obj[0].name
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
        onClose: function () {
            this.copayClmCnclInfo.copayDpsHisPk = 0
            this.$emit('isClose', '.')
        },
        clearForm: function () {
            this.copayClmCnclInfo.copayDpsHisPk = 0
            this.copayClmCnclInfo.dpsPrcHisPk = 0
            // this.copayClmCnclInfo.dpsCnclDt = ''
            // this.copayClmCnclInfo.offcr = ''
            this.copayClmCnclInfo.rmrks = ''
            this.copayClmCnclInfo.dpsClcd = '2'

            this.$refs.dpstCnclForm.resetValidation()
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        menu: [false,],
        saveBtn: true,
        forceRender: 0,
        copayClmCnclInfo: {
            copayDpsHisPk: 0, dpsPrcHisPk: 0, cashBillHisPk: 0,
            dpsCnclDt: '', offcr: '', rmrks: '', dpsClcd: '2', prpymRfndAmt: 0,
            cashYn : '2', rfndYn: '2',
        },
        rules: {
            required: value => !!value || 'Required.',
        },
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>