<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" min-height="700">
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">급여수가 변경내역</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <div class=" pr-2 mt-2">
            <v-tabs v-model="tab" class="tab-dft-Theme" active-class="black" center-active hide-slider>
                <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class">{{ item.tab }}</v-tab>
            </v-tabs>
            <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
            <v-tabs-items v-model="tab" style="height:500px;">
                <v-tab-item eager transition="fade-transition">
                    <tab1 ref="tab1" :tab="tab"></tab1>
                </v-tab-item>
                <v-tab-item eager transition="fade-transition">
                    <tab2 ref="tab2" :tab="tab"></tab2>
                </v-tab-item>
            </v-tabs-items>
        </div>
    </v-sheet>
</template>

<script>
import tab1 from '../../components/opr/oprFeeInfListTab1.vue';
import tab2 from '../../components/opr/oprFeeInfListTab2.vue';

export default {
    name: 'oprFeeInfList',

    components: {
        tab1,
        tab2,
    },

    created: function(){
        
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
        })
    },

    computed: {

    },

    watch: {
    },
        
    methods: {
        onClose: function () {
            this.$emit('isClose', '.')
        },

    },
    
    data: () => ({
        tab:0,
        tabItems: [
            { index: 0, tab: '급여수가 변경내역', class: 'fontOneDotTwoRem px-15',},
            { index: 1, tab: '비급여수가 변경내역', class: 'fontOneDotTwoRem px-15',},
        ],
    }),
};
</script>