<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold">계약의사&nbsp;관리</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose()">mdi-close</v-icon>
        </div>
        <div class="mt-5 mb-2 d-flex justify-left align-center"> 
            <span class="d-inline-block" style="width: 130px; height: 35px;">
                <v-text-field v-model="schFields" class="ma-0 pa-0 hostplSearchField" style="" background-color="white" append-icon="mdi-magnify"
                    hide-details single-line rounded outlined dense filled label="계약의사조회">
                </v-text-field>
            </span>
        </div>
        <div class="mt-2">
            <v-form ref="dctForm" lazy-validation>
                <v-data-table locale="ko" class="" height="400"
                    :headers="headers" :items="items" :search="schFields" fixed-header
                    hide-default-header hide-default-footer disable-pagination dense>
                    <template v-slot:header="{ props }" >
                        <thead>
                            <tr style="height: 40px;">
                                <th class="hsptlHeader" style="font-size: 1.1rem; border-right: 1px solid #dbdbdb !important; width: 15%;">
                                    <span class="black--text">{{props.headers[0].text}}</span>
                                </th>
                                <th class="hsptlHeader" style="font-size: 1.1rem; border-right: 1px solid #dbdbdb !important; width: 18%;">
                                    <span class="black--text">{{props.headers[1].text}}</span>
                                </th>
                                <th class="hsptlHeader" style="font-size: 1.1rem; border-right: 1px solid #dbdbdb !important; width: 26%;">
                                    <span class="black--text">{{props.headers[2].text}}</span>
                                </th>
                                <th class="hsptlHeader" style="font-size: 1.1rem; border-right: 1px solid #dbdbdb !important; width: 15%;">
                                    <v-btn class="" color="blue200" height="26" @click="addRow" icon>
                                        <v-icon>mdi-plus-circle-outline</v-icon>
                                    </v-btn>
                                </th>
                                <th class="hsptlHeader" style="font-size: 1.1rem; width: 8%;">

                                </th>
                            </tr>
                            <tr style="border-top: 1px solid #dbdbdb !important;">

                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item, index }'>
                        <tr class="rounded-0" style="height: 36px;">
                            <td class="tableContentCommon px-2" style="border-right: 1px solid #dbdbdb !important; min-width: 100%;">
                                <v-text-field v-model="item.dctNm" :rules="[rules.required]" height="28" hide-details outlined dense></v-text-field>
                            </td>
                            <td class="tableContentCommon px-2" style="border-right: 1px solid #dbdbdb !important;">
                                <v-text-field v-model="item.hospNm" height="28" hide-details outlined dense></v-text-field>
                            </td>
                            <td class="tableContentCommon px-2" style="border-right: 1px solid #dbdbdb !important;">
                                <v-text-field v-model="item.tel" height="28" hide-details outlined dense></v-text-field>
                            </td>                            
                            <td class="tableContentCommon px-2" style="border-right: 1px solid #dbdbdb !important;">
                                <v-btn class="" color="blueBtnColor"
                                    height="26" @click="preSetDctInf(index)" icon>
                                    <v-icon>mdi-content-save-edit-outline</v-icon>
                                </v-btn>
                                <v-btn v-if="item.dctInfPk != 0" class="" color="grey006"
                                    height="26" @click="removeDctInf(item.dctInfPk)" icon>
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn>
                                <v-btn v-else class="" color="grey006"
                                    height="26" @click="items.splice(index, 1)" icon>
                                    <v-icon>mdi-minus-circle-outline</v-icon>
                                </v-btn>
                            </td>
                            <td class="tableContentCommon px-2">
                                <v-btn
                                    v-if="item.dctInfPk != 0"
                                    class="ml-1 white--text darken-1" style="padding: 1px 0px 0px 2px !important"
                                    color="bckgrColor" min-width="30" height="25"
                                    @click="onAction(index)"
                                    dark small>
                                        <v-icon size="20">mdi-checkbox-marked-outline</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-form>
        </div>
    </v-sheet>
</template>

<script>
import { selDctInfList, insDctInf, delDctInf } from '../../api/bnftrcrd.js';

export default {
    name: 'DctrCarerCntrcMdf',

    props : {
       
    },
            
    components: {
       
    },

    created: function(){
        
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getDctInfList()
        })
    },

    computed: {

    },

    watch: {

    },
        
    methods: {
        getDctInfList: function () {
            selDctInfList(this.$store.getters.getFcltyNum)
                .then((response) => ( this.items = response.data ))
                .catch((error) => console.log('connect error /cmm/selDctInfList : ' + error))
        },
        setDctInf: function (obj) {
            insDctInf(obj)
                .then((response) => ( this.setDctInfAfter(response.data) ))
                .catch((error) => console.log('connect error /cmm/insDctInf : ' + error))
        },
        removeDctInf: function (dctPk) {
            delDctInf(this.$store.getters.getFcltyNum, dctPk, this.$store.getters.getUserNm)
                .then((response) => ( this.setDctInfAfter(response.data) ))
                .catch((error) => console.log('connect error /cmm/delDctInf : ' + error))
        },
        setDctInfAfter: function (res) {
            if(res.statusCode == 200){
                this.snackControll([true, 2500, res.message, 'info'])
                this.items.splice(0)
                this.getDctInfList()
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        preSetDctInf: function (index) {
            let obj = Object.assign({}, this.items[index])
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm
            let chk = this.$refs.dctForm.validate()
            if(chk){
                this.setDctInf(obj)
            }
        },
        onAction: function (index) {
            let obj = {
                dctInfPk:this.items[index].dctInfPk,
                hospNm : this.items[index].hospNm + ' ' + this.items[index].dctNm
            }

            this.$emit('outDct', obj)
            this.onClose()
        },
        onClose: function () {
            this.$emit('isClose', '.')
        },
        addRow: function () {
            let y = Object.assign({}, this.dummyItem)
            this.items.push(y)
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    
    data: () => ({
        schFields: '',
        agrItems: [{valcdnm: '협약', valcd: '1'}, {valcdnm: '미협약', valcd: '2'}],
        items: [],
        dummyItem: { dctNm: '', hospNm: '', tel: '', dctInfPk: 0, },
        headers: [
            { text: '의사명', value: 'dctNm', align: 'center', sortable: true, width: '15%', class: 'black--text greyE01'},
            { text: '병원명', value: 'hospNm', align: 'center', sortable: true, width: '22%', class: 'black--text greyE01'},
            { text: '전화번호', value: 'tel', align: 'center', sortable: true, width: '18%', class: 'black--text greyE01'},            
            { text: '', value: '', align: 'center', sortable: true, width: '23%', class: ''},
            { text: '', value: '', align: 'center', sortable: true, width: '23%', class: ''},
        ],
        rules: {
            required: value => !!value || 'Required.',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            telCheck: value => /^([0-9]{3,4})-?([0-9]{3,4})-?([0-9]{4})$/.test(value) || 'Tel Validate.',
        },
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>

<style>
    .hsptlHeader { background-color: #ededed !important; text-align: center !important; }
    .hostplSearchField div div div .v-label { line-height: 20px !important; }
    .hostplSearchField .v-input__control .v-input__slot  { padding: 0 10px 0 16px; }
    .hsptlSelect .v-input__icon { height: 22px; }
    .v-data-table__wrapper > table > tbody > tr:hover { background: inherit !important; }
</style>