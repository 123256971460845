<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold">기타&nbsp;비용</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose()">mdi-close</v-icon>
        </div>
        <v-form ref="msclnFeeDtlForm" lazy-validation>
            <v-row class="ma-0 pa-0 mt-5 tableBT" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium" style="font-size:1.1rem">청구년월</span>
                </v-col>
                <v-col class="d-flex justify-left align-center py-1 px-3" cols="4">
                    <span class="black--text" style="font-size:1rem">{{inqYYMM}}</span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium" style="font-size:1.1rem">비용발생&nbsp;항목</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-1 px-2" cols="4">
                    <span class="d-inline-block" style="width:100%;">
                        <v-text-field v-model="msclnFeeDtl.costItm" :rules="[rules.required]" hide-details outlined dense></v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-row class="ma-0 pa-0 tableBT" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium" style="font-size:1.1rem">급여제공일</span>
                </v-col>
                <v-col class="d-flex justify-left align-center py-1 px-2" cols="4">
                    <span class="d-inline-block" style="width: 154px;">
                        <CmmDateComp v-model="msclnFeeDtl.bnftOfrDt" :required="true"></CmmDateComp>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium" style="font-size:1.1rem">금액</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-1 px-2" cols="4">
                    <span class="d-inline-block" style="width:100%;">
                        <v-text-field v-model="msclnFeeDtl.amt " type="number" :rules="[rules.required]" hide-details outlined dense></v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-row class="ma-0 pa-0 tableBT" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium" style="font-size:1.1rem">본인부담금&nbsp;청구여부</span>
                </v-col>
                <v-col class="px-2" cols="4">
                    <v-radio-group
                        class="radio-dft-Theme py-2 ma-0"
                        v-model="msclnFeeDtl.copayClmYn "
                        :rules="[rules.required]"
                        hide-details row>
                        <v-radio :ripple="false" label="청구" value="1"></v-radio>
                        <v-radio :ripple="false" label="미청구" value="2"></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium" style="font-size:1.1rem">비용처리자</span>
                </v-col>
                <v-col class="d-flex justify-left align-center py-1 px-2" cols="4">
                    <span class="d-inline-block" style="width:50%;">
                        <v-text-field v-model="msclnFeeDtl.wrtr" :rules="[rules.required]" readonly hide-details outlined dense></v-text-field>
                    </span>
                    <v-btn 
                        class="ml-1 brown lighten-3" style="padding:0px 1px 0px 2px !important"
                        min-width="30" height="25" @click="menu.splice(1, 1, true)"
                        dark small>
                        <v-icon size="20">mdi-account-edit</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="ma-0 pa-0 tableBT tableBB" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium" style="font-size:1.1rem">상세내용</span>
                </v-col>
                <v-col class="d-flex justify-left align-center py-1 px-2" cols="10">
                    <span class="d-inline-block" style="width:100%;">
                        <v-text-field v-model="msclnFeeDtl.dtlCntnt" hide-details outlined dense></v-text-field>
                    </span>
                </v-col>
            </v-row>
        </v-form>
        <div class="mt-3 d-flex justify-center">
            <v-btn
                class="white--text" height="26" color="blueBtnColor" @click="preInsSet()" samll rounded>
                <v-icon small>mdi-content-save-outline</v-icon>
                <span style="font-size: 1rem;">저장</span>
            </v-btn>
        </div>
        <v-dialog v-model="menu[1]" eager content-class="round" persistent max-width="800">
            <EmpSelector @isClose="menu.splice(1, 1, false)" @outEmp="getEmpInfo"></EmpSelector>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { insMsclnFeeDtl } from '../../api/bnfcr.js';

import CmmDateComp from '../commons/CmmDateComp.vue';
import EmpSelector from '../commons/EmpSelector.vue';

export default {
    name: 'MsclnExpnsInsPrc',

    props : {

    },
        
    components: {
        CmmDateComp,
        EmpSelector,
    },

    created: function(){
 
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
       
        })
    },

    computed: {

    },

    watch:{
        'inqYYMM': function () {
            this.preYYMMSetter()
        },
    },
    
    methods: {
        setMsclnFeeDtl: function (obj) {
            insMsclnFeeDtl(obj)
                .then((response) => ( this.setMsclnFeeDtlAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/insMsclnFeeDtl : ' + error))
        },
        setMsclnFeeDtlAfter: function (res) {
            if(res.statusCode == 200){
                this.$parent.$parent.$parent.$parent.reloadList()
                this.snackControll([true, 2500, res.message, 'info'])
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        preYYMMSetter: function () {
            let da = this.inqYYMM.split('.', 2)
            let first = new Date(Number(da[0]), Number(da[1]), 1).getDate()
            let last = new Date(Number(da[0]), Number(da[1]), 0).getDate()
            this.minDate = da[0] + '-' + ('00' + da[1]).slice(-2) + '-' + ('00' + first).slice(-2)
            this.maxDate = da[0] + '-' + ('00' + da[1]).slice(-2) + '-' + ('00' + last).slice(-2)
            
            if(this.msclnFeeDtl.bnftOfrDt == '')
                this.msclnFeeDtl.bnftOfrDt = this.minDate
        },
        preInsSet: function () {
            let chk = this.$refs.msclnFeeDtlForm.validate()

            if(chk){
                let obj = Object.assign({}, this.msclnFeeDtl)
                obj.bnftOfrDt = obj.bnftOfrDt.afterDateFormatHyp()
                obj.inqYYMM = this.inqYYMM
                obj.fcltyNum = this.$store.getters.getFcltyNum
                obj.userNm = this.$store.getters.getUserNm

                this.setMsclnFeeDtl(obj)
            }
            else
                this.snackControll([true, 2000, '필수 항목을 입력해주세요.', 'warning'])
        },
        getEmpInfo: function (obj) {
            this.msclnFeeDtl.wrtr = obj[0].name
        },
        isModal: function (value) {
            this.menu.splice(value, 1, false)
        },
        onClose: function () {
            this.msclnFeeDtl = Object.assign({}, this.dummyMsclnFeeDtl)
            this.$refs.msclnFeeDtlForm.resetValidation()
            this.$emit('isClose', '.')
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        minDate: '',
        maxDate: '',
        inqYYMM: '',
        menu: [false, false],
        msclnFeeDtl: { msclnFeeDtlPk: 0, bnMmbrPk: 0, bnftOfrDt: '', costItm: '', amt: 0, dtlCntnt: '', copayClmYn: '1', wrtr: '' },
        dummyMsclnFeeDtl: { msclnFeeDtlPk: 0, bnMmbrPk: 0, bnftOfrDt: '', costItm: '', amt: 0, dtlCntnt: '', copayClmYn: '1', wrtr: '' },
        rules: {
            required: value => !!value || 'Required.',
        },
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>