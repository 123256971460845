<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div> 
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold">표준약관&nbsp;상세</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <v-row class="pa-0" no-gutters>
            <v-col class="pa-0" cols="6">
                <v-row class="pa-0 mt-5" no-gutters>
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold">계약정보</span>
                </v-row>

                <v-divider class="mt-3 mr-1"></v-divider>
                <v-row class="pa-0 mr-1" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center" cols="1">
                        <span class="black--text font-weight-medium">계약일</span>
                    </v-col>
                    <v-col class="txtAlgnMid pa-2" cols="3">
                        <CmmDateComp v-model="bnfcrCntrc.cntrcDt" :disabled="true" :required="true"></CmmDateComp>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                        <span class="black--text font-weight-medium">계약기간</span>
                    </v-col>
                    <v-col class="pa-2" cols="6">
                        <span class="d-inline-block" style="width: 43%;">
                            <CmmDateComp v-model="bnfcrCntrc.cntrcPrdStrt" :maxDt="bnfcrCntrc.cntrcPrdEnd" :disabled="true" :required="true"></CmmDateComp>
                        </span>
                        <span class="mx-2">
                            <v-icon class="" size="14">mdi-tilde</v-icon>
                        </span>
                        <span class="d-inline-block" style="width: 43%;">
                            <CmmDateComp v-model="bnfcrCntrc.cntrcPrdEnd" :minDt="bnfcrCntrc.cntrcPrdStrt" :disabled="true" :required="true"></CmmDateComp>                   
                        </span>
                    </v-col>
                </v-row>
                <v-divider class="mr-1"></v-divider>
                <v-row class="pa-0 mr-1" no-gutters>
                    <v-col class="greyE01 txtAlgnMid py-1" cols="1">
                        <span class="black--text font-weight-medium">납부일</span>
                    </v-col>
                    <v-col class="txtAlgnEnd px-3 py-1" cols="3">
                        <span class="font-weight-mideum">매월</span>
                        <span class="d-inline-block mx-2" style="width: 58px;">
                            <v-text-field
                                v-model="bnfcrCntrc.pymntDt" :disabled="true"
                                class="" type="number" height="28" color="blue"
                                hide-details outlined dense>
                            </v-text-field>
                        </span>
                        <span class="font-weight-mideum">일</span>
                    </v-col>
                    <v-col class="greyE01 txtAlgnMid py-1" cols="2">
                        <span class="black--text font-weight-medium">납부방법</span>
                    </v-col>
                    <v-col class="px-2 py-1" cols="6">
                         <span class="">계좌이체</span>
                    </v-col>
                </v-row>
                <v-divider class="mr-1"></v-divider>
                <v-divider class="mt-2 mr-1"></v-divider>
                <v-row class="pa-0 mr-1" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center" cols="1">
                        <div>
                            <div class="txtAlgnMid">
                                <span class="black--text font-weight-medium">본인</span>
                            </div>
                            <div class="txtAlgnMid">
                                <span class="black--text font-weight-medium">부담률</span>
                            </div>
                        </div>
                    </v-col>
                    <v-col class="d-flex justify-center align-center px-2" cols="3">
                        <v-select
                            v-model="bnfcrCntrc.copayRate"
                            :disabled="true" :key="forceRender"
                            :items="$parent.$parent.$parent.$parent.vCd63"
                            item-text="valcdnm" item-value="valcd"
                            dense hide-details outlined>
                        </v-select>                
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-0" cols="2">
                        <span class="black--text font-weight-medium">인정등급</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center px-2" cols="2">
                        <v-select
                            v-model="bnfcrCntrc.acknwRtng"
                            :disabled="true" :key="forceRender"
                            :items="$parent.$parent.$parent.$parent.vCd62"
                            item-text="valcdnm" item-value="valcd"
                            dense hide-details outlined>
                        </v-select>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                        <span class="black--text font-weight-medium">정부지원</span>
                    </v-col>
                    <v-col class="pa-2" cols="2">
                        <v-checkbox
                            class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                            v-model="bnfcrCntrc.gvrnmSnack"
                            :color="bnfcrCntrc.gvrnmSnack == '1' ? 'blue' : ''"
                            :ripple="false" :disabled="true"
                            label="간식비" true-value="1" false-value="2"
                            on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                            hide-details>
                        </v-checkbox>
                        <v-checkbox
                            class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                            v-model="bnfcrCntrc.gvrnmMeal"
                            :color="bnfcrCntrc.gvrnmSnack == '1' ? 'blue' : ''"
                            :ripple="false" :disabled="true"
                            label="식사 재료비" true-value="1" false-value="2"
                            on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                            hide-details>
                        </v-checkbox>
                    </v-col>
                </v-row>
                <v-divider class="mr-1"></v-divider>
                <v-row class="pa-0 mt-5" no-gutters>
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold">본인부담금&nbsp;정보</span>
                </v-row>
                <v-divider class="mt-2 mr-1"></v-divider>
                <v-row class="pa-0 mr-1" no-gutters>
                    <span class="greyE01 d-inline-block txtAlgnMid py-1" style="width: 23.8%; height: auto;">
                        <span class="black--text font-weight-medium">비급여&nbsp;수가&nbsp;기준</span>
                    </span>
                    <span class="d-inline-block tableBL" style="width: 75.7%; height: auto;">
                        <v-radio-group
                            class="radio-dft-Theme mt-0"
                            v-model="bnfcrCntrc.npymntFeeStd" :key="forceRender"
                            hide-details row>
                            <v-radio
                                class="d-inline-block ma-0 py-1 pl-2 tableBR" style="width: 50%;" label="시설 비급여 수가"
                                :ripple="false" :disabled="true"
                                value="1">
                            </v-radio>
                            <v-radio
                                class="d-inline-block ma-0 py-1 pl-2" style="width: 50%" label="개별 비급여 수가"
                                :ripple="false" :disabled="true"
                                value="2">
                            </v-radio>
                        </v-radio-group>
                    </span>
                </v-row>
                <v-divider class="mr-1"></v-divider>
                <v-row class="pa-0 mr-1" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center" cols="1">
                        <span class="black--text font-weight-medium">
                            비<br/>급<br/>여<br/><br/>수<br/>가<br/>정<br/>보
                        </span>
                    </v-col>
                    <v-col class="pa-0 tableBL" cols="11">
                        <v-row class="pa-0" no-gutters>
                            <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                                <span class="black--text font-weight-medium">식사재료비</span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBL" cols="10">
                                <span class="d-inline-block tableBR" style="width: 49.6%; height: 100%;">
                                    <v-row class="pa-0" style="height: 100%;" no-gutters>
                                        <v-col class="d-flex justify-center align-center tableBR" cols="6">
                                            <span class="fontOneRem">&#40;1식&#41;</span>
                                        </v-col>
                                        <v-col class="d-flex justify-center align-center" cols="6">
                                            <span class="fontOneRem">
                                                {{ nbnftFeeInfo != null ? nbnftFeeInfo.mealCost?.toLocaleString('ko-KR') + '원' : '' }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                </span>
                                <span class="d-inline-block" style="width: 50%; height: 100%;">
                                    <v-row class="pa-0" no-gutters>
                                        <v-col class="tableBR py-1" cols="4">
                                            <span class="fontOneRem">&#40;1식&#41;</span>
                                        </v-col>
                                        <v-col class="px-2 py-1" cols="8">
                                            <v-text-field
                                                v-model="indvCntrc.mealCost"
                                                type="number" height="28"
                                                :key="forceRender" :disabled="true"
                                                hide-details outlined dense>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </span>
                            </v-col>
                        </v-row>
                        <v-divider class=""></v-divider>
                        <v-row class="pa-0" no-gutters>
                            <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                                <span class="black--text font-weight-medium">경관유동식</span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBL" cols="10">
                                <span class="d-inline-block tableBR" style="width: 49.6%; height: 100%;">
                                    <v-row class="pa-0" style="height: 100%;" no-gutters>
                                        <v-col class="d-flex justify-center align-center tableBR" cols="6">
                                            <span class="fontOneRem">&#40;{{ ( bnfcrCntrc.lqdFoodClcd == '1' ) ? '1개월' : '1식' }}&#41;</span>
                                        </v-col>
                                        <v-col class="d-flex justify-center align-center" cols="6">
                                            <span class="fontOneRem">
                                                {{ nbnftFeeInfo != null ? nbnftFeeInfo.lqdFood?.toLocaleString('ko-KR') + '원' : '' }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                </span>
                                <span class="d-inline-block" style="width: 50%; height: 100%;">
                                    <v-row class="pa-0" no-gutters>
                                        <v-col class="tableBR px-2 py-1" cols="4">
                                            <v-select
                                                class="wrkSelect" height="28"
                                                v-model="indvCntrc.lqdFoodClcd"
                                                :items="lqdFood_term" :disabled="true" :key="forceRender"
                                                item-text="valcdnm" item-value="valcd"
                                                dense hide-details outlined>
                                            </v-select>
                                        </v-col>
                                        <v-col class="px-2 py-1" cols="8">
                                            <v-text-field
                                                v-model="indvCntrc.lqdFood"
                                                type="number" height="28"
                                                :key="forceRender" :disabled="true"
                                                hide-details outlined dense>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </span>
                            </v-col>
                        </v-row>
                        <v-divider class=""></v-divider>
                        <v-row class="pa-0" no-gutters>
                            <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                                <span class="black--text font-weight-medium">간식비</span>
                            </v-col>
                            <v-col class="d-flex tableBL" cols="10">
                                <span class="d-inline-block" style="width: 49.6%; height: 100%; padding-left: 1px;">
                                    <v-row class="pa-0" style="height: 100%;" no-gutters>
                                        <v-col class="d-flex justify-center align-center tableBR" cols="6">
                                            <span class="fontOneRem">&#40;{{ ( bnfcrCntrc.snackOfrClcd == '1' ) ? '1일' : '1식' }}&#41;</span>
                                        </v-col>
                                        <v-col class="py-1" cols="6">
                                            <div class="txtAlgnMid mb-1">
                                                <span class="fontOneRem">
                                                    {{ nbnftFeeInfo != null ? nbnftFeeInfo.snackFee?.toLocaleString('ko-KR') + '원' : '' }}
                                                </span>
                                            </div>
                                            <div class="px-2">
                                                <v-select
                                                    class="wrkSelect" height="28"
                                                    v-model="bnfcrCntrc.snackCntCd"
                                                    :items="cnt_term" :key="forceRender"
                                                    :disabled="true"
                                                    item-text="valcdnm" item-value="valcd"
                                                    dense hide-details outlined>
                                                </v-select>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </span>
                                <span class="d-inline-block tableBL" style="width: 50%; height: 100%; padding-left: 1px;">
                                    <v-row class="pa-0" style="height: 100%;" no-gutters>
                                        <v-col class="d-flex align-center tableBR px-2" style="height: 100%;" cols="4">
                                            <v-select
                                                class="wrkSelect" height="28"
                                                v-model="indvCntrc.snackOfrClcd"
                                                :items="snackFee_term" :disabled="true" :key="forceRender"
                                                item-text="valcdnm" item-value="valcd"
                                                dense hide-details outlined>
                                            </v-select>
                                        </v-col>
                                        <v-col class="d-flex align-center px-2" style="height: 100%;" cols="8">
                                            <span class="d-inline-block pr-1" style="width: 53%;">
                                                <v-text-field
                                                    v-model="indvCntrc.snackFee"
                                                    type="number" height="28"
                                                    :key="forceRender" :disabled="true"
                                                    hide-details outlined dense>
                                                </v-text-field>
                                            </span>
                                            <span class="d-inline-block" style="width: 47%;">
                                                <v-select
                                                    class="wrkSelect" height="28"
                                                    v-model="indvCntrc.snackCntCd"
                                                    :items="cnt_term" :key="forceRender"
                                                    :disabled="true"
                                                    item-text="valcdnm" item-value="valcd"
                                                    dense hide-details outlined>
                                                </v-select>
                                            </span>
                                        </v-col>
                                    </v-row>
                                </span>
                            </v-col>
                        </v-row>
                        <v-divider class=""></v-divider>
                        <v-row class="pa-0" no-gutters>
                            <v-col class="greyE01 d-flex justify-center align-center py-2" cols="2">
                                <div style="line-height: 1.3;">
                                    <div class="txtAlgnMid">
                                        <span class="black--text font-weight-medium">식사재료비</span>
                                    </div>
                                    <div class="txtAlgnMid">
                                        <span class="black--text font-weight-medium">월&nbsp;한도액</span>
                                    </div>
                                </div>
                            </v-col>
                            <v-col class="d-flex txtAlgnMid tableBL" cols="10">
                                <span class="d-inline-block" style="width: 49.6%; height: 100%; padding-left: 1px;">
                                    <v-row class="pa-0" style="height: 100%;" no-gutters>
                                        <v-col class="d-flex justify-center align-center tableBR" cols="6">
                                            <span class="fontOneRem">&#40;{{ (bnfcrCntrc.mealCostLmtYn == '1') ? '설정' : '미설정' }}&#41;</span>
                                        </v-col>
                                        <v-col class="d-flex justify-center align-center" cols="6">
                                            <span class="fontOneRem">
                                                {{ nbnftFeeInfo != null ? nbnftFeeInfo.mealCostLmt?.toLocaleString('ko-KR') + '원' : '' }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                </span>
                                <span class="d-inline-block tableBL" style="width: 50%; height: 100%; padding-left: 1px;">
                                    <v-row class="pa-0" style="height: 100%;" no-gutters>
                                        <v-col class="d-flex align-center tableBR px-2" cols="4">
                                            <v-checkbox
                                                class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                v-model="indvCntrc.mealCostLmtYn"
                                                :key="forceRender" :ripple="false" :disabled="true"
                                                true-value="1" false-value="2"
                                                label="설정" value="1" hide-details>
                                            </v-checkbox>
                                        </v-col>
                                        <v-col class="d-flex align-center px-2" cols="8">
                                            <v-text-field
                                                v-model="indvCntrc.mealCostLmt"
                                                type="number" height="28"
                                                :key="forceRender" :disabled="true"
                                                hide-details outlined dense>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </span>
                            </v-col>
                        </v-row>
                        <v-divider class=""></v-divider>
                        <v-row class="pa-0" no-gutters>
                            <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                                <span class="black--text font-weight-medium">이&#47;미용료</span>
                            </v-col>
                            <v-col class="d-flex txtAlgnMid tableBL" cols="10">
                                <span class="d-inline-block tableBR" style="width: 49.8%; height: 100%; padding-left: 1px;">
                                    <v-row class="pa-0" style="height: 100%;" no-gutters>
                                        <v-col class="d-flex justify-center align-center tableBR" cols="6">
                                            <span class="fontOneRem">&#40;1개월&#41;</span>
                                        </v-col>
                                        <v-col class="d-flex justify-center align-center" cols="6">
                                            <span class="fontOneRem">
                                                {{ nbnftFeeInfo != null ? nbnftFeeInfo.btyFee?.toLocaleString('ko-KR') + '원' : '' }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                </span>
                                <span class="d-inline-block" style="width: 50%; height: 100%;">
                                    <v-row class="pa-0" style="height: 100%;" no-gutters>
                                        <v-col class="d-flex justify-center align-center tableBR px-2" cols="4">
                                            <span class="fontOneRem">&#40;1개월&#41;</span>
                                        </v-col>
                                        <v-col class="px-2 py-1" style="height: 100%;" cols="8">
                                            <v-text-field
                                                v-model="indvCntrc.btyFee"
                                                type="number" height="28"
                                                :key="forceRender" :disabled="true"
                                                hide-details outlined dense>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </span>
                            </v-col>
                        </v-row>
                        <v-divider class=""></v-divider>
                        <v-row class="pa-0" no-gutters>
                            <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                                <span class="black--text font-weight-medium">상급침실비</span>
                            </v-col>
                            <v-col class="txtAlgnMid tableBL" cols="10">
                                <span class="d-inline-block tableBR" style="width: 49.6%; height: 100%;">
                                    <v-row class="pa-0" style="height: 100%;" no-gutters>
                                        <v-col class="px-2 py-1 tableBR" cols="6">
                                            <v-select
                                                class="wrkSelect" height="28"
                                                v-model="bnfcrCntrc.upBedClcd"
                                                :disabled="true"
                                                :items="lvnRm_item" :key="forceRender"
                                                item-text="valcdnm" item-value="valcd"
                                                dense hide-details outlined>
                                            </v-select>
                                        </v-col>
                                        <v-col class="px-2 py-1" cols="6">
                                            <span class="d-inline-block" style="width: 70%; height: auto;">
                                                <v-text-field
                                                    v-model="bnfcrCntrc.upBedFee"
                                                    type="number" height="28" :key="forceRender" :disabled="true"
                                                    hide-details outlined dense>
                                                </v-text-field>
                                            </span>
                                            <span class="fontOneRem ml-1">원</span>
                                        </v-col>
                                    </v-row>
                                </span>
                                <span class="d-inline-block" style="width: 50%; height: 100%;">
                                    <v-row class="pa-0" no-gutters>
                                        <v-col class="tableBR px-2 py-1" cols="4">
                                            <v-select
                                                class="wrkSelect" height="28"
                                                v-model="indvCntrc.upBedClcd"
                                                :disabled="true"
                                                :items="lvnRm_item" :key="forceRender"
                                                item-text="valcdnm" item-value="valcd"
                                                dense hide-details outlined>
                                            </v-select>
                                        </v-col>
                                        <v-col class="px-2 py-1" cols="8">
                                            <v-text-field
                                                v-model="indvCntrc.upBedFee"
                                                type="number" height="28" :key="forceRender" :disabled="true"
                                                hide-details outlined dense>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider class="mr-1"></v-divider>
            </v-col>
            <v-col class="px-2" cols="6">
                <v-row class="pa-0 mt-5" no-gutters>
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold">세부내역&nbsp;&#40;30일&nbsp;기준&#41;</span>
                </v-row>
                <v-divider class="mt-3 mr-1"></v-divider>
                <v-row class="greyE01 pa-0 mr-1" no-gutters>
                    <v-col class="txtAlgnMid py-1" cols="6">
                        <span class="black--text font-weight-medium">계약일</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBL py-1" cols="6">
                        <span class="black--text font-weight-medium">금액&#40;원&#41;</span>
                    </v-col>
                </v-row>
                <v-divider class="mr-1"></v-divider>
                <v-row class="pa-0 mr-1" no-gutters>
                    <v-col class="greyE01 txtAlgnMid py-1" cols="6">
                        <span class="black--text font-weight-medium">총계</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBL py-1" cols="6">
                        <span>{{ cntrcNbnftInfo.nbnftTot?.toLocaleString('ko-KR') + '원' }}</span>
                    </v-col>
                </v-row>
                <v-divider class="mr-1"></v-divider>
                <v-row class="pa-0 mr-1" no-gutters>
                    <v-col class="txtAlgnMid" cols="12">
                        <v-row class="pa-0" no-gutters>
                            <v-col class="" cols="12">
                                <v-row class="pa-0" no-gutters>
                                    <v-col class="nmlBfClrImp-greyE01 d-flex justify-center align-center" cols="3">
                                        <span class="black--text font-weight-medium">요양보험&nbsp;부담비용</span>
                                    </v-col>
                                    <v-col class="tableBL" cols="9">
                                        <v-row class="pa-0 tableBB" no-gutters>
                                            <v-col class="nmlBfClrImp-greyE01 py-1" cols="4">
                                                <span class="black--text font-weight-medium">소개</span>
                                            </v-col>
                                            <v-col class="tableBL py-1" cols="8">
                                                <span>{{ cntrcNbnftInfo.nhisTot?.toLocaleString('ko-KR') + '원' }}</span>
                                            </v-col>
                                        </v-row>
                                        <v-row class="pa-0" no-gutters>
                                            <v-col class="nmlBfClrImp-greyE01 py-1" cols="4">
                                                <span class="black--text font-weight-medium">요양급여비용&#40;{{ 100-bnfcrCntrc.copayRateDesc }}%&#41;</span>
                                            </v-col>
                                            <v-col class="tableBL py-1" cols="8">
                                                <span>{{ cntrcNbnftInfo.nhisSum?.toLocaleString('ko-KR') + '원' }}</span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider class="mr-1"></v-divider>
                <v-row class="pa-0 mr-1" no-gutters>
                    <v-col class="txtAlgnMid" cols="12">
                        <v-row class="pa-0" no-gutters>
                            <v-col class="" cols="12">
                                <v-row class="pa-0" no-gutters>
                                    <v-col class="nmlBfClrImp-greyE01 d-flex justify-center align-center" cols="3">
                                        <span class="black--text font-weight-medium">개인&nbsp;부담비용</span>
                                    </v-col>
                                    <v-col class="tableBL" cols="9">
                                        <v-row class="pa-0 tableBB" no-gutters>
                                            <v-col class="nmlBfClrImp-greyE01 py-1" cols="4">
                                                <span class="black--text font-weight-medium">소개</span>
                                            </v-col>
                                            <v-col class="tableBL py-1" cols="8">
                                                <span>{{ cntrcNbnftInfo.copayTot?.toLocaleString('ko-KR') + '원' }}</span>
                                            </v-col>
                                        </v-row>
                                        <v-row class="pa-0 tableBB" no-gutters>
                                            <v-col class="nmlBfClrImp-greyE01 py-1" cols="4">
                                                <span class="black--text font-weight-medium">요양급여비용&#40;{{ bnfcrCntrc.copayRateDesc }}%&#41;</span>
                                            </v-col>
                                            <v-col class="tableBL py-1" cols="8">
                                                <span>{{ cntrcNbnftInfo.copaySum?.toLocaleString('ko-KR') + '원' }}</span>
                                            </v-col>
                                        </v-row>
                                        <v-row class="pa-0 tableBB" no-gutters>
                                            <v-col class="nmlBfClrImp-greyE01 py-1" cols="4">
                                                <span class="black--text font-weight-medium">식사&nbsp;재료비&#47;간식비</span>
                                            </v-col>
                                            <v-col class="tableBL py-1" cols="8">
                                                <span>{{ cntrcNbnftInfo.mealCost?.toLocaleString('ko-KR') + '원' }}</span>
                                            </v-col>
                                        </v-row>
                                        <v-row class="pa-0 tableBB" no-gutters>
                                            <v-col class="nmlBfClrImp-greyE01 py-1" cols="4">
                                                <span class="black--text font-weight-medium">이&#47;미용료</span>
                                            </v-col>
                                            <v-col class="tableBL py-1" cols="8">
                                                <span>{{ cntrcNbnftInfo.btyFee?.toLocaleString('ko-KR') + '원' }}</span>
                                            </v-col>
                                        </v-row>
                                        <v-row class="pa-0" no-gutters>
                                            <v-col class="nmlBfClrImp-greyE01 py-1" cols="4">
                                                <span class="black--text font-weight-medium">상급&nbsp;침실비</span>
                                            </v-col>
                                            <v-col class="tableBL py-1" cols="8">
                                                <span>{{ cntrcNbnftInfo.upbedFee?.toLocaleString('ko-KR') + '원' }}</span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider class="mr-1"></v-divider>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import CmmDateComp from '../commons/CmmDateComp.vue';

import { selBnfcrCntrcSttsInfo, } from '../../api/bnfcr.js';

export default {
    name: 'BnfcrCntrcSttsMdl',

    props : {                
        
    },
            
    components: {
        CmmDateComp,
    },

    created: function(){
       
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {  
            this.getBnfcrCntrcSttsInfo()
        })
    },

    computed: {

    },

    watch: {
       
    },
        
    methods: {
        
        onClose: function () {
            this.$emit('isClose')
        },     
        snackControll: function (options) {
            this.snackOptions = options
        },
        
        getBnfcrCntrcSttsInfo: function () {
            this.clearFrm()

            let obj = {
                fcltyNum        : this.$store.getters.getFcltyNum,
                bnMmbrPk        : this.$parent.$parent.$parent.$parent.sltItem.bnMmbrPk,
                bnfcrCntrcInfPk : this.$parent.$parent.$parent.$parent.sltItem.bnfcrCntrcInfPk,
                clsfcCode       : '2',
                rnStrt          : '0',
                rnLmt           : '100',
            }
            
            this.dataCall = true
            selBnfcrCntrcSttsInfo(obj)
                .then((response) => ( this.getBnfcrCntrcSttsInfoAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selBnfcrCntrcSttsInfo : ' + error))
        },
        getBnfcrCntrcSttsInfoAfter(res){
            if(res.statusCode == 200){
                if(res.result.cntrcInfo != null){
                    this.bnfcrCntrc.bnfcrCntrcInfPk = res.result.cntrcInfo.bnfcrCntrcInfPk
                    this.bnfcrCntrc.cntrcDt         = this.$moment(res.result.cntrcInfo.cntrcDt, 'YYYYMMDD').format('YYYY-MM-DD')
                    this.bnfcrCntrc.cntrcPrdStrt    = this.$moment(res.result.cntrcInfo.cntrcPrdStrt, 'YYYYMMDD').format('YYYY-MM-DD')
                    this.bnfcrCntrc.cntrcPrdEnd     = this.$moment(res.result.cntrcInfo.cntrcPrdEnd, 'YYYYMMDD').format('YYYY-MM-DD')
                    this.bnfcrCntrc.pymntDt         = res.result.cntrcInfo.pymntDt
                    this.bnfcrCntrc.acknwRtng       = res.result.cntrcInfo.acknwRtng
                    this.bnfcrCntrc.copayRate       = String(res.result.cntrcInfo.copayRate)

                    this.bnfcrCntrc.npymntFeeStd    = res.result.cntrcInfo.npymntFeeStd
                    this.bnfcrCntrc.mealCost        = res.result.cntrcInfo.mealCost
                    this.bnfcrCntrc.lqdFood         = res.result.cntrcInfo.lqdFood
                    this.bnfcrCntrc.lqdFoodClcd     = res.result.cntrcInfo.lqdFoodClcd
                    this.bnfcrCntrc.snackFee        = res.result.cntrcInfo.snackFee
                    this.bnfcrCntrc.snackCntCd      = res.result.cntrcInfo.snackCntCd
                    this.bnfcrCntrc.snackOfrClcd    = res.result.cntrcInfo.snackOfrClcd
                    this.bnfcrCntrc.mealCostLmt     = res.result.cntrcInfo.mealCostLmt
                    this.bnfcrCntrc.mealCostLmtYn   = res.result.cntrcInfo.mealCostLmtYn
                    this.bnfcrCntrc.btyFee          = res.result.cntrcInfo.btyFee
                    this.bnfcrCntrc.upBedClcd       = res.result.cntrcInfo.upBedClcd
                    this.bnfcrCntrc.upBedFee        = res.result.cntrcInfo.upBedFee

                    this.$parent.$parent.$parent.$parent.vCd63.forEach(itm => {
                        if(itm.valcd == res.result.cntrcInfo.copayRate)
                            this.bnfcrCntrc.copayRateDesc = Number(itm.valdesc)
                    });

                    this.getGvrnmSprt(res.result.cntrcInfo.gvrnmSprt)
                    this.setNbnftFeeInfo(res.result.cntrcInfo, res.result.nbnftFeeInfo)
                }

                if(Array.isArray(res.result.lvnRmTypList)){
                    this.lvnRm_item.splice(0)
                    this.lvnRm_item.push({ valcdnm: '선택', valcd: '0', desc: 0 })
                    res.result.lvnRmTypList.forEach((lvn, i) => {
                        this.lvnRm_item.push({ valcdnm: lvn.lvnRmTypNm, valcd: String(i+1), desc: lvn.useFee })
                    });
                }

                if(res.result.cntrcNbnftInfo != null){
                    this.cntrcNbnftInfo.nbnftTot = res.result.cntrcNbnftInfo.nbnftTot
                    this.cntrcNbnftInfo.nhisTot = res.result.cntrcNbnftInfo.nhisTot
                    this.cntrcNbnftInfo.nhisSum = res.result.cntrcNbnftInfo.nhisSum
                    this.cntrcNbnftInfo.copayTot = res.result.cntrcNbnftInfo.copayTot
                    this.cntrcNbnftInfo.copaySum = res.result.cntrcNbnftInfo.copaySum
                    this.cntrcNbnftInfo.mealCost = res.result.cntrcNbnftInfo.mealCost
                    this.cntrcNbnftInfo.btyFee = res.result.cntrcNbnftInfo.btyFee
                    this.cntrcNbnftInfo.upbedFee = res.result.cntrcNbnftInfo.upbedFee
                }
                
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])

        },

        getGvrnmSprt: function(v){
            if( v.findIndex( v => v == '1' ) != -1 )
                this.bnfcrCntrc.gvrnmSnack = '1'
            else
                this.bnfcrCntrc.gvrnmSnack = '2'

            if( v.findIndex( v => v == '2' ) != -1 )
                this.bnfcrCntrc.gvrnmMeal = '1'
            else
                this.bnfcrCntrc.gvrnmMeal = '2'
        },

        setNbnftFeeInfo: function(cntrcInf, nbnftFeeInf){
            // 시설 비급여 수가
            if(cntrcInf.npymntFeeStd == '1'){
                this.nbnftFeeInfo.mealCost      = cntrcInf.mealCost
                this.nbnftFeeInfo.lqdFood       = cntrcInf.lqdFood
                this.nbnftFeeInfo.lqdFoodClcd   = cntrcInf.lqdFoodClcd
                this.nbnftFeeInfo.snackFee      = cntrcInf.snackFee
                this.nbnftFeeInfo.snackCntCd    = cntrcInf.snackCntCd
                this.nbnftFeeInfo.snackOfrClcd  = cntrcInf.snackOfrClcd
                this.nbnftFeeInfo.mealCostLmt   = cntrcInf.mealCostLmt
                this.nbnftFeeInfo.mealCostLmtYn = cntrcInf.mealCostLmtYn
                this.nbnftFeeInfo.btyFee        = cntrcInf.btyFee
                this.nbnftFeeInfo.upBedClcd     = cntrcInf.upBedClcd
                this.nbnftFeeInfo.upBedFee      = cntrcInf.upBedFee

                this.indvCntrc.mealCost         = 0
                this.indvCntrc.lqdFood          = 0
                this.indvCntrc.lqdFoodClcd      = '0'
                this.indvCntrc.snackFee         = 0
                this.indvCntrc.snackCntCd       = '0'
                this.indvCntrc.snackOfrClcd     = '0'
                this.indvCntrc.mealCostLmt      = 0
                this.indvCntrc.mealCostLmtYn    = '0'
                this.indvCntrc.btyFee           = 0
                this.indvCntrc.upBedClcd        = '0'
                this.indvCntrc.upBedFee         = 0
            }
            // 개별 비급여 수가
            else if(cntrcInf.npymntFeeStd == '2'){
                this.nbnftFeeInfo.mealCost      = nbnftFeeInf.mealCost
                this.nbnftFeeInfo.lqdFood       = nbnftFeeInf.lqdFoodFee
                this.nbnftFeeInfo.lqdFoodClcd   = nbnftFeeInf.lqdFoodClcd
                this.nbnftFeeInfo.snackFee      = nbnftFeeInf.snackFee
                this.nbnftFeeInfo.snackCntCd    = nbnftFeeInf.snackCntCd
                this.nbnftFeeInfo.snackOfrClcd  = nbnftFeeInf.snackOfrClcd
                this.nbnftFeeInfo.mealCostLmt   = nbnftFeeInf.mealCostLmt
                this.nbnftFeeInfo.mealCostLmtYn = nbnftFeeInf.mealCostLmtYn
                this.nbnftFeeInfo.btyFee        = nbnftFeeInf.btyFee
                this.nbnftFeeInfo.upBedClcd     = nbnftFeeInf.upBedClcd
                this.nbnftFeeInfo.upBedFee      = nbnftFeeInf.upBedFee

                this.indvCntrc.mealCost         = cntrcInf.mealCost
                this.indvCntrc.lqdFood          = cntrcInf.lqdFood
                this.indvCntrc.lqdFoodClcd      = cntrcInf.lqdFoodClcd
                this.indvCntrc.snackFee         = cntrcInf.snackFee
                this.indvCntrc.snackCntCd       = cntrcInf.snackCntCd
                this.indvCntrc.snackOfrClcd     = cntrcInf.snackOfrClcd
                this.indvCntrc.mealCostLmt      = cntrcInf.mealCostLmt
                this.indvCntrc.mealCostLmtYn    = cntrcInf.mealCostLmtYn
                this.indvCntrc.btyFee           = cntrcInf.btyFee
                this.indvCntrc.upBedClcd        = cntrcInf.upBedClcd
                this.indvCntrc.upBedFee         = cntrcInf.upBedFee
            }
        },

        clearFrm: function(){
            this.bnfcrCntrc.bnfcrCntrcInfPk = 0
            this.bnfcrCntrc.acknwRtng = ''
            this.bnfcrCntrc.cntrcDt = ''
            this.bnfcrCntrc.cntrcPrdStrt = ''
            this.bnfcrCntrc.cntrcPrdEnd = ''
            this.bnfcrCntrc.copayRate = ''
            this.bnfcrCntrc.copayRateNm = ''
            this.bnfcrCntrc.gvrnmMeal = ''
            this.bnfcrCntrc.gvrnmSnack = ''
            this.bnfcrCntrc.pymntDt = ''
            this.bnfcrCntrc.copayRateDesc = 0

            this.indvCntrc.mealCost         = 0
            this.indvCntrc.lqdFood          = 0
            this.indvCntrc.lqdFoodClcd      = '0'
            this.indvCntrc.snackFee         = 0
            this.indvCntrc.snackCntCd       = '0'
            this.indvCntrc.snackOfrClcd     = '0'
            this.indvCntrc.mealCostLmt      = 0
            this.indvCntrc.mealCostLmtYn    = '2'
            this.indvCntrc.btyFee           = 0
            this.indvCntrc.upBedClcd        = '0'
            this.indvCntrc.upBedFee         = 0

            this.cntrcNbnftInfo.nbnftTot = 0
            this.cntrcNbnftInfo.nhisTot = 0
            this.cntrcNbnftInfo.nhisSum = 0
            this.cntrcNbnftInfo.copayTot = 0
            this.cntrcNbnftInfo.copaySum = 0
            this.cntrcNbnftInfo.mealCost = 0
            this.cntrcNbnftInfo.btyFee = 0
            this.cntrcNbnftInfo.upbedFee =0 
        }
    },
    
    data: () => ({
        forceRender: 0,
        bnfcrCntrc: {
            bnfcrCntrcInfPk: 0, acknwRtng: '', cntrcDt: '', cntrcPrdStrt: '', cntrcPrdEnd: '', copayRate: '', copayRateNm: '',
            gvrnmMeal: '', gvrnmSnack: '', pymntDt: '', npymntFeeStd: '', copayRateDesc: 0
        },
        indvCntrc: { 
            mealCost: 0, lqdFoodClcd: '0', lqdFood: 0, snackCntCd: '0', snackFee: 0, mealCostLmtYn: '2', mealCostLmt: 0,
            snackOfrClcd: '0', btyFee: 0, pymntMthdCd: 1, upBedFee: 0
        },
        cntrcNbnftInfo: {
            nbnftTot: 0, nhisTot: 0, nhisSum: 0, copayTot: 0, copaySum: 0, mealCost: 0, btyFee: 0, upbedFee: 0
        },
        nbnftFeeInfo: { 
            mealCost: 0, lqdFoodClcd: '0', lqdFood: 0, snackCntCd: '0', snackFee: 0, mealCostLmtYn: '2', mealCostLmt: 0,
            snackOfrClcd: '0', btyFee: 0, pymntMthdCd: 1, upBedFee: 0
        },
        year_term: [
            { valcdnm: '선택', valcd: '0' }, { valcdnm: '1년', valcd: '1' },
            { valcdnm: '2년', valcd: '2' }, { valcdnm: '3년', valcd: '3' },
        ],
        lvnRm_item: [],
        lqdFood_term: [ { valcdnm: '선택', valcd: '0' }, { valcdnm: '1개월', valcd: '1' }, { valcdnm: '1식', valcd: '2' } ],
        snackFee_term: [ { valcdnm: '선택', valcd: '0' }, { valcdnm: '1일', valcd: '1' }, { valcdnm: '1식', valcd: '2' }, ],
        cnt_term: [ { valcdnm: '선택', valcd: '0' }, { valcdnm: '1회', valcd: '1' }, { valcdnm: '2회', valcd: '2' }, ],
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>