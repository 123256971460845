<template>
    <v-sheet class="d-inline-block rounded-lg rounded-l-0 pa-3" min-width="1260" max-width="1260" height="835">
        <v-row class="ma-0 pa-0 mb-10" no-gutters>
            <v-col class="text-h4 d-flex align-center" cols="5">
                <!-- <v-icon color="topTitleColor" style="margin-top: 2px;">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">10-5. 홈페이지&nbsp;관리</span>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>             
        <div>
            <v-tabs
                v-model="tab" class="tab-dft-Theme" active-class="black"
                center-active hide-slider>
                <v-tab class="fmlySend" v-for="item in tabItems" :key="item.tab" :class="item.class" :disabled="item.enter">{{item.tab}}</v-tab>
            </v-tabs>

            <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>

            <v-tabs-items v-model="tab">
                <v-tab-item eager transition="fade-transition">
                    <HomIntro ref="homIntro"></HomIntro>
                </v-tab-item>
                <v-tab-item eager transition="fade-transition">
                    <HomFiles ref="homFiles"></HomFiles>
                </v-tab-item>
                <v-tab-item transition="fade-transition">
                    <HomBbs v-if="tab==2" ref="homBbs"></HomBbs>
                </v-tab-item>
                <v-tab-item transition="fade-transition">
                    <HomBbsPht v-if="tab==3" ref="homBbsPht"></HomBbsPht>
                </v-tab-item>
            </v-tabs-items>
        </div>
    </v-sheet>
</template>

<script>
import HomIntro from '../../components/hom/HomIntro.vue';
import HomFiles from '../../components/hom/HomFiles.vue';
import HomBbs from '../../components/hom/HomBbs.vue';
import HomBbsPht from '../../components/hom/HomBbsPht.vue';


export default {
    name: 'HomMgmt',
    
    components: {       
        HomIntro, 
        HomFiles,
        HomBbs,
        HomBbsPht,
    },

    props : {

    },

    created: function(){
        
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            
        })
    },

    computed: {
        
    },  

    watch: {
       
    },

    methods: {
        
    },

    data: () => ({
        tab: 0,
        dialog: [],           
        tabItems: [
            { index: 0, tab: '기본설정', class: 'fontOneDotTwoRem px-15', enter: false },
            { index: 1, tab: '로고 및 배너', class: 'fontOneDotTwoRem px-15', enter: false },
            { index: 2, tab: '공지사항', class: 'fontOneDotTwoRem px-15', enter: false },
            { index: 3, tab: '시설둘러보기', class: 'fontOneDotTwoRem px-15', enter: false },
        ],        
    }),
};
</script>