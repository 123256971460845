<template>
    <v-sheet class="d-flex rounded-lg rounded-l-0" color="" min-width="1260" max-width="1560" height="835">
        <v-sheet class="d-inline-block" :width="midAreaWidth" height="835" color="midAreaColor">
            <v-sheet class="d-inline-block" :width="midAreaWidth" height="835" color="midAreaColor">
                <div class="text-h4 px-2 mt-1">
                    <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                    <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">
                        <span>4-4. 프로그램&nbsp;수급자&nbsp;그룹관리</span>
                    </span>
                </div>
                <div class="d-flex mx-3 mt-5">
                    <span class="d-inline-block" style="width: 130px;">
                        <v-text-field class="ma-0 pa-0 nameSearch" height="35px" background-color="white"
                            append-icon="mdi-magnify" single-line rounded outlined dense filled hide-details
                            label="그룹명조회" @input="setSearchText">
                        </v-text-field>
                    </span>
                </div>
                <div class="mt-2 mx-3 rounded-lg">
                    <v-data-table
                        class="dft-hvr-tbl" height="720" locale="ko"
                        :headers="headers" :items="items" :custom-filter="customFilter" :search="schDummy"
                        disable-pagination hide-default-footer dense>
                        <template v-slot:item='{ item }'>
                            <tr :class="[selectedItem1 == item.prgBnfcrgrInfPk ? 'onClr' : '']"
                                @click="tableClickEvent(item.prgBnfcrgrInfPk)">
                                <td class="fontOneRem txtAlgnMid pl-2 pr-7">
                                    {{ item.rn }}
                                </td>
                                <td class="fontOneRem txtAlgnMid pl-2 pr-6">
                                    {{ item.bnfcrgrNm }}
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </div>
            </v-sheet>

        </v-sheet>
        <router-view ref="prgmChild" @reload="reset()"></router-view>
    </v-sheet>
</template>

<script>
import { selPrgBnfcrgrList } from '../../api/prgrm.js';
export default {
    name: 'BnfcrAdv2',

    components: {

    },

    props: {

    },

    created: function () {

    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.$store.getters.getBnfcrState.bnmmbr != 0){
                this.selectedItem1 = this.$store.getters.getBnfcrState.bnmmbr
            }
            this.selPrgBnfcrgrList()
        })
    },

    computed: {

    },

    watch: {

    },

    methods: {
        selPrgBnfcrgrList: function () {
            selPrgBnfcrgrList(this.$store.getters.getFcltyNum)
                .then((response) => (this.selPrgBnfcrgrListAfter(response.data)))
                .catch((error) => console.log('connect error /bnfcr/selCnslnHisList : ' + error))
        },
        selPrgBnfcrgrListAfter: function (data) {
            if (data != '') {
                this.items = data;
            } else {
                data.splice(0)
            }
        },
        reset: function () {
            this.selPrgBnfcrgrList()
        },
        setSearchText: function (value) {
            this.schField = value
            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            // value: items, search: search key, filter: array column
            let col0 = filter.bnfcrgrNm.includes(this.schField)
            
            let col1 = true
            if(this.schChkField.bnfcrgrNm.length != 0){
                this.schChkField.bnfcrgrNm.forEach((bnfcrgrNm1, i) => {
                    if(i == 0) col1 = filter.bnfcrgrNm.includes(bnfcrgrNm1)
                    else col1 = col1 || filter.bnfcrgrNm.includes(bnfcrgrNm1)
                });
            }
            this.selectedItem = -1
            return col0 && col1 ;
        },
        tableClickEvent: function (pk){
            if(this.selectedItem1 == -1){
                this.selectedItem1 = pk
            }
            else if(this.selectedItem1 != pk){
                this.selectedItem1 = pk
            }
            else{
                //Form Clear
                this.selectedItem1 = -1
            }
        },
    },
    data: () => ({
        headers: [
            { text: '연번', value: 'rn', align: 'center', sortable: true, width: '76', class: 'black--text bottomBorder py-2 px-2' },
            { text: '수급자 그룹', value: 'name', align: 'center', sortable: true, width: '240', class: 'black--text bottomBorder px-2' },
        ],
        exBtn: [
            {
                index: 0,
                btnTitle: '입소현황',
                btnIcon: 'house',
                exWidth: 104,
                exHeigth: 150,
                expandList: [],
            },
            {
                index: 1,
                btnTitle: '등급',
                btnIcon: 'wheelchair',
                exWidth: 104,
                exHeigth: 220,
                expandList: [],
            },
        ],
        selectedItem1: -1,
        midAreaWidth: '360',
        items: [],
        schChkField: { bnfcrgrNm: [],  target: '' },
        schDummy: '',
        schField: '',
        
    }),
};
</script>