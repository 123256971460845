<template>
    <v-sheet class="pa-7" min-width="600px" max-width="900px" min-height="720px">
        <div style="position: relative;">
            <v-snackbar
                class="pa-0" min-width="200" max-width="500" rounded="pill" transition="slide-y-transition"
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" :color="snackOptions[3]" 
                absolute centered top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>

        <v-row class="align-center pa-0 mb-7" no-gutters>
            <span class="text-h4 font-weight-bold topTitleColor--text">{{ $store.getters.getBnfcrState.name }}&nbsp;</span>
            <span class="text-h4 font-weight-bold">퇴소&nbsp;이력</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="onClose" x-large>mdi-close</v-icon>
        </v-row>

        <v-tabs
            v-model="tab"
            class="tab-dft-Theme" active-class="black"
            center-active hide-slider>
            <v-tab
                v-if="tabItems[0].visible"
                class="fontOneDotTwoRem px-15" :disabled="tabItems[0].enter">
                {{ tabItems[0].tab }}
            </v-tab>
            <v-tab
                v-if="tabItems[1].visible"
                class="fontOneDotTwoRem px-15" :disabled="tabItems[1].enter">
                {{ tabItems[1].tab }}
            </v-tab>
        </v-tabs>

        <v-divider class="bodyColor mb-2" style="padding: 1px 0px;"></v-divider>

        <v-tabs-items v-model="tab">
            <v-tab-item v-if="tabItems[0].visible" :transition="false" eager>
                <Rtrmn style="max-height: 721px;"></Rtrmn>
            </v-tab-item>
            <v-tab-item v-if="tabItems[1].visible" :transition="false" eager>
                <Rcrd style="max-height: 721px;"></Rcrd>
            </v-tab-item>
        </v-tabs-items>
    </v-sheet>
</template>

<script>
import Rtrmn from '../../components/bnfcr/BnfcrExtLinkRecRtrmn.vue';
import Rcrd from '../../components/bnfcr/BnfcrExtLinkRecRcrd.vue';

export default {
    
    name: 'BnfcrExtLinkRec',
            
    components: {
        Rtrmn,
        Rcrd,
    },

    created: function(){

    },

    props: {
        typ: { type: String, default: 'nrm' },
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.aplctTyp()
            this.lvDt = this.$store.getters.getBnfcrLvDt
            this.entDt = this.$store.getters.getBnfcrEntDt
        }) 
    },

    computed: {
        getBnMmbrPk: function(){
            return this.$store.getters.getBnfcrState.bnmmbr
        },
        getBnfcrLvDt: function () {
            return this.$store.getters.getBnfcrLvDt
        },
        getBnfcrEntDt: function () {
            return this.$store.getters.getBnfcrEntDt
        },
    },

    watch:{
        getBnMmbrPk: function(){
            this.tab = 0
        },
        getBnfcrLvDt: function(v){
            this.lvDt = v
        },
        getBnfcrEntDt: function(v){
            this.entDt = v
        },
        'typ': function(){
            this.aplctTyp()
        },
    },
        
    methods: {
        aplctTyp: function () {
            if(this.typ == 'rtrmn'){
                this.tabItems[0].visible = true
                this.tabItems[1].visible = false
            }
            else if(this.typ == 'rcrd'){
                this.tabItems[0].visible = false
                this.tabItems[1].visible = true
            }
            else{
                this.tabItems.forEach(itm => {
                    itm.visible = true
                });
            }
        },

        updtTrg: function () {
            this.$emit('midLoad', 'trigger')
        },

        onMsg: function (txt) {
            this.$emit('onMsg', txt)
        },

        onSndMsg: function (lrpk, dt) {
            this.$emit('onSndMsg', lrpk, dt)
        },

        onRcrdPrt: function (lrpk) {
            this.$emit('onRcrdPrt', lrpk)
        },

        snackControll: function (options) {
            this.snackOptions = options
        },

        onClose: function () {
            this.$emit('madalState', 3)
        },
    },
    
    data: () => ({
        tab: 0,
        entDt: '',
        lvDt: '',
        tabItems: [
            { index: 0, tab: '퇴소관리', enter: false, visible: true },
            { index: 1, tab: '연계기록지', enter: false, visible: true },
        ],
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>