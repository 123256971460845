<template>
    <v-sheet>
        <v-form ref="tabform" lazy-validation>
        <v-row no-gutters>
            <v-col cols="6">
                <div>
                    <div class="d-flex align-center">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">경관영양 관리기록</span>
                        <v-spacer></v-spacer>
                        <span v-if="preBtn[0]">
                            <v-btn 
                                class="ml-1" color="#3C9099" style="padding: 13px 12px 12px 10px !important"
                                min-width="30" height="25"
                                @click="getPreInfo"
                                small outlined>
                                <v-icon size="14">mdi-text-box-search-outline</v-icon>
                                <span class="fontOneRem ml-1" style="padding-bottom: 2px;">이전자료</span>                
                            </v-btn>
                        </span>
                    </div>
                    <v-row class="pa-0 mt-2" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="4">
                            <span class="black--text font-weight-medium">경관영양</span>
                        </v-col>
                        <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="8">
                            <span style="width:200px;">
                                <v-radio-group class="radio-dft-Theme mt-0" v-model="recItems1.itm1Val" :rules="[rules.required]" hide-details row>
                                    <v-radio v-for="(code, i) in codeItem30" :key="i" :ripple="false" :label="code.valcdnm" :value="code.valcd"></v-radio>
                                </v-radio-group>
                            </span>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="4">
                            <div>
                                <div class="d-flex justify-center black--text font-weight-medium">도구 소독 등</div>
                                <div class="d-flex justify-center black--text font-weight-medium">관리기록</div>
                            </div>
                        </v-col>
                        <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="8">
                            <v-textarea v-model="items.cntnt" class="rmrksArea" style="font-size:0.9rem !important;"
                                rows="15" outlined dense hide-details no-resize>
                            </v-textarea>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="4">
                            <span class="black--text font-weight-medium">제공자</span>
                        </v-col>
                        <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="8">   
                            <span class="d-inline-block" style="width: 100px;">
                                <v-text-field v-model="items.wrtr" color="blue" height="28" ref="info" readonly
                                    :rules="[rules.required]" hide-details outlined dense :key="forceRender"></v-text-field>
                            </span>
                            <v-btn 
                                class="ml-1 brown lighten-3" 
                                small 
                                min-width="30"
                                height="26"  
                                style="padding:0px 1px 0px 2px !important"
                                @click="onModal(0)"
                                >                                    
                                <v-tooltip top >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon size="20" class="white--text" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                    </template>
                                    <span>직원선택</span>
                                </v-tooltip>

                            </v-btn>                     
                        </v-col>
                    </v-row>
                </div>
            </v-col>
            <v-col cols="6">
                <div class="pl-2 ma-0 align-self-start">
                    <div class="d-flex align-center">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">경관식 제공 기록</span>
                        <v-spacer></v-spacer>
                        <span v-if="preBtn[1]">
                            <v-btn 
                                class="ml-1" color="#3C9099" style="padding: 13px 12px 12px 10px !important"
                                min-width="30" height="25"
                                @click="getPreList"
                                small outlined>
                                <v-icon size="14">mdi-text-box-search-outline</v-icon>
                                <span class="fontOneRem ml-1" style="padding-bottom: 2px;">이전자료</span>                
                            </v-btn>
                        </span>
                    </div>
                    <div :class="['d-flex mt-2', nsgCareRcrdList.length > 7 ? 'mr-2__5' : '']" no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;font-size:1rem">
                        <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:10%;">                                
                            <span class="black--text font-weight-medium">횟수</span>
                        </div>
                        <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:34%;border-left: 1px solid #dbdbdb !important;">                                
                            <span class="black--text font-weight-medium">식사종류</span>
                        </div>
                        <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:23%;border-left: 1px solid #dbdbdb !important;">                                
                            <span class="black--text font-weight-medium">식사량&#40;ml&#41;</span>
                        </div>
                        <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:23%;border-left: 1px solid #dbdbdb !important;">                                
                            <span class="black--text font-weight-medium">식사시간</span>
                        </div>
                        <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">                                
                            <v-btn icon class="mr-1" color="blue200" height="26" @click="addRow()">
                                <v-icon>mdi-plus-circle-outline</v-icon>
                            </v-btn>
                        </div>
                    </div>
                    <div style="height:316px;overflow-y:auto;">
                        <div v-if="nsgCareRcrdList.length > 0">
                            <div v-for="(item,m) in nsgCareRcrdList" :key="m" class="d-flex pa-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:10%;">                                
                                    {{m+1}}
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:34%;border-left: 1px solid #dbdbdb !important;">                                
                                    <span class="d-inline-block" style="width: 140px;">
                                        <v-select 
                                            v-model="item.itm2Val"                               
                                            :items="codeItem91"
                                            item-text="valcdnm"
                                            item-value="valcd"
                                            :rules="[rules.required]"
                                            dense hide-details outlined
                                        /> 
                                    </span>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:23%;border-left: 1px solid #dbdbdb !important;">                                
                                    <span style="width:90px">
                                        <v-text-field color="blue" height="28" v-model="item.itm3Val" @focus="item.itm3Val=''" type="number" hide-details outlined dense></v-text-field>
                                    </span>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:23%;border-left: 1px solid #dbdbdb !important;">                                
                                    <span class="white d-inline-block pa-1" style="width: 80px;">                                     
                                        <CmmTimeField v-model="item.ofrTmStrt" :required="true"></CmmTimeField>    
                                    </span>
                                </div>
                                <div  class="d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">                                                                   
                                    <v-btn v-if="item.bnftOfrRecPk > 0" icon color='grey006' @click="delRow(m)"> 
                                        <v-icon>mdi-trash-can-outline</v-icon>                                           
                                    </v-btn> 
                                    <v-btn v-else icon color='grey006' @click="delRow(m)"> 
                                        <v-icon>mdi-minus-circle-outline</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                        <div v-else class="d-flex pa-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%">                                
                                데이터가 없습니다.
                            </div> 
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>            
        </v-form>
        <div class="d-flex align-center mt-3">
            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold">경관식 제공 기록 내역</span>
        </div>
        <div class="mt-2">
            <v-data-table
                class="dft-hvr-tbl" height="225" locale="ko"
                :headers="histHeaders" :items="nsgCareRcrdHist" 
                hide-default-header hide-default-footer fixed-header dense disable-pagination>
                <template v-slot:header="{ props }" >
                    <thead>
                        <tr> 
                            <th class="black--text clmsFixedHeader tableBR" style="width:15%">{{props.headers[0].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:35%">{{props.headers[1].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:35%">{{props.headers[2].text}}</th>
                            <th class="black--text clmsFixedHeader" style="width:15%">{{props.headers[3].text}}</th>
                       
                        </tr>
                    </thead>
                </template>

                <template v-slot:item='{ item }'>
                    <tr :class="[$parent.$parent.$parent.$parent.inqYMD == item.recDt ? 'onClr' : '']"
                        @click="tableClickEvent(item.recDt)">
                        <td class="clmsBodyCommon tableBR">{{item.recDt?.beforeDateFormatDot()}}</td> 
                        <td class="clmsBodyCommon tableBR">
                            <div style="width:250px;" class="overflow-text-hidden">{{item.dietRec}}</div>
                        </td>                             
                        <td class="clmsBodyCommon tableBR">
                            <div style="width:250px;" class="overflow-text-hidden">{{item.cntnt}}</div>
                        </td>  
                        <td class="clmsBodyCommon">{{item.wrtr}}</td>            
                    </tr>
                </template>
            </v-data-table>
        </div>
        <div>            
            <v-pagination
                v-model="page"
                :length="pageCount"
                @input="getPage"
                total-visible="5">
            </v-pagination>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
                    <EmpSelector @isClose="isModal(0)" @outEmp="getEmpInfo" :prtWrtDt="$parent.$parent.$parent.$parent.inqYMD"></EmpSelector>
            </v-dialog>  
        </div>        
    </v-sheet>
</template>
<script>
import EmpSelector from '../commons/EmpSelector.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';
import { getMultiStcd } from '../../api/index.js';
import { selBnftOfrRecList, selPreBnftOfrRecList, insMultiBnftRcrd, selNsgstTubeRecList, delAllBnftOfrRec, delBnftOfrRec} from '../../api/bnftrcrd.js';

export default {
    name: 'NsgCareRcrdTab2',
    
    components: {
        EmpSelector,
        CmmTimeField,

    },

    props : {
        
    },

    created: function(){       
        getMultiStcd(['30','91'], this.$store.getters.getFcltyNum)
                .then((response) => ( this.codeMapping(response.data) ))
                .catch((error) => console.log(error))
    },

    mounted: function(){
        
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },  

    watch: {
        getSubEvt: function (bol) {
            if(bol && this.rmNsgTab2AllCnfm){
                this.rmAllBnftOfrRec()
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
                this.rmNsgTab2AllCnfm = false
            }

            if(bol && this.rmNsgTab2RecCnfm){
                this.rmBnftOfrRec()
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
                this.rmNsgTab2RecCnfm = false
            }
        },
    },

    methods: {
        codeMapping: function (res){            
            this.codeItem30 = res.vCd30
            this.codeItem91 = res.vCd91
        },
        onLoad:function(){
            this.frmReset()
            this.getBnftOfrRecList()
            this.getNsgstTubeRecList(0,this.itemsRows)
            this.$refs.tabform.resetValidation()
        },
        frmReset:function(){
            this.$refs.tabform.reset()              
            this.nsgCareRcrdList.splice(0)
            this.items.bnftOfrRecHisPk  = 0
            this.items.bnftOfrRecPk     = 0
            this.items.recDt            = ''
            this.items.itm1Val          = ''
            this.items.cntnt            = ''
            this.items.spclNote         = ''
            this.items.wrtr             = ''
            this.items.wrtrMmbrPk       = 0

            this.recItems1.bnftOfrRecHisPk  = 0
            this.recItems1.bnftOfrRecPk     = 0
            this.recItems1.recDt            = ''
            this.recItems1.wrtr             = ''
            this.recItems1.wrtrMmbrPk       = 0
            this.recItems1.ofrTmStrt        =  ""
            this.recItems1.ofrTmHhStrt      = ""
            this.recItems1.ofrTmMmStrt      = ""
            this.recItems1.itm1Val          =  ""
        },        
        getPreInfo:function(){
            this.pretype = "1"
            this.getPreBnftOfrRecList1()
        },
        getPreList:function(){
            this.pretype = "2"
            this.getPreBnftOfrRecList1()
        },
        getBnftOfrRecList(){

            let fcltyNum = this.$store.getters.getFcltyNum
            let obj = {
                fcltyNum: fcltyNum, 
                clsfc1Cd: '301',
                clsfc2Cd: '2',
                clsfc3Cd: '2',
                bnMmbrPk: this.$parent.$parent.$parent.$parent.bnMmbrPk,
                inqYMD:this.$parent.$parent.$parent.$parent.inqYMD,
            }

            selBnftOfrRecList(obj)
                .then((response) => ( this.getBnftOfrRecListAfter(response.data)))
                .catch((error) => console.log(error))
        },      
        getBnftOfrRecListAfter(res){               
            let obj = {}
            if(res.statusCode == 200){
                if(res.result.length > 0){   
                    this.$parent.$parent.$parent.$parent.outlinedBtn[0].disabled = false
                    
                    res.result.forEach(x=>{
                        
                        if(x.wrtr != null){
                            this.items.wrtr = x.wrtr
                            this.items.wrtrMmbrPk = x.wrtrMmbrPk
                        }

                        this.items.cntnt = x.cntnt                    
                        this.items.bnftOfrRecHisPk = x.bnftOfrRecHisPk
                        this.items.itm1Val = x.itm1Val
                        this.items.mdfDt = x.mdfDt  
                        
                        if(x.itm1Cd == '1' && x.itm2Cd =='2' && x.itm3Cd == '3'){
                            let obj = {
                                bnftOfrRecHisPk : x.bnftOfrRecHisPk,
                                bnftOfrRecPk: x.bnftOfrRec301Pk,
                                recDt:'',
                                clsfc1Cd:'301',
                                clsfc2Cd:'2',
                                clsfc3Cd:'2',
                                ofrTmStrt: '',
                                itm1Cd:"1",
                                itm1Val: x.itm1Val,
                                itm2Cd: "2",
                                itm2Val: x.itm2Val,
                                itm3Cd: "3",
                                itm3Val: x.itm3Val
                            }

                            if(x.ofrTmHhStrt?.isEmptyVal() && x.ofrTmMmStrt?.isEmptyVal()){
                                obj.ofrTmStrt =  x.ofrTmHhStrt+":"+x.ofrTmMmStrt
                            }

                            this.nsgCareRcrdList.push(obj)
                        }else{
                            this.recItems1.bnftOfrRecHisPk =  x.bnftOfrRecHisPk
                            this.recItems1.bnftOfrRecPk = x.bnftOfrRec301Pk
                            this.recItems1.itm1Cd = "1"
                            this.recItems1.itm1Val =  x.itm1Val
                            this.recItems1.mdfDt =  x.mdfDt 
                        }
                        
                    })                
                }

                if(this.items.bnftOfrRecHisPk > 0){
                    this.preBtn.splice(0,1,false)
                } else {
                    this.preBtn.splice(0,1,true)
                }

                if(this.nsgCareRcrdList.length > 0) {
                    this.preBtn.splice(1,1,false)
                }else{
                    this.preBtn.splice(1,1,true)
                }

            }else{
                    obj.actv = true                 // 경고 노출여부
                    obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                    obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                    obj.title = res.message         // 경고 타이틀
                    this.$store.commit('setWrnnInf', obj)
            }               
            
        }, 

        getPreBnftOfrRecList1(){
            let obj = {
                fcltyNum: this.$store.getters.getFcltyNum, 
                bnMmbrPk: this.$parent.$parent.$parent.$parent.bnMmbrPk,
                clsfc1Cd: '301',
                clsfc2Cd: '2',
                clsfc3Cd: '2',
                inqYMD:this.$parent.$parent.$parent.$parent.inqYMD,
            }
            selPreBnftOfrRecList(obj)
            .then((response) => ( this.getPreBnftOfrRecList1After(response.data)))
            .catch((error) => console.log('upload error /bnftrcrd/selPreBnftOfrRecList : ' + error))
        },
        getPreBnftOfrRecList1After:function(res){
            if(this.pretype == '2'){
                this.nsgCareRcrdList.splice(0)
            }

            if(res.length > 0){     
                res.forEach(x=>{  

                    if(this.pretype == '1'){                              
                        this.items.wrtr = x.hisWrtr
                        this.items.wrtrMmbrPk = x.hisWrtrMmbrPk
                        this.items.cntnt = x.cntnt             
                        this.items.itm1Val = x.itm1Val         
                    }

                    if(x.itm1Cd == '1' && x.itm2Cd =='2' && x.itm3Cd == '3'){
                        if(this.pretype == '2'){ 
                                
                            let obj = {
                                bnftOfrRecHisPk : 0,
                                bnftOfrRecPk: 0,
                                recDt:'',
                                clsfc1Cd:'301',
                                clsfc2Cd:'2',
                                clsfc3Cd:'2',
                                ofrTmStrt: '',
                                itm1Cd:"1",
                                itm1Val: x.itm1Val,
                                itm2Cd: "2",
                                itm2Val: x.itm2Val,
                                itm3Cd: "3",
                                itm3Val: x.itm3Val
                            }

                            if(x.ofrTmHhStrt?.isEmptyVal() && x.ofrTmMmStrt?.isEmptyVal()){
                                obj.ofrTmStrt =  x.ofrTmHhStrt+":"+x.ofrTmMmStrt
                            }

                            this.nsgCareRcrdList.push(obj)
                        }
                    }else{
                        if(this.pretype == '1'){                            
                            this.recItems1.itm1Val =  x.itm1Val
                        }
                    }
                    
                })
            }
        },
          
        getNsgstTubeRecList : function(strt, lmt){     
            let obj = {
                fcltyNum: this.$store.getters.getFcltyNum,
                bnMmbrPk: this.$parent.$parent.$parent.$parent.bnMmbrPk,
                rnStrt:strt,
                rnLmt:lmt, 
            }         
            selNsgstTubeRecList(obj)
                .then((response) => ( this.getNsgstTubeRecListAfter(response.data)))
                .catch((error) => console.log(error))                
        },
        getNsgstTubeRecListAfter:function(res){
            this.pageCount = Math.ceil(Number(res.rnTot)/this.itemsRows)
            this.nsgCareRcrdHist = res.list   
            this.nsgCareRcrdHist.forEach(e => {
                let dietRec = ""
                let n=0
                e.dietRec.forEach(e => {                                        
                    if(n == 0)
                        dietRec = e
                    else
                        dietRec += ", "+e
                    n++
                });
                e.dietRec = dietRec
            });         
        },
        getPage:function(){
            let strt = ((this.page-1)*this.itemsRows)
            this.getNsgstTubeRecList(strt,this.itemsRows)
        },
        onSave:function(dupYn = '2'){
            if(this.$parent.$parent.$parent.$parent.bnMmbrPk > 0){

                if(this.$refs.tabform.validate()) {

                    let obj = {       
                        fcltyNum    : this.$store.getters.getFcltyNum,
                        bnMmbrPk    : this.$parent.$parent.$parent.$parent.bnMmbrPk,
                        userNm      : this.$store.getters.getUserNm,
                        ignrYn      : dupYn,                
                        hisList     : [],
                    }
    
                    let recItems = {                                             
                        recDt       : this.$parent.$parent.$parent.$parent.inqYMD,
                        mdfDt       : this.items.mdfDt,
                        wrtr        : this.items.wrtr,
                        wrtrMmbrPk  : this.items.wrtrMmbrPk,
                        cntnt       : this.items.cntnt,
                        spclNote    : this.items.spclNote,
                        clsfc1Cd    : '301',
                        clsfc2Cd    : '2',
                        clsfc3Cd    : '2',
                        recList     : []
                    }
    
                    recItems.recList.push({
                        itm1Cd          : '1',
                        itm1Val         : this.recItems1.itm1Val,
                        wrtr            : this.items.wrtr,
                        wrtrMmbrPk      : this.items.wrtrMmbrPk,
                        bnftOfrRec301Pk : this.recItems1.bnftOfrRecPk,
                    })
    
                    this.nsgCareRcrdList.forEach(x=>{                    
                        let itm = {
                            wrtr        :this.items.wrtr,
                            wrtrMmbrPk  :this.items.wrtrMmbrPk,
                            ofrTmStrt   : "",
                            ofrTmHhStrt : "",
                            ofrTmMmStrt : "",
                            itm1Cd      : "1",
                            itm1Val     : this.recItems1.itm1Val,
                            itm2Cd      : "2",
                            itm2Val     : x.itm2Val,
                            itm3Cd      : "3",
                            itm3Val     : x.itm3Val,
                            bnftOfrRec301Pk : x.bnftOfrRecPk,
                        }
                        if(x.ofrTmStrt?.isEmptyVal()){
                            let ofrTmStrt = x.ofrTmStrt.split(":")
                            itm.ofrTmHhStrt = ofrTmStrt[0]
                            itm.ofrTmMmStrt = ofrTmStrt[1]
                        }
                        
                        recItems.recList.push(itm)
                    })
                    obj.hisList.push(recItems)                                    

                    this.$parent.$parent.$parent.$parent.progress = true
                    insMultiBnftRcrd(obj)
                        .then((response) => ( this.onSaveAfter(response.data) ))
                        .catch((error) => console.log('upload error /bnftrcrd/insMultiBnftRcrd : ' + error))
                    
                }else{
                    let obj = {} 
                    obj.actv = true                 // 경고 노출여부
                    obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                    obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                    obj.title = '필수항목을 입력해주세요.'         // 경고 타이틀

                    this.$store.commit('setWrnnInf', obj)
                }
            }else{
                let obj = {} 
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = '수급자를 선택해주세요.'         // 경고 타이틀

                this.$store.commit('setWrnnInf', obj)
            }
        },
        onSaveAfter:function(res){
            this.$emit('nsgCareRcrdTrigger', res);
            if(res.statusCode == 200) this.onLoad()
        }, 
        onforce(key){
            this.mtimes1[key] = false
            ++this.forceRender
        },
        onModal: function (value) {
            this.dialog.splice(value, 1, true)
        },
        isModal: function (value) {
            this.dialog.splice(value, 1, false)
        },
        getEmpInfo: function (obj) {
            this.items.wrtr = obj[0].name
            this.items.wrtrMmbrPk = obj[0].mmbrPk            
        },
        addRow(){
            this.nsgCareRcrdList.push({bnftOfrRecPk:0,clsfc1Cd:'301',clsfc2Cd:'2',clsfc3Cd:'2',ofrTmStrt: "",itm1Cd:"1",itm1Val: "",itm2Cd: "2",itm2Val: "",itm3Cd: "3",itm3Val: ""})
        },
        delAll(){
            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            obj.title = '경관영향'
            obj.cntnt = [this.$moment(this.$parent.$parent.$parent.$parent.inqYMD,'YYYYMMDD').format('YYYY.MM.DD')+' 경관영향을(를) 삭제하시겠습니까?']
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 삭제하겠습니다.'

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
            });   

            this.rmNsgTab2AllCnfm = true
        },
        delRow(key){
            if(this.nsgCareRcrdList[key].bnftOfrRecPk > 0){                
                this.idx = key                

                let obj = {}
                obj.icon = 'mdi-alert'
                obj.iconClr = 'red'
                obj.title = '경관식 제공 기록'
                obj.cntnt = [this.nsgCareRcrdList[key].ofrTmStrt+' 의 경관식 제공 기록을(를) 삭제하시겠습니까?']
                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 삭제하겠습니다.'

                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });   

                this.rmNsgTab2RecCnfm = true
            }else{
                this.nsgCareRcrdList.splice(key,1)
            }
        },
        rmBnftOfrRec:function(){

            let obj = {
                fcltyNum: this.$store.getters.getFcltyNum,
                bnMmbrPk : this.$parent.$parent.$parent.$parent.bnMmbrPk,
                clsfc1Cd        : '301',              
                bnftOfrRec301Pk : this.nsgCareRcrdList[this.idx].bnftOfrRecPk,
            }

            this.$parent.$parent.$parent.$parent.progress = true

            delBnftOfrRec(obj)                
                .then((response) => ( this.onDeleteAfter(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/delBnftOfrRec : ' + error))
        },
        rmAllBnftOfrRec:function(){
            let fcltyNum = this.$store.getters.getFcltyNum                    
            let obj = {
                    fcltyNum:fcltyNum,
                    userNm:this.$store.getters.getUserNm,
                    recDt       : this.$parent.$parent.$parent.$parent.inqYMD,
                    bnMmbrPk    : this.$parent.$parent.$parent.$parent.bnMmbrPk,
                    clsfc1Cd    : '301',
                    clsfc2Cd    : '2',
                    clsfc3CdS   : ['2'],
            }      
            
            this.$parent.$parent.$parent.$parent.progress = true

            delAllBnftOfrRec(obj)            
                .then((response) => ( this.onDeleteAfter(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/delAllBnftOfrRec : ' + error))
        },           
        onDeleteAfter(res){            
            this.idx = -1

            this.$parent.$parent.$parent.$parent.progress = false

            this.$emit('nsgCareRcrdTrigger', res)

            if(res.statusCode == 200) this.onLoad()
        },
        tableClickEvent(value){
            if(value != ''){
                this.$emit("setDate",value)
            }
        },
    },
    data: () => ({
        rmNsgTab2RecCnfm:false,
        rmNsgTab2AllCnfm:false,
        delModelTitle:'',
        type:'',
        forceRender:0,
        pageCount:0,
        itemsRows: 6,
        page:1,
        items:{bnftOfrRecHisPk:0,bnftOfrRecPk:0,mdfDt:'',recDt : '',itm1Val:'',cntnt:'',spclNote:'',wrtr:'',wrtrMmbrPk:0,},        
        recItems1:{bnftOfrRecHisPk:0,bnftOfrRecPk:0,mdfDt:'',recDt:'',clsfc1Cd:'301',clsfc2Cd:'2',clsfc3Cd:'2',wrtr:'',wrtrMmbrPk:0,ofrTmStrt: "",ofrTmHhStrt:"",ofrTmMmStrt:"",itm1Cd:"1",itm1Val: ""},        
        nsgCareRcrdList:[
            {bnftOfrRecHisPk:0,bnftOfrRecPk:0,recDt:'',clsfc1Cd:'301',clsfc2Cd:'2',clsfc3Cd:'2',wrtr:'',wrtrMmbrPk:0,ofrTmStrt: "",ofrTmHhStrt:"",ofrTmMmStrt:"",itm1Cd:"1",itm1Val: "",itm2Cd: "2",itm2Val: "",itm3Cd: "3",itm3Val: ""}
        ],
        nsgCareRcrdHist:[],
        histHeaders: [
            { text: '기록일자',width: '', value: '', align: 'center', },
            { text: '식사종류(식사량 ml)',width: '', value: '', align: 'center', },
            { text: '관리내용',width: '', value: '', align: 'center', },
            { text: '제공자',width: '', value: '', align: 'center', },
        ],
        codeItem30:[],
        codeItem91:[],        
        rtimes1:[false,false,false,false,false,false,false,],
        mtimes1:[false,false,false,false,false,false,false,],
        dialog:[false,],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
        pretype:'',
        preBtn:[true,true]
    }),
};
</script>
<style>
.overflow-text-hidden { overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
</style>