<template>
    <v-sheet class="ma-0" rounded="md">
        <div class="d-flex mx-2">
            <v-spacer></v-spacer>
            <span v-if="progress">
                <v-progress-circular            
                :size="30"
                color="primary"
                indeterminate
                ></v-progress-circular>
            </span>
            <span v-if="!progress">
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn :class="list.class" height="30" :color="list.color"
                        :disabled="list.disabled" @click="onClickBtn(i)" samll rounded>
                        <v-icon small>{{list.icon}}</v-icon>
                        <span style="font-size: 1rem;">{{list.text}}</span>
                    </v-btn>
                </span>
            </span>
        </div>
        <div class="pa-1">
            <v-divider class="mt-3"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                    <span class="black--text font-weight-medium">로고</span>
                    <span class="d-inline-block ml-1" style="padding-top: 2px;">
                        <v-file-input
                            class="athFileInput" v-model="logo" truncate-length="5" @change="upFile('1')"                            
                            :disabled="logoList.length > 0" prepend-icon="mdi-upload" counter hide-input outlined dense hide-details>
                        </v-file-input>
                    </span>
                </v-col>
                <v-col class="pa-1" cols="10">
                    <div class="overflow-y-auto" style="height:150px;">
                        <span class="ma-1 d-inline-block" v-for="(item,i) in logoList" :key="i">
                            <imageCard ref="imageCard" :imgSrc="item.src" @isEvent="isDelete(i,'1')" @onDown="onDownload(i,'1')" :imgWidth="'200'"></imageCard>
                        </span>
                    </div>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>               
                <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                    <span class="black--text font-weight-medium">시설 대표사진</span>
                    <span class="d-inline-block ml-1" style="padding-top: 2px;">
                        <v-file-input
                            class="athFileInput" v-model="intro" truncate-length="5" @change="upFile('3')"
                            :accept="imgAccept"   :disabled="introList.length > 0"                         
                            prepend-icon="mdi-upload" counter hide-input outlined dense hide-details>
                        </v-file-input>
                    </span>
                </v-col>
                <v-col class="pa-1" cols="10">
                    <div class="overflow-y-auto" style="height:150px;">
                        <span class="ma-1 d-inline-block" v-for="(item,j) in introList" :key="j">
                            <imageCard ref="imageCard" :imgSrc="item.src" @isEvent="isDelete(j,'3')" @onDown="onDownload(j,'3')" :imgWidth="'200'"></imageCard>
                        </span>
                    </div>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                    <span class="black--text font-weight-medium">메인 배너</span>
                    <span class="d-inline-block ml-1" style="padding-top: 2px;">
                        <v-file-input
                            class="athFileInput" v-model="banner" truncate-length="5" @change="upFile('2')"                            
                            prepend-icon="mdi-upload" multiple counter hide-input outlined dense hide-details>
                        </v-file-input>
                    </span>
                </v-col>
                <v-col class="pa-1" cols="10">
                    <div class="overflow-y-auto" style="height:300px;">
                        <span class="ma-1 d-inline-block" v-for="(item,n) in bannerList" :key="n">
                            <imageCard ref="imageCard" :imgSrc="item.src" @isEvent="isDelete(n,'2')" @onDown="onDownload(n,'2')" :imgWidth="'500'"></imageCard>
                        </span>                
                    </div>    
                </v-col>               
            </v-row>
            <v-divider class=""></v-divider>
        </div>
    </v-sheet>    
</template>
<script>
import { remoteURL } from '@/api/baseAxios';
import { selFcltyHomFileList, tmpHomImgUpload, homImgDownload, insFcltyHomFile, delFcltyHomFile } from '../../api/hom.js';
import imageCard from '../commons/imageCard.vue';

export default {
    name: 'HomFiles',

    props : {
       
    },
        
    components: {
        imageCard
    },

    created: function(){
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getFcltyHomFileList()
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch:{
        getSubEvt: function (bol) {
            if(bol && this.rmHomFile){
                this.onDelete()
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.rmHomFile = false
            }
        },

    },
    
    methods: {
        upFile:function(key){
            let formData = new FormData()
            let len      = 0
            this.key = key
            
            if(this.key == '1'){
                if(this.logo != null){
                    this.logoList.splice(0)
                    formData.append("files", this.logo)
                    len++
                }
            } else if(this.key == '2'){
                if(this.banner != null){
                    this.banner.forEach(element => {
                        formData.append("files", element)
                        len++
                    })
                    
                }
            } else if(this.key == '3'){
                if(this.intro != null){
                    this.introList.splice(0)
                    formData.append("files", this.intro)
                    len++
                }
            }

            if(len > 0){
                this.setTmpImgUpload(formData)
            }
            
        },

        onClickBtn:function(key){
            switch(key) {
                case 0:     //저장
                    this.preInsFcltyHomFile()
                break;
            default:
                break;
            }
        },

        preInsFcltyHomFile:function(){
            this.progress = true

            let obj = {
                fcltyNum  : this.$store.getters.getFcltyNum,
                userNm    : this.$store.getters.getUserNm,
                files     : []
            }

            if(this.logoList.length > 0){
                this.logoList.forEach(el => {
                    if(el.fcltyHomFilePk == 0) obj.files.push(el)
                });
            }

            if(this.introList.length > 0){
                this.introList.forEach(el => {
                    if(el.fcltyHomFilePk == 0) obj.files.push(el)
                });
            }

            if(this.bannerList.length > 0){
                this.bannerList.forEach(el => {
                    if(el.fcltyHomFilePk == 0) obj.files.push(el)
                });
            }

            if(obj.files.length > 0){                
                this.setInsFcltyHomFile(obj)
            }else{
                let obj = {}
                obj.actv = true  
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = '변경사항이  없습니다.'         // 경고 타이틀
                this.$store.commit('setWrnnInf', obj)

                this.progress = false
            }
        },

        isDelete:function(idx,key){

            this.sltFile.fcltyHomFilePk = 0
            this.sltFile.filePth        = ''
            this.sltFile.fileNm         = ''
            this.sltFile.fileClcd       = key

            switch (key) {
                case '1':
                    this.sltFile.fcltyHomFilePk =  this.logoList[idx].fcltyHomFilePk
                    this.sltFile.filePth =  this.logoList[idx].filePth
                    this.sltFile.fileNm =  this.logoList[idx].fileNm
                    break;
                case '2':
                    this.sltFile.fcltyHomFilePk =  this.bannerList[idx].fcltyHomFilePk
                    this.sltFile.filePth =  this.bannerList[idx].filePth
                    this.sltFile.fileNm =  this.bannerList[idx].fileNm
                    break;
                case '3':
                    this.sltFile.fcltyHomFilePk =  this.introList[idx].fcltyHomFilePk
                    this.sltFile.filePth =  this.introList[idx].filePth
                    this.sltFile.fileNm =  this.introList[idx].fileNm
                    break;
                default:
                    break;
            }

            if(this.sltFile.fcltyHomFilePk > 0){
                
                let obj = {}
                obj.icon = 'mdi-alert'
                obj.iconClr = 'red'
                obj.title = '홈페이지 관리'
                if(this.key == '1'){
                    obj.cntnt = ['홈페이지 로고 파일을(를) 삭제하시겠습니까?']
                }
                else if(this.key == '2'){
                    obj.cntnt = ['홈페이지 시설 대표사진 파일을(를) 삭제하시겠습니까?']
                }
                else if(this.key == '3'){
                    obj.cntnt = ['홈페이지 메인 배너 파일을(를) 삭제하시겠습니까?']
                }

                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 삭제하겠습니다.'

                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });

                this.rmHomFile = true

            } else {
                if(this.sltFile.fileClcd == '1') this.logoList.splice(idx,1)
                else if(this.sltFile.fileClcd == '2') this.bannerList.splice(idx,1)
                else if(this.sltFile.fileClcd == '3') this.introList.splice(idx,1)
            }   
        },

        onDelete:function(){
            if(this.sltFile.fcltyHomFilePk > 0){

                let obj = {
                    fcltyNum        : this.$store.getters.getFcltyNum,
                    userNm          : this.$store.getters.getUserNm,
                    fcltyHomFilePk  : this.sltFile.fcltyHomFilePk
                }

                this.setDelFcltyHomFile(obj)
            }else{
                let obj = {}
                obj.actv = true  
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = '대상을 찾을 수 없습니다.'         // 경고 타이틀
                this.$store.commit('setWrnnInf', obj)
            }
        },
        onDownload:function(idx,key){

            let obj = {
                fcltyHomFilePk:0,
                filePth:'',
                fileNm:'',
            }

            switch (key) {
                case '1':
                    obj.fcltyHomFilePk =  this.logoList[idx].fcltyHomFilePk
                    obj.filePth =  this.logoList[idx].filePth
                    obj.fileNm =  this.logoList[idx].fileNm
                    break;
                case '2':
                    obj.fcltyHomFilePk =  this.bannerList[idx].fcltyHomFilePk
                    obj.filePth =  this.bannerList[idx].filePth
                    obj.fileNm =  this.bannerList[idx].fileNm
                    break;
                case '3':
                    obj.fcltyHomFilePk =  this.introList[idx].fcltyHomFilePk
                    obj.filePth =  this.introList[idx].filePth
                    obj.fileNm =  this.introList[idx].fileNm
                    break;
                default:
                    break;
            }

            this.gethomImgDownload(obj)
        },

        getFcltyHomFileList:function(){
            this.logoList.splice(0)
            this.introList.splice(0)
            this.bannerList.splice(0)
            selFcltyHomFileList(this.$store.getters.getFcltyNum)
                .then((response) => ( this.getFcltyHomFileListAfter(response.data) ))
                .catch((error) => console.log('upload error /hominf/tmpHomImgUpload : ' + error))
        },

        setTmpImgUpload:function(formData){
            tmpHomImgUpload(formData)
                .then((response) => ( this.setTmpImgUploadAfter(response.data) ))
                .catch((error) => console.log('upload error /hominf/tmpHomImgUpload : ' + error))
        },

        gethomImgDownload:function(obj){
            homImgDownload(obj)
                .then((response) => ( this.gethomImgDownloadAfter(response) ))
                .catch((error) => console.log('upload error /hominf/homImgDownload : ' + error))
        },

        setInsFcltyHomFile:function(obj){
            insFcltyHomFile(obj)
                .then((response) => ( this.setInsFcltyHomFileAfter(response.data) ))
                .catch((error) => console.log('upload error /hominf/insFcltyHomFile : ' + error))
        },

        setDelFcltyHomFile:function(obj){           
            delFcltyHomFile(obj)
                .then((response) => ( this.setDelFcltyHomFileAfter(response.data) ))
                .catch((error) => console.log('upload error /hominf/delFcltyHomFile : ' + error))
        },

        getFcltyHomFileListAfter:function(res){
            this.progress = false
            let obj = {}
            obj.actv = true  

            if(res.statusCode == 200){                                               
                             
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)                   
                if(res.result != null && res.result.length > 0){                    
                    this.setImgFileList(res.result)                   
                }
            }
            else{                
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
                this.$store.commit('setWrnnInf', obj)
            }
        },

        setTmpImgUploadAfter:function(res){
            this.progress = false
            let obj = {}
            obj.actv = true   
            
            if(res.statusCode == 200){                                               
                             
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)   

                if(res.files != null && res.files.length > 0){
                    res.files.forEach(el => {
                        el.fcltyHomFilePk = 0                        
                        el.fileClcd = this.key
                    });
                    this.setImgFileList(res.files)                   
                }

                switch (this.key) {
                    case '1':
                        this.logo = null
                        break;
                    case '2':
                        this.banner = null
                        break;
                    case '3':
                        this.intro = null
                        break;
                    default:
                        break;
                }

            }
            else{                
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
                this.$store.commit('setWrnnInf', obj)
            }
            this.key = ''
        },

        gethomImgDownloadAfter:function(res){
            let contentDisposition = decodeURI(res.headers['content-disposition'])
       
            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'));
                if (fileNameMatch)
                    [ , fileName ] = fileNameMatch.split('=');
            }
            link.href = url;
 
            fileName = fileName.replaceAll('"', '')
            link.setAttribute('download', `${fileName}`);
            link.style.cssText = 'display:none';
            document.body.appendChild(link);
            link.click();
            link.remove();
        },

        setInsFcltyHomFileAfter:function(res){
            this.progress = false
            let obj = {}
            obj.actv = true   
            
            if(res.statusCode == 200){                                         
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)   
                this.getFcltyHomFileList()
            }
            else{                
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
            }

            this.$store.commit('setWrnnInf', obj)
        },

        setDelFcltyHomFileAfter:function(res){            
            let obj = {}
            obj.actv = true   
            
            if(res.statusCode == 200){                                         
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!) 
                this.getFcltyHomFileList()  
            }
            else{                
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
            }

            this.$store.commit('setWrnnInf', obj)
        },

        setImgFileList(files){
            files.forEach(el => {
                if(el.fileClcd == '1') this.logoList.push(el)
                else if(el.fileClcd == '2') this.bannerList.push(el)
                else if(el.fileClcd == '3') this.introList.push(el)
                
                if(el.fcltyHomFilePk == 0) el.src = remoteURL + 'opr/imgPrview?fileNm='+el.fileNm+'&filePth='+el.filePth+'&fileKndCd=2'
                else el.src = remoteURL + 'opr/imgView?fileNm='+el.fileNm+'&filePth='+el.filePth+'&fileKndCd=2'
            });
        }
    },

    data: () => ({
        progress:false,
        rmHomFile:false,
        key:'',
        logo: null,
        intro: null,
        banner: null,
        imgAccept:'image/*',        
        logoList: [],
        introList: [],
        bannerList: [],
        sltFile:{
            fcltyHomFilePk:0,
            filePth:'',
            fileNm:'',
            fileClcd : '',
        },
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'white--text', color:'blueBtnColor', text: '저장', disabled: false, },           
        ],
    }),
};
</script>