<template>
    <v-sheet class="rounded-lg rounded-l-0 overflow-auto pa-2" color="" :min-width="areaWidth+100" :max-width="areaWidth+300">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <v-row class="ma-0 pa-0" no-gutters>
            <v-spacer></v-spacer>
            <v-col class="d-flex pr-2" cols="6">
                <v-spacer></v-spacer>
                <span v-if="tab == 0">
                    <v-btn class="ml-1 white--text" color="blueBtnColor" height="30" @click="menu.splice(0, 1, true)" samll rounded>
                        <v-icon small>mdi-pencil-outline</v-icon>
                        <span style="font-size: 1rem;">신규</span>
                    </v-btn>
                </span>
                <span v-if="$parent.$parent.sltEmpPk != -1 && tab == 0">
                    <v-btn class="ml-1 white--text" color="blueBtnColor" height="30" @click="mdlOpener()" samll rounded>
                        <v-icon small>mdi-pencil-outline</v-icon>
                        <span style="font-size: 1rem;">수정</span>
                    </v-btn>
                </span>
                <span v-if="$parent.$parent.sltEmpPk != -1 && jobCd == '자원봉사자' && tab == 1">
                    <v-btn class="ml-1 white--text" color="blueBtnColor" height="30" @click="menu.splice(3, 1, true)" samll rounded>
                        <v-icon small>mdi-pencil-outline</v-icon>
                        <span style="font-size: 1rem;">자원봉사자 활동일지 작성</span>
                    </v-btn>
                </span>
                <span v-if="tab == 1">
                    <v-btn
                        class="ml-1 font-weight-bold" height="30" color="grey006" samll rounded outlined>
                        <v-icon small>mdi-printer-outline</v-icon>
                        <span style="font-size: 1rem;">자원봉사자 활동일지 출력</span>
                    </v-btn>
                </span>
            </v-col>
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row class="pa-0 mt-3" no-gutters>
            <v-col cols="12">
                <v-tabs
                    v-model="tab" class="tab-dft-Theme" active-class="black"
                    center-active hide-slider>
                    <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class" :disabled="item.enter">{{item.tab}}</v-tab>
                </v-tabs>
                <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
                <v-tabs-items v-model="tab">
                    <v-tab-item eager :transition="false">
                        <WrkSMntlWrkshttEmp ref="wrkSMntlWrkshttEmp"></WrkSMntlWrkshttEmp>
                    </v-tab-item>
                    <v-tab-item :transition="false">
                        <PrdWorkJrnl ref="prdWorkJrnl"></PrdWorkJrnl>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
        <v-dialog v-model="menu[0]" max-width="800" content-class="round" persistent>
            <ExtEmpMdl ref="extEmpMdl" @modalClose="mdlCloser"></ExtEmpMdl>
        </v-dialog>
        <v-dialog v-model="menu[1]" max-width="800" content-class="round" persistent eager>
            <MdfExtEmpMdl ref="mdfExtEmpMdl" @modalClose="mdlCloser"></MdfExtEmpMdl>
        </v-dialog>
        <v-dialog v-model="menu[2]" max-width="800" content-class="round" persistent eager>
            <MdfExtVlntrMdl ref="mdfExtVlntrMdl" @modalClose="mdlCloser"></MdfExtVlntrMdl>
        </v-dialog>
        <v-dialog v-model="menu[3]" max-width="800" content-class="round" persistent eager>
            <VlntrWorkJrnlMdl ref="vlntrWorkJrnlMdl" @modalClose="mdlCloser"></VlntrWorkJrnlMdl>
        </v-dialog>
    </v-sheet>
</template>

<script>
import WrkSMntlWrkshttEmp from '../../components/emp/MntlWrksh.vue';
import PrdWorkJrnl from '../../components/emp/PrdWorkJrnl.vue';
import VlntrWorkJrnlMdl from '../../components/emp/VlntrWorkJrnlMdl.vue';
import MdfExtEmpMdl from '../../components/emp/MdfExtEmpMdl.vue';
import MdfExtVlntrMdl from '../../components/emp/MdfExtVlntrMdl.vue';

import ExtEmpMdl from '../../components/emp/ExtEmpMdl.vue';

export default {
    name: 'OtsdExprtMgmt',
    
    components: {
        WrkSMntlWrkshttEmp,
        PrdWorkJrnl,
        VlntrWorkJrnlMdl,
        MdfExtEmpMdl,
        MdfExtVlntrMdl,
        ExtEmpMdl,
    },

    props : {

    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.areaWidth = 1260 - Number(this.$parent.$parent.midAreaWidth)
        })
    },

    computed: {
        getAreaWidth: function () {
            return this.$parent.$parent.midAreaWidth
        },
        getSltEmpPk: function () {
            return this.$parent.$parent.sltEmpPk
        },
    },  

    watch: {
        getAreaWidth: function (value) {
            this.areaWidth = 1260 - Number(value)
        },
        getSltEmpPk: function (value) {
            this.$parent.$parent.empList.forEach(items => {
                if(items.empPk == value){
                    this.jobCd = items.jobCd
                }
            });
        },
    },

    methods: {
        mdlOpener: function () {
            if(this.$parent.$parent.sltEmpPk != -1){
                this.$parent.$parent.empList.forEach(items => {
                    if(this.$parent.$parent.sltEmpPk == items.empPk){
                        if( items.jobCd == '외부강사' || items.jobCd == '16' )
                            this.menu.splice(1, 1, true)
                        else if( items.jobCd == '자원봉사자' || items.jobCd == '17' )
                            this.menu.splice(2, 1, true)
                    }
                });
            }
        },
        mdlCloser: function (index) {
            this.menu.splice(index, 1, false)
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    data: () => ({
        tab: 0,
        jobCd: '',
        menu: [false, false, false, false,],
        areaWidth: 708,
        tabItems: [
            { index: 0, tab: '월별 근무표', class: 'fontOneDotTwoRem px-15', enter: false, },
            { index: 1, tab: '기간별 활동일지', class: 'fontOneDotTwoRem px-15', enter: false, to: '/' },
        ],
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>