<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">   
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>       
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">그룹 관리</span>
            <v-spacer></v-spacer>            
            <v-btn v-for="(list, i) in filledBtn" :key="i"  @click = "onClickBtn(i)"
                :class="list.class" height="30" :color="list.color" :disabled="list.disabled" rounded
                style="font-size: 14px;">
                <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                {{list.text}}
            </v-btn>
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <v-form ref="tabform" lazy-validation> 
        <div class="mt-3">
            <v-row class="pa-0" style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">그룹명</span>
                </v-col>
                <v-col class="pa-1" cols="10">
                    <v-text-field
                        v-model="authGrpItems.authNm" :rules="[rules.required]"
                        color="blue" height="28" ref="info"
                        hide-details outlined dense>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row class="pa-0 ma-0"  style="border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">그룹설명</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="10">                        
                    <v-textarea v-model="authGrpItems.authDesc" class="rmrksArea" no-resize  
                        rows="5" outlined dense hide-details>
                    </v-textarea>
                </v-col>
            </v-row>
        </div>        
        <div v-if="authGrpPk > 0" class="mt-2">
            <v-data-table height="300" locale="ko"
                :headers="authEmpHeader"
                :items="authEmpList" 
                hide-default-header       
                hide-default-footer 
                fixed-header
                dense disable-pagination>
                <template v-slot:header="{ props }" >
                    <thead>
                        <tr> 
                            <th class="black--text clmsFixedHeader tableBR" style="width:110px !important;">{{props.headers[0].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:50px !important;">{{props.headers[1].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:110px !important;">{{props.headers[2].text}}</th>
                            <th class="black--text clmsFixedHeader" style="width:90px !important;">{{props.headers[3].text}}</th>
                        </tr>
                    </thead>
                </template>

                <template v-slot:item='{ item }'>
                    <tr>
                        <td class="clmsBodyCommon tableBR fixedCol0">{{item.name}}</td>                         
                        <td class="clmsBodyCommon tableBR fixedCol0">
                            <span>
                                <v-img class="d-inline-block" style="vertical-align: middle;" v-if="item.gndr == 'F'" src="../../assets/icon/ic-female.svg"></v-img>
                                <v-img class="d-inline-block" style="vertical-align: middle;" v-else-if="item.gndr == 'M'" src="../../assets/icon/ic-male.svg"></v-img>
                            </span>
                        </td>                         
                        <td class="clmsBodyCommon tableBR fixedCol0">{{item.jobCd}}</td> 
                        <td class="clmsBodyCommon fixedCol0">{{item.entDt?.beforeDateFormatDot()}}</td>            
                    </tr>
                </template>
            </v-data-table>
        </div>   
        </v-form>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete title="그룹" @madalState="onDelete"></btnModalDelete>  
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import { getMultiStcd } from '../../api/index.js';
import { selCmnAuthGrpInfo, insCmnAuthGrp, delCmnAuthGrp, selCmnAuthGrpEmpList } from '../../api/cmm.js';

export default {
    name: 'CmmAuthWrt',
    
    components: {  
        btnModalDelete
    },

    props : {
    },

    computed: {
        
    },

    created: function(){   
    },

    mounted: function(){
        this.$nextTick(function () {
            this.getCode(['42'])
        })
    },

    watch: {
        
    },

    methods: {  
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        stCdMapping: function (res) {
            this.vCd42 = res.vCd42
        },
        onLoad: function(pk){            
            this.authGrpPk = pk

            if(this.authGrpPk > 0){ 
                this.filledBtn[1].disabled = false 
                this.getCmnAuthGrpInfo()
                this.getCmnAuthGrpEmpList()
            }
            else{
                this.authGrpItems = Object.assign({})
                this.filledBtn[1].disabled = true
                this.$refs.tabform.resetValidation()
            }

        },        
        onClickBtn: function (key) {
            switch (key) {
                case 0:
                    this.onSave()
                    break;  
                case 1:
                    this.dialogDelete = true
                    break;              
                default:
                    break;
            }
        },
        onClose: function () {
            this.$emit('modalEvent', 0)
        },
        onSave:function(){
            if(this.$refs.tabform.validate()){
                let obj = {
                    fcltyNum:this.$store.getters.getFcltyNum,
                    userNm:this.$store.getters.getUserNm,
                    cmnAuthGrpPk:this.authGrpPk,
                    authNm:this.authGrpItems.authNm,
                    authDesc:this.authGrpItems.authDesc,
                }
                insCmnAuthGrp(obj)
                    .then((response) => ( this.onSaveAfter(response.data) ))
                    .catch((error) => console.log('connect error /cmm/insCmnAuthGrp : ' + error))
            }
        },
        onSaveAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.$emit('modalEvent', 99)
                this.onLoad(res.result)
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onDelete:function(res){            
            if(res == 9990){
                if(this.authGrpPk > 0){
                    let obj = {
                        fcltyNum : this.$store.getters.getFcltyNum,
                        userNm:this.$store.getters.getUserNm,
                        cmnAuthGrpPk:this.authGrpPk
                    }
                    delCmnAuthGrp(obj)
                        .then((response) => ( this.onDeleteAfter(response.data) ))
                        .catch((error) => console.log('connect error /impExp/delCmnAuthGrp : ' + error))
                }else{
                    this.snackControll([true, 3500, '삭제 할 대상을 선택해주세요.', 'warning'])
                    this.dialogDelete = false
                }
            }else if(res == 7){
                this.dialogDelete = false
            }
        },
        onDeleteAfter:function(res){
            this.dialogDelete = false
            this.authGrpPk = 0

            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.$emit('modalEvent', 99)
                this.onClose()
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        getCmnAuthGrpInfo: function () {
            selCmnAuthGrpInfo(this.$store.getters.getFcltyNum, this.authGrpPk)
                .then((response) => ( this.getCmnAuthGrpInfoAfter(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnAuthGrpInfo : ' + error))
        },
        getCmnAuthGrpInfoAfter:function(res){
            this.authGrpItems = res
            this.$refs.tabform.resetValidation()
        },
        getCmnAuthGrpEmpList: function () {
            selCmnAuthGrpEmpList(this.$store.getters.getFcltyNum, this.authGrpPk)
                .then((response) => ( this.getCmnAuthGrpEmpListAfter(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnAuthGrpEmpList : ' + error))
        },
        getCmnAuthGrpEmpListAfter:function(res){
            this.authEmpList = res
            this.authEmpList.forEach(item => {
                this.vCd42.forEach(cd => {
                    if(item.jobCd == cd.valcd) item.jobCd = cd.valcdnm
                })
            });
        },        
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    data: () => ({
        vCd42:[],
        authGrpPk:0,
        authGrpItems:{},
        authEmpHeader:[
            { text: '직원성명',width: '', value: '', align: 'center', },
            { text: '성별',width: '', value: '', align: 'center', },
            { text: '담당직종',width: '', value: '', align: 'center', },
            { text: '입사일',width: '', value: '', align: 'center', },
        ],
        authEmpList:[],
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type: 'action', width:'800px', disabled: false, },            
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type: 'action', width:'500px', visible: true, disabled: true, },
        ],
        snackOptions: [false, 3000, '', 'info'],
        dialogDelete:false,
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
    }),
};
</script>