<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold">노인인권&nbsp;보호지침&nbsp;제공&nbsp;정보</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <div class="mt-5 d-flex justyfy-right">
            <v-spacer></v-spacer>
            <v-btn v-for="(list, i) in filledBtn" :key="i" @click="btnAction(list.text)"
                :class="list.class" samll height="28" :color="list.color" rounded>
                <v-icon small>{{list.icon}}</v-icon>
                <span style="font-size: 1rem;">{{list.text}}</span>
            </v-btn>
        </div>
        <v-divider class="mt-3"></v-divider>
        <v-row class="" no-gutters>
            <v-col class="pa-0 ma-0 greyE01 d-flex justify-center" cols="4">
                <span class="black--text font-weight-medium py-1">수급자</span>
            </v-col>
            <v-col class="pa-0 ma-0 d-flex justify-left" cols="8">
                <span class="black--text my-1 ml-5">{{bnfcrInfo == null ? '' : bnfcrInfo.name}}</span>
            </v-col>
        </v-row>
        <v-form ref="eldForm" lazy-validation>
            <v-divider class=""></v-divider>
            <v-row class="" no-gutters>
                <v-col class="pa-0 ma-0 greyE01 d-flex justify-center" cols="4">
                    <span class="black--text font-weight-medium py-1">제공일</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-left" cols="8">
                    <span class="white d-inline-block py-1 pl-4" style="width: 153px;">
                        <CmmDateComp v-model="eldHmnOfrDt" :required="true"></CmmDateComp>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="" no-gutters>
                <v-col class="pa-0 ma-0 greyE01 d-flex justify-center" cols="4">
                    <span class="black--text font-weight-medium py-1">제공&nbsp;받은자</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-left align-center" cols="8">
                    <span class="black--text font-weight-medium ml-4" style="width: 166px;" >
                        <v-radio-group
                            class="radio-dft-Theme mt-0"
                            v-model="eldHmnRcvPstnCd" :rules="[rules.required]"
                            hide-details row>
                            <v-radio :ripple="false" label="수급자" value="1"></v-radio>
                            <v-radio :ripple="false" label="보호자" value="2"></v-radio>
                        </v-radio-group>
                    </span>
                    <span class="d-inline-block py-1" style="width: 120px;">
                        <v-text-field
                            v-model="eldHmnRcvNm" color="blue" height="28"
                            :rules="[rules.required, rules.strCheck]"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
        </v-form>
        <v-divider class="mb-2"></v-divider>
    </v-sheet>
</template>

<script>
import { bnfcrCntrcDetail, setBnfcrCntrcEld } from '../../api/bnfcr.js';

import CmmDateComp from '../commons/CmmDateComp.vue';

export default {
    name: 'EldHmnOfrInfo',

    props : {
        bnfcrInfo: { type: Object, default: new Object() }
    },

    components: {
        CmmDateComp,
    },

    computed: {

    },

    watch: {
        'bnfcrInfo': function(){
            if(this.bnfcrInfo.mmbrPk != 0)
                this.getCntrcList(this.$store.getters.getBnfcrState)
        },
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    methods:{
        getCntrcList: function (value) {
            bnfcrCntrcDetail(this.$store.getters.getFcltyNum, value)
                .then((response) => ( this.getCntrcMmbrPk(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selBnfcrCntrcInf : ' + error))
        },
        setCntrcEld: function (date, ofrYn, pstnCd, rcvNm, cntrcPk, code, bnmmbr, bnfcr, nm) {
            let obj = {}
            obj.eldHmnOfrDt = date
            obj.eldHmnOfrYn = ofrYn
            obj.eldHmnRcvPstnCd = pstnCd
            obj.eldHmnRcvNm = rcvNm
            obj.bnfcrCntrcInfPk = cntrcPk
            obj.fcltyNum = code
            obj.bnMmbrPk = bnmmbr
            obj.bnfcrPk = bnfcr
            obj.userNm = nm

            setBnfcrCntrcEld(obj)
                .then((response) => ( this.insCntrcAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/updBnfcrCntrcAgr : ' + error))
        },
        getCntrcMmbrPk: function (res) {
            if(res.eldHmnOfrDt == '')
                res.eldHmnOfrDt = this.$store.getters.getPrsntDt('yyyy-mm-dd')

            this.bnfcrCntrc = res
            this.eldHmnRcvNm = res.eldHmnRcvNm
            this.eldHmnRcvPstnCd = res.eldHmnRcvPstnCd

            if(res.eldHmnOfrDt != '' || res.eldHmnOfrDt != 'undefined')
                this.eldHmnOfrDt = res.eldHmnOfrDt?.beforeDateFormatHyp()
        },
        insCntrcAfter: function (res) {
            if(res.statusCode == 200){
                this.bnfcrCntrc = null
                this.eldHmnOfrDt = ''
                this.eldHmnRcvPstnCd = ''
                this.eldHmnRcvNm = ''
                this.onClose()
                this.$parent.$parent.$parent.$parent.getHmnHltList()
            }
            else
                console.log('save failed')
        },
        btnAction: function (text) {
            if(text == '저장'){
                let chk = this.$refs.eldForm.validate()
                if(chk){
                    this.setCntrcEld(
                        this.eldHmnOfrDt.afterDateFormatHyp(),
                        'Y',
                        this.eldHmnRcvPstnCd,
                        this.eldHmnRcvNm,
                        this.bnfcrInfo.bnfcrCntrcInfPk,
                        this.$store.getters.getFcltyNum,
                        this.bnfcrInfo.mmbrPk,
                        this.bnfcrInfo.bnfcrPk,
                        this.$store.getters.getUserNm
                    )
                }
            }
            else if(text == '삭제')
                this.setCntrcEld('', '', '', '', this.bnfcrInfo.bnfcrCntrcInfPk, 0, 0, 0, this.$store.getters.getUserNm)
        },
        onClose: function () {
            this.$emit('isClose', '.')
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
    },

    data: () => ({
        menu: [false, false],
        bnfcrCntrc: null,
        eldHmnOfrDt: '',
        eldHmnRcvPstnCd: '',
        eldHmnRcvNm: '',
        forceRender : 0,
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', },
        ],
        rules: {
            required: value => !!value || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
        },
    })
}
</script>