<template>
    <v-sheet class="rounded-lg rounded-l-0 pa-3" color="" min-width="1260" max-width="1560" height="835">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col class="text-h4 d-flex align-center" cols="5">
                <v-icon color="topTitleColor">mdi-chevron-down</v-icon>
                <span class="ml-1 hamletFont text-h5 topTitleColor--text font-weight-black">7-3.&nbsp;근무일지(출퇴근)</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex align-center pr-3" cols="5">
                <v-spacer></v-spacer>
                <span>
                    <v-btn
                        class="ml-1 font-weight-bold" height="30" color="blueBtnColor" @click="setAllEmpAtnd" samll rounded>
                        <v-icon class="white--text" style="padding-top: 1px;" small>mdi-printer-outline</v-icon>
                        <span class="white--text ml-1" style="font-size: 1rem;">근무일정 일괄반영</span>
                    </v-btn>
                </span>
                <!-- <span class="" style="width: 190px; height: 30px;">
                    <v-radio-group class="" hide-details row>
                        <v-radio class="d-flex align-center ma-0 mr-2" :ripple="false" label="일일" value="1"></v-radio>
                        <v-radio class="d-flex align-center ma-0 mr-2" :ripple="false" label="주간" value="2"></v-radio>
                        <v-radio class="d-flex align-center ma-0 mr-2" :ripple="false" label="월간" value="3"></v-radio>
                    </v-radio-group>
                </span> -->
                <!-- <span>
                    <v-btn
                        class="font-weight-bold" height="30" color="grey006"
                        rounded outlined samll>
                        <div class="d-flex justify-left align-center">
                            <v-icon size="18">mdi-printer-outline</v-icon>
                            <span class="fontOneRem ml-2">근무현황&nbsp;출력</span>
                        </div>
                    </v-btn>
                </span> -->
            </v-col>
        </v-row>
        <v-row class="pa-0 mt-5" no-gutters>
            <v-col cols="12">
                <v-tabs
                    v-model="tab" class="tab-dft-Theme" active-class="black"
                    center-active hide-slider>
                    <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class" :disabled="item.enter">{{item.tab}}</v-tab>
                </v-tabs>
                <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
                <v-tabs-items v-model="tab">
                    <v-tab-item eager :transition="false">
                    </v-tab-item>
                    <v-tab-item eager :transition="false">
                        <v-row class="ma-0 pa-0" no-gutters>
                            <v-col class="my-2" cols="12">
                                <expandNowDatePicker :prtDate="sltDate" @nowDate="getDate"></expandNowDatePicker>
                            </v-col>
                        </v-row>
                        <v-row class="mx-2 pa-0" no-gutters>
                            <v-col class="d-flex align-center" cols="3">
                                <exBtn class="mr-1" :btnData="exBtn[0]" @btnClosed="expandClose" @changeCheck="filterSearch" />
                                <span class="d-inline-block mt-1 ml-6" style="width: 124px;">
                                    <v-text-field
                                        @input="setSearchText"
                                        class="txt-Theme" height="34" label="이름조회"
                                        background-color="white" prepend-inner-icon="mdi-magnify"
                                        single-line outlined dense filled hide-details>
                                    </v-text-field>
                                </span>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col class="d-flex align-end" cols="4">
                                <v-spacer></v-spacer>
                                <span class="font-weight-medium" style="font-size: 0.975rem; color: #666;">
                                    ※근무시간&nbsp;&#61;&nbsp;퇴근&#40;근무종료&#41;시간&nbsp;&#45;&nbsp;출근&#40;근무시작&#41;시간&nbsp;&#45;&nbsp;휴게시간
                                </span>
                            </v-col>
                        </v-row>
                        <div class="mt-2 rounded-lg">
                            <v-data-table class="dlyWrk" height="620" locale="ko"
                                :headers="headerList" :items="empAtndList" :search="schDummy" :custom-filter="customFilter"
                                hide-default-header fixed-header disable-pagination hide-default-footer dense>
                                <template v-slot:header="{ props }" >
                                    <thead>
                                        <tr class="">
                                            <th class="tableBR txtAlgnMidImp py-2" rowspan="2" style="width: 90px;">
                                                <span class="fontOneRem black--text">{{props.headers[0].text}}</span>
                                            </th>
                                            <th class="tableBR txtAlgnMidImp px-1 py-2" rowspan="2" style="width: 110px;">
                                                <span class="fontOneRem black--text">{{props.headers[1].text}}</span>
                                            </th>
                                            <th class="tableBR txtAlgnMidImp px-1 py-2" rowspan="2" style="width: 120px;">
                                                <span class="fontOneRem black--text">{{props.headers[2].text}}</span>
                                            </th>
                                            <th class="tableBR txtAlgnMidImp px-1 py-2" rowspan="2" style="width: 90px;">
                                                <span class="fontOneRem black--text">{{props.headers[3].text}}</span>
                                            </th>
                                            <th class="tableBR txtAlgnMidImp px-1 py-2" rowspan="2" style="width: 100px;">
                                                <span class="fontOneRem black--text">{{props.headers[4].text}}</span>
                                            </th>
                                            <th class="tableBR txtAlgnMidImp px-1 py-2" rowspan="2" style="width: 80px;">
                                                <span class="fontOneRem black--text">{{props.headers[5].text}}</span>
                                            </th>
                                            <th class="tableBR txtAlgnMidImp px-1 py-2" rowspan="2" style="width: 80px;">
                                                <span class="fontOneRem black--text">{{props.headers[6].text}}</span>
                                            </th>
                                            <th class="tableBR txtAlgnMidImp px-1 py-2" rowspan="2" style="width: 40px; line-height: 1.2;">
                                                <span class="fontOneRem black--text">{{props.headers[7].text}}</span>
                                            </th>
                                            <th class="tableBR txtAlgnMidImp tblIntBL py-2" colspan="2" style="width: 120px;">
                                                <span class="fontOneRem black--text">휴게시간</span>
                                            </th>
                                            <th class="tableBR txtAlgnMidImp px-1 py-2" rowspan="2" style="width: 116px;">
                                                <span class="fontOneRem black--text">{{props.headers[11].text}}</span>
                                            </th>
                                            <th class="tableBR txtAlgnMidImp px-1 py-2" rowspan="2">
                                                <span class="fontOneRem black--text">{{props.headers[12].text}}</span>
                                            </th>
                                            <th class="txtAlgnMidImp px-1 py-2" rowspan="2" style="width: 50px;">
                                                <span class="fontOneRem black--text">{{props.headers[13].text}}</span>
                                            </th>
                                        </tr>
                                        <tr class="" style="line-height: 1.2;">
                                            <th class="txtAlgnMidImp tableBR px-0 py-2" style="width: 72px; top: 38px;">
                                                <span class="fontOneRem black--text">{{props.headers[8].text}}</span>
                                            </th>
                                            <th class="txtAlgnMidImp tableBR px-0 py-2" style="width: 72px; top: 38px;">
                                                <span class="fontOneRem black--text">{{props.headers[9].text}}</span>
                                            </th>
                                            <!-- <th class="tableBR px-0 py-2" style="text-align: center;">
                                                <span class="fontOneRem black--text">{{props.headers[10].text}}</span>
                                            </th> -->
                                        </tr>
                                    </thead>
                                </template>
                                <template v-slot:item='{ item, index }'>
                                    <tr>
                                        <td class="txtAlgnMid tableBB tableBR py-2">
                                            <span class="fontOneDotOneRem">{{ item.name }}</span>
                                        </td>
                                        <td class="txtAlgnMid tableBB tableBR px-1 py-2">
                                            <span class="fontOneDotOneRem">{{ item.jobCd }}</span>
                                        </td>
                                        <td class="txtAlgnMid tableBB tableBR px-1 py-2">
                                            <span class="fontOneDotOneRem">
                                                {{ item.esWrkTmHhStrt }}&#58;{{ item.esWrkTmMmStrt }}&nbsp;&#126;
                                                {{ item.esWrkTmHhEnd }}&#58;{{ item.esWrkTmMmEnd }}
                                            </span>
                                        </td>
                                        <td class="txtAlgnMid tableBB tableBR px-1 py-2">
                                            <span class="d-inline-block" style="width: 100%;">
                                                <v-select class="wrkSelect"
                                                    v-model="item.wrkTypCd" height="28"
                                                    item-text="valcdnm" item-value="valcd" :items="vCd73"
                                                    dense hide-details outlined>
                                                </v-select>
                                            </span>
                                        </td>
                                        <td class="txtAlgnMid tableBB tableBR px-1 py-2">
                                            <span class="d-inline-block" style="width: 100%;">
                                                <v-select class="wrkSelect"
                                                    v-model="item.vctnTypCd" height="28"
                                                    item-text="valcdnm" item-value="valcd"
                                                    :items="vCd107" :disabled="item.wrkTypCd == '2' ? false : true"
                                                    dense hide-details outlined>
                                                </v-select>
                                            </span>
                                        </td>
                                        <td class="px-1 tableBB tableBR">
                                            <span class="d-inline-block" style="width: 100%;">
                                                <CmmTimeField v-model="atndStrt[index]" :required="false"></CmmTimeField>    
                                            </span>
                                        </td>
                                        <td class="px-1 tableBB tableBR">
                                            <span class="d-inline-block" style="width: 100%;">
                                                <CmmTimeField v-model="leavEnd[index]" :required="false"></CmmTimeField>    
                                            </span>
                                        </td>
                                        <td class="pr-0 tableBB tableBR" style="padding-left: 7px !important;">
                                            <v-checkbox
                                                v-model="item.wrkOvnYn"
                                                class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                true-value="1" false-value="2"
                                                on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                                hide-details>
                                            </v-checkbox>
                                        </td>
                                        <td class="px-1 tableBB tableBR">
                                            <span class="d-inline-block" style="width: 100%;">
                                                <v-text-field v-model="item.brkDay"
                                                    type="number" height="28" @input="wrkHrCalcIndex(index)"
                                                    color="blue" hide-details outlined dense>
                                                </v-text-field>
                                            </span>
                                        </td>
                                        <td class="px-1 tableBB tableBR">
                                            <span class="d-inline-block" style="width: 100%;">
                                                <v-text-field
                                                    v-model="item.brkNgt"
                                                    type="number" height="28" @input="wrkHrCalcIndex(index)"
                                                    color="blue" hide-details outlined dense>
                                                </v-text-field>
                                            </span>
                                        </td>
                                        <!-- <td class="px-1 tableBB tableBR">
                                            <span class="d-inline-block" style="width: 100%;">
                                                <v-text-field v-model="item.brkOvn"
                                                    type="number" @input="wrkHrCalcIndex(index)"
                                                    color="blue" hide-details outlined dense>
                                                </v-text-field>
                                            </span>
                                        </td> -->
                                        <td class="txtAlgnMid tableBB tableBR">
                                            <span style="font-size: 1.1rem;">{{ item.actWrkTxt }}</span>
                                        </td>
                                        <td class="px-1 tableBB tableBR">
                                            <span class="d-inline-block" style="width: 100%;">
                                                <v-text-field
                                                    v-model="item.rmrks"
                                                    color="blue" height="28" hide-details outlined dense>
                                                </v-text-field>
                                            </span>
                                        </td>
                                        <td class="px-0 tableBB">
                                            <div class="d-flex justify-center">
                                                <v-btn class="" color="blueBtnColor" height="26" @click="preEmpAtnd(index)" icon>
                                                    <v-icon class="ml-1">mdi-content-save-edit-outline</v-icon>
                                                </v-btn>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                            <v-divider></v-divider>
                        </div>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import expandNowDatePicker from '../../components/commons/expandNowDatePicker.vue';
import exBtn from '../../components/commons/expandButtonCustom.vue';
import CmmTimeField from '../../components/commons/CmmTimeField.vue';

import { getMultiStcd } from '../../api/index.js';
import { selEmpAtndList, insEmpAtnd, insAllEmpAtnd } from '../../api/emp.js';

export default {
    name: 'DlyWrkStt',
    
    components: {
        expandNowDatePicker,
        exBtn,
        CmmTimeField,
    },

    props : {

    },

    created: function(){
        let timezoneOffset = new Date().getTimezoneOffset() * 60000
        let timezoneDate = new Date(Date.now() - timezoneOffset)
        this.now = timezoneDate.toISOString().split('T',2)[0]
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getCode(['42', '73', '107'])
        })
    },

    computed: {

    },  

    watch: {
        'tab': function() {
            if(this.tab == 0)
                this.$router.push({ name: 'WrkngJrnl', })
        },
        'start': function () {
            //this.getEmpWrkJobCntList(this.start.afterDateFormatHyp().substring(0, 6))
        },
    },

    methods: {
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        getEmpAtndList: function (ymd) {
            selEmpAtndList(this.$store.getters.getFcltyNum, null, null, ymd)
                .then((response) => ( this.getEmpAtndListAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/selEmpAtndList : ' + error))
        },
        setEmpAtnd: function (obj) {
            insEmpAtnd(obj)
                .then((response) => ( this.asyncResult(response.data) ))
                .catch((error) => console.log('connect error /emp/insEmpAtnd : ' + error))
        },
        setAllEmpAtnd: function () {
            insAllEmpAtnd(this.$store.getters.getFcltyNum, this.$store.getters.getUserNm, this.sltDate)
                .then((response) => ( this.asyncResult(response.data) ))
                .catch((error) => console.log('connect error /emp/insAllEmpAtnd : ' + error))
        },
        stCdMapping: function (res) {
            this.vCd42 = res.vCd42
            this.vCd73 = res.vCd73
            this.vCd107 = res.vCd107

            this.vCd42.forEach(code => {
                this.exBtn[0].expandList.push(code.valcdnm)
            })
        
            if(this.empAtndList.length > 0){
                this.empAtndList.forEach(itm => {
                    this.vCd42.forEach(items => {
                        if(itm.jobCd == items.valcd) itm.jobCd = items.valcdnm
                    });
                });
            }
        },
        getEmpAtndListAfter: function (res) {
            this.atndStrt.splice(0)
            this.leavEnd.splice(0)
          
            res.forEach(item => {
                this.vCd42.forEach(items => {
                    if(item.jobCd == items.valcd)
                        item.jobCd = items.valcdnm
                });
                if(item.atndTmHh == null || item.atndTmMm == null)
                    item.atndStrt = ''
                else
                    item.atndStrt = item.atndTmHh + ':' + item.atndTmMm

                if(item.leavTmHh == null || item.leavTmMm == null)
                    item.leavEnd = ''
                else
                    item.leavEnd = item.leavTmHh + ':' + item.leavTmMm

                if(item.atndStrt == '' && item.leavEnd == ''){
                    item.actWrkTxt = ''
                    item.actWrkHr = ''
                }
                else{
                    let ttm = this.wrkHrCalcObj(item)
                    item.actWrkHr = ttm.actWrkHr
                    item.actWrkTxt = ttm.actWrkTxt
                }

                if(item.esWrkOvnYn == null)
                    item.esWrkOvnYn = '2'

                if(typeof item.wrkOvnYn == 'undefined' || item.wrkOvnYn == null)
                    item.esWrkOvnYn = item.wrkOvnYn
                
                this.atndStrt.push(item.atndStrt)
                this.leavEnd.push(item.leavEnd)
                this.menuStrt.push(false)
            });
            this.empAtndList = res
            this.forceRender += 1
        },
        asyncResult: function (res) {
            if(res.statusCode == 200){
                this.snackControll([true, 2500, res.message, 'info'])
                this.getEmpAtndList(this.sltDate)
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        preEmpAtnd: function (index) {
            let obj = Object.assign({}, this.empAtndList[index])
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm
            obj.atndStrt = this.atndStrt[index]
            obj.atndTmHh = this.atndStrt[index].split(':', 2)[0]
            obj.atndTmMm = this.atndStrt[index].split(':', 2)[1]
            obj.leavEnd = this.leavEnd[index]
            obj.leavTmHh = this.leavEnd[index].split(':', 2)[0]
            obj.leavTmMm = this.leavEnd[index].split(':', 2)[1]
            obj.atndDt = obj.wrkDt

            if(obj.wrkTypCd != '2')
                obj.vctnTypCd = ''

            let ttm = this.wrkHrCalcObj(obj)
            obj.actWrkHr = ttm.actWrkHr
            obj.actWrkTxt = ttm.actWrkTxt

            if(this.atndStrt[index] == '' || this.leavEnd[index] == '')
                this.snackControll([true, 2500, '필수 항목을 입력해 주세요.', 'warning'])
            else if(obj.wrkTypCd == '2' && obj.vctnTypCd == null && obj.vctnTypCd == '')
                this.snackControll([true, 2500, '필수 항목을 입력해 주세요1.', 'warning'])
            else
                this.setEmpAtnd(obj)
        },
        wrkHrCalcObj: function (obj) {
            //시간을 분단위로 변경
            let strtMm = Number( obj.atndStrt.split(':', 2)[0] * 60 )
                + Number( obj.atndStrt.split(':', 2)[1] )
            let endMm = Number(obj.leavEnd.split(':', 2)[0]) * 60
                + Number( obj.leavEnd.split(':', 2)[1] )
          
            if(obj.wrkOvnYn == '1'){
                let hh = Math.floor( (((24 * 60) - strtMm) + endMm - obj.brkDay - obj.brkNgt - obj.brkOvn ) / 60 )
                let mm = ( ((24 * 60) - strtMm) + endMm - obj.brkDay - obj.brkNgt - obj.brkOvn ) % 60
                obj.actWrkTxt = hh + '시간 ' + mm + '분'
                obj.actWrkHr = (hh * 60) + mm
            }
            else {
                let hh = Math.floor( ( endMm - strtMm - obj.brkDay - obj.brkNgt - obj.brkOvn ) / 60 )
                let mm = ( endMm - strtMm - obj.brkDay - obj.brkNgt - obj.brkOvn ) % 60
                obj.actWrkTxt = hh + '시간 ' + mm + '분'
                obj.actWrkHr = (hh * 60) + mm
            }

            this.forceRender += 1
           
            return { actWrkTxt: obj.actWrkTxt, actWrkHr: obj.actWrkHr }
        },
        wrkHrCalcIndex: function (i) {
            //시간을 분단위로 변경
            let strtMm = Number( this.atndStrt[i].split(':', 2)[0] * 60 )
                + Number( this.atndStrt[i].split(':', 2)[1] )
            let endMm = Number(this.leavEnd[i].split(':', 2)[0]) * 60
                + Number( this.leavEnd[i].split(':', 2)[1] )

            if(this.empAtndList[i].wrkOvnYn == '1'){
                let hh = Math.floor( (((24 * 60) - strtMm) + endMm - this.empAtndList[i].brkDay - this.empAtndList[i].brkNgt - this.empAtndList[i].brkOvn ) / 60 )
                let mm = ( ((24 * 60) - strtMm) + endMm - this.empAtndList[i].brkDay - this.empAtndList[i].brkNgt - this.empAtndList[i].brkOvn ) % 60
                this.empAtndList[i].actWrkTxt = hh + '시간 ' + mm + '분'
                this.empAtndList[i].actWrkHr = (hh * 60) + mm
            }
            else{
                let hh = Math.floor( ( endMm - strtMm - this.empAtndList[i].brkDay - this.empAtndList[i].brkNgt - this.empAtndList[i].brkOvn ) / 60 )
                let mm = ( endMm - strtMm - this.empAtndList[i].brkDay - this.empAtndList[i].brkNgt - this.empAtndList[i].brkOvn ) % 60
                this.empAtndList[i].actWrkTxt = hh + '시간 ' + mm + '분'
                this.empAtndList[i].actWrkHr = (hh * 60) + mm
            }

            this.forceRender += 1
        },
        getDate: function (value) {
            this.sltDate = value.date
            this.getEmpAtndList(this.sltDate)
        },
        setSearchText: function (value) {
            this.schField = value
            this.tableSelected.splice(0)
            this.schDummy = this.schDummy + '.'
        },
        filterSearch: function (sch) {
            switch(this.schChkField.target) {
                case 'jobCd': this.schChkField.jobCd.splice(0)
                    sch.forEach((item) => { this.schChkField.jobCd.push(item) });
                    break;
                default:
                    break;
            }

            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column
            let col0 = filter.name.includes(this.schField)

            let col1 = true
            if(this.schChkField.jobCd.length != 0){
                this.schChkField.jobCd.forEach((stcd, i) => {
                    if(i == 0) col1 = filter.jobCd.includes(stcd)
                    else col1 = col1 || filter.jobCd.includes(stcd)
                });
            }

            return col0 && col1
        },
        timeAtndAplct: function (i) {
            this.$refs.empAtnd.save(this.atndStrt[i])
            this.empAtndList[i].atndStrt = this.atndStrt[i]

            this.menuStrt.splice(i, 1, false)
            this.forceRender += 1
        },
        timeLeavAplct: function (i) {
            this.$refs.empLeav.save(this.leavEnd[i])
            this.empAtndList[i].leavEnd = this.leavEnd[i]

            this.menuEnd.splice(i, 1, false)
            this.forceRender += 1
        },
        expandClose: function (index) {
            switch(index) {
                case 0: this.schChkField.target = 'jobCd'
                    break;
                default: this.schChkField.target = ''
                    break;
            }
        },
        rprtActCall: function (){
            this.prtThrowObj.list[0].bzPk.splice(0)
            //this.prtThrowObj.list[0].bzPk.push(this.$parent.$parent.sltEmpPk)
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
            });
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        tab: 1,
        vCd42: [],          // 담당직종
        vCd73: [],          // 근무형태
        vCd107: [],         // 휴가종류
        menu: [ false, ],
        menuStrt: [],
        menuEnd: [],
        atndStrt: [],
        leavEnd: [],
        now: '',
        sltDate: '',
        start: '',
        schField: '',
        schDummy: '',
        forceRender: 0,
        empAtndList: [],
        schChkField: { wrkStcd: [], jobCd: [], target: 'jobCd' },
        tabItems: [
            { index: 0, tab: '직원별 근무현황', class: 'fontOneDotTwoRem px-15', enter: false, },
            { index: 1, tab: '일별 근무현황', class: 'fontOneDotTwoRem px-15', enter: false, },
        ],
        exBtn: [
            { index:  0, btnTitle: '직종', btnIcon: 'business', exWidth: 134, exHeigth: 220, expandList: [], },
        ],
        headerList: [
            { text: '직원명', value: 'name', align: 'center', on: false, sortable: true, width: '100', class: 'black--text sftyHeader py-2'},
            { text: '담당직종', value: 'jobCd', align: 'center', on: false, sortable: true, width: '90', class: 'black--text sftyHeader py-2'},
            { text: '근무시간', value: 'entDt', align: 'center', on: false, sortable: true, width: '100', class: 'black--text sftyHeader py-2'},
            { text: '유형', value: 'entDt', align: 'center', on: false, sortable: true, width: '120', class: 'black--text sftyHeader py-2'},
            { text: '휴가 유형', value: '', align: 'center', on: false, sortable: true, width: '140', class: 'black--text sftyHeader py-2'},
            { text: '출근시간', value: '', align: 'center', on: false, sortable: true, width: '80', class: 'black--text sftyHeader py-2'},
            { text: '퇴근시간', value: '', align: 'center', on: false, sortable: true, width: '80', class: 'black--text sftyHeader py-2'},
            { text: '익일근로', value: '', align: 'center', on: false, sortable: true, width: '80', class: 'black--text sftyHeader py-2'},
            { text: '주간(06~22)', value: '', align: 'center', on: false, sortable: true, width: '80', class: 'black--text sftyHeader py-2'},
            { text: '야간(22~06)', value: '', align: 'center', on: false, sortable: true, width: '80', class: 'black--text sftyHeader py-2'},
            { text: '야간익일(01~05)', value: '', align: 'center', on: false, sortable: true, width: '80', class: 'black--text sftyHeader py-2'},
            { text: '근무시간', value: '', align: 'center', on: false, sortable: true, width: '80', class: 'black--text sftyHeader py-2'},
            { text: '비고', value: '', align: 'center', on: false, sortable: true, width: '100', class: 'black--text sftyHeader py-2'},
            { text: '처리', value: '', align: 'center', on: false, sortable: true, width: '100', class: 'black--text sftyHeader py-2'},
        ],
        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '', inqYYMM: '',
            rptTyp: '1', mode: '2', list: [ { bzClcd: [], bzPk: [] } ],
        },
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>

<style>
    .dlyWrk .v-data-table__wrapper table thead tr th {
        background-color: #ededed !important; box-shadow: initial !important;
        border-top: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;
    }
</style>