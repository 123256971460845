<template>
    <v-sheet class="ma-0 pa-0" rounded="">
        <div>
            <basicInfo :bnMmbrPk="this.$parent.$parent.$parent.$parent.selectedItem" ref="basicInfo"></basicInfo>
        </div>
        <v-snackbar v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500"
            class="pa-0" :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
            <div style="text-align: center;">
                <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{ snackOptions[2] }}</span>
            </div>
        </v-snackbar>
        <v-form ref="mdlform" lazy-validation>
            <v-row class="mt-3" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 justify-center align-center pa-0 ma-0" cols="8"
                    style="height: 80px; text-align: center;">
                    <div class="greyE01 d-flex justify-center align-center pa-0 ma-0"
                        style="height: 40px; text-align: center; border-bottom: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">수급자평가</span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-0 ma-0"
                        style="height: 40px; text-align: center; border-bottom: 1px solid #dbdbdb !important;">
                        <v-col class="d-flex justify-center align-center pa-0 ma-0" cols="6"
                            style="height: 40px; border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                            <span class="greyE01 black--text font-weight-medium" style="">
                                신체상태
                                <v-btn icon class=" rounded d-inline-block" :disabled="items2.bnfcrExamHisPk == null"
                                    @click="showDialog(0, items2.bnfcrExamHisPk)"><v-icon>mdi-square-edit-outline</v-icon></v-btn>
                            </span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center pa-0 ma-0" cols="6">
                            <span class="black--text font-weight-medium" style="">수급자욕구
                                <v-btn icon class=" rounded d-inline-block" :disabled="items3.bnfcrExamHisPk == null"
                                    @click="showDialog(1, items3.bnfcrExamHisPk)"><v-icon>mdi-square-edit-outline</v-icon></v-btn></span>
                        </v-col>
                    </div>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="4"
                    style="height: 80px; text-align: center; border-left: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium">급여제공 계획
                        <v-btn icon class=" rounded d-inline-block" :disabled="items4.bnftOfrPlnHisPk == null"
                            @click="showDialog(2, items4.bnftOfrPlnHisPk)"><v-icon>mdi-square-edit-outline</v-icon></v-btn></span>
                </v-col>
                <v-col class="d-flex  align-center pa-0 ma-0" cols="8" style="height: 150px; text-align: center;">
                    <v-col class="d-flex justify-center align-center pa-0 ma-0" cols="6"
                        style="height: 150px; border-right: 1px solid #dbdbdb !important;">
                        <span class="black--text" style="width: 99%; text-align: left;height: 150px;overflow-y:scroll;">
                            {{ items2.itmVal }}
                        </span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center pa-0 ma-0" cols="6">
                        <span class="black--text" style="width: 99%; text-align: left; height: 150px;overflow-y:scroll;">
                            {{ items3.itmVal }}
                        </span>
                    </v-col>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-0 ma-0" cols="4"
                    style="height: 150px; border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text"
                        style="height: 150px; text-align: left !important; width: 99%;  overflow-x:hidden;overflow-y:scroll;"
                        v-if="items4 == {}">
                    </span>
                    <span class="black--text"
                        style="height: 150px; text-align: left !important; width: 99%;  overflow-x:hidden;overflow-y:scroll;"
                        v-else>
                        {{ items4.cmnts }}</span>
                </v-col>
            </v-row>
            <div style="margin-top: 10px;">
                <span class=" hamletFont text-h6 black--text">
                    <v-icon color="black">mdi-chevron-right</v-icon>
                    <span>프로그램&nbsp;수급자&nbsp;그룹&nbsp;정보</span>
                </span>
            </div>
            <v-row class="mt-3" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="2"
                    style="height: 40px; text-align: center;">
                    <span class="black--text font-weight-medium">
                        작성일
                    </span>
                </v-col>
                <v-col class="d-flex align-center pa-0 ma-0" cols="4" style="height: 40px;">
                    <!-- <span class="d-inline-block ml-1" style="width:50%;">
                        <v-menu ref="menu2" v-model="menu[0]" min-width="100" max-width="300"
                            :close-on-content-click="false" :return-value.sync="items.wrtDt" transition="scale-transition"
                            offset-y dense>
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="items.wrtDt" :rules="[rules.required]" readonly hide-details outlined
                                    dense v-bind="attrs" v-on="on">
                                </v-text-field>
                            </template>
                            <v-date-picker v-model="items.wrtDt" no-title scrollable @click="datepicker()" :min="nowDate">
                                <v-spacer></v-spacer>
                                <v-btn class="font-weight-bold" color="blueBtnColor" text @click="onModal(0)">Cancel</v-btn>
                                <v-btn class="font-weight-bold" color="blueBtnColor" text
                                    @click="$refs.menu2.save(items.wrtDt)">OK</v-btn>
                            </v-date-picker>
                        </v-menu>
                    </span> -->
                    <span class="white d-inline-block py-1 ml-1" style="width: 150px;">
                        <CmmDateComp v-model="items.wrtDt" :required="true"></CmmDateComp>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="2"
                    style="height: 40px; text-align: center;">
                    <span class="black--text font-weight-medium">
                        작성자
                    </span>
                </v-col>
                <v-col class="d-flex align-center pa-0 ma-0" cols="4" style="height: 40px;">
                    <span class="d-inline-block ml-1" style="width: 50%;">
                        <v-text-field
                            v-model="items.wrtr" :key="forceRender" :rules="[rules.required]"
                            color="blue" height="28" ref="info"
                            readonly hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <v-btn class="ml-1 brown lighten-3 " small min-width="30" height="25" dark
                        style="padding:0px 1px 0px 2px !important" @click="onModal(1)">
                        <v-icon size="20">mdi-account-edit</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-data-table class="msclnTable mt-2" height="" locale="ko" :headers="headers" :items="items1"
                hide-default-header disable-pagination hide-default-footer dense style="border-bottom: 1px solid #dbdbdb;">
                <template v-slot:header="{ props }">
                    <thead style="">
                        <th class=" justify-center align-center greyE01 py-1"
                            style="border-right: 1px solid #dbdbdb !important; width: 25%; border-top: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                            {{ props.headers[0].text }}</th>
                        <th class=" justify-center align-center greyE01"
                            style="border-right: 1px solid #dbdbdb !important; width: 65%; border-top: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                            {{ props.headers[1].text }}</th>
                        <th class=" justify-center align-center greyE01"
                            style="width: 10%; border-top: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                            <v-btn class="" color="blue200" icon height="26" :disabled="btnOnOff"
                                @click="onModal(2)"><v-icon>mdi-plus-circle-outline</v-icon></v-btn>
                        </th>
                    </thead>
                </template>
                <template v-slot:item='{ item, index }'>
                    <tr style="border-top: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                        <td class="fixedCol0"
                            style="height:40px !important; text-align: center; border-right: 1px solid #dbdbdb !important;">
                            <span style="width:35%; font-size: 1rem !important;">{{ item.bnfcrgrNm }}</span>
                        </td>
                        <td class="fixedCol0"
                            style="height:40px !important; text-align: center; border-right: 1px solid #dbdbdb !important;">
                            <span style="width:35%;">{{ item.bnfcrgrDesc }}</span>
                        </td>
                        <td class="fixedCol0"
                            style="height:40px !important; text-align: center;">
                            <span style="width:9%;">
                                <v-btn class="mr-1" color="grey006" icon height="26" v-if="item.bnfcrgrBnfcrPk > 0"
                                    @click="onDelete(index)"><v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn>
                                <v-btn class="mr-1" color="grey006" icon height="26" v-else
                                    @click="onDelete3(index)"><v-icon>mdi-minus-circle-outline</v-icon>
                                </v-btn>
                            </span>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-form>
        <v-dialog v-model="menu[1]" eager content-class="round" persistent max-width="800">
            <EmpSelector @isClose="isModal(1)" @outEmp="getEmpInfo" :prtWrtDt="''"></EmpSelector>
        </v-dialog>
        <v-dialog v-model="menu[2]" eager content-class="round" persistent max-width="800">
            <PrgrmgrMultiSelector ref="pMSelector" @isClose="isModal(2)" @reload="reset()" @outEmp="setprggr" :prgBnfcrgrChkItems="prgGrchkItems" :prgBnfcrgrNoItems="prgGrNoItems">
            </PrgrmgrMultiSelector>
        </v-dialog>
        <v-dialog eager v-model="dialogTab[0]" persistent max-width="1400" min-width="1400">
            <HlthEvltnComp 
                ref="hlthEvltn" 
                :bnMmbrPk="bnMmbrPk" 
                :inqYYYY="year" 
                :btnDisabled="true"
                :hlthEvltnPk="hlthEvltnPk"
                @modalEvent="modalEvent">
            </HlthEvltnComp>
        </v-dialog>
        <v-dialog eager v-model="dialogTab[1]" persistent max-width="1400" min-width="1400">
            <DsrEvltnComp 
                ref="dsrEvltn" 
                :bnMmbrPk="bnMmbrPk"    
                :inqYYYY="year" 
                :btnDisabled="true" 
                :bsrEvltnPk="bsrEvltnPk"
                @modalEvent="modalEvent">
            </DsrEvltnComp>
        </v-dialog>
        <v-dialog v-model="dialogTab[2]" persistent max-width="1600" min-width="1600">
            <BnftSrvPlnComp 
                v-if="dialogTab[2]"
                ref="bnftSrvPln"
                :bnMmbrPk="bnMmbrPk"
                :bnfcrPk="bnfcrPk"
                :inqYYYY="year"
                :btnDisabled="true"
                :bnftSrvPlnCmpPk="bnftSrvPlnCmpPk"
                @modalEvent="modalEvent"
            ></BnftSrvPlnComp>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500">
            <btnModalDelete :title="'프로그램 수급자 그룹'" @madalState="onDeleteBtn"></btnModalDelete>
        </v-dialog>
    </v-sheet>
</template>

<script>
import basicInfo from '../bnfcr/BnfcrBasicInfo.vue';
import EmpSelector from '../../components/commons/EmpSelector.vue';
import PrgrmgrMultiSelector from '../../components/prgrm/PrgrmgrMultiSelector.vue'
import HlthEvltnComp from '../../components/bnftSrv/HlthEvltn.vue'; //인지기능
import DsrEvltnComp from '../../components/bnftSrv/DsrEvltn.vue'; //욕구사정
import BnftSrvPlnComp from '../../components/bnftSrv/BnftSrvPln.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';
import { getBnfcrBasicInfo } from '../../api/bnfcr.js';
import { selBnfcrExamSmryList, selBnfcrBnftPlnHisInfo } from '../../api/bnftoffer';
import { selBnfcrgrBnfcrList, insBnfcrgrBnfcrMulti, delBnfcrgrBnfcr } from '../../api/prgrm.js';
export default {
    name: 'GrpstRcpnt',

    components: {
        basicInfo,
        EmpSelector,
        PrgrmgrMultiSelector,
        HlthEvltnComp,
        DsrEvltnComp,
        BnftSrvPlnComp,
        btnModalDelete,
        CmmDateComp,

    },

    props: {

    },

    created: function () {

    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.bnfcrPk = this.$store.getters.getBnfcrState.bnfcr
            this.getInfo()
            this.selBnfcrgrBnfcrList()
            this.selBnfcrExamSmryList1()
            this.selBnfcrExamSmryList2()
            this.selBnfcrBnftOfrPlnList()

            if (this.$store.getters.getBnfcrState.bnmmbr > 0) {
                this.btnOnOff = false

            } else {
                this.btnOnOff = true
            }
        })
    },

    computed: {
        getBnmmbrPk() {
            return this.$store.getters.getBnfcrState.bnmmbr
        }
    },

    watch: {
        getBnmmbrPk: function (value) {
            this.items2 = Object.assign({})
            this.items3 = Object.assign({})
            this.items4 = Object.assign({})
            if (value > 0) {
                this.btnOnOff = false

            } else {
                this.btnOnOff = true
            }
            this.bnMmbrPk = value
            this.bnfcrPk = this.$store.getters.getBnfcrState.bnfcr
            this.getInfo()
            this.selBnfcrgrBnfcrList()
            this.selBnfcrExamSmryList1()
            this.selBnfcrExamSmryList2()
            this.selBnfcrBnftOfrPlnList()
        },
    },

    methods: {
        selBnfcrExamSmryList1: function () {
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum,
            obj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            obj.bnfcrPk = this.$store.getters.getBnfcrState.bnfcr
            obj.clsfc1Cd = '105'
            obj.clsfc2Cd = '1'
            obj.clsfc3Cd = '1'

            selBnfcrExamSmryList(obj)
                .then((response) => (this.selBnfcrExamSmryListAfter1(response.data)))
                .catch((error) => console.log('connect error /bnfcr/selBnfcrExamSmryList : ' + error))
        },
        selBnfcrExamSmryListAfter1: function (res) {
            if (res.length > 0)
                this.items2 = res[0]
            else
                this.$refs.mdlform.resetValidation()
        },
        selBnfcrExamSmryList2: function () {
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum,
            obj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            obj.bnfcrPk = this.$store.getters.getBnfcrState.bnfcr
            obj.clsfc1Cd = '101'
            obj.clsfc2Cd = '999'  

            selBnfcrExamSmryList(obj)
                .then((response) => (this.selBnfcrExamSmryListAfter2(response.data)))
                .catch((error) => console.log('connect error /bnfcr/selBnfcrExamSmryList : ' + error))
        },
        selBnfcrExamSmryListAfter2: function (res) {
            if (res.length > 0)
                this.items3 = res[0]
            else
                this.$refs.mdlform.resetValidation()
        },
        selBnfcrBnftOfrPlnList: function () {            
            let obj = { 
                fcltyNum    : this.$store.getters.getFcltyNum, 
                bnMmbrPk    : this.$store.getters.getBnfcrState.bnmmbr, 
                plnClcd     : '201',
                mdfClcd     : '1'
            }

            selBnfcrBnftPlnHisInfo(obj)
                .then((response) => (this.selBnfcrBnftOfrPlnListAfter(response.data)))
                .catch((error) => console.log('connect error /bnfcr/selBnfcrBnftPlnHisInfo : ' + error))
        },
        selBnfcrBnftOfrPlnListAfter: function (res) {
            if (res.result != null)
                this.items4 = res.result
        },
        selBnfcrgrBnfcrList: function () {
            selBnfcrgrBnfcrList(this.$store.getters.getFcltyNum, this.$store.getters.getBnfcrState.bnmmbr)
                .then((response) => (this.selBnfcrgrBnfcrListAfter(response.data)))
                .catch((error) => console.log('connect error /prgrm/selBnfcrgrBnfcrList : ' + error))
        },
        selBnfcrgrBnfcrListAfter: function (data) {
            if (data != '') {
                this.items1 = data;
            } else {
                this.items1.splice(0)
            }
            if (data.length > 0) {
                this.items.wrtDt = data[0].wrtDt?.beforeDateFormatHyp()
                this.items.wrtr = data[0].wrtr
            }
        },
        onShow: function () {
            this.insBnfcrgrBnfcrMulti()
        },
        insBnfcrgrBnfcrMulti: function () {
            if (this.$refs.mdlform.validate()) {
                let obj = {
                    list: [],
                }
                this.items1.forEach(e => {
                        let item = {
                            prgBnfcrgrInfPk: e.prgBnfcrgrInfPk,
                            bnfcrgrBnfcrPk: e.bnfcrgrBnfcrPk,
                            fcltyNum: this.$store.getters.getFcltyNum,
                            bnMmbrPk: this.bnMmbrPk,
                            userNm: this.$store.getters.getUserNm,
                            wrtDt: this.items.wrtDt?.afterDateFormatHyp(),
                            wrtr: this.items.wrtr,
                        }
                        obj.list.push(item)
                });
                insBnfcrgrBnfcrMulti(obj)
                    .then((response) => (this.insBnfcrgrBnfcrMultiAfter(response.data)))
                    .catch((error) => console.log('connect error /opr/insBnfcrgrBnfcrMulti : ' + error))
            }else{
                this.snackControll([true, 2500, '필수 항목을 입력해주세요.', 'warning'])
            }
                
        },
        insBnfcrgrBnfcrMultiAfter(res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.reset()
            }
            else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        getInfo() {
            let fcltyNum = this.$store.getters.getFcltyNum
            let bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            getBnfcrBasicInfo(fcltyNum, bnMmbrPk)
                .then((response) => (this.setDataFormat(response.data)))
                .catch((error) => console.log(error))
        },
        setDataFormat(data) {
            if (data != '') {
                this.items = data;
                let gndr = this.items.gndr;
                let entDt = this.items.entDt;
                let majorDsss = this.items.majorDsss
                //성별
                if (gndr != null) {
                    if (gndr == 'F') {
                        gndr = '여';
                    } else if (gndr == 'M') {
                        gndr = '남';
                    }
                }

                //날짜 형식 포멧 YYYY.MM.DD
                if (entDt != null) {
                    entDt = entDt.beforeDateFormatDot();
                }

                if (majorDsss != null) {
                    majorDsss = this.cutText(majorDsss, 20)
                }
                this.items.gndr = gndr;
                this.items.entDt = entDt;
                this.items.majorOrgDsss = this.items.majorDsss
                this.items.majorDsss = majorDsss;

                this.$emit('setParentData', this.items)
            } else {
                this.items = Object.assign({})
                this.$refs.mdlform.resetValidation()
            }
        },
        onDeleteBtn: function (res) {
            if (res == 9990) {
                if (this.items1[this.key].bnfcrgrBnfcrPk > 0) {
                    let obj = {
                        fcltyNum: this.$store.getters.getFcltyNum,
                        userNm: this.$store.getters.getUserNm,
                        bnfcrgrBnfcrPk: this.items1[this.key].bnfcrgrBnfcrPk,
                    }
                    delBnfcrgrBnfcr(obj)
                        .then((response) => (this.delBnfcrgrBnfcrAfter(response.data)))
                        .catch((error) => console.log(error))
                } else {
                    this.items1.splice(this.key, 1)
                }
            } else if (res == 7) {
                this.dialogDelete = false
            }

        },
        onDelete(key) {
            this.key = key
            this.dialogDelete = true
        },
        onDelete3(key) {
            this.items1.splice(key, 1)
        },
        modalEvent: function (event) {
            if (event == 99) {
                this.getAllBnftList();
            } else {
                this.hlthEvltnPk = -1
                this.bsrEvltnPk  = -1
                this.bnftSrvPlnCmpPk = -1
                this.dialogTab.splice(0, 1, false)
                this.dialogTab.splice(1, 1, false)
                this.dialogTab.splice(2, 1, false)
            }
        },
        delBnfcrgrBnfcrAfter(res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.dialogDelete = false
                this.reset()
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        reset() {
            this.selBnfcrgrBnfcrList()
        },
        bnfcrBasicInfoReLoad() {
            this.getInfo()
        },
        getBnfcrData() {
            return this.items
        },
        cutText(text, count, more = "...") {
            let length = text.length;

            if (length <= count)
                return text;
            else
                return text.substring(0, count) + " " + more;
        },
        onModal: function (key) {
            this.menu.splice(key, 1, true)
            if (key == 2) {
                this.$refs.pMSelector.btnShowMethod()
                this.$refs.pMSelector.onShow()
                this.prgGrchkItems.splice(0)
                this.items1.forEach(x => {
                    this.prgGrchkItems.push(x.prgBnfcrgrInfPk)
                })
                this.$refs.pMSelector.onShow()
            } else if ( key == 0 ) {
                this.menu.splice(0, 1, false)
            }
        },
        isModal: function (key) {
            this.menu.splice(key, 1, false)
            
        },
        getEmpInfo: function (obj) {
            this.items.wrtr = obj[0].name
        },
        clearForm: function () {
            this.items1.splice(0)
        },
        setprggr: function (emps) {
            let mmbrs = []
            this.items1.forEach(x => { mmbrs.push(x.prgBnfcrgrInfPk) })
            emps.forEach(x => {
                let bl = mmbrs.includes(x.prgBnfcrgrInfPk)

                if (!bl) {
                    let obj = {
                        bnfcrgrNm: x.bnfcrgrNm,
                        bnfcrgrDesc: x.bnfcrgrDesc,
                        prgBnfcrgrInfPk: x.prgBnfcrgrInfPk,
                        bnfcrgrBnfcrPk: 0
                    }
                    this.items1.push(obj)
                    this.items.wrtDt = null
                }
            })
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        showDialog(tabkey, value) {                        
            this.dialogTab.splice(tabkey, 1, true)

            if (tabkey == 0) {
                this.hlthEvltnPk = value
            } else if (tabkey == 1) {
                this.bsrEvltnPk = value
            } else if (tabkey == 2) {
                this.bnftSrvPlnCmpPk = value                
            }

        },

    },
    data: () => ({
        bnMmbrPk: 0,
        bnfcrPk: 0,
        hlthEvltnPk:-1,
        bsrEvltnPk:-1,
        bnftSrvPlnCmpPk:-1,
        headers: [
            { text: '수급자 그룹명', hisPk: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '설명', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
        ],
        rules: {
            required: value => !!value || 'Required.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            selCheck: value => !(value == '선택' || value == '') || 'Required.',
        },
        majorDsssTooltip: false,
        items: {},
        items1: [],
        items2: [],
        items3: [],
        items4: {},
        dialogTab: [false, false, false],
        dialog: [false],
        menu: [false, false, false],
        nowDate: '',
        forceRender: 0,
        snackOptions: [false, 3000, '', 'info'],
        year: 0,
        btnOnOff: true,
        key: 0,
        dialogDelete: false,
        prgGrchkItems: [],
        prgGrNoItems: [],
    }),
};
</script>