<template>
    <v-sheet class="d-flex rounded-lg rounded-l-0" color="" min-width="1260" max-width="1560" height="835">
        <v-sheet class="d-inline-block pa-2" :width="midAreaWidth" height="835" color="midAreaColor">
            <v-row class="px-2" no-gutters>
                <v-col cols="12">
                    <span class="hamletFont text-h5 topTitleColor--text font-weight-black">5-7. 급식운영일지</span>
                </v-col>
            </v-row>
            <v-row class="pa-0 my-4" no-gutters>
                <v-col cols="12">
                    <expandWeekOnlyPicker :prtDate="sltDate" @nowWeek="getWeek"></expandWeekOnlyPicker>
                </v-col>
            </v-row>
            <v-row class="pa-0 mt-4 ml-3 mr-2" no-gutters>
                <v-col cols="12">
                    <!-- v-data-table -->
                    <v-data-table class="dft-hvr-tbl py-1" locale="ko" :headers="headerList" :items="sftyList"
                    disable-pagination fixed-header hide-default-header hide-default-footer dense>
                
                    <template v-slot:header="{ props }" >
                        <thead>
                            <tr>
                                <th :class="props.headers[0].class" :style="props.headers[0].style">{{props.headers[0].text}}</th>
                                <th :class="props.headers[1].class" :style="props.headers[1].style">{{props.headers[1].text}}</th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item }'>
                        <tr :class="[selectedItem == item.mealSrvcDt ? 'onClr' : '']" @click="tableClickEvent(item.mealSrvcDt)">
                            <td class="" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{item.mealSrvcDt?.beforeDateFormatDot()}}</span>
                            </td>
                            <td class="" style="height:40px !important; text-align: center;">
                                <span v-if="item.wrtr == '미작성'" class="cmmFontSizeOne red--text" style="color: red">미작성</span>
                                <span v-else class="cmmFontSizeOne">{{item.wrtr}}</span>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
                </v-col>
            </v-row>
        </v-sheet>
        <router-view ref="sftyChild"  @reload="reset()"></router-view>
    </v-sheet>
</template>

<script>
import { selMealSrvcJrnlSmryList } from '../../api/bnftrcrd.js';
import expandWeekOnlyPicker from '../../components/commons/expandWeekOnlyPicker.vue';

export default {
    name: 'SftySpCnfgr',
        
    components: {
        expandWeekOnlyPicker,
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.selectedItem = this.sltDate
            let timezoneOffset = new Date().getTimezoneOffset() * 60000
            let timezoneDate = new Date(Date.now() - timezoneOffset)
            this.tableClickEvent(timezoneDate.toISOString().split('T',2)[0].afterDateFormatHyp())
        })
    },

    computed: {

    },

    watch:{ 
        
    },
        
    methods: {
        getWeek: function(ws, we){
            this.date.startDay = ws
            this.date.endDay = we
            this.selMealSrvcJrnlSmryList(this.date.startDay, this.date.endDay)
        },
        selMealSrvcJrnlSmryList: function (ws, we){
            selMealSrvcJrnlSmryList(this.$store.getters.getFcltyNum, ws, we)
                .then((response) => ( this.selMealSrvcJrnlSmryListAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/selMealSrvcJrnlSmryList : ' + error))
        },
        selMealSrvcJrnlSmryListAfter: function (res) {
            this.sftyList = res
        },
        tableClickEvent: function (dt){
            this.$refs.sftyChild.sltDate = dt.afterDateFormatDot()
            if(this.selectedItem == -1){
                this.$refs.sftyChild.pageReset()
                this.selectedItem = dt
                this.$refs.sftyChild.selMealSrvcJrnlList(this.$refs.sftyChild.sltDate, 2)
            }
            else if(this.selectedItem != dt){
                this.$refs.sftyChild.pageReset()
                this.selectedItem = dt
                this.$refs.sftyChild.selMealSrvcJrnlList(this.$refs.sftyChild.sltDate, 2)
            }
            else{
                //Form Clear
                this.selectedItem = -1
                this.$refs.sftyChild.pageReset()
            }
        },
        reset: function () {
            this.selMealSrvcJrnlSmryList(this.date.startDay, this.date.endDay)
        },
    },
    
    data: () => ({
        sltDate: '',
        date: { startDay: '', endDay: '' },
        midAreaWidth: '420',
        headerList: [
            { text: '급식일', value: '', align: 'center', sortable: true, width:  '80', class: 'black--text sftyHeader', style: 'border-bottom: 2px solid !important;'},
            { text: '급식자', value: '', align: 'center', sortable: true, width: '80', class: 'black--text sftyHeader', style: 'border-bottom: 2px solid !important;'},
        ],
        sftyList: [],
        selectedItem: -1,
        
    }),
};
</script>