<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">   
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>       
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">전체 건강 바이탈 기록</span>
            <v-btn
                class="ml-1" width="20" height="20" @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 16)" icon>
                <v-icon size="20" style="padding-top: 3px;">mdi-help-circle-outline</v-icon>
            </v-btn>
            <v-spacer></v-spacer> 
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <div class="mx-2 mt-2 d-flex">
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn v-if="list.type == 'action' && list.visible" @click = "onClickBtn(list.text)"
                    :class="list.class" height="30" :color="list.color" rounded
                    :disabled="hlthLoader"
                    style="font-size: 14px;">
                    <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                    {{list.text}}
                </v-btn>
            </span>
        </div>
        <div class="mx-2 mt-2 d-flex justify-center align-center">
             <div>
                <expandNowDatePicker :prtDate="inqYMD" @nowDate="getDate"></expandNowDatePicker>        
            </div>
        </div>
        <div class="mx-2 mt-2 d-flex justify-left align-center">
            <div class="d-flex">
                <exBtn class="mr-1" :btnData="exBtn[0]" @btnClosed="expandClose" @changeCheck="filterSearch" />
                <exBtn v-if="routerRocation != 'EmrgnRcrd' && routerRocation != 'SpclBdrmUseRcrd'"
                    class="mr-1" :btnData="exBtn[1]" @btnClosed="expandClose" @changeCheck="filterSearch" />            
                <span class="d-inline-block" style="width: 130px;">
                    <v-text-field class="ma-0 pa-0 nameSearch" height="35px" background-color="white" append-icon="mdi-magnify"
                        single-line rounded outlined dense filled hide-details label="이름조회" @input="setSearchText">
                    </v-text-field>
                </span>
            </div>           
            <v-spacer></v-spacer>
            <div class="d-flex justify-center align-center pr-4">
                <span class="black--text font-weight-medium">작성자</span>
                <span class="px-1">&#58;</span>
                <span class="d-inline-block" style="width: 70px;">
                    <v-text-field v-model="allWrtr" color="blue" height="28" ref="info" readonly
                        hide-details outlined dense :key="forceRender"></v-text-field>
                </span>
                <v-btn 
                    class="ml-1 brown lighten-3" 
                    small 
                    min-width="30"
                    height="26"  
                    style="padding:0px 1px 0px 2px !important"
                    @click="onModal(1,0)"
                    >
                    <v-icon size="20" class="white--text">mdi-account-edit</v-icon>
                </v-btn>
            </div>   
        </div>
        <v-form ref="form" lazy-validation>
            <div class="mx-2 mt-2">
                <v-data-table 
                    class="" 
                    locale="ko" height="570"
                    :headers="hlthBlnftSrvHeader" 
                    :items="hlthBlnftSrvList" 
                    :loading="hlthLoader"
                    loading-text="Loading... Please wait"
                    :search="schDummy" 
                    :custom-filter="customFilter"
                    hide-default-footer dense 
                    fixed-header 
                    disable-pagination
                    >
                    <!-- <template v-slot:header="{ props }" >
                        <thead>
                            <tr>                                                        
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 4%">{{props.headers[0].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 6%">{{props.headers[1].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 4%">{{props.headers[2].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 7%">{{props.headers[3].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 7%">{{props.headers[4].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 10%">{{props.headers[5].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 10%">{{props.headers[6].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 10%">{{props.headers[7].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 7%">{{props.headers[8].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 7%">{{props.headers[9].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 10%">{{props.headers[10].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 8%">{{props.headers[11].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 8%">{{props.headers[12].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBT" style="width: 4%">{{props.headers[13].text}}</th>
                            </tr>  
                        </thead>
                    </template> -->

                    <template v-slot:item='{ item ,index }'>
                        <tr class="fixedCol">
                            <td class="fixedCol0 clmsBodyCommon tableBR">{{item.rn}}</td>
                            <td class="fixedCol0 clmsBodyCommon tableBR">{{item.name}}</td>
                            <td class="fixedCol0 clmsBodyCommon tableBR">
                                <div class="d-flex justify-center align-center pa-1">
                                    <span>
                                        <v-img class="ic-sex" v-if="item.gndr == 'F'" src="../../assets/icon/ic-female.svg"></v-img>
                                        <v-img class="ic-sex" v-else-if="item.gndr == 'M'" src="../../assets/icon/ic-male.svg"></v-img>
                                    </span>
                                </div>
                            </td>                        
                            <td class="fixedCol0 clmsBodyCommon tableBR">{{item.entStcd}}</td>
                            <td class="fixedCol0 clmsBodyCommon tableBR">{{item.lvnRmNm}}</td>
                            <td class="fixedCol0 clmsBodyCommon tableBR">
                                <div class="d-flex justify-center align-center pa-1">
                                    <span style="width:60px;">
                                        <CmmTimeField v-model="item.obsrvTm" :ref="`cmmtime_`+(index)+`_1`" @nxtFocus="nxtAction(index,2)"></CmmTimeField> 
                                    </span>
                                </div>
                                <!-- <div class="d-flex justify-center align-center pa-1">
                                    <span style="width:60px;">
                                        <v-text-field v-model="item.obsrvTmHh" color="blue" type="number" hide-details outlined dense></v-text-field>
                                    </span>
                                    <span class='px-1'>&#58;</span>
                                    <span style="width:60px;">
                                        <v-text-field v-model="item.obsrvTmMm" color="blue" type="number" hide-details outlined dense></v-text-field>
                                    </span>
                                </div> -->
                            </td>
                            <td class="fixedCol0 clmsBodyCommon tableBR">
                                <div class="d-flex justify-center align-center pa-1">
                                    <span style="width:70px;">
                                        <v-text-field v-model="item.bldPrsH" placeholder="고" color="blue" height="28" hide-details outlined dense :ref="`cmmtime_`+(index)+`_2`" @keydown.enter="nxtAction(index,3)" onfocus="this.select()"></v-text-field>
                                    </span>
                                    <span class='px-1'>&#47;</span>
                                    <span style="width:70px;">
                                        <v-text-field v-model="item.bldPrsL" placeholder="저" color="blue" height="28" hide-details outlined dense :ref="`cmmtime_`+(index)+`_3`" @keydown.enter="nxtAction(index,4)" onfocus="this.select()"></v-text-field>
                                    </span>
                                </div>
                            </td>
                            <td class="fixedCol0 clmsBodyCommon tableBR">
                                <div class="d-flex justify-center align-center pa-1">
                                    <span style="width:70px;">
                                        <v-text-field v-model="item.pulse" color="blue" height="28" hide-details outlined dense :ref="`cmmtime_`+(index)+`_4`" @keydown.enter="nxtAction(index,5)" onfocus="this.select()"></v-text-field>
                                    </span>
                                </div>
                            </td>
                            <td class="fixedCol0 clmsBodyCommon tableBR">
                                <div class="d-flex justify-center align-center pa-1">
                                    <span style="width:70px;">
                                        <v-text-field v-model="item.tmprt" color="blue" height="28" hide-details outlined dense  :ref="`cmmtime_`+(index)+`_5`" @keydown.enter="nxtAction(index,6)" onfocus="this.select()"></v-text-field>
                                    </span>
                                </div>
                            </td>
                            <td class="fixedCol0 clmsBodyCommon tableBR">
                                <div class="d-flex justify-center align-center pa-1">
                                    <span style="width:70px;">
                                        <v-text-field v-model="item.rsprt" color="blue" height="28" hide-details outlined dense :ref="`cmmtime_`+(index)+`_6`" @keydown.enter="nxtAction(index,7)" onfocus="this.select()"></v-text-field>
                                    </span>
                                </div>
                            </td>
                            <td class="fixedCol0 clmsBodyCommon tableBR">
                                <div class="d-flex justify-center align-center pa-1">
                                    <span style="width:70px;">
                                        <v-text-field v-model="item.bldSgr" color="blue" height="28" hide-details outlined dense :ref="`cmmtime_`+(index)+`_7`" @keydown.enter="nxtAction(index,8)" onfocus="this.select()"></v-text-field>
                                    </span>
                                </div>
                            </td>
                            <td class="fixedCol0 clmsBodyCommon tableBR">
                                <div class="d-flex justify-center align-center pa-1">
                                    <span style="width:70px;">
                                        <v-text-field v-model="item.wght" color="blue" height="28" hide-details outlined dense :ref="`cmmtime_`+(index)+`_8`" @keydown.enter="nxtAction(index,9)" onfocus="this.select()"></v-text-field>
                                    </span>
                                </div>
                            </td>
                            <td class="fixedCol0 clmsBodyCommon tableBR">  
                                <div class="d-flex justify-center align-center pa-1">
                                    <span class="d-inline-block" style="width: 70px;">
                                        <v-text-field v-model="item.wrtr" color="blue" height="28" ref="info" readonly
                                            hide-details outlined dense :key="forceRender"></v-text-field>
                                    </span>
                                    <v-btn 
                                        class="ml-1 brown lighten-3" 
                                        small 
                                        min-width="30"
                                        height="26"  
                                        style="padding:0px 1px 0px 2px !important"
                                        @click="onModal(0,item.bnMmbrPk)"
                                        >
                                        <v-icon size="20" class="white--text">mdi-account-edit</v-icon>
                                    </v-btn>
                                </div>                          
                            </td>
                            <td class="fixedCol0 clmsBodyCommon">
                                <div class="d-flex justify-center align-center pa-1">
                                    <v-btn  icon color='grey006' @click="delList(item)">                            
                                        <v-tooltip top >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon size="20" v-bind="attrs" v-on="on">mdi-trash-can-outline</v-icon>
                                            </template>
                                            <span>삭제</span>
                                        </v-tooltip>
                                    </v-btn>
                                </div>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </div>
        </v-form>
        <div class="ma-0 pa-0">
            <v-dialog v-model="menu[0]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="isModal" @outEmp="getEmpInfo" :prtWrtDt = prtWrtDt></EmpSelector>
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import CmmTimeField from '../commons/CmmTimeField.vue';
import exBtn from '../../components/commons/expandButtonCustom.vue';
import expandNowDatePicker from '../../components/commons/expandNowDatePicker.vue';
import EmpSelector from '../../components/commons/EmpSelector.vue';
import { getMultiStcd } from '../../api/index.js';
import { selLvnRmInf } from '../../api/opr.js';
import { selAllHlthVtlRecList, insHlthVtlMultiRec} from '../../api/bnftrcrd.js';

export default {
    name: 'HlthBlnftSrvRcrdVitWrt',
    
    components: {  
        exBtn,
        expandNowDatePicker,
        EmpSelector,
        CmmTimeField,
    },

    props : {
        hlthBlnftSrvRcrdVitPk:{type:Number, default:0},
        inqYMD:{type:String, default:''}
    },

    computed: {
        
    },

    created: function(){       
       this.getCode(['65'])   
       this.getLvnRmInf() 
    },

    mounted: function(){
        this.$nextTick(function () {
            this.ymd = this.inqYMD
            // this.getAllHlthVtlRecList()
        })
    },

    watch: {
        // 'hlthBlnftSrvRcrdVitPk':function(value){
        //     if(value>0){         
        //         this.ymd = this.inqYMD
        //         // this.getAllHlthVtlRecList()                
        //     }
        // }
    },

    methods: {  
        nxtAction:function(index, key){
            if(key == 1){
                let target =  'cmmtime_'+(index+1)+'_'+key
                if(this.$refs[target] != undefined)  this.$refs[target].focus()
            }else if(key == 2){
                let target =  'cmmtime_'+index+'_'+key
                if(this.$refs[target] != undefined)  this.$refs[target].focus()
            }else if(key == 3){
                let target =  'cmmtime_'+index+'_'+key
                if(this.$refs[target] != undefined)  this.$refs[target].focus()
            }else if(key == 4){
                let target =  'cmmtime_'+index+'_'+key
                if(this.$refs[target] != undefined)  this.$refs[target].focus()
            }else if(key == 5){
                let target =  'cmmtime_'+index+'_'+key
                if(this.$refs[target] != undefined)  this.$refs[target].focus()
            }else if(key == 6){
                let target =  'cmmtime_'+index+'_'+key
                if(this.$refs[target] != undefined)  this.$refs[target].focus()
            }else if(key == 7){
                let target =  'cmmtime_'+index+'_'+key
                if(this.$refs[target] != undefined)  this.$refs[target].focus()
            }else if(key == 8){
                let target =  'cmmtime_'+index+'_'+key
                if(this.$refs[target] != undefined)  this.$refs[target].focus()
            }else if(key == 9){
                if(index+1 < this.hlthBlnftSrvList.length){
                    let target =  'cmmtime_'+(index+1)+'_1'
                    if(this.$refs[target].$refs.cmmTimeField != undefined) this.$refs[target].$refs.cmmTimeField.focus()
                }else if(index+1 == this.hlthBlnftSrvList.length){
                    let target =  'cmmtime_'+0+'_1'
                    if(this.$refs[target].$refs.cmmTimeField != undefined) this.$refs[target].$refs.cmmTimeField.focus()
                }

                
            }
        },
        getDate: function (value) {
            if(this.getPreData) this.getPreData = false
            this.ymd = value.date  
            this.getAllHlthVtlRecList()
        },
        getAllHlthVtlRecList: function () {
            this.hlthLoader = true     
            this.hlthBlnftSrvList.splice(0)             
            selAllHlthVtlRecList(this.$store.getters.getFcltyNum, this.ymd)
                .then((response) => ( this.getAllHlthVtlRecListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/selAllHlthVtlRecList : ' + error))
        },
        getAllHlthVtlRecListAfter:function(res){
            this.filledBtn[0].visible = true            
            res.forEach(el => {
                this.cdItems.forEach((cd) => {
                    if(el.entStcd == cd.valcd) el.entStcd = cd.valcdnm
                });//end foreach

                if(el.obsrvTmHh !='' && el.obsrvTmHh !=null && el.obsrvTmMm !='' && el.obsrvTmMm !=null ){
                    el.obsrvTm = el.obsrvTmHh+":"+el.obsrvTmMm
                }

                if( el.hlthVtlRecPk > 0) this.filledBtn[0].visible = false 

                if(el.obsrvTm == null || el.obsrvTm == undefined)  el.obsrvTm = ''

                this.hlthBlnftSrvList.push(el)
            });
            this.hlthLoader = false
        },

        getPreAllHlthVtlRecList: function () {                  
            this.hlthLoader = true   
            this.hlthBlnftSrvList.splice(0)
            selAllHlthVtlRecList(this.$store.getters.getFcltyNum, this.preymd)
                .then((response) => ( this.getPreAllHlthVtlRecListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/selAllHlthVtlRecList : ' + error))
        },
        getPreAllHlthVtlRecListAfter:function(res){
            
            res.forEach(el => {
                this.cdItems.forEach((cd) => {
                    if(el.entStcd == cd.valcd) el.entStcd = cd.valcdnm
                });//end foreach

                if(el.obsrvTmHh !='' && el.obsrvTmHh !=null && el.obsrvTmMm !='' && el.obsrvTmMm !=null ){
                    el.obsrvTm = el.obsrvTmHh+":"+el.obsrvTmMm
                }

                if(this.getPreData) el.hlthVtlRecPk = 0
                this.hlthBlnftSrvList.push(el)
            });

            if(this.getPreData) this.getPreData = false
            this.hlthLoader = false
        },

        onClickBtn:function(text){            
            if(text == "전일자료") { 
                
                this.getPreData = true
                let date = new Date(this.ymd?.beforeDateFormatHyp())
                let dateArr = new Date(date.setDate(date.getDate()-1)).toISOString().split('T',2)
                date = dateArr[0]?.afterDateFormatHyp()                
                this.preymd = date
                this.getPreAllHlthVtlRecList()
            } else if(text == "저장") { 
                this.onSave()
            }
        },
        onSave:function(){
            if(this.$refs.form.validate()){
                let obj = {
                    list:[],
                }
    
                this.hlthBlnftSrvList.forEach(e => {
    
                    if(e.obsrvTm !='' && e.obsrvTm !=null && e.obsrvTm != undefined){
                        let obsrvTm = e.obsrvTm.split(":")
                        e.obsrvTmHh = obsrvTm[0]
                        e.obsrvTmMm = obsrvTm[1]
                    }
    
                    e.recDt = this.ymd    
                    e.clsfc1Cd = '302'
                    e.clsfc2Cd = '1'
                    e.fcltyNum = this.$store.getters.getFcltyNum
                    e.userNm = this.$store.getters.getUserNm

                    if(e.bldPrsH != '' && e.bldPrsH != null && e.bldPrsL != '' && e.bldPrsL != null) obj.list.push(e)

                })

                this.hlthLoader = true 
                insHlthVtlMultiRec(obj)
                    .then((response) => ( this.onSaveAfter(response.data) ))
                    .catch((error) => console.log('upload error /bnftrcrd/insHlthVtlMultiRec : ' + error))
            }
        },
        onSaveAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.hlthLoader = false
                this.filledBtn[0].visible = false
                this.getAllHlthVtlRecList()
                this.$emit('modelEvent')
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
                this.hlthLoader = false
            }
        },
        delList:function(obj){
            obj.obsrvTmHh = 0
            obj.obsrvTmMm = 0
            obj.bldPrsH = 0
            obj.bldPrsL = 0
            obj.pulse   = ''
            obj.tmprt   = ''
            obj.rsprt   = ''
            obj.bldSgr  = ''
            obj.wght    = ''
        },
        onClose:function(){
            this.$emit('isClose')
        },
        expandClose: function (index) {
            switch(index) {
                case 0: this.schChkField.target = 'entStcd'
                    break;
                case 1: this.schChkField.target = 'lvnRmNm'
                    break;
                default: this.schChkField.target = ''
                    break;
            }
        },
        setSearchText: function (value) {
            this.schField = value

            this.schDummy = this.schDummy + '.'
        },
        filterSearch: function (sch) {
            switch(this.schChkField.target) {
                case 'entStcd': this.schChkField.entStcd.splice(0)
                    sch.forEach((item) => { this.schChkField.entStcd.push(item) });
                    break;
                case 'lvnRmNm': this.schChkField.lvnRmNm.splice(0)
                    sch.forEach((item) => { this.schChkField.lvnRmNm.push(item) });
                    break;
            }
          
            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column
            let col0 = filter.name.includes(this.schField)
            
            let col1 = true
            if(this.schChkField.entStcd.length != 0){
                this.schChkField.entStcd.forEach((stcd, i) => {
                    if(i == 0) col1 = filter.entStcd.includes(stcd)
                    else col1 = col1 || filter.entStcd.includes(stcd)
                });
            }

            let col2 = true
            if(this.schChkField.lvnRmNm.length != 0){
                this.schChkField.lvnRmNm.forEach((stcd, i) => {
                    if(i == 0) col2 = filter.lvnRmNm.includes(stcd)
                    else col2 = col2 || filter.lvnRmNm.includes(stcd)
                });
            }

            return col0 && col1 && col2;
        },
        getLvnRmInf: function () {
            selLvnRmInf(this.$store.getters.getFcltyNum)
                .then((response) => ( this.getLvnRmInfAfter(response.data) ))
                .catch((error) => console.log('connect error /opr/selLvnRmInf : ' + error))
        },

        getLvnRmInfAfter: function (res){
            this.lvnRmInf = res

            this.lvnRmInf.forEach((code) => {
                this.exBtn[1].expandList.push(code.lvnRmNm)
            });//end foreach
        },
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        stCdMapping: function (res){

            this.cdItems = res.vCd65

            res.vCd65.forEach((code) => {
                this.exBtn[0].expandList.push(code.valcdnm)
            });//end foreach
        },
        //작업자선택
        onModal: function (key, idx) {  
            this.key = key
            this.idx = idx
            this.prtWrtDt = this.ymd
            this.menu.splice(0, 1, true)
        },
        isModal: function () {               
            this.menu.splice(0, 1, false)
        },   
        getEmpInfo: function (obj) {
            if(this.key == 0){
                let target = this.hlthBlnftSrvList.filter(x=> x.bnMmbrPk == this.idx)
                target[0].wrtr = obj[0].name
            }else{
                this.allWrtr = obj[0].name
                this.hlthBlnftSrvList.forEach(el => {
                    el.wrtr = obj[0].name
                });
            }

            this.key = 0
            this.idx = 0
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    data: () => ({  
        getPreData:false,
        forceRender:0,
        key:0,
        idx:0,
        allWrtr:'',
        menu:[false,],
        routerRocation: '',
        ymd:'',  
        prtWrtDt:'',
        preymd:'',
        cdItems:[],
        lvnRmInf:[],
        schField: '',
        schDummy: '', 
        hlthLoader:true,
        hlthBlnftSrvHeader:[
            { text: '연번', value: 'rn', align:'center', sortable: false, width: '60', class: 'black--text greyE01 fontOneRem'},
            { text: '수급자', value: 'name', align:'center', sortable: true, width: '100', class: 'black--text greyE01 fontOneRem',},
            { text: '성별', value: 'gndr', align:'center', sortable: true, width: '80', class: 'black--text greyE01 fontOneRem'},
            { text: '현황', value: 'entStcd', align:'center', sortable: true, width: '80', class: 'black--text greyE01 fontOneRem'},
            { text: '생활실', value: 'lvnRmNm', align:'center', sortable: true, width: '120', class: 'black--text greyE01 fontOneRem'},
            { text: '시간(필요시)', value: '', align:'center', sortable: false, width: '110', class: 'black--text greyE01 fontOneRem'},
            { text: '혈압(고/저)', value: '', align:'center', sortable: false, width: '150', class: 'black--text greyE01 fontOneRem'},
            { text: '맥박(회/분)', value: '', align:'center', sortable: false, width: '100', class: 'black--text greyE01 fontOneRem'},
            { text: '체온(°C)', value: '', align:'center', sortable: false, width: '100', class: 'black--text greyE01 fontOneRem'},
            { text: '호흡(회/분)', value: '', align:'center', sortable: false, width: '100', class: 'black--text greyE01 fontOneRem'},
            { text: '혈당(mg/oL)', value: '', align:'center', sortable: false, width: '100', class: 'black--text greyE01 fontOneRem'},
            { text: '체중(kg)', value: '', align:'center', sortable: false, width: '100', class: 'black--text greyE01 fontOneRem'},
            { text: '작성자', value: '', align:'center', sortable: false, width: '130', class: 'black--text greyE01 fontOneRem'},
            { text: '처리', value: '', align:'center', sortable: false, width: '60', class: 'black--text greyE01 fontOneRem'},
        ],
        schChkField: { entStcd: [], lvnRmNm: [], target: '' },
        exBtn: [
            { index:  0, btnTitle: '입소현황', btnIcon: 'house', exWidth: 104, exHeigth: 130, expandList: [], },
            { index:  1, btnTitle: '생활실', btnIcon: 'bed', exWidth: 124, exHeigth: 100, expandList: [], },
        ],
        hlthBlnftSrvList:[],
        filledBtn: [
            { icon: 'mdi-text-box-search-outline', class: 'white--text', color:'#3C9099', text: '전일자료', type: 'action', visible: true, disabled: false, },
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장',type: 'action', visible: true,disabled: false, },                        
        ],
        snackOptions: [false, 3000, '', 'info'],   
    }),
};
</script>
<style scoped>

</style>